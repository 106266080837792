import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { PatternInstance } from '../pattern-instance.model';
import { PluginModel } from '../../plugins/plugin.model';
import { requireNonNull } from '../../common/utils/utils';
import { CreatePatternHelper } from './create-pattern.helper';
import { Dictionary } from '../../model/reducer';
import { SplitPaneConfig } from '../../common/model/split-pane-config.model';
import { IOutputData } from 'angular-split/lib/interface';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../common/mixins/split.mixin';
import { ResizeHelper } from '../../common/helpers/resize.helper';

/**
 * This is the main window for adding a new pattern
 */
@Component({
  selector: 'adm4-create-pattern',
  template: `
    <div class='full-height-flex'>
      <div class='remaining-space-flex-content-wrapper'>
        <div class="remaining-space-flex-content">
          <div class='full-height'>
            <as-split class="hide-as-split-gutter" direction='horizontal' gutterSize='1' useTransition (dragEnd)='onResize($event)'>
              <as-split-area [order]='splitPaneConfig[categoriesSplitAreaKey].order' [size]='splitPaneConfig[categoriesSplitAreaKey].size' [minSize]='20'>
                <div class="full-height-flex">
                  <adm4-column-header styleClass="grey-border-header">
                    {{PATTERN_CATEGORIES}}
                  </adm4-column-header>
                  <div class="remaining-space-flex-content-wrapper">
                    <div class="remaining-space-flex-content">
                      <div class='pattern-categories-container full-height'>
                        <div class="categories-element" *ngFor='let category of categories'
                             [class.selected]='isCategorySelected(category)'
                             (click)='onCategoryClick(category)'>
                          <span class='pattern-category-badge' [class.selected]='isCategorySelected(category)'>{{category | abbreviate:2}}</span>
                          <span class='pattern-category-title'>{{category}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </as-split-area>
              <as-split-area [order]='splitPaneConfig[listSplitAreaKey].order' [size]='splitPaneConfig[listSplitAreaKey].size' [minSize]='30'>
                <div class="full-height-flex patterns-column">
                  <adm4-column-header styleClass="create-pattern-header">
                    {{PATTERNS}}
                  </adm4-column-header>
                  <div class="remaining-space-flex-content-wrapper">
                    <div class='remaining-space-flex-content'>
                      <div class="full-height-flex available-pattern-list-container">
                        <div class='search-wrapper'>
                          <adm4-filter (filter)="searchPatternsByNameAndCategory($event)"
                                       [placeholderText]='"Find patterns"'
                                       [focused]='true'
                                       class='search-input'>
                          </adm4-filter>
                        </div>
                        <div class="remaining-space-flex-content-wrapper">
                          <div class="remaining-space-flex-content">
                            <div class='pattern-list-container full-height'>
                              <adm4-create-pattern-list-element *ngFor='let pattern of filteredPlugins'
                                                                [pattern]='pattern'
                                                                [selectedCategories]='selectedCategories'
                                                                [selected]='isPatternSelected(pattern)'
                                                                [textToHighLight]='filterText'
                                                                (patternClicked)='onPatternClicked(pattern)'
                                                                (patternDoubleClicked)='onAddPattern(patternName, pattern)'></adm4-create-pattern-list-element>
                              <div *ngIf='noFilterResult' class='no-patterns-message'>
                                No result found for {{filterText}}.
                              </div>
                              <div *ngIf='noPatternsFound' class='no-patterns-message'>
                                No pattern types found.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </as-split-area>
              <as-split-area [order]='splitPaneConfig[helpSplitAreaKey].order' [size]='splitPaneConfig[helpSplitAreaKey].size' [minSize]='20'>
                <div class="full-height-flex">
                  <adm4-column-header styleClass="grey-border-header">
                    {{HELP}}
                  </adm4-column-header>
                  <div class="remaining-space-flex-content-wrapper">
                    <div class='remaining-space-flex-content'>
                      <adm4-pattern-details [projectKey]='projectKey'
                                            [pattern]='selectedPlugin'
                      ></adm4-pattern-details>
                    </div>
                  </div>
                </div>
              </as-split-area>
            </as-split>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button class='admn4-button-text'
                (click)='closeClicked.emit()'>Cancel
        </button>
        <button class='admn4-button-ellipse-blue'
                id='createPattern'
                [disabled]='!selectedPlugin'
                (click)='onAddPattern(patternName, selectedPlugin)'>
          {{ADD_PATTERN_TO_PROJECT}}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['create-pattern.component.scss'],
})
@Mixin([SplitMixin])
export class CreatePatternComponent implements ISplitMixin, OnChanges {
  @Input() plugins: PluginModel[];
  @Input() projectKey: string;
  @Input() patternName: string | undefined;
  @Output() dialogChange = new EventEmitter();
  @Output() addPattern = new EventEmitter();
  @Output() closeClicked = new EventEmitter<any>();

  selectedCategories: string[] = [];
  selectedCategory: string;
  categories: string[];
  filterText = '';
  public filteredPlugins: PluginModel[] = [];
  filteredPluginsByCategory: PluginModel[] = [];
  filteredPluginsBySearch: PluginModel[] = [];
  selectedPlugin: PluginModel | undefined;

  PATTERN_CATEGORIES = 'Pattern categories';
  PATTERNS = 'Patterns';
  ADD_PATTERN_TO_PROJECT = 'Add pattern';
  HELP = 'Help';

  readonly categoriesSplitAreaKey = 'categories';
  readonly listSplitAreaKey = 'list';
  readonly helpSplitAreaKey = 'help';
  readonly splitPaneConfigLocalStorageKey = 'create-pattern-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.categoriesSplitAreaKey]: {order: 0, size: 20},
    [this.listSplitAreaKey]: {order: 1, size: 50},
    [this.helpSplitAreaKey]: {order: 2, size: 30}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  constructor() {
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
  }

  get noPatternsFound(): boolean {
    return _.isEmpty(this.filteredPlugins) && _.isEmpty(this.filterText);
  }

  get noFilterResult(): boolean {
    return _.isEmpty(this.filteredPlugins) && !_.isEmpty(this.filterText);
  }

  ngOnChanges(changes: SimpleChanges) {
    const patternsChanged = changes['plugins'];
    if (patternsChanged && patternsChanged.currentValue) {
      this.categories = CreatePatternHelper.getAllCategoriesFromPattern(this.plugins);
      this.filteredPlugins = this.plugins;
      this.filteredPluginsByCategory = this.plugins;
      this.filteredPluginsBySearch = this.plugins;
    }
  }

  searchPatternsByNameAndCategory(filterText: string): void {
    this.filterText = filterText;
    if (this.plugins.length > 0) {
      this.filteredPluginsBySearch = this.plugins.filter((pattern: PluginModel) =>
        pattern.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
        || this.searchByCategory(filterText, pattern.category)
      );
    }
    this.filteredPlugins = CreatePatternHelper.getIntersectionOfFilters(this.filteredPluginsBySearch, this.filteredPluginsByCategory);
    this.selectPatternOnSearch();
  }

  selectPatternOnSearch(): void {
    if (this.filteredPlugins.length === 1) {
      this.selectedPlugin = this.filteredPlugins[0];
    } else {
      this.selectedPlugin = undefined;
    }
  }

  searchByCategory(searchData: string, categories: string[]) {
    return categories.filter((category: string) => category.toLocaleLowerCase().includes(searchData.toLocaleLowerCase())).length > 0;
  }

  onCategoryClick(category: string) {
    const selectedIndex = this.selectedCategories.indexOf(category);
    if (selectedIndex === -1) {
      this.allowOnlyOneCategorySelected();
      this.selectedCategories.push(category);
      this.selectedCategory = category;
    } else {
      this.selectedCategories.splice(selectedIndex, 1);
    }
    this.filterByCategory();
  }

  /**
   * intermediate fix to let only one category selected
   */
  private allowOnlyOneCategorySelected() {
    this.selectedCategories.splice(0, this.selectedCategories.length);
  }

  isCategorySelected(category: string): boolean {
    return this.selectedCategories.indexOf(category) > -1;
  }

  /**
   * Filters out patterns by category, having filterByCategory as the selected categories
   */
  filterByCategory(): void {
    if (this.selectedCategories.length > 0) {
      this.filteredPluginsByCategory = this.plugins.filter(pattern =>
        pattern.category.filter(r => this.selectedCategories.indexOf(r) > -1).length === this.selectedCategories.length
      );
      this.selectPatternOnSelectCategory();
    } else {
      this.filteredPluginsByCategory = this.plugins;
      this.selectedPlugin = undefined;
    }
    this.filteredPlugins = CreatePatternHelper.getIntersectionOfFilters(this.filteredPluginsBySearch, this.filteredPluginsByCategory);
  }

  selectPatternOnSelectCategory(): void {
    if (this.filteredPluginsByCategory.length === 1) {
      this.selectedPlugin = this.filteredPluginsByCategory[0];
    } else {
      this.selectedPlugin = undefined;
    }
  }

  isPatternSelected(pattern: PluginModel): boolean {
    return _.isEqual(this.selectedPlugin, pattern);
  }

  onPatternClicked(pattern: PluginModel) {
    const isSamePlugin = _.isEqual(this.selectedPlugin, pattern);
    if (!isSamePlugin) {
      this.selectedPlugin = pattern;
    } else if (isSamePlugin && this.filteredPlugins.length > 1) {
      this.selectedPlugin = undefined;
    }
  }

  onAddPattern(patternName: string | undefined, selectedPlugin?: PluginModel) {
    const newPattern = new PatternInstance();
    selectedPlugin = requireNonNull<PluginModel>(selectedPlugin);
    newPattern.className = selectedPlugin.className;
    newPattern.name = this.getNewPatternName(patternName, selectedPlugin);
    this.addPattern.emit(newPattern);
  }

  getNewPatternName(patternName: string | undefined, selectedPlugin: PluginModel): string {
    return this.plugins ? patternName || `New ${selectedPlugin.name}` : 'Unnamed';
  }
}

import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { BundleManagementComponent } from './bundle-management.component';
import { BundleManagementTableComponent } from './bundle-management-table/bundle-management-table.component';
import { CommonModules } from '../../common/common.module';
import { BundleDownloadUrlPipe } from './bundle-download-url.pipe';
import { BundleTableComponent } from './bundle-management-table/bundle-table.component';

@NgModule({
  declarations: [
    BundleManagementComponent,
    BundleManagementTableComponent,
    BundleTableComponent,
    BundleDownloadUrlPipe
  ],
  exports: [],
  imports: [
    CommonModules,
    MatTableModule,
    MatSortModule
  ]
})
export class BundleManagementModule { }

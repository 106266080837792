import { ControlValueAccessor } from '@angular/forms';

export class ValueAccessorBase implements ControlValueAccessor {
  _value: any;

  propagateChange = (_: any) => {/* base class */};
  propagateTouch = () => {/* base class */};

  /**
   * @deprecated: probably the whole`ValueAccessorBase` could be removed.
   */
  get value() {
    return this._value;
  }

  /**
   * @deprecated: probably the whole`ValueAccessorBase` could be removed.
   */
  set value(value: any) {
    if (value !== this._value) {
      this._value = value;
      this.propagateChange(value);
    }
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

}

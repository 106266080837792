import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { DeploymentHistoryItem, DeploymentHistoryJobStatus, DeploymentHistoryState, } from '../../../../common/model/deployment-history.model';

@Component({
  selector: 'adm4-inventory-deployment-state-icons',
  template: `
    <mat-icon [ngClass]='statusIconClass' [attr.aria-label]="statusText"
              [ngbTooltip]='deploymentStatusTooltip' placement='top'>
      {{statusIcon}}
    </mat-icon>
    <ng-template #deploymentStatusTooltip><span>{{statusText}}</span></ng-template>

    <mat-icon *ngIf='historyItem.deploymentState' [svgIcon]='k8sStatusIcon' [attr.aria-label]="k8sStatusText"
              [ngbTooltip]='k8sStatusTooltip' placement='bottom'></mat-icon>
    <ng-template #k8sStatusTooltip><span>{{k8sStatusText}}</span></ng-template>
  `,
  styleUrls: ['./inventory-deployment-state-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryDeploymentStateIconsComponent implements OnChanges {

  public statusIcon: string;
  public statusIconClass: string;
  public statusText: string | undefined;

  public k8sStatusIcon: string | undefined;
  public k8sStatusText: string | undefined;

  @Input() historyItem: DeploymentHistoryItem;

  ngOnChanges(): void {
    const errorOutline = 'error_outline';
    const errorIcon = 'error-icon';

    const status: DeploymentHistoryJobStatus | null = this.historyItem.jobStatus;
    const deploymentState: DeploymentHistoryState | undefined = this.historyItem.deploymentState;
    if (deploymentState === DeploymentHistoryState.ROLLBACK_FAILED || deploymentState === DeploymentHistoryState.PROMOTE_FAILED) {
      this.statusIcon = errorOutline;
      this.statusIconClass = errorIcon;
      this.statusText = 'The deployment has failed.';
    } else {
      switch (status) {
        case DeploymentHistoryJobStatus.Done:
          this.statusIcon = 'done';
          this.statusIconClass = 'success-icon';
          this.statusText = 'The deployment was successful.';
          break;
        case DeploymentHistoryJobStatus.Failed:
          this.statusIcon = errorOutline;
          this.statusIconClass = errorIcon;
          this.statusText = 'The deployment has failed.';
          break;
        case DeploymentHistoryJobStatus.Running:
          this.statusIcon = 'autorenew';
          this.statusIconClass = 'done-icon';
          this.statusText = 'The deployment is in progress.';
          break;
        default:
          this.statusIcon = 'report_problem';
          this.statusIconClass = 'warning-icon';
          this.statusText = 'There were some issues on the deployment.';
      }
    }

    switch (deploymentState) {
      case DeploymentHistoryState.CANARY:
        this.k8sStatusIcon = 'canary_active';
        this.k8sStatusText = 'The Secondary deployment is active.';
        break;
      case DeploymentHistoryState.ROLLBACK_FAILED:
        this.k8sStatusIcon = 'canary_active';
        this.k8sStatusText = 'Rollback failed.';
        break;
      case DeploymentHistoryState.PROMOTE_FAILED:
        this.k8sStatusIcon = 'canary_active';
        this.k8sStatusText = 'Promote failed.';
        break;
      case DeploymentHistoryState.PROMOTED:
        this.k8sStatusIcon = 'canary_promoted';
        this.k8sStatusText = 'The Secondary deployment was promoted and became Primary.';
        break;
      case DeploymentHistoryState.ROLLED_BACK:
        this.k8sStatusIcon = 'canary_rolled_back';
        this.k8sStatusText = 'The Secondary deployment was rolled back.';
        break;
      case DeploymentHistoryState.DELETED:
        this.k8sStatusIcon = 'delete';
        this.k8sStatusText = 'The deployment was deleted.';
        break;
      case DeploymentHistoryState.DELETE_FAILED:
        this.k8sStatusIcon = 'delete_forever';
        this.k8sStatusText = 'Deleting the deployment failed.';
        break;
      case DeploymentHistoryState.OUTDATED:
        this.k8sStatusIcon = 'canary_outdated';
        this.k8sStatusText = 'The Secondary deployment became outdated as the newer version was re-deployed.';
        break;
      default:
        this.k8sStatusIcon = undefined;
        this.k8sStatusText = undefined;
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { VariablesDiffViewContext } from './variables-diff-view.context';
import { Observable } from 'rxjs';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { VariableDiffData } from './variable-diff-data.model';
import { UserStateService } from '../../../common/services/user/user.state.service';
import { DiffViewComponent } from '../../../common/model/publish-changes/diff-view-component.model';
import { ProjectChangesetItem } from '../project-changeset-item.model';
import { MetaInfo } from '../../../version-control/meta-info.model';

@Component({
  selector: 'adm4-variables-diff-view',
  templateUrl: './variables-diff-view.component.html',
  styleUrls: ['./variables-diff-view.component.scss', '../../../common/styles/component-specific/diff-view-header.scss', '../../../common/styles/component-specific/dummy-diff-space-taker.scss'],
  providers: [VariablesDiffViewContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariablesDiffViewComponent implements DiffViewComponent<ProjectChangesetItem<MetaInfo, VariablesDiffViewComponent>> {

  /**
   * This field represents selected changeset in left column of publish project, it's a way to connect the 2 sides when interation between them is needed
   */
  changesetItem: ProjectChangesetItem<MetaInfo, VariablesDiffViewComponent>;

  variablesDiffData$: Observable<Diff<VariableDiffData>[]>;
  projectKey$: Observable<string>;
  currentBranch$: Observable<string | null>;
  showOnlyDifferences$: Observable<boolean>;
  currentUsername: string | null;

  constructor(private variablesDiffContext: VariablesDiffViewContext, private userStateService: UserStateService) {
    this.variablesDiffData$ = this.variablesDiffContext.variablesDiffData$;
    this.projectKey$ = this.variablesDiffContext.projectKey$;
    this.currentBranch$ = this.variablesDiffContext.currentBranch$;
    this.showOnlyDifferences$ = this.variablesDiffContext.showOnlyDifferences$;
    this.currentUsername = this.userStateService.username;
  }

  rememberShowDifferencesView(showDiffOnly: boolean): void {
    this.variablesDiffContext.rememberShowDifferencesView(showDiffOnly);
  }

}

<ng-template #sampleDisabled>You need at least one project to be able to include sample data</ng-template>
<form [formGroup]="createInventoryForm" name="form" (ngSubmit)='createInventory()'>
  <div class="create-inventory-container content-container create-item-container full-height-flex">
    <div class='create-inventory-column'>
      <div class='form-group'>
        <label class='input-label' for='inventoryKeyInput'>Inventory key*</label>
        <div class="item-key-container">
          <div class='tenant-key-wrapper' *ngIf='displayTenant'>
            <span>{{selectedTenantKey}} - </span>
            <div class='validation-message-container'></div>
          </div>
          <div class='item-key-input-wrapper'>
            <input cdkFocusInitial
                   class="form-control admn4-text-input"
                   placeholder=' '
                   name="inventoryKey"
                   id='inventoryKeyInput'
                   [formControlName]='INVENTORY_KEY_FORM_CONTROL_NAME'
                   required/>
            <div class="validation-message-container">
              <adm4-validation-message *ngIf='shouldShowInventoryKeyErrorMessage("pattern")' [isError]='true'
                                       [message]='inventoryInvalidError'></adm4-validation-message>
              <adm4-validation-message *ngIf='shouldShowInventoryKeyErrorMessage("inventoryKeyExists")' [isError]='true'
                                       [message]='inventoryKeyAlreadyExistsError'></adm4-validation-message>
              <adm4-validation-message *ngIf='shouldShowInventoryKeyErrorMessage("required")' [isError]='true'
                                       [message]='ERROR_INVENTORY_KEY_REQUIRED'></adm4-validation-message>
            </div>
          </div>
        </div>
      </div>
      <div class='sample-data form-group slight-indentation'>
        <label class='input-label checkbox-label'
               [ngbTooltip]='sampleDisabled' placement='top'
               [disableTooltip]="!projectsAreEmpty(projects$ | async)">
          <mat-checkbox [formControlName]='INCLUDE_SAMPLE_FORM_CONTROL_NAME'
                        [adm4ControlDisable]="projectsAreEmpty(projects$ | async)"></mat-checkbox>
          <span>Include sample data from project</span>
        </label>
        <mat-form-field class='sample-select-wrapper'>
          <mat-select #projectSelection
                      [formControlName]='PROJECT_KEY_FORM_CONTROL_NAME'
                      [disableOptionCentering]='true'
                      [adm4ControlDisable]="shouldDisableProjectDropdown(projects$ | async)"
                      [ngbTooltip]='sampleDisabled' placement='top'
                      [disableTooltip]="!projectsAreEmpty(projects$ | async)"
                      (click)='focusDropdownInput()'>
            <adm4-searchable-dropdown-input *ngIf='projectSelection.focused'
                                            [sourceItems]='projects$ | async'
                                            [placeholder]="'Please select a project...'"
                                            [searchableFormatFn]='searchableProjectFormatFn'
                                            [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                            (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
            <mat-option *ngFor='let project of projects$ | async'
                        [value]='project.projectKey'
                        [hidden]='!isProjectFilteredOut(project)'>{{project.projectKey | cropTenantFromKey}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='form-group'>
        <label class='input-label slidebtn-label'>
          <mat-slide-toggle [formControlName]='IS_VERSIONED_FORM_CONTROL_NAME'></mat-slide-toggle>
          <span class="toggle-label">Connect to Git</span>
        </label>
        <hr>
      </div>
      <div [class.slight-opacity]='!isVersioned' class='slight-indentation'>
        <adm4-version-control-form [predefinedVersionControl]='predefinedVersionControlData()'
                                   [automaticPath]='createInventoryForm.value[INVENTORY_KEY_FORM_CONTROL_NAME]'
                                   [pathRequired]='true'
                                   [isDisabled]='!isVersioned'
                                   (formReady)='addVersionControlForm($event)'
        ></adm4-version-control-form>
        <div class='form-group'>
          <label class='input-label checkbox-label'>
            <mat-checkbox [formControlName]='PUBLISH_REQUIRED_FORM_CONTROL_NAME'
                          [adm4ControlDisable]="!isVersioned"></mat-checkbox>
            <span>Require a published project and inventory for deployment</span>
          </label>
        </div>
      </div>
    </div>
    <div class='create-inventory-column'>
      <div class='form-group kubernetes-toggle-form-group' #kubernetes>
        <label class='input-label slidebtn-label kubernetes-toggle-label'>
          <mat-slide-toggle [formControlName]='IS_KUBERNETES_FORM_CONTROL_NAME'
          ></mat-slide-toggle>
          <span class="toggle-label">Deploy to Kubernetes</span>
        </label>
      </div>
      <div [class.slight-opacity]='!isKubernetes'>
        <hr>
        <adm4-kubernetes-inventory-creation-form (formReady)='addK8sControlForm($event)'
                                                 [isDisabled]='!isKubernetes'></adm4-kubernetes-inventory-creation-form>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <adm4-button-bar [isSubmitDisabled]="!canSave"
                     [submitButtonText]="BUTTON_CREATE"
                     (cancelClicked)='cancel()'></adm4-button-bar>
  </div>
</form>

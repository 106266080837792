import { EmptyAction, NevisAdminAction } from '../actions';
import { GlobalConstantWithUsage, GlobalConstant, Inventory } from '../../inventory/inventory.model';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { InventoryPublishRequiredPayload } from '../../inventory/inventory-publish-required-payload.model';
import { CreateInventoryPayload } from '../../inventory/create-inventory/create-inventory-payload';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import { localStorageInventoryKey } from '../../common/constants/local-storage-keys.constants';
import * as _ from 'lodash';
import { InventoryFileImportPayload } from '../../inventory/file-based-import-inventory/inventory-file-import-payload.model';
import { ConnectInventoryVersionControlPayload } from '../../inventory/connect-inventory-version-control/connect-inventory-version-control.payload';
import { DeploymentHistoryItem } from '../../common/model/deployment-history.model';
import { InventoryUpdateContentPayload } from '../../inventory/inventory-update-content-payload.model';
import { InventoryResourcesWithUsageState, InventoryResourcesState } from './inventory.reducer';
import { Maybe } from '../../common/utils/utils';

export enum InventoryActionTypes {
  SelectInventory = '[Inventory] Select Inventory',
  SelectDiffInventory = '[Inventory] Select Diff Inventory',
  FetchInventoryData = '[Inventory] Fetch Inventory Data',
  StoreInventoryFromLocalStorage = '[Inventory] Store Inventory From Local Storage',
  CheckUpdatesOfInventory = '[Inventory] Check Updates Of Inventory',
  GetUpdatesOfInventory = '[Inventory] Get Updates Of Inventory',
  DeleteInventory = '[Inventory] Delete Inventory',
  DeleteInventorySuccess = '[Inventory] Delete Inventory success',
  LoadInventoriesIfMissing = '[Inventory] Load Inventories if missing',
  LoadInventories = '[Inventory] Load Inventories',
  LoadInventoriesSuccess = '[Inventory] Load Inventories Success',
  InventoriesAvailable = '[Inventory] Inventories available',
  LoadInventoryContent = '[Inventory] Load Inventory Content',
  LoadInventoryContentSuccess = '[Inventory] Load Inventory Content Success',
  LoadDiffInventoryContentSuccess = '[Inventory] Load Diff Inventory Content Success',
  ResetInventories = '[Inventories] Reset Inventories',
  UpdateInventoryContent = '[Inventory] Update Inventory Content',
  UpdateInventoryTimestamp = '[Inventory] Update inventory timestamp',
  UpdateInventoryTimestampSuccess = '[Inventory] Update inventory timestamp Success',
  ClearLocalChangesOfInventory = '[Inventory] Clear local changes of inventory',
  CreateInventory = '[Inventory] Create inventory metadata',
  ImportInventory = '[Inventory] Import inventory',
  ImportInventoryFile = '[Inventory] Import inventory file',
  ConnectInventoryToVersionControl = '[Inventory] Connect Inventory to Version Control',
  ConnectInventoryToVersionControlSuccess = '[Inventory] Connect Inventory to Version Control Success',
  ChangePublishRequiredFlag = '[Inventory] Change Publish Required Flag',
  LoadInventoriesAndNavigate = '[Inventory] Load Inventories And Navigate',
  NavigateToCreatedInventory = '[Inventory] Navigate To Created Inventory',
  ValidateInventoryContent = '[Inventory] Validate Inventory Content',
  ValidateInventoryContentSuccess = '[Inventory] Validate Inventory Content Success',
  ValidateInventoryContentBeforeSave = '[Inventory] Validate Inventory Content Before Save',
  // main difference from reset inventories is that it has special side effect to navigate to inventory editor without selecting any inventory since it's not possible
  ResetInventoriesAfterDeletingLast = '[Inventory] Reset Inventories after deleting last',
  RedeployDeployment = '[Inventory] Redeploy Deployment',

  LoadInventoryResourcesWithUsage = '[Inventory] Load Inventory resources with usage',
  LoadInventoryResources = '[Inventory] Load Inventory resources',
  LoadInventoryResourcesWithUsageSuccess = '[Inventory] Load Inventory resources with usage success',
  LoadInventoryResourcesSuccess = '[Inventory] Load Inventory resources success',
  LoadInventoryTenantScopedResourcesWithUsage = '[Inventory] Load Inventory Tenant Scoped Resources with usage',
  LoadInventoryTenantScopedResources = '[Inventory] Load Inventory Tenant Scoped Resources',
  LoadInventoryTenantScopedResourcesWithUsageSuccess = '[Inventory] Load Inventory Tenant Scoped Resources with usage Success',
  LoadInventoryTenantScopedResourcesSuccess = '[Inventory] Load Inventory Tenant Scoped Resources Success',

  LoadTenantConstants = '[Inventory] Load Inventory tenant constants',
  LoadTenantConstantsWithUsageSuccess = '[Inventory] Load Inventory tenant constants with usage success',
  LoadTenantConstantsSuccess = '[Inventory] Load inventory tenant constants success',
}

export class SelectInventory implements NevisAdminAction<string | null> {
  readonly type = InventoryActionTypes.SelectInventory;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: string | null) {}
}

export class SelectDiffInventory implements NevisAdminAction<string | null> {
  readonly type = InventoryActionTypes.SelectDiffInventory;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: Maybe<string>) {}
}

export class LoadInventoryResourcesWithUsage implements NevisAdminAction<string | null> {
  readonly type = InventoryActionTypes.LoadInventoryResourcesWithUsage;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: string | null) {}
}

export class LoadInventoryResources implements NevisAdminAction<string | null> {
  readonly type = InventoryActionTypes.LoadInventoryResources;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: string | null) {}
}

export class LoadInventoryResourcesWithUsageSuccess implements NevisAdminAction<InventoryResourcesWithUsageState> {
  readonly type = InventoryActionTypes.LoadInventoryResourcesWithUsageSuccess;

  constructor(public payload: InventoryResourcesWithUsageState) {}
}

export class LoadInventoryResourcesSuccess implements NevisAdminAction<InventoryResourcesState> {
  readonly type = InventoryActionTypes.LoadInventoryResourcesSuccess;

  constructor(public payload: InventoryResourcesState,) {}
}

export class LoadInventoryTenantScopedResourcesWithUsage implements NevisAdminAction<string | null> {
  readonly type = InventoryActionTypes.LoadInventoryTenantScopedResourcesWithUsage;
}

export class LoadInventoryTenantScopedResources implements NevisAdminAction<string | null> {
  readonly type = InventoryActionTypes.LoadInventoryTenantScopedResources;
}

export class LoadInventoryTenantScopedSecretsWithUsageSuccess implements NevisAdminAction<InventoryResourcesWithUsageState> {
  readonly type = InventoryActionTypes.LoadInventoryTenantScopedResourcesWithUsageSuccess;

  constructor(public payload: InventoryResourcesWithUsageState) {}
}

export class LoadInventoryTenantScopedSecretsSuccess implements NevisAdminAction<InventoryResourcesState> {
  readonly type = InventoryActionTypes.LoadInventoryTenantScopedResourcesSuccess;

  constructor(public payload: InventoryResourcesState) {}
}

export class FetchInventoryData implements NevisAdminAction {
  readonly type = InventoryActionTypes.FetchInventoryData;
}

export class StoreInventoryFromLocalStorage implements NevisAdminAction<string | null> {
  readonly type = InventoryActionTypes.StoreInventoryFromLocalStorage;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: string | null) {}
}

export function loadSelectedInventoryFromLocalStorage(tenantKey: string): StoreInventoryFromLocalStorage | EmptyAction {
  const localStorageKey = LocalStorageHelper.prefixKey(localStorageInventoryKey, tenantKey);
  const inventoryKey = LocalStorageHelper.retrieve(localStorageKey);
  if (!_.isNil(inventoryKey)) {
    return new StoreInventoryFromLocalStorage(inventoryKey);
  } else {
    return new EmptyAction();
  }
}

export class ChangePublishRequiredFlag implements NevisAdminAction<InventoryPublishRequiredPayload> {
  readonly type = InventoryActionTypes.ChangePublishRequiredFlag;

  constructor(public payload: InventoryPublishRequiredPayload) {}
}

export class DeleteInventory implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.DeleteInventory;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: string) {}
}

export class DeleteInventorySuccess implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.DeleteInventorySuccess;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: string) {}
}

export class LoadInventoriesIfMissing implements NevisAdminAction {
  readonly type = InventoryActionTypes.LoadInventoriesIfMissing;
}

export class LoadInventories implements NevisAdminAction {
  readonly type = InventoryActionTypes.LoadInventories;
}

export class LoadInventoriesSuccess implements NevisAdminAction<Inventory[]> {
  readonly type = InventoryActionTypes.LoadInventoriesSuccess;

  constructor(public payload: Inventory[]) {}
}
export class InventoriesAvailable implements NevisAdminAction<Inventory[]> {
  readonly type = InventoryActionTypes.InventoriesAvailable;
}

export class LoadInventoryContent implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.LoadInventoryContent;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: string) {}
}

export class LoadInventoryContentSuccess implements NevisAdminAction<{inventoryKey: string, inventoryContent: string}> {
  readonly type = InventoryActionTypes.LoadInventoryContentSuccess;

  constructor(public payload: {inventoryKey: string, inventoryContent: string}) {}
}

export class LoadDiffInventoryContentSuccess implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.LoadDiffInventoryContentSuccess;

  /**
   * @param payload - inventory content
   */
  constructor(public payload: string) {}
}

export class ResetInventories implements NevisAdminAction {
  readonly type = InventoryActionTypes.ResetInventories;
}

export class UpdateInventoryContent implements NevisAdminAction<InventoryUpdateContentPayload> {
  readonly type = InventoryActionTypes.UpdateInventoryContent;

  constructor(public payload: InventoryUpdateContentPayload) {}
}

export class UpdateInventoryTimestamp implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.UpdateInventoryTimestamp;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: string) {}
}

export class UpdateInventoryTimestampSuccess implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.UpdateInventoryTimestampSuccess;

  /**
   * @param payload - timestamp
   */
  constructor(public payload: string) {}
}

export class ClearLocalChangesOfInventory implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.ClearLocalChangesOfInventory;

  /**
   *
   * @param payload inventoryKey
   */
  constructor(public payload: string) {}
}

export class CreateInventory implements NevisAdminAction<CreateInventoryPayload> {
  readonly type = InventoryActionTypes.CreateInventory;

  constructor(public payload: CreateInventoryPayload) {}
}

export class ImportInventoryFile implements NevisAdminAction<InventoryFileImportPayload> {
  readonly type = InventoryActionTypes.ImportInventoryFile;

  constructor(public payload: InventoryFileImportPayload) {}
}

export class LoadInventoriesAndNavigate implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.LoadInventoriesAndNavigate;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: string) {}
}

export class NavigateToCreatedInventory implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.NavigateToCreatedInventory;

  /**
   * @param payload - inventoryKey
   */
  constructor(public payload: string) {}
}

export class ValidateInventoryContent implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.ValidateInventoryContent;

  /**
   * @param payload - inventory content
   */
  constructor(public payload: string) {}
}

export class ValidateInventoryContentSuccess implements NevisAdminAction<ValidationStatus<InventoryValidationIssue> | null> {
  readonly type = InventoryActionTypes.ValidateInventoryContentSuccess;

  constructor(public payload: ValidationStatus<InventoryValidationIssue> | null) {}
}

export class ValidateInventoryContentBeforeSave implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.ValidateInventoryContentBeforeSave;

  /**
   * @param payload - inventory content
   */
  constructor(public payload: string) {}
}

export class ResetInventoriesAfterDeletingLast implements NevisAdminAction {
  readonly type = InventoryActionTypes.ResetInventoriesAfterDeletingLast;
}

export class CheckUpdatesOfInventory implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.CheckUpdatesOfInventory;

  constructor(public payload: string) {}
}

export class GetUpdatesOfInventory implements NevisAdminAction<string> {
  readonly type = InventoryActionTypes.GetUpdatesOfInventory;

  constructor(public payload: string) {}
}

export class ConnectInventoryToVersionControl implements NevisAdminAction<ConnectInventoryVersionControlPayload> {
  readonly type = InventoryActionTypes.ConnectInventoryToVersionControl;

  constructor(public payload: ConnectInventoryVersionControlPayload) {}
}

export class ConnectInventoryToVersionControlSuccess implements NevisAdminAction<Inventory> {
  readonly type = InventoryActionTypes.ConnectInventoryToVersionControlSuccess;

  constructor(public payload: Inventory) {}
}

export class ImportInventory implements NevisAdminAction<Inventory> {
  readonly type = InventoryActionTypes.ImportInventory;

  constructor(public payload: Inventory) {}
}

export class RedeployDeployment implements NevisAdminAction<DeploymentHistoryItem> {
  readonly type = InventoryActionTypes.RedeployDeployment;

  constructor(public payload: DeploymentHistoryItem) {}
}

export class LoadTenantConstants implements NevisAdminAction<{usedIn: boolean}> {
  readonly type = InventoryActionTypes.LoadTenantConstants;

  constructor(public payload: {usedIn: boolean}) {}
}

export class LoadTenantConstantsWithUsageSuccess implements NevisAdminAction<GlobalConstantWithUsage[]> {
  readonly type = InventoryActionTypes.LoadTenantConstantsWithUsageSuccess;

  /**
   * @param payload - the global constants
   */
  constructor(public payload: GlobalConstantWithUsage[]) {}
}

export class LoadTenantConstantsSuccess implements NevisAdminAction<GlobalConstant[]> {
  readonly type = InventoryActionTypes.LoadTenantConstantsSuccess;

  /**
   * @param payload - the global constants without the usages
   */
  constructor(public payload: GlobalConstant[]) {}
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SetVariableContainerComponent } from './set-variable-container.component';
import { SetVariableData } from './set-variable-data.model';

@Injectable()
export class SetVariableService {

  constructor(private dialog: MatDialog) {}

  openCreateVariableDialog(createVariableData: SetVariableData): MatDialogRef<SetVariableContainerComponent> {
    const config = {
      width: '40%',
      data: createVariableData,
      autoFocus: false,
      disableClose: true
    };
    return this.dialog.open(SetVariableContainerComponent, config);
  }
}

import * as _ from 'lodash';
import { TenantHelper } from '../../../common/helpers/tenant.helper';

export class InventoryResourceActionDialogHelper {

  static getResourceUsageDescription(usages: string[]): string {
    const inventoryKeyListWithoutTenantKey = _.map(usages, usage => TenantHelper.cropTenantFromKey(usage));
    const listOfUsage = _.join(['<li>', inventoryKeyListWithoutTenantKey.join('</li><li>'), '</li>'], '');
    return `<br/><br/>Please note that it is used in the following inventories which will be affected:<br/><div class='scrollable-container'><ul>${listOfUsage}</ul></div>`;
  }
}

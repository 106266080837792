import { NgModule } from '@angular/core';
import { ProjectInformationComponent } from './project-information/project-information.component';
import { ProjectDependenciesComponent } from './project-dependencies/project-dependencies.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { ProjectSettingsDetailsComponent } from './project-settings-details/project-settings-details.component';
import { ProjectSettingsMainComponent } from './project-settings-main/project-settings-main.component';
import { CommonModules } from '../common/common.module';
import { ProjectSettingsHeaderComponent } from './project-settings-header/project-settings-header.component';
import { SelectDependenciesContainerComponent } from './project-dependencies/select-dependencies-container.component';
import { ProjectDependenciesTableComponent } from './project-dependencies/project-dependencies-table.component';
import { ModalDialogModule } from '../modal-dialog/modal-dialog.module';
import { ProjectDependenciesDialogService } from './project-dependencies/project-dependencies-dialog.service';
import { ProjectPermissionsComponent } from './project-permissions/project-permissions.component';
import { PermissionsModule } from '../permissions/permissions.module';
import { SelectDependenciesDialogComponent } from './project-dependencies/select-dependencies-dialog.component';
import { ProjectCommonDependenciesComponent } from './project-dependencies/project-common-dependencies/project-common-dependencies.component';
import { UpgradeNotesComponent } from './project-dependencies/upgrade-notes.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ProjectHeaderModule } from '../projects/project-header/project-header.module';

@NgModule({
  imports: [
    MatTableModule,
    CommonModules,
    ModalDialogModule,
    PermissionsModule,
    MatSlideToggleModule,
    ScrollingModule,
    ProjectHeaderModule,
  ],
  declarations: [
    ProjectInformationComponent,
    ProjectSettingsMainComponent,
    ProjectSettingsDetailsComponent,
    ProjectDependenciesComponent,
    ProjectSettingsHeaderComponent,
    ProjectDependenciesTableComponent,
    ProjectPermissionsComponent,
    SelectDependenciesContainerComponent,
    SelectDependenciesDialogComponent,
    ProjectCommonDependenciesComponent,
    UpgradeNotesComponent
  ],
  exports: [
    ProjectSettingsMainComponent
  ],
  providers: [
    ProjectDependenciesDialogService
  ]
})
export class ProjectSettingsModule {
}

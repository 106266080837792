import { EmptyAction, NevisAdminAction } from '../actions';
import { IssueModel } from '../../common/model/issue.model';
import { Project } from '../../projects/project.model';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import { localStorageProjectKey } from '../../common/constants/local-storage-keys.constants';
import * as _ from 'lodash';
import { ProjectFileImportPayload } from '../../projects/file-based-import-project/project-file-import-payload.model';
import { ConnectProjectVersionControlPayload } from './connect-project-version-control-payload.model';
import { Maybe } from '../../common/utils/utils';

export enum ProjectActionTypes {
  /**
   * Fetches all global data related to project: patterns, pattern types, property types, variables, bundles
   */
  FetchProjectData = '[Project] Fetch Project Data',
  SelectProject = '[Project] Select Project',
  StoreProjectFromLocalStorage = '[Project] Store Project From Local Storage',
  LoadIssuesSuccess = '[Project] Load Issues Success',
  LoadIssues = '[Project] Load Issues',
  ImportProject = '[Project] Import project',
  ImportProjectFile = '[Project] Import project file',
  LoadProjectsIfMissing = '[Project] Load Projects If Missing', // loads project if missing, ie. if not yet loaded
  LoadProjects = '[Project] Load Projects',
  LoadProjectsWithInventoryDeployments = '[Project] Load Projects with inventory deployments',
  LoadProjectsSuccess = '[Project] Load Projects Success',
  ProjectsNowAvailable = '[Project] Now Available', // signals that the projects are available
  ProjectMetaTimestampChangedInBackground = '[Project] project meta change reported by timestamp',
  UpdateProjectIssueTimestamp = '[Project] update project issue timestamp',
  CheckUpdatesOfProject = '[Project] Check updates of project', // check if project has updates, then request updates
  RequestUpdatesOfProject = '[Project] Request updates of project',
  GetUpdatesOfProject = '[Project] Get updates of project',
  ClearLocalChangesOfProject = '[Project] Clear local changes of project',
  RevertProject = '[Project] Revert Project',
  CreateProject = '[Project] Create project',
  BranchProject = '[Project] Branch project',
  ConnectProjectToVersionControl = '[Project] Connect Project to Version Control',
  ConnectProjectToVersionControlSuccess = '[Project] Connect Project to Version Control Success',
  LoadProjectsAndNavigate = '[Project] Load projects and navigate',
  NavigateToCreatedProject = '[Project] Navigate to created project',
  DeleteProject = '[Project] Delete project',
  DeleteProjectSuccess = '[Project] Delete project success',
  ResetProjectsAfterDeletingLast = '[Project] Reset Projects after deleting last',
}

export class FetchProjectData implements NevisAdminAction {
  readonly type = ProjectActionTypes.FetchProjectData;
}

export class CheckUpdatesOfProject implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.CheckUpdatesOfProject;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class RevertProject implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.RevertProject;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class RequestUpdatesOfProject implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.RequestUpdatesOfProject;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class GetUpdatesOfProject implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.GetUpdatesOfProject;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class ClearLocalChangesOfProject implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.ClearLocalChangesOfProject;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class ImportProject implements NevisAdminAction<Project> {
  readonly type = ProjectActionTypes.ImportProject;

  constructor(public payload: Project) {}
}

export class ImportProjectFile implements NevisAdminAction<ProjectFileImportPayload> {
  readonly type = ProjectActionTypes.ImportProjectFile;

  constructor(public payload: ProjectFileImportPayload) {}
}

export class SelectProject implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.SelectProject;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

/**
 * when getting project from local storage we don't want to instantly trigger effects of select project to unwanted requests therefore this action has ONLY reducer part
 */
export class StoreProjectFromLocalStorage implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.StoreProjectFromLocalStorage;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export function loadSelectedProjectFromLocalStorage(tenantKey: string): StoreProjectFromLocalStorage | EmptyAction {
  const localStorageKey = LocalStorageHelper.prefixKey(localStorageProjectKey, tenantKey);
  const projectKey = LocalStorageHelper.retrieve(localStorageKey);
  if (!_.isNil(projectKey)) {
    return new StoreProjectFromLocalStorage(projectKey);
  } else {
    return new EmptyAction();
  }
}

export class LoadIssues implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.LoadIssues;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class LoadIssuesSuccess implements NevisAdminAction<IssueModel[]> {
  readonly type = ProjectActionTypes.LoadIssuesSuccess;

  constructor(public payload: IssueModel[]) {}
}

export class LoadProjectsIfMissing implements NevisAdminAction<Maybe<string>> {
  readonly type = ProjectActionTypes.LoadProjectsIfMissing;
}

export class LoadProjects implements NevisAdminAction {
  readonly type = ProjectActionTypes.LoadProjects;
}

export class LoadProjectsWithInventoryDeployments implements NevisAdminAction {
  readonly type = ProjectActionTypes.LoadProjectsWithInventoryDeployments;
}

export class LoadProjectsSuccess implements NevisAdminAction<Project[]> {
  readonly type = ProjectActionTypes.LoadProjectsSuccess;

  constructor(public payload: Project[]) {}
}

export class ProjectsNowAvailable implements NevisAdminAction<Project[]> {
  readonly type = ProjectActionTypes.ProjectsNowAvailable;
}

/**
 * Used to trigger the update of the project meta from the sync service.
 */
export class ProjectMetaTimestampChangedInBackground implements NevisAdminAction<{local: string, remote: string}> {
  readonly type = ProjectActionTypes.ProjectMetaTimestampChangedInBackground;

  constructor(public payload: {local: string, remote: string}) {}
}

export class UpdateProjectIssueTimestamp implements NevisAdminAction<{local: string, remote: string}> {
  readonly type = ProjectActionTypes.UpdateProjectIssueTimestamp;

  constructor(public payload: {local: string, remote: string}) {}
}

export class CreateProject implements NevisAdminAction<{project: Project, projectTemplateKey: string | undefined}> {
  readonly type = ProjectActionTypes.CreateProject;

  constructor(public payload: {project: Project, projectTemplateKey: string | undefined}) {}
}

export class BranchProject implements NevisAdminAction<Project> {
  readonly type = ProjectActionTypes.BranchProject;

  constructor(public payload: Project) {}
}

export class ConnectProjectToVersionControl implements NevisAdminAction<ConnectProjectVersionControlPayload> {
  readonly type = ProjectActionTypes.ConnectProjectToVersionControl;

  constructor(public payload: ConnectProjectVersionControlPayload) {}
}

export class ConnectProjectToVersionControlSuccess implements NevisAdminAction<Project> {
  readonly type = ProjectActionTypes.ConnectProjectToVersionControlSuccess;

  constructor(public payload: Project) {}
}

export class LoadProjectsAndNavigate implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.LoadProjectsAndNavigate;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class NavigateToCreatedProject implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.NavigateToCreatedProject;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class DeleteProject implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.DeleteProject;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class DeleteProjectSuccess implements NevisAdminAction<string> {
  readonly type = ProjectActionTypes.DeleteProjectSuccess;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class ResetProjectsAfterDeletingLast implements NevisAdminAction {
  readonly type = ProjectActionTypes.ResetProjectsAfterDeletingLast;
}

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChangePasswordComponent } from './change-password.component';
import { Injectable } from '@angular/core';

@Injectable()
export class ChangePasswordService {

  constructor(private dialog: MatDialog) {}

  openChangePasswordDialog(): MatDialogRef<ChangePasswordComponent> {

    return this.dialog.open(ChangePasswordComponent,
      {
        width: '30%',
        maxWidth: '50%',
        disableClose: true,
        autoFocus: false
      });
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DiffViewComponent } from '../../../common/model/publish-changes/diff-view-component.model';
import { ProjectChangesetItem } from '../project-changeset-item.model';
import { MetaInfo } from '../../../version-control/meta-info.model';
import { BundlesDiffViewContext } from './bundles-diff-view.context';
import { TextDiffWrapperContext } from '../text-diff-wrapper/text-diff-wrapper.model';

@Component({
  selector: 'adm4-bundles-diff-view',
  template: `<adm4-text-diff-wrapper></adm4-text-diff-wrapper>`,
  providers: [
      {provide: TextDiffWrapperContext, useClass: BundlesDiffViewContext}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BundlesDiffViewComponent implements DiffViewComponent<ProjectChangesetItem<MetaInfo, BundlesDiffViewComponent>> {

  /**
   * This field represents selected changeset in left column of publish project, it's a way to connect the 2 sides when interation between them is needed
   */
  changesetItem: ProjectChangesetItem<MetaInfo, BundlesDiffViewComponent>;

}

import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';

import { ProjectSummaryReportModel } from './project-summary-report.model';
import { AppState } from '../../../model/reducer';
import {
  isSelectedProjectVersionedView,
  issuesView,
  patternListItemsView,
  patternSummaryApplicationsReportView,
  patternSummaryInstancesReportView,
  patternSummaryRealmsReportView,
  patternSummaryReportsError,
  patternSummaryReportsNotFound,
  patternTypesView,
  projectKeyView,
  projectMetaView,
  variablesListView
} from '../../../model/views';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PluginHelper } from '../../../plugins/plugin.helper';
import { PluginModel } from '../../../plugins/plugin.model';
import { INSTANCE_CLASS_NAMES, REALM_CLASS_NAMES, SERVICE_CLASS_NAMES } from '../../../plugins/plugin.enums';
import { PatternListData } from '../../../patterns/pattern-list-data.model';
import { ProjectDescription, ProjectMeta } from '../../project.model';
import { VariableModel } from '../../../variables/variable.model';
import { IssueModel } from '../../../common/model/issue.model';
import { MarkdownRendererService } from '../../../common/services/markdown-renderer.service';
import { CustomSanitizerService } from '../../../common/services/custom-sanitizer.service';
import { ProjectService } from '../../project.service';

@Injectable()
export class ProjectSummaryReportContext implements OnDestroy {

  private readonly teardownSubscription = new Subscription();

  projectKey$: Observable<string | null>;

  private readonly projectDescriptionNew$: ReplaySubject<string | undefined> = new ReplaySubject();
  private readonly projectDescriptionLoad$: Subject<void> = new Subject();
  private readonly projectDescriptionPlainHolder$: ReplaySubject<string | undefined>;
  readonly projectDescriptionHtml$: Observable<SafeHtml | undefined>;
  readonly projectDescriptionPlain$: Observable<string | undefined>;

  patternList$: Observable<PatternListData[]>;
  versioned$: Observable<boolean>;
  projectStatusModel$: Observable<ProjectMeta | null>;
  variables$: Observable<VariableModel[]>;
  issues$: Observable<IssueModel[]>;

  applicationsReport$: Observable<ProjectSummaryReportModel | undefined>;
  realmsReport$: Observable<ProjectSummaryReportModel | undefined>;
  instancesReport$: Observable<ProjectSummaryReportModel | undefined>;

  reportsNotFound$: Observable<boolean>;
  reportsError$: Observable<boolean>;

  servicePlugins$: Observable<PluginModel[]>;
  realmPlugins$: Observable<PluginModel[]>;
  instancesPlugins$: Observable<PluginModel[]>;

  private _searchTerm$: BehaviorSubject<string | undefined> = new BehaviorSubject('');
  searchTerm$: Observable<string | undefined> = this._searchTerm$.asObservable();

  constructor(
      private store$: Store<AppState>,
      private projectService: ProjectService,
      private sanitizer: DomSanitizer,
      private markdownRenderer: MarkdownRendererService,
      private customSanitizer: CustomSanitizerService,
    ) {
    this.projectKey$ = this.store$.pipe(select(projectKeyView));
    const saveProjectDescription = this.projectDescriptionNew$.pipe(
        withLatestFrom(this.projectKey$),
        switchMap(([newDescription, projectKey]: [string | undefined, string]) => {
          return this.projectService.updateProjectDescription(projectKey, {description: newDescription});
        }),
        tap(() => this.projectDescriptionLoad$.next()),
    );
    this.teardownSubscription.add(saveProjectDescription.subscribe());

    const loadProjectDescription = this.projectDescriptionLoad$.asObservable().pipe(
        withLatestFrom(this.projectKey$),
        switchMap(([_loadEvent, projectKey]: [void, string]): Observable<ProjectDescription> => {
          return this.projectService.getProjectDescription(projectKey);
        }),
        tap((pd: ProjectDescription) => this.projectDescriptionPlainHolder$.next(pd.description)),
    );
    this.teardownSubscription.add(loadProjectDescription.subscribe());

    this.projectDescriptionPlainHolder$ = new ReplaySubject<string | undefined>();
    this.projectDescriptionPlainHolder$.next(undefined);
    this.projectDescriptionPlain$ = this.projectDescriptionPlainHolder$.asObservable();
    this.projectDescriptionHtml$ = this.projectDescriptionPlain$.pipe(
        map((markdownString: string) => this.markdownRenderer.renderAndSanitize(markdownString)),
    );

    this.patternList$ = this.store$.pipe(select(patternListItemsView));
    this.versioned$ = store$.pipe(select(isSelectedProjectVersionedView));
    this.projectStatusModel$ = this.store$.pipe(select(projectMetaView));
    this.variables$ = this.store$.pipe(select(variablesListView));
    this.issues$ = this.store$.pipe(select(issuesView));

    this.applicationsReport$ = this.store$.pipe(select(patternSummaryApplicationsReportView));
    this.realmsReport$ = this.store$.pipe(select(patternSummaryRealmsReportView));
    this.instancesReport$ = this.store$.pipe(select(patternSummaryInstancesReportView));

    this.reportsNotFound$ = this.store$.pipe(select(patternSummaryReportsNotFound));
    this.reportsError$ = this.store$.pipe(select(patternSummaryReportsError));

    const allPlugins$: Observable<PluginModel[]> = this.store$.pipe(select(patternTypesView)).pipe(
      map(patternTypes => _.map(patternTypes, patternType => PluginHelper.convertPatternTypeToPlugin(patternType)))
    );

    this.servicePlugins$ = allPlugins$.pipe(
      map((plugins: PluginModel[]) => PluginHelper.filterPluginsByClassNames(plugins, SERVICE_CLASS_NAMES))
    );
    this.realmPlugins$ = allPlugins$.pipe(
      map((plugins: PluginModel[]) => PluginHelper.filterPluginsByClassNames(plugins, REALM_CLASS_NAMES))
    );
    this.instancesPlugins$ = allPlugins$.pipe(
      map((plugins: PluginModel[]) => PluginHelper.filterPluginsByClassNames(plugins, INSTANCE_CLASS_NAMES))
      );

    this.projectDescriptionLoad$.next();
  }

  ngOnDestroy(): void {
    this.teardownSubscription.unsubscribe();
  }

  searchReportTables(searchTerm?: string) {
    this._searchTerm$.next(searchTerm);
  }

  saveDescription(description?: string) {
    this.projectDescriptionNew$.next(description);
  }
}


import { Observable } from 'rxjs';
import { DeploymentWizardMainComponent } from './deployment-wizard-main.component';
import { DeploymentStep } from './deployment-dialog/deployment-step.enum';
import { DeploymentDialogComponent } from './deployment-dialog/deployment-dialog.component';

export class DeploymentWizardDeactivationGuard  {
  canDeactivate(component: DeploymentWizardMainComponent): Observable<boolean> | Promise<boolean> | boolean {
    const deploymentWizardDialog: DeploymentDialogComponent = component.dialogRef.componentInstance;
    if (!deploymentWizardDialog) {
      return true;
    }

    const atSelectionOrDoneStep = deploymentWizardDialog.currentStep <= DeploymentStep.Selection;
    const nothingToDeploy = deploymentWizardDialog.nothingToDeploy;
    const atPreviewOrDeploymentStep = deploymentWizardDialog.currentStep >= DeploymentStep.Preview;
    const shouldBypassClosingGuard = deploymentWizardDialog.shouldBypassClosingGuard;

    const canDeactivate = atSelectionOrDoneStep ||
      (nothingToDeploy && atPreviewOrDeploymentStep) ||
      shouldBypassClosingGuard;

    if (!canDeactivate) {
      if (!confirm('Do you want to close the screen? This will not interrupt the deployment if it is already in progress.')) {
        return false;
      } else {
        deploymentWizardDialog.shouldBypassClosingGuard = false;
        deploymentWizardDialog.nothingToDeploy = false;
        return true;
      }
    }
    return true;
  }

}

import { PermissionAssignee, PermissionSavePayload } from '../model/permissions/permissions.model';
import { Observable } from 'rxjs';
import { BaseService } from '../shared/base.service';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class PermissionsService extends BaseService {

  getPermissionAssigneesForTarget(target: string, operationKeys: string[], withUserName: boolean, allAssignees: boolean): Observable<PermissionAssignee[]> {
    let params: HttpParams = new HttpParams()
      .set('withUserName', withUserName.toString())
      .set('allAssignees', allAssignees.toString());
    if (target) {
      params = params.set('target', target);
    }
    if (operationKeys) {
      operationKeys.forEach((operationKey) => {
        params = params.append('operationKey', operationKey);
      });

    }
    const url = `/permissions`;
    return this.httpGetUnwrapped(url, undefined, params);
  }

  updatePermissions(permissionsSavePayload: PermissionSavePayload[]): Observable<PermissionSavePayload[]> {
    const url = '/permissions';
    return this.httpPut(url, permissionsSavePayload);
  }

}

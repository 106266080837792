import { PatternListData } from '../pattern-list-data.model';

export interface GroupedPatternListData {
  labelName: string;
  patterns: PatternListData[];
  isExpanded: boolean;
}

// Flattened GroupedPatternListData (template representation): - level 0: elements are labels,  - level 1: elements are patterns
export interface FlattenedGroupedPatternListData {
  level: number;
  isExpanded: boolean;
}

export interface FlattenedPatternListData extends FlattenedGroupedPatternListData {
  patternListData: PatternListData;
}

export interface FlattenedLabel extends FlattenedGroupedPatternListData {
  labelName: string;
}

export function isFlattenedPatternListDataType(object: any): object is FlattenedPatternListData {
  return 'patternListData' in object;
}

export function isFlattenedLabelType(object: any): object is FlattenedLabel {
  return 'labelName' in object;
}

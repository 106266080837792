import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { VariableListItem } from './variable.model';
import { PropertyType } from '../plugins/property-type.model';
import { Dictionary } from '../model/reducer';
import * as _ from 'lodash';


@Component({
  selector: 'adm4-variables',
  template: `
    <div class='variable-list-container full-height-flex'>
      <div class='filters-wrapper'>
        <div class='var-filter'>
          <adm4-filter [placeholderText]='placeholderText'
                       (filter)="onFilterVariables($event)"
                       class='ui-filter'></adm4-filter>
        </div>
      </div>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <div class='var-list ui_list' #scrollArea>
          <adm4-variable-list *ngIf='!shouldShowEmptyResultMessage'
                              #variableListComp
                              [variableListItems]='variableListItems'
                              [variableTypes]='variableTypes'
                              [filteredVariables]='filteredVariables'
                              [textToHighLight]="filterText"
                              [projectKey]="projectKey"
                              [scrollArea]='scrollArea'
                              [selectedVariableKey]='selectedVariableKey' ></adm4-variable-list>
            <div *ngIf="shouldShowEmptyResultMessage" class="no-variables">
              No variables found.
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['variables.component.scss']
})
export class VariablesComponent implements OnInit, OnChanges {
  @Input() variableListItems: VariableListItem[];
  @Input() variableTypes: Dictionary<PropertyType>;
  @Input() projectKey: string;
  @Input() selectedVariableKey: string;
  @ViewChild('scrollArea', {static: false}) public scrollArea;


  filteredVariables: VariableListItem[];
  placeholderText = 'Find variables';
  filterText = '';

  ngOnInit(): void {
    this.filteredVariables = this.variableListItems;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['variableListItems'] && changes['variableListItems'].currentValue) {
      this.filteredVariables = this.variableListItems;
    }
  }

  onFilterVariables(text: string): void {
    try {
      this.filterText = text;
      const regExp = new RegExp(_.escapeRegExp(text), 'gi');
      this.filteredVariables = this.variableListItems.filter((variableListItem: VariableListItem) => variableListItem.variable.variableKey.match(regExp) || (variableListItem.variable.value && variableListItem.variable.value.toString().match(regExp)));
    } catch (error) {
      console.error(`Something went wrong at variable filtering: ${error}`);
    }
  }

  get shouldShowEmptyResultMessage(): boolean {
    return !_.isEmpty(this.variableListItems) && _.isEmpty(this.filteredVariables) && !_.isNil(this.variableTypes);
  }
}

import { CheckModel, CheckStatus, CommandModel, DirectoryModel, FileModel, GenerationOutputModel, GenerationTriggerModel, KubernetesCustomResource, OutputType, PlanningOutputModel } from '../deploy/deployment-preview/planning-output.model';
import { TreeNode } from './tree-node.model';
import { TreeNodeTypeEnum } from './tree-node-type.enum';
import { Pattern } from '../../patterns/pattern.model';
import { PathHelper } from '../../common/helpers/path.helper';
import * as _ from 'lodash';

const setParentRefsInChildren = (parent: TreeNode,) => {
  parent.children?.forEach((childNode: TreeNode) => childNode.parent = parent);
};

export class TreeGeneratingHelper {
  static getKubernetesTreeFromPlanOutput(items: PlanningOutputModel[]): TreeNode[] {
    return _.flatten(items.map((item: PlanningOutputModel) => {
      const kubernetesCustomResourcesNodes = item.kubernetesCustomResources.map(
        (resource: KubernetesCustomResource) => this.createKubernetesCustomResourceNode(resource, item));

      const artifactNodes: TreeNode[] = this.createDirectoriesSubtree(item);
      TreeGeneratingHelper.addArtifactNodesForNevisComponents(item, kubernetesCustomResourcesNodes, artifactNodes);
      return kubernetesCustomResourcesNodes;
    }));
  }

  static getClassicTreeFromPlanOutput(items: PlanningOutputModel[]): TreeNode[] {
    return items.map((item: PlanningOutputModel) => this.createHostNode(item, OutputType.PLAN));
  }

  static getKubernetesTreeFromGenerationOutput(items: GenerationOutputModel[], patterns: Map<string, Pattern>): TreeNode[]  {
    return items.map((item: GenerationOutputModel): TreeNode => {
      const patternServiceNode = this.createPatternServiceNode(item, patterns);
      if (!_.isEmpty(item.kubernetesCustomResources)) {
        const kubernetesCustomResources = this.createKubernetesCustomResourcesNode(item);
        patternServiceNode.children.push(kubernetesCustomResources);
      }

      const artifactNodes: TreeNode[] = this.createDirectoriesSubtree(item);
      patternServiceNode.children.push(...artifactNodes);
      setParentRefsInChildren(patternServiceNode);
      return patternServiceNode;
    });
  }

  static getClassicTreeFromGenOutput(items: GenerationOutputModel[], patterns: Map<string, Pattern>): TreeNode[] {
    return items.reduce((patternNodes: TreeNode[], item: GenerationOutputModel) => {
      // if node for this pattern is already created we need to update it by adding another host node to it otherwise create new one
      const existingNode = patternNodes.find((node: TreeNode) => node.id === item.patternId);
      const hostNode = this.createHostNode(item, OutputType.GENERATION);
      if (!existingNode) {
        const patternNode: TreeNode = Object.assign({}, this.createPatternNode(item, patterns), {
          children: [hostNode]
        });
        setParentRefsInChildren(patternNode);
        return [...patternNodes, patternNode];
      } else {
        const extendedExistingNode: TreeNode = {
          ...existingNode,
          children: [...<TreeNode[]>existingNode.children, hostNode]
        };
        setParentRefsInChildren(extendedExistingNode);
        return patternNodes.map((node: TreeNode) => {
          return node.id !== existingNode.id ? node : extendedExistingNode;
        });
      }
    }, []);
  }

  static createHostNode(item: PlanningOutputModel | GenerationOutputModel, outputType: OutputType): TreeNode {
    const nodeId = (<GenerationOutputModel>item).patternId ? (<GenerationOutputModel>item).patternId + '-' + item.host : item.host;
    const uniqueNodeId = _.uniqueId(nodeId);
    const hostNode = this.createNode(TreeNodeTypeEnum.Host, uniqueNodeId, item.host, []);
    if (item.error) {
      hostNode.nrErrors = 1;
      const errorNode = this.createErrorNode(item.host, item.error);
      hostNode.children = [errorNode];
    }
    if (this.shouldCreateCheckNodes(item.checks, outputType)) {
      const checksNode = this.createChecksParentNode(item, outputType);
      hostNode.children.push(checksNode);
    }
    const commandsNode = this.createCommandNode(item);
    const artifactNodes: TreeNode[] = this.createDirectoriesSubtree(item);
    hostNode.children.push(commandsNode, ...artifactNodes);
    setParentRefsInChildren(hostNode);
    return hostNode;
  }

  private static createErrorNode(host: string, error: string): TreeNode {
    const errorNode = this.createNode(TreeNodeTypeEnum.HostError, _.uniqueId('_ERROR' + host), error, []);
    errorNode.nrErrors = 1;
    return errorNode;
  }

  static createPatternServiceNode(outputModel: GenerationOutputModel, patterns: Map<string, Pattern>): TreeNode {
    const pattern = patterns.get(outputModel.patternId);
    const patternName = pattern ? pattern.name : outputModel.patternId;
    const serviceName = outputModel.host;
    const nodeName = this.getPatternServiceNodeName(patternName, serviceName);
    const nodeId = outputModel.patternId + serviceName;

    return this.createNode(TreeNodeTypeEnum.DeployablePattern, nodeId, nodeName, []);
  }

  static getPatternServiceNodeName(patternName: string, serviceName: string): string {
    if (patternName === serviceName) {
      return patternName;
    } else {
      return `${patternName} (${serviceName})`;
    }
  }

  static createKubernetesCustomResourcesNode(item: PlanningOutputModel | GenerationOutputModel): TreeNode {
    const customResourceNodes: TreeNode[] =
      item.kubernetesCustomResources.map((resource: KubernetesCustomResource) =>
        this.createKubernetesCustomResourceNode(resource, item)
      );

    const patternId = item.kubernetesCustomResources[0].patternId;

    const nodeId = `${item.host}-${patternId}-ServiceConfigurations`;

    const k8sCustomResource: TreeNode = Object.assign({},
      this.createNode(TreeNodeTypeEnum.KubernetesCustomResources, nodeId, 'Service configurations', []),
      {
        children: customResourceNodes
      });
    setParentRefsInChildren(k8sCustomResource);
    return k8sCustomResource;
  }

  private static createKubernetesCustomResourceNode(customResource: KubernetesCustomResource, item: PlanningOutputModel | GenerationOutputModel): TreeNode {
    return this.createNode(TreeNodeTypeEnum.KubernetesCustomResource, 'root' + customResource.fileId, customResource.label, customResource.tasks, customResource, item);
  }

  static createPatternNode(item: GenerationOutputModel, patterns: Map<string, Pattern>): TreeNode {
    const pattern = patterns.get(item.patternId);
    const patternName = pattern ? pattern.name : item.patternId;
    return this.createNode(TreeNodeTypeEnum.DeployablePattern, item.patternId, patternName, []);
  }

  static createCommandNode(item: PlanningOutputModel | GenerationOutputModel): TreeNode {
    const commandNodes = item.commands.map((command: CommandModel) => {
      return this.createNode(TreeNodeTypeEnum.Command, command.commandId, command.label, command.tasks, command, item);
    });
    const commandsHaveTask = item.commands.some(command => !_.isEmpty(command.tasks));
    const commandParent: TreeNode = Object.assign({},
      this.createNode(TreeNodeTypeEnum.CommandParent, _.uniqueId('Commands'), 'Commands', []),
      {
        children: commandNodes,
        hasDescendantTask: commandsHaveTask
      });
    setParentRefsInChildren(commandParent);
    return commandParent;
  }

  static createChecksParentNode(item: GenerationOutputModel | PlanningOutputModel, outputType: OutputType): TreeNode {
    const failingChecks: CheckModel[] = item.checks.filter((check: CheckModel) => check.status === CheckStatus.FAILED);
    // system checks are the checks that are running on commands and failed checks can prevent deployment
    const systemChecksNodes: TreeNode[] = item.checks.map((check: CheckModel) => this.createCheckNode(check, item));
    // triggers are custom checks that in case of fail will execute another command, so cannot trouble the deployment, therefore they are not displayed in plan output
    let triggerNodes: TreeNode[] = [];
    if (outputType === OutputType.GENERATION) {
      triggerNodes = (<GenerationOutputModel>item).triggers.map((trigger: GenerationTriggerModel) => this.createCheckNodeFromTrigger(trigger, <GenerationOutputModel>item));
    }
    const checksParent: TreeNode =  Object.assign({},
      this.createNode(TreeNodeTypeEnum.CheckParent, _.uniqueId('Checks'), 'Pre-deployment checks', []),
      {
        children: systemChecksNodes.concat(triggerNodes),
        nrErrors: failingChecks.length
      });
    setParentRefsInChildren(checksParent);
    return checksParent;
  }

  static createCheckNode(check: CheckModel, item: GenerationOutputModel | PlanningOutputModel): TreeNode {
    return Object.assign({},
      this.createNode(TreeNodeTypeEnum.Check, _.uniqueId(), check.label, [], check, item),
      {
        nrErrors: check.status === CheckStatus.FAILED ? 1 : 0
      }
    );
  }

  static createCheckNodeFromTrigger(trigger: GenerationTriggerModel, item: GenerationOutputModel): TreeNode {
    return this.createNode(TreeNodeTypeEnum.Check, trigger.triggerId, trigger.label, [], trigger, item);
  }

  static anyChildrenHasTasks(outputItem: PlanningOutputModel | GenerationOutputModel): boolean {
    return outputItem.directories.some((directory: DirectoryModel) => this.directoryChildrenHaveTask(outputItem, directory));
  }

  private static directoryChildrenHaveTask(outputItem: PlanningOutputModel | GenerationOutputModel, directory: DirectoryModel): boolean {
    const childrenDirectoryNodes: TreeNode[] = this.createDirectoriesSubtree(outputItem, directory.directoryId);
    const fileNodesInCurrentDirectory: TreeNode[] = this.createFileNodesForDirectory(outputItem, directory.directoryId);
    return childrenDirectoryNodes.concat(fileNodesInCurrentDirectory).some((node: TreeNode) => node.hasDescendantTask || !_.isEmpty(node.tasks));
  }

  static createDirectoriesSubtree(outputItem: PlanningOutputModel | GenerationOutputModel, currentRootDirectoryId?: string): TreeNode[] {
    const rootDirectories = outputItem.directories.filter((directory: DirectoryModel) => directory.parentId === currentRootDirectoryId);
    return rootDirectories.reduce((directoriesNodes: TreeNode[], directory: DirectoryModel) => {
      const childrenDirectoryNodes: TreeNode[] = this.createDirectoriesSubtree(outputItem, directory.directoryId);
      const fileNodesInCurrentDirectory: TreeNode[] = this.createFileNodesForDirectory(outputItem, directory.directoryId);
      const childrenHaveTasks: boolean = this.directoryChildrenHaveTask(outputItem, directory);
      const nodeName = this.getDirectoryNodeName(directory.path, currentRootDirectoryId);

      const newDirectory: TreeNode = Object.assign(<TreeNode>{},
          this.createNode(TreeNodeTypeEnum.Directory, directory.directoryId, nodeName, directory.tasks, directory, outputItem),
          {
            children: childrenDirectoryNodes.concat(fileNodesInCurrentDirectory),
            hasDescendantTask: childrenHaveTasks
          });
      setParentRefsInChildren(newDirectory);
      return directoriesNodes.concat(newDirectory);
    }, []);
  }

  static getDirectoryNodeName(directoryPath: string, currentRootDirectoryId?: string): string {
    const directoryNodeName: string = _.isNil(currentRootDirectoryId) ? directoryPath : PathHelper.getArtifactNameFromPath(directoryPath);
    return `${directoryNodeName}/`;
  }

  static createFileNodesForDirectory(outputItem: PlanningOutputModel | GenerationOutputModel, currentDirectoryId: string | undefined): TreeNode[] {
    return outputItem.files
      .filter((file: FileModel) => !_.isNil(currentDirectoryId) && file.parentId === currentDirectoryId)
      .map((file: FileModel) => this.createNode(TreeNodeTypeEnum.File, file.fileId, PathHelper.getArtifactNameFromPath(file.path), file.tasks, file, outputItem));
  }

  static addArtifactNodesForNevisComponents(item: PlanningOutputModel, kubernetesCustomResourcesNodes: TreeNode[], artifactNodes: TreeNode[]) {
    const childrenHasTasks = this.anyChildrenHasTasks(item);
    kubernetesCustomResourcesNodes.forEach((k8sCrdNode: TreeNode) => {
      k8sCrdNode.hasDescendantTask = childrenHasTasks;
      if (TreeGeneratingHelper.isNevisComponent(k8sCrdNode.name)) {
        k8sCrdNode.children.push(...artifactNodes);
      }
      setParentRefsInChildren(k8sCrdNode);
    });
  }

  static createNode(
    type: TreeNodeTypeEnum,
    id: string,
    name: string,
    tasks: string[],
    details?: any,
    outputItem?: PlanningOutputModel | GenerationOutputModel
  ): TreeNode {
    return {
      id: id,
      name: name,
      type: type,
      nrErrors: 0,
      nrWarnings: 0,
      tasks: tasks || [],
      hasDescendantTask: false,
      details: details,
      children: [],
      output: outputItem
    };
  }

  static shouldCreateCheckNodes(checks: CheckModel[], outputType: OutputType): boolean {
    switch (outputType) {
      case OutputType.GENERATION:
        return checks.length > 0;
      case OutputType.PLAN:
        return checks.length > 0 && checks.some(check => check.status === CheckStatus.FAILED);
      default:
        return false;
    }
  }

  private static isNevisComponent(name: string) {
    return _.endsWith(name, '(NevisComponent)');
  }
}

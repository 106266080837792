import { CopyMode, CopyModel } from './batch-action.model';
import * as _ from 'lodash';
import { PatternId } from '../../model/pattern';

export class CopyActionHelper {

  static createCopyModelByMode(copyMode: CopyMode, patternIdsToCopy: PatternId[], projectKey: string, includeVariables: boolean, prefixText: string, suffixText: string): CopyModel {
    return {
      copyMode: copyMode,
      patternIds: patternIdsToCopy,
      targetProjectKey: projectKey,
      includeVariables: includeVariables,
      ...this.isCopyAsNewMode(copyMode) && !_.isEmpty(_.trim(prefixText)) && { prefix: _.trim(prefixText)},
      ...this.isCopyAsNewMode(copyMode) && !_.isEmpty(_.trim(suffixText)) && { postfix: _.trim(suffixText)}
    } as CopyModel;
  }

  static isCopyAsNewMode(copyMode: CopyMode): boolean {
    return _.isEqual(copyMode, CopyMode.NEW) || _.isEqual(copyMode, CopyMode.DRY_RUN_NEW);
  }
}

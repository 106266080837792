import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PublishProjectDialogComponent } from './publish-project-dialog.component';

@Injectable({providedIn: 'root'})
export class PublishProjectDialogService {

  constructor(private dialog: MatDialog) {}

  openPublishChangesWindow(projectKey: string): MatDialogRef<PublishProjectDialogComponent> {
    return this.dialog.open<PublishProjectDialogComponent, string>(PublishProjectDialogComponent, {
      panelClass: 'big-dialog',
      disableClose: true,
      closeOnNavigation: false,
      autoFocus: false,
      data: projectKey
    });
  }
}

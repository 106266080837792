import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ValueAccessorBase } from '../../../property-widgets/value-accessor.base';
import { UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { blurOnKeyDown } from '../../utils/keypress.utils';

/**
 * This component is for displaying some text that can be edited when the user clicks on it.
 */
@Component({
  selector: 'adm4-editable-text',
  template: `
    <ng-container [formGroup]='form'>
      <input type='text' [formControlName]="fcName"
                class='form-control editable-title'
                [ngClass]="styleClass"
                (keydown.enter)='onEnterPressed($event)'
                (blur)='onBlurTitle()'
                #editableText
                [readonly]='readOnly'
                name='editable-text'
                id='editable-pattern-title'>
    </ng-container>
  `,
  styleUrls: ['editable-text.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditableTextComponent),
    multi: true
  }]
})
export class EditableTextComponent extends ValueAccessorBase implements AfterViewInit {
  @Input() readOnly: boolean;
  @Input() fcName: string;
  @Input() styleClass: string;
  @Input() form: UntypedFormGroup;

  @Output() sizeChanged = new EventEmitter<number>();
  @Output() focusLost = new EventEmitter<void>();

  @ViewChild('editableText', {static: false}) textInput;

  ngAfterViewInit() {
    setTimeout(() => {
      this.textInput.nativeElement.focus();
    });
  }

  onBlurTitle() {
    this.focusLost.emit();
  }

  onEnterPressed(event) {
    blurOnKeyDown(event);
  }
}

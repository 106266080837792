import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export const CONTENT_TYPE = 'Content-Type';
export const YAML_CONTENT = 'application/x-yaml';
export const JSON_HEADER = {name: CONTENT_TYPE, value: 'application/json'};
export const REST_ARRAY_WRAPPER = 'items';

/**
 * This is a helper service that provides GET, POST, PUT and DELETE methods for the REST API call. All these methods
 * use the Http object provided by Angular.
 */
@Injectable()
export class BaseService {
  /*
   * The API's base url, without the trailing forward slash, aka `/`
   */
  baseUrl = environment.baseUrl;

  public jsonHeader = new HttpHeaders().append(JSON_HEADER.name, JSON_HEADER.value);

  constructor(public http: HttpClient) {}

  public httpGetUnwrapped<LIST extends Array<any>>(url: string, headers: HttpHeaders = this.jsonHeader, params?: HttpParams): Observable<LIST> {
    return this.http.get(this.baseUrl + url, {headers: headers, params: params, observe: 'response'}).pipe(
      map(response => this.unwrapResponse(response))
    );
  }

  public httpGet(url: string, headers: HttpHeaders = this.jsonHeader, params?: HttpParams): Observable<any> {
    return this.http.get(this.baseUrl + url, {headers: headers, params: params, observe: 'response'}).pipe(
      map(response => response.body)
    );
  }

  public httpGetBlob(url: string, headers: HttpHeaders = this.jsonHeader, params?: HttpParams): Observable<Blob> {
    return this.http.get(this.baseUrl + url, {headers: headers, params: params, observe: 'response', responseType: 'blob'}).pipe(
      map(response => this.unwrapResponse(response))
    );
  }

  public httpGetBlobDefault(url: string): Observable<Blob> {
    return this.http.get(this.baseUrl + url, {observe: 'response', responseType: 'blob'}).pipe(
      map(response => this.unwrapResponse(response))
    );
  }

  /**
   * This method is to GET text data from the backend.
   * All other methods will assume that the reponse body is in JSON and try to parse it.
   */
  public httpGetText(url: string, params?: HttpParams): Observable<string | null> {
    return this.http.get<string>(this.baseUrl + url, {responseType: 'text' as any, params: params});
  }

  /**
   * Doing http post request
   * @param {string} url - subpath of the service url
   * @param objectToPost
   * @param {(response: HttpResponse<ResponseBody>) => Result} mappingFunction - by default maps to body of the response, but different mapping can be specified
   * @returns {Observable<Result>} value returned by mapping function
   */
  public httpPost<RequestBody, ResponseBody, Result>(
    url: string,
    objectToPost: RequestBody,
    mappingFunction: (response: HttpResponse<ResponseBody>) => Result = response => this.unwrapResponse(response)
  ): Observable<Result> {
    return this.http.post<ResponseBody>(this.baseUrl + url, JSON.stringify(this.wrapListInRequest(objectToPost)), {headers: this.getJsonHeader(), observe: 'response'}).pipe(
      map(mappingFunction)
    );
  }

  /**
   * Doing http put request
   * @param {string} url - subpath of the service url
   * @param objectToPost - body of the request
   * @param {HttpHeaders} headers - headers of the request
   * @param {HttpParams} params - params of the request
   * @param {(response: HttpResponse<ResponseBody>) => Result} mappingFunction - by default maps to body of the response, but different mapping can be specified
   * @returns {Observable<Result>} value returned by mapping function
   */
  public httpPut<RequestBody, ResponseBody, Result>(
    url: string,
    objectToPost: RequestBody,
    headers: HttpHeaders = this.jsonHeader,
    params?: HttpParams,
    mappingFunction: (response: HttpResponse<ResponseBody>) => Result = response => this.unwrapResponse(response)
  ): Observable<Result> {
    return this.http.put<ResponseBody>(this.baseUrl + url, JSON.stringify(this.wrapListInRequest(objectToPost)), {headers: headers, observe: 'response', params: params})
      .pipe(
        map(mappingFunction)
      );
  }

  /**
   * Doing http patch request
   * @param {string} url - subpath of the service url
   * @param patchContent - body of the request
   * @param {HttpHeaders} headers - headers of the request
   * @param {HttpParams} params - params of the request
   * @param {(response: HttpResponse<ResponseBody>) => Result} mappingFunction - by default maps to body of the response, but different mapping can be specified
   * @returns {Observable<Result>} value returned by mapping function
   */
  public httpPatch<RequestBody, ResponseBody, Result>(
    url: string,
    patchContent: RequestBody,
    headers: HttpHeaders = this.jsonHeader,
    params?: HttpParams,
    mappingFunction: (response: HttpResponse<ResponseBody>) => Result = response => this.unwrapResponse(response)
    ): Observable<Result> {
    return this.http.patch<ResponseBody>(this.baseUrl + url, JSON.stringify(this.wrapListInRequest(patchContent)), {observe: 'response', headers: headers, params: params})
      .pipe(
        map(mappingFunction)
      );
  }

  public httpPutFormData<ResponseBody, Result>(
    url: string,
    formData: FormData,
    params?: HttpParams,
    mappingFunction: (response: HttpResponse<ResponseBody>) => Result = response => this.unwrapResponse(response)
  ): Observable<Result> {
    return this.http.put<ResponseBody>(this.baseUrl + url, (formData), {params: params, observe: 'response'}).pipe(
      map(mappingFunction)
    );
  }

  public httpPostFormData<ResponseBody, Result>(
    url: string,
    formData: FormData,
    headers?: HttpHeaders,
    params?: HttpParams,
    mappingFunction: (response: HttpResponse<ResponseBody>) => Result = response => this.unwrapResponse(response)
  ): Observable<Result> {
    return this.http.post<ResponseBody>(this.baseUrl + url, (formData), {headers: headers, params: params, observe: 'response'}).pipe(
      map(mappingFunction)
    );
  }

  public httpPutBlob(url: string, blob: Blob, headers: HttpHeaders = this.jsonHeader): Observable<any> {
    return this.http.put(this.baseUrl + url, blob, {headers: headers});
  }

  public httpDelete(url: string): Observable<any> {
    return this.http.delete(this.baseUrl + url, {observe: 'response'}).pipe(
      map(response => this.unwrapResponse(response))
    );
  }

  private getJsonHeader(): HttpHeaders {
    return new HttpHeaders().append(JSON_HEADER.name, JSON_HEADER.value);
  }

  public unwrapResponse(response: HttpResponse<any | null>): any {
    if (this.isJSONResponse(response)) {
      if (response.body) {
        return (response.body.hasOwnProperty(REST_ARRAY_WRAPPER)) ? response.body[REST_ARRAY_WRAPPER] : response.body;
      } else {
        return null;
      }
    } else {
      return response.body;
    }
  }


  /**
   * if the request object contains a list we need to wrap it
   * @param objectToSend
   * @returns {any}
   */
  private wrapListInRequest(objectToSend: any): any {
    if (objectToSend instanceof Array) {
      return {[REST_ARRAY_WRAPPER]: objectToSend};
    }
    return objectToSend;
  }

  private isJSONResponse(response: HttpResponse<Object | null>): boolean {
    return this.getContentType(response).includes(JSON_HEADER.value);
  }

  private getContentType(response: HttpResponse<Object | null>): string {
    if (response && response.headers && response.headers.get(CONTENT_TYPE)) {
      return response.headers.get(CONTENT_TYPE) || '';
    } else {
      return '';
    }
  }
}

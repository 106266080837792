import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SetVariableData } from './set-variable-data.model';
import { CreateVariableOutputData } from './create-variable-output-data.model';
import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';
import { closeModalOnEscape } from '../modal-dialog/modal-dialog.helper';
import {select, Store} from '@ngrx/store';
import {propertyTypesView} from '../model/views';
import {AppState, Dictionary} from '../model/reducer';
import {PropertyType} from '../plugins/property-type.model';

@Component({
  selector: 'adm4-create-variable-container',
  template: `
    <adm4-modal-dialog-title [header]='header'
                             [showClose]='true'
                             (closeClicked)="cancelVariableCreation()">
      <adm4-create-variable [createVariableData]='data'
                            [variableTypes]="variableTypes$ | async"
                            (variableCreate)='onVariableCreate($event)'
                            (variableSelect)='onVariableSelect($event)'
                            (cancel)='cancelVariableCreation()'></adm4-create-variable>
    </adm4-modal-dialog-title>
  `
})
export class SetVariableContainerComponent implements OnInit, OnDestroy {
  header: string;

  public variableTypes$: Observable<Dictionary<PropertyType> | null>;
  private destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public data: SetVariableData,
              private dialogRef: MatDialogRef<SetVariableContainerComponent>,
              private store$: Store<AppState>) {}

  ngOnInit() {
    this.header = this.data.patternTypeProperty ? `Set variable for ${this.data.patternTypeProperty.name}` : 'Set variable';
    closeModalOnEscape(this.dialogRef, this.destroyed$);
    this.variableTypes$ = this.store$.pipe(select(propertyTypesView));

  }

  onVariableCreate(createVariableOutput: CreateVariableOutputData): void {
    this.data.onCreate(createVariableOutput);
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onVariableSelect(selectedVariableName: string): void {
    this.data.onSelect(selectedVariableName);
    this.dialogRef.close();
  }

  cancelVariableCreation(): void {
    if (this.data.onCancel && _.isFunction(this.data.onCancel)) {
      this.data.onCancel();
    }
    this.dialogRef.close();
  }
}

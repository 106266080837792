/**
 * Contains helper methods to compare two software versions.
 */
export class VersionCompareHelper {

  /**
   * Compares two software version numbers (e.g. "1.7.1" or "4.3.0-20190514T060514Z").
   * Sorts in ascending order if passed to Array.sort().
   *
   * @param {string} v1 The first version to be compared.
   * @param {string} v2 The second version to be compared.
   *
   * @returns {number}
   * - 0 if the versions are equal
   * - a negative integer if v1 < v2
   * - a positive integer if v1 > v2
   */
  static compare(v1: string, v2: string): number {
    // Given version 4.3.0.1-20190521T080336Z, we cut it by dashes
    const v1parts = v1.split('-');
    // [4,3,0,1]
    const v1VersionNumbers = v1parts[0].split('.').map(Number);
    // 20190521T080336Z
    const v1Timestamp = v1parts[1];

    const v2parts = v2.split('-');
    const v2VersionNumbers = v2parts[0].split('.').map(Number);
    const v2Timestamp = v2parts[1];

    // Extend the version numbers to have equal length (to be able to compare for example: 4.3.0 with 4.3.0.1)
    while (v1VersionNumbers.length < v2VersionNumbers.length) {
      v1VersionNumbers.push(0);
    }

    while (v2VersionNumbers.length < v1VersionNumbers.length) {
      v2VersionNumbers.push(0);
    }

    // Compare version number part
    for (let i = 0; i < v1VersionNumbers.length; ++i) {
      if (v1VersionNumbers[i] > v2VersionNumbers[i]) {
        return 1;
      } else if (v1VersionNumbers[i] < v2VersionNumbers[i]) {
        return -1;
      }
    }

    if (v1Timestamp && v2Timestamp) {
      // Compare timestamps if any
      return this.compareValues(v1Timestamp, v2Timestamp);
    } else {
      // Otherwise if we cannot compare we fall back to comparing the 2 versions as string.
      return this.compareValues(v1, v2);
    }
  }

  private static compareValues(value1: string, value2: string) {
    if (value1 === value2) {
      return 0;
    } else if (value1 > value2) {
      return 1;
    } else {
      return -1;
    }
  }

  /**
   * The reverse of compare. Sorts an array of versions in descending order when passed to Array.sort().
   *
   * @param {string} v1 The first version to be compared.
   * @param {string} v2 The second version to be compared.
   *
   * @returns {number}
   */
  static reverseCompare(v1: string, v2: string): number {
    return VersionCompareHelper.compare(v2, v1);
  }
}

<section>
	<h2>About the Inventory File</h2>
	<p>An inventory file is the place where the deployment environment is defined.
		It is divided into different sections: <code>hosts</code>, <code>groups</code>, <code>vars</code> (variables) and <code>consts</code>.</p>
	<ul>
		<li>The <code>hosts</code> section defines all hosts on which Nevis components are deployed.</li>
		<li>The <code>groups</code> section defines groups of hosts for deploying Nevis components.
			You can only group hosts that are already defined in the <code>hosts</code> section.
			Grouping hosts is not mandatory, but recommended.
			It is useful in cases where a Nevis component is simultaneously running on more than one host with the same configuration.</li>
		<li>The <code>vars</code> section contains variables which are defined for the whole inventory.
			Variables can also be defined at <code>groups</code> or <code>hosts</code> level.</li>
		<li>The <code>consts</code> section allows you to create constants that can be reused in multiple variables.</li>
	</ul>

	<p>The <code>hosts</code> section is mandatory, whereas <code>groups</code>, <code>vars</code>, <code>consts</code> are optional.</p>

	<p>It is common practice to have more than one inventory file: namely one for each stage.
		For example: one inventory file describes the hosts used in your test stage and a second inventory file describes the hosts used in your production stage.</p>

</section>

<section>
	<h2>File Format</h2>
	<p>The inventory file uses YAML syntax.
		To get familiar with the YAML syntax, use one of the many online tutorials.
		In YAML, indentation is a crucial part of the syntax and is done with spaces. If tab stops are used, that leads to syntax errors.</p>

	<p>The integrated editor converts a tab stop automatically into two spaces, but be careful when copy-pasting from external editors.
		The syntax is automatically validated while you are editing the inventory file.</p>

	<p>The green traffic light in the top right corner switches to red to indicate syntax violation.
		Hovering over the traffic light reveals details about the syntax violation.</p>

	<p>The inventory file starts with the mandatory preamble which defines the <code>schemaVersion</code>. This ensures that future format changes can be handled.</p>

	<p>Optionally, you can define a color for the inventory. This helps to distinguish between inventories at a glance, for example you can assign different colors for different stages. The available values for <code>color</code> are:
		<span class='pill red'>Red</span>,
		<span class='pill yellow'>Yellow</span>,
		<span class='pill green'>Green</span>,
		<span class='pill purple'>Purple</span>,
		<span class='pill brown'>Brown</span> and
		<span class='pill blue'>Blue</span>.
	</p>

	<p>The main part of the inventory file consists of the following sections: <code>hosts</code>, <code>groups</code>, <code>vars</code>, and <code>consts</code>.</p>

<!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
<pre>
schemaVersion: 1

color: Blue

hosts:
  &lt;items&gt;
groups:
  &lt;items&gt;
vars:
  &lt;items&gt;
consts:
  &lt;items&gt;
</pre>
</section>

<section>
	<h2>Hosts</h2>
	<p>In this section the hosts available for deployment are defined. Each host is usually referenced by its DNS name.
		Alternatively, you can define an abstract name or use its IP address as identifier.</p>

	<p>Each host can have variables that are used by the NEVIS components running on this particular host.
		They are defined right below the host identifier as a list.
		For hosts there are the following four predefined variables:</p>

	<ul>
		<li>
			<code>__connection_host</code>: If you use the abstract name as identifier of a host, you have to define the DNS name or IP address in this variable.
			If this variable is not set, the host name itself will be used.
		</li>
		<li>
			<code>__connection_port</code>: With this variable you define an alternative port to use for deployment.
			If this variable is not set, the port 22 (default for SSH) will be used.
		</li>
		<li>
			<code>__connection_user</code>: This variable defines an alternative user name to use for deployment.
			If this variable is not set, the user name <em>nevis</em> will be used.
		</li>
		<li>
			<code>__connection_password</code>: This variable defines the user's password to use for deployment.
			If this variable is not set, the tenant's SSH key will be used to authenticate.
		</li>
	</ul>

<!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
<pre>
hosts:
- nevis-host1.siven.ch

- nevis-host2.siven.ch

- nevis-host3.siven.ch:
    vars:
      __connection_port: 5222
      __connection_user: root

- proxy-prod:
    vars:
      __connection_host: nevis-host4.siven.ch
      __connection_port: 5222
      __connection_user: techuser
      __connection_password: dhei8Pha7ZeemoSho

- idm-prod:
    vars:
      __connection_host: nevis-host5.siven.ch
</pre>
</section>

<section>
	<h2>Groups</h2>
	<p>In this section the previously defined hosts can be organized into one or more <code>groups</code>.<p>

	<p>Groups allow you to organize your inventory logically.
	As an example, you can structure the <code>hosts</code> in <code>groups</code> according to their functions within the Nevis Security Suite.</p>

	<p>Another approach is to group them according to their physical location, e.g., if your infrastructure is geographically separated into different data centers.</p>

	<p>A combination of both approaches is also possible.
	Variables can also be defined at group level.</p>

<!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
<pre>
groups:
- proxy:
    members:
      - nevis-host3.siven.ch
      - proxy-prod
    vars:
      public_dns: www.siven.ch

- auth:
    members:
      - nevis-host1.siven.ch
      - nevis-host2.siven.ch

- idm:
    members:
      - nevis-host2.siven.ch
      - idm-prod
    vars:
      database_primary: db-host1
      database_secondary: db-host2

- line_active:
    members:
      - proxy-prod
      - nevis-host1.siven.ch
      - idm-prod

- line_standby:
    members:
      - nevis-host3.siven.ch
      - nevis-host2.siven.ch
</pre>
</section>

<section>
	<h2>Vars (Variables)</h2>
	<p>The vars section defines variables that are valid for the entire inventory.
		This means that they are defined for every host in the <code>hosts</code> section. It is common practice to define variables at this level.</p>

	<p>The names of the variables are defined in the project. In the inventory the values are assigned to these variables.</p>

	<p>In case some hosts or groups need different values for the same variable, they can be configured at the corresponding host or group level.
		Keep in mind that variables defined on host level override variables defined on group level, which themselves override variables defined on inventory level.
		Try to keep the confusion at a minimum by defining the variables at the appropriate level, rather than using precedence.</p>

	<p>Variable names consist only of letters, numbers, underscores and dashes.</p>

	<p>Variables do not only support simple key / value pairs, but also more complicated structures, such as lists, dictionaries and even nested structures.</p>

	<div class="admonition-info">
		<p>For testing purposes only, you can enable the automatic key management with the following predefined variable:</p>
		<ul>
			<li>
				<code>__nevisadmin_pki_automatic_enabled</code>: This variable defines if the automatic key management is used (<code>true</code>) for deployment or not (<code>false</code>).
				If this variable is not set, it will be considered as disabled.
				See <adm4-external-link [linkUrl]="'configurationguide/use-cases/Key-Material-and-Certificates/' | docLink"
										 [linkLabel]='"Configuring Key Material and Certificates"'
										 [displayStyle]="'inline'"
										 [matIconName]="'open_in_new'"
										 [openInNewTab]='true'></adm4-external-link> for more details.
			</li>
		</ul>
	</div>

<!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
<pre>
vars:
  __nevisadmin_pki_automatic_enabled: true

  proxy_bind_address: https://www.siven.ch/

  proxy_alias:
    - https://www.nevis.ch/
    - https://www.nevis-security.ch/
    - https://www.nevis-security.de/

  # property with multiple value
  session:
    ttl_max_sec: 43200
    inactive_interval_sec: 1800

  # variable example for key-value property
  log-level:
    - EsAuthStart: INFO
    - org.apache.catalina.startup.HostConfig: Error
</pre>
</section>

<section>
  <h2>Consts (Constants)</h2>
  <p>Constants allows you to create one constant and use it in multiple variables.
		You can link your constant to variables by inserting a reference in the following formats:</p>
  <ul>
		<li>For local constants: <code>{{'${const://constantVariableName}'}}</code></li>
		<li>For global constants: <code>{{'${g-const://constantVariableName}'}}</code></li>
	</ul>
	<p>This will also give you a possibility to manage your variables efficiently. If you need to update the value of
		your variable later on, you will no longer need to update it in multiple places where it is used, but simply you
		update the constant and this will apply to all variables which are referencing to the given constant.</p>
  <p>Constants cannot be referenced in another constants.</p>

	<h3>Local constants</h3>
	<p>Local constants can be created and used only in the given inventory.
		To create and use local constants, define consts section in the inventory file and list the constants in that section.
		To use a constant in your variable, insert its reference as a variable value.</p>

  <!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
  <pre>
consts:
  c1: nevis.net
  c2: nevis
  c3: 8080
  c4: true
  c5: secret://cd445cb0e611e26f8f6480c2

vars:
  var1: {{'${const://c1}'}}                         # resolved: nevis.net
  var2: {{'http://${const://c1}:${const://c3}'}}    # resolved: http://nevis.net:8080
  var3: {{'https://${const://notfound}'}}           # resolved: {{'https://${const://notfound}'}}
  var4: {{'${const://c3}'}}                         # resolved: 8080 (note: 8080 as a number, not as a string)
  var5: {{'${const://c4}'}}                         # resolved: true (note: true as a boolean, not as a string)
  var6:
    varKey: {{'${const://c2}'}}                     # resolved: nevis
  var7:
    - {{'${const://c1}'}}                           # resolved: [ nevis.net, portal.nevis.com ]
    - portal.nevis.com
  var8: {{'${const://c5}'}}                         # resolved: secret://cd445cb0e611e26f8f6480c2
  </pre>

	<h3>Global constants</h3>
	<p>Unlike local constants, the global constants can be created in Global Constants screen and used in multiple inventories. To use a global constant in your variable, insert its reference as a variable value. You can insert the reference in three ways:</p>
	<ul>
		<li>By using Insert global constant button</li>
		<li>By copy-pasting it (copy it from Global Constants screen)</li>
		<li>By manually typing it in the format of <code>{{'${g-const://constantVariableName}'}}</code></li>
	</ul>
</section>

<section>
    <h2>Product Analytics</h2>
    <p>For this feature, the setup described in the <adm4-external-link [linkUrl]="'nevisadmin4/Installation/Software-Installation/Product-Analytics' | docLink"
                                                                        [linkLabel]='"Product Analytics"'
                                                                        [displayStyle]="'inline'"
                                                                        [matIconName]="'open_in_new'"
                                                                        [openInNewTab]='true'></adm4-external-link>
        chapter the nevisAdmin 4 documentation must be in place.</p>
    <p>To enable a default Open Telemetry configuration in the project, inventory variable must be configured with the
        correct otel collector endpoints under the <code>__otel</code> variable.</p>
    <pre>
vars:
  __otel:
    metricsEndpoint: http://otel.siven.ch:4318/v1/metrics
    logsEndpoint: http://otel.siven.ch:4318/v1/logs
    tracesEndpoint: http://otel.siven.ch:4318/v1/traces
  </pre>
</section>

<section>
	<h2>Disable Patterns</h2>
	<p>If you want to exclude some patterns from the deployment, you can disable them.
		For example, this allows you to apply a pattern in one stage while skipping it in another. The following predefined variables can be used:</p>

	<ul>
		<li>
			<code>__disabled_patterns</code>: There are two ways to disable a pattern: by its name or by referencing its ID. It is possible to combine both options on the same list.
			Pattern name supports wildcard character asterisk <code>*</code> which matches zero or any number of characters. It can be used multiple times in pattern name.
		</li>
		<li>
			<code>__disabled_pattern_types</code>: Disables all the patterns of a certain type. Pattern types are defined by their fully-qualified class name.
		</li>
	</ul>

<!-- Note: Please make sure you don't change indentation of code samples here by accident. Also make sure they are indented with spaces as YAML requires that. -->
<pre>
vars:
  __disabled_patterns:
    - "Test Auth"
    - "*Proxy*"
    - "PEM*"
    - "*Instance"
    - "Generic*Settings"
    - "pattern://d3f51b1fcbd3eaf433588645"

  __disabled_pattern_types:
    - "ch.nevis.admin.v4.plugin.nevisauth.patterns2.LdapLogin"
</pre>

</section>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProjectBundle } from '../../projects/project.model';
import { ProjectService } from '../../projects/project.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { ProjectDependenciesHelper } from './project-dependencies.helper';
import * as _ from 'lodash';
import { DirtyFormGuardConnectorService } from '../../common/services/dirty-form-guard-connector.service';
import { NavigationService } from '../../navbar/navigation.service';
import { Subject } from 'rxjs';
import { MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';
import { UpgradeNotesModel } from './project-dependencies.model';
import { ProjectDependenciesDialogService } from './project-dependencies-dialog.service';
import { StandardBundleNames, UpgradeNote } from '../../resources/bundle-management/bundle.model';
import { BundleService } from '../../resources/bundle-management/bundle.service';
import { requireNonNull } from '../../common/utils/utils';
import { UpgradeNotesComponent } from './upgrade-notes.component';
import { VersionCompareHelper } from '../../common/helpers/version-compare.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHelper } from '../../common/helpers/error.helper';

@Component({
  selector: 'adm4-select-dependencies-dialog',
  template: `
    <div class='full-height-flex'>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <div class="dependencies-dialog-container full-height content-container">
            <adm4-project-common-dependencies *ngIf='hasCommonBundles; else noCommonBundles' [projectBundles]='commonProjectBundles'
                                              [isEditMode]='true'
                                              (projectBundlesChange)='updateSelectedCommonOptions($event)'
                                              [currentProjectBaseVersion]='currentProjectBaseVersion'
                                              (navigateToPatternLibraryManagement)='navigateToPatternLibraryManagement()'
            ></adm4-project-common-dependencies>
            <ng-template #noCommonBundles>
              <div class='dependencies-header'>
                Standard libraries
              </div>
              <div>No common libraries found in the system. To upload a library first go to <a (click)='navigateToPatternLibraryManagement()'>Pattern library management</a></div>
            </ng-template>
            <div *ngIf='hasAdditionalBundles'>
              <div class='dependencies-header'>
                Additional libraries
              </div>
              <adm4-project-dependencies-table
                      [isEditMode]="true"
                      [projectBundles]="additionalProjectBundles"
                      (projectBundlesChange)="updateSelectedAdditionalOptions($event)"
              ></adm4-project-dependencies-table>
            </div>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button class='admn4-button-text' (click)='onCancel()'>Cancel</button>
        <button (click)="saveChanges()" class='admn4-button-ellipse-blue' [disabled]='!canSave' title='Please read the upgrade notes for the currently selected common library'>
          Save changes
        </button>
      </div>
    </div>
  `,
  styleUrls: [
    'select-dependencies-dialog.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDependenciesDialogComponent implements OnInit, OnDestroy {
  @Input() commonProjectBundles: ProjectBundle[];
  @Input() additionalProjectBundles: ProjectBundle[];
  @Input() projectKey: string;

  @Output() bundlesSaved = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  /** Emits true when there is a change in any of the bundles. Needed to track dirtiness by formGuardConnectorService. */
  @Output() bundlesChanged = new EventEmitter<boolean>();

  /**
   * Contains all project bundles that will be used after changes, including unmodified bundles
   */
  updatedCommonProjectBundles: ProjectBundle[] = [];
  updatedAdditionalProjectBundles: ProjectBundle[] = [];
  private currentProjectBaseVersion: string | undefined;
  notes: UpgradeNote[] = [];
  filteredUpgradeNotes: UpgradeNote[] = [];
  private upgradeNotesDialog: MatDialogRef<UpgradeNotesComponent>;

  /** Indicates if there are any unsaved changes. */
  canSave = false;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private projectService: ProjectService,
              private toastNotificationService: ToastNotificationService,
              private formGuardConnectorService: DirtyFormGuardConnectorService,
              private navigationService: NavigationService,
              private dialogRef: MatDialogRef<SelectDependenciesDialogComponent>,
              private bundleService: BundleService,
              private projectDependenciesDialogService: ProjectDependenciesDialogService) {
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);

    this.formGuardConnectorService.connect(this.bundlesChanged, () => this.saveChanges(), false);

    this.updatedCommonProjectBundles = this.commonProjectBundles;
    this.updatedAdditionalProjectBundles = this.additionalProjectBundles;
    this.currentProjectBaseVersion = this.pluginBaseBundle ? this.pluginBaseBundle.selectedVersion : undefined;
    this.getInitialUpgradeNotes();
  }

  getInitialUpgradeNotes() {
    const initialProjectBaseBundle = this.pluginBaseBundle;
    if (initialProjectBaseBundle) {
      this.bundleService.getBundleUpgradeNotes(requireNonNull(this.pluginBaseBundle)).subscribe(
        (notes: UpgradeNote[]) => {
          this.notes = notes;
        }, () => {
          this.notes = [];
        });
    }
  }

  get pluginBaseBundle(): ProjectBundle | undefined {
    return this.updatedCommonProjectBundles.find(bundle => bundle.symbolicName === StandardBundleNames.BaseGeneration);
  }

  ngOnDestroy(): void {
    this.formGuardConnectorService.disconnect();

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  saveChanges() {
   if (this.shouldShowUpgradeNotes()) {
     this.openUpgradeNotesDialog();
      return;
    }
    const bundlesToUpdate: string[] = this.updatedCommonProjectBundles.concat(this.updatedAdditionalProjectBundles)
      .filter(projectBundle => projectBundle.isActiveForProject)
      .map(projectBundle => ProjectDependenciesHelper.createBundleKeyFromProjectBundle(projectBundle));
    this.projectService.updateBundlesForProject(bundlesToUpdate, this.projectKey)
      .subscribe(() => {
        this.toastNotificationService.showSuccessToast('Dependencies of the project were successfully saved.', 'Changes saved');
        this.bundlesSaved.next();
        this.closeUpgradeNotesDialogs();
      }, (error: HttpErrorResponse) => {
        const errorMessage = ErrorHelper.getErrorDetail(error, 'Something went wrong during the save.');
        this.toastNotificationService.showErrorToast(errorMessage, 'Changes could not be saved');
        this.dialogRef.close();
      });
  }

  shouldShowUpgradeNotes(): boolean {
    if (_.isEmpty(this.filteredUpgradeNotes)) {
      return false;
    }
    return _.isNil(this.upgradeNotesDialog) ? true : this.upgradeNotesDialog.getState() === MatDialogState.CLOSED;
  }

  openUpgradeNotesDialog() {
    const notesModel: UpgradeNotesModel = {
      notes: this.filteredUpgradeNotes,
      projectKey: this.projectKey,
      onSaveCallback: () => {
        this.saveChanges();
      }
    };
    this.upgradeNotesDialog = this.projectDependenciesDialogService.openUpgradeNotesDialog(notesModel);
  }

  closeUpgradeNotesDialogs() {
    if (this.upgradeNotesDialog) {
      this.upgradeNotesDialog.close();
    }
  }

  navigateToPatternLibraryManagement() {
      this.formGuardConnectorService.doIfConfirmed(() => {
        this.dialogRef.close();
        this.navigationService.navigateToLibraryManagement();
      });
  }

  updateSelectedCommonOptions(projectBundles: ProjectBundle[]): void {
    this.updatedCommonProjectBundles = projectBundles;
    this.filteredUpgradeNotes = this.getUpgradeNotesForBundle();

    this.canSave = this.areBundlesChanged();
    this.bundlesChanged.next(this.canSave);
  }

  getUpgradeNotesForBundle(): UpgradeNote[] {
    if (_.isEmpty(this.notes)) {
      return [];
    }
    const projectBaseBundle: ProjectBundle | undefined = this.pluginBaseBundle;
    const currentVersion: string | undefined = this.currentProjectBaseVersion;
    if (_.isNil(projectBaseBundle) || _.isNil(currentVersion)) {
      return [];
    }
    return this.notes.filter((note: UpgradeNote) => {
      if (_.isEmpty(note.html)) {
        return false;
      }
      const isNewerThanCurrentVersion = VersionCompareHelper.compare(note.version, currentVersion) > 0;
      const isNotNewerThanSelectedVersion = VersionCompareHelper.compare(note.version, projectBaseBundle.selectedVersion) <= 0;
      return isNewerThanCurrentVersion && isNotNewerThanSelectedVersion;
    });
  }

  updateSelectedAdditionalOptions(projectBundles: ProjectBundle[]): void {
    this.updatedAdditionalProjectBundles = projectBundles;

    this.canSave = this.areBundlesChanged();
    this.bundlesChanged.next(this.canSave);
  }

  onCancel(): void {
    this.cancel.emit();
  }

  get hasCommonBundles(): boolean {
    return !_.isEmpty(this.commonProjectBundles);
  }

  get hasAdditionalBundles(): boolean {
    return !_.isEmpty(this.additionalProjectBundles);
  }

  private areBundlesChanged(): boolean {
    const commonBundlesChanged = !ProjectDependenciesHelper.areProjectBundleListsIdentical(this.commonProjectBundles, this.updatedCommonProjectBundles);
    const additionalBundlesChanged = !ProjectDependenciesHelper.areProjectBundleListsIdentical(this.additionalProjectBundles, this.updatedAdditionalProjectBundles);
    return commonBundlesChanged || additionalBundlesChanged;
  }
}

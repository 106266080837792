import * as _ from 'lodash';
import { Project } from '../project.model';
import { DEFAULT_BRANCH_NAME } from '../../common/components/version-control-form/version-control-form.constants';
import { VersionControlHelper } from '../../common/helpers/version-control.helper';
import { ImportProjectForm } from '../create-import-project-common/create-import-branch-project-form.model';

export class ImportProjectHelper {
  static combineProjectName(repository: string, branch: string): string {
    if (!_.isEmpty(repository) && !_.isEmpty(branch)) {
      return `${repository}-${branch}`.toUpperCase();
    } else  {
      return '';
    }
  }

  static getProjectFromFormValue(formValue: ImportProjectForm, tenantKey: string): Project {
    const branchName = _.isNil(formValue.versionControl.branch) ? DEFAULT_BRANCH_NAME : formValue.versionControl.branch.name;
    return {
      projectKey: `${tenantKey}-${formValue.projectKey}`.toUpperCase(),
      tenantKey: tenantKey,
      branch: branchName,
      repository: formValue.versionControl.repository,
      path: VersionControlHelper.getValidPath(formValue.versionControl.path)
    };
  }
}

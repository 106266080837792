export enum DeployedServiceStatus {
  Stable = 'Stable',
  Failed = 'Failed',
  Unknown = 'Unknown'
}

export enum PodStatus {
  Pending = 'Pending',
  Running = 'Running',
  Succeeded = 'Succeeded',
  Completed = 'Completed',
  Failed = 'Failed',
  Unknown = 'Unknown'
}

export interface DeployedServiceItem {
  serviceKey: string;
  status: DeployedServiceStatus;
  pods?: Pod[];
}

export interface Pod {
  podKey: string;
  containers: string[];
  status: string;
  phase: string;
  ready: boolean;
  creationTimestamp: string;
  image?: string;
  namespace: string;
}

import { Injectable } from '@angular/core';
import { PublishProjectContext } from './publish-project.context';
import { combineLatest, Observable } from 'rxjs';
import { ProjectChangesetItem } from './project-changeset-item.model';
import { MetaInfo } from '../../version-control/meta-info.model';
import { first, map, startWith } from 'rxjs/operators';
import { ProjectMeta } from '../project.model';
import { IssueModel } from '../../common/model/issue.model';
import { Pattern } from '../../patterns/pattern.model';
import { Dictionary } from '../../model/reducer';
import { PatternType } from '../../plugins/pattern-type.model';
import * as _ from 'lodash';
import { PublishProjectChangesetHelper } from './publish-project-changeset.helper';
import { CommitMessageHelper } from '../../common/helpers/commit-message.helper';

/**
 * PublishProjectChangesetContext is derived from PublishProjectContext to avoid circular dependencies
 * which can be caused by PublishProjectChangesetHelper as it references dynamic components which also depend on PublishProjectContext
 */
@Injectable()
export class PublishProjectChangesetContext {
  changesetItems$: Observable<ProjectChangesetItem<MetaInfo, any>[]>;
  initialCommitMessage$: Observable<string>;

  constructor(private publishProjectContext: PublishProjectContext) {
    this.changesetItems$ = combineLatest([this.publishProjectContext.projectMeta$, this.publishProjectContext.issues$, this.publishProjectContext.patterns$, this.publishProjectContext.patternTypes$]).pipe(
      first((changesetDataTuple: [ProjectMeta | null, IssueModel[], Map<string, Pattern>, Dictionary<PatternType> | null]) => changesetDataTuple.every(value => !_.isNil(value))),
      map(([projectMeta, issues, patterns, patternTypes]: [ProjectMeta, IssueModel[], Map<string, Pattern>, Dictionary<PatternType>]) => {
        return PublishProjectChangesetHelper.createProjectChangesetFromProjectMeta(projectMeta, patterns, patternTypes, issues);
      }),
      startWith([])
    );
    this.initialCommitMessage$ = this.changesetItems$.pipe(map((changesetItems: ProjectChangesetItem<MetaInfo, any>[]) => CommitMessageHelper.createDefaultCommitMessage(changesetItems)));
  }
}

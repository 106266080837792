import { ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { NavigationConstants } from '../constants/navigation.constants';

export class MenuHelper {
  static onMenuClosed(menuButton: any): void {
    // Workaround for triggering menu icon to lose focus by default it is focused again when clicking a menu item.
    // This only an issue for selecting a project, because when navigating away or closing a dialog it is lost anyway.
    setTimeout(() => {
      menuButton._elementRef.nativeElement.classList.remove('cdk-program-focused');
      menuButton._elementRef.nativeElement.blur();
    }, 100);
  }

  static isActiveLink(routeSnapshot: ActivatedRouteSnapshot, linkSegment: NavigationConstants): boolean {
    const isLinkSegmentPartOfURL: (url: UrlSegment[]) => boolean = (url: UrlSegment[]) => url.some(urlSegment => urlSegment.path === linkSegment);
    return isLinkSegmentPartOfURL(routeSnapshot.url) || routeSnapshot.children.some(childRoute => isLinkSegmentPartOfURL(childRoute.url));
  }
}

import * as _ from 'lodash';
import { CREATED_INVENTORY_KEY_FORM_CONTROL_NAME, EXISTING_INVENTORY_KEY_FORM_CONTROL_NAME, FileBasedImportInventoryFormValue, INVENTORY_KEY_MODE_FORM_CONTROL_NAME, INVENTORY_ZIP_FORM_CONTROL_NAME, InventoryKeyMode } from './file-based-import-inventory-form-value.model';
import { InventoryFileImportPayload } from './inventory-file-import-payload.model';

export class FileBasedImportInventoryHelper {
  static getInventoryNameFromZip(inventoryZip: File | null): string | null {
    const inventoryZipNameRegExp: RegExp = new RegExp('^(inventory_)([a-zA-Z0-9\-]+)(_).');
    const inventoryNameFromZipMatch = _.isNil(inventoryZip) ? null : inventoryZip.name.match(inventoryZipNameRegExp);
    return _.isNil(inventoryNameFromZipMatch) ? null : inventoryNameFromZipMatch[2].toUpperCase();
  }

  // null checks for file and project key are covered by form validation, so this method should be called after form is valid
  static createProjectFileImportPayload(projectFileImportFormValue: FileBasedImportInventoryFormValue, tenantKey: string): InventoryFileImportPayload {
    return {
      inventoryKey: this.getProjectKeyFromFormValue(projectFileImportFormValue, tenantKey),
      inventoryFile: projectFileImportFormValue[INVENTORY_ZIP_FORM_CONTROL_NAME] as File
    };
  }

  private static getProjectKeyFromFormValue(projectFileImportFormValue: FileBasedImportInventoryFormValue, tenantKey: string): string {
    switch (projectFileImportFormValue[INVENTORY_KEY_MODE_FORM_CONTROL_NAME]) {
      case InventoryKeyMode.New:
        return `${tenantKey}-${projectFileImportFormValue[CREATED_INVENTORY_KEY_FORM_CONTROL_NAME]}`.toUpperCase();
      case InventoryKeyMode.Existing:
        return projectFileImportFormValue[EXISTING_INVENTORY_KEY_FORM_CONTROL_NAME] as string;
      default:
        throw new Error(`Unspecified mode of project key: ${projectFileImportFormValue[INVENTORY_KEY_MODE_FORM_CONTROL_NAME]}`);
    }
  }
}

import { TreeNodeWithDetails } from '../../../../tree-viewer/tree-node.model';
import { CheckModel, CheckStatus, CommandModel, DirectoryModel, FileModel, OutputType } from '../../planning-output.model';
import * as _ from 'lodash';
import { TreeNodeTypeEnum } from '../../../../tree-viewer/tree-node-type.enum';
import { CheckActionText, CommandActionText, DirectoryActionText, FileActionText } from './detail-message.enums';
import { Task } from '../../../../task-enum';

export class DetailMessageHelper {
  static getActionTextByCriteria(node: TreeNodeWithDetails<any>, outputType: OutputType): string {
    if (outputType === OutputType.PLAN && !_.isNil(node.details)) {
      switch (node.type) {
        case TreeNodeTypeEnum.Check:
          return this.getActionTextByCheckCriteria(node.details);
        case TreeNodeTypeEnum.Command:
          return this.getActionTextByCommandCriteria(node.details);
        case TreeNodeTypeEnum.Directory:
          return this.getActionTextByDirectoryCriteria(node.details);
        case TreeNodeTypeEnum.File:
        case TreeNodeTypeEnum.KubernetesCustomResource:
          return this.getActionTextByFileCriteria(node.details);
        default:
          throw new Error('Unhandled case or unsupported action');
      }
    }
    return '';
  }

  static getActionTextByCheckCriteria(detail: CheckModel): string {
    if (detail.status === CheckStatus.SUCCEEDED) {
      return CheckActionText.Passed;
    } else if (detail.status === CheckStatus.FAILED) {
      return CheckActionText.Failed;
    }
    return '';
  }

  static getActionTextByCommandCriteria(detail: CommandModel): string {
    if (_.includes(detail.tasks, 'execute')) {
      return CommandActionText.Execute;
    } else {
      return CommandActionText.NoExecute;
    }
  }

  static getActionTextByDirectoryCriteria(detail: DirectoryModel): string {
    const changeTasks = [Task.ChangeOwner, Task.ChangeGroup, Task.ChangePermission];

    const hasCreateTask = _.includes(detail.tasks, Task.CreateDirectory);
    const hasChangeTask = detail.tasks.some((task: string) => _.includes(changeTasks, task));
    if (hasCreateTask) {
      return DirectoryActionText.Create;
    } else if (detail.deleteUnmanagedFiles && hasChangeTask) {
      return DirectoryActionText.AttributeChangeAndChildrenDelete;
    } else if (hasChangeTask) {
      return DirectoryActionText.AttributeChange;
    } else if (_.isEmpty(detail.tasks)) {
      return DirectoryActionText.NoChange;
    }
    throw new Error('Unsupported task or case.');
  }

  static getActionTextByFileCriteria(detail: FileModel): string {
    const changeAttributesTasks = [Task.ChangeOwner, Task.ChangeGroup, Task.ChangePermission];

    const hasCreateTask = _.includes(detail.tasks, Task.CreateFile);
    const hasChangeContentTask = _.includes(detail.tasks, Task.ChangeContent);
    const hasChangeAttributesTask = detail.tasks.some((task: string) => _.includes(changeAttributesTasks, task));
    if (hasCreateTask) {
      return FileActionText.Create;
    } else if (hasChangeContentTask && hasChangeAttributesTask) {
      return FileActionText.ContentAndAttributeChange;
    } else if (hasChangeContentTask) {
      return FileActionText.ContentChange;
    } else if (hasChangeAttributesTask) {
      return FileActionText.AttributeChange;
    } else if (_.isEmpty(detail.tasks)) {
      return FileActionText.NoChange;
    }
    throw new Error('Unsupported task or case.');
  }
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { MatSelectChange } from '@angular/material/select';

import { Store } from '@ngrx/store';

import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import { AppState } from '../../../model/reducer';
import { tenantScopedConstantsView } from '../../../model/views';
import { GlobalConstant } from '../../inventory.model';

const SELECTION = 'selection';

@Component({
  selector: 'adm4-insert-global-constant-dialog',
  template: `
    <adm4-modal-dialog-title [header]='"Insert global constant reference"'
                             [showClose]='true'
                             (closeClicked)="closeDialog()">
      <form [formGroup]="form" (ngSubmit)="save()">
        <div class="content-container">

          <label class="input-label">Select the global constant</label>
          <div class='form-group admn4-input-group-with-icon'>
            <mat-form-field class='full-width'>
              <mat-select #matSelect
                          [placeholder]="'Please select a global constant...'"
                          [disableOptionCentering]="true"
                          [formControlName]="'selection'"
                          (selectionChange)="selectionChanged($event)"
                          (openedChange)="selectToggled($event)">
                <adm4-searchable-dropdown-input *ngIf='matSelect.focused'
                                                [showSpinner]="false"
                                                [focusTrigger]="searchInputFocusTrigger$"
                                                (searchText)="search.next($event)"
                ></adm4-searchable-dropdown-input>
                <ng-container *ngIf="(constants$ | async)?.length >= 1; else noResults">
                  <ng-container *ngFor="let gc of (constants$ | async);">
                    <mat-option [value]="gc"
                                [ngbTooltip]="optionTooltip" placement="bottom"
                    >{{gc.name}}</mat-option>
                    <ng-template #optionTooltip>
                      <p>{{gc.value}}</p>
                      <hr/>
                      <p>{{gc.description || '-'}}</p>
                    </ng-template>
                  </ng-container>
                </ng-container>
                <ng-template #noResults><mat-option disabled>No results.</mat-option></ng-template>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="selectedGC" class="selected-gc">
            <p><b>Value: </b>{{selectedGC.value}}</p>
            <p><strong>Description: </strong>{{selectedGC.description || '-'}}</p>
          </div>
        </div>
        <div mat-dialog-actions>
          <adm4-button-bar
            [isSubmitDisabled]="form.invalid"
            [submitButtonText]="'Insert'"
            (cancelClicked)="closeDialog()"
          ></adm4-button-bar>
        </div>
      </form>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['./insert-global-constant-dialog.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-mat-dialog'"},
})
export class InsertGlobalConstantDialogComponent {

  public readonly search: Subject<string | undefined> = new Subject();

  public readonly constants$: Observable<GlobalConstant[]>;
  public readonly search$ = this.search.asObservable();

  _searchInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchInputFocusTrigger$: Observable<void> = this._searchInputFocusTrigger$.asObservable();

  public selectedGC: GlobalConstant | undefined;

  public readonly form: UntypedFormGroup;

  constructor(
      public dialogRef: MatDialogRef<InsertGlobalConstantDialogComponent>,
      private store$: Store<AppState>,
      formBuilder: UntypedFormBuilder,
  ) {
    this.form = formBuilder.group({
      [SELECTION]: [undefined, {validators: Validators.required, updateOn: 'change'}],
    });

    this.constants$ = combineLatest([
      this.store$.select(tenantScopedConstantsView),
      this.search$.pipe(debounceTime(200), startWith('')),
    ]).pipe(
        map(([allConstants, search]: [GlobalConstant[], string | undefined]): GlobalConstant[] =>
            allConstants.filter((gc: GlobalConstant) => {
              if (!search) {
                return true;
              }
              const lcSearch = search.toLowerCase();
              return gc.name.toLowerCase().includes(lcSearch)
                  || gc.value.toLowerCase().includes(lcSearch)
                  || gc.description?.toLowerCase().includes(lcSearch);
            })),
    );
  }

  selectionChanged(event: MatSelectChange) {
    this.selectedGC = event.value;
  }

  save() {
    this.dialogRef.close(this.form.get(SELECTION)?.value.name);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  selectToggled(isOpen: boolean) {
    if (isOpen) {
      this._searchInputFocusTrigger$.next();
    }
  }

}

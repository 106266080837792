import { ActionTypes } from './actions';
import { EntityCache } from './reducer';
import { Dictionary } from 'lodash';
import { PluginDocumentationModel } from '../help/plugin-documentation.model';

export function cacheReducer(cache: EntityCache, action: any): EntityCache {
  switch (action.type) {
    case ActionTypes.LoadPluginDocSuccess:
      return cachePluginDoc(cache, action.payload);
    case ActionTypes.LoadPluginDocForPatternTypesSuccess:
      return cachePluginDocArray(cache, action.payload);
    case ActionTypes.InvalidatePluginDoc:
      return invalidatePluginDocArray(cache);
    default:
      return cache;
  }
}

function cachePluginDoc(cache: EntityCache, doc: PluginDocumentationModel): EntityCache {
  const newEntry: Dictionary<string | null> = {};
  newEntry[doc.projectKeyClassName] = doc.htmlContent;
  return {
    ...cache,
    pluginDocumentations: {
      ...cache.pluginDocumentations,
      ...newEntry
    }
  };
}

function cachePluginDocArray(cache: EntityCache, docArray: PluginDocumentationModel[]): EntityCache {
  const newEntry: Dictionary<string | null> = {};
  docArray.forEach((doc) => {
    newEntry[doc.projectKeyClassName] = doc.htmlContent;
  });
  return {
    ...cache,
    pluginDocumentations: {
      ...cache.pluginDocumentations,
      ...newEntry
    }
  };
}

function invalidatePluginDocArray(cache: EntityCache): EntityCache {
  return {
    ...cache,
    pluginDocumentations: {}
  };
}

import { Dictionary } from '../reducer';
import { VariableModel } from '../../variables/variable.model';
import * as fromActions from '../actions';
import { VariableActionTypes } from './variable.actions';
import * as _ from 'lodash';
import * as fromShared from '../shared/shared.actions';
import * as fromProject from '../project/project.actions';

export class VariableState {
  readonly allVariables: Dictionary<VariableModel>;
}

export const initState: VariableState = {
  allVariables: {}
};

export function reducer(state: VariableState, action: fromActions.NevisAdminAction<any>): VariableState {
  switch (action.type) {
    case fromShared.SharedActionTypes.SelectTenantKey:
    case fromProject.ProjectActionTypes.SelectProject:
      return initState;
    case VariableActionTypes.LoadVariablesSuccess:
    case VariableActionTypes.DeleteVariableSuccess:
    case VariableActionTypes.UpdateVariablesSuccess:
    case VariableActionTypes.CreateVariableSuccess: {
      const newEntries = _.mapKeys(action.payload as VariableModel[], variable => variable.variableKey);
      return {
        ...state,
        allVariables: newEntries
      };
    }
    default:
      return state;
  }
}

import { Property } from '../plugins/pattern-type.model';
import * as _ from 'lodash';
import { VariableModel } from '../variables/variable.model';
import { reduceUntil } from '../common/utils/utils';
import { SetVariableData } from './set-variable-data.model';
import { VariablesHelper } from '../variables/variables.helper';

export class SetVariableHelper {
  static combineDefaultVariableName(patternName: string, property: Property | undefined): string {
    const propertyName: string = _.isNil(property) ? '' : property.name;
    return _(`${patternName}-${propertyName}`)
      .replace(new RegExp(' ', 'g'), '-')
      .replace(new RegExp('[^a-zA-Z-_0-9]', 'g'), '')
      .valueOf()
      .toLocaleLowerCase();
  }

  static createDefaultVariableName(patternName: string, property: Property | undefined, variables: VariableModel[]): string {
    const defaultVariableName = this.combineDefaultVariableName(patternName, property);
    const existingVariableNames = variables.map((variable: VariableModel) => variable.variableKey);
    return reduceUntil(defaultVariableName, (index: number) => {
      return index === 0 ? defaultVariableName : `${defaultVariableName}-${index}`;
    }, (variableName: string) => !_.includes(existingVariableNames, variableName));
  }

  static getDefaultVariableValue(createVariableData: SetVariableData): any {
    if (!VariablesHelper.canVariableHaveSampleValue(createVariableData.variableType)) {
      return undefined;
    }
    return createVariableData.variableStartingValue;
  }
}

import { ActionReducer, createFeature, createReducer, on } from '@ngrx/store';

import { NevisAdminAction } from '../../model/actions';
import { ProjectTemplate } from './marketplace.model';
import { marketplaceItemsLoaded, resetMarketplace, selectItem, textFilterChanged } from './marketplace.actions';

interface MarketPlaceFilters {
  text: string | null;
}

export interface MarketplaceState {
  allItems: ProjectTemplate[];
  filters: MarketPlaceFilters;
  selectedKey: string | null;
}

export const initialState: MarketplaceState = {
  allItems: [],
  selectedKey: null,
  filters: {
    text: null,
  },
};

export const STORE_FEATURE_KEY_MARKETPLACE = 'marketplace';

export const marketplaceReducer: ActionReducer<MarketplaceState, NevisAdminAction> = createReducer<MarketplaceState, NevisAdminAction>(
    initialState,
    on(marketplaceItemsLoaded, (state: MarketplaceState, action): MarketplaceState => {
      return {
        ...state,
        allItems: action.items,
      };
    }),
    on(textFilterChanged, (state: MarketplaceState, action): MarketplaceState => {
      const filters: MarketPlaceFilters = {
        ...state.filters,
        text: action.textFilter,
      };
      return {
        ...state,
        filters,
      };
    }),
    on(selectItem, (state: MarketplaceState, action): MarketplaceState => {
      const currentSelection: string | null = state.selectedKey;
      let newSelection: string | null;
      if (currentSelection) {
        newSelection = currentSelection === action.selectedProjectTemplateKey ? null : action.selectedProjectTemplateKey;
      } else {
        newSelection = action.selectedProjectTemplateKey;
      }
      return {
        ...state,
        selectedKey: newSelection,
      };
    }),
    on(resetMarketplace, (state: MarketplaceState): MarketplaceState => {
      return {
        ...initialState,
        allItems: state.allItems,
      };
    }),
);

export const marketplaceStoreFeature = createFeature({
  name: STORE_FEATURE_KEY_MARKETPLACE,
  reducer: marketplaceReducer,
});

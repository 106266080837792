import { IssueModel } from '../common/model/issue.model';
import { PatternType } from '../plugins/pattern-type.model';
import { Pattern } from './pattern.model';

/**
 * Models the data needed for displaying the pattern list
 */
export class PatternListData {
  constructor(public pattern: Pattern,
              public issues: IssueModel[],
              public type?: PatternType) {
  }
}

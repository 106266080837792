import { NavigationModel } from './navigation.model';

export const navigationElements: Record<'projects' | 'infrastructure' | 'userManagement' | 'deploy', NavigationModel> = {
  projects: {
    title: 'Configuration',
    path: 'projects'
  },
  infrastructure: {
    title: 'Infrastructure',
    path: 'infrastructure'
  },
  userManagement: {
    title: 'Resources',
    path: 'resources'
  },
  deploy: {
    title: 'Deploy',
    className: 'deploy',
    icon: 'play_circle_filled',
    path: {outlets: {modal: 'deploy'}}
  }
};

import { Injectable } from '@angular/core';
import { ActivatedRoute, EventType, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import * as _ from 'lodash';

import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { UrlHelper } from '../common/helpers/url.helper';
import { NavigationConstants } from '../common/constants/navigation.constants';
import { ProjectKey } from '../model/reducer';
import { PatternId } from '../model/pattern';
import { Maybe } from '../common/utils/utils';
import { InventoryResourceType } from '../inventory/inventory.model';

/**
 * This service is used for taking data out of the URL
 */
@Injectable()
export class NavigationService {

  private readonly AZURE_UPGRADE_URL = 'https://portal.nevis.net/portal/secure/nevisadmin4/rolling/upgrade';

  /**
   * The current URL after redirects. Emits only when the navigation was finished.
   */
  public urlAfterRedirects$: Observable<string>;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.urlAfterRedirects$ = this.router.events.pipe(
      filter((e): e is NavigationEnd => EventType.NavigationEnd === e.type),
      map((e: NavigationEnd): string => e.urlAfterRedirects),
      shareReplay(1),
    );
  }

  public navigateToLogin(returnUrl?: string): void {
    if (!returnUrl) {
      returnUrl = this.router.routerState.snapshot.url;
    }
    if (returnUrl.startsWith('/login')) {
      // we are in the login page, do not need navigation
      // reason: this case the navigation is done by the interceptor respond 401
      // and multiple request could be out responding with 401 in the same time
      return;
    }
    this.router.navigate([NavigationConstants.ROOT, NavigationConstants.LOGIN], {queryParams: {returnUrl: returnUrl}});
  }

  navigateToRoot(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.ROOT]);
  }

  navigateToReturnUrl(returnURL = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/projects'): Promise<boolean> {
    return this.router.navigateByUrl(returnURL);
  }

  navigateToWelcome(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.ROOT, NavigationConstants.WELCOME]);
  }

  navigateToInventoryWelcome(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.ROOT, NavigationConstants.INFRA_WELCOME]);
  }

  navigateToLogout(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.ROOT, NavigationConstants.LOGOUT]);
  }

  navigateToGraphViewPattern(projectKey: string, patternId: string): Promise<boolean> {
    return this.navigatePrimaryOnly([NavigationConstants.GRAPH_VIEW, NavigationConstants.PROJECTS, projectKey, NavigationConstants.PATTERNS, patternId]);
  }

  navigateToPatterns(projectKey: string): Promise<boolean> {
    return this.router.navigate([NavigationConstants.PROJECTS, projectKey, NavigationConstants.PATTERNS]);
  }

  navigateToIssues(projectKey: string): Promise<boolean> {
    return this.navigatePrimaryOnly([NavigationConstants.PROJECTS, projectKey, NavigationConstants.ISSUES]);
  }

  navigateToProjectSummary(projectKey: string, replaceUrl = false): Promise<boolean> {
    return this.router.navigate([NavigationConstants.PROJECTS, projectKey, NavigationConstants.OVERVIEW], {replaceUrl});
  }

  navigateToPattern(projectKey: string, patternId: string): Promise<boolean> {
    return this.navigatePrimaryOnly([NavigationConstants.PROJECTS, projectKey, NavigationConstants.PATTERNS, patternId]);
  }

  navigateToNewlyCreatedPattern(projectKey: string, patternId: string): Promise<boolean> {
    return this.navigatePrimaryOnly([NavigationConstants.PROJECTS, projectKey, NavigationConstants.PATTERNS, patternId], {fragment: NavigationConstants.TITLE_EDIT});
  }

  navigateToVariables(projectKey: string): Promise<boolean> {
    return this.navigatePrimaryOnly([NavigationConstants.PROJECTS, projectKey, NavigationConstants.VARIABLES]);
  }

  navigateToReports(projectKey: string): Promise<boolean> {
    return this.navigatePrimaryOnly([NavigationConstants.PROJECTS, projectKey, NavigationConstants.REPORTS]);
  }

  navigateToProjectSettings(projectKey: string): Promise<boolean> {
    return this.navigatePrimaryOnly([NavigationConstants.PROJECTS, projectKey, NavigationConstants.PROJECT_SETTINGS]);
  }

  navigateToReport(projectKey: string, className: string): Promise<boolean> {
    return this.navigatePrimaryOnly([NavigationConstants.PROJECTS, projectKey, NavigationConstants.REPORTS, className]);
  }

  navigateToVariable(projectKey: string, variableName: string, replaceUrl = false): Promise<boolean> {
    return this.navigatePrimaryOnly([NavigationConstants.PROJECTS, projectKey, NavigationConstants.VARIABLES, variableName], {replaceUrl});
  }

  navigateToDeploymentWizard(): Promise<boolean> {
    return this.router.navigate([{outlets: {modal: NavigationConstants.DEPLOY_MODAL_OUTLET}}]);
  }

  navigateToGlobalSearch(): Promise<boolean> {
    return this.router.navigate([{outlets: {modal: NavigationConstants.GLOBAL_SEARCH}}]);
  }

  navigateToPublishWindowOfProject(projectKey: ProjectKey): Promise<boolean> {
    return this.navigateToProject(projectKey).then(() => this.navigateToPublishProjectWindow());
  }

  navigateToPublishProjectWindow(): Promise<boolean> {
    return this.router.navigate([{outlets: {modal: NavigationConstants.PUBLISH_PROJECT}}]);
  }

  navigateToPublishInventoryWindow(): Promise<boolean> {
    return this.router.navigate([{outlets: {modal: NavigationConstants.PUBLISH_INVENTORY}}]);
  }

  /**
   * Closes modal window which uses auxiliary router outlet modal
   */
  navigateAwayFromModalWindow(): Promise<boolean> {
    return this.router.navigate([{outlets: {modal: null}}]);
  }

  navigateToInventories(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.INFRASTRUCTURE, NavigationConstants.INVENTORIES]);
  }

  navigateToInventory(inventoryKey: string, replaceUrl = false): Promise<boolean> {
    return this.router.navigate([NavigationConstants.INFRASTRUCTURE, NavigationConstants.INVENTORIES, inventoryKey, NavigationConstants.EDIT], {replaceUrl});
  }

  navigateToInventoryWithEditorPosition(inventoryKey: string, lineNumber: number, column: number, replaceUrl = false): Promise<boolean> {
    return this.router.navigate(
      [NavigationConstants.INFRASTRUCTURE, NavigationConstants.INVENTORIES, inventoryKey, NavigationConstants.EDIT, {outlets: {modal: null}}],
      {replaceUrl, queryParams: {[NavigationConstants.PARAM_POSITION]: `${lineNumber}-${column}`}},
    );
  }

  navigateToInventoryDeploymentHistory(inventoryKey: string): Promise<boolean> {
    return this.router.navigate([NavigationConstants.INFRASTRUCTURE, NavigationConstants.INVENTORIES, inventoryKey, NavigationConstants.DEPLOYMENT_HISTORY]);
  }

  navigateToInventoryKubernetesStatus(inventoryKey: string): Promise<boolean> {
    return this.router.navigate([NavigationConstants.INFRASTRUCTURE, NavigationConstants.INVENTORIES, inventoryKey, NavigationConstants.KUBERNETES_STATUS]);
  }

  navigateToInventoryStatus(inventoryKey: string): Promise<boolean> {
    return this.router.navigate([NavigationConstants.INFRASTRUCTURE, NavigationConstants.INVENTORIES, inventoryKey, NavigationConstants.INVENTORY_STATUS]);
  }

  navigateToInventorySettings(inventoryKey?: string): Promise<boolean> {
    return this.router.navigate(_.compact([NavigationConstants.INFRASTRUCTURE, NavigationConstants.INVENTORIES, inventoryKey, NavigationConstants.INVENTORY_SETTINGS]));
  }

  navigateToProject(projectKey: string, replaceUrl = false): Promise<boolean> {
    return this.router.navigate([NavigationConstants.PROJECTS, projectKey], {replaceUrl});
  }

  navigateToGlobalConstants(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.RESOURCES, NavigationConstants.GLOBAL_CONSTANTS]);
  }

  navigateToSecretManagement(replaceUrl: boolean = false): Promise<boolean> {
    return this.router.navigate([NavigationConstants.RESOURCES, NavigationConstants.SECRET_MANAGEMENT], {replaceUrl});
  }

  navigateToSecretResource(resourceType: InventoryResourceType, resourceId: string, scope: string): Promise<boolean> {
    return this.router.navigate(
      [NavigationConstants.RESOURCES, NavigationConstants.SECRET_MANAGEMENT],
      {
        queryParams: {
          [NavigationConstants.PARAM_RESOURCETYPE]: resourceType,
          [NavigationConstants.PARAM_RESOURCEID]: resourceId,
          [NavigationConstants.PARAM_SCOPE]: scope,
        }
      },
    );
  }

  navigateToCertificates(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.RESOURCES, NavigationConstants.CERTIFICATE_OVERVIEW]);
  }

  navigateToKubernetesCertificates(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.RESOURCES, NavigationConstants.KUBERNETES_CERTIFICATE_OVERVIEW]);
  }

  navigateToLibraryManagement(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.RESOURCES, NavigationConstants.LIBRARY_MANAGEMENT]);
  }

  navigateToMarketPlace(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.ROOT, NavigationConstants.RESOURCES, NavigationConstants.MARKETPLACE]);
  }

  navigateToMarketPlaceTemplate(templateKey: string): Promise<boolean> {
    return this.router.navigate([NavigationConstants.ROOT, NavigationConstants.RESOURCES, NavigationConstants.MARKETPLACE, templateKey]);
  }

  navigateToUsers(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.ROOT, NavigationConstants.ACCESS_MANAGEMENT, NavigationConstants.USER_MANAGEMENT]);
  }

  navigateToGroups(): Promise<boolean> {
    return this.router.navigate([NavigationConstants.ROOT, NavigationConstants.ACCESS_MANAGEMENT, NavigationConstants.GROUP_MANAGEMENT]);
  }

  navigateToUserDetails(userKey: string): Promise<boolean> {
    return this.router.navigate([NavigationConstants.ROOT, NavigationConstants.ACCESS_MANAGEMENT, NavigationConstants.USER_MANAGEMENT, userKey]);
  }

  navigateToGroupDetails(groupKey: string): Promise<boolean> {
    return this.router.navigate([NavigationConstants.ROOT, NavigationConstants.ACCESS_MANAGEMENT, NavigationConstants.GROUP_MANAGEMENT, groupKey]);
  }

  navigateToCategory(categoryIndex: number) {
    if (categoryIndex < 1) {
      // do not add category param if that is the first one
      this.router.navigate([], {preserveFragment: true});
    } else {
      this.router.navigate([], {queryParams: {[NavigationConstants.CATEGORY]: categoryIndex}, preserveFragment: true});
    }
  }

  navigateToSummaryTabWithSearch(projectKey: string, reportTab: string, searchTerm: string) {
    const commands = [NavigationConstants.PROJECTS, projectKey, NavigationConstants.OVERVIEW, reportTab];
    const extras = {queryParams: {[NavigationConstants.SUMMARY_SEARCH_TERM]: searchTerm}};
    return this.router.navigate(commands, extras);
  }

  toAzureUpgradeNewTab() {
    window.open(this.AZURE_UPGRADE_URL, '_blank');
  }

  scrollToProperty(propertyKey: string): Promise<boolean> {
    return this.router.navigate([], {
      fragment: propertyKey,
      replaceUrl: true,
      queryParamsHandling: 'preserve'
    });
  }

  /**
   * wraps router navigation and removes aux routes to only have primary outlet navigation
   * RouterLink directive does not work properly with removing aux routes so this method is rather a workaround
   * If currently there is a modal opened, links will be opened in a new tab
   * @param {any[]} commands
   * @param {NavigationExtras} extras
   * @returns {Promise<boolean>}
   */
  private navigatePrimaryOnly(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    if (this.hasAuxModalRouteActive()) {
      return new Promise<boolean>((resolve: Function, reject: Function) => {
        try {
          const commandsWithRemovedAuxRoutes = [{outlets: {modal: null, primary: commands}}];
          const urlTree = this.router.createUrlTree(commandsWithRemovedAuxRoutes, extras);
          const url = this.router.serializeUrl(urlTree);
          const fullUrl = UrlHelper.combineFullUrl(url);
          window.open(fullUrl, '_blank');
          resolve(true);
        } catch (e) {
          reject(e);
        }
      });
    } else {
      return this.router.navigate(commands, extras);
    }
  }

  private hasAuxModalRouteActive(): boolean {
    return this.activatedRoute.children.some(child => child.outlet === 'modal');
  }

  getGraphViewLink(projectKey: ProjectKey, patternId: Maybe<PatternId>) {
    let pathArray = ['#', NavigationConstants.GRAPH_VIEW, NavigationConstants.PROJECTS, projectKey];
    const patternIdSubPath = [NavigationConstants.PATTERNS, patternId];
    if (patternId) {
      return _.join(_.concat(pathArray, patternIdSubPath), '/');
    }
    return _.join(pathArray, '/');
  }
}

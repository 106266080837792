import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Dictionary } from '../../model/reducer';

export class FormHelper {

  static VALIDATION_KEY_MAX_LENGTH = 'maxlength';
  static VALIDATION_KEY_REQUIRED = 'required';

  static VALIDATOR_PATTERN_STANDARD_CHARSET = '[a-zA-Z0-9_-]*';

  static shouldShowFormControlInvalid(formControl: AbstractControl): boolean {
    return (formControl.dirty || formControl.touched) && !formControl.valid;
  }

  static shouldShowFormControlErrorForKey(formControl: AbstractControl, errorKey: string): boolean {
    return (formControl.dirty || formControl.touched) && !_.isNil(formControl.errors) && !!formControl.errors[errorKey];
  }

  /**
   * When form is stored on objects in store we must guarantee that store state is immutable, however formGroup is mutable by itself
   * Therefore when we need to pass the stored form to the view we create can its clone which can be further mutated by the view
   * NOTE: this would not have been needed if the components were not tightly coupled with form, but they require it even if it's needed only for display
   * @param form
   */
  static cloneForm(form: UntypedFormGroup): UntypedFormGroup {
    return _.cloneDeep(form);
  }

  static createFormFromFormValue(formValue: Dictionary<any>): UntypedFormGroup {
    const form: UntypedFormGroup = new UntypedFormGroup({});
    _.toPairs(formValue).forEach(([formControlName, value]: [string, any]) => {
      form.addControl(formControlName, new UntypedFormControl(value));
    });
    return form;
  }
}

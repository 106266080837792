import { debounceTime, takeUntil } from 'rxjs/operators';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

const DEBOUNCE_TIME_MILI = 400;

/**
 * This component is for displaying the input field for filtering. This can be used for filtering any component
 * It contains a placeholder for the input field, an icon which is or not shown inside the input field and a filterText
 * event which should be called whenever the filter is performed.
 * The filter is done with a 400ms debounce time, and it emits the event with the filter text as parameter
 */
@Component({
  selector: 'adm4-filter',
  template: `
    <div class='fl-wrapper'>
      <input #filterInput type='text' class='admn4-text-input form-control' [value]="filterText"
             [placeholder]='placeholderText' (input)="term.next($event.target.value)"/>
      <button class='admn4-button-filter-icon selected'>
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
    </div>`,
  styleUrls: ['filter.scss']
})
export class FilterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() placeholderText: string;
  @Input() withSearchIcon = true;
  @Input() filterText = '';
  @Input() focused = false;
  @Output() filter = new EventEmitter<string>();

  private destroyed$: Subject<boolean> = new Subject();
  public term = new Subject<string>();
  @ViewChild('filterInput', {static: false}) filterInput;

  /**
   * It subscribes to the input change event with a debounce time specified above. It emits the filter event if the
   * input field content changed
   */
  ngOnInit(): void {
    this.term.pipe(
      debounceTime(DEBOUNCE_TIME_MILI),
      takeUntil(this.destroyed$)
    )
      .subscribe(term => {
        this.filterText = term;
        this.filter.emit(this.filterText);
      });
  }

  ngAfterViewInit() {
    if (this.focused) {
      setTimeout(() => {
        this.filterInput.nativeElement.focus();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}

import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatExpansionPanel } from '@angular/material/expansion';
import { DraggableMenuInfoModel } from './draggable-menu-info.model';
import { LocalStorageHelper } from '../../helpers/local-storage.helper';

@Component({
  selector: 'adm4-draggable-checkbox-menu',
  template: `
    <mat-accordion displayMode="flat" [multi]="false" cdkDropListGroup #matAccording>
      <mat-expansion-panel #chapterExpansionPanel
                           hideToggle="true"
                           (mouseenter)='mouseEnterHandler($event, chapterExpansionPanel)' class='expansion-panel'>
        <mat-expansion-panel-header class='draggable-menu-dropdown-wrapper'>
          <mat-panel-title class='draggable-menu-dropdown-title'>
            <mat-icon class='customize-icon'>settings</mat-icon>
            {{menuInfo.titleText}}
            <span class="material-icons open">arrow_drop_down</span>
            <span class="material-icons close">arrow_drop_up</span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf='menuInfo' class='menu-info-wrapper'>
          <div class='menu-descr-header'>{{menuInfo.headerText}}</div>
          <p>{{menuInfo.headerDescription}}</p>
          <div class='all-selection'><a (click)='selectAll()'>{{allSelectionText}}</a></div>
        </div>
        <ul class='draggable-list' (cdkDropListDropped)="cdkDropListDroppedHandler($event)"
            cdkDropList [cdkDropListData]="allItemList">
          <li cdkDrag *ngFor="let item of allItemList" class='drop-list-item'>
            <div class='drag-icon-wrapper'><i class="fa fa-arrows-v" aria-hidden="true"></i></div>
            <span class='drag-item-text'>{{ item | capitalizeFirst }}</span>
            <mat-checkbox class='pattern-element-checkbox'
                          [checked]='isItemSelected(item)'
                          (change)='onCheckboxTick($event.checked, item)'
            ></mat-checkbox>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>
  `,
  styleUrls: ['draggable-checkbox-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DraggableCheckboxMenuComponent<T> implements OnInit {

  @Input() initialSelection: Readonly<Array<T>>;
  @Input() allItemList: T[];
  @Input() menuInfo: DraggableMenuInfoModel;
  @Input() localStorageKey: string;
  @Output() selectionChange: EventEmitter<T[]> = new EventEmitter();
  selectedItems: T[];

  @ViewChild('matAccording', {static: false}) matAccording: ElementRef;
  @ViewChild('chapterExpansionPanel', {static: false}) matExpansionPanelElement: MatExpansionPanel;

  ngOnInit(): void {
    this.selectedItems = this.getInitialSelection();
  }

  getInitialSelection(): T[] {
    if (_.isNil(this.localStorageKey)) {
      return Object.values(this.initialSelection);
    }
    const preSelectedItems = LocalStorageHelper.retrieve(this.localStorageKey);
    return _.isNil(preSelectedItems) ? Object.values(this.initialSelection) : JSON.parse(preSelectedItems);
  }

  cdkDropListDroppedHandler(event: CdkDragDrop<any[]>) {
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex);
    this.selectedItems = _.filter(event.container.data, displayableItem => _.includes(this.selectedItems, displayableItem));
    this.triggerSelectionChange();
  }

  // Open the expansion panel during drag & drop action every time to detect list-order changes
  mouseEnterHandler(event: MouseEvent, chapterExpansionPanel: MatExpansionPanel): void {
    if (event.buttons && !chapterExpansionPanel.expanded) {
      chapterExpansionPanel.open();
    }
  }

  @HostListener('document:click', ['$event'])
  handleClickEvent(event) {
    if (!this.matAccording.nativeElement.contains(event.target)) {
      this.matExpansionPanelElement.close();
    }
  }

  onCheckboxTick(isChecked: boolean, selectedItem: T): void {
    if (isChecked) {
      this.selectedItems.push(selectedItem);
    } else {
      _.pull(this.selectedItems, selectedItem);
    }
    // Need to sort to keep the default order of the items based on the available allItemList
    this.selectedItems.sort((item1, item2) => {
      return this.allItemList.indexOf(item1) - this.allItemList.indexOf(item2);
    });
    this.triggerSelectionChange();
  }

  selectAll(): void {
    this.selectedItems = this.isAllCheckBoxSelected ? [] : [...this.allItemList];
    this.triggerSelectionChange();
  }

  isItemSelected(itemName: T): boolean {
    return this.selectedItems.includes(itemName);
  }

  triggerSelectionChange(): void {
    this.selectionChange.emit(this.selectedItems);
    LocalStorageHelper.save(this.localStorageKey, JSON.stringify(this.selectedItems));
  }

  get allSelectionText(): string {
    return this.isAllCheckBoxSelected ? 'Deselect All' : 'Select All';
  }

  get isAllCheckBoxSelected(): boolean {
    return _.isEqual(this.selectedItems.length, this.allItemList.length);
  }
}

import { NgModule } from '@angular/core';
import { CommonModules } from '../common/common.module';
import { FallbackPropertyComponent } from './fallback-property.component';
import { PatternEditorComponent } from './pattern-editor.component';
import { PropertyListElementComponent } from './property-list-element.component';
import { SelectionPropertyComponent } from './selection-property.component';
import { TextPropertyComponent } from './text-property.component';
import { PatternReferencePropertyComponent } from './pattern-reference/pattern-reference-property.component';
import { CreatePatternModule } from '../patterns/create-pattern/create-pattern.module';
import { AddedPatternListComponent } from './pattern-reference/added-pattern-list.component';
import { PatternReferencePropertyMainComponent } from './pattern-reference/pattern-reference-property-main.component';
import { DropdownListComponent } from './pattern-reference/dropdown-list-elements.component';
import { DeploymentHostWidgetComponent } from './deployment-host-widget/deployment-host-widget';
import { PatternMenuModule } from './pattern-menu/pattern-menu.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { SimpleTextareaPropertyComponent } from './simple-textarea-property.component';
import { PatternIssueListComponent } from './pattern-issue-list.component';
import { AttachmentPropertyListComponent } from './attachment-property-component/attachment-property-list.component';
import { AttachmentPropertyComponent } from './attachment-property-component/attachment-property.component';
import { SetVariableModule } from '../set-variable/set-variable.module';
import { PropertyListComponent } from './property-list/property-list.component';
import { PatternInfoPopoverComponent } from './pattern-info-popover.component';
import { PropertyExternalLinkListComponent } from './property-external-link-list.component';
import { PropertyIssuesComponent } from './property-issues.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatetimePropertyComponent } from './datetime-property.component';
import { ModSecurityRulePropertyComponent } from './modsecurityrule-property.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { WidgetComponent } from './widget.component';
import { PatternNotesWidgetComponent } from './pattern-note-widget/pattern-notes-widget';
import { PatternFileEditorDialogComponent } from './pattern-file-editor/pattern-file-editor-dialog.component';
import { KeyValuePropertyComponent } from './key-value-widget/key-value-property.component';

import '../../../node_modules/codemirror/mode/groovy/groovy.js';
import '../../../node_modules/codemirror/mode/xml/xml.js';
import '../../../node_modules/codemirror/mode/yaml/yaml.js';
import '../../../node_modules/codemirror/mode/javascript/javascript.js';
import '../../../node_modules/codemirror/addon/edit/matchbrackets.js';
import '../../../node_modules/codemirror/addon/display/placeholder.js';

@NgModule({
  imports: [
    CommonModules,
    CodemirrorModule,
    CreatePatternModule,
    MatChipsModule,
    PatternMenuModule,
    SetVariableModule,
    DragDropModule,
    MatTabsModule
  ],
  declarations: [
    WidgetComponent,
    FallbackPropertyComponent,
    DropdownListComponent,
    PatternEditorComponent,
    PropertyListElementComponent,
    SelectionPropertyComponent,
    TextPropertyComponent,
    PatternReferencePropertyComponent,
    AddedPatternListComponent,
    SimpleTextareaPropertyComponent,
    DatetimePropertyComponent,
    PatternReferencePropertyMainComponent,
    DeploymentHostWidgetComponent,
    PatternIssueListComponent,
    AttachmentPropertyListComponent,
    AttachmentPropertyComponent,
    PropertyListComponent,
    PatternInfoPopoverComponent,
    PropertyExternalLinkListComponent,
    PropertyIssuesComponent,
    ModSecurityRulePropertyComponent,
    PatternNotesWidgetComponent,
    PatternFileEditorDialogComponent,
    KeyValuePropertyComponent,
  ],
  exports: [
    PatternEditorComponent,
    PropertyListComponent,
    PatternReferencePropertyComponent,
    PatternReferencePropertyMainComponent,
    PropertyListElementComponent,
    DeploymentHostWidgetComponent,
    PatternNotesWidgetComponent
  ]
})
export class PropertyWidgetsModule {
}

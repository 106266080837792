import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { Inventory } from '../inventory.model';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { InventoryContext } from '../inventory.context';
import { catchError, delayWhen, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import { InventoryDeploymentHistoryContext } from '../inventory-deployment-history/inventory-deployment-history.context';
import { NavigationService } from '../../navbar/navigation.service';
import { InventoryService } from '../inventory.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DeployedHostError, DeploymentHostConnectionStatus, DeploymentHostStatus, DeploymentHostStatusResponseModel, DeploymentInstance, DeploymentInstanceActionModel } from './inventory-status-item.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { CapitalizeFirstPipe } from '../../common/pipes/capitalize-first.pipe';
import { DeploymentInstanceTableModel, inventoryInstanceColumns } from './inventory-status-table/inventory-deployment-instance-table.model';
import { HTTP_STATUS_INTERNAL_SERVER_ERROR } from '../../shared/http-status-codes.constants';
import { ErrorHelper } from '../../common/helpers/error.helper';
import { InventorySchemaTypeHelper } from '../../deployment-wizard/deployment-selection/inventory-list/inventory-schema-type.helper';
import { InventoryStatusHelper } from './inventory-status.helper';

@Component({
  selector: 'adm4-inventory-status-component',
  template: `
    <div class="full-height-flex">
      <adm4-inventory-header [currentInventory]='currentInventory$ | async'
                             [inventories]="inventories$ | async"
                             [inventoryValidationStatus]='inventoryValidationStatus$ | async'
                             [readOnly]="isCurrentInventoryReadonly$ | async"></adm4-inventory-header>
      <div *ngIf='currentInventory$ | async' class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content details-container'>
          <div class='full-height-flex'>
            <div class='section-title'>
              Deployed Instances
            </div>
            <div class='remaining-space-flex-content-wrapper'>
              <div class='remaining-space-flex-content'>
                <ng-container *ngIf='inventoryStatusList$ | async'>
                  <adm4-inventory-deployment-instance-table *ngIf='hasInventoryStatus(inventoryStatusList$ | async); else hostStatusWarnings'
                                                            [deploymentInstances]="inventoryStatusList$ | async"
                                                            [currentInventory]='currentInventory$ | async'
                                                            [hasDeployPermission]='hasDeployPermission$ | async'
                                                            [displayedColumns]='displayedColumns'
                                                            [deployedHostErrors]='deployedHostErrors'
                                                            (triggerDeployAction)='triggerAction($event)'
                  ></adm4-inventory-deployment-instance-table>
                  <ng-template #hostStatusWarnings>
                    <ng-container *ngIf='deployedInventoryError; else noDeployedInstance'>
                      {{deployedInventoryError}}
                    </ng-container>
                    <ng-template #noDeployedInstance>
                      This inventory has no deployed instances yet, since nothing was deployed into it.
                    </ng-template>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../../common/styles/component-specific/create-form.scss', '../../common/styles/component-specific/settings-details.scss'],
  providers: [InventoryDeploymentHistoryContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryStatusComponent implements OnInit, OnDestroy {

  currentInventory$: Observable<Inventory | undefined>;
  inventories$: Observable<Inventory[]>;
  inventoryStatusList$: Observable<DeploymentInstance[]>;
  inventoryValidationStatus$: Observable<ValidationStatus<InventoryValidationIssue> | null>;
  isCurrentInventoryReadonly$: Observable<boolean>;
  hasDeployPermission$: Observable<boolean>;
  displayedColumns: DeploymentInstanceTableModel[];
  deployedHostErrors: DeployedHostError[] = [];
  deployedInventoryError: string | undefined;
  readonly DEFAULT_CONVERT_ERROR_MESSAGE = 'It was not possible to convert the log message to whitelist rule.';
  readonly HOST_ACTION_ERROR_HOST_FIELD = 'HOST';
  timerSubject = new BehaviorSubject(true);
  private destroyed$: Subject<boolean> = new Subject();

  constructor(private inventoryDeploymentHistoryContext: InventoryDeploymentHistoryContext,
              private inventoryContext: InventoryContext,
              private inventoryService: InventoryService,
              private navigationService: NavigationService,
              private modalNotificationService: ModalNotificationService,
              private toastNotificationService: ToastNotificationService) {
    this.currentInventory$ = this.inventoryContext.currentInventory$;
    this.inventories$ = this.inventoryContext.inventories$.pipe(map(_.values));
    this.inventoryValidationStatus$ = this.inventoryContext.inventoryValidationStatus$;
    this.isCurrentInventoryReadonly$ = this.inventoryContext.isCurrentInventoryReadonly$;
    this.hasDeployPermission$ = this.inventoryContext.hasDeployPermission$;
    this.displayedColumns = inventoryInstanceColumns;
  }


  ngOnInit(): void {
    this.inventoryStatusList$ = this.initInventoryStatusList();
  }

  initInventoryStatusList(): Observable<DeploymentInstance[]> {
    return combineLatest([
      this.timerSubject,
      this.currentInventory$.pipe(
        distinctUntilChanged((prev: Inventory | undefined, curr: Inventory | undefined) => {
          const isSameInventory = _.isEqual(prev, curr);
          if (!isSameInventory) {
            this.timerSubject.next(false);
          }
          return isSameInventory;
        }))
    ]).pipe(
      delayWhen((timeDelayTrigger: [boolean, Inventory | undefined], index: number) => {
        const inventoryChangedWithinDelayTime = _.isEqual(timeDelayTrigger[0], false);
        return timer(index === 0 || inventoryChangedWithinDelayTime ? 0 : 3000);
      }),
      takeUntil(this.destroyed$),

      withLatestFrom(this.currentInventory$),
      map(([, currentInventory]: [[boolean, Inventory | undefined], Inventory | undefined]) => currentInventory),
      filter((inventory: Inventory) => !_.isNil(inventory) && InventorySchemaTypeHelper.isClassicDeployment(inventory.schemaType)),
      switchMap((inventory: Inventory) => this.inventoryService.getHostStatus(inventory.inventoryKey).pipe(map((hostResponse: DeploymentHostStatusResponseModel) => {
          this.deployedHostErrors = InventoryStatusHelper.hostResponseHasWarning(hostResponse) ? InventoryStatusHelper.unwrapHostWarnings(hostResponse) : [];
          this.deployedInventoryError = InventoryStatusHelper.hostResponseHasWarning(hostResponse) ? InventoryStatusHelper.unwrapInventoryWarnings(hostResponse, inventory.inventoryKey) : '';
          return hostResponse.items;
        }), map((hostStatusList: DeploymentHostStatus[]) => {
          return hostStatusList.reduce((deployedInstances: DeploymentInstance[], hostStatusItem: DeploymentHostStatus) => {
            if (_.isEqual(hostStatusItem.connection, DeploymentHostConnectionStatus.DOWN)) {
              return [...deployedInstances, {host: hostStatusItem.host}];
            }
            const singleHostInstanceList: DeploymentInstance[] = hostStatusItem.instances.map(instance => ({...instance, host: hostStatusItem.host}));
            return [...deployedInstances, ...singleHostInstanceList];
          }, []);
        }),
        tap(() => {
          this.timerSubject.next(true);
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          this.modalNotificationService.openErrorDialog({title: 'Cannot load deployment instances', description: `An error occurred while loading deployment instances of inventory ${inventory.inventoryKey}`});
          return of([]);
        }))), shareReplay(1));
  }

  hasInventoryStatus(inventoryStatusList: DeploymentInstance[]): boolean {
    return !_.isEmpty(inventoryStatusList);
  }

  triggerAction(deploymentInstanceActionModel: DeploymentInstanceActionModel): void {
    const capitalizeFirstPipe = new CapitalizeFirstPipe();
    const deploymentActionTitle = capitalizeFirstPipe.transform(deploymentInstanceActionModel.action) + ' instance';
    this.modalNotificationService.openConfirmDialog(
      {
        headerTitle: 'Warning',
        title: deploymentActionTitle,
        description: `You are about to ${deploymentInstanceActionModel.action.toLowerCase()} the ${deploymentInstanceActionModel.instance.instance} at host ${deploymentInstanceActionModel.instance.host} <br/><br/>
          Please note that the process may take few minutes. You cannot cancel the action nor initiate it again until the process is complete. <br/><br/>
          Do you want to continue?`
      }, {
        confirmButtonText: 'Continue'
      }
    ).afterClosed().pipe(switchMap((confirmed: boolean) => {
      if (confirmed) {
        return this.inventoryContext.inventoryKey$.pipe(
          take(1),
          filter((inventoryKey: string | null) => !_.isNil(inventoryKey)),
          map((inventoryKey: string) => {
            return this.inventoryService.triggerInventoryDeploymentAction(inventoryKey, deploymentInstanceActionModel).pipe((instanceActionResult) => {
                return instanceActionResult;
              },
              catchError((err: HttpErrorResponse) => {
                console.error(`Something went wrong while initiating instance action`, err);
                this.modalNotificationService.openErrorDialog({title: 'Could not initiate instance action', description: err?.error?.error?.detail});
                if (err.status === HTTP_STATUS_INTERNAL_SERVER_ERROR && ErrorHelper.hasSource(err, {FIELD: this.HOST_ACTION_ERROR_HOST_FIELD})) {
                  const hostActionError = {host: err.error.sources[this.HOST_ACTION_ERROR_HOST_FIELD], detail: ErrorHelper.getErrorDetail(err, this.DEFAULT_CONVERT_ERROR_MESSAGE), isError: true};
                  this.deployedHostErrors.push(hostActionError);
                }
                return of(null);
              })).subscribe(() => {
              this.toastNotificationService.showSuccessToast(`You have successfully initiated the ${deploymentInstanceActionModel.action.toLowerCase()} instance action.`, `${deploymentActionTitle}`);
            });
          }));
      }
      return of(false);
    })).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}

import { NgModule } from '@angular/core';
import { PublishProjectDialogComponent } from './publish-project-dialog.component';
import { PublishProjectComponent } from './publish-project.component';
import { PublishProjectChangesetComponent } from './publish-project-changeset/publish-project-changeset.component';
import { PublishChangesetItemComponent } from './publish-changeset-item/publish-changeset-item.component';
import { PublishProjectMainComponent } from './publish-project-main.component';
import { CommonModules } from '../../common/common.module';
import { ModalDialogModule } from '../../modal-dialog/modal-dialog.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PatternModule } from '../../patterns/pattern.module';
import { PatternDiffViewComponent } from './pattern-diff-view/pattern-diff-view.component';
import { PatternNameDiffComponent } from './pattern-diff-view/pattern-name-diff/pattern-name-diff.component';
import { SimplePatternFieldDiffComponent } from './pattern-diff-view/simple-pattern-field-diff/simple-pattern-field-diff.component';
import { DeploymentHostDiffComponent } from './pattern-diff-view/deployment-host-diff/deployment-host-diff.component';
import { PropertyWidgetsModule } from '../../property-widgets/property-widgets.module';
import { PropertyDiffComponent } from './pattern-diff-view/property-diff/property-diff.component';
import { VariablesDiffViewComponent } from './variables-diff-view/variables-diff-view.component';
import { VariableDiffComponent } from './variables-diff-view/variable-diff/variable-diff.component';
import { VariableModule } from '../../variables/variable.module';
import { BundlesDiffViewComponent } from './bundles-diff-view/bundles-diff-view.component';
import { TextDiffWrapperComponent } from './text-diff-wrapper/text-diff-wrapper.component';
import { PatternLinkDiffComponent } from './pattern-diff-view/pattern-link-diff/pattern-link-diff.component';
import { PatternLabelDiffComponent } from './pattern-diff-view/pattern-label-diff/pattern-label-diff.component';
import { PatternNotesDiffComponent } from './pattern-diff-view/pattern-notes-diff/pattern-notes-diff.component';
import { ProjectDiffViewComponent } from './project-diff-view/project-diff-view.component';

@NgModule({
  declarations: [
    PublishProjectMainComponent,
    PublishProjectDialogComponent,
    PublishProjectComponent,
    PublishProjectChangesetComponent,
    PublishChangesetItemComponent,
    PatternDiffViewComponent,
    PatternNameDiffComponent,
    PatternLabelDiffComponent,
    PatternLinkDiffComponent,
    SimplePatternFieldDiffComponent,
    DeploymentHostDiffComponent,
    PropertyDiffComponent,
    VariablesDiffViewComponent,
    VariableDiffComponent,
    BundlesDiffViewComponent,
    PatternNotesDiffComponent,
    TextDiffWrapperComponent,
    ProjectDiffViewComponent
  ],
  imports: [
    CommonModules,
    ModalDialogModule,
    MatSlideToggleModule,
    PatternModule,
    PropertyWidgetsModule,
    VariableModule
  ]
})
export class PublishProjectModule { }

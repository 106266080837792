import { TreeNode } from './tree-node.model';
import { TreeNodeTypeEnum } from './tree-node-type.enum';

export class TreeNodeHelper {
  static findNodeById(nodes: TreeNode[], nodeId: string): TreeNode | null {
    return nodes.reduce((foundNode: TreeNode, node: TreeNode) => {
      if (foundNode) {
        return foundNode;
      } else if (node.id === nodeId) {
        return node;
      } else if (node.children) {
        return this.findNodeById(node.children, nodeId);
      }
      return null;
    }, null);
  }

  static applyFiltering(nodes: Readonly<Array<Readonly<TreeNode>>>, shouldFilterOut: boolean): Array<TreeNode> {
    return nodes
      // calculating hasChangesOrIsImportantType for each node
      .map((node: Readonly<TreeNode>): TreeNode => {
        const hasChangesOrIsImportantType = TreeNodeHelper.hasNodeChangesOrIsImportantType(node);
        return {...node, hasChangesOrIsImportantType};
      })
      // keeping the node if we are not filtering the tree or if the node has changes or is of an important type
      .filter((node: Readonly<TreeNode>): boolean => !shouldFilterOut || !!node.hasChangesOrIsImportantType)
      // applying the filtering to the children
      .map((node: Readonly<TreeNode>): TreeNode => {
        const newChildren: TreeNode[] = this.applyFiltering(node.children, shouldFilterOut);
        return {...node, children: newChildren};
      });
  }

  static isPatternNode(node: TreeNode): boolean {
    return node.type === TreeNodeTypeEnum.Pattern || node.type === TreeNodeTypeEnum.DeployablePattern;
  }

  static isHostNode(node: TreeNode): boolean {
    return node.type === TreeNodeTypeEnum.Host;
  }

  static isChecksParentNode(node: TreeNode): boolean {
    return node.type === TreeNodeTypeEnum.CheckParent;
  }

  static isCheckNode(node: TreeNode): boolean {
    return node.type === TreeNodeTypeEnum.Check;
  }

  static nodeHasError(node: TreeNode): boolean {
    return node.nrErrors > 0;
  }

  static nodeHasOnlyWarnings(node: TreeNode): boolean {
    return node.nrErrors === 0 && node.nrWarnings > 0;
  }

  static nodeHasTask(node: TreeNode): boolean {
    return node.tasks.length > 0;
  }

  static hasNodeChangesOrIsImportantType(node: TreeNode): boolean {
    const visibilityCriteria: boolean[] = [
      this.nodeHasTask(node),
      node.hasDescendantTask,
      this.nodeHasError(node),
      this.nodeHasOnlyWarnings(node),
      this.isPatternNode(node),
      this.isHostNode(node),
      this.isChecksParentNode(node),
      this.isCheckNode(node)
    ];
    return visibilityCriteria.some(v=>v);
  }
}

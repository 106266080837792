import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileBasedImportInventoryContext } from './file-based-import-inventory.context';
import { Observable, Subject } from 'rxjs';
import { Tenant } from '../../tenant/tenant.model';
import { Inventory } from '../inventory.model';
import { InventoryFileImportPayload } from './inventory-file-import-payload.model';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';

@Component({
  selector: 'adm4-file-based-import-inventory-container',
  template: `
    <adm4-modal-dialog-title header='Import Inventory from Zip' [showClose]='true' (closeClicked)="closeDialog()">
      <adm4-file-based-import-inventory
              [selectedTenant]='currentTenant$ | async'
              [inventories]='inventories$ | async'
              [displayTenant]='displayTenant$ | async'
              (cancelClicked)='closeDialog()'
              (inventoryImport)='importInventory($event)'
      ></adm4-file-based-import-inventory>
    </adm4-modal-dialog-title>
  `,
  styleUrls: [],
  providers: [
    FileBasedImportInventoryContext
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileBasedImportInventoryContainerComponent implements OnDestroy {
  currentTenant$: Observable<Tenant>;
  inventories$: Observable<Inventory[]>;
  displayTenant$: Observable<boolean>;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<FileBasedImportInventoryContainerComponent>,
    private fileBasedImportInventoryContext: FileBasedImportInventoryContext
  ) {
    closeModalOnEscape(this.dialogRef, this.destroyed$);

    this.currentTenant$ = this.fileBasedImportInventoryContext.currentTenant$;
    this.inventories$ = this.fileBasedImportInventoryContext.inventories$;
    this.displayTenant$ = this.fileBasedImportInventoryContext.displayTenant$;
  }

  importInventory(inventoryFileImportPayload: InventoryFileImportPayload): void {
    this.fileBasedImportInventoryContext.importInventory(inventoryFileImportPayload);
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

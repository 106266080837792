import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ProjectComponent } from './project.component';
import { PatternMainComponent } from '../patterns/pattern-main.component';
import { VariablesMainComponent } from '../variables/variables-main.component';
import { PatternEditorDeactivationGuard } from '../patterns/pattern-editor-deactivation.guard';
import { VariableEditorDeactivationGuard } from '../variables/variable-editor-deactivation.guard';
import { AuthenticationGuard } from '../common/guards/authentication.guard';
import { IssuesMainComponent } from '../issues/issues-main.component';
import { VariableDetailsComponent } from '../variables/variable-details/variable-details.component';
import { ReportsMainComponent } from '../reports/reports-main/reports-main.component';
import { TenantGuard } from '../tenant/tenant.guard';
import { ProjectGuard } from './project.guard';
import { ReportGuard } from '../reports/report.guard';
import { VariableGuard } from '../variables/variable.guard';
import { ProjectSummaryReportTabApplicationsComponent } from './project-summary/project-summary-report/project-summary-report-tab-applications/project-summary-report-tab-applications.component';
import { ProjectSummaryReportTabRealmsComponent } from './project-summary/project-summary-report/project-summary-report-tab-realms/project-summary-report-tab-realms.component';
import { ProjectSummaryReportTabInstancesComponent } from './project-summary/project-summary-report/project-summary-report-tab-instances/project-summary-report-tab-instances.component';
import { ProjectSummaryReportTabSummaryComponent } from './project-summary/project-summary-report/project-summary-report-tab-summary/project-summary-report-tab-summary.component';
import { NavigationConstants } from '../common/constants/navigation.constants';
import { IssueGuard } from '../variables/issue.guard';
import { ProjectSettingsMainComponent } from '../project-settings/project-settings-main/project-settings-main.component';
import { ProjectSettingsGuard } from '../resources/project-settings.guard';

const OVERVIEW_SUMMARY_ROUTE = 'overview/summary';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'projects',
        component: ProjectComponent,
        canActivate: [AuthenticationGuard, TenantGuard, ProjectGuard]
      },
      {
        path: 'projects/:projectKey',
        component: ProjectComponent,
        canActivateChild: [AuthenticationGuard, TenantGuard, ProjectGuard],
        children: [
          {
            path: '',
            redirectTo: OVERVIEW_SUMMARY_ROUTE,
            pathMatch: 'full',
          },
          {
            path: 'patterns',
            redirectTo: OVERVIEW_SUMMARY_ROUTE
          },
          {
            path: 'overview',
            redirectTo: OVERVIEW_SUMMARY_ROUTE
          },
          {
            path: 'overview',
            component: PatternMainComponent,
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
            data: {
              reuse: 'reusePatternMainComponentsFilterContent'
            },
            children: [
              {
                path: NavigationConstants.OVERVIEW_APPLICATIONS,
                component : ProjectSummaryReportTabApplicationsComponent,
              },
              {
                path: NavigationConstants.OVERVIEW_REALMS,
                component : ProjectSummaryReportTabRealmsComponent,
              },
              {
                path: NavigationConstants.OVERVIEW_INSTANCES,
                component : ProjectSummaryReportTabInstancesComponent
              },
              {
                path: 'summary',
                component : ProjectSummaryReportTabSummaryComponent
              },
            ]
          },
          {
            path: 'patterns/:patternId',
            component: PatternMainComponent,
            canDeactivate: [PatternEditorDeactivationGuard],
            runGuardsAndResolvers: 'paramsChange',
            data: {
              reuse: 'reusePatternMainComponentsFilterContent'
            }
          },
          {
            path: 'variables',
            component: VariablesMainComponent,
            canActivate: [VariableGuard],
            children: [{
                path: ':variableKey',
                component: VariableDetailsComponent,
                canDeactivate: [VariableEditorDeactivationGuard],
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'}
            ]
          },
          {
            path: 'issues',
            component: IssuesMainComponent,
            canActivate: [IssueGuard]
          },
          {
            path: 'reports',
            component: ReportsMainComponent,
            canActivate: [ReportGuard]
          },
          {
            path: 'reports/:reportClass',
            component: ReportsMainComponent,
            canActivate: [ReportGuard]
          },
          {
            path: 'project-settings',
            component: ProjectSettingsMainComponent,
            canActivate: [ProjectSettingsGuard],
          },
        ]
      }
    ])
  ],
  providers: [
    PatternEditorDeactivationGuard,
    VariableEditorDeactivationGuard
  ]
})
export class ProjectRoutingModule {
}

import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VariableListItem } from '../variable.model';
import { PropertyType } from '../../plugins/property-type.model';
import { Dictionary } from '../../model/reducer';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import * as _ from 'lodash';
import { ListRange } from '@angular/cdk/collections';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScrollService } from '../../common/services/scroll/scroll.service';

@Component({
  selector: 'adm4-variable-list',
  template: `
      <cdk-virtual-scroll-viewport [itemSize]="40" class='gkz-viewport cdk-virtual-scroll-width-100'>
        <adm4-variable-list-element *cdkVirtualFor='let variableListItem of filteredVariables;trackBy: trackByFn'
                                    [selected]="selected(variableListItem)"
                                    [variable]='variableListItem.variable'
                                    [textToHighLight]='textToHighLight'
                                    [scrollArea]='scrollArea'
                                    [issues]='variableListItem.issues'
                                    [projectKey]='projectKey'
                                    (triggerScroll)='scrollToSelectedVariable()'></adm4-variable-list-element>
      </cdk-virtual-scroll-viewport>
    `,
  styleUrls: ['variable-list.scss']
})
export class VariableListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() variableListItems: VariableListItem[];
  @Input() variableTypes: Dictionary<PropertyType>;
  @Input() filteredVariables: VariableListItem[];
  @Input() textToHighLight: string;
  @Input() projectKey: string;
  @Input() selectedVariableKey: string;
  @Input() public scrollArea: any;
  @ViewChild(CdkVirtualScrollViewport, {static: true}) viewPort: CdkVirtualScrollViewport;
  private renderedElementsRange: ListRange;
  private destroyed$: Subject<boolean> = new Subject();

  ngOnInit(): void {
    this.viewPort.renderedRangeStream.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((range: ListRange) => {
      this.renderedElementsRange = range;
    });
  }

  selected(variableListItem): boolean {
    return variableListItem.variable.variableKey === this.selectedVariableKey;
  }

  trackByFn(_index, variable: VariableListItem): string {
    return variable.variable.variableKey;
  }

  private getIndexOfSelectedVariable() {
    return _.findIndex(this.filteredVariables, (line: VariableListItem) => {
      return line.variable.variableKey === this.selectedVariableKey;
    });
  }

  ngOnChanges(): void {
    this.scrollToSelectedVariableIfThatIsNotRendered();
  }

  private scrollToSelectedVariableIfThatIsNotRendered() {
    const index = this.getIndexOfSelectedVariable() || 0;

    // If the element not in the renderedElementsRange, then we are sure that it is not visible
    if (!ScrollService.isIndexWithinRenderedElement(index, this.renderedElementsRange)) {
      this.scrollToSelectedVariable();
    } else {
      // if the element is in the rendered range, then it still not necessarily mean that it is visible, so let the child decide if scrolling is required
    }
  }

  public scrollToSelectedVariable() {
    const index = this.getIndexOfSelectedVariable();
    this.viewPort.scrollToIndex(index);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.scrollToSelectedVariable());
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}

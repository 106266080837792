import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PluginModel } from '../../plugins/plugin.model';
import { Inventory } from '../../inventory/inventory.model';
import { ReportsContext } from '../reports.context';

@Component({
  selector: 'adm4-report-details',
  template: `
    <div class='full-height-flex'>
      <ng-container *ngIf='projectKey && report'>
        <adm4-column-header styleClass='nevis-green-header flex-header-stretched'>
          <div class='wrapper full-width'>
           {{report.name}}
          </div>
        </adm4-column-header>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content report-details-container'>
            <adm4-report-generation
                    [projectKey]='projectKey'
                    [reportClassName]='report.className'
                    [inventories]='inventories'
                    [lastReportInfo]='reportsContext.lastReportInfo$ | async'
            ></adm4-report-generation>
          </div>
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./report-details.component.scss']
})
export class ReportDetailsComponent implements OnChanges {
  @Input() projectKey: string;
  @Input() report: PluginModel;
  @Input() inventories: Inventory[];

  constructor(public reportsContext: ReportsContext) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.report && this.report) {
      this.reportsContext.selectReport(this.report.className);
    }
  }

}

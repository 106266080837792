import { EventEmitter, FactoryProvider, InjectionToken } from '@angular/core';

export const TREE_EXPAND: InjectionToken<string> = new InjectionToken('treeExpand');

export function expandEventFactory(): EventEmitter<void> {
  return new EventEmitter();
}

/**
 * Provider for event emitter to connect expand all files functionality and tree
 * Add to component providers where this feature is needed
 */
export const treeExpandProvider: FactoryProvider = {provide: TREE_EXPAND, useFactory: expandEventFactory};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { allInventoriesView, inventoryKeyView } from '../../model/views';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppState, Dictionary } from '../../model/reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { Inventory, InventoryMeta } from '../inventory.model';
import { PublishInventoryChangesetHelper } from './publish-inventory-changeset.helper';
import { InventoryService } from '../inventory.service';
import { LoadInventoryMetaSuccess } from '../../model/publish-inventory/publish-inventory.actions';

@Injectable({providedIn: 'root'})
export class InventoryHasUnpublishedChangesGuard  {

  readonly ERROR_MODAL_TITLE = 'Could not open publish changes';

  constructor(private store$: Store<AppState>,
              private modalNotificationService: ModalNotificationService,
              private inventoryService: InventoryService
  ) {
  }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return forkJoin([
      this.store$.pipe(select(inventoryKeyView), first(inventoryKey => !_.isNil(inventoryKey))),
      this.store$.pipe(select(allInventoriesView), first(inventories => !_.isEmpty(inventories)))
    ]).pipe(
      switchMap(([inventoryKey, inventories]: [string, Dictionary<Inventory>]) => {
        if (_.isNil(inventoryKey) || _.isNil(inventories[inventoryKey])) {
          this.modalNotificationService.openErrorDialog({title: this.ERROR_MODAL_TITLE, description: 'Either no inventory is selected or selected inventory does not exist'});
          return of(false);
        }
        if (_.isNil(inventories[inventoryKey].repository)) {
          this.modalNotificationService.openErrorDialog({title: this.ERROR_MODAL_TITLE, description: 'Inventory is not connected to version control'});
          return of(false);
        }
        return this.inventoryService.getInventoryMeta(inventoryKey).pipe(
          map((inventoryMeta: InventoryMeta) => {
            this.store$.dispatch(new LoadInventoryMetaSuccess(inventoryMeta));
            const hasLocalChanges = PublishInventoryChangesetHelper.hasLocalChanges(inventoryMeta);
            if (!hasLocalChanges) {
              this.modalNotificationService.openInfoDialog({title: 'No pending changes found', description: 'There are currently no changes in the inventory'});
            }
            return hasLocalChanges;
          }),
          catchError((err: HttpErrorResponse) => {
            this.modalNotificationService.openErrorDialog({title: 'Could not open publish changes', description: 'Something went wrong with fetching inventory status'});
            console.error(err);
            return of(false);
          })
        );
      })
    );
  }
}

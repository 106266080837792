import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { NavigationService } from '../../navbar/navigation.service';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { isAuthExpiredView } from '../../model/views';

@Injectable()
export class AuthenticationDeactivationGuard  {

  constructor(private navigationService: NavigationService, private store$: Store<AppState>, public notificationService: ModalNotificationService) {
  }

  canDeactivate(): Observable<boolean> {
    return this.store$.pipe(select(isAuthExpiredView)).pipe(
      map((isExpired) => {
        if (isExpired) {
          setTimeout(() => {
            this.notificationService.openWarningDialog({title: 'Your session has ended', description: 'Your session expired, or you logged out in another browser tab. <br/>Please log in again to continue working.\n'});
            this.navigationService.navigateAwayFromModalWindow();
          });
        }
        return true;
      })
    );
  }
}

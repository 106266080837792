export enum OperationKey {
  // project operations
  VIEW_PROJECT = 'VIEW_PROJECT',
  MODIFY_PROJECT = 'MODIFY_PROJECT',
  ADMIN_PROJECT = 'ADMIN_PROJECT',
  // inventory operations
  VIEW_INVENTORY = 'VIEW_INVENTORY',
  MODIFY_INVENTORY = 'MODIFY_INVENTORY',
  ADMIN_INVENTORY = 'ADMIN_INVENTORY',
  DEPLOY_INVENTORY = 'DEPLOY_INVENTORY',
  CREATE_PROJECT = 'CREATE_PROJECT',
  CREATE_INVENTORY = 'CREATE_INVENTORY',
  VIEW_SECRET_CONTENT_TENANT = 'VIEW_SECRET_CONTENT_TENANT',
  VIEW_SECRET_CONTENT_INVENTORY = 'VIEW_SECRET_CONTENT_INVENTORY',
  MODIFY_TENANT = 'MODIFY_TENANT',
  // super admin permission
  SUPER_ADMIN = 'SUPER_ADMIN',

  CREATE_USER = 'CREATE_USER',
  MODIFY_USER = 'MODIFY_USER',

  ADMIN_TENANT = 'ADMIN_TENANT',
}

export interface OperationKeyLabel {
  label: string;
  infoTooltip: string;
}

export const OperationKeyLabels: Readonly<{ [key in OperationKey]: OperationKeyLabel}> = {
// global only
  [OperationKey.SUPER_ADMIN]: {label: 'Super admin', infoTooltip: 'Grants permissions to all other REST APIs which are not protected by undermentioned permissions, e.g. Upload new libraries, Create tenant, Manage groups, etc.'},
  [OperationKey.CREATE_USER]: {label: 'Create user', infoTooltip: 'Can create a local user.'},
  [OperationKey.MODIFY_USER]: {label: 'Modify user details', infoTooltip: 'Can modify the user details.'},
// tenant
  [OperationKey.ADMIN_TENANT]: {label: 'Administrate tenant permissions', infoTooltip: `Can assign the following tenant permissions to other users:
<ul>
<li>Administrate tenant permissions</li>
<li>Manage global resources</li>
<li>View global secret content</li>
<li>Create project</li>
<li>Create inventory</li>
</ul>`},
  [OperationKey.MODIFY_TENANT]: {label: 'Manage global resources', infoTooltip: 'Can create, update and delete tenant (global) constants, secrets and files.'},
  [OperationKey.VIEW_SECRET_CONTENT_TENANT]: {label: 'View global secret content', infoTooltip: 'Can view the content of tenant secrets.'},
  [OperationKey.CREATE_PROJECT]: {label: 'Create project', infoTooltip: 'Can create a project.'},
  [OperationKey.CREATE_INVENTORY]: {label: 'Create inventory', infoTooltip: 'Can create an inventory.'},
  // project
  [OperationKey.ADMIN_PROJECT]: {label: 'Administrate project', infoTooltip: 'Can assign project permissions to other users.'},
  [OperationKey.MODIFY_PROJECT]: {label: 'Modify project', infoTooltip: 'Can modify the project.'},
  [OperationKey.VIEW_PROJECT]: {label: 'View project', infoTooltip: 'Can view the project.'},
// inventory
  [OperationKey.ADMIN_INVENTORY]: {label: 'Administrate inventory', infoTooltip: 'Can assign inventory permissions to other users.'},
  [OperationKey.DEPLOY_INVENTORY]: {label: 'Deploy inventory', infoTooltip: 'Can make a deployment with the inventory.'},
  [OperationKey.MODIFY_INVENTORY]: {label: 'Modify inventory', infoTooltip: 'Can modify the inventory.'},
  [OperationKey.VIEW_INVENTORY]: {label: 'View inventory', infoTooltip: 'Can view the inventory.'},
  [OperationKey.VIEW_SECRET_CONTENT_INVENTORY]: {label: 'View inventory secret content', infoTooltip: 'Can view the content of inventory secrets.'},
} as const;

export const globalPermissions: Readonly<Array<OperationKey>> = [OperationKey.SUPER_ADMIN, OperationKey.CREATE_USER, OperationKey.MODIFY_USER] as const;

export const tenantPermissions: Readonly<Array<OperationKey>> = [OperationKey.ADMIN_TENANT, OperationKey.MODIFY_TENANT, OperationKey.VIEW_SECRET_CONTENT_TENANT, OperationKey.CREATE_PROJECT, OperationKey.CREATE_INVENTORY] as const;

export const projectPermissions: Readonly<Array<OperationKey>> = [OperationKey.ADMIN_PROJECT, OperationKey.MODIFY_PROJECT, OperationKey.VIEW_PROJECT] as const;

export const inventoryPermissions: Readonly<Array<OperationKey>> = [OperationKey.ADMIN_INVENTORY, OperationKey.DEPLOY_INVENTORY, OperationKey.MODIFY_INVENTORY, OperationKey.VIEW_INVENTORY, OperationKey.VIEW_SECRET_CONTENT_INVENTORY] as const;

export const filterOpKeysForGlobal = (opKeys: OperationKey[]) => opKeys.filter((opKey: OperationKey) => globalPermissions.includes(opKey));
export const filterOpKeysForTenant = (opKeys: OperationKey[]) => opKeys.filter((opKey: OperationKey) => tenantPermissions.includes(opKey));
export const filterOpKeysForProject = (opKeys: OperationKey[]) => opKeys.filter((opKey: OperationKey) => projectPermissions.includes(opKey));
export const filterOpKeysForInventory = (opKeys: OperationKey[]) => opKeys.filter((opKey: OperationKey) => inventoryPermissions.includes(opKey));

/**
 * Entity that can have a permission in this case it's either user or group
 * assignee field contains assignee name having prefix user:// for users and group:// for groups
 */
export interface PermissionAssignee {
  /** The user or group. */
  assignee: string;
  assigneeName?: string;
  // FIXME NEVISADMV4-8860 where does this field come from?
  permissions: Permission[];
}

/**
 * Shows whether the permission is assigned directly to the user (`USER_DIRECT`) or via a group (`GROUP_DIRECT`)
 */
export enum PermissionType {
  USER_DIRECT = 'USER_DIRECT',
  GROUP_DIRECT = 'GROUP_DIRECT',
}

export interface AssignedPermission {
  assignee: string;
  assigneeName?: string;
  permission: Permission;
  permissionType: PermissionType;
}

export interface Permission {
  /** For example: '/' or '/tenants/DEV/projects/DEV-EXPERIMENTAL-MASTER' */
  target: string;
  operationKey: OperationKey;
}

export interface PermissionWithTypeModel extends Permission {
  permissionType: PermissionType;
}
export const assignedPermissionToModel = (ap: AssignedPermission): PermissionWithTypeModel =>
    ({...ap.permission, permissionType: ap.permissionType});

export const permissionByOperationKey = (operationKeyFilter: OperationKey) =>
    (permission: Permission) => permission.operationKey === operationKeyFilter;

export const permissionByTarget = (targetFilter: string) =>
    (permission: Permission) => permission.target === targetFilter;

export interface PermissionSavePayload {
  assignee: string;
  addPermissions: Permission[];
  removePermissions: Permission[];
}

export interface PermissionModificationPayload {
  permissionAssignee: PermissionAssignee;
  operationKey: OperationKey;
  checked: boolean;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalToPercents'
})
export class DecimalToPercentsPipe implements PipeTransform {

  transform(value: number): string {
    const percentage = Math.round(value * 100);
    return `${percentage}%`;
  }

}

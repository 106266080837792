import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TextEditorDialogComponent, TextEditorDialogPayload } from './text-editor-dialog.component';
import { WhitelistConverterDialogComponent } from './whitelist-converter-dialog.component';
import { WhitelistConverterDialogPayload } from './whitelist-converter-dialog.payload';
import { Maybe } from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class TextEditorDialogService {

  constructor(private dialog: MatDialog) {}

  openTextEditorDialog(content: string, syntax?: Maybe<string>): MatDialogRef<TextEditorDialogComponent, string> {
    return this.dialog.open<TextEditorDialogComponent, TextEditorDialogPayload, string>(TextEditorDialogComponent, {
      panelClass: 'big-dialog',
      disableClose: true,
      autoFocus: false,
      data: {content, syntax},
    });
  }

  openWhitelistConverterDialog(propertyKey: string, content: string): MatDialogRef<WhitelistConverterDialogComponent> {
    return this.dialog.open<WhitelistConverterDialogComponent, WhitelistConverterDialogPayload>(WhitelistConverterDialogComponent, {
      width: '60%',
      height: '1000',
      disableClose: true,
      autoFocus: false,
      data: {
        propertyKey: propertyKey,
        content: content
      }
    });
  }
}

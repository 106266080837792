import { AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[adm4AfterViewInit]'
})
export class AfterViewInitDirective implements AfterViewInit {
  @Output() adm4AfterViewInit: EventEmitter<void> = new EventEmitter(true);

  ngAfterViewInit() {
    this.adm4AfterViewInit.emit();
  }
}

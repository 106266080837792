import { NgModule } from '@angular/core';
import { IssuesMainComponent } from './issues-main.component';
import { CommonModules } from '../common/common.module';
import { ProjectHeaderModule } from '../projects/project-header/project-header.module';
import { IssueListComponent } from './issue-list/issue-list.component';
import { PatternIssueSeverityGroupsComponent } from './pattern-issue-severity-groups-list/pattern-issue-severity-groups.component';
import { PatternIssueSeverityGroupComponent } from './pattern-issues-severity-group/pattern-issue-severity-group.component';
import { IssuesHelpComponent } from './issues-help/issues-help.component';
import { PropertyWidgetsModule } from '../property-widgets/property-widgets.module';
import {PatternIssueGroupModule} from './pattern-issue-group/pattern-issue-group-module';
import {IssueGroupListComponent} from './issue-group-list/issue-group-list.component';
import { GeneralIssueListComponent } from './general-issue-list/general-issue-list.component';

@NgModule({
  imports: [
    CommonModules,
    ProjectHeaderModule,
    PropertyWidgetsModule,
    PatternIssueGroupModule
  ],
  declarations: [
    IssuesMainComponent,
    IssueListComponent,
    IssueGroupListComponent,
    PatternIssueSeverityGroupsComponent,
    PatternIssueSeverityGroupComponent,
    IssuesHelpComponent,
    GeneralIssueListComponent
  ],
  exports: [
    GeneralIssueListComponent
  ]
})
export class IssuesModule {

}

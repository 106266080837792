import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AppState } from '../../model/reducer';
import { isAuthDoneView, isAuthenticatedView } from '../../model/views';
import { NavigationService } from '../../navbar/navigation.service';

@Injectable()
export class LoginGuard  {
  constructor(private store$: Store<AppState>, private navigationService: NavigationService) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([
      this.store$.pipe(select(isAuthenticatedView)),
      this.store$.pipe(select(isAuthDoneView))]
    ).pipe(
      filter(([_isAuthenticated, isAuthDone]) => isAuthDone),
      map(([isAuthenticated]) => {
        if (isAuthenticated) {
          setTimeout(() => this.navigationService.navigateToReturnUrl());
        }
        return !isAuthenticated;
      })
    );
  }
}

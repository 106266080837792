import { Pipe, PipeTransform } from '@angular/core';
import { PermissionAssignee } from '../model/permissions/permissions.model';
import { PermissionsHelper } from './permissions.helper';

@Pipe({
  name: 'permissionAssigneeUsername'
})
export class PermissionAssigneeUsernamePipe implements PipeTransform {

  transform(permissionAssignee: PermissionAssignee): string {
    return PermissionsHelper.getAssigneeUsername(permissionAssignee);
  }

}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReportsContext } from '../reports.context';
import { NavigationService } from '../../navbar/navigation.service';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-report-generation-output',
  template: `
    <div class='generation-output-container'>
      <ng-container *ngIf='reportsContext.showGenerationOutput$ | async'>
        <div [innerHtml]='reportsContext.generationOutput$ | async' *ngIf="isSafeHtml(reportsContext.generationOutput$ | async) ; else show_in_iframe"></div>
        <ng-template #show_in_iframe>
          <iframe [srcdoc]="reportsContext.generationOutput$ | async | reportsHtml" style="display: block; width: 100%; height: 100%; border: none;"></iframe>
        </ng-template>
      </ng-container>
      <div class="content" *ngIf='reportsContext.notGenerated$ | async'>Please generate a report</div>
      <div class="content" *ngIf='reportsContext.isGenerationInProgress$ | async'>
        <span class='generation-in-progress'><mat-spinner [diameter]='50'></mat-spinner></span>
        <span>Generating report - Inventory: {{inventoryKey}}</span>
      </div>
      <div class="content failed" *ngIf='reportsContext.isGenerationFailed$ | async'>
        <p>The report could not be generated.</p>
        <p>Please check the project for issues. Also, you can go to step 2 in the <a href='javascript:void(0)'
                                                                                     (click)='onOpenDeploymentWizard()'>
          deployment wizard</a> to validate the project against the selected inventory.</p>
      </div>
      <div class="content failed" *ngIf='reportsContext.isNothingToDo$ | async'>
        <p>The report could not be generated.</p>
        <p>Please make sure that the project has at least an instance pattern to be able to generate the
          report.</p>
      </div>
    </div>
  `,
  styleUrls: ['report-generation-output.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportGenerationOutputComponent {
  @Input() inventoryKey: string;


  constructor(public reportsContext: ReportsContext, private navigationService: NavigationService) {
  }

  onOpenDeploymentWizard() {
    this.navigationService.navigateToDeploymentWizard();
  }

  isSafeHtml(html: string) {
    return !_.includes(html, '<script');
  }
}

import { Component, Input } from '@angular/core';
import { InventoryVariable } from '../variable-details/variable-details.model';

@Component({
  selector: 'adm4-inventory-variable',
  template: `
    <label class='full-width inventory-variable'>
      <span *ngIf='inventoryVariable.sourceName' class='inventory-variable-source'>{{inventoryVariable.sourceName}}</span>
      <textarea adm4Autosize [data]='inventoryVariable.value' class='admn4-textarea-input full-width form-control' [value]='inventoryVariable.value' readonly></textarea>
    </label>

  `,
  styleUrls: ['./inventory-variable.component.scss']
})
export class InventoryVariableComponent {
  @Input() inventoryVariable: InventoryVariable;
}

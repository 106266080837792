import { Component } from '@angular/core';
import { FileDetailsComponent } from '../../deployment-preview-details/file-details/file-details.component';

@Component({
  selector: 'adm4-k8s-custom-resource-details',
  templateUrl: './k8s-custom-resource-details.component.html',
  styleUrls: ['../../item-details.scss']
})
export class K8sCustomResourceDetailsComponent extends FileDetailsComponent {
}

import { Project } from '../project.model';
import * as _ from 'lodash';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { VersionControlData } from '../../common/model/version-control-data.model';

export interface IPredefinedProjectFormDataMixin {
  predefinedProjectData: Project | null;
  selectedTenantKey: string;
  initialProjectKeyFormValue: () => string;
  initialNewBranchNameValue?: () => string;
  predefinedVersionControlData: () => VersionControlData | null;
}

export class PredefinedProjectFormDataMixin implements IPredefinedProjectFormDataMixin {
  predefinedProjectData: Project | null;
  selectedTenantKey: string;

  initialProjectKeyFormValue(): string {
    return _.isNil(this.predefinedProjectData) ? '' : TenantHelper.cropTenantFromKey(this.predefinedProjectData.projectKey);
  }

  predefinedVersionControlData(): VersionControlData | null {
    return _.isNil(this.predefinedProjectData) ? null : {
      repository: this.predefinedProjectData.repository,
      branch: this.predefinedProjectData.branch,
      path: this.predefinedProjectData.path
    };
  }
}

export class PredefinedBranchProjectFormDataMixin extends PredefinedProjectFormDataMixin {
  override predefinedProjectData: Project | null;
  override selectedTenantKey: string;

  override initialProjectKeyFormValue(): string {
    return super.initialProjectKeyFormValue();
  }

  initialNewBranchNameValue(): string {
    return _.isNil(this.predefinedProjectData) || _.isNil(this.predefinedProjectData.branch) ? '' : this.predefinedProjectData.branch;
  }

  override predefinedVersionControlData(): VersionControlData | null {
    return _.isNil(this.predefinedProjectData) ? null : {
      repository: this.predefinedProjectData.repository,
      branch: this.predefinedProjectData.branchFrom,
      path: this.predefinedProjectData.path
    };
  }
}

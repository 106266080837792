import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PluginModel } from '../../../plugins/plugin.model';
import { PluginHelper } from '../../../plugins/plugin.helper';
import { CreatePatternService } from '../../../patterns/create-pattern/create-pattern.service';
import { NavigationService } from '../../../navbar/navigation.service';
import { ProjectContext } from '../../project.context';
import { Observable } from 'rxjs/internal/Observable';
import { select, Store } from '@ngrx/store';
import { pluginDocView } from '../../../model/views';
import { AppState, Dictionary } from '../../../model/reducer';
import { LoadPluginDocForPatternTypes } from '../../../model/actions';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-project-summary-empty',
  templateUrl: './project-summary-empty.component.html',
  styleUrls: ['./project-summary-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryEmptyComponent implements OnChanges {

  @Input() currentProjectKey: string;
  @Input() pluginsByPatternType: PluginModel[];
  pluginDocView$: Observable<Dictionary<string | null>>;

  pluginsForCreateLinks: PluginModel[];

  constructor(private createPatternService: CreatePatternService,
              private navigationService: NavigationService,
              private projectContext: ProjectContext,
              private store$: Store<AppState>) {
    this.pluginDocView$ = this.store$.pipe(select(pluginDocView));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pluginsByPatternType']) {
      this.pluginsForCreateLinks = PluginHelper.createPluginsForCreateLinks(this.pluginsByPatternType);
      this.store$.dispatch(new LoadPluginDocForPatternTypes(this.pluginsForCreateLinks));
    }
  }

  getDocumentation(documentations: Dictionary<string | null>, className: string): string {

    const fullProjectKey = this.currentProjectKey + className;

    const documentation = documentations[fullProjectKey];
    return !_.isNil(documentation) ? this.sliceStringSnippet(documentation, '<section', '</section>') : '';
  }

  sliceStringSnippet (document: string, startTag: string, endTag: string): string {
    return document.slice(document.indexOf(startTag), document.indexOf(endTag) + endTag.length + 1);
  }

  openDeployModalDialog(): void {
    this.navigationService.navigateToDeploymentWizard();
  }

  openCreatePatternModalDialog(): void {
    this.createPatternService.openAddPatternWindowThenNavigate(this.pluginsByPatternType, this.currentProjectKey);
  }

  createPattern(plugin: PluginModel): void {
    this.createPatternService.createPattern(plugin, this.currentProjectKey);
  }

  navigateToInventories(): void {
    this.navigationService.navigateToInventories();
  }

  get isProjectReadOnly(): Observable<boolean> {
    return this.projectContext.isProjectReadOnly$;
  }
}

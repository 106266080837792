import { NevisAdminAction } from '../actions';
import { VariableModel } from '../../variables/variable.model';
import { RenameVariablePayload, UpdateAndRenameVariablePayload, UpdateVariablesPayload } from './update-variables-payload.model';

export enum VariableActionTypes {
  LoadVariables = '[Variables] Load Variables',
  LoadVariablesSuccess = '[Variables] Load Variables Success',
  UpdateVariables = '[Variables] Update Variables',
  UpdateVariablesSuccess = '[Variables] Update Variables Success',
  RenameVariable = '[Variables] Rename Variable',
  UpdateAndRenameVariable = '[Variables] Update and Rename Variable',
  DeleteVariable = '[Variables] Delete Variable',
  DeleteVariableSuccess = '[Variables] Delete Variable Success',
  CreateVariable = '[Variables] Create Variable',
  CreateVariableSuccess = '[Variables] Create Variable Success'
}

export class LoadVariables implements NevisAdminAction<string> {
  readonly type = VariableActionTypes.LoadVariables;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class LoadVariablesSuccess implements NevisAdminAction<VariableModel[]> {
  readonly type = VariableActionTypes.LoadVariablesSuccess;

  constructor(public payload: VariableModel[]) {}
}

export class UpdateVariables implements NevisAdminAction<UpdateVariablesPayload> {
  readonly type = VariableActionTypes.UpdateVariables;

  constructor(public payload: UpdateVariablesPayload) {}
}

export class UpdateVariablesSuccess implements NevisAdminAction<VariableModel[]> {
  readonly type = VariableActionTypes.UpdateVariablesSuccess;

  constructor(public payload: VariableModel[]) {}
}

export class RenameVariable implements NevisAdminAction<RenameVariablePayload> {
  readonly type = VariableActionTypes.RenameVariable;

  constructor(public payload: RenameVariablePayload) {}
}

export class DeleteVariable implements NevisAdminAction<UpdateVariablesPayload> {
  readonly type = VariableActionTypes.DeleteVariable;

  constructor(public payload: UpdateVariablesPayload) {}
}

export class DeleteVariableSuccess implements NevisAdminAction<VariableModel[]> {
  readonly type = VariableActionTypes.DeleteVariableSuccess;

  constructor(public payload: VariableModel[]) {}
}

export class CreateVariable implements NevisAdminAction<VariableModel[]> {
  readonly type = VariableActionTypes.CreateVariable;

  constructor(public payload: VariableModel[]) {}
}

export class CreateVariableSuccess implements NevisAdminAction<VariableModel[]> {
  readonly type = VariableActionTypes.CreateVariableSuccess;

  constructor(public payload: VariableModel[]) {}
}

export class UpdateAndRenameVariable implements NevisAdminAction<UpdateAndRenameVariablePayload> {
  readonly type = VariableActionTypes.UpdateAndRenameVariable;

  constructor(public payload: UpdateAndRenameVariablePayload) {}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PermissionsHelper } from './permissions.helper';
import { PermissionAssignee, PermissionModificationPayload, PermissionSavePayload } from '../model/permissions/permissions.model';
import { OperationColumnData } from './model/operation-column-data.model';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-select-permissions-dialog',
  template: `
    <div class="full-height-flex">
      <div class='remaining-space-flex-content-wrapper'>
        <div class="remaining-space-flex-content">
          <div class="permissions-dialog-container full-height-flex">
            <div class="filter-input-container">
              <adm4-filter (filter)="filterText = $event"
                           [placeholderText]='"Find permissions..."'
                           [filterText]="filterText"
                           class='search-input'>
              </adm4-filter>
            </div>
            <div class='remaining-space-flex-content-wrapper'>
              <div class='remaining-space-flex-content project-permissions-table-wrapper'>
                <adm4-permissions-table
                        [editMode]="true"
                        [permissionAssignees]="permissionAssignees"
                        [operationColumns]='operationColumns'
                        [filterText]="filterText"
                        [target]="target"
                        (permissionCheck)='onPermissionModification($event)'
                ></adm4-permissions-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button class='admn4-button-text' (click)='onCancel()'>Cancel</button>
        <button (click)="saveChanges()" [disabled]='!hasModification' class='admn4-button-ellipse-blue'>
          Save changes
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./select-permissions-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectPermissionsDialogComponent {

  @Input() target: string;
  @Input() operationColumns: OperationColumnData[];
  @Input() permissionAssignees: PermissionAssignee[];
  @Output() permissionsSaved: EventEmitter<PermissionSavePayload[]> = new EventEmitter();

  @Output() cancel: EventEmitter<void> = new EventEmitter();
  permissionModifications: PermissionSavePayload[] = [];
  filterText = '';

  get hasModification(): boolean {
    return !_.isEmpty(this.permissionModifications);
  }

  onPermissionModification(permissionModificationPayload: PermissionModificationPayload): void {
    this.permissionModifications = PermissionsHelper.updatedPermissionModifications(this.target, this.permissionModifications, permissionModificationPayload);
  }

  saveChanges() {
    this.permissionsSaved.emit(this.permissionModifications);
  }

  onCancel(): void {
    this.cancel.emit();
  }
}

import * as fromActions from '../actions';
import { RequestActionTypes } from './request.actions';

export class RequestState {
  readonly slowPendingRequestNr: number;
}

export const initState: RequestState = {
  slowPendingRequestNr: 0
};

export function reducer(state: RequestState, action: fromActions.NevisAdminAction<any>): RequestState {
  switch (action.type) {
    case RequestActionTypes.AddSlowPendingRequest:
      const slowPendingRequestNr_added = state.slowPendingRequestNr + 1;
      return {
        ...state,
        slowPendingRequestNr: slowPendingRequestNr_added
      };
    case RequestActionTypes.RemoveSlowPendingRequest: {
      const slowPendingRequestNr_removed = state.slowPendingRequestNr - 1;
      return {
        ...state,
        slowPendingRequestNr: slowPendingRequestNr_removed
      };
    }
    default: {
      return state;
    }
  }
}

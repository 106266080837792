import { Project } from '../../project.model';
import { CreateProjectForm } from '../../create-import-project-common/create-import-branch-project-form.model';
import { VersionControlHelper } from '../../../common/helpers/version-control.helper';

export class CreateProjectHelper {
  static convertFormValueToProject(formValue: CreateProjectForm, tenantKey: string): Project {
    const projectKey = `${tenantKey}-${formValue.projectKey}`.toUpperCase();
    return {
      projectKey: projectKey,
      tenantKey: tenantKey
    };
  }

  static convertFormValueToVersionedProject(formValue: CreateProjectForm, tenantKey: string): Project {
    const projectKey = `${tenantKey}-${formValue.projectKey}`.toUpperCase();
    const repository = formValue.versionControl.repository;
    const branchName = VersionControlHelper.getBranchName(formValue.versionControl.branch);
    return {
      projectKey: projectKey,
      tenantKey: tenantKey,
      repository: repository,
      branch: branchName,
      path: VersionControlHelper.getValidPath(formValue.versionControl.path)
    };
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'adm4-external-link',
  template: `
    <div class='link' [class.disabled]='isDisabled' [ngStyle]="{display: displayStyle}"  >
      <a class='link-with-icon' [href]='linkUrl' [target]='linkTarget' #linkElement>
        <span>{{linkLabel}}</span>
        <mat-icon>{{matIconName}}</mat-icon>
      </a>
    </div>
  `,
  styleUrls: ['../../styles/component-specific/link.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalLinkComponent {
  @Input() linkUrl: string;
  @Input() linkLabel: string;
  @Input() matIconName: string;
  @Input() openInNewTab: boolean;
  @Input() isDisabled: boolean;
  @Input() displayStyle: string;

  get linkTarget(): string | undefined {
    return this.openInNewTab ? '_blank' : '_self';
  }
}

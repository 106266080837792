import { Project } from './project.model';
import * as _ from 'lodash';
import { Dictionary } from '../model/reducer';
import { RouteParamHelper } from '../common/helpers/route-param.helper';
import { PatternType } from '../plugins/pattern-type.model';
import { DisplayComponentHelperService } from '../common/services/display-component-helper.service';
import { PluginModel } from '../plugins/plugin.model';
import { PluginHelper } from '../plugins/plugin.helper';

export class ProjectHelper {
  static isVersionedProject(project: Project) {
    return !_.isEmpty(project.repository) && !_.isEmpty(project.branch);
  }

  static getFromStoreOrFirstAvailable(storedKey: string | null, projects: Dictionary<Project>, localStorageKey: string): string | undefined {
    const existingStoredKey: string | undefined = RouteParamHelper.takeKeyFromStore(storedKey, projects, localStorageKey);
    return _.isNil(existingStoredKey) ? this.getFirstProjectKey(projects) : existingStoredKey;
  }

  static getFirstProjectKey(projects: Dictionary<Project>): string | undefined {
    const firstProject: Project | undefined  = _.values(projects)[0];
    return _.isNil(firstProject) ? undefined : firstProject.projectKey;
  }

  /**
   * Converts to Plugin model, and also filters only those patternTypes which is visible for the ApplicationMode, that can be created by the current mode
   * @param patternTypes
   * @param displayComponentHelperService the service that does the check
   * @private
   */
  static convertToFilteredPluginModel(patternTypes: Dictionary<PatternType> | null, displayComponentHelperService: DisplayComponentHelperService) {
    return _.reduce(patternTypes, (pluginModels: PluginModel[], patternType) => {
        if (!displayComponentHelperService.showByPatternType(patternType)) {
          return pluginModels;
        }
        pluginModels.push(PluginHelper.convertPatternTypeToPlugin(patternType));
        return pluginModels;
      }, []
    );
  }
}

export enum TreeNodeTypeEnum {
  Root = '_ROOT',
  Host = 'HOST',
  HostError = 'HOST_ERROR',
  CheckParent = 'CHECK_PARENT',
  Check = 'CHECK',
  CommandParent = 'COMMAND_PARENT',
  Command = 'COMMAND',
  Directory = 'DIRECTORY',
  File = 'FILE',
  KubernetesCustomResources = 'KUBERNETES_CUSTOM_RESOURCES',
  KubernetesCustomResource = 'KUBERNETES_CUSTOM_RESOURCE',
  Pattern = 'PATTERN',
  DeployablePattern = 'DEPLOYABLE_PATTERN'
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValueAccessorBase } from './value-accessor.base';
import { IssueModel } from '../common/model/issue.model';
import { Observable } from 'rxjs';
import { VariableModel } from '../variables/variable.model';
import { UntypedFormGroup } from '@angular/forms';
import { PatternProperty } from '../patterns/pattern-instance.model';
import { PropertyType } from '../plugins/property-type.model';
import { ParameterHelper } from './parameter.helper';
import * as _ from 'lodash';

// FIXME @inputs are not needed, see `PropertyListElementComponent.setPropertyInstance`

/**
 * Base widget component which contains the common attributes.
 */
@Component({
  template: ''
})
export class WidgetComponent extends ValueAccessorBase implements OnInit {
  @Input() label: string;
  @Input() patternId: string;
  @Input() name: string;
  @Input() issues: IssueModel[];
  @Input() readOnly: boolean;
  @Input() parameters: any;
  @Input() widgetProperty: PatternProperty;
  @Input() variables$: Observable<VariableModel>;
  @Input() group: UntypedFormGroup;
  @Input() propertyType?: PropertyType;
  @Input() propertyName: string; // required for checkbox property label fallback (see NEVISADMV4-1959)
  @Output() selectionChanged = new EventEmitter<string>();

  @Output() validate = new EventEmitter<any>();
  scrollArea?: HTMLElement;

  minRequired: number;
  maxAllowed: number;
  defaultValue: string | null;
  local: boolean;
  isSecret: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {
    // we do not know the propertyType in case of FallbackProperty
    if (this.propertyType) {
      this.minRequired = ParameterHelper.getSpecificParameterValueNumber(ParameterHelper.MIN_REQUIRED, this.parameters, this.propertyType) || 0;
      this.maxAllowed = ParameterHelper.getSpecificParameterValueNumber(ParameterHelper.MAX_ALLOWED, this.parameters, this.propertyType) || Number.MAX_SAFE_INTEGER;
      this.isSecret = ParameterHelper.getSpecificParameterValueBoolean(ParameterHelper.SECRET_PARAM , this.parameters, this.propertyType) || false;
    }
  }

  /**
   * This method signals the widget component that all of the inputs are set, they will not change for now,
   * and the widget might reconfigure itself. Works similarly to `ngOnChanges`
   */
  public initFinished() {
    /* nothing to be done by default, can be overridden */
  }

  onSelect(_event: any, elem: any) {
    this.selectionChanged.emit(this.widgetProperty.propertyKey);
    if (elem && elem.focus) {
      elem.focus();
    }
  }

  onValidate(event: any) {
    this.validate.emit(event);
  }

  /**
   * Override if value should be converted into an array (eg. SimpleTextProperty)
   * @returns {boolean}
   */
  isMultiValue(): boolean {
    return false;
  }

  /**
   * Double check if the widget has specified value or the FormGroup still contains value after it was removed by the cancel icon but wasn't updated in the ValueAccessorBase.
   * @returns {boolean}
   */
  isValueEmpty(): boolean {
    return _.isEmpty(this.value) || _.isEmpty(this.group.controls[this.widgetProperty.propertyKey].value);
  }

  /**
   * Returns placeholder for property
   * If default value exists then default text is returned otherwise it returns whitespace to keep placeholder available which helps differentiating styles for empty and filled inputs
   */
   get defaultText(): string {
    return this.defaultValue ? `default (${this.defaultValue})` : ' ';
   }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { InsertSecretsModalPayload } from './insert-secret.payload';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import { InventoryResource, SecretWrapper, TenantResourceFlag } from '../../inventory.model';
import { secretsView } from '../../../model/views';
import * as _ from 'lodash';
import { secretReferencePrefix } from '../../../common/constants/reference-prefix.constants';
import { ResourceLevelGroup } from '../insert-inventory-attachments-dialog/inventory-attachment-payload.model';
import { ResourceLevelGroupHelper } from '../insert-inventory-attachments-dialog/resource-level-group.helper';

@Component({
  selector: 'adm4-insert-secrets-modal',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title dialog-info'
                             [header]='"Insert secret"'
                             [showClose]='true'
                             (closeClicked)="closeSecretDialog()">
      <form name='secretForm' [formGroup]="form" (ngSubmit)='onFormSubmit()'>
        <div class="content-container">
          <div class="form-group secret-creation-form-elements">
            <div [class.disabled-opacity]='shouldSecretInputBeDisabled'>
              <label class='input-label'>Define secret for variable {{variableName}}</label>
              <div class='admn4-input-group-with-icon'>
                <input id="secretInput" class='admn4-text-input form-control' cdkFocusInitial placeholder='Please enter value' [type]="hide ? 'password' : 'text'" [formControlName]='SECRET_TEXT' autocomplete="off" [adm4ControlDisable]='shouldSecretInputBeDisabled'>
                <mat-icon matSuffix (click)="hide = !hide" class='input-icon visibility-icon admn4-button-ellipse-blue'>{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
              </div>
              <div class="validation-message-container">
                <adm4-validation-message [isInfo]='true' [message]='secretInfoMsg'></adm4-validation-message>
              </div>
              <label for='attachment-description' class='input-label description-label'>Description (optional)</label>
              <textarea class='form-control admn4-textarea-input description-textarea'
                        placeholder='Add your description (150 characters)'
                        maxlength='150'
                        id='attachment-description'
                        [formControlName]='SECRET_COMMENT_CONTROL'
                        [readOnly]='shouldSecretInputBeDisabled'></textarea>
            </div>
            <div class='global-secret-selection' [class.disabled-opacity]='shouldTenantSelectionBeDisabled'>
              <label class='input-label' for='global-resource-selection'>Or select existing secret</label>
              <div class='form-group admn4-input-group-with-icon'>
                <mat-form-field class='full-width'>
                  <mat-select id='global-resource-selection'
                              #matSelect placeholder="Please select a secret..."
                              [adm4ControlDisable]='shouldTenantSelectionBeDisabled'
                              [formControlName]='SECRET_KEY'
                              [disableOptionCentering]='true'
                              (click)='focusDropdownInput()'>
                    <adm4-searchable-dropdown-input *ngIf='matSelect.focused'
                                                    [sourceItems]="(secretsView$ | async) || []"
                                                    [searchableFormatFn]='getSecretLabel'
                                                    [placeholder]="'Please select a secret...'"
                                                    [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                                    (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
                    <mat-option>None</mat-option>
                    <mat-optgroup *ngFor="let secretGroup of groupedSecrets"
                                  [label]='secretGroup.level | capitalizeFirst'
                                  [hidden]='!shouldShowSecretGroup(secretGroup)'>
                      <mat-option *ngFor='let secret of secretGroup.resources'
                                  [value]='secret'
                                  [hidden]='!isSecretFilteredOut(secret)'
                                  [ngbTooltip]='secret?.description' placement='bottom'>
                        {{getSecretLabel(secret)}}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>

              <div *ngIf='matSelect.value?.description' class='global-resource-description'>
                <strong>Description: </strong>{{matSelect.value.description}}
              </div>
            </div>
          </div>
        </div>
        <div mat-dialog-actions>
          <adm4-button-bar [isSubmitDisabled]="form.invalid"
                           submitButtonText='Insert secret'
                           (cancelClicked)='closeSecretDialog()'></adm4-button-bar>
        </div>
      </form>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['../../../common/styles/component-specific/modal-window.scss', './insert-secrets-modal.component.scss']
})
export class InsertSecretsModalComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<boolean> = new Subject();

  public hide = true;
  public form: UntypedFormGroup;
  public SECRET_TEXT = 'secretText';
  public SECRET_KEY = 'secretKey';
  public SECRET_COMMENT_CONTROL = 'secretComment';
  public variableName: string;
  public secretInfoMsg = `The secret will be visible as "secret://xxxxxxxx" in the inventory.`;
  public secretsView$: Observable<(SecretWrapper & TenantResourceFlag)[]>;
  filteredSecrets: (SecretWrapper & TenantResourceFlag)[];
  groupedSecrets: ResourceLevelGroup<SecretWrapper>[];

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  insertSecretValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const text = !_.isEmpty(fg.get(this.SECRET_TEXT)?.value);
    const key = !_.isEmpty(fg.get(this.SECRET_KEY)?.value);
    const newVar = (text || key) && !(text && key) ? null : {textAndKeyFilled: true};
    return newVar;
  };
  constructor(@Inject(MAT_DIALOG_DATA) public data: InsertSecretsModalPayload, private fb: UntypedFormBuilder,
              public dialogRef: MatDialogRef<InsertSecretsModalComponent>,
              private store$: Store<AppState>) {
    this.variableName = data.variableName;
    this.secretsView$ = this.store$.select(secretsView);
    this.secretsView$.pipe(takeUntil(this.destroyed$)).subscribe((secrets) => {
      this.groupedSecrets = ResourceLevelGroupHelper.groupResourcesByLevel(secrets);
    });
  }

  ngOnInit() {
    this.form = this.fb.group({}, {validators: this.insertSecretValidator});
    this.form.addControl(this.SECRET_TEXT, new UntypedFormControl('', null));
    this.form.addControl(this.SECRET_KEY, new UntypedFormControl('', null));
    this.form.addControl(this.SECRET_COMMENT_CONTROL, new UntypedFormControl('', null));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onFormSubmit() {
    this.data.onSaveCallback(this.haveTenantScopeSecretSelected, this.form.controls[this.SECRET_TEXT].value, secretReferencePrefix + this.selectedTenantScopedSecret?.secretId, this.form.controls[this.SECRET_COMMENT_CONTROL].value);
    this.closeSecretDialog();
  }

  get haveTenantScopeSecretSelected() {
    return _.isEmpty(this.form.controls[this.SECRET_KEY].value);
  }


  get shouldTenantSelectionBeDisabled() {
    return !_.isEmpty(this.form.controls[this.SECRET_TEXT].value);
  }

  get shouldSecretInputBeDisabled() {
    return !_.isEmpty(this.form.controls[this.SECRET_KEY].value);
  }

  get selectedTenantScopedSecret() {
    return this.form.controls[this.SECRET_KEY].value as (SecretWrapper & TenantResourceFlag | undefined);
  }

  getSecretLabel = (secret: SecretWrapper & TenantResourceFlag): string => {
    return String(secretReferencePrefix + secret.secretId + (_.isEmpty(secret.description) ? '' : ` (${secret.description})`));
  };

  updateSearchResult(filteredList: (SecretWrapper & TenantResourceFlag)[]): void {
    this.filteredSecrets = filteredList;
  }

  focusDropdownInput(): void {
   this._searchableDropdownInputFocusTrigger$.next();
  }

  shouldShowSecretGroup(resourceGroup: ResourceLevelGroup<SecretWrapper>): boolean {
    return ResourceLevelGroupHelper.hasDisplayableResourcesByLevel(resourceGroup) && _.some(resourceGroup.resources, resource => this.isSecretFilteredOut(resource));
  }

  isSecretFilteredOut(secret: InventoryResource & TenantResourceFlag): boolean {
    return _.some(this.filteredSecrets, filteredSecret => _.isEqual(filteredSecret, secret));
  }

  closeSecretDialog() {
    this.dialogRef.close();
  }
}

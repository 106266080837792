import { CanaryRoutingOption, DeploymentAction } from '../../deployment-wizard/deployment-dialog/deployment-process.model';

export enum DeploymentHistoryJobStatus {
  Done = 'DONE',
  Failed = 'FAILED',
  Running = 'RUNNING'
}

export enum DeploymentHistoryState {
  CANARY = 'CANARY',
  OUTDATED = 'OUTDATED',
  PROMOTED = 'PROMOTED',
  ROLLED_BACK = 'ROLLEDBACK',
  ROLLBACK_FAILED = 'ROLLBACK_FAILED',
  PROMOTE_FAILED = 'PROMOTE_FAILED',
  DELETED = 'DELETED',
  DELETE_FAILED = 'DELETE_FAILED',
}

export interface DeploymentHistoryItem {
  deploymentHistoryId: number;
  jobStatus: DeploymentHistoryJobStatus | null;
  hostExpression?: string;
  inventoryKey: string;
  projectKey: string;
  inventoryCommitId?: string;
  projectCommitId?: string;
  projectDeleted: boolean;
  timestamp: string;
  namespace?: string;
  userKey: string;
  deploymentState?: DeploymentHistoryState;
  canaryRouting?: CanaryRoutingOption;
  deploymentTargets?: string[];
  actions?: DeploymentAction[];
  postfix?: string;
  tag?: string;
}

import { DeploymentHistoryJobStatus } from '../../common/model/deployment-history.model';
import { REVISION_KEY_SEPARATOR } from './inventory-deployment.model';

export class InventoryDeploymentHistoryHelper {
  static getStatusOrderNumber(status: DeploymentHistoryJobStatus | null): number {
    switch (status) {
      case DeploymentHistoryJobStatus.Done:
        return 0;
      case DeploymentHistoryJobStatus.Failed:
        return 2;
      default:
        return 1;
    }
  }

  static createRevisionKey(key: string, commitId: string): string {
    const shortCommitId: string = commitId.substring(0, 7);
    return `${key}${REVISION_KEY_SEPARATOR}${shortCommitId}`.toUpperCase();
  }

  static getOriginKeyFromRevisionKey(revisionKey: string): string {
    const [originKey] = revisionKey.split(REVISION_KEY_SEPARATOR);
    return originKey;
  }
}

import { PatternInstance } from '../patterns/pattern-instance.model';
import { PatternType } from '../plugins/pattern-type.model';
import { PropertyType } from '../plugins/property-type.model';
import { IssueModel } from '../common/model/issue.model';
import { Dictionary } from 'lodash';

/**
 * Data model of the PatternEditorComponent
 */
export class PropertyListComponentModel {
  constructor(public pattern: PatternInstance,
              public patternType: PatternType | undefined,
              public propertyTypeArray: Dictionary<PropertyType>,
              public issues: IssueModel[]
  ) {
  }
}

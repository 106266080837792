import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, Dictionary } from '../../model/reducer';
import { LoadPluginDocForPatternType } from '../../model/actions';
import { PluginModel } from '../../plugins/plugin.model';
import * as _ from 'lodash';
import { PluginDocumentationHelper } from '../../help/plugin-documentation.helper';

/**
 * This component is for displaying the pattern details inside the add pattern window
 */
@Component({
  selector: 'adm4-pattern-details',
  template: `
    <div class="pattern-details-container" #scrollArea>
      <div *ngIf='!pattern' class='pattern-detail-empty'>
        {{SELECT_PATTERN}}
      </div>
      <div *ngIf='pattern' class='help-info' [innerHtml]="helpData$ | async"></div>
    </div>

  `,
  styleUrls: ['pattern-details.component.scss', '../../common/styles/component-specific/help-info.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternDetailsComponent implements OnChanges {
  @Input() projectKey: string;
  @Input() pattern: PluginModel;
  @ViewChild('scrollArea', {static: false}) scrollArea: ElementRef<HTMLElement>;
  SELECT_PATTERN = 'Select a pattern to see its details.';
  helpData$: Observable<string | null>;
  // TODO 30.Aug.2018/soroka probably makes sense to extract this part into a context file, just not sure how to pass project key and plugin data there properly so it would still be better
  // to make it rather declarative solution behavior subject is used here, even though it would work with accessing class field, but we would still need to handle undefined case probably
  pluginDocKey$: BehaviorSubject<string | null> = new BehaviorSubject(null);

  constructor(private store$: Store<AppState>) {
    // the main point is not to create a new observable in ngOnChanges, since it will be recreated on every call, it's not really okay, we want to keep it declarative
    this.helpData$ = this.store$.pipe(select((state) => state.entities.pluginDocumentations))
      .pipe(
        withLatestFrom(this.pluginDocKey$),
        filter(([_pluginDocs, pluginDocKey]: [Dictionary<string | null>, string | null]) => !_.isNil(pluginDocKey)),
        map(([pluginDocs, pluginDocKey]: [Dictionary<string | null>, string]) => pluginDocs[pluginDocKey]),
        // when loads new helpData, we need to scroll help container back to top
        tap(() => {
          if (!_.isNil(this.scrollArea)) {
            const nativeElement: HTMLElement = this.scrollArea.nativeElement;
            nativeElement.scrollTop = 0;
          }
        })
      );
  }

  ngOnChanges(): void {
    if (this.pattern) {
      this.pluginDocKey$.next(PluginDocumentationHelper.createPluginDocKey(this.projectKey, this.pattern.className));
      this.store$.dispatch(new LoadPluginDocForPatternType(this.pattern));
    }
  }
}

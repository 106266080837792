import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DEPLOYMENT_REPOSITORY_FORM_CONTROL_NAME, K8S_CLUSTER_NAMESPACE_FORM_CONTROL_NAME, K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME, K8S_CLUSTER_URL_FORM_CONTROL_NAME } from './kubernetes-inventory.form';
import { Observable } from 'rxjs/internal/Observable';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'adm4-kubernetes-inventory-creation-form',
  templateUrl: 'kubernetes-inventory-creation-form.component.html',
  styleUrls: ['./kubernetes-inventory-creation-form.component.scss', '../../../common/components/version-control-form/version-control-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KubernetesInventoryCreationFormComponent implements OnInit, OnDestroy {

  readonly K8S_CLUSTER_URL_FORM_CONTROL_NAME = K8S_CLUSTER_URL_FORM_CONTROL_NAME;
  readonly K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME = K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME;
  readonly K8S_CLUSTER_NAMESPACE_FORM_CONTROL_NAME = K8S_CLUSTER_NAMESPACE_FORM_CONTROL_NAME;

  @Input() isDisabled = false;
  @Output() formReady: EventEmitter<UntypedFormGroup> = new EventEmitter();

  hasError$: Observable<boolean>;
  hideToken = true;

  form: UntypedFormGroup;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private fb: UntypedFormBuilder,
              private cdr: ChangeDetectorRef,
              ) {
  }

  ngOnInit() {
    this.form = this.createFormGroup();
    this.formReady.emit(this.form);
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    group.addControl(this.K8S_CLUSTER_URL_FORM_CONTROL_NAME, this.fb.control(null, Validators.required));
    group.addControl(this.K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME, this.fb.control(null, Validators.required));
    group.addControl(this.K8S_CLUSTER_NAMESPACE_FORM_CONTROL_NAME, this.fb.control([], Validators.required));
    return group;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get clusterUrl(): string {
    return this.form.controls[this.K8S_CLUSTER_URL_FORM_CONTROL_NAME].value;
  }

  get token(): string {
    return this.form.controls[this.K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME].value;
  }

  addDeploymentRepositoryForm(deploymentRepositoryForm: UntypedFormGroup) {
    this.form.setControl(DEPLOYMENT_REPOSITORY_FORM_CONTROL_NAME, deploymentRepositoryForm);
  }
}

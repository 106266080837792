import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { delay, distinctUntilChanged, finalize, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

const untrackedUrls: RegExp[] = [
  /^\/projects\/[A-Za-z0-9_-]+\/timestamp\/?$/,
  /^\/projects\/[A-Za-z0-9_-]+\/issues\/timestamp\/?$/,
  // note that this does not differentiate between polling and actual history browsing,
  // but for now we don't browse the history on the UI, only poll it
  /^\/tenants\/[A-Za-z0-9_-]+\/deployment-history\?.*$/,
  /^\/inventories\/[A-Za-z0-9_-]+\/timestamp$/,
  /^\/inventories\/[A-Za-z0-9_-]+\/host-status$/,
  /^\/inventories\/[A-Za-z0-9_-]+\/services$/,
  /^\/inventories\/[A-Za-z0-9_-]+\/services\?.*$/,
  /^\/me\/?$/,
  /^\/assets\/.+$/,
];

@Injectable()
export class LoadingInterceptorService implements HttpInterceptor {
  private static readonly HIDE_LOADING_DELAY = 800;

  private readonly refresh: Subject<void> = new Subject();
  public readonly isLoading: Observable<boolean>;

  private onGoingRequests: Readonly<Set<string>> = new Set();
  private prefixLength: number = environment.baseUrl.length;

  constructor() {
    this.isLoading = this.refresh.asObservable().pipe(
      map(() => this.onGoingRequests.size > 0),
      distinctUntilChanged(),
      switchMap((isLoading): Observable<boolean> => {
        return isLoading ? of(true).pipe(delay(0)) : of(false).pipe(delay(LoadingInterceptorService.HIDE_LOADING_DELAY));
      }),
      startWith(false),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url: string = req.urlWithParams.slice(this.prefixLength);
    const isTracked = this.isRequestTracked(url);
    if (isTracked) {
      this.onGoingRequests.add(url);
      this.refresh.next();
    }
    return next.handle(req).pipe(
      finalize(() => {
        if (isTracked) {
          this.onGoingRequests.delete(url);
          this.refresh.next();
        }
      }),
    );
  }

  private isRequestTracked(url: string): boolean {
    return !untrackedUrls.some((untrackedUrl: RegExp) => untrackedUrl.test(url));
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PatternListData } from '../pattern-list-data.model';
import { PatternHelper } from '../pattern.helper';
import { requireNonNull } from '../../common/utils/utils';
import { PatternViewModel } from '../../common/model/pattern-view.model';
import * as _ from 'lodash';
import { PatternHierarchyData } from './pattern-hierarchy-tree.model';

// responsibility: store the main patterns and build a hierarchy tree for them
@Injectable()
export class PatternHierarchyContext {
  _allPatternsMap$ = new BehaviorSubject<Map<string, PatternListData>>(new Map());
  _selectedPatternHierarchy$ = new BehaviorSubject<PatternHierarchyData | null>(null);

  selectedPatternHierarchy: Observable<PatternHierarchyData | null> = this._selectedPatternHierarchy$.asObservable();

  get patternsMapData(): Map<string, PatternListData> {
    return this._allPatternsMap$.value;
  }

  get selectedPatternHierarchyData(): PatternHierarchyData | null {
    return this._selectedPatternHierarchy$.value;
  }

  loadAllPatternsIntoMap(patterns: PatternListData[]): void {
    this._allPatternsMap$.next(PatternHelper.convertPatternListToMap(patterns));
  }

  createPatternHierarchyByContextPatternId(_selectedContextPatternId: string, patternView: PatternViewModel): void {
    const selectedPatternWithHierarchy = this.convertPatternViewModelToPatternHierarchyData(patternView);
    const hasDifferentHierarchy = !_.isEqual(this.selectedPatternHierarchyData, selectedPatternWithHierarchy);
    if (hasDifferentHierarchy) {
      this._selectedPatternHierarchy$.next(selectedPatternWithHierarchy);
    }
  }

  convertPatternViewModelToPatternHierarchyData(pattern: PatternViewModel): PatternHierarchyData {
    const mainPattern: PatternListData = requireNonNull(this.patternsMapData.get(pattern.patternId));
    return {
      ...mainPattern,
      parentPropertyKey: pattern?.parentPropertyKey,
      children: this.getChildPatternHierarchyData(pattern),
      loop: pattern?.circle,
      parents: pattern.parentPatternIds ? pattern.parentPatternIds : [],
      aborted: pattern?.aborted
    };
  }

  getChildPatternHierarchyData(patternNode: PatternViewModel): PatternHierarchyData[] {
    const patternList: PatternHierarchyData[] = [];
    if (patternNode.children && patternNode.children.length > 0) {
      const directParentPattern = this.patternsMapData.get(patternNode.patternId);
      patternNode.children.forEach((childNode: PatternViewModel) => {
        const child: PatternListData | undefined = this.patternsMapData.get(childNode.patternId);
        if (!_.isNil(child)) {
          const extendedChild: PatternHierarchyData = {
            ...child,
            parentPropertyKey: this.getPropertyLabelByPropertyKey(directParentPattern, childNode?.parentPropertyKey),
            parents: childNode.parentPatternIds ? childNode.parentPatternIds : [],
            loop: childNode?.circle,
            children: this.getChildPatternHierarchyData(childNode),
            aborted: childNode.aborted
          };
          patternList.push(extendedChild);
        }
      });
    }
    return patternList;
  }

  getPatternListDataByPatternIds(patternIds: string[]): PatternListData[] {
    return patternIds.map((parentPatterId) => requireNonNull(this.patternsMapData.get(parentPatterId)));
  }

  getPropertyLabelByPropertyKey(pattern?: PatternListData, targetPropertyKey?: string): string | undefined {
    if (_.isNil(pattern)) {
      return;
    }
    return pattern.type?.properties?.find(property => property.propertyKey === targetPropertyKey)?.name;
  }
}

import { NgModule } from '@angular/core';
import { CommonModules } from '../../common/common.module';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CertificateManagementTableComponent } from './certificate-management-table/certificate-management-table.component';
import { CertificateManagementComponent } from './certificate-management.component';
import { BundleManagementModule } from '../bundle-management/bundle-management.module';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    CertificateManagementComponent,
    CertificateManagementTableComponent
  ],
  imports: [
    BundleManagementModule,
    CommonModules,
    MatTableModule,
    ClipboardModule,
    MatSortModule
  ]
})
export class CertificateManagementModule {
}

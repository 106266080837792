import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Mixin } from '../../decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../mixins/split.mixin';
import { Dictionary } from '../../../model/reducer';
import { SplitPaneConfig } from '../../model/split-pane-config.model';
import { ResizeHelper } from '../../helpers/resize.helper';
import { IOutputData } from 'angular-split/lib/interface';
import { SideMenuService } from '../../services/side-menu.service';

@Component({
  selector: 'adm4-area-with-side-menu',
  template: `
    <div class='full-height collapsible-split-pane-container'>
      <as-split direction='horizontal' gutterSize='1' class="hide-as-split-gutter" useTransition [disabled]='isCollapsed' (dragEnd)='onResize($event)'>
        <as-split-area class='side-menu' [order]='splitPaneConfig[sideMenuSplitAreaKey].order' (transitionend)="transitionFinished()"
                       [size]='splitPaneConfig[sideMenuSplitAreaKey].size' [minSize]='10' [maxSize]='30'
                       [visible]='!isCollapsed'>
          <div class='full-height-flex'>
            <adm4-column-header styleClass='{{columnHeaderClassName}} flex-header-stretched'>
              <span *ngIf='!isCollapsed'>{{title}}</span>
              <div class='side-menu-header'>
                <adm4-toggle-collapse-button side='left' buttonClass='{{toggleCollapseButtonClassName}}' [isCollapsed]='isCollapsed'
                                             (click)='collapse(!isCollapsed)'
                ></adm4-toggle-collapse-button>
              </div>
            </adm4-column-header>
            <div class='remaining-space-flex-content-wrapper'>
              <div class='remaining-space-flex-content menu-container'>
                <ng-content select='[side-menu]'></ng-content>
              </div>
            </div>
          </div>
        </as-split-area>
        <as-split-area class='content-area' [order]='splitPaneConfig[contentSplitAreaKey].order'
                       [size]='splitPaneConfig[contentSplitAreaKey].size'
                       [class.content-with-collapsed-menu]='isCollapsed'>
          <ng-content select='[area-content]'></ng-content>
        </as-split-area>
      </as-split>
    </div>
  `,
  styleUrls: ['./area-with-side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SideMenuService]
})
@Mixin([SplitMixin])
export class AreaWithSideMenuComponent implements ISplitMixin {
  @Input()
  public title: string;
  readonly splitPaneConfigLocalStorageKey: string = 'side-menu-split-pane-config';
  readonly sideMenuSplitAreaKey = 'side-menu';
  readonly contentSplitAreaKey = 'content';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.sideMenuSplitAreaKey]: { order: 0, size: 20, isCollapsed: false },
    [this.contentSplitAreaKey]: { order: 1, size: 80 }
  };

  /**
   * Implemented by SplitMixin
   */
  onCollapse: (isCollapsed: boolean, collapsibleAreaKey: string) => void;
  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  constructor(
      private sideMenuService: SideMenuService,
      private cdr: ChangeDetectorRef,
  ) {
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
    this.sideMenuService.collapse(this.isCollapsed);
  }

  collapse(isCollapsed: boolean) {
    this.sideMenuService.collapse(isCollapsed);
    this.onCollapse(isCollapsed, this.sideMenuSplitAreaKey);
  }

  get toggleCollapseButtonClassName(): string {
    return this.isCollapsed ? 'admn4-button-icon-white' : 'admn4-button-nevis-green';
  }

  get columnHeaderClassName(): string {
    return this.isCollapsed ? 'nevis-green-header' : 'sidemenu-titlebar-header';
  }

  get isCollapsed(): boolean| undefined {
    const splitPaneConfigElement = this.splitPaneConfig[this.sideMenuSplitAreaKey];
    return splitPaneConfigElement.isCollapsed;
  }

  // should be called when the split pane's transition ends, to explicitly trigger change detection
  transitionFinished() {
    this.cdr.detectChanges();
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { CollapsiblePaneMixin, ICollapsiblePaneMixin } from '../../common/mixins/collapsible-pane.mixin';
import { InventorySchemaType } from '../inventory.model';
import { InventorySchemaTypeHelper } from '../../deployment-wizard/deployment-selection/inventory-list/inventory-schema-type.helper';

@Component({
  selector: 'adm4-inventory-help',
  template: `
    <div class='full-height-flex'>
      <adm4-column-header styleClass="light-header">
        About inventories
        <adm4-toggle-collapse-button side='right'
                                     [isCollapsed]='false'
                                     (click)='setCollapse(true)'
        ></adm4-toggle-collapse-button>
      </adm4-column-header>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='inventory-help-container remaining-space-flex-content inline-help'>
          <adm4-inventory-help-classical *ngIf='isClassicDeployment()'></adm4-inventory-help-classical>
          <adm4-inventory-help-kubernetes *ngIf='isKubernetesDeployment()'></adm4-inventory-help-kubernetes>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./inventory-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Mixin([CollapsiblePaneMixin])
export class InventoryHelpComponent implements ICollapsiblePaneMixin {
  @Input() inventorySchemaType: InventorySchemaType;
  @Output() collapse: EventEmitter<boolean> = new EventEmitter();

  /**
   * Implemented by CollapsiblePaneMixin
   */
  setCollapse: (isCollapsed: boolean) => void;

  isClassicDeployment(): boolean {
    return InventorySchemaTypeHelper.isClassicDeployment(this.inventorySchemaType);
  }

  isKubernetesDeployment(): boolean {
    return InventorySchemaTypeHelper.isKubernetesDeployment(this.inventorySchemaType);
  }
}

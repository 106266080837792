import { MatDialogConfig } from '@angular/material/dialog';

export class ModalOpeniningHelper {
  static getBaseModalConfig<T = any>(data?: T): MatDialogConfig<T> {
    return {
      width: '40%',
      disableClose: true,
      data: data,
      autoFocus: false
    };
  }
}

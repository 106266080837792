import { Subject } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ImportProjectHelper } from './import-project.helper';
import { Project } from '../project.model';
import { checkExistingProjectKey } from '../project-creation.validator';
import { PROJECT_KEY_FORM_CONTROL_NAME, VERSION_CONTROL_FORM_CONTROL_NAME } from '../create-import-project-common/create-import-branch-project-form.model';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { INestedVersionControlFormMixin, NestedVersionControlFormMixin } from '../../common/components/version-control-form/nested-version-control-form.mixin';
import { IPredefinedProjectFormDataMixin, PredefinedProjectFormDataMixin } from '../create-import-project-common/predefined-project-form-data.mixin';
import { FormHelper } from '../../common/helpers/form.helper';
import { VersionControlData } from '../../common/model/version-control-data.model';

@Component({
  selector: 'adm4-import-project-dialog',
  templateUrl: './import-project-dialog.component.html',
  styleUrls: ['../../common/styles/component-specific/modal-window.scss', '../../common/styles/component-specific/create-form.scss']
})
@Mixin([NestedVersionControlFormMixin, PredefinedProjectFormDataMixin])
export class ImportProjectDialogComponent implements OnInit, OnDestroy, INestedVersionControlFormMixin, IPredefinedProjectFormDataMixin {

  @Input() projects: Project[];
  @Input() predefinedProjectData: Project | null;
  @Input() selectedTenantKey: string;
  @Input() displayTenant: boolean;

  @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
  @Output() importProject = new EventEmitter<Project>();
  @Output() closeClicked = new EventEmitter<any>();

  public form: UntypedFormGroup;

  readonly ERROR_INVALID_PROJECT_KEY = 'The project key entered contains some invalid characters. Must be of format [A-Z0-9_-]';
  readonly ERROR_PROJECT_KEY_REQUIRED = 'The project key is required';

  readonly VERSION_CONTROL_FORM_CONTROL_NAME = VERSION_CONTROL_FORM_CONTROL_NAME;
  readonly PROJECT_KEY_FORM_CONTROL_NAME = PROJECT_KEY_FORM_CONTROL_NAME;

  destroyed$: Subject<boolean> = new Subject();

  // implemented by mixin NestedVersionControlFormMixin
  updateDefaultKeyOnVersionControlChange: (projectKeyFormControlName: string) => void;

  // implemented by mixin PredefinedProjectFormDataMixin
  initialProjectKeyFormValue: () => string;
  predefinedVersionControlData: () => VersionControlData | null;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.createFormGroup();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    group.addControl(this.PROJECT_KEY_FORM_CONTROL_NAME, this.fb.control(this.initialProjectKeyFormValue(), [Validators.pattern(/^[a-zA-Z0-9_-]+$/), Validators.required, checkExistingProjectKey(this.projects, this.selectedTenantKey)]));
    return group;
  }

  addVersionControlForm(versionControlForm: UntypedFormGroup): void {
    this.form.addControl(this.VERSION_CONTROL_FORM_CONTROL_NAME, versionControlForm);
    if (_.isNil(this.predefinedProjectData) || _.isNil(this.predefinedProjectData.projectKey)) {
      this.updateDefaultKeyOnVersionControlChange(this.PROJECT_KEY_FORM_CONTROL_NAME);
    }
  }

  submitImportProject(): void {
    if (this.canSave) {
      const project: Project = ImportProjectHelper.getProjectFromFormValue(this.form.value, this.selectedTenantKey);
      this.importProject.emit(project);
    }
  }

  shouldShowProjectKeyErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[this.PROJECT_KEY_FORM_CONTROL_NAME], errorKey);
  }

  get canSave(): boolean {
    const versionControlForm = this.form.controls[this.VERSION_CONTROL_FORM_CONTROL_NAME] as UntypedFormGroup;
    const versionControlValid: boolean = _.values(versionControlForm.controls).every(control => control.valid);
    return this.form.valid && versionControlValid;
  }

  get projectForTenantExistsError(): string {
    return `This key is already in use for tenant ${this.selectedTenantKey}`;
  }
}

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'adm4-empty-resource',
  template: '<ng-content></ng-content>',
  styleUrls: ['./empty-resource.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyResourceComponent {

  @HostBinding('class.left')
  @Input()
  public leftAligned: boolean = false;
}

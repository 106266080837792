import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { singularPlural } from '../../projects/project-summary/project-summary.helper';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { Subject } from 'rxjs';
import { PatternListData } from '../pattern-list-data.model';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'adm4-batch-actions-menu',
  template: `
    <button type="button" class="imgContainer" [matMenuTriggerFor]="menu" aria-label="menu" [disabled]='!hasSelectablePattern'>
      <i class="fa fa-caret-down" aria-hidden="true" aria-label="menu"></i>
    </button>
    <mat-menu class="overflow-menu min-width-250" #menu="matMenu" [xPosition]="'after'">
      <div mat-menu-item class='overall-menu-title' [disabled]='true' [class.multi-selection-info]='hasSelectedPattern'>
        <span class='menu-text'>{{overallSelectionInfoText}}
        </span>
      </div>
      <button type="button" mat-menu-item (click)='selectAll.emit($event)' [disabled]='isSaveAllDisabled' [class.slight-opacity]='isSaveAllDisabled'>
        <mat-icon>check</mat-icon>
        <span class="menu-text">Select all</span>
      </button>
      <button type="button" mat-menu-item [disabled]='!hasSelectedPattern' [class.slight-opacity]='!hasSelectedPattern' (click)="copyPatterns()">
        <mat-icon>content_copy</mat-icon>
        <span class="menu-text">Copy selected</span>
      </button>
      <button type="button" mat-menu-item *ngIf='!readOnly'
              [matMenuTriggerFor]="labelling"
              (menuOpened)="labelMenu.setFocusOnInput()"
              (menuClosed)='labelMenu.closeLabelOptionsDropdown()'
              [disabled]='!hasSelectedPattern'
              [class.slight-opacity]='!hasSelectedPattern'>
        <mat-icon>local_offer</mat-icon>
        <span class="menu-text">Label selected as</span>
      </button>
      <button type="button" mat-menu-item (click)='deleteSelected.emit($event)'
              (menuOpened)="labelMenu.setFocusOnInput()"
              (menuClosed)='labelMenu.closeLabelOptionsDropdown()'
              [class.slight-opacity]='!hasSelectedPattern'
              [disabled]='!hasSelectedPattern'>
      <mat-icon>delete_forever</mat-icon>
        <span class="menu-text">Remove selected</span>
      </button>
      <mat-menu #labelling>
        <adm4-label-menu-option
                #labelMenu
                [selectedPatternIds]='selectedPatternIds'
                [projectKey]='projectKey'
                [allPatternLabels]='allPatternLabels'
                [labels]='labels'
                (triggerMenuClose)='closeMenu()'>
        </adm4-label-menu-option>
      </mat-menu>
    </mat-menu>`,
  styleUrls: ['../../common/styles/component-specific/overflow-menu.scss', 'batch-actions-menu.scss']
})
export class BatchActionsMenuComponent implements OnChanges, OnDestroy {
  @Input() hasProjectModificationPermission: boolean;
  @Input() multiSelectionCount: number;
  @Input() filteredPatternCount: number;
  @Input() selectedPatterns: PatternListData[];
  @Input() projectKey: string;
  @Input() allPatternLabels: string[];
  @Input() shouldDisableBatchSelection: boolean;
  @Input() readOnly: boolean;
  labels: string[] = [];
  selectedPatternIds: string[] = [];
  @Output() selectAll = new EventEmitter();
  @Output() copy = new EventEmitter<void>();
  @Output() deleteSelected = new EventEmitter<void>();
  readonly DEFAULT_OVERALL_SELECTION_INFO = 'Select patterns to enable actions';
  @ViewChild(MatMenuTrigger, {static: false}) menuTrigger: MatMenuTrigger;
  private destroyed$: Subject<boolean> = new Subject();

  constructor(public modalNotificationService: ModalNotificationService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedPatterns'] || changes['allPatternLabels']) {
      this.labels = this.getSelectedPatternLabels();
      this.selectedPatternIds = this.selectedPatterns.map((patternListData: PatternListData) => patternListData.pattern.patternId);
    }
  }

  getSelectedPatternLabels(): string[] {
    return this.selectedPatterns.reduce((labelList: string[], pattern: PatternListData) => {
      if (!pattern.pattern.label || labelList.includes(pattern.pattern.label)) {
        return labelList;
      } else {
        return [...labelList, pattern.pattern.label];
      }
    }, []);
  }

  get hasSelectedPattern(): boolean {
    return this.multiSelectionCount > 0;
  }

  get hasSelectablePattern(): boolean {
    return this.filteredPatternCount > 0;
  }

  get isSaveAllDisabled(): boolean {
    return this.shouldDisableBatchSelection || !this.hasSelectablePattern;
  }

  get overallSelectionInfoText(): string {
    return this.hasSelectedPattern ? this.selectedPatternsSummary : this.DEFAULT_OVERALL_SELECTION_INFO;
  }

  get selectedPatternsSummary(): string {
    const patternCount = this.multiSelectionCount;
    const what = singularPlural(patternCount, 'pattern selected', 'patterns selected');
    return `${patternCount} ${what}:`;
  }

  copyPatterns() {
    if (!this.hasProjectModificationPermission) {
      const title = `Target project was not found`;
      this.modalNotificationService.openErrorDialog({title: title, description: `There is no target project found. It does not seem to exist, or you may not have access.`});
      return;
    }
    this.copy.emit();
  }

  closeMenu(): void {
    this.menuTrigger.closeMenu();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}

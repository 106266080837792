import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState, Dictionary, ProjectKey } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { allProjectsView, issuesView, projectKeyView } from '../../model/views';
import { ProjectSettingsContext } from '../project-settings.context';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Project, ProjectMetaChangeIndicator } from '../../projects/project.model';
import { IssueModel } from '../../common/model/issue.model';
import { IssueSeverityEnum } from '../../common/model/issue-severity.enum';
import { getHighestSeverity } from '../../projects/project-issues/project-issues.helper';
import { requireNonNull } from '../../common/utils/utils';
import { ProjectContext } from '../../projects/project.context';

@Component({
  selector: 'adm4-project-settings',
  template: `
    <div class='full-height-flex'>
      <adm4-column-header styleClass="project-header">
        <adm4-project-header
          [projectKey]='projectKey$ | async'
          [projectIssues]='allIssues$ | async'
          [highestSeverity]="highestSeverity$ | async"
          [projects]='projects$ | async'
          [projectMetaIndicator]="projectMetaChangeIndicator$ | async"
        ></adm4-project-header>
      </adm4-column-header>
      <div class="remaining-space-flex-content-wrapper">
        <adm4-project-settings-details [projectKey]='projectKey$ | async'></adm4-project-settings-details>
      </div>
    </div>
  `,
  providers: [
    ProjectSettingsContext
  ]
})
export class ProjectSettingsMainComponent {
  public readonly projectKey$: Observable<ProjectKey>;
  public readonly projects$: Observable<Dictionary<Project>>;
  public readonly allIssues$: Observable<IssueModel[] | undefined>;
  public readonly highestSeverity$: Observable<IssueSeverityEnum>;
  public readonly project$: Observable<Project | undefined>;
  public projectMetaChangeIndicator$: Observable<ProjectMetaChangeIndicator>;

  constructor(
      private store$: Store<AppState>,
      public projectSettingsContext: ProjectSettingsContext,
      projectContext: ProjectContext,
  ) {
    this.projectKey$ = this.store$.pipe(select(projectKeyView), filter<string>((projectKey: string | null) => !_.isNil(projectKey)));
    this.projects$ = this.store$.pipe(select(allProjectsView));
    this.allIssues$ = this.store$.pipe(select(issuesView));
    this.highestSeverity$ = this.allIssues$.pipe(map((issues) => getHighestSeverity(requireNonNull(issues))));
    this.project$ = this.projectSettingsContext.currentProject$;
    this.projectMetaChangeIndicator$ = projectContext.projectMetaChangeIndicator$;
  }
}

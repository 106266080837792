import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import * as _ from 'lodash';

import { allInventoriesListView, selectedTenantKeyView } from '../../model/views';
import { AppState } from '../../model/reducer';
import { filterEmpty, Maybe } from '../../common/utils/utils';
import { Inventory, PreselectedResource } from '../../inventory/inventory.model';
import {
  getInventoriesWithModifyInventoryAccessView,
  getInventoriesWithViewSecretContentAccessView, hasTenantModificationAccessView,
  hasViewSecretContentTenantAccessView,
} from '../../model/views/permission.views';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { NavigationConstants } from '../../common/constants/navigation.constants';

const GLOBAL_CERTIFICATE_SELECTION = 'GLOBAL';

@Injectable()
export class SecretManagementContextService {

  public preselectedResource$: Observable<Maybe<PreselectedResource>>;

  public selectedTenantKey$: Observable<string>;
  public inventories$: Observable<Inventory[]>;

  public hasViewSecretContentTenantLevelPermission$: Observable<boolean>;
  public hasViewSecretContentInventoryLevelPermission$: Observable<boolean>;
  public hasModifyInventoryPermission$: Observable<boolean>;
  public hasModifyTenantPermission$: Observable<boolean>;
  public hasModifyTenantAndInventoryPermission$: Observable<boolean>;

  private _selectedScope$: Subject<string> = new ReplaySubject(1);
  public selectedScope$: Observable<string> = this._selectedScope$.asObservable();

  public selectedScopeAndTenant$: Observable<{scope: string, tenant: string}>;

  public scopes$: Observable<string[]>;
  public scopeOptions$: Observable<Array<{ label: string, value: string }>>;

  constructor(
    private store$: Store<AppState>,
    activatedRoute: ActivatedRoute,
  ) {
    this.selectedTenantKey$ = this.store$.select(selectedTenantKeyView).pipe(
      filter(filterEmpty),
    );
    this.selectedScopeAndTenant$ = combineLatest([
      this.selectedScope$,
      this.selectedTenantKey$,
    ]).pipe(
      map(([scope, tenant]: [string, string]) => ({scope, tenant})),
    );
    this.inventories$ = this.store$.select(allInventoriesListView);

    this.hasViewSecretContentTenantLevelPermission$ = this.store$.select(hasViewSecretContentTenantAccessView);
    this.hasViewSecretContentInventoryLevelPermission$ = combineLatest([
      this.store$.select(getInventoriesWithViewSecretContentAccessView),
      this.selectedScope$,
    ]).pipe(
      map(([inventories, selectedScope]: [string[], string]) => _.includes(inventories, selectedScope)),
    );
    this.hasModifyInventoryPermission$ = combineLatest([
      this.store$.select(getInventoriesWithModifyInventoryAccessView),
      this.selectedScope$,
    ]).pipe(
      map(([inventories, selectedScope]: [string[], string]) => _.includes(inventories, selectedScope))
    );
    this.hasModifyTenantPermission$ = this.store$.pipe(select(hasTenantModificationAccessView));
    this.hasModifyTenantAndInventoryPermission$ = combineLatest([
      this.hasModifyTenantPermission$,
      this.hasModifyInventoryPermission$,
    ]).pipe(
      map(([hasModifyTenant, hasModifyInventory]: [boolean, boolean]): boolean => hasModifyTenant && hasModifyInventory),
    );

    this.scopes$ = combineLatest([
      this.inventories$,
      this.selectedTenantKey$,
    ]).pipe(
      distinctUntilChanged(),
      map(([inventories, tenantKey]: [Array<Inventory>, string]) => {
        const inventoryKeys = inventories.map(i => i.inventoryKey);
        return [tenantKey].concat(inventoryKeys);
      }),
    );
    this.scopeOptions$ = this.scopes$.pipe(
      withLatestFrom(this.selectedTenantKey$),
      map(([scopes, selectedTenantKey]: [string[], string]) => {
        return scopes.map(scope => ({value: scope, label: this.resolveScopeItemText(scope, selectedTenantKey)}));
      }),
    );

    this.preselectedResource$ = activatedRoute.queryParams.pipe(
      map((queryParams: Params): Maybe<PreselectedResource> => {
        if (queryParams[NavigationConstants.PARAM_RESOURCEID]
          && queryParams[NavigationConstants.PARAM_RESOURCETYPE] && queryParams[NavigationConstants.PARAM_SCOPE]) {
          return {
            resourceId: queryParams[NavigationConstants.PARAM_RESOURCEID],
            resourceType: queryParams[NavigationConstants.PARAM_RESOURCETYPE],
            scope: queryParams[NavigationConstants.PARAM_SCOPE],
          };
        }
        return undefined;
      }),
      distinctUntilChanged(),
    );
  }

  public updateSelectedScope(selectedScope: string) {
    this._selectedScope$.next(selectedScope);
  }

  public resolveScopeItemText(scope: string, selectedTenantKey: string): string {
    if (_.isEqual(scope, selectedTenantKey)) {
      return GLOBAL_CERTIFICATE_SELECTION;
    }
    return TenantHelper.cropTenantFromKey(scope);
  }

}

import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Mixin } from '../../decorators/mixin.decorator';
import { EventControlMixin, IEventControlMixin } from '../../mixins/event-control.mixin';

@Directive({
  selector: '[adm4StopEventPropagation]'
})
@Mixin([EventControlMixin])
export class StopEventPropagationDirective implements IEventControlMixin, OnChanges, OnDestroy {
  /**
   * List of event names to prevent
   */
  @Input() adm4StopEventPropagation: string[];

  private _subscriptions: Subscription[] = [];

  /**
   * Implemented by EventControlMixin
   */
  controlEvents: (element: HTMLElement, events: string[], onEvent: (event: Event) => void) => Subscription[];

  constructor(private elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.adm4StopEventPropagation) {
      this.stopPropagationOfEvents();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private stopPropagationOfEvents(): void {
    this._subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this._subscriptions = this.controlEvents(this.elementRef.nativeElement, this.adm4StopEventPropagation, (event: Event) => event.stopPropagation());
  }
}

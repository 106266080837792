import { Injectable } from '@angular/core';
import { PropertyWidgetContext } from '../property-widgets/property-widget.context';
import { Observable } from 'rxjs';
import { Dictionary } from '../model/reducer';
import { ResourceList } from '../patterns/pattern-attachment.model';
import { Project } from '../projects/project.model';
import { PatternType } from '../plugins/pattern-type.model';
import { Pattern } from '../patterns/pattern.model';
import { PropertyWidgetContextType } from '../property-widgets/property-widget-context-type.enum';

@Injectable({
  providedIn: 'root'
})
export class VariableContext implements PropertyWidgetContext {

  allPatternResources$: Observable<Dictionary<Dictionary<ResourceList>>>;
  currentProject$: Observable<Project | null>;
  patternTypes$: Observable<Dictionary<PatternType> | null>;
  patterns$: Observable<Map<string, Pattern>>;
  type = PropertyWidgetContextType.CREATE_VARIABLE;
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'adm4-column-header',
  template: `
            <div class="header-container" [ngClass]="styleClass">
                <ng-content></ng-content>
            </div>`,

  styleUrls: ['column-header.scss']
})
export class ColumnHeaderComponent {
  @Input() styleClass: string;
}

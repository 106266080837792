<div class="full-height-flex">
  <div class="step-sub-heading-with-actions">
    <div class='step-content-header'><mat-icon class='title' *ngIf="titleIcon">{{titleIcon}}</mat-icon>{{node.details.path}}</div>
  </div>
  <div class="remaining-space-flex-content-wrapper">
    <div class="remaining-space-flex-content">
      <div class="step-content-card item-details full-height" [ngClass]='boxShadowClass'>
        <p>
          <adm4-detail-message
                  [node]='node'
                  [outputType]='outputType'
                  [pattern]='pattern'
                  [projectKey]='projectKey'>
          </adm4-detail-message>
        </p>
        <p>
          <strong>Deployment phase</strong>
        </p>
        <p>It is scheduled for the <strong>{{node.details.phase}}</strong> phase of the deployment.</p>
        <p>
          <strong>{{node.type | capitalizeFirst}} attributes</strong>
        </p>
        <ul>
          <li>Owner: {{node.details.requested.owner}}</li>
          <li>Group: {{node.details.requested.group}}</li>
          <li>Permissions: {{node.details.requested.permissions}}</li>
        </ul>
        <ng-container *ngIf='node.details.deleteUnmanagedFiles'>
          <p>
            <strong>Deletion of unmanaged files</strong>
          </p>
          <p>Any files and sub-directories within this directory that are not part of the current deployment will be
            deleted.</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { DirtyFormGuardConnectorService } from '../../../common/services/dirty-form-guard-connector.service';
import { SaverService } from '../../../common/services/saver.service';

@Component({
  selector: 'adm4-project-summary-description',
  template: `
      <ng-template #markdownInfo>
          <p>Use this field to describe your project specifics. It supports markdown and has a limit of 10000 chars.</p>
      </ng-template>
      <ng-template #editDescription>
        <span>Edit project description</span>
      </ng-template>
      <ng-template #noEditPermission>
        <span>You don’t have a permission to edit the project description.</span>
      </ng-template>

      <section>
          <h4>
              <span>About Project</span>
              <button class="icon-button" type="button" *ngIf="!isEditing"
                      [disabled]="!hasEditPermission" (click)="startEditing()">
                <mat-icon [ngbTooltip]="hasEditPermission ? editDescription : noEditPermission" placement="right">edit</mat-icon>
              </button>
              <i class="fa fa-info-circle help-icon"
                 [ngbTooltip]="markdownInfo" placement='bottom-right'></i>
          </h4>

          <ng-container *ngIf="!isEditing">
              <div class="markdown-rendered" [innerHTML]="descriptionHtml" *ngIf="descriptionHtml"
                   [ngClass]="{'expanded': isExpanded}"></div>
              <p *ngIf="!descriptionHtml">
                Use this field to describe your project specifics. It supports markdown and has a limit of {{MAX_LENGTH}} chars.</p>
              <a *ngIf="!isExpanded && descriptionHtml" (click)="toggleExpanded()">Show more</a>
              <a *ngIf="isExpanded && descriptionHtml" (click)="toggleExpanded()">Show less</a>
          </ng-container>

          <form [formGroup]="form" *ngIf="isEditing">
                <textarea maxlength="{{MAX_LENGTH}}" class="form-control admn4-textarea-input description-textarea"
                          [formControlName]="'description'"></textarea>
              <div class="actions">
                  <adm4-validation-message *ngIf="!form.valid" [isError]="true"
                    message="You have exceeded the maximum character limit of 10000."></adm4-validation-message>
                  <div></div>
                  <button type="button" class="admn4-button-text" (click)="cancelEditing()">Cancel</button>
                  <button type="button" class="admn4-button-ellipse-blue" (click)="save()">Save</button>
              </div>
          </form>
      </section>
  `,
  styleUrls: ['./project-summary-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSummaryDescriptionComponent implements OnDestroy {

  private destroySub = new Subscription();

  readonly MAX_LENGTH = 10000;

  @Input() hasEditPermission: boolean = false;
  @Input() descriptionHtml: SafeHtml | undefined;
  @Input() descriptionPlain: string | undefined;

  @Output() descriptionSaved: EventEmitter<string | undefined> = new EventEmitter();

  readonly form: UntypedFormGroup;

  isEditing = false;
  isExpanded = false;

  constructor(
      private dirtyGuard: DirtyFormGuardConnectorService,
      formBuilder: UntypedFormBuilder,
      saverService: SaverService,
  ) {
    this.form = formBuilder.group({
      description: ['', [Validators.maxLength(this.MAX_LENGTH)]],
    });
    this.destroySub.add(saverService.onSave.subscribe(() => this.save()));
  }

  ngOnDestroy(): void {
    this.dirtyGuard.disconnect();
    this.destroySub.unsubscribe();
  }

  startEditing() {
    if (this.hasEditPermission) {
      this.form.setValue({description: this.descriptionPlain || ''});
      this.isEditing = true;
      this.dirtyGuard.connect(
          this.form.valueChanges.pipe(
              map(() => this.form.dirty),
          ),
          () => this.save()
      );
    }
  }

  cancelEditing() {
    this.dirtyGuard.disconnect();
    this.form.reset({description: this.descriptionPlain});
    this.isEditing = false;
  }

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  save() {
    if (this.hasEditPermission) {
      this.descriptionSaved.next(this.trimToUndefined(this.form.value['description']));
      this.isEditing = false;
      this.isExpanded = false;
      // the form needs to be reset so because the clean form will trigger `DirtyFormGuardConnectorService` to continue with the navigation
      this.form.reset();
    }
  }

  private trimToUndefined(input: unknown): string | undefined {
    if (typeof input === 'string') {
      const trimmed = input.trim();
      if (trimmed.length > 0) {
        return trimmed;
      }
    }
    return undefined;
  }

}

import { PublishInventoryActionTypes } from './publish-inventory.actions';
import { NevisAdminAction } from '../actions';
import { TextDiffData } from '../../common/model/publish-changes/text-diff-data.model';
import { Diff } from '../../common/model/publish-changes/diff.model';
import { InventoryMeta } from '../../inventory/inventory.model';


export interface PublishInventoryState {
  inventoryMeta: InventoryMeta | null;
  inventoryContentDiff: Diff<TextDiffData> | null;
}

export const initialState: PublishInventoryState = {
  inventoryContentDiff: null,
  inventoryMeta: null
};

export function reducer(state = initialState, action: NevisAdminAction<any>): PublishInventoryState {
  switch (action.type) {
    case PublishInventoryActionTypes.LoadInventoryMetaSuccess:
      return {
        ...state,
        inventoryMeta: action.payload
      };
    case PublishInventoryActionTypes.LoadInventoryContentDiffDataSuccess:
      return {
        ...state,
        inventoryContentDiff: action.payload
      };
    case PublishInventoryActionTypes.ClearPublishInventoryData:
      return initialState;
    default:
      return state;
  }
}

import * as _ from 'lodash';
import { ListRange } from '@angular/cdk/collections';

export class ScrollService {

  public static isElemVisible(scrollArea: HTMLElement, element: HTMLElement) {
    if (_.isNil(element)) {
      return false;
    }
    const contHeight = scrollArea.offsetHeight;
    const elemTop = element.getBoundingClientRect().top - scrollArea.getBoundingClientRect().top;
    const elemBottom = element.getBoundingClientRect().bottom - scrollArea.getBoundingClientRect().top;
    return elemTop < 0 || elemBottom > contHeight;
  }

  public static isIndexWithinRenderedElement(index: number, renderedElementsRange: ListRange) {
    if (_.isNil(renderedElementsRange)) {
      return true;
    }
    return index >= renderedElementsRange.start && index <= renderedElementsRange.end;
  }

  scrollIntoViewIfNeeded(scrollArea: HTMLElement, selectedElement: HTMLElement): void {
    try {
      this.scrollToElementIfOutsideViewPort(selectedElement, scrollArea);
    } catch (err) {
      console.warn('scrolling is not available!', err);
    }
  }

  private scrollToElementIfOutsideViewPort(element: HTMLElement, scrollArea: HTMLElement): void {
    if (ScrollService.isElemVisible(scrollArea, element)) {
      element.scrollIntoView(true);
    }
  }
}

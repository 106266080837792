import { Component, EventEmitter, Inject, Input, OnChanges, Output } from '@angular/core';
import { IssueModel } from '../../../common/model/issue.model';
import { ValidationTreeHelper } from './validation-tree.helper';
import { TreeNode } from '../../tree-viewer/tree-node.model';
import { Pattern } from '../../../patterns/pattern.model';
import { PatternIssueData } from '../../../issues/model/pattern-issue-data.model';
import { VariableModel } from '../../../variables/variable.model';
import { TREE_EXPAND, treeExpandProvider } from '../../tree-viewer/tree-expand.provider';
import { Dictionary } from '../../../model/reducer';
import { SplitPaneConfig } from '../../../common/model/split-pane-config.model';
import { IOutputData } from 'angular-split/lib/interface';
import { ResizeHelper } from '../../../common/helpers/resize.helper';
import { Mixin } from '../../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../../common/mixins/split.mixin';
import { InventorySchemaType } from '../../../inventory/inventory.model';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-validation-results',
  template: `
    <div class="full-height-flex">
      <h2 class='step-content-header'>Validation results</h2>
      <div class="remaining-space-flex-content-wrapper">
        <div class="remaining-space-flex-content">
          <as-split class='deployment-step-split as-split-handle-only as-split-handle-upper' direction='horizontal' gutterSize='5' useTransition (dragEnd)='onResize($event)'>
            <as-split-area [order]='splitPaneConfig[treeSplitAreaKey].order' [size]='splitPaneConfig[treeSplitAreaKey].size' [minSize]='20'>
              <div class="full-height split-primary-content">
                <div class='full-height-flex'>
                  <div class='validation-top-actions'>
                    <div>
                      <button *ngIf='shouldShowExpandAllButton'
                              (click)="expandAll()"
                              class="step-content-text-button">
                        Expand all <mat-icon class='step-content-text-button-icon'>add</mat-icon>
                      </button>
                    </div>
                    <button *ngIf='showGenerationButtonVisible'
                            class='admn4-button-text'
                            (click)='showGenerationResults.emit()'>
                      Generation results
                    </button>
                  </div>
                  <div class='remaining-space-flex-content-wrapper'>
                    <div class='remaining-space-flex-content'>
                      <div class='step-content-card margin-sm full-height' [ngClass]='boxShadowClass'>
                        <adm4-tree-viewer *ngIf='issueNodes?.length'
                                          [nodes]='issueNodes'
                                          [initialExpandNodeIds]='initialExpandNodeIds'
                                          [forceShowAllItems]="true"
                                          (nodeSelected)='onNodeSelect($event)'></adm4-tree-viewer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </as-split-area>
            <as-split-area [order]='splitPaneConfig[contentSplitAreaKey].order' [size]='splitPaneConfig[contentSplitAreaKey].size' [minSize]='40'>
              <div class="full-height split-secondary-content">
                <div class="step-content-card margin-sm full-height issues-panel position-relative" [ngClass]='boxShadowClass'>
                  <div class="position-absolute inset-0">
                    <adm4-general-issue-list
                            *ngIf='shouldDisplayGeneralIssues'
                            [generalIssues]='generalIssues'></adm4-general-issue-list>
                    <adm4-pattern-issue-group-list
                            [patternIssueGroups]='patternIssueGroups'
                            [projectKey]='projectKey'
                            [variables]='variables'></adm4-pattern-issue-group-list>
                  </div>
                </div>
              </div>
            </as-split-area>
          </as-split>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./validation-results.component.scss'],
  providers: [treeExpandProvider]
})
@Mixin([SplitMixin])
export class ValidationResultsComponent implements ISplitMixin, OnChanges {
  @Input() validationIssues: IssueModel[];
  @Input() generalIssues: IssueModel[];
  @Input() shouldDisplayGeneralIssues: boolean;
  @Input() patterns: Map<string, Pattern>;
  @Input() showGenerationButtonVisible: boolean;
  @Input() boxShadowClass: string;
  @Input() patternIssueGroups: PatternIssueData[];
  @Input() projectKey: string;
  @Input() variables: VariableModel[];
  @Input() selectedInventorySchemaType: InventorySchemaType;
  @Output() showGenerationResults: EventEmitter<void> = new EventEmitter();
  @Output() nodeSelected: EventEmitter<IssueModel[]> = new EventEmitter();
  issueNodes?: TreeNode[];
  initialExpandNodeIds: string[];

  readonly treeSplitAreaKey = 'tree';
  readonly contentSplitAreaKey = 'content';
  readonly splitPaneConfigLocalStorageKey = 'generation-issues-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.treeSplitAreaKey]: {order: 0, size: 30},
    [this.contentSplitAreaKey]: {order: 1, size: 70}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  constructor(@Inject(TREE_EXPAND) private treeExpand: EventEmitter<void>) {
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
  }

  onNodeSelect(node: TreeNode) {
    this.nodeSelected.emit(node.details || []);
  }

  ngOnChanges(): void {
    if (this.validationIssues) {
      this.issueNodes = this.createTreeNodesFromValidationIssues();
      this.initialExpandNodeIds = _.map(this.issueNodes, node => node.id);
    }
  }

  private createTreeNodesFromValidationIssues(): TreeNode[] | undefined {
    switch (this.selectedInventorySchemaType) {
      case InventorySchemaType.CLASSIC:
        return ValidationTreeHelper.getTreeFromValidationResult(this.validationIssues, this.patterns);
      case InventorySchemaType.KUBERNETES:
        return ValidationTreeHelper.getKubernetesTreeFromValidationResult(this.validationIssues, this.patterns);
    }
  }

  expandAll() {
    this.treeExpand.emit();
  }

  get shouldShowExpandAllButton(): boolean {
    return this.selectedInventorySchemaType === InventorySchemaType.CLASSIC && !_.isEmpty(this.issueNodes);
  }
}

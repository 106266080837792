import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IssueModel } from '../../model/issue.model';
import { ActivatedRoute } from '@angular/router';
import { MenuHelper } from '../../helpers/menu.helper';
import { NavigationConstants } from '../../constants/navigation.constants';
import { IssueSeverityCount } from '../../../projects/project-issues-display.model';
import { singularPlural } from '../../../projects/project-summary/project-summary.helper';

@Component({
  selector: 'adm4-project-issues-tooltip',
  template: `
  <ng-container *ngIf="showGeneral">
    <h4 class="header-general">General issues:</h4>
    <div *ngFor="let generalIssue of generalIssues;" class="issue-item general">
      <div class="icon-wrapper">
        <adm4-validation-indicator [issueSeverity]="generalIssue.severity" [isDisplayed]="true"></adm4-validation-indicator>
      </div>
      <span>{{generalIssue.message}}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="showPatternSeverityCount">
    <h4 class="header-pattern">Pattern issues:</h4>
    <div *ngIf="patternIssueSeverityCount.error > 0" class="issue-item pattern-error">
      <div class="icon-wrapper">
        <adm4-validation-indicator validationStatus="error" [isDisplayed]="true"></adm4-validation-indicator>
      </div>
      <span>{{errorCountLabel}}</span>
    </div>
    <div *ngIf="patternIssueSeverityCount.warning > 0" class="issue-item pattern-warn">
      <div class="icon-wrapper">
        <adm4-validation-indicator validationStatus="warning" [isDisplayed]="true"></adm4-validation-indicator>
      </div>
      <span>{{warnCountLabel}}</span>
    </div>
    <div *ngIf="patternIssueSeverityCount.info > 0" class="issue-item pattern-info">
      <div class="icon-wrapper">
        <adm4-validation-indicator validationStatus="info" [isDisplayed]="true"></adm4-validation-indicator>
      </div>
      <span>{{infoCountLabel}}</span>
    </div>
  </ng-container>

  <div class="actions" *ngIf='!issuesScreen;'>
    <a (click)='navigateToIssues.emit()' class="d-flex align-items-center">
      <mat-icon>label_outline</mat-icon>
      <span>View issues</span>
    </a>
  </div>
`,
  styleUrls: ['project-issues-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectIssuesTooltipComponent implements OnChanges {
  @Input() generalIssues: IssueModel[];
  @Input() patternIssueSeverityCount: IssueSeverityCount | null;

  @Output() navigateToIssues: EventEmitter<void> = new EventEmitter();

  showGeneral: boolean = false;
  showPatternSeverityCount = false;

  constructor(private route: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.generalIssues) {
      this.showGeneral = this.generalIssues && this.generalIssues.length > 0;
    }
    if (changes.patternIssueSeverityCount) {
      if (this.patternIssueSeverityCount) {
        const sum = this.patternIssueSeverityCount.info + this.patternIssueSeverityCount.warning + this.patternIssueSeverityCount.error;
        this.showPatternSeverityCount = sum > 0;
      } else {
        this.showPatternSeverityCount = false;
      }
    }
  }

  get issuesScreen() {
    return MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.ISSUES);
  }

  get infoCountLabel(): string {
    if (this.patternIssueSeverityCount) {
      return `${this.patternIssueSeverityCount.info} ${singularPlural(this.patternIssueSeverityCount.info, 'info', 'info')}`;
    }
    return '';
  }

  get warnCountLabel(): string {
    if (this.patternIssueSeverityCount) {
      return `${this.patternIssueSeverityCount.warning} ${singularPlural(this.patternIssueSeverityCount.warning, 'warning', 'warnings')}`;
    }
    return '';
  }

  get errorCountLabel(): string {
    if (this.patternIssueSeverityCount) {
      return `${this.patternIssueSeverityCount.error} ${singularPlural(this.patternIssueSeverityCount.error, 'error', 'errors')}`;
    }
    return '';
  }

}

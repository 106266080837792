import { PluginModel } from '../plugins/plugin.model';
import { PatternInstance } from './pattern-instance.model';

export function createNewPatternInstanceObject(plugin: PluginModel, patternName?: string): PatternInstance {
  return  {
    className : plugin.className,
    name: patternName || `New ${plugin.name}`
  } as PatternInstance;
}

/**
 * Needed for purposes of replacing patterns that could not be loaded from servers, e.g. to display issues of such pattern
 * @param {string} patternId
 * @returns {PatternInstance}
 */
export function createDummyPatternInstance(patternId: string): PatternInstance {
  return {
    patternId: patternId,
    name: patternId,
    properties: [],
    className: '',
    bundleKey: ''
  };
}

import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';

import { Inventory, InventorySchemaType } from '../inventory.model';
import { CreateInventoryDialogService } from '../create-inventory/create-inventory-dialog.service';
import { InventoryColorHelper } from '../../common/helpers/inventory-color.helper';
import { NavigationService } from '../../navbar/navigation.service';
import { DirtyFormGuardConnectorService } from '../../common/services/dirty-form-guard-connector.service';
import { InventoryHelper } from '../inventory.helper';
import { AppState } from '../../model/reducer';
import { CheckUpdatesOfInventory, ClearLocalChangesOfInventory } from '../../model/inventory';
import { MenuHelper } from '../../common/helpers/menu.helper';
import { NavigationConstants } from '../../common/constants/navigation.constants';
import { MenuElement } from '../../common/menu-element';
import { ImportInventoryDialogService } from '../import-inventory/import-inventory-dialog.service';
import { FileDownloader } from '../../common/helpers/file-downloader';
import { environment } from '../../../environments/environment';
import { DynamicLinkCreatorService } from '../../common/services/dynamic-link-creator.service';
import { SideMenuService } from '../../common/services/side-menu.service';
import { FileBasedImportInventoryDialogService } from '../file-based-import-inventory/file-based-import-inventory-dialog.service';
import { OperationKey } from '../../model/permissions/permissions.model';

@Component({
  selector: 'adm4-inventory-menu',
  template: `
    <div id='menu-items' class='full-height'>
      <div class="sidenav">
        <adm4-menu-element *ngFor='let navLink of navigationTabs' [menuElement]='navLink'></adm4-menu-element>
      </div>
      <adm4-side-menu-group-header text="actions" [collapsed]="sideMenuService.collapsed$ | async"></adm4-side-menu-group-header>
      <div class='inventory-operations scroll-shadows'>
        <adm4-menu-action *ngFor='let menuElement of inventoryActions' [menuElement]='menuElement'></adm4-menu-action>
      </div>
    </div>
  `,
  styleUrls: [
    '../../common/styles/component-specific/mat-menu.scss',
    './inventory-menu.component.scss',
  ]
})
export class InventoryMenuComponent implements OnChanges {
  @Input() currentInventory?: Inventory;
  @Input() inventories: Inventory[];
  @Input() canCreateInventory: boolean;
  @Input() readOnly: boolean;
  @ViewChild('matMenuTrigger', {read: ElementRef, static: false}) matMenuTrigger: ElementRef<HTMLElement>;

  /** Contains menu options which does NOT navigate the user away from the current screen (i.e. modal opening). */
  inventoryActions: MenuElement[];

  /** Contains menu options which navigate the user away from the current screen. */
  navigationTabs: MenuElement[];
  private readonly allNavigationTabs: MenuElement[];

  readonly getInventoryMenuItemClassName = (color: string) => InventoryColorHelper.getInventoryMenuItemClassName(color);

  isVersioned: boolean = false;

  constructor(private createInventoryService: CreateInventoryDialogService,
              private store$: Store<AppState>,
              private navigationService: NavigationService,
              private formGuardConnectorService: DirtyFormGuardConnectorService,
              private route: ActivatedRoute,
              private importInventoryDialogService: ImportInventoryDialogService,
              private linkCreatorService: DynamicLinkCreatorService,
              private fileBasedImportInventoryDialogService: FileBasedImportInventoryDialogService,
              public sideMenuService: SideMenuService,
  ) {
    this.inventoryActions = [
      {
        title: 'Create New',
        icon: 'create_inventory',
        invertedIcon: 'create_inventory-inv',
        useSvg: true,
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.createInventoryService.openCreateInventoryDialog()),
        displayCondition: () => this.canCreateInventory
      },
      {
        title: 'Export to Zip',
        icon: 'export_to_zip',
        invertedIcon: 'export_to_zip-inv',
        useSvg: true,
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => FileDownloader.downloadFileFromUrl(`${environment.baseUrl}/inventories/${this.currentInventoryKey}/file-export`)
        ),
      },
      {
        title: 'Import from Zip',
        icon: 'import_from_zip_blk',
        invertedIcon: 'import_from_zip_inv',
        useSvg: true,
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => this.fileBasedImportInventoryDialogService.openFileBasedImportInventoryDialog()
        ),
        displayCondition: () => {
          return this.canCreateInventory || this.inventories.some(inventory => _.includes(inventory._userAuthorization, OperationKey.MODIFY_INVENTORY));
        }
      },
      {
        title: 'Publish to Git',
        icon: 'file_upload',
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.navigationService.navigateToPublishInventoryWindow()),
        displayCondition: () => !_.isNil(this.currentInventory) && InventoryHelper.isVersionedInventory(this.currentInventory) && !this.readOnly
      },
      {
        title: 'Import from Git',
        icon: 'save_alt_outline',
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.importInventoryDialogService.openImportInventoryDialog()),
        displayCondition: () => {
          return this.canCreateInventory;
        }
      },
      {
        title: 'Update from Git',
        icon: 'update',
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.store$.dispatch(new CheckUpdatesOfInventory((<Inventory>this.currentInventory).inventoryKey))),
        displayCondition: () => !_.isNil(this.currentInventory) && InventoryHelper.isVersionedInventory(this.currentInventory) && !this.readOnly
      },
      {
        title: 'Clear local changes',
        icon: 'cancel',
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.clearLocalInventoryChanges()),
        displayCondition: () => this.isVersioned && !this.readOnly,
      },
    ];

    this.allNavigationTabs = [
      {
        title: 'Inventory Editor',
        icon: 'edit',
        event: () => this.currentInventoryKey ? this.navigationService.navigateToInventory(this.currentInventoryKey) : this.navigationService.navigateToInventories(),
        disabledCondition: () => _.isNil(this.currentInventory),
        displayCondition: () => true,
        selectedCondition: () => {
          return MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.INVENTORIES)
              && !MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.DEPLOYMENT_HISTORY)
              && !MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.KUBERNETES_STATUS)
              && !MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.INVENTORY_STATUS)
              && !MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.INVENTORY_SETTINGS);
        }
      },
      {
        title: 'Deployment History',
        icon: 'history',
        event: () => this.navigationService.navigateToInventoryDeploymentHistory(this.currentInventoryKey as string),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.DEPLOYMENT_HISTORY),
        displayCondition: () => true,
        disabledCondition: () => _.isNil(this.currentInventory),
      },
      {
        title: 'Kubernetes Status',
        icon: 'cloud',
        displayCondition: () => this.isKubernetesInventory(),
        event: () => this.navigationService.navigateToInventoryKubernetesStatus(this.currentInventoryKey as string),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.KUBERNETES_STATUS),
        disabledCondition: () => _.isNil(this.currentInventory),
      },
      {
        title: 'Host Status ',
        icon: 'insert_drive_file',
        displayCondition: () => !this.isKubernetesInventory(),
        event: () => this.navigationService.navigateToInventoryStatus(this.currentInventoryKey as string),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.INVENTORY_STATUS),
        disabledCondition: () => _.isNil(this.currentInventory),
      },
      {
        title: 'Settings',
        icon: 'inventory_settings',
        useSvg: true,
        href: this.linkCreatorService.inventorySettingsMenuLink,
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => this.navigationService.navigateToInventorySettings(this.currentInventory?.inventoryKey)
        ),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.INVENTORY_SETTINGS),
        displayCondition: () => true,
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentInventory']) {
      this.navigationTabs = this.allNavigationTabs.filter((menuElement: MenuElement) => menuElement.displayCondition?.());
      this.isVersioned = !!this.currentInventory && InventoryHelper.isVersionedInventory(this.currentInventory);
    }
  }

  get currentInventoryKey(): string | null {
    return _.isNil(this.currentInventory) ? null : this.currentInventory.inventoryKey;
  }

  public isKubernetesInventory() {
    return _.isEqual(InventorySchemaType.KUBERNETES, this.currentInventory?.schemaType);
  }

  private clearLocalInventoryChanges() {
    if (this.currentInventory) {
      this.store$.dispatch(new ClearLocalChangesOfInventory(this.currentInventory.inventoryKey));
    }
  }
}

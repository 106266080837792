import { FAKE_VARIABLE_ISSUE_CODE, IssueModel, SourceType, Target } from '../../common/model/issue.model';
import * as _ from 'lodash';
import { PrimaryIssueUniqueCriteria, VariableIssueUniqueCriteria } from './issue-unique-criteria.model';
import { getHighestSeverityBetweenIssues, getOptionalTargetValue, getSpecificTargetOfIssue } from './project-issues.helper';
import { VariableModel } from '../../variables/variable.model';

export class IssueReducingHelper {
  /**
   * Returns object which contains criteria based on which we can decide if issue is unique
   * @param {IssueModel} issue
   * @returns {PrimaryIssueUniqueCriteria}
   */
  private static getIssueUniqueCriteria(issue: IssueModel): PrimaryIssueUniqueCriteria {
    const issuePattern = issue.target.find(target => target.sourceType === SourceType.PATTERN);
    const issueProperty = issue.target.find(target => target.sourceType === SourceType.PROPERTY);
    const issueField = issue.target.find(target => target.sourceType === SourceType.FIELD);
    return {
      code: issue.code,
      message: issue.message,
      patternId: getOptionalTargetValue(issuePattern),
      propertyName: getOptionalTargetValue(issueProperty),
      field: getOptionalTargetValue(issueField)
    };
  }

  /**
   * Returns object which contains criteria based on which we can decide if issue is unique based on variable
   * If issue does not come from variable then it returns null
   * @param issue
   */
  private static getVariableIssueCriteria(issue: IssueModel): VariableIssueUniqueCriteria | null {
    const issueProject = issue.target.find(target => target.sourceType === SourceType.PROJECT);
    const issueVariable = issue.target.find(target => target.sourceType === SourceType.VARIABLE);
    if (_.isNil(issueProject) || _.isNil(issueVariable)) {
      return null;
    }
    return {
      code: issue.code,
      message: issue.message,
      project: getOptionalTargetValue(issueProject),
      variable: getOptionalTargetValue(issueVariable)
    };
  }

  /**
   * Returns boolean value which defines whether issues can be considered as equal by primary criteria, e.g. it is same property or field on same pattern, but comes from different deployable patterns
   * @param issue1
   * @param issue2
   */
  private static compareIssuesPrimaryCriteria(issue1: IssueModel, issue2: IssueModel): boolean {
    const issue1Criteria = this.getIssueUniqueCriteria(issue1);
    const issue2Criteria = this.getIssueUniqueCriteria(issue2);
    return _.isEqual(issue1Criteria, issue2Criteria);
  }

  /**
   * Returns boolean value which defines whether issues can be considered as equal by variable criteria, e.g. issues are coming for same variable but are duplicated because of multiple usages of the variable
   * If issue does not have variable target, then issues considered unique - returns false
   * @param issue1
   * @param issue2
   */
  private static isIssueFromSameVariable(issue1: IssueModel, issue2: IssueModel): boolean {
    const issue1Criteria = this.getVariableIssueCriteria(issue1);
    const issue2Criteria = this.getVariableIssueCriteria(issue2);
    if (_.isNil(issue1Criteria) || _.isNil(issue2Criteria)) {
      return false;
    }
    return _.isEqual(issue1Criteria, issue2Criteria);
  }

  /**
   * Compares issues by different criteria, returns true if issues can be considered equal and should be merged, false returned otherwise
   * @param issue1
   * @param issue2
   */
  static compareIssuesForIdentity(issue1: IssueModel, issue2: IssueModel): boolean {
    const criterias: ((issue1: IssueModel, issue2: IssueModel) => boolean)[] = [
      this.compareIssuesPrimaryCriteria.bind(this),
      this.isIssueFromSameVariable.bind(this)
    ];
    return criterias.some(criteria => criteria(issue1, issue2));
  }

  /**
   * Returns list of issues excluding duplicates by certain criteria, it merges lists of targets of duplicated issues into a single list
   * @param {IssueModel[]} issues
   *
   * @returns {IssueModel[]}
   */
  static getUniqueIssues(issues: IssueModel[]): IssueModel[] {
    return issues.reduce((uniqueIssues: IssueModel[], issue: IssueModel) => {
      const duplicateIssue = uniqueIssues.find(uniqueIssue => this.compareIssuesForIdentity(issue, uniqueIssue));
      if (_.isNil(duplicateIssue)) {
        return _.concat(uniqueIssues, [issue]);
      } else {
        const remainingUniqueIssues = uniqueIssues.filter(uniqueIssue => uniqueIssue !== duplicateIssue);
        const unifiedUniqueIssue = Object.assign({}, duplicateIssue, {
          target: _(duplicateIssue.target).concat(issue.target).uniqWith(_.isEqual).value()
        });
        return _.concat(remainingUniqueIssues, [unifiedUniqueIssue]);
      }
    }, []);
  }
  /**
   * Returns list of issues, while replacing variable issues with fake issues when targeted variable exists
   * The duplicates are removed (only severity is not considered when checking for duplicates - it selects issue with the highest severity)
   * @param issues
   * @param variables
   */
  static fakeVariableIssues(issues: IssueModel[], variables: VariableModel[]): IssueModel[] {
    return issues.reduce((uniqueIssues: IssueModel[], nextIssue: IssueModel) => {
      const variableTarget: Target | undefined = getSpecificTargetOfIssue(nextIssue, SourceType.VARIABLE);
      const targetedVariable: VariableModel | undefined = _.isNil(variableTarget) ? undefined : variables.find(variable => variable.variableKey === variableTarget.value);
      if (!_.isNil(variableTarget) && !_.isNil(targetedVariable)) {
        const fakeVariableIssue = this.fakeVariableIssue(nextIssue);
        const duplicateFakeVariableIssue: IssueModel | undefined = uniqueIssues.find(issue => _.isEqual(this.createVariableDuplicateComparisionObject(issue), this.createVariableDuplicateComparisionObject(fakeVariableIssue)));
        const issueWithCorrectSeverity = _.isNil(duplicateFakeVariableIssue) ? fakeVariableIssue : {...fakeVariableIssue, severity: getHighestSeverityBetweenIssues(fakeVariableIssue, duplicateFakeVariableIssue)};
        return uniqueIssues.filter(issue => issue !== duplicateFakeVariableIssue).concat(issueWithCorrectSeverity);
      }
      return uniqueIssues.concat(nextIssue);
    }, []);
  }

  private static createVariableDuplicateComparisionObject(issue: IssueModel): Partial<IssueModel> {
    return _.pickBy(issue, (_value: IssueModel[keyof IssueModel], key: keyof IssueModel) => key !== 'severity');
  }

  private static fakeVariableIssue(issue: IssueModel): IssueModel {
    return {
      ...issue,
      message: 'There is an issue with this property. Please click the variable for details.',
      code: FAKE_VARIABLE_ISSUE_CODE
    };
  }
}

import * as _ from 'lodash';
import { PropertyType } from '../plugins/property-type.model';
import { PropertyFormValueConverter } from '../property-widgets/property-form-value-converter';
import { PropertyTypeEnum } from '../common/constants/app.constants';
import { VariableModel } from './variable.model';
import { Property } from '../plugins/pattern-type.model';
import { Dictionary } from '../model/reducer';
import {SetVariableData} from '../set-variable/set-variable-data.model';

export class VariablesHelper {

  static readonly SECRET_VARIABLE_CONST = 'secret';
  static readonly SECRET_VARIABLE_SAMPLE_VALUE = 'sample password';


  static getNormalizedVariableValue(variableValue: any, propertyType: PropertyType | undefined): any {
    return _.isString(variableValue) && _.isEmpty(variableValue) ? undefined : PropertyFormValueConverter.toPropertyValue(propertyType, variableValue);
  }

  static canVariableHaveSampleValue(variableType?: PropertyType): boolean {
    return _.isNil(variableType) || variableType.uiComponent !== PropertyTypeEnum.AttachmentPropertyComponent;
  }

  static isSecretVariable(variable?: VariableModel | Property): boolean {
    if (_.isNil(variable) || _.isNil(variable.parameters)) {
      return false;
    }

    return variable.parameters[VariablesHelper.SECRET_VARIABLE_CONST];
  }

  static checkUiComponentEquality(newVariableData: SetVariableData, existingVariable: VariableModel, allVariableTypes: Dictionary<PropertyType>): boolean {
    if (_.isNil(existingVariable) || _.isNil(newVariableData.variableType) || _.isNil(newVariableData.variableType.uiComponent) ||
      _.isNil(allVariableTypes) || _.isNil((allVariableTypes)[existingVariable.className]) || _.isNil((allVariableTypes)[existingVariable.className].uiComponent)) {
      return true;
    }

    const existingVariableClassName = existingVariable.className;
    const uiComponent: string = newVariableData.variableType.uiComponent;
    const existingVariableUiComponent: string|undefined = allVariableTypes[existingVariableClassName].uiComponent;

    return _.isEqual(uiComponent, existingVariableUiComponent);
  }
}

import { NgModule } from '@angular/core';
import { PatternListElementComponent } from './pattern-list-element.component';
import { PatternMasterListComponent } from './pattern-master-list.component';
import { PatternErrorListHoverComponent } from './pattern-error-list-hover.component';
import { CommonModules } from '../common/common.module';
import { CreatePatternModule } from './create-pattern/create-pattern.module';
import { CreatePatternService } from './create-pattern/create-pattern.service';
import { PatternListComponent } from './pattern-list/pattern-list.component';
import { ModalDialogModule } from '../modal-dialog/modal-dialog.module';
import { ProjectMenuModule } from '../projects/project-menu/project-menu.module';
import { PatternHelpComponent } from './pattern-help.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BatchActionModule } from './batch-actions/batch-action.module';
import { GroupedPatternListContext } from './pattern-list/grouped-pattern-list.context';
import { BatchActionMenuModule } from './batch-actions-menu/batch-action-menu.module';
import { MatTabsModule } from '@angular/material/tabs';
import { PatternHierarchyTreeComponent } from './pattern-hierarchy/pattern-hierarchy-tree.component';
import { PatternHierarchyTreeItemDetailComponent } from './pattern-hierarchy/pattern-hierarchy-tree-item-detail/pattern-hierarchy-tree-item-detail.component';
import { PatternHierarchyContext } from './pattern-hierarchy/pattern-hierarchy-context';
import { MatTreeModule } from '@angular/material/tree';

@NgModule({
  imports: [
    CommonModules,
    CreatePatternModule,
    ModalDialogModule,
    ProjectMenuModule,
    ScrollingModule,
    BatchActionModule,
    BatchActionMenuModule,
    MatTabsModule,
    MatTreeModule
  ],
  declarations: [
    PatternMasterListComponent,
    PatternListElementComponent,
    PatternErrorListHoverComponent,
    PatternListComponent,
    PatternHelpComponent,
    PatternHierarchyTreeComponent,
    PatternHierarchyTreeItemDetailComponent,
  ],
  providers: [
    CreatePatternService,
    GroupedPatternListContext,
    PatternHierarchyContext
  ],
  exports: [
    PatternErrorListHoverComponent,
    PatternMasterListComponent,
    PatternListComponent,
    PatternHelpComponent
  ]
})
export class PatternModule {
}

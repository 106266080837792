import { NevisAdminAction } from './actions';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { JobService } from '../shared/job.service';
import { LoadingService } from '../modal-dialog/loading-modal/loading.service';
import { LoadingModalData } from '../modal-dialog/loading-modal/loading-modal-data.model';

export interface IImportEffectMixin {
  actions$: Actions<NevisAdminAction<any>>;
  loadingService: LoadingService;
  jobService: JobService;

  importEffect: <P, SuccessAction extends NevisAdminAction<any>, ErrorAction extends NevisAdminAction<any>>(
    importActionType: string,
    serviceFn: (payload: P) => Observable<string>,
    handleSuccess: (payload: P) => SuccessAction,
    handleError: (err: HttpErrorResponse, payload: P) => Observable<ErrorAction>,
    keyExtractFunction: (payload: P) => string,
    importedType: string,
    importSource: string
  ) => Observable<SuccessAction | ErrorAction>;
}

export class ImportEffectMixin implements IImportEffectMixin {
  actions$: Actions<NevisAdminAction>;
  loadingService: LoadingService;
  jobService: JobService;

  private static createLoadingModalData(importedType: string, importSource: string, key: string): LoadingModalData {
    return {
      title: `Importing ${key} ${importedType} from ${importSource}`,
      description: `Please wait while your ${importedType} is being imported.`
    };
  }

  importEffect<P, SuccessAction extends NevisAdminAction<any>, ErrorAction extends NevisAdminAction<any>>(
    importActionType: string,
    serviceFn: (payload: P) => Observable<string>,
    handleSuccess: (payload: P) => SuccessAction,
    handleError: (err: HttpErrorResponse, payload: P) => Observable<ErrorAction>,
    keyExtractFunction: (payload: P) => string,
    importedType: string,
    importSource: string
  ): Observable<SuccessAction | ErrorAction> {
    return this.actions$
      .pipe(
        ofType(importActionType),
        map((action: NevisAdminAction) => action.payload),
        tap((payload: P) => {
          const loadingData: LoadingModalData = ImportEffectMixin.createLoadingModalData(importedType, importSource, keyExtractFunction(payload));
          this.loadingService.showLoading(loadingData);
        }),
        switchMap((payload: P) => {
          return serviceFn(payload).pipe(
            mergeMap((jobUrl: string) => this.jobService.pollJob(jobUrl)),
            map(() => handleSuccess(payload)),
            catchError((err: HttpErrorResponse) => handleError(err, payload)),
            finalize(() => this.loadingService.hideLoading()));
        })
      );
  }
}

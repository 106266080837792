import { VersionControlForm } from '../../../common/components/version-control-form/version-control.form';

export const DEPLOYMENT_REPOSITORY_FORM_CONTROL_NAME = 'deploymentRepository';
export const K8S_CLUSTER_URL_FORM_CONTROL_NAME = 'k8sClusterUrl';
export const K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME = 'k8sClusterToken';
export const K8S_CLUSTER_NAMESPACE_FORM_CONTROL_NAME = 'k8sClusterNs';

export interface KubernetesInventoryForm {
  [DEPLOYMENT_REPOSITORY_FORM_CONTROL_NAME]: VersionControlForm;
  [K8S_CLUSTER_URL_FORM_CONTROL_NAME]: string;
  [K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME]: string;
  [K8S_CLUSTER_NAMESPACE_FORM_CONTROL_NAME]: string;
}

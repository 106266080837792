import { MenuHelper } from '../helpers/menu.helper';

/**
 * Allows to share the workaround for mat-icon-button, that triggers menu to properly lose focus on close
 */
export interface IMatMenuFocusFixMixin {
  onMenuClosed: (matMenuTrigger: any) => void;
}

export class MatMenuFocusFixMixin implements IMatMenuFocusFixMixin {
  onMenuClosed(matMenuTrigger: any): void {
    MenuHelper.onMenuClosed(matMenuTrigger);
  }
}

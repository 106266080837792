import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { Inventory } from '../../inventory.model';
import { DeployedHostError, DeploymentInstance, DeploymentInstanceActionModel, DeploymentInstanceActionType } from '../inventory-status-item.model';
import { DeploymentInstanceTableModel } from './inventory-deployment-instance-table.model';
import { Pattern } from '../../../patterns/pattern.model';
import { Observable } from 'rxjs';
import { AppState } from '../../../model/reducer';
import { patternsView } from '../../../model/views';
import { select, Store } from '@ngrx/store';
import { NavigationService } from '../../../navbar/navigation.service';

@Component({
  selector: 'adm4-inventory-deployment-instance-table',
  templateUrl: './inventory-deployment-instance-table.component.html',
  styleUrls: [
    '../../../inventory/inventory-deployment-history/inventory-deployment-history-table/inventory-deployment-history-table.component.scss',
    '../../../inventory/inventory-deployment-history/inventory-deployment-history-table/inventory-deployment-details/inventory-deployment-details.component.scss',
    './inventory-deployment-instance-table.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryDeploymentInstanceTableComponent implements OnChanges {
  @Input() deploymentInstances: DeploymentInstance[];
  @Input() currentInventory: Inventory;
  @Input() hasDeployPermission: boolean;
  @Input() displayedColumns: DeploymentInstanceTableModel[];
  @Input() deployedHostErrors: DeployedHostError[] = [];
  @Output() triggerDeployAction: EventEmitter<DeploymentInstanceActionModel> = new EventEmitter();
  patterns$: Observable<Map<string, Pattern>>;
  expandedDeploymentIndexes: number[];

  tableDataSource: MatTableDataSource<DeploymentInstance> = new MatTableDataSource([]);

  readonly deploymentDetailsColumnName = 'deploymentDetails';
  readonly deploymentInstanceTableColumns = DeploymentInstanceTableModel;
  expandableColumns = [this.deploymentDetailsColumnName];

  constructor(
              private navigationService: NavigationService,
              private store$: Store<AppState>
  ) {
    this.patterns$ = this.store$.pipe(select(patternsView));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deploymentInstances) {
      this.tableDataSource.data = this.deploymentInstances;
    }
  }

  triggerDeploymentAction(inventoryStatusItem: DeploymentInstance, deploymentInstanceActionType: DeploymentInstanceActionType): void {
    const deploymentInstanceActionModel: DeploymentInstanceActionModel = {instance: inventoryStatusItem, action: deploymentInstanceActionType};
    this.triggerDeployAction.emit(deploymentInstanceActionModel);
  }

  isDeploymentExpanded(deploymentInstance: DeploymentInstance): boolean {
    const selectedDeploymentIndex = this.tableDataSource.data.findIndex(deployment => _.isEqual(deployment, deploymentInstance));
    return _.includes(this.expandedDeploymentIndexes, selectedDeploymentIndex);
  }

  toggleDeploymentDetails(deploymentInstance: DeploymentInstance): void {
    if (!this.hasDetailedContent(deploymentInstance)) {
      return;
    }
    const selectedDeploymentIndex = this.tableDataSource.data.findIndex(deployment => _.isEqual(deployment, deploymentInstance));
    if (this.isDeploymentExpanded(deploymentInstance)) {
      this.expandedDeploymentIndexes = this.expandedDeploymentIndexes.filter((deploymentIndex: number) => deploymentIndex !== selectedDeploymentIndex);
    } else {
      this.expandedDeploymentIndexes = _.concat(this.expandedDeploymentIndexes, selectedDeploymentIndex);
    }
  }

  getPrimaryAction(deploymentInstanceActions: DeploymentInstanceActionType[]): DeploymentInstanceActionType {
    return deploymentInstanceActions[0];
  }

  getSecondaryActions(deploymentInstanceActions: DeploymentInstanceActionType[]): DeploymentInstanceActionType[] {
    return deploymentInstanceActions.filter(action => !_.isEqual(action, this.getPrimaryAction(deploymentInstanceActions)));
  }

  hasDetailedContent(deploymentInstance: DeploymentInstance): boolean {
    return !_.isNil(deploymentInstance.projectKey) && !_.isEmpty(deploymentInstance.projectKey);
  }

  hasMultipleAction(actions: DeploymentInstanceActionType): boolean {
    return !_.isNil(actions) && actions.length > 1;
  }

  navigateToPattern(projectKey: string, patternId: string): void {
    this.navigationService.navigateToPattern(projectKey, patternId);
  }

  getDeploymentInstanceErrorByHost(deploymentHost: string): DeployedHostError | undefined {
    return this.deployedHostErrors.find(error => _.isEqual(error.host, deploymentHost));
  }

  resolveDeploymentErrorIconClass(deploymentHost: string): string | undefined {
    const deploymentInstanceError: DeployedHostError | undefined = this.getDeploymentInstanceErrorByHost(deploymentHost);
    if (_.isNil(deploymentInstanceError)) {
      return;
    }
   return deploymentInstanceError.isError ? 'error-icon' : 'warning-icon';
  }

  resolveDeploymentErrorStatusIcon(deploymentHost: string): string | undefined {
    const deploymentInstanceError: DeployedHostError | undefined = this.getDeploymentInstanceErrorByHost(deploymentHost);
    if (_.isNil(deploymentInstanceError)) {
      return;
    }
    return deploymentInstanceError.isError ? 'error_outline' : 'report_problem';
  }

  displayedDeploymentErrorTooltip(deploymentHost: string): string | undefined {
    const deploymentInstanceError: DeployedHostError | undefined = this.deployedHostErrors.find(error => _.isEqual(error.host, deploymentHost));
    if (_.isNil(deploymentInstanceError)) {
      return;
    }
    return deploymentInstanceError.detail;
  }

  shouldDisplayPatternId(deploymentInstance: DeploymentInstance): boolean {
    return !_.isNil(deploymentInstance.patternId) && !_.isEmpty(deploymentInstance.patternId);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { PathHelper } from '../helpers/path.helper';

@Pipe({
  name: 'parentPath'
})
export class ParentPathPipe implements PipeTransform {
  transform(path: string): string {
    return PathHelper.getParentPath(path);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { TextDiffData } from '../../../common/model/publish-changes/text-diff-data.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import { PublishInventoryContext } from '../publish-inventory.context';
import { inventoryContentDiffView } from '../../../model/views';

@Injectable()
export class InventoryContentDiffViewContext {
  inventoryContentDiff$: Observable<Diff<TextDiffData> | null>;
  currentBranch$: Observable<string | null>;

  constructor(private store$: Store<AppState>, private publishInventoryContext: PublishInventoryContext) {
    this.inventoryContentDiff$ = this.store$.pipe(select(inventoryContentDiffView));
    this.currentBranch$ = this.publishInventoryContext.currentBranch$;
  }
}

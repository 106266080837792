import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Project } from '../../../projects/project.model';
import { DeploymentSelectionListComponent } from '../deployment-selection-list/deployment-selection-list.component';
import * as _ from 'lodash';
import { PatternMasterListHelper } from '../../../patterns/pattern-master-list.helper';
import { TenantHelper } from '../../../common/helpers/tenant.helper';
import { DeployToOption } from '../deploy-to-option.model';
import { DeploymentActivityIndicator } from '../../../common/services/deployment-activity-context.service';
import { Maybe } from '../../../common/utils/utils';

@Component({
  selector: 'adm4-project-list',
  template: `
    <div class='full-height-flex'>
      <h2 class='step-content-header'>Select project</h2>
      <div *ngIf='noAvailableOptions; else itemsList' class='step-content-info'>There are no projects to display.</div>
      <ng-template #itemsList>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <div class="list-group default-inventory-box-shadow">
              <div class='search-filter'>
                <adm4-filter (filter)="filterBySearch($event)"
                             [placeholderText]='"Type to filter"'
                             [filterText]="filterText"
                             class='search-input'>
                </adm4-filter>
              </div>
              <div *ngIf='shouldShowEmptyFilterResultMessage' class='no-result-found-text'>No project found.</div>
              <div class='selection-list'>
                <ng-container *ngFor='let project of filteredItems; let i = index'>
                  <adm4-deployment-project-list-item
                    [project]="project"
                    [selectedProjectKey]="preSelectedProjectKey"
                    [isClassicDeployment]="isClassicDeployment"
                    [classicDeployPatterns]="classicDeployPatterns"
                    [deploymentActivity]="deploymentActivity"
                    (kbSelection)="keyDown($event, i)"
                    (selected)="selectItem(project, i)"
                    (instancePatternSelected)="triggerInstancePatternSelection($event)"
                  ></adm4-deployment-project-list-item>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: [
    '../deployment-selection-list/deployment-selection-list.component.scss'
  ],
})
export class ProjectListComponent extends DeploymentSelectionListComponent<Project> implements OnChanges {
  @Input() preSelectedProjectKey: string | undefined;
  @Input() isClassicDeployment: boolean;
  @Input() classicDeployPatterns: DeployToOption[];
  @Input() public deploymentActivity: Maybe<DeploymentActivityIndicator>;
  @Output() instancePatternSelected: EventEmitter<any> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.activeItemIndex = _.findIndex(this.items, (item: Project) => item.projectKey === this.preSelectedProjectKey);
      if (this.preSelectedProjectKey && this.activeItemIndex >= 0) {
        this.itemSelected.emit(this.items[this.activeItemIndex]);
      }
      this.filteredItems = this.getFilteredItemList();
    }
  }

  override getFilteredItemList = () => {
    return PatternMasterListHelper.getWildCardFilteredItemList(this.items, this.filterText, (project) => TenantHelper.cropTenantFromKey(project.projectKey));
  };

  triggerInstancePatternSelection(selectedDeployToOption: DeployToOption): void {
    this.instancePatternSelected.emit(selectedDeployToOption);
  }
}

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../model/reducer';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { projectKeyView } from '../model/views';
import { NavigationService } from '../navbar/navigation.service';
import * as _ from 'lodash';
import { IssueModel } from '../common/model/issue.model';
import { LoadIssuesSuccess } from '../model/project';
import { ProjectService } from '../projects/project.service';

@Injectable({
  providedIn: 'root'
})
export class IssueGuard  {

  constructor(private store$: Store<AppState>,
              private navigationService: NavigationService,
              private projectService: ProjectService) {
  }

  canActivate(): Observable<boolean> {
    return this.canActivateIssueScreen();
  }

  private canActivateIssueScreen(): Observable<boolean> {

    return this.store$.pipe(
      select(projectKeyView),
      filter((projectKey: string | null) => !_.isNil(projectKey)),
      mergeMap((projectKey: string) => {
        return this.projectService.getAllIssuesOfProject(projectKey).pipe(
          tap(loadedIssues => this.store$.dispatch(new LoadIssuesSuccess(loadedIssues)))
        );
      }),
      withLatestFrom(this.store$.pipe(select(projectKeyView))),
      map(([issues, projectKey]: [IssueModel[], string | null]) => this.canActivateBasedOnData(issues, projectKey))
    );
  }

  private canActivateBasedOnData(issues: IssueModel[], projectKey: string | null): boolean {

    if (_.isNil(projectKey)) {
      // Should not happen since user can only got to issue screen when project is selected first.
      return false;
    }

    if (_.isEmpty(issues)) {
      // If there are no issues, navigate to project summary screen
      setTimeout(() => this.navigationService.navigateToProjectSummary(projectKey, true));
      return false;
    }

    return true;
  }
}

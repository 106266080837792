import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { Maybe } from '../common/utils/utils';

@Injectable()
export class SystemApiService extends BaseService {

  public static createGetSystemLogsUrl(sinceSeconds?: number, tailLines?: number): {url: string, params: HttpParams} {
    const url = '/log';
    let params: HttpParams = new HttpParams();
    if (sinceSeconds) {
      params = params.set('sinceSeconds', sinceSeconds.toString());
    }
    if (tailLines) {
      params = params.set('tailLines', tailLines.toString());
    }
    return {url, params};
  }

  public getSystemLogs(sinceSeconds?: number, tailLines?: number): Observable<Maybe<string>> {
    const {url, params} = SystemApiService.createGetSystemLogsUrl(sinceSeconds, tailLines);
    return this.httpGetText(url, params);
  }
}

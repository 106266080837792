import { ALL_INSTANCE_PATTERNS_EXPRESSION_NAME, DeployToClassicOptionType, DeployToOption, LAST_SELECTED_INSTANCE_PATTERNS_EXPRESSION_NAME } from '../deploy-to-option.model';
import * as _ from 'lodash';

export class DeploymentTargetInstanceHelper {
  static getMergedPatternInstancesHostExpression(deployToOptions: DeployToOption[]): string {
    return _.map(deployToOptions, patternInstance => patternInstance.hostExpression).toString();
  }

  /**
   * Calculates a label for the selected pattern instances, separated by a comma.<br/>
   * Since no selection means all of them will be deployed,
   * {@link ALL_INSTANCE_PATTERNS_EXPRESSION_NAME} will be returned in that case.
   * @param deployToOptions
   */
  static getMergedSelectedPatternInstancesName(deployToOptions: DeployToOption[]): string {
    const joined = _.join(_.map(deployToOptions, patternInstance => patternInstance.name), ', ');
    return joined || ALL_INSTANCE_PATTERNS_EXPRESSION_NAME;
  }

  static getLastSelectedPatternInstanceOption(storedInstancePatternsHostExpression: string | undefined): DeployToOption {
    return {
      type: DeployToClassicOptionType.Pattern,
      name: LAST_SELECTED_INSTANCE_PATTERNS_EXPRESSION_NAME,
      hostExpression: storedInstancePatternsHostExpression
    };
  }

  static getAllHostPatternInstanceOption(): DeployToOption {
    return {
      type: DeployToClassicOptionType.Pattern,
      name: ALL_INSTANCE_PATTERNS_EXPRESSION_NAME,
      hostExpression: ''
    };
  }
}

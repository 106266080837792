import { KubernetesInventoryContent } from '../create-inventory/create-inventory-payload';
import { InventorySchemaType } from '../inventory.model';
import * as _ from 'lodash';
import * as yaml from 'js-yaml';
import { Pattern } from '../../patterns/pattern.model';
import { VariableModel } from '../../variables/variable.model';
import { InventorySampleContentCommonHelper } from './inventory-sample-content-common.helper';

export class InventoryKubernetesSampleContentHelper {

  static createInitialKubernetesInventoryContent(includeSample: boolean,
                                                 kubernetesInventoryContent?: KubernetesInventoryContent,
                                                 patterns?: Pattern[],
                                                 variables?: VariableModel[]): string {

    let content = `schemaType: ${InventorySchemaType.KUBERNETES}
schemaVersion: ${1}

`;

    if (includeSample) {
      content += InventoryKubernetesSampleContentHelper.createSample(patterns, variables) + '\n';
    }

    let kubernetesContent = yaml.safeDump(kubernetesInventoryContent);


    kubernetesContent = InventoryKubernetesSampleContentHelper.fixTsYamlNewLineIssue(kubernetesContent);
    if (!_.isNil(kubernetesContent)) {
      content += kubernetesContent;
    }

    return content;
  }

  static createSample(patterns?: Pattern[], variables?: VariableModel[]) {
    const servicesPart = this.createServicesBlock(patterns);
    const variablesPart = InventorySampleContentCommonHelper.createVariablesSampleBlock(variables);

    const parts = [servicesPart];
    if (variablesPart) {
      parts.push(variablesPart);
    }

    // Add a new line between services and vars part.
    return parts.join('\n');
  }

  private static createServicesBlock(patterns?: Pattern[]): string {
    if (_.isNil(patterns) || _.isEmpty(patterns)) {
      return 'services:\n';
    }
    const deploymentHosts = InventorySampleContentCommonHelper.getDeploymentHosts(patterns);
    const inventorySampleObject = {
      services: deploymentHosts
    };
    return yaml.safeDump(inventorySampleObject);
  }

  // NEVISADMV4-5263
  private static fixTsYamlNewLineIssue(content: string) {
    return _.replace(content, 'url: >-\n    ', 'url: ');
  }
}

import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as _ from 'lodash';

import { DeploymentHistoryItem, DeploymentHistoryJobStatus, DeploymentHistoryState } from '../../../common/model/deployment-history.model';
import { criteriaSorting, reverseCompareResult } from '../../../common/utils/utils';
import { InventoryDeploymentHistoryHelper } from '../inventory-deployment-history.helper';
import { ALL_HOSTS_HOST_EXPRESSION_NAME } from '../../../deployment-wizard/deployment-selection/deploy-to-option.model';
import { DeploymentHistoryTableModel } from './inventory-deployment-history-table.model';
import { NavigationConstants } from '../../../common/constants/navigation.constants';
import { Inventory, InventorySchemaType } from '../../inventory.model';
import { DeployedServiceItem, Pod } from '../../inventory-kubernetes-status/deployed-service.model';
import { KubernetesStatusDialogService } from '../../inventory-kubernetes-status/kubernetes-status-dialog.service';
import { InventoryDeploymentHistoryTableContextService } from './inventory-deployment-history-table-context.service';

@Component({
  selector: 'adm4-inventory-deployment-history-table',
  templateUrl: './inventory-deployment-history-table.component.html',
  styleUrls: ['./inventory-deployment-history-table.component.scss'],
  providers: [InventoryDeploymentHistoryTableContextService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryDeploymentHistoryTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() inventoryDeploymentHistory: DeploymentHistoryItem[];
  @Input() currentInventory: Inventory;
  @Input() deployedServices: DeployedServiceItem[];
  @Input() isSecondary: boolean = false;
  @Output() redeployDeployment: EventEmitter<DeploymentHistoryItem> = new EventEmitter();
  expandedDeploymentIds: number[];
  @Input() displayedColumns: DeploymentHistoryTableModel[];
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  isKubernetesStatusScreen: boolean = false;
  isKubernetesInventory: boolean = false;
  displayedStartDateColumnName: string = '';

  tableDataSource: MatTableDataSource<DeploymentHistoryItem> = new MatTableDataSource([]);

  readonly deploymentDetailsColumnName = 'deploymentDetails';

  readonly deploymentHistoryTableColumns = DeploymentHistoryTableModel;
  expandableColumns = [this.deploymentDetailsColumnName];

  constructor(
              private kubernetesStatusDialogService: KubernetesStatusDialogService,
              activatedRoute: ActivatedRoute,
  ) {
    this.isKubernetesStatusScreen = _.endsWith(activatedRoute.snapshot.url.toString(), NavigationConstants.KUBERNETES_STATUS);
  }

  ngOnInit(): void {
    this.tableDataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inventoryDeploymentHistory) {
      this.tableDataSource.data = this.inventoryDeploymentHistory;
    }
    if (changes.currentInventory) {
      this.isKubernetesInventory = InventorySchemaType.KUBERNETES === this.currentInventory?.schemaType;
      this.displayedStartDateColumnName = this.isKubernetesInventory ? 'Last action' : 'Start date';
    }
  }

  ngAfterViewInit(): void {
    this.tableDataSource.sortData = this.deploymentHistorySorting();
  }

  private compareByDate(sort: MatSort): (dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => number {
    return (dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => {
      const compareResult = dh1.timestamp.localeCompare(dh2.timestamp);
      return sort.active === DeploymentHistoryTableModel.StartDateColumnName && sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
    };
  }

  private compareByStatus(sort: MatSort): (dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => number {
    return (dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => {
      const dh1StatusOrder = InventoryDeploymentHistoryHelper.getStatusOrderNumber(dh1.jobStatus);
      const dh2StatusOrder = InventoryDeploymentHistoryHelper.getStatusOrderNumber(dh2.jobStatus);
      const compareResult = dh1StatusOrder - dh2StatusOrder;
      return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
    };
  }

  private compareByProjectKey(sort: MatSort): (dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => number {
    return (dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => {
      const compareResult = dh1.projectKey.localeCompare(dh2.projectKey);
      return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
    };
  }

  private compareByDeployTo(sort: MatSort): (dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => number {
    return (dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => {
      const hostExpression1: string = this.getDeployToOfDeploymentHistoryItem(dh1);
      const hostExpression2: string = this.getDeployToOfDeploymentHistoryItem(dh2);
      const compareResult = hostExpression1.localeCompare(hostExpression2);
      return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
    };
  }

  private compareByUser(sort: MatSort): (dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => number {
    return (dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => {
      const compareResult = dh1.userKey.localeCompare(dh2.userKey);
      return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
    };
  }

  private deploymentHistorySorting(): (data: DeploymentHistoryItem[], sort: MatSort) => DeploymentHistoryItem[] {
    return (data: DeploymentHistoryItem[], sort: MatSort) => {
      if (_.isEmpty(sort.direction)) {
        return data.sort(this.compareByDate(sort));
      }
      switch (sort.active) {
        case DeploymentHistoryTableModel.StatusColumnName:
          return data.sort(criteriaSorting([
            this.compareByStatus(sort),
            this.compareByDate(sort)
          ]));
        case DeploymentHistoryTableModel.ProjectColumnName:
          return data.sort(criteriaSorting([
            this.compareByProjectKey(sort),
            this.compareByDate(sort)
          ]));
        case DeploymentHistoryTableModel.DeployedToColumnName:
          return data.sort(criteriaSorting([
            this.compareByDeployTo(sort),
            this.compareByDate(sort)
          ]));
        case DeploymentHistoryTableModel.DeployedByColumnName:
          return data.sort(criteriaSorting([
            this.compareByUser(sort),
            this.compareByDate(sort)
          ]));
        case DeploymentHistoryTableModel.StartDateColumnName:
          return data.sort(this.compareByDate(sort));
        default:
          return data.sort(this.compareByDate(sort));
      }
    };
  }

  getDeployToOfDeploymentHistoryItem(deploymentHistoryItem: DeploymentHistoryItem): string {
    return _.isNil(deploymentHistoryItem.hostExpression) ? ALL_HOSTS_HOST_EXPRESSION_NAME : deploymentHistoryItem.hostExpression;
  }

  canRedeployDeployment(deploymentHistoryItem: DeploymentHistoryItem): boolean {
    return deploymentHistoryItem.jobStatus === DeploymentHistoryJobStatus.Done
      && !deploymentHistoryItem.projectDeleted
      && !_.isNil(deploymentHistoryItem.projectCommitId)
      && !_.isNil(deploymentHistoryItem.inventoryCommitId)
      && !_.isEqual(deploymentHistoryItem.deploymentState, DeploymentHistoryState.OUTDATED)
      && !_.isEqual(deploymentHistoryItem.deploymentState, DeploymentHistoryState.ROLLED_BACK);
  }

  isDeploymentExpanded(deploymentHistoryItem: DeploymentHistoryItem): boolean {
    return _.some(this.expandedDeploymentIds, (deploymentId: number) => deploymentId === deploymentHistoryItem.deploymentHistoryId);
  }

  toggleDeploymentDetails(deploymentHistoryItem: DeploymentHistoryItem): void {
    if (this.isDeploymentExpanded(deploymentHistoryItem)) {
      this.expandedDeploymentIds = this.expandedDeploymentIds.filter((deploymentId: number) => deploymentId !== deploymentHistoryItem.deploymentHistoryId);
    } else {
      this.expandedDeploymentIds = _.concat(this.expandedDeploymentIds, deploymentHistoryItem.deploymentHistoryId);
    }
  }

  triggerRedeployDeployment(deploymentHistoryItem: DeploymentHistoryItem): void {
    this.redeployDeployment.emit(deploymentHistoryItem);
  }

  displayedDateTooltip(dateToDisplay: string, inventoryDeploymentHistoryState: DeploymentHistoryState, userKey: string): string {
    if (_.isNil(inventoryDeploymentHistoryState)) {
      return dateToDisplay;
    }
    switch (inventoryDeploymentHistoryState) {
      case DeploymentHistoryState.PROMOTED:
        return `Promoted by ${userKey}, ${dateToDisplay}`;
      case DeploymentHistoryState.ROLLED_BACK:
        return `Rolled back by ${userKey}, ${dateToDisplay}`;
      default:
        return dateToDisplay;
    }
  }

  openPodLogsDialog(pod: Pod): void {
    this.kubernetesStatusDialogService.openPodLogsDialog(this.currentInventory.inventoryKey, pod);
  }
}

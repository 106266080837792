import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { CanaryTreeNode } from './custom-resource-tree-node.model';
import { KubernetesDialogTypeEnum } from './kubernetes-status-dialog-payload.model';
import { CanaryRoutingOption } from '../../deployment-wizard/deployment-dialog/deployment-process.model';
import { InventoryService } from '../inventory.service';
import { catchError, distinctUntilChanged } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-canary-deployment-generation-output',
  template: `
    <div class="full-height-flex">
      <div class="remaining-space-flex-content-wrapper content-container">
        <div>
          <div class='text-with-icon-container warning'>
            <mat-icon class='warning-icon'>report_problem</mat-icon>
            <p *ngIf="isPromote">After promoting the Secondary deployments, your Primary deployments will be removed including all the related resources. The promoted Secondary deployment will become your Primary version and all the traffic goes to that version.</p>
            <p *ngIf="!isPromote">After rolling back the Secondary deployments, they will be removed, including all the related resources. The Primary deployment will receive all the traffic again.</p>
          </div>
          <div class='img-container' *ngIf='!displayCustomResourceTree'>
            <img src='{{imgResource}}' alt=''/>
            <div class='img-description'>
              <div *ngIf='canaryConfig.header'>Header name = {{canaryConfig?.header}}</div>
              <div *ngIf='canaryConfig.cookie'>Cookie name = {{canaryConfig?.cookie}}</div>
              <div *ngIf='canaryConfig.percentage'>User base (%) = {{canaryConfig.percentage}}% to Secondary</div>
            </div>
          </div>
        </div>
        <div class='resources-container'>
          <div class="resource-list-container">
            <div class="remaining-space-flex-content-wrapper">
              <div class="remaining-space-flex-content">
                <div class="full-height-flex step-content-card tree-view">
                  <div class='text-with-icon-container' (click)='toggleCustomResource()'>
                    <i class="{{displayCustomResourceTree ? 'fa fa-caret-down' : 'fa fa-caret-right'}}" aria-hidden="true"></i>
                    <div class='resource-toggle'>Resources being changed</div>
                  </div>
                  <div class='resource-tree-container' *ngIf='displayCustomResourceTree' [ngClass]='boxShadowClass'>
                    <div class="remaining-space-flex-content">
                      <div class='tree-view'>
                        <adm4-canary-tree-viewer *ngIf='k8sResourceTree?.length'
                                                 [nodes]='k8sResourceTree'
                                                 [kubernetesActionType]='kubernetesActionType'
                                                 (nodeSelected)='selectNode($event)'></adm4-canary-tree-viewer>
                      </div>
                    </div>
                  </div>
                  <div class='comment-wrapper'>
                    <div class="comment-title">
                      Comment (optional)
                    </div>
                    <textarea class='form-control admn4-textarea-input'
                              placeholder='Add your comments'
                              [(ngModel)]='commitMessage'
                              (ngModelChange)='onCommentMessageChange($event)'></textarea>
                    <div class="validation-message-container">
                      <adm4-validation-message *ngIf="commentHasError$ | async" [isError]='true' [message]='"You have exceeded the maximum character limit of 150."'></adm4-validation-message>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="file-details-container">
            <adm4-k8s-custom-resource-details
                    *ngIf='displayCustomResourceTree'
                    [node]='selectedNode'
                    [inventoryKey]='inventoryKey'
                    [kubernetesActionType]='kubernetesActionType'>
            </adm4-k8s-custom-resource-details>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button class='admn4-button-text' (click)='closeClicked.emit()'>Cancel</button>
        <button type='button' class='admn4-button-ellipse-blue' [disabled]='commentHasError$ | async' (click)='triggerDeployments()'>{{kubernetesActionType}} deployments</button>
      </div>
    </div>
  `,
  styleUrls: ['./canary-deployment-generation-output.component.scss']
})
export class CanaryDeploymentGenerationOutputComponent implements OnChanges {
  @Input() k8sResourceTree: CanaryTreeNode[] = [];
  @Input() inventoryKey: string;
  @Input() kubernetesActionType: KubernetesDialogTypeEnum;
  @Input() canaryConfig: CanaryRoutingOption;
  @Output() closeClicked: EventEmitter<void> = new EventEmitter();
  public selectedNode: CanaryTreeNode;
  boxShadowClass = 'default-inventory-box-shadow';
  displayCustomResourceTree = false;
  commitMessage = '';
  isPromote: boolean = true;

  _commentHasError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  commentHasError$: Observable<boolean> = this._commentHasError$.asObservable().pipe(distinctUntilChanged(_.isEqual));

  constructor(private inventoryService: InventoryService,
              private modalNotificationService: ModalNotificationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.kubernetesActionType) {
      this.isPromote = KubernetesDialogTypeEnum.PROMOTE === this.kubernetesActionType;
    }
  }

  selectNode(selectedNode: CanaryTreeNode): void {
    this.selectedNode = selectedNode;
  }

  toggleCustomResource() {
    this.displayCustomResourceTree = !this.displayCustomResourceTree;
  }

  get imgResource(): string {
    if (this.kubernetesActionType === KubernetesDialogTypeEnum.PROMOTE) {
      return 'assets/promote.png';
    }
    return 'assets/rollback.png';
  }

  triggerDeployments() {
    if (this.kubernetesActionType === KubernetesDialogTypeEnum.PROMOTE) {
      this.inventoryService.promoteCanaryDeployment(this.inventoryKey, this.getTruncatedCommitMessage()).pipe((promoteProcess) => {
          return promoteProcess;
        },
        catchError((err: HttpErrorResponse) => {
          console.error(`Something went wrong while promoting deployment`, err);
          this.modalNotificationService.openErrorDialog({title: 'Could not promote the deployments', description: err?.error?.error?.detail});
          return of(null);
        })).subscribe((promoteResult) => {
        if (!_.isNil(promoteResult)) {
          this.closeClicked.emit();
          this.modalNotificationService.openSuccessDialog({title: 'Success', description: 'The deployments have been successfully promoted. Please note that deleting the old resources may take some time. Therefore, existing environment might be running for a few minutes.'});
        }
      });
    } else {
      this.inventoryService.rollbackCanaryDeployment(this.inventoryKey, this.getTruncatedCommitMessage()).pipe((rollbackProcess) => {
          return rollbackProcess;
        },
        catchError((err: HttpErrorResponse) => {
          console.error(`Something went wrong while rollback deployment`, err);
          this.modalNotificationService.openErrorDialog({title: 'Could not rollback the deployments', description: err.error.error.detail});
          return of(null);
        })).subscribe((rollbackResult) => {
        if (!_.isNil(rollbackResult)) {
          this.closeClicked.emit();
          this.modalNotificationService.openSuccessDialog({title: 'Success', description: 'The deployments have been successfully rolled back. Please note that deleting the related resources may take some time. Therefore, existing environment might be running for a few minutes.'});
        }
      });
    }
  }

  private getTruncatedCommitMessage() {
    return _.isEmpty(this.commitMessage) ? undefined : this.commitMessage;
  }

  onCommentMessageChange(commentMessage: string): void {
    const isInvalidCommentLength = commentMessage.length > 150;
    this._commentHasError$.next(isInvalidCommentLength);
  }
}

import { createAction, props } from '@ngrx/store';
import { ProjectTemplate } from './marketplace.model';

export enum MarketplaceActionType {
  LOAD_ITEMS = '[MarketPlace] Load items',
  ITEMS_LOADED = '[MarketPlace] Items loaded',
  RESET = '[MarketPlace] Reset',
  TEXT_FILTER_CHANGED = '[MarketPlace] Text filter changed',
  SELECT_ITEM = '[MarketPlace] Select item',
}

export const loadMarketPlaceItems = createAction(
    MarketplaceActionType.LOAD_ITEMS,
);

export const marketplaceItemsLoaded = createAction(
    MarketplaceActionType.ITEMS_LOADED,
    props<{ items: ProjectTemplate[]}>()
);

export const resetMarketplace = createAction(
    MarketplaceActionType.RESET
);

export const textFilterChanged = createAction(
    MarketplaceActionType.TEXT_FILTER_CHANGED,
    props<{textFilter: string | null}>(),
);

export const selectItem = createAction(
    MarketplaceActionType.SELECT_ITEM,
    props<{ selectedProjectTemplateKey: string | null }>(),
);

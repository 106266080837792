<ng-template #popContentIssues>
  <adm4-pattern-error-list-hover [issues]='changesetItem.issues'
                                 [projectKey]='projectKey'
  ></adm4-pattern-error-list-hover>
</ng-template>
<ng-template #popModifiedBy>
  <div>Modified by: {{changesetItem.metaData.localAuthors}}</div>
  <div>
    Changes were done by another user. You might want to review them.
  </div>
</ng-template>

<adm4-simple-list-item [isSelected]="isSelected" [itemSize]="'content'">
  <div class='list-element' [title]="isDeleted ? 'This file was deleted' : ''">
    <adm4-validation-indicator
            [ngbTooltip]='popContentIssues' placement='top' [disableTooltip]="!hasIssues"
            [isDisplayed]='hasIssues'
            [isError]='hasHighestSeverityErrors'
            [isWarning]='hasHighestSeverityWarnings'>
    </adm4-validation-indicator>
    <div class='list-element-content'>
      <div class='list-element-row'>
        <div class='list-element-title'>
          <span class='changeset-name' [class.deleted]='isDeleted'>
            {{changesetItem.name}}
          </span>
        </div>
        <img *ngIf='isLastChangeByAnotherUser'
             [ngbTooltip]='popModifiedBy' placement='right' width="24" height="24"
             src='assets/warningUserChanges24px.png' alt="Icon warning about user changes"/>
      </div>
      <div *ngIf='changesetItem.secondaryName' class='list-element-row list-element-info'>
        <span class='changeset-secondary-name'
           [class.deleted]='isDeleted'>
          {{changesetItem.secondaryName}}
        </span>
      </div>
    </div>
  </div>
</adm4-simple-list-item>

import { NevisAdminAction } from '../actions';
import { PatternInstance } from '../../patterns/pattern-instance.model';
import { PropertyType } from '../../plugins/property-type.model';
import { PatternType } from '../../plugins/pattern-type.model';
import { Usage } from '../../common/model/plugin-usage.model';
import { Pattern, PatternCopyTarget } from '../../patterns/pattern.model';
import { CreatePatternPayload, DuplicatePatternPayload, PatchPatternsPayload, UpdatePatternPayload } from './create-pattern-payload.model';
import { LoadPatternAttachmentsSuccessPayload } from './load-pattern-attachments-succe-payload.model';
import { DeleteAttachmentObj, SaveAttachmentObj } from '../../patterns/pattern-attachment.model';
import { PatchInstance } from '../../patterns/batch-actions/batch-action.model';
import { PatternId } from './pattern.reducer';

export enum PatternActionTypes {
  LoadAllPatternInstances = '[Patterns] Load All Pattern',
  LoadAllPatternInstancesSuccess = '[Patterns] Load All Patterns Success',
  SelectPatternId = '[Patterns] Select A Pattern',
  SelectPatternInstance = '[Patterns] Select Pattern Instance',
  UpdatePatternInstance = '[Patterns] Update Pattern Instance',
  UpdatePatternInstanceSuccess = '[Patterns] Update Pattern Instance success',
  PatchPatternInstances = '[Patterns] Patch Pattern Instances',
  PatchPatternInstancesSuccess = '[Patterns] Patch Pattern Instances success',
  LoadPatternTypes = '[Patterns] Load Pattern Types',
  LoadPatternTypesSuccess = '[Patterns] Load Pattern Type Success',
  LoadPropertyTypes = '[Patterns] Load Property Type',
  LoadPropertyTypesSuccess = '[Patterns] Load Property Type Success',
  CreatePattern = '[Patterns] Create pattern',
  CreatePatternSuccess = '[Patterns] Pattern created successfully',
  DuplicatePattern = '[Pattern] Duplicate pattern',
  DuplicatePatternSuccess = '[Pattern] Pattern duplicated successfully',
  DeletePattern = '[Patterns] Delete pattern',
  DeletePatternSuccess = '[Patterns] Delete pattern success',
  LoadPatternUsageSuccess = '[Patterns] Load pattern usage success',
  LoadPatternCopyTargetSuccess = '[Patterns] Load pattern copy targets success',
  SavePatternResource = '[Patterns] Save pattern resource',
  DeletePatternResource = '[Patterns] Delete pattern resource',
  LoadPatternAttachments = '[Patterns] Load pattern attachments',
  LoadPatternAttachmentsSuccess = '[Patterns] Load pattern attachments success'
}

export class CreatePattern implements NevisAdminAction<CreatePatternPayload> {
  readonly type = PatternActionTypes.CreatePattern;

  constructor(public payload: CreatePatternPayload) {}
}

export class CreatePatternSuccess implements NevisAdminAction<PatternInstance> {
  readonly type = PatternActionTypes.CreatePatternSuccess;

  constructor(public payload: PatternInstance) {}
}

export class DuplicatePattern implements NevisAdminAction<DuplicatePatternPayload> {
  readonly type = PatternActionTypes.DuplicatePattern;

  constructor(public payload: DuplicatePatternPayload) {}
}

export class DuplicatePatternSuccess implements NevisAdminAction<PatternInstance> {
  readonly type = PatternActionTypes.DuplicatePatternSuccess;

  constructor(public payload: PatternInstance) {}
}

export class UpdatePatternInstance implements NevisAdminAction<UpdatePatternPayload> {
  readonly type = PatternActionTypes.UpdatePatternInstance;

  constructor(public payload: UpdatePatternPayload) {}
}

export class UpdatePatternInstanceSuccess implements NevisAdminAction<PatternInstance> {
  readonly type = PatternActionTypes.UpdatePatternInstanceSuccess;

  constructor(public payload: PatternInstance) {}
}

export class PatchPatternInstances implements NevisAdminAction<PatchPatternsPayload> {
  readonly type = PatternActionTypes.PatchPatternInstances;

  constructor(public payload: PatchPatternsPayload) {}
}

export class PatchPatternInstancesSuccess implements NevisAdminAction<PatchInstance[]> {
  readonly type = PatternActionTypes.PatchPatternInstancesSuccess;

  constructor(public payload: PatchInstance[]) {}
}

export class LoadAllPatternInstances implements NevisAdminAction<string> {
  readonly type = PatternActionTypes.LoadAllPatternInstances;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class LoadAllPatternInstancesSuccess implements NevisAdminAction<Pattern[]> {
  readonly type = PatternActionTypes.LoadAllPatternInstancesSuccess;

  constructor(public payload: Pattern[]) {}
}

export class SelectPatternId implements NevisAdminAction<string | null> {
  readonly type = PatternActionTypes.SelectPatternId;

  /**
   * @param payload - patternId
   */
  constructor(public payload: string | null) {}
}

export class SelectPatternInstance implements NevisAdminAction<PatternInstance | null> {
  readonly type = PatternActionTypes.SelectPatternInstance;

  constructor(public payload: PatternInstance | null) {}
}

export class LoadPatternTypes implements NevisAdminAction<string> {
  readonly type = PatternActionTypes.LoadPatternTypes;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class LoadPatternTypesSuccess implements NevisAdminAction<PatternType[]> {
  readonly type = PatternActionTypes.LoadPatternTypesSuccess;

  constructor(public payload: PatternType[]) {}
}

export class LoadPropertyTypes implements NevisAdminAction<string> {
  readonly type = PatternActionTypes.LoadPropertyTypes;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class LoadPropertyTypesSuccess implements NevisAdminAction<PropertyType[]> {
  readonly type = PatternActionTypes.LoadPropertyTypesSuccess;

  constructor(public payload: PropertyType[]) {}
}

export class DeletePattern implements NevisAdminAction<Pattern[]> {
  readonly type = PatternActionTypes.DeletePattern;

  constructor(public payload: Pattern[]) {}
}

export class DeletePatternSuccess implements NevisAdminAction<PatternId[]> {
  readonly type = PatternActionTypes.DeletePatternSuccess;

  /**
   * @param payload - patternId
   */
  constructor(public payload: PatternId[]) {}
}

export class LoadPatternUsageSuccess implements NevisAdminAction<Usage[]> {
  readonly type = PatternActionTypes.LoadPatternUsageSuccess;

  constructor(public payload: Usage[]) {}
}

export class LoadPatternCopyTargetSuccess implements NevisAdminAction<PatternCopyTarget[]> {
  readonly type = PatternActionTypes.LoadPatternCopyTargetSuccess;

  constructor(public payload: PatternCopyTarget[]) {}
}

export class SavePatternResource implements NevisAdminAction<SaveAttachmentObj[]> {
  readonly type = PatternActionTypes.SavePatternResource;

  constructor(public payload: SaveAttachmentObj[]) {}
}

export class DeletePatternResource implements NevisAdminAction<DeleteAttachmentObj[]> {
  readonly type = PatternActionTypes.DeletePatternResource;

  constructor(public payload: DeleteAttachmentObj[]) {}
}

export class LoadPatternAttachments implements NevisAdminAction<PatternInstance> {
  readonly type = PatternActionTypes.LoadPatternAttachments;

  constructor(public payload: PatternInstance) {}
}

export class LoadPatternAttachmentsSuccess implements NevisAdminAction<LoadPatternAttachmentsSuccessPayload> {
  readonly type = PatternActionTypes.LoadPatternAttachmentsSuccess;

  constructor(public payload: LoadPatternAttachmentsSuccessPayload) {}
}

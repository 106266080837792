import { InventoryMeta } from '../inventory.model';
import { InventoryChangesetItem } from './inventory-changeset-item.model';
import { LocalStatus, MetaInfo } from '../../version-control/meta-info.model';
import * as _ from 'lodash';
import { INVENTORY_CONTENT_CHANGESET_ITEM_KEY, INVENTORY_CONTENT_CHANGESET_ITEM_NAME } from './publish-inventory.constants';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { InventoryContentDiffViewComponent } from './inventory-content-diff-view/inventory-content-diff-view.component';

export class PublishInventoryChangesetHelper {
  static hasLocalChanges(inventoryMeta: InventoryMeta): boolean {
    return !_.isNil(inventoryMeta.lastModification);
  }

  static createInventoryChangesetFromInventoryMeta(inventoryMeta: InventoryMeta, inventoryValidationStatus: ValidationStatus<InventoryValidationIssue> | null): InventoryChangesetItem<MetaInfo, any>[] {
    const hasModifiedInventoryContent = !_.isNil(inventoryMeta.content) && inventoryMeta.content.localStatus !== LocalStatus.Unmodified;
    const inventoryContentChangesetItem: InventoryChangesetItem<MetaInfo, any> | null = hasModifiedInventoryContent ? this.createInventoryContentChangesetItem(inventoryMeta.content, inventoryValidationStatus) : null;
    return [inventoryContentChangesetItem].filter(changesetItem => !_.isNil(changesetItem)) as InventoryChangesetItem<MetaInfo, any>[];
  }

  static createInventoryContentChangesetItem(inventoryContentMetaInfo: MetaInfo, inventoryValidationStatus: ValidationStatus<InventoryValidationIssue> | null): InventoryChangesetItem<MetaInfo, any> {
    return {
      key: INVENTORY_CONTENT_CHANGESET_ITEM_KEY,
      name: INVENTORY_CONTENT_CHANGESET_ITEM_NAME,
      localAuthor: inventoryContentMetaInfo.localAuthor,
      metaData: inventoryContentMetaInfo,
      validationStatus: inventoryValidationStatus,
      diffComponent: InventoryContentDiffViewComponent
    };
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '../notification/notification-type.enum';
import { NotificationModel } from '../notification/notification.model';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { closeModalOnEscape } from './modal-dialog.helper';
import { RETURN_BUTTON_LABEL } from '../notification/notification.constants';

export interface ModalDialogOptions {
  style: string;
  header: string;
}

export const dialogs = {
  [NotificationType.DIALOG_SUCCESS]:
    {style: 'dialog-success', header: 'Success'},
  [NotificationType.DIALOG_INFO]:
    {style: 'dialog-info', header: 'Info'},
  [NotificationType.DIALOG_WARNING]:
    {style: 'dialog-warning', header: 'Warning'},
  [NotificationType.DIALOG_ERROR]:
    {style: 'dialog-error', header: 'Error'}
};

@Component({
  selector: 'adm4-modal-dialog-notification',
  template: `
		<!-- This part is fallback for unhandled error which happens during change detection in the template -->
		<div class='crash-error-heading' [hidden]='true'>
			<h2>Unfortunately, an unexpected error happened</h2>
		</div>
		<!---->
		<adm4-modal-dialog-title (closeClicked)="onCloseClicked(dialogRef, data.notificationCallback)"
														 [ngClass]='options.style'
														 [header]="data.notificationMessage.headerTitle || options.header"
														 [isFullHeightContent]='false'>
			<!-- This part is fallback for unhandled error which happens during change detection in the template -->
			<div class='crash-error-description' [hidden]='true'>
        <p>
          We're sorry for the hassle. Please reload this page to continue. <br/>
          If this problem happens repeatedly, please report it to us. The details can be found in the console. <br/>
				</p>
			</div>
			<!---->
			<div mat-dialog-content class="content-container">
				<span class="title">{{data.notificationMessage.title}}</span><br>
				<div class="description" [innerHtml]="data.notificationMessage.description">{{data.notificationMessage.description}}</div>
			</div>
			<mat-dialog-actions>
				<button class='admn4-button-ellipse-default'
								(click)="onButtonClicked(dialogRef, data.notificationCallback)">
					{{returnButtonLabel}}
				</button>
			</mat-dialog-actions>
		</adm4-modal-dialog-title>`,
  styleUrls: ['./modal-dialog-notification.component.scss', './unhandled-crash-error.scss'],
})

export class ModalDialogNotificationComponent implements OnInit, OnDestroy {
  options: any;
  returnButtonLabel = RETURN_BUTTON_LABEL;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(public dialogRef: MatDialogRef<ModalDialogNotificationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: NotificationModel) {
    this.options = dialogs[data.type];
    dialogRef.disableClose = (data.type === NotificationType.DIALOG_ERROR || data.type === NotificationType.DIALOG_WARNING);
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  onCloseClicked(dialogRef: MatDialogRef<ModalDialogNotificationComponent>, notificationCallback?: () => void) {
    dialogRef.close();
    if (_.isFunction(notificationCallback)) {
      notificationCallback();
    }
  }

  onButtonClicked(dialogRef: MatDialogRef<ModalDialogNotificationComponent>, notificationCallback?: () => void) {
    dialogRef.close();
    if (_.isFunction(notificationCallback)) {
      notificationCallback();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

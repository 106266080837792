import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  /**
   * Returns the value of the key as a boolean, converted from the `string` raw value.<br/>
   * If the value is missing, returns false.<br/>
   * If the value is not `'true'` or `'false'`, throws an error.
   * @param key
   */
  public getBoolean(key: string): boolean {
    const raw = localStorage.getItem(key);
    if (typeof raw === 'undefined' || raw === null || raw === 'false') {
      return false;
    } else if (raw === 'true') {
      return true;
    } else {
      console.warn(`LocalStorageService#getBoolean, unexpected value for key '${key}': '${raw}'`);
      throw new Error(`Unexpected value found in localStorage for key '${key}': '${raw}'`);
    }
  }

  public setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }
}

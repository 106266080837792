import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from '../project.model';
import { AppState } from '../../model/reducer';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { VersionControlData } from '../../common/model/version-control-data.model';
import { ConnectProjectToVersionControl } from '../../model/project';

@Component({
  selector: 'adm4-connect-project-version-control-container',
  template: `
    <adm4-modal-dialog-title [header]='header'
                             [showClose]='true'
                             (closeClicked)="dialogRef.close()">
      <adm4-connect-project-version-control [project]='project'
                                            (projectConnect)='onProjectConnect($event)'
                                            (cancel)='dialogRef.close()'
      ></adm4-connect-project-version-control>
    </adm4-modal-dialog-title>
  `
})
export class ConnectProjectVersionControlContainerComponent implements OnDestroy {
  header: string;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(public dialogRef: MatDialogRef<ConnectProjectVersionControlContainerComponent>,
              @Inject(MAT_DIALOG_DATA) public project: Project,
              private store$: Store<AppState>) {

    closeModalOnEscape(this.dialogRef, this.destroyed$);

    const projectName = TenantHelper.cropTenantFromKey(project.projectKey);
    this.header = `Connect ${projectName} to Git`;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onProjectConnect(projectVersionConrolData: VersionControlData): void {
    this.store$.dispatch(new ConnectProjectToVersionControl({projectKey: this.project.projectKey, projectVersionControlData: projectVersionConrolData}));
    this.dialogRef.close();
  }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ReportsContext } from '../reports.context';
import { PluginModel } from '../../plugins/plugin.model';

@Component({
  selector: 'adm4-report-list',
  template: `
    <div class='full-height report-list-container'>
      <adm4-simple-list-item *ngFor='let report of reportsContext.reports$ | async'
                             [link]='["/projects", projectKey, "reports", report.className]'
                             [isSelected]='isReportSelected(report)'>
        <div class='list-element'>
          <div class='list-element-content'>
            <div class='list-element-row'>
              <div class='list-element-title'>{{report.name}}</div>
            </div>
          </div>
        </div>
      </adm4-simple-list-item>
    </div>
  `,
  styleUrls: ['../../common/styles/component-specific/extended-list-item.scss', './report-list.component.scss']
})
export class ReportListComponent implements OnChanges {
  @Input() projectKey: string | null;
  @Input() selectedReportClassName: string;

  constructor(public reportsContext: ReportsContext) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectKey && this.projectKey) {
       this.reportsContext.loadProjectVariables(this.projectKey);
    }
  }

  isReportSelected(report: PluginModel): boolean {
    return this.selectedReportClassName === report.className;
  }
}

<adm4-modal-dialog-title [header]='header'
                         [showClose]='true'
                         [isFullHeightContent]='true'
                         (closeClicked)="closeDialog()">
  <div class='full-height-flex'>
    <div class='content-container remaining-space-flex-content-wrapper'>
      <div class='remaining-space-flex-content' #editorContainer>
        <ngx-monaco-editor [(ngModel)]='editedContent'
                           [options]='editorOptions'
                           (ngModelChange)='modelChanged()'
                           (onInit)='initializeEditor($event)'>
        </ngx-monaco-editor>
      </div>
    </div>
    <div mat-dialog-actions>
      <button class='admn4-button-text' (click)='cancelDialog()'>Cancel</button>
      <button class='admn4-button-ellipse-blue' [disabled]='!hasChanges' (click)="applyEdit()">
        Apply
      </button>
    </div>
  </div>
</adm4-modal-dialog-title>

<form [formGroup]="form" name="form" (ngSubmit)='submitCreateProject()'>
  <div class="content-container create-item-container">
    <div class='form-group'>
      <label class='input-label checkbox-label'>
        <mat-checkbox [formControlName]='IS_VERSIONED_FORM_CONTROL_NAME'></mat-checkbox>
        <span>Enable Git</span>
      </label>
    </div>
    <div [@showVersionControlForm]='showVersionControlFormState'>
      <adm4-version-control-form [predefinedVersionControl]="predefinedVersionControlData()" (formReady)='addVersionControlForm($event)'></adm4-version-control-form>
    </div>
    <div class='form-group'>
      <label class='input-label'>Project key*</label>
      <div class="item-key-container">
        <div class='tenant-key-wrapper' *ngIf='displayTenant'>
          <span>{{selectedTenantKey}} - </span>
          <div class='validation-message-container'></div>
        </div>
        <div class='item-key-input-wrapper'>
          <input class="form-control admn4-text-input" cdkFocusInitial
                 placeholder=' '
                 name="projectKey"
                 [formControlName]='PROJECT_KEY_FORM_CONTROL_NAME'
                 required/>
          <div class="validation-message-container">
            <adm4-validation-message *ngIf='shouldShowProjectKeyErrorMessage("pattern")' [isError]='true' [message]='ERROR_INVALID_PROJECT_KEY'></adm4-validation-message>
            <adm4-validation-message *ngIf='shouldShowProjectKeyErrorMessage("required")' [isError]='true' [message]='ERROR_PROJECT_KEY_REQUIRED'></adm4-validation-message>
            <adm4-validation-message *ngIf='shouldShowProjectKeyErrorMessage("projectKeyExists")' [isError]='true' [message]='projectForTenantExistsError'></adm4-validation-message>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="availableProjectTemplates.length > 0">
      <label class="input-label">Create from template</label>
      <mat-form-field class="full-width template-selection">
        <mat-select [formControlName]="PROJECT_TEMPLATE_KEY_FORM_CONTROL_NAME" #ptSelect (openedChange)="selectToggled($event)">
          <adm4-searchable-dropdown-input *ngIf='ptSelect.focused'
                                          [showSpinner]="false"
                                          [focusTrigger]="searchInputFocusTrigger$"
                                          (searchText)="projectTemplateSearchChanged($event)"
          ></adm4-searchable-dropdown-input>
          <mat-option [value]="''">None</mat-option>
          <ng-container *ngFor="let pt of filteredProjectTemplates;">
            <mat-option [value]="pt.projectTemplateKey"
                        [ngbTooltip]="optionTooltip" placement="left" tooltipClass="adm4-tooltip small-tooltip" [openDelay]="400" [closeDelay]="400"
            >{{pt.title}}</mat-option>
            <ng-template #optionTooltip>
              <p><strong>{{pt.title}}</strong></p>
              <p>{{pt.summary}}</p>
              <p>ID: <code>{{pt.projectTemplateKey}}</code></p>
            </ng-template>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <p class="mt-4">To learn more about these templates, <a (click)="goToMarketplace()">go to Project Templates</a>.</p>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <adm4-button-bar [isSubmitDisabled]="!canSave"
                     [submitButtonText]="BUTTON_CREATE"
                     (cancelClicked)='cancel()'></adm4-button-bar>
  </div>
</form>

import { Branch } from '../../version-control/branch.model';
import * as _ from 'lodash';
import { DEFAULT_BRANCH_NAME } from '../components/version-control-form/version-control-form.constants';

export class VersionControlHelper {
  static getBranchName(branch?: Branch | null | undefined): string {
    return _.isNil(branch) ? DEFAULT_BRANCH_NAME : branch.name;
  }

  static getValidPath(path?: string): string | undefined {
    return _.isEmpty(path) ? undefined :  this.trimLeadingSlash(path);
  }

  static trimLeadingSlash(path) {
    return path.charAt(0) === '/' ? path.substring(1) : path;
  }

  static getRepositoryNameFromUrl(repositoryUrl: string | undefined): string {
    if (_.isNil(repositoryUrl)) {
      return '';
    }

    const startingNamePosition = repositoryUrl.lastIndexOf('/') + 1;
    const endPosition = repositoryUrl.indexOf('.git');

    if (startingNamePosition > 0 && endPosition > 0 && endPosition > startingNamePosition) {
      return repositoryUrl.substring(startingNamePosition, endPosition);
    } else {
      return '';
    }
  }

  static selectInitialBranch(branches: Branch[]): Branch | undefined {
    const masterBranch = branches.find(branch => branch.name === 'master');
    return _.isNil(masterBranch) ? branches[0] : masterBranch;
  }
}

import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { select, Store } from '@ngrx/store';

import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';

import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';
import { FetchGenerationOutput } from '../../model/deploy';
import { AppState } from '../../model/reducer';
import { InventoryService } from '../inventory.service';
import {
  CanaryTreeNode,
  k8sCustomResourceListToTree,
  KubernetesCustomResource,
  KubernetesCustomResourceState,
  SideBySideModel
} from './custom-resource-tree-node.model';
import { inventoryKeyView } from '../../model/views';
import { KubernetesDialogTypeEnum, PromoteRollbackDialogPayload } from './kubernetes-status-dialog-payload.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';

@Component({
  selector: 'adm4-promote-rollback-dialog',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title'
                             [header]='header'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog()">
      <div class='full-height remaining-space-flex-content-wrapper'>
        <div class="remaining-space-flex-content">
          <adm4-canary-deployment-generation-output class='full-height'
                                                    [k8sResourceTree]='(k8sResourceTree$ | async) || []'
                                                    [inventoryKey]='inventoryKey$ | async'
                                                    [kubernetesActionType]='kubernetesActionType'
                                                    [canaryConfig]='payload.canaryConfig'
                                                    (closeClicked)='closeDialog()'></adm4-canary-deployment-generation-output>
        </div>
      </div>
    </adm4-modal-dialog-title>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
})
export class PromoteRollbackDialogComponent implements OnDestroy {

  header: string;
  k8sResourceTree$: Observable<CanaryTreeNode[]>;
  kubernetesActionType: KubernetesDialogTypeEnum;
  inventoryKey$: Observable<string | null>;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public payload: PromoteRollbackDialogPayload,
              private dialogRef: MatDialogRef<PromoteRollbackDialogComponent>,
              private inventoryService: InventoryService,
              private store$: Store<AppState>,
              modalNotificationService: ModalNotificationService) {
    this.header = this.payload.canaryDialogType + ' Secondary deployments';
    this.kubernetesActionType = this.payload.canaryDialogType;
    this.k8sResourceTree$ = this.inventoryService.getInventoryCustomResources(payload.inventoryKey).pipe(
      catchError((err) => {
        modalNotificationService.openHttpErrorDialog(err, 'Unable to fetch kubernetes resources');
        return of([]);
      }),
      map((resourceList: KubernetesCustomResource[]) => {
        const deploymentCategories: Record<string, KubernetesCustomResource[]> = _.groupBy(resourceList, (customResource) => customResource.state);
        const groupedCustomResources: SideBySideModel[] = _.entries(deploymentCategories)
          .filter(([groupName,]: [string, KubernetesCustomResource[]]) => {
            // if we are rolling back, only showing the secondary resources
            const justSecondaryNeeded = this.kubernetesActionType === KubernetesDialogTypeEnum.ROLLBACK;
            return justSecondaryNeeded ? groupName === KubernetesCustomResourceState.SECONDARY : true;
          })
          .map(([groupName, customResourceList]: [string, KubernetesCustomResource[]]): SideBySideModel => ({
            host: groupName,
            kubernetesCustomResources: customResourceList
          }));
        return groupedCustomResources;
      }),
      map((sideBySides: SideBySideModel[]): CanaryTreeNode[] => sideBySides.map(k8sCustomResourceListToTree)),
    );
    this.inventoryKey$ = this.store$.pipe(select(inventoryKeyView));
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  loadGenerationOutput(deploymentId: string): void {
    this.store$.dispatch(new FetchGenerationOutput(deploymentId));
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

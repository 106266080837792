import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { SessionExpiryDialogComponent } from './session-expiry-dialog..component';

@Injectable()
export class SessionExpiryDialogService {

  constructor(private dialog: MatDialog) {
  }

  openSessionExpiryDialog(): MatDialogRef<SessionExpiryDialogComponent> {

    return this.dialog.open(SessionExpiryDialogComponent,
      {
        width: '300',
        disableClose: true,
        autoFocus: true,
        backdropClass: 'blurred-background'
      });
  }
}

import { VersionControlForm } from '../common/components/version-control-form/version-control.form';
import { KubernetesInventoryForm } from './create-inventory/kubernetes-inventory-creation-form/kubernetes-inventory.form';

export const VERSION_CONTROL_FORM_CONTROL_NAME = 'versionControl';
export const INVENTORY_KEY_FORM_CONTROL_NAME = 'inventoryKey';
export const IS_VERSIONED_FORM_CONTROL_NAME = 'isVersioned';
export const INCLUDE_SAMPLE_FORM_CONTROL_NAME = 'includeSample';
export const PROJECT_KEY_FORM_CONTROL_NAME = 'projectKey';
export const PUBLISH_REQUIRED_FORM_CONTROL_NAME = 'publishRequired';
export const IS_KUBERNETES_FORM_CONTROL_NAME = 'isKubernetes';
export const K8S_CONTROL_FORM_CONTROL_NAME = 'kubernetes-cluster';

export interface ImportInventoryForm {
  [VERSION_CONTROL_FORM_CONTROL_NAME]: VersionControlForm;
  [INVENTORY_KEY_FORM_CONTROL_NAME]: string;
  [PUBLISH_REQUIRED_FORM_CONTROL_NAME]: boolean;
}

export interface CreateInventoryForm extends ImportInventoryForm {
  [IS_VERSIONED_FORM_CONTROL_NAME]: boolean;
  [INCLUDE_SAMPLE_FORM_CONTROL_NAME]: boolean;
  [PROJECT_KEY_FORM_CONTROL_NAME]: string;
  [IS_KUBERNETES_FORM_CONTROL_NAME]: boolean;
  [K8S_CONTROL_FORM_CONTROL_NAME]: KubernetesInventoryForm;
}

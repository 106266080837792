
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { ProjectMeta } from '../projects/project.model';
import { Revision } from './revision/revision.model';
import { Branch } from './branch.model';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class VersionControlService extends BaseService {

  getBranchesOfRepo(repoUrl: string): Observable<Branch[]> {
    const url = `/vc/branches`;
    const params: HttpParams = new HttpParams().set('repoUrl', repoUrl);
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getBranchByRepoAndName(repoUrl: string, branchName: string): Observable<Branch | undefined> {
    const url = `/vc/branches`;
    const params: HttpParams = new HttpParams().set('repoUrl', repoUrl).set('name', branchName);
    return this.httpGetUnwrapped(url, undefined, params).pipe(map((branches: Branch[]) => branches[0])); // this request should return array with only one element so we map it to that element
  }

  getProjectStatus(projectKey: string): Observable<ProjectMeta> {
    const url = `/projects/${projectKey}/meta`;
    return this.httpGet(url);
  }

  getRevisionOfProjectCommit(projectKey: string, commitId: string): Observable<Revision> {
    const url = `/projects/${projectKey}/revisions/${commitId}`;
    return this.httpGet(url);
  }

  getRevisionOfInventoryCommit(inventoryKey: string, commitId: string): Observable<Revision> {
    const url = `/inventories/${inventoryKey}/revisions/${commitId}`;
    return this.httpGet(url);
  }
}




import { OperationColumnData } from '../../permissions/model/operation-column-data.model';
import { OperationKey } from '../../model/permissions/permissions.model';

export const projectPermissionOperationColumns: OperationColumnData[] = [
  {
    operationKey: OperationKey.ADMIN_PROJECT,
    columnLabel: 'Administrate',
    columnKey: 'administrate'
  },
  {
    operationKey: OperationKey.MODIFY_PROJECT,
    columnKey: 'modify',
    columnLabel: 'Modify'
  },
  {
    operationKey: OperationKey.VIEW_PROJECT,
    columnKey: 'view',
    columnLabel: 'View'
  }
];

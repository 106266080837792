import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PluginModel } from '../../plugins/plugin.model';

/**
 * This component is for displaying the pattern list inside the add pattern window
 */
@Component({
  selector: 'adm4-create-pattern-list-element',
  template: `
              <button mat-button class='pattern-container'
                 [class.selected]='selected'
                 (click)='onPatternClick()'
                 (dblclick)='onPatternDoubleClick()'>
                <div class='element-row'>
                  <span class='pattern-title' [innerHtml]='pattern.name | highlight: textToHighLight'></span>
                  <span class='categories-container'>
                    <span class="category" *ngFor='let category of pattern.category' [ngbTooltip]='patternCategory' placement='top'>
                      <ng-template #patternCategory>
                        {{category}}
                      </ng-template>
                      <button class='admn4-button-filter-pattern-icon category' disabled
                              [class.selected]='isSelectedCategory(category)'>
                        {{category | abbreviate: 2}}
                      </button>
                    </span>
                  </span>
                </div>
              </button>
  `,
  styleUrls: ['create-pattern-list-element.component.scss']
})
export class CreatePatternListComponent {
  @Input() pattern: PluginModel;
  @Input() selected: boolean;
  @Input() selectedCategories: string[];
  @Input() textToHighLight: string;
  @Output() patternClicked: EventEmitter<void> = new EventEmitter();
  @Output() patternDoubleClicked: EventEmitter<void> = new EventEmitter();

  isSelectedCategory(category: string): boolean {
    return this.selectedCategories.includes(category);
  }

  onPatternClick(): void {
    this.patternClicked.emit();
  }

  onPatternDoubleClick(): void {
    this.patternDoubleClicked.emit();
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { CreateProjectContainerComponent, CreateProjectDialogPayload } from './create-project-container/create-project-container.component';
import { Project } from '../project.model';
import { AppState } from '../../model/reducer';
import { loadMarketPlaceItems } from '../../resources/marketplace/marketplace.actions';

@Injectable({providedIn: 'root'})
export class CreateProjectDialogService {

  constructor(
      private dialog: MatDialog,
      private store: Store<AppState>,
  ) { }

  public openCreateProjectDialog(
      predefinedProjectData: Project | null = null,
      preselectedProjectTemplateKey?: string,
  ): MatDialogRef<CreateProjectContainerComponent> {
    this.store.dispatch(loadMarketPlaceItems());

    const config: MatDialogConfig<CreateProjectDialogPayload> = {
      width: '30%',
      maxWidth: '50%',
      autoFocus: false,
      position: {
        top: 'calc(25vh - 64px)'
      },
      data: {predefinedProjectData, preselectedProjectTemplateKey},
      disableClose: true
    };
    return this.dialog.open(CreateProjectContainerComponent, config);
  }
}

<div class="full-height-flex">
	<div class='step-sub-heading-with-actions'>
		<div class='step-content-header'><mat-icon class='title' *ngIf="titleIcon">{{titleIcon}}</mat-icon>{{node.details.label}}</div>
	</div>
	<div class="remaining-space-flex-content-wrapper">
		<div class="remaining-space-flex-content">
			<div class="step-content-card item-details full-height" [ngClass]='boxShadowClass'>
				<p>
					<adm4-detail-message
									[node]='node'
									[outputType]='outputType'
									[pattern]='pattern'
									[projectKey]='projectKey'>
					</adm4-detail-message>
				</p>
				<p>
					<strong>Command</strong>
				</p>
				<p>{{node.details.command}}</p>
				<p>
					<strong>Deployment phase</strong>
				</p>
				<p>It is scheduled for the <strong>{{node.details.phase}}</strong> phase of the deployment.</p>
				<ng-container *ngIf='shouldDisplayConditions'>
					<p>
						<strong>Conditions</strong>
					</p>
					<p>
						This command will only be executed if at least one of the following conditions is met:
					</p>
					<ul>
						<li *ngFor="let file of conditionFiles">
							<span>Change in file </span>
							<span class='link-button' (click)='navigateToArtifact(file.fileId)'>
								{{file.path | nameFromPath}}
							</span>
						</li>
						<li *ngFor='let trigger of conditionFailureTriggers'>
							<span>Failed </span><span [class.link-button]='canNavigateToTrigger' (click)='navigateToTrigger(trigger.triggerId)'>{{trigger.label}}</span>
						</li>
						<li *ngFor='let trigger of conditionSuccessTriggers'>
							<span>Success </span><span [class.link-button]='canNavigateToTrigger' (click)="navigateToTrigger(trigger.triggerId)">{{trigger.label}}</span>
						</li>
					</ul>
				</ng-container>
			</div>
		</div>
	</div>
</div>
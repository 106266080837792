import { FileUploadResult, FileUploadStatus } from '../model/file-upload-result.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHelper } from './error.helper';

export class FileUploadResultHelper {
  static createSuccessFileUploadResult<ResultType>(file: File, result?: ResultType): FileUploadResult<ResultType> {
    return {
      fileName: file.name,
      status: FileUploadStatus.Success,
      result: result
    };
  }

  static createFailFileUploadResult(file: File, error: HttpErrorResponse, defaultMessage?: string): FileUploadResult {
    return {
      fileName: file.name,
      status: FileUploadStatus.Fail,
      errorDetail: ErrorHelper.getErrorDetail(error, defaultMessage)
    };
  }
}

import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Pattern } from '../patterns/pattern.model';
import { PatternType } from '../plugins/pattern-type.model';
import { PatternHelper } from '../patterns/pattern.helper';
import { ParsedBundle } from '../resources/bundle-management/bundle.model';
import { BundleHelper } from '../resources/bundle-management/bundle.helper';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-pattern-info-popover',
  template: `
    <ng-template #unknownPattern>
      <p class='pattern-title'>
        <strong>Unknown pattern</strong>
      </p>
      <p class='pattern-description'>The class (script) for this pattern was not found. Please enable missing pattern libraries under project settings.</p>
    </ng-template>
    <ng-template #standardPattern>
      <p class='pattern-title'>
        <strong>NEVIS Standard Library</strong>
      </p>
      <p class='pattern-description'>This pattern is part of the NEVIS Standard Library and has been tested for NEVIS compatibility.</p>
    </ng-template>
    <ng-template #experimentalStandardPattern>
      <p class='pattern-title'>
        <strong>Experimental Pattern</strong>
      </p>
      <p class='pattern-description'>Since the pattern is experimental, please do not use for production setups. It may change considerably over time.</p>
    </ng-template>
    <ng-template #customPattern>
      <p class='pattern-title'>
        <strong>Custom pattern</strong>
      </p>
      <p class='pattern-description'>This custom pattern has not been tested by NEVIS.</p>
    </ng-template>
    <ng-container *ngTemplateOutlet='description'></ng-container>
    <ng-container *ngIf='!isUnknownPattern'>
      <p class='class-information-item'>
        <strong>Type: </strong> {{pattern.className}}
      </p>
      <p class='class-information-item'>
        <strong>Bundle: </strong> {{bundle.symbolicName}}
      </p>
      <p class='class-information-item'>
        <strong>Version: </strong> {{bundle.version}}
      </p>
    </ng-container>
  `,
  styleUrls: ['./pattern-info-popover.component.scss']
})
export class PatternInfoPopoverComponent implements OnInit, AfterViewInit {
  @Input() pattern: Pattern;
  @Input() patternType: PatternType | undefined;
  @Input() bundleKey?: string;
  @ViewChild('unknownPattern', {static: false}) unknownPatternTemplate: TemplateRef<any>;
  @ViewChild('standardPattern', {static: false}) standardPatternTemplate: TemplateRef<any>;
  @ViewChild('experimentalStandardPattern', {static: false}) experimentalStandardPatternTemplate: TemplateRef<any>;
  @ViewChild('customPattern', {static: false}) customPatternTemplate: TemplateRef<any>;

  description: TemplateRef<any>;
  bundle: ParsedBundle | null;

  ngOnInit(): void {
    this.bundle = _.isNil(this.bundleKey) ? null : BundleHelper.parseBundleFromBundleKey(this.bundleKey);
  }

  ngAfterViewInit() {
    setTimeout(() => this.description = this.resolveDescriptionTemplate());
  }

  resolveDescriptionTemplate(): TemplateRef<any> {
    if (PatternHelper.isUnknownPattern(this.patternType)) {
      return this.unknownPatternTemplate;
    } else if (PatternHelper.isExperimentalStandardPattern(this.pattern, this.patternType)) {
      return this.experimentalStandardPatternTemplate;
    } else if (PatternHelper.isStandardPattern(this.pattern)) {
      return this.standardPatternTemplate;
    } else {
      return this.customPatternTemplate;
    }
  }

  get isUnknownPattern(): boolean {
    return PatternHelper.isUnknownPattern(this.patternType) || _.isNil(this.bundle);
  }
}

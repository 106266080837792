import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ParsedBundle } from '../bundle.model';

@Component({
  selector: 'adm4-bundle-table-component',
  template: `
    <mat-table class='adm4-mat-table full-width bundle-table' [dataSource]='bundles'>
      <ng-container [matColumnDef]="bundleNameColumnName">
        <mat-cell *matCellDef="let bundle"> {{bundle.symbolicName}} </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="versionColumnName">
        <mat-cell *matCellDef="let bundle">
        <span class='bundle-version-container'>
          <a [href]='bundle.bundleKey | bundleDownloadUrl' download class='link-with-icon'><mat-icon>file_download</mat-icon></a>
          <span>{{bundle.version}}</span>
        </span>
        </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BundleTableComponent {
  @Input() bundles: ParsedBundle[];

  readonly bundleNameColumnName = 'bundleName';
  readonly versionColumnName = 'version';

  displayedColumns = [this.bundleNameColumnName, this.versionColumnName];
}

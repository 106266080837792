import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { inventoryKeyParam } from '../inventory-routing.constants';
import * as _ from 'lodash';
import { REVISION_KEY_SEPARATOR } from './inventory-deployment.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryDeploymentHistoryGuard  {

  constructor(private modalNotificationService: ModalNotificationService) {}

  canActivate(next: ActivatedRouteSnapshot): boolean {
    const inventoryKey: string | undefined = _.isNil(next.parent) ? undefined : next.parent.params[inventoryKeyParam];
    if (_.isNil(inventoryKey)) {
      return false;
    }
    if (_.includes(inventoryKey, REVISION_KEY_SEPARATOR)) {
      this.modalNotificationService.openErrorDialog({title: 'Cannot navigate to Deployment History screen', description: 'Revision inventories are not allowed to have deployment history'});
      return false;
    }
    return true;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { DeploymentFileInfo } from '../deployment-file-view/deployment-file-urls.model';
import { DeploymentFileViewService } from '../deployment-file-view/deployment-file-view.service';

@Component({
  selector: 'adm4-deployment-file-view-container',
  template: `
    <div class='full-height-flex'>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <div class='full-height-flex'>
            <adm4-deployment-file-view *ngIf='fileInfo'
                                       class='full-height'
                                       [fileInfo]="fileInfo"
                                       [boxShadowClass]='boxShadowClass'></adm4-deployment-file-view>
            <div class='file-view-action-bar'>
              <button class='admn4-button-ellipse-blue close-button' (click)='closeFileView()'>Close viewer</button>
            </div>
          </div>
        </div>
      </div>
      <div class='empty-step-action-bar'></div>
    </div>
  `,
  styleUrls: ['./deployment-file-view-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentFileViewContainerComponent implements OnDestroy {
  @Input() fileInfo: DeploymentFileInfo;
  @Input() boxShadowClass: string;

  constructor(private fileViewer: DeploymentFileViewService) {}

  ngOnDestroy(): void {
    this.closeFileView();
  }

  closeFileView(): void {
    this.fileViewer.viewingFileInfo.next(null);
  }
}

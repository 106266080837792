import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Revision } from '../../../version-control/revision/revision.model';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-deployment-revision-information',
  template: `
    <div>
      <div class='deployment-detail-label'>Commit ID</div>
      <div class='copy-clipboard-container'>
        <div class='text-to-copy'>{{commitId}}</div>
        <mat-icon [cdkCopyToClipboard]="commitId">content_copy</mat-icon>
      </div>
      <ng-container *ngIf='hasComment'>
        <div class='deployment-detail-label'>Comment</div>
        <div *ngIf='revision.message && {showMore: false} as showMoreInfo '>
          <div class='comment-content'>
            {{(showMoreInfo.showMore) ? revision.message : (revision.message | slice:0:50)}}
            <span *ngIf="!showMoreInfo.showMore && revision.message.length > 50">...</span><br>
            <a href="javascript:void(0)" *ngIf="!showMoreInfo.showMore && revision.message.length > 50" (click)="showMoreInfo.showMore=true">Show More</a>
          </div>
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['./deployment-revision-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentRevisionInformationComponent {
  @Input() commitId: string;
  @Input() revision: Revision | null;

  get hasComment(): boolean {
    return !_.isNil(this.revision) && !_.isEmpty(this.revision.message);
  }
}

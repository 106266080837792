import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  publishReplay,
  refCount,
  startWith,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { GenerationStatusModel } from './generation-status.model';
import { IssueModel } from '../../common/model/issue.model';
import { getGeneralIssues, isInfoIssue } from '../../projects/project-issues/project-issues.helper';
import { ValidateDeploymentHelper } from './validate-deployment.helper';
import { PatternIssueData } from '../../issues/model/pattern-issue-data.model';
import { PatternInstance } from '../../patterns/pattern-instance.model';
import { PatternType } from '../../plugins/pattern-type.model';
import { ProjectIssuesDisplayHelper } from '../../projects/project-issues-display.helper';
import { AppState, Dictionary } from '../../model/reducer';
import { PropertyType } from '../../plugins/property-type.model';
import * as _ from 'lodash';
import { select, Store } from '@ngrx/store';
import { createDummyPatternInstance } from '../../patterns/pattern-instance.helper';
import { PatternService } from '../../patterns/pattern.service';
import { DeploymentWizardContext } from '../deployment-wizard.context';
import { FetchGenerationOutput } from '../../model/deploy';
import { GenerationOutputModel } from '../deploy/deployment-preview/planning-output.model';
import { Inventory, InventoryMeta } from '../../inventory/inventory.model';
import { Project, ProjectMeta } from '../../projects/project.model';
import {
  deploymentGenerationIsInProgressView,
  deploymentGenerationIssuesView,
  deploymentGenerationOutputView,
  deploymentGenerationStatusView,
  deploymentIdView,
  deploymentWizardSelectionInventoryKeyView,
  deploymentWizardSelectionProjectKeyView,
  selectedInventoryForDeployment,
  selectedProjectForDeployment
} from '../../model/views';
import { hasInventoryDeployAccessView } from '../../model/views/permission.views';
import { VersionControlService } from '../../version-control/version-control.service';
import { DeploymentPropertyContext } from '../deployment-properties-context.service';
import { PropertyWidgetContext } from '../../property-widgets/property-widget.context';
import { InventoryService } from '../../inventory/inventory.service';
import { IssueReducingHelper } from '../../projects/project-issues/issue-reducing.helper';
import { FileDownloader } from '../../common/helpers/file-downloader';
import { FileService } from '../../common/services/file/file.service';
import { DeploymentService } from '../deployment-dialog/deployment.service';
import { filterEmpty } from '../../common/utils/utils';

@Injectable()
export class ValidateDeploymentContext implements OnDestroy {

  deploymentId$: Observable<string>;
  generationStatus$: Observable<GenerationStatusModel | undefined>;
  generationIssues$: Observable<IssueModel[]>;
  generalIssues$: Observable<IssueModel[]>;
  isValidationInProgress$: Observable<boolean>;
  isGenerationFailed$: Observable<boolean>;
  isValidationSuccessful$: Observable<boolean>;
  canBeAborted$: Observable<boolean>;
  generationOutput$: Observable<GenerationOutputModel[]>;
  patternIssueGroups$: Observable<PatternIssueData[]>;
  propertyTypes$: Observable<Dictionary<PropertyType> | null>;
  patternTypes$: Observable<Dictionary<PatternType> | null>;
  issuedPatterns$: Observable<PatternInstance[]>;
  projectKey$: Observable<string | undefined>;
  selectedInventoryKey$: Observable<string | undefined>;
  hasDeployPermission$: Observable<boolean>;
  publishRequired$: Observable<boolean>;
  disablePreviewDeployment$: Observable<boolean>;
  shouldShowValidationResults$: Observable<boolean>;
  shouldShowGenerationResultsButton$: Observable<boolean>;
  shouldDisplayGeneralIssues$: Observable<boolean>;
  selectedIssues$: BehaviorSubject<IssueModel[]> = new BehaviorSubject([]);

  constructor(private store$: Store<AppState>,
              private deploymentWizardContext: DeploymentWizardContext,
              @Inject(PropertyWidgetContext) private deploymentPropertyContext: DeploymentPropertyContext,
              private patternService: PatternService,
              private inventoryService: InventoryService,
              private versionControlService: VersionControlService,
              private fileService: FileService,
  ) {
    this.hasDeployPermission$ = this.store$.pipe(select(hasInventoryDeployAccessView));

    this.deploymentId$ = this.store$.select(deploymentIdView).pipe(filter(filterEmpty));
    this.generationStatus$ = this.store$.pipe(select(deploymentGenerationStatusView));
    this.generationIssues$ = this.store$.pipe(select(deploymentGenerationIssuesView), map((issues: IssueModel[]) => issues.filter(issue => !isInfoIssue(issue))));

    this.generalIssues$ = this.generationIssues$.pipe(
      filter((issues: IssueModel[]) => !_.isEmpty(issues)),
      map((issues: IssueModel[]) => getGeneralIssues(issues)),
      map((issues: IssueModel[]) => IssueReducingHelper.getUniqueIssues(issues))
    );
    this.shouldDisplayGeneralIssues$ = combineLatest([
      this.generalIssues$,
      this.selectedIssues$
    ]).pipe(
      filter((tuple: [IssueModel[], IssueModel[]]) => tuple.every(entry => !_.isEmpty(entry))),
      map(([generalIssues, selectedIssues]: [IssueModel[], IssueModel[]]) => generalIssues.some((generalIssue: IssueModel) => !_.isNil(_.find(selectedIssues, (selectedIssue: IssueModel) => IssueReducingHelper.compareIssuesForIdentity(selectedIssue, generalIssue)))))
    );

    this.isValidationInProgress$ = this.store$.pipe(select(deploymentGenerationIsInProgressView));
    this.isGenerationFailed$ = this.isValidationInProgress$.pipe(withLatestFrom(this.generationStatus$),
      map(([isValidationInProgress, generationStatus]) => !isValidationInProgress && ValidateDeploymentHelper.isGenerationFailed(generationStatus)));
    this.isValidationSuccessful$ = this.isValidationInProgress$.pipe(withLatestFrom(this.generationStatus$),
      map(([isValidationInProgress, generationStatus]) => !isValidationInProgress && ValidateDeploymentHelper.isValidationSuccessful(generationStatus)));
    this.canBeAborted$ = this.generationStatus$.pipe(map((generationStatus: GenerationStatusModel | undefined) => ValidateDeploymentHelper.isGenerationInProgress(generationStatus)));
    this.shouldShowValidationResults$ = combineLatest([
      this.isValidationInProgress$,
      this.isValidationSuccessful$,
      this.generationIssues$,
    ]).pipe(map(([isValidationInProgress, isValidationSuccessFul, generationIssues]) => !isValidationInProgress && (!isValidationSuccessFul || !_.isEmpty(generationIssues))));
    this.shouldShowGenerationResultsButton$ = combineLatest([
      this.isValidationInProgress$,
      this.isGenerationFailed$
    ]).pipe(map(([isValidationInProgress, isGenerationEntirelyFailed]) => !isValidationInProgress && !isGenerationEntirelyFailed));
    this.generationOutput$ = this.store$.pipe(select(deploymentGenerationOutputView));

    this.propertyTypes$ = deploymentWizardContext.propertyTypes;
    this.patternTypes$ = deploymentWizardContext.patternTypes;
    this.projectKey$ = this.store$.pipe(select(deploymentWizardSelectionProjectKeyView));
    this.selectedInventoryKey$ = this.store$.pipe(select(deploymentWizardSelectionInventoryKeyView));
    this.issuedPatterns$ = this.generationIssues$.pipe(
      map(issues => ProjectIssuesDisplayHelper.getIssuedPatternIds(issues)),
      distinctUntilChanged(_.isEqual),
      withLatestFrom(this.projectKey$),
      switchMap(([patternIds, projectKey]: [string[], string]) => {
        if (_.isEmpty(patternIds)) {
          return of([]);
        }
        return forkJoin(
          patternIds.map(patternId => this.patternService.getPatternInstance(projectKey, patternId).pipe(
            // if for any reason pattern fails to return pattern instance then we create a dummy pattern instance to keep the rest of the process working
            catchError(() => of(createDummyPatternInstance(patternId))))
          )
        );
      }),
      publishReplay(),
      refCount()
    );
    this.patternIssueGroups$ = combineLatest([
      this.issuedPatterns$,
      this.patternTypes$,
      this.propertyTypes$,
      this.selectedIssues$
    ]).pipe(
      filter((tuple: [PatternInstance[], Dictionary<PatternType> | null, Dictionary<PropertyType> | null, IssueModel[]]) => tuple.every(entry => !_.isNil(entry))),
      map(([patterns, patternTypes, propertyTypes, issues]: [PatternInstance[], Dictionary<PatternType>, Dictionary<PropertyType>, IssueModel[]]) => {
        return ValidateDeploymentHelper.getPatternIssueGroupsOfSelectedIssues(issues, patterns, patternTypes, propertyTypes);
      }),
      publishReplay(),
      refCount()
    );
    this.publishRequired$ = combineLatest([
      this.store$.pipe(select(selectedInventoryForDeployment)),
      this.store$.pipe(select(selectedInventoryForDeployment), switchMap((inventory: Inventory | null) => _.isNil(inventory) ? of(null) : this.inventoryService.getInventoryMeta(inventory.inventoryKey))),
      this.store$.pipe(select(selectedProjectForDeployment), switchMap((project: Project | null) => _.isNil(project) ? of(null) : this.versionControlService.getProjectStatus(project.projectKey)))
    ]).pipe(
      map(([selectedInventory, inventoryMeta, projectMeta]: [Inventory | null, InventoryMeta | null, ProjectMeta | null]) => {
        return ValidateDeploymentHelper.isPublishRequired(selectedInventory, inventoryMeta, projectMeta);
      }),
      // basically it's same as shareReplay in terms of result, but shareReplay is bugged as doesn't clean up inner subscription
      publishReplay(),
      refCount()
    );
    this.disablePreviewDeployment$ = combineLatest([
      this.hasDeployPermission$,
      this.isGenerationFailed$,
      this.publishRequired$
    ]).pipe(
      map(([hasDeployPermission, isGenerationEntirelyFailed, publishRequired]: [boolean, boolean, boolean]) => !hasDeployPermission || isGenerationEntirelyFailed || publishRequired),
      startWith(true)
    );
    this.deploymentPropertyContext.loadAttachmentsForPatterns(this.issuedPatterns$);
  }

  ngOnDestroy(): void {
    this.deploymentPropertyContext.resetAttachments();
  }

  selectIssues(issues: IssueModel[]): void {
    this.selectedIssues$.next(issues);
  }

  loadGenerationOutput(deploymentId: string): void {
    this.store$.dispatch(new FetchGenerationOutput(deploymentId));
  }

  downloadGenerationResult() {
    combineLatest([
      this.deploymentId$,
      this.projectKey$.pipe(filter(filterEmpty)),
      this.selectedInventoryKey$.pipe(filter(filterEmpty)),
    ]).pipe(first()).subscribe(([deploymentId, projectKey, inventoryKey]: [string, string, string]): void => {
      const fileName = `generation_${projectKey}_${inventoryKey}_${new Date().toISOString()}.zip`;
      const url = DeploymentService.createGenerationResultUrl(deploymentId);
      this.fileService.loadFile(url).subscribe((blob: Blob) => FileDownloader.downloadFile(blob, fileName));
    });
  }
}

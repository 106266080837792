import { map, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';

import {
  Inventory,
  InventorySchemaType,
} from '../inventory.model';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { InventoryMainContext } from './inventory-main.context';
import { InventoryEditorContextService } from '../inventory-editor/insert-secrets-modal/inventory-editor-context.service';
import * as _ from 'lodash';
import { InventoryContext } from '../inventory.context';
import { AppState, Dictionary } from '../../model/reducer';
import { SplitPaneConfig } from '../../common/model/split-pane-config.model';
import { IOutputData } from 'angular-split/lib/interface';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../common/mixins/split.mixin';
import { ResizeHelper } from '../../common/helpers/resize.helper';
import { Maybe } from '../../common/utils/utils';
import { SelectDiffInventory } from '../../model/inventory';
import { FilePosition } from '../../common/model/file-info.model';

@Component({
  selector: 'adm4-inventory-main',
  template: `
    <div class='full-height'>
      <as-split class="hide-as-split-gutter" direction='horizontal' gutterSize='1' useTransition (dragEnd)='onResize($event)'>
        <as-split-area [order]='splitPaneConfig[editorSplitAreaKey].order' [size]='splitPaneConfig[editorSplitAreaKey].size' [minSize]='40'>
          <div class="full-height-flex">
            <adm4-inventory-header [currentInventory]='currentInventory$ | async'
                                   [inventories]="inventories$ | async"
                                   [readOnly]="isCurrentInventoryReadonly$ | async"
                                   [inventoryValidationStatus]='inventoryValidationStatus$ | async'
                                   [inventoryBackgroundClassName]='inventoryBackgroundCssClass$ | async'
                                   [isHelpCollapsed]='splitPaneConfig[helpSplitAreaKey].isCollapsed'
                                   [diffViewEnabled]="true"
                                   [selectedDiffInventory]="diffInventoryKey$ | async"
                                   (diffInventorySelectionChanged)="diffInventorySelectionChanged($event)"
                                   (expandHelp)='onCollapse(false, helpSplitAreaKey)'
            ></adm4-inventory-header>
            <div *ngIf='currentInventory$ | async' class='remaining-space-flex-content-wrapper'>
              <div class='remaining-space-flex-content inventory-editor-container'>
                <adm4-inventory-editor [readOnly]="isCurrentInventoryReadonly$ | async"
                                       [code]='inventoryContent$ | async'
                                       [color]='inventoryColor$ | async'
                                       [inventoryKey]='inventoryKey$ | async'
                                       [diffInventoryKey]="diffInventoryKey$ | async"
                                       [diffCode]="diffInventoryContent$ | async"
                                       [editorPosition]="editorPosition$ | async"
                                       [validationStatus]="inventoryValidationStatus$ | async "
                                       (saveInventoryContent)='saveInventory($event)'
                                       (dirty)='codeHasChanged($event)'
                                       (codeChange)='onCodeChange($event)'></adm4-inventory-editor>
              </div>
            </div>
          </div>
        </as-split-area>
        <as-split-area [order]='splitPaneConfig[helpSplitAreaKey].order' [size]='splitPaneConfig[helpSplitAreaKey].size' [minSize]='20' [visible]='!splitPaneConfig[helpSplitAreaKey].isCollapsed'>
          <div class='full-height'>
            <adm4-inventory-help [inventorySchemaType]='inventorySchemaType$ | async'
                                 (collapse)='onCollapse($event, helpSplitAreaKey)'></adm4-inventory-help>
          </div>
        </as-split-area>
      </as-split>
    </div>
  `,
  styleUrls: ['./inventory-main.component.scss'],
  providers: [InventoryMainContext, InventoryEditorContextService],
})
@Mixin([SplitMixin])
export class InventoryMainComponent implements ISplitMixin, OnDestroy {
  inventoryKey$: Observable<string | null>;
  inventories$: Observable<Inventory[]>;
  inventoryContent$: Observable<string | null>;
  inventoryValidationStatus$: Observable<ValidationStatus<InventoryValidationIssue> | null>;
  currentInventory$: Observable<Inventory | undefined>;
  inventoryColor$: Observable<string>;
  inventorySchemaType$: Observable<InventorySchemaType>;
  inventoryBackgroundCssClass$: Observable<string>;
  isCurrentInventoryReadonly$: Observable<boolean>;
  diffInventoryKey$: Observable<Maybe<string>>;
  diffInventoryContent$: Observable<Maybe<string>>;
  public editorPosition$: Observable<Maybe<FilePosition>>;

  hasUnsavedChanges = false;

  readonly editorSplitAreaKey = 'editor';
  readonly helpSplitAreaKey = 'help';
  readonly splitPaneConfigLocalStorageKey = 'inventory-settings-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.editorSplitAreaKey]: {order: 0, size: 70},
    [this.helpSplitAreaKey]: {order: 1, size: 30, isCollapsed: false}
  };
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  /**
   * Implemented by SplitMixin
   */
  onCollapse: (isCollapsed: boolean, collapsibleAreaKey: string) => void;

  constructor(
      private inventoryMainContext: InventoryMainContext,
      private inventoryContext: InventoryContext,
      private store$: Store<AppState>,
      editorContext: InventoryEditorContextService,
  ) {
    this.inventoryKey$ = this.inventoryContext.inventoryKey$;
    this.inventories$ = this.inventoryContext.inventories$.pipe(map(_.values));
    this.inventoryContent$ = this.inventoryMainContext.inventoryContent$;
    this.inventoryValidationStatus$ = this.inventoryContext.inventoryValidationStatus$;
    this.currentInventory$ = this.inventoryContext.currentInventory$;
    this.inventoryColor$ = this.inventoryMainContext.inventoryColor$;
    this.inventorySchemaType$ = this.inventoryMainContext.inventorySchemaType$;
    this.inventoryBackgroundCssClass$ = this.inventoryMainContext.inventoryBackgroundCssClass$;
    this.isCurrentInventoryReadonly$ = inventoryContext.isCurrentInventoryReadonly$;
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
    this.inventoryMainContext.pollInventoryTimestamp().pipe(takeUntil(this.destroyed$)).subscribe();
    this.diffInventoryKey$ = editorContext.diffInventoryKey$;
    this.diffInventoryContent$ = editorContext.diffInventoryContent$;
    this.editorPosition$ = editorContext.editorPosition$;
  }

  saveInventory(inventoryContent: string): void {
    // inventory content will be saved by effect of this action if content is valid or errored content was confirmed by user
    this.inventoryMainContext.saveInventory(inventoryContent);
  }

  codeHasChanged(hasChanged: boolean): void {
    this.hasUnsavedChanges = hasChanged;
    this.inventoryMainContext._inventoryHasUnsavedChanges$.next(hasChanged);
  }

  diffInventorySelectionChanged(newSelectedDiffInventoryKey: Maybe<string>) {
    this.store$.dispatch(new SelectDiffInventory(newSelectedDiffInventoryKey));
    if (newSelectedDiffInventoryKey) {
      this.onCollapse(true, this.helpSplitAreaKey);
    }
  }

  onCodeChange(inventoryContent: string): void {
    this.inventoryMainContext.validateInventory(inventoryContent);
    this.inventoryMainContext._editedInventoryContent$.next(inventoryContent);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
    this.store$.dispatch(new SelectDiffInventory(null));
  }
}

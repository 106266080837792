import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { DeployedServiceItem, DeployedServiceStatus, Pod } from '../deployed-service.model';
import { ValidationIndicator } from '../../../common/components/validation/validation-indicator.component';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-operator-status',
  template: `
    <mat-expansion-panel *ngIf="isPresent; else noOperator">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2 class="title">
            <adm4-validation-indicator [validationStatus]="operatorStatus" [diameter]="9"
                                       [isDisplayed]="true"></adm4-validation-indicator>
            <span>{{operator?.serviceKey}}</span>
          </h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
        <div class="row header">
          <div class="col-2"><span>Pod status</span></div>
          <div class="col-4"><span>Pod</span></div>
          <div class="col-2"><span>Docker image</span></div>
          <div class="col-4"><span>Docker image repository</span></div>
        </div>
        <div class="row pod" *ngFor="let pod of operator.pods">
          <div class="col-2">
            <adm4-pod-status-indicator [pod]="pod"></adm4-pod-status-indicator>
          </div>
          <div class="col-4">
            <adm4-pod-details [pod]="pod" [isOperator]="true" (viewLogs)="viewLogsClicked($event)"></adm4-pod-details>
          </div>
          <div class="col-2">{{getImageName(pod.image)}}</div>
          <div class="col-4">{{pod.image}}</div>
        </div>
    </mat-expansion-panel>
    <ng-template #noOperator>
      <mat-card class="no-operator cursor-default">
        <mat-card-content>
          <adm4-validation-indicator [validationStatus]="'neutral'" [diameter]="9"
                                     [isDisplayed]="true"></adm4-validation-indicator>
          <span>No operator services found.</span>
        </mat-card-content>
      </mat-card>
    </ng-template>
  `,
  styleUrls: ['./operator-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorStatusComponent implements OnChanges {

  @Input() services: DeployedServiceItem[] | undefined;
  @Output() viewLogs: EventEmitter<Pod> = new EventEmitter<Pod>();

  isPresent: boolean = false;
  operator: DeployedServiceItem | undefined;
  operatorStatus: ValidationIndicator | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.services) {
      if (this.services && this.services.length > 0) {
        this.isPresent = true;
        this.operator = this.services[0];
        this.operatorStatus = this.calculateOperatorStatus(this.operator);
      } else {
        this.isPresent = false;
        this.operator = undefined;
        this.operatorStatus = 'neutral';
      }
    }
  }

  private calculateOperatorStatus(service: DeployedServiceItem): ValidationIndicator {
    switch(service.status) {
      case DeployedServiceStatus.Stable:
          return 'valid';
      case DeployedServiceStatus.Failed:
        return 'error';
      case DeployedServiceStatus.Unknown:
        return 'warning';
    }
  }

  viewLogsClicked(pod): void {
    this.viewLogs.emit(pod);
  }

  getImageName(imageName: string){
    if(_.isEmpty(imageName)){
      return '';
    }
    return imageName.split('/').pop();
  }

}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeploymentSelectionListComponent } from '../deployment-selection-list/deployment-selection-list.component';
import * as _ from 'lodash';
import { DeployToClassicOptionType, DeployToKubernetesOptionType, DeployToOption } from '../deploy-to-option.model';
import { DeployToOptionHelper } from '../deploy-to-option.helper';
import { PatternMasterListHelper } from '../../../patterns/pattern-master-list.helper';
import { InventoryColorHelper } from '../../../common/helpers/inventory-color.helper';

@Component({
  selector: 'adm4-host-list',
  template: `
    <div class='full-height-flex'>
      <h2 class='step-content-header'>Deploy to</h2>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <div class="list-group" [ngClass]='boxShadowClass'>
            <div class='search-filter'>
              <adm4-filter (filter)="filterBySearch($event)"
                           [placeholderText]='"Type to filter"'
                           [filterText]="filterText"
                           class='search-input'>
              </adm4-filter>
            </div>
            <div *ngIf='shouldShowEmptyFilterResultMessage' class='no-result-found-text'>No deployment target found.</div>
            <div class='selection-list'>
              <ng-container *ngFor='let host of filteredItems; let i = index'>
                <div class="menu-item-wrapper indictable-host" [class.selected]='isSelectedOption(host)' [ngClass]='getInventorySelectionItemClassName(inventoryColor)'>
                  <button mat-button #selectionList class="btn-font-regular"
                          (keydown)='keyDown($event, i)'
                          (click)='selectItem(host, i)'>
                    <mat-icon [title]='getDeployToOptionTooltipByType(host.type)'>{{getDeployToOptionIconByType(host.type)}}</mat-icon>
                    <span title="{{host.name}}">{{host.name}}</span>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./deploy-target-list.component.scss', '../deployment-selection-list/deployment-selection-list.component.scss']
})
export class DeployTargetListComponent extends DeploymentSelectionListComponent<DeployToOption> implements OnChanges {
  @Input() preSelectedHostExpression: string;
  @Input() boxShadowClass: string;
  @Input() inventoryColor: string;

  readonly getInventorySelectionItemClassName = (color: string) => InventoryColorHelper.getInventorySelectionItemClassName(color);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.preSelectedHostExpression || (changes.items && !_.isEqual(changes.items.previousValue, changes.items.currentValue))) {
      this.activeItemIndex = _.findIndex(this.items, (item: DeployToOption) => item.hostExpression === this.preSelectedHostExpression);
      if (this.activeItemIndex >= 0) {
        this.itemSelected.emit(this.items[this.activeItemIndex]);
      }
      this.filteredItems = this.getFilteredItemList();
    }
  }

  getDeployToOptionIconByType(deployToType: DeployToClassicOptionType | DeployToKubernetesOptionType): string {
    return DeployToOptionHelper.getDeployToOptionIconByType(deployToType);
  }

  getDeployToOptionTooltipByType(deployToType: DeployToClassicOptionType | DeployToKubernetesOptionType): string | undefined {
    return DeployToOptionHelper.getDeployToOptionTooltipByType(deployToType);
  }

  override getFilteredItemList = () => {
      return PatternMasterListHelper.getWildCardFilteredItemList(this.items, this.filterText, (deployToOption) => deployToOption.name)
        .sort((item1, item2) => DeployToOptionHelper.sortByDeployTargetSortingFn(item1, item2));
  };

  isSelectedOption(deployToOption: DeployToOption): boolean {
    return _.isEqual(this.preSelectedHostExpression, deployToOption.hostExpression);
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of } from 'rxjs';
import { EmptyAction, NevisAdminAction } from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from '../reducer';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { FailedToLoadLoadPatternSummaryReport, GenerateReport, GetGenerationOutput, GetGenerationOutputComplete, LoadPatternSummaryReports, LoadPatternSummaryReportsSuccess, LoadReports, LoadReportsSuccess, PollGenerationStatus, PollGenerationStatusSuccess, ReportActionTypes, StopGeneration } from './report.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { PluginService } from '../../plugins/plugin.service';
import { ReportsService } from '../../reports/reports.service';
import { ReportGenerationModel, ReportGenerationStatus, ReportGenerationStatusModel } from '../../reports/reports.model';
import { ReportsHelper } from '../../reports/reports.helper';
import { PluginClassEnum } from '../../plugins/plugin.enums';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { ProjectService } from '../../projects/project.service';
import { PROJECT_OVERVIEW_APPLICATIONS_REPORT_CLASS_NAME, PROJECT_OVERVIEW_INSTANCES_REPORT_CLASS_NAME, PROJECT_OVERVIEW_REALMS_REPORT_CLASS_NAME, ProjectSummaryReportGenerationModel, ProjectSummaryReportModel } from '../../projects/project-summary/project-summary-report/project-summary-report.model';

@Injectable()
export class ReportEffects {

  private readonly GENERATE_REPORT_MODAL_TITLE = 'Generate report';

   loadReports: Observable<LoadReportsSuccess | EmptyAction> = createEffect(() => this.actions$
    .pipe(ofType(ReportActionTypes.LoadReports))
    .pipe(
      map((action: LoadReports) => action.payload),
      switchMap((projectKey: string) => {
        return this.pluginService.getPluginClassesFilteredByClass(projectKey, PluginClassEnum.Report).pipe(
          map((reports) => new LoadReportsSuccess(reports)),
          catchError((error) => {
            const projectName = TenantHelper.cropTenantFromKey(projectKey);
            this.modalNotificationService.openErrorDialog({title: 'Load report', description: `Loading reports of the project ${projectName} failed`});
            console.error(error);
            return of(new EmptyAction());
          }));
      })
    ));

   generateReport: Observable<PollGenerationStatus | StopGeneration> = createEffect(() => this.actions$
    .pipe(ofType(ReportActionTypes.GenerateReport))
    .pipe(
      map((action: GenerateReport) => action.payload),
      switchMap((generationModel: ReportGenerationModel) => {
        return this.reportService.generateReport(generationModel).pipe(
          map((result: ReportGenerationStatusModel) => new PollGenerationStatus(result.reportId)),
          catchError((error) => {
            this.modalNotificationService.openErrorDialog({title: this.GENERATE_REPORT_MODAL_TITLE, description: `Could not generate report for ${generationModel.reportClassName}`});
            console.error(error);
            return of(new StopGeneration());
          }));
      })
    ));

   pollGenerationStatus: Observable<PollGenerationStatusSuccess | GetGenerationOutput | StopGeneration> = createEffect(() => this.actions$
    .pipe(ofType(ReportActionTypes.PollGenerationStatus))
    .pipe(
      map((action: PollGenerationStatus) => action.payload),
      switchMap((reportId: string) => {
        return this.reportService.pollGenerationStatus(reportId).pipe(
          mergeMap((result: ReportGenerationStatusModel) => {
            const actionsToDispatch: (PollGenerationStatusSuccess | GetGenerationOutput | StopGeneration)[] = [new PollGenerationStatusSuccess(result)];
            if (result.overallStatus === ReportGenerationStatus.DONE) {
              actionsToDispatch.push(new GetGenerationOutput(result));
            } else if (result.overallStatus === ReportGenerationStatus.NOTHING_TO_DO ) {
              actionsToDispatch.push(new StopGeneration());
            }
            return of(...actionsToDispatch);
          }),
          catchError((error) => {
            this.modalNotificationService.openErrorDialog({title: this.GENERATE_REPORT_MODAL_TITLE, description: `Could not generate report`});
            console.error(error);
            return of(new StopGeneration());
          }));
      })
    ));

   getGenerationOutput: Observable<GetGenerationOutputComplete> = createEffect(() => this.actions$
    .pipe(ofType(ReportActionTypes.GetGenerationOutput))
    .pipe(
      map((action: GetGenerationOutput) => action.payload),
      switchMap((genStatusModel: ReportGenerationStatusModel) => {
        const reportKey = ReportsHelper.createReportKey(genStatusModel);
        return this.reportService.getReportOutput(genStatusModel.reportId).pipe(
          map((output: string) => ReportsHelper.applyEdits(output, genStatusModel.projectKey)),
          map((output: string) => new GetGenerationOutputComplete({reportKey, generationOutput: output})),
          catchError((error) => {
            this.modalNotificationService.openErrorDialog({title: this.GENERATE_REPORT_MODAL_TITLE, description: `Could not get generated report`});
            console.error(error);
            return of(new GetGenerationOutputComplete({reportKey, generationOutput: null}));
          }));
      })
    ));

   loadPatternSummaryReports: Observable<LoadPatternSummaryReportsSuccess | FailedToLoadLoadPatternSummaryReport> = createEffect(() => this.actions$
    .pipe(ofType(ReportActionTypes.LoadPatternSummaryReports))
    .pipe(
      map((action: LoadPatternSummaryReports) => action.payload),
      switchMap((projectKey: string) => {
        const mediaType = 'application/json';
        const applicationReportGenModel: ProjectSummaryReportGenerationModel = {
          reportClassName: PROJECT_OVERVIEW_APPLICATIONS_REPORT_CLASS_NAME,
          mediaType: mediaType,
        };
        const realmsReportGenModel: ProjectSummaryReportGenerationModel = {
          reportClassName: PROJECT_OVERVIEW_REALMS_REPORT_CLASS_NAME,
          mediaType: mediaType,
        };
        const instancesReportGenModel: ProjectSummaryReportGenerationModel = {
          reportClassName: PROJECT_OVERVIEW_INSTANCES_REPORT_CLASS_NAME,
          mediaType: mediaType,
        };
        return forkJoin([
          this.projectService.getPatternSummaryReport(projectKey, applicationReportGenModel),
          this.projectService.getPatternSummaryReport(projectKey, realmsReportGenModel),
          this.projectService.getPatternSummaryReport(projectKey, instancesReportGenModel)
        ]).pipe(
          map((reports: ProjectSummaryReportModel[]) => {
            return new LoadPatternSummaryReportsSuccess(reports);
          }),
          catchError((error) => {
            console.error(`Failed to load Pattern Summary reports for project ${projectKey}`, error);
            return of(new FailedToLoadLoadPatternSummaryReport(error.status));
          })
        );
      })
    ));

  constructor(private pluginService: PluginService,
              private projectService: ProjectService,
              private reportService: ReportsService,
              private actions$: Actions<NevisAdminAction<any>>,
              private store$: Store<AppState>,
              private modalNotificationService: ModalNotificationService) {}
}

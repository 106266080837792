import { DeploymentProcessModel } from '../../deployment-wizard/deployment-dialog/deployment-process.model';
import { NevisAdminAction } from '../actions';
import { DeployActionTypes } from './deploy.actions';
import { DeploymentSelection } from '../../deployment-wizard/deployment-selection/deployment-selection.model';
import { allHosts, DeployToOptions } from '../../deployment-wizard/deployment-selection/deploy-to-option.model';
import { DeploymentStatusModel, GenerationStatusModel } from '../../deployment-wizard/validate-deployment/generation-status.model';
import { IssueModel } from '../../common/model/issue.model';
import { GenerationOutputModel, PlanningOutputModel } from '../../deployment-wizard/deploy/deployment-preview/planning-output.model';
import { ValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';

/**
 * State that represents second step of deployment wizard
 */
export interface GenerationState {
  readonly status?: GenerationStatusModel;
  readonly isInProgress?: boolean;
  readonly issues?: IssueModel[];
  readonly output?: GenerationOutputModel[];
}

/**
 * State that represents third step of deployment wizard
 */
export interface PlanningState {
  readonly status?: DeploymentStatusModel;
  readonly isInProgress?: boolean;
  readonly output?: PlanningOutputModel[];
  readonly redeploymentForced?: boolean;
}

export interface DeploymentState {
  readonly status?: DeploymentStatusModel;
}

export interface DeployState {
  readonly isWizardOpened: boolean;
  readonly deployProcess: DeploymentProcessModel | null;
  readonly deploymentSelection: DeploymentSelection;
  /** Contains validation issues (warnings) when the inventory is invalid. */
  readonly inventoryValidationStatus: ValidationStatus<ValidationIssue> | null;
  readonly hostExpressions: DeployToOptions;
  readonly generation: GenerationState;
  readonly planning: PlanningState;
  readonly deployment: DeploymentState;
}

export const initState: DeployState = {
  isWizardOpened: false,
  deploymentSelection: {} as DeploymentSelection,
  inventoryValidationStatus: null,
  hostExpressions: {
    deployTargets: [],
    groups: [],
    patterns: []
  },
  deployProcess: null,
  generation: {} as GenerationState,
  planning: {} as PlanningState,
  deployment: {} as DeploymentState
};

export function reducer(state: DeployState, action: NevisAdminAction<any>): DeployState {
  switch (action.type) {
    case DeployActionTypes.StoreDeploymentWizardWindowState:
      const isWizardOpened: boolean = action.payload;
      return {
        ...state,
        isWizardOpened: isWizardOpened,
        hostExpressions: isWizardOpened ? state.hostExpressions : initState.hostExpressions,
      };
    case DeployActionTypes.StoreDeploymentProject:
      return {
        ...state,
        deploymentSelection: {
          ...state.deploymentSelection,
          projectKey: action.payload,
          deployTarget: allHosts.hostExpression
        }
      };
    case DeployActionTypes.StoreDeploymentInventory:
      return {
        ...state,
        deploymentSelection: {
          ...state.deploymentSelection,
          inventoryKey: action.payload
        },
        // Reset the inventory validation errors of previous deployment when new inventory is selected.
        inventoryValidationStatus: null
      };
    case DeployActionTypes.StoreDeploymentHost:
      return {
        ...state,
        deploymentSelection: {
          ...state.deploymentSelection,
          deployTarget: action.payload,
          canaryRouting: undefined
        }
      };
    case DeployActionTypes.StoreDeploymentCanaryRoutingOptions:
      return {
        ...state,
        deploymentSelection: {
          ...state.deploymentSelection,
          deployTarget: allHosts.hostExpression,
          canaryRouting: action.payload
        }
      };
    case DeployActionTypes.LoadDeployablePatternInstancesSuccess:
      return {
        ...state,
        hostExpressions: {
          ...state.hostExpressions,
          patterns: action.payload
        }
      };
    case DeployActionTypes.LoadHostsSuccess:
      return {
        ...state,
        hostExpressions: {
          ...state.hostExpressions,
          deployTargets: action.payload
        }
      };
    case DeployActionTypes.LoadGroupsSuccess:
      return {
        ...state,
        hostExpressions: {
          ...state.hostExpressions,
          groups: action.payload
        }
      };
    case DeployActionTypes.GetDeploymentProcessSuccess: {
      return {
        ...state,
        deployProcess: action.payload as DeploymentProcessModel
      };
    }
    case DeployActionTypes.StartGeneration:
      return {
        ...state,
        generation: {
          isInProgress: true
        },
        // when start generation(or repeat generation), reset planning
        planning: {}
      };
    case DeployActionTypes.StartGenerationFailed:
      return {
        ...state,
        inventoryValidationStatus: action.payload
      };

    case DeployActionTypes.PollGenerationStatusSuccess:
      const generationStatus: GenerationStatusModel = action.payload;
      return {
        ...state,
        generation: {
          ...state.generation,
          status: generationStatus,
        }
      };
    case DeployActionTypes.GenerationDone:
      return {
        ...state,
        generation: {
          ...state.generation,
          isInProgress: false
        }
      };
    case DeployActionTypes.FetchGenerationIssuesSuccess:
      return {
        ...state,
        generation: {
          ...state.generation,
          issues: action.payload as IssueModel[]
        }
      };
    case DeployActionTypes.FetchGenerationOutputSuccess:
      return {
        ...state,
        generation: {
          ...state.generation,
          output: action.payload as GenerationOutputModel[]
        }
      };
    case DeployActionTypes.AbortGenerationSuccess:
      return {
        ...state,
        generation: {}
      };
    case DeployActionTypes.StartDeploymentPlanning:
      return {
        ...state,
        planning: {
          isInProgress: true
        }
      };
    case DeployActionTypes.PollDeploymentPlanningStatusSuccess:
      return {
        ...state,
        planning: {
          ...state.planning,
          status: action.payload as DeploymentStatusModel
        }
      };
    case DeployActionTypes.DeploymentPlanningDone:
      return {
        ...state,
        planning: {
          ...state.planning,
          isInProgress: false
        }
      };
    case DeployActionTypes.FetchDeploymentPlanningOutputSuccess:
      return {
        ...state,
        planning: {
          ...state.planning,
          output: action.payload as PlanningOutputModel[]
        }
      };
    case DeployActionTypes.ForceRedeployment:
      return {
        ...state,
        planning: {
          redeploymentForced: true
        }
      };
    case DeployActionTypes.PollDeploymentStatusSuccess:
      return {
        ...state,
        deployment: {
          ...state.deployment,
          status: action.payload as DeploymentStatusModel
        }
      };
    case DeployActionTypes.DeleteDeploymentProcessSuccess:
      return {
        ...state,
        deployProcess: null,
        generation: {},
        planning: {},
        deployment: {}
      };
    default:
      return state;
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ProjectSyncService } from '../projects/project-sync.service';
import { ApplicationInfoService } from './application-info.service';
import { applicationInfoView } from '../model/views';
import { AppState } from '../model/reducer';
import { ModalNotificationService } from '../notification/modal-notification.service';

const NEVIS_LOGO = 'assets/Logo-Nevis-RGB-positive.svg';
const STOP_IMAGE = 'assets/close_grey_hover.png';

/**
 * Footer of the application
 */
@Component({
  selector: 'adm4-footer',
  template: `
    <footer class='footer'>
      <div class='footer-container'>
        <div class="logo flex-center-xy" (mouseenter)="onMouseenter()" (mouseleave)="onMouseleave()">
          <img alt="Nevis logo" #nevisLogo (click)="onClick()" [src]='imageSrc'/>
        </div>
        <div class="middle flex-center-xy" *ngIf='showVersion'>
          <span class="version">{{frontEndVersion | async}} {{applicationInfo | async}}</span>
          <button type="button" class="upgrade flex-center-xy" *ngIf="upgradeAvailable" (click)="upgradeClicked()">
            <mat-icon svgIcon="cloud_up"></mat-icon>
            <span>Upgrade available</span>
          </button>
        </div>
        <span class='copyright'>© Nevis Security AG, {{year}}</span>
      </div>
    </footer>`,
  styleUrls: ['footer.scss']
})
export class FooterComponent implements OnInit {
  year: number;
  imageSrc: string;
  @Input() showVersion: boolean;
  @Input() upgradeAvailable: boolean;
  public frontEndVersion: Observable<string>;
  public applicationInfo: Observable<string>;


  constructor(
      private syncService: ProjectSyncService,
      private store$: Store<AppState>,
      private infoService: ApplicationInfoService,
      private dialogs: ModalNotificationService,
  ) {}

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    this.imageSrc = NEVIS_LOGO;
    this.frontEndVersion = this.infoService.getFeVersion().pipe(map(value => `FE ${value}`));
    this.applicationInfo = this.store$.select(applicationInfoView).pipe(map(value => `- BE ${value?.version??''}`));
  }

  onMouseenter() {
    if (!this.syncService.isSyncOn()) {
      this.imageSrc = STOP_IMAGE;
    }
  }

  onMouseleave() {
    if (this.imageSrc !== NEVIS_LOGO) {
      this.imageSrc = NEVIS_LOGO;
    }
  }

  onClick() {
    if (this.syncService.isSyncOn()) {
      console.warn('Project polling is now disabled until navigating to the projects again.');
      this.syncService.stopSync();
    } else {
      console.info('Project polling is now started.');
      this.syncService.startSync();
    }
  }

  upgradeClicked(): void {
    this.dialogs.openUpgradeAvailableDialog();
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { allProjectsView, selectedTenantView, shouldDisplayTenantsView } from '../../model/views';
import { Tenant } from '../../tenant/tenant.model';
import { Project } from '../project.model';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { ProjectFileImportPayload } from './project-file-import-payload.model';
import { ImportProjectFile } from '../../model/project/project.actions';

@Injectable()
export class FileBasedImportProjectContext {
  currentTenant$: Observable<Tenant>;
  projects$: Observable<Project[]>;
  displayTenant$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {
    this.currentTenant$ = this.store$.pipe(select(selectedTenantView)) as Observable<Tenant>;
    this.projects$ = this.store$.pipe(select(allProjectsView), map(_.values));
    this.displayTenant$ = this.store$.pipe(select(shouldDisplayTenantsView));
  }


  importProjectFile(projectFileImportPayload: ProjectFileImportPayload): void {
    this.store$.dispatch(new ImportProjectFile(projectFileImportPayload));
  }
}

import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

/**
 * Close the modal dialog when the user presses the Escape key.
 * Listens for the Escape key event but only until the component responsible for the dialog is destroyed.
 *
 * @param dialogRef Defines which dialog's key down events to subscribe to.
 * @param destroyed$ Only listens for the Escape key, until the component responsible for the dialog is destroyed.
 * @param proceedCallback? : () => void - The optional function which is invoked when the user presses save changes in the confirmation modal. By default it closes the dialog.
 */
export function closeModalOnEscape<T>(dialogRef: MatDialogRef<T>, destroyed$: Subject<boolean>, proceedCallback?: () => void): void {
  dialogRef.keydownEvents()
    .pipe(
      filter((event: KeyboardEvent) => event.key === 'Escape'),
      takeUntil(dialogRef.beforeClosed()),
      takeUntil(destroyed$)
    )
    .subscribe(() => _.isFunction(proceedCallback) ? proceedCallback() : dialogRef.close());
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { Project } from '../../../../projects/project.model';
import { Maybe } from '../../../../common/utils/utils';
import { DeployToOption } from '../../deploy-to-option.model';
import {
  DeploymentActivity,
  DeploymentActivityIndicator
} from '../../../../common/services/deployment-activity-context.service';

@Component({
  selector: 'adm4-deployment-project-list-item',
  template: `
      <div class="menu-item-wrapper inidicatable-project" [class.selected]='isSelected'
           [adm4ScrollTarget]="project.projectKey"
           [scrollToWhenSelectedKey]="selectedProjectKey" [scrollMode]="'instant'">
          <button mat-button #selectionList class="btn-font-regular"
                  (keydown)='this.kbSelection.emit($event)'
                  (click)='this.selected.emit()'>
              <mat-icon>description</mat-icon>
              <div class="label-and-spinner">
                <span [ngbTooltip]="project.projectKey | cropTenantFromKey">{{project.projectKey | cropTenantFromKey}}</span>
                <span class="flex-grow-1"></span>
                <mat-progress-spinner [diameter]="18" [mode]="'indeterminate'" *ngIf="hasActivity"
                    [ngbTooltip]="activityTooltip" [disableTooltip]="!hasActivity" [placement]="'right'"
                ></mat-progress-spinner>
                <ng-template #activityTooltip>
                  <p>
                    This project is being deployed to
                    <ng-container *ngIf="!currentActivity.isInventoryRedacted; else inventoryKeyRedacted">
                      the inventory <strong>{{currentActivity.inventoryKey | cropTenantFromKey}}</strong>
                    </ng-container>
                    <ng-template #inventoryKeyRedacted>
                      an undisclosed inventory
                    </ng-template>
                    by user <strong>{{currentActivity.userKey}}</strong>.
                  </p>
                </ng-template>
              </div>
          </button>
      </div>
      <adm4-deployment-target-instance-selection class="selected" *ngIf='isSelected'
                                                 [project]='project'
                                                 [preSelectedProjectKey]='selectedProjectKey'
                                                 [isClassicDeployment]='isClassicDeployment'
                                                 [classicDeployPatterns]='classicDeployPatterns'
                                                 (instancePatternSelected)='this.instancePatternSelected.emit($event)'>
      </adm4-deployment-target-instance-selection>
  `,
  styleUrls: ['./deployment-project-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeploymentProjectListItemComponent implements OnChanges {

  @Input() public project: Project;
  @Input() public selectedProjectKey: Maybe<string>;
  @Input() public isClassicDeployment: boolean;
  @Input() public classicDeployPatterns: DeployToOption[];
  @Input() public deploymentActivity: Maybe<DeploymentActivityIndicator>;

  @Output() public kbSelection: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() public selected: EventEmitter<void> = new EventEmitter();
  @Output() public instancePatternSelected: EventEmitter<any> = new EventEmitter();

  public isSelected: boolean = false;
  public hasActivity: boolean = false;
  public currentActivity: Maybe<DeploymentActivity>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project || changes.selectedProjectKey) {
      this.isSelected = !!this.project && this.selectedProjectKey === this.project.projectKey;
    }
    if (changes.project || changes.deploymentActivity) {
      if (!this.project || !(this.deploymentActivity?.hasActivity)) {
        this.hasActivity = false;
      } else {
        this.currentActivity = this.deploymentActivity.activities.find((a) => a.projectKey === this.project.projectKey);
        this.hasActivity = !!this.currentActivity;
      }
    }
  }

}

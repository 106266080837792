import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { Observable } from 'rxjs';
import { Tenant } from '../../tenant/tenant.model';
import { Inventory } from '../inventory.model';
import { allInventoriesView, selectedTenantView, shouldDisplayTenantsView } from '../../model/views';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { InventoryFileImportPayload } from './inventory-file-import-payload.model';
import { ImportInventoryFile } from '../../model/inventory';

@Injectable()
export class FileBasedImportInventoryContext {
  currentTenant$: Observable<Tenant>;
  inventories$: Observable<Inventory[]>;
  displayTenant$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {
    this.currentTenant$ = this.store$.pipe(select(selectedTenantView)) as Observable<Tenant>;
    this.inventories$ = this.store$.pipe(select(allInventoriesView), map(_.values));
    this.displayTenant$ = this.store$.pipe(select(shouldDisplayTenantsView));
  }

  importInventory(inventoryFileImportPayload: InventoryFileImportPayload): void {
    this.store$.dispatch(new ImportInventoryFile(inventoryFileImportPayload));
  }
}

import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FileBasedImportProjectContext } from './file-based-import-project.context';
import { Observable, Subject } from 'rxjs';
import { Tenant } from '../../tenant/tenant.model';
import { Project } from '../project.model';
import { ProjectFileImportPayload } from './project-file-import-payload.model';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';

@Component({
  selector: 'adm4-file-based-import-project-container',
  template: `
    <adm4-modal-dialog-title header='Import Project from Zip' [cdkTrapFocus] [cdkTrapFocusAutoCapture]="true"
           [showClose]='true' (closeClicked)="closeDialog()">
      <adm4-file-based-import-project
              [selectedTenant]='currentTenant$ | async'
              [projects]='projects$ | async'
              [displayTenant]='displayTenant$ | async'
              (projectImport)='triggerFileBasedProjectImport($event)'
              (cancelClicked)='closeDialog()'
      ></adm4-file-based-import-project>
    </adm4-modal-dialog-title>
  `,
  providers: [
    FileBasedImportProjectContext
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileBasedImportProjectContainerComponent implements OnDestroy {
  currentTenant$: Observable<Tenant>;
  projects$: Observable<Project[]>;
  displayTenant$: Observable<boolean>;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private dialogRef: MatDialogRef<FileBasedImportProjectContainerComponent>,
              private fileBasedImportProjectContext: FileBasedImportProjectContext) {
    closeModalOnEscape(dialogRef, this.destroyed$);

    this.currentTenant$ = fileBasedImportProjectContext.currentTenant$;
    this.projects$ = fileBasedImportProjectContext.projects$;
    this.displayTenant$ = fileBasedImportProjectContext.displayTenant$;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  triggerFileBasedProjectImport(projectFileImportPayload: ProjectFileImportPayload): void {
    this.fileBasedImportProjectContext.importProjectFile(projectFileImportPayload);
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

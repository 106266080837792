import { Branch } from '../../../version-control/branch.model';

export const REPOSITORY_FORM_CONTROL_NAME = 'repository';
export const BRANCH_FORM_CONTROL_NAME = 'branch';
export const PATH_FORM_CONTROL_NAME = 'path';

export interface VersionControlForm {
  [REPOSITORY_FORM_CONTROL_NAME]: string;
  [BRANCH_FORM_CONTROL_NAME]?: Branch | null;
  [PATH_FORM_CONTROL_NAME]: string;
}

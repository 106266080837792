import { ValidatingHelper } from '../validating/validating.helper';
import { DeploymentStatusModel } from '../validate-deployment/generation-status.model';

export class DeploymentPollingHelper {

  /**
   * When new status update comes we should display current task of running items only in conditions described in NEVISADMV4-1841
   * @param {DeploymentStatusModel} newDeploymentStatus
   * @param {DeploymentStatusModel | undefined} previousDeploymentStatus
   * @returns {DeploymentStatusModel}
   */
  static updatePolledDeploymentStatus(newDeploymentStatus: DeploymentStatusModel, previousDeploymentStatus: DeploymentStatusModel | undefined): DeploymentStatusModel {
    return {
      ...newDeploymentStatus,
      items: [
        ...newDeploymentStatus.items.filter(item => !ValidatingHelper.isItemRunning(item.status)),
        ...newDeploymentStatus.items
          .filter(item => ValidatingHelper.isItemRunning(item.status))
          .map(runningItem => {
            const previousPendingItem = previousDeploymentStatus ? previousDeploymentStatus.items.find(item => item.label === runningItem.label) : null;
            return {
              ...runningItem,
              currentTask: previousPendingItem && runningItem.currentTask === previousPendingItem.currentTask ? runningItem.currentTask : ''
            };
          })
      ]
    };
  }
}

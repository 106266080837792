import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Project } from '../project.model';
import { ConnectProjectVersionControlContainerComponent } from './connect-project-version-control-container.component';

@Injectable({providedIn: 'root'})
export class ConnectProjectVersionControlDialogService {
  constructor(private dialog: MatDialog) { }

  public openConnectProjectToVersionControlDialog(project: Project): MatDialogRef<ConnectProjectVersionControlContainerComponent> {
    const config: MatDialogConfig<Project | null> = {
      width: '30%',
      maxWidth: '50%',
      autoFocus: false,
      disableClose: true,
      data: project
    };
    return this.dialog.open(ConnectProjectVersionControlContainerComponent, config);
  }
}

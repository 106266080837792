import { Component } from '@angular/core';
import { ItemDetailsComponent } from '../item-details.component';
import { DirectoryModel } from '../../planning-output.model';

@Component({
  selector: 'adm4-directory-details',
  templateUrl: './directory-details.component.html',
  styleUrls: ['../../item-details.scss']
})
export class DirectoryDetailsComponent extends ItemDetailsComponent<DirectoryModel> {}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, Dictionary } from '../model/reducer';
import { filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { VariableModel } from './variable.model';
import { projectKeyView, variablesView } from '../model/views';
import { RouteParamHelper } from '../common/helpers/route-param.helper';
import { variableKeyParam } from '../projects/project-routing.constants';
import { NavigationService } from '../navbar/navigation.service';
import * as _ from 'lodash';
import { VariableService } from './variable.service';
import { LoadVariablesSuccess } from '../model/variables';

@Injectable({
  providedIn: 'root'
})
export class VariableGuard  {

  constructor(private store$: Store<AppState>,
              private navigationService: NavigationService,
              private variableService: VariableService) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivateVariableScreen(next);
  }

  private canActivateVariableScreen(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store$.pipe(
      select(variablesView),
      first(),
      switchMap((variables: Dictionary<VariableModel>) => {
        if (_.isEmpty(variables)) {
          return this.store$.pipe(
            select(projectKeyView),
            filter((projectKey: string | null) => !_.isNil(projectKey)),
            mergeMap((projectKey: string) => {
              return this.variableService.getAllVariablesOfProject(projectKey).pipe(
                tap(loadedVariables => this.store$.dispatch(new LoadVariablesSuccess(loadedVariables))),
                map((loadedVariables: VariableModel[]) => _.mapKeys(loadedVariables, 'variableKey'))
              );
            })
          );
        }
        return of(variables);
      }),
      withLatestFrom(this.store$.pipe(select(projectKeyView))),
      map(([variables, storedProjectKey]: [Dictionary<VariableModel>, string | null]) => this.canActivateBasedOnData(next, variables, storedProjectKey))
    );
  }

  private canActivateBasedOnData(next: ActivatedRouteSnapshot, variables: Dictionary<VariableModel>, projectKey: string | null): boolean {

    const queryVariableKey: string | undefined = RouteParamHelper.getPathParamFromRoute(next, variableKeyParam) ||
      RouteParamHelper.getPathParamFromChildRoute(next, variableKeyParam);

    if (_.isNil(projectKey)) {
      // Should not happen since user can only got to variables screen when project is selected first.
      return false;
    }

    const variablesArray = _.values(variables);

    if (_.isEmpty(variablesArray)) {
      // If there are no variables, navigate to project summary screen
      setTimeout(() => this.navigationService.navigateToProjectSummary(projectKey, true));
      return false;
    } else if (_.isNil(queryVariableKey)) {
      // if there is no selected variable, select the first one automatically
      this.navigateToFirstVariable(projectKey, variablesArray);
      return true;
    } else if (_.isNil(variables[queryVariableKey])) {
      // if no variable exists with selected key, navigate to first variable.
      this.navigateToFirstVariable(projectKey, variablesArray);
      return false;
    }

    // otherwise navigate to variable with key from url
    return true;
  }

  private navigateToFirstVariable(projectKey: string, variablesArray: VariableModel[]): void {
    setTimeout(() => this.navigationService.navigateToVariable(projectKey, variablesArray[0].variableKey, true));
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, first, shareReplay, tap } from 'rxjs/operators';

import { Revision } from '../../../version-control/revision/revision.model';
import { VersionControlService } from '../../../version-control/version-control.service';
import { ToastNotificationService } from '../../../notification/toast-notification.service';
import { InventoryService } from '../../inventory.service';
import { ModalNotificationService } from '../../../notification/modal-notification.service';

@Injectable()
export class InventoryDeploymentHistoryTableContextService {

  constructor(
      private versionControlService: VersionControlService,
      private toastNotificationService: ToastNotificationService,
      private inventoryApi: InventoryService,
      private notificationService: ModalNotificationService
  ) { }

  public getRevisionOfProjectCommit(projectKey: string, commitId: string): Observable<Revision | null> {
    return this.versionControlService.getRevisionOfProjectCommit(projectKey, commitId).pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error when trying to load revision of projectCommit ${commitId} for project ${projectKey}`);
          console.error(error);
          this.toastNotificationService.showErrorToast(`Revision information could not be loaded for project ${projectKey}`);
          return of(null);
        }),
        shareReplay(1)
    );
  }

  public getRevisionOfInventoryCommit(inventoryKey: string, commitId: string): Observable<Revision | null> {
    return this.versionControlService.getRevisionOfInventoryCommit(inventoryKey, commitId).pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error when trying to load revision of inventoryCommit ${commitId} for project ${inventoryKey}`);
          console.error(error);
          this.toastNotificationService.showErrorToast(`Revision information could not be loaded for inventory ${inventoryKey}`);
          return of(null);
        }),
        shareReplay(1)
    );
  }

  public deleteK8sDeployment(deploymentHistoryId: number, inventoryKey: string) {
    this.notificationService.openConfirmDialog({
      headerTitle: `Warning`,
      title: 'Delete deployment',
      description: '<p>Do you want to delete the deployment?<br/>' +
        'Deleting a single deployment may cause issues when you have projects that depend on each other.<br/>' +
        'Please consider making a secondary deployment and promoting it.<br/>' +
        'The secondary deployment also ensures the zero downtime during clean up.</p>'
    }, {
      confirmButtonText: 'Delete deployment'
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed) {
        this.inventoryApi.deleteKubernetesDeployment(deploymentHistoryId, inventoryKey).pipe(
          first(),
          tap(() => {
            this.notificationService.openSuccessDialog({
              title: 'Success', description: 'The deployment has been successfully deleted. ' +
                'Please note that deleting the related resources may take some time. Therefore, existing environment might be running for a few minutes.'
            });
          }),
          catchError((error: HttpErrorResponse) => {
            this.notificationService.openHttpErrorDialog(error, 'Cannot delete the deployment');
            return EMPTY;
          })
        ).subscribe();
      }
    });
  }
}

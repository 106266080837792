import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Project } from '../../projects/project.model';
import { ProjectService } from '../../projects/project.service';
import { Unsubscriber } from '../../common/helpers/unsubscriber';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { projectKeyView } from '../../model/views';

@Injectable()
export class InsertProjectVariablesDialogContext {
  private _projects$: BehaviorSubject<Project[]> = new BehaviorSubject([]);

  projects$: Observable<Project[]> = this._projects$.asObservable();
  currentProjectKey$: Observable<string | null>;

  private projectSubscription: Subscription | undefined;

  constructor(private projectService: ProjectService, private store$: Store<AppState>) {
    this.currentProjectKey$ = this.store$.pipe(select(projectKeyView));
  }

  loadProjects(tenantKey: string): void {
    Unsubscriber.unsubscribeFrom(this.projectSubscription);

    this.projectSubscription = this.projectService.getProjectsOfTenant(tenantKey).subscribe((projects => {
      this._projects$.next(projects);
    }));
  }
}

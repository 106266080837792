import { Component } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

import { Observable } from 'rxjs';

import { LoadingInterceptorService } from '../common/interceptors/loading-interceptor.service';

@Component({
  selector: 'adm4-navigation',
  template: `
    <div [@mainProgressSpinner] class="main-progress-spinner" *ngIf="showLoading | async">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class='nav-container'>
      <div class='nav-logo'>
        <a [routerLink]='"/"'>nevisAdmin 4</a>
      </div>
      <div class='nav-tabs'>
        <adm4-tabs></adm4-tabs>
      </div>
      <div class='nav-user'>
        <adm4-user-menu></adm4-user-menu>
      </div>
    </div>
  `,
  styleUrls: ['navigation.component.scss'],
  animations: [
    trigger(
      'mainProgressSpinner',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('0.3s', style({ opacity: 1 })),
          ],
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('0.3s', style({ opacity: 0 })),
          ],
        ),
      ],
    ),
  ],
})
export class NavigationComponent {

  public showLoading: Observable<boolean>;

  constructor(
    private loadingService: LoadingInterceptorService,
  ) {
    this.showLoading = this.loadingService.isLoading;
  }
}

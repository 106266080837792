import { NgModule } from '@angular/core';
import { DeploymentDialogComponent } from './deployment-dialog/deployment-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CdkTreeModule } from '@angular/cdk/tree';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { ValidatingComponent } from './validating/validating.component';
import { ValidatingItemsComponent } from './validating/validating-items/validating-items.component';
import { TreeViewerComponent } from './tree-viewer/tree-viewer.component';
import { DeploymentPreviewComponent } from './deploy/deployment-preview.component';
import { DeploymentPreviewResultComponent } from './deploy/deployment-preview/deployment-preview-result/deployment-preview-result.component';
import { DeploymentComponent } from './deployment/deployment.component';
import { ModalDialogModule } from '../modal-dialog/modal-dialog.module';
import { DeploymentPreviewDetailsComponent } from './deploy/deployment-preview/deployment-preview-details/deployment-preview-details.component';
import { DeploymentPreviewContainerComponent } from './deploy/deployment-preview/deployment-preview-container.component';
import { CommonModules } from '../common/common.module';
import { CommandDetailsComponent } from './deploy/deployment-preview/deployment-preview-details/command-details/command-details.component';
import { DetailMessageComponent } from './deploy/deployment-preview/deployment-preview-details/detail-message/detail-message.component';
import { CheckDetailComponent } from './deploy/deployment-preview/deployment-preview-details/check-detail/check-detail.component';
import { DeploymentFileViewComponent } from './deployment-file-view/deployment-file-view.component';
import { DeploymentFileViewService } from './deployment-file-view/deployment-file-view.service';
import { NothingToDeployComponent } from './nothing-to-deploy/nothing-to-deploy.component';
import { DeploymentWizardMainComponent } from './deployment-wizard-main.component';
import { DeploymentWizardWindowService } from './deployment-wizard-window.service';
import { DeploymentProcessService } from './deployment-process.service';
import { InventoryListComponent } from './deployment-selection/inventory-list/inventory-list.component';
import { DeploymentSelectionComponent } from './deployment-selection/deployment-selection.component';
import { DeployTargetListComponent } from './deployment-selection/deploy-target-list/deploy-target-list.component';
import { ProjectListComponent } from './deployment-selection/project-list/project-list.component';
import { DeploymentSelectionListComponent } from './deployment-selection/deployment-selection-list/deployment-selection-list.component';
import { WarningValidationDialogComponent } from './validate-deployment/warning-dialog/warning-validation-dialog.component';
import { GenerationOutputComponent } from './validate-deployment/generation-output/generation-output.component';
import { DeploymentValidatingComponent } from './validate-deployment/validating/deployment-validating.component';
import { ValidationResultsComponent } from './validate-deployment/validation-results/validation-results.component';
import { ValidateDeploymentComponent } from './validate-deployment/validate-deployment.component';
import { DirectoryDetailsComponent } from './deploy/deployment-preview/deployment-preview-details/directory-details/directory-details.component';
import { FileDetailsComponent } from './deploy/deployment-preview/deployment-preview-details/file-details/file-details.component';
import { IssuesModule } from '../issues/issues.module';
import { PatternIssueGroupModule } from '../issues/pattern-issue-group/pattern-issue-group-module';
import { InventoryHasErrorsComponent } from './inventory-has-errors/inventory-has-errors.component';
import { InventoryModule } from '../inventory/inventory.module';
import { K8sCustomResourceDetailsComponent } from './deploy/deployment-preview/deployment-preview-details-kubernetes/k8s-custom-resource-details/k8s-custom-resource-details.component';
import { K8sDetailMessageComponent } from './deploy/deployment-preview/deployment-preview-details-kubernetes/k8s-detail-message/k8s-detail-message.component';
import { DeploymentPreviewDetailsKubernetesComponent } from './deploy/deployment-preview/deployment-preview-details-kubernetes/deployment-preview-details-kubernetes.component';
import { K8sDirectoryDetailMessageComponent } from './deploy/deployment-preview/deployment-preview-details-kubernetes/k8s-directory-detail-message/k8s-directory-detail-message.component';
import { K8sFileDetailMessageComponent } from './deploy/deployment-preview/deployment-preview-details-kubernetes/k8s-file-detail-message/k8s-file-detail-message.component';
import { DeploymentFileViewContainerComponent } from './deployment-file-view-container/deployment-file-view-container.component';
import { ItemDetailsComponent } from './deploy/deployment-preview/deployment-preview-details/item-details.component';
import { DeployTargetK8sListComponent } from './deployment-selection/deploy-target-list/deploy-target-K8s-list.component';
import { DeploymentTargetInstanceSelectionComponent } from './deployment-selection/project-list/deployment-target-instance-selection.component';
import { MatSliderModule } from '@angular/material/slider';
import { SecondaryDeploymentSettingsComponent } from './deployment-selection/deploy-target-list/secondary-deployment-settings.component';
import { DeploymentResourceIconComponent } from './tree-viewer/deployment-resource-icon/deployment-resource-icon.component';
import { DeploymentInventoryListItemComponent } from './deployment-selection/inventory-list/deployment-inventory-list-item/deployment-inventory-list-item.component';
import { DeploymentProjectListItemComponent } from './deployment-selection/project-list/deployment-project-list-item/deployment-project-list-item.component';

@NgModule({
  imports: [
    MatStepperModule,
    MatExpansionModule,
    ModalDialogModule,
    CommonModules,
    MatSlideToggleModule,
    PatternIssueGroupModule,
    IssuesModule,
    InventoryModule,
    MatTabsModule,
    MatSliderModule,
    CdkTreeModule,
    ScrollingModule,
  ],
  providers: [
    DeploymentWizardWindowService,
    DeploymentProcessService,
    DeploymentFileViewService
  ],
  declarations: [
    DeploymentWizardMainComponent,
    DeploymentDialogComponent,
    InventoryListComponent,
    DeploymentSelectionComponent,
    DeployTargetListComponent,
    DeployTargetK8sListComponent,
    TreeViewerComponent,
    ValidatingComponent,
    ValidateDeploymentComponent,
    ValidatingItemsComponent,
    ValidationResultsComponent,
    DeploymentPreviewComponent,
    DeploymentPreviewResultComponent,
    DeploymentComponent,
    DeploymentPreviewDetailsComponent,
    DeploymentPreviewContainerComponent,
    DeploymentValidatingComponent,
    WarningValidationDialogComponent,
    GenerationOutputComponent,
    CommandDetailsComponent,
    DetailMessageComponent,
    CheckDetailComponent,
    DeploymentFileViewComponent,
    NothingToDeployComponent,
    InventoryHasErrorsComponent,
    ProjectListComponent,
    DeploymentSelectionListComponent,
    DirectoryDetailsComponent,
    FileDetailsComponent,
    K8sCustomResourceDetailsComponent,
    K8sDetailMessageComponent,
    DeploymentPreviewDetailsKubernetesComponent,
    K8sDirectoryDetailMessageComponent,
    K8sFileDetailMessageComponent,
    DeploymentFileViewContainerComponent,
    ItemDetailsComponent,
    DeploymentTargetInstanceSelectionComponent,
    SecondaryDeploymentSettingsComponent,
    DeploymentResourceIconComponent,
    DeploymentInventoryListItemComponent,
    DeploymentProjectListItemComponent,
  ]
})
export class DeploymentWizardModule {
}

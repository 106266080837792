import * as _ from 'lodash';
import { Tenant } from '../../tenant/tenant.model';
import { LocalStorageHelper } from './local-storage.helper';
import { localStorageTenantKey } from '../constants/local-storage-keys.constants';
import { Dictionary } from '../../model/reducer';
import { DEFAULT_TENANT_KEY } from '../constants/app.constants';

export class TenantHelper {
  /**
   * Parses tenant from project or inventory inventoryKey
   * Returns empty string if input string is not a valid use case
   * @param {string} key - either inventory or project inventoryKey, first part of which is always a tenant
   * @returns {string}
   */
  static getTenantFromKey(key: string): string {
    const endOfTenant = key.indexOf('-');
    if (endOfTenant < 0) {
      return '';
    }
    return key.substring(0, endOfTenant);
  }

  /**
   * Returns part of key without tenant
   * Example: DEV-SIVEN-MASTER => SIVEN-MASTER
   * If string is not value for this method, empty string is returned
   * @param key
   */
  static cropTenantFromKey(key: string): string {
    const endOfTenant = key.indexOf('-');
    if (endOfTenant < 0) {
      return '';
    }
    return key.substring(endOfTenant + 1);
  }

  static keysHaveSameTenant(key1: string, key2: string): boolean {
    const tenant1 = this.getTenantFromKey(key1);
    const tenant2 = this.getTenantFromKey(key2);
    return tenant1 === tenant2;
  }

  static getTenantByKey(tenants: Dictionary<Tenant>, tenantKey: string | null): Tenant | null {
    return _.isNil(tenantKey) ? null : tenants[tenantKey] || null;
  }

  static getInitialTenantKey(tenants: Tenant[]): string {
    const keyFromLocalStorage: string | null = LocalStorageHelper.retrieve(localStorageTenantKey);
    const tenantFromLocalStorage: Tenant | null | undefined = _.isNil(keyFromLocalStorage) ? null : tenants.find(tenant => tenant.tenantKey === keyFromLocalStorage);
    return _.isNil(tenantFromLocalStorage) ? tenants[0].tenantKey : <string>keyFromLocalStorage;
  }

  static getFirstPossibleTenant(tenants: Tenant[], ...possibleKeys: (string | null | undefined)[]): string | null | undefined {
    return possibleKeys.find(key => !_.isNil(key) && tenants.some(tenant => tenant.tenantKey === key));
  }

  static shouldShowTenants(tenants: Tenant[], selectedTenantKey: string | null): boolean {
    const hasSelectedTenant = !_.isNil(selectedTenantKey);
    const onlyHasDefaultTenant = tenants.length === 1 && tenants[0].tenantKey === DEFAULT_TENANT_KEY;
    return hasSelectedTenant && !onlyHasDefaultTenant;
  }

  /**
   * Checks whether the scope is tenant level, as opposed to project/inventory.<br/>
   * Tenants can not have a `-` in the name, but projects and inventories do have the tenant prefixed with a `-`.
   * @param unCroppedScope the scope, without the tenantKey cropped from it.
   */
  static isScopeTenantLevel(unCroppedScope: string): boolean {
    return !unCroppedScope.includes('-');
  }
}

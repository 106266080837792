import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TreeGeneratingHelper } from '../../../tree-viewer/tree-generating.helper';
import { PlanningOutputModel } from '../planning-output.model';
import { TreeNode } from '../../../tree-viewer/tree-node.model';
import { TreeNodeHelper } from '../../../tree-viewer/tree-node.helper';
import * as _ from 'lodash';
import { TREE_EXPAND, treeExpandProvider } from '../../../tree-viewer/tree-expand.provider';
import { InventorySchemaType } from '../../../../inventory/inventory.model';

@Component({
  selector: 'adm4-deployment-preview-result',
  template: `
    <div class="full-height-flex">
      <div class='step-sub-heading-with-actions'>
        <button *ngIf='nodes?.length'
                (click)="expandFiles()"
                class="step-content-text-button">
          Expand files <mat-icon class='step-content-text-button-icon'>add</mat-icon>
        </button>
        <mat-slide-toggle *ngIf='!redeploymentForced' labelPosition='before'
                          [checked]="showOnlyDifferences"
                          (change)='toggleShowOnlyDifferences($event.checked)'>
          <span class="toggle-label">Show changes only</span>
        </mat-slide-toggle>
      </div>
      <div class="remaining-space-flex-content-wrapper">
        <div class="remaining-space-flex-content">
          <div class="step-content-card margin-sm full-height" [ngClass]='boxShadowClass'>
            <adm4-tree-viewer *ngIf='treeLoaded; else treeLoading'
                              [nodes]='nodes'
                              [selectedNodeId]='selectedNodeId'
                              [showOnlyDifferences]='showOnlyDifferences'
                              [forceShowAllItems]='redeploymentForced'
                              (nodeSelected)='nodeSelected.emit($event)'></adm4-tree-viewer>
            <ng-template #treeLoading>
              <div class='tree-loading full-height'>
                <mat-spinner [diameter]='100'></mat-spinner>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./deployment-preview-result.scss'],
  providers: [treeExpandProvider]
})
export class DeploymentPreviewResultComponent implements OnInit, OnChanges {
  @Input() planningResults: PlanningOutputModel[];
  @Input() selectedNodeId: string;
  @Input() redeploymentForced: boolean;
  @Input() boxShadowClass: string;
  @Input() selectedInventorySchemaType: InventorySchemaType;
  @Output() nodeSelected: EventEmitter<TreeNode> = new EventEmitter();

  public nodes: TreeNode[] = [];
  public showOnlyDifferences: boolean;

  get treeLoaded(): boolean {
    return !_.isEmpty(this.nodes);
  }

  constructor(@Inject(TREE_EXPAND) private treeExpand: EventEmitter<void>) {}

  ngOnInit(): void {
    this.showOnlyDifferences = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['planningResults'] && this.planningResults) {
      this.nodes = this.createTreeNodesFromPlanOutput();
    }
    const selectedNodeIdChanged = changes.selectedNodeId && this.selectedNodeId && this.selectedNodeId !== changes.selectedNodeId.previousValue;
    if (selectedNodeIdChanged && this.showOnlyDifferences) {
      this.switchOffShowDifferencesOnlyIfNeeded(this.nodes, this.selectedNodeId);
    }
  }

  private createTreeNodesFromPlanOutput(): TreeNode[] {
    switch (this.selectedInventorySchemaType) {
      case InventorySchemaType.CLASSIC:
        return TreeGeneratingHelper.getClassicTreeFromPlanOutput(this.planningResults);
      case InventorySchemaType.KUBERNETES:
        return TreeGeneratingHelper.getKubernetesTreeFromPlanOutput(this.planningResults);
      default:
        return [];
    }
  }

  expandFiles() {
    this.treeExpand.emit();
  }

  toggleShowOnlyDifferences(showOnlyDifferences: boolean): void {
    this.showOnlyDifferences = showOnlyDifferences;
  }

  switchOffShowDifferencesOnlyIfNeeded(nodes: TreeNode[], nodeId: string): void {
    const node: TreeNode | null = TreeNodeHelper.findNodeById(nodes, nodeId);
    if (node && !TreeNodeHelper.hasNodeChangesOrIsImportantType(node)) {
      this.toggleShowOnlyDifferences(false);
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DetailMessageComponent } from '../../deployment-preview-details/detail-message/detail-message.component';

@Component({
  selector: 'adm4-k8s-detail-message',
  templateUrl: './k8s-detail-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class K8sDetailMessageComponent extends DetailMessageComponent {
}

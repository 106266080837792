import { Component, Input } from '@angular/core';
import { DeploymentItemStatusModel } from '../deployment-status.model';
import { DeploymentFileViewService } from '../../deployment-file-view/deployment-file-view.service';
import { GenerationItemStatus } from '../../validate-deployment/generation-status.model';
import { ValidatingHelper } from '../validating.helper';
import * as _ from 'lodash';
import { PatternLogHelper } from './pattern-log.helper';
import { InventorySchemaType } from '../../../inventory/inventory.model';

@Component({
  selector: 'adm4-validating-items',
  template: `
    <div class='result-container'>
      <div *ngFor='let item of validationItems' class='validation-item'>
        <div class='item-icon-validation-text'>
          <mat-spinner *ngIf='shouldDisplaySpinner(item)' [diameter]='15'></mat-spinner>
          <mat-icon *ngIf='isItemDone(item)' class='validation-item-icon done-icon'>done</mat-icon>
          <mat-icon *ngIf='isItemFailed(item)' class='validation-item-icon error-icon'>error_outline</mat-icon>
          <span class='item-label'>{{item.label}}</span>:
        </div>
        <span class='item-status-text'>{{item.status | lowercase}} {{item?.errorMessage ? '- ' + item?.errorMessage : ''}}</span>
        <span *ngIf='item.progress' class='item-status-progress'>{{item.progress | decimalToPercents}}</span>
        <span *ngIf='item.currentTask' class='item-status-current-task'>: {{item.currentTask}}</span>
        <span *ngIf='shouldDisplayViewLog(item)' class='item-status-log'>
          <span>&nbsp;-&nbsp;</span>
          <a (click)="viewLog(item)">view log</a>
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./validating-items.scss']
})
export class ValidatingItemsComponent {
  @Input() validationItems: DeploymentItemStatusModel[];
  @Input() selectedInventorySchemaType?: InventorySchemaType;

  readonly isItemDone = (item: DeploymentItemStatusModel | GenerationItemStatus) => ValidatingHelper.isItemDone(item.status);
  readonly isItemFailed = (item: DeploymentItemStatusModel | GenerationItemStatus) => ValidatingHelper.isItemFailed(item.status);

  constructor(private fileViewer: DeploymentFileViewService) {
  }

  shouldDisplaySpinner(item: DeploymentItemStatusModel | GenerationItemStatus): boolean {
    return ValidatingHelper.isItemPending(item.status) || ValidatingHelper.isItemRunning(item.status);
  }

  shouldDisplayViewLog(item: DeploymentItemStatusModel | GenerationItemStatus): boolean {
    return (ValidatingHelper.isItemDone(item.status) || ValidatingHelper.isItemFailed(item.status)) && !_.isNil(item.log);
  }

  viewLog(item: DeploymentItemStatusModel): void {
    this.fileViewer.viewingFileInfo.next({
      path: ValidatingHelper.combineItemLogName(item),
      localFileUrl: <string>item.log,
      // log from the backend always comes from same source containing all info
      // until it's change on BE, we grep that log per deployment item from same log
      fileContentMapper: (content: string) => PatternLogHelper.grepPatternLog(content, item)
    });
  }
}

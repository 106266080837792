import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { WidgetComponent } from './widget.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/internal/Subscription';
import { Unsubscriber } from '../common/helpers/unsubscriber';

@Component({
  selector: 'adm4-selection-property',
  template: `
    <div [formGroup]='group'>
      <mat-form-field class='full-width' [class.default]='defaultSelected'>
        <mat-select #selection
                    [disableOptionCentering]='true'
                    [multiple]='multiple'
                    [formControlName]='widgetProperty.propertyKey'
                    [required]='parameters.required'
                    [adm4ControlDisable]='readOnly'
                    (blur)='onValidate($event)'
                    (focus)='onSelect($event, selection)'>
          <mat-option *ngIf='!multiple' value="" class='default'>{{defaultText}}</mat-option>
          <mat-option *ngFor='let option of parameters.options'
                      [value]='option'>{{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span *ngIf='!valid'>{{validationMessage}}</span>
    </div>
  `,
  styleUrls: ['widget.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectionPropertyComponent),
    multi: true
  }]
})
export class SelectionPropertyComponent extends WidgetComponent implements OnInit, OnDestroy {
  valid = true;
  validationMessage: string;
  multiple: boolean = false;

  private valueChangesSubscription: Subscription;

  override ngOnInit(): void {
    super.ngOnInit();
    this.multiple = this.maxAllowed > 1;
    this.valueChangesSubscription = this.group.controls[this.widgetProperty.propertyKey].valueChanges
      .subscribe(value => {
        this.onSelectionChange(value);
      });
  }

  onSelectionChange(value: any) {
    this.valid = !Array.isArray(value) || this.checkMinValidity(value) && this.checkMaxValidity(value);
  }

  checkMinValidity(value: any) {
    if (this.minRequired > value.length) {
      this.validationMessage = `You must select at least ${this.minRequired} options`;
      return false;
    }
    return true;
  }

  checkMaxValidity(value: any) {
    if (this.maxAllowed < value.length) {
      this.validationMessage = `You can select at most ${this.maxAllowed} options`;
      return false;
    }
    return true;
  }

  get defaultSelected() {
    return _.isEmpty(this.group.value[this.widgetProperty.propertyKey]);
  }

  ngOnDestroy(): void {
    Unsubscriber.unsubscribeFrom(this.valueChangesSubscription);
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingModalData } from './loading-modal-data.model';

@Component({
  selector: 'adm4-loading-modal-container',
  template: `
      <adm4-modal-dialog-title [header]='data.title'>
          <adm4-loading-modal [loadingData]='data'>
          </adm4-loading-modal>
      </adm4-modal-dialog-title>`
})
export class LoadingModalContainerComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: LoadingModalData) {
  }

}

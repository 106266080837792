import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LocalStatus, MetaInfo } from '../../../version-control/meta-info.model';
import * as _ from 'lodash';
import { InventoryChangesetItem } from '../inventory-changeset-item.model';
import { ValidationStatusHelper } from '../../../common/helpers/validation-status.helper';

@Component({
  selector: 'adm4-publish-inventory-changeset-item',
  templateUrl: './publish-inventory-changeset-item.component.html',
  styleUrls: ['./publish-inventory-changeset-item.component.scss', '../../../common/styles/component-specific/extended-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishInventoryChangesetItemComponent {
  @Input() changesetItem: InventoryChangesetItem<MetaInfo, any>;
  @Input() isSelected: boolean;
  @Input() currentUserName: string | null;

  get isDeleted(): boolean {
    return this.changesetItem.metaData.localStatus === LocalStatus.Deleted;
  }

  get isLastChangeByAnotherUser(): boolean {
    return !_.isNil(this.changesetItem.localAuthor) && !_.isNil(this.currentUserName) && this.changesetItem.localAuthor !== this.currentUserName;
  }

  get isValidationValid(): boolean {
    return ValidationStatusHelper.isValidationValid(this.changesetItem.validationStatus);
  }

  get isValidationWithError(): boolean {
    return ValidationStatusHelper.isValidationWithError(this.changesetItem.validationStatus);
  }

  get isValidationWithWarning(): boolean {
    return ValidationStatusHelper.isValidationWithWarning(this.changesetItem.validationStatus);
  }
}

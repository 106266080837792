import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { Observable, Subject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { PatchInstance, PatchOption } from '../batch-actions/batch-action.model';
import { PatchPatternInstances } from '../../model/pattern';
import { AppState } from '../../model/reducer';
import { Store } from '@ngrx/store';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { DirtyFormGuardConnectorService } from '../../common/services/dirty-form-guard-connector.service';
import * as _ from 'lodash';
import { LabelActionConnectService } from './label-action-connect.service';

@Component({
  selector: 'adm4-label-menu-option',
  template: `
    <mat-form-field *ngIf='!hasPatternLabel' class="label-input-wrapper">
      <input placeholder="Type to search or create"
             #labelInput
             matInput
             (adm4AfterViewInit)='setFocusOnInput()'
             [formControl]="labelForm"
             (click)='$event.stopPropagation()'
             [matAutocomplete]="auto"/>
      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="addLabel($event)" [panelWidth]='220'>
        <mat-option *ngFor="let label of filteredLabels | async" [value]="label" class="option-large">
          {{displayLabelOption(label)}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div class='label-chips-wrapper'>
      <mat-chip-listbox>
        <mat-chip *ngFor="let label of displayedLabels"
                  class='label-chip'
                  [removable]="false">
          <span class='label-chip-text'>{{label}}</span>
        </mat-chip>
      </mat-chip-listbox>
    </div>
    <button type="button" mat-menu-item class='remove-option' *ngIf='displayedLabels.length > 0' (click)='removeLabel($event)'>
      Remove label
    </button>`,
  styleUrls: ['../../common/styles/component-specific/overflow-menu.scss', 'label-menu-option.scss']
})
export class LabelMenuOptionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedPatternIds: string[];
  @Input() projectKey: string;
  @Input() allPatternLabels: string[];
  @Input() labels: string[];
  @ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autoCompleteTrigger: MatAutocompleteTrigger;
  @Output() triggerMenuClose = new EventEmitter();
  displayedLabels: string[] = [];
  filteredLabels: Observable<string[]>;
  labelForm = new UntypedFormControl();
  private destroyed$: Subject<boolean> = new Subject();

  constructor(public modalNotificationService: ModalNotificationService,
              private toastNotificationService: ToastNotificationService,
              private store$: Store<AppState>,
              private formGuardConnectorService: DirtyFormGuardConnectorService,
              private labelActionConnectService: LabelActionConnectService) {
  }

  ngOnInit() {
    this.filteredLabels = this.labelForm.valueChanges.pipe(
      takeUntil(this.destroyed$),
      startWith(null),
      map((labelFormValue: string | null) => {
        return labelFormValue ? this.filterMatchingLabels(labelFormValue) : this.allPatternLabels.slice();
      }));
    this.displayedLabels = this.labels;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['labels'] && changes['labels'].currentValue && changes['labels'].previousValue && !changes['labels'].firstChange) {
      const hasPatternLabelChanged = !_.isEqual(changes['labels'].currentValue, changes['labels'].previousValue);
      const shouldUpdateDisplayedLabels = !_.isEqual(this.displayedLabels, this.labels);
      if (hasPatternLabelChanged || shouldUpdateDisplayedLabels) {
        this.displayedLabels = this.labels;
      }
    }
  }

  get hasPatternLabel(): boolean {
    return this.displayedLabels && this.displayedLabels.length > 0;
  }

  displayLabelOption(label: string): string {
    const isNewLabel = !_.isNil(this.labelForm) ? this.labelForm.value === label : false;
    return isNewLabel ? `Create new label "${label}"` : label;
  }

  addLabel(event: MatAutocompleteSelectedEvent): void {
    this.formGuardConnectorService.doIfConfirmed(() => {
      const labelName = event.option.value.trim();
      this.labelForm.setValue(null);
      if (_.isEmpty(labelName)) {
        this.modalNotificationService.openErrorDialog({title: 'Could not save empty label', description: `An empty label cannot be used. Please fill it.`});
        return;
      }
      this.labelActionConnectService.updateLabel(labelName);
      this.triggerMenuClose.emit();
      this.updatePatternLabel(labelName, PatchOption.ADD);
      this.displayedLabels = [labelName];
    });
  }

  removeLabel($event): void {
    this.formGuardConnectorService.doIfConfirmed(() => {
      this.displayedLabels = [];
      this.updatePatternLabel(undefined, PatchOption.REMOVE);
      $event.stopPropagation();
    });
  }

  updatePatternLabel(label: string | undefined, patchOption: PatchOption): void {
    const patchList: PatchInstance[] = this.selectedPatternIds.map((patternId) => {
      const patternToPatch: PatchInstance = {
        id: patternId,
        op: patchOption,
        path: '/label',
        value: label
      };
      return patternToPatch;
    });
    this.store$.dispatch(new PatchPatternInstances({
      patchList: patchList,
      projectKey: this.projectKey,
      onPatchSuccess: () => this.displayToastMessage(label, patchOption)
    }));
  }

  displayToastMessage(labelName: string | undefined, patchOption: PatchOption): void {
    const removeLabelMessage = this.selectedPatternIds.length > 1 ? `The labels are successfully removed from the patterns` : `The label <b>${this.labels[0]}</b> is successfully removed from the pattern`;
    const addLabelMessage = this.selectedPatternIds.length > 1 ? `The patterns are successfully labelled as <b>${labelName}</b>` : `The pattern is successfully labelled as <b>${labelName}</b>`;
    const messageToDisplay = patchOption === PatchOption.ADD ? addLabelMessage : removeLabelMessage;
    this.toastNotificationService.showSuccessToast(messageToDisplay, 'Success');
  }

  private filterMatchingLabels(labelFormValue: string): string[] {
    const labelsToDisplay: string[] = this.allPatternLabels.filter(label => label.indexOf(labelFormValue) === 0);
    if (!labelsToDisplay.includes(labelFormValue)) {
      labelsToDisplay.push(labelFormValue);
    }
    return labelsToDisplay;
  }

  setFocusOnInput(): void {
    setTimeout(() => !!this.labelInput?.nativeElement && this.labelInput.nativeElement.focus(), 100);
  }

  closeLabelOptionsDropdown(): void {
    if (this.autoCompleteTrigger) {
      this.autoCompleteTrigger.closePanel();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}

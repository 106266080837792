import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CheckModel, CommandModel, GenerationTriggerModel } from '../../planning-output.model';
import { ItemDetailsComponent } from '../item-details.component';
import * as _ from 'lodash';
import { OutputHelper } from '../../../../output.helper';

@Component({
  selector: 'adm4-check-detail',
  templateUrl: './check-detail.component.html',
  styleUrls: ['../../item-details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckDetailComponent extends ItemDetailsComponent<CheckModel | GenerationTriggerModel> {
  @Output() changeSelectedDetail: EventEmitter<string> = new EventEmitter<string>();

  get shouldDisplayConditions(): boolean {
    const detailId: string | undefined = _.get(this.node.details, 'id');
    // in case check doesn't have id then we don't care about conditions because such check cannot have them
    // otherwise it's a trigger so we need to display its conditions
    return !_.isNil(detailId);
  }

  // conditions will only be present in case it's a trigger, so please check above getter
  get conditionFailureCommands(): CommandModel[] {
    const triggerDetails = this.node.details as GenerationTriggerModel;
    return OutputHelper.getCommandsByIds(this.node.output, triggerDetails.onFailure);
  }

  get conditionSuccessCommands(): CommandModel[] {
    const triggerDetails = this.node.details as GenerationTriggerModel;
    return _.isNil(triggerDetails.onSuccess) ? [] : OutputHelper.getCommandsByIds(this.node.output, triggerDetails.onSuccess);
  }

  navigateToCommand(commandId: string): void {
    this.changeSelectedDetail.emit(commandId);
  }
}



export interface ReportGenerationModel {
  projectKey: string;
  inventoryKey: string;
  reportClassName: string;
  mediaType: string;
}

export interface ReportGenerationStatusModel {
  reportId: string;
  projectKey: string;
  inventoryKey: string;
  reportClassName: string;
  mediaType: string;
  overallStatus: ReportGenerationStatus;
  overallProgress: number;
}

export interface GeneratedReportInfo {
  reportId: string;
  inventoryKey: string;
  generationDatetime: number;
  generationOutput: string | null;
  finalGenerationStatus: ReportGenerationStatus | null;
}

export interface ReportGeneratingOutputPayload {
  reportKey: string;
  generationOutput: string | null;
}

export enum ReportGenerationStatus {
  CREATED = 'CREATED',
  INITIALIZED = 'INITIALIZED',
  GENERATING = 'GENERATING',
  AGGREGATING = 'AGGREGATING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  NOTHING_TO_DO = 'NOTHING_TO_DO'
}


import * as _ from 'lodash';
import { DEFAULT_BRANCH_NAME } from '../../common/components/version-control-form/version-control-form.constants';
import { VersionControlHelper } from '../../common/helpers/version-control.helper';
import { Inventory, InventorySchemaType } from '../inventory.model';
import { ImportInventoryForm } from '../inventory-creation.model';
import { DEFAULT_INVENTORY_TYPE } from '../../common/constants/app.constants';

export class ImportInventoryHelper {
  static combineInventoryName(repository: string, branch: string): string {
    if (!_.isEmpty(repository) && !_.isEmpty(branch)) {
      return `${repository}-${branch}`.toUpperCase();
    } else  {
      return '';
    }
  }

  static getInventoryFromFormValue(formValue: ImportInventoryForm, tenantKey: string): Inventory {
    const branchName = _.isNil(formValue.versionControl.branch) ? DEFAULT_BRANCH_NAME : formValue.versionControl.branch.name;
    return {
      inventoryKey: `${tenantKey}-${formValue.inventoryKey}`.toUpperCase(),
      tenantKey: tenantKey,
      branch: branchName,
      repository: formValue.versionControl.repository,
      path: VersionControlHelper.getValidPath(formValue.versionControl.path),
      type: DEFAULT_INVENTORY_TYPE,
      schemaType: InventorySchemaType.CLASSIC,
      publishRequired: formValue.publishRequired
    };
  }
}

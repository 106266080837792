import { Injectable } from '@angular/core';
import { ToastOpeningHelper } from './toast-opening.helper';
import { ActiveToast } from 'ngx-toastr/toastr/toastr.service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable()
export class ToastNotificationService {

  constructor(private toastrService: ToastrService) {}

  showSuccessToast(description: string, title: string = 'Success'): ActiveToast<void> | null {
    return this.toastrService.success(description, title, ToastOpeningHelper.getBaseClass('success'));
  }

  showInfoToast(description: string, title: string = 'Info', config?: Partial<IndividualConfig>): ActiveToast<void> | null {
    return this.toastrService.info(description, title, config || ToastOpeningHelper.getBaseClass('info'));
  }

  showWarningToast(description: string, title: string = 'Warning'): ActiveToast<void> | null {
    return this.toastrService.warning(description, title, ToastOpeningHelper.getBaseClass('warning'));
  }

  showErrorToast(description: string, title: string = 'Error', config?: Partial<IndividualConfig>): ActiveToast<void> | null {
    return this.toastrService.error(description, title, config || ToastOpeningHelper.getBaseClass('error'));
  }

  showPersistedInfoToast(description: string, title?: string): ActiveToast<void> | null {
    return this.showInfoToast(description, title, ToastOpeningHelper.getPersistentClass('info'));
  }

  showPersistedErrorToast(description: string, title?: string): ActiveToast<void> | null {
    return this.showErrorToast(description, title, ToastOpeningHelper.getPersistentClass('error'));
  }
}

import { Component, Input } from '@angular/core';
import { GeneratedReportInfo } from '../reports.model';

@Component({
  selector: 'adm4-report-generation-info',
  template: `
    <ng-template #lastGenerationDetailTooltip>{{lastReportInfo.generationDatetime | date: 'yyyy-MM-dd HH:mm'}}</ng-template>
    <div *ngIf='lastReportInfo' class='last-generated'>
      <div>Last generation:
        <span [ngbTooltip]='lastGenerationDetailTooltip' placement='top'>
          {{lastReportInfo.generationDatetime| customDate}}
        </span>
      </div>
      <div>Inventory: {{lastReportInfo.inventoryKey | cropTenantFromKey}}</div>
    </div>
  `
})
export class ReportGenerationInfoComponent {
  @Input() lastReportInfo: GeneratedReportInfo | null;
}

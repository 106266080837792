import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './common/guards/authentication.guard';
import { DeploymentWizardMainComponent } from './deployment-wizard/deployment-wizard-main.component';
import { ModalWindowGuard } from './common/guards/modal-window.guard';
import { DeploymentWizardDeactivationGuard } from './deployment-wizard/deployment-wizard-deactivation.guard';
import { PublishCommitMessageGuard } from './common/guards/publish-commit-message.guard';
import { shouldUseHashStrategy } from './app-routing.constants';
import { WelcomeComponent } from './welcome/welcome.component';
import { TenantGuard } from './tenant/tenant.guard';
import { PublishProjectMainComponent } from './projects/publish-project/publish-project-main.component';
import { ProjectHasUnpublishedChangesGuard } from './projects/publish-project/project-has-unpublished-changes.guard';
import { PublishInventoryMainComponent } from './inventory/publish-inventory/publish-inventory-main.component';
import { InventoryHasUnpublishedChangesGuard } from './inventory/publish-inventory/inventory-has-unpublished-changes.guard';
import { AuthenticationDeactivationGuard } from './common/guards/authentication-deactivation.guard';
import { NavigationConstants } from './common/constants/navigation.constants';

export const routes: Routes = [
  { path: '', redirectTo: '/projects', pathMatch: 'full' },
  {path: 'welcome', component: WelcomeComponent, canActivate: [AuthenticationGuard, TenantGuard]},
  { path: 'infrastructure', redirectTo: `/infrastructure/inventories`, pathMatch: 'full' },
  {path: NavigationConstants.INFRA_WELCOME, pathMatch: 'full', component: WelcomeComponent, canActivate: [AuthenticationGuard, TenantGuard], data: { mode: 'inventory' }},
  {outlet: 'modal', path: 'deploy', component: DeploymentWizardMainComponent, canActivate: [AuthenticationGuard, TenantGuard, ModalWindowGuard], canDeactivate: [DeploymentWizardDeactivationGuard, AuthenticationDeactivationGuard]},
  {outlet: 'modal', path: 'publishProject', component: PublishProjectMainComponent, canActivate: [AuthenticationGuard, TenantGuard, ModalWindowGuard, ProjectHasUnpublishedChangesGuard], canDeactivate: [PublishCommitMessageGuard, AuthenticationDeactivationGuard]},
  {outlet: 'modal', path: 'publishInventory', component: PublishInventoryMainComponent, canActivate: [AuthenticationGuard, TenantGuard, ModalWindowGuard, InventoryHasUnpublishedChangesGuard], canDeactivate: [PublishCommitMessageGuard]},
  {
    outlet: 'modal',
    path: 'global-search',
    loadChildren: () => import('./global-search/global-search.module').then(m => m.GlobalSearchModule),
  },
  {
    path: 'graph',
    loadChildren: () => import('./graph/graph.module').then(m => m.GraphModule),
    canActivate: [AuthenticationGuard, TenantGuard],
  },
  {
    path: 'access-mgmt',
    loadChildren: () => import('./access-mgmt/access-management.module').then(m => m.AccessManagementModule),
    canActivate: [AuthenticationGuard, TenantGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: shouldUseHashStrategy })],

  providers: [
    AuthenticationGuard,
    ModalWindowGuard,
    DeploymentWizardDeactivationGuard,
    PublishCommitMessageGuard,
    AuthenticationDeactivationGuard
  ]
})
export class AppRoutingModule {
}

import { Injectable } from '@angular/core';

import { PublishMainComponent } from '../model/publish-changes/publish-main-component.model';
import { PublishDialogComponent } from '../model/publish-changes/publish-dialog-component.model';

/**
 * If publish changes modal has changed a changed commit message, then it should ask for confirmation before closing modal
 */
@Injectable()
export class PublishCommitMessageGuard  {
  canDeactivate(component: PublishMainComponent<PublishDialogComponent>): boolean {

    // sometimes this guard get called when the dialog is not even activated
    if (!component.dialogRef) {
      return true;
    }

    const publishDialog: PublishDialogComponent = component.dialogRef.componentInstance;
    const isCommitMessageChanged = !component.dialogRef.componentInstance.messageChanged;
    return isCommitMessageChanged || publishDialog.shouldBypassCloseGuard || confirm('Commit message will be discarded, are you sure you want to close?');
  }
}

import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { PluginService } from '../../plugins/plugin.service';
import { WidgetComponent } from '../widget.component';
import { Observable } from 'rxjs';
import { Pattern } from '../../patterns/pattern.model';
import { PatternType } from '../../plugins/pattern-type.model';
import { Store } from '@ngrx/store';
import { AppState, Dictionary } from '../../model/reducer';
import { PropertyWidgetContext } from '../property-widget.context';
import { Project } from '../../projects/project.model';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-pattern-reference-property-main',
  template: `
    <div [formGroup]='group'>
      <adm4-pattern-reference [patterns]='patterns$ | async'
                              [patternTypes]='patternTypes$ | async'
                              [propertyType]='propertyType'
                              [projectKey]='projectKey$ | async'
                              [group]='group'
                              [widgetProperty]='widgetProperty'
                              [value]='value'
                              [parameters]='parameters'
                              [formControlName]='widgetProperty.propertyKey'
                              [readOnly]='readOnly'
                              [scrollArea]='scrollArea'
                              (select)="onSelect(null, $event)">
      </adm4-pattern-reference>
    </div>`
})
export class PatternReferencePropertyMainComponent extends WidgetComponent implements OnInit {
  public patternsMap$: Observable<Map<string, Pattern>>;
  public patternTypes$: Observable<Dictionary<PatternType> | null>;
  public projectKey$: Observable<string | null>;
  public patterns$: Observable<Pattern[]>;

  constructor(private pluginService: PluginService,
              private store$: Store<AppState>,
              private propertyWidgetContext: PropertyWidgetContext) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.projectKey$ = this.propertyWidgetContext.currentProject$.pipe(map((project: Project | null) => _.isNil(project) ? null : project.projectKey));
    this.patternsMap$ = this.propertyWidgetContext.patterns$;
    // this is needed to avoid expression after change and to make sure an array is passed to the other component and not a Map
    this.patterns$ = this.patternsMap$.pipe(map((p: Map<string, Pattern>) => Array.from(p.values())));
    this.patternTypes$ = this.propertyWidgetContext.patternTypes$;
  }

  override isMultiValue(): boolean {
    return true;
  }
}

import { InventoryColor } from '../inventory.model';
import * as _ from 'lodash';

import { CancellationToken, editor, IRange, languages, Position } from 'monaco-editor';
import CompletionItemProvider = languages.CompletionItemProvider;

/**
 * Creates custom completion for the Monaco source code editor.
 * https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-completion-provider-example
 *
 * @returns {CompletionItemProvider} Object with a method provideCompletionItems(model, position) implemented.
 */
export function createColorCompletionItemProvider(): CompletionItemProvider {
  const colors: ReadonlyArray<string> = _.values(InventoryColor)
      .filter(color => !_.isEmpty(color))
      .map(color => color.toString());

  return {
    provideCompletionItems(
        model: editor.ITextModel, position: Position, _context: languages.CompletionContext, _token: CancellationToken
    ): languages.ProviderResult<languages.CompletionList> {
      // enable completion if a line starts with 'color:' and the user is typing the first word after that
      const range: IRange = {startLineNumber: position.lineNumber, startColumn: 1, endLineNumber: position.lineNumber, endColumn: position.column};
      const textUntilPosition = model.getValueInRange(range);

      // Create a list of completion objects based on the possible inventory colors.
      const completions = colors
          .map((color: string): languages.CompletionItem => ({
            label: color,
            insertText: color,
            kind: languages.CompletionItemKind.Color,
            range,
          }));

      const match = textUntilPosition.match(/color\s*:\s*\w*$/);
      if (match) {
        return {suggestions: completions};
      } else {
        return {suggestions: [], incomplete: false};
      }
    }
  };
}

import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { CommonModules } from '../common/common.module';
import { ModalDialogModule } from '../modal-dialog/modal-dialog.module';
import { ReportsMainComponent } from './reports-main/reports-main.component';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportDetailsComponent } from './report-details/report-details.component';
import { ReportGenerationComponent } from './report-generation/report-generation.component';
import { ReportGenerationInfoComponent } from './report-generation/report-generation-info.component';
import { ReportGenerationOutputComponent } from './report-generation-output/report-generation-output.component';
import { ProjectHeaderModule } from '../projects/project-header/project-header.module';

@NgModule({
  imports: [
    MatTableModule,
    CommonModules,
    ModalDialogModule,
    ProjectHeaderModule
  ],
  declarations: [
    ReportsMainComponent,
    ReportListComponent,
    ReportDetailsComponent,
    ReportGenerationComponent,
    ReportGenerationOutputComponent,
    ReportGenerationInfoComponent
  ],
  exports: [
    ReportsMainComponent
  ]
})
export class ReportsModule {
}

import { DirectoryModel, FileModel, GenerationOutputModel } from '../deploy/deployment-preview/planning-output.model';
import * as _ from 'lodash';

export class DeploymentHelper {
  /**
   * There is a bug on the backend that on hosts some files point to directories which are only sent within 1 host
   * FE fixes that problem by copying those directories over to hosts where it's missing
   * @param {GenerationOutputModel[]} generationOutput
   * @returns {GenerationOutputModel[]}
   */
  static fixDeploymentResponse(generationOutput: GenerationOutputModel[]): GenerationOutputModel[] {
    return generationOutput.map((item: GenerationOutputModel, _index: number, items: GenerationOutputModel[]) => {
      const existingDirectoryIds: string[] = item.directories.map(directory => directory.directoryId);
      const directoryIdsNotPresentInItem: string[] = this.getDirectoryIdsNotPresentInGenerationItem(item, existingDirectoryIds);
      const foundDirectoriesFromAllItems: DirectoryModel[] = this.findDirectoriesInALlItems(item, items, directoryIdsNotPresentInItem);
      return {...item, directories: _.concat(item.directories, foundDirectoriesFromAllItems)};
    });
  }

  private static getDirectoryIdsNotPresentInGenerationItem(item: GenerationOutputModel, existingDirectoryIds: string[]): string[] {
    const filesParentIds: string[] = item.files.map((file: FileModel) => file.parentId);
    const directoryParentIds: string[] = item.directories
      .filter((directory: DirectoryModel) => !_.isNil(directory.parentId))
      .map((directory: DirectoryModel) => <string>directory.parentId);
    return _(filesParentIds)
      .concat(directoryParentIds)
      .filter(artifactParentId => !_.includes(existingDirectoryIds, artifactParentId))
      .uniq()
      .value();
  }

  private static findDirectoriesInALlItems(currentItem: GenerationOutputModel, items: GenerationOutputModel[], directoryIdsNotPresentInItem: string[]): DirectoryModel[] {
    return directoryIdsNotPresentInItem
      .map((directoryId: string) => {
        return _.flatMap(items, searchingItem => searchingItem.directories).find(directory => directory.directoryId === directoryId);
      })
      .filter((directory: DirectoryModel | undefined) => !_.isNil(directory))
      .map((directory: DirectoryModel) => {
        return {...directory, patternId: currentItem.patternId};
      });
  }
}

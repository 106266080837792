<div class='full-height'>
  <as-split class="hide-as-split-gutter" direction='horizontal' gutterSize='1' useTransition (dragEnd)='onResize($event)'>
    <as-split-area [order]='splitPaneConfig[listSplitAreaKey].order' [size]='splitPaneConfig[listSplitAreaKey].size' [minSize]='20'>
      <div class="full-height-flex">
        <adm4-column-header styleClass="project-header">
          <adm4-project-header [projectKey]='projectKey$ | async'
                               [projectIssues]='issues$ | async'
                               [highestSeverity]="highestSeverity$ | async"
                               [projects]='projects$ | async'
                               [projectMetaIndicator]="projectMetaChangeIndicator$ | async"
          ></adm4-project-header>
        </adm4-column-header>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <adm4-pattern-issue-severity-groups></adm4-pattern-issue-severity-groups>
          </div>
        </div>
      </div>
    </as-split-area>
    <as-split-area [order]='splitPaneConfig[issuesSplitAreaKey].order' [size]='splitPaneConfig[issuesSplitAreaKey].size' [minSize]='40'>
      <div class="full-height">
        <adm4-issue-list [isHelpCollapsed]='splitPaneConfig[helpSplitAreaKey].isCollapsed' (expandHelp)='onCollapse(false, helpSplitAreaKey)'></adm4-issue-list>
      </div>
    </as-split-area>
    <as-split-area [order]='splitPaneConfig[helpSplitAreaKey].order' [size]='splitPaneConfig[helpSplitAreaKey].size' [minSize]='20' [visible]='!splitPaneConfig[helpSplitAreaKey].isCollapsed'>
      <div class='full-height'>
        <adm4-issues-help (collapse)='onCollapse($event, helpSplitAreaKey)'></adm4-issues-help>
      </div>
    </as-split-area>
  </as-split>
</div>

import { ParsedBundle } from './bundle.model';
import * as _ from 'lodash';

export class BundleHelper {
  static parseBundlesFromBundleKeys(bundleKeys: string[]): ParsedBundle[] {
    return bundleKeys.map(bundleKey => this.parseBundleFromBundleKey(bundleKey));
  }

  static parseBundleFromBundleKey(bundleKey: string): ParsedBundle {
    const [bundleSymbolicName, bundleVersion] = bundleKey.split(':');
    return {
      bundleKey: bundleKey,
      symbolicName: bundleSymbolicName,
      version: _.isNil(bundleVersion) ? '' : bundleVersion
    };
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PluginModel } from '../../../plugins/plugin.model';
import { PatternListData } from '../../../patterns/pattern-list-data.model';
import { ProjectMeta } from '../../project.model';
import { VariableModel } from '../../../variables/variable.model';
import { IssueModel } from '../../../common/model/issue.model';

@Component({
  selector: 'adm4-project-summary',
  template: `
    <div class='full-height-flex'>
      <adm4-column-header styleClass="nevis-green-header flex-header-stretched">Project Overview</adm4-column-header>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <div class='full-height-flex project-summary-border'>
            <ng-template #emptyProject>
              <adm4-project-summary-empty
                      [currentProjectKey]='currentProjectKey'
                      [pluginsByPatternType]='pluginsByPatternType'></adm4-project-summary-empty>
            </ng-template>
            <adm4-project-summary-content *ngIf='displayProjectSummary; else emptyProject'
                                          [projectKey]='currentProjectKey'></adm4-project-summary-content>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./project-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryComponent {
  @Input() public currentProjectKey: string;
  @Input() public pluginsByPatternType: PluginModel[];
  @Input() public patternList: PatternListData[];
  @Input() public projectStatusModel: ProjectMeta | null;
  @Input() public variables: VariableModel[];
  @Input() public reports: PluginModel[];
  @Input() public issues: IssueModel[];
  @Input() versioned: boolean;

  get displayProjectSummary(): boolean {
    return !!this.patternList && this.patternList.length > 0;
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { Dictionary } from '../../model/reducer';

export enum ErrorHelperConstants {
  FIELD = 'FIELD'
}

export class ErrorHelper {
  static responseErrorHasDetail(errorResponse: HttpErrorResponse): boolean {
    return !_.isNil(errorResponse.error) && !_.isNil(errorResponse.error.error) && !_.isNil(errorResponse.error.error.detail);
  }

  static getErrorDetail<T extends string | undefined>(errorResponse: HttpErrorResponse, defaultMessage?: T): T {
    const msg = 'Please try again to continue. If this problem happens repeatedly, please report it to us. The details can be found in the console.';
    return this.responseErrorHasDetail(errorResponse) ? errorResponse.error.error.detail : defaultMessage || msg;
  }

  static hasSource(errorResponse: HttpErrorResponse, searchedSource: Dictionary<string>): boolean {
    if (_.isNil(errorResponse.error) || _.isNil(errorResponse.error.error) || _.isNil(errorResponse.error.error.sources)) {
      return false;
    }
    const errorSources: Dictionary<string>[] = errorResponse.error.error.sources;
    return errorSources.some((source: Dictionary<string>) => _.keys(searchedSource).every(key => source[key] === searchedSource[key]));
  }

  static hasSourceInStatus(errorResponse: HttpErrorResponse, searchedSource: Dictionary<string>): boolean {
    if (!this.responseErrorHasStatus) {
      return false;
    }
    const errorResourceList = errorResponse.error._status._errors;
    return errorResourceList.some((errorSource: any) => errorSource.sources.some((source: Dictionary<string>[]) => _.keys(searchedSource).every(key => source[key] === searchedSource[key])));
  }

  static responseErrorHasStatus(errorResponse: HttpErrorResponse): boolean {
    return !_.isNil(errorResponse.error) && !_.isNil(errorResponse.error._status) && !_.isNil(errorResponse.error._status._errors);
  }

  static getErrorDetailFromStatus(errorResponse: HttpErrorResponse, searchedSource: Dictionary<string>): string {
    if (!this.responseErrorHasStatus) {
      return '';
    }
    const errorResourceList = errorResponse.error._status._errors;
    let errorDetail = '';
    const hasDetail: boolean = errorResourceList.some((errorSource: any): boolean => {
      if (errorSource.sources[0][ErrorHelperConstants.FIELD] === searchedSource[ErrorHelperConstants.FIELD]) {
        errorDetail = errorSource.detail;
        return true;
      }
      return false;
    });
    return hasDetail ? errorDetail : '';
  }
}

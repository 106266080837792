import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MetaInfo } from '../../../version-control/meta-info.model';
import { Observable } from 'rxjs';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { TextDiffData } from '../../../common/model/publish-changes/text-diff-data.model';
import { InventoryChangesetItem } from '../inventory-changeset-item.model';
import { InventoryContentDiffViewContext } from './inventory-content-diff-view.context';

@Component({
  selector: 'adm4-inventory-content-diff-view',
  template: `
    <div class='full-height-flex'>
      <div class='diff-view-header diff-with-editor-header'>
        <div class='remote'>
          Remote inventory <span *ngIf='currentBranch$ | async'>({{currentBranch$ | async}})</span>
        </div>
        <div class='local'>
          Local inventory
        </div>
      </div>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <adm4-file-content-diff *ngIf='inventoryContentDiff$ | async' [fileContentDiff]="inventoryContentDiff$ | async"></adm4-file-content-diff>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../../../common/styles/component-specific/diff-view-header.scss'],
  providers: [InventoryContentDiffViewContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryContentDiffViewComponent {

  /**
   * This field represents selected changeset in left column of publish project, it's a way to connect the 2 sides when interation between them is needed
   */
  changesetItem: InventoryChangesetItem<MetaInfo, InventoryContentDiffViewComponent>;

  inventoryContentDiff$: Observable<Diff<TextDiffData> | null>;
  currentBranch$: Observable<string | null>;

  constructor(private inventoryContentDiffViewContext: InventoryContentDiffViewContext) {
    this.inventoryContentDiff$ = this.inventoryContentDiffViewContext.inventoryContentDiff$;
    this.currentBranch$ = this.inventoryContentDiffViewContext.currentBranch$;
  }

}

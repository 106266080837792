import * as _ from 'lodash';
import { variableReferencePrefix } from '../common/constants/reference-prefix.constants';

export function isVariable(val: any): boolean {
  let parsedValue: any;
  try {
    parsedValue = JSON.parse(val);
  } catch (e) {
    parsedValue = val;
  }
  return _.startsWith(parsedValue, variableReferencePrefix);
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileBasedImportProjectContainerComponent } from './file-based-import-project-container.component';

@Injectable({
  providedIn: 'root'
})
export class FileBasedImportProjectDialogService {

  constructor(private matDialog: MatDialog) { }

  openFileBasedImportProjectDialog(): MatDialogRef<FileBasedImportProjectContainerComponent> {
    return this.matDialog.open(FileBasedImportProjectContainerComponent, {
      width: '30%',
      autoFocus: false,
      disableClose: true
    });
  }
}

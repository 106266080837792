import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as _ from 'lodash';

import { Pattern } from '../patterns/pattern.model';
import { PropertyType } from '../plugins/property-type.model';
import { AppState, Dictionary } from '../model/reducer';
import { PatternType } from '../plugins/pattern-type.model';
import { VariableModel } from '../variables/variable.model';
import { Unsubscriber } from '../common/helpers/unsubscriber';
import { PatternService } from '../patterns/pattern.service';
import { PatternHelper } from '../patterns/pattern.helper';
import { PluginService } from '../plugins/plugin.service';
import { VariableService } from '../variables/variable.service';
import { ValidationIssue, ValidationStatus } from '../common/model/validation-status.model';
import {
  allInventoriesView, allProjectsView,
  deployInventoryValidationStatusView, deploymentWizardSelectionInventoryKeyView, deploymentWizardSelectionProjectKeyView,
} from '../model/views';
import { Inventory, InventorySchemaType } from '../inventory/inventory.model';
import { filterEmpty, filterNotNil } from '../common/utils/utils';
import { Project, ProjectInventoryDeployment } from '../projects/project.model';

@Injectable()
export class DeploymentWizardContext {
  private _patterns: BehaviorSubject<Map<string, Pattern>> = new BehaviorSubject(new Map());
  private _patternTypes: BehaviorSubject<Dictionary<PatternType> | null> = new BehaviorSubject(null);
  private _propertyTypes: BehaviorSubject<Dictionary<PropertyType> | null> = new BehaviorSubject(null);
  private _variables: BehaviorSubject<VariableModel[]> = new BehaviorSubject([]);

  // backend subscriptions
  private _patternsSubscription: Subscription;
  private _patternTypesSubscription: Subscription;
  private _propertyTypesSubscription: Subscription;
  private _variablesSubscription: Subscription;

  patterns: Observable<Map<string, Pattern>> = this._patterns.asObservable();
  patternTypes: Observable<Dictionary<PatternType> | null> = this._patternTypes.asObservable();
  propertyTypes: Observable<Dictionary<PropertyType> | null> = this._propertyTypes.asObservable();
  variables: Observable<VariableModel[]> = this._variables.asObservable();

  selectedInventoryKey$: Observable<string | undefined>;
  selectedProjectInventoryDeployments$: Observable<ProjectInventoryDeployment[]>;
  inventoryColor$: Observable<string>;
  selectedInventorySchemaType$: Observable<InventorySchemaType | undefined>;
  inventoryValidationStatus$: Observable<ValidationStatus<ValidationIssue> | null>;

  constructor(private patternService: PatternService,
              private pluginService: PluginService,
              private variablesService: VariableService,
              private store$: Store<AppState>) {

    this.inventoryValidationStatus$ = this.store$.pipe(select(deployInventoryValidationStatusView));
    this.selectedInventoryKey$ = this.store$.pipe(select(deploymentWizardSelectionInventoryKeyView));

    this.selectedProjectInventoryDeployments$ = combineLatest([
        this.store$.select(deploymentWizardSelectionProjectKeyView).pipe(filter(filterEmpty)),
        this.store$.select(allProjectsView),
    ]).pipe(
        map(([selectedProjectKey, allProjects]: [string, Record<string, Project>]): ProjectInventoryDeployment[]  => {
          return (allProjects[selectedProjectKey]?._deployedInventories)??[];
        }),
    );

    this.inventoryColor$ = combineLatest([
      this.store$.pipe(select(allInventoriesView), filterNotNil()),
      this.store$.pipe(select(deploymentWizardSelectionInventoryKeyView))])
      .pipe(
        map(([inventories, selectedInventoryKey]) => {
          const inventory = !!selectedInventoryKey ? inventories[selectedInventoryKey] : undefined;
          return !inventory ? undefined : inventory.color;
        }),
        distinctUntilChanged(_.isEqual)
      );
    const selectedInventory$: Observable<Inventory | undefined> = combineLatest([
      this.store$.pipe(select(allInventoriesView), filterNotNil()),
      this.store$.pipe(select(deploymentWizardSelectionInventoryKeyView)),
    ]).pipe(
        map(([inventories, selectedInventoryKey]): Inventory | undefined => {
          return selectedInventoryKey ? inventories[selectedInventoryKey] : undefined;
        }),
        shareReplay(),
    );
    this.selectedInventorySchemaType$ = selectedInventory$.pipe(
      map((inventory: Inventory | undefined): InventorySchemaType | undefined => !inventory ? undefined : inventory.schemaType),
      distinctUntilChanged(_.isEqual),
    );
  }

  resetPatterns(): void {
    this._patterns.next(new Map());
  }

  resetPatternTypes(): void {
    this._patternTypes.next(null);
  }

  resetPropertyTypes(): void {
    this._propertyTypes.next(null);
  }

  resetVariables(): void {
    this._variables.next([]);
  }

  loadPatterns(projectKey: string): void {
    // cancel previous request when triggering new one
    Unsubscriber.unsubscribeFrom(this._patternsSubscription);

    this.resetPatterns();
    this._patternsSubscription = this.patternService.getAllPatterns(projectKey)
      .subscribe((patterns: Pattern[]) => this._patterns.next(PatternHelper.convertPatternsListToMap(patterns)));
  }

  loadPatternTypes(projectKey: string): void {
    // cancel previous request when triggering new one
    Unsubscriber.unsubscribeFrom(this._patternTypesSubscription);

    this.resetPatternTypes();
    this._patternTypesSubscription = this.patternService.getPatternTypes(projectKey)
      .subscribe((patternTypes: PatternType[]) => this._patternTypes.next(PatternHelper.convertTypesListToDictionary(patternTypes)));
  }

  loadPropertyTypes(projectKey: string): void {
    // cancel previous request when triggering new one
    Unsubscriber.unsubscribeFrom(this._propertyTypesSubscription);

    this.resetPropertyTypes();
    this._propertyTypesSubscription = this.pluginService.getPropertyTypes(projectKey)
      .subscribe((propertyTypes: PropertyType[]) => this._propertyTypes.next(PatternHelper.convertTypesListToDictionary(propertyTypes)));
  }

  loadVariables(projectKey: string): void {
    // cancel previous request when triggering new one
    Unsubscriber.unsubscribeFrom(this._variablesSubscription);

    this.resetVariables();
    this._variablesSubscription = this.variablesService.getAllVariablesOfProject(projectKey)
      .subscribe((variables: VariableModel[]) => this._variables.next(variables));
  }

  /**
   * Facade method for loading all project related data
   * @param {string} projectKey
   */
  loadProjectData(projectKey: string): void {
    this.loadPatterns(projectKey);
    this.loadPatternTypes(projectKey);
    this.loadPropertyTypes(projectKey);
    this.loadVariables(projectKey);
  }
}

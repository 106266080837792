import { Component, Input } from '@angular/core';
import {PatternIssueData} from '../model/pattern-issue-data.model';
import {VariableModel} from '../../variables/variable.model';

@Component({
selector: 'adm4-pattern-issue-group-list',
template: `
  <div *ngFor='let patternIssueGroup of patternIssueGroups'>
    <adm4-pattern-issue-group [patternIssueGroup]='patternIssueGroup'
                              [isSelected]='isGroupSelected(selectedGroupId, patternIssueGroup.pattern.patternId)'
                              [projectKey]='projectKey'
                              [variables]='variables'
    ></adm4-pattern-issue-group>
  </div>
`,
styleUrls: ['../issue-group-heading.scss']
})
export class PatternIssueGroupListComponent {
    @Input() patternIssueGroups: PatternIssueData[];
    @Input() selectedGroupId: string | undefined;
    @Input() projectKey: string;
    @Input() variables: VariableModel[];

    isGroupSelected(selectedGroupId: string | undefined, groupId: string): boolean {
        return selectedGroupId === groupId;
    }
}

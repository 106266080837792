import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { first, map, share } from 'rxjs/operators';
import { merge, Observable } from 'rxjs';
import * as _ from 'lodash';

import { NavigationModel } from '../navigation.model';
import { navigationElements } from '../navigation-elements';
import { UrlHelper } from '../../common/helpers/url.helper';
import { NavigationService } from '../navigation.service';
import { DynamicLinkCreatorService } from '../../common/services/dynamic-link-creator.service';
import { DirtyFormGuardConnectorService } from '../../common/services/dirty-form-guard-connector.service';
import { Maybe } from '../../common/utils/utils';
import { DeploymentActivityContextService, DeploymentActivityIndicator } from '../../common/services/deployment-activity-context.service';

/**
 * This component contains a list of tab items which is part of the navigation bar.
 */
@Component({
  selector: 'adm4-tabs',
  template: `
      <button class="global-search-trigger" [class.hidden]="!(showSearchOnProjectTab | async)" (click)="searchClicked()">
          <mat-icon>search</mat-icon>
          <span>Search...</span>
      </button>
      <adm4-tab-item [title]='"Configuration"'
                     [path]='linkCreatorService.configurationLink | async'
                     [active]="isSelected(tabs.projects,(selectedTab|async))"
      ></adm4-tab-item>
      <adm4-tab-item [title]='"Infrastructure"'
                     [path]='linkCreatorService.inventoriesLink | async'
                     [active]="isSelected(tabs.infrastructure,(selectedTab|async))"
      ></adm4-tab-item>
      <adm4-tab-item [title]='"Resources"'
                     [path]='linkCreatorService.resourcesLink | async'
                     [active]="isSelected(tabs.userManagement,(selectedTab|async))"
      ></adm4-tab-item>
      <ng-container *ngIf="(deploymentActivity | async) || {hasActivity: false}; let activity">
        <button class='tab-element nav-deploy-btn focus-nevis-green' (click)='deploy()'
                [class.activity-current-scope]="activity.inCurrentScope"
                [ngbTooltip]="deploymentActivityTooltip" [placement]="'bottom-right'"
                [disableTooltip]="!activity.hasActivity">
          <span class='tab-title'>Deploy</span>
          <ng-container *ngIf="activity.hasActivity else noActivity">
              <!-- the spinner is repeated for the 2 sizes, because the size can not be set from css -->
              <!-- the width of the icon is set to a parent div, because the spinner has inline size set -->
              <div class="deploy-icon flex-center-xy show-large">
                <mat-progress-spinner class="spinner-white" [diameter]="18" [mode]="'indeterminate'"></mat-progress-spinner>
              </div>
              <div class="deploy-icon flex-center-xy show-small">
                <mat-progress-spinner class="spinner-white" [diameter]="16" [mode]="'indeterminate'"></mat-progress-spinner>
              </div>
          </ng-container>
          <ng-template #noActivity>
              <mat-icon class="deploy-icon">play_circle_filled</mat-icon>
          </ng-template>
        </button>
        <ng-template #deploymentActivityTooltip>
            <p *ngFor="let deployment of activity.activities">
              User <strong>{{deployment.userKey}}</strong> has started a deployment of
              <ng-container *ngIf="!deployment.isProjectRedacted; else projectRedacted">
                  the project <strong>{{deployment.projectKey  | cropTenantFromKey}}</strong>
              </ng-container>
              <ng-template #projectRedacted>
                  an undisclosed project
              </ng-template>
              to
              <ng-container *ngIf="!deployment.isInventoryRedacted; else inventoryRedacted">
                  the inventory <strong>{{deployment.inventoryKey | cropTenantFromKey}}</strong>
              </ng-container>
              <ng-template #inventoryRedacted>
                  an undisclosed inventory
              </ng-template>
              .
            </p>
        </ng-template>
      </ng-container>
  `,
  styleUrls: ['tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  public selectedTab: Observable<NavigationModel | undefined>;
  public tabs: Record<string, NavigationModel> = navigationElements;
  public showSearchOnProjectTab: Observable<boolean>;
  public deploymentActivity: Observable<DeploymentActivityIndicator>;

  constructor(
      private router: Router,
      public linkCreatorService: DynamicLinkCreatorService,
      public formGuardConnectorService: DirtyFormGuardConnectorService,
      private navigationService: NavigationService,
      deploymentActivityContext: DeploymentActivityContextService,
  ) {
    this.selectedTab = merge(
        navigationService.urlAfterRedirects$,
        // adding the current path when the first router event happens, because sometimes the actual navigation is already finished
        this.router.events.pipe(
            first(),
            map(() => this.router.url),
        ),
    ).pipe(
        map((path: string) => this.getSelectedTabFromUrl(path)),
        share(),
    );

    this.showSearchOnProjectTab = this.selectedTab.pipe(
        map((nav: Maybe<NavigationModel>): boolean => navigationElements.projects === nav || navigationElements.infrastructure === nav),
    );

    this.deploymentActivity = deploymentActivityContext.deploymentActivity$;
  }

  public searchClicked() {
    this.navigationService.navigateToGlobalSearch();
  }

  getSelectedTabFromUrl(url: string): NavigationModel | undefined {
    const selectedTabPath = decodeURI(UrlHelper.getHeadOfUrl(url));
    return _.values(navigationElements).find(tab => tab.path === selectedTabPath) || undefined;
  }

  isSelected(tab: NavigationModel, selectedTab: NavigationModel) {
    if (!_.isNil(selectedTab)) {
      return tab === selectedTab;
    }
    return tab === this.getSelectedTabFromUrl(this.router.url);
  }

  deploy() {
    this.formGuardConnectorService.doIfConfirmed(() => this.navigationService.navigateToDeploymentWizard());
  }

}

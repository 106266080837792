import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Project } from '../project.model';
import { VERSION_CONTROL_FORM_CONTROL_NAME } from '../create-import-project-common/create-import-branch-project-form.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ConnectProjectVersionControlHelper } from './connect-project-version-control.helper';
import { VersionControlData } from '../../common/model/version-control-data.model';

@Component({
  selector: 'adm4-connect-project-version-control',
  template: `
    <form [formGroup]="form" name="form" #addProjectForm (ngSubmit)='submitConnectProjectToVersionControl()' cdkFocusInitial>
      <div class="content-container create-item-container">
        <adm4-version-control-form (formReady)='addVersionControlForm($event)'></adm4-version-control-form>
      </div>
      <div mat-dialog-actions>
        <adm4-button-bar [isSubmitDisabled]="!canSave"
                         [submitButtonText]="'Connect'"
                         (cancelClicked)='cancel.emit()'></adm4-button-bar>
      </div>
    </form>
  `,
  styleUrls: [
    '../../common/styles/component-specific/create-form.scss',
    '../../common/styles/component-specific/modal-window.scss'
  ]
})
export class ConnectProjectVersionControlComponent {
  @Input() project: Project;
  @Output() projectConnect: EventEmitter<VersionControlData> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  form: UntypedFormGroup;

  readonly VERSION_CONTROL_FORM_CONTROL_NAME = VERSION_CONTROL_FORM_CONTROL_NAME;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({});
  }

  addVersionControlForm(versionControlForm: UntypedFormGroup): void {
    this.form.setControl(this.VERSION_CONTROL_FORM_CONTROL_NAME, versionControlForm);
  }

  submitConnectProjectToVersionControl(): void {
    const projectVersionControlData = ConnectProjectVersionControlHelper.getProjectVersionControlData(this.form.value[this.VERSION_CONTROL_FORM_CONTROL_NAME]);
    this.projectConnect.emit(projectVersionControlData);
  }

  get canSave(): boolean {
    const versionControlForm = this.form.controls[this.VERSION_CONTROL_FORM_CONTROL_NAME] as UntypedFormGroup;
    const versionControlValid: boolean = _.values(versionControlForm.controls).every(control => control.valid);
    return !_.isNil(versionControlForm) && versionControlValid;
  }
}

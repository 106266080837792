import { NevisAdminAction } from '../actions';
import * as fromUserActions from './user.actions';
import { UserData, UserToken } from './user.model';

export class UserState {
  readonly user: UserData | null;
  readonly userId: UserToken | null;
/// to be used
  readonly authenticated: boolean;
  // error message
  readonly error?: string;
  // true if we have attempted existing auth session
  readonly loaded: boolean;
  // true when loading
  readonly loading: boolean;
  // session is expired, or logged out from other browser window
  readonly expired: boolean;
  // Does the user have super admin permission
  readonly superAdmin: boolean;

}

export const initState: UserState = {
  user: null,
  userId: null,
  loaded: false,
  loading: false,
  authenticated: false,
  expired: false,
  superAdmin: false,
  error: undefined,
};

export function reducer(state: UserState, action: NevisAdminAction<any>): UserState {

  switch (action.type) {
    case fromUserActions.UserActionTypes.LoginComplete: {
      return {
        ...state,
        userId: action.payload.userId,
        authenticated: !!(action.payload.user),
        user: action.payload.user,
        loading: false,
        loaded: true
      };
    }
    case fromUserActions.UserActionTypes.Login: {
      return {
        ...state,
        userId: initState.userId,
        error: initState.error,
        authenticated: initState.authenticated,
        loading: true,
        loaded: false
      };
    }

    case fromUserActions.UserActionTypes.LoginError : {
      return {
        ...state,
        userId: initState.userId,
        authenticated: initState.authenticated,
        error: action.payload,
        loading: false,
        loaded: true
      };
    }
    case fromUserActions.UserActionTypes.GetUserSuccess : {
      return {
        ...state,
        user: action.payload,
        loading: false,
        authenticated: !!(action.payload),
        loaded: true
      };
    }
    case fromUserActions.UserActionTypes.GetUserSuperAdminSuccess : {
      return {
        ...state,
        superAdmin: action.payload
      };
    }
    case fromUserActions.UserActionTypes.GetUserError: {
      return {
        ...state,
        user: initState.user,
        userId: initState.userId,
        authenticated: initState.authenticated,
        loaded: true
      };
    }
    case fromUserActions.UserActionTypes.SignOut : {
      return {
        ...state,
        user: initState.user,
        userId: initState.userId,
        authenticated: initState.authenticated,
        loaded: true,
        expired: state.loaded ? action.payload : false
      };
    }
    case fromUserActions.UserActionTypes.ClearUserError: {
      return {
        ...state,
        error: undefined,
      };
    }
    default:
      return state;
  }
}

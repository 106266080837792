import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { InventoryComponent } from './inventory.component';
import { InventoryEditorDeactivationGuard } from './inventory-editor-deactivation-guard.service';
import { InventoryMainComponent } from './inventory-main/inventory-main.component';
import { AuthenticationGuard } from '../common/guards/authentication.guard';
import { TenantGuard } from '../tenant/tenant.guard';
import { InventoryGuard } from './inventory.guard';
import { InventoryDeploymentHistoryComponent } from './inventory-deployment-history/inventory-deployment-history.component';
import { InventoryDeploymentHistoryGuard } from './inventory-deployment-history/inventory-deployment-history.guard';
import { InventoryKubernetesStatusComponentComponent } from './inventory-kubernetes-status/inventory-kubernetes-status-component.component';
import { InventoryKubernetesStatusGuard } from './inventory-kubernetes-status/inventory-kubernetes-status.guard';
import { InventoryStatusComponent } from './infrastructure-status/inventory-status.component';
import { InventoryDeploymentStatusGuard } from './infrastructure-status/inventory-deployment-status.guard';
import { InventorySettingsComponent } from './inventory-settings/inventory-settings.component';
import { InventorySettingsGuard } from './inventory-settings.guard';
import { NavigationConstants } from '../common/constants/navigation.constants';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'infrastructure/inventories',
        component: InventoryComponent,
        canActivate: [AuthenticationGuard, TenantGuard, InventoryGuard],
        children: [
          {
            path: '',
            component: InventoryMainComponent,
            canDeactivate: [InventoryEditorDeactivationGuard]
          }
        ]
      },
      {
        path: 'infrastructure/inventories/:inventoryKey',
        canActivate: [AuthenticationGuard, TenantGuard],
        canActivateChild: [AuthenticationGuard, TenantGuard, InventoryGuard],
        component: InventoryComponent,
        children: [
          {
            path: '',
            redirectTo: NavigationConstants.EDIT,
            pathMatch: 'full',
          },
          {
            path: NavigationConstants.EDIT,
            component: InventoryMainComponent,
            canDeactivate: [InventoryEditorDeactivationGuard]
          },
          {
            path: 'deployments',
            component: InventoryDeploymentHistoryComponent,
            canActivate: [InventoryDeploymentHistoryGuard]
          },
          {
            path: 'k8s-status',
            component: InventoryKubernetesStatusComponentComponent,
            canActivate: [InventoryKubernetesStatusGuard]
          },
          {
            path: 'inventory-status',
            component: InventoryStatusComponent,
            canActivate: [InventoryDeploymentStatusGuard]
          },
          {
            path: 'inventory-settings',
            component: InventorySettingsComponent,
            canActivate: [InventorySettingsGuard],
          }
        ]
      }
    ])
  ],
  providers: [
    InventoryEditorDeactivationGuard
  ]
})
export class InventoryRoutingModule {
}

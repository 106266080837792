import { Observable, of as observableOf } from 'rxjs';

import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EmptyAction, NevisAdminAction } from '../actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadProjectMeta, LoadProjectMetaSuccess, LoadRevision, LoadRevisionSuccess, VersionControlActionTypes } from './version-control.actions';
import { VersionControlService } from '../../version-control/version-control.service';
import { AppState } from '../reducer';
import { select, Store } from '@ngrx/store';
import { requireNonNull } from '../../common/utils/utils';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { projectKeyView } from '../views';
import * as _ from 'lodash';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { ActiveToast } from 'ngx-toastr';
import { Revision } from '../../version-control/revision/revision.model';
import { ProjectMeta } from '../../projects/project.model';

@Injectable()
export class VersionControlEffects {
   loadRevision: Observable<LoadRevisionSuccess | EmptyAction>;
   projectMeta: Observable<LoadProjectMetaSuccess | EmptyAction>;

  /**
   * As project meta is being constantly polled it can happen that on the server there is some error and we want to keep rest of application running
   * This field is reference to a toast that is constantly shown while meta is failing until it actually results in successful response
   */
  private projectMetaErrorToast: ActiveToast<void> | null = null;

  constructor(private versionCtrlService: VersionControlService,
              private actions$: Actions<NevisAdminAction<any>>,
              private store$: Store<AppState>,
              private modalNotificationService: ModalNotificationService,
              private toastNotificationService: ToastNotificationService) {
    this.loadRevision = createEffect(() => this.actions$
      .pipe(ofType(VersionControlActionTypes.LoadRevision))
      .pipe(
        map((action: LoadRevision) => action.payload),
        withLatestFrom(this.store$.pipe(select(projectKeyView))),
        mergeMap(([commitId, projectKey]) => {
          return this.versionCtrlService.getRevisionOfProjectCommit(requireNonNull(projectKey), commitId).pipe(
            map((revision: Revision) => new LoadRevisionSuccess(revision)),
            catchError((error) => {
              console.error(error);
              this.modalNotificationService.openErrorDialog({title: 'Error', description: 'Error when loading revision'});
              return observableOf(new EmptyAction());
            }));
        })
      ));

    this.projectMeta = createEffect(() => this.actions$
      .pipe(ofType(VersionControlActionTypes.LoadProjectMeta))
      .pipe(
        withLatestFrom(store$.pipe(select(projectKeyView))),
        filter(([, projectKey]: [LoadProjectMeta, string | null]) => !_.isNil(projectKey)),
        switchMap(([, projectKey]: [LoadProjectMeta, string]) => {
          return this.versionCtrlService.getProjectStatus(projectKey).pipe(
            // when project meta call resulted in successful call, hide project meta error toast
            tap(() => this.hideProjectMetaError()),
            map((projectMeta: ProjectMeta) => new LoadProjectMetaSuccess(projectMeta)),
            catchError(() => {
              // when project meta call fails, show the toast message about problem
              this.showProjectMetaError();
              return observableOf(new EmptyAction());
            }));
        })
      ));
  }

  // NOTE: a more generic solution maybe could applied for handling failing polling requests in a dedicated place
  // but since project meta polling is done in one place and actual backend call is done in a different place at the moment it's not possible to separate it
  // a bigger refactoring would be required in order to achieve that where those would use generic polling method which would include this default error handling
  private showProjectMetaError(): void {
    if (_.isNil(this.projectMetaErrorToast)) {
      this.projectMetaErrorToast = this.toastNotificationService.showPersistedErrorToast('An error happened while contacting the server. Displayed data may be outdated.');
    }
  }

  private hideProjectMetaError(): void {
    if (!_.isNil(this.projectMetaErrorToast)) {
      this.projectMetaErrorToast.toastRef.close();
      this.projectMetaErrorToast = null;
    }
  }

}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

import { MenuElement } from '../../menu-element';

@Component({
  selector: 'adm4-menu-action',
  template: `
    <ng-template #actionContent>
      <div class='menu-action-inner' matRipple matRippleColor="rgba(255 255 255 / .2)" (mouseenter)="setHover(true)" (mouseleave)="setHover(false)">
        <ng-container *ngIf='menuElement.useSvg; else fontIcon'>
          <mat-icon *ngIf="!isHovered" [svgIcon]='menuElement.icon' [ngbTooltip]="menuElement.title" class="svg-icon"></mat-icon>
          <mat-icon *ngIf="isHovered"  [svgIcon]='invertedIcon' [ngbTooltip]="menuElement.title" class="svg-icon"></mat-icon>
        </ng-container>
        <ng-template #fontIcon>
          <mat-icon class="font-icon" [ngbTooltip]="menuElement.title">{{menuElement.icon}}</mat-icon>
        </ng-template>
        <span class="label text-ellipsis">{{menuElement.title}}</span>
      </div>
    </ng-template>

    <ng-container *ngIf='hasTitle; else divider'>
      <ng-container *ngIf='(!menuElement.href || !(menuElement.href | async)); else useLink'>
        <button role="link" *ngIf='shouldBeDisplayed' class='menu-action focus-bg-light-grey'
              [class.selected]='isSelected'
              (click)='triggerEvent()'>
          <ng-container *ngTemplateOutlet="actionContent"></ng-container>
        </button>
      </ng-container>
      <ng-template #useLink>
        <a *ngIf='shouldBeDisplayed'
           [href]='menuElement.href | async' class='menu-action focus-bg-light-grey' [class.selected]='isSelected'>
          <ng-container *ngTemplateOutlet="actionContent"></ng-container>
        </a>
      </ng-template>
    </ng-container>
    <ng-template #divider>
      <mat-divider *ngIf='!menuElement.title && shouldBeDisplayed'></mat-divider>
    </ng-template>`,
  styleUrls: [
    './menu-action.component.scss'
  ],
  // not onPush, because the `displayCondition` and the `href` might change
})
export class MenuActionComponent implements OnChanges {
  @Input() menuElement: MenuElement;

  public invertedIcon: string | undefined;

  public hasTitle: boolean = false;
  public isHovered: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuElement) {
      this.hasTitle = !!this.menuElement?.title;
      this.invertedIcon = this.menuElement.invertedIcon?? this.menuElement.icon;
    }
  }

  get shouldBeDisplayed(): boolean {
    return !_.isFunction(this.menuElement.displayCondition) || this.menuElement.displayCondition();
  }

  get isSelected(): boolean {
    return _.isFunction(this.menuElement.selectedCondition) && this.menuElement.selectedCondition();
  }

  get isDisabled(): boolean {
    return _.isFunction(this.menuElement.disabledCondition) && this.menuElement.disabledCondition();
  }

  triggerEvent(): void {
    if (!_.isFunction(this.menuElement.event)) {
      return;
    }
    const disabled = _.isFunction(this.menuElement.disabledCondition) && this.menuElement.disabledCondition() ;
    if (disabled) {
      return;
    }
    this.menuElement.event();
  }

  public setHover(isHovered: boolean): void {
    this.isHovered = isHovered;
  }
}

import { BaseService } from '../../shared/base.service';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { UserData, UserToken } from '../../model/user/user.model';
import { ChangePasswordModel } from '../../username-menu/change-password.model';
import { catchError } from 'rxjs/operators';
import { AssignedPermission } from '../../model/permissions/permissions.model';

@Injectable()
export class UserService extends BaseService {

  public login(userKey: string, password: string): Observable<UserToken> {
    const url = '/login?tokenType=cookie';
    return this.httpPost(url, {
      userKey: userKey, password: password
    });
  }

  // no need data to send header is enough
  public getUserData(): Observable<UserData> {
    const url = '/me';
    return this.httpGet(url).pipe(
      catchError(() => {
        // SUPPORT CASE WHERE THERE IS NO AUTHENTICATION ON THE BACKEND
        // NEVISADMV4-3326
        return EMPTY;
      })
    );
  }

  public changeUserPassword(changePasswordModel: ChangePasswordModel): Observable<UserData> {
    const url = '/me/password';
    return this.httpPost(url, changePasswordModel);
  }

  /**
   *
   * @param userKey
   */
  public getUserPermissionsV2(userKey: string): Observable<AssignedPermission[]> {
    const url = `/users/${userKey}/permissions`;
    return this.httpGetUnwrapped(url);
  }

  public getAllUsers(): Observable<UserData[]> {
    const url = '/users';
    return this.httpGetUnwrapped(url);
  }

  public createUser(user: UserData): Observable<void> {
    const url = `/users`;
    return this.httpPost<UserData, void, void>(url, user);
  }

  public updateUser(user: Omit<UserData, 'password'>): Observable<void> {
    const url = `/users/${user.userKey}`;
    return this.httpPut<UserData, void, void>(url, user);
  }

  public deleteUser(userKey: string): Observable<void> {
    const url = `/users/${userKey}`;
    return this.httpDelete(url);
  }
}

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {TreeNodeTypeEnum} from '../tree-node-type.enum';

@Component({
  selector: 'adm4-deployment-resource-icon',
  template: `
      <ng-container [ngSwitch]='type'>
          <mat-icon *ngSwitchCase='TreeNodeTypeEnum.Host' class='tree-node-icon'>storage</mat-icon>
          <mat-icon *ngSwitchCase='TreeNodeTypeEnum.Pattern' class='tree-node-icon'>content_paste</mat-icon>
          <mat-icon *ngSwitchCase='TreeNodeTypeEnum.DeployablePattern' class='tree-node-icon'>content_paste</mat-icon>
          <mat-icon *ngSwitchCase='TreeNodeTypeEnum.CheckParent' class='tree-node-icon'>playlist_add_check</mat-icon>
          <mat-icon *ngSwitchCase='TreeNodeTypeEnum.CommandParent' class='tree-node-icon'>input</mat-icon>
          <mat-icon *ngSwitchCase='TreeNodeTypeEnum.Directory' class='tree-node-icon'>{{expanded ? 'folder_open' : 'folder'}}</mat-icon>
          <mat-icon *ngSwitchCase='TreeNodeTypeEnum.File' class='tree-node-icon'>insert_drive_file</mat-icon>
          <mat-icon *ngSwitchCase='TreeNodeTypeEnum.KubernetesCustomResources' class='tree-node-icon'>collections_bookmark</mat-icon>
          <mat-icon *ngSwitchCase='TreeNodeTypeEnum.KubernetesCustomResource' class='tree-node-icon'>book</mat-icon>
          <mat-icon *ngSwitchDefault class='tree-node-icon'>insert_drive_file</mat-icon>
      </ng-container>
  `,
  styleUrls: ['./deployment-resource-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeploymentResourceIconComponent {

  public readonly TreeNodeTypeEnum = TreeNodeTypeEnum;

  @Input()
  public type: TreeNodeTypeEnum;

  @Input()
  public expanded: boolean;
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeploymentItemStatusModel, Status } from './deployment-status.model';
import { GenerationStatusModel } from '../validate-deployment/generation-status.model';
import { ValidatingHelper } from './validating.helper';
import * as _ from 'lodash';
import { InventorySchemaType } from '../../inventory/inventory.model';

@Component({
  selector: 'adm4-validating',
  template: `
    <div class="full-height-flex">
      <div *ngIf='shouldShowValidating; else finishedValidation'
           class='validating'>
        <mat-spinner [diameter]='75'></mat-spinner>
          <div class='validating-details'>
            <h2 class='validating-text step-content-header'>{{validatingText}}</h2>
            <ng-container *ngIf='canShowOverallProgress'>
              <ng-container *ngTemplateOutlet='validatingDetailsNoSuccess'></ng-container>
            </ng-container>
          </div>
      </div>
      <ng-template #finishedValidation>
        <div *ngIf='!shouldShowValidating'
             class='validation-result'>
          <mat-icon *ngIf='isSuccessValidation' class='validation-icon done-icon'>done</mat-icon>
          <mat-icon *ngIf='hasErrorValidation' class='validation-icon error-icon'>error_outline</mat-icon>
          <div class='validating-details'>
            <h2 class='validating-text step-content-header'>{{validationDoneText}}</h2>
            <ng-container *ngTemplateOutlet='hasErrorValidation ? validatingDetailsNoSuccess : validatingDetailsSuccess'></ng-container>
          </div>
        </div>
      </ng-template>
      <div class="remaining-space-flex-content-wrapper">
        <div class="remaining-space-flex-content">
          <adm4-validating-items [validationItems]='status?.items'
                                 [selectedInventorySchemaType]='selectedInventorySchemaType'></adm4-validating-items>
        </div>
      </div>
    </div>
    <ng-template #validatingDetailsSuccess>
      <ng-content class='validating-text' select='.success'></ng-content>
    </ng-template>
    <ng-template #validatingDetailsNoSuccess>
      <div class='validating-text'>
        <span>Pending: {{pendingItems?.length}} </span>
        <span>Failed: {{failedItems?.length}} </span>
        <span>Done: {{doneItems?.length}} </span>
      </div>
    </ng-template>
  `,
  styleUrls: ['./validating.component.scss']
})
export class ValidatingComponent implements OnChanges {
  @Input() validatingText: string;
  @Input() validationDoneText: string;
  @Input() status: GenerationStatusModel;
  @Input() selectedInventorySchemaType?: InventorySchemaType;

  pendingItems: DeploymentItemStatusModel[];
  doneItems: DeploymentItemStatusModel[];
  failedItems: DeploymentItemStatusModel[];

  static filterItems(items: DeploymentItemStatusModel[], filterCondition: (item) => boolean): DeploymentItemStatusModel[] {
    return _.filter(items, item => filterCondition(item));
  }

  get canShowOverallProgress(): boolean {
    return !_.isEmpty(this.pendingItems) || !_.isEmpty(this.doneItems) || !_.isEmpty(this.failedItems);
  }

  get isSuccessValidation(): boolean {
    return ValidatingHelper.isAllSuccess(this.status);
  }

  get hasErrorValidation(): boolean {
    return ValidatingHelper.isAnyFailed(this.status);
  }

  get shouldShowValidating(): boolean {
    return !this.status || (!ValidatingHelper.isItemDone(this.status.status) && !ValidatingHelper.isItemFailed(this.status.status));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasStatusChanged(changes, this.status)) {
      this.pendingItems = ValidatingComponent.filterItems(this.status.items, this.pendingCondition);
      this.doneItems = ValidatingComponent.filterItems(this.status.items, item => item.status === Status.Done);
      this.failedItems = ValidatingComponent.filterItems(this.status.items, item => item.status === Status.Failed);
    }
  }

  hasStatusChanged(changes: SimpleChanges, status: GenerationStatusModel): boolean {
    return !!changes.status && !!status;
  }

  pendingCondition(item: DeploymentItemStatusModel): boolean {
    return item.status === Status.Running || item.status === Status.Pending;
  }
}

import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { Diff } from '../../../common/model/publish-changes/diff.model';
import { TextDiffData } from '../../../common/model/publish-changes/text-diff-data.model';
import { TextDiffWrapperContext } from './text-diff-wrapper.model';

@Component({
  selector: 'adm4-text-diff-wrapper',
  template: `

      <div class='full-height-flex'>
          <div class='diff-view-header diff-with-editor-header'>
              <div class='remote'>
                  Remote project <span *ngIf='currentBranch$ | async'>({{currentBranch$ | async}})</span>
              </div>
              <div class='local'>
                  Local project
              </div>
          </div>
          <div class='remaining-space-flex-content-wrapper'>
              <div class='remaining-space-flex-content'>
                  <adm4-file-content-diff *ngIf='textDiff$ | async'
                      [fileContentDiff]='textDiff$ | async'></adm4-file-content-diff>
              </div>
          </div>
      </div>
  `,
  styleUrls: ['../../../common/styles/component-specific/diff-view-header.scss'],
})
export class TextDiffWrapperComponent {

  textDiff$: Observable<Diff<TextDiffData> | null>;
  currentBranch$: Observable<string | null>;

  constructor(private context: TextDiffWrapperContext) {
    this.textDiff$ = this.context.textDiff$;
    this.currentBranch$ = this.context.currentBranch$;
  }

}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Diff } from '../../../../common/model/publish-changes/diff.model';
import { PatternLinkDiffData } from '../pattern-diff-data.model';

@Component({
  selector: 'adm4-pattern-link-diff',
  template: `
    <adm4-diff-view-element [remoteCommitInfo]='patternLinkDiff.remote.commitInfo'
                            [localChangeInfo]='patternLinkDiff.local.changeInfo'
                            [localFallbackLabel]='patternLinkDiff.local.fallbackLabel'
                            [currentUsername]='currentUsername'
                            [showOnlyDifferences]='showOnlyDifferences'
                            [boxed]='true'
    >
      <div remote *ngIf='patternLinkDiff.remote.link'>
        <div class='pr_container info-indicator'>
          <div class='pr-heading'>
            <label class='pr-label' for='copyDetails'>Copy details</label>
          </div>
          <div class="pattern-copy-description">
            <span id='copyDetails'>This pattern was copied from {{patternLinkDiff.remote.link?.sourceProjectKey | cropTenantFromKey}} by {{patternLinkDiff.remote.link?.author}}, {{patternLinkDiff.remote.link?.lastCopied | customDate}}.</span>
          </div>
        </div>
      </div>
      <div local *ngIf='patternLinkDiff.local.link'>
        <div class='pr_container info-indicator'>
          <div class='pr-heading'>
            <label class='pr-label' for='copyDetails'>Copy details</label>
          </div>
          <div class="pattern-copy-description">
            <span id='copyDetails'>This pattern was copied from {{patternLinkDiff.local.link?.sourceProjectKey | cropTenantFromKey}} by {{patternLinkDiff.local.link?.author}}, {{patternLinkDiff.local.link?.lastCopied | customDate}}.</span>
          </div>
        </div>
      </div>
    </adm4-diff-view-element>
  `,
  styleUrls: ['./pattern-link-diff.component.scss', '../../../../property-widgets/widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternLinkDiffComponent {
  @Input() patternLinkDiff: Diff<PatternLinkDiffData>;
  @Input() showOnlyDifferences: boolean;
  @Input() currentUsername: string | null;
}

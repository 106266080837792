import * as _ from 'lodash';
import { KubernetesCertificateWrapper, ResourceWrapperWithUsage, SecretResourceWrapperWithUsage, SecretWrapperWithUsage, TenantResourceFlag } from '../../inventory/inventory.model';
import { InventoryService } from '../../inventory/inventory.service';
import { Observable, of } from 'rxjs';
import { requireNonNull } from '../../common/utils/utils';
import { FileService } from '../../common/services/file/file.service';

export class SecretManagementHelper {
  static isTenantScopedSelection(selectedScope: string, selectedTenantKey: string): boolean {
    return _.isEqual(selectedScope, selectedTenantKey);
  }

  static getSecretResourceContent(inventoryService: InventoryService, secretItem: ((SecretWrapperWithUsage) & TenantResourceFlag)): Observable<string> {
    return secretItem.isTenantScoped ? inventoryService.getTenantSecretContent(secretItem.scope, requireNonNull(secretItem?.secretId)) : inventoryService.getInventorySecretContent(secretItem.scope, secretItem.secretId);
  }

  static getResourceFileContent(fileService: FileService, resourceItem: ((SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage | KubernetesCertificateWrapper) & TenantResourceFlag)): Observable<string> {
    const targetFileUrl = this.getResourceContentUrlByType(resourceItem);
    return fileService.loadFileContent(requireNonNull(targetFileUrl));
  }

  static getResourceContentUrlByType(resourceItem: ((SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage | KubernetesCertificateWrapper) & TenantResourceFlag)): string | undefined {
    const levelPrefix = resourceItem?.isTenantScoped ? '/tenants/' : '/inventories/';
    if ('secretId' in resourceItem) {
      return `${levelPrefix}${resourceItem.scope}/secrets/${resourceItem.secretId}`;
    } else if ('secretResourceId' in resourceItem) {
      return `${levelPrefix}${resourceItem.scope}/secret-resources/${resourceItem.secretResourceId}`;
    } else if ('resourceId' in resourceItem) {
      return `${levelPrefix}${resourceItem.scope}/resources/${resourceItem.resourceId}`;
    } else if ('kubernetesSecretKey' in resourceItem) {
      return `/inventories/${resourceItem.scope}/kubernetes-certificates/${resourceItem.kubernetesSecretKey}`;
    }
    return undefined;
  }

  static getInventoryResourceDeletionFunction(inventoryService: InventoryService, resourceItem: ((SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag)): Observable<any | undefined> {
    if ('secretId' in resourceItem) {
      return resourceItem.isTenantScoped ? inventoryService.deleteTenantSecret(resourceItem.scope, resourceItem.secretId) : inventoryService.deleteInventorySecret(resourceItem.scope, resourceItem.secretId);
    } else if ('secretResourceId' in resourceItem) {
      return resourceItem.isTenantScoped ? inventoryService.deleteTenantSecretResource(resourceItem.scope, resourceItem.secretResourceId)  : inventoryService.deleteInventorySecretResource(resourceItem.scope, resourceItem.secretResourceId);
    } else if ('resourceId' in resourceItem) {
      return resourceItem.isTenantScoped ? inventoryService.deleteTenantResource(resourceItem.scope, resourceItem.resourceId)  : inventoryService.deleteInventorResource(resourceItem.scope, resourceItem.resourceId);
    }
    return of(undefined);
  }

  static getInventoryResourceReplacementFunction(inventoryService: InventoryService, resourceItem: ((SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag), file: File, description?: string): Observable<void> {
    if ('secretResourceId' in resourceItem) {
      return resourceItem.isTenantScoped ? inventoryService.updateTenantSecretResource(resourceItem.scope, resourceItem.secretResourceId, file, description) : inventoryService.updateInventorySecretResource(resourceItem.scope, resourceItem.secretResourceId, file, description);
    } else if ('resourceId' in resourceItem) {
      return resourceItem.isTenantScoped ? inventoryService.updateTenantResource(resourceItem.scope, resourceItem.resourceId, file, description) : inventoryService.updateInventoryResource(resourceItem.scope, resourceItem.resourceId, file, description);
    }
    return of(undefined);
  }

  static getInventoryResourceDescriptionChangeFunction(inventoryService: InventoryService, resourceItem: ((SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag), description: string): Observable<void> {
    if ('secretResourceId' in resourceItem) {
      return resourceItem.isTenantScoped
          ? inventoryService.patchTenantSecretResource(resourceItem.scope, resourceItem.secretResourceId, description)
          : inventoryService.patchInventorySecretResource(resourceItem.scope, resourceItem.secretResourceId, description);
    } else if ('resourceId' in resourceItem) {
      return resourceItem.isTenantScoped
          ? inventoryService.patchTenantResource(resourceItem.scope, resourceItem.resourceId, description)
          : inventoryService.patchInventoryResource(resourceItem.scope, resourceItem.resourceId, description);
    }
    return of(undefined);
  }
}

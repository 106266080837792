import {  Subject } from 'rxjs';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { UserService } from '../user/login/user.service';
import { HTTP_STATUS_UNPROCESSABLE_ENTITY } from '../shared/http-status-codes.constants';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ChangePasswordModel } from './change-password.model';
import { ErrorHelper } from '../common/helpers/error.helper';
import { PasswordErrorFieldSource } from './password.constants';
import { UserHelper } from './user.helper';
import { UserStateService } from '../common/services/user/user.state.service';
import { UserData } from '../model/user/user.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'adm4-change-password-dialog',
  template: `
    <form [formGroup]="changePasswordForm" name="form" #changePasswordF (ngSubmit)='changePasswordForm.valid && changePassword()'>
      <div mat-dialog-content class='change-password-container'>
        <div *ngIf='shouldShowPasswordChangeWarning()' class='change-password-warning-content'>
          <span>
            <label class='input-label warning-title'>
              <span class='warning-icon-container'>
                 <mat-icon class='warning-icon'>report_problem</mat-icon>
              </span>
              <span>
                <strong>{{ADMIN_PASSWORD_CHANGE_WARNING_TITLE}}</strong><br/>
                The admin password is also used for encryption purposes. In the event you lose files (key material) on your nevisAdmin system, you use this
                <strong>critical recovery password</strong> to access encrypted secrets again. If you lose the files AND the password, all your secrets will be lost.
              </span>
            </label>
            </span>
        </div>
        <div>
          <label class='input-label'>Old password*</label>
          <div class='form-group admn4-input-group-with-icon'>
            <input class='admn4-text-input form-control'
                   [type]="hideOld ? 'password' : 'text'" required
                   [formControlName]='OLD_PASSWORD'
                   autocomplete="off">
            <mat-icon matSuffix (click)="hideOld = !hideOld" class='input-icon visibility-icon admn4-button-ellipse-blue'>{{hideOld ? 'visibility' : 'visibility_off'}}</mat-icon>
          </div>
          <div class='validation-message-container'>
            <adm4-validation-message *ngIf="oldPasswordInvalid" [isError]='true' [message]='oldPasswordError'></adm4-validation-message>
            <adm4-validation-message *ngIf="oldPasswordEmpty" [isError]='true' [message]='EMPTY_PASSWORD_FIELD'></adm4-validation-message>
          </div>
        </div>
        <div>
          <label class='input-label'>New password*</label>
          <div class='form-group admn4-input-group-with-icon'>
            <input class='admn4-text-input form-control' [type]="hideNew ? 'password' : 'text'" required [formControlName]='NEW_PASSWORD' autocomplete="off">
            <mat-icon matSuffix (click)="hideNew = !hideNew" class='input-icon visibility-icon admn4-button-ellipse-blue'>{{hideNew ? 'visibility' : 'visibility_off'}}</mat-icon>
          </div>
          <div class='validation-message-container'>
            <adm4-validation-message *ngIf="newPasswordInvalid" [isError]='true' [message]='newPasswordError'></adm4-validation-message>
            <adm4-validation-message *ngIf="newPasswordEmpty" [isError]='true' [message]='EMPTY_PASSWORD_FIELD'></adm4-validation-message>
          </div>
        </div>
        <div>
          <label class='input-label'>Confirm new password*</label>
          <div class='form-group admn4-input-group-with-icon'>
            <input class='admn4-text-input form-control' [type]="hideConfirm ? 'password' : 'text'" required [formControlName]='CONFIRM_NEW_PASSWORD' autocomplete="off">
            <mat-icon matSuffix (click)="hideConfirm = !hideConfirm" class='input-icon visibility-icon admn4-button-ellipse-blue'>{{hideConfirm ? 'visibility' : 'visibility_off'}}</mat-icon>
          </div>
          <div class='validation-message-container'>
            <adm4-validation-message *ngIf="showConfirmPasswordMismatchErrorMessage" [isError]='true' [message]='ERROR_PASSWORDS_DIFFER'></adm4-validation-message>
            <adm4-validation-message *ngIf="confirmPasswordEmpty" [isError]='true' [message]='EMPTY_PASSWORD_FIELD'></adm4-validation-message>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <adm4-button-bar [isSubmitDisabled]="changeDisabled"
                         submitButtonText='Change password'
                         (cancelClicked)='cancelClicked.emit()'></adm4-button-bar>
      </div>
    </form>
  `,
  styleUrls: ['./change-password-dialog.component.scss', '../common/styles/component-specific/modal-window.scss']
})
export class ChangePasswordDialogComponent implements OnInit, OnDestroy {
  @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
  @Output() passwordChanged: EventEmitter<any> = new EventEmitter();

  changePasswordForm: UntypedFormGroup;
  OLD_PASSWORD = 'oldPassword';
  CONFIRM_NEW_PASSWORD = 'confirmNewPassword';
  NEW_PASSWORD = 'newPassword';
  hideOld = true;
  hideNew = true;
  hideConfirm = true;
  oldPasswordInvalid = false;
  newPasswordInvalid = false;
  validateOldPassword = false;
  validateNewPassword = false;
  validateConfirmPassword = false;
  private readonly currentUser: UserData | null;

  readonly EMPTY_PASSWORD_FIELD = 'This field is required.';
  readonly ERROR_PASSWORDS_DIFFER = 'Passwords do not match.';
  readonly ADMIN_PASSWORD_CHANGE_WARNING_TITLE = 'Copy the new admin password to a safe place.';
  private oldPasswordError: string;
  private newPasswordError: string;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private fb: UntypedFormBuilder, private userService: UserService, private userStateService: UserStateService) {
    this.currentUser = this.userStateService.currentUser;
  }

  ngOnInit(): void {
    this.changePasswordForm = this.createFormGroup();

    // We only want to validate after the user stopped typing for 1 sec.
    const oldPasswordCtrl = this.changePasswordForm.controls['oldPassword'];
    oldPasswordCtrl.valueChanges.pipe(
      takeUntil(this.destroyed$),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(() => {
      this.validateOldPassword = !!oldPasswordCtrl.errors;
      this.oldPasswordInvalid = false;
    });

    const newPasswordCtrl = this.changePasswordForm.controls['newPassword'];
    const confirmPasswordCtrl = this.changePasswordForm.controls['confirmNewPassword'];
    newPasswordCtrl.valueChanges.pipe(
      takeUntil(this.destroyed$),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(() => {
      this.validateNewPassword = !!newPasswordCtrl.errors;
      this.validateConfirmPassword = !!confirmPasswordCtrl.errors;
      this.newPasswordInvalid = false;
    });

    confirmPasswordCtrl.valueChanges.pipe(
      takeUntil(this.destroyed$),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(() => {
      this.validateConfirmPassword = !!confirmPasswordCtrl.errors;
    });
  }

  shouldShowPasswordChangeWarning(): boolean {
    return UserHelper.isAdminUser(this.currentUser);
  }

  showFormControlErrorMessage(formControl: string, errorKey: string): boolean {
    const control = this.changePasswordForm.controls[formControl];
    return control.dirty && !_.isNil(control.errors) && control.errors[errorKey];
  }

  get showConfirmPasswordMismatchErrorMessage(): boolean  {
    return !this.confirmPasswordEmpty && this.showFormControlErrorMessage(this.CONFIRM_NEW_PASSWORD, 'passwordsNotSame') && this.validateConfirmPassword;
  }

  get oldPasswordEmpty(): boolean {
    return this.showFormControlErrorMessage(this.OLD_PASSWORD, 'required');
  }

  get newPasswordEmpty(): boolean {
    return this.showFormControlErrorMessage(this.NEW_PASSWORD, 'required');
  }

  get confirmPasswordEmpty(): boolean {
    return this.showFormControlErrorMessage(this.CONFIRM_NEW_PASSWORD, 'required');
  }

  get changeDisabled(): boolean {
    return this.changePasswordForm.invalid || this.oldPasswordInvalid;
  }

  changePassword(): void {
    const changePass: ChangePasswordModel = {
      oldPassword: this.changePasswordForm.value[this.OLD_PASSWORD],
      newPassword: this.changePasswordForm.value[this.NEW_PASSWORD]
    };
    this.userService.changeUserPassword(changePass).subscribe(() => {
      this.passwordChanged.emit();
    }, (errorResponse: HttpErrorResponse) => {
      if (errorResponse.status === HTTP_STATUS_UNPROCESSABLE_ENTITY) {
        this.oldPasswordInvalid = ErrorHelper.hasSourceInStatus(errorResponse, {FIELD: PasswordErrorFieldSource.OldPassword});
        this.oldPasswordError = ErrorHelper.getErrorDetailFromStatus(errorResponse, {FIELD: PasswordErrorFieldSource.OldPassword});
        this.newPasswordInvalid = ErrorHelper.hasSourceInStatus(errorResponse, {FIELD: PasswordErrorFieldSource.NewPassword});
        this.newPasswordError = ErrorHelper.getErrorDetailFromStatus(errorResponse, {FIELD: PasswordErrorFieldSource.NewPassword});
      }
    });
  }

  confirmedPassWordMatchValidator(): ValidatorFn {
    return (group: UntypedFormGroup) => {
      const newPasswordControl = group.controls[this.NEW_PASSWORD];
      const confirmNewPasswordControl = group.controls[this.CONFIRM_NEW_PASSWORD];
      if (confirmNewPasswordControl.errors && !confirmNewPasswordControl.errors.passwordsNotSame) {
        return null;
      }
      if (newPasswordControl.value !== confirmNewPasswordControl.value) {
        confirmNewPasswordControl.setErrors({passwordsNotSame: true});
      } else {
        confirmNewPasswordControl.setErrors(null);
      }
      return null;
    };
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    group.addControl(this.OLD_PASSWORD, this.fb.control('', Validators.required));
    group.addControl(this.NEW_PASSWORD, this.fb.control('', [Validators.required]));
    group.addControl(this.CONFIRM_NEW_PASSWORD, this.fb.control('', [Validators.required]));
    group.setValidators(this.confirmedPassWordMatchValidator());
    return group;
  }
}

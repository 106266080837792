import { IssueModel } from '../common/model/issue.model';
import { Dictionary } from '../model/reducer';
import { VariableMetaInfo } from '../version-control/variable-meta-info.model';

export class VariableModel {
  variableKey: string;
  className: string;
  bundleKey: string;
  parameters: Dictionary<any>;
  value?: any;
  requireOverloading: boolean;
}

export class VersionedVariableModel extends VariableModel {
  _meta: VariableMetaInfo;
}

export const REQUIRE_OVERLOADING_POSTFIX = 'require-overload';

export class VariableListItem {
    constructor(public variable: VariableModel,
                public issues: IssueModel[]) {}
}

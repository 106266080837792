import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { CollapsiblePaneMixin, ICollapsiblePaneMixin } from '../../common/mixins/collapsible-pane.mixin';

@Component({
  selector: 'adm4-issues-help',
  templateUrl: './issues-help.component.html',
  styleUrls: ['./issues-help.component.scss', '../../common/styles/component-specific/help.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Mixin([CollapsiblePaneMixin])
export class IssuesHelpComponent implements ICollapsiblePaneMixin {
  @Input() collapseLocalStorageKey: string;
  @Output() collapse: EventEmitter<boolean> = new EventEmitter();

  /**
   * Implemented by CollapsiblePaneMixin
   */
  setCollapse: (isCollapsed: boolean) => void;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SetVariableData } from './set-variable-data.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SetVariableHelper } from './set-variable.helper';
import { CreateVariableOutputData } from './create-variable-output-data.model';
import { VariableModel } from '../variables/variable.model';
import * as _ from 'lodash';
import { VariablesHelper } from '../variables/variables.helper';
import { VariableValidators } from '../variables/variable.validators';
import { VariableContext } from './variable-context.service';
import { PropertyWidgetContext } from '../property-widgets/property-widget.context';
import {Dictionary} from '../model/reducer';
import {PropertyType} from '../plugins/property-type.model';

@Component({
  selector: 'adm4-create-variable',
  templateUrl: './set-variable.component.html',
  styleUrls: ['./set-variable.component.scss', '../common/styles/component-specific/modal-window.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: PropertyWidgetContext, useValue: VariableContext}]
})
export class SetVariableComponent implements OnInit {

  @Input() createVariableData: SetVariableData;
  @Output() variableCreate: EventEmitter<CreateVariableOutputData> = new EventEmitter();
  @Output() variableSelect: EventEmitter<string> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  form: UntypedFormGroup;
  selectedVariable: VariableModel | null;
  variablesForSelection: VariableModel[] = [];
  @Input() variableTypes: Dictionary<PropertyType> | null;

  readonly variableNameFormControlName = 'variable_name';
  readonly variableValueFormControlName = 'variable_value';

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.createForm();
    const variableData = this.createVariableData;
    const variableTypes = this.variableTypes;

    if (_.isNil(variableData.patternTypeProperty) || _.isNil(variableTypes)) {
      return;
    }

    this.variablesForSelection = variableData.existingVariables.filter((variable: VariableModel) => {
      return VariablesHelper.checkUiComponentEquality(variableData, variable, variableTypes);
    });
    this.form.controls[this.variableNameFormControlName].setValue(SetVariableHelper.createDefaultVariableName(variableData.patternName, variableData.patternTypeProperty, this.variablesForSelection));
  }

  get createVariableInputDisabled(): boolean | undefined {
    return _.isNil(this.selectedVariable) ? undefined : true;
  }

  get isVariableDropdownShown(): boolean {
    return !_.isEmpty(this.variablesForSelection);
  }

  get canVariableDefineSampleValueonCreation(): boolean {
    return VariablesHelper.canVariableHaveSampleValue(this.createVariableData.variableType) && !VariablesHelper.isSecretVariable(this.createVariableData.patternTypeProperty);
  }

  shouldShowVariableNameValidationIssue(errorKey: string): boolean {
    const variableNameFormControl = this.form.controls[this.variableNameFormControlName];
    return variableNameFormControl.dirty && !_.isNil(variableNameFormControl.errors) && variableNameFormControl.errors[errorKey];
  }

  createForm(): UntypedFormGroup {
    const form = this.fb.group({});
    const defaultVariableName = SetVariableHelper.combineDefaultVariableName(this.createVariableData.patternName, this.createVariableData.patternTypeProperty);
    const defaultVariableValue = SetVariableHelper.getDefaultVariableValue(this.createVariableData);
    form.addControl(this.variableNameFormControlName, this.fb.control(defaultVariableName, [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9_-]*'),
      VariableValidators.VariableKey.variableWithSuchKeyExists(this.createVariableData.existingVariables)
    ]));
    form.addControl(this.variableValueFormControlName, this.fb.control(defaultVariableValue));
    return form;
  }

  selectVariable(variable: VariableModel | null): void {
    this.selectedVariable = variable;
    // if form is errored when we select variable we should hide the error and allow saving
    // when variable is removed we should put errors back
    if (_.isNil(variable)) {
      _.values(this.form.controls).forEach(control => control.updateValueAndValidity());
    } else {
      _.values(this.form.controls).forEach(control => control.setErrors(null));
    }
  }

  onFormSubmit(): void {
    if (_.isNil(this.selectedVariable)) {
      const variableValue = this.form.value[this.variableValueFormControlName];
      this.variableCreate.emit({
        variableName: this.form.value[this.variableNameFormControlName].trim(),
        value: VariablesHelper.getNormalizedVariableValue(variableValue, this.createVariableData.variableType)
      });
    } else {
      this.variableSelect.emit(this.selectedVariable.variableKey);
    }
  }

  get variableType() {
    return this.createVariableData.variableType;
  }

  get isSecret() {
    return VariablesHelper.isSecretVariable(this.createVariableData.patternTypeProperty);
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { editor } from 'monaco-editor';
import { DiffEditorModel } from 'ngx-monaco-editor-v2/lib/types';

import { Diff } from '../../model/publish-changes/diff.model';
import { TextDiffData } from '../../model/publish-changes/text-diff-data.model';

const EDITOR_LANGUAGE = 'text';

/**
 * This component serves purpose of isolating change detection of monaco editor in bundles diff view
 * Reason: Even despite being inside OnPush component, the change detection being triggered from within the component itself would make monaco editor rerender with each change detection cycle
 */
@Component({
  selector: 'adm4-file-content-diff-editor',
  template: `
    <div class='diff-editor-container full-height' #editorContainer>
      <ngx-monaco-diff-editor [modifiedModel]='modifiedModel'
                              [originalModel]='originalContent'
                              [options]='editorOptions'
                              (onInit)='initializeEditor($event)'></ngx-monaco-diff-editor>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileContentDiffEditorComponent implements OnChanges {
  @Input() fileContentDiff: Diff<TextDiffData>;
  @ViewChild('editorContainer', {static: false}) editorContainer: ElementRef;

  modifiedModel: DiffEditorModel;
  originalContent: DiffEditorModel;

  editorOptions: editor.IDiffEditorOptions = {
    readOnly: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    },
    enableSplitViewResizing: false,
    automaticLayout: true
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileContentDiff) {
      this.modifiedModel = {
        code: this.fileContentDiff.local.content.text,
        language: EDITOR_LANGUAGE,
      };
      this.originalContent = {
        code: this.fileContentDiff.remote.content.text,
        language: EDITOR_LANGUAGE,
      };
    }
  }

  initializeEditor(initEditor: any): void {
    initEditor.layout({
      width: this.editorContainer.nativeElement.clientWidth,
      height: this.editorContainer.nativeElement.clientHeight
    });
  }
}

import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: '[adm4ControlDisable]'
})
export class ControlDisableDirective {

  /**
   * Purpose of this directive is to provide declarative way of disabling input fields of reactive forms, as using [disabled] leads to warnings
   * @param condition
   */
  @Input() set adm4ControlDisable(condition: boolean) {
    // setTimeout is used to put it next change detection cycle, because @Input inside one that is already running, disabling inputs in it means that it can lead to expression checked blabla error on forms
    setTimeout(() => {
      if (_.isNil(this.ngControl) || _.isNil(this.ngControl.control)) {
        return;
      }
      if (condition) {
        this.ngControl.control.disable();
      } else {
        this.ngControl.control.enable();
      }
    });
  }

  constructor(private ngControl: NgControl) {
  }

}

import { Component, ElementRef, forwardRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { first, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, from, Observable, Subject } from 'rxjs';

import * as _ from 'lodash';

import { DeletePatternResource, PatternActionTypes, SavePatternResource } from '../../model/pattern';
import { Dictionary, ProjectKey } from '../../model/reducer';
import { WidgetComponent } from '../widget.component';
import { NevisAdminAction } from '../../model/actions';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { resourceReferencePrefix } from '../../common/constants/reference-prefix.constants';
import { PropertyWidgetContext } from '../property-widget.context';
import { LocalStatus } from '../../version-control/meta-info.model';
import { Project } from '../../projects/project.model';
import { ProjectHelper } from '../../projects/project.helper';
import { DeleteAttachmentObj, ResourceItem, ResourceList, SaveAttachmentObj } from '../../patterns/pattern-attachment.model';
import { PropertyWidgetContextType } from '../property-widget-context-type.enum';
import { FileDownloader } from '../../common/helpers/file-downloader';
import { PatternFileEditorDialogComponent, PatternFileEditorDialogPayload, PatternFileEditorDialogResult } from '../pattern-file-editor/pattern-file-editor-dialog.component';
import { PatternService } from '../../patterns/pattern.service';
import { isASCIIContent, isBinaryByExtension } from '../../file-utils';
import { Maybe } from '../../common/utils/utils';

const ACTIONS_TO_DISPATCH_FC_NAME = '#actionsToDistpach';

@Component({
  selector: 'adm4-attachment-property',
  template: `
    <div [formGroup]='group' class='input-field-container'>
      <ng-container *ngIf="!shouldShowSecretWarningMessage; else secretWarning">
        <div *ngIf='!readOnly' class='file-uploader' (click)='triggerFileUpload()'>
          <input placeholder='Upload a file...'
                 class='admn4-text-input form-control file-visible-input'
                 (focus)='onSelect($event, this)'
                 (blur)='setTouchedFlag()'
                 (keyup.enter)='triggerFileUpload()'
          />
          <input placeholder='Upload a file...'
                 class='admn4-text-input form-control'
                 type='file' multiple
                 [hidden]='true'
                 [attr.data-resource-list-count]='resourceListCount$ | async'
                 (change)="onNewFileUpload($event, fileInput.dataset.resourceListCount)"
                 [name]='widgetProperty.propertyKey'
                 [id]='widgetProperty.propertyKey'
                 #fileInput/>
          <i class='material-icons attach-file-icon form-control' *ngIf='!readOnly'>attach_file</i>
          <ng-template #limitReached><span>The number of files has reached the allowed limit ({{maxAllowed}}) for this property.</span></ng-template>
          <span [ngbTooltip]="limitReached" [disableTooltip]="!(canAddOneMore$ | async)">
            <button mat-button class="add-new-file" type="button" (click)="onCreateNewFile($event)"
                [disabled]="canAddOneMore$ | async">
              <mat-icon>note_add</mat-icon><span>Create file...</span>
            </button>
          </span>
        </div>
        <adm4-attachment-property-list [propertyKey]='propertyKey'
                                       [newAttachmentList]="newAttachmentList"
                                       (removeFileItem)="removeNewAttachment($event)"
                                       (deleteResource)='deleteResource($event, deleteResourceList)'
                                       (downloadResource)="downloadResource($event)"
                                       (editResource)="editResource($event)"
                                       [patternResourceItemList]='resourceList$ | async'
                                       [deleteResourceList]='deleteResourceList'
                                       [readOnly]='readOnly'
                                       [publish]='publish'
                                       [local]='local'
                                       [versioned]='versioned$ | async'
        >
        </adm4-attachment-property-list>
      </ng-container>
      <ng-template #secretWarning>
        Please set a variable for this property. To define the value, edit the inventory and insert a secret (Attach files).
      </ng-template>
    </div>
  `,
  styleUrls: ['../widget.component.scss', './attachment-property.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AttachmentPropertyComponent),
    multi: true
  }]
})
export class AttachmentPropertyComponent extends WidgetComponent implements OnInit {

  private attachmentsChanged: Subject<void> = new Subject<void>();

  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  projectKey$: Observable<ProjectKey | null>;
  versioned$: Observable<boolean>;
  private actionControl: AbstractControl;
  public propertyKey: string;

  newAttachmentList: SaveAttachmentObj[] = [];
  deleteResourceList: DeleteAttachmentObj[];
  resourceList$: Observable<ResourceItem[]>;
  resourceListCount$: Observable<number>;
  canAddOneMore$: Observable<boolean>;

  constructor(private modalNotificationService: ModalNotificationService,
              private patternContext: PropertyWidgetContext,
              private patternService: PatternService,
              private matDialog: MatDialog,
              private toast: ToastNotificationService,
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.projectKey$ = this.patternContext.currentProject$.pipe(map((project: Project | null) => _.isNil(project) ? null : project.projectKey));
    this.versioned$ = this.patternContext.currentProject$.pipe(map((project: Project | null) => !_.isNil(project) && ProjectHelper.isVersionedProject(project)));
    this.propertyKey = this.widgetProperty.propertyKey;
    if (!this.readOnly) {
      this.setFormControl();
    }
    this.resourceList$ = this.getResourceList$();
    this.resourceListCount$ = this.resourceList$.pipe(map((resourceList: ResourceItem[]) => resourceList.length));
    this.canAddOneMore$ = combineLatest([
      this.resourceList$,
      this.attachmentsChanged.pipe(startWith(undefined)),
    ]).pipe(
        // checking if the limit would be reached with the new file
        map(([resourceList, _changed]: [ResourceItem[], void]): boolean => this.chkMaxAttachmentReached(1, resourceList.length)),
        shareReplay(1),
    );
  }

  /*
    If we are in Publish more, the resource list will be loaded from state.pattern.patternAttachmentsMeta
    In pattern editor mode it will fetch the data from state.pattern.patternAttachments
   */
  private getResourceList$(): Observable<ResourceItem[]> {
    return this.patternContext.allPatternResources$.pipe(
      map((allPatternResources: Dictionary<Dictionary<ResourceList>>) => {
        if (_.isNil(allPatternResources[this.patternId]) || _.isNil(allPatternResources[this.patternId][this.propertyKey]) || !this.hasReferenceToAttachments) {
          return [];
        }
        const resourceItemsMeta = allPatternResources[this.patternId][this.propertyKey]._meta;
        const resourceItems: ResourceItem[] = _.isEmpty(resourceItemsMeta) || _.isNil(resourceItemsMeta) ? allPatternResources[this.patternId][this.propertyKey].items : resourceItemsMeta;
        return this.publish ? resourceItems : resourceItems.filter(item => item.localStatus !== LocalStatus.Deleted);
      })
    );
  }

  triggerFileUpload(): void {
    if (!this.readOnly) {
      this.fileInput.nativeElement.click();
    }
  }

  public onCreateNewFile(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.collectCurrentFileNames().pipe(
        first(),
        switchMap((fileNames: string[]): Observable<Maybe<PatternFileEditorDialogResult>> => {
          const matDialogRef: MatDialogRef<PatternFileEditorDialogComponent, PatternFileEditorDialogResult | null> =
              this.matDialog.open<PatternFileEditorDialogComponent, PatternFileEditorDialogPayload, PatternFileEditorDialogResult | null>(
                  PatternFileEditorDialogComponent,
                  {
                    data: {mode: 'create', alreadyTakenFileNames: fileNames},
                    width: '1000px', height: '800px', autoFocus: 'input#fileName', disableClose: true,
                  });
          return matDialogRef.afterClosed();
        }),
        tap((result: Maybe<PatternFileEditorDialogResult>): void => {
          if (!result || !result.fileName) {
            return;
          }
          const newFile: File = new File([result.fileContentText], result.fileName);
          this.prepareFileUpload([newFile], false);
        }),
        first(),
    ).subscribe();
  }

  private loadResource(resourceName: string): Observable<Blob> {
    let projectKeyToResponse: (projectKey: ProjectKey) => Observable<Blob>;
    if (this.local) {
      projectKeyToResponse = (projectKey: ProjectKey) => this.patternService.getResourceContent(projectKey, this.patternId, this.propertyKey, resourceName);
    } else {
      projectKeyToResponse = (projectKey: ProjectKey) => this.patternService.getHeadResourceContent(projectKey, this.patternId, this.propertyKey, resourceName);
    }
    return this.projectKey$.pipe(first(), switchMap(projectKeyToResponse), first());
  }

  /**
   *  Downloads the attachment to the user's computer,
   *  considering whether the attachment has to be downloaded from the remote repository, or local project (for publish view)
   * @param item the `ResourceItem` to download
   */
  downloadResource(item: ResourceItem): void {
    const resourceName = item.resourceName;
    this.loadResource(resourceName).subscribe(fileContent => FileDownloader.downloadFile(fileContent, resourceName));
  }

  /**
   * Opens the attachment for editing in a dialog,
   * considering whether the attachment has to be downloaded from the remote repository, or local project (for publish view)
   * @param item the `ResourceItem` to edit
   */
  editResource(item: ResourceItem): void {
    const resourceName = item.resourceName;
    if (isBinaryByExtension(resourceName)) {
      this.openBinaryFileInfoDialog();
      return;
    }
    this.loadResource(resourceName).pipe(
        switchMap((content: Blob) => from(content.text())),
    ).subscribe((fileContent: string) => {
      if (isASCIIContent(fileContent)) {
        const matDialogRef: MatDialogRef<PatternFileEditorDialogComponent, PatternFileEditorDialogResult | null> =
            this.matDialog.open<PatternFileEditorDialogComponent, PatternFileEditorDialogPayload, PatternFileEditorDialogResult | null>(
                PatternFileEditorDialogComponent,
                {
                  data: {mode: 'edit', content: fileContent, fileName: resourceName},
                  width: '1000px', height: '800px', autoFocus: 'textarea.code', disableClose: true,
                });
        matDialogRef.afterClosed().pipe(first()).subscribe((result: PatternFileEditorDialogResult) => {
          if (result) {
            const newFile: File = new File([result.fileContentText], resourceName);
            this.prepareFileUpload([newFile], true);
          }
        });
      } else {
        this.openBinaryFileInfoDialog();
      }
    });
  }

  private openBinaryFileInfoDialog(): void {
    this.modalNotificationService.openInfoDialog({
      description: 'The given file content cannot be displayed. You can download the file.',
      title: 'File content cannot be opened'});
  }

  onNewFileUpload(event, existingRecourseListCount: string | undefined): void {
    const files: File[] = event.target.files;
    if (files && files.length > 0) {
      const currentAttachmentListSize: number = _.isNil(existingRecourseListCount) ? 0 : JSON.parse(existingRecourseListCount);
      if (this.chkMaxAttachmentReached(files.length, (currentAttachmentListSize))) {
        this.modalNotificationService.openInfoDialog({
          title: 'Attachment limit exceeded',
          description: 'The selected file cannot be attached because it reaches the maximum number of file attachments. Please delete the existing ones from the list and try again.'
        });
        this.resetFileInput();
        return;
      }
      this.prepareFileUpload(files);
    }
  }

  /**
   * Adds the file to the 'queue' which will be uploaded when the user saves the pattern.
   * @param files
   * @param warnIfReplace whether the user should be warned about the fact that a file is changed, as opposed to a new being added.
   * @private
   */
  private prepareFileUpload(files: File[], warnIfReplace: boolean = false): void {
    const emptyAttachments: SaveAttachmentObj[] = [];
    _.forEach(files, (file: File) => {
      const attachment: SaveAttachmentObj = {fileToSave: file, propertyKey: this.propertyKey};
      if (attachment.fileToSave.size === 0) {
        emptyAttachments.push(attachment);
        this.attachmentsChanged.next();
        return;
      }
      // checking if there's a not yet saved one with this filename
      const newIndex: number = this.newAttachmentList.findIndex((o) => o.fileToSave.name === attachment.fileToSave.name);
      if (newIndex === -1) {
        this.newAttachmentList.push(attachment);
        this.removeOverwrittenFileFromDeleteList(attachment, this.deleteResourceList);
      } else {
        this.newAttachmentList[newIndex] = attachment;
      }
      this.attachmentsChanged.next();

      // checking if overwriting an already saved one
      this.resourceList$.pipe(first()).subscribe((resources: ResourceItem[]) => {
        const savedIndex: number = resources.findIndex((res: ResourceItem) => res.resourceName === attachment.fileToSave.name);
        // no warning if directly editing, because only an existing one can be edited
        if (!warnIfReplace && (newIndex > -1 || savedIndex > -1)) {
          this.toast.showWarningToast(`The file content of <strong>${attachment.fileToSave.name}</strong> will be replaced.`, 'Replacing file');
        }
      });

    });
    if (!_.isEmpty(emptyAttachments)) {
      this.alertEmptyFileUpdate(emptyAttachments);
    }
    this.resetFileInput();
    this.setDirtyFlag(this.newAttachmentList, this.deleteResourceList);
    this.setFormControlReferenceValue();
  }

  private alertEmptyFileUpdate(emptyAttachments: SaveAttachmentObj[]): void {
    this.modalNotificationService.openWarningDialog({title: 'Cannot attach empty file(s)', description: `The following attachment(s) selected seems to be empty and cannot be uploaded: <br> ${emptyAttachments.map((file: SaveAttachmentObj) => file.fileToSave.name).join('<br/>')}`});
  }

  private removeOverwrittenFileFromDeleteList(attachment: SaveAttachmentObj, deleteResourceList: DeleteAttachmentObj[]) {
    _.remove(deleteResourceList, (itemToDelete: DeleteAttachmentObj) => {
      return itemToDelete.fileName === attachment.fileToSave.name;
    });
  }

  public resetFileInput() {
    this.fileInput.nativeElement.value = '';
  }

  deleteResource(resource: ResourceItem, deleteResourceList: DeleteAttachmentObj[]) {
    deleteResourceList.push({fileName: resource.resourceName, propertyKey: this.propertyKey} as DeleteAttachmentObj);
    this.setDirtyFlag(this.newAttachmentList, this.deleteResourceList);
    this.setTouchedFlag();
    this.setFormControlReferenceValue();
    this.attachmentsChanged.next();
  }

  removeNewAttachment(file: SaveAttachmentObj) {
    _.pull(this.newAttachmentList, file);
    this.setDirtyFlag(this.newAttachmentList, this.deleteResourceList);
    this.setTouchedFlag();
    this.setFormControlReferenceValue();
    this.attachmentsChanged.next();
  }

  /**
   * Checks if the max attachment limit is reached. Also considers the files scheduled to be deleted and scheduled to be added.
   * @param filesToUpload This number of additional files is included in the calculation, ie,
   * it checks if this number off additional files would be still in the limit.
   * @param attachmentListSize the number of original attachments
   */
  chkMaxAttachmentReached(filesToUpload: number, attachmentListSize: number): boolean {
    const nrOfAttachments = (this.newAttachmentList.length + attachmentListSize - this.deleteResourceList.length + filesToUpload);
    return nrOfAttachments > this.maxAllowed;
  }

  private collectCurrentFileNames(): Observable<string[]> {
    return this.resourceList$.pipe(
        first(),
        map((existingFiles: ResourceItem[]): string[] => {
          const fileNames: Set<string> = new Set(existingFiles.map((r: ResourceItem) => r.resourceName));
          this.newAttachmentList.forEach((newAttachment: SaveAttachmentObj) => fileNames.add(newAttachment.fileToSave.name));
          this.deleteResourceList.forEach((deleteAttachment: DeleteAttachmentObj) => fileNames.delete(deleteAttachment.fileName));
          return Array.from(fileNames);
        }),
    );
  }

  setFormControl() {
    if (this.publish) {
      throw Error('cannot use this for getting patternId');
    }
    this.actionControl = this.group.controls[ACTIONS_TO_DISPATCH_FC_NAME];

    // think again
    this.actionControl.valueChanges
      .subscribe(() => {
        this.initFormControl();
      });
    this.initFormControl();
  }

  /**
   * This initializes the `newAttachmentList` and `deleteResourceList` from the actions in the form control.
   */
  private initFormControl() {
    const actions: NevisAdminAction<any>[] = this.actionControl.value;
    let action = this.getAction(PatternActionTypes.SavePatternResource, actions);

    if (!action) {
      actions.push(new SavePatternResource([]));
    }

    action = this.getAction(PatternActionTypes.SavePatternResource, actions);
    if (action) {
      this.newAttachmentList = actions[action].payload;
    }

    action = this.getAction(PatternActionTypes.DeletePatternResource, actions);

    if (!action) {
      actions.push(new DeletePatternResource([]));
    }

    action = this.getAction(PatternActionTypes.DeletePatternResource, actions);
    if (action) {
      this.deleteResourceList = actions[action].payload;
    }
  }

  private getAction(actionType: string, actions: NevisAdminAction<any>[]) {

    return _.findKey(actions, (o: NevisAdminAction<any>) => {
      return o.type === actionType;
    });
  }

  setFormControlReferenceValue() {
    const valueOfPatternInstanceProperty = this.group.controls[this.propertyKey];
    this.resourceList$.pipe(take(1)).subscribe((existingResources: ResourceItem[]) => {
      const availableResourceCount = _.size(existingResources) + _.size(this.newAttachmentList);
      const deltaResourceCount = availableResourceCount - _.size(this.deleteResourceList);
      const hasAttachments = deltaResourceCount > 0;
      valueOfPatternInstanceProperty.setValue(hasAttachments ? `${resourceReferencePrefix}${this.patternId}#${this.propertyKey}` : undefined);
    });
  }

  setDirtyFlag(newAttachmentList: SaveAttachmentObj[], deleteResourceList: DeleteAttachmentObj[]) {
    if (newAttachmentList.length + deleteResourceList.length === 0) {
      this.actionControl.reset([]);
    } else {
      this.actionControl.markAsDirty();

      const propertyFormControl = this.group.controls[this.propertyKey];
      propertyFormControl.markAsDirty();
    }
  }

  setTouchedFlag(): void {
    const propertyFormControl = this.group.controls[this.propertyKey];
    propertyFormControl.markAsTouched();
  }

  /**
   * Since adding/removing attachments is not transactional it can happen that attachments are loaded but not referenced, then they don't have to be displayed
   */
  get hasReferenceToAttachments(): boolean {
    return !_.isEmpty(this.widgetProperty.value);
  }

  get publish() {
    return this.patternContext.type === PropertyWidgetContextType.PUBLISH;
  }

  isPatternEditorScreen() {
    return this.patternContext.type === PropertyWidgetContextType.PATTERN_EDITOR;
  }

  get shouldShowSecretWarningMessage(): boolean {
    return this.isSecret && this.isValueEmpty() && this.isPatternEditorScreen();
  }
}

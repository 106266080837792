import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

/**
 * General directive to indicate if content is loading.
 *
 * How it works:
 * It will add the "loading" class to the HTML element, the directive is attached to when the given Observable emits true.
 * In case the given Observable emits false, the directive will remove the "loading" class from the HTML element.
 *
 * How to use:
 *
 * 1. Declare a boolean Subject field in your component:
 * <code>
 *   loading$ = new Subject<boolean>();
 * </code>
 * This subject can indicate if content is loading (true) or finished loading (false).
 *
 * 2. Bind the boolean Subject to our Observable sequence like so:
 * <code>
 *   this.userService.create(new User(name)).
 *     pipe(indicate(this.loading$))
 *     .subscribe()
 * </code>
 * So that <code>loading$</code> will emit true when the loading starts, and emits false when the loading was finished.
 *
 * 3. Add the <code>adm4LoadingIndicator</code> directive to HTML element where you want to indicate loading and
 * set the directive value to your Subject.
 * <code>
 *   <div [adm4LoadingIndicator]='loading$'>...</div>
 * </code>
 */
@Directive({
  selector: '[adm4LoadingIndicator]'
})
export class LoadingIndicatorDirective implements OnInit, OnDestroy {
  @Input('adm4LoadingIndicator') loadingObservable: Observable<boolean>;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.loadingObservable.pipe(
      takeUntil(this.destroyed$),
      tap((isLoading: boolean) => this.indicateLoading(isLoading))
    ).subscribe();
  }

  /**
   * Handles the state of loading.
   * In case the content is loading, adds the "loading" class to the HTML element.
   * In case the content is loaded, removes the "loading" class to the HTML element.
   *
   * @param isLoading boolean flag to indicate if the content is loading.
   */
  private indicateLoading(isLoading: boolean): void {
    if (isLoading) {
      this.renderer.addClass(this.elementRef.nativeElement, 'loading');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'loading');
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}

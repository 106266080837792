import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EmptyAction, NevisAdminAction } from '../actions';
import {
  InitTenantKey,
  LoadAnalyticsInfoSuccess,
  LoadApplicationInfoSuccess,
  SelectTenantKey,
  SelectTenantKeyByUrl,
  SharedActionTypes,
} from './shared.actions';
import { filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import {
  localStorageAnalyticsMuteNotifications,
  localStorageApplicationMode,
  localStorageTenantKey,
} from '../../common/constants/local-storage-keys.constants';
import { Store } from '@ngrx/store';
import { AppState, Dictionary } from '../reducer';
import { NavigationService } from '../../navbar/navigation.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { from, Observable, of } from 'rxjs';
import { loadSelectedProjectFromLocalStorage, StoreProjectFromLocalStorage } from '../project';
import { loadSelectedInventoryFromLocalStorage, StoreInventoryFromLocalStorage } from '../inventory';
import { tenantsView } from '../views';
import { Tenant } from '../../tenant/tenant.model';
import * as _ from 'lodash';
import { SelectTenantPayload } from './select-tenant-payload.model';
import { ApplicationInfoService } from '../../footer/application-info.service';
import { AppInfoState, ApplicationInfo, ApplicationMode } from './application-info.model';
import { UserActionTypes } from '../user';
import { AnalyticsState } from './analytics.model';
import { LocalStorageService } from '../../common/services/local-storage.service';
import { Maybe } from '../../common/utils/utils';

const REPORTING_PERIOD_MONTHS = 3;

@Injectable()
export class SharedEffects {

  private calculateLastReportingPeriodStart = (now: Date): Date => {
    const currentMonthB0 = now.getMonth();

    const remainder = currentMonthB0 % REPORTING_PERIOD_MONTHS;
    const startMonth = currentMonthB0 - remainder;

    const year = now.getFullYear();
    return new Date(year, startMonth, 1, 1, 0, 0, 0);
  };

  private calculateIsSubmissionDue = (lastConfirmTimeRaw: Maybe<string>): boolean => {
    if (!lastConfirmTimeRaw) {
      return true;
    }
    const lastConfirmTime: Date = new Date(lastConfirmTimeRaw);
    const now = new Date();
    const lastReportingPeriodStart: Date = this.calculateLastReportingPeriodStart(now);
    return lastConfirmTime.getTime()-lastReportingPeriodStart.getTime() < 0;
  };

  private createAnalyticsLoaded = (startupInfo: ApplicationInfo): LoadAnalyticsInfoSuccess => {
    const isSubmissionDue: boolean = this.calculateIsSubmissionDue(startupInfo.analyticsMeta.lastConfirmTime);
    const analyticState: AnalyticsState = {
      ...startupInfo.analyticsMeta,
      isSubmissionDue,
      isSubmissionNotificationMuted: this.localStorageService.getBoolean(localStorageAnalyticsMuteNotifications),
    };
    return new LoadAnalyticsInfoSuccess(analyticState);
  };

  private createAppInfoLoaded = (startupConfig: ApplicationInfo): LoadApplicationInfoSuccess => {
    // setting app mode from local storage for testing
    let localAppModes: ApplicationMode | null = null;
    const localAppModeRaw = this.localStorageService.getItem(localStorageApplicationMode);
    try {
      if (localAppModeRaw) {
        localAppModes = JSON.parse(localAppModeRaw);
      }
    } catch (e) {
    }
    const appinfo: AppInfoState = {
      modes: Array.isArray(localAppModes) ? localAppModes : startupConfig.modes,
      commitId: startupConfig.commitId,
      latestVersion: startupConfig.latestVersion,
      platform: startupConfig.platform,
      version: startupConfig.version,
    };
    return new LoadApplicationInfoSuccess(appinfo);
  };

  loadApplicationInfo: Observable<LoadApplicationInfoSuccess | LoadAnalyticsInfoSuccess> = createEffect(() => this.actions$
    .pipe(
      ofType(SharedActionTypes.LoadApplicationInfo, UserActionTypes.LoginComplete),
      switchMap((): Observable<ApplicationInfo> => {
        return this.applicationInfoService.getApplicationInfo().pipe(
          take(1),
        );
      }),
      switchMap((startupInfo: ApplicationInfo): Observable<LoadApplicationInfoSuccess | LoadAnalyticsInfoSuccess> => {
        const actions = [this.createAppInfoLoaded(startupInfo), this.createAnalyticsLoaded(startupInfo)];
        return from(actions);
      }),
    ));

  storeSelectedTenantInLocalStorage: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType(SharedActionTypes.InitTenantKey, SharedActionTypes.SelectTenantKey),
      map((action: InitTenantKey | SelectTenantKey) => action.payload),
      tap((selectTenantPayload: SelectTenantPayload) => LocalStorageHelper.save(localStorageTenantKey, selectTenantPayload.tenantKey))
    ), {dispatch: false});

  selectTenantByUrl: Observable<SelectTenantKey> = createEffect(() => this.actions$
    .pipe(
      ofType(SharedActionTypes.SelectTenantKeyByUrl),
      map((action: SelectTenantKeyByUrl) => action.payload),
      withLatestFrom(this.store$.select(tenantsView).pipe(filter((allTenants) => !_.isEmpty(allTenants)))),
      map(([selectTenantPayload, tenants]: [SelectTenantPayload, Dictionary<Tenant>]) => {
        if (_.isNil(tenants[selectTenantPayload.tenantKey])) {
          return new SelectTenantKey({tenantKey: _.values(tenants)[0].tenantKey, afterSelect: () => this.navigationService.navigateToRoot()});
        }
        return new SelectTenantKey({tenantKey: selectTenantPayload.tenantKey, afterSelect: selectTenantPayload.afterSelect});
      })
    ));

  loadTenantSensitiveDataFromLocalStorage: Observable<StoreProjectFromLocalStorage | StoreInventoryFromLocalStorage | EmptyAction> = createEffect(() => this.actions$
    .pipe(
      ofType(SharedActionTypes.InitTenantKey, SharedActionTypes.SelectTenantKey),
      map((action: InitTenantKey | SelectTenantKey) => action.payload),
      mergeMap((selectTenantPayload: SelectTenantPayload) => of(
        // list actions to load data from local storage here
        loadSelectedProjectFromLocalStorage(selectTenantPayload.tenantKey),
        loadSelectedInventoryFromLocalStorage(selectTenantPayload.tenantKey)
      ))
    ));

  navigateToRootOnTenantSwitch: Observable<any> = createEffect(() => this.actions$
    .pipe(
      ofType(SharedActionTypes.SelectTenantKey),
      map((action: SelectTenantKey) => action.payload),
      tap((selectTenantPayload: SelectTenantPayload) => {
        const afterSelect = _.isFunction(selectTenantPayload.afterSelect) ? selectTenantPayload.afterSelect : () => this.navigationService.navigateToRoot();
        afterSelect()
          .then(() => this.toastNotificationService.showSuccessToast(`Tenant ${selectTenantPayload.tenantKey} is now selected.`));
      })
    ), {dispatch: false});

  constructor(private actions$: Actions<NevisAdminAction<any>>,
              private store$: Store<AppState>,
              private navigationService: NavigationService,
              private toastNotificationService: ToastNotificationService,
              private applicationInfoService: ApplicationInfoService,
              private localStorageService: LocalStorageService,
  ) {}
}

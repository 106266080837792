import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { VariableModel } from '../variable.model';
import { VariableDetails } from './variable-details.model';
import { VariableEditorComponent } from '../variable-editor/variable-editor.component';
import { VariableDetailsContext } from './variable-details.context';
import { ProjectContext } from '../../projects/project.context';
import { MetaInfo } from '../../version-control/meta-info.model';
import { SplitPaneConfig } from '../../common/model/split-pane-config.model';
import { IOutputData } from 'angular-split/lib/interface';
import { Dictionary } from '../../model/reducer';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../common/mixins/split.mixin';

@Component({
  selector: 'adm4-variable-details',
  template: `
    <div class="split-pane-content-secondary full-height">
      <as-split direction='horizontal' gutterSize='1' useTransition (dragEnd)='onResize($event)'>
        <as-split-area [order]='splitPaneConfig[editorSplitAreaKey].order' [size]='splitPaneConfig[editorSplitAreaKey].size' [minSize]='40'>
          <div class="full-height">
            <adm4-variable-editor *ngIf="variableDetails$ | async"
                                  #variableEditorComp
                                  [variableDetails]='variableDetails$ | async'
                                  [variables]='variables$ | async'
                                  [variablesMetaInfo]='variablesMetaInfo$ | async'
                                  [readOnly]='projectContext.isProjectReadOnly$ | async'
                                  [isHelpCollapsed]='splitPaneConfig[helpSplitAreaKey].isCollapsed'
                                  (expandHelp)='onCollapse(false, helpSplitAreaKey)'
            ></adm4-variable-editor>
          </div>
        </as-split-area>
        <as-split-area [order]='splitPaneConfig[helpSplitAreaKey].order' [size]='splitPaneConfig[helpSplitAreaKey].size' [minSize]='25' [visible]='!splitPaneConfig[helpSplitAreaKey].isCollapsed'>
          <div class="full-height">
            <adm4-variable-help *ngIf="variableDetails$ | async"
                                [variableDetails]='variableDetails$ | async'
                                (collapse)='onCollapse($event, helpSplitAreaKey)'
            >
            </adm4-variable-help>
          </div>
        </as-split-area>
      </as-split>
    </div>
  `,
  providers: [VariableDetailsContext]
})
@Mixin([SplitMixin])
export class VariableDetailsComponent implements ISplitMixin {
  variables$: Observable<VariableModel[]>;
  variableDetails$: Observable<VariableDetails>;
  variablesMetaInfo$: Observable<MetaInfo | null>;

  @ViewChild('variableEditorComp', {static: false}) variableEditorComp: VariableEditorComponent;

  readonly editorSplitAreaKey = 'editor';
  readonly helpSplitAreaKey = 'help';
  readonly splitPaneConfigLocalStorageKey = 'variable-editor-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.editorSplitAreaKey]: {order: 0, size: 70},
    [this.helpSplitAreaKey]: {order: 1, size: 30, isCollapsed: false}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  /**
   * Implemented by SplitMixin
   */
  onCollapse: (isCollapsed: boolean, collapsibleAreaKey: string) => void;

  constructor(private variableDetailsContext: VariableDetailsContext,
              public projectContext: ProjectContext) {
    this.variables$ = this.variableDetailsContext.variables$;
    this.variableDetails$ = this.variableDetailsContext.variableDetails$;
    this.variablesMetaInfo$ = this.variableDetailsContext.variablesMetaInfo$;
  }
}

import { NgModule } from '@angular/core';
import { PatternDetailsComponent } from './pattern-details.component';
import { CreatePatternComponent } from './create-pattern.component';
import { CreatePatternListComponent } from './create-pattern-list-element.component';
import { CommonModules } from '../../common/common.module';
import { CreatePatternContainerComponent } from './create-pattern-container/create-pattern-container.component';
import { ModalDialogModule } from '../../modal-dialog/modal-dialog.module';

@NgModule({
  imports: [
    CommonModules,
    ModalDialogModule
  ],
  declarations: [
    PatternDetailsComponent,
    CreatePatternComponent,
    CreatePatternListComponent,
    CreatePatternContainerComponent
  ]
})
export class CreatePatternModule {
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PatternIssueGroupComponent} from './pattern-issue-group.component';
import {PatternIssueGroupListComponent} from './pattern-issue-group-list.component';
import {CommonModules} from '../../common/common.module';
import {PropertyWidgetsModule} from '../../property-widgets/property-widgets.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CommonModules,
        PropertyWidgetsModule
    ],
    declarations: [
        PatternIssueGroupComponent,
        PatternIssueGroupListComponent
    ],
    exports: [
        PatternIssueGroupListComponent
    ]
})
export class PatternIssueGroupModule {}

import { InventoryResource, TenantResourceFlag } from '../../inventory.model';

export class InventoryAttachmentPayload {
}

export class InventoryAttachFilePayload extends InventoryAttachmentPayload {
  file: File;
  description?: string;
}

export class InventoryAttachTenantResourcePayload extends InventoryAttachmentPayload {
  resourceKey: string;
}

export enum InventoryAttachmentResourceMode {
  resourceFile = 'file',
  secretResourceFile = 'secret file',
  certificate = 'certificate'
}

export enum InventoryAttachmentTenantResourceMode {
  tenantLevelResourceFile = 'global resource file',
  tenantLevelSecretResourceFile = 'global secret resource file'
}

export interface ResourceLevelGroup<T extends InventoryResource> {
  level: ResourceLevelEnum;
  resources: (T & TenantResourceFlag)[];
}

export enum ResourceLevelEnum {
  GLOBAL = 'global',
  INVENTORY = 'inventory'
}

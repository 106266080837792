import { Component, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { BehaviorSubject, combineLatest, Observable, of, Subject, timer } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import * as _ from 'lodash';

import { DeploymentHistoryItem } from '../../common/model/deployment-history.model';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { InventoryContext } from '../inventory.context';
import { Inventory } from '../inventory.model';
import { DeploymentHistoryTableModel, inventoryK8sStatusColumns } from '../inventory-deployment-history/inventory-deployment-history-table/inventory-deployment-history-table.model';
import { InventoryDeploymentHistoryContext } from '../inventory-deployment-history/inventory-deployment-history.context';
import { NavigationService } from '../../navbar/navigation.service';
import { AppState } from '../../model/reducer';
import { CanaryRoutingOption, DeploymentActionType } from '../../deployment-wizard/deployment-dialog/deployment-process.model';
import { StoreDeploymentCanaryRoutingOptions } from '../../model/deploy';
import { requireNonNull } from '../../common/utils/utils';
import { KubernetesStatusDialogService } from './kubernetes-status-dialog.service';
import { KubernetesDialogTypeEnum } from './kubernetes-status-dialog-payload.model';
import { KubernetesStatusHelper } from './kubernetes-status.helper';
import { DeployedServiceItem, Pod } from './deployed-service.model';
import { InventoryService } from '../inventory.service';
import { selectedInventoryView } from '../../model/views';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { ErrorHelper } from '../../common/helpers/error.helper';

@Component({
  selector: 'adm4-inventory-kubernetes-status-component',
  template: `
    <div class="full-height-flex">

      <adm4-inventory-header [currentInventory]='currentInventory$ | async'
                             [inventories]='inventories$ | async'
                             [inventoryValidationStatus]='inventoryValidationStatus$ | async'
                             [readOnly]='isCurrentInventoryReadonly$ | async'></adm4-inventory-header>
      <div id='kubernetes-status-container'>
        <div class="row">
          <div class="col operator">
            <span class='deployment-title'>nevisOperator</span>
            <hr>
            <adm4-operator-status [services]="operatorServices$ | async" (viewLogs)="openPodLogsDialog($event)"></adm4-operator-status>
          </div>
        </div>
        <div class="row">
          <div class='deployment-column bordered-column col-lg-6'>
            <span class='deployment-title'>Active Primary deployments</span>
            <hr>
            <ng-container *ngIf='hasHistory(primaryDeploymentHistory$ | async); else noPrimaryHistory'>
              <div class='deployment-settings-container'>
                <ng-container
                        *ngIf='(promotionFailed$ | async); else defaultInfoMessage'>
                  <div class='info-with-icon-container'>
                    <mat-icon class='warning-icon'>report_problem</mat-icon>
                    The Primary deployments may have been affected from the failed promotion (e.g.
                    partially removed, traffic changed). Thus, try promotion again or re-deploy all of
                    your Primary deployments.
                  </div>
                </ng-container>
                <ng-template #defaultInfoMessage>
                    <span>Active Primary deployments are your actual deployments. Expand the list to see the details.</span>
                </ng-template>
              </div>
              <adm4-inventory-deployment-history-table
                      [inventoryDeploymentHistory]='primaryDeploymentHistory$ | async'
                      [currentInventory]='currentInventory$ | async'
                      [deployedServices]='deployedServices$ | async'
                      [displayedColumns]='displayedColumns'
                      [isSecondary]="false"
              ></adm4-inventory-deployment-history-table>
            </ng-container>
            <ng-template #noPrimaryHistory>
              <div>There is no active Primary deployment for this inventory yet.</div>
              <div class='deploy-with-icon-container' (click)='openDeployDialog(true)'>
                <mat-icon>play_circle_filled</mat-icon>
                <a>Deploy as Primary</a>
              </div>
            </ng-template>
          </div>
          <div class='deployment-column col-lg-6'>
            <div class='secondary-deployment-title-container'>
              <span class='deployment-title'> Active Secondary deployments (side-by-side)</span>
              <i class="fa fa-info-circle help-icon" aria-hidden="true"
                 [ngbTooltip]='secondaryTooltip' placement='top'>
              </i>
              <ng-template #secondaryTooltip>
                The secondary version can run alongside your primary version.
                You can promote or rollback the secondary version. Find further details at
                <adm4-external-link
                        [linkUrl]="'nevisadmin4/User-Guide/Deployment-of-the-Configuration/Kubernetes-Deployment/Side-by-side-Deployment/' | docLink"
                        [linkLabel]='"Side-by-side Deployment"'
                        [displayStyle]="'inline'"
                        [openInNewTab]='true'></adm4-external-link>
              </ng-template>
            </div>
            <hr>
            <ng-container *ngIf='hasHistory(secondaryDeploymentHistory$ | async); else noSecondaryHistory'>
              <div class='deployment-settings-container'>
                Active Secondary deployments are your deployments running side-by-side to your Primary
                deployments. Expand the list to see the details.
              </div>
              <adm4-inventory-deployment-history-table
                      [inventoryDeploymentHistory]='secondaryDeploymentHistory$ | async'
                      [currentInventory]='currentInventory$ | async'
                      [deployedServices]='deployedServices$ | async'
                      [displayedColumns]='displayedColumns'
                      [isSecondary]="true"
              ></adm4-inventory-deployment-history-table>
              <div *ngIf="(latestCanaryConfig | async) as canaryConfig" class='button-wrapper'>
                <button type='button' class='admn4-button-ellipse-blue rollback-button'
                        [title]='getDeploymentActionBtnTooltip(secondaryDeploymentHistory$ | async, DeploymentActionTypes.ROLLBACK)'
                        [disabled]='isPromoteDisabled(secondaryDeploymentHistory$ | async)'
                        (click)='openKubernetesDialog(KubernetesDialogTypes.ROLLBACK, canaryConfig)'>Rollback
                </button>
                <button type='button' class='admn4-button-ellipse-blue'
                        [title]='getDeploymentActionBtnTooltip(secondaryDeploymentHistory$ | async, DeploymentActionTypes.PROMOTE)'
                        [disabled]='isRollbackFailed(secondaryDeploymentHistory$ | async)'
                        (click)='openKubernetesDialog(KubernetesDialogTypes.PROMOTE, canaryConfig)'>Promote
                </button>
              </div>
            </ng-container>
            <ng-template #noSecondaryHistory>
              <ng-container *ngIf='hasHistory(primaryDeploymentHistory$ | async); else primaryDeploymentNeeded'>
                <div>There is no active Secondary deployment for this inventory yet.</div>
                <div class='deploy-with-icon-container' (click)='openDeployDialog(false)'>
                  <mat-icon>play_circle_filled</mat-icon>
                  <a>Deploy as Secondary</a>
                </div>
              </ng-container>
              <ng-template #primaryDeploymentNeeded>
                <span>There is no active Secondary deployment for this inventory yet. First, make Primary deployments to be able to create Secondary deployments.</span>
              </ng-template>
            </ng-template>
          </div>
        </div>

      </div>
    </div>
  `,
  styleUrls: ['./inventory-kubernetes-status-component.component.scss', '../../common/styles/component-specific/create-form.scss', '../../common/styles/component-specific/settings-details.scss'],
  providers: [InventoryDeploymentHistoryContext]
})
export class InventoryKubernetesStatusComponentComponent implements OnDestroy {

  currentInventory$: Observable<Inventory | undefined>;
  inventories$: Observable<Inventory[]>;
  inventoryDeploymentHistory$: Observable<DeploymentHistoryItem[] | never>;
  deployedServices$: Observable<DeployedServiceItem[]>;
  operatorServices$: Observable<DeployedServiceItem[]>;
  primaryDeploymentHistory$: Observable<DeploymentHistoryItem[] | never>;
  secondaryDeploymentHistory$: Observable<DeploymentHistoryItem[] | never>;
  inventoryValidationStatus$: Observable<ValidationStatus<InventoryValidationIssue> | null>;
  isCurrentInventoryReadonly$: Observable<boolean>;
  displayedColumns: DeploymentHistoryTableModel[];
  latestCanaryConfig: Observable<CanaryRoutingOption>;

  promotionFailed$: Observable<boolean>;

  readonly KubernetesDialogTypes = KubernetesDialogTypeEnum;
  readonly DeploymentActionTypes = DeploymentActionType;
  private POLLING_INTERVAL = 3000;
  private isOperatorPollingError = new BehaviorSubject<boolean>(false);
  private destroyed$: Subject<boolean> = new Subject();

  constructor(private inventoryDeploymentHistoryContext: InventoryDeploymentHistoryContext,
              private inventoryContext: InventoryContext,
              private navigationService: NavigationService,
              private store$: Store<AppState>,
              private kubernetesDialogService: KubernetesStatusDialogService,
              private inventoryService: InventoryService,
              private kubernetesStatusDialogService: KubernetesStatusDialogService,
              private modalNotificationService: ModalNotificationService,
  ) {
    this.currentInventory$ = this.inventoryContext.currentInventory$;
    this.inventories$ = this.inventoryContext.inventories$.pipe(map(_.values));
    this.inventoryDeploymentHistory$ = this.inventoryDeploymentHistoryContext.inventoryDeploymentHistory$.pipe(map((deploymentList: DeploymentHistoryItem[]) => KubernetesStatusHelper.getSortedDeploymentHistoryByTime(deploymentList)));
    this.deployedServices$ = this.pollDeployedServices();
    this.operatorServices$ = this.pollOperatorServices();
    this.primaryDeploymentHistory$ = this.inventoryDeploymentHistory$.pipe(
        map((deployments: DeploymentHistoryItem[]) => KubernetesStatusHelper.filterForActivePrimaryDeployments(deployments)),
    );
    this.secondaryDeploymentHistory$ = this.inventoryDeploymentHistory$.pipe(map((deploymentList: DeploymentHistoryItem[]) => KubernetesStatusHelper.getSecondaryList(deploymentList)));
    this.inventoryValidationStatus$ = this.inventoryContext.inventoryValidationStatus$;
    this.isCurrentInventoryReadonly$ = this.inventoryContext.isCurrentInventoryReadonly$;
    this.displayedColumns = inventoryK8sStatusColumns;
    this.latestCanaryConfig = this.getLatestCanaryConfig();
    this.promotionFailed$ = this.inventoryDeploymentHistory$.pipe(
        map((historyItems: DeploymentHistoryItem[]): boolean => KubernetesStatusHelper.isPromoteFailed(historyItems)),
    );
  }

  hasHistory(inventoryDeploymentHistory: DeploymentHistoryItem[]): boolean {
    return !_.isEmpty(inventoryDeploymentHistory);
  }

  private pollDeployedServices(): Observable<DeployedServiceItem[]> {
    return combineLatest([
      timer(0, this.POLLING_INTERVAL),
      this.currentInventory$.pipe(
          filter((i: Inventory): boolean => !_.isNil(i)),
          tap(() => this.isOperatorPollingError.next(false))
      ),
    ]).pipe(
        filter(() => !this.isOperatorPollingError.getValue()),
        switchMap(([_poll, inventory]: [number, Inventory]): Observable<DeployedServiceItem[]> => {
          return this.inventoryService.getInventoryDeployedServices(inventory.inventoryKey).pipe(
              catchError((err): Observable<DeployedServiceItem[]> => {
                this.isOperatorPollingError.next(true);
                console.log(`InventoryKubernetesStatusComponentComponent#pollOperatorServices, getInventoryDeployedServices`, err);
                this.modalNotificationService.openErrorDialog({
                  title: 'Unfortunately, an unexpected error happened',
                  description: ErrorHelper.getErrorDetail(err),
                });
                return of([]);
              }),
          );
        }),
        distinctUntilChanged(_.isEqual),
        takeUntil(this.destroyed$),
        shareReplay(1),
    );
  }

  private pollOperatorServices(): Observable<DeployedServiceItem[]> {
    return combineLatest([
      timer(0, this.POLLING_INTERVAL),
      this.currentInventory$.pipe(
          filter((i: Inventory): boolean => !_.isNil(i)),
          tap(() => this.isOperatorPollingError.next(false))
      ),
    ]).pipe(
        filter(() => !this.isOperatorPollingError.getValue()),
        switchMap(([_poll, inventory]: [number, Inventory]): Observable<DeployedServiceItem[]> => {
          return this.inventoryService.getInventoryOperatorServices(inventory.inventoryKey).pipe(
              catchError((err): Observable<DeployedServiceItem[]> => {
                this.isOperatorPollingError.next(true);
                console.log(`InventoryKubernetesStatusComponentComponent#pollOperatorServices, getInventoryOperatorServices`, err);
                this.modalNotificationService.openErrorDialog({
                  title: 'Unfortunately, an unexpected error happened',
                  description: ErrorHelper.getErrorDetail(err),
                });
                return of([]);
              }),
          );
        }),
        distinctUntilChanged(_.isEqual),
        takeUntil(this.destroyed$),
        shareReplay(1),
    );
  }

  openDeployDialog(isPrimaryDeployment: boolean): void {
    const canaryRoutingOptions = isPrimaryDeployment ? undefined : {} as CanaryRoutingOption;
    this.store$.dispatch(new StoreDeploymentCanaryRoutingOptions(canaryRoutingOptions));
    this.navigationService.navigateToDeploymentWizard();
  }

  openPodLogsDialog(pod: Pod): void {
    this.store$.pipe(select(selectedInventoryView)).pipe(
      filter((inventory: Inventory | undefined): inventory is Inventory => !_.isNil(inventory)),
      first(),
      map(i => i.inventoryKey),
    ).subscribe(
      (inventoryKey: string) => this.kubernetesStatusDialogService.openPodLogsDialog(inventoryKey, pod)
    );
  }

  private getLatestCanaryConfig() {
    return this.secondaryDeploymentHistory$.pipe(filter((item: DeploymentHistoryItem[]) => {
      return !(_.isNil(item) || _.isNil(item[0]) || _.isNil(item[0].canaryRouting));
    }), map((item) => requireNonNull<CanaryRoutingOption>(item[0].canaryRouting)));
  }

  /**
   * returns the weight of the primary routing in case no canary header||cookie has been set
   * @param routing
   */
  getPrimaryPercentage(routing: CanaryRoutingOption | undefined) {
    if (_.isNil(routing)) {
      return 100;
    }

    if (!_.isNil(routing.header) || !_.isNil(routing?.cookie)) {
      return;
    }

    return 100 - (routing.percentage || 0);
  }

  openKubernetesDialog(kubernetesDialogType: KubernetesDialogTypeEnum, canaryConfig: any): void {
    this.currentInventory$.pipe(take(1), filter((inventory) => !_.isNil(inventory)))
      .subscribe((inventory: Inventory) => {
        this.kubernetesDialogService.openPromoteRollbackDialog(inventory.inventoryKey, kubernetesDialogType, canaryConfig)
          .afterClosed().pipe(take(1)).subscribe(() => {
          this.inventoryDeploymentHistoryContext.getUpdatesOfTheInventory();
        });
      });
  }

  isPromoteDisabled(secondaryDeploymentHistory: DeploymentHistoryItem[]): boolean {
    return KubernetesStatusHelper.isPromoteDisabled(secondaryDeploymentHistory);
  }

  isRollbackFailed(secondaryDeploymentHistory: DeploymentHistoryItem[]): boolean {
    return KubernetesStatusHelper.isRollbackDisabled(secondaryDeploymentHistory);
  }

  getDeploymentActionBtnTooltip(secondaryDeploymentHistory: DeploymentHistoryItem[], actionType: DeploymentActionType): string {
    const hasRunningDeployment = secondaryDeploymentHistory.some((deploymentHistoryItem: DeploymentHistoryItem) => KubernetesStatusHelper.isDeploymentRunning(deploymentHistoryItem));
    if (hasRunningDeployment) return `The deployment is still in progress. You cannot ${actionType.toLowerCase()}.`;
    const deploymentHasError = _.isEqual(actionType, DeploymentActionType.ROLLBACK) ? KubernetesStatusHelper.isRollbackDisabled(secondaryDeploymentHistory) : this.isPromoteDisabled(secondaryDeploymentHistory);
    return deploymentHasError ? `There is an error in the deployments. You cannot ${actionType.toLowerCase()}.` : '';
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}


import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Diff } from '../../../../common/model/publish-changes/diff.model';
import { PatternNameDiffData } from '../pattern-diff-data.model';

@Component({
  selector: 'adm4-pattern-name-diff',
  template: `
    <adm4-diff-view-element [remoteCommitInfo]='patternNameDiff.remote.commitInfo'
                            [localChangeInfo]='patternNameDiff.local.changeInfo'
                            [localFallbackLabel]='patternNameDiff.local.fallbackLabel'
                            [currentUsername]='currentUsername'
                            [showOnlyDifferences]='showOnlyDifferences'
                            [boxed]='false'
    >
      <div remote>
        <h2 class='pattern-name'>{{patternNameDiff.remote.patternName}}</h2>
      </div>
      <div local>
        <h2 class='pattern-name'>{{patternNameDiff.local.patternName}}</h2>
      </div>
    </adm4-diff-view-element>
  `,
  styleUrls: ['./pattern-name-diff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternNameDiffComponent {
  @Input() patternNameDiff: Diff<PatternNameDiffData>;
  @Input() showOnlyDifferences: boolean;
  @Input() currentUsername: string | null;
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Diff } from '../../../../common/model/publish-changes/diff.model';
import { PropertyDiffData } from '../pattern-diff-data.model';
import { FormHelper } from '../../../../common/helpers/form.helper';
import { VariableModel } from '../../../../variables/variable.model';

@Component({
  selector: 'adm4-property-diff',
  templateUrl: './property-diff.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyDiffComponent implements OnChanges {
  @Input() propertyDiff: Diff<PropertyDiffData>;
  @Input() projectKey: string;
  @Input() variables: VariableModel[];
  @Input() showOnlyDifferences: boolean;
  @Input() currentUsername: string | null;

  public localForm: UntypedFormGroup;
  public remoteForm: UntypedFormGroup;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.propertyDiff) {
      // As form in store is frozen and should not be changed in order to pass it down to actual display component we have to clone it
      // Sure it could have been done better if low level component was not coupled to form, but it is
      this.localForm = FormHelper.cloneForm(this.propertyDiff.local.form);
      this.remoteForm = FormHelper.cloneForm(this.propertyDiff.remote.form);
    }
  }
}

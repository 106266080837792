import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { PatternIssueSeverityGroup } from '../model/pattern-issue-severity-group.model';
import { IssueModel } from '../../common/model/issue.model';
import { Pattern } from '../../patterns/pattern.model';
import { PatternIssueSeverityGroupsHelper } from './pattern-issue-severity-groups.helper';
import { IssueGroupFilteringContext } from '../issue-group-filtering.context';
import { issuesView, patternsView } from '../../model/views';

@Injectable()
export class PatternIssueSeverityGroupsContext {
  patternIssueSeverityGroups$: Observable<PatternIssueSeverityGroup[]>;

  constructor(private store$: Store<AppState>, private issueGroupFilteringContext: IssueGroupFilteringContext) {
    const patternIssueSeverityGroups$ = combineLatest([
      this.store$.pipe(select(issuesView)),
      this.store$.pipe(select(patternsView))
    ]).pipe(
      map(([issues, patterns]: [IssueModel[], Map<string, Pattern>]) => {
        return PatternIssueSeverityGroupsHelper.createGroups(issues, patterns);
      }));
    // when user types something into search field, we react to that and filter the results
    this.patternIssueSeverityGroups$ = combineLatest([patternIssueSeverityGroups$, this.issueGroupFilteringContext.filterText$])
      .pipe(map(([patternIssueSeverityGroups, filterText]: [PatternIssueSeverityGroup[], string]) => PatternIssueSeverityGroupsHelper.filterPatternIssueSeverityGroupByGroupName(patternIssueSeverityGroups, filterText)));
  }

}

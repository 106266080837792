export class PathHelper {

  /**
   * Returns name of directory or file, subtracted from path
   * Directory name is prefixed with /
   * @param {string} path
   * @returns {string}
   */
  static getArtifactNameFromPath(path: string): string {
    const indexOfLastSlash = path.lastIndexOf('/');
    const nameStart = indexOfLastSlash + 1;
    return path.substring(nameStart, path.length);
  }

  static getParentPath(path: string): string {
    const indexOfLastSlash = path.lastIndexOf('/');
    return path.substring(0, indexOfLastSlash);
  }
}

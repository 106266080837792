import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Tenant } from '../../tenant/tenant.model';
import { Project, ProjectMeta } from '../project.model';
import { OperationKey } from '../../model/permissions/permissions.model';
import * as _ from 'lodash';
import { CREATED_PROJECT_KEY_FORM_CONTROL_NAME, EXISTING_PROJECT_KEY_FORM_CONTROL_NAME, PROJECT_KEY_MODE_FORM_CONTROL_NAME, PROJECT_ZIP_FORM_CONTROL_NAME, ProjectKeyMode } from './file-based-import-project-form.model';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { checkExistingProjectKey } from '../project-creation.validator';
import { FormHelper } from '../../common/helpers/form.helper';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileBasedImportProjectHelper } from './file-based-import-project.helper';
import { ProjectFileImportPayload } from './project-file-import-payload.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { ProjectHelper } from '../project.helper';
import { VersionControlService } from '../../version-control/version-control.service';
import { PublishProjectChangesetHelper } from '../publish-project/publish-project-changeset.helper';

@Component({
  selector: 'adm4-file-based-import-project',
  templateUrl: './file-based-import-project.component.html',
  styleUrls: ['../../common/styles/component-specific/modal-window.scss', '../../common/styles/component-specific/create-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileBasedImportProjectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedTenant: Tenant;
  @Input() projects: Project[];
  @Input() displayTenant: boolean;
  @Output() cancelClicked: EventEmitter<void> = new EventEmitter();
  @Output() projectImport: EventEmitter<ProjectFileImportPayload> = new EventEmitter();

  readonly ProjectKeyMode = ProjectKeyMode;
  readonly PROJECT_ZIP_FORM_CONTROL_NAME = PROJECT_ZIP_FORM_CONTROL_NAME;
  readonly PROJECT_KEY_MODE_FORM_CONTROL_NAME = PROJECT_KEY_MODE_FORM_CONTROL_NAME;
  readonly CREATED_PROJECT_KEY_FORM_CONTROL_NAME = CREATED_PROJECT_KEY_FORM_CONTROL_NAME;
  readonly EXISTING_PROJECT_KEY_FORM_CONTROL_NAME = EXISTING_PROJECT_KEY_FORM_CONTROL_NAME;
  readonly ERROR_INVALID_PROJECT_KEY = 'The project key entered contains some invalid characters. Must be of format [A-Z0-9_-]';
  readonly ERROR_PROJECT_KEY_REQUIRED = 'The project key is required';
  readonly DISABLED_NEW_PROJECT_MODE_TOOLTIP_TEXT = `You don't have permission to create projects.`;
  readonly DISABLED_EXISTING_PROJECT_MODE_TOOLTIP_TEXT = `You don't have permission to modify existing projects.`;

  form: UntypedFormGroup;
  projectsWithModifyPermission: Project[];
  selectedExitingProjectMeta?: ProjectMeta;
  filteredProjectList: Project[];

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();


  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private modalNotificationService: ModalNotificationService,
    private versionCtrlService: VersionControlService) { }

  ngOnInit() {
    this.form = this.fb.group({
      [this.PROJECT_ZIP_FORM_CONTROL_NAME]: this.fb.control(null, [Validators.required]),
      [this.PROJECT_KEY_MODE_FORM_CONTROL_NAME]: this.fb.control(null, [Validators.required]),
      [this.CREATED_PROJECT_KEY_FORM_CONTROL_NAME]: this.fb.control('', [
        this.wrapProjectKeyValidator(Validators.required, ProjectKeyMode.New),
        this.wrapProjectKeyValidator(Validators.pattern(/^[a-zA-Z0-9_-]+$/), ProjectKeyMode.New),
        this.wrapProjectKeyValidator(checkExistingProjectKey(this.projects, this.selectedTenant.tenantKey), ProjectKeyMode.New)
      ]),
      [this.EXISTING_PROJECT_KEY_FORM_CONTROL_NAME]: this.fb.control(null, [this.wrapProjectKeyValidator(Validators.required, ProjectKeyMode.Existing)])
    });
    this.form.controls[this.PROJECT_ZIP_FORM_CONTROL_NAME].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((projectZip: File | null) => {
        this.handleFileSelection(projectZip);
        this.cdr.markForCheck();
      });
    this.form.controls[this.PROJECT_KEY_MODE_FORM_CONTROL_NAME].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        _.values(this.form.controls).forEach(control => control.updateValueAndValidity({emitEvent: false}));
        this.cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projects) {
      this.projectsWithModifyPermission = this.projects.filter(project => _.includes(project._userAuthorization, OperationKey.MODIFY_PROJECT));
      this.updateSearchResult(this.projectsWithModifyPermission);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private wrapProjectKeyValidator(validator: ValidatorFn, mode: ProjectKeyMode): ValidatorFn {
    return (control: AbstractControl) => {
      const projectKeyModeFormControl = _.isNil(this.form) ? null : this.form.controls[this.PROJECT_KEY_MODE_FORM_CONTROL_NAME];
      if (!_.isNil(projectKeyModeFormControl) && projectKeyModeFormControl.value === mode) {
        return validator(control);
      }
      return null;
    };
  }

  private handleFileSelection(projectZip: File | null): void {
    const projectNameFromZip = FileBasedImportProjectHelper.getProjectNameFromZip(projectZip);
    const existingProjectKey: string | undefined = this.projects.map(project => project.projectKey).find(projectKey => TenantHelper.cropTenantFromKey(projectKey) === projectNameFromZip);
    if (_.isNil(projectZip)) {
      this.form.reset(undefined, {emitEvent: false});
    } else if (_.isNil(projectNameFromZip)) {
      this.form.controls[this.PROJECT_KEY_MODE_FORM_CONTROL_NAME].setValue(null);
    } else if (!_.isNil(existingProjectKey) && this.hasModifyProjectPermission) {
      this.form.controls[this.PROJECT_KEY_MODE_FORM_CONTROL_NAME].setValue(ProjectKeyMode.Existing);
      this.form.controls[this.EXISTING_PROJECT_KEY_FORM_CONTROL_NAME].setValue(existingProjectKey);
    } else if (!_.isNil(projectNameFromZip) && this.hasCreateProjectPermission) {
      this.form.controls[this.PROJECT_KEY_MODE_FORM_CONTROL_NAME].setValue(ProjectKeyMode.New);
      this.form.controls[this.CREATED_PROJECT_KEY_FORM_CONTROL_NAME].setValue(projectNameFromZip);
    }
  }

  shouldShowCreatedProjectKeyErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[this.CREATED_PROJECT_KEY_FORM_CONTROL_NAME], errorKey);
  }

  chooseNewProjectKeyMode(): void {
    if (!this.canCreateNewProject) {
      return;
    }
    this.form.controls[this.PROJECT_KEY_MODE_FORM_CONTROL_NAME].setValue(ProjectKeyMode.New);
  }

  chooseExistingProjectKeyMode(): void {
    if (!this.canSelectExistingProject) {
      return;
    }
    this.form.controls[this.PROJECT_KEY_MODE_FORM_CONTROL_NAME].setValue(ProjectKeyMode.Existing);
  }

  onSelectExistingProject(selectedProjectKey) {
    this.versionCtrlService.getProjectStatus(selectedProjectKey).subscribe(
      (projectMeta: ProjectMeta) => {
        this.selectedExitingProjectMeta = projectMeta;
      }
    );
  }

  submitProjectZip(): void {
    if (!this.canImport) {
      return;
    }
    const projectKey = this.form.controls[this.EXISTING_PROJECT_KEY_FORM_CONTROL_NAME].value;
    const project = this.projects.find((proj: Project) => proj.projectKey === projectKey);
    const projectFileImportPayload: ProjectFileImportPayload = FileBasedImportProjectHelper.createProjectFileImportPayload(this.form.value, this.selectedTenant.tenantKey);

    if (this.shouldShowOverwritingUnversionedProjectModal(project)) {
      this.confirmImportWithUnversionedModal(projectKey, projectFileImportPayload);
    } else if (this.shouldShowLocalChangesFoundModal()) {
      this.confirmImportWithLocalChangesModal(projectKey, projectFileImportPayload);
    } else if (this.isInExistingProjectMode) {
      this.importIntoExistingProjectAfterConfirmation(projectFileImportPayload);
    } else {
      this.importProject(projectFileImportPayload);
    }
  }

  importProject(projectFileImportPayload: ProjectFileImportPayload): void {
    this.projectImport.emit(projectFileImportPayload);
  }

  importIntoExistingProjectAfterConfirmation(projectFileImportPayload: ProjectFileImportPayload): void {
    const projectName = TenantHelper.cropTenantFromKey(projectFileImportPayload.projectKey);
    this.modalNotificationService.openConfirmDialog({
      headerTitle: `Warning`,
      title: 'Overwriting existing project',
      description: `${projectName} project data will be lost and replaced with the imported project from ${projectFileImportPayload.projectFile.name} file. It cannot be undone. Do you want to continue?`
    }, {
      confirmButtonText: 'Import'
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed) {
        this.importProject(projectFileImportPayload);
      }
    });
  }

  cancel(): void {
    this.cancelClicked.emit();
  }

  get hasZipSelected(): boolean {
    return !_.isNil(this.form.value[this.PROJECT_ZIP_FORM_CONTROL_NAME]);
  }

  get canImport(): boolean {
    return this.form.valid;
  }

  get isInExistingProjectMode(): boolean {
    return this.form.value[PROJECT_KEY_MODE_FORM_CONTROL_NAME] === ProjectKeyMode.Existing;
  }

  get hasCreateProjectPermission(): boolean {
    return _.includes(this.selectedTenant._userAuthorization, OperationKey.CREATE_PROJECT);
  }

  get hasModifyProjectPermission(): boolean {
    return !_.isEmpty(this.projectsWithModifyPermission);
  }

  get canCreateNewProject(): boolean {
    return this.hasCreateProjectPermission && this.hasZipSelected;
  }

  get canSelectExistingProject(): boolean {
    return this.hasModifyProjectPermission && this.hasZipSelected;
  }

  get shouldShowDisabledNewProjectModeTooltip(): boolean {
    return !this.hasCreateProjectPermission && this.hasZipSelected;
  }

  get shouldShowDisabledExistingProjectModeTooltip(): boolean {
    return !this.hasModifyProjectPermission && this.hasZipSelected;
  }

  get projectForTenantExistsError(): string {
    return `This key is already in use for tenant ${this.selectedTenant.tenantKey}`;
  }

  get isImportModeExisting(): boolean {
    return this.form.controls[this.PROJECT_KEY_MODE_FORM_CONTROL_NAME].value === ProjectKeyMode.Existing;
  }

  private shouldShowOverwritingUnversionedProjectModal(project: Project | undefined): boolean {
    return this.isImportModeExisting && !_.isNil(project) && !ProjectHelper.isVersionedProject(project);
  }

  private shouldShowLocalChangesFoundModal(): boolean {
    return this.isImportModeExisting && !_.isNil(this.selectedExitingProjectMeta) && PublishProjectChangesetHelper.hasLocalChanges(this.selectedExitingProjectMeta);
  }

  private  confirmImportWithUnversionedModal(projectKey: string, projectFileImportPayload: ProjectFileImportPayload) {
    const zipFile: File = this.form.controls[this.PROJECT_ZIP_FORM_CONTROL_NAME].value;
    const zipFileName = zipFile.name;
    const projectName: string = TenantHelper.cropTenantFromKey(projectKey);
    const title = `Overwriting unversioned project`;
    const description = `${projectName} project configuration will be lost and replaced with the imported project from ${zipFileName} file. It cannot be undone. Do you want to continue?`;
    this.openConfirmImportModal(description, title, projectFileImportPayload);
  }

  private  confirmImportWithLocalChangesModal(projectKey: string, projectFileImportPayload: ProjectFileImportPayload) {
    const projectName: string = TenantHelper.cropTenantFromKey(projectKey);
    const title = `Local changes found`;
    const description = `Local changes pending to be published were found on ${projectName}. If you continue with the import those changes will be lost. It cannot be undone. Do you want to continue?`;
    this.openConfirmImportModal(description, title, projectFileImportPayload);
  }

  private openConfirmImportModal(description: string, title: string, projectFileImportPayload: ProjectFileImportPayload) {
      this.modalNotificationService.openConfirmDialog({
        headerTitle: `Warning`,
        title: title,
        description: description
      }, {
        confirmButtonText: 'Import'
      }).afterClosed().subscribe((confirmed?: boolean) => {
        if (confirmed) {
          this.importProject(projectFileImportPayload);
        }
      });
  }

  searchableProjectFormatFn = (project: Project): string => {
    return TenantHelper.cropTenantFromKey(project.projectKey);
  };

  updateSearchResult(filteredList: Project[]): void {
    this.filteredProjectList = filteredList;
  }

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }
}

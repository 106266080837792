import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import * as _ from 'lodash';

import { Pod, PodStatus } from '../inventory-kubernetes-status/deployed-service.model';

@Component({
  selector: 'adm4-pod-status-indicator',
  template: `
    <span class='pod-status'
          [ngbTooltip]='tooltip' placement='left'
          [ngClass]='serviceColor'>{{statusText}}</span>
    <ng-template #tooltip><span>{{statusText}}</span></ng-template>
  `,
  styleUrls: ['./pod-status-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PodStatusIndicatorComponent implements OnChanges {

  @Input() pod: Pod = {} as any;

  serviceColor: string;
  statusText: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pod) {
      this.serviceColor = this.resolveDeployedServiceColor(this.pod);
      this.statusText = this.resolvePodStatusText(this.pod);
    }
  }

  resolveDeployedServiceColor(pod: Pod): string {
    switch(pod.phase) {
      case PodStatus.Running:
        return pod.ready ? 'valid' : 'warning';
      case PodStatus.Succeeded:
      case PodStatus.Completed:
        return 'valid';
      case PodStatus.Unknown:
        return 'neutral';
      case PodStatus.Failed:
        return 'error';
      default:
        return 'warning';
    }
  }

  resolvePodStatusText(pod: Pod): string {
    if (_.isEqual(pod.phase, PodStatus.Running)) {
      const podStatusReadyExpression = pod.ready ? '(ready)' : '(not ready)';
      return pod.status + ' ' + podStatusReadyExpression;
    }
    return pod.status;
  }

}

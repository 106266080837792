/**
 * It models the issues of a project. It contains all the model classes and enum that relates to this model
 */

export class IssueModel {
  code: string;
  severity: IssueSeverity;
  message: string;
  target: Target[];
}

export class Target {
  sourceType: SourceType;
  value: string;
}

export enum SourceType {
  PROJECT = 'PROJECT',
  DEPLOYABLE_PATTERN = 'DEPLOYABLE_PATTERN',
  PATTERN = 'PATTERN',
  PROPERTY = 'PROPERTY',
  HOST = 'HOST',
  FIELD = 'FIELD',
  TENANT = 'TENANT',
  VARIABLE = 'VARIABLE',
  JOB = 'JOB'
}

export enum IssueSeverity {
  INTO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  /**
   * Not a real severity, it's info with unused code. But since the unused needs to be present separately at some places, this was added.
   */
  UNUSED = 'UNUSED',
}

const IssueSeverityValues = {
  [IssueSeverity.UNUSED]: 0,
  [IssueSeverity.INTO]: 1,
  [IssueSeverity.WARNING]: 2,
  [IssueSeverity.ERROR]: 3,
} as const;

const compareIssueSeverityDesc = (severity1: IssueSeverity, severity2: IssueSeverity): 1 | 0 | -1 => {
  const is1: number = IssueSeverityValues[severity1];
  const is2: number = IssueSeverityValues[severity2];
  if (is1 > is2) {
    return -1;
  }
  if (is1 < is2) {
    return 1;
  }
  return 0;
};

/**
 * Orders the `IssueModel`s by severity in descending order, ie.  error - warning - info
 * @param issue1
 * @param issue2
 */
export const compareIssueModelBySeverityDesc = (issue1: IssueModel, issue2: IssueModel): 1 | 0 | -1 => {
  return compareIssueSeverityDesc(issue1.severity, issue2.severity);
};

export const FAKE_VARIABLE_ISSUE_CODE = 'FAKE_VARIABLE_ISSUE';

export const UNUSED_PATTERN_ISSUE_CODE = 'PATTERN_UNUSED';

export const BUNDLE_OLD_VERSION_ISSUE_CODE = 'BUNDLE_OLD_VERSION';


import { EMPTY, forkJoin, from as observableFrom, Observable, of as observableOf, of, throwError } from 'rxjs';

import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  finalize, first,
  map, mapTo,
  mergeMap, shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EmptyAction, NevisAdminAction } from '../actions';
import { Action, select, Store } from '@ngrx/store';
import { InventoryService } from '../../inventory/inventory.service';
import {
  ChangePublishRequiredFlag,
  CheckUpdatesOfInventory,
  ConnectInventoryToVersionControl, ConnectInventoryToVersionControlSuccess,
  CreateInventory, DeleteInventory, DeleteInventorySuccess,
  FetchInventoryData,
  GetUpdatesOfInventory,
  InventoryActionTypes,
  LoadInventories, LoadInventoriesAndNavigate,
  LoadInventoriesSuccess, InventoriesAvailable,
  LoadInventoryContent,
  LoadInventoryContentSuccess, LoadInventoryResourcesWithUsage, LoadInventoryResourcesSuccess,
  LoadInventoryResourcesWithUsageSuccess, LoadInventoryTenantScopedResourcesWithUsage,
  LoadInventoryTenantScopedSecretsWithUsageSuccess,
  LoadTenantConstantsWithUsageSuccess, LoadTenantConstantsSuccess, LoadTenantConstants,
  NavigateToCreatedInventory,
  RedeployDeployment,
  ResetInventoriesAfterDeletingLast,
  SelectInventory,
  StoreInventoryFromLocalStorage,
  UpdateInventoryContent,
  UpdateInventoryTimestamp,
  UpdateInventoryTimestampSuccess,
  ValidateInventoryContent, ValidateInventoryContentBeforeSave,
  ValidateInventoryContentSuccess, LoadInventoryTenantScopedSecretsSuccess,
  SelectDiffInventory, LoadDiffInventoryContentSuccess, ClearLocalChangesOfInventory,
} from './inventory.actions';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import { FileService } from '../../common/services/file/file.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HTTP_STATUS_CONFLICT, HTTP_STATUS_FORBIDDEN } from '../../shared/http-status-codes.constants';
import * as _ from 'lodash';
import {
  GlobalConstantWithUsage, GlobalConstant,
  Inventory,
  InventoryMeta,
  ResourceWrapper, ResourceWrapperWithUsage,
  SecretResourceWrapperWithUsage, SecretResourceWrapper,
  SecretWrapperWithUsage, SecretWrapper,
} from '../../inventory/inventory.model';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { ValidationStatusHelper } from '../../common/helpers/validation-status.helper';
import { StoreDeploymentInventory, StoreDeploymentProject } from '../deploy';
import { AppState, Dictionary } from '../reducer';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { NavigationService } from '../../navbar/navigation.service';
import { localStorageInventoryKey } from '../../common/constants/local-storage-keys.constants';
import { allInventoriesListView, allInventoriesView, inventoryKeyView, selectedTenantKeyView } from '../views';
import { InventoryPublishRequiredPayload } from '../../inventory/inventory-publish-required-payload.model';
import { CreateInventoryPayload } from '../../inventory/create-inventory/create-inventory-payload';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { IImportEffectMixin, ImportEffectMixin } from '../import-effect.mixin';
import { LoadingService } from '../../modal-dialog/loading-modal/loading.service';
import { JobService } from '../../shared/job.service';
import { InventoryFileImportPayload } from '../../inventory/file-based-import-inventory/inventory-file-import-payload.model';
import { EffectWithErrorHandlingMixin, IEffectWithErrorHandlingMixin } from '../effect-with-error-handling.mixin';
import { NotificationMessage } from '../../notification/notification.model';
import { ErrorHelper } from '../../common/helpers/error.helper';
import { ConnectInventoryVersionControlPayload } from '../../inventory/connect-inventory-version-control/connect-inventory-version-control.payload';
import { CreateInventoryDialogService } from '../../inventory/create-inventory/create-inventory-dialog.service';
import { ImportInventoryDialogService } from '../../inventory/import-inventory/import-inventory-dialog.service';
import { RETURN_BUTTON_LABEL } from '../../notification/notification.constants';
import { InventoryErrorFieldSource } from '../../inventory/inventory.constants';
import { Branch } from '../../version-control/branch.model';
import { VersionControlService } from '../../version-control/version-control.service';
import { ProjectService } from '../../projects/project.service';
import { ProjectErrorFieldSource } from '../../projects/project.constants';
import { Project } from '../../projects/project.model';
import { InventoryDeploymentHistoryHelper } from '../../inventory/inventory-deployment-history/inventory-deployment-history.helper';
import { InventoryUpdateContentPayload } from '../../inventory/inventory-update-content-payload.model';
import { InventorySchemaTypeHelper } from '../../deployment-wizard/deployment-selection/inventory-list/inventory-schema-type.helper';
import { LocalChangesModalNotificationService } from '../../notification/local-changes-modal-notification.service';
import { filterEmpty, filterNotNil, Maybe, requireNonNull, timeoutWithMessage } from '../../common/utils/utils';
import { InventoryKubernetesSampleContentHelper } from '../../inventory/inventory-sample/inventory-kubernetes-sample-content.helper';
import { PatternService } from '../../patterns/pattern.service';
import { Pattern } from '../../patterns/pattern.model';
import { VariableService } from '../../variables/variable.service';
import { VariableModel } from '../../variables/variable.model';
import { InventorySampleContentHelper } from '../../inventory/inventory-sample/inventory-sample-content.helper';
import { LocalStatus } from '../../version-control/meta-info.model';

@Injectable()
@Mixin([ImportEffectMixin, EffectWithErrorHandlingMixin])
export class InventoryEffects implements IImportEffectMixin, IEffectWithErrorHandlingMixin {
  selectedTenantKeyMaybe$: Observable<string | null> = this.store$.pipe(select(selectedTenantKeyView));
  selectedTenantKey$: Observable<string> = this.store$.select(selectedTenantKeyView).pipe(
    filter(filterEmpty),
    shareReplay(1),
  );

  inventoryValidationDebouncingRate = 1000;

  /**
   * Implemented by ImportEffectMixin
   */
  importEffect: <P, SuccessAction extends NevisAdminAction<any>, ErrorAction extends NevisAdminAction<any>>(
    importActionType: string,
    serviceFn: (payload: P) => Observable<string>,
    handleSuccess: (payload: P) => SuccessAction,
    handleError: (err: HttpErrorResponse, payload: P) => Observable<ErrorAction>,
    keyExtractFunction: (payload: P) => string,
    importedType: string,
    importSource: string
  ) => Observable<SuccessAction | ErrorAction>;

  /**
   * Implemented by EffectWithErrorHandlingMixin
   */
  handleErrorAction: <T extends NevisAdminAction<any> = EmptyAction>(error: any, displayMessage: NotificationMessage, returnedAction?: T) => T;

   fetchInventoryDataOnUpdates: Observable<FetchInventoryData> = createEffect(() => this.createFetchInventoryDataOnUpdatesEffect());
   fetchInventoryData: Observable<LoadInventoryContent> = createEffect(() => this.createFetchInventoryDataEffect());
   loadInventoriesIfMissing: Observable<InventoriesAvailable> = createEffect(() => this.createLoadInventoriesIfMissing());
   loadInventories: Observable<LoadInventoriesSuccess | EmptyAction> = createEffect(() => this.createLoadInventoriesEffect());
   loadInventoryContent: Observable<LoadInventoryContentSuccess | ValidateInventoryContent | UpdateInventoryTimestamp | EmptyAction> = createEffect(() => this.createLoadInventoryContentEffect());
   loadDiffInventoryContent: Observable<LoadDiffInventoryContentSuccess | EmptyAction> = createEffect(() => this.createLoadDiffInventoryEffect());
   updateInventoryContent: Observable<LoadInventoryContent | LoadInventories | UpdateInventoryTimestampSuccess | EmptyAction> = createEffect(() => this.createUpdateInventoryContentEffect());
   updateInventoryTimestamp: Observable<UpdateInventoryTimestampSuccess> = createEffect(() => this.creatUpdateInventoryTimestampEffect());
   clearLocalChangesOfInventory: Observable<Action> = createEffect(() => this.createClearLocalChangesOfInventoryEffect());
   saveSelectedInventoryToLocalStorage: Observable<any> = createEffect(() => this.createSaveSelectedInventoryToLocalStorageEffect(), {dispatch: false});
   createNewInventory: Observable<UpdateInventoryContent | LoadInventoriesAndNavigate | EmptyAction> = createEffect(() => this.createNewInventoryEffect());
   importInventory: Observable<LoadInventoriesAndNavigate | LoadInventories | EmptyAction> = createEffect(() => this.createImportInventoryEffect());
   importInventoryFromZip: Observable<LoadInventoriesAndNavigate | EmptyAction> = createEffect(() => this.createImportInventoryFromZipEffect());
   connectInventoryToVersionControl: Observable<ConnectInventoryToVersionControlSuccess | LoadInventories | EmptyAction> = createEffect(() => this.createConnectInventoryToVersionControlEffect());
   checkUpdatesOfInventory: Observable<GetUpdatesOfInventory | EmptyAction> = createEffect(() => this.createCheckUpdatesOfInventoryEffect());
   getUpdatesOfInventory: Observable<FetchInventoryData | EmptyAction> = createEffect(() => this.createGetUpdatesOfInventoryEffect());
   loadInventoriesAndNavigate: Observable<LoadInventoriesSuccess | LoadInventoryContent | NavigateToCreatedInventory | EmptyAction> = createEffect(() => this.createLoadInventoriesAndNavigateEffect());
   navigateToCreatedInventory: Observable<any> = createEffect(() => this.createNavigateToCreatedInventoryEffect(), {dispatch: false});
   changePublishRequiredFlag: Observable<LoadInventories | EmptyAction> = createEffect(() => this.createChangePublishRequiredFlagEffect());

   loadInventoryResourcesWithUsage = createEffect(() => this.loadInventoryResourcesWithUsageEffect());
   loadInventoryResources = createEffect(() => this.loadInventoryResourcesEffect());
   loadInventoryTenantScopedResourcesWithUsage = createEffect(() => this.loadInventoryTenantScopedResourcesWithUsageEffect());
   loadInventoryTenantScopedResources = createEffect(() => this.loadInventoryTenantScopedResourcesEffect());

   loadInventoryTenantConstants = createEffect(() => this.loadInventoryTenantConstantsEffect());

  /**
   * Calling service to delete inventory, if error happens error modal will displayed and no further action will be needed
   * If inventory is deleted successfully, success modal is displayed, after it's closed delete success will be dispatch and further actions will be taken in related effect
   */
   deleteInventory: Observable<DeleteInventorySuccess | EmptyAction> = createEffect(() => this.createDeleteInventoryEffect());
  /**
   * This effect contains flow after deleting an inventory and takes next steps:
   * 1. List of inventories will be loaded again from the backend
   *   1.1. If loading inventories fails, the current inventory list will be used filtering deleted inventory out from it
   * 2. Navigation will be triggered next
   * 2.1. If last inventory was deleted we dispatch action which will clear inventories from store and navigate to inventory editor with no inventory selected
   * 2.2. Otherwise we stay on inventory settings screen
   * * IMPORTANT NOTE: navigation is triggered before handling success of loading inventories for 2 reasons:
   * * not to add extra conditional behavior into effects of that
   * * not to interfere with inventory.component routing subscription, as it would trigger its own navigation if inventory list changed
   * 2.2.1. After navigation happened loadInventoriesSuccess is dispatched to update list of inventories in the store
   * @type {Observable<ResetInventoriesAfterDeletingLast | LoadInventoriesSuccess>}
   */
   deleteInventorySuccess: Observable<ResetInventoriesAfterDeletingLast | LoadInventoriesSuccess> = createEffect(() => this.createDeleteInventorySuccessEffect());
   navigateToInventoryEditorAfterDeletingLast: Observable<any> = createEffect(() => this.createNavigateToInventoryEditorAfterDeletingLastEffect(), {dispatch: false});
   validateInventoryContent: Observable<ValidateInventoryContentSuccess | EmptyAction> = createEffect(() => this.createValidateInventoryContentEffect());
   validateInventoryContentBeforeSave: Observable<ValidateInventoryContentSuccess | UpdateInventoryContent | EmptyAction> = createEffect(() => this.createValidateInventoryContentBeforeSaveEffect());
   storeInventoryKeyForDeploymentSelection: Observable<StoreDeploymentInventory> = createEffect(() => this.createStoreInventoryKeyForDeploymentSelectionEffect());
   redeployDeployment: Observable<StoreDeploymentProject | StoreDeploymentInventory | EmptyAction> = createEffect(() => this.createRedeployDeploymentEffect());


  constructor(
    public store$: Store<AppState>,
    public actions$: Actions<NevisAdminAction<any>>,
    public loadingService: LoadingService,
    public jobService: JobService,
    private inventoryService: InventoryService,
    private projectService: ProjectService,
    private patternService: PatternService,
    private variableService: VariableService,
    private fileService: FileService,
    private versionControlService: VersionControlService,
    private navigationService: NavigationService,
    private createInventoryDialogService: CreateInventoryDialogService,
    private importInventoryDialogService: ImportInventoryDialogService,
    private toastNotificationService: ToastNotificationService,
    public modalNotificationService: ModalNotificationService,
    private localChangesModalNotificationService: LocalChangesModalNotificationService
  ) {}

  private createFetchInventoryDataOnUpdatesEffect(): Observable<FetchInventoryData> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.SelectInventory))
      .pipe(
        map(() => {
          return new FetchInventoryData();
        })
      );
  }

  private createFetchInventoryDataEffect(): Observable<LoadInventoryContent> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.FetchInventoryData))
      .pipe(
        withLatestFrom(this.store$.pipe(select(inventoryKeyView))),
        filter(([, inventoryKey]: [FetchInventoryData, string | null]) => !_.isNil(inventoryKey)),
        switchMap(([, inventoryKey]: [FetchInventoryData, string]) => {
          return of(
            new LoadInventoryContent(inventoryKey)
          );
        })
      );
  }

  private createLoadInventoriesIfMissing(): Observable<InventoriesAvailable> {
    return this.actions$.pipe(
      ofType(InventoryActionTypes.LoadInventoriesIfMissing),
      exhaustMap((): Observable<InventoriesAvailable> => {
        return this.store$.select(allInventoriesListView).pipe(
            first(),
            switchMap((allInventories: Array<Inventory>): Observable<InventoriesAvailable> => {
              if (_.isEmpty(allInventories)) {
                this.store$.dispatch(new LoadInventories());
                return this.actions$.pipe(
                  ofType(InventoryActionTypes.LoadInventoriesSuccess),
                  first(),
                  mapTo(new InventoriesAvailable()),
                  timeoutWithMessage(5000, 'InventoryEffects#loadInventoriesIfMissing did not complete'),
                );
              } else {
                return of(new InventoriesAvailable());
              }
            }),
        );
      }),
    );
  }

  private createLoadInventoriesEffect(): Observable<LoadInventoriesSuccess | EmptyAction> {
    return this.actions$.pipe(
      ofType(InventoryActionTypes.LoadInventories),
      switchMap((): Observable<string> => this.selectedTenantKey$.pipe(first())),
      switchMap((selectedTenantKey: string) => this.inventoryService.getInventoriesOfTenant(selectedTenantKey)
        .pipe(
          map((inventories: Inventory[]) => new LoadInventoriesSuccess(inventories)),
          catchError((err: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(err, {title: 'Failed to load inventories', description: 'Error while loading inventories'})))
        )
      )
    );
  }

  private createLoadInventoryContentEffect(): Observable<LoadInventoryContentSuccess | ValidateInventoryContent | UpdateInventoryTimestamp | EmptyAction> {
    return this.actions$.pipe(ofType(InventoryActionTypes.LoadInventoryContent))
      .pipe(
        map((action: LoadInventoryContent) => action.payload),
        switchMap((inventoryKey: string) => {
          return this.fileService.loadYamlFileContent(`/inventories/${inventoryKey}`)
            .pipe(
              mergeMap((inventoryContent) => {
                return [
                  new LoadInventoryContentSuccess({inventoryKey, inventoryContent}),
                  new ValidateInventoryContent(inventoryContent),
                  new UpdateInventoryTimestamp(inventoryKey)
                ];
              }),
              catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Something went wrong with loading inventory content.'})))
            );
        })
      );
  }

  private createLoadDiffInventoryEffect(): Observable<LoadDiffInventoryContentSuccess | EmptyAction> {
    return this.actions$.pipe(
        ofType(InventoryActionTypes.SelectDiffInventory),
        map((action: SelectDiffInventory) => action.payload),
        switchMap((selectedInventoryKey: string | null): Observable<LoadDiffInventoryContentSuccess | EmptyAction> => {
          if (selectedInventoryKey) {
            return this.fileService.loadYamlFileContent(`/inventories/${selectedInventoryKey}`).pipe(
                map((inventoryContent: string) => new LoadDiffInventoryContentSuccess(inventoryContent)),
                catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(
                    error,
                    {title: 'Error', description: 'Something went wrong when loading the content of inventory ' + selectedInventoryKey},
                ))),
            );
          } else {
            return EMPTY;
          }
        }),
    );
  }

  private createUpdateInventoryContentEffect(): Observable<LoadInventoryContent | LoadInventories | UpdateInventoryTimestampSuccess | EmptyAction> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.UpdateInventoryContent))
      .pipe(
        map((action: UpdateInventoryContent) => action.payload),
        switchMap((payload: InventoryUpdateContentPayload) => {
          return this.fileService.saveYamlContentToFile(`/inventories/${payload.inventoryKey}`, payload.content).pipe(
            mergeMap(() => {
              this.toastNotificationService.showSuccessToast(`The inventory has been successfully updated.`, 'Successfully updated');
              return [
                new LoadInventoryContent(payload.inventoryKey),
                new LoadInventories(),
                new UpdateInventoryTimestampSuccess('')
              ];
            }),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Something went wrong during updating the inventory.'})))
          );
        })
      );
  }

  private creatUpdateInventoryTimestampEffect(): Observable<UpdateInventoryTimestampSuccess> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.UpdateInventoryTimestamp))
      .pipe(
        map((action: UpdateInventoryTimestamp) => action.payload),
        switchMap((inventoryKey: string) => {
          return this.inventoryService.getInventoryTimeStamp(inventoryKey).pipe(map((timestamp) => {
            return new UpdateInventoryTimestampSuccess(timestamp.timestamp);
          }));
        }));
  }

  private createClearLocalChangesOfInventoryEffect(): Observable<Action> {
    return this.actions$.pipe(
      ofType(InventoryActionTypes.ClearLocalChangesOfInventory),
      map((action: ClearLocalChangesOfInventory): Maybe<string> => action.payload),
      filterNotNil(),
      exhaustMap((inventoryKey: string): Observable<Action> => {
        const inventoryKeyLabel: string = TenantHelper.cropTenantFromKey(inventoryKey);
        return this.inventoryService.getInventoryMeta(inventoryKey).pipe(
          first(),
          switchMap((meta: InventoryMeta): Observable<Action> => {
            if (LocalStatus.Unmodified === meta.content.localStatus) {
              this.modalNotificationService.openInfoDialog(
                {title: 'No local changes found', description: `No local changes found in the inventory <strong>${inventoryKeyLabel}</strong>.`},
              );
              return EMPTY;
            } else {
              return this.confirmAndClearInventoryLocalChanges(inventoryKey);
            }
          }),
        );
      }),
    );
  }

  private confirmAndClearInventoryLocalChanges(inventoryKey: string): Observable<Action> {
    const inventoryKeyLabel: string = TenantHelper.cropTenantFromKey(inventoryKey);
    return this.modalNotificationService.openConfirmDialog({
      title: 'Local changes will be lost',
      description: `<br/>Your local changes to inventory <strong>${inventoryKeyLabel}</strong> will be lost after you clear them.<br/><br/>Do you want to continue?`,
    }, {
      confirmButtonText: 'Continue',
    }).afterClosed().pipe(
      first(),
      switchMap((confirmed: Maybe<boolean>): Observable<Action> => {
        if (confirmed) {
          this.loadingService.showLoading({
            title: `The inventory ${inventoryKeyLabel} is currently being reset.`,
            description: `This may take a few seconds`,
          });
          return this.inventoryService.clearLocalChanges(inventoryKey).pipe(
            first(),
            catchError((error: HttpErrorResponse): Observable<Action> => {
              this.modalNotificationService.openHttpErrorDialog(error, `Could not reset inventory ${inventoryKeyLabel}`);
              return EMPTY;
            }),
            finalize(() => this.loadingService.hideLoading()),
            tap(() => this.modalNotificationService.openSuccessDialog({
              title: 'Reset successful',
              description: `The inventory <strong>${inventoryKeyLabel}</strong> has been reset successfully.`,
            })),
            map(() => new LoadInventoryContent(inventoryKey)),
          );
        } else {
          return EMPTY;
        }
      }),
    );
  }

  private createSaveSelectedInventoryToLocalStorageEffect(): Observable<any> {
    return this.actions$.pipe(ofType(InventoryActionTypes.SelectInventory))
      .pipe(
        map((action: SelectInventory) => action.payload),
        filter((inventoryKey: string) => !_.isEmpty(inventoryKey)),
        withLatestFrom(this.selectedTenantKeyMaybe$.pipe(map((tenantKey: string) => LocalStorageHelper.prefixKey(localStorageInventoryKey, tenantKey)))),
        tap(([inventoryKey, inventoryKeyLocalStorageKey]: [string, string]) => LocalStorageHelper.save(inventoryKeyLocalStorageKey, inventoryKey))
      );
  }

  private createNewInventoryEffect(): Observable<UpdateInventoryContent | LoadInventoriesAndNavigate | EmptyAction> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.CreateInventory))
      .pipe(
        map((action: CreateInventory) => action.payload),
        switchMap((payload: CreateInventoryPayload) => {
          const inventoryName = TenantHelper.cropTenantFromKey(payload.inventory.inventoryKey);
          const projectKeyForSampleGeneration = payload.projectKeyForSampleGeneration;
          return this.inventoryService.createInventory(payload.inventory).pipe(
            switchMap((inventory: Inventory) => {

              const backendCalls: Observable<any>[] = [];

              const isKubernetes = InventorySchemaTypeHelper.isKubernetesDeployment(payload.inventory.schemaType);
              if (isKubernetes && !_.isNil(payload.kubernetesInventoryContent)) {
                const kubernetesInventoryContentElement = payload.kubernetesInventoryContent['kubernetes-cluster'];
                backendCalls.push(this.inventoryService.createInventorySecret(payload.inventory.inventoryKey, kubernetesInventoryContentElement.token));
              }

              if (payload.includeSample && projectKeyForSampleGeneration) {
                backendCalls.push(this.patternService.getAllPatterns(projectKeyForSampleGeneration));
                backendCalls.push(this.variableService.getAllVariablesOfProject(projectKeyForSampleGeneration));
              }

              if (_.isEmpty(backendCalls)) {
                return this.handleCreateClassicInventorySuccess([inventory], payload, inventoryName);
              }

              return forkJoin(backendCalls)
                .pipe(
                  mergeMap((results: any[]) => {
                    results.unshift(inventory); //always the inventory has to be the 1st item
                    if (isKubernetes) {
                      return this.handleCreateKubernetesInventorySuccess(results, payload, inventoryName);
                    } else {
                      return this.handleCreateClassicInventorySuccess(results, payload, inventoryName);
                    }
                  }),
                  catchError((error: HttpErrorResponse) => {
                    this.handleCreateInventoryError(error, payload, inventoryName);
                    return observableOf(new EmptyAction());
                  })
                );
            }),
            catchError((error: HttpErrorResponse) => {
              this.handleCreateInventoryError(error, payload, inventoryName);
              return observableOf(new EmptyAction());
            }));
        })
      );
  }

  private handleCreateKubernetesInventorySuccess(results: any[], payload: CreateInventoryPayload, inventoryName: string): Observable<UpdateInventoryContent | LoadInventoriesAndNavigate> {
    if (_.isNil(results) || _.isEmpty(results)) {
      throw new Error('Error while creating new Kubernetes inventory. Backend call did not return expected results.');
    }

    const inventory: Inventory = results[0];
    const secret: string = results[1];
    const patterns: Pattern[] = results.length >= 3 ? results[2] : [];
    const variables: VariableModel[] = results.length >= 4 ? results[3] : [];

    const newPayload = this.replaceSecretInInventory(payload, secret);

    const content = InventoryKubernetesSampleContentHelper.createInitialKubernetesInventoryContent(newPayload.includeSample, newPayload.kubernetesInventoryContent, patterns, variables);
    return this.handleCreateNewInventorySuccess(content, inventoryName, inventory.inventoryKey);
  }

  private handleCreateClassicInventorySuccess(results: any[], payload: CreateInventoryPayload, inventoryName: string): Observable<UpdateInventoryContent | LoadInventoriesAndNavigate> {
    if (_.isNil(results) || _.isEmpty(results)) {
      throw new Error('Error while creating new classic inventory. Backend call did not return expected results.');
    }
    const inventory: Inventory = results[0];
    const patterns: Pattern[] = results.length >= 2 ? results[1] : [];
    const variables: VariableModel[] = results.length >= 3 ? results[2] : [];

    const content = InventorySampleContentHelper.createInitialInventoryContent(payload.includeSample, patterns, variables);
    return this.handleCreateNewInventorySuccess(content, inventoryName, inventory.inventoryKey);
  }

  /**
   * Replace free-text token with secret id in the inventory content (in the given CreateInventoryPayload object).
   *
   * @param payload the CreateInventoryPayload in which we have to replace the free-text token.
   * @param secret the id of the secret (for example: 'secret://b3ead344a64d8a6f6df1f202')
   *
   * @return the new (modified) CreateInventoryPayload object.
   */
  private replaceSecretInInventory(payload: CreateInventoryPayload, secret: string): CreateInventoryPayload {
    // We need to clone the payload object because it is not modifiable.
    const newPayload = _.cloneDeep(payload);
    if (!_.isNil(newPayload.kubernetesInventoryContent)) {
      const newKubernetesInventoryContent = newPayload.kubernetesInventoryContent['kubernetes-cluster'];
      newKubernetesInventoryContent.token = secret;
    }
    return newPayload;
  }

  private handleCreateNewInventorySuccess(inventoryContent: string, inventoryName, inventoryKey: string): Observable<UpdateInventoryContent | LoadInventoriesAndNavigate> {
    this.toastNotificationService.showSuccessToast(`The inventory ${inventoryName} has been created successfully.`, 'Successfully created');
    return of(new UpdateInventoryContent({inventoryKey: inventoryKey, content: inventoryContent}), new LoadInventoriesAndNavigate(inventoryKey));
  }

  private createImportInventoryEffect(): Observable<LoadInventoriesAndNavigate | LoadInventories | EmptyAction> {
    return this.importEffect<Inventory, LoadInventoriesAndNavigate, EmptyAction | LoadInventories>(
      InventoryActionTypes.ImportInventory,
      (inventory: Inventory) => this.inventoryService.importInventory(inventory),
      (inventory: Inventory) => {
        const inventoryName = TenantHelper.cropTenantFromKey(inventory.inventoryKey);
        this.toastNotificationService.showSuccessToast(`${inventoryName} was successfully imported from Git`, 'Successfully imported');
        return new LoadInventoriesAndNavigate(inventory.inventoryKey);
      },
      (error: HttpErrorResponse, inventory: Inventory) => {
        if (error.status === HTTP_STATUS_CONFLICT) {
          this.handleImportInventoryConflict(error, inventory);
        } else {
          const inventoryName = TenantHelper.cropTenantFromKey(inventory.inventoryKey);
          console.error(`Something went wrong with importing inventory: \n%O`, error);
          this.toastNotificationService.showErrorToast(`The inventory ${inventoryName} could not be imported.`);
          return of(new LoadInventories());
        }
        return observableOf(new EmptyAction());
      },
      (inventory: Inventory) => inventory.inventoryKey,
      'inventory',
      'Git'
    );
  }

  private createImportInventoryFromZipEffect(): Observable<LoadInventoriesAndNavigate | EmptyAction> {
    return this.importEffect<InventoryFileImportPayload, LoadInventoriesAndNavigate, EmptyAction>(
      InventoryActionTypes.ImportInventoryFile,
      (inventoryFileImportPayload: InventoryFileImportPayload) => this.inventoryService.importInventoryFile(inventoryFileImportPayload),
      (payload: InventoryFileImportPayload) => {
        const inventoryName = TenantHelper.cropTenantFromKey(payload.inventoryKey);
        this.toastNotificationService.showSuccessToast(`${inventoryName} was successfully imported from Zip`, 'Successfully imported');
        return new LoadInventoriesAndNavigate(payload.inventoryKey);
      },
      (error: HttpErrorResponse, payload: InventoryFileImportPayload) => {
        const inventoryName = TenantHelper.cropTenantFromKey(payload.inventoryKey);
        console.error(`Something went wrong with importing inventory: \n%O`, error);
        this.toastNotificationService.showErrorToast(`The inventory ${inventoryName} could not be imported.`);
        return of(new EmptyAction());
      },
      (payload: InventoryFileImportPayload) => payload.inventoryKey,
      'inventory',
      'Zip'
    );
  }

  private createConnectInventoryToVersionControlEffect(): Observable<ConnectInventoryToVersionControlSuccess | LoadInventories | EmptyAction> {
    return this.actions$
      .pipe(
        ofType(InventoryActionTypes.ConnectInventoryToVersionControl),
        map((action: ConnectInventoryToVersionControl) => action.payload),
        switchMap((payload: ConnectInventoryVersionControlPayload) => this.inventoryService.connectInventoryToVersionControl(payload.inventoryKey, payload.inventoryVersionControlData)
          .pipe(
            mergeMap((inventory: Inventory) => {
              const inventoryName = TenantHelper.cropTenantFromKey(payload.inventoryKey);
              this.toastNotificationService.showSuccessToast(`${inventoryName} was successfully connected to Git`, 'Successfully connected');
              return of(
                new ConnectInventoryToVersionControlSuccess(inventory),
                new LoadInventories()
              );
            }),
            catchError((err: HttpErrorResponse) => {
              console.error(`Something went wrong while connecting inventory to Git:`, err);
              const inventoryName = TenantHelper.cropTenantFromKey(payload.inventoryKey);
              if (ErrorHelper.responseErrorHasDetail(err)) {
                this.modalNotificationService.openErrorDialog({title: 'Failed to connect to Git', description: err.error.error.detail});
              } else {
                this.modalNotificationService.openErrorDialog({description: `The inventory ${inventoryName} could not be connected to ${payload.inventoryVersionControlData.repository}.`});
              }
              return of(new EmptyAction());
            })
          )
        )
      );
  }

  private createCheckUpdatesOfInventoryEffect(): Observable<GetUpdatesOfInventory | EmptyAction> {
    return this.actions$
      .pipe(
        ofType(InventoryActionTypes.CheckUpdatesOfInventory),
        map((action: CheckUpdatesOfInventory) => action.payload),
        withLatestFrom(this.store$.pipe(select(allInventoriesView))),
        map(([inventoryKey, inventories]: [string, Dictionary<Inventory>]) => inventories[inventoryKey]),
        switchMap((inventory: Inventory | undefined) => {
          if (_.isNil(inventory) || _.isNil(inventory.repository) || _.isNil(inventory.branch)) {
            this.modalNotificationService.openErrorDialog({description: 'Cannot check for updates because inventory data is missing.'});
            return observableOf(new EmptyAction());
          }
          return forkJoin([
            this.inventoryService.getInventoryMeta(inventory.inventoryKey),
            this.versionControlService.getBranchByRepoAndName(inventory.repository, inventory.branch)
          ]).pipe(
            mergeMap(([inventoryMeta, branch]: [InventoryMeta, Branch | undefined]) => {
              const hasRemoteChange = !_.isNil(branch) && branch.commitId !== inventoryMeta.localHead;
              if (!hasRemoteChange) {
                const inventoryName = TenantHelper.cropTenantFromKey(inventory.inventoryKey);
                this.modalNotificationService.openInfoDialog({title: 'No updates found', description: `There are no updates available for inventory ${inventoryName}. The inventory is up to date.`});
                return of(new EmptyAction());
              }
              if (_.isNil(inventoryMeta.lastModification)) {
                return of(new GetUpdatesOfInventory(inventory.inventoryKey));
              }
              return this.localChangesModalNotificationService.openConfirmDialog({
                title: 'Inventory not up to date',
                description: ''
              }, {
                confirmButtonText: 'Cancel',
                cancelButtonText: 'Update inventory',
                inventoryKey: inventory.inventoryKey
              }).afterClosed().pipe(
                map((confirmed?: boolean) => confirmed === false ? new GetUpdatesOfInventory(inventory.inventoryKey) : new EmptyAction())
              );
            }),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Failed to update', description: 'An error occurred while trying to check for updates'})))
          );
        })
      );
  }

  private createGetUpdatesOfInventoryEffect(): Observable<FetchInventoryData | EmptyAction> {
    return this.actions$
      .pipe(
        ofType(InventoryActionTypes.GetUpdatesOfInventory),
        map((action: GetUpdatesOfInventory) => action.payload),
        tap((inventoryKey: string) => {
          const inventoryName: string = TenantHelper.cropTenantFromKey(inventoryKey);
          this.loadingService.showLoading({title: `The inventory ${inventoryName} is currently being updated`, description: 'This may take a few seconds.'});
        }),
        switchMap((inventoryKey: string) => this.inventoryService.triggerUpdatesOfInventory(inventoryKey).pipe(
          switchMap((jobUrl: string) => this.jobService.pollJob(jobUrl)),
          map(() => {
            this.modalNotificationService.openSuccessDialog({title: 'Update successful', description: 'The inventory has been updated successfully.'});
            return new FetchInventoryData();
          }),
          catchError((error) => {
            const inventoryName: string = TenantHelper.cropTenantFromKey(inventoryKey);
            return of(this.handleErrorAction<EmptyAction>(error, {title: `Failed to update inventory ${inventoryName}`, description: 'Error while loading inventory updates'}));
          }),
          finalize(() => this.loadingService.hideLoading())
        ))
      );
  }

  private createLoadInventoriesAndNavigateEffect(): Observable<LoadInventoriesSuccess | LoadInventoryContent | NavigateToCreatedInventory | EmptyAction> {
    return this.actions$
      .pipe(
        ofType(InventoryActionTypes.LoadInventoriesAndNavigate),
        map((action: LoadInventoriesAndNavigate) => action.payload),
        withLatestFrom(this.selectedTenantKeyMaybe$),
        switchMap(([inventoryKey, selectedTenantKey]: [string, string]) => this.inventoryService.getInventoriesOfTenant(selectedTenantKey)
          .pipe(
            mergeMap((inventories: Inventory[]) => of(new LoadInventoriesSuccess(inventories), new LoadInventoryContent(inventoryKey), new NavigateToCreatedInventory(inventoryKey))),
            catchError((err: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(err, {description: 'Error while loading inventories list'})))
          )
        )
      );
  }

  private createNavigateToCreatedInventoryEffect(): Observable<any> {
    return this.actions$
      .pipe(
        ofType(InventoryActionTypes.NavigateToCreatedInventory),
        map((action: NavigateToCreatedInventory) => action.payload),
        map((inventoryKey: string) => this.navigationService.navigateToInventory(inventoryKey))
      );
  }

  private createChangePublishRequiredFlagEffect(): Observable<LoadInventories | EmptyAction> {
    return this.actions$
      .pipe(
        ofType(InventoryActionTypes.ChangePublishRequiredFlag),
        map((action: ChangePublishRequiredFlag) => action.payload),
        switchMap((inventoryChangePublishRequiredPayload: InventoryPublishRequiredPayload) =>
          this.inventoryService.updatePublishRequiredFlagOnInventory(inventoryChangePublishRequiredPayload.inventoryKey, inventoryChangePublishRequiredPayload.publishRequired)
            .pipe(
              map(() => new LoadInventories()),
              tap(() => this.toastNotificationService.showSuccessToast('Inventory updated successfully.')),
              catchError((err: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(err, {description: 'Could not update publish required flag.'})))
            )
        )
      );
  }

  private createDeleteInventoryEffect(): Observable<DeleteInventorySuccess | EmptyAction> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.DeleteInventory))
      .pipe(
        map((action: DeleteInventory) => action.payload),
        switchMap((inventoryKey: string) => {
          const inventoryName = TenantHelper.cropTenantFromKey(inventoryKey);
          return this.inventoryService.deleteInventory(inventoryKey).pipe(
            mergeMap(() => this.modalNotificationService.openSuccessDialog({title: 'Delete inventory ', description: `${inventoryName} successfully deleted`}).afterClosed()),
            map(() => new DeleteInventorySuccess(inventoryKey)),
            catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Delete inventory', description: `Error while deleting inventory ${inventoryName}`})))
          );
        })
      );
  }

  private createDeleteInventorySuccessEffect(): Observable<ResetInventoriesAfterDeletingLast | LoadInventoriesSuccess> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.DeleteInventorySuccess))
      .pipe(
        tap(() => LocalStorageHelper.delete(localStorageInventoryKey))).pipe(
        map((action: DeleteInventorySuccess) => action.payload),
        withLatestFrom(this.store$.pipe(select(allInventoriesView)).pipe(map(_.values)), this.selectedTenantKeyMaybe$),
        mergeMap(([inventoryKey, inventories, selectedTenantKey]: [string, Inventory[], string]) => {
          return this.inventoryService.getInventoriesOfTenant(selectedTenantKey).pipe(
            // if reloading inventory lists somehow failed use current list and filter out inventoryKey of deleted inventory from it
            catchError(() => inventories.filter(inventory => inventory.inventoryKey !== inventoryKey))
          );
        }),
        mergeMap((inventories: Inventory[]) => {
          if (_.isEmpty(inventories)) {
            return of(new ResetInventoriesAfterDeletingLast());
          } else {
            const inventoryToSelect = inventories[0];
            return observableFrom(this.navigationService.navigateToInventorySettings(inventoryToSelect.inventoryKey)).pipe(map(() => {
              return new LoadInventoriesSuccess(inventories);
            }));
          }
        })
      );
  }

  private createNavigateToInventoryEditorAfterDeletingLastEffect(): Observable<any> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.ResetInventoriesAfterDeletingLast))
      .pipe(tap(() => this.navigationService.navigateToInventories()));
  }

  private createValidateInventoryContentEffect(): Observable<ValidateInventoryContentSuccess | EmptyAction> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.ValidateInventoryContent))
      .pipe(
        map((action: ValidateInventoryContent) => action.payload),
        withLatestFrom(this.store$.pipe(select(inventoryKeyView))),
        filter(([, inventoryKey]: [string, string | null]) => !_.isNil(inventoryKey)),
        debounceTime(this.inventoryValidationDebouncingRate),
        switchMap(([content, inventoryKey]: [string, string]) => {
          return this.fileService.saveYamlContentToFile(`/inventories/${inventoryKey}/validation`, content)
            .pipe(
              map(response => response._status || null),
              map((status: ValidationStatus<InventoryValidationIssue> | null) => {
                return new ValidateInventoryContentSuccess(status);
              }),
              catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Something went wrong during validating the inventory.'})))
            );
        })
      );
  }

  private createValidateInventoryContentBeforeSaveEffect(): Observable<ValidateInventoryContentSuccess | UpdateInventoryContent | EmptyAction> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.ValidateInventoryContentBeforeSave))
      .pipe(
        map((action: ValidateInventoryContentBeforeSave) => action.payload),
        withLatestFrom(this.store$.pipe(select(inventoryKeyView))),
        filter(([, inventoryKey]: [string, string | null]) => !_.isNil(inventoryKey)),
        switchMap(([content, inventoryKey]: [string, string]) => {
          return this.fileService.saveYamlContentToFile(`/inventories/${inventoryKey}/validation`, content)
            .pipe(
              map(response => response._status || null),
              mergeMap((status: ValidationStatus<InventoryValidationIssue> | null) => {
                if (ValidationStatusHelper.isValidationValid(status) || ValidationStatusHelper.isValidationWithInfo(status)) {
                  return of(new UpdateInventoryContent({inventoryKey: inventoryKey, content: content}));
                } else {
                  return this.modalNotificationService.openConfirmErrorDialog({
                    headerTitle: `Error`,
                    title: `Inventory has errors`,
                    description: this.getDescription(requireNonNull(status))
                  }, {
                    confirmButtonText: 'Save anyway'
                  }).afterClosed()
                    .pipe(
                      mergeMap((confirmed?: boolean) => {
                        let actionsToDispatch: (ValidateInventoryContentSuccess | UpdateInventoryContent)[] = [new ValidateInventoryContentSuccess(status)];
                        if (confirmed === true) {
                          // store is dispatched here because this is just a callback and by the time it's called effect has been already executed
                          actionsToDispatch = _.concat(actionsToDispatch, [new UpdateInventoryContent({inventoryKey: inventoryKey, content: content})]);
                        }
                        return of(...actionsToDispatch);
                      })
                    );
                }
              }),
              catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Error', description: 'Something went wrong during validating the inventory.'})))
            );
        })
      );
  }

  private getDescription(status: ValidationStatus<InventoryValidationIssue>): string {
    const descBeginningText = 'Would you like to save anyway?<br\>';
    if (_.size(status._errors) > 1 || _.size(status._warnings) > 1) {
      const listOfIssues = _.join(['<li>', _.map(_.concat([], status._errors || [], status._warnings || []), 'detail').join('</li><li>'), '</li>'], '');
      return descBeginningText + `<div class='scrollable-container'><ul> ${listOfIssues}</ul></div> `;
    }
    const errorDetails = _.map(_.concat([], status._errors || [], status._warnings || []), 'detail').join('<br/>');
    return descBeginningText + errorDetails;
  }

  private createStoreInventoryKeyForDeploymentSelectionEffect(): Observable<StoreDeploymentInventory> {
    return this.actions$
      .pipe(
        ofType(InventoryActionTypes.SelectInventory, InventoryActionTypes.StoreInventoryFromLocalStorage),
        map((action: SelectInventory | StoreInventoryFromLocalStorage) => action.payload),
        filter((inventoryKey: string | null) => !_.isNil(inventoryKey)),
        map((inventoryKey: string) => new StoreDeploymentInventory(inventoryKey))
      );
  }

  private loadInventoryResourcesWithUsageEffect(): Observable<LoadInventoryResourcesWithUsageSuccess> {
    return this.actions$
      .pipe(
        ofType(InventoryActionTypes.LoadInventoryResourcesWithUsage),
        map((action: LoadInventoryResourcesWithUsage) => action.payload),
        filter((inventoryKey: string | null) => !_.isNil(inventoryKey)),
        switchMap((inventoryKey: string) => forkJoin([
          this.inventoryService.getInventorySecretsWithUsage(inventoryKey),
          this.inventoryService.getInventoryResourcesWithUsage(inventoryKey),
          this.inventoryService.getInventorySecretResourcesWithUsage(inventoryKey)
        ])),
        map(([secrets, secretResources, resources]: [SecretWrapperWithUsage[], ResourceWrapperWithUsage[], SecretResourceWrapperWithUsage[]]) =>
          new LoadInventoryResourcesWithUsageSuccess({secrets: secrets, secretResources: resources, resources: secretResources}))
      );
  }

  private loadInventoryResourcesEffect(): Observable<LoadInventoryResourcesSuccess> {
    return this.actions$
      .pipe(
        ofType(InventoryActionTypes.LoadInventoryResources),
        map((action: LoadInventoryResourcesWithUsage) => action.payload),
        filter((inventoryKey: string | null) => !_.isNil(inventoryKey)),
        switchMap((inventoryKey: string) => forkJoin([
          this.inventoryService.getInventorySecrets(inventoryKey),
          this.inventoryService.getInventoryResources(inventoryKey),
          this.inventoryService.getInventorySecretResources(inventoryKey)
        ])),
        map(([secrets, secretResources, resources]: [SecretWrapper[], ResourceWrapper[], SecretResourceWrapper[]]) =>
          new LoadInventoryResourcesSuccess({secrets: secrets, secretResources: resources, resources: secretResources}))
      );
  }

  private loadInventoryTenantScopedResourcesWithUsageEffect(): Observable<LoadInventoryTenantScopedSecretsWithUsageSuccess> {
    return this.actions$
      .pipe(ofType(InventoryActionTypes.LoadInventoryTenantScopedResourcesWithUsage))
      .pipe(
        withLatestFrom(this.selectedTenantKeyMaybe$),
        filter(([,tenantKey]: [LoadInventoryTenantScopedResourcesWithUsage, string | null]) => !_.isNil(tenantKey)),
        switchMap(([,tenantKey]: [LoadInventoryTenantScopedResourcesWithUsage, string]) => {
          return forkJoin([
            this.inventoryService.getTenantSecretsWithUsage(tenantKey),
            this.inventoryService.getTenantResourcesWithUsage(tenantKey),
            this.inventoryService.getTenantSecretResourcesWithUsage(tenantKey),
          ]);
        }),
        map(([secrets, secretResources, resources]: [SecretWrapperWithUsage[], ResourceWrapperWithUsage[], SecretResourceWrapperWithUsage[]]) =>
          new LoadInventoryTenantScopedSecretsWithUsageSuccess(
              {secrets: secrets, secretResources: resources, resources: secretResources})
        ),
      );
  }

  private loadInventoryTenantScopedResourcesEffect(): Observable<LoadInventoryTenantScopedSecretsSuccess> {
    return this.actions$.pipe(
      ofType(InventoryActionTypes.LoadInventoryTenantScopedResources),
      withLatestFrom(this.selectedTenantKey$),
      switchMap(([_action, tenantKey]: [LoadInventoryTenantScopedResourcesWithUsage, string]) =>
        forkJoin([
          this.inventoryService.getTenantSecrets(tenantKey),
          this.inventoryService.getTenantResources(tenantKey),
          this.inventoryService.getTenantSecretResources(tenantKey),
        ])
      ),
      map(([secrets, secretResources, resources]: [SecretWrapper[], ResourceWrapper[], SecretResourceWrapper[]]) =>
          new LoadInventoryTenantScopedSecretsSuccess(
              {secrets: secrets, secretResources: resources, resources: secretResources})
      ),
    );
  }

  private loadInventoryTenantConstantsEffect(): Observable<LoadTenantConstantsWithUsageSuccess | LoadTenantConstantsSuccess> {
    return this.actions$.pipe(
      ofType(InventoryActionTypes.LoadTenantConstants),
      withLatestFrom(this.selectedTenantKey$),
      switchMap(([action, selectedTenantKey]: [LoadTenantConstants, string]): Observable<LoadTenantConstantsWithUsageSuccess | LoadTenantConstantsSuccess> => {
        if (action.payload.usedIn) {
          return this.inventoryService.getGlobalConstantsWithUsageForTenant(selectedTenantKey)
              .pipe(map((gcList: GlobalConstantWithUsage[]) => new LoadTenantConstantsWithUsageSuccess(gcList)));
        } else {
          return this.inventoryService.getGlobalConstantsForTenant(selectedTenantKey)
              .pipe(map((gcList: GlobalConstant[]) => new LoadTenantConstantsSuccess(gcList)));
        }
      }),
    );
  }

  private createRedeployDeploymentEffect(): Observable<StoreDeploymentProject | StoreDeploymentInventory | EmptyAction> {
    return this.actions$
      .pipe(
        ofType(InventoryActionTypes.RedeployDeployment),
        switchMap((action: RedeployDeployment) => {
          if (_.isNil(action.payload.projectCommitId) || _.isNil(action.payload.inventoryCommitId)) {
            return of(new EmptyAction());
          }
          const revisionProjectKey = InventoryDeploymentHistoryHelper.createRevisionKey(action.payload.projectKey, action.payload.projectCommitId);
          const revisionInventoryKey = InventoryDeploymentHistoryHelper.createRevisionKey(action.payload.inventoryKey, action.payload.inventoryCommitId);
          let projectImportingLoadingFinished$: Observable<undefined> = of(undefined);
          return this.projectService.importProjectForRevision(action.payload.projectKey, action.payload.projectCommitId).pipe(
            tap(() => projectImportingLoadingFinished$ = this.loadingService.showLoading({title: 'Importing project for revision', description: 'Please, wait until project is imported'})),
            mergeMap((jobUrl: string) => this.jobService.pollJob(jobUrl).pipe(finalize(() => this.loadingService.hideLoading()))),
            catchError((err: HttpErrorResponse) => {
              if (err.status === HTTP_STATUS_CONFLICT && ErrorHelper.hasSource(err, {FIELD: ProjectErrorFieldSource.ProjectKey})) {
                return of(undefined);
              }
              return throwError(err);
            }),
            mergeMap(() => projectImportingLoadingFinished$),
            mergeMap(() => this.projectService.getProject(revisionProjectKey)),
            mergeMap((project: Project) => {
              let inventoryImportingLoadingFinished$: Observable<undefined> = of(undefined);
              return this.inventoryService.importInventoryForRevision(action.payload.inventoryKey, action.payload.inventoryCommitId as string).pipe(
                tap(() => inventoryImportingLoadingFinished$ = this.loadingService.showLoading({title: 'Importing inventory for revision', description: 'Please, wait until inventory is imported'})),
                mergeMap((jobUrl: string) => this.jobService.pollJob(jobUrl).pipe(finalize(() => this.loadingService.hideLoading()))),
                catchError((err: HttpErrorResponse) => {
                  if (err.status === HTTP_STATUS_CONFLICT && ErrorHelper.hasSource(err, {FIELD: InventoryErrorFieldSource.InventoryKey})) {
                    return of(undefined);
                  }
                  return throwError(err);
                }),
                mergeMap(() => inventoryImportingLoadingFinished$),
                mergeMap(() => this.inventoryService.getInventory(revisionInventoryKey)),
                mergeMap((inventory: Inventory) => of(new StoreDeploymentProject(project.projectKey), new StoreDeploymentInventory(inventory.inventoryKey)))
              );
            }),
            tap(() => this.navigationService.navigateToDeploymentWizard()),
            catchError((err: HttpErrorResponse | Error) => of(this.handleImportRevisionError(err, revisionProjectKey)))
          );
        })
      );
  }

  private handleCreateInventoryError(error: HttpErrorResponse, payload: CreateInventoryPayload, inventoryName: string): void {
    if (error.status === HTTP_STATUS_CONFLICT && !_.isEmpty(payload.inventory.repository)) {
      this.handleCreateInventoryConflict(error, payload.inventory);
    } else {
      console.error('Something went wrong with creating inventory:', error);
      this.toastNotificationService.showErrorToast(`The inventory ${inventoryName} could not be created.`);
    }
  }

  private handleCreateInventoryConflict(errorResponse: HttpErrorResponse, inventory: Inventory): void {
    if (ErrorHelper.hasSource(errorResponse, {FIELD: InventoryErrorFieldSource.Repository})) {
      this.handleCreateInventoryRepositoryError(inventory);
    } else if (ErrorHelper.hasSource(errorResponse, {FIELD: InventoryErrorFieldSource.InventoryKey})) {
      this.handleInventoryKeyAlreadyExistsError(inventory, () => this.createInventoryDialogService.openCreateInventoryDialog(inventory));
    }
  }

  private handleCreateInventoryRepositoryError(inventory: Inventory): void {
    this.modalNotificationService.openConfirmDialog({
      title: 'Repository folder must be empty',
      description: 'You can import the existing inventory, manually empty the target folder using Git tools or change the path, branch or repository.'
    }, {
      cancelButtonText: 'Import inventory',
      confirmButtonText: RETURN_BUTTON_LABEL
    }).afterClosed()
      .subscribe((confirmed?: boolean) => {
        if (confirmed === true) {
          this.createInventoryDialogService.openCreateInventoryDialog(inventory);
        } else if (confirmed === false) {
          this.importInventoryDialogService.openImportInventoryDialog(inventory);
        }
      });
  }

  private handleImportInventoryConflict(errorResponse: HttpErrorResponse, inventory: Inventory): void {
    if (ErrorHelper.hasSource(errorResponse, {FIELD: InventoryErrorFieldSource.Repository})) {
      this.handleImportInventoryRepositoryError(inventory);
    } else if (ErrorHelper.hasSource(errorResponse, {FIELD: InventoryErrorFieldSource.InventoryKey})) {
      this.handleInventoryKeyAlreadyExistsError(inventory, () => this.importInventoryDialogService.openImportInventoryDialog(inventory));
    }
  }

  private handleImportInventoryRepositoryError(inventory: Inventory): void {
    this.modalNotificationService.openConfirmDialog({
      title: 'Repository folder is empty',
      description: 'There is no inventory to be imported on the repository folder. You can create a new inventory or change the path, branch or repository to point to an existing inventory to import.'
    }, {
      cancelButtonText: 'Create inventory',
      confirmButtonText: RETURN_BUTTON_LABEL
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed === true) {
        this.importInventoryDialogService.openImportInventoryDialog(inventory);
      } else if (confirmed === false) {
        this.createInventoryDialogService.openCreateInventoryDialog(inventory);
      }
    });
  }

  private handleInventoryKeyAlreadyExistsError(inventory: Inventory, onConfirmedFn: any): void {
    this.modalNotificationService.openConfirmDialog({
      title: 'Inventory key already exists.',
      description: `There is already an inventory with the key ${inventory.inventoryKey}, please choose another key.<br/>
        Note that you might not have permission to see all inventories.`
    }, {
      confirmButtonText: RETURN_BUTTON_LABEL
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed === true && _.isFunction(onConfirmedFn)) {
        onConfirmedFn();
      }
    });
  }

  private handleImportRevisionError(error: Error | HttpErrorResponse, revisionProjectKey: string): EmptyAction {
    let errorDescription = 'Something went wrong while preparing data for deployment';
    if (error instanceof HttpErrorResponse && _.includes(error.url, 'projects') && error.status === HTTP_STATUS_FORBIDDEN) {
      const revisionProjectName = TenantHelper.cropTenantFromKey(revisionProjectKey);
      errorDescription = `You do not have permission to view project ${revisionProjectName}`;
    }
    return this.handleErrorAction(error, {title: 'Could not deploy', description: errorDescription});
  }

}

import { Tenant } from '../../tenant/tenant.model';
import { NevisAdminAction } from '../actions';
import { SelectTenantPayload } from './select-tenant-payload.model';
import { AppInfoState, ApplicationInfo } from './application-info.model';
import { AnalyticsState } from './analytics.model';

export enum SharedActionTypes {
  LoadTenantsSuccess = '[Shared] Load tenants success',
  InitTenantKey = '[Shared] Init tenant key',
  SelectTenantKey = '[Shared] Select tenant key',
  SelectTenantKeyByUrl = '[Shared] Select tenant key by url',
  LoadApplicationInfo = '[Shared] Load Application Info',
  LoadApplicationInfoSuccess = '[Shared] Load Application Info Success',
  LoadAnalyticsInfoSuccess = '[Shared] Load Analytics Info Success',
  SetAnalyticsSubmissionNotificationMute = '[Shared] Set Analytics Submission Notification Mute',
}

export class LoadTenantsSuccess implements NevisAdminAction<Tenant[]> {
  readonly type = SharedActionTypes.LoadTenantsSuccess;

  constructor(public payload: Tenant[]) {}
}

export class LoadApplicationInfo implements NevisAdminAction<ApplicationInfo> {
  readonly type = SharedActionTypes.LoadApplicationInfo;
}

export class LoadApplicationInfoSuccess implements NevisAdminAction<AppInfoState> {
  readonly type = SharedActionTypes.LoadApplicationInfoSuccess;

  constructor(public payload: AppInfoState) { }
}

export class LoadAnalyticsInfoSuccess implements NevisAdminAction<AnalyticsState> {
  readonly type = SharedActionTypes.LoadAnalyticsInfoSuccess;

  constructor(public payload: AnalyticsState) { }
}

export class SetAnalyticsSubmissionNotificationMute implements NevisAdminAction<boolean> {
  readonly type = SharedActionTypes.SetAnalyticsSubmissionNotificationMute;

  constructor(public payload: boolean) { }
}

export class InitTenantKey implements NevisAdminAction<SelectTenantPayload  > {
  readonly type = SharedActionTypes.InitTenantKey;

  constructor(public payload: SelectTenantPayload) {}
}

export class SelectTenantKey implements NevisAdminAction<SelectTenantPayload> {
  readonly type = SharedActionTypes.SelectTenantKey;

  constructor(public payload: SelectTenantPayload) {}
}

/**
 * selecting tenant by url is a separate action because it requires extra validation, if tenant from URL exists it will then dispatch selectTenantKey action
 */
export class SelectTenantKeyByUrl implements NevisAdminAction<SelectTenantPayload> {
  readonly type = SharedActionTypes.SelectTenantKeyByUrl;

  constructor(public payload: SelectTenantPayload) {}
}

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService} from '../../shared/base.service';
import { DeploymentProcessModel } from './deployment-process.model';
import { EMPTY, Observable, throwError } from 'rxjs';
import { IssueModel } from '../../common/model/issue.model';
import { GenerationOutputModel, PlanningOutputModel } from '../deploy/deployment-preview/planning-output.model';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { DeploymentStatusModel, GenerationStatusModel } from '../validate-deployment/generation-status.model';
import { Status } from '../validating/deployment-status.model';
import { POLL_INTERVAL } from '../../common/constants/app.constants';
import { poll } from '../../rx.utils';
import { DeploymentHelper } from './deployment.helper';
import { HTTP_STATUS_CONFLICT } from '../../shared/http-status-codes.constants';

@Injectable()
export class DeploymentService extends BaseService {

  createDeploymentProcess(deployment: DeploymentProcessModel): Observable<DeploymentProcessModel> {
    const url = '/deployments';
    return this.httpPost(url, deployment);
  }

  deleteDeploymentProcess(deploymentId: string): Observable<void> {
    const url = `/deployments/${deploymentId}`;
    return this.httpDelete(url);
  }

  getDeploymentProcess(deploymentId: string): Observable<DeploymentProcessModel> {
    const url = `/deployments/${deploymentId}`;
    return this.httpGet(url);
  }

  startGeneration(deploymentId: string): Observable<GenerationStatusModel> {
    const url = `/deployments/${deploymentId}/generation`;
    // it sends undefined in body since the back end doesn't expect any body
    return this.httpPut(url, undefined);
  }

  abortGeneration(deploymentId: string): Observable<void> {
    const url = `/deployments/${deploymentId}/generation`;
    return this.httpDelete(url).pipe(
      catchError((error: HttpErrorResponse) => {
        // ignore error if trying to abort already completed generation
        if (error.status === HTTP_STATUS_CONFLICT) {
          return EMPTY;
        }
        return throwError(error);
      })
    );
  }

  private getStatusOfGenerationStep(deploymentId: string): Observable<GenerationStatusModel> {
    const url = `/deployments/${deploymentId}/generation`;
    return this.httpGet(url);
  }

  pollGenerationStatus(deploymentId: string): Observable<GenerationStatusModel> {
    return this.pollStatus<GenerationStatusModel>(() => this.getStatusOfGenerationStep(deploymentId));
  }

  getGenerationIssues(deploymentId: string): Observable<IssueModel[]> {
    const url = `/deployments/${deploymentId}/generation/issues`;
    return this.httpGetUnwrapped(url);
  }

  getGenerationOutput(deploymentId: string): Observable<GenerationOutputModel[]> {
    const url = `/deployments/${deploymentId}/generation/output`;
    return this.httpGetUnwrapped(url).pipe(map((generationOutput: GenerationOutputModel[]) => {
      return DeploymentHelper.fixDeploymentResponse(generationOutput);
    }));
  }

  startDeploymentPlanning(deploymentId: string): Observable<DeploymentStatusModel> {
    const url = `/deployments/${deploymentId}/plan`;
    return this.httpPut(url, undefined);
  }

  startForceDeploymentPlanning(deploymentId: string): Observable<DeploymentStatusModel> {
    const url = `/deployments/${deploymentId}/plan`;
    const params: HttpParams = new HttpParams().set('scan', 'false');
    // it sends undefined in body since the back end doesn't expect any body
    return this.httpPut(url, undefined, undefined, params);
  }

  pollDeploymentPlanningStatus(deploymentId: string): Observable<DeploymentStatusModel | undefined> {
    return this.pollStatus<DeploymentStatusModel>(() => this.getStatusOfDeploymentPlanning(deploymentId));
  }

  abortDeploymentPlanning(deploymentId: string): Observable<void> {
    const url = `/deployments/${deploymentId}/plan`;
    return this.httpDelete(url).pipe(
      // ignore error if trying to abort already completed planning
      catchError((error: HttpErrorResponse) => {
        if (error.status === HTTP_STATUS_CONFLICT) {
          return EMPTY;
        }
        return throwError(error);
      })
    );
  }

  private getStatusOfDeploymentPlanning(deploymentId: string): Observable<DeploymentStatusModel> {
    const url = `/deployments/${deploymentId}/plan`;
    return this.httpGet(url);
  }

  getPlanningResult(deploymentId: string): Observable<PlanningOutputModel[]> {
    const url = `/deployments/${deploymentId}/plan/output`;
    return this.httpGetUnwrapped(url);
  }

  startDeployment(deploymentId: string, comment: string): Observable<any> {
    const url = `/deployments/${deploymentId}/deploy`;
    return this.httpPut(url, {comment: comment});
  }

  private getStatusOfDeploy(deploymentId: string): Observable<DeploymentStatusModel> {
    const url = `/deployments/${deploymentId}/deploy`;
    return this.httpGet(url);
  }

  pollDeploymentStatus(deploymentId: string): Observable<DeploymentStatusModel> {
    return this.pollStatus<DeploymentStatusModel>(() => this.getStatusOfDeploy(deploymentId));
  }

  /**
   * Polls status job until it's done
   * @param functionToPoll
   */
  private pollStatus<T extends DeploymentStatusModel>(functionToPoll: () => Observable<T>): Observable<T> {
    const pollCondition = (result: T) => result.status !== Status.Done;
    return poll(POLL_INTERVAL, functionToPoll, pollCondition);
  }

  public static createGenerationResultUrl(deploymentId: string): string {
    return `/deployments/${deploymentId}/generation/content/`;
  }
}

import { forkJoin, Observable, of as observableOf } from 'rxjs';
import { catchError, filter, map, mergeAll, switchMap, withLatestFrom } from 'rxjs/operators';
import { ActionTypes, LoadPluginDoc, LoadPluginDocForPatternType, LoadPluginDocForPatternTypes, LoadPluginDocForPatternTypesSuccess, LoadPluginDocSuccess, NevisAdminAction, } from './actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HelpService } from '../help/help.service';
import { PluginDocumentationModel } from '../help/plugin-documentation.model';
import { GetUser } from './user';
import { select, Store } from '@ngrx/store';
import { AppState } from './reducer';
import { PluginDocumentationHelper } from '../help/plugin-documentation.helper';
import { projectKeyView } from './views';
import { PluginModel } from '../plugins/plugin.model';
import { LoadApplicationInfo } from './shared/shared.actions';

const PATTERN_NOT_FOUND = '<div class="documentation"><section>Help is not available for this pattern</section></div>';

@Injectable()
export class NetworkEffects {

   appInit = createEffect(() => this.actions$
    .pipe(ofType(ActionTypes.InitApplication))
    .pipe(
      map(() => [
        // list actions to be executed on application startup
        new GetUser(),
        new LoadApplicationInfo(),
      ]),
      mergeAll()
    ));

   initPluginDoc: Observable<LoadPluginDocSuccess> = createEffect(() => this.actions$
    .pipe(ofType(ActionTypes.LoadPluginDoc))
    .pipe(
      map((action: LoadPluginDoc) => action.payload),
      withLatestFrom(this.store$.pipe(select(projectKeyView))),
      filter(([, projectKey]) => projectKey !== null),
      switchMap(([pattern, projectKey]) => {
        return this.loadPlugin(projectKey, pattern.className);
      })
    ));

   initPluginDocForPlugin: Observable<LoadPluginDocSuccess> = createEffect(() => this.actions$
    .pipe(ofType(ActionTypes.LoadPluginDocForPatternType))
    .pipe(
      map((action: LoadPluginDocForPatternType) => action.payload),
      withLatestFrom(this.store$.pipe(select(projectKeyView))),
      filter(([, projectKey]) => projectKey !== null),
      switchMap(([plugin, projectKey]) => {
        return this.loadPlugin(projectKey, plugin.className);
      })
    ));

   loadPluginDocForPatternTypes: Observable<LoadPluginDocForPatternTypesSuccess> = createEffect(() => this.actions$
    .pipe(ofType(ActionTypes.LoadPluginDocForPatternTypes))
    .pipe(
      map((action: LoadPluginDocForPatternTypes) => action.payload),
      withLatestFrom(this.store$.pipe(select(projectKeyView))),
      filter(([, projectKey]) => projectKey !== null),
      switchMap(([pluginArray, projectKey]) => {
        return this.loadPluginArray(projectKey, pluginArray);
      })
    ));

  constructor(private actions$: Actions<NevisAdminAction>,
              private helpService: HelpService,
              private store$: Store<AppState>
  ) {

  }

  loadPlugin(projectKey, className: string): Observable<LoadPluginDocSuccess> {
    return this.helpService.getPluginDocumentation(projectKey, className).pipe(
      map(dataText => {
        const doc = new PluginDocumentationModel();
        doc.projectKeyClassName = projectKey + className;
        doc.htmlContent = dataText ? dataText : PATTERN_NOT_FOUND;
        return doc;
      }),
      map((pluginDoc: PluginDocumentationModel) => new LoadPluginDocSuccess(pluginDoc)),
      catchError((error) => {
        console.error('Error while tried to fetch documentation for plugin ', projectKey, className, error);
        const plugDoc = new PluginDocumentationModel();
        plugDoc.projectKeyClassName = PluginDocumentationHelper.createPluginDocKey(projectKey, className);
        plugDoc.htmlContent = PATTERN_NOT_FOUND;
        return observableOf(new LoadPluginDocSuccess(plugDoc));
      }));
  }

  loadPluginArray(projectKey, pluginModelArray: PluginModel[]): Observable<LoadPluginDocForPatternTypesSuccess> {
    return forkJoin(
      pluginModelArray.map((plugin: PluginModel) => {
          return this.helpService.getPluginDocumentation(projectKey, plugin.className).pipe(
            map(dataText => {
                const doc = new PluginDocumentationModel();
                doc.projectKeyClassName = projectKey + plugin.className;
                doc.htmlContent = dataText ? dataText : PATTERN_NOT_FOUND;
                return doc;
            }),
            catchError((error) => {
              console.error('Error while tried to fetch documentation for plugin ', projectKey, plugin.className, error);
              const plugDoc = new PluginDocumentationModel();
              plugDoc.projectKeyClassName = PluginDocumentationHelper.createPluginDocKey(projectKey, plugin.className);
              plugDoc.htmlContent = PATTERN_NOT_FOUND;
              return observableOf(new LoadPluginDocSuccess(plugDoc));
            })
          );
        }
      )
    ).pipe(
      map((pluginDocArray: PluginDocumentationModel[]) => {
        return new LoadPluginDocForPatternTypesSuccess(pluginDocArray);
      })
    );
  }
}

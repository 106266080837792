import { Pipe, PipeTransform } from '@angular/core';
import { TenantHelper } from '../helpers/tenant.helper';

@Pipe({
  name: 'cropTenantFromKey'
})
export class CropTenantFromKeyPipe implements PipeTransform {

  transform(key: string): string {
    return TenantHelper.cropTenantFromKey(key);
  }

}

import { ValidationIssue, ValidationStatus } from '../model/validation-status.model';
import * as _ from 'lodash';

export enum StatusHelperConstants {
  PATTERN = 'PATTERN',
  PROJECT = 'PROJECT'
}

export class ResponseStatusHelper {

  static getWarningsFromStatus(validationStatus: ValidationStatus<ValidationIssue>, targetSource: string): ValidationIssue[] {
    if (_.isNil(validationStatus) || _.isNil(validationStatus._warnings) || _.isEmpty(validationStatus._warnings)) {
      return [];
    }
    return validationStatus._warnings.filter((issue: ValidationIssue) => _.keys(issue.sources[0]).every((key: string) => key === targetSource));
  }

  static getInfosFromStatus(validationStatus: ValidationStatus<ValidationIssue>, targetSource: string): ValidationIssue[] {
    if (_.isNil(validationStatus) || _.isNil(validationStatus._infos) || _.isEmpty(validationStatus._infos)) {
      return [];
    }
    return validationStatus._infos.filter((issue: ValidationIssue) => _.keys(issue.sources[0]).every((key: string) => key === targetSource));
  }

  static unwrapSourceDetail(issueList: ValidationIssue[], targetSourceKey: StatusHelperConstants, searchedValue: string): string | undefined {
    return issueList.filter((issue: ValidationIssue) => issue.sources[0][targetSourceKey] === searchedValue).map((searchedIssue: ValidationIssue) => {
      return searchedIssue.detail;
    })[0];
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PatternIssueSeverityGroup } from '../model/pattern-issue-severity-group.model';
import {isIssueSeverityEqualTo} from '../../projects/project-issues/project-issues.helper';
import {IssueSeverityEnum} from '../../common/model/issue-severity.enum';

@Component({
  selector: 'adm4-pattern-issue-severity-group',
  template: `
    <div>
      <div class="severity-group-title"
           [class.error]='isErrorGroup'
           [class.warning]='isWarningGroup'
           [class.info]='isInfoGroup'>
        <h2>
          {{group.severity}}
        </h2>
      </div>
      <adm4-simple-list-item *ngFor='let item of group.items' [link]='"."' [fragment]='item.fragment' [replaceUrl]='true'
                             [isSelected]='isSelectedGroupItem(item.fragment)'>
        <div class='list-element'>
          <adm4-validation-indicator [isDisplayed]="true"
                                     [isError]="isErrorGroup"
                                     [isWarning]="isWarningGroup"
                                     [isInfo]="isInfoGroup">
          </adm4-validation-indicator>
          <div class='list-element-content'>
            <div class='list-element-row'>
              <span class='list-element-title' [innerHtml]='item.name | highlight : filterText'></span>
            </div>
          </div>
        </div>
      </adm4-simple-list-item>
    </div>
  `,
  styleUrls: ['./pattern-issue-severity-group.component.scss', '../../common/styles/component-specific/extended-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternIssueSeverityGroupComponent {
  @Input() group: PatternIssueSeverityGroup;
  @Input() filterText: string;
  @Input() selectedFragment: string | undefined;

  get isErrorGroup(): boolean {
    return isIssueSeverityEqualTo(this.group.severity, IssueSeverityEnum.ERROR);
  }

  get isWarningGroup(): boolean {
    return isIssueSeverityEqualTo(this.group.severity, IssueSeverityEnum.WARNING);
  }

  get isInfoGroup(): boolean {
    return isIssueSeverityEqualTo(this.group.severity, IssueSeverityEnum.INFO);
  }

  isSelectedGroupItem(itemFragment: string): boolean {
    return itemFragment === this.selectedFragment;
  }

}

import { InjectionToken } from '@angular/core';

import * as _ from 'lodash';

/**
 * Provides the boolean value describing whether the current browser is supported.
 */
export const BROWSER_SUPPORT: InjectionToken<string> = new InjectionToken('is-browser-supported');

export function isSupportedBrowser(): boolean {
  const agent = navigator.userAgent.toLowerCase();
  const isChromeOrEdge = /edg/.test(agent) || (/chrome/.test(agent)  && /Google Inc/.test(navigator.vendor));
  const isUnsupported = /opr/.test(agent) || !_.isNil(window.navigator['brave']) || !_.isNil(window.navigator['safari']);
  return isChromeOrEdge && !isUnsupported;
}

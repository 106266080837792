import { Component, OnDestroy, OnInit } from '@angular/core';
import { InventoryContext } from './inventory.context';
import { Observable, Subject } from 'rxjs';
import { Inventory } from './inventory.model';
import { map, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { NavigationService } from '../navbar/navigation.service';

@Component({
  selector: 'adm4-inventory',
  template: `
    <adm4-area-with-side-menu [title]='"Infrastructure"'>
      <adm4-inventory-menu side-menu
                           [canCreateInventory]='canCreateInventory$ | async'
                           [currentInventory]='currentInventory$ | async'
                           [inventories]='inventories$ | async'
                           [readOnly]='isCurrentInventoryReadonly$ | async'
                           
      ></adm4-inventory-menu>
      <router-outlet area-content></router-outlet>
    </adm4-area-with-side-menu>
  `,
  providers: [InventoryContext]
})
export class InventoryComponent implements OnInit, OnDestroy {
  inventories$: Observable<Inventory[]>;
  currentInventory$: Observable<Inventory | undefined>;
  canCreateInventory$: Observable<boolean>;
  isCurrentInventoryReadonly$: Observable<boolean>;
  destroyed$: Subject<boolean> = new Subject();

  constructor(private inventoryContext: InventoryContext, private navigationService: NavigationService) {
    this.inventories$ = this.inventoryContext.inventories$.pipe(map(_.values));
    this.currentInventory$ = this.inventoryContext.currentInventory$;
    this.canCreateInventory$ = this.inventoryContext.canCreateInventory$;
    this.isCurrentInventoryReadonly$ = this.inventoryContext.isCurrentInventoryReadonly$;
  }

  ngOnInit(): void {
    this.inventories$.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      if (_.isEmpty(value)) {
        this.navigationService.navigateToInventoryWelcome();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}


import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Inventory } from '../inventory.model';
import { ConnectInventoryVersionControlContainerComponent } from './connect-inventory-version-control-container.component';

@Injectable({providedIn: 'root'})
export class ConnectInventoryVersionControlDialogService {
  constructor(private dialog: MatDialog) { }

  public openConnectInventoryToVersionControlDialog(inventory: Inventory): MatDialogRef<ConnectInventoryVersionControlContainerComponent> {
    const config: MatDialogConfig<Inventory | null> = {
      width: '30%',
      maxWidth: '50%',
      autoFocus: false,
      disableClose: true,
      data: inventory
    };
    return this.dialog.open(ConnectInventoryVersionControlContainerComponent, config);
  }
}

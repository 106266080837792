import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MenuElement } from '../../common/menu-element';
import { MenuHelper } from '../../common/helpers/menu.helper';
import { NavigationConstants } from '../../common/constants/navigation.constants';
import { NavigationService } from '../../navbar/navigation.service';
import { DynamicLinkCreatorService } from '../../common/services/dynamic-link-creator.service';

@Component({
  selector: 'adm4-resources-menu',
  template: `
    <div id='menu-items' class='full-height'>
      <div class='operations'>
        <adm4-menu-element *ngFor='let navLink of navLinks' [menuElement]='navLink'></adm4-menu-element>
      </div>
    </div>
  `,
  styleUrls: ['../../common/styles/component-specific/mat-menu.scss']
})
export class ResourcesMenuComponent {

  public navLinks: MenuElement [];

  constructor(
      private navigationService: NavigationService,
      private linkCreatorService: DynamicLinkCreatorService,
      private route: ActivatedRoute,
  ) {
    this.navLinks =  [
      {
        title: 'Global Constants',
        icon: 'global_constants',
        useSvg: true,
        event: () => this.navigationService.navigateToGlobalConstants(),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.GLOBAL_CONSTANTS)
      },
      {
        title: 'Secret & Files',
        icon: 'lock',
        event: () => this.navigationService.navigateToSecretManagement(),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.SECRET_MANAGEMENT)
      },
      {
        title: 'Certificates',
        icon: 'verified',
        event: () => this.navigationService.navigateToCertificates(),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.CERTIFICATE_OVERVIEW)
      },
      {
        title: 'Managed Kubernetes Certificates',
        icon: 'verified_user',
        event: () => this.navigationService.navigateToKubernetesCertificates(),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.KUBERNETES_CERTIFICATE_OVERVIEW)
      },
      {
        title: 'Pattern Libraries',
        icon: 'library_books',
        event: () => this.navigationService.navigateToLibraryManagement(),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.LIBRARY_MANAGEMENT)
      },
      {
        title: 'Project Templates',
        icon: 'widgets',
        useSvg: true,
        event: () => this.navigationService.navigateToMarketPlace(),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.MARKETPLACE),
      },
    ];
  }
}

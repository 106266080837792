import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Inventory } from '../../inventory.model';

@Component({
  selector: 'adm4-inventory-information',
  template: `
    <div class="section-title">
      <span>Inventory Information</span>
      <button type="button" class="admn4-button-ellipse-red" (click)="delete.emit()">Delete inventory</button>
    </div>
    <div class='inventory-information'>
      <table class='settings-info-table'>
        <tr>
          <td>
            <div class="key">Inventory key</div>
            <div><a [routerLink]="['/infrastructure/inventories', inventory.inventoryKey]">{{inventory.inventoryKey}}</a></div>
          </td>
          <td>
            <div class="key">Tenant key</div>
            {{ inventory.tenantKey }}
          </td>
        </tr>
      </table>
    </div>
  `,
  styleUrls: ['../../../common/styles/component-specific/settings-info-table.scss'],
  styles: [`
  .section-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryInformationComponent {
  @Input() inventory: Inventory;
  @Output() delete: EventEmitter<void> = new EventEmitter();
}

import { Pattern } from '../../patterns/pattern.model';
import { patternReferencePrefix } from '../../common/constants/reference-prefix.constants';
import { DeployToClassicOptionType, DeployToKubernetesOptionType, DeployToOption } from './deploy-to-option.model';
import * as _ from 'lodash';

export class DeployToOptionHelper {
  static possibleCharactersForRandomSecondarySettings = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  static randomSecondarySettingPrefix = 'generated';
  static randomCookieTargetLength = 24;

  static createDeployToOptionFromPattern(pattern: Pattern): DeployToOption {
    return {
      type: DeployToClassicOptionType.Pattern,
      name: pattern.name,
      hostExpression: `${patternReferencePrefix}${pattern.patternId}`
    };
  }

  static createDeployToOptionFromGroup(group: string): DeployToOption {
    return {
      type: DeployToClassicOptionType.Group,
      name: group,
      hostExpression: group
    };
  }

  static createDeployToOptionFromHost(host: string): DeployToOption {
    return {
      type: DeployToClassicOptionType.Host,
      name: host,
      hostExpression: host
    };
  }

  static createDeployToOptionFromService(service: string): DeployToOption {
    return {
      type: DeployToKubernetesOptionType.Service,
      name: service,
      hostExpression: service
    };
  }

  static getDeployToOptionIconByType(deployToType: DeployToClassicOptionType | DeployToKubernetesOptionType): string {
    switch (deployToType) {
      case DeployToClassicOptionType.AllHosts:
      case DeployToKubernetesOptionType.Primary:
        return 'all_inbox';
      case DeployToKubernetesOptionType.AllServices:
        return 'all_inbox';
      case DeployToClassicOptionType.Pattern:
        return 'content_paste';
      case DeployToClassicOptionType.Host:
      case DeployToKubernetesOptionType.Service:
        return 'storage';
      case DeployToKubernetesOptionType.Secondary:
        return 'alt_route';
      case DeployToClassicOptionType.Group:
      case DeployToKubernetesOptionType.Group:
        return 'dns';
      default:
        return '';
    }
  }

  static getDeployToOptionTooltipByType(deployToType: DeployToClassicOptionType | DeployToKubernetesOptionType): string | undefined {
    switch (deployToType) {
      case DeployToClassicOptionType.AllHosts:
        return 'All hosts';
      case DeployToClassicOptionType.Pattern:
        return 'Instance Pattern';
      case DeployToClassicOptionType.Host:
        return 'Host';
      case DeployToClassicOptionType.Group:
        return 'Host Group';
      case DeployToKubernetesOptionType.AllServices:
        return 'All Services';
      case DeployToKubernetesOptionType.Service:
        return 'Service';
      case DeployToKubernetesOptionType.Group:
        return 'Service Group';
      case DeployToKubernetesOptionType.Primary:
        return 'Primary';
      case DeployToKubernetesOptionType.Secondary:
        return 'Secondary';
      default:
        return undefined;
    }
  }

  // If the expression not contains ; separator (means no instance pattern is defined) than the full expression is the target host
  static getHostExpressionPrefix(fullHostExpression: string): string {
    const hostExpressionPrefix = fullHostExpression.substring(0, fullHostExpression.indexOf(';'));
    return _.isEmpty(hostExpressionPrefix) ? fullHostExpression : hostExpressionPrefix;
  }

  static unwrapPatternInstancesFromHostExpression(fullHostExpression: string): string | undefined {
    const separator = ';';
    if (!_.includes(fullHostExpression, separator)) return undefined;
    return fullHostExpression.split(separator).pop();
  }

  static sortByDeployTargetSortingFn(item1: DeployToOption, item2: DeployToOption): number {
    if (_.isEqual(item1.type, item2.type)) return 0;

    // Set allHost as first priority position
    if (_.isEqual(item1.type, DeployToClassicOptionType.AllHosts)) {
      return -1;
    }
    if (_.isEqual(item2.type, DeployToClassicOptionType.AllHosts)) {
      return 1;
    }
    // Set Host Groups as second priority position
    if (_.isEqual(item1.type, DeployToClassicOptionType.Group)) {
      return -1;
    }
    if (_.isEqual(item2.type, DeployToClassicOptionType.Group)) {
      return 1;
    }
    return 0;
  }
  
  static createRandomString(lengthOfCode: number, possibleCharacters: string, prefix?: string): string {
    let result = '';
    const maxLengthOfResult: number = prefix ? lengthOfCode - prefix?.length : lengthOfCode;
    for (let i = 0; i < maxLengthOfResult; i++) {
      result += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
    }
    return _.join([prefix, result], '');
  }
}

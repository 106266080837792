export enum StandardPatternCategory {
  AddOns = 'Add-ons',
  ApplicationProtection = 'Application Protection',
  Authentication = 'Authentication',
  Experimental = 'Experimental',
  IdentityManagement = 'Identity Management',
  InstancePatterns = 'Instance Patterns',
  Monitoring = 'Monitoring',
  Testing = 'Testing'
}

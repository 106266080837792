import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectPermissionsContainerComponent } from './select-permissions-container.component';
import { PermissionAssignee, PermissionSavePayload } from '../model/permissions/permissions.model';
import { SelectPermissionsPayload } from './model/select-permissions-payload.model';
import { OperationColumnData } from './model/operation-column-data.model';

@Injectable()
export class PermissionsDialogService {

  constructor(private dialog: MatDialog) {}

  openPermissionsEditTable(target: string, permissionAssignees: PermissionAssignee[], operationColumns: OperationColumnData[], onSaveCallback: (permissionModifications: PermissionSavePayload[]) => void) {
    return this.dialog.open<SelectPermissionsContainerComponent, SelectPermissionsPayload>(SelectPermissionsContainerComponent,
      {
        data: {
          target: target,
          permissionAssignees: permissionAssignees,
          operationColumns: operationColumns,
          onSaveCallback: onSaveCallback
        },
        panelClass: 'big-dialog',
        disableClose: true,
        autoFocus: false
      });
  }
}

import { ValidationIssue, ValidationStatus } from '../model/validation-status.model';
import * as _ from 'lodash';

export class ValidationStatusHelper {
  static isValidationWithError(status?: ValidationStatus<ValidationIssue> | null): boolean {
    return !_.isNil(status) && !_.isEmpty(status._errors);
  }

  static isValidationWithWarning(status?: ValidationStatus<ValidationIssue> | null): boolean {
    return !_.isNil(status) && !_.isEmpty(status._warnings);
  }

  static isValidationWithInfo(status?: ValidationStatus<ValidationIssue> | null): boolean {
    return !_.isNil(status) && !_.isEmpty(status._infos);
  }

  static isValidationValid(status?: ValidationStatus<ValidationIssue> | null): boolean {
    return _.isNil(status);
  }

  /**
   * This method is somewhat of a hack, so use it carefully.
   *
   * This method changes all warning issues to error issues.
   *
   * @param status The ValidationStatus object, holding all warning and error issues
   *
   * @return status The new ValidationStatus object, based on the original, just all warnings are moved to errors.
   */
  static changeAllIssuesToError(status?: ValidationStatus<ValidationIssue>): ValidationStatus<ValidationIssue> | undefined {
    return _.isNil(status) ? status : ValidationStatusHelper.moveAllIssuesToError(status);
  }

  private static moveAllIssuesToError(status: ValidationStatus<ValidationIssue>): ValidationStatus<ValidationIssue> {
    const oldErrors: ValidationIssue[] = _.isNil(status._errors) ? [] : status._errors;
    const oldWarnings: ValidationIssue[] = _.isNil(status._warnings) ? [] : status._warnings;
    return {
      ...status,
      _warnings: [],
      _errors: [...oldErrors, ...oldWarnings]
    };
  }

  static getValidationWarningsByTitle(status: ValidationStatus<ValidationIssue>, title: string): ValidationIssue[] {
    return _.isNil(status._warnings) ? [] : _.filter(status._warnings, warning => _.isEqual(warning.title, title));
  }
}

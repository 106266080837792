import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Branch } from '../../../version-control/branch.model';
import { distinctUntilChanged, finalize, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { VersionControlService } from '../../../version-control/version-control.service';
import { Unsubscriber } from '../../helpers/unsubscriber';
import { DEFAULT_BRANCH_NAME } from './version-control-form.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHelper } from '../../helpers/error.helper';

export const DEFAULT_FAIL_REPO_ERROR_MESSAGE = 'It was not possible to load from the repository. Please try again or change the repository.';

@Injectable()
export class VersionControlFormContext {
  private _branches$: BehaviorSubject<Branch[]> = new BehaviorSubject([]);
  private _isBranchesLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _repoErrorMessage$: BehaviorSubject<string | null> = new BehaviorSubject(null);

  // backend subscriptions
  private _branchesSubscription?: Subscription;

  branches$: Observable<Branch[]> = this._branches$.asObservable().pipe(distinctUntilChanged(_.isEqual));
  isBranchesLoading$: Observable<boolean> = this._isBranchesLoading$.asObservable();
  repoErrorMessage$: Observable<string | null> = this._repoErrorMessage$.asObservable();
  repoHasError$: Observable<boolean> = this.repoErrorMessage$.pipe(map((error: string | null) => !_.isEmpty(error)));

  constructor(private versionControlService: VersionControlService) {}

  resetBranches(): void {
    this._branches$.next([]);
  }

  loadBranches(repoName: string): void {
    this._isBranchesLoading$.next(true);
    this._repoErrorMessage$.next(null);

    // cancel previous request when triggering new one
    Unsubscriber.unsubscribeFrom(this._branchesSubscription);

    this._branchesSubscription = this.versionControlService.getBranchesOfRepo(repoName).pipe(
      // if branches are empty this hack creates default branch master so project can be still "imported"/created
      map(branches => _.isEmpty(branches) ? [{name: DEFAULT_BRANCH_NAME}] : branches),
      // END OF INTERMEDIATE SOLUTION
      finalize(() => this._isBranchesLoading$.next(false)))
      .subscribe((branches) => this._branches$.next(branches), (err: HttpErrorResponse) => {
        this.resetBranches();
        this._repoErrorMessage$.next(ErrorHelper.getErrorDetail(err, DEFAULT_FAIL_REPO_ERROR_MESSAGE));
      });
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Diff } from '../../../../common/model/publish-changes/diff.model';
import { VariableDiffData } from '../variable-diff-data.model';
import { FormHelper } from '../../../../common/helpers/form.helper';

@Component({
  selector: 'adm4-variable-diff',
  templateUrl: './variable-diff.component.html',
  styleUrls: ['./variable-diff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariableDiffComponent implements OnChanges {
  @Input() variableDiff: Diff<VariableDiffData>;
  @Input() projectKey: string;
  @Input() showOnlyDifferences: boolean;
  @Input() currentUsername: string | null;

  public localForm: UntypedFormGroup;
  public remoteForm: UntypedFormGroup;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variableDiff) {
      // As form in store is frozen and should not be changed in order to pass it down to actual display component we have to clone it
      // Sure it could have been done better if low level component was not coupled to form, but it is
      this.localForm = FormHelper.cloneForm(this.variableDiff.local.form);
      this.remoteForm = FormHelper.cloneForm(this.variableDiff.remote.form);
    }
  }
}

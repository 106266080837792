import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * It's used for informing PatternListComponent about a label action to be able to trigger the proper pattern label node expansion
 */
@Injectable({providedIn: 'root'})
export class LabelActionConnectService {
  private _labelChange = new Subject<string | null>();

  labelChange$: Observable<string | null> = this._labelChange.asObservable();

  updateLabel(label: string): void {
    this._labelChange.next(label);
  }
}

<div class="full-height-flex">
  <adm4-inventory-header [currentInventory]='currentInventory$ | async'
                         [inventories]="inventories$ | async"
                         [inventoryValidationStatus]='inventoryValidationStatus$ | async'
                         [readOnly]="isCurrentInventoryReadonly$ | async"></adm4-inventory-header>
  <div *ngIf='currentInventory$ | async' class='remaining-space-flex-content-wrapper'>
    <div class='remaining-space-flex-content details-container'>
      <div class='full-height-flex'>
        <div class='section-title'>
          Deployment History
        </div>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <ng-container *ngIf='inventoryDeploymentHistory$ | async'>
              <adm4-inventory-deployment-history-table *ngIf='hasHistory(inventoryDeploymentHistory$ | async); else noHistory'
                                                       [inventoryDeploymentHistory]='inventoryDeploymentHistory$ | async'
                                                       [currentInventory]='currentInventory$ | async'
                                                       [displayedColumns]='displayedColumns | async'
                                                       (redeployDeployment)='redeployDeployment($event)'
              ></adm4-inventory-deployment-history-table>
              <ng-template #noHistory>
                This inventory has no history yet, since nothing was deployed into it.
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

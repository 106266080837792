import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

/**
 * Filtering context is dedicated for search of issue groups in left column of issues screen
 * Whenever changeFilterText is called it updates local value, which is provided for outside users as an observable
 * which means that it can be combined with other observables in order to provide filtered output
 * This context can be injected into other context in order to make the described above trick
 */
@Injectable()
export class IssueGroupFilteringContext {
  private _filterText$: BehaviorSubject<string> = new BehaviorSubject('');

  filterText$: Observable<string> = this._filterText$.asObservable();

  changeFilterText(filterText: string): void {
    this._filterText$.next(filterText);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Project } from '../../projects/project.model';
import { FileBasedImportProjectHelper } from '../../projects/file-based-import-project/file-based-import-project.helper';
import { ImportInventoryDialogService } from '../../inventory/import-inventory/import-inventory-dialog.service';
import { FileBasedImportInventoryDialogService } from '../../inventory/file-based-import-inventory/file-based-import-inventory-dialog.service';
import { CreateInventoryDialogService } from '../../inventory/create-inventory/create-inventory-dialog.service';

@Component({
  selector: 'adm4-welcome-inventory-content',
  template: `
    <div class='welcome-content-wrapper'>

      <div class='header-wrapper'>
        <h1>Welcome to nevisAdmin 4!</h1>
      </div>


      <div class='call-to-action'>
        <h3 class='header'>To deploy your configuration, please create your first inventory:</h3>
        <div class='option-wrapper'>
          <div class='option-row'>
            <div class='icon-wrapper' [ngbTooltip]='disabledTooltipText' [disableTooltip]='canCreateInventory' placement='top'>
              <mat-icon svgIcon='create_inventory' (click)='createInventory()' [class.icon-disabled]='!canCreateInventory'></mat-icon>
            </div>
            <div class='icon-wrapper' [ngbTooltip]='disabledTooltipText' [disableTooltip]='canCreateInventory' placement='top'>
              <mat-icon (click)='importInventory()' [class.icon-disabled]='!canCreateInventory'>save_alt_outline</mat-icon>
            </div>
            <div class='icon-wrapper' [ngbTooltip]='disabledTooltipText' [disableTooltip]='canImportFromZip' placement='top'>
              <mat-icon svgIcon='import_from_zip_blk' (click)='importInventoryFromZip()' [class.icon-disabled]='!canImportFromZip'></mat-icon>
            </div>
          </div>
          <div class='option-row'>
            <p>Create a new inventory from scratch</p>
            <p>Import an inventory from a Git repository</p>
            <p>Import an inventory from a Zip file</p>
          </div>
          <div class='option-row' [ngbTooltip]='disabledTooltipText' [disableTooltip]='canCreateInventory' placement='top'>
            <button class='admn4-button-ellipse-blue'
                    (click)='createInventory()'
                    type='button'
                    [disabled]='!canCreateInventory'>
              Create inventory
            </button>
            <button class='admn4-button-ellipse-blue'
                    (click)='importInventory()'
                    type='button'
                    [disabled]='!canCreateInventory'>
              Import inventory from Git
            </button>
            <button class='admn4-button-ellipse-blue'
                    (click)='importInventoryFromZip()'
                    type='button'
                    [disabled]='!canImportFromZip'>
              Import inventory from Zip
            </button>
          </div>
        </div>
      </div>

    </div>
  `,
  styleUrls: ['./welcome-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeInventoryComponent {
  @Input() canCreateInventory: boolean;
  @Input() projects: Project[];

  public disabledTooltipText = 'You don\'t have permission to create or import inventoryies.';

  constructor(private importInventoryDialogService: ImportInventoryDialogService,
              private createInventoryDialogService: CreateInventoryDialogService,
              private fileBasedImportInventoryDialogService: FileBasedImportInventoryDialogService) {}

  importInventory(): void {
    this.importInventoryDialogService.openImportInventoryDialog();
  }

  importInventoryFromZip(): void {
    this.fileBasedImportInventoryDialogService.openFileBasedImportInventoryDialog();
  }

  createInventory(): void {
    this.createInventoryDialogService.openCreateInventoryDialog();
  }

  get canImportFromZip(): boolean {
    return FileBasedImportProjectHelper.canImportFromZip(this.canCreateInventory, this.projects);
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, first, map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { variableKeyParam } from '../projects/project-routing.constants';
import { merge, Observable, of } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class VariablesMainContext {
  /**
   * Selected variable key coming from route params of currently activated route
   * Since on this level of context we are only aware of current route which is simply /variables we need to access its child with :variableId
   * It can happen that we navigate to variable screen without any variable selected yet and then it has redirection to first available variable, therefore there is checking of routing navigation events to make sure that child route is actually initialized
   */
  selectedVariableKey$: Observable<string | undefined>;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.selectedVariableKey$ = merge(
      of(this.route.firstChild),
      this.router.events.pipe(filter(e => e instanceof NavigationEnd), map(() => this.route.firstChild))
    ).pipe(
      switchMap((childRoute: ActivatedRoute | null) => _.isNil(childRoute) ? of(undefined) : childRoute.params.pipe(map(params => params[variableKeyParam]), first())),
      publishReplay(1),
      refCount()
    );
  }
}

import { AbstractControl } from '@angular/forms';
import { Project } from './project.model';
import * as _ from 'lodash';
import { Branch } from '../version-control/branch.model';

export function checkExistingBranchName(projects: Project[], repository: string, remoteBranches: Branch[]): (control: AbstractControl) => { branchNameAlreadyExists: boolean } | null {
  return (control: AbstractControl) => {
    const branchNameAlreadyExistsInProjects = projects.some((project: Project): boolean => {
      if (!project.branch) {
        return false;
      }
      if (!_.isNil(repository) && project.repository === repository) {
        return project.branch === control.value;
      }
      return false;
    });
    const branchNameExistInRemote = remoteBranches.some((branch: Branch) => branch.name === control.value);

    if (!branchNameAlreadyExistsInProjects && !branchNameExistInRemote) {
      return null;
    }

    return {branchNameAlreadyExists: true};
  };
}

export function isValidBranchNameFormat(): (formControl: AbstractControl) => { pattern: boolean} | null {
  return (formControl: AbstractControl) => {
    if (_.isNil(formControl) || _.isNil(formControl.value)) {
      return null;
    }
    /* Branch name pattern: not contains: "?", "*", "[", "~", "^", "..", ":" or SP */
    const validFormatRegexp = new RegExp(/^(?![.\/])(?!.*\.\.)(?!\/)(?!.*\/\/)(?!.*@{)(?!@$)(?!.*\\)[^\000-\037\177 ~^:?*[]+[^\000-\037\177 ~^:?*[]+$/);
    /* cannot ends with ".", "/" or ".lock" */
    const invalidEndingRegexp = new RegExp(/.*[.\/]$|\.lock$/);
    return validFormatRegexp.test(formControl.value) && !invalidEndingRegexp.test(formControl.value) ? null : { pattern: true};
  };
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditDependenciesModel } from './project-dependencies.model';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { ProjectBundle } from '../../projects/project.model';
import { ProjectDependenciesHelper } from './project-dependencies.helper';
import { STANDARD_BUNDLE_INFO } from '../../resources/bundle-management/bundle.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'adm4-select-dependencies-container',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title'
                             header='Select libraries'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog()">
      <adm4-select-dependencies-dialog
              [commonProjectBundles]='standardProjectBundles$ | async'
              [additionalProjectBundles]='additionalProjectBundles$ | async'
              [projectKey]="data.projectKey"
              (bundlesSaved)="onSave()"
              (cancel)='closeDialog()'></adm4-select-dependencies-dialog>
    </adm4-modal-dialog-title>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
  styleUrls: ['../../common/styles/component-specific/modal-window.scss']
})
export class SelectDependenciesContainerComponent {
  standardProjectBundles$: Observable<ProjectBundle[]>;
  additionalProjectBundles$: Observable<ProjectBundle[]>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EditDependenciesModel,
              public dialogRef: MatDialogRef<SelectDependenciesContainerComponent>) {
    this.standardProjectBundles$ = this.data.projectBundles$.pipe(
      map((projectBundles: ProjectBundle[]) => projectBundles.filter(projectBundle => ProjectDependenciesHelper.isProjectBundleStandard(projectBundle, STANDARD_BUNDLE_INFO)))
    );

    this.additionalProjectBundles$ = this.data.projectBundles$.pipe(
      ProjectDependenciesHelper.marketplaceFilter,
      map((projectBundles: ProjectBundle[]) => {
        const additionalBundles = projectBundles.filter(projectBundle => !ProjectDependenciesHelper.isProjectBundleStandard(projectBundle, STANDARD_BUNDLE_INFO));
        return _.sortBy(additionalBundles, (projectBundle: ProjectBundle) => !projectBundle.isActiveForProject);
      })
    );
  }

  onSave() {
    if (_.isFunction(this.data.onSaveCallback)) {
      this.data.onSaveCallback();
    }
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close(SelectDependenciesContainerComponent);
  }
}

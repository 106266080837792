<div class="mp-tile" #tileBody tabindex="0" [ngClass]="{selected: isSelected}" (click)="select($event)" (keyup.enter)='select($event)'>
  <div class="tile-header">
    <div class="img-container">
      <img [src]="item.iconUrl" alt="Logo of this project template">
    </div>
    <p class="title">{{item.title}}</p>
  </div>

  <p class="row-desc">{{item.summary}}</p>
  <div class="row-categories">

  <div class="categories-list">
    <span *ngFor="let c of item.categories" class="cat-item">{{c}}</span>
  </div>
  </div>
</div>

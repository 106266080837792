import { NgModule } from '@angular/core';
import { CommonModules } from '../../common/common.module';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BundleManagementModule } from '../bundle-management/bundle-management.module';
import { MatSortModule } from '@angular/material/sort';
import { KubernetesCertificateManagementTableComponent } from './kubernetes-certificate-management-table/kubernetes-certificate-management-table.component';
import { KubernetesCertificateManagementComponent } from './kubernetes-certificate-management.component';

@NgModule({
  declarations: [
    KubernetesCertificateManagementComponent,
    KubernetesCertificateManagementTableComponent
  ],
  imports: [
    BundleManagementModule,
    CommonModules,
    MatTableModule,
    ClipboardModule,
    MatSortModule
  ]
})
export class KubernetesCertificateManagementModule {
}

import { VersionControlForm } from '../../common/components/version-control-form/version-control.form';

export const VERSION_CONTROL_FORM_CONTROL_NAME = 'versionControl';
export const PROJECT_KEY_FORM_CONTROL_NAME = 'projectKey';
export const IS_VERSIONED_FORM_CONTROL_NAME = 'isVersioned';
export const BRANCH_NAME_FORM_CONTROL_NAME = 'newBranchName';

export interface ImportProjectForm {
  [VERSION_CONTROL_FORM_CONTROL_NAME]: VersionControlForm;
  [PROJECT_KEY_FORM_CONTROL_NAME]: string;
}

export interface CreateProjectForm extends ImportProjectForm {
  [IS_VERSIONED_FORM_CONTROL_NAME]: boolean;
}

export interface BranchProjectForm extends ImportProjectForm {
  [BRANCH_NAME_FORM_CONTROL_NAME]: string;
}

<ng-template #commitInfoPopover>
  <adm4-commit-info [commitInfo]='fileContentDiff.remote.commitInfo'></adm4-commit-info>
</ng-template>

<div class='full-height-flex'>
  <div class='change-info-container'>
    <div class='remote'>
      <div class='change-info'>
        <ng-container *ngIf="hasRemoteCommitInfo">
        <span> published by
          <strong>{{fileContentDiff.remote.commitInfo.committer.name}},
            <span class="date" [ngbTooltip]='commitInfoPopover' placement='top'>
                {{fileContentDiff.remote.commitInfo.committer.when | customDate}}
            </span>
          </strong>
        </span>
        </ng-container>
      </div>
    </div>
    <div class='local'>
      <div class='change-info'>
        <ng-container *ngIf="hasLocalInfo">
        <span> {{fileContentDiff.local.changeInfo.localStatus | lowercase}} by
          <strong>{{fileContentDiff.local.changeInfo.localAuthor}},
            <span class="date">
                {{fileContentDiff.local.changeInfo.localDate | customDate}}
            </span>
          </strong>
        </span>
        </ng-container>
      </div>
    </div>
  </div>
  <div class='remaining-space-flex-content-wrapper'>
    <div class='remaining-space-flex-content'>
      <adm4-file-content-diff-editor [fileContentDiff]="fileContentDiff"></adm4-file-content-diff-editor>
    </div>
  </div>
</div>
import { ElementRef } from '@angular/core';

export const resetTextareaToTop = (element: ElementRef<HTMLElement>): void => {
  try {
    if (!element || !element.nativeElement || 'textarea' !== element.nativeElement.tagName.toLowerCase()) {
      return;
    }
    const textAreaElement: HTMLTextAreaElement = (element.nativeElement) as HTMLTextAreaElement;
    textAreaElement.setSelectionRange(0, 0);
    textAreaElement.scroll(0, 0);
    textAreaElement.focus();
  } catch (e) {
    console.warn('Unable to focus textarea', e);
  }
};

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, } from '@angular/core';

import { Pod } from '../inventory-kubernetes-status/deployed-service.model';

@Component({
  selector: 'adm4-pod-details',
  template: `
    <div class='pod-item-details'>
      <div class='pod-key-wrapper'>
        <span class='pod-key' [ngbTooltip]='podKey' placement='bottom'>{{pod.podKey}}</span>
        <span>&nbsp;-&nbsp;<a (click)='viewLogsClicked()'>{{isOperator ? 'view operator logs' : 'view logs'}}</a></span>
      </div>
      <div class='creation-time' [ngbTooltip]='podCreationDate' placement='top'>
        <span>Created {{pod.creationTimestamp | customDate}}</span>
      </div>
      <ng-template #podCreationDate><span>{{pod.creationTimestamp | date: 'medium' }}</span></ng-template>
      <ng-template #podKey><span>{{pod.podKey}}</span></ng-template>
    </div>
  `,
  styleUrls: ['./pod-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PodDetailsComponent {

  @Input() pod: Pod = {} as any;
  @Input() isOperator: boolean = false;

  @Output() viewLogs: EventEmitter<Pod> = new EventEmitter<Pod>();

  viewLogsClicked() {
    this.viewLogs.emit(this.pod);
  }
}

import { takeUntil } from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { VariableModel } from '../variable.model';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'adm4-variable-selection',
  template: `
		<div *ngIf='!selectedVariable || dropdownActive; else selectedVariableInput'
				 class='ui-dropdown'
         [class.opened]='isDropdownDisplayed'
				 #dropdownInput>
			<input [(ngModel)]='inputValue'
						 class='admn4-text-input form-control ui-dropdown-input'
						 placeholder='Search for a variable...'
						 (input)='term.next($event.target.value)'
             (keydown.enter)='onEnterKeyUp($event)'
             (keypress.enter)='onEnterKeyUp($event)'
						 (keyup.enter)='onEnterKeyUp($event)'
						 (keyup.esc)='toggleDropdown(false)'
						 (click)='inputClicked()'
             />
			<div class='ui-icon-container form-control' (click)='toggleDropdown()'>
				<span class='fa fa-caret-down'></span>
			</div>
		</div>
		<ng-template #selectedVariableInput>
			<div class='ui-dropdown'>
				<div class='selected-variable'>
					<a target="_blank" [routerLink]='["/projects", projectKey,  "variables"]'
						 [fragment]='selectedVariable.variableKey'
						 (click)='$event.stopPropagation()'>
                {{selectedVariable.variableKey}}
					</a>
					<div class='ui-close-icon-container' (click)='clearSelection()'>
						<i class="fa fa-times-circle-o admn4-button-icon cancel-icon" aria-hidden="true"></i>
					</div>
				</div>
			</div>
		</ng-template>
    <mat-select #variableDropdown [disableOptionCentering]='true' class='variable-selection-dropdown full-width'>
      <mat-option *ngFor='let variable of filteredVariables' [value]='variable.variableKey' (onSelectionChange)='onVariableSelected(variable)'>
        {{variable.variableKey}}
      </mat-option>
    </mat-select>
  `,
  styleUrls: ['variable-selection.scss']
})

export class VariableSelectionComponent implements OnChanges, OnDestroy {
  @Input() variables: VariableModel[];
  @Input() projectKey: string;

  @Output() close = new EventEmitter();
  @Output() variableSelected = new EventEmitter<VariableModel>();
  @Output() removeSelectedVariable: EventEmitter<void> = new EventEmitter();

  @ViewChild('variableDropdown', {static: false}) variableDropdown: MatSelect;
  @ViewChild('dropdownElements', {static: false}) dropdownElements: ElementRef;
  @ViewChild('dropdownInput', {static: false}) dropdownInput: ElementRef;
  public filteredVariables: VariableModel[];
  // It's a string when the variable is not found in the list
  public selectedVariable: VariableModel | string | null;
  public dropdownActive: boolean;
  public term = new Subject<string>();
  public inputValue: string;

  private destroyed$: Subject<boolean> = new Subject();

  constructor() {
    this.term.pipe(
      takeUntil(this.destroyed$))
      .subscribe(text => this.filteredVariables = this.onInputChange(text));
  }

  get isDropdownDisplayed(): boolean {
    return this.variables && this.dropdownActive;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['variables'] && this.variables) {
      this.filteredVariables = this.variables;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onInputChange(text: string): VariableModel[] {
    this.variableDropdown.open();
    return this.variables.filter((variable: VariableModel) =>
      variable.variableKey.toLowerCase().includes(text.toLowerCase())
    );
  }

  onVariableSelected(variable: VariableModel): void {
    this.dropdownActive = false;
    this.variableDropdown.close();
    this.selectedVariable = variable;
    this.variableSelected.emit(variable);
    this.filteredVariables = this.variables;
    this.variableDropdown.value = undefined;
  }

  onEnterKeyUp(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.variableDropdown.focus();
    const value = (<HTMLInputElement>event.target).value;
    const matchingVariable = this.filteredVariables.find(variable => variable.variableKey === value);
    if (_.isEmpty(value)) {
      return;
    } else if (!_.isNil(matchingVariable)) {
      this.onVariableSelected(matchingVariable);
    }
  }

  toggleDropdown(toggleValue?: boolean): void {
    this.variableDropdown.toggle();
    this.dropdownActive = toggleValue || !this.dropdownActive;
    if (!this.dropdownActive) {
      this.close.emit();
    }
  }

  clearSelection(): void {
    this.selectedVariable = null;
    this.inputValue = '';
    this.removeSelectedVariable.emit();
  }

  inputClicked() {
    this.dropdownActive = true;
    this.variableDropdown.open();
  }
}

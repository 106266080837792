import { VariableDiffData } from './variable-diff-data.model';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { REQUIRE_OVERLOADING_POSTFIX, VariableModel, VersionedVariableModel } from '../../../variables/variable.model';
import { Dictionary } from '../../../model/reducer';
import { PropertyType } from '../../../plugins/property-type.model';
import { IssueModel, SourceType } from '../../../common/model/issue.model';
import { Revision } from '../../../version-control/revision/revision.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { getIssuesById } from '../../project-issues/project-issues.helper';
import * as _ from 'lodash';
import { VariableHeadValue } from '../../../version-control/variable-meta-info.model';

export class VariablesDiffViewHelper {
  static createVariableDiff(variableMeta: VersionedVariableModel, propertyTypes: Dictionary<PropertyType>, issues: IssueModel[], revision?: Revision): Diff<VariableDiffData> {
    const remoteVariable: VariableModel = this.createRemoteVariable(variableMeta);
    const localForm = this.createVariableForm(variableMeta);
    const remoteForm = this.createVariableForm(remoteVariable);
    const variableIssues: IssueModel[] = getIssuesById(issues, SourceType.VARIABLE, variableMeta.variableKey);
    return {
      local: {
        variable: variableMeta,
        variableType: propertyTypes[variableMeta.className],
        form: localForm,
        changeInfo: variableMeta._meta,
        issues: variableIssues,
        fallbackLabel: variableMeta.variableKey
      },
      remote: {
        variable: remoteVariable,
        variableType: propertyTypes[remoteVariable.className],
        form: remoteForm,
        commitInfo: revision,
        issues: []
      }
    };
  }

  private static createVariableForm(variable: VariableModel): UntypedFormGroup {
    return new UntypedFormGroup({
      [variable.variableKey]: new UntypedFormControl(variable.value),
      [variable.variableKey + REQUIRE_OVERLOADING_POSTFIX]: new UntypedFormControl(variable.requireOverloading)
    });
  }

  private static createRemoteVariable(variableMeta: VersionedVariableModel): VariableModel {
    const variableHeadValue: VariableHeadValue | undefined = variableMeta._meta.headValue;
    return {
      variableKey: variableMeta.variableKey,
      className: _.isNil(variableHeadValue) ? variableMeta.className : variableHeadValue.className,
      parameters: _.isNil(variableHeadValue) ? variableMeta.parameters : variableHeadValue.parameters,
      value: _.isNil(variableHeadValue) ? variableMeta.value : variableHeadValue.value,
      requireOverloading: _.isNil(variableHeadValue) ? variableMeta.requireOverloading : variableHeadValue.requireOverloading,
      bundleKey: variableMeta.bundleKey
    };
  }
}

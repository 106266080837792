import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Inventory, InventorySchemaType } from '../../inventory.model';
import { InventorySchemaTypeHelper } from '../../../deployment-wizard/deployment-selection/inventory-list/inventory-schema-type.helper';
import { DeploymentRepository, KubernetesCluster, KubernetesInventoryContent } from '../../create-inventory/create-inventory-payload';
import * as _ from 'lodash';
import { InventoryParserHelper } from '../inventory-parser.helper';

@Component({
  selector: 'adm4-inventory-kubernetes-information',
  template: `
    <!-- TODO (paksyd): Re-enable displaying here the "Kubernetes Connection" section when the connect to Kubernetes modal dialog is implemented!-->
    <div class="section-title" *ngIf='isKubernetesInventory'>Kubernetes Connection</div>
    <table class='settings-info-table' *ngIf='isKubernetesInventory'>
      <tr>
        <td>
          <ng-container *ngIf="deploymentRepository && deploymentRepository.url">
            <div class="key"> Deployment repository</div>
            {{ deploymentRepository.url }}
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="deploymentRepository && deploymentRepository.branch">
            <div class="key"> Branch</div>
            {{ deploymentRepository.branch }}
          </ng-container>
        </td>
      </tr>
      <tr>
        <td>
          <ng-container *ngIf="kubernetesCluster && kubernetesCluster.url">
            <div class="key"> Kubernetes cluster</div>
            {{ kubernetesCluster.url }}
          </ng-container>
        </td>
        <td>
          <ng-container *ngIf="kubernetesCluster && kubernetesCluster.namespace">
            <div class="key"> Namespace</div>
            {{ kubernetesCluster.namespace }}
          </ng-container>
        </td>
      </tr>
    </table>
    <div class='inventory-actions' *ngIf='!isKubernetesInventory'>
      <span><!-- Note: This empty span is needed so that the following button is layed out on the right side. --></span>
      <!-- TODO (paksyd): Re-enable here the "Deploy to Kubernetes" button when the connect to Kubernetes modal dialog is implemented!-->
<!--
            <button type="button" class="admn4-button-ellipse-blue" (click)="openConnectInventoryToKubernetesDialog()">
              <span class="menu-item-text">Deploy to Kubernetes</span>
            </button>
  -->
          </div>
`,
  styleUrls: [
    '../inventory-information-common.scss',
    '../../../common/styles/component-specific/settings-info-table.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryKubernetesInformationComponent implements OnChanges {
  @Input() inventory: Inventory;
  @Input() inventoryContent?: string;
  @Input() inventorySchemaType?: InventorySchemaType;

  deploymentRepository: DeploymentRepository | null;
  kubernetesCluster: KubernetesCluster | null;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inventoryContent'] && this.isKubernetesInventory) {
      const inventoryContentParsed: KubernetesInventoryContent | null = InventoryParserHelper.parseInventoryContent(this.inventoryContent);
      if (!_.isNil(inventoryContentParsed)) {
        this.deploymentRepository = inventoryContentParsed['deployment-repository'];
        this.kubernetesCluster = inventoryContentParsed['kubernetes-cluster'];
      }
    }
  }

  get isKubernetesInventory(): boolean {
    return InventorySchemaTypeHelper.isKubernetesDeployment(this.inventorySchemaType);
  }

  openConnectInventoryToKubernetesDialog(): void {
    // TODO (paksyd): Implement opening Connect to Kubernetes modal dialog here!
  }
}

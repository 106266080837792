import { NgModule } from '@angular/core';
import { CommonModules } from '../../common/common.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { BatchActionsMenuComponent } from './batch-actions-menu.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LabelMenuOptionComponent } from './label-menu-option.component';

@NgModule({
  imports: [
    CommonModules,
    MatChipsModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule
  ],
  declarations: [
    BatchActionsMenuComponent,
    LabelMenuOptionComponent,
  ],
  exports: [
    BatchActionsMenuComponent,
    LabelMenuOptionComponent
  ]
})
export class BatchActionMenuModule {
}

import { Injectable } from '@angular/core';
import { EventType, IsActiveMatchOptions, NavigationEnd, Router, UrlTree } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';

import {
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  interval,
  MonoTypeOperatorFunction,
  Observable,
  of
} from 'rxjs';
import { catchError, filter, map, mapTo, share, startWith, switchMap, tap } from 'rxjs/operators';

import { TenantService } from '../../tenant/tenant.service';
import { AppState } from '../../model/reducer';
import {
  DeploymentHistoryItem,
  DeploymentHistoryJobStatus
} from '../model/deployment-history.model';
import { inventoryKeyView, projectKeyView, selectedTenantKeyView } from '../../model/views';
import { filterNotNil, Maybe } from '../utils/utils';
import { NavigationConstants } from '../constants/navigation.constants';

export interface DeploymentActivity {
  projectKey: string;
  isProjectRedacted: boolean;
  inventoryKey: string;
  isInventoryRedacted: boolean;
  userKey: string;
}

export type DeploymentActivityIndicator = {
  hasActivity: true;
  inCurrentScope: boolean;
  activities: Array<DeploymentActivity>;
} | {hasActivity: false};

const REDACTED = '***';

const historyItemToDeploymentActivity = (hi: DeploymentHistoryItem): DeploymentActivity => {
  return {
    userKey: hi.userKey,
    projectKey: hi.projectKey,
    isProjectRedacted: hi.projectKey === REDACTED,
    inventoryKey: hi.inventoryKey,
    isInventoryRedacted: hi.inventoryKey === REDACTED,
  };
};

const isActiveMatchOptions: IsActiveMatchOptions = {paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored'};

@Injectable()
export class DeploymentActivityContextService {
  private POLLING_INTERVAL_NORMAL = 2000;
  private POLLING_INTERVAL_BACKOFF = 30000;
  private TWO_HOURS = 7200000;  // 3600 x 2 x 1000

  public deploymentActivity$: Observable<DeploymentActivityIndicator>;

  private pollingInterval: BehaviorSubject<number> = new BehaviorSubject(this.POLLING_INTERVAL_NORMAL);

  constructor(
      private tenantApi: TenantService,
      private store$: Store<AppState>,
      router: Router,
  ) {
    const takeUntilDestroyedOp: MonoTypeOperatorFunction<DeploymentActivityIndicator> = takeUntilDestroyed();
    const navigationEnds: Observable<void> = router.events.pipe(
        filter((e): e is NavigationEnd => EventType.NavigationEnd === e.type),
        mapTo(undefined),
    );
    const historyPolling: Observable<Array<DeploymentHistoryItem>> = this.pollingInterval.asObservable().pipe(
        switchMap((currentInterval: number) => interval(currentInterval, asyncScheduler)),
        switchMap(() => this.store$.select(selectedTenantKeyView).pipe(filterNotNil())),
        switchMap((selectedTenantKey): Observable<Array<DeploymentHistoryItem>> => {
          const twoHoursAgo = new Date(new Date().getTime() - this.TWO_HOURS);
          return this.tenantApi.getTenantDeploymentHistory(selectedTenantKey, twoHoursAgo, DeploymentHistoryJobStatus.Running).pipe(
            tap(() => {
              // if the request was successful, and we're after an error, reset to the normal interval
              if (this.pollingInterval.getValue() === this.POLLING_INTERVAL_BACKOFF) {
                this.pollingInterval.next(this.POLLING_INTERVAL_NORMAL);
              }
            }),
            catchError(() => {
              // if this is the first error (session timeout, network down, etc.), switch to the backoff interval
              if (this.pollingInterval.getValue() === this.POLLING_INTERVAL_NORMAL) {
                this.pollingInterval.next(this.POLLING_INTERVAL_BACKOFF);
              }
              return of([]);
            }),
          );
        }),
    );
    const projectRoute: UrlTree = router.parseUrl('/' + NavigationConstants.PROJECTS);
    const activeProject: Observable<string | false> = combineLatest([
      store$.select(projectKeyView),
      navigationEnds,
    ]).pipe(
        map(([projectKey]: [Maybe<string>, void]): string | false => {
          if (!projectKey || !router.isActive(projectRoute, isActiveMatchOptions)) {
            return false;
          }
          return projectKey;
        }),
    );
    const inventoryRoute: UrlTree = router.parseUrl('/' + NavigationConstants.INFRASTRUCTURE + '/' + NavigationConstants.INVENTORIES);
    const activeInventory: Observable<string | false> = combineLatest([
      store$.select(inventoryKeyView),
      navigationEnds,
    ]).pipe(
        map(([inventoryKey]: [Maybe<string>, void]): string | false => {
          if (!inventoryKey || !router.isActive(inventoryRoute, isActiveMatchOptions)) {
            return false;
          }
          return inventoryKey;
        }),
    );

    this.deploymentActivity$ = combineLatest([historyPolling, activeProject, activeInventory,]).pipe(
        map((
          [historyItems, selectedProjectKey, selectedInventoryKey]: [Array<DeploymentHistoryItem>, string | false, string | false]
        ): DeploymentActivityIndicator => {
          if (historyItems.length === 0) {
            return {hasActivity: false};
          }
          const activities: Array<DeploymentActivity> = historyItems.map(historyItemToDeploymentActivity);
          const projectInCurrentScope: boolean = !!selectedProjectKey && historyItems.some((hi: DeploymentHistoryItem) => hi.projectKey === selectedProjectKey);
          const inventoryInCurrentScope: boolean = !!selectedInventoryKey && historyItems.some((hi: DeploymentHistoryItem) => hi.inventoryKey === selectedInventoryKey);
          return {
            hasActivity: true,
            activities,
            inCurrentScope: projectInCurrentScope || inventoryInCurrentScope,
          };
        }),
        takeUntilDestroyedOp,
        startWith({hasActivity: false} as DeploymentActivityIndicator),
        share(),
    );
  }
}

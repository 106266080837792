import { Component } from '@angular/core';
import { FileDetailsComponent } from '../../deployment-preview-details/file-details/file-details.component';

@Component({
  selector: 'adm4-k8s-file-detail-message',
  templateUrl: './k8s-file-detail-message.component.html',
  styleUrls: ['../../item-details.scss']
})
export class K8sFileDetailMessageComponent extends FileDetailsComponent {

}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * It capitalizes the first letter of the string and transforms to lower case the others
 */
@Pipe({
  name: 'capitalizeFirst'
})
export class CapitalizeFirstPipe implements PipeTransform {
  transform(value: string): any {
    if (value === null) {
      return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}

import { BehaviorSubject, interval, Observable, ObservedValueOf, of as observableOf, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { ProjectService } from './project.service';
import { AppState } from '../model/reducer';
import { select, Store } from '@ngrx/store';
import { projectIssueStateView, projectKeyView, projectMetaStateView } from '../model/views';
import { UpdateProjectIssueTimestamp, ProjectMetaTimestampChangedInBackground } from '../model/project';
import { NevisAdminAction } from '../model/actions';
import * as _ from 'lodash';

@Injectable()
export class ProjectSyncService {

  private readonly POLLING_INTERVAL_INITIAL = 2000;
  private pollMetaSubscription: Subscription;
  private pollIssueSubscription: Subscription;
  timerSubject = new BehaviorSubject(this.POLLING_INTERVAL_INITIAL);
  private projectKey$: Observable<string> = this.store$.pipe(select(projectKeyView), filter<string>((projectKey: string | null) => !_.isNil(projectKey)));

  constructor(public projectService: ProjectService,
              private store$: Store<AppState>,
              private zone: NgZone) {}

  startSync() {
    this.zone.runOutsideAngular(() => {
      this.pollMetaSubscription = this.pollService(
        (projectKey) => this.projectService.getProjectTimeStamp(projectKey),
        this.store$.pipe(select(projectMetaStateView)),
        (local: string, remote: string) => new ProjectMetaTimestampChangedInBackground({local, remote}),
        'project meta service',
      );
      this.pollIssueSubscription = this.pollService(
        (projectKey) => this.projectService.getIssueTimeStamp(projectKey),
        this.store$.pipe(select(projectIssueStateView)),
        (local: string, remote: string) => new UpdateProjectIssueTimestamp({local, remote}),
        'issues service',
      );
    });
  }

  stopSync() {
    if (this.pollMetaSubscription) {
      this.pollMetaSubscription.unsubscribe();
    }
    if (this.pollIssueSubscription) {
      this.pollIssueSubscription.unsubscribe();
    }
  }

  isSyncOn() {
    return this.pollMetaSubscription && this.pollIssueSubscription && !this.pollMetaSubscription.closed && !this.pollIssueSubscription.closed;
  }

  private pollService(
              functionToPoll: (projectKey: string) => Observable<{ timestamp: string }>,
              timestampStateSelector: Observable<{ local: string, remote: string }>,
              actionToDispatch: (remoteTimestamp: string, localTimestamp: string) => NevisAdminAction<any>,
              serviceName: string,
  ): Subscription {
    let pollingInterval = this.POLLING_INTERVAL_INITIAL;
    return this.timerSubject.pipe(
      switchMap(i => interval(i)),
      withLatestFrom(this.projectKey$), filter(([, projectKey]) => {
        return !!projectKey;
      }),
      mergeMap(([, projectKey]) => {
        return functionToPoll(projectKey).pipe(
          catchError(e => {
            console.warn(`An error occurred when polling ${serviceName}`, e);
            this.stopSync();
            return observableOf({timestamp: ''});
          }));
      }), distinctUntilChanged(), withLatestFrom(timestampStateSelector))
      .subscribe(([serverTimestamp, innerTimestampStateSelector]) => {
        if (this.shouldUpdate(innerTimestampStateSelector, serverTimestamp)) {
          pollingInterval = pollingInterval * 2 ;
          this.zone.run(() => this.store$.dispatch(actionToDispatch(innerTimestampStateSelector.local, serverTimestamp.timestamp)));
        } else {
          pollingInterval = this.POLLING_INTERVAL_INITIAL;
        }
        this.timerSubject.next(pollingInterval);
      });
  }

  private shouldUpdate(innerTimestampStateSelector: ObservedValueOf<Observable<{ local: string; remote: string }>>, serverTimestamp: { timestamp: string }) {
    const isServerDataNewer = this.isServerDataNewer(innerTimestampStateSelector, serverTimestamp);
    return !innerTimestampStateSelector.local || isServerDataNewer;
  }

  private isServerDataNewer(innerTimestampStateSelector: ObservedValueOf<Observable<{ local: string; remote: string }>>, serverTimestamp: { timestamp: string }) {
    const serverDataTime = serverTimestamp ? new Date(serverTimestamp.timestamp) : new Date();
    return !!innerTimestampStateSelector.local && serverDataTime > new Date(innerTimestampStateSelector.local);
  }
}

import { Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { Project, ProjectMeta } from '../../projects/project.model';
import { ProjectSettingsContext } from '../project-settings.context';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { ModalNotificationService } from '../../notification/modal-notification.service';

@Component({
  selector: 'adm4-project-settings-details',
  template: `
    <div class='full-height-flex'>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='details-container remaining-space-flex-content'>
          <adm4-project-information *ngIf='!!(project$ | async)'
                                    [project]='project$ | async'
                                    [projectMeta]='projectMeta$ | async'
                                    (deleteProject)="triggerDeleteProject()"
          ></adm4-project-information>
          <adm4-project-dependencies
                  [readOnly]="isCurrentProjectReadonly$ | async"
                  [projectKey]="projectKey"></adm4-project-dependencies>
          <adm4-project-permissions
            [readOnly]="!(hasAdminAccess$ | async)"
            [projectKey]="projectKey"></adm4-project-permissions>
          <div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../../common/styles/component-specific/settings-details.scss'],
  styles: [`
:host {
    background-color: orange;
}
  `],
})
export class ProjectSettingsDetailsComponent {
  @Input() projectKey: string;

  project$: Observable<Project | undefined>;
  projectMeta$: Observable<ProjectMeta | null>;
  hasAdminAccess$: Observable<boolean>;
  hasWriteAccess$: Observable<boolean>;
  isCurrentProjectReadonly$: Observable<boolean>;


  constructor(
      public projectSettingsContext: ProjectSettingsContext,
      private modalNotificationService: ModalNotificationService,
  ) {
    this.project$ = this.projectSettingsContext.currentProject$;
    this.projectMeta$ = this.projectSettingsContext.projectMeta$;
    this.hasAdminAccess$ = projectSettingsContext.hasAdminAccess$;
    this.hasWriteAccess$ = projectSettingsContext.hasWriteAccess$;
    this.isCurrentProjectReadonly$ = projectSettingsContext.isCurrentProjectReadonly$;
  }

  triggerDeleteProject() {
    const projectName = TenantHelper.cropTenantFromKey(this.projectKey);
    this.modalNotificationService.openConfirmDialog({
      headerTitle: `Warning`,
      title: `Delete ${projectName}`,
      description: `You are deleting the "${projectName}" project. The deletion is irreversible. It can't be undone. Project data published to GIT will not be deleted.`
    }, {
      confirmButtonText: 'Delete'
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed === true) {
        this.projectSettingsContext.deleteProject(this.projectKey);
      }
    });
  }

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Maybe } from '../../common/utils/utils';
import { FormHelper } from '../../common/helpers/form.helper';

export interface PatternFileEditorCreatePayload {
  mode: 'create';
  alreadyTakenFileNames: string[];
}
export interface PatternFileEditorEditPayload {
  mode: 'edit';
  content: string;
  fileName: string;
}

const EMPTY_SYNTAX = '';
export const extractSyntaxFromFileName = (fileName: Maybe<string>): string => {
  if (typeof fileName !== 'string') {
    return EMPTY_SYNTAX;
  }
  const split: Array<string> = fileName.split('.');
  if (split.length < 2) {
    return EMPTY_SYNTAX;
  }
  const ext = split[split.length - 1];
  if (ext.length === 0) {
    return EMPTY_SYNTAX;
  }
  switch (ext) {
    case 'yml': return 'yaml';
    case 'sh': return 'shell';
    case 'js': return 'javascript';
    case 'svg': return 'xml';
    case 'htm': return 'html';
    case 'properties': return 'ini';
    // TODO NEVISADMV4-9807 add groovy syntax highlight to the attachment property
    case 'groovy': return 'java';
    default: return ext;
  }
};

export type PatternFileEditorDialogPayload = PatternFileEditorCreatePayload | PatternFileEditorEditPayload;

/**
 * If the `fileName` is present, the file is new, if it's not present, and existing file is edited.
 */
export interface PatternFileEditorDialogResult {
  fileContentText: string;
  fileName?: string;
}

@Component({
  selector: 'adm4-pattern-file-editor-dialog',
  template: `
    <adm4-modal-dialog-title
                             [header]='header'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog()">
      <form [formGroup]="form" class="full-height-flex" (submit)='save($event)'>
        <div class="content-container flex-grow-1 file-edit-content d-flex flex-column">
          <div class="form-group" [class.has-error]="shouldShowNameInvalidState()" *ngIf="!isEditMode">
            <label for="fileName" class="input-label">File name*</label>
            <input type="text" id="fileName" class="form-control admn4-text-input" [formControlName]="nameControlName" cdkFocusInitial>
            <div class="validation-message-container">
              <adm4-validation-message *ngIf="shouldShowNameRequiredMessage()"
                                       [isError]='true'
                                       message="The file name is required."></adm4-validation-message>
              <adm4-validation-message *ngIf="shouldShowNameDupedMessage()"
                                       [isError]='true'
                                       message="A file with that name already exists."></adm4-validation-message>
            </div>
          </div>
          <div class="form-group flex-grow-1 d-flex flex-column" [class.has-error]="shouldShowValueInvalidState()">
            <label for="fileContent" class="input-label">File content*</label>
            <adm4-monaco-editor-form-control id="fileContent" class="form-control admn4-text-input flex-grow-1" cdkFocusInitial tabindex="0"
              [formControlName]="contentControlName" [syntax]="editorSyntax"
              (editorChanged)="monacoChanged()"
            ></adm4-monaco-editor-form-control>
            <div class="validation-message-container">
              <adm4-validation-message *ngIf="shouldShowContentRequiredMessage()"
                                       [isError]='true'
                                       message="The file content can not be empty."></adm4-validation-message>
            </div>
          </div>
          <div class='encoding-warning flex-grow-0 d-flex align-items-center'>
            <i class="fa fa-info-circle help-icon"></i>
            <span>The edited content will be saved with <code>utf-8</code> encoding.</span>
          </div>
        </div>
        <mat-dialog-actions>
          <button class='admn4-button-text' type='button' (click)='closeDialog()'>Cancel</button>
          <button class="admn4-button-ellipse-blue" type='button' [disabled]='!form.dirty || !form.valid' (click)="save($event)">Done</button>
        </mat-dialog-actions>
      </form>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['../../common/styles/component-specific/modal-window.scss', './pattern-file-editor-dialog.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatternFileEditorDialogComponent implements OnDestroy {

  private static readonly FILE_DUPE: string = 'fileNameAlreadyExists';

  private readonly destroyed$: Subject<boolean> = new Subject();

  public readonly contentControlName = 'fileContent';
  public readonly nameControlName = 'fileName';

  public readonly isEditMode: boolean;
  private readonly alreadyTakenFileNames: string[] = [];

  public readonly header: string;

  public readonly form: FormGroup<{
    fileContent: FormControl<string>;
    fileName: FormControl<Maybe<string>>;
  }>;

  public editorSyntax: string;

  constructor(
      fb: FormBuilder,
      @Inject(MAT_DIALOG_DATA) payload: PatternFileEditorDialogPayload,
      private dialogRef: MatDialogRef<PatternFileEditorDialogComponent, PatternFileEditorDialogResult | null>,
      private cdRef: ChangeDetectorRef,
  ) {
    if (payload.mode === 'edit') {
      this.form = fb.group({
        fileContent: new FormControl<string>(payload.content, {validators: [Validators.required], nonNullable: true}),
        fileName: new FormControl<Maybe<string>>({value: '', disabled: true}),
      });
      this.isEditMode = true;
      this.header = `Edit content of ${payload.fileName}`;
      this.editorSyntax = extractSyntaxFromFileName(payload.fileName);
    } else {
      this.isEditMode = false;
      this.header = 'Create new file';
      this.alreadyTakenFileNames = payload.alreadyTakenFileNames;
      const alreadyTakenValidator: ValidatorFn = (control: AbstractControl) => {
        const value = control.value;
        if (typeof value === 'string' && this.alreadyTakenFileNames.some((fileName: string) => fileName === value)) {
          return {[PatternFileEditorDialogComponent.FILE_DUPE]: true};
        }
        return null;
      };
      this.form = fb.group({
        fileContent: new FormControl<string>('', {validators: [Validators.required], nonNullable: true}),
        fileName: new FormControl<Maybe<string>>({value: '', disabled: false}, {validators: [Validators.required, alreadyTakenValidator], nonNullable: true}),
      });

      this.form.controls.fileName.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((fileName: Maybe<string>) => {
        this.editorSyntax = extractSyntaxFromFileName(fileName);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public monacoChanged(): void {
    this.cdRef.detectChanges();
  }

  public save(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      const newContent = this.form.controls.fileContent.value;
      if (this.isEditMode) {
        this.dialogRef.close({fileContentText: newContent});
      } else {
        const newFileName = this.form.controls.fileName.value || 'unnamed-file';
        this.dialogRef.close({fileContentText: newContent, fileName: newFileName});
      }
    }
  }

  public closeDialog() {
    this.dialogRef.close(null);
  }

  public shouldShowNameRequiredMessage(): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls.fileName, FormHelper.VALIDATION_KEY_REQUIRED);
  }

  public shouldShowNameDupedMessage(): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls.fileName, PatternFileEditorDialogComponent.FILE_DUPE);
  }

  public shouldShowContentRequiredMessage(): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls.fileContent, FormHelper.VALIDATION_KEY_REQUIRED);
  }

  public shouldShowNameInvalidState(): boolean {
    return FormHelper.shouldShowFormControlInvalid(this.form.controls.fileName);
  }

  public shouldShowValueInvalidState(): boolean {
    return FormHelper.shouldShowFormControlInvalid(this.form.controls.fileContent);
  }
}

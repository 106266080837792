import * as fromActions from '../actions';
import { ProjectActionTypes } from './project.actions';
import { IssueModel } from '../../common/model/issue.model';
import { Dictionary, ProjectKey } from '../reducer';
import * as _ from 'lodash';
import { Project } from '../../projects/project.model';
import * as fromVC from '../version-control';
import * as fromPattern from '../pattern/pattern.actions';
import * as fromVariables from '../variables/variable.actions';
import * as fromShared from '../shared/shared.actions';

export class ProjectState {
  readonly selectedProjectKey: ProjectKey | null;
  readonly allProjects: Dictionary<Project>;
  readonly issues: IssueModel[];
  readonly validating: boolean;
  readonly allBundles: string[];
  readonly projectMetaState: {
    local: string,
    remote: string
  };
  readonly projectIssueState: {
    local: string,
    remote: string
  };
}

export const initState: ProjectState = {
  selectedProjectKey: null,
  allProjects: {} as Dictionary<Project>,
  issues: [],
  validating: false,
  allBundles:  [],
  projectMetaState: {
    local: '',
    remote: ''
  },
  projectIssueState: {
    local: '',
    remote: ''
  }
};

export function reducer(state: ProjectState, action: fromActions.NevisAdminAction<any>): ProjectState {
  switch (action.type) {
    case fromShared.SharedActionTypes.SelectTenantKey:
      return initState;
    case ProjectActionTypes.SelectProject:
      return {
        ...state,
        selectedProjectKey: action.payload
      };
    case ProjectActionTypes.ConnectProjectToVersionControlSuccess:
      const connectedProject: Project = action.payload;
      return {
        ...state,
        allProjects: {
          ...state.allProjects,
          [connectedProject.projectKey]: connectedProject
        }
      };
    case ProjectActionTypes.LoadProjectsAndNavigate: {
      return initState;
    }
    case ProjectActionTypes.LoadIssuesSuccess: {
      return {
        ...state,
        validating: false,
        issues: action.payload as IssueModel[],
        projectIssueState: {local: state.projectIssueState.remote, remote: ''}
      };
    }
    case ProjectActionTypes.LoadProjectsSuccess: {
      const newEntries = _.mapKeys(action.payload as Project[], project => project.projectKey);
      return {
        ...state,
        allProjects: newEntries
      };
    }
    case ProjectActionTypes.ResetProjectsAfterDeletingLast:
      return {
        ...state,
        selectedProjectKey: null,
        allProjects: {}
      };
    case  ProjectActionTypes.ProjectMetaTimestampChangedInBackground: {
      return {
        ...state,
        projectMetaState: {local: action.payload.local, remote: action.payload.remote}
      };
    }
    case  ProjectActionTypes.UpdateProjectIssueTimestamp: {
      return {
        ...state,
        projectIssueState: {local: action.payload.local, remote: action.payload.remote}
      };
    }
    case fromVC.VersionControlActionTypes.LoadProjectMetaSuccess: {
      if (_.isNil(action.payload.lastModification)) {
        return state;
      }
      return {
        ...state,
        projectMetaState: {local: action.payload.lastModification, remote: action.payload.lastModification}
      };
    }
    case fromPattern.PatternActionTypes.LoadAllPatternInstancesSuccess: {
      return {
        ...state,
        projectMetaState: {local: state.projectMetaState.remote, remote: state.projectMetaState.remote}
      };
    }

    case fromPattern.PatternActionTypes.UpdatePatternInstanceSuccess: {
      return {
          ...state,
          validating: true,
       };
    }

    case fromVariables.VariableActionTypes.LoadVariablesSuccess: {
      return {
        ...state,
        projectMetaState: {local: state.projectMetaState.remote, remote: state.projectMetaState.remote}
      };
    }

    default: {
      return state;
    }
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from '../notification/notification-type.enum';
import { ConfirmationModalConfig, NotificationModelWithModalConfig } from '../notification/notification.model';
import { dialogs, ModalDialogOptions } from './modal-dialog-notification.component';
import { Subject } from 'rxjs';
import { closeModalOnEscape } from './modal-dialog.helper';

@Component({
  selector: 'adm4-confirmation-dialog',
  template: `
    <adm4-modal-dialog-title [ngClass]='options.style'
                             [header]="data.notificationMessage.headerTitle || options.header"
                             [showClose]="!dialogRef.disableClose"
                             (closeClicked)="closeDialog()">
      <div mat-dialog-content class="content-container">
        <span class="title">{{data.notificationMessage.title}}</span><br>
        <div class="description" [innerHtml]="data.notificationMessage.description">{{data.notificationMessage.description}}</div>
      </div>
      <mat-dialog-actions>
        <button class='admn4-button-text' (click)='closeDialog(false)'>{{cancelButtonText}}</button>
        <button class='admn4-button-ellipse-default'
                (click)="closeDialog(true)">
          {{confirmButtonText}}
        </button>
      </mat-dialog-actions>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['./modal-dialog-notification.component.scss']
})

export class ConfirmationDialogComponent implements OnInit, OnDestroy {
  public options: ModalDialogOptions;
  readonly DEFAULT_CONFIRM_BUTTON_TEXT = 'Ok';
  readonly DEFAULT_CANCEL_BUTTON_TEXT = 'Cancel';

  private destroyed$: Subject<boolean> = new Subject();

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: NotificationModelWithModalConfig<ConfirmationModalConfig>) {

    const notificationType = this.getNotificationType(data.type);
    this.options = dialogs[notificationType];
  }

  // Confirmation dialog is a warning, unless error confirmation is requested.
  getNotificationType(notificationType: NotificationType): NotificationType {
    switch (notificationType) {
      case NotificationType.DIALOG_CONFIRMATION:
        return NotificationType.DIALOG_WARNING;
      case NotificationType.DIALOG_INFO:
        return NotificationType.DIALOG_INFO;
      default:
        return NotificationType.DIALOG_ERROR;
    }
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get confirmButtonText(): string {
    return this.data.modalConfig.confirmButtonText || this.DEFAULT_CONFIRM_BUTTON_TEXT;
  }

  get cancelButtonText(): string {
    return this.data.modalConfig.cancelButtonText || this.DEFAULT_CANCEL_BUTTON_TEXT;
  }

  closeDialog(confirmed?: boolean): void {
    this.dialogRef.close(confirmed);
  }
}

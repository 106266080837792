import { first, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of, race, Subscription, timer } from 'rxjs';
import { SaveChangesDialogService } from '../../modal-dialog/save-changes-dialog/save-changes-dialog.service';
import { SaverService } from './saver.service';
import { MILLISECONDS_IN_SECOND } from '../constants/app.constants';
import { CONFIRM_UNSAVED_CHANGES } from '../../inventory/inventory-editor-deactivation-guard.service';
import { ModalNotificationService } from '../../notification/modal-notification.service';

/**
 * This service can be used to block the navigation in case there is unsaved changes.
 *
 * How to use:
 * - In the component, where the dirty state has to be protected, connect the service to a form, or a boolean observable which produces the dirty state.
 * - At places where navigation is triggered, pass your navigation function to `doIfConfirmed` so that it will be delayed until either
 *    - the user confirms saving the dirty state and it is saved
 *    - the user cancels the navigation and the user stays with their dirty component
 * - The `disconnect()` method has to be called when the connected component destroyed.
 */
@Injectable()
export class DirtyFormGuardConnectorService {
  private dirty$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private subscription: Subscription;

  /** The function which is invoked when the user presses save changes in the confirmation modal. */
  private proceedCallback: (() => void);
  /** Indicates if the confirmation modal should offer to auto-save changes. */
  private withAutoSave: boolean;

  constructor(private saverService: SaverService, private saveChangesDialogService: SaveChangesDialogService, public modalNotificationService: ModalNotificationService) {}

  private setValueChanges(observable: Observable<boolean>) {
    this.unsubscribeFromPrevious();
    this.subscription = observable.subscribe(dirty => {
      this.dirty$.next(dirty);
    });
  }

  /**
   * One subscription is provided at a time, so it unsubscribes from the previous one,
   * in case an other connection is requested
   */
  private unsubscribeFromPrevious() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // FIXME: this is a huge hack that is needed to modify current solution until we find out a good way to handle multi modals in case of save inventory changes during navigation
  // THIS IS A TEMPORARY SOLUTION ONLY, PLEASE, CLEAN THIS UP WHEN PROPER SOLUTION IS IMPLEMENTED
  private hackInventoryScreen(navigateCommand: () => void, rejectCommand: () => void): boolean {
    if (window.location.href.includes('inventories/') && this.dirty$.getValue()) {
      this.modalNotificationService.openConfirmDialog({
        headerTitle: `Warning`,
        title: 'Unsaved changes',
        description: CONFIRM_UNSAVED_CHANGES
      }, {
        confirmButtonText: 'Discard changes'
      }).afterClosed().subscribe((confirmed?: boolean) => {
        if (confirmed) {
          this.callNavigateCommand(navigateCommand);
          this.proceedCallback();
        } else {
          rejectCommand();
        }
      });
      return true;
    }
    return false;
  }

  /**
   * If page (not necessarily form) is dirty, then calls the navigateCommand function, only if the user confirmed saving changes.
   */
  public doIfConfirmed(navigateCommand: () => void, rejectCommand: () => void = () => {/* noop by default */}): void {
    if (this.hackInventoryScreen(navigateCommand, rejectCommand)) {
      return;
    }
    if (this.dirty$.getValue()) {
      this.saveChangesDialogService.confirmSavingChanges(() => this.proceedCallback(), this.withAutoSave)
        .pipe(
          switchMap((confirmed: boolean) => {
            if (confirmed) {
              // when user confirmed that he wants to save his changes before doing the action we need to wait until it's saved and it becomes pristine
              const formBecomesPristine$ = this.dirty$.pipe(first(dirty => !dirty), map(() => confirmed));
              // however if saving somehow failed the above stream will hang and wait until the saving really happens which could result in next manual saving to do the action immediately after save
              // therefore there is a timeout that expects save to be completed within certain time otherwise action is cancelled
              const cancelsActionAfterTimeout$ = timer(MILLISECONDS_IN_SECOND).pipe(map(() => false));
              return race(formBecomesPristine$, cancelsActionAfterTimeout$);
            }
            return of(confirmed);
          })
        )
        .subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.callNavigateCommand(navigateCommand);
          } else {
            rejectCommand();
          }
        });
    } else {
      this.callNavigateCommand(navigateCommand);
    }
  }

  private callNavigateCommand(navigateCommand: () => void) {
    if (_.isFunction(navigateCommand)) {
      navigateCommand();
    }
  }

  /**
   * This will connect the service to a form.
   * In the other part of the app, call the doIfConfirmed upon navigation.
   *
   * @param {UntypedFormGroup} group - which should be checked for dirtiness.
   */
  public connectForm(group: UntypedFormGroup) {
    const observable = group.valueChanges.pipe(map(() => group.dirty));
    this.connect(observable);
    this.withAutoSave = true;
    this.proceedCallback = () => this.saverService.save();
  }

  /**
   * This will connect the service to a boolean observable, what produces the dirty state of the form.
   * In the other part of the app, call the doIfConfirmed upon navigation.
   *
   * @param {Observable<boolean>} observable - what produces the dirty state of the form.
   * @param {() => void} proceedCallback - The function which is invoked when the user presses save changes in the confirmation modal.
   * @param {boolean} withAutoSave - Optional parameter to control if the confirmation modal should offer to auto-save changes.
   */
  public connect(observable: Observable<boolean>, proceedCallback?: () => void, withAutoSave = true) {
    this.setValueChanges(observable);
    this.dirty$.next(false);
    this.proceedCallback = _.isFunction(proceedCallback) ? proceedCallback : () => this.saverService.save();
    this.withAutoSave = withAutoSave;
  }

  /**
   * disconnect has to be called when the connected component destroyed
   */
  public disconnect() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.dirty$.next(false);
  }
}

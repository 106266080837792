import { shouldUseHashStrategy } from '../../app-routing.constants';
import * as _ from 'lodash';

export class UrlHelper {
  /**
   * It return the head of url which is going to be on the second place of the array
   * (the first is empty string because url starts with '/')
   * @param url
   * @returns {string} head of url
   */
  static getHeadOfUrl(url: string): string {
    const urlFragments = url.split('/');
    return urlFragments[1];
  }

  static combineFullUrl(feNaviationPath: string): string {
    const baseUrl = shouldUseHashStrategy ? `${window.location.origin}${window.location.pathname}` : document.baseURI;
    const hash = shouldUseHashStrategy ? '#' : '';
    return `${baseUrl}${hash}${feNaviationPath}`;
  }

  static isLogin(url: string): boolean {
    return _.includes(url, 'login?');
  }

  static isGetUser(url: string | null): boolean {
    return _.isNil(url) ? false : url.endsWith('me');
  }

  static isLogout(url: string | null): boolean {
    return _.isNil(url) ? false : url.endsWith('logout');
  }
}

<ng-template #commitInfoPopover>
  <adm4-commit-info [commitInfo]='remoteCommitInfo'></adm4-commit-info>
</ng-template>

<div *ngIf='shouldShowDiff' class='diff-view-element-container'>
  <div class='remote'>
    <ng-container *ngIf='shouldShowRemote'>
      <div class='change-info'>
        <ng-container *ngIf="hasRemoteCommitInfo">
        <span> published by
          <strong>{{remoteCommitInfo.committer.name}},
            <span class="date" [ngbTooltip]='commitInfoPopover' placement='top'>
                {{remoteCommitInfo.committer.when | customDate}}
            </span>
          </strong>
        </span>
          <img *ngIf='isDoneByAnotherUser(remoteCommitInfo.committer.name)' width="16" height="16" src='assets/warningUserChanges24px.png'
               alt="Icon warning about user changes"/>
        </ng-container>
      </div>
      <div [class.change-content-box]='boxed'>
        <div [class.unmodified]='isUnmodified'>
          <ng-content select='[remote]'></ng-content>
        </div>
      </div>
    </ng-container>
  </div>
  <div class='local'>
    <div class='change-info'>
      <ng-container *ngIf="hasLocalInfo">
        <span> {{localChangeInfo.localStatus | lowercase}} by
          <strong>{{localChangeInfo.localAuthor}},
            <span class="date">
                {{localChangeInfo.localDate | customDate}}
            </span>
          </strong>
        </span>
        <img *ngIf='isDoneByAnotherUser(localChangeInfo.localAuthor)' width="16" height="16" src='assets/warningUserChanges24px.png'
             alt="Icon warning about user changes"/>
      </ng-container>
    </div>
    <div [class.change-content-box]='boxed'>
      <div [class.unmodified]='isUnmodified'>
        <ng-container *ngIf='shouldShowLocal; else deletedLocalLabel'>
          <ng-content select='[local]'></ng-content>
        </ng-container>
        <ng-template #deletedLocalLabel>
          <div class='deleted-local-label'>
            {{localFallbackLabel}}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
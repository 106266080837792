import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectSyncService } from './project-sync.service';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { ProjectContext } from './project.context';
import { AppState, Dictionary, ProjectKey } from '../model/reducer';
import { Project } from './project.model';
import { IssueModel } from '../common/model/issue.model';
import { PluginModel } from '../plugins/plugin.model';
import { VariableModel } from '../variables/variable.model';
import { ProjectIssuesDisplayContext } from './project-issues-display.context';
import { canCreateProjectView, hasTenantModificationAccessView } from '../model/views/permission.views';
import { Maybe } from '../common/utils/utils';

/**
 * This component represents the project list
 */
@Component({
  selector: 'adm4-project',
  template: `
    <adm4-area-with-side-menu [title]='"Configuration"'>
      <adm4-project-menu side-menu
                         [projects]='projects$ | async'
                         [versioned]='versioned$ | async'
                         [variables]='variables$ | async'
                         [issues]='issues$ | async'
                         [readOnly]='isProjectReadOnly$ | async'
                         [currentProjectKey]='projectKey$ | async'
                         [patternPlugins]='pluginsByPatternType$ | async'
                         [firstPatternId]='firstPatternId | async'
                         [canCreateProject]='canCreateProject$ | async'
                         [canModifyTenant]='canModifyTenant$ | async'
      ></adm4-project-menu>
      <router-outlet area-content></router-outlet>
    </adm4-area-with-side-menu>
  `,
  providers: [ProjectContext, ProjectIssuesDisplayContext]
})
export class ProjectComponent implements OnInit, OnDestroy {
  projectKey$: Observable<ProjectKey>;
  projects$: Observable<Dictionary<Project>>;
  issues$: Observable<IssueModel[]>;
  versioned$: Observable<boolean>;
  variables$: Observable<VariableModel[]>;
  isProjectReadOnly$: Observable<boolean>;
  pluginsByPatternType$: Observable<PluginModel[]>;
  canCreateProject$: Observable<boolean>;
  firstPatternId: Observable<Maybe<string>>;
  canModifyTenant$: Observable<boolean>;

  constructor(
      private projectSyncService: ProjectSyncService,
      private projectContext: ProjectContext,
      private store$: Store<AppState>,
  ) {
    this.projectKey$ = this.projectContext.projectKey$;
    this.pluginsByPatternType$ = this.projectContext.pluginsByPatternType$;
    this.variables$ = this.projectContext.variables$;
    this.projects$ = this.projectContext.projects$;
    this.issues$ = this.projectContext.issues$;
    this.versioned$ = this.projectContext.versioned$;
    this.isProjectReadOnly$ = projectContext.isProjectReadOnly$;
    this.firstPatternId = projectContext.firstPatternId;
    this.canCreateProject$ = this.store$.select(canCreateProjectView);
    this.canModifyTenant$ = this.store$.select(hasTenantModificationAccessView);
  }

  ngOnInit(): void {
    this.projectSyncService.startSync();
  }

  ngOnDestroy(): void {
    this.projectSyncService.stopSync();
  }
}

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { Link } from '../plugins/link.model';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { LinkType } from '../plugins/link-type.enum';
import { environment } from '../../environments/environment';

@Component({
  selector: 'adm4-property-external-link-list',
  template: `
    <adm4-external-link *ngFor='let link of links'
                        [linkUrl]='resolveLinkUrl(link)'
                        [linkLabel]='link.label'
                        [displayStyle]="'block'"
                        [matIconName]='resolveIconName(link)'
                        [openInNewTab]='shouldLinkOpenInANewTab(link)'
                        [isDisabled]="isLinkDisabled(link, dynamicLinksDisabled$ | async)"
                        [ngbTooltip]='disabledExternalLinkReasonTooltip$ | async' [disableTooltip]='!isLinkDisabled(link, dynamicLinksDisabled$ | async)' placement='top'
    ></adm4-external-link>
    <ng-template #disabledLinkValidationReason>
      <div class='disabled-link-validation-reason'>
        <mat-spinner [diameter]='15' mode="indeterminate"></mat-spinner>
        Calculation in progress...
      </div>
    </ng-template>
    <ng-template #disabledLinkErrorReason>
      This page is not available because the project has issues.
      <br>
      Please <a [routerLink]="['/projects', projectKey, 'issues']">fix the issues</a> first.
    </ng-template>
    <ng-template #disabledLinkDirtyFormReason>
      Please save changes to enable this link.
    </ng-template>
  `,
  styleUrls: ['./property-external-link-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyExternalLinkListComponent implements AfterViewInit {
  @Input() links: Link[];
  @Input() projectKey: string;
  @Input() patternId: string;
  @Input() externalLinkDisablingDependencies$: Observable<[boolean, boolean, boolean]>;
  @ViewChild('disabledLinkValidationReason', {static: false}) disabledLinkValidationReason: TemplateRef<any>;
  @ViewChild('disabledLinkErrorReason', {static: false}) disabledLinkErrorReason: TemplateRef<any>;
  @ViewChild('disabledLinkDirtyFormReason', {static: false}) disabledLinkDirtyFormReason: TemplateRef<any>;

  dynamicLinksDisabled$: Observable<boolean>;
  disabledExternalLinkReasonTooltip$: Observable<TemplateRef<any> | null>;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.dynamicLinksDisabled$ = this.externalLinkDisablingDependencies$
      .pipe(debounceTime(0), map(([isValidationInProgress, isErroredProject, formDirty]: [boolean, boolean, boolean]) => isValidationInProgress || isErroredProject || formDirty));
    this.disabledExternalLinkReasonTooltip$ = this.externalLinkDisablingDependencies$
      .pipe(debounceTime(0), map(([isValidationInProgress, isErroredProject, formDirty]: [boolean, boolean, boolean]) => this.resolveReasonTooltip(isValidationInProgress, formDirty, isErroredProject)));
    this.cdr.markForCheck();
  }

  shouldLinkOpenInANewTab(link: Link): boolean {
    return link.linkType !== LinkType.RESOURCE;
  }

  isLinkDisabled(link: Link, dynamicLinksDisabled: boolean): boolean {
    return link.linkType === LinkType.DYNAMIC_DATA && dynamicLinksDisabled;
  }

  resolveLinkUrl(link: Link): string {
    switch (link.linkType) {
      case LinkType.DYNAMIC_DATA:
        return `${environment.externalUrl}/projects/${this.projectKey}/patterns/${this.patternId}/dynamic-data/${link.target}`;
      case LinkType.RESOURCE:
        return `${environment.externalUrl}/projects/${this.projectKey}/resources/${link.target}`;
      default:
        return '';
    }
  }

  resolveIconName(link: Link): string {
    switch (link.linkType) {
      case LinkType.DYNAMIC_DATA:
        return 'open_in_new';
      case LinkType.RESOURCE:
        return 'get_app';
      default:
        return '';
    }
  }

  resolveReasonTooltip(isValidationInProgress: boolean, formDirty: boolean, isErroredProject: boolean): TemplateRef<any> | null {
    if (isValidationInProgress) {
      return this.disabledLinkValidationReason;
    } else if (formDirty) {
      return this.disabledLinkDirtyFormReason;
    } else if (isErroredProject) {
      return this.disabledLinkErrorReason;
    }
    return null;
  }
}

import { Observable, Subject } from 'rxjs';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import * as _ from 'lodash';
import { Project } from '../../project.model';
import { BranchProject } from '../../../model/project';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { BranchProjectContext } from './branch-project.context';

@Component({
  selector: 'adm4-branch-project-container',
  template: `
    <adm4-modal-dialog-title [header]='header'
                             [showClose]='true'
                             (closeClicked)="dialogRef.close()">
      <adm4-branch-project [projects]='branchProjectContext.projects$ | async'
                           [predefinedProjectData]='predefinedProjectData'
                           [selectedProject]='selectedProject$ | async'
                           [selectedTenantKey]='branchProjectContext.selectedTenantKey$ | async'
                           [displayTenant]='branchProjectContext.displayTenant$ | async'
                           [projectMeta]='branchProjectContext.projectMeta$ | async'
                           (cancelClicked)='onCancelClicked()'
                           (branchProject)='onBranchProject($event)'>
      </adm4-branch-project>
    </adm4-modal-dialog-title>
  `,
  providers: [
    BranchProjectContext
  ]
})
export class BranchProjectContainerComponent implements OnDestroy {
  header = 'Branch Project';
  selectedProject$: Observable<Project | null>;
  private destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public predefinedProjectData: Project | null,
              public dialogRef: MatDialogRef<BranchProjectContainerComponent>,
              public branchProjectContext: BranchProjectContext,
              private store$: Store<AppState>) {
    closeModalOnEscape(dialogRef, this.destroyed$);
    if (_.isNil(predefinedProjectData)) {
      this.selectedProject$ = this.branchProjectContext.selectedProject$;
    }
  }

  onCancelClicked(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onBranchProject(project: Project): void {
    this.store$.dispatch(new BranchProject(project));
    this.dialogRef.close();
  }
}

import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';
import * as _ from 'lodash';

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {

  /**
   * Determines if a route should be reused
   * @param future
   * @param current
   */
  override shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    if (_.isNil(future?.routeConfig?.data?.reuse)) {
      return super.shouldReuseRoute(future, current);
    }

    return future?.routeConfig?.data?.reuse === current?.routeConfig?.data?.reuse;
  }
}

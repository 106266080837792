import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Tenant } from '../../tenant/tenant.model';
import { Inventory } from '../inventory.model';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { CREATED_INVENTORY_KEY_FORM_CONTROL_NAME, EXISTING_INVENTORY_KEY_FORM_CONTROL_NAME, INVENTORY_KEY_MODE_FORM_CONTROL_NAME, INVENTORY_ZIP_FORM_CONTROL_NAME, InventoryKeyMode } from './file-based-import-inventory-form-value.model';
import * as _ from 'lodash';
import { OperationKey } from '../../model/permissions/permissions.model';
import { takeUntil } from 'rxjs/operators';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { FileBasedImportInventoryHelper } from './file-based-import-inventory.helper';
import { InventoryValidators } from '../inventory.validators';
import { FormHelper } from '../../common/helpers/form.helper';
import { InventoryFileImportPayload } from './inventory-file-import-payload.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';

@Component({
  selector: 'adm4-file-based-import-inventory',
  templateUrl: './file-based-import-inventory.component.html',
  styleUrls: ['../../common/styles/component-specific/modal-window.scss', '../../common/styles/component-specific/create-form.scss']
})
export class FileBasedImportInventoryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedTenant: Tenant;
  @Input() inventories: Inventory[];
  @Input() displayTenant: boolean;
  @Output() cancelClicked: EventEmitter<void> = new EventEmitter();
  @Output() inventoryImport: EventEmitter<InventoryFileImportPayload> = new EventEmitter();

  readonly InventoryKeyMode = InventoryKeyMode;
  readonly INVENTORY_ZIP_FORM_CONTROL_NAME = INVENTORY_ZIP_FORM_CONTROL_NAME;
  readonly INVENTORY_KEY_MODE_FORM_CONTROL_NAME = INVENTORY_KEY_MODE_FORM_CONTROL_NAME;
  readonly CREATED_INVENTORY_KEY_FORM_CONTROL_NAME = CREATED_INVENTORY_KEY_FORM_CONTROL_NAME;
  readonly EXISTING_INVENTORY_KEY_FORM_CONTROL_NAME = EXISTING_INVENTORY_KEY_FORM_CONTROL_NAME;
  readonly ERROR_INVALID_INVENTORY_KEY = 'The inventory key entered contains some invalid characters. Must be of format [A-Z0-9_-]';
  readonly ERROR_INVENTORY_KEY_REQUIRED = 'The inventory key is required';
  readonly DISABLED_NEW_INVENTORY_MODE_TOOLTIP_TEXT = `You don't have permission to create inventories.`;
  readonly DISABLED_EXISTING_INVENTORY_MODE_TOOLTIP_TEXT = `You don't have permission to modify existing inventories.`;

  form: UntypedFormGroup;
  inventoriesWithModifyPermission: Inventory[];
  filteredInventoryList: Inventory[];

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private fb: UntypedFormBuilder,
              private modalNotificationService: ModalNotificationService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.form = this.fb.group({
      [this.INVENTORY_ZIP_FORM_CONTROL_NAME]: this.fb.control(null, [Validators.required]),
      [this.INVENTORY_KEY_MODE_FORM_CONTROL_NAME]: this.fb.control(null, [Validators.required]),
      [this.CREATED_INVENTORY_KEY_FORM_CONTROL_NAME]: this.fb.control('', [
        this.wrapInventoryKeyValidator(Validators.required, InventoryKeyMode.New),
        this.wrapInventoryKeyValidator(Validators.pattern(/^[a-zA-Z0-9_-]+$/), InventoryKeyMode.New),
        this.wrapInventoryKeyValidator(InventoryValidators.checkExistingInventoryKey(this.inventories, this.selectedTenant.tenantKey), InventoryKeyMode.New)
      ]),
      [this.EXISTING_INVENTORY_KEY_FORM_CONTROL_NAME]: this.fb.control(null, [this.wrapInventoryKeyValidator(Validators.required, InventoryKeyMode.Existing)])
    });
    this.form.controls[this.INVENTORY_ZIP_FORM_CONTROL_NAME].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((inventoryZip: File | null) => {
        this.handleFileSelection(inventoryZip);
        this.cdr.markForCheck();
      });
    this.form.controls[this.INVENTORY_KEY_MODE_FORM_CONTROL_NAME].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        _.values(this.form.controls).forEach(control => control.updateValueAndValidity({emitEvent: false}));
        this.cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inventories) {
      this.inventoriesWithModifyPermission = this.inventories.filter(inventory => _.includes(inventory._userAuthorization, OperationKey.MODIFY_INVENTORY));
      this.updateSearchResult(this.inventoriesWithModifyPermission);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private handleFileSelection(inventoryZip: File | null): void {
    const inventoryNameFromZip = FileBasedImportInventoryHelper.getInventoryNameFromZip(inventoryZip);
    const existingInventoryKey: string | undefined = this.inventories.map(inventory => inventory.inventoryKey).find(inventoryKey => TenantHelper.cropTenantFromKey(inventoryKey) === inventoryNameFromZip);
    if (_.isNil(inventoryZip)) {
      this.form.reset(undefined, {emitEvent: false});
    } else if (_.isNil(inventoryNameFromZip)) {
      this.form.controls[this.INVENTORY_KEY_MODE_FORM_CONTROL_NAME].setValue(null);
    } else if (!_.isNil(existingInventoryKey) && this.hasModifyInventoryPermission) {
      this.form.controls[this.INVENTORY_KEY_MODE_FORM_CONTROL_NAME].setValue(InventoryKeyMode.Existing);
      this.form.controls[this.EXISTING_INVENTORY_KEY_FORM_CONTROL_NAME].setValue(existingInventoryKey);
    } else if (!_.isNil(inventoryNameFromZip) && this.hasCreateInventoryPermission) {
      this.form.controls[this.INVENTORY_KEY_MODE_FORM_CONTROL_NAME].setValue(InventoryKeyMode.New);
      this.form.controls[this.CREATED_INVENTORY_KEY_FORM_CONTROL_NAME].setValue(inventoryNameFromZip);
    }
  }

  private wrapInventoryKeyValidator(validator: ValidatorFn, mode: InventoryKeyMode): ValidatorFn {
    return (control: AbstractControl) => {
      const inventoryKeyModeFormControl = _.isNil(this.form) ? null : this.form.controls[this.INVENTORY_KEY_MODE_FORM_CONTROL_NAME];
      if (!_.isNil(inventoryKeyModeFormControl) && inventoryKeyModeFormControl.value === mode) {
        return validator(control);
      }
      return null;
    };
  }

  shouldShowCreatedInventoryKeyErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[this.CREATED_INVENTORY_KEY_FORM_CONTROL_NAME], errorKey);
  }

  chooseNewInventoryKeyMode(): void {
    if (!this.canCreateNewInventory) {
      return;
    }
    this.form.controls[this.INVENTORY_KEY_MODE_FORM_CONTROL_NAME].setValue(InventoryKeyMode.New);
  }

  chooseExistingInventoryKeyMode(): void {
    if (!this.canSelectExistingInventory) {
      return;
    }
    this.form.controls[this.INVENTORY_KEY_MODE_FORM_CONTROL_NAME].setValue(InventoryKeyMode.Existing);
  }

  submitInventoryZip(): void {
    if (!this.canImport) {
      return;
    }
    const importInventoryPayload: InventoryFileImportPayload = FileBasedImportInventoryHelper.createProjectFileImportPayload(this.form.value, this.selectedTenant.tenantKey);
    if (this.isInExistingInventoryMode) {
      this.importIntoExistingInventoryAfterConfirmation(importInventoryPayload);
      return;
    }
    this.importInventory(importInventoryPayload);
  }

  importInventory(importInventoryPayload: InventoryFileImportPayload): void {
    this.inventoryImport.emit(importInventoryPayload);
  }

  importIntoExistingInventoryAfterConfirmation(importInventoryPayload: InventoryFileImportPayload): void {
    const inventoryName = TenantHelper.cropTenantFromKey(importInventoryPayload.inventoryKey);
    this.modalNotificationService.openConfirmDialog({
      headerTitle: `Warning`,
      title: 'Overwriting existing inventory',
      description: `${inventoryName} inventory data will be lost and replaced with the imported inventory from ${importInventoryPayload.inventoryFile.name} file. It cannot be undone. Do you want to continue?`
    }, {
      confirmButtonText: 'Import'
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed) {
        this.importInventory(importInventoryPayload);
      }
    });
  }

  cancel(): void {
    this.cancelClicked.emit();
  }

  get hasZipSelected(): boolean {
    return !_.isNil(this.form.value[this.INVENTORY_ZIP_FORM_CONTROL_NAME]);
  }

  get canImport(): boolean {
    return this.form.valid;
  }

  get isInExistingInventoryMode(): boolean {
    return this.form.value[INVENTORY_KEY_MODE_FORM_CONTROL_NAME] === InventoryKeyMode.Existing;
  }

  get hasCreateInventoryPermission(): boolean {
    return _.includes(this.selectedTenant._userAuthorization, OperationKey.CREATE_INVENTORY);
  }

  get hasModifyInventoryPermission(): boolean {
    return !_.isEmpty(this.inventoriesWithModifyPermission);
  }

  get canCreateNewInventory(): boolean {
    return this.hasCreateInventoryPermission && this.hasZipSelected;
  }

  get canSelectExistingInventory(): boolean {
    return this.hasModifyInventoryPermission && this.hasZipSelected;
  }

  get shouldShowDisabledNewInventoryModeTooltip(): boolean {
    return !this.hasCreateInventoryPermission && this.hasZipSelected;
  }

  get shouldShowDisabledExistingInventoryModeTooltip(): boolean {
    return !this.hasModifyInventoryPermission && this.hasZipSelected;
  }

  get inventoryForTenantExistsError(): string {
    return `This key is already in use for tenant ${this.selectedTenant.tenantKey}`;
  }

  searchableInventoryFormatFn = (inventory: Inventory): string => {
    return TenantHelper.cropTenantFromKey(inventory.inventoryKey);
  };

  updateSearchResult(filteredList: Inventory[]): void {
    this.filteredInventoryList = filteredList;
  }

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }
}

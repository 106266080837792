import { Observable, of, throwError } from 'rxjs';
import { readFileInfo } from '../../file-utils';
import { switchMap } from 'rxjs/operators';
import { FileInfo } from '../../common/model/file-info.model';

export class YamlCertificateHelper {

  static validateCertificate(file: File): Observable<boolean> {
    return readFileInfo(file).pipe(
      switchMap((fileInfo: FileInfo) => {
        return this.isValidCertificate(fileInfo);
      })
    );
  }

  static isValidCertificate(fileInfo: FileInfo): Observable<boolean> {
    const regExpPemCertificate = new RegExp('^.*\\.(pem)$');
    const isPemFormat = regExpPemCertificate.test(fileInfo.name);
    const isValidCertificateContent = new RegExp('(-+BEGIN CERTIFICATE-+)([^]*?)(-+END CERTIFICATE-+)', 'g').test(fileInfo.content);
    if (isPemFormat && isValidCertificateContent) {
      return of(true);
    }
    return throwError(new Error(this.createErrorMessageForFailedCertificates(isPemFormat, isValidCertificateContent, fileInfo.name)));
  }

  private static createErrorMessageForFailedCertificates(isPemFormat: boolean, isValidCertificateContent: boolean, fileName: string): string {
    const unsupportedExtensionInfo = `The file format is not supported. Please upload only .pem file.`;
    const unsupportedFormatInfo = `Certificate is invalid or was not found. Please fix the file ${fileName} and try again.`;
    if (!isPemFormat) {
      return unsupportedExtensionInfo;
    }
    return isValidCertificateContent ? '' : unsupportedFormatInfo;
  }
}


import { first } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OperationKey, PermissionAssignee } from '../../model/permissions/permissions.model';
import { ProjectSettingsContext } from '../project-settings.context';
import { Observable } from 'rxjs';
import { PermissionsDialogService } from '../../permissions/permissions-dialog.service';
import { projectPermissionOperationColumns } from './project-permission-operation-columns.constant';
import { OperationColumnData } from '../../permissions/model/operation-column-data.model';
import { PermissionsHelper } from '../../permissions/permissions.helper';
import { PermissionsService } from '../../permissions/permissions.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';

@Component({
  selector: 'adm4-project-permissions',
  template: `
    <div class="section-title">Permissions</div>
    <div class="table-container">
      <adm4-permissions-table
              [target]="target"
              [permissionAssignees]="permissionAssignees$ | async"
              [operationColumns]='operationColumns'
              [editMode]="false"
      ></adm4-permissions-table>
      <div *ngIf="!readOnly" class="button-container">
        <button class="admn4-button-ellipse-blue" (click)='openPermissionsEditTable()'>Edit</button>
      </div>
    </div>
  `,
  styleUrls: ['project-permissions.component.scss']
})
export class ProjectPermissionsComponent implements OnChanges {
  @Input() projectKey: string;
  @Input() readOnly: boolean;

  target: string;
  permissionAssignees$: Observable<PermissionAssignee[]>;

  readonly operationColumns: OperationColumnData[] = projectPermissionOperationColumns;
  readonly allowedOperationKeys: OperationKey[] = PermissionsHelper.getOperationKeysFromPermissionOperationColumns(projectPermissionOperationColumns);

  constructor(private projectSettingsContext: ProjectSettingsContext,
              private permissionsService: PermissionsService,
              private projectPermissionsDialogService: PermissionsDialogService,
              private toastNotificationService: ToastNotificationService,
  ) {
    this.permissionAssignees$ = this.projectSettingsContext.projectPermissions;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectKey) {
      this.target = PermissionsHelper.createProjectTarget(this.projectKey);
      this.projectSettingsContext.loadProjectPermissions(this.target, this.allowedOperationKeys);
    }
  }

  openPermissionsEditTable() {
    const onSave = (permissionModifications) => {
      this.permissionsService.updatePermissions(permissionModifications)
        .subscribe(
          () => this.projectSettingsContext.loadProjectPermissions(this.target, this.allowedOperationKeys),
          () => this.toastNotificationService.showErrorToast('Could not save permissions. Something went wrong.')
        );
    };
    this.permissionsService.getPermissionAssigneesForTarget(this.target, this.allowedOperationKeys, true, true)
      .pipe(first())
      .subscribe((projectPermissionAssignees) => {
        this.projectPermissionsDialogService.openPermissionsEditTable(this.target, projectPermissionAssignees, projectPermissionOperationColumns, onSave);
      });
  }
}

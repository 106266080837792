import { Component } from '@angular/core';

import { InventoryContext } from '../inventory/inventory.context';

@Component({
  selector: 'adm4-resources',
  template: `
    <adm4-area-with-side-menu [title]='"Resources"'>
      <adm4-resources-menu side-menu></adm4-resources-menu>
      <div area-content class='full-height'>
        <router-outlet></router-outlet>
      </div>
    </adm4-area-with-side-menu>
  `,
  providers: [InventoryContext]
})
export class ResourcesComponent {
}

import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BaseService } from '../shared/base.service';
import { Tenant } from './tenant.model';
import {
  DeploymentHistoryItem, DeploymentHistoryJobStatus
} from '../common/model/deployment-history.model';

/**
 * This service provides methods to get data about tenants.
 */
@Injectable()
export class TenantService extends BaseService {

  getAllTenants(): Observable<Tenant[]> {
    const url = '/tenants';
    return this.httpGetUnwrapped(url);
  }

  getTenantDeploymentHistory(tenantKey: string, sinceTime: Date, status: DeploymentHistoryJobStatus): Observable<Array<DeploymentHistoryItem>> {
    const url = `/tenants/${tenantKey}/deployment-history`;
    const params: HttpParams = new HttpParams().set('since', sinceTime.toISOString()).set('jobStatus', status);
    return this.httpGetUnwrapped<Array<DeploymentHistoryItem>>(url, undefined, params);
  }
}

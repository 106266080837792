/**
 * It's a util class that has utility function on different key events
 */

/**
 * Blurs the target element on a key event
 * @param event
 */
export function blurOnKeyDown(event): void {
  try {
    event.stopPropagation();
    event.target.blur();
  } catch (e) {
    console.error(`Something went wrong during blur: ${e}`);
  }
}

export enum Keys {
  UP_ARROW = 'ArrowUp',
  DOWN_ARROW = 'ArrowDown',
  ESCAPE = 'Escape',
}

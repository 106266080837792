import { NgModule } from '@angular/core';
import { PatternMenuComponent } from './pattern-menu.component';
import { CommonModules } from '../../common/common.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { BatchActionMenuModule } from '../../patterns/batch-actions-menu/batch-action-menu.module';

@NgModule({
  imports: [
    CommonModules,
    MatChipsModule,
    MatAutocompleteModule,
    MatInputModule,
    BatchActionMenuModule
  ],
  declarations: [
    PatternMenuComponent
  ],
  exports: [
    PatternMenuComponent
  ]
})
export class PatternMenuModule {
}

import { NevisAdminAction } from '../actions';
import { PluginModel } from '../../plugins/plugin.model';
import { ReportGenerationModel, ReportGenerationStatusModel } from '../../reports/reports.model';
import { ReportGenerationOutputLoadingPayload } from './report-generation-output-loading-payload.model';
import { ProjectSummaryReportModel } from '../../projects/project-summary/project-summary-report/project-summary-report.model';

export enum ReportActionTypes {
  LoadReports = '[Reports] Load Reports',
  LoadReportsSuccess = '[Reports] Load Reports Success',
  GenerateReport = '[Reports] Generate Report',
  PollGenerationStatus = '[Reports] Poll generation status',
  PollGenerationStatusSuccess = '[Reports] Poll generation status success',
  GetGenerationOutput = '[Reports] Get generation output',
  GetGenerationOutputComplete = '[Reports] Get generation output complete',
  StopGeneration = '[Reports] Stop generation',
  LoadPatternSummaryReports = '[Reports] Load PatternSummaryReports',
  InvalidatePatternSummaryReports = '[Reports] Invalidate PatternSummaryReports',
  LoadPatternSummaryReportsSuccess = '[Reports] Load PatternSummaryReports success',
  FailedToLoadLoadPatternSummaryReport = '[Reports] PatternSummaryReports failed to load',
}

export class LoadReports implements NevisAdminAction<string> {
  readonly type = ReportActionTypes.LoadReports;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class LoadReportsSuccess implements NevisAdminAction<PluginModel[]> {
  readonly type = ReportActionTypes.LoadReportsSuccess;

  constructor(public payload: PluginModel[]) {}
}

export class GenerateReport implements NevisAdminAction<ReportGenerationModel> {
  readonly type = ReportActionTypes.GenerateReport;

  constructor(public payload: ReportGenerationModel) {}
}

export class PollGenerationStatus implements NevisAdminAction<string> {
  readonly type = ReportActionTypes.PollGenerationStatus;

  /**
   * @param payload - generationId
   */
  constructor(public payload: string) {}
}

export class PollGenerationStatusSuccess implements NevisAdminAction<ReportGenerationStatusModel> {
  readonly type = ReportActionTypes.PollGenerationStatusSuccess;

  constructor(public payload: ReportGenerationStatusModel) {}
}

export class GetGenerationOutput implements NevisAdminAction<ReportGenerationStatusModel> {
  readonly type = ReportActionTypes.GetGenerationOutput;

  constructor(public payload: ReportGenerationStatusModel) {}
}

export class GetGenerationOutputComplete implements NevisAdminAction<ReportGenerationOutputLoadingPayload> {
  readonly type = ReportActionTypes.GetGenerationOutputComplete;

  constructor(public payload: ReportGenerationOutputLoadingPayload) {}
}

export class StopGeneration implements NevisAdminAction {
  readonly type = ReportActionTypes.StopGeneration;
}

export class LoadPatternSummaryReports implements NevisAdminAction<string> {
  readonly type = ReportActionTypes.LoadPatternSummaryReports;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class InvalidatePatternSummaryReports implements NevisAdminAction<string> {
  readonly type = ReportActionTypes.InvalidatePatternSummaryReports;

  /**
   * @param payload - projectKey
   */
  constructor(public payload: string) {}
}

export class LoadPatternSummaryReportsSuccess implements NevisAdminAction<ProjectSummaryReportModel[]> {
  readonly type = ReportActionTypes.LoadPatternSummaryReportsSuccess;

  constructor(public payload: ProjectSummaryReportModel[]) {}
}

export class FailedToLoadLoadPatternSummaryReport implements NevisAdminAction<number> {
  readonly type = ReportActionTypes.FailedToLoadLoadPatternSummaryReport;

  /**
   * @param payload - status code
   */
  constructor(public payload: number) {}
}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

/**
 * It shortens the string keeping the specified number of characters
 */
@Pipe({
  name: 'abbreviate'
})
export class AbbreviatePipe implements PipeTransform {

  transform(value: string, keep: number): string {
    if (_.isNil(value)) {
      return '';
    }
    return value.substring(0, keep);
  }
}

import { VersionControlForm } from '../../common/components/version-control-form/version-control.form';
import * as _ from 'lodash';
import { DEFAULT_BRANCH_NAME } from '../../common/components/version-control-form/version-control-form.constants';
import { VersionControlHelper } from '../../common/helpers/version-control.helper';
import { VersionControlData } from '../../common/model/version-control-data.model';

export class ConnectProjectVersionControlHelper {
  static getProjectVersionControlData(versionControlForm: VersionControlForm): VersionControlData {
    const branchName = _.isNil(versionControlForm.branch) ? DEFAULT_BRANCH_NAME : versionControlForm.branch.name;
    return {
      repository: versionControlForm.repository,
      branch: branchName,
      path: VersionControlHelper.getValidPath(versionControlForm.path)
    };
  }
}

import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { combineLatest, merge, Observable, of } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';

import * as _ from 'lodash';

import { AppState, Dictionary, ProjectKey } from '../model/reducer';
import { canCreateProjectView, isSelectedProjectReadonlyView } from '../model/views/permission.views';
import { Project, ProjectMeta, ProjectMetaChangeIndicator } from './project.model';
import { IssueModel } from '../common/model/issue.model';
import { PluginModel } from '../plugins/plugin.model';
import { IssueSeverityEnum } from '../common/model/issue-severity.enum';
import { VariableModel } from '../variables/variable.model';
import { UserData } from '../model/user/user.model';
import {
  allProjectsView,
  isSelectedProjectVersionedView,
  issuesView,
  patternsView,
  patternTypesView,
  projectKeyView,
  projectMetaView, userDataView,
  variablesListView,
} from '../model/views';
import { PluginHelper } from '../plugins/plugin.helper';
import { allPatternsToFirstId, getHighestSeverity } from './project-issues/project-issues.helper';
import { Maybe, requireNonNull } from '../common/utils/utils';
import { DisplayComponentHelperService } from '../common/services/display-component-helper.service';
import { ProjectHelper } from './project.helper';
import * as fromProject from '../model/project';
import { VersionControlActionTypes } from '../model/version-control';

@Injectable()
export class ProjectContext {
  private readonly metaChangeIndicationDurationMs = 4000;

  projectKey$: Observable<ProjectKey>;
  projects$: Observable<Dictionary<Project>>;
  issues$: Observable<IssueModel[]>;
  patternPlugins$: Observable<PluginModel[]>;
  highestSeverity$: Observable<IssueSeverityEnum>;
  versioned$: Observable<boolean>;
  variables$: Observable<VariableModel[]>;
  isProjectReadOnly$: Observable<boolean>;
  canCreateProject$: Observable<boolean>;
  pluginsByPatternType$: Observable<PluginModel[]>;
  public readonly projectMetaChangeIndicator$: Observable<ProjectMetaChangeIndicator>;

  /**
   * The id of the first pattern in the currently selected project, selected alphabetically.
   * Undefined if there are no patterns.
   */
  firstPatternId: Observable<Maybe<string>>;

  constructor(
      private store$: Store<AppState>,
      private actions$: Actions,
      displayComponentHelperService: DisplayComponentHelperService,
  ) {
    this.projectKey$ = store$.pipe(select(projectKeyView), filter<string>((projectKey: string | null) => !_.isNil(projectKey)));
    this.projects$ = store$.pipe(select(allProjectsView));
    this.patternPlugins$ = this.store$.pipe(select(patternTypesView))
      .pipe(map(patternTypes => _.map(patternTypes, patternType => PluginHelper.convertPatternTypeToPlugin(patternType))));
    this.issues$ = this.store$.pipe(select(issuesView));
    this.highestSeverity$ = this.issues$.pipe(
      map((issues) => {
        return getHighestSeverity(requireNonNull(issues));
      })
    );
    this.versioned$ = store$.pipe(select(isSelectedProjectVersionedView));
    this.variables$ = this.store$.pipe(select(variablesListView));
    this.canCreateProject$ = this.store$.pipe(select(canCreateProjectView));
    this.isProjectReadOnly$ = this.store$.pipe(select(isSelectedProjectReadonlyView));
    this.pluginsByPatternType$ = this.store$.pipe(select(patternTypesView)).pipe(
      map(patternTypes => ProjectHelper.convertToFilteredPluginModel(patternTypes, displayComponentHelperService)));

    this.firstPatternId = this.store$.select(patternsView).pipe(
      map(allPatternsToFirstId),
      distinctUntilChanged(_.isEqual),
    );

    /**
     * Whenever the timestamp signals that the project is updated, we wait for one subsequent complete project meta update,
     * and start the realtime change notification from that point in time.<br/>
     * Yes, this does not check _who_ did the change, (i.e. it could be the same user in another session),
     * or theoretically the update process could return that everything is the same,
     * even though that's unlikely as this is triggered by the timestamp check.
     */
    const showRealTimeProjectMetaUpdate$: Observable<boolean> = this.actions$.pipe(
        ofType(fromProject.ProjectActionTypes.ProjectMetaTimestampChangedInBackground),
        switchMap((): Observable<boolean> => {
          return this.actions$.pipe(
              ofType(VersionControlActionTypes.LoadProjectMetaSuccess), first(),
              switchMap((): Observable<boolean> => {
                return merge(
                    of(true),
                    of(false).pipe(delay(this.metaChangeIndicationDurationMs)),
                );
              }),
              startWith(false),
          );
        }),
        startWith(false),
    );

    this.projectMetaChangeIndicator$ = combineLatest([
      store$.select(projectMetaView),
      store$.select(userDataView),
      showRealTimeProjectMetaUpdate$,
    ]).pipe(
        map(([projectMeta, user, showRealTimeChange]: [Maybe<ProjectMeta>, Maybe<UserData>, boolean]): ProjectMetaChangeIndicator => {
          if (!projectMeta?.lastAuthor || ! user) {
            return {isModifiedByOtherUser: false};
          }
          if (projectMeta.lastAuthor === user.userKey) {
            return {isModifiedByOtherUser: false};
          }
          return {
            isModifiedByOtherUser: true,
            lastModifiedTime: new Date(projectMeta.lastModification!),
            lastModifiedByUser: projectMeta.lastAuthor,
            showRealTimeChange,
          };
        }),
        shareReplay(1),
    );
  }
}

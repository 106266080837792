import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppState } from '../../model/reducer';
import { Store } from '@ngrx/store';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { VersionControlData } from '../../common/model/version-control-data.model';
import { Inventory } from '../inventory.model';
import { ConnectInventoryToVersionControl } from '../../model/inventory';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';
import { Subject } from 'rxjs';

@Component({
  selector: 'adm4-connect-inventory-version-control-container',
  template: `
    <adm4-modal-dialog-title [header]='header'
                             [showClose]='true'
                             (closeClicked)="dialogRef.close()">
      <adm4-connect-inventory-version-control [inventory]='inventory'
                                            (inventoryConnect)='onInventoryConnect($event)'
                                            (cancel)='dialogRef.close()'
      ></adm4-connect-inventory-version-control>
    </adm4-modal-dialog-title>
  `
})
export class ConnectInventoryVersionControlContainerComponent implements OnInit, OnDestroy {
  header: string;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(public dialogRef: MatDialogRef<ConnectInventoryVersionControlContainerComponent>,
              @Inject(MAT_DIALOG_DATA) public inventory: Inventory,
              private store$: Store<AppState>) {
    const inventoryName = TenantHelper.cropTenantFromKey(inventory.inventoryKey);
    this.header = `Connect ${inventoryName} to Git`;
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onInventoryConnect(inventoryVersionControlData: VersionControlData): void {
    this.store$.dispatch(new ConnectInventoryToVersionControl({inventoryKey: this.inventory.inventoryKey, inventoryVersionControlData: inventoryVersionControlData}));
    this.dialogRef.close();
  }

}

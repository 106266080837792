import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GeneratedReportInfo, ReportGenerationModel } from '../reports.model';
import { Inventory } from '../../inventory/inventory.model';
import { ReportsContext } from '../reports.context';
import { AppState } from '../../model/reducer';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { GenerateReport } from '../../model/report/report.actions';
import { Observable, Subject } from 'rxjs';
import { TenantHelper } from '../../common/helpers/tenant.helper';

@Component({
  selector: 'adm4-report-generation',
  template: `
    <div class='full-height-flex'>
      <div class='generation-container'>
        <div class='select-inventory'>
          <label>Inventory</label>
          <mat-form-field class='inventory-select'>
            <mat-select (selectionChange)="onInventoryKeyChange($event)"
                        [disabled]='!hasInventories'
                        [value]="selectedInventoryKey"
                        [disableOptionCentering]='true'
                        #inventorySelection
                        (click)='focusDropdownInput()'>
              <adm4-searchable-dropdown-input *ngIf='inventorySelection.focused'
                                              [sourceItems]="inventories"
                                              [placeholder]="'Please select an inventory...'"
                                              [searchableFormatFn]='searchableInventoryFormatFn'
                                              [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                              (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
              <mat-option *ngFor='let inventory of filteredInventoryList'
                          [value]='inventory.inventoryKey'>{{inventory.inventoryKey | cropTenantFromKey}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class='generate'>
          <button class='admn4-button-ellipse-blue' [disabled]='!selectedInventoryKey' (click)='generateReport()'>Generate</button>
        </div>
        <adm4-report-generation-info [lastReportInfo]='lastReportInfo'></adm4-report-generation-info>
      </div>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <adm4-report-generation-output
                  [inventoryKey]='selectedInventoryKey'
          ></adm4-report-generation-output>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./report-generation.component.scss']
})
export class ReportGenerationComponent implements OnChanges {
  @Input() inventories: Inventory[];
  @Input() reportClassName: string;
  @Input() projectKey: string;
  @Input() lastReportInfo: GeneratedReportInfo | null;

  selectedInventoryKey: string;
  filteredInventoryList: Inventory[];

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();


  constructor(public reportsContext: ReportsContext, private store$: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lastReportInfo && this.lastReportInfo) {
      this.selectedInventoryKey = this.lastReportInfo.inventoryKey;
    } else if (changes.inventories && !_.isEmpty(this.inventories)) {
      this.selectedInventoryKey = this.inventories[0].inventoryKey;
      this.updateSearchResult(this.inventories);
    }
  }

  onInventoryKeyChange(event) {
    this.selectedInventoryKey = event.value;
  }

  generateReport() {
    const generationModel: ReportGenerationModel = {
      projectKey: this.projectKey,
      inventoryKey: this.selectedInventoryKey,
      reportClassName: this.reportClassName,
      mediaType: 'text/html'
    };
    this.store$.dispatch(new GenerateReport(generationModel));
  }

  get hasInventories(): boolean {
    return !_.isEmpty(this.inventories);
  }

  searchableInventoryFormatFn = (inventory: Inventory): string => {
    return TenantHelper.cropTenantFromKey(inventory.inventoryKey);
  };

  updateSearchResult(filteredList: Inventory[]): void {
    this.filteredInventoryList = filteredList;
  }

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }
}

import { Component, Input } from '@angular/core';
import { InventoryValidationIssue, ValidationStatus } from '../../model/validation-status.model';

@Component({
  selector: 'adm4-inventory-validation-tooltip-content',
  template: `
    <div *ngFor='let validationError of inventoryValidationStatus?._errors' class='issue-container'>
      <adm4-inventory-validation-issue [issue]='validationError' [isError]='true'></adm4-inventory-validation-issue>
    </div>
    <div *ngFor='let validationWarning of inventoryValidationStatus?._warnings' class='issue-container'>
      <adm4-inventory-validation-issue [issue]='validationWarning' [isWarning]='true'></adm4-inventory-validation-issue>
    </div>
    <div *ngFor='let validationInfo of inventoryValidationStatus?._infos' class='issue-container'>
      <adm4-inventory-validation-issue [issue]='validationInfo' [isInfo]='true'></adm4-inventory-validation-issue>
    </div>
  `,
  styleUrls: ['./inventory-validation-tooltip-content.component.scss']
})
export class InventoryValidationTooltipContentComponent {
  @Input() inventoryValidationStatus: ValidationStatus<InventoryValidationIssue> | null;
}

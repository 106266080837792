import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { Project } from '../project.model';
import { ImportProject } from '../../model/project';
import { ImportProjectContext } from './import-project.context';

@Component({
  selector: 'adm4-import-project',
  template: `
    <adm4-modal-dialog-title header='Import Project from Git' [showClose]='true' (closeClicked)="onCloseClicked()">
      <adm4-import-project-dialog [projects]='importProjectContext.projects$ | async'
                                  [predefinedProjectData]='predefinedProjectData'
                                  [selectedTenantKey]="importProjectContext.selectedTenantKey$ | async"
                                  [displayTenant]='importProjectContext.displayTenant$ | async'
                                  (cancelClicked)='onCancelClicked()'
                                  (importProject)='onImportProject($event)'
      >
      </adm4-import-project-dialog>
    </adm4-modal-dialog-title>
  `,
  providers: [
    ImportProjectContext
  ]
})
export class ImportProjectComponent {

  constructor(public dialogRef: MatDialogRef<ImportProjectComponent>,
              @Inject(MAT_DIALOG_DATA) public predefinedProjectData: Project | null,
              private store$: Store<AppState>,
              public importProjectContext: ImportProjectContext) {
  }

  onCancelClicked(): void {
    this.dialogRef.close();
  }

  onImportProject(project: Project): void {
    this.store$.dispatch(new ImportProject(project));
    this.dialogRef.close();
  }

  onCloseClicked() {
    this.dialogRef.close();
  }

}

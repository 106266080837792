import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyDataModel } from '../property-data.model';
import { PatternInstance, PatternProperty } from '../../patterns/pattern-instance.model';
import { VariableModel } from '../../variables/variable.model';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { DisplayComponentHelperService } from '../../common/services/display-component-helper.service';
import { HideableUIElement } from '../../plugins/pattern-type.model';

@Component({
  selector: 'adm4-property-list',
  template: `
    <ng-container *ngFor="let propModel of propertiesModel; trackBy: trackByPatternAndPropertyType">
      <adm4-property-list-element *ngIf='shouldBeDisplayedForMode(propModel.patternTypeProp) && shouldBeDisplayedForPatternData(propModel.patternProp.propertyKey, pattern?.displayOptions?.hidden)'
                                  [issues]='propModel.issues'
                                  [patternProperty]='propModel.patternProp'
                                  [patterTypeProp]='propModel.patternTypeProp'
                                  [propertyType]='propModel.propType'
                                  [projectKey]='projectKey'
                                  [patternId]='pattern.patternId'
                                  [variables]='variables'
                                  [form]='form'
                                  [readOnly]='readOnly || shouldBeReadOnlyBasedOnPatternData(propModel.patternProp.propertyKey, pattern?.displayOptions?.readOnly)'
                                  [isSelected]='isPropertySelected(propModel.patternProp.propertyKey)'
                                  [scrollArea]='scrollArea'
                                  (selectProperty)='onPropertySelect(propModel.patternProp.propertyKey)'
                                  (validate)="propagateValidation($event)"></adm4-property-list-element>
    </ng-container>
  `
})
export class PropertyListComponent {

  @Input() propertiesModel: PropertyDataModel[];
  @Input() projectKey: string;
  @Input() pattern: PatternInstance;
  @Input() variables: VariableModel[];
  @Input() selectedPropertyKey: string | undefined;
  @Input() form: UntypedFormGroup;
  @Input() readOnly: boolean;
  @Input() scrollArea: HTMLElement;
  @Input() options: {};
  @Output() selectProperty: EventEmitter<string> = new EventEmitter();
  @Output() validate: EventEmitter<PatternProperty> = new EventEmitter();

  constructor(private displayComponentHelperService: DisplayComponentHelperService) {
  }

  // to avoid reinitialization of property list elements on every change this trackBy function is used
  // though in case we change selected pattern, we should reinitialize it, that's why we combine it based on that too
  trackByPatternAndPropertyType: (number, PropertyDataModel) => string = (_index: number, propModel: PropertyDataModel) => `${this.pattern.patternId}-${propModel.patternProp.propertyKey}`;

  isPropertySelected(propertyKey): boolean {
    return !_.isNil(this.selectedPropertyKey) && propertyKey === this.selectedPropertyKey;
  }

  onPropertySelect(propertyKey: string): void {
    this.selectProperty.emit(propertyKey);
  }

  propagateValidation(patternProperty: PatternProperty): void {
    this.validate.emit(patternProperty);
  }

  shouldBeDisplayedForMode(property: HideableUIElement) {
    return this.displayComponentHelperService.showByPatternType(property);
  }

  shouldBeDisplayedForPatternData(propertyKey: string, restrictions: ['*', 'field.deploymentHosts', ('field.notes' | string)] | undefined) {
    return !this.displayComponentHelperService.shouldBeRestrictedByProperty(restrictions, propertyKey);
  }

  shouldBeReadOnlyBasedOnPatternData(propertyKey: string, restrictions: ['*', 'field.deploymentHosts', ('field.notes' | string)] | undefined) {
    return this.displayComponentHelperService.shouldBeRestrictedByProperty(restrictions, propertyKey);
  }
}

import { combineLatest, forkJoin, Observable, ObservableInput, of, Subject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { catchError, filter, finalize, map, mergeAll, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EmptyAction, LoadPluginDoc, NevisAdminAction } from '../actions';
import { PatternService } from '../../patterns/pattern.service';
import {
  CreatePattern,
  CreatePatternSuccess,
  DeletePattern,
  DeletePatternResource,
  DeletePatternSuccess,
  DuplicatePattern,
  DuplicatePatternSuccess,
  LoadAllPatternInstances,
  LoadAllPatternInstancesSuccess,
  LoadPatternAttachments,
  LoadPatternAttachmentsSuccess,
  LoadPatternCopyTargetSuccess,
  LoadPatternTypes,
  LoadPatternTypesSuccess,
  LoadPatternUsageSuccess,
  LoadPropertyTypes,
  LoadPropertyTypesSuccess,
  PatchPatternInstances,
  PatchPatternInstancesSuccess,
  PatternActionTypes,
  SavePatternResource,
  SelectPatternId,
  SelectPatternInstance,
  UpdatePatternInstance,
  UpdatePatternInstanceSuccess
} from './pattern.actions';
import { PluginService } from '../../plugins/plugin.service';
import { AppState, Dictionary, ProjectKey } from '../reducer';
import { PatternInstance } from '../../patterns/pattern-instance.model';
import { requireNonNull } from '../../common/utils/utils';
import { Usage } from '../../common/model/plugin-usage.model';
import { Pattern, PatternCopyTarget } from '../../patterns/pattern.model';
import * as _ from 'lodash';
import { CreatePatternPayload, DuplicatePatternPayload, PatchPatternsPayload, UpdatePatternPayload } from './create-pattern-payload.model';
import { PatternType, Property } from '../../plugins/pattern-type.model';
import { PropertyType } from '../../plugins/property-type.model';
import { LoadingService } from '../../modal-dialog/loading-modal/loading.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { NavigationService } from '../../navbar/navigation.service';
import { patternTypesView, projectKeyView, propertyTypesView, selectedPatternDocView, selectedPatternInstanceView } from '../views';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { LoadPatternAttachmentsSuccessPayload } from './load-pattern-attachments-succe-payload.model';
import { DeleteAttachmentObj, SaveAttachmentObj } from '../../patterns/pattern-attachment.model';
import { InvalidatePatternSummaryReports, LoadPatternSummaryReports } from '../report/report.actions';
import { LoadIssues } from '../project';
import { ClearProjectMeta, LoadProjectMetaSuccess } from '../version-control';

@Injectable()
export class PatternEffects {

   createPattern: Observable<CreatePatternSuccess | InvalidatePatternSummaryReports | EmptyAction> = createEffect(() => this.createCreatePatternEffect());
   duplicatePattern: Observable<DuplicatePatternSuccess | InvalidatePatternSummaryReports | LoadIssues | EmptyAction> = createEffect(() => this.createDuplicatePatternEffect());
   loadPatterns: Observable<LoadAllPatternInstancesSuccess | EmptyAction> = createEffect(() => this.createLoadPatternsEffect());
   loadPatternTypes: Observable<LoadPatternTypesSuccess | EmptyAction> = createEffect(() => this.createLoadPatternTypesEffect());
   loadPropertyTypes: Observable<LoadPropertyTypesSuccess | EmptyAction> = createEffect(() => this.createLoadPropertyTypesEffect());
   loadPatternInstance: Observable<SelectPatternInstance | LoadPatternUsageSuccess | SelectPatternId> = createEffect(() => this.createLoadPatternInstanceEffect());
   deletePattern: Observable<DeletePatternSuccess | LoadPatternSummaryReports | EmptyAction> = createEffect(() => this.createDeletePatternEffect());
  /**
   * Triggers load of pattern help html if it hasn't been loaded before whenever pattern is selected
   */
   loadPatternHelp: Observable<LoadPluginDoc> = createEffect(() => this.createLoadPatternHelpEffect());
   loadPatternUsage: Observable<LoadPatternUsageSuccess | EmptyAction> = createEffect(() => this.createLoadPatternUsageEffect());
   loadPatternCopyTarget: Observable<LoadPatternCopyTargetSuccess | EmptyAction> = createEffect(() => this.createLoadPatternCopyTargetEffect());
   loadPatternInstanceAfterUpdate: Observable<SelectPatternInstance | SelectPatternId> = createEffect(() => this.createLoadPatternInstanceAfterUpdateEffect());
   savePatternResource: Observable<LoadPatternAttachments | EmptyAction> = createEffect(() => this.createSavePatternResourceEffect());
   deletePatternResource: Observable<LoadPatternAttachments | EmptyAction> = createEffect(() => this.createDeletePatternResourceEffect());
   loadPatternAttachments: Observable<LoadPatternAttachmentsSuccess> = createEffect(() => this.createLoadPatternAttachmentsEffect());
   updatePatternInstance: Observable<SelectPatternInstance | LoadAllPatternInstances | UpdatePatternInstanceSuccess | InvalidatePatternSummaryReports | LoadProjectMetaSuccess | ClearProjectMeta | EmptyAction> = createEffect(() => this.createUpdatePatternInstanceEffect());
   patchPatternInstances: Observable<PatchPatternInstancesSuccess | EmptyAction> = createEffect(() => this.createPatchPatternInstancesEffect());

  constructor(private patternService: PatternService,
              private pluginService: PluginService,
              private store$: Store<AppState>,
              private actions$: Actions<NevisAdminAction<any>>,
              private loadingService: LoadingService,
              private modalNotificationService: ModalNotificationService,
              private toastNotificationService: ToastNotificationService,
              private navigationService: NavigationService) {}

  private createCreatePatternEffect(): Observable<CreatePatternSuccess | InvalidatePatternSummaryReports | EmptyAction> {
    return this.actions$
      .pipe(ofType(PatternActionTypes.CreatePattern))
      .pipe(
        map((action: CreatePattern) => action.payload),
        switchMap((payload: CreatePatternPayload) => {
          return this.patternService.createPatternInstance(payload.pattern, payload.projectKey).pipe(
            mergeMap((pattern: PatternInstance) => {
              if (_.isFunction(payload.onCreateSuccess)) {
                payload.onCreateSuccess(pattern);
              }
              this.toastNotificationService.showSuccessToast(`Pattern <b>${pattern.name}</b> has been added`, 'Successfully added');
              return [
                new CreatePatternSuccess(pattern),
                new InvalidatePatternSummaryReports(payload.projectKey)
              ];
            }),
            catchError((error) => {
              console.error(error);
              this.modalNotificationService.openErrorDialog({title: `Failed to create pattern ${payload.pattern.name}`, description: 'Something went wrong while creating a pattern'});
              return of(new EmptyAction());
            }));
        })
      );
  }

  private createDuplicatePatternEffect(): Observable<DuplicatePatternSuccess | InvalidatePatternSummaryReports | LoadIssues | EmptyAction> {
    return this.actions$
      .pipe(ofType(PatternActionTypes.DuplicatePattern))
      .pipe(
        map((action: DuplicatePattern) => action.payload),
        switchMap((payload: DuplicatePatternPayload) => {
          return this.patternService.duplicatePattern(payload.projectKey, payload.patternId)
            .pipe(
              mergeMap((duplicatedPattern: PatternInstance) => {
                if (_.isFunction(payload.onCreateSuccess)) {
                  payload.onCreateSuccess(duplicatedPattern);
                }
                this.toastNotificationService.showSuccessToast(`Pattern <b>${payload.patternName}</b> has been duplicated`, 'Successfully duplicated');
                return [
                  new DuplicatePatternSuccess(duplicatedPattern),
                  new InvalidatePatternSummaryReports(payload.projectKey),
                  new LoadIssues(payload.projectKey)
                ];
              }),
              catchError((error) => {
                console.error(error);
                this.modalNotificationService.openErrorDialog({title: `Failed to duplicate pattern ${payload.patternName}`, description: 'Something went wrong while duplicating a pattern'});
                return of(new EmptyAction());
              }));
        })
      );
  }

  private createLoadPatternsEffect(): Observable<LoadAllPatternInstancesSuccess | EmptyAction> {
    return this.actions$
      .pipe(ofType(PatternActionTypes.LoadAllPatternInstances))
      .pipe(
        map((action: LoadAllPatternInstances) => action.payload),
        switchMap((projectKey: ProjectKey) => {
          return this.patternService.getAllPatterns(projectKey).pipe(
            map((patterns: Pattern[]) => new LoadAllPatternInstancesSuccess(patterns)),
            catchError((error) => {
              console.error(error);
              const projectName: string = TenantHelper.cropTenantFromKey(projectKey);
              this.modalNotificationService.openErrorDialog({title: 'Error while loading patterns', description: `Something went wrong while loading patterns for ${projectName}`});
              return of(new EmptyAction());
            }));
        })
      );
  }

  private createLoadPatternTypesEffect(): Observable<LoadPatternTypesSuccess | EmptyAction> {
    return this.actions$
      .pipe(ofType(PatternActionTypes.LoadPatternTypes))
      .pipe(
        map((action: LoadPatternTypes) => action.payload),
        switchMap((projectKey: string) => this.patternService.getPatternTypes(projectKey)
          .pipe(
            map((patternTypes: PatternType[]) => new LoadPatternTypesSuccess(patternTypes)),
            catchError((error) => {
              this.modalNotificationService.openErrorDialog({title: 'Error while loading pattern types', description: error});
              return of(new EmptyAction());
            })
          )
        )
      );
  }

  private createLoadPropertyTypesEffect(): Observable<LoadPropertyTypesSuccess | EmptyAction> {
    return this.actions$
      .pipe(ofType(PatternActionTypes.LoadPropertyTypes))
      .pipe(
        map((action: LoadPropertyTypes) => action.payload),
        switchMap((projectKey: string) => this.pluginService.getPropertyTypes(projectKey)
          .pipe(
            map((propertyTpes: PropertyType[]) => new LoadPropertyTypesSuccess(propertyTpes)),
            catchError((error) => {
              this.modalNotificationService.openErrorDialog({title: 'Error while loading property types', description: error});
              return of(new EmptyAction());
            })
          )
        )
      );
  }

  private createLoadPatternInstanceEffect(): Observable<SelectPatternInstance | LoadPatternUsageSuccess | SelectPatternId> {
    return this.actions$
      .pipe(ofType(PatternActionTypes.SelectPatternId))
      .pipe(
        map((action: SelectPatternId) => action.payload),
        withLatestFrom(this.store$.pipe(select(projectKeyView))),
        filter(([, projectKey]) => projectKey !== null),
        switchMap<[string | null, string], ObservableInput<SelectPatternInstance | LoadPatternUsageSuccess | SelectPatternId>>(([patternId, projectKey]: [string | null, string]) => {
          if (patternId === null) {
            return of(
              new SelectPatternInstance(null),
              new LoadPatternUsageSuccess([])
            );
          }
          return this.patternService.getPatternInstance(projectKey || '', patternId).pipe(
            map((pattern: PatternInstance) => new SelectPatternInstance(pattern)),
            catchError(err => {
              console.error('Something went wrong when fetching pattern instance:', err);
              const projectName: string = _.isNil(projectKey) ? '' : TenantHelper.cropTenantFromKey(projectKey);
              this.modalNotificationService.openErrorDialog({title: 'Could not load pattern instance', description: `Most likely pattern ${patternId} was deleted or doesn't exist on project ${projectName}`});
              this.navigationService.navigateToProject(projectKey);
              return of(
                new SelectPatternId(null)
              );
            }));
        })
      );
  }

  private createDeletePatternEffect(): Observable<DeletePatternSuccess | LoadPatternSummaryReports | EmptyAction> {
    return this.actions$.pipe(ofType(PatternActionTypes.DeletePattern))
      .pipe(
        map((action: DeletePattern) => action.payload),
        withLatestFrom(this.store$.pipe(select(projectKeyView))),
        switchMap(([pattern, projectKey]: [PatternInstance[], string]) => {
          if (!projectKey) {
            console.error('project key is undefined during removal of pattern');
            this.modalNotificationService.openErrorDialog({title: 'Error while removing pattern', description: ''});
            return of(new EmptyAction());
          }
          const patternIdList = pattern.map(value => value.patternId);
          return this.patternService.deletePatternList(projectKey, {items: patternIdList}).pipe(
            mergeMap(() => {
              this.toastNotificationService.showSuccessToast(`The selected pattern(s) successfully removed from the project.`, `Pattern(s) successfully removed`);
              this.navigationService.navigateToProjectSummary(projectKey);
              return [
                new DeletePatternSuccess(patternIdList)
              ];
            }),
            catchError(err => {
              console.error(err);
              this.modalNotificationService.openErrorDialog({title: 'Error while removing pattern', description: err});
              return of(new EmptyAction());
            }));
        })
      );
  }

  private createLoadPatternHelpEffect(): Observable<LoadPluginDoc> {
    return this.actions$.pipe(
      ofType(PatternActionTypes.SelectPatternInstance),
      map((action: SelectPatternInstance) => action.payload),
      withLatestFrom(this.store$.pipe(select(selectedPatternDocView))),
      filter(([patternInstance, helpData]: [PatternInstance | null, string | null]) => !_.isNil(patternInstance) && _.isNil(helpData)),
      map(([patternInstance]: [PatternInstance, null]) => new LoadPluginDoc(patternInstance))
    );
  }

  private createLoadPatternUsageEffect(): Observable<LoadPatternUsageSuccess | EmptyAction> {
    return this.actions$.pipe(
        ofType(PatternActionTypes.SelectPatternId),
        map((action: SelectPatternId) => action.payload),
        withLatestFrom(this.store$.pipe(select(projectKeyView))),
        filter(([patternId, projectKey]: [string | null, string | null]) => patternId !== null && projectKey !== null),
        switchMap(([patternId, projectKey]: [string, string]) => {
          return this.patternService.getPatternUsage(requireNonNull(projectKey), patternId).pipe(
            map((usage: Usage[]) => new LoadPatternUsageSuccess(usage)),
            catchError(err => {
              console.error(`Could not load usages of pattern with id ${patternId} in project ${projectKey}:`, err);
              this.modalNotificationService.openErrorDialog({title: 'Could not load pattern usages', description: `Something went wrong while loading usages of pattern ${patternId}`});
              return of(new EmptyAction());
            }));
        })
      );
  }

  private createLoadPatternCopyTargetEffect(): Observable<LoadPatternCopyTargetSuccess | EmptyAction> {
    return this.actions$.pipe(ofType(PatternActionTypes.SelectPatternId)).pipe(
      ofType(PatternActionTypes.SelectPatternId),
      map((action: SelectPatternId) => action.payload),
      withLatestFrom(this.store$.pipe(select(projectKeyView))),
      filter(([patternId, projectKey]: [string | null, string | null]) => patternId !== null && projectKey !== null),
      switchMap(([patternId, projectKey]: [string, string]) => this.patternService.getPatternCopyTargets(projectKey, patternId)),
      map((copyTargets: PatternCopyTarget[]) => new LoadPatternCopyTargetSuccess(copyTargets)),
    );
  }

  private createLoadPatternInstanceAfterUpdateEffect(): Observable<SelectPatternInstance | SelectPatternId> {
    return this.actions$
      .pipe(ofType(PatternActionTypes.LoadAllPatternInstancesSuccess))
      .pipe(
        map((action: LoadAllPatternInstancesSuccess) => action.payload),
        withLatestFrom(
          this.store$.pipe(select((state: AppState) => state.pattern.selectedPatternInstance)),
          this.store$.pipe(select(projectKeyView))
        ),
        filter(([patterns, selectedPatternInstance, projectKey]: [Pattern[], PatternInstance, string]) => {
          const isInLoadedPatterns = !!selectedPatternInstance ?
            _.includes(patterns.map((pattern) => pattern.patternId), selectedPatternInstance.patternId) : false;
          return !!projectKey && isInLoadedPatterns;
        }),
        switchMap(([, selectedPatternInstance, projectKey]: [Pattern[], PatternInstance, string]) => {
          return this.patternService.getPatternInstance(projectKey, selectedPatternInstance.patternId).pipe(
            map((pattern: PatternInstance) => new SelectPatternInstance(pattern)),
            catchError(err => {
              console.error('Something went wrong when fetching pattern instance:', err);
              const projectName: string = TenantHelper.cropTenantFromKey(projectKey);
              this.modalNotificationService.openErrorDialog({title: 'Could not load pattern instance', description: `Most likely pattern ${selectedPatternInstance.patternId} was deleted or doesn't exist on project ${projectName}`});
              return of(
                new SelectPatternId(null)
              );
            }));
        })
      );
  }

  private createSavePatternResourceEffect(): Observable<LoadPatternAttachments | EmptyAction> {
    return this.actions$.pipe(ofType(PatternActionTypes.SavePatternResource))
      .pipe(map((action: SavePatternResource) => action.payload),
        withLatestFrom(
          this.store$.pipe(select((state: AppState) => state.pattern.selectedPatternInstance)),
          this.store$.pipe(select(projectKeyView))
        ),
        filter((tuple: [SaveAttachmentObj[], PatternInstance | null, string | null]) => tuple.every(value => !_.isEmpty(value))),
        switchMap(
          ([attachmentList, selectedPatternInstance, projectKey]: [SaveAttachmentObj[], PatternInstance, string]) => {
            const combineProgressText = (loadedAttachments: number) => `${loadedAttachments}/${attachmentList.length} files are loaded to the server`;
            const progressText = new Subject<string>();
            let loadedAttachmentsAmount = 0;
            const messages: string[] = [];
            this.loadingService.showLoading({title: 'Saving pattern changes', description: 'Uploading attachments', progressText: progressText});
            return forkJoin(
              attachmentList.map((attachment: SaveAttachmentObj) => {
                  return this.patternService.uploadPatternResource(projectKey, selectedPatternInstance.patternId, attachment.propertyKey, attachment.fileToSave).pipe(
                    tap(() => progressText.next(combineProgressText(loadedAttachmentsAmount++))),
                    catchError((err: HttpResponse<any>) => {
                      console.error(err);
                      messages.push(`"${attachment.fileToSave.name}"`);
                      return of(null);
                    })
                  );
                }
              )
            ).pipe(
              map(() => new LoadPatternAttachments(selectedPatternInstance)),
              catchError((err) => {
                console.error(err);
                this.modalNotificationService.openErrorDialog({title: 'Could not upload attachments', description: `Something went wrong while uploading attachments for ${selectedPatternInstance.name}`});
                return of(new EmptyAction());
              }),
              finalize(() => {
                this.loadingService.hideLoading();
                if (!_.isEmpty(messages)) {
                  this.modalNotificationService.openErrorDialog({title: 'Following file(s) was not uploaded because it exceeds maximum size limit.', description: messages.join('<br/>')});
                } else {
                  this.toastNotificationService.showSuccessToast('Files were successfully saved', 'Files saved');
                }
              })
            );
          })
      );
  }

  private createDeletePatternResourceEffect(): Observable<LoadPatternAttachments | EmptyAction> {
    return this.actions$.pipe(ofType(PatternActionTypes.DeletePatternResource))
      .pipe(map((action: DeletePatternResource) => action.payload),
        withLatestFrom(
          this.store$.pipe(select((state: AppState) => state.pattern.selectedPatternInstance)),
          this.store$.pipe(select(projectKeyView))
        ),
        filter(([, selectedPatternInstance, projectKey]: [DeleteAttachmentObj[], PatternInstance, string]) => {
          return selectedPatternInstance !== null && projectKey !== null;
        }),
        switchMap(([attachmentsToDelete, selectedPatternInstance, projectKey]: [DeleteAttachmentObj[], PatternInstance, string]) => {
          return forkJoin(
            attachmentsToDelete.map((attachment: DeleteAttachmentObj) => {
              return this.patternService.deletePatternResource(projectKey, selectedPatternInstance.patternId, attachment.propertyKey, attachment.fileName);
            })
          ).pipe(
            map(() => {
              this.toastNotificationService.showSuccessToast(' ', 'File(s) are deleted');
              return new LoadPatternAttachments(selectedPatternInstance);
            }),
            catchError(err => {
              console.error(err);
              this.modalNotificationService.openErrorDialog({title: 'Could not delete files', description: `Something went wrong while deleting attachments of ${selectedPatternInstance.name}`});
              return of(new EmptyAction());
            })
          );
        })
      );
  }

  private createLoadPatternAttachmentsEffect(): Observable<LoadPatternAttachmentsSuccess> {
    return this.fetchPatternResourceList(
      this.actions$.pipe(
        ofType(PatternActionTypes.LoadPatternAttachments, PatternActionTypes.SelectPatternInstance),
        map((action: LoadPatternAttachments | SelectPatternInstance) => action.payload)
      ),
      (payload: LoadPatternAttachmentsSuccessPayload) => new LoadPatternAttachmentsSuccess(payload),
      true
    );
  }

  private createUpdatePatternInstanceEffect(): Observable<SelectPatternInstance | LoadAllPatternInstances | UpdatePatternInstanceSuccess | InvalidatePatternSummaryReports | LoadProjectMetaSuccess| ClearProjectMeta | EmptyAction> {
    return this.actions$
      .pipe(ofType(PatternActionTypes.UpdatePatternInstance))
      .pipe(
        map((action: UpdatePatternInstance) => action.payload),
        switchMap((payload: UpdatePatternPayload) => {
          return this.patternService.updatePatternInstance(payload.pattern, payload.projectKey, payload.patternId).pipe(
            withLatestFrom(this.store$.pipe(select(selectedPatternInstanceView))),
            mergeMap(([updatedPattern, selectedPattern]: [PatternInstance, PatternInstance]) => {
              if (_.isFunction(payload.onUpdateSuccess)) {
                payload.onUpdateSuccess(updatedPattern);
              }
              this.toastNotificationService.showSuccessToast(`Pattern <b> ${updatedPattern.name} </b> was successfully saved`, 'Changes saved');
              // If the currently selected pattern instance is not the updated pattern anymore, don't execute the selectPatternInstance action
              const selectUpdatedPattern = !_.isNil(selectedPattern) && payload.patternId === selectedPattern.patternId;
              const actionsAfterUpdate: (SelectPatternInstance | LoadAllPatternInstances | UpdatePatternInstanceSuccess | InvalidatePatternSummaryReports | ClearProjectMeta)[] = [];

              if (selectUpdatedPattern) {
                actionsAfterUpdate.push(new SelectPatternInstance(updatedPattern));
              }
              actionsAfterUpdate.push(new ClearProjectMeta());
              if (payload.patternNameChanged) {
                actionsAfterUpdate.push(new LoadAllPatternInstances(payload.projectKey));
              }
              actionsAfterUpdate.push(new UpdatePatternInstanceSuccess(updatedPattern));
              return actionsAfterUpdate;
            }),
            catchError((error) => {
              console.error('Something went wrong with the property list save:', error);
              this.modalNotificationService.openErrorDialog({title: 'Changes could not be saved', description: 'Something went wrong during the save'});
              return of(new EmptyAction());
            }));
        })
      );
  }

  private createPatchPatternInstancesEffect(): Observable<PatchPatternInstancesSuccess | EmptyAction> {
    return this.actions$
      .pipe(ofType(PatternActionTypes.PatchPatternInstances))
      .pipe(
        map((action: PatchPatternInstances) => action.payload),
        switchMap((payload: PatchPatternsPayload) => {
          return this.patternService.patchPatternInstances(payload.patchList, payload.projectKey).pipe(
            map(() => {
              if (_.isFunction(payload.onPatchSuccess)) {
                payload.onPatchSuccess(payload.patchList);
              }
              return new PatchPatternInstancesSuccess(payload.patchList);
            }),
            catchError((error) => {
              console.error('Something went wrong with the labeling patterns:', error);
              this.modalNotificationService.openErrorDialog({title: 'Pattern labelling failed', description: 'Something went wrong during pattern labelling'});
              return of(new EmptyAction());
            }));
        })
      );
  }

  private fetchPatternResourceList<ReturnedAction extends NevisAdminAction<any>>(observable: Observable<any | undefined>, successAction: (payload: LoadPatternAttachmentsSuccessPayload) => ReturnedAction, loadMeta: boolean) {
    return combineLatest([
      observable.pipe(filter((selectedPatternInstance) => !_.isNil(selectedPatternInstance))),
      this.store$.pipe(select(projectKeyView), filter((projectKey: string | null) => !_.isNil(projectKey))),
      this.store$.pipe(select(patternTypesView), filter((patternTypes: Dictionary<PatternType> | null) => !_.isNil(patternTypes))),
      this.store$.pipe(select(propertyTypesView), filter((propertyTypes: Dictionary<PropertyType> | null) => !_.isNil(propertyTypes)))
    ]).pipe(
      filter((tuple: [Pattern, ProjectKey, Dictionary<PatternType>, Dictionary<PropertyType>]) => {
        return tuple.every(value => !_.isEmpty(value));
      }),
      filter(([selectedPatternInstance, , patternTypes]: [Pattern, ProjectKey, Dictionary<PatternType>, Dictionary<PropertyType>]) => {
        return !_.isNil(selectedPatternInstance) && !_.isNil(patternTypes) && !_.isNil(patternTypes[selectedPatternInstance.className]);
      }),
      switchMap(([selectedPatternInstance, selectedProjectKey, patternTypes, propertyTypes]: [Pattern, ProjectKey, Dictionary<PatternType>, Dictionary<PropertyType>]) => {
        return _.filter(patternTypes[selectedPatternInstance.className].properties, property => {
          return propertyTypes[property.className]?.uiComponent === 'AttachmentPropertyComponent';
        }).map((property: Property) => property.propertyKey).map((propertyKey: string) => {
          return this.patternService.getPatternResourceList(selectedProjectKey, selectedPatternInstance.patternId, propertyKey, loadMeta).pipe(map((response: any) => {
            return successAction({propertyKey, resourceList: response});
          }));
        });
      }),
      mergeAll()
    );
  }
}

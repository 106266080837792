import { Component, Input } from '@angular/core';
import { MenuElement } from '../../menu-element';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-menu-element',
  template: `
    <ng-container *ngIf='hasTitle; else divider'>
      <ng-container *ngIf='(!menuElement.href || !(menuElement.href | async)); else useLink'>
        <div *ngIf='shouldBeDisplayed' mat-menu-item class='menu-element-indicated-default menu-item-overflow-x-hidden list-item-shrink'
             [class.selected]='isSelected'
             [disabled]='isDisabled'
             (keyup.enter)='triggerEvent()'
             (click)='triggerEvent()'>
          <adm4-menu-item-wrapper [menuElement]='menuElement'></adm4-menu-item-wrapper>
        </div>
      </ng-container>
      <ng-template #useLink>
        <a *ngIf='shouldBeDisplayed' mat-menu-item
           [href]='menuElement.href | async' class='menu-element-indicated-default menu-item-overflow-x-hidden' [class.selected]='isSelected' [disabled]='isDisabled'>
          <adm4-menu-item-wrapper [menuElement]='menuElement'></adm4-menu-item-wrapper>
        </a>
      </ng-template>
    </ng-container>
    <ng-template #divider>
      <mat-divider *ngIf='!menuElement.title && shouldBeDisplayed'></mat-divider>
    </ng-template>
  `,
  styleUrls: ['../../styles/component-specific/mat-menu.scss']
})
export class MenuElementComponent {
  @Input() menuElement: MenuElement;

  get hasTitle(): boolean {
    return !_.isEmpty(this.menuElement.title);
  }

  get shouldBeDisplayed(): boolean {
    return !_.isFunction(this.menuElement.displayCondition) || this.menuElement.displayCondition();
  }

  get isSelected(): boolean {
    return _.isFunction(this.menuElement.selectedCondition) && this.menuElement.selectedCondition();
  }

  get isDisabled(): boolean {
    return _.isFunction(this.menuElement.disabledCondition) && this.menuElement.disabledCondition();
  }

  triggerEvent() {
    if (!_.isFunction(this.menuElement.event)) {
      return;
    }

    const disabled = _.isFunction(this.menuElement.disabledCondition) && this.menuElement.disabledCondition() ;

    if (disabled) {
      return;
    }

    this.menuElement.event();
  }
}

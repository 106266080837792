import { LocalStorageHelper } from './local-storage.helper';
import { entryWithKeyExists } from '../utils/utils';
import { Dictionary } from '../../model/reducer';
import * as _ from 'lodash';
import { ActivatedRouteSnapshot } from '@angular/router';

export class RouteParamHelper {
  /**
   * @param {string | null} storedKey - inventoryKey which was saved and considered as previous selection
   * @param {Dictionary<T>} entries - list of entries on which to check if entry with such key exists
   * @param {string} localStorageKey - inventoryKey of stored inventoryKey in local storage to value of which we fallback if storedKey is unspecified
   * @returns {string | undefined} - whether key from ngrx store or local storage, or, in case such project doesn't exist or key is not stored, undefined
   */
  static takeKeyFromStore<T>(storedKey: string | null, entries: Dictionary<T>, localStorageKey: string): string | undefined {
    const keyFromLocalStorage = LocalStorageHelper.retrieve(localStorageKey);
    if (!_.isNil(storedKey) && entryWithKeyExists(storedKey, entries)) {
      return storedKey;
    } else if (!_.isNil(keyFromLocalStorage) && entryWithKeyExists(keyFromLocalStorage, entries)) {
      return keyFromLocalStorage;
    }
    return undefined;
  }

  static getPathParamFromRoute(next: ActivatedRouteSnapshot, paramKey: string): string | undefined {
    if (_.isNil(next.params[paramKey])) {
      const parentRouteMatchingHavingParam = next.pathFromRoot.find((parent: ActivatedRouteSnapshot) => !_.isNil(parent.params[paramKey]));
      return _.isNil(parentRouteMatchingHavingParam) ? undefined : parentRouteMatchingHavingParam.params[paramKey];
    }
    return next.params[paramKey];
  }

  static getPathParamFromChildRoute(next: ActivatedRouteSnapshot, paramKey: string): string | undefined {
    if (_.isNil(next.params[paramKey])) {
      const childRouteMatchingHavingParam = next.children.find((childRoute: ActivatedRouteSnapshot) => !_.isNil(childRoute.params[paramKey]));
      return _.isNil(childRouteMatchingHavingParam) ? undefined : childRouteMatchingHavingParam.params[paramKey];
    }
    return next.params[paramKey];
  }
}

import { Injectable } from '@angular/core';
import { TextDiffWrapperContext } from '../text-diff-wrapper/text-diff-wrapper.model';
import { Observable } from 'rxjs';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { TextDiffData } from '../../../common/model/publish-changes/text-diff-data.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import { PublishProjectContext } from '../publish-project.context';
import { projectDiffView } from '../../../model/views';

@Injectable()
export class ProjectDiffViewContextService extends TextDiffWrapperContext {

  readonly textDiff$: Observable<Diff<TextDiffData> | null>;
  readonly currentBranch$: Observable<string | null>;

  constructor( store$: Store<AppState>, private publishProjectContext: PublishProjectContext) {
    super();
    this.textDiff$ = store$.select(projectDiffView);
    this.currentBranch$ = this.publishProjectContext.currentBranch$;
  }
}

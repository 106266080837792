export class FileDownloader {
  static downloadFile(blob: Blob, fileName?: string): boolean | undefined {
    const sUrl: string = window.URL.createObjectURL(blob);
    return this.downloadFileFromUrl(sUrl, fileName);
  }

  static downloadFileFromUrl(sUrl: string, fileName?: string): boolean | undefined {
    const link = document.createElement('a');
    link.href = sUrl;

    if (link.download !== undefined && fileName) {
      // Set HTML5 download attribute. This will prevent file from opening if supported.
      link.download = fileName;
    }
    link.click();
    return  true;
  }
}

import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

/**
 * It's used for sending data between the PropertyList and PatternHelpComponent component
 * It sets to which html class it should scroll the help component
 */
@Injectable()
export class HelpConnectService {
  private htmlClassSource = new Subject<string | null>();

  htmlClass$: Observable<string | null> = this.htmlClassSource.asObservable();

  setHtmlClass(htmlClass: string): void {
    this.htmlClassSource.next(htmlClass);
  }

  resetHelpSelection(): void {
    this.htmlClassSource.next(null);
  }

  isHelpDisplayedForProperty(propertyHelpClass: string): Observable<boolean> {
    return this.htmlClass$.pipe(map((currentHelpClass: string | null) => currentHelpClass === propertyHelpClass));
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PermissionSavePayload } from '../model/permissions/permissions.model';
import { SelectPermissionsPayload } from './model/select-permissions-payload.model';
import { Subject } from 'rxjs';
import { closeModalOnEscape } from '../modal-dialog/modal-dialog.helper';

@Component({
  selector: 'adm4-select-permissions-container',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title'
                             header='Select permissions'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog()">
      <adm4-select-permissions-dialog
              [target]="data.target"
              [operationColumns]='data.operationColumns'
              [permissionAssignees]='data.permissionAssignees'
              (permissionsSaved)="onSave($event)"
              (cancel)='closeDialog()'></adm4-select-permissions-dialog>
    </adm4-modal-dialog-title>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectPermissionsContainerComponent implements OnDestroy {

  private destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public data: SelectPermissionsPayload,
              private dialogRef: MatDialogRef<SelectPermissionsContainerComponent>) {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  onSave(permissionModifications: PermissionSavePayload[]) {
    this.data.onSaveCallback(permissionModifications);
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

import { Component, Input } from '@angular/core';
import { VariableModel } from '../../variables/variable.model';
import { PatternIssueData } from '../model/pattern-issue-data.model';
import * as _ from 'lodash';
import { SpecificPatternFieldsEnum } from '../../common/model/specific-pattern-fields.enum';
import { getIssueCssClassBySeverity } from '../../projects/project-issues/project-issues.helper';
import { IssueModel } from '../../common/model/issue.model';
import { IssueSeverityEnum } from '../../common/model/issue-severity.enum';
import { NavigationService } from '../../navbar/navigation.service';

@Component({
  selector: 'adm4-pattern-issue-group',
  templateUrl: './pattern-issue-group.component.html',
  styleUrls: ['../issue-group-list/issue-group-list.component.scss', '../issue-group-heading.scss']
})
export class PatternIssueGroupComponent {
  readonly deploymentHostsFormControlName = SpecificPatternFieldsEnum.DeploymentHosts;
  @Input() patternIssueGroup: PatternIssueData;
  @Input() isSelected: boolean;
  @Input() projectKey: string;
  @Input() variables: VariableModel[];

  constructor(private navigationService: NavigationService) {}

  getIssueIndicationCssClass(issue: IssueModel): string {
    return getIssueCssClassBySeverity(IssueSeverityEnum[issue.severity]);
  }

  get shouldDisplayGeneralIssues(): boolean {
    return !_.isEmpty(this.patternIssueGroup.generalIssues);
  }

  get shouldDisplayDeploymentHosts(): boolean {
    return !_.isEmpty(this.patternIssueGroup.deploymentHostIssues);
  }

  navigateToPattern(patternId: string): void {
        this.navigationService.navigateToPattern(this.projectKey, patternId);
  }

}

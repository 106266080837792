import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { select, Store } from '@ngrx/store';

import { catchError, map, shareReplay, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject, scheduled, asyncScheduler } from 'rxjs';

import * as _ from 'lodash';

import {
  customizableKubernetesCertificatesColumns, fixKubernetesCertificatesColumns, initialKubernetesCertificatesColumns, SecretManagementTableModel
} from '../secret-management/secret-management-table/secret-management-table.model';
import { Inventory, InventorySchemaType, KubernetesCertificateWrapper, } from '../../inventory/inventory.model';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { AppState } from '../../model/reducer';
import { allInventoriesView, selectedTenantKeyView } from '../../model/views';
import { NavigationService } from '../../navbar/navigation.service';
import { InventoryService } from '../../inventory/inventory.service';
import { localKubernetesCertificateColumns } from '../../common/constants/local-storage-keys.constants';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import { customizableColumnsMenuInfo } from '../certificates/customizable-columns-menu-info.payload';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { ErrorHelper } from '../../common/helpers/error.helper';

@Component({
  selector: 'adm4-kubernetes-certificate-management',
  template: `
      <div class='full-height-flex'>
        <div class='secret-management-header'>
          <adm4-column-header [styleClass]='"light-inline-header"'>
            <span>Managed Kubernetes Certificates</span>
          </adm4-column-header>
        </div>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <div class='full-height-flex details-container'>
              <div class='filters-wrapper'>
                <label for='scope' class='input-label scope-label'>Kubernetes Inventory: </label>
                <mat-form-field class='scope-selection'>
                  <mat-select id='scope' #scopeSelection
                              [(ngModel)]='selectedScope'
                              [placeholder]='"Please select a Kubernetes Inventory"'
                              [formControl]='scopeSelectionControl'
                              [disableRipple]='true'
                              [disableOptionCentering]='true'
                              (click)='focusDropdownInput()'>
                    <adm4-searchable-dropdown-input *ngIf='scopeSelection.focused'
                                                    [sourceItems]='scopes$ | async'
                                                    [placeholder]="'Please select a Kubernetes Inventory...'"
                                                    [searchableFormatFn]='searchableProjectFormatFn'
                                                    [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                                    (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
                    <mat-option *ngFor='let scope of scopes$ | async' [value]='scope' [hidden]='!isScopeFilteredOut(scope)'>
                      {{resolveScopeItemText(scope)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class='search-filter'>
                  <adm4-filter (filter)="filterBySearch($event)"
                               [placeholderText]='"Type to filter..."'
                               [filterText]="filterText"
                               class='search-input'>
                  </adm4-filter>
                </div>
              </div>
              <div class='remaining-space-flex-content-wrapper'>
                <div class='remaining-space-flex-content'>
                  <div class='customize-menu-container'>
                    <adm4-draggable-checkbox-menu class='draggable-menu'
                                                  [initialSelection]="defaultCertificatesColumnSelection"
                                                  [allItemList]="customizableKubernetesCertificatesColumns"
                                                  [menuInfo]='customizableColumnsMenuInfo'
                                                  [localStorageKey]='localKubernetesCertificateColumns'
                                                  (selectionChange)='updateDisplayableColumns($event)'>
                    </adm4-draggable-checkbox-menu>
                  </div>
                  <ng-container *ngIf='(kubernetesCertificates$ | async)?.length > 0; else noCertificateFound'>
                    <adm4-kubernetes-certificate-management-table
                            [resourceGroup]='kubernetesCertificates$ | async'
                            [displayedColumns]='displayedColumnsForCertificates'
                            [filterText]='filterText'
                            [selectedTenantKey]='selectedTenantKey$ | async'
                            [selectedScope]='selectedScope'
                            (triggerResourceLoad)='triggerResourceLoad()'>
                    </adm4-kubernetes-certificate-management-table>
                  </ng-container>
                  <ng-template #noCertificateFound>
                    <adm4-empty-resource><span>{{emptyTableText}}</span></adm4-empty-resource>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  `,
  styleUrls: ['../secret-management/secret-management.component.scss', '../../common/styles/component-specific/settings-details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KubernetesCertificateManagementComponent implements OnDestroy {
  selectedTenantKey$: Observable<string>;
  inventories$: Observable<Inventory[]>;
  kubernetesCertificates$: Observable<(KubernetesCertificateWrapper)[]>;
  displayedColumnsForCertificates: SecretManagementTableModel[];
  scopes$: Observable<string[]>;
  selectedScope: string;
  scopeSelectionControl = new UntypedFormControl();
  filteredScopes: string[];
  selectedTenantKey: string;
  emptyTableText = 'There is no Managed Kubernetes certificate found.';

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  private _resourceLoadTrigger$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  resourceLoadTrigger$: Observable<boolean> = this._resourceLoadTrigger$.asObservable();
  readonly customizableKubernetesCertificatesColumns = customizableKubernetesCertificatesColumns;
  readonly fixKubernetesCertificatesColumns = fixKubernetesCertificatesColumns;
  readonly customizableColumnsMenuInfo = customizableColumnsMenuInfo;
  readonly localKubernetesCertificateColumns = localKubernetesCertificateColumns;
  public filterText = '';
  private destroyed$: Subject<boolean> = new Subject();

  constructor(private navigationService: NavigationService,
              private inventoryService: InventoryService,
              private store$: Store<AppState>,
              private modalNotificationService: ModalNotificationService) {
    this.displayedColumnsForCertificates = this.getInitialCertificateColumns();
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;
    this.inventories$ = this.store$.pipe(select(allInventoriesView)).pipe(
      map(_.values),
      map(inventories => inventories.filter((inventory: Inventory) => inventory.schemaType === InventorySchemaType.KUBERNETES)),
    );
    this.scopes$ = this.inventories$.pipe(
      withLatestFrom(this.selectedTenantKey$),
      tap((inventories: any) => {
        // Set first scope by default only for the first time for initial scope selection
        if (_.isNil(this.selectedScope) && !_.isEmpty(inventories) && !_.isEmpty(inventories[0])) {
          this.selectedScope = inventories[0][0].inventoryKey;
        }
      }),
      map(([inventoryList, tenantKey]) => {
        this.selectedTenantKey = tenantKey;
        return _.reduce(inventoryList, (output: string[], inventory: Inventory) => {
          return output.concat(inventory.inventoryKey);
        }, []);
      }));
    this.kubernetesCertificates$ = this.getCertificates();
  }

  // Set the Action column always as the last element
  get defaultCertificatesColumnSelection(): SecretManagementTableModel[] {
    return _.intersection(initialKubernetesCertificatesColumns, this.customizableKubernetesCertificatesColumns);
  }

  getCertificates(): Observable<KubernetesCertificateWrapper[]> {
    return combineLatest([
      this.scopeSelectionControl.valueChanges,
      this.selectedTenantKey$,
      this.resourceLoadTrigger$]).pipe(
        takeUntil(this.destroyed$),
        switchMap(([selectedInventory]: [string, string, boolean]) => {
          return this.inventoryService.getInventoryKubernetesCertificates(selectedInventory).pipe(
              catchError(errorResponse => {
                const errorDetail = ErrorHelper.getErrorDetail(errorResponse, 'Error while connecting to Kubernetes cluster');
                this.modalNotificationService.openErrorDialog({title: 'Unfortunately, an unexpected error happened', description: errorDetail});
                this.emptyTableText = errorDetail;
                return scheduled([], asyncScheduler);
              }),
          );
        }),
      withLatestFrom(this.selectedTenantKey$),
      map(([certificates, selectedTenantKey]: [KubernetesCertificateWrapper[], string]) => {
        return _.map(certificates, (cert) => {
          return {...cert, isTenantScoped: _.isEqual(selectedTenantKey, cert.scope)};
        });
      }),
      shareReplay(1)
      );
  }

  getInitialCertificateColumns(): SecretManagementTableModel[] {
    const retrieve = LocalStorageHelper.retrieve(localKubernetesCertificateColumns);
    const preSelectedColumns = _.isNil(retrieve) ? initialKubernetesCertificatesColumns : JSON.parse(retrieve);
    return this.sortDisplayableColumns(_.union(fixKubernetesCertificatesColumns, preSelectedColumns));
  }

  filterBySearch(filterText: string): void {
    this.filterText = filterText;
  }

  resolveScopeItemText(scope: string): string {
    return TenantHelper.cropTenantFromKey(scope);
  }

  navigateToSecretManagement(): void {
    this.navigationService.navigateToSecretManagement();
  }

  triggerResourceLoad(): void {
    this._resourceLoadTrigger$.next(true);
  }

  searchableProjectFormatFn = (scope: string): string => {
    return this.resolveScopeItemText(scope);
  };

  updateSearchResult(filteredList: string[]): void {
    this.filteredScopes = filteredList;
  }

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }

  updateDisplayableColumns(columnsToDisplay: SecretManagementTableModel[]) {
    this.displayedColumnsForCertificates = this.sortDisplayableColumns(_.union(this.fixKubernetesCertificatesColumns, columnsToDisplay));
  }

  sortDisplayableColumns(displayableColumns: SecretManagementTableModel[]): SecretManagementTableModel[] {
    return _.sortBy(displayableColumns, column => _.isEqual(column, SecretManagementTableModel.ActionColumnName) ? 1 : 0);
  }

  isScopeFilteredOut(scope: string): boolean {
    return _.includes(this.filteredScopes, scope);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

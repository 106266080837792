import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { VariableDetailsComponent } from './variable-details/variable-details.component';
import { Injectable } from '@angular/core';
import { SaverService } from '../common/services/saver.service';
import { SaveChangesDialogService } from '../modal-dialog/save-changes-dialog/save-changes-dialog.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { RouteParamHelper } from '../common/helpers/route-param.helper';
import { projectKeyParam } from '../projects/project-routing.constants';

@Injectable()
export class VariableEditorDeactivationGuard  {

  constructor(private saverService: SaverService, private saveChangesDialogService: SaveChangesDialogService) {}

  canDeactivate(component: VariableDetailsComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> {
    const varEditorComp = component.variableEditorComp;
    return of(varEditorComp && varEditorComp.variableForm.dirty && (this.hasProjectSelectionChanged(currentRoute, currentState, nextState))).pipe(
      switchMap((hasUnsavedChanges: boolean) => {
        if (hasUnsavedChanges) {
          return this.saveChangesDialogService.confirmSavingChanges(() => this.saverService.save());
        }
        return of(this.hasProjectSelectionChanged(currentRoute, currentState, nextState));
      })
    );
  }

  hasProjectSelectionChanged(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
    if (currentState && nextState) {
      const currentProjectKey = RouteParamHelper.getPathParamFromRoute(currentRoute, projectKeyParam);
      const nextProjectKey = RouteParamHelper.getPathParamFromChildRoute(nextState.root, projectKeyParam);
      if (!_.isEqual(currentProjectKey, nextProjectKey)) {
        return true;
      }
      /**
       * When the project dropdown selection is the same as it's previous value, the default router redirects to '/variables' that deactivates VariableDetailsComponent
       * To prevent it, needs an extra check and disable deactivation.
       */
      const nextVariableIsDefined = !nextState.url.endsWith('variables');
      return nextVariableIsDefined;
    }
    return false;
  }
}

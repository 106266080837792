import { PatternDiffData } from '../../projects/publish-project/pattern-diff-view/pattern-diff-data.model';
import { ProjectMeta } from '../../projects/project.model';
import { NevisAdminAction } from '../actions';
import { Diff } from '../../common/model/publish-changes/diff.model';
import { VariableDiffData } from '../../projects/publish-project/variables-diff-view/variable-diff-data.model';
import { PublishProjectPayload } from '../../projects/publish-project/publish-project-payload.model';
import { TextDiffData } from '../../common/model/publish-changes/text-diff-data.model';

export enum PublishProjectActionTypes {
  LoadDiffData = '[PublishProject] Load Diff Data',
  LoadPatternsDiffData = '[PublishProject] Load Patterns Diff Data',
  LoadPatternDiffDataSuccess = '[PublishProject] Load Pattern Diff Data Success',
  LoadVariablesDiffData = '[PublishProject] Load Variables Diff Data',
  LoadVariablesDiffDataSuccess = '[PublishProject] Load Variables Diff Data Success',
  LoadBundlesDiffData = '[PublishProject] Load Bundles Diff Data',
  LoadBundlesDiffDataSuccess = '[PublishProject] Load Bundles Diff Data Success',
  LoadProjectDiffData = '[PublishProject] Load Project Diff Data',
  LoadProjectDiffDataSuccess = '[PublishProject] Load Project Diff Data Success',
  StoreShowOnlyDifferences = '[PublishProject] Store Show Only Differences',
  RequestPublishProject = '[PublishProject] Request Publish Project',
  PublishProject = '[PublishProject] Publish Project',
  ClearPublishProjectData = '[PublishProject] Clear Publish Project Data'
}

export interface LoadProjectDiffPayload {
  projectMeta: ProjectMeta;
  projectKey: string;
}

export class LoadDiffData implements NevisAdminAction {
  readonly type = PublishProjectActionTypes.LoadDiffData;
}

export class LoadPatternsDiffData implements NevisAdminAction<LoadProjectDiffPayload> {
  readonly type = PublishProjectActionTypes.LoadPatternsDiffData;

  constructor(public payload: LoadProjectDiffPayload) {}
}

export class LoadPatternDiffDataSuccess implements NevisAdminAction<PatternDiffData> {
  readonly type = PublishProjectActionTypes.LoadPatternDiffDataSuccess;

  constructor(public payload: PatternDiffData) {}
}

export class LoadVariablesDiffData implements NevisAdminAction<LoadProjectDiffPayload> {
  readonly type = PublishProjectActionTypes.LoadVariablesDiffData;

  constructor(public payload: LoadProjectDiffPayload) {}
}

export class LoadVariablesDiffDataSuccess implements NevisAdminAction<Diff<VariableDiffData>[]> {
  readonly type = PublishProjectActionTypes.LoadVariablesDiffDataSuccess;

  constructor(public payload: Diff<VariableDiffData>[]) {}
}

export class LoadBundlesDiffData implements NevisAdminAction<LoadProjectDiffPayload> {
  readonly type = PublishProjectActionTypes.LoadBundlesDiffData;

  constructor(public payload: LoadProjectDiffPayload) {}
}

export class LoadBundlesDiffDataSuccess implements NevisAdminAction<Diff<TextDiffData>> {
  readonly type = PublishProjectActionTypes.LoadBundlesDiffDataSuccess;

  constructor(public payload: Diff<TextDiffData>) {}
}

export class LoadProjectDiffData implements NevisAdminAction<LoadProjectDiffPayload> {
  readonly type = PublishProjectActionTypes.LoadProjectDiffData;

  constructor(public payload: LoadProjectDiffPayload) {}
}

export class LoadProjectDiffDataSuccess implements NevisAdminAction<Diff<TextDiffData>> {
  readonly type = PublishProjectActionTypes.LoadProjectDiffDataSuccess;

  constructor(public payload: Diff<TextDiffData>) {}
}

export class StoreShowOnlyDifferences implements NevisAdminAction<boolean> {
  readonly type = PublishProjectActionTypes.StoreShowOnlyDifferences;

  constructor(public payload: boolean) {}
}

export class RequestPublishProject implements NevisAdminAction<PublishProjectPayload> {
  readonly type = PublishProjectActionTypes.RequestPublishProject;

  constructor(public payload: PublishProjectPayload) {}
}

export class PublishProject implements NevisAdminAction<PublishProjectPayload> {
  readonly type = PublishProjectActionTypes.PublishProject;

  constructor(public payload: PublishProjectPayload) {}
}

export class ClearPublishProjectData implements NevisAdminAction {
  readonly type = PublishProjectActionTypes.ClearPublishProjectData;
}

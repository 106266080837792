import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

import { NestedTreeControl } from '@angular/cdk/tree';

import { Subject } from 'rxjs';

import { CanaryTreeNode } from './custom-resource-tree-node.model';
import { KubernetesDialogTypeEnum } from './kubernetes-status-dialog-payload.model';
import { Maybe } from '../../common/utils/utils';

@Component({
  selector: 'adm4-canary-tree-viewer',
  template: `
    <cdk-tree [dataSource]="nodes" [treeControl]="treeControl">
      <cdk-nested-tree-node *cdkTreeNodeDef="let node">
        <div class='tree-node' (click)="resourceSelected(node)" [class.node-selected]="node.id === selectedNode?.id">
          <span class='node-wrapper'>
            <mat-icon class='tree-node-icon'>book</mat-icon><span>{{node.name}}</span>
          </span>
        </div>
      </cdk-nested-tree-node>

      <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild">
        <div class='tree-node parent' (click)="treeControl.toggle(node)">
          
          <mat-icon *ngIf="treeControl.isExpanded(node); else collapsedIcon">arrow_drop_down</mat-icon>
          <ng-template #collapsedIcon><mat-icon >arrow_right</mat-icon></ng-template>
          
          <span class='strikethrough'>{{ node.isSecondaryNode? 'Secondary' : node.name | capitalizeFirst }}</span>
          <span *ngIf='node.isSecondaryNode && shouldDisplayPrimary' class='primary-text'> => &nbsp;Primary</span>
        </div>
        <div class="node-children" [class.children-invisible]="!treeControl.isExpanded(node)">
            <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
      </cdk-nested-tree-node>
    </cdk-tree>
  `,
  styleUrls: ['../../deployment-wizard/tree-viewer/tree-viewer.component.scss', 'canary-tree-viewer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanaryTreeViewerComponent implements OnChanges, OnDestroy {
  @Input() nodes: CanaryTreeNode[];
  @Input() kubernetesActionType: KubernetesDialogTypeEnum;
  @Output() nodeSelected: EventEmitter<CanaryTreeNode> = new EventEmitter();

  treeControl: NestedTreeControl<CanaryTreeNode, string>;

  public shouldDisplayPrimary: boolean = false;

  selectedNode: Maybe<CanaryTreeNode>;
  destroyed$: Subject<boolean> = new Subject();


  constructor() {
    this.treeControl = new NestedTreeControl<CanaryTreeNode, string>(node => node.children, {trackBy: node => node.id});
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.kubernetesActionType) {
      this.shouldDisplayPrimary = KubernetesDialogTypeEnum.PROMOTE === this.kubernetesActionType;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  hasChild(_index: number, node: CanaryTreeNode): boolean {
    return !node.isChildNode;
  }

  resourceSelected(node: CanaryTreeNode): void {
    this.nodeSelected.emit(node);
    this.selectedNode = node;
  }
}

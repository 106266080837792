import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of } from 'rxjs';
import { LoadBundlesDiffData, LoadBundlesDiffDataSuccess, LoadPatternDiffDataSuccess, LoadPatternsDiffData, LoadProjectDiffData, LoadProjectDiffDataSuccess, LoadVariablesDiffData, LoadVariablesDiffDataSuccess, PublishProject, PublishProjectActionTypes, RequestPublishProject } from './publish-project.actions';
import { AppState, Dictionary } from '../reducer';
import { select, Store } from '@ngrx/store';
import { catchError, filter, finalize, first, map, mergeAll, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { issuesView, patternTypesView, projectKeyView, projectMetaView, propertyTypesView } from '../views';
import { Project, ProjectDescription, ProjectDescriptionMeta, ProjectMeta } from '../../projects/project.model';
import * as _ from 'lodash';
import { PatternInstanceMeta } from '../../patterns/pattern-instance.model';
import { Diff } from '../../common/model/publish-changes/diff.model';
import { DeploymentHostDiffData, PatternDiffData, PatternLabelDiffData, PatternLinkDiffData, PatternNameDiffData, PatternNotesDiffData, PropertyDiffData, SimplePatternDiffData } from '../../projects/publish-project/pattern-diff-view/pattern-diff-data.model';
import { PatternService } from '../../patterns/pattern.service';
import { Revision } from '../../version-control/revision/revision.model';
import { PatternDiffViewHelper } from '../../projects/publish-project/pattern-diff-view/pattern-diff-view.helper';
import { VersionControlService } from '../../version-control/version-control.service';
import { PatternType, Property } from '../../plugins/pattern-type.model';
import { IssueModel } from '../../common/model/issue.model';
import { PropertyMetaInfo } from '../../version-control/pattern-field-meta-info.model';
import { LocalStatus } from '../../version-control/meta-info.model';
import { PropertyType } from '../../plugins/property-type.model';
import { VariableService } from '../../variables/variable.service';
import { VersionedVariableModel } from '../../variables/variable.model';
import { VariableDiffData } from '../../projects/publish-project/variables-diff-view/variable-diff-data.model';
import { VariablesDiffViewHelper } from '../../projects/publish-project/variables-diff-view/variables-diff-view.helper';
import { EmptyAction, NevisAdminAction } from '../actions';
import { GetUpdatesOfProject } from '../project';
import { Branch } from '../../version-control/branch.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { LoadProjectMeta } from '../version-control';
import { PublishPayload } from '../../common/model/publish-changes/publish-payload.model';
import { LoadingService } from '../../modal-dialog/loading-modal/loading.service';
import { ProjectService } from '../../projects/project.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationMessage } from '../../notification/notification.model';
import { BundlesDiffViewHelper } from '../../projects/publish-project/bundles-diff-view/bundles-diff-view.helper';
import { TextDiffData } from '../../common/model/publish-changes/text-diff-data.model';
import { BundlesVersionedInfo } from '../../version-control/bundles-meta-info.model';
import { PropertyTypeEnum } from '../../common/constants/app.constants';
import { mergeDictionaries, requireNonNull } from '../../common/utils/utils';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { EffectWithErrorHandlingMixin, IEffectWithErrorHandlingMixin } from '../effect-with-error-handling.mixin';
import { IPublishEffectMixin, PublishEffectMixin } from '../publish-effect.mixin';
import { ResourceList } from '../../patterns/pattern-attachment.model';

/**
 * Effects are used to collect all required data for the diff views of the project
 * Reason to use effects is that it makes it simpler to divide complex collecting of data from different data sources for different parts of the diff views
 * One action calls to load diff data, then that it being separated into several actions which then have its effects which can also divide loading into separate parts and then combine them
 */
@Injectable()
@Mixin([EffectWithErrorHandlingMixin, PublishEffectMixin])
export class PublishProjectEffects implements IEffectWithErrorHandlingMixin, IPublishEffectMixin {
   loadDiffData: Observable<LoadPatternsDiffData | LoadVariablesDiffData | LoadBundlesDiffData | LoadProjectDiffData>;
   loadPatternsDiff: Observable<LoadPatternDiffDataSuccess | EmptyAction>;
   loadVariablesDiff: Observable<LoadVariablesDiffDataSuccess | EmptyAction>;
   loadBundlesDiff: Observable<LoadBundlesDiffDataSuccess | EmptyAction>;
   loadProjectDiff: Observable<LoadProjectDiffDataSuccess | EmptyAction>;
   requestPublishProject: Observable<PublishProject | GetUpdatesOfProject | EmptyAction>;
   publishProject: Observable<LoadProjectMeta | EmptyAction>;
   clearRevisionCache: Observable<any>;

  /**
   * Implemented by EffectWithErrorHandlingMixin
   */
  handleErrorAction: <T extends NevisAdminAction<any> = EmptyAction>(error: any, displayMessage: NotificationMessage, returnedAction?: T) => T;

  /**
   * Implemented by PublishEffectMixin
   */
  getDiffWithRevision$: <T extends object, D extends Diff<T> | null = Diff<T>>(inventoryKey: string, commitId: (string | undefined), mapper: (revision?: Revision) => D) => Observable<D>;
  /**
   * Implemented by PublishEffectMixin
   */
  handlePublishError: (error: HttpErrorResponse) => void;

  /**
   * Used by PublishEffectMixin for feedback to user
   */
  readonly publishItemLabel = 'project';

  /**
   * To prevent loading same revision information because different meta entries point to same commit we cache requests in this map, which stores observables with cached values
   * It should be alive only while project publish is open and cleared when it's closed
   * cache.ts is not used here, because we're not caching plain values, but only observables with publishReplay, which I think putting into store would be an overkill
   * although moving there would not a bit deal, if it turns out to be better at some point
   */
  revisionCache: Map<string, Observable<Revision>> = new Map();

  constructor(private actions$: Actions,
              private store$: Store<AppState>,
              private versionControlService: VersionControlService,
              private patternService: PatternService,
              private variableService: VariableService,
              private projectService: ProjectService,
              public modalNotificationService: ModalNotificationService,
              private loadingService: LoadingService,
  ) {
    this.loadDiffData = createEffect(() => this.actions$.pipe(
      ofType(PublishProjectActionTypes.LoadDiffData),
      switchMap(() => {
        return forkJoin([
          this.store$.pipe(select(projectMetaView), first((projectMeta: ProjectMeta | null) => !_.isNil(projectMeta))),
          this.store$.pipe(select(projectKeyView), first((projectKey: string | null) => !_.isNil(projectKey)))
        ]).pipe(
          mergeMap(([projectMeta, projectKey]: [ProjectMeta, string]) => {
            return [
              new LoadPatternsDiffData({projectMeta, projectKey}),
              new LoadVariablesDiffData({projectMeta, projectKey}),
              new LoadBundlesDiffData({projectMeta, projectKey}),
              new LoadProjectDiffData({projectMeta, projectKey}),
            ];
          }),
          takeUntil(this.actions$.pipe(ofType(PublishProjectActionTypes.ClearPublishProjectData)))
        );
      })
    ));

    this.loadPatternsDiff = createEffect(() => this.actions$.pipe(
      ofType(PublishProjectActionTypes.LoadPatternsDiffData),
      switchMap((action: LoadPatternsDiffData) => {
        const patternIds: string[] = _.keys(action.payload.projectMeta.patterns).filter(key => action.payload.projectMeta.patterns[key].localStatus !== LocalStatus.Unmodified);
        return patternIds.map((patternId: string) => {
          return (this.patternService.getPatternInstance(action.payload.projectKey, patternId, true) as Observable<PatternInstanceMeta>).pipe(
            mergeMap((patternMeta: PatternInstanceMeta) => {
              return forkJoin([
                this.getPatternNameDiff$(patternMeta, action.payload.projectKey),
                this.getPatternLabelDiff$(patternMeta, action.payload.projectKey),
                this.getSimplePatternDiffData$(patternMeta, action.payload.projectKey),
                this.getPatternDeploymentHostDiff$(patternMeta, action.payload.projectKey),
                this.getPatternLinkDiff$(patternMeta, action.payload.projectKey),
                this.getPatternPropertiesDiff$(patternMeta, action.payload.projectKey),
                this.getPatternAttachmentsMeta$(patternMeta, action.payload.projectKey),
                this.getPatternNotesDiff$(patternMeta, action.payload.projectKey),
              ]).pipe(
                map((
                  [patternNameDiffData, patternLabelDiffData, patternSimpleDiffData, deploymentHostDiffData, patternlinkDiffData, propertiesDiffData, patternResources, patternNotes]:
                    [Diff<PatternNameDiffData> | null, Diff<PatternLabelDiffData> | null, Diff<SimplePatternDiffData>[], Diff<DeploymentHostDiffData> | null, Diff<PatternLinkDiffData> | null, Diff<PropertyDiffData>[], Dictionary<ResourceList>, Diff<PatternNotesDiffData> | null]
                ) => {
                  return new LoadPatternDiffDataSuccess(<PatternDiffData>{
                    patternId: patternMeta.patternId,
                    patternNameDiffData: patternNameDiffData,
                    patternLabelDiffData: patternLabelDiffData,
                    simplePatternDiffData: patternSimpleDiffData,
                    deploymentHostDiffData: deploymentHostDiffData,
                    patternLinkDiffData: patternlinkDiffData,
                    propertiesDiffData: propertiesDiffData,
                    patternResources: patternResources,
                    patternNotesDiffData: patternNotes
                  });
                }),
                catchError((error) => {
                  return of(this.handleErrorAction<EmptyAction>(error, {title: 'Could not create diff view of patterns', description: 'Something went wrong while loading data for diff view of patterns.'}));
                })
              );
            }),
            takeUntil(this.actions$.pipe(ofType(PublishProjectActionTypes.ClearPublishProjectData)))
          );
        });
      }),
      mergeAll()
    ));

    this.loadVariablesDiff = createEffect(() => this.actions$.pipe(
      ofType(PublishProjectActionTypes.LoadVariablesDiffData),
      switchMap((action: LoadVariablesDiffData) => {
        return this.variableService.getAllVariablesOfProject(action.payload.projectKey, true).pipe(
          mergeMap((variablesMeta: VersionedVariableModel[]) => this.getVariablesDiff$(variablesMeta, action.payload.projectKey).pipe(
            map((variablesDiff: Diff<VariableDiffData>[]) => new LoadVariablesDiffDataSuccess(variablesDiff)),
            catchError((error) => {
              return of(this.handleErrorAction<EmptyAction>(error, {title: 'Could not create diff view of variables', description: 'Something went wrong while loading data for diff view of variables.'}));
            })
          )));
      })
    ));

    this.loadBundlesDiff = createEffect(() => this.actions$.pipe(
      ofType(PublishProjectActionTypes.LoadBundlesDiffData),
      switchMap((action: LoadBundlesDiffData) => {
        return this.projectService.getProjectBundlesMeta(action.payload.projectKey).pipe(
          mergeMap((bundlesMeta: BundlesVersionedInfo) => this.getDiffWithRevision$<TextDiffData>(action.payload.projectKey, bundlesMeta._meta.commitId, (revision?: Revision) => BundlesDiffViewHelper.createBundlesDiff(bundlesMeta, revision)).pipe(
            map((bundlesDiff: Diff<TextDiffData>) => new LoadBundlesDiffDataSuccess(bundlesDiff)),
            catchError((error) => {
              return of(this.handleErrorAction<EmptyAction>(error, {title: 'Could not create diff view of variables', description: 'Something went wrong while loading data for diff view of variables.'}));
            })
          ))
        );
      })
    ));

    this.loadProjectDiff = createEffect(() => {
      return this.actions$.pipe(
          ofType(PublishProjectActionTypes.LoadProjectDiffData),
          switchMap((action: LoadProjectDiffData):  Observable<ProjectDescription> => this.projectService.getProjectDescription(action.payload.projectKey, true)),
          switchMap((pd: ProjectDescription): Observable<LoadProjectDiffDataSuccess | EmptyAction> => {
            return store$.select(projectMetaView).pipe(
                filter((projectMeta: ProjectMeta | null): projectMeta is ProjectMeta => !!projectMeta),
                switchMap((projectMeta: ProjectMeta): Observable<LoadProjectDiffDataSuccess | EmptyAction> => {
                  const descriptionMeta: ProjectDescriptionMeta = projectMeta.description;
                  const result: Diff<TextDiffData> = {
                    local: {
                      changeInfo: descriptionMeta,
                      fallbackLabel: 'About Project',
                      content: {text: pd.description || ''},
                    },
                    remote: {
                      content: {text: descriptionMeta.headValue || ''},
                    },
                  };
                  return of(new LoadProjectDiffDataSuccess(result));
                }),
            );
          }),
          catchError((error) => {
            console.error('PublishProjectEffects#loadProjectDiff: could not create project diff data.');
            console.error(error);
            return of(this.handleErrorAction<EmptyAction>(error, {
              title: 'Could not create diff view project description',
              description: 'Something went wrong while loading data for the diff view of the project description.'
            }));
          }),
      );
    });

    this.requestPublishProject = createEffect(() => this.actions$.pipe(
      ofType(PublishProjectActionTypes.RequestPublishProject),
      switchMap((action: RequestPublishProject) => {
        const projectToPublish: Project = action.payload.project;
        if (_.isNil(projectToPublish.repository) || _.isNil(projectToPublish.branch)) {
          return of(new EmptyAction());
        }
        return this.versionControlService.getBranchByRepoAndName(projectToPublish.repository, projectToPublish.branch).pipe(
          mergeMap((branch: Branch | undefined) => {
            const hasRemoteChange = !_.isNil(branch) && branch.commitId !== action.payload.projectMeta.localHead;
            if (hasRemoteChange) {
              return this.modalNotificationService.openConfirmDialog({
                title: 'Project not up to date',
                description: 'Please update the project before publishing changes. Note that there are local changes that will be lost if the project was modified remotely. Do you want to continue?'
              }, {
                confirmButtonText: 'Cancel',
                cancelButtonText: 'Update project'
              }).afterClosed().pipe(
                map((confirmed?: boolean) => {
                  return confirmed === false ? new GetUpdatesOfProject(action.payload.project.projectKey) : new EmptyAction();
                })
              );
            } else {
              return of(new PublishProject(action.payload));
            }
          })
        );
      })
    ));

    this.publishProject = createEffect(() => this.actions$.pipe(
      ofType(PublishProjectActionTypes.PublishProject),
      switchMap((action: PublishProject) => {
        if (_.isNil(action.payload.projectMeta.lastModification)) {
          this.modalNotificationService.openErrorDialog({description: 'There are no changes to publish. Something went wrong.'});
          return of(new EmptyAction());
        }
        const publishPayload: PublishPayload = {
          message: action.payload.commitMessage,
          lastModification: action.payload.projectMeta.lastModification,
          localHead: action.payload.projectMeta.localHead
        };
        this.loadingService.showLoading({title: 'We are publishing your changes to the repository', description: `The changes are published to the project ${action.payload.project.projectKey}. This may take a few seconds`});
        return this.projectService.publishProject(action.payload.project.projectKey, publishPayload).pipe(
          map(() => new LoadProjectMeta()),
          tap(() => this.modalNotificationService.openSuccessDialog({title: 'Success', description: 'Publish was successful!'})),
          finalize(() => this.loadingService.hideLoading()),
          catchError((error: HttpErrorResponse) => {
            this.handlePublishError(error);
            console.error(error);
            return of(new EmptyAction());
          })
        );
      })
    ));

    this.clearRevisionCache = createEffect(() => this.actions$.pipe(
      ofType(PublishProjectActionTypes.ClearPublishProjectData),
      tap(() => this.revisionCache.clear())
    ), {dispatch: false});
  }

  getRevisionOfCommit$(projectKey: string, commitId: string): Observable<Revision> {
    return this.versionControlService.getRevisionOfProjectCommit(projectKey, commitId);
  }

  private getPatternNameDiff$(patternMeta: PatternInstanceMeta, projectKey: string): Observable<Diff<PatternNameDiffData> | null> {
    if (patternMeta._meta.name.localStatus === LocalStatus.Unmodified) {
      return of(null);
    }
    return this.getDiffWithRevision$<PatternNameDiffData>(projectKey, patternMeta._meta.name.commitId, (revision?: Revision) => PatternDiffViewHelper.createPatternNameDiffData(patternMeta, revision));
  }

  private getPatternLabelDiff$(patternMeta: PatternInstanceMeta, projectKey: string): Observable<Diff<PatternLabelDiffData> | null> {
    if (_.isNil(patternMeta._meta.label) || patternMeta._meta.label.localStatus === LocalStatus.Unmodified) {
      return of(null);
    }
    const commitId: string | undefined = _.isNil(patternMeta._meta.label) ? undefined : patternMeta._meta.label.commitId;
    return this.getDiffWithRevision$<PatternLabelDiffData, Diff<PatternLabelDiffData> | null>(projectKey, commitId, (revision?: Revision) => PatternDiffViewHelper.createPatternLabelDiffData(patternMeta, revision));
  }

  private getPatternLinkDiff$(patternMeta: PatternInstanceMeta, projectKey: string): Observable<Diff<PatternLinkDiffData> | null> {
    if (_.isNil(patternMeta._meta.link) || patternMeta._meta.link.localStatus === LocalStatus.Unmodified) {
      return of(null);
    }
    return this.getDiffWithRevision$<PatternLinkDiffData>(projectKey, patternMeta._meta.link.commitId, (revision?: Revision) => PatternDiffViewHelper.createPatternLinkDiffData(patternMeta, revision));
  }


  private getPatternTypeDiff$(projectKey: string, patternMeta: PatternInstanceMeta): Observable<Diff<SimplePatternDiffData>> | null {
    return patternMeta._meta.className.localStatus !== LocalStatus.Modified ? null : this.store$.pipe(
      select(patternTypesView),
      first((patternTypes: Dictionary<PatternType> | null) => !_.isNil(patternTypes)),
      switchMap((patternTypes: Dictionary<PatternType>) => {
        return this.getDiffWithRevision$<SimplePatternDiffData>(projectKey, patternMeta._meta.className.commitId, (revision?: Revision) => PatternDiffViewHelper.createPatternTypeDiff(patternMeta, patternTypes, revision));
      })
    );
  }

  private getPatternDeploymentHostDiff$(patternMeta: PatternInstanceMeta, projectKey: string): Observable<Diff<DeploymentHostDiffData> | null> {
    return forkJoin([
      this.store$.pipe(select(patternTypesView), first((patternTypes: Dictionary<PatternType> | null) => !_.isNil(patternTypes))),
      this.store$.pipe(select(issuesView), first())
    ]).pipe(
      switchMap(([patternTypes, issues]: [Dictionary<PatternType>, IssueModel[]]) => {
        const commitId: string | undefined = _.isNil(patternMeta._meta.deploymentHosts) ? undefined : patternMeta._meta.deploymentHosts.commitId;
        return this.getDiffWithRevision$<DeploymentHostDiffData, Diff<DeploymentHostDiffData> | null>(projectKey, commitId, (revision?: Revision) => PatternDiffViewHelper.createDeploymentHostDiff(patternMeta, patternTypes, issues, revision));
      })
    );
  }

  private getPatternNotesDiff$(patternMeta: PatternInstanceMeta, projectKey: string): Observable<Diff<PatternNotesDiffData> | null> {
    if (_.isNil(patternMeta._meta.notes) || patternMeta._meta.notes.localStatus === LocalStatus.Unmodified) {
      return of(null);
    }
    const commitId: string | undefined = _.isNil(patternMeta._meta.notes) ? undefined : patternMeta._meta.notes.commitId;
    return this.getDiffWithRevision$<PatternNotesDiffData, Diff<PatternNotesDiffData> | null>(projectKey, commitId, (revision?: Revision) => PatternDiffViewHelper.createPatternNotesDiff(patternMeta, revision));
  }

  private getPatternPropertiesDiff$(patternMeta: PatternInstanceMeta, projectKey: string): Observable<Diff<PropertyDiffData>[]> {
    if (_.isEmpty(patternMeta.properties) && _.isEmpty(patternMeta?._meta?.properties)) {
      return of([]);
    }
    return forkJoin([
      this.store$.pipe(select(patternTypesView), first((patternTypes: Dictionary<PatternType> | null) => !_.isNil(patternTypes))),
      this.store$.pipe(select(propertyTypesView), first((propertyTypes: Dictionary<PropertyType> | null) => !_.isNil(propertyTypes))),
      this.store$.pipe(select(issuesView), first())
    ]).pipe(
      switchMap(([patternTypes, propertyTypes, issues]: [Dictionary<PatternType>, Dictionary<PropertyType>, IssueModel[]]) => {
        const unsetMetaProperties: PropertyMetaInfo[] = PatternDiffViewHelper.getUnsetMetaProperties(patternMeta, patternTypes);
        const allPropertyMetaInfo: PropertyMetaInfo[] = _.concat(patternMeta._meta.properties, unsetMetaProperties);
        return forkJoin(
          allPropertyMetaInfo.map((propertyMetaInfo: PropertyMetaInfo) => {
            return this.getDiffWithRevision$<PropertyDiffData>(projectKey, propertyMetaInfo.commitId, (revision?: Revision) => PatternDiffViewHelper.createPropertyDiff(propertyMetaInfo, patternMeta, patternTypes, propertyTypes, issues, revision));
          })
        );
      })
    );
  }

  private getSimplePatternDiffData$(patternMeta: PatternInstanceMeta, projectKey: string): Observable<Diff<SimplePatternDiffData>[]> {
    const patternTypeDiff$: Observable<Diff<SimplePatternDiffData>> | null = this.getPatternTypeDiff$(projectKey, patternMeta);
    const simpleDiffs: Observable<Diff<SimplePatternDiffData>>[] = [patternTypeDiff$].filter((diff) => !_.isNil(diff)) as Observable<Diff<SimplePatternDiffData>>[];
    return _.isEmpty(simpleDiffs) ? of([]) : forkJoin(simpleDiffs);
  }

  private getPatternAttachmentsMeta$(patternMeta: PatternInstanceMeta, projectKey: string): Observable<Dictionary<ResourceList>> {
    return forkJoin([
      this.store$.pipe(select(patternTypesView), first((patternTypes: Dictionary<PatternType> | null) => !_.isNil(patternTypes))),
      this.store$.pipe(select(propertyTypesView), first((propertyTypes: Dictionary<PropertyType> | null) => !_.isNil(propertyTypes))),
    ]).pipe(
      switchMap(([patternTypes, propertyTypes]: [Dictionary<PatternType>, Dictionary<PropertyType>]) => {
        if (_.isNil(patternTypes[patternMeta.className]) || _.isNil(patternTypes[patternMeta.className].properties)) {
          return of({});
        }
        const attachmentSources$: Observable<Dictionary<ResourceList>>[] = requireNonNull<Property[]>(patternTypes[patternMeta.className].properties)
          .filter((property: Property) => !_.isNil(propertyTypes[property.className]) && propertyTypes[property.className].uiComponent === PropertyTypeEnum.AttachmentPropertyComponent)
          .map(property => this.patternService.getPatternResourceList(projectKey, patternMeta.patternId, property.propertyKey, true).pipe(
            map((resourceList: ResourceList) => {
              return {[property.propertyKey]: resourceList};
            })
          ));
        if (_.isEmpty(attachmentSources$)) {
          return of({});
        }
        return forkJoin(attachmentSources$).pipe(map((resourceLists: Dictionary<ResourceList>[]) => mergeDictionaries(resourceLists)));
      })
    );
  }

  private getVariablesDiff$(variablesMeta: VersionedVariableModel[], projectKey: string): Observable<Diff<VariableDiffData>[]> {
    return forkJoin([
      this.store$.pipe(select(propertyTypesView), first((propertyTypes: Dictionary<PropertyType> | null) => !_.isNil(propertyTypes))),
      this.store$.pipe(select(issuesView), first())
    ]).pipe(
      switchMap(([propertyTypes, issues]: [Dictionary<PropertyType>, IssueModel[]]) => {
        return forkJoin(
          variablesMeta.map((variableMeta: VersionedVariableModel) => {
            return this.getDiffWithRevision$<VariableDiffData>(projectKey, variableMeta._meta.commitId, (revision?: Revision) => VariablesDiffViewHelper.createVariableDiff(variableMeta, propertyTypes, issues, revision));
          })
        );
      })
    );
  }
}

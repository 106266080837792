import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { CommonModules } from '../../common/common.module';
import { SessionExpiryDialogComponent } from './session-expiry-dialog..component';
import { SessionExpiryDialogService } from './session-expiry-dialog.service';

@NgModule({
  imports: [
    CommonModules,
    LoginRoutingModule,
  ],
  declarations: [
    LoginComponent,
    SessionExpiryDialogComponent
  ],
  providers: [SessionExpiryDialogService]
})
export class LoginModule {

}

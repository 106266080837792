<div [formGroup]='form'>
  <div class='form-group project-repository'>
    <label id='git-repo-label' class='input-label'>
      <span>{{gitRepoLabel}}</span>
      <span *ngIf='gitRepoIconTooltip' class='info-icon-container'>
        <i class="fa fa-info-circle help-icon" aria-hidden="true" [title]='gitRepoIconTooltip'></i>
      </span>
    </label>
    <div class='scan-input'>
      <input class='admn4-text-input form-control'
             [formControlName]="REPOSITORY_FORM_CONTROL_NAME"
             [name]="REPOSITORY_FORM_CONTROL_NAME"
             [class.errored]='repoHasError$ | async'
             (input)='onRepoNameChange()'
             (change)='scan()'
             [adm4ControlDisable]='shouldDisableRepoInput(shouldDisableRepoInput$ | async)'
             placeholder=' '/>
      <button type = 'button'
              [disabled]='shouldDisableRepoScanButton()'
              (click)='scan()'>
        <i class="fa fa-repeat input-icon" aria-hidden="true"></i>
      </button>
    </div>
    <div class='validation-message-container'>
      <adm4-validation-message *ngIf="repoHasError$ | async" [isError]='true'
                               [message]='repoErrorMessage$ | async'></adm4-validation-message>
    </div>
  </div>
  <div class='form-group'>
    <label for='import-project-branches' class='input-label'>Branch*</label>
    <mat-form-field class='full-width'>
      <mat-select id='import-project-branches'
                  [formControlName]="BRANCH_FORM_CONTROL_NAME"
                  [disableOptionCentering]='true'
                  [adm4ControlDisable]="shouldDisableBranch(branchDisabled$ | async)">
        <mat-option *ngFor='let branch of branches$ | async'
                    [value]='branch'>
          {{branch.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class='validation-message-container'></div>
  </div>
  <div class='form-group' *ngIf='pathVisible'>
    <label for='import-project-path' class='input-label'>
      Directory<ng-container *ngIf='pathRequired'>*</ng-container>
    </label>
    <input type='text'
           id='import-project-path'
           class='admn4-text-input form-control'
           [class.errored]='form.controls[PATH_FORM_CONTROL_NAME].invalid'
           [placeholder]='pathPlaceholder'
           [formControlName]='PATH_FORM_CONTROL_NAME'
           [adm4ControlDisable]="isDisabled">
    <div class='validation-message-container'>
      <adm4-validation-message *ngIf='shouldShowPathErrorMessage("required")'
                               [isError]='true'
                               [message]='ERROR_PATH__REQUIRED'
      ></adm4-validation-message>
      <adm4-validation-message *ngIf='shouldShowPathErrorMessage("pattern")'
                               [isError]='true'
                               [message]='ERROR_INVALID_PATH'></adm4-validation-message>
    </div>
  </div>
</div>

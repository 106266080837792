import { Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ReportsContext } from '../reports.context';
import { PluginModel } from '../../plugins/plugin.model';
import { Inventory } from '../../inventory/inventory.model';
import { ActivatedRoute } from '@angular/router';
import { reportClassParam } from '../../projects/project-routing.constants';
import { ProjectContext } from '../../projects/project.context';
import { IssueModel } from '../../common/model/issue.model';
import { IssueSeverityEnum } from '../../common/model/issue-severity.enum';
import { Dictionary } from '../../model/reducer';
import { SplitPaneConfig } from '../../common/model/split-pane-config.model';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../common/mixins/split.mixin';
import { ResizeHelper } from '../../common/helpers/resize.helper';
import { IOutputData } from 'angular-split/lib/interface';
import { Project, ProjectMetaChangeIndicator } from '../../projects/project.model';

@Component({
  selector: 'adm4-reports',
  template: `
    <div class='full-height'>
      <as-split class="hide-as-split-gutter" direction='horizontal' gutterSize='1' useTransition (dragEnd)='onResize($event)'>
        <as-split-area [order]='splitPaneConfig[listSplitAreaKey].order' [size]='splitPaneConfig[listSplitAreaKey].size' [minSize]='20'>
          <div class='full-height-flex'>
            <adm4-column-header styleClass="project-header">
              <adm4-project-header [projectKey]='currentProjectKey$ | async'
                                   [projectIssues]='issues$ | async'
                                   [highestSeverity]="highestSeverity$ | async"
                                   [projects]='projects$ | async'
                                   [projectMetaIndicator]="projectMetaChangeIndicator$ | async"
              ></adm4-project-header>
            </adm4-column-header>
            <div class='remaining-space-flex-content-wrapper'>
              <div class='remaining-space-flex-content'>
                <adm4-report-list *ngIf='currentProjectKey$ | async'
                                  [projectKey]='currentProjectKey$ | async'
                                  [selectedReportClassName]='selectedReportClassName$ | async'>
                </adm4-report-list>
              </div>
            </div>
          </div>
        </as-split-area>
        <as-split-area [order]='splitPaneConfig[reportSplitAreaKey].order' [size]='splitPaneConfig[reportSplitAreaKey].size' [minSize]='40'>
          <div class='full-height'>
            <adm4-report-details
                    [projectKey]='currentProjectKey$ | async'
                    [report]='selectedReport$ | async'
                    [inventories]='inventories$ | async'
            ></adm4-report-details>
          </div>
        </as-split-area>
      </as-split>
    </div>
  `,
  providers: [
    ReportsContext
  ],
})
@Mixin([SplitMixin])
export class ReportsMainComponent implements ISplitMixin {
  currentProjectKey$: Observable<string | null>;
  selectedReportClassName$: Observable<string | null>;
  selectedReport$: Observable<PluginModel | undefined>;
  inventories$: Observable<Inventory[]>;
  projects$: Observable<Dictionary<Project>>;
  issues$: Observable<IssueModel[]>;
  highestSeverity$: Observable<IssueSeverityEnum>;
  public projectMetaChangeIndicator$: Observable<ProjectMetaChangeIndicator>;

  readonly listSplitAreaKey = 'list';
  readonly reportSplitAreaKey = 'report';
  readonly splitPaneConfigLocalStorageKey = 'report-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.listSplitAreaKey]: {order: 0, size: 30},
    [this.reportSplitAreaKey]: {order: 1, size: 70}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  constructor(private reportsContext: ReportsContext, private projectContext: ProjectContext, private route: ActivatedRoute) {
    this.currentProjectKey$ = this.reportsContext.projectKey$;
    this.selectedReportClassName$ = this.route.params.pipe(map(params => params[reportClassParam]), distinctUntilChanged());
    this.inventories$ = this.reportsContext.inventories$;
    this.projects$ = this.projectContext.projects$;
    this.selectedReport$ = combineLatest([this.reportsContext.reports$, this.selectedReportClassName$]).pipe(map(([reports, selectedReportsClass]: [PluginModel[], string]) => {
      return reports.find((report) => report.className === selectedReportsClass);
    }));
    this.issues$ = this.projectContext.issues$;
    this.highestSeverity$ = this.projectContext.highestSeverity$;
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
    this.projectMetaChangeIndicator$ = this.projectContext.projectMetaChangeIndicator$;
  }
}

import { Project, ProjectMeta } from '../../project.model';
import { BranchProjectForm } from '../../create-import-project-common/create-import-branch-project-form.model';
import { VersionControlHelper } from '../../../common/helpers/version-control.helper';
import * as _ from 'lodash';
import { NotificationMessage } from '../../../notification/notification.model';
import { Branch } from '../../../version-control/branch.model';
import { VersionControlForm } from '../../../common/components/version-control-form/version-control.form';
import { VersionControlData } from '../../../common/model/version-control-data.model';

export enum ProjectChangesStatus {
  REMOTE = 'Remote',
  LOCAL = 'Local',
  BOTH = 'Both',
  NONE = 'None'
}

export class BranchProjectHelper {

  static checkProjectChanges(projectMeta: ProjectMeta, branch?: Branch): ProjectChangesStatus {
    const hasRemoteChange = !_.isNil(branch) && branch.commitId !== projectMeta.localHead;
    const hasLocalChanges = !_.isNil(projectMeta.lastModification);
    if (hasRemoteChange && hasLocalChanges) {
      return ProjectChangesStatus.BOTH;
    }
    if (!hasRemoteChange && hasLocalChanges) {
      return ProjectChangesStatus.LOCAL;
    }
    if (hasRemoteChange && !hasLocalChanges) {
      return ProjectChangesStatus.REMOTE;
    }
    return ProjectChangesStatus.NONE;
  }

  static getProjectChangesStatusNotification (changeType: ProjectChangesStatus): NotificationMessage {
    switch (changeType) {
      case ProjectChangesStatus.BOTH:
        return {
          title: 'Local and remote changes found',
          description: `The current project has remote changes pending to be updated from Git and local changes pending to be published to Git on the current project.
            <br/>Note that if you continue, the new branched project will contain the remote changes, but not your local changes.
            <br/>Do you want to continue anyway?`
        };

      case ProjectChangesStatus.LOCAL:
        return {
          title: 'Local changes found',
          description: `There are local changes pending to be published to Git on the current project.
            <br/>Note that if you continue, the new branched project will not contain your local changes.
            <br/>Do you want to continue anyway?`
        };

      case ProjectChangesStatus.REMOTE:
        return {
          title: 'Remote changes found',
          description: `The current project is not up to date. Please get updates first to have the latest changes from the Git repository.
            <br/>Note that if you continue, the new branched project will contain the remote changes but the current project will not.
            <br/>Do you want to continue anyway?`
        };
      default:
        throw new Error('Unhandled case or unsupported project status');
    }
}

  static convertFormValueToVersionControlData(formValue: VersionControlForm): VersionControlData {
    const repository = formValue.repository;
    const branchName = VersionControlHelper.getBranchName(formValue.branch);
    return {
      repository: repository,
      branch: branchName,
      path: VersionControlHelper.getValidPath(formValue.path)
    };
  }

  static convertFormValueToVersionedProject(formValue: BranchProjectForm, tenantKey: string): Project {
    const projectKey = `${tenantKey}-${formValue.projectKey}`.toUpperCase();
    const repository = formValue.versionControl.repository;
    const branchName = VersionControlHelper.getBranchName(formValue.versionControl.branch);
    const newBranch = formValue.newBranchName;
    return {
      projectKey: projectKey,
      tenantKey: tenantKey,
      repository: repository,
      branch: newBranch,
      branchFrom: branchName,
      path: VersionControlHelper.getValidPath(formValue.versionControl.path)
    };
  }
}

import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState, Dictionary, ProjectKey } from './reducer';
import { PatternListData } from '../patterns/pattern-list-data.model';
import * as _ from 'lodash';
import { PatternType } from '../plugins/pattern-type.model';
import { IssueModel, SourceType } from '../common/model/issue.model';
import { dictionaryToArray, entryWithKeyExists, mapToArray, requireNonNull } from '../common/utils/utils';
import { Pattern, PatternCopyTarget } from '../patterns/pattern.model';
import { Usage } from '../common/model/plugin-usage.model';
import { PatternInstance } from '../patterns/pattern-instance.model';
import {
  GlobalConstant,
  GlobalConstantWithUsage,
  Inventory,
  ResourceWrapper,
  ResourceWrapperWithUsage,
  SecretResourceWrapper,
  SecretResourceWrapperWithUsage,
  SecretWrapper,
  SecretWrapperWithUsage,
  TenantResourceFlag,
} from '../inventory/inventory.model';
import {
  allHosts,
  DeployToOption,
  DeployToOptions,
} from '../deployment-wizard/deployment-selection/deploy-to-option.model';
import { VariableListItem, VariableModel } from '../variables/variable.model';
import {
  getIssuesById,
  getPatternIssuesWithoutPropertyInfo,
  isErrorIssue
} from '../projects/project-issues/project-issues.helper';
import {
  GenerationOutputModel,
  PlanningOutputModel,
} from '../deployment-wizard/deploy/deployment-preview/planning-output.model';
import { PluginModel } from '../plugins/plugin.model';
import { UsageHelper } from '../common/helpers/usage.helper';
import { Tenant } from '../tenant/tenant.model';
import { TenantHelper } from '../common/helpers/tenant.helper';
import { UsageInfo } from '../common/components/usage/usage-info';
import { Project, ProjectMeta } from '../projects/project.model';
import * as fromReport from './report/report.reducer';
import { ApplicationInfo } from './shared/application-info.model';
import { ProjectState } from './project';
import { InventoryState } from './inventory';
import { PatternVersionInfo } from '../version-control/pattern-meta-info.model';
import { MetaInfo } from '../version-control/meta-info.model';
import { SharedState } from './shared/shared.reducer';

// global shared view
export const tenantsView = (state: AppState) => state.shared.allTenants;
export const selectedTenantKeyView = (state: AppState) => state.shared.selectedTenantKey;
export const applicationInfoView = (state: AppState) => state.shared.applicationInfo;
export const sharedView = (state: AppState) => state.shared;

export interface UpgradeInfo {
  current: string;
  upgrade: string;
}

/**
 * Returns `undefined` if the appInfo is not loaded yet, `false`, if there's no available upgrade,
 * and the current and the available version, if there's an upgrade available.
 */
export const availableVersionView: MemoizedSelector<AppState, undefined | false | UpgradeInfo> = createSelector(
    applicationInfoView,
    (appInfo: ApplicationInfo): undefined | false | UpgradeInfo => {
      if (!appInfo) {
        return undefined;
      } else if (!appInfo.latestVersion) {
        return false;
      } else {
        return {current: appInfo.version, upgrade: appInfo.latestVersion};
      }
    });

export const analyticsEnabledView: MemoizedSelector<AppState, boolean> = createSelector(
  sharedView,
  (sharedState: SharedState): boolean => sharedState.analytics.enabled,
);

export const isAnalyticsSubmissionDueView: MemoizedSelector<AppState, boolean> = createSelector(
  sharedView,
  (sharedState: SharedState): boolean => sharedState.analytics.isSubmissionDue,
);

export const isAnalyticsSubmissionNotificationMutedView: MemoizedSelector<AppState, boolean> = createSelector(
  sharedView,
  (sharedState: SharedState): boolean => sharedState.analytics.isSubmissionNotificationMuted,
);
// end of shared state views

// project state views
export const projectKeyView = (state: AppState): string | null => state.project.selectedProjectKey;
export const allProjectsView = (state: AppState): Dictionary<Project> => state.project.allProjects;

export const allProjectsListView: MemoizedSelector<AppState, Project[], (projectState: ProjectState) => Project[]> = createSelector(
    (state: AppState): ProjectState => state.project,
    (projectState: ProjectState): Project[] => Object.values(projectState.allProjects ?? {}),
);

export const issuesView = (state: AppState) => state.project.issues;
export const projectIssueStateView = (state: AppState) => state.project.projectIssueState;
export const projectMetaStateView = (state: AppState) => state.project.projectMetaState;
export const projectValidatingView = (state: AppState) => state.project.validating;
// end of project state views

// inventory state views
const inventoryFeatureSelector = createFeatureSelector<InventoryState>('inventory');
export const inventoryKeyView = (state: AppState) => state.inventory.selectedInventoryKey;
export const diffInventoryKeyView = (state: AppState) => state.inventory.diffInventoryKey;
export const allInventoriesView = (state: AppState) => state.inventory.allInventories;
export const allInventoriesListView: MemoizedSelector<AppState, Inventory[]> = createSelector(
    inventoryFeatureSelector,
    (inventoryState: InventoryState): Inventory[] => Object.values(inventoryState.allInventories??{}),
);
export const selectedInventoryContentView = (state: AppState) => state.inventory.selectedInventoryContent;
export const selectedDiffInventoryContentView = (state: AppState) => state.inventory.diffInventoryContent;
export const inventoryValidationStatusView = (state: AppState) => state.inventory.validationStatus;
export const inventoryTimestampView = (state: AppState) => state.inventory.timestamp;

export const tenantScopedInventoryResourcesWithUsageView = (state: AppState): ResourceWrapperWithUsage[] => state.inventory.tenantScopedInventoryResourcesWithUsage.resources;
export const tenantScopedInventoryResourcesView = (state: AppState): ResourceWrapper[] => state.inventory.tenantScopedInventoryResources.resources;
export const inventoryScopedInventoryResourcesWithUsageView = (state: AppState): ResourceWrapperWithUsage[] => state.inventory.inventoryScopedInventoryResourcesWithUsage.resources;
export const inventoryScopedInventoryResourcesView = (state: AppState): ResourceWrapper[] => state.inventory.inventoryScopedInventoryResources.resources;

export const tenantScopedSecretResourcesWithUsageView = (state: AppState): SecretResourceWrapperWithUsage[] => state.inventory.tenantScopedInventoryResourcesWithUsage.secretResources;
export const tenantScopedSecretResourcesView = (state: AppState): SecretResourceWrapper[] => state.inventory.tenantScopedInventoryResources.secretResources;
export const inventoryScopedSecretResourcesWithUsageView = (state: AppState): SecretResourceWrapperWithUsage[] => state.inventory.inventoryScopedInventoryResourcesWithUsage.secretResources;
export const inventoryScopedSecretResourcesView = (state: AppState): SecretResourceWrapper[] => state.inventory.inventoryScopedInventoryResources.secretResources;

export const tenantScopedInventorySecretsWithUsageView = (state: AppState): SecretWrapperWithUsage[] => state.inventory.tenantScopedInventoryResourcesWithUsage.secrets;
export const tenantScopedInventorySecretsView = (state: AppState): SecretWrapper[] => state.inventory.tenantScopedInventoryResources.secrets;
export const inventoryScopedInventorySecretsWithUsageView = (state: AppState): SecretWrapperWithUsage[] => state.inventory.inventoryScopedInventoryResourcesWithUsage.secrets;
export const inventoryScopedInventorySecretsView = (state: AppState): SecretWrapper[] => state.inventory.inventoryScopedInventoryResources.secrets;

/**
 * AKA Global Constants.
 */
export const tenantScopedConstantsWithUsageView = createSelector(
    inventoryFeatureSelector,
    (state: InventoryState): GlobalConstantWithUsage[] => state.tenantScopedConstantsWithUsage,
);
export const tenantScopedConstantsView = createSelector(
    inventoryFeatureSelector,
    (state: InventoryState): GlobalConstant[] => state.tenantScopedConstants,
);

export const resourcesWithUsageView = createSelector(
  tenantScopedInventoryResourcesWithUsageView,
  inventoryScopedInventoryResourcesWithUsageView,
  (tenantScopedInventoryResources, selectedInventoryResources): (ResourceWrapperWithUsage & TenantResourceFlag)[] => {
    const tenantScopedResources: (ResourceWrapperWithUsage & TenantResourceFlag)[] = _.map(tenantScopedInventoryResources, (resource) => {
      return {...resource, isTenantScoped: true};
    });
    return _.union((tenantScopedResources), (selectedInventoryResources)) as (ResourceWrapperWithUsage & TenantResourceFlag)[];
  }
);

export const resourcesView = createSelector(
  [tenantScopedInventoryResourcesView, inventoryScopedInventoryResourcesView],
  (tenantScopedInventoryResources, selectedInventoryResources): (ResourceWrapper & TenantResourceFlag)[] => {
    const tenantScopedResources: (ResourceWrapper & TenantResourceFlag)[] = _.map(tenantScopedInventoryResources, (resource) => {
      return {...resource, isTenantScoped: true};
    });
    return _.union((tenantScopedResources), (selectedInventoryResources)) as (ResourceWrapper & TenantResourceFlag)[];
  }
);

export const secretResourcesWithUsageView = createSelector(
  tenantScopedSecretResourcesWithUsageView, inventoryScopedSecretResourcesWithUsageView,
  (tenantScopedSecretResources, inventoryScopedSecretResources): (SecretResourceWrapperWithUsage & TenantResourceFlag)[] => {
    const tenantScopedResources: (SecretResourceWrapperWithUsage & TenantResourceFlag)[] = _.map(tenantScopedSecretResources, (secretResource) => {
      return {...secretResource, isTenantScoped: true};
    });
   return _.union((tenantScopedResources), (inventoryScopedSecretResources)) as (SecretResourceWrapperWithUsage & TenantResourceFlag)[];
  }
);

export const secretResourcesView = createSelector(
  [tenantScopedSecretResourcesView, inventoryScopedSecretResourcesView],
  (tenantScopedSecretResources, inventoryScopedSecretResources): (SecretResourceWrapper & TenantResourceFlag)[] => {
    const tenantScopedResources: (SecretResourceWrapper & TenantResourceFlag)[] = _.map(tenantScopedSecretResources, (secretResource) => {
      return {...secretResource, isTenantScoped: true};
    });
   return _.union((tenantScopedResources), (inventoryScopedSecretResources)) as (SecretResourceWrapper & TenantResourceFlag)[];
  }
);

export const secretsWithUsageView = createSelector(
  tenantScopedInventorySecretsWithUsageView, inventoryScopedInventorySecretsWithUsageView,
  (tenantScopedInventorySecrets, inventoryScopedInventorySecrets): (SecretWrapperWithUsage & TenantResourceFlag)[] => {
    const tenantScopedResources: (SecretWrapperWithUsage & TenantResourceFlag)[] = _.map(tenantScopedInventorySecrets, (secret) => {
      return {...secret, isTenantScoped: true};
    });
    return _.union((tenantScopedResources), (inventoryScopedInventorySecrets)) as (SecretWrapperWithUsage & TenantResourceFlag)[];
  }
);

export const secretsView = createSelector(
  [tenantScopedInventorySecretsView, inventoryScopedInventorySecretsView],
  (tenantScopedInventorySecrets, inventoryScopedInventorySecrets): (SecretWrapper & TenantResourceFlag)[] => {
    const tenantScopedResources: (SecretWrapper & TenantResourceFlag)[] = _.map(tenantScopedInventorySecrets, (secret) => {
      return {...secret, isTenantScoped: true};
    });
    return _.union((tenantScopedResources), (inventoryScopedInventorySecrets)) as (SecretWrapper & TenantResourceFlag)[];
  }
);

// end of inventory state views

// pattern state views
export const selectedPatternInstanceView = (state: AppState) => state.pattern.selectedPatternInstance;
export const patternsView = (state: AppState) => state.pattern.allPatterns;
export const patternTypesView = (state: AppState) => state.pattern.patternTypes;
export const propertyTypesView = (state: AppState) => state.pattern.propertyTypes;
export const patternUsageDataView = (state: AppState) => state.pattern.selectedPatternUsageList;
export const copyTargetsView = (state: AppState): PatternCopyTarget[] => state.pattern.selectedPatternCopies;
export const patternResourcesView = (state: AppState) => state.pattern.patternAttachments;
// end of pattern state views

// variable state views
export const variablesView = (state: AppState) => state.variable.allVariables;
// end of variable state views

// version control state views
export const projectMetaView = (state: AppState) => state.versionControl.projectMeta;
// end of version control state views

// report state view
export const projectReportsView = (state: AppState) => state.report.reports;
export const generatedReportsView = (state: AppState) => state.report.generatedReports;
export const isReportGenerationInProgressView = (state: AppState) => state.report.isGenerationInProgress;
export const patternSummaryReportsCachedView = (state: AppState) => !_.isEqual(state.report.patternSummaryReports, fromReport.initState.patternSummaryReports);
export const patternSummaryApplicationsReportView = (state: AppState) => state.report.patternSummaryReports.applicationsReport;
export const patternSummaryRealmsReportView = (state: AppState) => state.report.patternSummaryReports.realmsReport;
export const patternSummaryInstancesReportView = (state: AppState) => state.report.patternSummaryReports.instancesReport;
export const patternSummaryReportsNotFound = (state: AppState) => state.report.patternSummaryReports.reportsNotFound;
export const patternSummaryReportsError = (state: AppState) => state.report.patternSummaryReports.reportsFailedToLoad;
// end of report state view

// publish project state view
export const patternsDiffView = (state: AppState) => state.publishProject.patternsDiffData;
export const variablesDiffView = (state: AppState) => state.publishProject.variablesDiffData;
export const bundlesDiffView = (state: AppState) => state.publishProject.bundlesDiff;
export const projectDiffView = (state: AppState) => state.publishProject.projectDescriptionDiff;
export const showOnlyDifferencesView = (state: AppState) => state.publishProject.showOnlyDifferences;
// end of publish project state view

// publish inventory state view
export const inventoryMetaView = (state: AppState) => state.publishInventory.inventoryMeta;
export const inventoryContentDiffView = (state: AppState) => state.publishInventory.inventoryContentDiff;
// end of publish inventory state view

// cached state views
export const pluginDocView = (state: AppState) => state.entities.pluginDocumentations;
// end of cached state views

// deployment wizard state views
export const isDeploymentWizardOpenedView = (state: AppState) => state.deploy.isWizardOpened;
export const deploymentWizardSelectionView = (state: AppState) => state.deploy.deploymentSelection;
export const deploymentWizardSelectionProjectKeyView = (state: AppState) => state.deploy.deploymentSelection.projectKey;
export const deploymentWizardSelectionInventoryKeyView = (state: AppState) => state.deploy.deploymentSelection.inventoryKey;
export const hostExpressionsView = (state: AppState) => state.deploy.hostExpressions;
export const deployInventoryValidationStatusView = (state: AppState) => state.deploy.inventoryValidationStatus;
export const deployProcessView = (state: AppState) => state.deploy.deployProcess;
export const deploymentIdView = (state: AppState): string | undefined => state.deploy.deployProcess?.deploymentId;
export const deploymentGenerationStatusView = (state: AppState) => state.deploy.generation.status;
export const deploymentGenerationIssuesView: (state: AppState) => IssueModel[] = (state: AppState) => state.deploy.generation.issues || [];
export const deploymentGenerationIsInProgressView: (state: AppState) => boolean = (state: AppState) => state.deploy.generation.isInProgress || false;
export const deploymentGenerationOutputView: (state: AppState) => GenerationOutputModel[] = (state: AppState) => state.deploy.generation.output || [];
export const deploymentPlanningStatusView = (state: AppState) => state.deploy.planning.status;
export const deploymentPlanningIsInProgressView: (state: AppState) => boolean = (state: AppState) => state.deploy.planning.isInProgress || false;
export const deploymentPlanningOutputView: (state: AppState) => PlanningOutputModel[] = (state: AppState) => state.deploy.planning.output || [];
export const deploymentPlanningForcedView: (state: AppState) => boolean = (state: AppState) => state.deploy.planning.redeploymentForced || false;
export const deploymentStatusView = (state: AppState) => state.deploy.deployment.status;
// end of deployment wizard state views

export const isErroredProjectView = createSelector(issuesView, (issues: IssueModel[]) => {
  return issues.some(issue => isErrorIssue(issue));
});

// authentication Views
export const isAuthenticatedView = (state: AppState) => state.userState.authenticated;
export const isAuthDoneView = (state: AppState) => state.userState.loaded;
export const isAuthExpiredView = (state: AppState) => state.userState.expired;
export const userDataView = (state: AppState) => state.userState.user;
export const superAdminView = (state: AppState) => state.userState.superAdmin;

// end of authentication Views

export const selectedTenantView = createSelector([tenantsView, selectedTenantKeyView], (tenants: Dictionary<Tenant>, selectedTenantKey: string | null) => {
  return TenantHelper.getTenantByKey(tenants, selectedTenantKey);
});

export const patternListItemsView: MemoizedSelector<AppState, PatternListData[], (allPatterns: Map<string, Pattern>, allIssues: IssueModel[], allPatTypes: Record<string, PatternType> | null) => PatternListData[]> =
  createSelector(
    patternsView,
    issuesView,
    patternTypesView,
    (allPatterns: Map<string, Pattern>, allIssues: IssueModel[], allPatTypes: Record<string, PatternType> | null): PatternListData[] => {
      return Array.from(allPatterns.values()).map((pattern: Pattern): PatternListData => {
        const patternIssues = getIssuesById(allIssues, SourceType.PATTERN, pattern.patternId);
        const patternType = _.find(allPatTypes, type => type.className === pattern.className);
        return new PatternListData(pattern, patternIssues, requireNonNull(patternType, `patternType for ${pattern.patternId}`));
      });
    },
  );


export const variableListView: MemoizedSelector<AppState, VariableModel[]> = createSelector(
  variablesView,
  (allVariables: Dictionary<VariableModel>): VariableModel[] => _.values(allVariables),
);

export const variableListItemsView: MemoizedSelector<AppState, VariableListItem[]> =
  createSelector(
    variablesView,
    issuesView,
    (allVariables: Record<string, VariableModel>, allIssues: IssueModel[]): VariableListItem[] => {
      return Object.values(allVariables).map((variable: VariableModel) => {
        const variableIssues = getIssuesById(allIssues, SourceType.VARIABLE, variable.variableKey);
        return new VariableListItem(variable, variableIssues);
      });
    }
  );

export const selectedPatternIssuesView: MemoizedSelector<AppState, IssueModel[] | undefined> = createSelector(
  selectedPatternInstanceView,
  issuesView,
  (pattern: PatternInstance | null, allIssues: IssueModel[]): IssueModel[] | undefined  => {
    if (pattern) {
      return getIssuesById(allIssues, SourceType.PATTERN, requireNonNull(pattern.patternId));
    }
    return undefined;
  }
);

export const selectedPatternIssuesWithoutPropertyInfoView: MemoizedSelector<AppState, IssueModel[] | undefined>
  = createSelector(
    selectedPatternInstanceView,
    issuesView,
    (pattern: PatternInstance | null, allIssues: IssueModel[]): IssueModel[] | undefined => {
      if (pattern) {
        return getPatternIssuesWithoutPropertyInfo(allIssues, requireNonNull(pattern.patternId));
      }
      return undefined;
    }
  );

export const selectedPatternDocView: MemoizedSelector<AppState, string | null> = createSelector(
  selectedPatternInstanceView,
  pluginDocView,
  projectKeyView,
  (pattern: PatternInstance | null, doc: Dictionary<string | null>, key: ProjectKey): string | null => {
    if (pattern) {
      return doc[key + pattern.className];
    }
    return null;
  }
);

export const patternUsageView: MemoizedSelector<AppState, UsageInfo[]> = createSelector(
  patternsView,
  patternTypesView,
  patternUsageDataView,
  (patternList: Map<string, Pattern>, pTypes: Dictionary<PatternType>, usages: Usage[]): UsageInfo[] => {
    return UsageHelper.createUsageInfo(usages, mapToArray(patternList), dictionaryToArray(pTypes));
  }
);

export const variablesListView: MemoizedSelector<AppState, VariableModel[]> = createSelector(
  variablesView,
  (allVariables) => Object.values(allVariables),
);

export const patternMetaInfoView: MemoizedSelector<AppState, PatternVersionInfo | null> = createSelector(
  projectMetaView,
  selectedPatternInstanceView,
  (projectMetaInfo: ProjectMeta | null, pattern: PatternInstance | null): PatternVersionInfo | null => {
    if (projectMetaInfo && pattern && projectMetaInfo.patterns && projectMetaInfo.patterns[pattern.patternId]) {
      return projectMetaInfo.patterns[pattern.patternId];
    }
    return null;
  },
);

export const variablesMetaInfoView: MemoizedSelector<AppState, MetaInfo | null> = createSelector(
  projectMetaView,
  (projectMetaInfo: ProjectMeta | null): MetaInfo | null => {
    return _.isNil(projectMetaInfo) ? null : projectMetaInfo.variables;
  },
  );

export const selectedProjectView: MemoizedSelector<AppState, Project | undefined> = createSelector(
  projectKeyView,
  allProjectsView,
  (currentProjectKey: string | null, projects: Record<string, Project>): Project | undefined => {
    if (!_.isEmpty(projects) && !_.isNil(currentProjectKey)) {
      return projects[currentProjectKey];
    }
    return undefined;
  },
);

export const deployToClassicInstancePatternsView: MemoizedSelector<AppState, DeployToOption[]> = createSelector(
  hostExpressionsView,
  (deploymentWizardDeployToOptions: DeployToOptions) => deploymentWizardDeployToOptions.patterns,
);

export const deployToClassicOptionsView = createSelector(hostExpressionsView, (deploymentWizardDeployToOptions: DeployToOptions) => {
  return _.concat([allHosts], deploymentWizardDeployToOptions.deployTargets, deploymentWizardDeployToOptions.groups);
});

export const isSelectedProjectVersionedView: MemoizedSelector<AppState, boolean> = createSelector(
  projectKeyView,
  allProjectsView,
  (currentProjectKey, projects): boolean => {
  if (!_.isEmpty(projects) && !_.isNil(currentProjectKey) && entryWithKeyExists(currentProjectKey, projects)) {
    const project = projects[currentProjectKey];
    return !!project.branch && !!project.repository;
  }
  return false;
});

export const sortedProjectReportsView: MemoizedSelector<AppState, PluginModel[]> = createSelector(
  projectReportsView,
  (reports: PluginModel[]) => _.sortBy(reports, report => report.name));

export const selectedInventoryView = createSelector(
  inventoryKeyView,
  allInventoriesView,
  (inventoryKey: string | null, allInventories: Dictionary<Inventory>) => {
    if (_.isNil(inventoryKey)) {
      return undefined;
    }
    return allInventories[inventoryKey];
  },
);

export  const selectedProjectForDeployment =  createSelector(
  allProjectsView,
  deploymentWizardSelectionProjectKeyView,
  (allProjects, selectedProjectKey) => {
    if (selectedProjectKey) {
      return allProjects[selectedProjectKey];
    }
    return null;
  },
);

export const selectedInventoryForDeployment = createSelector(
  allInventoriesView,
  deploymentWizardSelectionInventoryKeyView,
  (allInventories: Dictionary<Inventory>, selectedInventoryKey: string | undefined) => {
    if (!_.isNil(selectedInventoryKey)) {
      return allInventories[selectedInventoryKey];
    }
    return null;
  },
);

export const shouldDisplayTenantsView = createSelector(
  tenantsView,
  selectedTenantKeyView,
  (allTenants: Dictionary<Tenant>, selectedTenantKey: string | undefined) => {
    return _.isNil(selectedTenantKey) || TenantHelper.shouldShowTenants(_.values(allTenants), selectedTenantKey);
  },
);

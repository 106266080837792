import * as _ from 'lodash';
import { NavigationService } from '../../navbar/navigation.service';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { InventoryService } from '../../inventory/inventory.service';
import { AppState, Dictionary } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { NevisAdminAction } from '../../model/actions';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, race } from 'rxjs';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { allInventoriesListView, allInventoriesView, inventoryKeyView, selectedTenantKeyView } from '../../model/views';
import { TenantHelper } from '../helpers/tenant.helper';
import { RouteParamHelper } from '../helpers/route-param.helper';
import { SelectTenantKeyByUrl } from '../../model/shared/shared.actions';
import { localStorageInventoryKey } from '../constants/local-storage-keys.constants';
import { LocalStorageHelper } from '../helpers/local-storage.helper';
import { InventoryHelper } from '../../inventory/inventory.helper';
import { inventoryKeyParam } from '../../inventory/inventory-routing.constants';
import { Inventory } from '../../inventory/inventory.model';
import {
  InventoryActionTypes,
  LoadInventoriesIfMissing,
  LoadInventoryResources,
  LoadInventoryTenantScopedResources, LoadTenantConstants,
  SelectInventory,
} from '../../model/inventory';
import { Maybe, wrapPromiseInSetTimeOut } from '../utils/utils';
import { InventoryMainComponent } from '../../inventory/inventory-main/inventory-main.component';

export interface IInventoryGuardMixin {
  store$: Store<AppState>;
  actions$: Actions<NevisAdminAction<any>>;
  navigationService: NavigationService;
  inventoryService: InventoryService;
  modalNotificationService: ModalNotificationService;

  showCannotOpenInventoryWindow: (inventoryKey: string, additionalText?: string) => void;

  navigateToInventoryScreen: (inventoryKey?: string) => Promise<boolean>;

  canActivateInventoryScreen: (next: ActivatedRouteSnapshot) => Observable<boolean>;
}

/**
 * Makes sure that the inventories are loaded, reads the selected inventory from the route, then does either of the following:
 * - selects the inventory coming from the route, even switches tenants if needed
 * - selects a default project
 */
export class InventoryGuardMixin implements IInventoryGuardMixin {

  // the following fields are implemented in the class the mixin called from
  inventoryService: InventoryService;
  modalNotificationService: ModalNotificationService;
  navigationService: NavigationService;
  store$: Store<AppState>;
  actions$: Actions<NevisAdminAction<any>>;
  navigateToInventoryScreen: (inventoryKey?: string) => Promise<boolean>;
  showCannotOpenInventoryWindow: (inventoryKey: string, additionalText?: string) => void;

  canActivateInventoryScreen(next: ActivatedRouteSnapshot): Observable<boolean> {
    this.store$.dispatch(new LoadInventoriesIfMissing());

    return race(
      this.actions$.pipe(ofType(InventoryActionTypes.InventoriesAvailable), first(),),
      this.store$.select(allInventoriesListView).pipe(
        filter((allInventories: Maybe<Array<Inventory>>) => !_.isEmpty(allInventories)),
        first(),
      ),
    ).pipe(
      switchMap((): Observable<Record<string, Inventory>> => {
        return this.store$.select(allInventoriesView).pipe(
          filter((allInventories: Record<string, Inventory>): allInventories is Record<string, Inventory> => typeof allInventories === 'object'),
          first(),
        );
      }),
      withLatestFrom(this.store$.pipe(select(inventoryKeyView), first()), this.store$.pipe(select(selectedTenantKeyView), first())),
      map(([inventories, storedInventoryKey, selectedTenantKey]: [Dictionary<Inventory>, string | null, string]) =>
          this.canActivateBasedOnData(next, inventories, storedInventoryKey, selectedTenantKey)
      ),
    );
  }

  /**
   * Loads resources for the selected inventory if the inventory is specified.<br/>
   * Also checks if it is for the inventory editor, unless forced for any other component.
   */
  private loadResourcesForInventoryMain(selectedInventoryKey: Maybe<string>, nextRoute: ActivatedRouteSnapshot, forceForAnyComponent: boolean = false): void {
    if (
      !!selectedInventoryKey &&
      (forceForAnyComponent || nextRoute.component === InventoryMainComponent)
    ) {
      this.store$.dispatch(new LoadInventoryResources(selectedInventoryKey));
      this.store$.dispatch(new LoadInventoryTenantScopedResources());
      this.store$.dispatch(new LoadTenantConstants({usedIn: false}));
    }
  }

  private canActivateBasedOnData(next: ActivatedRouteSnapshot, inventories: Dictionary<Inventory>, storedInventoryKey: string | null, selectedTenantKey: string): boolean {
    const queryInventoryKey: string | undefined = RouteParamHelper.getPathParamFromRoute(next, inventoryKeyParam);
    // automatically switch tenant if tenant of queried inventory key different from current
    const tenantKeyOfQueriedInventory: string | null = _.isNil(queryInventoryKey) ? null : TenantHelper.getTenantFromKey(queryInventoryKey);
    if (!_.isNil(tenantKeyOfQueriedInventory) && tenantKeyOfQueriedInventory !== selectedTenantKey) {
      // angular has a bug with doubles navigation with hashchange trigger (described in https://github.com/angular/angular/issues/16710), therefore timeout works it around
      this.store$.dispatch(new SelectTenantKeyByUrl({
        tenantKey: tenantKeyOfQueriedInventory,
        afterSelect: () => wrapPromiseInSetTimeOut(() => this.navigateToInventoryScreen(<string>queryInventoryKey))
      }));
      return false;
    }
    if (_.isEmpty(inventories) && !_.isNil(queryInventoryKey)) {
      this.showCannotOpenInventoryWindow(queryInventoryKey);
      setTimeout(() => this.navigationService.navigateToInventories());
      return false;
    }
    if (_.isEmpty(inventories)) {
      return true;
    }
    // go to stored inventory or first from list if url doesn't contain inventory key
    if (_.isNil(queryInventoryKey)) {
      this.navigateToInitialInventory(inventories, storedInventoryKey, selectedTenantKey);
      this.loadResourcesForInventoryMain(storedInventoryKey, next);
      return false;
    }
    // navigate to inventory with key from url if such inventory exists
    if (!_.isNil(inventories[queryInventoryKey])) {
      if (storedInventoryKey !== queryInventoryKey) {
        this.store$.dispatch(new SelectInventory(queryInventoryKey));
        // the selected inventory is changing, forcing the reload even if not the editor is visible
        this.loadResourcesForInventoryMain(queryInventoryKey, next, true);
      }
      return true;
    } else {
      // otherwise from store or first from list
      this.showCannotOpenInventoryWindow(queryInventoryKey, 'Instead, the first available inventory is now selected.');
      this.navigateToInitialInventory(inventories, storedInventoryKey, selectedTenantKey);
      this.loadResourcesForInventoryMain(storedInventoryKey, next);
      return false;
    }
  }

  private navigateToInitialInventory(inventories: Dictionary<Inventory>, storedInventoryKey: string | null, selectedTenantKey: string): void {
    const localStorageKey = LocalStorageHelper.prefixKey(localStorageInventoryKey, selectedTenantKey);
    const inventoryKey = InventoryHelper.getFromStoreOrFirstAvailable(storedInventoryKey, inventories, localStorageKey);
    if (!_.isNil(inventoryKey)) {
      // angular has a bug with doubles navigation with hashchange trigger (described in https://github.com/angular/angular/issues/16710), therefore timeout works it around
      setTimeout(() => this.navigateToInventoryScreen(inventoryKey));
    }
  }

}

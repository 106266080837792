<mat-table multiTemplateDataRows matSort class='adm4-mat-table full-width bundle-management-table' [dataSource]='tableDataSource'>
  <!-- Name Column -->
  <ng-container [matColumnDef]="bundleNameColumnName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-icon class='expand-icon' [class.expanded]='isGroupExpanded(element)'>chevron_right</mat-icon>
      {{element.latestBundle.symbolicName}}
    </mat-cell>
  </ng-container>

  <!-- Version Column -->
  <ng-container [matColumnDef]="versionColumnName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Version </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span class='bundle-version-container'>
        <a [href]='element.latestBundle.bundleKey | bundleDownloadUrl' download [adm4StopEventPropagation]='["click"]' class='link-with-icon'><mat-icon>file_download</mat-icon></a>
        <span>{{element.latestBundle.version}}</span>
      </span>
    </mat-cell>
  </ng-container>

  <!-- Expanded Content Column - The previous version bundles row is made up of this one column that spans across all columns -->
  <ng-container [matColumnDef]="previousBundleVersionsColumnName">
    <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div [@previousVersionBundlesExpand] *ngIf='isGroupExpanded(element)' class='expanded-previous-versions full-width'>
        <adm4-bundle-table-component *ngIf='groupHasPreviousVersionBundles(element); else noPreviousVersions' [bundles]='element.previousVersionBundles'></adm4-bundle-table-component>
        <ng-template #noPreviousVersions>
          <div class='no-previous-version-bundles-message'>
            There are no previous versions uploaded for this pattern library.
          </div>
        </ng-template>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)='toggleBundleGroup(row)'></mat-row>
  <mat-row *matRowDef="let row; columns: expandableColumns;" class='expanded-previous-versions-row' [class.expanded]='isGroupExpanded(row)'></mat-row>
</mat-table>

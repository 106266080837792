import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { DeploymentHistoryItem } from '../../common/model/deployment-history.model';
import { InventoryContext } from '../inventory.context';
import { InventoryService } from '../inventory.service';
import { catchError, debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { RedeployDeployment } from '../../model/inventory';
import { deployProcessView } from '../../model/views';
import { DeploymentProcessModel, DeploymentProcessState } from '../../deployment-wizard/deployment-dialog/deployment-process.model';
import { InventoryDeploymentHistoryHelper } from './inventory-deployment-history.helper';

@Injectable()
export class InventoryDeploymentHistoryContext {
  inventoryDeploymentHistory$: Observable<DeploymentHistoryItem[] | never>;
  private historyChanged = new BehaviorSubject(true);
  constructor(
    private inventoryContext: InventoryContext,
    private inventoryService: InventoryService,
    private modalNotificationService: ModalNotificationService,
    private store$: Store<AppState>,
  ) {
    const deploymentProcessSuccess: Observable<string> = this.store$.pipe(
      select(deployProcessView),
      withLatestFrom(this.inventoryContext.inventoryKey$),
      filter(([deploymentProcess, inventoryKey]: [DeploymentProcessModel | null, string | null]) => {
        if (_.isNil(deploymentProcess) || _.isNil(inventoryKey)) {
          return false;
        }
        const deploymentInventoryKey = InventoryDeploymentHistoryHelper.getOriginKeyFromRevisionKey(deploymentProcess.inventoryKey);
        return deploymentInventoryKey === inventoryKey && deploymentProcess.state === DeploymentProcessState.Deployed;
      }),
      map(([_deploymentProcess, inventoryKey]: [DeploymentProcessModel, string]) => {
        return inventoryKey;
      })
    );

    const notNullInventoryKey$ = this.inventoryContext.inventoryKey$.pipe(filter((inventoryKey: string | null) => !_.isNil(inventoryKey)));
    this.inventoryDeploymentHistory$ = merge(
        notNullInventoryKey$.pipe(distinctUntilChanged()),
        this.historyChanged.pipe(withLatestFrom(notNullInventoryKey$), map(([, key]) => key)),
        deploymentProcessSuccess,
    ).pipe(
      debounceTime(300),
      switchMap((inventoryKey: string) => this.inventoryService.getInventoryDeploymentHistory(inventoryKey).pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          this.modalNotificationService.openErrorDialog({title: 'Cannot load deployment history', description: `An error occurred while loading deployment history of inventory ${inventoryKey}`});
          return of([]);
        })
      )),
      shareReplay(1)
    );
  }

  redeployDeployment(deploymentHistoryItem: DeploymentHistoryItem): void {
    this.store$.dispatch(new RedeployDeployment(deploymentHistoryItem));
  }

  getUpdatesOfTheInventory() {
    this.historyChanged.next(true);
  }
}

import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpgradeNotesModel } from './project-dependencies.model';
import * as _ from 'lodash';
import { ReportsHelper } from '../../reports/reports.helper';
import { UpgradeNote } from '../../resources/bundle-management/bundle.model';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';
import { Subject } from 'rxjs';


@Component({
  selector: 'adm4-upgrade-notes',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title'
                             [header]='"Important notes for this upgrade"'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog()">
      <div class='full-height-flex'>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <div class="dependencies-dialog-container full-height content-container">
              <div class="content-container" *ngFor="let note of notesContext">
                <h2 class='"modal-local-heading'>{{note.version}}</h2>
                <div class="content" [innerHTML]='formatHtml(note.html)'></div>
              </div>
            </div>
          </div>
        </div>
        <div mat-dialog-actions>
          <button class='admn4-button-text' (click)='closeDialog()'>Cancel</button>
          <button (click)="onUpdate()" class='admn4-button-ellipse-blue'>
            Accept & save
          </button>
        </div>
      </div>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['select-dependencies-dialog.component.scss', '../../common/styles/component-specific/modal-window.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpgradeNotesComponent implements OnInit, OnDestroy {
  notesContext: UpgradeNote[];
  private destroyed$: Subject<boolean> = new Subject();


  constructor(@Inject(MAT_DIALOG_DATA) private data: UpgradeNotesModel,
              private dialogRef: MatDialogRef<UpgradeNotesComponent>) {
    this.notesContext = data.notes;
  }

  formatHtml(htmlContent: string): string {
    return ReportsHelper.applyEdits(htmlContent, this.data.projectKey);
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  closeDialog(): void {
    this.dialogRef.close(UpgradeNotesComponent);
  }

  onCancel(): void {
    this.closeDialog();
  }

  onUpdate() {
    if (_.isFunction(this.data.onSaveCallback)) {
      this.data.onSaveCallback();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

export const localStoragePrefixSeparator = '-';
export const localStorageTenantKey = 'selectedTenantKey';
export const localStorageProjectKey = 'selectedProjectKey';
export const localStorageInventoryKey = 'selectedInventoryKey';
export const localStoragePatternListSortingKey = 'patternSelectedSortingOption';
export const localStoragePatternListGroupingKey = 'patternSelectedGroupingOption';
export const localStorageAlwaysSaveChanges = 'alwaysSaveChanges';
export const localStorageCopyPatternLastProject = 'copyPatternLastProject';
export const localStorageExpandedGroups = 'expandedGroups';
export const localStorageHelpTabIndex = 'helpTabIndex';
export const localCertificateColumns = 'certificateColumns';
export const localKubernetesCertificateColumns = 'kubernetesCertificateColumns';
export const localStorageApplicationMode = 'applicationMode';
export const localStorageScrollBufferSize = 'scrollBufferSize';
export const localStorageDeployToInstancePatterns = 'deployToInstancePatterns';
export const localStorageUpgradeLastNotified = 'upgradeLastNotified';
export const localStorageLogViewerWrapLines = 'logViewerWrapLines';
export const localStorageIgnoreUnsupportedBrowser = 'ignoreUnsupportedBrowser';

export const localStorageAnalyticsMuteNotifications = 'userpref.analytics.muteSubmitNotifications';

<as-split class="hide-as-split-gutter" direction='vertical' gutterSize='1' useTransition (dragEnd)='onResize($event)'>
  <as-split-area [order]='splitPaneConfig[changesetSplitAreaKey].order' [size]='splitPaneConfig[changesetSplitAreaKey].size' [minSize]='50'>
    <div class='full-height-flex'>
      <div class='changeset-header'>
        <adm4-validation-indicator [ngbTooltip]='popContentValidationErrors' placement='bottom-left' closeDelay="4000"
                                   [disableTooltip]="allProjectIssues.length < 1"

                                   [isDisplayed]='true'
                                   [isValid]='valid'
                                   [isError]="error"
                                   [isWarning]="warning">
        </adm4-validation-indicator>
        <div>Changed files</div>
        <ng-template #popContentValidationErrors>
          <adm4-project-issues-tooltip
                  [generalIssues]="generalIssues$ | async"
                  [patternIssueSeverityCount]="patternIssueNumberBySeverity$ | async"
                  (navigateToIssues)="navigateToIssues()"
          ></adm4-project-issues-tooltip>
        </ng-template>
      </div>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content publish-changeset-list-container'>
          <div class='changeset-list'>
            <adm4-publish-changeset-item *ngFor='let changesetItem of changesetItems'
                                         [changesetItem]='changesetItem'
                                         [isSelected]='isSelectedChangesetItem(changesetItem)'
                                         [projectKey]='projectKey'
                                         [currentUserName]='currentUserName'
                                         (click)='selectChangesetItem(changesetItem)'
            >
            </adm4-publish-changeset-item>
          </div>
        </div>
      </div>
    </div>
  </as-split-area>
  <as-split-area [order]='splitPaneConfig[commitMessageSplitAreaKey].order' [size]='splitPaneConfig[commitMessageSplitAreaKey].size' [minSize]='20'>
    <div class='full-height-flex commit-message-container'>
      <label for='comment'>Publish comment</label>
      <textarea id='comment' placeholder='Publish message' [ngModel]='initialCommitMessage' (ngModelChange)='onCommitMessageChange($event)'></textarea>
    </div>
  </as-split-area>
</as-split>

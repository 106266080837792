import { HelpConnectService } from './help-connect.service';
import * as _ from 'lodash';

export class ScrollToHelpMixin {
  helpConnectService: HelpConnectService | null;

  scrollToHelp(htmlClass: string): void {
    if (_.isNil(this.helpConnectService)) {
      return;
    }
    this.helpConnectService.setHtmlClass(htmlClass);
  }
}

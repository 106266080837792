import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeploymentService } from '../deployment-dialog/deployment.service';
import { Observable } from 'rxjs';
import { DeploymentStatusModel } from '../validate-deployment/generation-status.model';
import { PlanningOutputModel } from './deployment-preview/planning-output.model';
import { DeploymentWizardContext } from '../deployment-wizard.context';
import { Pattern } from '../../patterns/pattern.model';
import { DeploymentPreviewContext } from './deployment-preview.context';
import { first } from 'rxjs/operators';
import { InventorySchemaType } from '../../inventory/inventory.model';
import { DeploymentPreviewHelper } from './deployment-preview.helper';

@Component({
  selector: 'adm4-deployment-preview',
  templateUrl: './deployment-preview.component.html',
  styleUrls: ['./deployment-preview.component.scss'],
  providers: [DeploymentPreviewContext]
})
export class DeploymentPreviewComponent implements OnInit {
  @Input() deploymentId: string;
  @Input() boxShadowClass: string;
  @Output() deploy: EventEmitter<string> = new EventEmitter();
  @Output() abortPlanning: EventEmitter<string> = new EventEmitter();
  @Output() closeWizard: EventEmitter<void> = new EventEmitter();
  @Output() repeatPlanning: EventEmitter<string> = new EventEmitter();
  @Output() forceRedeployment: EventEmitter<string> = new EventEmitter();

  patterns$: Observable<Map<string, Pattern>>;

  selectedInventorySchemaType$: Observable<InventorySchemaType | undefined>;
  planningStatus$: Observable<DeploymentStatusModel | undefined>;
  isPlanningInProgress$: Observable<boolean>;
  isPlanningEntirelyFailed$: Observable<boolean>;
  planningOutput$: Observable<PlanningOutputModel[]>;
  hostsCount$: Observable<number>;
  canRepeatPlanning$: Observable<boolean>;
  redeploymentForced$: Observable<boolean>;
  shouldDisplayValidating$: Observable<boolean>;
  noDeploymentNeeded$: Observable<boolean>;
  canDeploy$: Observable<boolean>;
  canGoBack$: Observable<boolean>;
  canShowForceRedeploymentButton$: Observable<boolean>;
  canShowCloseButton$: Observable<boolean>;

  constructor(private deploymentService: DeploymentService,
              private deploymentWizardContext: DeploymentWizardContext,
              private deploymentPreviewContext: DeploymentPreviewContext) {
  }

  ngOnInit(): void {
    this.selectedInventorySchemaType$ = this.deploymentWizardContext.selectedInventorySchemaType$;
    this.planningStatus$ = this.deploymentPreviewContext.planningStatus$;
    this.patterns$ = this.deploymentWizardContext.patterns;
    this.isPlanningInProgress$ = this.deploymentPreviewContext.planningInProgress$;
    this.isPlanningEntirelyFailed$ = this.deploymentPreviewContext.isPlanningFailedEntirely$;
    this.planningOutput$ = this.deploymentPreviewContext.planningOutput$;
    this.hostsCount$ = this.deploymentPreviewContext.hostsCount$;
    this.canRepeatPlanning$ = this.deploymentPreviewContext.canRepeatPlanning$;
    this.redeploymentForced$ = this.deploymentPreviewContext.redeploymentForced$;
    this.shouldDisplayValidating$ = this.deploymentPreviewContext.shouldDisplayValidating$;
    this.noDeploymentNeeded$ = this.deploymentPreviewContext.noDeploymentNeeded$;
    this.canDeploy$ = this.deploymentPreviewContext.canDeploy$;
    this.canGoBack$ = this.deploymentPreviewContext.canGoBack$;
    this.canShowForceRedeploymentButton$ = this.deploymentPreviewContext.canShowForceRedeploymentButton$;
    this.canShowCloseButton$ = this.deploymentPreviewContext.canShowCloseButton$;
  }

  onRepeatPlanning(): void {
    this.repeatPlanning.emit(this.deploymentId);
  }

  triggerForceRedeployment(deploymentId: string): void {
    this.forceRedeployment.emit(deploymentId);
  }

  getValidatingText(inventorySchemaType: InventorySchemaType): string {
    return DeploymentPreviewHelper.getTextBasedOnSchemaType(inventorySchemaType, 'Generating change preview ...', 'Scanning hosts...');
  }

  getValidationDoneText(inventorySchemaType: InventorySchemaType): string {
    return DeploymentPreviewHelper.getTextBasedOnSchemaType(inventorySchemaType, 'Preview report', 'Scanning report');
  }

  getRepeatPlanningButtonText(inventorySchemaType: InventorySchemaType): string {
    return DeploymentPreviewHelper.getTextBasedOnSchemaType(inventorySchemaType, 'Repeat preview', 'Repeat scanning');
  }

  getDisabledNextStepToolTipLinkText(inventorySchemaType: InventorySchemaType): string {
    return DeploymentPreviewHelper.getTextBasedOnSchemaType(inventorySchemaType, 'repeat the preview', 'repeat the scanning');
  }

  triggerAbortPlanning(): void {
    this.deploymentPreviewContext.canBeAborted$.pipe(first()).subscribe((canBeAborted: boolean) => {
      if (canBeAborted) {
        this.abortPlanning.emit(this.deploymentId);
      }
    });
  }
}

import { Component, Input } from '@angular/core';
import { IssueModel, SourceType } from '../common/model/issue.model';
import { getHighestSeverity } from '../projects/project-issues/project-issues.helper';
import { IssueSeverityEnum } from '../common/model/issue-severity.enum';
import { PatternLink } from '../patterns/pattern.model';
import * as _ from 'lodash';

/**
 * The issues which have no property information are displayed here.
 */
@Component({
  selector: 'adm4-pattern-issue-list',
  template: `
    <ng-template #patternCopy>
     <span [ngbTooltip]='copiedBy' placement='top'> This pattern was copied from
      <a [routerLink]="['/projects', patternLinkInfo.sourceProjectKey, 'patterns', patternLinkInfo.sourcePatternId]">{{patternLinkInfo.sourceProjectKey | cropTenantFromKey}}</a>
       {{patternLinkInfo?.lastCopied | customDate}}.
     </span>
    </ng-template>
    <ng-template #copiedBy>
      Copied by {{patternLinkInfo?.author}}, {{dateOfCopy | date:'MMMM d, y'}} at {{dateOfCopy | date:'HH:mm'}}
    </ng-template>
    <div>
      <div class='pattern-issues-container' *ngIf="shouldDisplayIssueList">
        <div class="pattern-issues-title" [class.warning]='!containsOnlyInfoIssues'>
          <span *ngIf='containsOnlyInfoIssues; else warning' class="pattern-issues-title-text"><mat-icon>info</mat-icon><span>Please review the following points:</span></span>
          <ng-template #warning><span class="pattern-issues-title-text"><mat-icon>warning</mat-icon><span>Please fix the following issues:</span></span></ng-template>
        </div>
        <div *ngFor="let issue of issues" class="pattern-issue" [ngClass]="issue.severity | lowercase">
          <adm4-validation-issue [issue]="issue"></adm4-validation-issue>
        </div>
        <div *ngIf='isPatternCopied && containsOnlyInfoIssues' class="pattern-issue" [ngClass]="copyInfo.severity | lowercase">
          <adm4-validation-issue [issue]="copyInfo">
            <ng-container *ngTemplateOutlet="patternCopy"></ng-container>
          </adm4-validation-issue>
        </div>
      </div>
      <div class='pattern-issues-container' *ngIf='isPatternCopied && !containsOnlyInfoIssues'>
        <div class="pattern-issues-title">
          <span class="pattern-issues-title-text"><mat-icon>info</mat-icon>
              <ng-container *ngTemplateOutlet="patternCopy"></ng-container>
            </span>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  `,
  styleUrls: ['pattern-issue-list.scss']

})
export class PatternIssueListComponent {
  @Input() issues: IssueModel[];
  @Input() patternLinkInfo?: PatternLink;

  get shouldDisplayIssueList() {
    return this.issues && this.issues.length > 0;
  }

  get containsOnlyInfoIssues(): boolean {
    return getHighestSeverity(this.issues) === IssueSeverityEnum.INFO;
  }

  get isPatternCopied() {
    return !_.isNil(this.patternLinkInfo);
  }


  get dateOfCopy() {
    if (_.isNil(this.patternLinkInfo) || _.isNil(this.patternLinkInfo.lastCopied)) {
      return '';
    }
    return new Date(this.patternLinkInfo.lastCopied);
  }

  get copyInfo() {
    return {
      code: '',
      message: '',
      severity: 'INFO',
      target: [{
        sourceType: SourceType.PATTERN,
        value: ''
      }]
    };
  }
}

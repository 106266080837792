import { NevisAdminAction } from '../actions';
import { UserData } from './user.model';
import { LoginCompletePayload, LoginPayloadModel } from './login-payload.model';

export enum UserActionTypes {
  Login = '[User] Login user',
  LoginError = '[User] login error',
  LoginComplete = '[User] login complete, authenticated',
  GetUser = '[User] loading user data',
  GetUserError = '[User] Loading user data error',
  GetUserSuccess = '[User] Loading user data success',
  SignOut = '[User] Sign out',
  GetUserSuperAdminSuccess = '[User] Query super_admin permission success',
  ClearUserError = '[User] Clear user error',
}

export class Login implements NevisAdminAction<LoginPayloadModel> {
  readonly type = UserActionTypes.Login;

  constructor(public payload: LoginPayloadModel) {}

}

export class LoginComplete implements NevisAdminAction<LoginCompletePayload> {
  readonly type = UserActionTypes.LoginComplete;

  constructor(public payload: LoginCompletePayload) {}
}

export class LoginError implements NevisAdminAction {
  readonly type = UserActionTypes.LoginError;

  constructor(public payload: any = null) {}
}

export class GetUser implements NevisAdminAction {
  readonly type = UserActionTypes.GetUser;
}

export class GetUserSuccess implements NevisAdminAction<UserData> {
  readonly type = UserActionTypes.GetUserSuccess;

  constructor(public payload: UserData) {}
}

export class GetUserSuperAdminSuccess implements NevisAdminAction<boolean> {
  readonly type = UserActionTypes.GetUserSuperAdminSuccess;

  constructor(public payload: boolean) {}
}

export class GetUserError implements NevisAdminAction {
  readonly type = UserActionTypes.GetUserError;

  constructor(public payload: any) {}
}

export class SignOut implements NevisAdminAction<boolean> {
  readonly type = UserActionTypes.SignOut;

  /**
   * @param payload - isExpired
   */
  constructor(public payload = false) {}
}

export class ClearUserError implements NevisAdminAction<void> {
  readonly type = UserActionTypes.ClearUserError;
}

import * as _ from 'lodash';
import { CREATED_PROJECT_KEY_FORM_CONTROL_NAME, EXISTING_PROJECT_KEY_FORM_CONTROL_NAME, FileBasedImportProjectFormValue, PROJECT_KEY_MODE_FORM_CONTROL_NAME, PROJECT_ZIP_FORM_CONTROL_NAME, ProjectKeyMode } from './file-based-import-project-form.model';
import { ProjectFileImportPayload } from './project-file-import-payload.model';
import { OperationKey } from '../../model/permissions/permissions.model';
import { Project } from '../project.model';

export class FileBasedImportProjectHelper {
  static getProjectNameFromZip(projectZip: File | null): string | null {
    const projectZipNameRegExp: RegExp = new RegExp('^(project_)([a-zA-Z0-9\-]+)(_).');
    const projectNameFromZipMatch = _.isNil(projectZip) ? null : projectZip.name.match(projectZipNameRegExp);
    return _.isNil(projectNameFromZipMatch) ? null : projectNameFromZipMatch[2].toUpperCase();
  }

  /**
   * null checks for file and project key are covered by form validation, so this method should be called after form is valid
   */
  static createProjectFileImportPayload(projectFileImportFormValue: FileBasedImportProjectFormValue, tenantKey: string): ProjectFileImportPayload {
    return {
      projectKey: this.getProjectKeyFromFormValue(projectFileImportFormValue, tenantKey),
      projectFile: projectFileImportFormValue[PROJECT_ZIP_FORM_CONTROL_NAME] as File
    };
  }

  private static getProjectKeyFromFormValue(projectFileImportFormValue: FileBasedImportProjectFormValue, tenantKey: string): string {
    switch (projectFileImportFormValue[PROJECT_KEY_MODE_FORM_CONTROL_NAME]) {
      case ProjectKeyMode.New:
        return `${tenantKey}-${projectFileImportFormValue[CREATED_PROJECT_KEY_FORM_CONTROL_NAME]}`.toUpperCase();
      case ProjectKeyMode.Existing:
        return projectFileImportFormValue[EXISTING_PROJECT_KEY_FORM_CONTROL_NAME] as string;
      default:
        throw new Error(`Unspecified mode of project key: ${projectFileImportFormValue[PROJECT_KEY_MODE_FORM_CONTROL_NAME]}`);
    }
  }

  static canImportFromZip(canCreateProject: boolean, projects: Project[]): boolean {
    return canCreateProject || !_.isEmpty(projects) && projects.some(project => _.includes(project._userAuthorization, OperationKey.MODIFY_PROJECT));
  }
}

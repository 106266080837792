import { Inventory } from './inventory.model';
import { VersionControlData } from '../common/model/version-control-data.model';
import { TenantHelper } from '../common/helpers/tenant.helper';
import * as _ from 'lodash';


export interface IPredefinedInventoryFormDataMixin {
  predefinedInventoryData: Inventory | null;
  selectedTenantKey: string;
  initialInventoryKeyFormValue: () => string;
  predefinedVersionControlData: () => VersionControlData | null;
  initialPublishRequiredFormValue: () => boolean;
}

export class PredefinedInventoryFormDataMixin implements IPredefinedInventoryFormDataMixin {
  predefinedInventoryData: Inventory | null;
  selectedTenantKey: string;

  initialInventoryKeyFormValue(): string {
    return _.isNil(this.predefinedInventoryData) ? '' : TenantHelper.cropTenantFromKey(this.predefinedInventoryData.inventoryKey);
  }

  predefinedVersionControlData(): VersionControlData | null {
    return _.isNil(this.predefinedInventoryData) ? null : {
      repository: this.predefinedInventoryData.repository,
      branch: this.predefinedInventoryData.branch,
      path: this.predefinedInventoryData.path
    };
  }

  initialPublishRequiredFormValue(): boolean {
    return !_.isNil(this.predefinedInventoryData) && !_.isNil(this.predefinedInventoryData.repository) && this.predefinedInventoryData.publishRequired;
  }
}

import * as yaml from 'js-yaml';
import { KubernetesInventoryContent } from '../create-inventory/create-inventory-payload';

export class InventoryParserHelper {

  /**
   * Parses the string yaml inventory content to KubernetesInventoryContent object.
   *
   * @param inventoryContent the string yaml inventory content to be parsed.
   *
   * @return KubernetesInventoryContent object if the parsing was successful. Otherwise it returns null.
   */
  static parseInventoryContent(inventoryContent?: string): KubernetesInventoryContent | null {
    return yaml.safeLoad(inventoryContent);
  }
}

import { Component, forwardRef, OnInit } from '@angular/core';
import { WidgetComponent } from './widget.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { blurOnKeyDown } from '../common/utils/keypress.utils';
import * as _ from 'lodash';
import { PropertyWidgetContext } from './property-widget.context';
import { ParameterHelper } from './parameter.helper';

@Component({
  selector: 'adm4-date-time-property',
  template: `
    <div [formGroup]='group' class='input-field-container'>
        <input placeholder='yyyy-mm-dd HH:mm'
               class='admn4-text-input form-control textfield-property'
               [readonly]='readOnly'
               [formControlName]='widgetProperty.propertyKey'
               (keydown.enter)='onEnterPressed($event)'
               (focus)='onSelect($event, this)'
               (blur)='trimWhiteSpace($event)'
               [min]='min'
               [max]='max'
        />
    </div>
  `,
  styleUrls: ['widget.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatetimePropertyComponent),
    multi: true
  }]
})

export class DatetimePropertyComponent extends WidgetComponent implements OnInit {

  // The expected lower bound for the element’s value.
  public min: any;
  // The expected upper bound for the element’s value.
  public max: any;

  constructor(private pwc: PropertyWidgetContext) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.min = ParameterHelper.getSpecificParameterValue(ParameterHelper.MIN, this.parameters, this.propertyType);
    this.max = ParameterHelper.getSpecificParameterValue(ParameterHelper.MAX, this.parameters, this.propertyType);

  }

  override isMultiValue() {
    return true;
  }

  /**
   * Cannot call blurOnEnterDown directly from template
   * @param event
   */
  onEnterPressed(event): void {
    blurOnKeyDown(event);
  }

  trimWhiteSpace(event) {
    if (this.readOnly) {
      return;
    }
    this.group.controls[this.widgetProperty.propertyKey].setValue(_.trim(event.target.value));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';

import { marketplaceStoreFeature } from './marketplace.reducer';

import { MarketplacePageComponent } from './marketplace-page/marketplace-page.component';
import { CommonModules } from '../../common/common.module';
import { EffectsModule } from '@ngrx/effects';
import { MarketplaceEffects } from './marketplace.effects';
import { MarketplaceApiService } from './marketplace-api.service';
import { MarketplaceTileComponent } from './marketplace-page/marketplace-tile/marketplace-tile.component';

const components = [
  MarketplacePageComponent,
];

@NgModule({
  declarations: [
      ...components,
      MarketplaceTileComponent,
  ],
  imports: [
    CommonModule,
    CommonModules,
    StoreModule.forFeature(marketplaceStoreFeature),
    EffectsModule.forFeature([MarketplaceEffects]),
  ],
  exports: [
    MarketplacePageComponent,
  ],
  providers: [
    MarketplaceApiService,
  ],
})
export class MarketplaceModule { }

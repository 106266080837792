import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { IssueModel } from '../common/model/issue.model';

@Component({
  selector: 'adm4-property-issues',
  template: `
    <ng-template #validationRequiredMessage>
      <adm4-validation-message [isInfo]="true" message='Please save to validate your input.'></adm4-validation-message>
    </ng-template>
    <ng-template #listOfIssues>
      <adm4-validation-issue *ngFor='let issue of issues' [issue]='issue'></adm4-validation-issue>
    </ng-template>
    <ng-container *ngTemplateOutlet='currentIssuesTemplate'></ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyIssuesComponent {
  @Input() issues: IssueModel[];
  @Input() shouldShowValidationRequiredMessage: boolean;

  @ViewChild('validationRequiredMessage', {static: false}) validationRequiredMessageTemplate: TemplateRef<any>;
  @ViewChild('listOfIssues', {static: true}) listOfIssuesTemplate: TemplateRef<any>;

  get currentIssuesTemplate(): TemplateRef<any> {
    if (this.shouldShowValidationRequiredMessage) {
      return this.validationRequiredMessageTemplate;
    }
    return this.listOfIssuesTemplate;
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatternInstance } from '../../pattern-instance.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import { CreatePattern } from '../../../model/pattern';
import { CreatePatternData } from '../create-pattern-data.model';
import { Subject } from 'rxjs';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';

@Component({
  selector: 'adm4-create-pattern-container',
  template: `
      <adm4-modal-dialog-title [header]='header'
                               [showClose]='true'
                               [isFullHeightContent]='true'
                               (closeClicked)="dialogRef.close()">
          <adm4-create-pattern [plugins]='data.plugins'
                               [projectKey]='data.projectKey'
                               [patternName]='data?.patternName'
                               (closeClicked)='dialogRef.close()'
                               (addPattern)='onCreatePattern($event)'>
          </adm4-create-pattern>
      </adm4-modal-dialog-title>`,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
})
export class CreatePatternContainerComponent implements OnInit, OnDestroy {
  header = 'Add Pattern';

  private destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public data: CreatePatternData,
              public dialogRef: MatDialogRef<CreatePatternContainerComponent>,
              private store$: Store<AppState>) {
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  onCreatePattern(pattern: PatternInstance): void {
    this.dialogRef.afterClosed().subscribe(() => {
      const patternPayload = {
        pattern: pattern,
        projectKey: this.data.projectKey,
        onCreateSuccess: this.data.onCreateSuccess
      };
      this.store$.dispatch(new CreatePattern(patternPayload));
    });
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

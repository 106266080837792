import { Subscription } from 'rxjs';
import * as _ from 'lodash';

export class Unsubscriber {
  /**
   * safely unsubscribe from subscription
   * @param {Subscription | undefined} subscription
   */
  static unsubscribeFrom(subscription: Subscription | undefined): void {
    if (!_.isNil(subscription)) {
      subscription.unsubscribe();
    }
  }
}

import { NevisAdminAction } from '../actions';
import { Revision } from '../../version-control/revision/revision.model';
import { ProjectMeta } from '../../projects/project.model';

export enum VersionControlActionTypes {
  LoadRevision = '[Version Control] Load Revision',
  LoadRevisionSuccess = '[Version Control] Load Revision Success',
  LoadProjectMeta = '[Version Control] Load Project Meta',
  LoadProjectMetaSuccess = '[Version Control] Load Project Meta Success',
  clearProjectMeta = '[Version Control] Remove pattern from project meta',
}

export class LoadRevision implements NevisAdminAction<string> {
  readonly type = VersionControlActionTypes.LoadRevision;

  /**
   * @param payload - commitId
   */
  constructor(public payload: string) {}
}

export class LoadRevisionSuccess implements NevisAdminAction<Revision> {
  readonly type = VersionControlActionTypes.LoadRevisionSuccess;

  constructor(public payload: Revision) {}
}

export class LoadProjectMeta implements NevisAdminAction {
  readonly type = VersionControlActionTypes.LoadProjectMeta;
}

export class LoadProjectMetaSuccess implements NevisAdminAction<ProjectMeta> {
  readonly type = VersionControlActionTypes.LoadProjectMetaSuccess;

  constructor(public payload: ProjectMeta) {}
}

/**
 * When we update the pattern project meta has to be cleared, in order to avoid wrong warning that applies to the new pattern data, with old meta
 */
export class ClearProjectMeta implements NevisAdminAction<void> {
  readonly type = VersionControlActionTypes.clearProjectMeta;
}
import { MatTableDataSource } from '@angular/material/table';
import { PermissionsHelper } from './permissions.helper';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {OperationKey, Permission, PermissionAssignee, PermissionModificationPayload} from '../model/permissions/permissions.model';
import * as _ from 'lodash';
import { AssigneeNamePrefixEnum } from './model/assignee-name-prefix.enum';
import { OperationColumnData } from './model/operation-column-data.model';

@Component({
  selector: 'adm4-permissions-table',
  templateUrl: './permissions-table.component.html',
  styleUrls: ['./permissions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionsTableComponent implements OnChanges {
  @Input() permissionAssignees: PermissionAssignee[];
  @Input() editMode: boolean;
  @Input() filterText: string;
  @Input() target: string;
  @Input() operationColumns: OperationColumnData[];
  @Output() permissionCheck: EventEmitter<PermissionModificationPayload> = new EventEmitter();

  NO_PERMISSION_FOUND = 'No permission found';

  readonly nameColumn = 'name';
  readonly usernameColumn = 'username';

  displayedColumns: string[];
  dataSource: MatTableDataSource<PermissionAssignee>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['permissionAssignees'] && this.permissionAssignees) {
      this.resolveDisplayableColumns();
      this.createTable();
      this.setFilterPredicate();
    }
    if (changes['filterText'] && this.dataSource) {
      this.filterTable(this.filterText);
    }
  }

  filterTable(filterText: string): void {
    this.dataSource.filter = filterText;
  }

  createTable(): void {
    const sortedPermissionDataList = PermissionsHelper.sortPermissions(this.permissionAssignees);
    this.dataSource = new MatTableDataSource(sortedPermissionDataList);
  }

  resolveDisplayableColumns(): void {
    this.displayedColumns = [this.nameColumn, this.usernameColumn].concat(this.operationColumns.map(operationColumn => operationColumn.columnKey));
  }

  setFilterPredicate(): void {
    this.dataSource.filterPredicate =
      (data: PermissionAssignee, filter: string) => PermissionsHelper.shouldElementBeDisplayedForFilter(data, filter);
  }

  isGroup(permissionAssignee: PermissionAssignee): boolean {
    return PermissionsHelper.containsAssigneePrefix(permissionAssignee.assignee, AssigneeNamePrefixEnum.Group);
  }

  isDisabled(permissionAssignee: PermissionAssignee, operationKey: OperationKey): boolean {
    const existingPermission = _.find(permissionAssignee.permissions, permission => permission.operationKey === operationKey);
    return !_.isNil(existingPermission) && existingPermission.target !== this.target;
  }

  onSelectionChange(event: any, permissionAssignee: PermissionAssignee, operationKey: OperationKey): void {
    this.updatePermissionInTable(event.checked, permissionAssignee, operationKey);
    const savedPermissionAssignee = _.find(this.permissionAssignees, savedPermission => savedPermission.assignee === permissionAssignee.assignee);
    this.permissionCheck.emit({
      permissionAssignee: savedPermissionAssignee as PermissionAssignee,
      operationKey: operationKey,
      checked: event.checked
    });
  }

  updatePermissionInTable(chekced: boolean, permissionAssignee: PermissionAssignee, operationKey: OperationKey) {
    if (chekced) {
      const addedPermission: Permission = { target: this.target, operationKey: operationKey };
      permissionAssignee.permissions.push(addedPermission);
    } else {
      permissionAssignee.permissions = permissionAssignee.permissions.filter(permission => permission.operationKey !== operationKey);
    }
  }

  hasPermissionToOperation(permissionAssignee: PermissionAssignee, operationKey: OperationKey) {
    return permissionAssignee.permissions.map(permission => permission.operationKey).includes(operationKey);
  }

  get filterMatches(): boolean {
    return !_.isEmpty(this.dataSource.filteredData);
  }
}

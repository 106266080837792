<div class='full-height-flex help-wrapper-container'>
  <adm4-column-header styleClass="light-header">
    Issues help
    <adm4-toggle-collapse-button side='right'
                                 [isCollapsed]='false'
                                 (click)='setCollapse(true)'
    ></adm4-toggle-collapse-button>
  </adm4-column-header>
    <div adm4Scrollable class='full-height help-container scrollable-container'>
      <div class='help-heading'>
        <h1 class='main-title'>Help</h1>
      </div>
      <div class='help-content'>
        <p>This page shows all current validation issues in your project. Issues can be caused by:</p>
        <ul>
          <li>required fields that are not filled in</li>
          <li>invalid values for pattern fields or variables</li>
          <li>inconsistent values or conflicting values</li>
          <li>problems with patterns scripts or project dependencies</li>
          <li>and more.</li>
        </ul>
        <p>Each issue has a severity level:</p>
        <ul>
          <li>ERROR: something in the project configuration is wrong and needs to be fixed. Deployment is not possible.
          </li>
          <li>WARNING: a potential problem was detected in the project configuration and should be fixed. Deployment is
            still possible.
          </li>
          <li>INFO: provides information about the project configuration that may be of interest, but no action is
            required.
          </li>
        </ul>
        <p>To fix an issue:</p>
        <ul>
          <li>navigate to the source of the issue
            <ul>
              <li>for pattern-level issues, click on the pattern name</li>
              <li>for general (project-level) issues, the issue description tells you where to fix the problem</li>
            </ul>
          </li>
          <li>at the source, change your configuration to fix the issue</li>
        </ul>
      </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, ComponentRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { MetaInfo } from '../../version-control/meta-info.model';
import { InventoryChangesetItem } from './inventory-changeset-item.model';
import { UserStateService } from '../../common/services/user/user.state.service';
import { DiffViewComponent } from '../../common/model/publish-changes/diff-view-component.model';
import { PublishInventoryContext } from './publish-inventory.context';
import * as _ from 'lodash';
import { Dictionary } from '../../model/reducer';
import { SplitPaneConfig } from '../../common/model/split-pane-config.model';
import { IOutputData } from 'angular-split/lib/interface';
import { ResizeHelper } from '../../common/helpers/resize.helper';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../common/mixins/split.mixin';

@Component({
  selector: 'adm4-publish-inventory',
  templateUrl: './publish-inventory.component.html',
  styleUrls: ['./publish-inventory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Mixin([SplitMixin])
export class PublishInventoryComponent implements ISplitMixin, OnInit, OnChanges {
  @Input() changesetItems: InventoryChangesetItem<MetaInfo, any>[];
  @Input() initialCommitMessage = '';
  @Output() messageChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() publish: EventEmitter<string> = new EventEmitter();
  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @ViewChild('diffViewContainer', {read: ViewContainerRef, static: false}) diffViewContainer: ViewContainerRef;

  commitMessage = this.initialCommitMessage;
  currentUsername: string | null;
  selectedChangesetItem?: InventoryChangesetItem<MetaInfo, any>;

  readonly listSplitAreaKey = 'list';
  readonly diffViewSplitAreaKey = 'diff-view';
  readonly splitPaneConfigLocalStorageKey = 'publish-inventory-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.listSplitAreaKey]: {order: 0, size: 30},
    [this.diffViewSplitAreaKey]: {order: 1, size: 70}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  constructor(private inventoryPublishContext: PublishInventoryContext,
              private userStateService: UserStateService,
  ) {
    this.currentUsername = this.userStateService.username;
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
  }

  ngOnInit(): void {
    this.inventoryPublishContext.preloadDiffs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialCommitMessage'] && _.isEmpty(this.commitMessage)) {
      this.commitMessage = this.initialCommitMessage;
    }
  }

  onChangesetItemSelected(changesetItem: InventoryChangesetItem<MetaInfo, any>): void {
    this.selectedChangesetItem = changesetItem;
    this.generateDynamicDiffView(changesetItem);
  }

  private generateDynamicDiffView(changesetItem: InventoryChangesetItem<MetaInfo, any>): void {
    this.diffViewContainer.clear();
    const componentRef: ComponentRef<DiffViewComponent<InventoryChangesetItem<MetaInfo, any>>>
        = this.diffViewContainer.createComponent(changesetItem?.diffComponent);
    componentRef.instance.changesetItem = changesetItem;
  }

  onCommitMessageChange(commitMessage: string): void {
    this.commitMessage = commitMessage;
    const commitMessageChanged: boolean = this.commitMessage !== this.initialCommitMessage;
    this.messageChanged.emit(commitMessageChanged);
  }

  triggerPublish(): void {
    this.publish.emit(this.commitMessage);
  }

  triggerCancel(): void {
    this.cancel.emit();
  }

}

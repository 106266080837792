import { NevisAdminAction } from '../actions';

export enum RequestActionTypes {
  AddSlowPendingRequest = '[Request] Add 1 to slow requests pending',
  RemoveSlowPendingRequest = '[Request] Remove 1 from slow requests pending'
}

export class AddSlowPendingRequest implements NevisAdminAction {
  readonly type = RequestActionTypes.AddSlowPendingRequest;
}

export class RemoveSlowPendingRequest implements NevisAdminAction {
  readonly type = RequestActionTypes.RemoveSlowPendingRequest;
}

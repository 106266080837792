<div class='import-dialog'>
  <form [formGroup]="form" name="form" (ngSubmit)='submitImportInventory()' cdkFocusInitial>
    <div class="content-container create-item-container">
      <adm4-version-control-form [predefinedVersionControl]="predefinedVersionControlData()"
                                 [pathRequired]='true'
                                 (formReady)='addVersionControlForm($event)'></adm4-version-control-form>
      <div class="form-group">
        <label class='input-label'>Inventory key*</label>
        <div class="item-key-container">
          <div class='tenant-key-wrapper' *ngIf='displayTenant'>
            <span>{{selectedTenantKey}} - </span>
            <div class='validation-message-container'></div>
          </div>
          <div class='item-key-input-wrapper'>
            <input class='admn4-text-input form-control'
                   [class.errored]="form.controls[INVENTORY_KEY_FORM_CONTROL_NAME].invalid"
                   [formControlName]='INVENTORY_KEY_FORM_CONTROL_NAME'
                   name="inventoryKey"
                   required/>
            <div class='validation-message-container'>
              <adm4-validation-message *ngIf='shouldShowInventoryKeyErrorMessage("pattern")' [isError]='true'
                                       [message]='ERROR_INVALID_INVENTORY_KEY'></adm4-validation-message>
              <adm4-validation-message *ngIf='shouldShowInventoryKeyErrorMessage("required")' [isError]='true'
                                       [message]='ERROR_INVENTORY_KEY_REQUIRED'></adm4-validation-message>
              <adm4-validation-message *ngIf='shouldShowInventoryKeyErrorMessage("inventoryKeyExists")' [isError]='true'
                                       [message]='inventoryForTenantExistsError'></adm4-validation-message>
            </div>
          </div>
        </div>
      </div>
      <div class='form-group'>
        <label class='input-label checkbox-label'>
          <mat-checkbox [formControlName]='PUBLISH_REQUIRED_FORM_CONTROL_NAME'></mat-checkbox>
          <span>Require a published project and inventory for deployment</span>
        </label>
      </div>
    </div>
    <div mat-dialog-actions>
      <button type='button' class='admn4-button-text'
              (click)='cancelClicked.emit()'>
        Cancel
      </button>
      <button class='admn4-button-ellipse-blue'
              [disabled]='!canSave'>
        Import
      </button>
    </div>
  </form>
</div>

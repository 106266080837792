import { Inventory, InventorySchemaType } from '../inventory.model';
import { DEFAULT_INVENTORY_TYPE } from '../../common/constants/app.constants';
import { CreateInventoryPayload, KubernetesInventoryContent } from './create-inventory-payload';
import { DEPLOYMENT_REPOSITORY_FORM_CONTROL_NAME, K8S_CLUSTER_NAMESPACE_FORM_CONTROL_NAME, K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME, K8S_CLUSTER_URL_FORM_CONTROL_NAME } from './kubernetes-inventory-creation-form/kubernetes-inventory.form';
import { VersionControlHelper } from '../../common/helpers/version-control.helper';
import * as _ from 'lodash';
import { BRANCH_FORM_CONTROL_NAME, REPOSITORY_FORM_CONTROL_NAME } from '../../common/components/version-control-form/version-control.form';
import { Branch } from '../../version-control/branch.model';
import { CreateInventoryForm, K8S_CONTROL_FORM_CONTROL_NAME, PROJECT_KEY_FORM_CONTROL_NAME } from '../inventory-creation.model';

export class CreateInventoryHelper {
  static convertFormValueToCreateInventoryPayload(formValue: CreateInventoryForm, tenantKey: string): CreateInventoryPayload {
    const inventory: Inventory = formValue.isVersioned
      ? this.convertFormValueToVersionedInventory(formValue, tenantKey)
      : this.convertFromValueToInventory(formValue, tenantKey);
    let kubernetesInventoryContent: KubernetesInventoryContent | undefined;
    if (formValue.isKubernetes) {
      kubernetesInventoryContent = this.createKubernetesInventoryContent(formValue);
    }
    return {
      inventory: inventory,
      includeSample: formValue.includeSample,
      projectKeyForSampleGeneration: formValue[PROJECT_KEY_FORM_CONTROL_NAME],
      kubernetesInventoryContent: kubernetesInventoryContent
    };
  }

  static createKubernetesInventoryContent(formValue: CreateInventoryForm): KubernetesInventoryContent {
    const kubernetesInventoryContent = _.get(formValue, K8S_CONTROL_FORM_CONTROL_NAME);
    const deploymentRepository = _.get(kubernetesInventoryContent, DEPLOYMENT_REPOSITORY_FORM_CONTROL_NAME);
    const branch: Branch | undefined | null = _.get(deploymentRepository, BRANCH_FORM_CONTROL_NAME);

    return {
      'deployment-repository': {
        url: _.get(deploymentRepository, REPOSITORY_FORM_CONTROL_NAME),
        branch: !!branch ? branch.name : '',
      },
      'kubernetes-cluster': {
        url: _.get(kubernetesInventoryContent, K8S_CLUSTER_URL_FORM_CONTROL_NAME),
        namespace: _.get(kubernetesInventoryContent, K8S_CLUSTER_NAMESPACE_FORM_CONTROL_NAME),
        token: _.get(kubernetesInventoryContent, K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME),
      }
    };
  }

  static convertFromValueToInventory(formValue: CreateInventoryForm, tenantKey: string): Inventory {
    const inventoryKey = `${tenantKey}-${formValue.inventoryKey}`.toUpperCase();
    const schemaType = formValue.isKubernetes ? InventorySchemaType.KUBERNETES : InventorySchemaType.CLASSIC;

    return {
      inventoryKey: inventoryKey,
      tenantKey: tenantKey,
      type: DEFAULT_INVENTORY_TYPE,
      schemaType: schemaType,
      publishRequired: false
    };
  }

  static convertFormValueToVersionedInventory(formValue: CreateInventoryForm, tenantKey: string): Inventory {
    const inventoryKey = `${tenantKey}-${formValue.inventoryKey}`.toUpperCase();
    const repository = formValue.versionControl.repository;
    const branchName = VersionControlHelper.getBranchName(formValue.versionControl.branch);
    const schemaType = formValue.isKubernetes ? InventorySchemaType.KUBERNETES : InventorySchemaType.CLASSIC;

    return {
      inventoryKey: inventoryKey,
      tenantKey: tenantKey,
      type: DEFAULT_INVENTORY_TYPE,
      schemaType: schemaType,
      publishRequired: formValue.publishRequired,
      repository: repository,
      branch: branchName,
      path: VersionControlHelper.getValidPath(formValue.versionControl.path)
    };
  }
}

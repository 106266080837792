import { Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';

/**
 * This directive can be used on any textarea which height is wanted to be resized depending on its content.
 */
@Directive({
  selector: 'textarea[adm4Autosize]'
})
export class AutosizeDirective implements OnChanges {
  @Input() data: string;

  @Output() sizeChanged = new EventEmitter<number>();
  private previousHeight: number;

  constructor(public element: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      setTimeout(() => this.adjust(changes['data']));
    }
  }

  adjust(change: SimpleChange) {
    this.element.nativeElement.style.overflow = 'hidden';
    this.element.nativeElement.style.height = '1px';
    this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight + 'px';
    if (this.element.nativeElement.scrollHeight !== this.previousHeight) {
      this.previousHeight = this.element.nativeElement.scrollHeight;
      if (!change.firstChange) {
        this.sizeChanged.emit(this.previousHeight);
      }
    }
  }
}

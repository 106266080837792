export class DeploymentItemStatusModel {
  public status: Status;
  public host: string;
  public label: string;
  public progress?: number;
  public currentTask?: string;
  public log?: string;
  public errorMessage?: string;
}

export enum Status {
  Running = 'RUNNING',
  Pending = 'PENDING',
  Done = 'DONE',
  Failed = 'FAILED',
  CANCELLED = 'CANCELLED',
}

import { LocalChangesConfirmationModalConfig, NotificationMessage, NotificationModelWithModalConfig } from './notification.model';
import { ConfirmationDialogComponent } from '../modal-dialog/confirmation-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationType } from './notification-type.enum';
import { ModalNotificationService } from './modal-notification.service';
import { LocalChangesConfirmationDialogComponent } from '../modal-dialog/local-changes-confirmation-dialog/local-changes-confirmation-dialog.component';

@Injectable()
export class LocalChangesModalNotificationService {

  constructor(private dialog: MatDialog, private modalNotificationService: ModalNotificationService) {}

  openConfirmDialog(notificationMessage: NotificationMessage, confirmationConfig: LocalChangesConfirmationModalConfig): MatDialogRef<ConfirmationDialogComponent, boolean | undefined> {
    const notification: NotificationModelWithModalConfig<LocalChangesConfirmationModalConfig> = {
      type: NotificationType.DIALOG_CONFIRMATION,
      notificationMessage: notificationMessage,
      modalConfig: confirmationConfig
    };
    return this.modalNotificationService.openDefaultDialog<LocalChangesConfirmationDialogComponent, NotificationModelWithModalConfig<LocalChangesConfirmationModalConfig>>(LocalChangesConfirmationDialogComponent, notification);
  }

  openInfoDialog(notificationMessage: NotificationMessage, confirmationConfig: LocalChangesConfirmationModalConfig): MatDialogRef<ConfirmationDialogComponent, boolean | undefined> {
    const notification: NotificationModelWithModalConfig<LocalChangesConfirmationModalConfig> = {
      type: NotificationType.DIALOG_INFO,
      notificationMessage: notificationMessage,
      modalConfig: confirmationConfig
    };
    return this.modalNotificationService.openDefaultDialog<LocalChangesConfirmationDialogComponent, NotificationModelWithModalConfig<LocalChangesConfirmationModalConfig>>(LocalChangesConfirmationDialogComponent, notification);
  }
}

import * as _ from 'lodash';
import { PatternType } from '../plugins/pattern-type.model';
import { Inventory } from '../inventory/inventory.model';
import { Project } from '../projects/project.model';
import { DeployToOption } from '../deployment-wizard/deployment-selection/deploy-to-option.model';
import { IssueModel, IssueSeverity } from '../common/model/issue.model';
import { isInfoIssue, isUnusedPattern } from '../projects/project-issues/project-issues.helper';
import { Maybe } from '../common/utils/utils';

export class PatternMasterListHelper {

  static initCategoriesSelectedArray(categories: string[]): Record<string, boolean> {
    const categoriesSelected: Record<string, boolean> = {};
    categories.forEach(category => categoriesSelected[category] = false);
    return categoriesSelected;
  }

  static getFilterRegexpByWord(filterText: string) {
    const anies = _.join(_.map(_.split(filterText, ' '), (word: string) => {
      return _.escapeRegExp(word) + '.*';
    }), '');

    return new RegExp(anies, 'gi');
  }

  static getWildCardRegexp(text: string): RegExp {
    let regexEscapedWithoutWildCard = text.replace(/[.+^${}()|[\]\\]/g, '\\$&'); //
    return new RegExp(`^${regexEscapedWithoutWildCard.replace(/\*/g, '.*').replace(/\?/g, '.')}$`, 'i');
  }

  static getWildCardFilteredItemList<T extends Project | Inventory | DeployToOption>(items: T[], filterText: string, itemKeyFormatFn: (T) => string): T[] {
    return _.filter(items, item => {
      const formattedItemKey = itemKeyFormatFn(item);
      const containsWildCardChar = _.includes(filterText, '*');
      const regExp = containsWildCardChar ? PatternMasterListHelper.getWildCardRegexp(filterText) : this.getFilterRegexpByWord(filterText);
      return regExp.test(formattedItemKey); // .toLowerCase());
    });
  }

  static getAllCategoriesFromPatternTypes(patternTypes: PatternType[] = []): string[] {
    const resultCategories: string[] = [];
    // filters out undefined or null values
    patternTypes.filter(type => type !== undefined)
      .forEach((patternType: PatternType) => {
        const categoriesNotYetAdded = _.filter(patternType.categories, category => resultCategories.indexOf(category) === -1);
        resultCategories.push(...categoriesNotYetAdded);
      });
    return resultCategories.sort((a, b) => a.localeCompare(b));
  }

  static patternContainsAllSelectedCategories(patternCategories: string[], categoriesSelected: Record<string, boolean>): boolean {
    const categoryKeys: string[] = Object.keys(categoriesSelected);
    return categoryKeys.filter((key: string) => categoriesSelected[key]).every((key: string) => {
      const foundCategory = patternCategories.find((category: string) => category === key);
      return !!foundCategory;
    });
  }

  /**
   * Checks whether the selected severity filters match any of the issues of a pattern,
   * ie. if the pattern should be shown given the selected filters.
   * @param patternIssues The issues of the pattern being checked
   * @param issueSeverityFiltersSelected The map of selected issue severity filters
   */
  static patternContainsSelectedIssue(patternIssues: IssueModel[], issueSeverityFiltersSelected: Map<IssueSeverity, boolean>): boolean {
    const selectedIssueSeverityFilters: IssueSeverity[] = Array.from(issueSeverityFiltersSelected.keys())
        .filter((key: IssueSeverity) => issueSeverityFiltersSelected.get(key));

    return selectedIssueSeverityFilters.some((filteredIssueSeverity: IssueSeverity): boolean => {
      const isFilteredForUnusedSeverity = _.isEqual(filteredIssueSeverity, IssueSeverity.UNUSED);
      const isFilteredForInfoSeverity = _.isEqual(filteredIssueSeverity, IssueSeverity.INTO);
      return patternIssues.some((issue: IssueModel): boolean => {
        // info is an exception, because unused pattern issues are INFO level + issue.code
        if (isInfoIssue(issue)) {
          return (isFilteredForUnusedSeverity && isUnusedPattern(issue)) || (isFilteredForInfoSeverity && !isUnusedPattern(issue));
        }
        return _.isEqual(issue.severity, filteredIssueSeverity);
      });
    });
  }

  static hasCategoriesSelected(selectedCategoriesMap: Record<string, boolean>): boolean {
    const selectedCategories = Object.values(selectedCategoriesMap).filter((value: boolean) => value);
    return !_.isEmpty(selectedCategories);
  }

  static hasIssueFilterSelected(selectedIssueFilterMap: Map<IssueSeverity, boolean>): boolean {
    const selectedIssueFilters = Array.from(selectedIssueFilterMap.values()).filter((value: boolean) => value);
    return !_.isEmpty(selectedIssueFilters);
  }

  /**
   * When first loading, new map of category selection will be created.<br/>
   * When reloading patterns it can happen that new patterns were added or old ones were removed, therefore categories list can also change,
   * and we delete no longer available categories and add new ones.
   * The categories which stay will keep their selected status.
   */
  static fillSelectedCategories(categoriesSelected: Maybe<Record<string, boolean>>, availableCategories: string[]): Record<string, boolean> {
    if (_.isNil(categoriesSelected)) {
      return PatternMasterListHelper.initCategoriesSelectedArray(availableCategories);
    }
    const cleanedUpSelection: Record<string, boolean> = Object.entries(categoriesSelected)
        .filter(([existingCategory, _isSelected]: [string, boolean]) => availableCategories.find(c => c === existingCategory))
        .reduce(
          (acc: Record<string, boolean>, [currentKey, currentSelected]: [string, boolean]): Record<string, boolean> => {
            acc[currentKey] = currentSelected;
            return acc;
          },
          {}
        );
    availableCategories.forEach((category: string) => {
      if (cleanedUpSelection[category] === undefined) {
        cleanedUpSelection[category] = false;
      }
    });
    return cleanedUpSelection;
  }
}

import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { ReportGenerationStatusModel, ReportGenerationModel, ReportGenerationStatus } from './reports.model';
import { poll } from '../rx.utils';
import { POLL_INTERVAL } from '../common/constants/app.constants';

@Injectable()
export class ReportsService extends BaseService {

  generateReport(genModel: ReportGenerationModel): Observable<ReportGenerationStatusModel> {
    const url = `/reports`;
    return this.httpPost(url, genModel);
  }

  getReportStatus(reportId: string): Observable<ReportGenerationStatusModel> {
    const url = `/reports/${reportId}`;
    return this.httpGet(url);
  }

  getReportOutput(reportId: string): Observable<string | null> {
    const url = `/reports/${reportId}/output`;
    return this.httpGetText(url);
  }

  pollGenerationStatus(reportId: string): Observable<ReportGenerationStatusModel> {
    return this.pollStatus<ReportGenerationStatusModel>(() => this.getReportStatus(reportId));
  }

  private pollStatus<T extends ReportGenerationStatusModel>(functionToPoll: () => Observable<T>): Observable<T> {
    const pollCondition = (result: T) => result.overallStatus !== ReportGenerationStatus.DONE && result.overallStatus !== ReportGenerationStatus.FAILED && result.overallStatus !== ReportGenerationStatus.NOTHING_TO_DO;
    return poll(POLL_INTERVAL, functionToPoll, pollCondition);
  }
}

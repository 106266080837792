import { VariableModel } from '../variable.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adm4-variable-menu',
  template: `
    <button type="button" class="imgContainer" [matMenuTriggerFor]="menu" aria-label="menu">
      <i class="material-icons">{{icon}}</i>
    </button>
    <mat-menu class="overflow-menu" #menu="matMenu" [xPosition]="'before'">
      <button type="button" mat-menu-item (click)="renameVar()">
        <mat-icon>mode_edit</mat-icon>
        <span class="menu-item-text">Rename Variable</span>
      </button>
      <button type="button" mat-menu-item (click)="deleteVar()">
        <mat-icon>delete_forever</mat-icon>
        <span class="menu-item-text">Delete Variable</span>
      </button>
    </mat-menu>`,
  styleUrls: ['../../common/styles/component-specific/overflow-menu.scss']
})
export class VariableMenuComponent {
  @Input() variable: VariableModel;
  @Input() icon;
  @Output() rename = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();

  renameVar() {
    this.rename.emit();
  }

  deleteVar() {
    this.delete.emit();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adm4-issue-list',
  template: `
    <div class='full-height-flex'>
      <adm4-column-header styleClass="nevis-green-header">
        <div class='issues-header-wrapper'>
          <span class='issues-header'>Issues</span>
          <adm4-toggle-collapse-button *ngIf='isHelpCollapsed'
                                       side='right'
                                       buttonClass='admn4-button-icon-white'
                                       [isCollapsed]='isHelpCollapsed'
                                       (click)='onHelpExpanderClick()'
          ></adm4-toggle-collapse-button>
        </div>
      </adm4-column-header>
      <div class='remaining-space-flex-content-wrapper'>
        <div class="issue-list-container remaining-space-flex-content">
          <adm4-issue-group-list></adm4-issue-group-list>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./issue-list.component.scss']
})
export class IssueListComponent {
  @Input() isHelpCollapsed: boolean;
  @Output() expandHelp: EventEmitter<undefined> = new EventEmitter();

  onHelpExpanderClick(): void {
    this.expandHelp.emit();
  }
}

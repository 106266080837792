import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChildren, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNode } from '@angular/material/tree';

import { MonoTypeOperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

import * as _ from 'lodash';

import { PatternListData } from '../pattern-list-data.model';
import { HierarchyTreeNode, PatternHierarchyData } from './pattern-hierarchy-tree.model';
import { NavigationService } from '../../navbar/navigation.service';
import { PatternHierarchyContext } from './pattern-hierarchy-context';
import { IssueSeverityEnum } from '../../common/model/issue-severity.enum';
import { getHighestSeverity } from '../../projects/project-issues/project-issues.helper';
import { ScrollService } from '../../common/services/scroll/scroll.service';
import { PatternHierarchyHelper } from './pattern-hierarchy-helper';
import { CustomTreeControl } from '../../common/tree-control/custom-tree.control';
import { Maybe } from '../../common/utils/utils';

@Component({
  selector: 'adm4-pattern-hierarchy-tree',
  template: `
    <div class="hierarchy-tree-actions">
        <button type="button" mat-button class="toggle-expand" (click)="expandAll()">
            <i class="fa fa-plus-square-o" aria-hidden="true"></i>
            <span>Expand all</span>
        </button>
        <button type="button" mat-button class="toggle-collapse" (click)="collapseAll()">
            <i class="fa fa-minus-square-o" aria-hidden="true"></i>
            <span>Collapse all</span>
        </button>
    </div>
    <div class="tree-scroll-wrapper scroll-shadows">
    <mat-tree *ngIf='shouldRenderAuthTree' #tree class="hierarchy-tree" [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node class="child-node" *matTreeNodeDef="let node;" [class.selected]='isPatternSelected(node)'
            [attr.id]='node?.pattern?.pattern?.patternId' [attr.data-localid]='node?.localId'>
        <ng-container *ngIf='isNodeContainsPattern(node)'>
        <span class="space-holder d-flex">
            <li *ngFor="let spacer of recNode(undefined, dataSource.data, -1, treeControl.dataNodes?.indexOf(node))[2]"
                [ngClass]="{'node-lined': spacer, 'node-empty': !spacer}"></li>
        </span>
        <div class="line-crossing" [class.last-single-child]='isLastChildNode(node)' disabled></div>
        <div class="node horizontal-line">
          <i *ngIf='node.expandable' matTreeNodeToggle (click)='toggleExpand(node)' class='fa expand-icon' [ngClass]="treeControl.isExpanded(node) ? 'fa-minus-square-o' : 'fa-plus-square-o'"></i>
          <adm4-validation-indicator [ngbTooltip]='popContentPatternErrors' [disableTooltip]='!node.shouldDisplayIssue' placement='top'
                                     [isDisplayed]='true'
                                     [isError]="node.hasErrorIssue"
                                     [isWarning]="node.hasWarnIssue"
                                     [isNeutral]="node.hasNeutralIssue"
                                     [diameter]='10'>
          </adm4-validation-indicator>
          <ng-template #popContentPatternErrors>
            <adm4-pattern-error-list-hover
                    [issues]='node.pattern.issues'
                    [projectKey]='projectKey'></adm4-pattern-error-list-hover>
          </ng-template>
        </div>
        <adm4-pattern-hierarchy-tree-item-detail [node]="node"
                                                 (navigateToPatternById)="navigateToPattern($event)"
                                                 (navigateTreeNode)="navigateToNodeLocal($event)"
        ></adm4-pattern-hierarchy-tree-item-detail>
        </ng-container>
      </mat-tree-node>
      <mat-tree-node class="parent-node" *matTreeNodeDef="let node; when: isGroupNode;" [class.selected]='isPatternSelected(node)'
                     [attr.id]='node?.pattern?.pattern?.patternId' [attr.data-localid]='node?.localId'>
        <ng-container *ngIf='isNodeContainsPattern(node)'>
        <span class="space-holder d-flex">
            <li *ngFor="let r of recNode(undefined, dataSource.data, -1, treeControl.dataNodes?.indexOf(node))[2]"
                [ngClass]="{'node-lined': r, 'node-empty': !r}"></li>
        </span>
        <div class="line-crossing">
          <i *ngIf='!node.isTopLevel && node.expandable' matTreeNodeToggle (click)='toggleExpand(node)' class='fa expand-icon' [ngClass]="treeControl.isExpanded(node) ? 'fa-minus-square-o' : 'fa-plus-square-o'"></i>
        </div>
        <div class="node horizontal-line">
          <adm4-validation-indicator [ngbTooltip]='popContentPatternErrors' [disableTooltip]='!node.shouldDisplayIssue' placement='top'
                                     [isDisplayed]='true'
                                     [isError]="node.hasErrorIssue"
                                     [isWarning]="node.hasWarnIssue"
                                     [isNeutral]="node.hasNeutralIssue"
                                     [diameter]='10'>
          </adm4-validation-indicator>
          <ng-template #popContentPatternErrors>
            <adm4-pattern-error-list-hover
                    [issues]='node.pattern.issues'
                    [projectKey]='projectKey'></adm4-pattern-error-list-hover>
          </ng-template>
        </div>
        <adm4-pattern-hierarchy-tree-item-detail [node]="node"
                                                 (navigateToPatternById)="navigateToPattern($event)"
                                                 (navigateTreeNode)="navigateToNodeLocal($event)"
        ></adm4-pattern-hierarchy-tree-item-detail>
        </ng-container>
      </mat-tree-node>
    </mat-tree>
    </div>
  `,
  styleUrls: ['pattern-hierarchy-tree.scss']
})
export class PatternHierarchyTreeComponent implements AfterViewInit, OnChanges {
  @Input() public patterns: PatternListData[];
  @Input() public selection: Maybe<string>;
  @Input() public projectKey: string;
  @Input() public scrollArea: any;
  @Input() shouldRenderAuthTree: boolean;
  @ViewChildren(MatTreeNode, {read: ElementRef}) treeNodeList: ElementRef[];
  treeControl: CustomTreeControl<HierarchyTreeNode>;
  dataSource: MatTreeFlatDataSource<PatternHierarchyData, HierarchyTreeNode>;

  /**
   * Used to store the last pattern id that was navigated to locally (i.e. by clicking on a tree node).
   * With this, we can do more precise node activation based on the `localId`,
   * and prevent doing less precise node activation based on the patternID coming from the route.
   */
  private lastLocallyNavigatedPatternId: Maybe<string>;
  private _takeUntilDestroyed: MonoTypeOperatorFunction<PatternHierarchyData | null>;

  constructor(private navigationService: NavigationService, public activatedRoute: ActivatedRoute, private patternHierarchyContext: PatternHierarchyContext) {
    this._takeUntilDestroyed = takeUntilDestroyed();

    const isExpandable = (node: HierarchyTreeNode) => node.expandable;
    const getLevel = (node: HierarchyTreeNode) => node.level;
    const treeFlattener: MatTreeFlattener<PatternHierarchyData, HierarchyTreeNode> =
        new MatTreeFlattener(this.transformToTreeNode.bind(this), getLevel, isExpandable, (node: PatternHierarchyData) => node.children);
    this.treeControl = new CustomTreeControl<HierarchyTreeNode>(getLevel, isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, treeFlattener);
  }

  ngAfterViewInit(): void {
    this.patternHierarchyContext.selectedPatternHierarchy.pipe(
        filter(patternWithHierarchy => !_.isNil(patternWithHierarchy)),
        this._takeUntilDestroyed,
    ).subscribe((patternWithHierarchy: PatternHierarchyData) => {
      this.dataSource.data = [patternWithHierarchy];
      // `transformToTreeNode` already created the nodes, but the `sharedPatternReferenceList` field of the nodes
      // needs the whole tree data, so it is populated in a 2nd walk of the nodes
      this.populateSharedPatternReferenceList(this.treeControl.dataNodes);

      const topNode: Maybe<HierarchyTreeNode> = !!this.treeControl.dataNodes && this.treeControl.dataNodes[0];
      if (topNode) {
        this.treeControl.expand(topNode);
      }
      setTimeout(() => {
        if (!_.isNil(this.treeNodeList) && !!this.selection) {
          this.activateNode(this.selection);
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selection'] && !!this.selection && !_.isNil(this.dataSource) && !_.isNil(this.treeControl?.dataNodes)) {
      this.activateNode(this.selection);
    }
  }

  private transformToTreeNode(pattern: PatternHierarchyData, level: number): HierarchyTreeNode {
    const hasErrorIssue: boolean = this.hasIssue('error', pattern);
    const hasWarnIssue: boolean = this.hasIssue('warning', pattern);
    const hasNeutralIssue: boolean = this.hasIssue('neutral', pattern);
    return {
      pattern,
      level: level,
      isTopLevel: level === 0,
      expandable: !_.isEmpty(pattern.children),
      isExpanded: level === 0,
      isSharedPattern: !_.isNil(pattern.parents) && pattern.parents.length > 1,
      sharedPatternReferenceList: [], // will be populated later
      hasErrorIssue, hasWarnIssue, hasNeutralIssue,
      shouldDisplayIssue: pattern.issues?.length > 0 && (hasErrorIssue || hasWarnIssue),
      graphViewLink: pattern.aborted ? this.calculateGraphViewLink(pattern) : undefined,
      localId: Math.random().toString(36).slice(2),
    };
  }

  private populateSharedPatternReferenceList(allNodes: Array<HierarchyTreeNode>): void {
    allNodes.forEach((node: HierarchyTreeNode) => {
      if (node.isSharedPattern) {
        node.sharedPatternReferenceList = this.collectSharedPatternReferenceList(node, allNodes);
      }
    });
  }

  /**
   * Collects those ancestors of `node` from among `allNodes` which are not the direct patterns of the node.
   */
  private collectSharedPatternReferenceList(node: HierarchyTreeNode, allNodes: Array<HierarchyTreeNode>): PatternListData[] {
    const directParent = PatternHierarchyHelper.getParentNode(node, allNodes);
    const ancestorsWithoutDirectParent = _.filter(_.uniq(node.pattern.parents), ancestor => !_.isEqual(ancestor, directParent?.pattern.pattern.patternId));
    return this.patternHierarchyContext.getPatternListDataByPatternIds(ancestorsWithoutDirectParent);
  }

  isGroupNode(_level: number, node: HierarchyTreeNode): boolean {
    return !_.isEmpty(node.pattern.children);
  }

  // It makes a recursive call on every visible node referring to the node index
  // It returns an array of true false values for the [ngClass] (true means node line crossing, false means an empty space holder)
  recNode(nodeContainer: any[], patternListItem: PatternHierarchyData[], index: number, maxIndex: number): any[] {
    if (nodeContainer === undefined) {
      nodeContainer = [];
    }
    for (let i = 0; i < patternListItem.length; i++) {
      index++;
      if (index === maxIndex) {
        return ([true, index, nodeContainer]);
      }
      if (!_.isNil(patternListItem[i].children) && patternListItem[i].children.length) {
        const childNode = this.recNode(nodeContainer, patternListItem[i].children, index, maxIndex);
        index = childNode[1];
        if (childNode[0] === true) {
          nodeContainer.splice(0, 0, (i !== (patternListItem.length - 1)));
          return ([true, index, nodeContainer]);
        }
      }
    }
    return ([false, index, nodeContainer]);
  }

  toggleExpand(node: HierarchyTreeNode): void {
    if (node.isExpanded) {
      this.treeControl.collapseDescendants(node);
    } else {
      this.treeControl.expandSmart(node);
    }
  }

  expandAll() {
    this.treeControl.expandAll();
  }

  collapseAll() {
    this.treeControl.collapseAllButTopLevel();
  }

  isPatternSelected(node: HierarchyTreeNode): boolean {
    return _.isEqual(this.activatedRoute.snapshot.url[this.activatedRoute.snapshot.url.length - 1].path.toString(), node.pattern?.pattern?.patternId);
  }

  hasIssue(issueType: string, pattern: PatternHierarchyData): boolean {
    const highestSeverityIssue: IssueSeverityEnum = getHighestSeverity(pattern.issues);
    switch (issueType) {
      case 'neutral':
        return highestSeverityIssue === IssueSeverityEnum.NO_ISSUE || highestSeverityIssue === IssueSeverityEnum.INFO;
      case 'warning':
        return highestSeverityIssue === IssueSeverityEnum.WARNING;
      case 'error':
        return highestSeverityIssue === IssueSeverityEnum.ERROR;
      default:
        return false;
    }
  }

  navigateToNodeLocal(node: HierarchyTreeNode): void {
    this.expandLocalNodeIfPresent(node.localId);
    this.lastLocallyNavigatedPatternId = node.pattern.pattern.patternId;
    this.activateNodeLocal(node.localId);
    this.navigationService.navigateToPattern(this.projectKey, node.pattern.pattern.patternId);
  }

  navigateToPattern(patternId: string): void {
    this.navigationService.navigateToPattern(this.projectKey, patternId);
  }

  activateNode(selectedPatternId: string): void {
    // if there was a local selection, the activation is already done
    if (this.lastLocallyNavigatedPatternId === selectedPatternId) {
      // we kinda use up the local selection
      this.lastLocallyNavigatedPatternId = null;
      return;
    }
    this.expandNodeIfPresent(selectedPatternId);
    const targetPatternReferences: ElementRef[] = this.findTreeNodesByPatternId(selectedPatternId);
    this.scrollToElement(targetPatternReferences);
  }

  activateNodeLocal(selectedLocalId: string): void {
    this.expandLocalNodeIfPresent(selectedLocalId);
    const targetPatternReferences: ElementRef[] = this.findTreeNodesByLocalId(selectedLocalId);
    this.scrollToElement(targetPatternReferences);
  }

  private scrollToElement(targetPatternReferences: ElementRef<HTMLElement>[]): void {
    if (targetPatternReferences.length === 0 || this.hasVisiblePatternWithSelectedId(targetPatternReferences)) {
      return;
    }
    targetPatternReferences[0].nativeElement.scrollIntoView();
  }

  private hasVisiblePatternWithSelectedId(targetPatternReferences: ElementRef[]): boolean {
    return targetPatternReferences.some(patternRef => !ScrollService.isElemVisible(this.scrollArea, patternRef.nativeElement));
  }

  private findTreeNodesByPatternId(selectedPatternId: string): ElementRef[] {
    return this.treeNodeList.filter((treeNodeRef: ElementRef) => selectedPatternId === treeNodeRef.nativeElement.id);
  }

  private findTreeNodesByLocalId(localId: string): ElementRef[] {
    return this.treeNodeList.filter((treeNodeRef: ElementRef) => localId === treeNodeRef.nativeElement?.getAttribute('data-localId'));
  }

  private expandNodeIfPresent(selectedPatternId: string): void {
    const selectedNode: Maybe<HierarchyTreeNode> = this.treeControl.dataNodes.find(node => node?.pattern?.pattern?.patternId === selectedPatternId);
    if (selectedNode) {
      this.treeControl.expandParents(selectedNode);
    }
  }

  private expandLocalNodeIfPresent(selectedLocalId: string): void {
    const selectedNode: Maybe<HierarchyTreeNode> = this.treeControl.dataNodes.find(node => node.localId === selectedLocalId);
    if (selectedNode) {
      this.treeControl.expandParents(selectedNode);
    }
  }

  // Need to detect last child nodes inside of every parent node to set proper line height for the tree node leaves.
  isLastChildNode(node: HierarchyTreeNode): boolean {
    const currentNodeIndex: number = this.treeControl.dataNodes.indexOf(node);
    const nextNode = this.treeControl.dataNodes[currentNodeIndex + 1];
    return !_.isNil(node) && node.level > nextNode?.level;
  }

  // Extra check needed to be sure that the node has pattern value in
  // (it can happen that the treeControl not updated instantly e.x.removal case which cause unExp error when reading undefined pattern value)
  isNodeContainsPattern(node: HierarchyTreeNode): boolean {
    return !_.isNil(node?.pattern?.pattern?.patternId);
  }

  private calculateGraphViewLink(pattern: PatternHierarchyData): string {
    return this.navigationService.getGraphViewLink(this.projectKey, pattern?.pattern?.patternId);
  }
}

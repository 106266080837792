import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { PublishProjectDialogComponent } from './publish-project-dialog.component';
import { PublishProjectDialogService } from './publish-project-dialog.service';
import { ClearPublishProjectData } from '../../model/publish-project/publish-project.actions';
import { projectKeyView } from '../../model/views';
import { first } from 'rxjs/operators';
import { PublishMainComponent } from '../../common/model/publish-changes/publish-main-component.model';

@Component({
  selector: 'adm4-publish-project-main',
  template: '',
})
export class PublishProjectMainComponent implements PublishMainComponent<PublishProjectDialogComponent>, OnInit, OnDestroy {
  dialogRef: MatDialogRef<PublishProjectDialogComponent>;

  constructor(private publishProjectDialogService: PublishProjectDialogService,
              private store$: Store<AppState>) {
  }

  ngOnInit(): void {
    // setTimeout is workaround for view creation inside change detection cycle
    setTimeout(() => {
      this.store$.pipe(select(projectKeyView), first()).subscribe((projectKey: string) => {
        this.dialogRef = this.publishProjectDialogService.openPublishChangesWindow(projectKey);
      });
    });
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.afterClosed().subscribe(() => {
        this.store$.dispatch(new ClearPublishProjectData());
      });
      this.dialogRef.close();
    }
  }
}

import {filter} from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Pattern } from '../../patterns/pattern.model';
import { Subscription } from 'rxjs';
import { GlobalClicks } from '../../common/services/global-clicks.service';
import { PatternType } from '../../plugins/pattern-type.model';
import { Dictionary } from 'lodash';
import { ScrollService } from '../../common/services/scroll/scroll.service';

@Component({
  selector: 'adm4-dropdown-list',
  template: `
		<div *ngIf='filteredListItems' class='ui-dropdown-elements' (adm4AfterViewInit)='scrollViewToDropdownList()' #dropdown>
      <ul class='dropdown-outer-list form-control'>
        <li>
          <ul class='dropdown-list'>
            <li class='dropdown-item' *ngFor='let item of filteredListItems' (click)='itemClicked.emit(item)'>
              <div class='ui-pattern-info'>
                <div class='ui-pattern-name' [innerHTML]='item.name | highlight: inputValue'></div>
                <div class='ui-pattern-class' [innerHTML]='patternTypes[item.className]?.name | highlight: inputValue'></div>
              </div>
              <div *ngIf='item.label' class='label-info' [ngbTooltip]='labelPopover' placement='top'>
                <span class='label-text' [innerHTML]='item.label | highlight: inputValue'></span>
                <mat-icon class='label-icon'>local_offer</mat-icon>
              </div>
              <ng-template #labelPopover>{{item?.label}}</ng-template>
            </li>
          </ul>
				</li>
				<li class='dropdown-item new-pattern-item' (click)='createPatternClicked.emit()'
						data-toggle="modal" data-target="#addModal">
					<i class='material-icons hero-add-circle'>add_circle</i>
          <a>Add pattern <ng-container *ngIf='inputValue?.length'>'{{inputValue}}'</ng-container></a>
        </li>
      </ul>
		</div>
  `,
  providers: [GlobalClicks],
  styleUrls: ['pattern-reference-property.scss']
})
export class DropdownListComponent implements OnChanges, OnDestroy {
  @Input() listItems: Pattern[];
  @Input() inputValue: string;
  @Input() patternTypes: Dictionary<PatternType>;
  @Input() scrollArea?: HTMLElement;
  @Output() itemClicked = new EventEmitter();
  @Output() outsideClicked: EventEmitter<EventTarget | null> = new EventEmitter();
  @Output() createPatternClicked = new EventEmitter();
  @ViewChild('dropdown', {static: false}) dropdown: ElementRef;

  public filteredListItems: Pattern[];
  private clickSubscription: Subscription;

  constructor(private clicks: GlobalClicks,
              private elementRef: ElementRef,
              private scrollService: ScrollService) {
    this.clickSubscription = clicks.click$.pipe(filter($event => {
      const host = elementRef.nativeElement;
      const target = $event.target;
      return !host.contains(target);
    }))
      .subscribe(($event) => {
        this.outsideClicked.emit($event.target);
      });
  }

  ngOnChanges(chages: SimpleChanges): void {
    if (chages.listItems) {
      this.filteredListItems = this.listItems;
    }
    if (chages.inputValue && this.listItems) {
      this.filteredListItems = this.filterPatterns(this.listItems, this.inputValue);
    }
  }

  scrollViewToDropdownList() {
    if (this.dropdown && this.scrollArea) {
      this.scrollService.scrollIntoViewIfNeeded(this.scrollArea, this.dropdown.nativeElement);
    }
  }

  filterPatterns(patterns: Pattern[] = [], filterText: string): Pattern[] {
    return patterns.filter((item: Pattern) => {
        const nameMatches = item.name.toLocaleLowerCase().includes(filterText.toLowerCase());
        const labelMatches = item.label && item.label.toLocaleLowerCase().includes(filterText.toLowerCase());
        return nameMatches || labelMatches;
      }
    );
  }

  ngOnDestroy(): void {
    this.clickSubscription.unsubscribe();
  }
}

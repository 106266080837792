export enum PatternGroupingOptionTitles {
  Label = 'Label',
  Ungrouped = 'Ungrouped list'
}

export const patternGroupingOptions: PatternGroupingOption[] = [
  {
    title: PatternGroupingOptionTitles.Ungrouped,
    iconClass: 'list'
  },
  {
    title: PatternGroupingOptionTitles.Label,
    iconClass: 'local_offer'
  }
];

export interface PatternGroupingOption {
  title: PatternGroupingOptionTitles;
  iconClass: string;
}

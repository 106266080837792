import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Project, ProjectMeta } from '../../project.model';
import { VersionControlService } from '../../../version-control/version-control.service';
import { AppState } from '../../../model/reducer';
import { allProjectsView, projectMetaView, selectedProjectView, selectedTenantKeyView, shouldDisplayTenantsView } from '../../../model/views';

@Injectable()
export class BranchProjectContext {
  projects$: Observable<Project[]>;
  selectedTenantKey$: Observable<string>;
  displayTenant$: Observable<boolean>;
  selectedProject$: Observable<Project | null>;
  projectMeta$: Observable<ProjectMeta | null>;

  constructor(private versionControlService: VersionControlService, private store$: Store<AppState>) {
    this.projects$ = this.store$.pipe(select(allProjectsView), map(_.values));
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;
    this.displayTenant$ = this.store$.pipe(select(shouldDisplayTenantsView));
    this.selectedProject$ = this.store$.pipe(select(selectedProjectView), map((project: Project | undefined) => project || null));
    this.projectMeta$ = this.store$.pipe(select(projectMetaView));
  }
}

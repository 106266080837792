import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProjectMeta } from '../../../projects/project.model';
import * as _ from 'lodash';
import { convertProjectStatus, haveMoreUnpublishedPatternChanges } from '../project-status.helper';
import { PatternVersionInfo } from '../../../version-control/pattern-meta-info.model';

@Component({
  selector: 'adm4-project-summary-changes',
  template: `
    <adm4-project-summary-section [title]='"Last unpublished changes"'>
      <ng-container *ngIf='displayLastUnpublishedChanges; else none'>
        <ul class='changes-group-list'>
          <ng-container *ngFor='let groups of unpublishedChanges'>
            <ng-container *ngFor='let group of groups'>
              <li *ngIf='isString(group); else subList'>{{group}}</li>
              <ng-template #subList>
                <ul class='changes-list'>
                  <li *ngFor='let patternInfo of group'>
                    {{patternInfo.name}} - {{patternInfo.localStatus | capitalizeFirst}} by {{patternInfo.localAuthor}}, {{patternInfo.localDate | customDate}}
                  </li>
                </ul>
              </ng-template>
            </ng-container>
          </ng-container>
        </ul>
        <a *ngIf='haveMoreUnpublishedChanges' href='javascript:void(0)' (click)='toggleShowAll()'>{{showLinkText}}</a>
      </ng-container>
      <ng-template #none>None</ng-template>
    </adm4-project-summary-section>
  `,
  styleUrls: ['./project-summary-changes.component.scss', '../project-summary-section.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryChangesComponent implements OnChanges {
  @Input() projectStatusModel: ProjectMeta;

  dateFormat = 'HH:mm';
  unpublishedChanges: Array<string | Array<PatternVersionInfo>>;
  haveMoreUnpublishedChanges: boolean;
  showLinkText: string;
  lastUnpublishedChanges: Array<string | Array<PatternVersionInfo>>;
  allUnpublishedChanges: Array<string | Array<PatternVersionInfo>>;
  private showingAllUnpublishedChanges: boolean;
  /** Last unpublished changes should show last 8 changes. */
  private readonly lastUnpublishedChangeLimit = 8;

  isString(value: any): boolean {
    return _.isString(value);
  }

  get displayLastUnpublishedChanges(): boolean {
    return !!this.lastUnpublishedChanges && !!this.lastUnpublishedChanges.length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['projectStatusModel']) {
      this.lastUnpublishedChanges = convertProjectStatus(this.projectStatusModel, this.lastUnpublishedChangeLimit);
      this.allUnpublishedChanges = convertProjectStatus(this.projectStatusModel);

      this.unpublishedChanges = this.lastUnpublishedChanges;
      this.showLinkText = 'Show all';

      this.haveMoreUnpublishedChanges = haveMoreUnpublishedPatternChanges(this.projectStatusModel, this.lastUnpublishedChangeLimit);
    }
  }

  toggleShowAll(): void {
    if (!this.showingAllUnpublishedChanges) {
      this.unpublishedChanges = this.allUnpublishedChanges;
      this.showLinkText = 'Show less';
    } else {
      this.showLinkText = 'Show all';
      this.unpublishedChanges = this.lastUnpublishedChanges;
    }

    this.showingAllUnpublishedChanges = !this.showingAllUnpublishedChanges;
  }
}

import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { customizableCertificatesColumns, fixCertificatesColumns, initialCertificatesColumns, SecretManagementTableModel } from '../secret-management/secret-management-table/secret-management-table.model';
import { map, shareReplay, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import { CertificateWrapperWithUsage, Inventory, InventoryResourceType, TenantResourceFlag } from '../../inventory/inventory.model';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { allInventoriesView, selectedTenantKeyView } from '../../model/views';
import { UntypedFormControl } from '@angular/forms';
import { NavigationService } from '../../navbar/navigation.service';
import { InventoryService } from '../../inventory/inventory.service';
import { customizableColumnsMenuInfo } from './customizable-columns-menu-info.payload';
import { localCertificateColumns } from '../../common/constants/local-storage-keys.constants';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';

@Component({
  selector: 'adm4-certificate-management',
  template: `
      <div class='full-height-flex'>
        <div class='secret-management-header'>
          <adm4-column-header [styleClass]='"light-inline-header"'>
            <span>Certificates</span>
          </adm4-column-header>
        </div>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <div class='full-height-flex details-container'>
              <div class='filters-wrapper'>
                <label for='scope' class='input-label scope-label'>Scope: </label>
                <mat-form-field class='scope-selection'>
                  <mat-select id='scope' #scopeSelection
                              [(ngModel)]='selectedScope'
                              [placeholder]='"Global"'
                              [formControl]='scopeSelectionControl'
                              [disableRipple]='true'
                              [disableOptionCentering]='true'
                              (click)='focusDropdownInput()'>
                    <adm4-searchable-dropdown-input *ngIf='scopeSelection.focused'
                                                    [sourceItems]='scopes$ | async'
                                                    [placeholder]="'Please select a scope...'"
                                                    [searchableFormatFn]='searchableProjectFormatFn'
                                                    [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                                    (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
                    <mat-option *ngFor='let scope of scopes$ | async' [value]='scope' [hidden]='!isScopeFilteredOut(scope)'>
                      {{resolveScopeItemText(scope, selectedTenantKey$ | async)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class='search-filter'>
                  <adm4-filter (filter)="filterBySearch($event)"
                               [placeholderText]='"Type to filter..."'
                               [filterText]="filterText"
                               class='search-input'>
                  </adm4-filter>
                </div>
              </div>
              <div class='remaining-space-flex-content-wrapper'>
                <div class='remaining-space-flex-content'>
                  <div class='certificates-info'>
                    The table shows the secret files and files which are considered as certificates. Please note that only the certificates with .pem extension are listed. To see other files or upload global .pem files visit <a (click)='navigateToSecretManagement()'>Secret & Files</a> screen.
                  </div>
                  <div class='customize-menu-container'>
                    <adm4-draggable-checkbox-menu class='draggable-menu'
                                                  [initialSelection]="defaultCertificatesColumnSelection"
                                                  [allItemList]="customizableCertificatesColumns"
                                                  [menuInfo]='customizableColumnsMenuInfo'
                                                  [localStorageKey]='localCertificateColumns'
                                                  (selectionChange)='updateDisplayableColumns($event)'>
                    </adm4-draggable-checkbox-menu>
                  </div>
                  <ng-container *ngIf='(certificates$ | async)?.length > 0; else noCertificateFound'>
                    <adm4-certificate-management-table
                            [resourceGroup]='certificates$ | async'
                            [displayedColumns]='displayedColumnsForCertificates'
                            [filterText]='filterText'
                            [selectedTenantKey]='selectedTenantKey$ | async'
                            [selectedScope]='selectedScope'
                            (triggerResourceLoad)='triggerResourceLoad()'>
                    </adm4-certificate-management-table>
                  </ng-container>
                  <ng-template #noCertificateFound>
                    <adm4-empty-resource><span>There is no certificate found.</span></adm4-empty-resource>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  `,
  styleUrls: ['../secret-management/secret-management.component.scss', '../../common/styles/component-specific/settings-details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CertificateManagementComponent implements OnDestroy {
  selectedTenantKey$: Observable<string>;
  inventories$: Observable<Inventory[]>;
  certificates$: Observable<(CertificateWrapperWithUsage & TenantResourceFlag)[]>;
  displayedColumnsForCertificates: SecretManagementTableModel[];
  scopes$: Observable<string[]>;
  selectedScope: string;
  inventoryResourceTypes = InventoryResourceType;
  scopeSelectionControl = new UntypedFormControl();
  canViewSecretContent$: Observable<boolean>;
  filteredScopes: string[];
  selectedTenantKey: string;

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  private _resourceLoadTrigger$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  resourceLoadTrigger$: Observable<boolean> = this._resourceLoadTrigger$.asObservable();
  readonly ALL_CERTIFICATE_SELECTION = 'ALL';
  readonly GLOBAL_CERTIFICATE_SELECTION = 'GLOBAL';
  readonly customizableCertificatesColumns = customizableCertificatesColumns;
  readonly fixCertificatesColumns = fixCertificatesColumns;
  readonly customizableColumnsMenuInfo = customizableColumnsMenuInfo;
  readonly localCertificateColumns = localCertificateColumns;
  public filterText = '';
  private destroyed$: Subject<boolean> = new Subject();

  constructor(private navigationService: NavigationService,
              private inventoryService: InventoryService,
              private store$: Store<AppState>) {
    this.displayedColumnsForCertificates = this.getInitialCertificateColumns();
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;
    this.inventories$ = this.store$.pipe(select(allInventoriesView)).pipe(map(_.values));
    this.scopes$ = this.inventories$.pipe(withLatestFrom(this.selectedTenantKey$), tap(() => {
        // Set all scope by default only for the first time for initial scope selection
        if (_.isNil(this.selectedScope)) {
          this.selectedScope = this.ALL_CERTIFICATE_SELECTION;
        }
      }),
      map(([inventoryList, tenantKey]) => {
        this.selectedTenantKey = tenantKey;
        return _.reduce(inventoryList, (output: string[], inventory: Inventory) => {
          return output.concat(inventory.inventoryKey);
        }, [this.ALL_CERTIFICATE_SELECTION, tenantKey]);
      }));
    this.certificates$ = this.getCertificates();
  }

  getCertificates(): Observable<CertificateWrapperWithUsage[]> {
    return combineLatest([
      this.scopeSelectionControl.valueChanges,
      this.selectedTenantKey$,
      this.resourceLoadTrigger$]).pipe(
        takeUntil(this.destroyed$),
        switchMap(([selectedScope, selectedTenantKey,]: [string, string, boolean]) => {
          if (_.isEqual(selectedTenantKey, selectedScope)) {
            return this.inventoryService.getTenantCertificates(selectedTenantKey, false);
          }
          if (_.isEqual(this.ALL_CERTIFICATE_SELECTION, selectedScope)) {
            return this.inventoryService.getTenantCertificates(selectedTenantKey, true);
          }
          return this.inventoryService.getInventoryCertificatesWithUsage(selectedScope, true);
        }),
      withLatestFrom(this.selectedTenantKey$),
      map(([certificates, selectedTenantKey]: [CertificateWrapperWithUsage[], string]) => {
        const tenantScopedCertificates: (CertificateWrapperWithUsage & TenantResourceFlag)[] = _.map(certificates, (cert) => {
          return {...cert, isTenantScoped: _.isEqual(selectedTenantKey, cert.scope)};
        });
        return tenantScopedCertificates;
      }), shareReplay(1));
  }


  getInitialCertificateColumns(): SecretManagementTableModel[] {
    const retrieve = LocalStorageHelper.retrieve(localCertificateColumns);
    const preSelectedColumns = _.isNil(retrieve) ? initialCertificatesColumns : JSON.parse(retrieve);
    return this.sortDisplayableColumns(_.union(fixCertificatesColumns, preSelectedColumns));
  }

  filterBySearch(filterText: string): void {
    this.filterText = filterText;
  }

  resolveScopeItemText(scope: string, selectedTenantKey: string): string {
    if (_.isEqual(scope, selectedTenantKey)) {
      return this.GLOBAL_CERTIFICATE_SELECTION;
    }
    if (_.isEqual(scope, this.ALL_CERTIFICATE_SELECTION)) {
      return this.ALL_CERTIFICATE_SELECTION;
    }
    return TenantHelper.cropTenantFromKey(scope);
  }

  navigateToSecretManagement(): void {
    this.navigationService.navigateToSecretManagement();
  }

  triggerResourceLoad(): void {
    this._resourceLoadTrigger$.next(true);
  }

  searchableProjectFormatFn = (scope: string): string => {
    return this.resolveScopeItemText(scope, this.selectedTenantKey);
  };

  updateSearchResult(filteredList: string[]): void {
    this.filteredScopes = filteredList;
  }

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }

  updateDisplayableColumns(columnsToDisplay: SecretManagementTableModel[]) {
    this.displayedColumnsForCertificates = this.sortDisplayableColumns(_.union(this.fixCertificatesColumns, columnsToDisplay));
  }

  // Set the Action column always as the last element
  get defaultCertificatesColumnSelection(): SecretManagementTableModel[] {
    return _.intersection(initialCertificatesColumns, this.customizableCertificatesColumns);
  }

  sortDisplayableColumns(displayableColumns: SecretManagementTableModel[]): SecretManagementTableModel[] {
    return _.sortBy(displayableColumns, column => _.isEqual(column, SecretManagementTableModel.ActionColumnName) ? 1 : 0);
  }

  isScopeFilteredOut(scope: string): boolean {
    return _.includes(this.filteredScopes, scope);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

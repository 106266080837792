<adm4-diff-view-element [remoteCommitInfo]='propertyDiff.remote.commitInfo'
                        [localChangeInfo]='propertyDiff.local.changeInfo'
                        [localFallbackLabel]='propertyDiff.local.fallbackLabel'
                        [currentUsername]='currentUsername'
                        [showOnlyDifferences]='showOnlyDifferences'
>
  <div remote>
    <adm4-property-list-element [patternProperty]='propertyDiff.remote.patternProperty'
                                [patterTypeProp]='propertyDiff.remote.patternTypeProperty'
                                [propertyType]='propertyDiff.remote.propertyType'
                                [projectKey]='projectKey'
                                [patternId]='propertyDiff.remote.patternId'
                                [variables]='variables'
                                [issues]='propertyDiff.remote.issues'
                                [form]='remoteForm'
                                [readOnly]='true'
                                [isLocal]='false'
    ></adm4-property-list-element>
  </div>
  <div local>
    <adm4-property-list-element [patternProperty]='propertyDiff.local.patternProperty'
                                [patterTypeProp]='propertyDiff.local.patternTypeProperty'
                                [propertyType]='propertyDiff.local.propertyType'
                                [projectKey]='projectKey'
                                [patternId]='propertyDiff.local.patternId'
                                [variables]='variables'
                                [form]='localForm'
                                [issues]='propertyDiff.local.issues'
                                [readOnly]='true'
                                [isLocal]='true'
    ></adm4-property-list-element>
  </div>
</adm4-diff-view-element>

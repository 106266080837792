import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-link',
  template: `
    <span (click)='trigger($event)'
          [title]='linkTitle'>
      <span class='link' [class.disabled]='isDisabled'>
        <a [href]='url' *ngIf='hasUrl; else withouthref' [target]='target'>
          <ng-container *ngTemplateOutlet='contentTempl'></ng-container>
        </a>
      </span>
    </span>
    <ng-template #withouthref>
      <a>
        <ng-container *ngTemplateOutlet='contentTempl'></ng-container>
      </a>
    </ng-template>

    <ng-template #contentTempl>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['../../styles/component-specific/link.scss']
})
export class LinkComponent {

  @Input() isDisabled = false;
  @Input() linkURL?: string;
  @Input() tooltipText: string;
  @Input() target: '_blank' | '_self' | '_parent' | '_top' = '_self';


  trigger(event: MouseEvent) {
    if (this.isDisabled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  get linkTitle() {
    return this.isDisabled ? this.tooltipText : '';
  }

  get hasUrl(): boolean {
    return !_.isNil(this.linkURL);
  }

  get url() {
    return this.linkURL;
  }

}

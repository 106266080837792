import { IssueModel, IssueSeverity, SourceType, Target } from '../../common/model/issue.model';
import { Pattern } from '../../patterns/pattern.model';
import { PatternIssueSeverityGroup } from '../model/pattern-issue-severity-group.model';
import * as _ from 'lodash';
import { PatternIssueGroupItem } from '../model/pattern-issue-group-item.model';
import { replaceArrayItem } from '../../common/utils/utils';
import { IssueSeveritySortingEnum } from '../model/issue-severity-sorting.enum';
import { getSpecificTargetOfIssue } from '../../projects/project-issues/project-issues.helper';
import { generalIssueGroupId, generalIssueGroupName } from '../issues.constants';
import { IssueGroupFragmentHelper } from '../issue-group-fragment.helper';

export class PatternIssueSeverityGroupsHelper {
  /**
   * Create sorted list of groups of issue groups by severity and target
   * @param {IssueModel[]} issues
   * @param {Map<string, Pattern>} patterns
   * @returns {PatternIssueSeverityGroup[]} - patterns + one GENERAL(in case there is no target pattern) groups created from issues grouped by issue severity
   */
  static createGroups(issues: IssueModel[], patterns: Map<string, Pattern>): PatternIssueSeverityGroup[] {
    return this.sortIssues(issues, patterns)
      .reduce((patternIssueSeverityGroups: PatternIssueSeverityGroup[], issue: IssueModel) => {
        const existingGroup: PatternIssueSeverityGroup | undefined = patternIssueSeverityGroups.find(group => group.severity === issue.severity);
        const groupItem: PatternIssueGroupItem = this.createGroupItem(issue, patterns);
        if (_.isNil(existingGroup)) {
          const newGroup: PatternIssueSeverityGroup = {
            severity: issue.severity,
            items: [groupItem]
          };
          return _.concat(patternIssueSeverityGroups, [newGroup]);
        }
        const existingGroupItem: PatternIssueGroupItem | undefined = existingGroup.items.find(item => item.id === groupItem.id);
        if (_.isNil(existingGroupItem)) {
          const updatedGroup: PatternIssueSeverityGroup = Object.assign({}, existingGroup, {
            items: _.concat(existingGroup.items, [groupItem])
          });
          return patternIssueSeverityGroups.map(replaceArrayItem<PatternIssueSeverityGroup>((group => group.severity === updatedGroup.severity))(updatedGroup));
        }
        return patternIssueSeverityGroups;
      }, []);
  }

  /**
   * Sorts issues by severity and pattern name if specified, if pattern is not specified then it is considered to be higher in the list
   * @param {IssueModel[]} issues
   * @param patterns
   * @returns {IssueModel[]}
   */
  static sortIssues(issues: IssueModel[], patterns: Map<string, Pattern>): IssueModel[] {
    return [...issues].sort((issue1: IssueModel, issue2: IssueModel) => {
      return this.compareIssues(issue1, issue2, patterns);
    });
  }

  static createGroupItem(issue: IssueModel, patterns: Map<string, Pattern>): PatternIssueGroupItem {
    const patternTarget: Target | undefined = getSpecificTargetOfIssue(issue, SourceType.PATTERN);
    if (_.isNil(patternTarget)) {
      return this.createGeneralGroupItem(issue.severity);
    }
    return this.createPatternGroupItem(patternTarget, patterns, issue.severity);
  }

  static createGeneralGroupItem(issueSeverity: IssueSeverity): PatternIssueGroupItem {
    return {
      id: generalIssueGroupId,
      name: generalIssueGroupName,
      fragment: IssueGroupFragmentHelper.createIssueSeverityGroupFragment(issueSeverity, generalIssueGroupId)
    };
  }

  static createPatternGroupItem(patternTarget: Target, patterns: Map<string, Pattern>, issueSeverity: IssueSeverity): PatternIssueGroupItem {
    const pattern: Pattern | undefined = patterns.get(patternTarget.value);
    return {
      id: patternTarget.value,
      name: _.isNil(pattern) ? patternTarget.value : pattern.name,
      fragment: IssueGroupFragmentHelper.createIssueSeverityGroupFragment(issueSeverity, patternTarget.value)
    };
  }

  static getIssueSeveritySortingPosition(issueSeverity: IssueSeverity): IssueSeveritySortingEnum {
    return IssueSeveritySortingEnum[issueSeverity];
  }

  static filterPatternIssueSeverityGroupByGroupName(patternIssueSeverityGroups: PatternIssueSeverityGroup[], filterText: string): PatternIssueSeverityGroup[] {
    return patternIssueSeverityGroups.map(group => {
      return {...group,
        items: group.items.filter(item => _.includes(item.name.toLowerCase(), filterText.toLowerCase()))
      };
    }).filter(group => !_.isEmpty(group.items));
  }

  private static compareIssues(issue1: IssueModel, issue2: IssueModel, patterns: Map<string, Pattern>): number {
    const position = this.compareIssuesBySeverity(issue1, issue2);
    if (position === 0) {
      return this.compareIssuesByPatternTarget(issue1, issue2, patterns);
    }
    return position;
  }

  private static compareIssuesBySeverity(issue1: IssueModel, issue2: IssueModel): number {
    return this.getIssueSeveritySortingPosition(issue1.severity) - this.getIssueSeveritySortingPosition(issue2.severity);
  }

  private static compareIssuesByPatternTarget(issue1: IssueModel, issue2: IssueModel, patterns: Map<string, Pattern>): number {
    const patternTarget1: Target | undefined = getSpecificTargetOfIssue(issue1, SourceType.PATTERN);
    const patternTarget2: Target | undefined = getSpecificTargetOfIssue(issue2, SourceType.PATTERN);
    if (_.isNil(patternTarget1)) {
      return -1;
    }
    if (_.isNil(patternTarget2)) {
      return 1;
    }
    const pattern1: Pattern | undefined = patterns.get(patternTarget1.value);
    const pattern2: Pattern | undefined = patterns.get(patternTarget2.value);
    const comparableName1 = _.isNil(pattern1) ? patternTarget1.value : pattern1.name;
    const comparableName2 = _.isNil(pattern2) ? patternTarget2.value : pattern2.name;
    return comparableName1.localeCompare(comparableName2);
  }
}


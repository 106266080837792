import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { FileHelper } from '../../helpers/file.helper';

@Component({
  selector: 'adm4-single-file-input',
  template: `
    <div *ngIf='!fileExists; else selectedFile' class='single-file-input admn4-input-group-with-icon'>
      <input type='file' hidden #fileInput
             [accept]='fileExtensions'
             [disabled]='disabled'
             (change)='onChange($event.target.files)'>
      <input type='text' class='admn4-fake-file-input form-control' readonly
             [placeholder]='placeholderText'
             (click)='fileInput.click()'
             (blur)='onTouched()'
      >
      <button type='button' class='admn4-button-ellipse-blue input-icon' (blur)='onTouched()' (click)='fileInput.click()' cdkFocusInitial
      >{{buttonText}}</button>
    </div>
    <ng-template #selectedFile>
      <div class='selected-file'>
        <span>{{file.name}}</span><em class='admn4-button-icon cancel-icon fa fa-times-circle-o' (click)='onChange(null)'></em>
      </div>
    </ng-template>
  `,
  styleUrls: ['./single-file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SingleFileInputComponent,
      multi: true
    }
  ]
})
export class SingleFileInputComponent implements ControlValueAccessor {
  @Input() fileExtensions: string[];
  @Input() placeholderText = 'Please click to select a file...';
  @Input() buttonText = 'Select file';

  file: File | null;

  disabled: boolean;
  onChange: (files: FileList | null) => void = (files: FileList | null) => {
    const file: File | null = FileHelper.getFileFromFileList(files);
    this.writeValue(file);
  };
  onTouched: () => void = () => {/* initial placeholder fn */};

  registerOnChange(fn: (file: File | null) => void): void {
    this.onChange = (files: FileList | null) => {
      const file: File | null = FileHelper.getFileFromFileList(files);
      this.writeValue(file);
      fn(file);
    };
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(file: File | null): void {
    this.file = file;
  }

  get fileExists(): boolean {
    return !_.isNil(this.file);
  }

}

import { NgModule } from '@angular/core';
import { TenantGuard } from '../tenant/tenant.guard';
import { AuthenticationGuard } from '../common/guards/authentication.guard';
import { RouterModule } from '@angular/router';
import { ResourcesComponent } from './resources.component';
import { BundleManagementComponent } from './bundle-management/bundle-management.component';
import { SecretManagementComponent } from './secret-management/secret-management.component';
import { GlobalSettingsGuard } from './global-settings.guard';
import { CertificateManagementComponent } from './certificates/certificate-management.component';
import { KubernetesCertificateManagementComponent } from './kubernetes-certificates/kubernetes-certificate-management.component';
import { GlobalConstantsComponent } from './global-constants/global-constants.component';
import { MarketplacePageComponent } from './marketplace/marketplace-page/marketplace-page.component';

export const ROUTE_PARAM_MARKETPLACE_TEMPLATEKEY = 'templateKey';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'resources',
        component: ResourcesComponent,
        canActivate: [AuthenticationGuard, TenantGuard],
        canActivateChild: [AuthenticationGuard, TenantGuard],
        children: [
          {
            path: 'global-constants',
            component: GlobalConstantsComponent,
            canActivate: [GlobalSettingsGuard]
          },
          {
            path: 'secret-management',
            component: SecretManagementComponent,
            canActivate: [GlobalSettingsGuard]
          },
          {
            path: 'certificate-overview',
            component: CertificateManagementComponent,
            canActivate: [GlobalSettingsGuard]
          },
          {
            path: 'kubernetes-certificate-overview',
            component: KubernetesCertificateManagementComponent,
            canActivate: [GlobalSettingsGuard]
          },
          {
            path: 'library-management',
            component: BundleManagementComponent
          },
          {
            path: 'marketplace',
            component: MarketplacePageComponent,
            data: {
              reuse: 'reuseMarketplaceWithTemplateKey',
            },
          },
          {
            path: `marketplace/:${ROUTE_PARAM_MARKETPLACE_TEMPLATEKEY}`,
            component: MarketplacePageComponent,
            data: {
              reuse: 'reuseMarketplaceWithTemplateKey',
            },
          }
        ]
      }
    ])
  ],
  providers: [GlobalSettingsGuard]
})
export class ResourcesRoutingModule {
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormHelper } from './form.helper';

export const VALIDATION_ERROR_PASSWORD_POLICY_DEFAULT = 'password-policy-default';
export const VALIDATION_ERROR_USERNAME_CHARS = 'username-safe-characters';

export const VALIDATION_MESSAGE_PASSWORD_POLICY_DEFAULT = 'Must be at least 8 characters. Use numbers, upper and lower case letters.';
export const VALIDATION_MESSAGE_USERNAME_CHARS = 'The username must not contain any of the following characters: / : & = # ? \\ ;';

const lowerCase: RegExp = /^.*[a-z]+.*$/;
const upperCase: RegExp = /.*[A-Z]+.*$/;
const number: RegExp = /^.*[0-9]+.*$/;
const notUserNameSafe: RegExp = /[/:&=#?;\\]+/;

export class Adm4FormValidators {
  /**
   * Password policy: at least 8 chars, at least one of each: number, lower case letter, upper case letter
   * @param control
   */
  static defaultPasswordPolicy: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control || typeof control.value !== 'string' || control.value === '') {
      return null;
    }
    const value = control.value;
    if (value.length < 8 || !lowerCase.test(value) || !upperCase.test(value) || !number.test(value)) {
      return {[VALIDATION_ERROR_PASSWORD_POLICY_DEFAULT]: true};
    }
    return null;
  };

  static requiredArray: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control) {
      return null;
    }
    const value: unknown = control.value;
    if (Array.isArray(value) && value.length > 0) {
      return null;
    } else {
      return {
        [FormHelper.VALIDATION_KEY_REQUIRED]: true,
      };
    }
  };

  static userNameSafeCharacters: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (notUserNameSafe.test(control?.value)) {
      return {[VALIDATION_ERROR_USERNAME_CHARS]: true};
    }
    return null;
  };
}

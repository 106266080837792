import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { Store } from '@ngrx/store';

import { combineLatest, EMPTY, fromEvent, merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, take, takeUntil } from 'rxjs/operators';

import { filterEmpty, Maybe } from '../../../common/utils/utils';
import { AppState } from '../../../model/reducer';
import { allProjectsView, selectedTenantKeyView } from '../../../model/views';
import { Project } from '../../project.model';
import { allProjectTemplatesView } from '../../../resources/marketplace/marketplace.view';
import { ProjectTemplate } from '../../../resources/marketplace/marketplace.model';
import { LoadProjects } from '../../../model/project';
import { FormHelper } from '../../../common/helpers/form.helper';
import { MarketplaceApiService } from '../../../resources/marketplace/marketplace-api.service';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { Adm4FormValidators } from '../../../common/helpers/form-validators';

export interface CreateCustomProjectTemplateDialogConfig {
  selectedProjectKey: Maybe<string>;
}

@Component({
  selector: 'adm4-create-custom-project-template-dialog',
  template: `
    <adm4-modal-dialog-title
      header='Create custom template from project'
      [showClose]="true"
      (closeClicked)="closeWithoutSaving()">
      <form [formGroup]="form">
        <div class="content-container bg-screen-white" cdkFocusInitial>
          <div class="form-group" [class.has-error]="shouldShowPrjInvalidState()">
            <label for="ct-project" class="input-label">Project</label>
            <mat-form-field class='full-width'>
              <mat-select #matSelect id='ct-project'
                          [placeholder]="'Please select a Project...'"
                          [disableOptionCentering]="true"
                          (openedChange)="projectSelectToggled($event)"
                          formControlName="projectKey">
                <adm4-searchable-dropdown-input *ngIf='matSelect.focused'
                                                [showSpinner]="false"
                                                [focusTrigger]="projectSearchInputFocusTrigger$"
                                                (searchText)="search.next($event)"
                ></adm4-searchable-dropdown-input>
                <ng-container *ngFor="let pk of (filteredProjectKeys | async);">
                  <mat-option [value]="pk"
                  ><span [innerHTML]="pk | cropTenantFromKey | highlight: (search | async)"></span></mat-option>
                </ng-container>
                <mat-option [disabled]='true' *ngIf='noFilteredResults | async'
                ><span>No projects match the search.</span></mat-option>
                <mat-option [disabled]='true' *ngIf='noProjects | async'
                ><span>There are no projects.</span></mat-option>
              </mat-select>
            </mat-form-field>
            <div class="validation-message-container">
              <p>The description of the project in the <strong>About Project</strong>
                section will be your template description.</p>
            </div>
            <div class="validation-message-container">
              <adm4-validation-message *ngIf="shouldShowPrjRequiredMsg()"
                                       [isError]='true'
                                       message="Please select a project to create the template from."></adm4-validation-message>
            </div>
          </div>

          <div class="form-group" [class.has-error]="shouldShowTemplateNameInvalidState()">
            <label for="ct-name" class="input-label">Template name*</label>
            <input type="text" id="ct-name" class="form-control admn4-text-input" formControlName="templateName">
            <div class="validation-message-container">
              <adm4-validation-message *ngIf="shouldShowTemplateNameRequiredMsg()"
                                       [isError]='true'
                                       message="The template name is required."></adm4-validation-message>
            </div>
          </div>
          <div class="form-group" [class.has-error]="shouldShowDescriptionInvalidState()">
            <label for="ct-short-desc" class="input-label">Short description*</label>
            <textarea id="ct-short-desc" placeholder="Max. 100 characters" formControlName="shortDescription" [maxlength]="DESCRIPTION_MAX_LENGTH + 1"
                      class="form-control admn4-textarea-input"></textarea>
            <div class="validation-message-container">
              <adm4-validation-message *ngIf="shouldShowDescriptionRequiredMsg()"
                                       [isError]='true'
                                       message="The description is required."></adm4-validation-message>
              <adm4-validation-message *ngIf="shouldShowDescriptionMaxLengthMsg()"
                                       [isError]='true'
                                       message="The description is allowed to be maximum 100 characters."></adm4-validation-message>
            </div>
          </div>
          <div class="form-group" [class.has-error]="shouldShowCategoryInvalidState()">
            <label for="ct-category" class="input-label mb-0">Category*</label>
            <mat-form-field class="d-block">
              <mat-chip-grid #chipList aria-label="Category selection">
                <mat-chip-row
                  *ngFor="let selectedCategory of (selectedCategories$ | async)"
                  (removed)="removeChip(selectedCategory)">
                  {{selectedCategory}}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </mat-chip-grid>
              <input id="ct-category"
                placeholder="Type to add categories..."
                #categoryInput
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (change)="matChipInputMarkDirty()" 
                (focus)="matChipInputMarkDirty()"
                (matChipInputTokenEnd)="matChipInputTokenEnd($event)">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
                <mat-option *ngFor="let cat of filteredCategories | async" [value]="cat" class="option-large">
                  <span [innerHTML]="cat | highlight: (categorySearch$ | async)"></span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="validation-message-container">
              <adm4-validation-message *ngIf="shouldShowCategoryRequiredMsg()"
                                       [isError]='true'
                                       message="The category is mandatory."></adm4-validation-message>
            </div>
            <div class="category-selector-hint d-flex justify-content-start">
              <i class="fa fa-info-circle"></i>
              <p>Press enter to add custom categories, or use the cursor keys to select the existing ones.</p>
            </div>
          </div>
        </div>
        <div mat-dialog-actions>
          <adm4-button-bar [isSubmitDisabled]="form.invalid || !form.dirty" [noForm]="true"
                           submitButtonText="Save"
                           (cancelClicked)='closeWithoutSaving()' (submitClicked)="save()" 
          ></adm4-button-bar>
        </div>
      </form>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['./create-custom-project-template-dialog.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-mat-dialog'"},
})
export class CreateCustomProjectTemplateDialogComponent implements OnDestroy, AfterViewInit {

  private readonly destroyed$: Subject<void> = new Subject();
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public readonly DESCRIPTION_MAX_LENGTH = 100;

  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;

  public _projectSearchInputFocusTrigger$: Subject<void> = new Subject<void>();
  public projectSearchInputFocusTrigger$: Observable<void> = this._projectSearchInputFocusTrigger$.asObservable();
  public _categorySearch$: Subject<Maybe<string>> = new Subject();
  public categorySearch$: Observable<Maybe<string>> = this._categorySearch$.asObservable();

  public selectedTenantKey: Observable<string>;

  public readonly form: FormGroup<{
    projectKey: FormControl<Maybe<string>>,
    templateName: FormControl<Maybe<string>>,
    shortDescription: FormControl<Maybe<string>>,
    categories: FormControl<string[]>,
  }>;

  public categoriesControl: FormControl<string[]>;
  public readonly selectedCategories$: Observable<string[]>;

  public readonly search: Subject<string> = new Subject();
  public readonly filteredProjectKeys: Observable<string[]>;
  public readonly allCategories: Observable<string[]>;
  public readonly filteredCategories: Observable<string[]>;

  public readonly noFilteredResults: Observable<boolean>;
  public readonly noProjects: Observable<boolean>;

  constructor(
      private store$: Store<AppState>,
      @Inject(MAT_DIALOG_DATA) public data: CreateCustomProjectTemplateDialogConfig,
      private dialogRef: MatDialogRef<CreateCustomProjectTemplateDialogComponent, ProjectTemplate>,
      private marketplaceApi: MarketplaceApiService,
      private modals: ModalNotificationService,
      fb: FormBuilder,
  ) {
    this.store$.dispatch(new LoadProjects());
    this.selectedTenantKey = store$.select(selectedTenantKeyView).pipe(filter(filterEmpty));
    const allProjectKeys: Observable<string[]> = this.store$.select(allProjectsView).pipe(
      map((projects: Record<string, Project>): string[] => Object.keys(projects)),
    );
    this.filteredProjectKeys = combineLatest([
      allProjectKeys,
      // if the project selector is disabled, the search doesn't emit
      merge(this.search, of('')),
    ]).pipe(
        map(([projectKeys, search]: [string[], Maybe<string>]): string[] => {
          return projectKeys.filter((projectKey: string): boolean => {
            return !search || projectKey.toLowerCase().includes(search.toLowerCase());
          });
        }),
    );
    this.allCategories = store$.select(allProjectTemplatesView).pipe(
        map((projects: ProjectTemplate[]): string[] => {
          const uniqueCategories: Set<string> = new Set();
          projects.map(p => p.categories).forEach((categories: string[]) => {
            categories.forEach((category: string) => uniqueCategories.add(category));
          });
          return Array.from(uniqueCategories.values());
        }),
    );

    this.noFilteredResults = combineLatest([allProjectKeys, this.filteredProjectKeys]).pipe(
      map(([allProjects, filteredProjectKeys]: [string[], string[]]): boolean => allProjects.length !== 0 && filteredProjectKeys.length === 0),
    );

    this.noProjects = allProjectKeys.pipe(
      map((projectKeys: string[]): boolean => projectKeys.length === 0),
    );

    const selectedProjectKey: Maybe<string> = data.selectedProjectKey ? data.selectedProjectKey : undefined;

    this.categoriesControl = new FormControl<string[]>(
        {value: [], disabled: false},
        {validators: [Adm4FormValidators.requiredArray], nonNullable: true},
    );

    this.form = fb.group({
      projectKey: new FormControl<Maybe<string>>(
          {value: selectedProjectKey, disabled: !!selectedProjectKey},
          {validators: [Validators.required]}),
      templateName: new FormControl<Maybe<string>>(
          {value: undefined, disabled: false},
          {validators: [Validators.required]}),
      shortDescription: new FormControl<Maybe<string>>(
          {value: undefined, disabled: false},
          {validators: [Validators.required, Validators.maxLength(this.DESCRIPTION_MAX_LENGTH)]}),
      categories: this.categoriesControl,
    });
    this.selectedCategories$ = this.form.valueChanges.pipe(
        takeUntil(this.destroyed$),
        map(formValue => formValue.categories ?? []),
    );

    this.filteredCategories = combineLatest([
      this.allCategories,
      merge(this.selectedCategories$, of([])),
      merge(this.categorySearch$, of(undefined)),
    ]).pipe(
        takeUntil(this.destroyed$),
        map(([allCategories, selectedCategories, categorySearch]: [string[], string[], Maybe<string>]): string[] => {
          return allCategories
              .filter((currentCategory: string): boolean => {
                const alreadySelected: boolean = selectedCategories.some((selectedCategory: string): boolean => currentCategory === selectedCategory);
                return !alreadySelected && (!categorySearch || currentCategory.toLowerCase().includes(categorySearch.toLowerCase()));
              });
        }),
    );
  }

  ngAfterViewInit(): void {
    fromEvent(this.categoryInput.nativeElement, 'input').pipe(
        takeUntil(this.destroyed$),
    ).subscribe((event: InputEvent) => {
      const rawValue = (event.target as any)?.value;
      const search: Maybe<string> = (typeof rawValue === 'string' || rawValue === undefined) ? rawValue : undefined;
      this._categorySearch$.next(search);
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public closeWithoutSaving(): void {
    this.dialogRef.close();
  }

  public removeChip(removed): void {
    const oldValue = this.categoriesControl.getRawValue();
    this.categoriesControl.setValue(oldValue.filter(v => v !== removed));
  }

  public projectSelectToggled(isOpen: boolean): void {
    if (isOpen) {
      this._projectSearchInputFocusTrigger$.next();
    }
  }

  public optionSelected(event: MatAutocompleteSelectedEvent): void {
    this.categoriesControl.markAsDirty();
    this.categoryInput.nativeElement.value = '';
    const oldValue: string[] = this.categoriesControl.getRawValue();
    oldValue.push(event.option.viewValue);
    this.categoriesControl.setValue([...oldValue]);
  }

  public matChipInputMarkDirty(): void {
    this.categoriesControl.markAsDirty();
  }

  public matChipInputTokenEnd(event: MatChipInputEvent): void {
    this.categoriesControl.markAsDirty();
    const textInput = (event.value || '').trim();
    if (textInput && textInput !== '') {
      const controlValue = this.categoriesControl.getRawValue();
      if (!controlValue.includes(textInput)) {
        controlValue.push(textInput);
      }
      this.categoriesControl.setValue([...controlValue]);
      this._categorySearch$.next(undefined);
      event.chipInput!.clear();
    }
  }

  public save(): void {
    if (this.form.valid) {
      const formValue = this.form.value;
      const projectKey: Maybe<string> = this.form.controls.projectKey.value;  // this way it's present even if disabled
      this.selectedTenantKey.pipe(take(1)).subscribe((tenantKey: string) => {
        this.marketplaceApi.createProjectFromTemplate(
            tenantKey,
            projectKey!,
            formValue.templateName!,
            formValue.shortDescription!,
            formValue.categories!,
        ).pipe(
            take(1),
            catchError((error: HttpErrorResponse) => {
              this.modals.openHttpErrorDialog(error, 'Could not create the custom project template.');
              return EMPTY;
            }),
        ).subscribe((pt: ProjectTemplate): void => {
          this.dialogRef.close(pt);
        });
      });
    }
  }

  public shouldShowPrjRequiredMsg(): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls.projectKey, FormHelper.VALIDATION_KEY_REQUIRED);
  }

  public shouldShowPrjInvalidState(): boolean {
    return FormHelper.shouldShowFormControlInvalid(this.form.controls.projectKey);
  }

  public shouldShowTemplateNameRequiredMsg(): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls.templateName, FormHelper.VALIDATION_KEY_REQUIRED);
  }

  public shouldShowTemplateNameInvalidState(): boolean {
    return FormHelper.shouldShowFormControlInvalid(this.form.controls.templateName);
  }

  public shouldShowCategoryRequiredMsg(): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls.categories, FormHelper.VALIDATION_KEY_REQUIRED);
  }

  public shouldShowCategoryInvalidState(): boolean {
    return FormHelper.shouldShowFormControlInvalid(this.form.controls.categories);
  }

  public shouldShowDescriptionRequiredMsg(): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls.shortDescription, FormHelper.VALIDATION_KEY_REQUIRED);
  }

  public shouldShowDescriptionInvalidState(): boolean {
    return FormHelper.shouldShowFormControlInvalid(this.form.controls.shortDescription);
  }

  public shouldShowDescriptionMaxLengthMsg(): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls.shortDescription, FormHelper.VALIDATION_KEY_MAX_LENGTH);
  }
}

import { distinctUntilChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DeploymentProcessModel, DeploymentProcessState } from './deployment-process.model';
import { Inventory, InventorySchemaType } from '../../inventory/inventory.model';
import { combineLatest, Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { DeploymentFileViewService } from '../deployment-file-view/deployment-file-view.service';
import { DeploymentFileInfo } from '../deployment-file-view/deployment-file-urls.model';
import { DeploymentStep } from './deployment-step.enum';
import { InventoryColorHelper } from '../../common/helpers/inventory-color.helper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DeploymentProcessService } from '../deployment-process.service';
import { DeploymentSelection } from '../deployment-selection/deployment-selection.model';
import { DeploymentDialogHelper } from './deployment-dialog.helper';
import { NavigationService } from '../../navbar/navigation.service';
import { PreviewDeploymentPayload } from '../model/preview-deployment-payload.model';
import { WarningValidationDialogComponent } from '../validate-deployment/warning-dialog/warning-validation-dialog.component';
import { DeploymentWizardContext } from '../deployment-wizard.context';
import { deploymentGenerationIsInProgressView, deploymentPlanningForcedView, deploymentPlanningOutputView, deploymentWizardSelectionView, deployProcessView, selectedProjectForDeployment } from '../../model/views';
import { DeploymentPreviewHelper } from '../deploy/deployment-preview.helper';
import { PlanningOutputModel } from '../deploy/deployment-preview/planning-output.model';
import * as _ from 'lodash';
import { Project } from '../../projects/project.model';
import { PropertyWidgetContext } from '../../property-widgets/property-widget.context';
import { DeploymentPropertyContext } from '../deployment-properties-context.service';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';
import { ValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { ValidationStatusHelper } from '../../common/helpers/validation-status.helper';
import { Keys } from '../../common/utils/keypress.utils';

@Component({
  selector: 'adm4-deployment-dialog',
  templateUrl: './deployment-dialog.component.html',
  styleUrls: ['./deployment-dialog.component.scss'],
  providers: [DeploymentWizardContext,
    {provide: PropertyWidgetContext, useClass: DeploymentPropertyContext}]
})
export class DeploymentDialogComponent implements OnInit, OnDestroy {

  @ViewChild('horizontalStepper', {static: false}) horizontalStepper: MatStepper;

  deploymentSelection: DeploymentSelection;
  /**
   * Keeps track of currently reached step of deployment wizard (not currently displayed)
   */
  currentStep: DeploymentStep;
  dialogTitle: string;
  deploymentProcess$: Observable<DeploymentProcessModel | null>;
  deploymentGenerationIsInProgress$: Observable<boolean | null>;
  selectedInventoryKey$: Observable<string | undefined>;
  selectedInventorySchemaType$: Observable<InventorySchemaType | undefined>;
  inventoryValidationStatus$: Observable<ValidationStatus<ValidationIssue> | null>;
  deploymentProcessId$: Observable<string | null>;
  viewingFileView$: Observable<DeploymentFileInfo | null>;
  backgroundClass: 'default-inventory-background';
  headerClass: string;
  boxShadowClass = 'default-inventory-box-shadow';
  shouldBypassClosingGuard: boolean;
  /**
   * Indicates if there is nothing to deploy or it is not possible to deploy because of some issues with the target host(s).
   */
  nothingToDeploy: boolean;
  selectedProject: Project;
  deploymentComment = '';

  private destroyed$: Subject<boolean> = new Subject();

  constructor(public dialogRef: MatDialogRef<DeploymentDialogComponent>,
              private dialog: MatDialog,
              private deploymentWizardContext: DeploymentWizardContext,
              private store$: Store<AppState>,
              private deploymentProcessService: DeploymentProcessService,
              private navigationService: NavigationService,
              fileViewer: DeploymentFileViewService) {
    this.viewingFileView$ = fileViewer.viewingFileInfo;
    this.inventoryValidationStatus$ = this.deploymentWizardContext.inventoryValidationStatus$;
    this.selectedInventoryKey$ = this.deploymentWizardContext.selectedInventoryKey$;
    this.selectedInventorySchemaType$ = this.deploymentWizardContext.selectedInventorySchemaType$;
  }

  // TODO improve naming so that it's not confusing with guards
  // TODO improve return value to be more self explanatory
  @HostListener('window:beforeunload')
  canDeactivate(): boolean | undefined {
    // check if there are pending changes here;
    if (this.currentStep > DeploymentStep.Selection) {
      return false;
    }
    return undefined;
  }

  ngOnInit() {
    this.dialogRef.keydownEvents()
      .pipe(
        filter((event: KeyboardEvent) => event.key === Keys.ESCAPE),
        takeUntil(this.dialogRef.beforeClosed()),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.closeDialog());

    this.store$.pipe(select(deploymentWizardSelectionView))
      .pipe(
        withLatestFrom(this.store$.pipe(select(selectedProjectForDeployment))),
        distinctUntilChanged(_.isEqual),
        takeUntil(this.destroyed$))
      .subscribe(([deploymentSelection, selectedProject]: [DeploymentSelection, Project]) => {
        this.deploymentSelection = deploymentSelection;
        this.selectedProject = selectedProject;
        this.dialogTitle = DeploymentDialogHelper.resolveDialogTitleFromDeploymentSelection(deploymentSelection);
        this.onDeploymentSelectionChanged();
      });
    // to prevent confirmation dialog when trying to close wizard in case step 3 doesn't have any tasks
    combineLatest([
      this.store$.pipe(select(deploymentPlanningOutputView)),
      this.store$.pipe(select(deploymentPlanningForcedView))
    ])
      .pipe(
        filter(([planOutput]: [PlanningOutputModel[], boolean]) => !_.isEmpty(planOutput)),
        map(([planOutput, redeploymentForced]: [PlanningOutputModel[], boolean]) => DeploymentPreviewHelper.hasPlanningOutputTasks(planOutput) || redeploymentForced),
        takeUntil(this.destroyed$)
      ).subscribe((canDeploy: boolean) => {
        this.nothingToDeploy = !canDeploy;
      });
    this.deploymentProcess$ = this.store$.pipe(select(deployProcessView));
    this.deploymentGenerationIsInProgress$ = this.store$.pipe(select(deploymentGenerationIsInProgressView));
    this.deploymentProcessId$ = this.deploymentProcess$.pipe(map(deployment => deployment ? deployment.deploymentId : null));
    this.currentStep = DeploymentStep.Selection;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  inventoryHasErrors(inventoryValidationStatus: ValidationStatus<ValidationIssue>): boolean {
    return !ValidationStatusHelper.isValidationValid(inventoryValidationStatus);
  }

  isNothingToDeploy(deploymentProcess: DeploymentProcessModel): boolean {
    return deploymentProcess && deploymentProcess.state === DeploymentProcessState.Initialized;
  }

  isFixIssuesStep(deploymentProcess: DeploymentProcessModel): boolean {
    return deploymentProcess && (deploymentProcess.state === DeploymentProcessState.Generating || deploymentProcess.state === DeploymentProcessState.Generated);
  }

  shouldRenderFixIssues(deploymentProcess: DeploymentProcessModel, inventoryValidationStatus: ValidationStatus<ValidationIssue>): boolean {
    return !this.inventoryHasErrors(inventoryValidationStatus) && (this.isFixIssuesStep(deploymentProcess) || this.currentStep > DeploymentStep.Validation);
  }

  isPreviewDeploymentStep(deploymentProcess: DeploymentProcessModel): boolean {
    return deploymentProcess && (deploymentProcess.state === DeploymentProcessState.Planning || deploymentProcess.state === DeploymentProcessState.Planned);
  }

  shouldRenderPreviewDeployment(deploymentProcess: DeploymentProcessModel): boolean {
    return this.isPreviewDeploymentStep(deploymentProcess) || this.currentStep > DeploymentStep.Preview;
  }

  isDeployStep(deploymentProcess: DeploymentProcessModel): boolean {
    return deploymentProcess && (deploymentProcess.state === DeploymentProcessState.Deploying || deploymentProcess.state === DeploymentProcessState.Deployed);
  }

  onChangeInventoryAfterNothingToDeploy(): void {
    this.deploymentProcessService.clearDeployProcess();
    this.currentStep = DeploymentStep.Selection;
  }

  onValidateDeployment(deployment: DeploymentProcessModel): void {
    if (this.currentStep === DeploymentStep.Selection) {
      this.deploymentProcessService.startDeploymentProcess(deployment);
      this.deploymentWizardContext.loadProjectData(deployment.projectKey);
      this.currentStep = DeploymentStep.Validation;
    }
  }

  onInventorySelected(inventory: Inventory): void {
    this.headerClass = InventoryColorHelper.getInventoryHeaderClassName(inventory.color);
    this.boxShadowClass = InventoryColorHelper.getInventoryBoxShadowClassName(inventory.color);
  }

  onDeploymentSelectionChanged(): void {
    if (this.currentStep > DeploymentStep.Selection) {
      this.deploymentProcessService.clearDeployProcess();
      this.currentStep = DeploymentStep.Selection;
    }
  }

  onPreviewDeployment(previewDeploymentPayload: PreviewDeploymentPayload): void {
    if (previewDeploymentPayload.generationIssues.length > 0 && this.currentStep === DeploymentStep.Validation) {
      this.confirmDeploymentWithWarning(previewDeploymentPayload);
    } else if (this.currentStep === DeploymentStep.Validation) {
      // when we go to next step for the first time, planning should be triggered
      this.handleDeploymentPreview(previewDeploymentPayload.deploymentId);
    } else {
      // when next step was already reached and no changes were done again, should just switch between views without any data operations
      this.horizontalStepper.next();
    }
  }

  private confirmDeploymentWithWarning(previewDeploymentPayload: PreviewDeploymentPayload) {
    // when there are issues and we go to next step for the first time, show modal to ask if warnings should be included
    const dialogRef = this.dialog.open(WarningValidationDialogComponent, {
      data: previewDeploymentPayload.generationIssues,
      width: '40%',
      disableClose: true
    });

    closeModalOnEscape(dialogRef, this.destroyed$);

    dialogRef.afterClosed().subscribe((nextStepAvailable: boolean) => {
      if (nextStepAvailable) {
        this.handleDeploymentPreview(previewDeploymentPayload.deploymentId);
      }
    });
  }

  handleDeploymentPreview(deploymentId) {
    this.horizontalStepper.next();
    this.deploymentProcessService.startDeploymentPlanning(deploymentId);
    this.currentStep = DeploymentStep.Preview;
  }

  onRepeatValidation(deploymentId: string) {
    this.deploymentProcessService.startGeneration(deploymentId);
    this.currentStep = DeploymentStep.Validation;
  }

  onDeploy(deploymentId: string): void {
    this.deploymentProcessService.startDeployment(deploymentId, this.deploymentComment);
  }

  onAbortGeneration(deploymentId: string): void {
    this.deploymentProcessService.abortGeneration(deploymentId);
    this.currentStep = DeploymentStep.Selection;
    this.horizontalStepper.previous();
  }

  onAbortPlanning(deploymentId: string): void {
    this.deploymentProcessService.abortDeploymentPlanning(deploymentId);
    this.currentStep = DeploymentStep.Validation;
    this.horizontalStepper.previous();
  }

  onRepeatPlanning(deploymentId: string): void {
    this.deploymentProcessService.startDeploymentPlanning(deploymentId);
  }

  onForceRedeployment(deploymentId: string): void {
    this.deploymentProcessService.forceRedeployment(deploymentId);
  }

  onDoneStateReached(): void {
    this.currentStep = DeploymentStep.Done;
  }

  closeDialog(): void {
    this.shouldBypassClosingGuard = this.currentStep < DeploymentStep.Validation || this.currentStep > DeploymentStep.Deployment;
    this.navigationService.navigateAwayFromModalWindow();
  }

  navigateToInventory(inventoryKey: string): void {
    // No need to confirm navigating away this time.
    this.shouldBypassClosingGuard = true;
    this.navigationService.navigateAwayFromModalWindow().then(() => this.navigationService.navigateToInventory(inventoryKey, true));
  }

  setDeploymentComment(comment: string) {
    this.deploymentComment = comment;
  }
}

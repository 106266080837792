import { Component, forwardRef } from '@angular/core';
import { WidgetComponent } from './widget.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * This component represents a default widget that means whenever there's a problem with a certain widget this one
 * is displayed
 */
@Component({
  selector: 'adm4-fallback-property',
  template: `
    <div [formGroup]='group'>
        <textarea class='form-control textarea-property'
                  [formControlName]='widgetProperty.propertyKey' [name]='name' #textArea
                  [readonly]='readOnly'
                  (blur)='onValidate($event)' (focus)='onSelect($event, textArea)'></textarea>
    </div>
  `,
  styleUrls: ['widget.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FallbackPropertyComponent),
    multi: true
  }]
})
export class FallbackPropertyComponent extends WidgetComponent {}

import * as _ from 'lodash';
import { DeployedHostError, DeploymentHostStatusResponseModel } from './inventory-status-item.model';

export enum InventoryStatusHelperConstants {
  HOST = 'HOST',
  INVENTORY = 'INVENTORY'
}

export class InventoryStatusHelper {
  private static DEFAULT_UNREACHABLE_HOST_MESSAGE = 'Host is not reachable.';

  static hostResponseHasWarning(deploymentHostResponse: DeploymentHostStatusResponseModel): boolean {
    return !_.isNil(deploymentHostResponse._status) && !_.isNil(deploymentHostResponse._status._warnings) && !_.isEmpty(deploymentHostResponse._status._warnings);
  }

  static unwrapHostWarnings(deploymentHostResponse: DeploymentHostStatusResponseModel): DeployedHostError[] {
    const hostWarnings = deploymentHostResponse._status._warnings;
    return hostWarnings.map(warning => {
      if (_.isEmpty(warning.sources)) {
        return;
      }
      return {host: warning.sources[0][InventoryStatusHelperConstants.HOST], detail: `<b>${(InventoryStatusHelper.DEFAULT_UNREACHABLE_HOST_MESSAGE)}</b><br> ${warning.detail}`, isError: false};
    });
  }

  static unwrapInventoryWarnings(deploymentHostResponse: DeploymentHostStatusResponseModel, inventoryKey: string): string | undefined {
    const hostStatusWarnings = deploymentHostResponse._status._warnings;
    if (_.isNil(hostStatusWarnings) || _.isEmpty(hostStatusWarnings) || _.isNil(hostStatusWarnings[0].sources)) {
      return;
    }
    return _.isEqual(hostStatusWarnings[0].sources[0][InventoryStatusHelperConstants.INVENTORY], inventoryKey)
      && !_.isNil(hostStatusWarnings[0].detail) ? hostStatusWarnings[0].detail : undefined;
  }
}

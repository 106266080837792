import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { DeploymentService } from '../deployment-dialog/deployment.service';
import { GenerationStatusModel } from './generation-status.model';
import { combineLatest, Observable } from 'rxjs';
import { IssueModel } from '../../common/model/issue.model';
import { Pattern } from '../../patterns/pattern.model';
import { GenerationOutputModel } from '../deploy/deployment-preview/planning-output.model';
import { PreviewDeploymentPayload } from '../model/preview-deployment-payload.model';
import { DeploymentWizardContext } from '../deployment-wizard.context';
import { ValidateDeploymentContext } from './validate-deployment.context';
import { PatternIssueData } from '../../issues/model/pattern-issue-data.model';
import { VariableModel } from '../../variables/variable.model';
import { debounceTime, first, map } from 'rxjs/operators';
import { InventorySchemaType } from '../../inventory/inventory.model';

@Component({
  selector: 'adm4-validate-deployment',
  templateUrl: './validate-deployment.component.html',
  providers: [ValidateDeploymentContext]
})
export class ValidateDeploymentComponent implements AfterViewInit {
  @Input() deploymentId: string;
  @Input() boxShadowClass: string;
  @Output() previewDeploymentClicked: EventEmitter<PreviewDeploymentPayload> = new EventEmitter();
  @Output() abortGeneration: EventEmitter<string> = new EventEmitter();
  @Output() repeatValidation: EventEmitter<string> = new EventEmitter();
  // tooltip template refs for disabled next step button
  @ViewChild('noPermissionToProceedTooltip', {static: false}) noPermissionTooltipTemplate: TemplateRef<any>;
  @ViewChild('generationFullyFailedTooltip', {static: false}) generationFullyFailedTooltipTemplate: TemplateRef<any>;
  @ViewChild('publishRequiredTooltip', {static: false}) publishRequiredTooltipTemplate: TemplateRef<any>;
  // end of // tooltip template refs for disabled next step button

  generationStatus$: Observable<GenerationStatusModel | undefined>;
  generationIssues$: Observable<IssueModel[]>;
  generalIssues$: Observable<IssueModel[]>;
  patterns$: Observable<Map<string, Pattern>>;
  generationOutput$: Observable<GenerationOutputModel[]>;
  isValidationInProgress$: Observable<boolean>;
  isGenerationFailed$: Observable<boolean>;
  isValidationSuccessful$: Observable<boolean>;
  isGenerationResultVisible = false;
  patternIssueGroups$: Observable<PatternIssueData[]>;
  projectKey$: Observable<string | undefined>;
  selectedInventoryKey$: Observable<string | undefined>;
  selectedInventorySchemaType$: Observable<InventorySchemaType | undefined>;
  variables$: Observable<VariableModel[]>;
  hasDeployPermission$: Observable<boolean>;
  publishRequired$: Observable<boolean>;
  disablePreviewDeployment$: Observable<boolean>;
  shouldShowValidationResults$: Observable<boolean>;
  shouldShowGenerationResultsButton$: Observable<boolean>;
  shouldDisplayGeneralIssues$: Observable<boolean>;
  nextStepDisabledTooltipTemplate$: Observable<TemplateRef<any> | null>;

  constructor(private deploymentService: DeploymentService,
              private deploymentWizardContext: DeploymentWizardContext,
              private validateDeploymentContext: ValidateDeploymentContext) {
    this.patterns$ = this.deploymentWizardContext.patterns;
    this.generationStatus$ = this.validateDeploymentContext.generationStatus$;
    this.generationIssues$ = this.validateDeploymentContext.generationIssues$;
    this.generalIssues$ = this.validateDeploymentContext.generalIssues$;
    this.isValidationInProgress$ = this.validateDeploymentContext.isValidationInProgress$;
    this.isGenerationFailed$ = this.validateDeploymentContext.isGenerationFailed$;
    this.isValidationSuccessful$ = this.validateDeploymentContext.isValidationSuccessful$;
    this.generationOutput$ = this.validateDeploymentContext.generationOutput$;
    this.patternIssueGroups$ = this.validateDeploymentContext.patternIssueGroups$;
    this.projectKey$ = this.validateDeploymentContext.projectKey$;
    this.hasDeployPermission$ = this.validateDeploymentContext.hasDeployPermission$;
    this.variables$ = this.deploymentWizardContext.variables;
    this.publishRequired$ = this.validateDeploymentContext.publishRequired$;
    this.selectedInventoryKey$ = this.validateDeploymentContext.selectedInventoryKey$;
    this.selectedInventorySchemaType$ = this.deploymentWizardContext.selectedInventorySchemaType$;
    this.disablePreviewDeployment$ = this.validateDeploymentContext.disablePreviewDeployment$;
    this.shouldShowValidationResults$ = this.validateDeploymentContext.shouldShowValidationResults$;
    this.shouldShowGenerationResultsButton$ = this.validateDeploymentContext.shouldShowGenerationResultsButton$;
    this.shouldDisplayGeneralIssues$ = this.validateDeploymentContext.shouldDisplayGeneralIssues$;
  }

  ngAfterViewInit(): void {
    this.nextStepDisabledTooltipTemplate$ = combineLatest([this.hasDeployPermission$, this.isGenerationFailed$, this.publishRequired$])
      .pipe(
        debounceTime(0),
        map(([hasPermission, isGenerationFullyFailed, publishRequired]: [boolean, boolean, boolean]) => this.resolveNextStepDisabledTooltipTemplate(hasPermission, isGenerationFullyFailed, publishRequired)),
      );
  }

  resolveNextStepDisabledTooltipTemplate(hasPermission: boolean, isGenerationFullyFailed: boolean, publishRequired: boolean): TemplateRef<any> | null {
    if (!hasPermission) {
      return this.noPermissionTooltipTemplate;
    } else if (isGenerationFullyFailed) {
      return this.generationFullyFailedTooltipTemplate;
    } else if (publishRequired) {
      return this.publishRequiredTooltipTemplate;
    }
    return null;
  }

  onRepeatValidation(): void {
    this.isGenerationResultVisible = false;
    this.repeatValidation.emit(this.deploymentId);
  }

  displayGenerationOutput(): void {
    this.isGenerationResultVisible = true;
    this.validateDeploymentContext.loadGenerationOutput(this.deploymentId);
  }

  onIssueGroupSelect(issues: IssueModel[]): void {
    this.validateDeploymentContext.selectIssues(issues);
  }

  onAbortGeneration(): void {
    this.validateDeploymentContext.canBeAborted$.pipe(first()).subscribe((canBeAborted: boolean) => {
      if (canBeAborted) {
        this.abortGeneration.emit(this.deploymentId);
      }
    });
  }

  onPreviewDeploymentClick(): void {
    this.generationIssues$.pipe(first())
      .subscribe((generationIssues: IssueModel[]) => {
        this.previewDeploymentClicked.emit({
          deploymentId: this.deploymentId,
          generationIssues: generationIssues
        });
      });
  }

  onDownloadGenerationResultClick(): void {
    this.validateDeploymentContext.downloadGenerationResult();
  }
}

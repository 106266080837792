import { first, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PermissionAssignee } from '../../../model/permissions/permissions.model';
import { OperationColumnData } from '../../../permissions/model/operation-column-data.model';
import { InventoryPermissionsContext } from './inventory-permissions.context';
import { inventoryOperationColumns } from './inventory-operation-columns.constant';
import { PermissionsService } from '../../../permissions/permissions.service';
import { PermissionsDialogService } from '../../../permissions/permissions-dialog.service';
import { ToastNotificationService } from '../../../notification/toast-notification.service';
import { select, Store } from '@ngrx/store';
import { hasInventoryAdminAccessView } from '../../../model/views/permission.views';
import { AppState } from '../../../model/reducer';

@Component({
  selector: 'adm4-inventory-permissions',
  template: `
    <div class="permissions-table-container">
      <div class='section-title'>Permissions</div>
      <div class='table-container'>
        <adm4-permissions-table
                [target]="target$ | async"
                [permissionAssignees]="permissionAssignees$ | async"
                [operationColumns]='operationColumns'
                [editMode]="false"
        ></adm4-permissions-table>
        <div class="button-container" *ngIf='hasAdminAccess$ | async'>
          <button class="admn4-button-ellipse-blue" (click)='openPermissionsEditTable()'>Edit</button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./inventory-permissions.component.scss'],
  providers: [InventoryPermissionsContext]
})
export class InventoryPermissionsComponent implements OnDestroy {
  target$: Observable<string>;
  permissionAssignees$: Observable<PermissionAssignee[]>;
  hasAdminAccess$: Observable<boolean>;
  readonly operationColumns: OperationColumnData[] = inventoryOperationColumns;
  readonly destroyed$: Subject<boolean> = new Subject();

  constructor(private inventoryPermissionsContext: InventoryPermissionsContext,
              private permissionsService: PermissionsService,
              private projectPermissionsDialogService: PermissionsDialogService,
              private toastNotificationService: ToastNotificationService,
              store$: Store<AppState>) {
    this.target$ = this.inventoryPermissionsContext.target$;
    this.permissionAssignees$ = this.inventoryPermissionsContext.permissionAssignees$;
    this.hasAdminAccess$ = store$.pipe(select(hasInventoryAdminAccessView));
  }

  openPermissionsEditTable(): void {
    const onSave = (permissionModifications) => {
      this.permissionsService.updatePermissions(permissionModifications)
        .subscribe(
          () => this.inventoryPermissionsContext.loadPermissionAssignees(),
          () => this.toastNotificationService.showErrorToast('Could not save permissions. Something went wrong.')
        );
    };
    this.inventoryPermissionsContext.target$.pipe(first(),
      switchMap(target => {
        return this.permissionsService.getPermissionAssigneesForTarget(target, this.inventoryPermissionsContext.allowedOperationKeys, true, true);
      }),
      withLatestFrom(this.inventoryPermissionsContext.target$),
      takeUntil(this.destroyed$))
      .subscribe(([inventoryPermissionAssignees, target]) => {
        this.projectPermissionsDialogService.openPermissionsEditTable(target, inventoryPermissionAssignees, inventoryOperationColumns, onSave);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}

import { ParsedBundle } from '../bundle-management/bundle.model';
import { BundleManagementActions, BundleManagementActionTypes } from './bundle-management.actions';


export interface BundleManagementState {
  bundles: ParsedBundle[];
}

export const initialState: BundleManagementState = {
  bundles: []
};

export function reducer(state = initialState, action: BundleManagementActions): BundleManagementState {
  switch (action.type) { // NOSONAR reducers depend on action types and with switch it's easily scalable
    case BundleManagementActionTypes.LoadBundlesSuccess:
      return {
        ...state,
        bundles: action.payload
      };
    default:
      return state;
  }
}

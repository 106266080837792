<div class='full-height'>
  <as-split class="hide-as-split-gutter" direction='horizontal' gutterSize='1' useTransition (dragEnd)='onResize($event)'>
    <as-split-area [size]='splitPaneConfig[listSplitAreaKey].size' [minSize]='20'>
      <div class='full-height-flex'>
        <adm4-column-header styleClass="project-header">
          <adm4-project-header
                  [projectKey]='projectKey$ | async'
                  [projectIssues]='allIssues$ | async'
                  [highestSeverity]="highestSeverity$ | async"
                  [projects]='projects$ | async'
                  [projectMetaIndicator]="projectMetaChangeIndicator$ | async"
          ></adm4-project-header>
        </adm4-column-header>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <adm4-pattern-master-list
                    class="pattern-list-container"
                    [selection]="patternId$ | async"
                    [patterns]="patternList$ | async"
                    [plugins]="pluginsByPatternType$ | async"
                    [projectKey]='projectKey$ | async'
                    [vcStatusOfProject]='vcInfoOfProject$ | async'
                    [readOnly]='readOnly$ | async'
                    [versioned]='versioned$ | async'>
            </adm4-pattern-master-list>
          </div>
        </div>
      </div>
    </as-split-area>
    <ng-template #projectSummary>
      <as-split-area [size]='summaryAreaSize' [minSize]='20'>
        <adm4-project-summary *ngIf='isSummaryRouteActive' [currentProjectKey]='projectKey$ | async'
                              [pluginsByPatternType]='pluginsByPatternType$ | async'
                              [patternList]='patternList$ | async'
                              [projectStatusModel]="vcInfoOfProject$ | async"
                              [variables]='variables$ | async'
                              [issues]='allIssues$ | async'
                              [versioned]='versioned$ | async'
                              [reports]='reports$ | async'></adm4-project-summary>
      </as-split-area>
    </ng-template>
    <as-split-area *ngIf='(pattern$ | async); else projectSummary' [size]='splitPaneConfig[editorSplitAreaKey].size'
                   [minSize]='20'>
      <div class='full-height'>
        <ng-container
                *ngIf="(patternId$ | async) && (pattern$ | async) && (patternTypes$ | async) && (propertyTypes$ | async)">
          <adm4-pattern-editor
            #propertyListComp
            [issues]='patternIssues$ | async'
            [patternTypes]='patternTypes$ | async'
            [propertyTypes]="propertyTypes$ | async"
            [pattern]='pattern$ | async'
            [patternMetaInfo]='patternMetaInfo$ | async'
            [projectKey]='projectKey$ | async'
            [selectedPropertyKey]="selectedPropertyKey$ | async"
            [variables]='variables$ | async'
            [readOnly]='readOnly$ | async'
            [isHelpCollapsed]='splitPaneConfig[helpSplitAreaKey].isCollapsed'
            [hasProjectModificationPermission]='hasProjectModificationPermission$ | async'
            (selectionChanged)='selectProperty($event)'
            (propertyHasChanged)="onPropertyHasChanged($event)"
            (expandHelp)='onCollapse(false, helpSplitAreaKey)'
            (labelViewClicked)='switchToLabelView()'>
          </adm4-pattern-editor>
        </ng-container>
      </div>
    </as-split-area>
    <as-split-area *ngIf='pattern$ | async' [visible]='!splitPaneConfig[helpSplitAreaKey].isCollapsed'
                   [size]='splitPaneConfig[helpSplitAreaKey].size' [minSize]='30'>
      <adm4-pattern-help
              *ngIf='(patternId$ | async) && (pattern$ | async) && (propertyTypes$ | async) && (propertyTypes$ | async)'
              [helpData]='helpData$ | async'
              [usageData]='usageData$ | async'
              [copyTargets]="(copyTargets | async) || []"
              [patternId]='patternId$ | async'
              [projectKey]='projectKey$ | async'
              [patterns]='patternList$ | async'
              [isCollapsed]='splitPaneConfig[helpSplitAreaKey].isCollapsed'
              (collapse)='onCollapse($event, helpSplitAreaKey)'
      ></adm4-pattern-help>
    </as-split-area>
  </as-split>
</div>

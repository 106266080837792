import { localStoragePrefixSeparator } from '../constants/local-storage-keys.constants';

export class LocalStorageHelper {

  private static isSupportedByBrowser() {
    return typeof(Storage) !== 'undefined';
  }

  static save(key: string, value: string): void {
    if (!this.isSupportedByBrowser() || !value) {
      return;
    }
    localStorage.setItem(key, value);
  }

  static retrieve(key: string): string | null {
    if (!this.isSupportedByBrowser()) {
      return null;
    }
    return localStorage.getItem(key);
  }

  static delete(key: string): void {
    if (!this.isSupportedByBrowser()) {
      return;
    }
    localStorage.removeItem(key);
  }

  static prefixKey(key: string, prefix: string): string {
    return `${prefix}${localStoragePrefixSeparator}${key}`;
  }

}

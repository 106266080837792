import { AbstractControl } from '@angular/forms';
import { Inventory } from './inventory.model';

export class InventoryValidators {
  static checkExistingInventoryKey(inventories: Inventory[], tenantKey: string) {
    return (control: AbstractControl) => {
      const inventoryKeyAlreadyExists = inventories.some((inventory: Inventory) => {
        const inventoryKeyFull = `${tenantKey}-${control.value}`.toUpperCase();
        return control.value && inventory.inventoryKey === inventoryKeyFull;
      });

      if (!inventoryKeyAlreadyExists) {
        return null;
      }

      return {inventoryKeyExists: true};
    };
  }
}

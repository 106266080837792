import { Component, Input } from '@angular/core';

@Component({
  selector: 'adm4-toggle-collapse-button',
  template: `
    <button type='button' class='toggle-collapse-button' [ngClass]='buttonClass'>
      <mat-icon>{{icon}}</mat-icon>
      <mat-icon>{{icon}}</mat-icon>
    </button>
  `,
  styleUrls: ['./toggle-collapse-button.component.scss']
})
export class ToggleCollapseButtonComponent {
  @Input() isCollapsed: boolean;
  /**
   * Elements can be collapsed to either side, depending on that direction of icons pointing can be different
   */
  @Input() side: 'left' | 'right';
  /**
   * Css class for button, which helps showing it differently if needed (e.g. different background)
   */
  @Input() buttonClass: 'admn4-button-icon' | 'admn4-button-icon-white' = 'admn4-button-icon';

  get icon(): string {
    if (this.side === 'left') {
      return this.isCollapsed ? 'chevron_right' : 'chevron_left';
    } else if (this.side === 'right') {
      return this.isCollapsed ? 'chevron_left' : 'chevron_right';
    } else {
      throw new Error('Side not set');
    }
  }
}

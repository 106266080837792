export class DeploymentProcessModel {
  public readonly deploymentId: string;
  public readonly state: DeploymentProcessState;
  public projectKey: string;
  public inventoryKey: string;
  public hostExpression: string;
  public canaryRouting: CanaryRoutingOption;
  public comment: string;
}

export enum DeploymentProcessState {
  Initialized = 'INITIALIZED',
  Generating = 'GENERATING',
  Generated = 'GENERATED',
  Planning = 'PLANNING',
  Planned = 'PLANNED',
  Deploying = 'DEPLOYING',
  Deployed = 'DEPLOYED'
}

export class CanaryRoutingOption {
  header?: string;
  headerValue?: string;
  headerPattern?: string;
  cookie?: string;
  query?: boolean;
  percentage?: number;
}

export enum CanaryRoutingConstant {
  CANARY = 'Canary',
  AB = 'A/B',
  CUSTOM = 'Custom'
}

export const defaultCanaryValue = 'canary';
export const defaultCanaryPercentage = 50;
export const defaultCanaryRoutingModel = {
  header: defaultCanaryValue,
  cookie: defaultCanaryValue,
  percentage: defaultCanaryPercentage
};

export class DeploymentAction {
  userKey: string;
  timestamp: string;
  action: DeploymentActionType;
  comment?: string;
}

export enum DeploymentActionType {
  DEPLOY = 'DEPLOY',
  PROMOTE = 'PROMOTE',
  ROLLBACK = 'ROLLBACK',
  DELETE = 'DELETE',
}

export const deploymentActionTypeToLabel = (actionType: any) => {
  switch (actionType) {
    case DeploymentActionType.DEPLOY:
      return 'deployed';
    case DeploymentActionType.PROMOTE:
      return 'promoted';
    case DeploymentActionType.ROLLBACK:
      return 'rolled back';
    case DeploymentActionType.DELETE:
      return 'deleted';
    default:
      console.warn('Unexpected DeploymentActionType: ' + actionType);
      return (actionType??'').toString().toLowerCase();
  }
};

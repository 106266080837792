import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { VersionControlForm } from './version-control.form';
import * as _ from 'lodash';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { VersionControlHelper } from '../../helpers/version-control.helper';

export interface INestedVersionControlFormMixin {
  readonly VERSION_CONTROL_FORM_CONTROL_NAME: string;

  form: UntypedFormGroup;

  destroyed$: Subject<boolean>;
  updateDefaultKeyOnVersionControlChange: (projectKeyFormControlName: string) => void;
}

export class NestedVersionControlFormMixin implements INestedVersionControlFormMixin {
  readonly VERSION_CONTROL_FORM_CONTROL_NAME: string;

  form: UntypedFormGroup;

  // NOTE: to be handled by component
  destroyed$: Subject<boolean>;

  updateDefaultKeyOnVersionControlChange(keyFormControlName: string): void {
    this.form.controls[this.VERSION_CONTROL_FORM_CONTROL_NAME].valueChanges
      .pipe(
        filter(() => this.form.controls[keyFormControlName].pristine),
        takeUntil(this.destroyed$),
        // debounce time has scheduler as a second parameter, which by default is async, which is equal to wrapping this whole statement in setTimeout in order to prevent expression was changed after it was changed blabla error
        debounceTime(0)
      )
      .subscribe((projectVersionControl: VersionControlForm) => {
        const branchName = _.isNil(projectVersionControl.branch) ? '' : projectVersionControl.branch.name;
        const repoName = VersionControlHelper.getRepositoryNameFromUrl(projectVersionControl.repository);
        const key = combineName(repoName, formatBranchName(branchName));
        this.form.controls[keyFormControlName].setValue(key);
        if (!_.isEmpty(key)) {
          this.form.controls[keyFormControlName].markAsTouched();
        }
      });
  }
}

export function combineName(repositoryName: string, branchName: string): string {
  if (!_.isEmpty(repositoryName) && !_.isEmpty(branchName)) {
    return `${repositoryName}-${branchName}`.toUpperCase();
  } else  {
    return '';
  }
}

export function formatBranchName(branchName: string): string {
  if (!branchName.includes('/')) {
    return branchName;
  }
  return branchName.replace('/', '-');
}

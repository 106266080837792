import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Diff } from '../../../../common/model/publish-changes/diff.model';
import { SimplePatternDiffData } from '../pattern-diff-data.model';

@Component({
  selector: 'adm4-simple-pattern-field-diff',
  template: `
    <adm4-diff-view-element [remoteCommitInfo]='simplePatternFieldDiff.remote.commitInfo'
                            [localChangeInfo]='simplePatternFieldDiff.local.changeInfo'
                            [localFallbackLabel]='simplePatternFieldDiff.local.fallbackLabel'
                            [currentUsername]='currentUsername'
                            [showOnlyDifferences]='showOnlyDifferences'
                            [boxed]='false'
    >
      <div remote>
        <div class='simple-field'>
          <span class='pattern-field-label'>{{simplePatternFieldDiff.remote.label}}: </span>
          <span>{{simplePatternFieldDiff.remote.value}}</span>
        </div>
      </div>
      <div local>
        <div class='simple-field'>
          <span class='pattern-field-label'>{{simplePatternFieldDiff.local.label}}: </span>
          <span>{{simplePatternFieldDiff.local.value}}</span>
        </div>
      </div>
    </adm4-diff-view-element>
  `,
  styleUrls: ['./simple-pattern-field-diff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimplePatternFieldDiffComponent {
  @Input() simplePatternFieldDiff: Diff<SimplePatternDiffData>;
  @Input() showOnlyDifferences: boolean;
  @Input() currentUsername: string | null;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IssueModel } from '../../model/issue.model';
import * as _ from 'lodash';
import { getHighestSeverity } from '../../../projects/project-issues/project-issues.helper';
import { IssueSeverityEnum } from '../../model/issue-severity.enum';

@Component({
  selector: 'adm4-highest-severity-issue-indicator',
  template: `
    <adm4-validation-indicator [isDisplayed]='isDisplayed'
                               [isValid]='isHighestSeverityValid'
                               [isWarning]='isHighestSeverityWarning'
                               [isError]='isHighestSeverityError'
                               [diameter]='diameter'
    ></adm4-validation-indicator>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighestSeverityIssueIndicatorComponent {
  @Input() diameter?: number;
  @Input() issues: IssueModel[];
  @Input() isDisplayed = true;

  get isHighestSeverityValid(): boolean {
    return _.isEmpty(this.issues);
  }

  get isHighestSeverityWarning(): boolean {
    return getHighestSeverity(this.issues) === IssueSeverityEnum.WARNING;
  }

  get isHighestSeverityError(): boolean {
    return getHighestSeverity(this.issues) === IssueSeverityEnum.ERROR;
  }
}

import { Component, ElementRef, forwardRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import * as _ from 'lodash';

import { WidgetComponent } from './widget.component';
import { TextEditorDialogService } from '../common/components/text-editor-dialog/text-editor-dialog.service';
import { GlobalClicks } from '../common/services/global-clicks.service';
import { ParameterHelper } from './parameter.helper';
import { Maybe } from '../common/utils/utils';

@Component({
  selector: 'adm4-text-property',
  template: `
    <div class='form-control json-editor'
         [class.disabled]='readOnly'
         [class.filled]='!isEmpty'
         [class.focused]='isFocused'>
      <ngx-codemirror #codeMirrorRef
                      [options]="codemirrorConfig"
                      [(ngModel)]='editedContent'
                      (focusChange)='onSelect($event)'
                      (ngModelChange)='setUpdatedValue($event)'></ngx-codemirror>
    </div>
    <div class='code-mirror-actions'>
      <a *ngIf='!readOnly' class='link-with-icon' (click)='editInFullScreenEditor()'>
        <span>Edit full-screen</span>
        <mat-icon>edit</mat-icon>
      </a>
    </div>
  `,
  styleUrls: ['text-property.component.scss'],
  providers: [
    GlobalClicks,
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextPropertyComponent), multi: true},
  ],
})
export class TextPropertyComponent extends WidgetComponent implements OnInit, OnDestroy {
  @ViewChild('codeMirrorRef') codeMirrorRef: CodemirrorComponent;
  codemirrorConfig: Record<string, string | object | null>;
  propertySyntax: Maybe<string>;
  isFocused: boolean;
  editedContent: string;
  private clickSubscription: Subscription;

  constructor(private textEditorDialogService: TextEditorDialogService, private elementRef: ElementRef, private clicks: GlobalClicks) {
    super();
  }

  override ngOnInit() {
    const rawSyntax: any = ParameterHelper.getSpecificParameterValue(ParameterHelper.SYNTAX, this.parameters, this.propertyType);
    this.propertySyntax = typeof rawSyntax === 'string' ? rawSyntax.trim().toLowerCase() : null;
    const cmMode: string | null | Record<string, string | boolean>  = 'json' === this.propertySyntax ? {name: 'javascript', json: true} : this.propertySyntax;
    const placeholder = this.defaultValue ? 'default:\n' + this.defaultValue : '';
    this.codemirrorConfig = {
      placeholder: placeholder,
      mode: cmMode,
    };
    this.editedContent = this.group.value[this.widgetProperty.propertyKey];
    this.refreshCodemirrorIfNeeded();
  }

  override initFinished() {
    super.initFinished();
    // If the value might have been updated, eg. `PropertyListElementComponent#exitVariableMode`,
    // we need to pass the new value to the CodeMirror, which is `ngModel`'d to the field `editedContent`.
    this.editedContent = this.group.value[this.widgetProperty.propertyKey];
  }

  override onSelect(focusEvent: boolean): void {
    this.isFocused = focusEvent;
    this.isFocused ? this.selectionChanged.emit(this.widgetProperty.propertyKey) : this.onValidate(focusEvent);
  }

  setUpdatedValue(updatedValue: string): void {
    this.group.controls[this.widgetProperty.propertyKey].markAsDirty();
    this.group.controls[this.widgetProperty.propertyKey].setValue(updatedValue);
    this.editedContent = updatedValue;
  }

  editInFullScreenEditor(): void {
    this.textEditorDialogService.openTextEditorDialog(this.group.value[this.widgetProperty.propertyKey], this.propertySyntax).afterClosed().subscribe((updatedValue?: string) => {
      if (typeof updatedValue === 'string') {
        this.setUpdatedValue(updatedValue);
      }
    });
  }

  get isEmpty() {
    return _.isEmpty(this.widgetProperty.value);
  }

  ngOnDestroy(): void {
    this.clickSubscription.unsubscribe();
  }

  // Need to subscribe to click events to be able to refresh the codemirror when it's become visible
  // otherwise the content becomes visible just after clicking inside even the value is there
  private refreshCodemirrorIfNeeded(): void {
    this.clickSubscription = this.clicks.click$.pipe(
      distinctUntilChanged((previousClick, currentClick) => {
        return _.isEqual(previousClick.target, currentClick.target);
      }),
      filter(() => !_.isNil(this.elementRef.nativeElement.offsetParent)),
    ).subscribe(() => {
      if (!_.isNil(this.codeMirrorRef) && !_.isNil(this.codeMirrorRef.codeMirror)) {
        this.codeMirrorRef.codeMirror.refresh();
      }
    });
  }
}

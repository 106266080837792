import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenerationStatusModel } from '../generation-status.model';
import { ValidatingHelper } from '../../validating/validating.helper';

@Component({
  selector: 'adm4-deployment-validating',
  template: `
    <adm4-validating [validatingText]='"Validating..."'
                     [validationDoneText]='validationTitle'
                     [status]='generationStatus'>
      <div class='success' *ngIf='isValidationSuccess'>
        <span>No issues detected. </span>
        <span *ngIf='showGenerationButtonVisible'>
          You find the <a  href='javascript:void(0)' (click)='showGenerationResults.emit()'>generation results here</a>
        </span>
      </div>
    </adm4-validating>
  `,
  styleUrls: ['./deployment-validating.component.scss']
})
export class DeploymentValidatingComponent {
  @Input() generationStatus: GenerationStatusModel;
  @Input() showGenerationButtonVisible: boolean;
  @Output() showGenerationResults: EventEmitter<void> = new EventEmitter();

  get isValidationSuccess() {
    return ValidatingHelper.isAllSuccess(this.generationStatus);
  }

  get validationTitle() {
    return this.isValidationSuccess ? 'Validation successful' : 'Validation failed';
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PatternListData } from '../../../../patterns/pattern-list-data.model';
import { ProjectMeta } from '../../../project.model';
import { VariableModel } from '../../../../variables/variable.model';
import { IssueModel } from '../../../../common/model/issue.model';
import { ProjectSummaryReportContext } from '../project-summary-report.context';
import { AppState } from '../../../../model/reducer';
import { hasProjectWriteAccessView } from '../../../../model/views/permission.views';

@Component({
  selector: 'adm4-project-summary-tab-content',
  template: `
    <div class='full-height-flex project-summary-table-wrapper'>
      <table class='project-summary-table'>
        <tr>
          <td colspan="3">
            <adm4-project-summary-description [descriptionPlain]="descriptionPlain$ | async"
                [descriptionHtml]="descriptionHtml$ | async"
                [hasEditPermission]="hasProjectWriteAccessView$ | async"
                (descriptionSaved)="projectDescriptionChanged($event)"
            ></adm4-project-summary-description>
          </td>
        </tr>

        <tr>
          <td class='small-cell'>
            <adm4-project-summary-patterns [patternList]='patternList$ | async'
                                           [projectStatusModel]='projectStatusModel$ | async'></adm4-project-summary-patterns>
          </td>
          <td class='small-cell'>
            <adm4-project-summary-variables [currentProjectKey]='projectKey$ | async'
                                            [variables]='variables$ | async'></adm4-project-summary-variables>
          </td>
          <td>
            <adm4-project-summary-issues [currentProjectKey]='projectKey$ | async'
                                         [issues]='issues$ | async'></adm4-project-summary-issues>
          </td>
        </tr>
        <tr *ngIf="versioned$ | async">
          <td colspan='3'>
            <adm4-project-summary-changes [projectStatusModel]='projectStatusModel$ | async'></adm4-project-summary-changes>
          </td>
        </tr>
      </table>
    </div>
  `,
  styleUrls: ['./project-summary-report-tab-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryReportTabSummaryComponent {
  projectKey$: Observable<string | null>;
  descriptionHtml$: Observable<SafeHtml | undefined>;
  descriptionPlain$: Observable<string | undefined>;
  patternList$: Observable<PatternListData[]>;
  projectStatusModel$: Observable<ProjectMeta | null>;
  versioned$: Observable<boolean>;
  variables$: Observable<VariableModel[]>;
  issues$: Observable<IssueModel[]>;
  hasProjectWriteAccessView$: Observable<boolean>;

  constructor(private reportContext: ProjectSummaryReportContext, private store$: Store<AppState>) {
    this.projectKey$ = reportContext.projectKey$;
    this.descriptionHtml$ = reportContext.projectDescriptionHtml$;
    this.descriptionPlain$ = reportContext.projectDescriptionPlain$;
    this.patternList$ = reportContext.patternList$;
    this.versioned$ = reportContext.versioned$;
    this.projectStatusModel$ = reportContext.projectStatusModel$;
    this.variables$ = reportContext.variables$;
    this.issues$ = reportContext.issues$;
    this.hasProjectWriteAccessView$ = this.store$.select(hasProjectWriteAccessView);
  }

  projectDescriptionChanged(projectDescription: string | undefined) {
    this.reportContext.saveDescription(projectDescription);
  }

}

import { PatternInstance } from '../../patterns/pattern-instance.model';
import { Dictionary } from '../reducer';
import { PatternType } from '../../plugins/pattern-type.model';
import { PropertyType } from '../../plugins/property-type.model';
import { NevisAdminAction } from '../actions';
import { PatternActionTypes } from './pattern.actions';
import { Pattern, PatternCopyTarget } from '../../patterns/pattern.model';
import { ProjectActionTypes } from '../project';
import { Usage} from '../../common/model/plugin-usage.model';
import { PatternHelper } from '../../patterns/pattern.helper';
import * as fromShared from '../shared/shared.actions';
import * as _ from 'lodash';
import { ResourceList } from '../../patterns/pattern-attachment.model';

export type PatternId = string;

export class PatternState {
  readonly allPatterns: Map<string, Pattern>;
  readonly selectedPatternInstance: PatternInstance | null;
  readonly patternAttachments: Dictionary<ResourceList>;
  readonly patternTypes: Dictionary<PatternType> | null;
  readonly propertyTypes: Dictionary<PropertyType> | null;
  readonly selectedPatternUsageList: Usage[];
  readonly selectedPatternCopies: PatternCopyTarget[];
}

export const initState: PatternState = {
  allPatterns: new Map(),
  selectedPatternInstance: null,
  patternAttachments: {},
  patternTypes: null,
  propertyTypes: null,
  selectedPatternUsageList: [],
  selectedPatternCopies: [],
};

export function reducer(state, action: NevisAdminAction<any>): PatternState {
  switch (action.type) {
    case fromShared.SharedActionTypes.SelectTenantKey:
      return initState;
    case PatternActionTypes.CreatePatternSuccess:
    case PatternActionTypes.DuplicatePatternSuccess: {
      const newEntries = new Map(state.allPatterns);
      const createdPattern = action.payload as PatternInstance;
      newEntries.set(createdPattern.patternId, createdPattern);
      return {
        ...state,
        allPatterns: newEntries
      };
    }
    case PatternActionTypes.LoadAllPatternInstancesSuccess: {
      const newEntries = PatternHelper.convertPatternsListToMap(action.payload);
      return {
        ...state,
        allPatterns: newEntries
      };
    }
    case PatternActionTypes.LoadPatternTypesSuccess: {
      const newEntries = PatternHelper.convertTypesListToDictionary(action.payload as PatternType[]);
      return {
        ...state,
        patternTypes: newEntries
      };
    }
    case PatternActionTypes.LoadPropertyTypesSuccess: {
      const newEntries = PatternHelper.convertTypesListToDictionary(action.payload as PropertyType[]);
      return {
        ...state,
        propertyTypes: newEntries
      };
    }
    case PatternActionTypes.SelectPatternInstance:
      // when a new pattern is selected we reset its attachments
      const newSelectedPatternInstance: PatternInstance | null = action.payload;
      const isDifferentPatternSelected: boolean = !_.isNil(newSelectedPatternInstance) && !_.isNil(state.selectedPatternInstance) && state.selectedPatternInstance.patternId !== action.payload.patternId;
      return {
        ...state,
        selectedPatternInstance: action.payload,
        patternAttachments: isDifferentPatternSelected ? {} : state.patternAttachments
      };
    case PatternActionTypes.DeletePattern:
      return {
        ...state,
        selectedPatternInstance: null,
        selectedPatternUsageList: [],
        selectedPatternCopies: [],
      };
    case PatternActionTypes.DeletePatternSuccess:
      const patternIdList: string = action.payload;
      const remainingPatterns: Pattern[] = Array.from<Pattern>(state.allPatterns.values())
        .filter((pattern: Pattern) => !_.includes(patternIdList, pattern.patternId));
      const newMap: Map<string, Pattern> = PatternHelper.convertPatternsListToMap(remainingPatterns);
      return {
        ...state,
        allPatterns: newMap
      };
    case ProjectActionTypes.SelectProject:
      return initState;
    case PatternActionTypes.LoadPatternAttachmentsSuccess: {
      const updatePatternAttacmentList = Object.assign({}, state.patternAttachments, {
        [action.payload.propertyKey]: action.payload.resourceList
      });

      return {
        ...state,
        patternAttachments: updatePatternAttacmentList
      };
    }
    case PatternActionTypes.LoadPatternUsageSuccess:
      return {
        ...state,
        selectedPatternUsageList: action.payload,
      };
    case PatternActionTypes.LoadPatternCopyTargetSuccess:
      return {
        ...state,
        selectedPatternCopies: action.payload,
      };
    default:
      return state;
  }
}

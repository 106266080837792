import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'adm4-side-menu-group-header',
  template: `
    <p *ngIf="!collapsed">{{text}}</p>
    <mat-divider *ngIf="collapsed"></mat-divider>
  `,
  styleUrls: ['./side-menu-group-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuGroupHeaderComponent {
  /**
   * The group header to display. Will be shown in all upper case.
   */
  @Input() text: string;

  @Input() collapsed: boolean = false;
}

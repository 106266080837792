import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { inventoryKeyView } from '../../model/views';
import { first } from 'rxjs/operators';
import { PublishInventoryDialogService } from './publish-inventory-dialog.service';
import { PublishInventoryDialogComponent } from './publish-inventory-dialog.component';
import { ClearPublishInventoryData } from '../../model/publish-inventory/publish-inventory.actions';

@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishInventoryMainComponent implements OnInit, OnDestroy {
  dialogRef: MatDialogRef<PublishInventoryDialogComponent>;

  constructor(private publishInventoryDialogService: PublishInventoryDialogService,
              private store$: Store<AppState>) {
  }

  ngOnInit(): void {
    // setTimeout is workaround for view creation inside change detection cycle
    setTimeout(() => {
      this.store$.pipe(select(inventoryKeyView), first()).subscribe((inventoryKey: string) => {
        this.dialogRef = this.publishInventoryDialogService.openPublishChangesWindow(inventoryKey);
      });
    });
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.afterClosed().subscribe(() => {
        this.store$.dispatch(new ClearPublishInventoryData());
      });
      this.dialogRef.close();
    }
  }
}

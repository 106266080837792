import { AssignedPermission, OperationKey, Permission, PermissionAssignee, PermissionModificationPayload, PermissionSavePayload } from '../model/permissions/permissions.model';
import * as _ from 'lodash';
import { criteriaSorting } from '../common/utils/utils';
import { AssigneeNamePrefixEnum } from './model/assignee-name-prefix.enum';
import { TenantHelper } from '../common/helpers/tenant.helper';
import { OperationColumnData } from './model/operation-column-data.model';
import { UserAuthorization } from '../model/permissions/user-authorization.model';
import { isTargetGlobal } from '../model/permissions/permission.utils';

export class PermissionsHelper {
  static shouldElementBeDisplayedForFilter(data: PermissionAssignee, filter: string): boolean {
    if (_.isEmpty(filter)) {
      return true;
    }
    filter = filter.trim().toLowerCase();
    const compareName = this.getAssigneeName(data);
    const matchesName = compareName.toLowerCase().includes(filter);
    const compareUsername = this.getAssigneeUsername(data);
    const matchesUsername = compareUsername.toLowerCase().includes(filter);
    return matchesName || matchesUsername;
  }

  static sortPermissions(permissions: PermissionAssignee[]): PermissionAssignee[] {
    return _.cloneDeep(permissions).sort(criteriaSorting<PermissionAssignee>([
      (p1, p2) => this.comparePermissions(p1, p2),
      (p1, p2) => this.compareIsGroup(p1, p2),
      (p1, p2) => this.compareUsername(p1, p2)
    ]));
  }

  static comparePermissions(permission1: PermissionAssignee, permission2: PermissionAssignee): number {
    const permissions1 = permission1.permissions;
    const permissions2 = permission2.permissions;
    if (_.isEmpty(permissions1) && !_.isEmpty(permissions2)) {
      return 1;
    } else if (!_.isEmpty(permissions1) && _.isEmpty(permissions2)) {
      return -1;
    }
    return 0;
  }

  static compareIsGroup(permission1: PermissionAssignee, permission2: PermissionAssignee): number {
    const isPermission1Group: boolean = this.containsAssigneePrefix(permission1.assignee, AssigneeNamePrefixEnum.Group);
    const isPermission2Group: boolean = this.containsAssigneePrefix(permission2.assignee, AssigneeNamePrefixEnum.Group);
    if (!isPermission1Group && isPermission2Group) {
      return 1;
    } else if (isPermission1Group && !isPermission2Group) {
      return -1;
    }
    return 0;
  }

  static compareUsername(permission1: PermissionAssignee, permission2: PermissionAssignee): number {
    const username1: string = this.getAssigneeUsername(permission1);
    const username2: string = this.getAssigneeUsername(permission2);
    return username1.localeCompare(username2);
  }

  /**
   * Gets a name of permissionAssignee, for groups name should be parsed from assignee, for users it's assigneeName if it exists otherwise it's empty
   * @param {PermissionAssignee} permissionAssignee
   * @returns {string}
   */
  static getAssigneeName(permissionAssignee: PermissionAssignee): string {
    const isGroup: boolean = this.containsAssigneePrefix(permissionAssignee.assignee, AssigneeNamePrefixEnum.Group);
    if (isGroup) {
      return _.replace(permissionAssignee.assignee, AssigneeNamePrefixEnum.Group, '');
    }
    return _.isNil(permissionAssignee.assigneeName) ? '' : permissionAssignee.assigneeName;
  }

  /**
   * Gets a username of permissionAssignee, for groups username should be empty, for user it should be parsed from assignee
   * @param {PermissionAssignee} permissionAssignee
   * @returns {string}
   */
  static getAssigneeUsername(permissionAssignee: PermissionAssignee): string {
    const isGroup: boolean = this.containsAssigneePrefix(permissionAssignee.assignee, AssigneeNamePrefixEnum.Group);
    if (isGroup) {
      return '';
    }
    return _.replace(permissionAssignee.assignee, AssigneeNamePrefixEnum.User, '');
  }

  static containsAssigneePrefix(assignee: string, prefix: AssigneeNamePrefixEnum): boolean {
    return _.includes(assignee, prefix);
  }

  static isProjectTarget(target: string, projectKey: string): boolean {
    return target === PermissionsHelper.createProjectTarget(projectKey);
  }

  static createProjectTarget(projectKey: string): string {
    if (_.isEmpty(projectKey)) {
      return '';
    }
    const tenantKey = TenantHelper.getTenantFromKey(projectKey);
    return `/tenants/${tenantKey}/projects/${projectKey}`;
  }

  static createInventoryTarget(inventoryKey: string): string {
    if (_.isEmpty(inventoryKey)) {
      return '';
    }
    const tenantKey = TenantHelper.getTenantFromKey(inventoryKey);
    return `/tenants/${tenantKey}/inventories/${inventoryKey}`;
  }

  static updatedPermissionModifications(target: string, permissionModifications: PermissionSavePayload[], permissionModificationPayload: PermissionModificationPayload): PermissionSavePayload[] {
    const permissionToChange: Permission = {target: target, operationKey: permissionModificationPayload.operationKey};
    const existingOperationKeys: OperationKey[] = permissionModificationPayload.permissionAssignee.permissions.map(permission => permission.operationKey);

    const permissionExists: boolean = _.includes(existingOperationKeys, permissionModificationPayload.operationKey);
    const permissionAdded: boolean = !permissionExists && permissionModificationPayload.checked;
    const permissionRemoved: boolean = permissionExists && !permissionModificationPayload.checked;

    const existingModification: PermissionSavePayload | undefined = permissionModifications.find(permissionModification => permissionModification.assignee === permissionModificationPayload.permissionAssignee.assignee);
    if (_.isNil(existingModification)) {
      let newPermissionModification: PermissionSavePayload;
      if (permissionAdded) {
        newPermissionModification = this.createAddPermissionModification(permissionModificationPayload.permissionAssignee.assignee, permissionToChange);
      } else if (permissionRemoved) {
        newPermissionModification = this.createRemovePermissionModification(permissionModificationPayload.permissionAssignee.assignee, permissionToChange);
      } else {
        // if trying to add/remove permission that is exists/doesn't exist repectively then the original modifications list is returned
        return permissionModifications;
      }
      return permissionModifications.concat([newPermissionModification]);
    }

    let updatedModification: PermissionSavePayload;
    if (permissionAdded) {
      updatedModification = {...existingModification, addPermissions: existingModification.addPermissions.concat([permissionToChange])};
    } else if (permissionRemoved) {
      updatedModification = {...existingModification, removePermissions: existingModification.removePermissions.concat([permissionToChange])};
    } else {
      // if permission was neither added neither removed it means that some change was reverted therefore we filter out permission with that operation key from both add/remove lists(to skip finding out where it actually is)
      updatedModification = {
        ...existingModification,
        addPermissions: existingModification.addPermissions.filter(permission => permission.operationKey !== permissionToChange.operationKey),
        removePermissions: existingModification.removePermissions.filter(permission => permission.operationKey !== permissionToChange.operationKey)
      };
    }
    const untouchedModifications: PermissionSavePayload[] = permissionModifications.filter(permissionModification => permissionModification.assignee !== existingModification.assignee);
    if (_.isEmpty(updatedModification.addPermissions) && _.isEmpty(updatedModification.removePermissions)) {
      return untouchedModifications;
    }
    return untouchedModifications.concat([updatedModification]);
  }

  static createAddPermissionModification(assignee: string, permission: Permission): PermissionSavePayload {
    return {
      assignee: assignee,
      addPermissions: [permission],
      removePermissions: []
    };
  }

  static createRemovePermissionModification(assignee: string, permission: Permission): PermissionSavePayload {
    return {
      assignee: assignee,
      addPermissions: [],
      removePermissions: [permission]
    };
  }

  static getOperationKeysFromPermissionOperationColumns(operationColumns: OperationColumnData[]): OperationKey[] {
    return operationColumns.map(columnData => columnData.operationKey);
  }

  static getObjectWithPermissionOf<T extends UserAuthorization>(objectWithPermission: T[], operationKey: OperationKey): Array<T> {
    return objectWithPermission.reduce((objectsHavingPermission: T[], objectWithAuthorization: T) => {
      if (this.objectHasPermission(objectWithAuthorization, operationKey)) {
        return _.concat(objectsHavingPermission, objectWithAuthorization);
      }
      return objectsHavingPermission;
    }, <T[]>[]);
  }

  static objectHasPermission<T extends UserAuthorization>(objectWithAuthorization: T, operationKey: OperationKey): boolean {
    return _.includes(objectWithAuthorization._userAuthorization, operationKey);
  }

  static hasGlobalSuperAdmin(assignedPermissions: AssignedPermission[]): boolean {
    return assignedPermissions.some((assignedPermission: AssignedPermission): boolean => {
      const {target, operationKey}: {target: string, operationKey: OperationKey} = assignedPermission.permission;
      return isTargetGlobal(target) && OperationKey.SUPER_ADMIN === operationKey;
    });
  }
}

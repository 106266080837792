import { Component, Inject, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProjectService } from '../project.service';
import { Revision } from '../../version-control/revision/revision.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { FileDownloader } from '../../common/helpers/file-downloader';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'adm4-revert-project-component',
  template: `
    <adm4-modal-dialog-title header='Revert Project' [showClose]='true' (closeClicked)="closeDialog()">
      <div class='create-item-container'>
        <div class="form-group revert-project-warning-content">
          <span>
            <label class='input-label warning-title'>
              <span class='warning-icon-container'>
                 <mat-icon class='warning-icon'>report_problem</mat-icon>
              </span>
              <span>
                Consider exporting the project <adm4-link (click)='exportProject()'>now</adm4-link>, since your local changes will be lost after reverting to a previous version. 
                Please note that you will only load the previous configurations, no automatic deployment is done.
              </span>
            </label>
          </span>
        </div>

        <div class="form-group revert-project-target">
          <label class='input-label' for='project-revisions'>Select revision</label>
          <mat-form-field class='full-width'>
            <mat-select #revisionSelection
                        id='project-revisions'
                        [(ngModel)]='selectedRevision'
                        [disableOptionCentering]="true"
                        (click)='focusDropdownInput()'
                        [placeholder]='placeholderMsg'>
              <adm4-searchable-dropdown-input *ngIf='revisionSelection.focused'
                                              [sourceItems]='revisions'
                                              [placeholder]="placeholderMsg"
                                              [searchableFormatFn]='searchableRevisionFormatFn'
                                              [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                              (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
              <mat-option *ngFor="let revision of revisions" [value]="revision" [hidden]='isRevisionFilteredOut(revision)' [title]='revision.message'>
                {{revision?.committer?.when | date: commitDateFormat}} by {{revision.author.name}} ({{revision.message}})
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div *ngIf='selectedRevision' class="commit-details fade-white">
            <p><strong>Commit ID: </strong>{{selectedRevision.commitId}}</p>
            <p><strong>Publish comment: </strong>{{selectedRevision.message}}</p>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button type='button' class='admn4-button-text'
                (click)='closeDialog()'>
          Cancel
        </button>
        <button class='admn4-button-ellipse-blue'
                [disabled]='!selectedRevision'
                (click)='revertToRevision(projectKey, selectedRevision)'>
          Load
        </button>
      </div>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['./revert-project.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-mat-dialog'"},
})
export class RevertProjectComponent implements OnInit {

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();
  selectedRevision: Revision;
  revisions: Revision[] | null;
  readonly commitDateFormat = 'dd.MM.yyyy HH:mm';
  private filteredRevisionList: Revision[];
  placeholderMsg= 'Please select a revision...';

  constructor(private projectService: ProjectService,
              @Inject(MAT_DIALOG_DATA) public projectKey: string,
              private dialogRef: MatDialogRef<RevertProjectComponent>) {
  }

  ngOnInit(): void {
    this.projectService.getProjectRevisionList(this.projectKey, 1000).subscribe(revision => {
      this.revisions = revision;
      if(this.hasEmptyRevisionList(revision)){
        this.placeholderMsg = 'The project has not been published yet. Only published projects can be reverted.';
      }
    });
  }

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }

  updateSearchResult(filteredList: Revision[]): void {
    this.filteredRevisionList = filteredList;
  }

  searchableRevisionFormatFn = (revision: Revision): string => {
    return revision.message + revision.author.name + revision.author.when + revision.commitId;
  };

  closeDialog() {
    this.dialogRef.close();
  }

  revertToRevision(projectKey: string, selectedRevision: Revision) {
    this.dialogRef.close({projectKey: projectKey, selectedRevision:selectedRevision});
  }

  isRevisionFilteredOut(revision: Revision) {
    return !_.some(this.filteredRevisionList, value => _.isEqual(value, revision));
  }

  //todo: zvitkoczi fix this
  exportProject() {
    FileDownloader.downloadFileFromUrl(`${environment.baseUrl}/projects/${this.projectKey}/file-export`);
  }

  hasEmptyRevisionList(revisions: Revision[] | null): boolean {
    if (_.isNil(revisions)) {
      return false;
    }
    return _.isEmpty(revisions);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService} from '../shared/base.service';
import { VariableModel } from './variable.model';
import { VariableUsage } from './variable-usage.model';
import { Usage } from '../common/model/plugin-usage.model';
import { map } from 'rxjs/operators';


@Injectable()
export class VariableService extends BaseService {

  getAllVariablesOfProject(projectKey: string, meta?: boolean): Observable<VariableModel[]> {
    let url = `/projects/${projectKey}/variables`;
    if (meta) {
      url += `?meta=${meta}`;
    }
    return this.httpGetUnwrapped(url);
  }

  getFilteredVariableOfProject(projectKey: string, filterClass: string): Observable<VariableModel[]> {
    const url = `/projects/${projectKey}/variables?className=${filterClass}&sort=name`;
    return this.httpGetUnwrapped(url);
  }

  upsertVariables(projectKey: string, variables: VariableModel[]): Observable<VariableModel[]> {
    const url = `/projects/${projectKey}/variables`;
    return this.httpPut(url, variables);
  }

  renameVariable(projectKey: string, variableKey: string, newVariableKey: string): Observable<VariableModel> {
    const url = `/projects/${projectKey}/variables/${variableKey}/key`;
    return this.httpPut(url, {variableKey: newVariableKey});
  }

  getVariableUsage(projectKey: string, varKey: string): Observable<Usage[]> {
    const url = `/projects/${projectKey}/variables/${varKey}/usage`;
    return this.httpGet(url).pipe(
      map((varUsage: VariableUsage) => varUsage.usages)
    );
  }
}

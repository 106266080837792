import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { GlobalConstantWithUsage } from '../../../inventory/inventory.model';

@Component({
  selector: 'adm4-constant-usage-list',
  styleUrls: ['constant-usage-list.component.scss'],
  template: `
    <ng-container *ngIf="items.length === 1">
      <a (click)="navigateToInventory.emit(items[0])" [innerHTML]="items[0] | cropTenantFromKey | highlight: highlight"></a>
    </ng-container>
    <ng-container *ngIf="items.length > 1">
      <ng-container *ngIf="!expanded">
        <a (click)="expanded = true">{{items.length}} inventories...</a>
      </ng-container>
      <ng-container *ngIf="expanded">
        <div class="d-flex flex-column">
          <a *ngFor="let item of items;" (click)="navigateToInventory.emit(item)" [innerHTML]="item | cropTenantFromKey | highlight: highlight" ></a>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="items.length === 0">
      <div class="d-flex align-items-center">
        <span class="disabled-opacity">Unused</span>
        <i class="fa fa-info-circle help-icon" aria-hidden="true" [ngbTooltip]='unusedPopover' placement='right'></i>
      </div>
    </ng-container>
    <ng-template #unusedPopover>The global constant <strong>{{globalConstant?.name}}</strong> is not used yet.</ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConstantUsageListComponent implements OnChanges {

  public expanded: boolean = false;
  public items: string[] = [];

  @Input() globalConstant: GlobalConstantWithUsage | undefined;
  @Input() highlight: string | undefined = undefined;

  @Output() navigateToInventory: EventEmitter<string> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.globalConstant || changes.highlight) {
      if (typeof this.globalConstant?.usedIn !== 'undefined') {
        this.items = this.globalConstant.usedIn;
      } else {
        this.items = [];
      }
    }
  }


}

<ng-container [ngSwitch]='node.type'>
	<ng-container *ngSwitchCase='DetailType.Directory'>
		<adm4-k8s-directory-detail-message
						[node]='node'
						[outputType]='outputType'
						[projectKey]='projectKey$ | async'
						[pattern]='pattern'
						[boxShadowClass]='boxShadowClass'
						(changeSelectedDetail)='navigateToNewDetail($event)'>
		</adm4-k8s-directory-detail-message>
	</ng-container>
	<ng-container *ngSwitchCase='DetailType.File'>
		<adm4-k8s-file-detail-message
						[node]='node'
						[outputType]='outputType'
						[projectKey]='projectKey$ | async'
						[pattern]='pattern'
						[boxShadowClass]='boxShadowClass'
						(changeSelectedDetail)='navigateToNewDetail($event)'>
		</adm4-k8s-file-detail-message>
	</ng-container>
	<ng-container *ngSwitchCase='DetailType.KubernetesCustomResource'>
		<adm4-k8s-custom-resource-details
						[node]='node'
						[outputType]='outputType'
						[projectKey]='projectKey$ | async'
						[pattern]='pattern'
						[boxShadowClass]='boxShadowClass'
						(changeSelectedDetail)='navigateToNewDetail($event)'>
		</adm4-k8s-custom-resource-details>
	</ng-container>
</ng-container>

import { combineLatest as observableCombineLatest, forkJoin as observableForkJoin, Observable, of as observableOf, of, Subject } from 'rxjs';

import { catchError, filter, map, shareReplay, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { AppState, Dictionary } from '../model/reducer';
import { IssueModel } from '../common/model/issue.model';
import { select, Store } from '@ngrx/store';
import { ProjectIssuesDisplayHelper } from './project-issues-display.helper';
import { PropertyType } from '../plugins/property-type.model';
import { PatternType } from '../plugins/pattern-type.model';
import { VariableModel } from '../variables/variable.model';
import * as _ from 'lodash';
import { IssueGroupList } from '../issues/model/issue-group-list.model';
import { PatternService } from '../patterns/pattern.service';
import { PatternInstance } from '../patterns/pattern-instance.model';

import { PatternIssueData } from '../issues/model/pattern-issue-data.model';
import { createDummyPatternInstance } from '../patterns/pattern-instance.helper';
import { issuesView, patternTypesView, projectKeyView, propertyTypesView, variablesView } from '../model/views';
import { IssueSeverityCount } from './project-issues-display.model';
import { filterForPatternIssues } from './project-issues/project-issues.helper';

@Injectable()
export class ProjectIssuesDisplayContext implements OnDestroy {
  private readonly allIssues$: Observable<IssueModel[]>;
  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();

  issueGroupList$: Observable<IssueGroupList>;
  projectKey$: Observable<string | null>;
  propertyTypes$: Observable<Dictionary<PropertyType> | null>;
  patternTypes$: Observable<Dictionary<PatternType> | null>;
  variables$: Observable<VariableModel[]>;
  patterns$: Observable<PatternInstance[]>;
  generalIssues$: Observable<IssueModel[]>;
  patternIssueGroups$: Observable<PatternIssueData[]>;
  patternIssueNumberBySeverity$: Observable<IssueSeverityCount>;
  shouldDisplayGeneralIssues$: Observable<boolean>;


  constructor(private store$: Store<AppState>, private patternService: PatternService) {
    this.projectKey$ = store$.pipe(select(projectKeyView));
    this.propertyTypes$ = store$.pipe(select(propertyTypesView));
    this.patternTypes$ = store$.pipe(select(patternTypesView));
    this.variables$ = store$.pipe(select(variablesView)).pipe(map(_.values));
    this.allIssues$ = this.store$.pipe(select(issuesView));
    this.patterns$ = this.allIssues$.pipe(
      map(issues => ProjectIssuesDisplayHelper.getIssuedPatternIds(issues)),
      withLatestFrom(this.projectKey$),
      switchMap(([patternIds, projectKey]: [string[], string]) => {
        if (_.isEmpty(patternIds)) {
          return of([]);
        }
        return observableForkJoin(
          patternIds.map(patternId => this.patternService.getPatternInstance(projectKey, patternId).pipe(
            // if for any reason pattern fails to return pattern instance then we create a dummy pattern instance to keep the rest of the process working
            catchError(() => observableOf(createDummyPatternInstance(patternId))))
          )
        );
      }),
      shareReplay(),
      takeUntil(this.destroyed$)
    );
    this.issueGroupList$ = observableCombineLatest([this.patterns$, this.patternTypes$, this.propertyTypes$, this.allIssues$]).pipe(
      filter((tuple: [PatternInstance[], Dictionary<PatternType> | null, Dictionary<PropertyType> | null, IssueModel[]]) => tuple.every(entry => !_.isNil(entry))),
      map(([patterns, patternTypes, propertyTypes, issues]: [PatternInstance[], Dictionary<PatternType>, Dictionary<PropertyType>, IssueModel[]]) => {
        return ProjectIssuesDisplayHelper.createIssueGroupList(patterns, patternTypes, propertyTypes, issues);
      }),
      shareReplay());
    this.generalIssues$ = this.issueGroupList$.pipe(map(issueGroupList => issueGroupList.generalIssues));
    this.patternIssueGroups$ = this.issueGroupList$.pipe(map(issueGroupList => issueGroupList.patternIssuesData));
    this.shouldDisplayGeneralIssues$ = this.generalIssues$.pipe(map((generalIssues: IssueModel[]) => !_.isEmpty(generalIssues)));
    this.patternIssueNumberBySeverity$ = this.allIssues$.pipe(
        map((issues: IssueModel[]) => filterForPatternIssues(issues)),
        map(ProjectIssuesDisplayHelper.issuesToCountBySeverity),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

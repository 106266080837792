import { ProjectSummaryReportCellType, ProjectSummaryReportType } from './project-summary-report.model';
import { reverseCompareResult } from '../../../common/utils/utils';
import * as _ from 'lodash';

export class ProjectSummaryReportTableSortHelper {

  static compareBySimpleStringParameter(sortDirection: string, index: number): (item1: ProjectSummaryReportType[] | null, item2: ProjectSummaryReportType[] | null) => number {
    return (row1: ProjectSummaryReportType[], row2: ProjectSummaryReportType[]) => {
      if (_.isNil(row1) || _.isNil(row2)) {
        return 0;
      }
      let compareResult: number;
      const cell1 = row1[index];
      const cell2 = row2[index];
      if (_.isNil(cell1) || _.isNil(cell2)) {
        return 0;
      }
      if (cell1['type'] === ProjectSummaryReportCellType.value && !!cell1['value']) {
        compareResult = cell1['value'].localeCompare(cell2['value']);
      } else if (!!cell1['linkName']) {
        compareResult = cell1['linkName'].localeCompare(cell2['linkName']);
      } else if (!!cell1['linkDescription']) {
        compareResult = cell1['linkDescription'].localeCompare(cell2['linkDescription']);
      } else {
        // At this point we cannot compare the two cells so we think them equal.
        compareResult = 0;
      }
      return sortDirection === 'asc' ? compareResult : reverseCompareResult(compareResult);
    };
  }

  static compareByStringArrayParameter(sortDirection: string, index: number): (item1: ProjectSummaryReportType[] | null, item2: ProjectSummaryReportType[] | null) => number {
    return (row1: ProjectSummaryReportType[], row2: ProjectSummaryReportType[]) => {
      if (_.isNil(row1) || _.isNil(row2)) {
        return 0;
      }
      const cell1 = row1[index];
      const cell2 = row2[index];
      if (_.isNil(cell1) || _.isNil(cell2) || _.isNil(cell1['values']) || _.isNil(cell2['values'])
      || _.isEmpty(cell1['values']) || _.isEmpty(cell2['values'])) {
        return 0;
      }

      const compareResult = cell1['values'][0].localeCompare(cell2['values'][0]);
      return sortDirection === 'asc' ? compareResult : reverseCompareResult(compareResult);
    };
  }
}

import { NgModule } from '@angular/core';
import { PermissionsTableComponent } from './permissions-table.component';
import { SelectPermissionsContainerComponent } from './select-permissions-container.component';
import { SelectPermissionsDialogComponent } from './select-permissions-dialog.component';
import { PermissionsDialogService } from './permissions-dialog.service';
import { MatTableModule } from '@angular/material/table';
import { ModalDialogModule } from '../modal-dialog/modal-dialog.module';
import { CommonModules } from '../common/common.module';
import { PermissionAssigneeUsernamePipe } from './permission-assignee-username.pipe';
import { PermissionAssigneeNamePipe } from './permission-assignee-name.pipe';

@NgModule({
  imports: [
    CommonModules,
    ModalDialogModule,
    MatTableModule
  ],
  declarations: [
    PermissionsTableComponent,
    SelectPermissionsContainerComponent,
    SelectPermissionsDialogComponent,
    PermissionAssigneeNamePipe,
    PermissionAssigneeUsernamePipe
  ],
  exports: [
    PermissionsTableComponent
  ],
  providers: [
    PermissionsDialogService
  ]
})
export class PermissionsModule { }

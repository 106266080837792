import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Diff } from '../../model/publish-changes/diff.model';
import { TextDiffData } from '../../model/publish-changes/text-diff-data.model';
import * as _ from 'lodash';
import { LocalStatus } from '../../../version-control/meta-info.model';

/**
 * Component used for displaying diff of file contents in publish changes modal windows
 */
@Component({
  selector: 'adm4-file-content-diff',
  templateUrl: './file-content-diff.component.html',
  styleUrls: ['./file-content-diff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileContentDiffComponent {
  @Input() fileContentDiff: Diff<TextDiffData>;

  readonly dateFormat = 'dd.MM.yyyy';
  readonly hourFormat = 'HH:mm';

  get hasRemoteCommitInfo(): boolean {
    return !_.isNil(this.fileContentDiff.remote.commitInfo);
  }

  get hasLocalInfo(): boolean {
    return this.fileContentDiff.local.changeInfo.localStatus !== LocalStatus.Unmodified;
  }
}

import { NgModule } from '@angular/core';
import { ProjectMenuComponent } from './project-menu.component';
import { CommonModules } from '../../common/common.module';

@NgModule({
  imports: [
    CommonModules
  ],
  declarations: [
    ProjectMenuComponent
  ],
  exports: [
    ProjectMenuComponent
  ]
})
export class ProjectMenuModule {
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { RevertProjectComponent } from './revert-project.component';
import { Revision } from '../../version-control/revision/revision.model';

@Injectable({
  providedIn: 'root'
})
export class RevertProjectDialogService {

  constructor(private dialog: MatDialog) {
  }

  public openRevertProjectDialog(projectKey: string): MatDialogRef<RevertProjectComponent, {projectKey: string, selectedRevision: Revision}> {
    const config: MatDialogConfig<string> = {
      width: '50%',
      maxWidth: '800px',
      autoFocus: false,
      position: {
        top: 'calc(25vh - 64px)'
      },
      data: projectKey,
      disableClose: false
    };
    return this.dialog.open(RevertProjectComponent, config);
  }
}

import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { OutputType, PlanningOutputModel } from './planning-output.model';
import { TreeNode } from '../../tree-viewer/tree-node.model';
import { Pattern } from '../../../patterns/pattern.model';
import { Dictionary } from '../../../model/reducer';
import { SplitPaneConfig } from '../../../common/model/split-pane-config.model';
import { IOutputData } from 'angular-split/lib/interface';
import { ResizeHelper } from '../../../common/helpers/resize.helper';
import { Mixin } from '../../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../../common/mixins/split.mixin';
import { InventorySchemaType } from '../../../inventory/inventory.model';

@Component({
  selector: 'adm4-deployment-preview-container',
  template: `
    <div class="full-height-flex">
      <h2 class='step-content-header'>Deployment preview</h2>
      <div class="remaining-space-flex-content-wrapper">
        <div class="remaining-space-flex-content">
          <as-split class='deployment-step-split as-split-handle-only as-split-handle-upper' direction='horizontal' gutterSize='5' useTransition (dragEnd)='onResize($event)'>
            <as-split-area [order]='splitPaneConfig[treeSplitAreaKey].order' [size]='splitPaneConfig[treeSplitAreaKey].size' [minSize]='20'>
              <div class="full-height split-primary-content">
                <adm4-deployment-preview-result [planningResults]='planningResults'
                                                [selectedNodeId]='selectedNodeId'
                                                [redeploymentForced]='redeploymentForced'
                                                [boxShadowClass]='boxShadowClass'
                                                [selectedInventorySchemaType]='selectedInventorySchemaType'
                                                (nodeSelected)='selectDetail($event)'></adm4-deployment-preview-result>
              </div>
            </as-split-area>
            <as-split-area [order]='splitPaneConfig[contentSplitAreaKey].order' [size]='splitPaneConfig[contentSplitAreaKey].size' [minSize]='40'>
              <div class="full-height split-secondary-content">
                <ng-container [ngSwitch]='selectedInventorySchemaType'>
                  <ng-container *ngSwitchCase='DeploymentType.KUBERNETES'>
                    <adm4-deployment-preview-details-kubernetes *ngIf='selectedNode'
                                                                [node]="selectedNode"
                                                                [outputType]='outputType'
                                                                [boxShadowClass]='boxShadowClass'
                                                                [patterns]='patterns'
                                                                (changeSelectedDetail)='selectNodeById($event)'>
                    </adm4-deployment-preview-details-kubernetes>
                  </ng-container>
                  <ng-container *ngSwitchCase='DeploymentType.CLASSIC'>
                    <adm4-deployment-preview-details *ngIf='selectedNode'
                                                     [node]="selectedNode"
                                                     [outputType]='outputType'
                                                     [boxShadowClass]='boxShadowClass'
                                                     [patterns]='patterns'
                                                     (changeSelectedDetail)='selectNodeById($event)'>
                    </adm4-deployment-preview-details>
                  </ng-container>
                </ng-container>
              </div>
            </as-split-area>
          </as-split>
        </div>
      </div>
    </div>
  `,
})
@Mixin([SplitMixin])
export class DeploymentPreviewContainerComponent implements ISplitMixin {
  @Input() planningResults: PlanningOutputModel[];
  @Input() redeploymentForced: boolean;
  @Input() boxShadowClass: string;
  @Input() patterns: Map<string, Pattern>;
  @Input() selectedInventorySchemaType: InventorySchemaType;
  readonly DeploymentType = InventorySchemaType;
  readonly outputType: OutputType = OutputType.PLAN;
  selectedNodeId: string;

  public selectedNode: any;

  readonly treeSplitAreaKey = 'tree';
  readonly contentSplitAreaKey = 'content';
  readonly splitPaneConfigLocalStorageKey = 'planning-output-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.treeSplitAreaKey]: {order: 0, size: 30},
    [this.contentSplitAreaKey]: {order: 1, size: 70}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  constructor(
      private cdRef: ChangeDetectorRef,
  ) {
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
  }

  selectDetail(selectedNode: TreeNode) {
    this.selectedNode = selectedNode;
    this.selectedNodeId = selectedNode.id;
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  selectNodeById(detailId: string): void {
    this.selectedNodeId = detailId;
  }
}

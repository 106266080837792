import { VariableModel } from '../../variables/variable.model';
import * as _ from 'lodash';
import { Pattern } from '../../patterns/pattern.model';
import { InsertProjectVariablesHelper } from '../insert-project-variables-dialog/insert-project-variables.helper';

export class InventorySampleContentCommonHelper {

  static createVariablesSampleBlock(variables?: VariableModel[]) {
    if (_.isNil(variables) || _.isEmpty(variables)) {
      return '';
    }

    return InsertProjectVariablesHelper.convertVariablesToYaml(variables, 'vars:') + '\n';
  }

  static getDeploymentHosts(patterns: Pattern[]): (string | undefined)[] {
    return _.uniq(patterns.filter((pattern: Pattern) => !_.isNil(pattern.deploymentHosts))
      .map((pattern: Pattern) => pattern.deploymentHosts)
      .map((deploymentHosts: string) => deploymentHosts.trim()));
  }
}


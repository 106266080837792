import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FlattenedGroupedPatternListData, FlattenedLabel, FlattenedPatternListData, GroupedPatternListData } from './grouped-pattern-list-data.model';
import { PatternListData } from '../pattern-list-data.model';
import * as _ from 'lodash';
import { PatternSortingHelper } from '../pattern-sorting.helper';

// responsibility: create groups of the patterns and update if labelView option or patterns has been changed
@Injectable()
export class GroupedPatternListContext {
  dataChange = new BehaviorSubject<GroupedPatternListData[]>([]);
  private readonly UNLABELLED_PATTERNS = 'Unlabelled patterns';

  get data(): GroupedPatternListData[] {
    return this.dataChange.value;
  }

  updatePatternGroups(patterns: PatternListData[], isLabelView: boolean, selection: string, expandedGroups: string[], filterTextFilled: boolean) {
    const groupedPatterns = this.groupedPatterns(patterns, isLabelView, selection, expandedGroups, filterTextFilled);
    this.dataChange.next(groupedPatterns);
  }

  groupedPatterns(patterns: PatternListData[], isLabelView: boolean, selection: string, expandedGroups: string[], filterTextFilled: boolean): GroupedPatternListData[] {
    if (_.isEmpty(patterns)) {
      return [];
    }
    if (!isLabelView) {
      return this.mergePatternsIntoOneGroup(patterns);
    }
    const noGroup = this.UNLABELLED_PATTERNS;
    const groupedPatterns = _.groupBy(patterns, (p: PatternListData) => {
      if (_.isNil(p.pattern.label)) {
        return noGroup;
      }
      return p.pattern.label;
    });
    return _.entries(groupedPatterns)
      .sort((group1: [string, PatternListData[]], group2: [string, PatternListData[]]) => PatternSortingHelper.byLabelNameSortingFn(group1, group2, this.UNLABELLED_PATTERNS))
      .map(([groupHeader, patternList]: [string, PatternListData[]]) => {
        return {
          labelName: groupHeader,
          patterns: patternList,
          isExpanded: this.isExpanded(groupHeader, patternList, selection, expandedGroups, filterTextFilled),
        };
      });
  }

  mergePatternsIntoOneGroup(patterns: PatternListData[]): GroupedPatternListData[] {
    return [{
      labelName: '',
      patterns: patterns,
      isExpanded: true,
    }];
  }

  private isExpanded(groupHeader: string, patternsWithingGroup: PatternListData[], selectedPatternId: string, expandedGroups: string[], filterTextFilled: boolean): boolean {
    const hasPatternSelected = _.reduce(patternsWithingGroup, (result, patternListData) => {

      if (patternListData.pattern.patternId === selectedPatternId) {
        return true;
      }
      return result;
    }, false);
    const filteredPatternToExpand = filterTextFilled ? true : expandedGroups.includes(groupHeader);
    return !_.isNil(_.find(expandedGroups, (expanded) => expanded === groupHeader)) || filteredPatternToExpand || hasPatternSelected;
  }

  /**
   * Creates a flat list of the GroupedPatternListData[] that can be displayed in the pattern master list
   * level: indicates if an element is label indicator (and has pattern child elements) or a simple pattern list element
   * labelName: only for level 0 items (that are FlattenedLabel types)
   * patternListData: only for level 1 items (that are FlattenedPatternListData types)
   * @param groupedPatternListDataItems
   * @param isLabelView
   */
  flattenDataSource(groupedPatternListDataItems: GroupedPatternListData[], isLabelView: boolean): FlattenedGroupedPatternListData[] {
    return _.reduce(groupedPatternListDataItems, (finalFlatList: FlattenedGroupedPatternListData[], groupedPatternListData: GroupedPatternListData) => {
      if (isLabelView) {
        const labelItem: FlattenedLabel = {
          level: 0,
          isExpanded: groupedPatternListData.isExpanded,
          labelName: groupedPatternListData.labelName
        };
        finalFlatList.push(labelItem);
      }
      if (!_.isEmpty(groupedPatternListData.patterns) && groupedPatternListData.isExpanded) {
        const flattenedPatternList: FlattenedPatternListData[] = this.flattenPatternListFromData(groupedPatternListData.patterns);
        finalFlatList.push(...flattenedPatternList);
      }
      return finalFlatList;
    }, []);
  }

  private flattenPatternListFromData(patternListDataItems: PatternListData[]): FlattenedPatternListData[] {
    return _.reduce(patternListDataItems, (patternList: FlattenedPatternListData[], patternListData: PatternListData) => {
      const flatChild: FlattenedPatternListData = {
        level: 1,
        isExpanded: true,
        patternListData: patternListData
      };
      return patternList.concat(flatChild);
    }, []);
  }
}

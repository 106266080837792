import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type InventoryEditorUserActions = 'RESET' | 'INSERT-GLOBAL-CONSTANT' |
    'INSERT-SECRET-URI' | 'ATTACH-SECRET-FILE' | 'ATTACH-FILE' | 'ATTACH-CERT' | 'INSERT-PRJ-VARS';

@Component({
  selector: 'adm4-inventory-editor-actions',
  template: `
<adm4-button-bar [isSubmitDisabled]='saveDisabled'
                 [isCancelDisabled]='saveDisabled'
                 [cancelButtonText]='"Reset"'
                 [wrapperClass]='"admn4-buttons-wrapper"'
                 (cancelClicked)="action.emit('RESET')">
  <div class='actions'>
    <ng-template #disabledTooltip>Please click inside the inventory file before you insert a secret, import
      variables or attach files.
    </ng-template>
    <div class="disabled-overlay" *ngIf="actionsDisabled" [ngbTooltip]='disabledTooltip'></div>
    <button mat-button type="button" class="touch-target-contain icon-flex" [disabled]="actionsDisabled" 
            [ngbTooltip]="'Insert global constant'" (click)="action.emit('INSERT-GLOBAL-CONSTANT')">
        <mat-icon [svgIcon]="'global_constants'"></mat-icon>
    </button>
    <button mat-button type="button" class="touch-target-contain" [disabled]="actionsDisabled"
            [ngbTooltip]="'Insert secret'" (click)="action.emit('INSERT-SECRET-URI')">
        <mat-icon>lock</mat-icon>
    </button>
    <button mat-button type="button" class="touch-target-contain icon-flex" [disabled]="actionsDisabled"
            [ngbTooltip]="'Attach secret file'" (click)="action.emit('ATTACH-SECRET-FILE')">
        <mat-icon [svgIcon]="'file-lock'"></mat-icon>
    </button>
    <button mat-button type="button" class="touch-target-contain" [disabled]="actionsDisabled"
            [ngbTooltip]="'Attach certificate'" (click)="action.emit('ATTACH-CERT')">
        <mat-icon>verified</mat-icon>
    </button>
    <button mat-button type="button" class="touch-target-contain" [disabled]="actionsDisabled"
            [ngbTooltip]="'Attach file'" (click)="action.emit('ATTACH-FILE')">
        <mat-icon>description</mat-icon>
    </button>
    <button mat-button type="button" class="touch-target-contain" [disabled]="actionsDisabled"
            [ngbTooltip]="'Import variables'" (click)="action.emit('INSERT-PRJ-VARS')">
        <mat-icon>save_alt</mat-icon>
    </button>
  </div>
</adm4-button-bar>
  `,
  styleUrls: ['./inventory-editor-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryEditorActionsComponent {

  @Input()
  public saveDisabled: boolean = true;

  @Input()
  public actionsDisabled: boolean = true;

  @Output()
  public action: EventEmitter<InventoryEditorUserActions> = new EventEmitter();
}

import { PluginDocumentationModel } from '../help/plugin-documentation.model';
import { PatternInstance } from '../patterns/pattern-instance.model';
import { PluginModel } from '../plugins/plugin.model';

interface Action<T> {
  readonly type: string;
  payload?: T;
}

export interface NevisAdminAction<T = undefined> extends Action<T> {
  payload?: T extends undefined ? Action<T>['payload'] : Required<Action<T>>['payload'];
}

export enum ActionTypes {
  InitApplication = 'Init application',
  LoadPluginDoc = 'Load plugin doc',
  LoadPluginDocForPatternType = 'Load plugin doc for create pattern',
  LoadPluginDocForPatternTypes = 'Load plugin doc array for create pattern',
  LoadPluginDocSuccess = 'Load plugin doc success',
  LoadPluginDocForPatternTypesSuccess = 'Load plugin doc array success',
  InvalidatePluginDoc = 'Invalidate plugin doc',
  Empty = 'Does nothing'
}

/** Synthetic action indicating application startup. Used only as a trigger for effects. */
export class InitApplication implements NevisAdminAction {
  readonly type = ActionTypes.InitApplication;
}

export class LoadPluginDoc implements NevisAdminAction<PatternInstance> {
  readonly type = ActionTypes.LoadPluginDoc;

  constructor(public payload: PatternInstance) {}
}

export class LoadPluginDocForPatternType implements NevisAdminAction<PluginModel> {
  readonly type = ActionTypes.LoadPluginDocForPatternType;

  constructor(public payload: PluginModel) {}
}

export class LoadPluginDocForPatternTypes implements NevisAdminAction<PluginModel []> {
  readonly type = ActionTypes.LoadPluginDocForPatternTypes;

  constructor(public payload: PluginModel[]) {}
}

export class LoadPluginDocSuccess implements NevisAdminAction<PluginDocumentationModel> {
  readonly type = ActionTypes.LoadPluginDocSuccess;

  constructor(public payload: PluginDocumentationModel) {}
}

export class LoadPluginDocForPatternTypesSuccess implements NevisAdminAction<PluginDocumentationModel []> {
  readonly type = ActionTypes.LoadPluginDocForPatternTypesSuccess;

  constructor(public payload: PluginDocumentationModel[]) {}
}

export class InvalidatePluginDoc implements NevisAdminAction<string> {
  readonly type = ActionTypes.InvalidatePluginDoc;
}

export class EmptyAction implements NevisAdminAction {
  readonly type = ActionTypes.Empty;
}

import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { InventoryChangesetItem } from './inventory-changeset-item.model';
import { MetaInfo } from '../../version-control/meta-info.model';
import { PublishInventoryContext } from './publish-inventory.context';
import { first, map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import { CommitMessageHelper } from '../../common/helpers/commit-message.helper';
import { InventoryMeta } from '../inventory.model';
import { PublishInventoryChangesetHelper } from './publish-inventory-changeset.helper';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';

@Injectable()
export class PublishInventoryChangesetContext {
  changesetItems$: Observable<InventoryChangesetItem<MetaInfo, any>[]>;
  initialCommitMessage$: Observable<string>;

  constructor(private publishInventoryContext: PublishInventoryContext) {
    this.changesetItems$ = combineLatest([this.publishInventoryContext.inventoryMeta$, this.publishInventoryContext.inventoryValidationStatus$]).pipe(
      first(([inventoryMeta]: [InventoryMeta | null, ValidationStatus<InventoryValidationIssue> | null]) => !_.isNil(inventoryMeta)),
      map(([inventoryMeta, inventoryValidationStatus]: [InventoryMeta, ValidationStatus<InventoryValidationIssue> | null]) => {
        return PublishInventoryChangesetHelper.createInventoryChangesetFromInventoryMeta(inventoryMeta, inventoryValidationStatus);
      }),
      startWith([])
    );
    this.initialCommitMessage$ = this.changesetItems$.pipe(map((changesetItems: InventoryChangesetItem<MetaInfo, any>[]) => CommitMessageHelper.createDefaultCommitMessage(changesetItems)));
  }
}

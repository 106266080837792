<form [formGroup]='form' (ngSubmit)='submitProjectZip()'>
  <div class='create-item-container content-container'>
    <div class="form-group">
      <label class='input-label'>Select file</label>
      <adm4-single-file-input [formControlName]='PROJECT_ZIP_FORM_CONTROL_NAME'
                              [fileExtensions]='[".zip"]'
                              [placeholderText]='"Select a .zip file containing a project..."'
                              [buttonText]='"Select project"'
      ></adm4-single-file-input>
    </div>
    <div class='form-group item-key-group'>
      <div class='input-label'>
        Import as
      </div>
      <mat-radio-group [adm4ControlDisable]='!hasZipSelected' [formControlName]='PROJECT_KEY_MODE_FORM_CONTROL_NAME'>
        <div class='new-item-group'>
          <div class="item-key-container" [ngbTooltip]='DISABLED_NEW_PROJECT_MODE_TOOLTIP_TEXT' [disableTooltip]='!shouldShowDisabledNewProjectModeTooltip' placement='top'>
            <div class='tenant-key-wrapper'>
              <mat-radio-button class="left-align" [disabled]='!canCreateNewProject' [value]='ProjectKeyMode.New'>New project: {{displayTenant? selectedTenant.tenantKey.concat(' - ') : ''}} </mat-radio-button>
              <div class='validation-message-container'></div>
            </div>
            <div class='item-key-input-wrapper'>
              <span class="radio-align-vertically">
                <input class='admn4-text-input form-control' placeholder=' ' name="projectKey"
                       [formControlName]='CREATED_PROJECT_KEY_FORM_CONTROL_NAME'
                       [class.errored]="form.controls[CREATED_PROJECT_KEY_FORM_CONTROL_NAME].invalid"
                       [adm4ControlDisable]='!canCreateNewProject'
                       (click)='chooseNewProjectKeyMode()'/>
              </span>
              <div class='validation-message-container'>
                <adm4-validation-message *ngIf='shouldShowCreatedProjectKeyErrorMessage("pattern")' [isError]='true'
                                         [message]='ERROR_INVALID_PROJECT_KEY'></adm4-validation-message>
                <adm4-validation-message *ngIf='shouldShowCreatedProjectKeyErrorMessage("required")' [isError]='true'
                                         [message]='ERROR_PROJECT_KEY_REQUIRED'></adm4-validation-message>
                <adm4-validation-message *ngIf='shouldShowCreatedProjectKeyErrorMessage("projectKeyExists")' [isError]='true'
                                         [message]='projectForTenantExistsError'></adm4-validation-message>
              </div>
            </div>
          </div>
        </div>
        <div class='new-item-group'>
          <div class="item-key-container" [ngbTooltip]='DISABLED_EXISTING_PROJECT_MODE_TOOLTIP_TEXT' [disableTooltip]='!shouldShowDisabledExistingProjectModeTooltip' placement='top'>
            <div class='tenant-key-wrapper'>
              <mat-radio-button class="left-align" [disabled]='!canSelectExistingProject' [value]='ProjectKeyMode.Existing'>Existing project: {{displayTenant? selectedTenant.tenantKey.concat(' - ') : ''}} </mat-radio-button>
              <div class='validation-message-container'></div>
            </div>
            <div class='item-key-input-wrapper'>
              <span class="radio-align-vertically">
                <mat-form-field class='full-width form-field-fix' (click)='chooseExistingProjectKeyMode()'>
                  <mat-select #projectSelection
                              [adm4ControlDisable]='!canSelectExistingProject'
                              [disableOptionCentering]='true'
                              [formControlName]='EXISTING_PROJECT_KEY_FORM_CONTROL_NAME'
                              (click)='focusDropdownInput()'>
                    <adm4-searchable-dropdown-input *ngIf='projectSelection.focused'
                                                    [sourceItems]='projectsWithModifyPermission'
                                                    [placeholder]="'Please select a project...'"
                                                    [searchableFormatFn]='searchableProjectFormatFn'
                                                    [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                                    (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
                    <mat-option (onSelectionChange)='onSelectExistingProject(project.projectKey)' *ngFor='let project of filteredProjectList' [value]='project.projectKey'>
                      {{project.projectKey | cropTenantFromKey}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
              <div class='validation-message-container'></div>
            </div>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </div>
  <div mat-dialog-actions>
    <button type='button' class='admn4-button-text'
            (click)='cancel()'>
      Cancel
    </button>
    <button class='admn4-button-ellipse-blue'
            [disabled]='!canImport'>
      Import
    </button>
  </div>
</form>

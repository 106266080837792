export class InventoryColorHelper {
  static getInventoryBackgroundClassName(colorName: string | undefined): string {
    if (!colorName) {
      return 'default-inventory-background';
    }
    return `${colorName.toLowerCase()}-inventory-background`;
  }

  static getInventoryBoxShadowClassName(color: string | undefined): string {
    if (!color) {
      return 'default-inventory-box-shadow';
    }
    return `${color.toLowerCase()}-inventory-box-shadow`;
  }

  static getInventoryHeaderClassName(color: string | undefined): string {
    if (!color) {
      return 'default-inventory-header';
    }
    return `${color.toLowerCase()}-inventory-header`;
  }

  static getInventorySelectionItemClassName(colorName: string | undefined): string {
    if (!colorName) {
      return 'default-inventory-selection-item';
    }
    return `${colorName.toLowerCase()}-inventory-selection-item`;
  }

  static getInventoryMenuItemClassName(colorName: string | undefined): string {
    if (!colorName) {
      return 'default-inventory-menu-item';
    }
    return `${colorName.toLowerCase()}-inventory-menu-item`;
  }

  static getInventoryMatExtensionPanelClassName(colorName: string | undefined): string {
    if (!colorName) {
      return 'default-inventory-mat-extension-panel';
    }
    return `${colorName.toLowerCase()}-mat-extension-panel`;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProjectSummaryReportContext } from '../project-summary-report/project-summary-report.context';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import { NavigationConstants } from '../../../common/constants/navigation.constants';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { LoadPatternSummaryReports } from '../../../model/report/report.actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';

import { Unsubscriber } from '../../../common/helpers/unsubscriber';
import { patternSummaryReportsCachedView, projectKeyView } from '../../../model/views';

@Component({
  selector: 'adm4-project-summary-content',
  template: `
    <div class='full-height-flex project-summary-content-container'>
      <nav mat-tab-nav-bar [tabPanel]="projectSummaryTabPanel">
        <a mat-tab-link *ngFor='let link of navLinks'
           [routerLink]='link.link'
           routerLinkActive #rla="routerLinkActive"
           [active]='rla.isActive'>{{link.label}}</a>
        <span class="flex-grow-1"></span>
        <div *ngIf='!isSummaryActive' class='search-input-wrapper'>
          <mat-form-field>
            <input matInput placeholder="Search..." [(ngModel)]="filterText" (ngModelChange)='searchReportTables($event)'>
            <button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearch()">
              <mat-icon class="size-16">close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </nav>
      <mat-tab-nav-panel #projectSummaryTabPanel class="remaining-space-flex-content-wrapper">
        <ng-template #generating><div class='loading-msg'>Reports are generating. Please wait... </div></ng-template>
        <div class='remaining-space-flex-content summary-tabs-content' *ngIf='isSummaryActive || (areReportsLoaded | async); else generating'>
          <router-outlet></router-outlet>
        </div>
      </mat-tab-nav-panel>
    </div>
  `,
  providers: [ProjectSummaryReportContext],
  styleUrls: ['./project-summary-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryContentComponent implements OnInit, OnDestroy {
  @Input() public projectKey: string;
  private _reportSubscription: Subscription;

  filterText = '';
  navLinks: any[];

  private reportTabPaths = [
    NavigationConstants.OVERVIEW_APPLICATIONS,
    NavigationConstants.OVERVIEW_REALMS,
    NavigationConstants.OVERVIEW_INSTANCES
  ];

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private reportContext: ProjectSummaryReportContext,
              private router: Router,
              private route: ActivatedRoute,
              private store$: Store<AppState>,
  ) {
    this.navLinks = [
      {
        label: 'Applications',
        link: './applications'
      },
      {
        label: 'Realms',
        link: './realms'
      },
      {
        label: 'Instances',
        link: './instances'
      },
      {
        label: 'Summary',
        link: './summary'
      },
    ];
  }

  get isSummaryActive() {
    const urlEnd = this.getUrlEnd();
    return urlEnd.startsWith(NavigationConstants.OVERVIEW_SUMMARY);
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      takeUntil(this.destroyed$),
      map(queryparams => _.get(queryparams, NavigationConstants.SUMMARY_SEARCH_TERM)),
    ).subscribe((searchTerm: string) => this.searchReportTablesFromUrl(searchTerm));

    this.triggerLoadingSummaryReportsIfNeeded();
  }

  /**
   * Trigger loading of Pattern Summary Reports in the first page load (if not the Summary tab is selected)
   * and when there's no report cache (or it become invalid) and a fresh report is needed
   */
  private triggerLoadingSummaryReportsIfNeeded(): void {
    this._reportSubscription = merge(
      this.store$.pipe(select(patternSummaryReportsCachedView), distinctUntilChanged()),
      this.router.events
        .pipe(
          takeUntil(this.destroyed$),
          filter(e => e instanceof NavigationEnd),
          filter((e: NavigationEnd) => this.reportTabPaths.some((link: string) => e.urlAfterRedirects.includes(link))),
          withLatestFrom(this.store$.pipe(select(patternSummaryReportsCachedView)))
        )).pipe(map((reportsCacheResponse: boolean | [NavigationEnd, boolean]) => {
      return reportsCacheResponse instanceof Array ? reportsCacheResponse[1] : reportsCacheResponse;
    }), withLatestFrom(this.store$.pipe(select(projectKeyView))))
      .subscribe((reportsCacheTrigger: [boolean, string]) => {
        const isReportCached = reportsCacheTrigger[0];
        const selectedProjectKey = reportsCacheTrigger[1];
        // We only need to trigger report loading when user navigates to any of the report tabs
        if (!this.isSummaryActive && !isReportCached) {
          this.store$.dispatch(new LoadPatternSummaryReports(selectedProjectKey));
        }
      });
  }

  private getUrlEnd() {
    const url = this.router.url;
    const lastSlashIndex = url.lastIndexOf('/');
    return url.substring(lastSlashIndex + 1);
  }

  searchReportTablesFromUrl(searchTerm: string) {
    this.filterText = searchTerm;
    this.searchReportTables(searchTerm);
  }

  searchReportTables(searchTerm: string) {
    this.reportContext.searchReportTables(searchTerm);
  }

  resetSearch() {
    this.filterText = '';
    this.searchReportTables('');
  }

  ngOnDestroy(): void {
    Unsubscriber.unsubscribeFrom(this._reportSubscription);
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  get areReportsLoaded(): Observable<boolean> {
    return this.store$.select(patternSummaryReportsCachedView);
  }
}

import { Directive, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[adm4FileLoader]'
})
export class FileLoaderDirective implements OnChanges, OnDestroy {
  @Input() multiFile = false;
  @Input() acceptedExtensions?: string[];
  @Output() load: EventEmitter<FileList> = new EventEmitter();

  private readonly formElement: HTMLFormElement;
  private readonly inputElement: HTMLInputElement;
  private readonly stopListener: Function;

  constructor(private readonly renderer: Renderer2) {
    this.formElement = this.renderer.createElement('form');
    this.inputElement = this.renderer.createElement('input');
    this.inputElement.type = 'file';
    this.stopListener = this.renderer.listen(this.inputElement, 'change', (event: Event) => this.onFilesLoad(event));
    this.renderer.appendChild(this.formElement, this.inputElement);
  }

  @HostListener('click')
  loadFiles() {
    this.inputElement.click();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Set multiple parameter just if multiFile set to true otherwise the default behavior is a single file upload
    if (changes.multiFile && _.isEqual(this.multiFile, true) ) {
      this.renderer.setAttribute(this.inputElement, 'multiple', this.multiFile.toString());
    }
    if (changes.acceptedExtensions) {
      this.renderer.setAttribute(this.inputElement, 'accept', _.isNil(this.acceptedExtensions) ? '' : this.acceptedExtensions.join(','));
    }
  }

  ngOnDestroy(): void {
    this.stopListener();
  }

  onFilesLoad(event: Event): void {
    const fileInput: HTMLInputElement = <HTMLInputElement>event.target;
    const files: FileList | null = fileInput.files;
    if (!_.isNil(files)) {
      this.load.emit(files);
    }
    this.formElement.reset();
  }
}

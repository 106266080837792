import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { VariableListItem } from './variable.model';
import { select, Store } from '@ngrx/store';
import { AppState, ProjectKey } from '../model/reducer';

import { PropertyType } from '../plugins/property-type.model';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { PatternType } from '../plugins/pattern-type.model';
import { issuesView, patternsView, patternTypesView, projectKeyView, propertyTypesView, variableListItemsView } from '../model/views';
import { Pattern } from '../patterns/pattern.model';
import { IssueModel } from '../common/model/issue.model';
import { requireNonNull } from '../common/utils/utils';
import { getHighestSeverity } from '../projects/project-issues/project-issues.helper';
import { IssueSeverityEnum } from '../common/model/issue-severity.enum';
import { ProjectContext } from '../projects/project.context';
import { VariablesMainContext } from './variables-main.context';
import { SplitPaneConfig } from '../common/model/split-pane-config.model';
import { IOutputData } from 'angular-split/lib/interface';
import { ResizeHelper } from '../common/helpers/resize.helper';
import { Mixin } from '../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../common/mixins/split.mixin';
import { Project, ProjectMetaChangeIndicator } from '../projects/project.model';

@Component({
  selector: 'adm4-variables-main',
  template: `
    <div class='full-height'>
      <as-split class="hide-as-split-gutter" direction='horizontal' gutterSize='1' useTransition (dragEnd)='onResize($event)'>
        <as-split-area [order]='splitPaneConfig[listSplitAreaKey].order' [size]='splitPaneConfig[listSplitAreaKey].size' [minSize]='20'>
          <div class='full-height-flex' [class.read-only]='readOnly$ | async'>
            <adm4-column-header styleClass="project-header">
              <adm4-project-header
                      [projectKey]='projectKey$ | async'
                      [projectIssues]='projectIssues$ | async'
                      [highestSeverity]="highestSeverity$ | async"
                      [projects]='projects$ | async'
                      [projectMetaIndicator]="projectMetaChangeIndicator$ | async"
              ></adm4-project-header>
            </adm4-column-header>
            <div class='remaining-space-flex-content-wrapper'>
              <div class='remaining-space-flex-content'>
                <adm4-variables #variableComp
                                [variableListItems]='variableListData$ | async'
                                [variableTypes]='variableTypes$ | async'
                                [projectKey]="projectKey$ | async"
                                [selectedVariableKey]='variableKey$ | async'
                ></adm4-variables>
              </div>
            </div>
          </div>
        </as-split-area>
        <as-split-area [order]='splitPaneConfig[contentSplitAreaKey].order' [size]='splitPaneConfig[contentSplitAreaKey].size' [minSize]='40'>
          <div class="full-height">
            <router-outlet></router-outlet>
          </div>
        </as-split-area>
      </as-split>
    </div>
  `,
  styleUrls: ['variable-main.scss'],
  providers: [VariablesMainContext]
})
@Mixin([SplitMixin])
export class VariablesMainComponent implements ISplitMixin, OnInit {
  public variableListData$: Observable<VariableListItem[]>;
  public projectKey$: Observable<ProjectKey>;
  public projects$: Observable<Dictionary<Project>>;
  public variableTypes$: Observable<Dictionary<PropertyType> | null>;
  public patternTypes$: Observable<Dictionary<PatternType> | null>;
  public patterns$: Observable<Pattern[]>;
  public readOnly$: Observable<boolean>;
  public projectIssues$: Observable<IssueModel[] | undefined>;
  public highestSeverity$: Observable<IssueSeverityEnum>;
  public projectMetaChangeIndicator$: Observable<ProjectMetaChangeIndicator>;

  variableKey$: Observable<string | undefined>;

  readonly listSplitAreaKey = 'list';
  readonly contentSplitAreaKey = 'content';
  readonly splitPaneConfigLocalStorageKey = 'variable-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.listSplitAreaKey]: {order: 0, size: 30},
    [this.contentSplitAreaKey]: {order: 1, size: 70}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  constructor(
              private store$: Store<AppState>,
              private projectContext: ProjectContext,
              private variableMainContext: VariablesMainContext,
  ) {
    this.projectKey$ = this.store$.pipe(select(projectKeyView), filter<string>((projectKey: string | null) => !_.isNil(projectKey)));
    this.projects$ = this.projectContext.projects$;
    this.variableListData$ = this.store$.pipe(select(variableListItemsView));
    this.variableTypes$ = this.store$.pipe(select(propertyTypesView));
    this.patternTypes$ = this.store$.pipe(select(patternTypesView));
    this.patterns$ = this.store$.pipe(select(patternsView), map(patterns => Array.from(patterns.values())));
    this.projectIssues$ = this.store$.pipe(select(issuesView));
    this.variableKey$ = this.variableMainContext.selectedVariableKey$;
    this.readOnly$ = projectContext.isProjectReadOnly$;
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
    this.projectMetaChangeIndicator$ = this.projectContext.projectMetaChangeIndicator$;
  }

  ngOnInit(): void {
    this.highestSeverity$ = this.projectIssues$.pipe(map(issues => getHighestSeverity(requireNonNull(issues))));
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileUploadResultsDialogComponent } from './file-upload-results-dialog.component';
import { FileUploadResult } from '../../model/file-upload-result.model';
import { FileUploadResultsOptions, FileUploadResultsPayload } from './file-upload-results-payload.model';

@Injectable({
  providedIn: 'root'
})
export class FileUploadResultsDialogService {

  constructor(private matDialog: MatDialog) {}

  openFileUploadResultsDialog(fileUploadResults: FileUploadResult<any>[], options?: FileUploadResultsOptions): MatDialogRef<FileUploadResultsDialogComponent> {
    return this.matDialog.open<FileUploadResultsDialogComponent, FileUploadResultsPayload>(FileUploadResultsDialogComponent, {
      autoFocus: false,
      width: '30%',
      data: {
        fileUploadResults: fileUploadResults,
        ...options
      }
    });
  }
}

import { mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService, YAML_CONTENT } from '../../../shared/base.service';
import { Observable, of } from 'rxjs';
import { readTextFile } from '../../../file-utils';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ValidationStatusHelper } from '../../helpers/validation-status.helper';
import * as _ from 'lodash';

@Injectable()
export class FileService extends BaseService {
  loadFile(fileUrl: string, headers?: HttpHeaders, params?: HttpParams): Observable<Blob> {
    return this.httpGetBlob(fileUrl, headers, params);
  }

  loadFileContent(fileUrl: string): Observable<string> {
    return this.loadFile(fileUrl).pipe(
      mergeMap((blob: Blob) => readTextFile(blob)));
  }

  loadFileContentWithHeaders(fileUrl: string, headers: HttpHeaders): Observable<string> {
    return this.loadFile(fileUrl, headers).pipe(
      mergeMap((blob: Blob) => readTextFile(blob)));
  }

  loadYamlFileContent(fileUrl: string): Observable<string> {
    return this.loadFileContentWithHeaders(fileUrl, new HttpHeaders({'Content-Type': YAML_CONTENT}));
  }

  writeToFile(text: string, type: string): Blob {
    return new Blob([text], {type: type});
  }

  saveTextToFile(fileUrl: string, text: string, textType: string, headers: HttpHeaders): Observable<any> {
    const blob = this.writeToFile(text, textType);
    return this.httpPutBlob(fileUrl, blob, headers);
  }

  saveYamlContentToFile(fileUrl: string, content): Observable<any> {
    if(_.isEmpty(content)){
      return  of({
        _status : {
          _errors : [ {
            detail : 'This inventory is empty.',
          } ],
        }
      });
    }

    return this.saveTextToFile(fileUrl, content, YAML_CONTENT, new HttpHeaders({'Content-Type': YAML_CONTENT})).pipe(tap(value =>
      value._status = ValidationStatusHelper.changeAllIssuesToError(value._status)
    ));
  }
}

<div class="full-height-flex">
	<div class="step-sub-heading-with-actions">
		<div class='step-content-header'><mat-icon class='title' *ngIf="titleIcon">{{titleIcon}}</mat-icon>{{node.details.path}}</div>
	</div>
	<div class="remaining-space-flex-content-wrapper">
		<div class="remaining-space-flex-content">
			<div class="step-content-card item-details full-height" [ngClass]='boxShadowClass'>
				<p>
					<adm4-k8s-detail-message
									[node]='node'
									[outputType]='outputType'
									[pattern]='pattern'
									[projectKey]='projectKey'>
					</adm4-k8s-detail-message>
				</p>
				<p>
					<strong>Target service</strong>
				</p>
				<p>{{node.output.host}}</p>
			</div>
		</div>
	</div>
</div>

import { Component, Input } from '@angular/core';
import { Revision } from '../../../version-control/revision/revision.model';

@Component({
  selector: 'adm4-commit-info',
  template: `
    <div class='commit-container'>
      <div class="title">
        {{commitInfo.committer.name}}, commit: <span class="commit-id">{{commitInfo.commitId}}</span>
      </div>
      <div class="subtitle">
        {{commitInfo.committer.when | date: commitDateFormat}}
      </div>
      <br/>
      <div class="message">
        {{commitInfo.message}}
      </div>
    </div>
  `,
  styleUrls: ['./commit-info.component.scss']
})
export class CommitInfoComponent {
  @Input() commitInfo: Revision;

  readonly commitDateFormat = 'dd.MM.yyyy HH:mm';
}

import { NevisAdminAction } from '../../model/actions';
import { ParsedBundle } from '../bundle-management/bundle.model';

export enum BundleManagementActionTypes {
  LoadBundles = '[BundleManagement] Load Bundles',
  LoadBundlesSuccess = '[BundleManagement] Load Bundles Success',
  UploadBundles = '[BundleManagement] Upload Bundles'
}

export class LoadBundles implements NevisAdminAction<null> {
  readonly type = BundleManagementActionTypes.LoadBundles;
  readonly payload = null;
}

export class LoadBundlesSuccess implements NevisAdminAction<ParsedBundle[]> {
  readonly type = BundleManagementActionTypes.LoadBundlesSuccess;
  constructor(public readonly payload: ParsedBundle[]) {}
}

export class UploadBundles implements NevisAdminAction<File[]> {
  readonly type = BundleManagementActionTypes.UploadBundles;
  constructor(public readonly payload: File[]) {}
}

export type BundleManagementActions = LoadBundles | LoadBundlesSuccess;

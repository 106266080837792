import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable, combineLatest, of, EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { GlobalConstantWithUsage } from '../../inventory/inventory.model';
import { InventoryService } from '../../inventory/inventory.service';

import { AppState } from '../../model/reducer';
import { LoadTenantConstants } from '../../model/inventory';
import { selectedTenantKeyView, tenantScopedConstantsWithUsageView } from '../../model/views';
import { hasTenantModificationAccessView } from '../../model/views/permission.views';
import {
  EditGlobalConstantDialogComponent,
  EditGlobalConstantDialogPayload
} from './edit-global-constant-dialog/edit-global-constant-dialog.component';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { ErrorHelper } from '../../common/helpers/error.helper';
import { NotificationMessage } from '../../notification/notification.model';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { NavigationService } from '../../navbar/navigation.service';
import { InventoryResourceActionDialogHelper } from '../secret-management/inventory-resource-action/inventory-resource-action-dialog.helper';

@Component({
  selector: 'adm4-global-constants',
  template: `
      <div class='full-height-flex'>
        <adm4-column-header [styleClass]='"light-inline-header"'>
          <span>Global Constants</span>
        </adm4-column-header>
        <div class='remaining-space-flex-content-wrapper details-container gc-content d-flex flex-column'>
          <p class="flex-shrink-0">The global constants are the global values which can be referenced in multiple inventories.
            You create a global constant here, and in your inventories you can refer to it for your variable value.</p>
          <div class="gc-toolbar flex-shrink-0 d-flex justify-content-between">
            <adm4-filter (filter)="filterBySearch($event)"
                         [placeholderText]='"Type to filter..."'
                         class='search-input'>
            </adm4-filter>
            <button class="admn4-button-ellipse-blue"
                    [disabled]="!(hasModifyTenantPermission$ | async)"
                    [title]="(hasModifyTenantPermission$ | async) ? 'Create global constant' : 'You don’t have permission to create.'"
                    (click)="createClicked()"
            >Create global constant</button>
          </div>
          <ng-container *ngIf="hasConstants | async; else emptyMessage">
            <adm4-global-constants-table class="flex-shrink-1"
                [globalConstants]="globalConstants | async"
                [filter]="tableFilter"
                [hasModifyTenantPermission]="hasModifyTenantPermission$ | async"
                (edit)="edit($event)"
                (delete)="delete($event)"
                (navigateToInventory)="navigateToInventory($event)"
            ></adm4-global-constants-table>
          </ng-container>
          <ng-template #emptyMessage>
            <adm4-empty-resource><span>There are no global constants defined.</span></adm4-empty-resource>
          </ng-template>
        </div>
      </div>
  `,
  styleUrls: ['./global-constants.component.scss', '../../common/styles/component-specific/settings-details.scss'],
})
export class GlobalConstantsComponent {

  public tableFilter: string | undefined;
  public currentDialog: MatDialogRef<EditGlobalConstantDialogComponent, void>;

  public readonly globalConstants: Observable<GlobalConstantWithUsage[]>;
  public readonly hasConstants: Observable<boolean>;
  public readonly hasModifyTenantPermission$: Observable<boolean>;

  constructor(
      private store$: Store<AppState>,
      private inventoryService: InventoryService,
      private notificationDialog: ModalNotificationService,
      private matDialog: MatDialog,
      private toastService: ToastNotificationService,
      private nav: NavigationService,
  ) {
    this.globalConstants = store$.select(tenantScopedConstantsWithUsageView);
    this.hasConstants = store$.select(tenantScopedConstantsWithUsageView).pipe(
        map((constants: GlobalConstantWithUsage[]): boolean => constants && constants.length > 0),
    );
    this.hasModifyTenantPermission$ = this.store$.select(hasTenantModificationAccessView);

    this.triggerLoadingTenantScopedData();
  }

  private triggerLoadingTenantScopedData() {
    this.store$.dispatch(new LoadTenantConstants({usedIn: true}));
  }

  public filterBySearch(text: string): void {
    this.tableFilter = text;
  }

  public navigateToInventory(inventoryKey: string) {
    this.nav.navigateToInventory(inventoryKey);
  }

  public createClicked(): void {
    this.openEditDialog();
  }

  public edit(gc: GlobalConstantWithUsage) {
    this.openEditDialog(gc);
  }

  private openEditDialog(gc?: GlobalConstantWithUsage) {
    const isNew = !gc;
    this.observeTenantAndPermission().subscribe(([selectedTenantKey, hasModifyTenantPermission]) => {
      if (hasModifyTenantPermission) {
        const saveCallback = (modifiedGC: GlobalConstantWithUsage) => this.doStore(isNew, modifiedGC, selectedTenantKey);
        this.currentDialog = this.matDialog.open<EditGlobalConstantDialogComponent, EditGlobalConstantDialogPayload, void>(
            EditGlobalConstantDialogComponent,
            {
              data: {globalConstant: gc, saveCallback},
              width: '800px',
              maxHeight: '792px', // calculated from the layout
              autoFocus: '.edit-gc-content input:not([disabled])',
            },
        );
      }
    });
  }

  private doStore(isNew: boolean, gc: GlobalConstantWithUsage, tenantKey: string): Observable<void> {
    const storeAction: Observable<void> = isNew
        ? this.createGlobalConstant(gc, tenantKey)
        : this.updateGlobalConstant(gc, tenantKey);
    return storeAction.pipe(
        tap(() => this.triggerLoadingTenantScopedData()),
        tap(() => {
          if (isNew) {
            this.toastService.showSuccessToast('Global constant has been successfully created.', 'Successfully created');
          } else {
            this.toastService.showSuccessToast('Global constant has been successfully updated.', 'Successfully updated');
          }
        }),
        tap(() => this.currentDialog.close()),
        catchError(e => {
          console.error(`GlobalConstantsComponent#doStore, error when saving`);
          console.error(e);
          const extractedError: string = ErrorHelper.getErrorDetail(e);
          this.notificationDialog.openErrorDialog({
            description: extractedError,
            title: 'Could not save Global Constant',
          }, () => {/* doing nothing when the error is ack'd */});
          return of(undefined);
        }),
    );
  }

  private updateGlobalConstant(gc: GlobalConstantWithUsage, tenantKey: string): Observable<void> {
    return this.inventoryService.updateGlobalConstant(gc, tenantKey);
  }

  private createGlobalConstant(gc: GlobalConstantWithUsage, tenantKey: string): Observable<void> {
    return this.inventoryService.createGlobalConstant(gc, tenantKey);
  }

  public delete(gc: GlobalConstantWithUsage) {
    this.observeTenantAndPermission().pipe(
        switchMap(([selectedTenantKey, hasModifyTenantPermission]): Observable<[string, boolean | undefined]> => {
          if (!hasModifyTenantPermission) {
            return EMPTY;
          }
          const deleteDialogRef = this.notificationDialog.openConfirmDialog(
              this.confirmationDialogContent(gc),
              {confirmButtonText: 'Delete', cancelButtonText: 'Cancel'});
          return deleteDialogRef.afterClosed().pipe(
              take(1),
              map((confirmed) => ([selectedTenantKey, confirmed])),
          );
        }),
        switchMap(([selectedTenantKey, confirmed]): Observable<void> => {
          if (!confirmed) {
            return EMPTY;
          }
          return this.inventoryService.deleteGlobalConstant(gc, selectedTenantKey);
        }),
        tap(() => this.triggerLoadingTenantScopedData()),
        tap(() => this.toastService.showSuccessToast('Global constant has been successfully deleted.', 'Successfully deleted')),
    ).subscribe();
  }

  /**
   * Creates an Observable, which emits the selected tenant's key and whether the user does have permission to modify the tenant.<br>
   * The observable will emit only once.
   * @private
   */
  private observeTenantAndPermission(): Observable<[string, boolean]> {
    return combineLatest([
      this.store$.select(selectedTenantKeyView).pipe(
          filter((selectedTenantKey: string | null): selectedTenantKey is string => !!selectedTenantKey),
      ),
      this.hasModifyTenantPermission$
    ]).pipe(take(1));
  }

  private confirmationDialogContent(gc: GlobalConstantWithUsage): NotificationMessage {
    let usages = '';
    const usedIn = gc.usedIn;
    if (usedIn && usedIn.length > 0) {
      usages = InventoryResourceActionDialogHelper.getResourceUsageDescription(usedIn);
    }
    let msg = `You are deleting <em><b>${gc.name}</b></em>. The removal is irreversible.${usages}`;
    return {
      title: 'Deleting global constant',
      description: msg,
    };
  }
}

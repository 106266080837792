import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PromoteRollbackDialogComponent } from './promote-rollback-dialog.component';
import { CanaryRoutingOption } from '../../deployment-wizard/deployment-dialog/deployment-process.model';
import { Pod } from './deployed-service.model';
import { ViewLogsDialogComponent, ViewPodLogsPayload } from '../../common/components/view-logs-dialog/view-logs-dialog.component';
import { KubernetesDialogTypeEnum, PromoteRollbackDialogPayload } from './kubernetes-status-dialog-payload.model';

@Injectable()
export class KubernetesStatusDialogService {
  constructor(private dialogService: MatDialog) {
  }

  openPromoteRollbackDialog(inventoryKey: string, canaryDialogType: KubernetesDialogTypeEnum, canaryConfig: CanaryRoutingOption): MatDialogRef<PromoteRollbackDialogComponent> {
    return this.dialogService.open<PromoteRollbackDialogComponent, PromoteRollbackDialogPayload>(PromoteRollbackDialogComponent, {
      width: '1000px',
      height: '600px',
      disableClose: true,
      autoFocus: false,
      data: {
        inventoryKey: inventoryKey,
        canaryDialogType: canaryDialogType,
        canaryConfig: canaryConfig
      }
    });
  }

  openPodLogsDialog(inventoryKey: string, pod: Pod): MatDialogRef<ViewLogsDialogComponent> {
    return this.dialogService.open<ViewLogsDialogComponent, ViewPodLogsPayload>(ViewLogsDialogComponent, {
      disableClose: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      panelClass: 'dialog-full-screen',
      autoFocus: false,
      data: {
        type: 'pod',
        inventoryKey: inventoryKey,
        pod: pod
      }
    });
  }
}

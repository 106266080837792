<ng-template #patternInfoPopover>
  <adm4-pattern-info-popover
          [pattern]='pattern'
          [patternType]='patternType'
          [bundleKey]="pattern.bundleKey"></adm4-pattern-info-popover>
</ng-template>
<div class='full-height'>
  <form class='full-height-flex' [formGroup]='form' (ngSubmit)='onFormSubmit.next(form.value)'>
    <adm4-column-header [styleClass]="headerStyle">
          <div class="pattern-title-wrapper">
            <div class='pattern-icon-container'>
              <div class='pattern-icon'
                   [ngClass]='patternTypeIconClass'
                   [ngbTooltip]='patternInfoPopover' placement='right'>
              </div>
            </div>
            <ng-container *ngIf="!headerEditMode; else editArea">
              <span (click)="onRenamePattern()" class='title' [title]='headerTitle'>{{headerTitle}}</span>
              <mat-icon *ngIf='pattern.label' [ngbTooltip]='labelPopover' class='label-icon' placement='bottom'>
                local_offer</mat-icon>
              <ng-template #labelPopover><p>Labelled as: <strong>{{pattern.label}}</strong></p>
                <p>Switch to <a (click)='switchToLabelView()'>Label view</a> to group the patterns by the labels.</p></ng-template>
            </ng-container>
            <ng-template #editArea>
              <adm4-editable-text
                      fcName="pattern_name"
                      [form]="form"
                      styleClass="headerTitle"
                      (focusLost)="onHeaderBlur()">
              </adm4-editable-text>
            </ng-template>
            <adm4-pattern-menu
                    [readOnly]='readOnly'
                    [pattern]="pattern"
                    [allPatternLabels]='patternLabels$ | async'
                    [icon]="headerEditMode ? 'edit' : 'more_vert'"
                    [projectKey]='projectKey'
                    (copy)="onCopyPattern()"
                    (rename)="onRenamePattern()"
                    (duplicate)="onDuplicatePattern()"></adm4-pattern-menu>
            <adm4-toggle-collapse-button *ngIf='isHelpCollapsed'
                                         side='right'
                                         buttonClass='admn4-button-icon-white'
                                         [isCollapsed]='isHelpCollapsed'
                                         (click)='onHelpExpanderClick()'
            ></adm4-toggle-collapse-button>
          </div>
    </adm4-column-header>
    <div class='remaining-space-flex-content-wrapper'>
      <div class='remaining-space-flex-content'>
        <div class='pr-property-container full-height-flex' #scrollArea>
          <adm4-pattern-issue-list
                  [issues]="patternIssues$ | async" [patternLinkInfo]='pattern?.link'></adm4-pattern-issue-list>
          <div class='pr_list ui_list'>
            <mat-tab-group class='full-height mat-tabs-full-height' [mat-stretch-tabs]='false'
                           *ngIf='!noProperties'
                           [selectedIndex]='categoryIndex$ | async'
                           (focusChange)='selectPropertyCategoryByIndex($event.index)'>
              <ng-container *ngFor='let propertyModelCategoryGroup of propertyModelCategoryGroups; trackBy: propertyCategoryGroupTrackBy'>
              <mat-tab>
                <ng-template mat-tab-label>
                  <div class='category-group-label'>
                    <adm4-highest-severity-issue-indicator *ngIf='propertyCategoryGroupHasIssues(propertyModelCategoryGroup)'
                                                           [diameter]='10'
                                                           [issues]='getIssuesOfPropertyCategoryGroup(propertyModelCategoryGroup)'
                    ></adm4-highest-severity-issue-indicator>
                    <span>{{propertyModelCategoryGroup.category}}</span>
                  </div>
                </ng-template>
                <div *ngIf='isDefaultPropertyModelCategoryGroup(propertyModelCategoryGroup)' #deploymentHost>
                  <adm4-deployment-host
                          *ngIf='patternType && patternType.deployablePattern && !shouldBeRestricted(pattern?.displayOptions?.hidden, "field.deploymentHosts")'
                          [form]='form'
                          [fcName]='deploymentHostsFormControlName'
                          [readOnly]='readOnly || shouldBeRestricted(pattern?.displayOptions?.readOnly, "field.deploymentHosts")'
                          [issues]='deploymentHostIssues'>
                  </adm4-deployment-host>
                </div>
                <adm4-property-list [propertiesModel]='propertyModelCategoryGroup.propertiesModel'
                                    [pattern]='pattern'
                                    [projectKey]='projectKey'
                                    [variables]='variables'
                                    [selectedPropertyKey]='selectedPropertyKey'
                                    [form]='form'
                                    [readOnly]='readOnly'
                                    [scrollArea]='scrollArea'
                                    (selectProperty)='changeSelectedProperty($event)'
                                    (validate)='triggerValidation($event)'
                ></adm4-property-list>
              </mat-tab>
              </ng-container>
            </mat-tab-group>
            <div class="noProperties" *ngIf="noProperties">
              {{NO_PROPERTIES_LABEL}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <adm4-pattern-notes class='floating-container'
                        [form]='form'
                        [fcName]="patternNotesFormControlName"
                        *ngIf='!shouldBeRestricted(pattern?.displayOptions?.hidden, "field.notes")'
                        [readOnly]='readOnly || shouldBeRestricted(pattern?.displayOptions?.readOnly, "field.notes")'
                        [isPatternNotesCollapsed]='isPatternNotesCollapsed$ | async'
                        (patterNoteCollapse)='updatePatternNotesCollapseState($event)'>
    </adm4-pattern-notes>
    <adm4-button-bar *ngIf='!readOnly'
                     [isSubmitDisabled]="formActionsDisabled"
                     [isCancelDisabled]='formActionsDisabled'
                     [cancelButtonText]='"Reset"'
                     [wrapperClass]='"admn4-buttons-wrapper"'
                     (cancelClicked)='resetForm()'></adm4-button-bar>
  </form>
</div>

export interface OutputModel {
  host: string;
  error?: string;
  checks: CheckModel[];
  triggers: (GenerationTriggerModel | PlanTriggerModel)[];
  commands: CommandModel[];
  directories: DirectoryModel[];
  files: FileModel[];
  kubernetesCustomResources: KubernetesCustomResource[];
}

export interface PlanningOutputModel extends OutputModel {
  triggers: PlanTriggerModel[];
}

export interface GenerationOutputModel extends OutputModel {
  triggers: GenerationTriggerModel[];
  patternId: string;
}

export interface CheckModel {
  command: string;
  label: string;
  status: CheckStatus;
  errorMessage?: string;
  deployablePatternId?: string;
}

export interface GenerationTriggerModel {
  triggerId: string;
  label: string;
  command: string;
  patternId: string;
  onFailure: string[];
  onSuccess?: string[];
}

export interface PlanTriggerModel extends GenerationTriggerModel {
  executionExitCode: number;
  executionOutput: string;
}

export interface CommandModel {
  commandId: string;
  command: string;
  phase: string;
  label: string;
  conditions: string[];
  tasks: string[];
  deployablePatternId?: string;
}

export interface DirectoryModel {
  directoryId: string;
  path: string;
  parentId?: string;
  patternId?: string;
  phase: string;
  deployablePatternId?: string;
  requested: Requested;
  onChange: string[];
  tasks: string[];
  deleteUnmanagedFiles?: boolean;
}

export interface FileModel {
  fileId: string;
  parentId: string;
  patternId?: string;
  deployablePatternId?: string;
  path: string;
  phase: string;
  requested: Requested;
  onChange: string[];
  tasks: string[];
  fileContent?: string;
  localFile?: string;
  remoteFile?: string;
}

export interface KubernetesCustomResource extends FileModel {
  label: string;
}

export interface Requested {
  owner: string;
  group: string;
  permissions: string;
}

export enum OutputType {
  GENERATION, PLAN
}

export enum CheckStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  PENDING = 'PENDING'
}

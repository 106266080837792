import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { combineLatest, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, publishReplay, refCount, startWith, switchMap } from 'rxjs/operators';

import * as _ from 'lodash';

import { AppState, Dictionary } from '../../model/reducer';
import { PropertyType } from '../../plugins/property-type.model';
import { patternsView, patternTypesView, projectKeyView, propertyTypesView, selectedProjectView, variableListItemsView, variablesMetaInfoView, variablesView } from '../../model/views';
import { Project } from '../../projects/project.model';
import { VariableDetails, VariableInventoryData } from './variable-details.model';
import { VariableListItem, VariableModel } from '../variable.model';
import { UsageInfo } from '../../common/components/usage/usage-info';
import { InventoryService } from '../../inventory/inventory.service';
import { VariableEditorHelper } from '../variable-editor/variable-editor.helper';
import { VariablesMainContext } from '../variables-main.context';
import { VariableService } from '../variable.service';
import { Usage } from '../../common/model/plugin-usage.model';
import { Pattern } from '../../patterns/pattern.model';
import { UsageHelper } from '../../common/helpers/usage.helper';
import { PatternType } from '../../plugins/pattern-type.model';
import { dictionaryToArray, mapToArray } from '../../common/utils/utils';
import { MetaInfo } from '../../version-control/meta-info.model';

@Injectable()
export class VariableDetailsContext {
  projectKey$: Observable<string>;
  selectedVariableKey$: Observable<string | undefined>;
  selectedProject$: Observable<Project | undefined>;
  variableListData$: Observable<VariableListItem[]>;
  variableTypes$: Observable<Dictionary<PropertyType> | null>;
  variablesMetaInfo$: Observable<MetaInfo | null>;
  variables$: Observable<VariableModel[]>;
  selectedVariable$: Observable<VariableListItem | undefined>;
  inventoriesData$: Observable<VariableInventoryData[] | null>;
  usageInfo$: Observable<UsageInfo[]>;
  variableDetails$: Observable<VariableDetails>;

  constructor(private store$: Store<AppState>,
              private variableMainContext: VariablesMainContext,
              private variablesService: VariableService,
              private inventoryService: InventoryService) {
    this.projectKey$ = store$.pipe(select(projectKeyView), filter<string>((projectKey: string | null) => !_.isNil(projectKey)));
    this.selectedVariableKey$ = this.variableMainContext.selectedVariableKey$;
    this.selectedProject$ = store$.pipe(select(selectedProjectView));
    this.variables$ = this.store$.pipe(select(variablesView), map(_.values), distinctUntilChanged(_.isEqual));
    this.variableListData$ = this.store$.pipe(select(variableListItemsView));
    this.variableTypes$ = this.store$.pipe(select(propertyTypesView));
    this.variablesMetaInfo$ = this.store$.pipe(select(variablesMetaInfoView));
    this.usageInfo$ = combineLatest([
      this.initVariableUsage(),
      this.store$.pipe(select(patternsView)),
      this.store$.pipe(select(patternTypesView))
    ]).pipe(
      map(([usages, patterns, patternTypes]: [Usage[], Map<string, Pattern>, Dictionary<PatternType>]) => UsageHelper.createUsageInfo(usages, mapToArray(patterns), dictionaryToArray(patternTypes))),
      catchError((err) => {
        console.error(err);
        return [];
      }),
      distinctUntilChanged(_.isEqual)
    );
    this.selectedVariable$ = combineLatest([this.selectedVariableKey$, this.variableListData$])
      .pipe(map(([varId, variableListData]) => variableListData.find(variableListItem => variableListItem.variable.variableKey === varId)));
    this.inventoriesData$ = combineLatest([this.selectedVariableKey$, this.selectedProject$])
      .pipe(
        filter((values: [string | undefined, Project | undefined]) => values.every(v => !_.isNil(v))),
        switchMap(([variableKey, project]: [string, Project]): Observable<VariableInventoryData[]> => {
          return this.inventoryService.getInventoriesForVariable(variableKey, project.tenantKey).pipe(
              map((result: { variableKey: string, inventories: VariableInventoryData[] }) => result.inventories),
              startWith([]),
          );
        }),
        publishReplay(1),
        refCount()
      );
    this.variableDetails$ = combineLatest([this.projectKey$, this.selectedVariable$, this.variableTypes$, this.inventoriesData$, this.usageInfo$])
      .pipe(
        filter((requiredDataTuple) => requiredDataTuple.every(value => !_.isNil(value))),
        map(([projectKey, selectedVariable, variableTypes, inventoriesData, usageData]: [string, VariableListItem, Dictionary<PropertyType>, VariableInventoryData[], UsageInfo[]]) =>
          VariableEditorHelper.convertData(projectKey, selectedVariable, variableTypes, inventoriesData, usageData)
        )
      );
  }

  /**
   * Returns observable which loads variable usage data on change of selected variable or project (to make sure first load is also handled
   */
  private initVariableUsage(): Observable<Usage[]> {
    return combineLatest([this.selectedVariableKey$, this.projectKey$])
      .pipe(
        filter((values: [string | undefined, string | null]) => values.every(v => !_.isNil(v))),
        switchMap(([variableKey, projectKey]: [string, string]):  Observable<Usage[]> => {
          return this.variablesService.getVariableUsage(projectKey, variableKey).pipe(
              startWith([]),
          );
        }),
        publishReplay(1),
        refCount()
      );
  }
}

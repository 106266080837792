import * as deepFreeze from 'deep-freeze-strict';

/**
 * Backend only sends list of bundle keys, which will then be parsed into this model
 */
export interface ParsedBundle {
  bundleKey: string;
  symbolicName: string;
  version: string;
}

export interface UpgradeNote {
  version: string;
  html: string;
}

export interface Bundle extends ParsedBundle {
  name: string;
}

export enum StandardBundleNames {
  /**
   * fake bundle that can include BaseGeneration, Proxy, Auth and IDM
   */
  Common = 'Common libraries',
  BaseGeneration = 'nevisadmin-plugin-base-generation',
  NevisProxy = 'nevisadmin-plugin-nevisproxy',
  NevisAuth = 'nevisadmin-plugin-nevisauth',
  NevisIdm = 'nevisadmin-plugin-nevisidm',
  Monitoring = 'nevisadmin-plugin-monitoring',
  MobileAuth = 'nevisadmin-plugin-mobile-auth',
  NevisFido2 = 'nevisadmin-plugin-fido2',
  NevisDetect = 'nevisadmin-plugin-nevisdetect',
  OAuth = 'nevisadmin-plugin-oauth',
  AuthCloud = 'nevisadmin-plugin-authcloud',
  NevisDataPorter = 'nevisadmin-plugin-nevisdp',
}

export interface StandardBundleInfo {
  symbolicName: StandardBundleNames;
  /**
   * indicates weight of bundle during sorting, allows to have same number which implies that it can be sorted by second factor
   */
  weight: number;
  /**
   * indicates whether should be merged into common
   */
  belongsToCommon: boolean;
}

export const STANDARD_BUNDLE_INFO: StandardBundleInfo[] = deepFreeze(<StandardBundleInfo[]>[
  {
    symbolicName: StandardBundleNames.Common,
    weight: 0,
    belongsToCommon: false
  },
  {
    symbolicName: StandardBundleNames.BaseGeneration,
    weight: 0,
    belongsToCommon: true
  },
  {
    symbolicName: StandardBundleNames.NevisProxy,
    weight: 1,
    belongsToCommon: true
  },
  {
    symbolicName: StandardBundleNames.NevisAuth,
    weight: 2,
    belongsToCommon: true
  },
  {
    symbolicName: StandardBundleNames.NevisIdm,
    weight: 3,
    belongsToCommon: true
  },
  {
    symbolicName: StandardBundleNames.MobileAuth,
    weight: 4,
    belongsToCommon: true
  },
  {
    symbolicName: StandardBundleNames.NevisFido2,
    weight: 5,
    belongsToCommon: true
  },
  {
    symbolicName: StandardBundleNames.NevisDetect,
    weight: 6,
    belongsToCommon: true
  },
  {
    symbolicName: StandardBundleNames.OAuth,
    weight: 7,
    belongsToCommon: true
  },
  {
    symbolicName: StandardBundleNames.AuthCloud,
    weight: 9,
    belongsToCommon: true
  },
  {
    symbolicName: StandardBundleNames.NevisDataPorter,
    weight: 10,
    belongsToCommon: true
  },
]);

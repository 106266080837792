import { PropertyKey } from '../plugins/pattern-type.model';

/**
 * Model of pattern coming from backend
 * /nevisadmin/rest/v1/projects/<projectKey>/patterns/
 * /nevisadmin/api/v1/projects/<projectKey>/patterns/<patternInstanceId>
 */
export class Pattern {
  patternId: string;
  className: string;
  bundleKey?: string;
  name: string;
  deploymentHosts?: string;
  notes?: string;
  label?: string;
  link?: PatternLink;
  displayOptions?: {
    hidden?: PatternRestrictions;
    readOnly?: PatternRestrictions;
  };
}

/**
 * Restriction based on pattern data <br/>
 * '*' means that whole pattern has to be filtered out
 */
export type PatternRestrictions = ('*' | 'field.deploymentHosts' | 'field.notes' | PropertyKey)[];

export class PatternLink {
  sourceProjectKey?: string;
  sourcePatternId?: string;
  author?: string;
  lastCopied?: string;
}

export interface PatternCopyTarget {
  projectKey: string;
  patternId: string;
  copyAuthor: string;
  sourceModification: string;
  targetModification: string;
  copyTime: string;
}

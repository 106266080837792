import { Injectable } from '@angular/core';
import { StoreShowOnlyDifferences } from '../../../model/publish-project/publish-project.actions';
import { PublishProjectContext } from '../publish-project.context';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import { Observable } from 'rxjs';
import { showOnlyDifferencesView, variablesDiffView } from '../../../model/views';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { VariableDiffData } from './variable-diff-data.model';

@Injectable()
export class VariablesDiffViewContext {

  projectKey$: Observable<string>;
  variablesDiffData$: Observable<Diff<VariableDiffData>[]>;
  currentBranch$: Observable<string | null>;
  showOnlyDifferences$: Observable<boolean>;

  constructor(private publishProjectContext: PublishProjectContext, private store$: Store<AppState>) {
    this.projectKey$ = this.publishProjectContext.projectKey$ as Observable<string>;
    this.variablesDiffData$ = this.store$.pipe(select(variablesDiffView));
    this.currentBranch$ = this.publishProjectContext.currentBranch$;
    this.showOnlyDifferences$ = this.store$.pipe(select(showOnlyDifferencesView));
  }

  rememberShowDifferencesView(showDiffOnly: boolean): void {
    this.store$.dispatch(new StoreShowOnlyDifferences(showDiffOnly));
  }
}

import { BundleGroup } from './bundle-group.model';
import { ParsedBundle } from './bundle.model';
import * as _ from 'lodash';
import { replaceArrayItem } from '../../common/utils/utils';
import { VersionCompareHelper } from '../../common/helpers/version-compare.helper';

export class BundleManagementHelper {
  static createBundleGroups(bundles: ParsedBundle[]): BundleGroup[] {
    const sortedBundles = [...bundles].sort((b1: ParsedBundle, b2: ParsedBundle) => VersionCompareHelper.reverseCompare(b1.version, b2.version));
    return sortedBundles.reduce((bundleGroups: BundleGroup[], bundle: ParsedBundle) => {
      const existingGroup: BundleGroup | undefined = bundleGroups.find((group: BundleGroup) => group.latestBundle.symbolicName === bundle.symbolicName);
      if (_.isNil(existingGroup)) {
        return _.concat(bundleGroups, {latestBundle: bundle, previousVersionBundles: []});
      }
      const updatedGroup = {...existingGroup, previousVersionBundles: _.concat(existingGroup.previousVersionBundles, bundle)};
      return bundleGroups.map(replaceArrayItem((group: BundleGroup) => group.latestBundle.symbolicName === existingGroup.latestBundle.symbolicName)(updatedGroup));
    }, []);
  }
}

import { NevisAdminAction } from '../actions';
import { InventoryMeta } from '../../inventory/inventory.model';
import { PublishInventoryPayload } from '../../inventory/publish-inventory/publish-inventory-payload.model';
import { Diff } from '../../common/model/publish-changes/diff.model';
import { TextDiffData } from '../../common/model/publish-changes/text-diff-data.model';

export enum PublishInventoryActionTypes {
  LoadInventoryMetaSuccess = '[PublishInventory] Load Inventory Meta Success',
  LoadDiffData = '[PublishInventory] Load Diff Data',
  LoadInventoryContentDiffData = '[PublishInventory] Load Inventory Content Diff Data',
  LoadInventoryContentDiffDataSuccess = '[PublishInventory] Load Inventory Content Diff Data Success',
  RequestPublishInventory = '[PublishInventory] Request Publish Inventory',
  PublishInventory = '[PublishInventory] Publish Inventory',
  ClearPublishInventoryData = '[PublishInventory] Clear Publish Inventory Data'
}

export interface LoadInventoryDiffPayload {
  inventoryMeta: InventoryMeta;
  inventoryKey: string;
}

export class LoadInventoryMetaSuccess implements NevisAdminAction<InventoryMeta> {
  readonly type = PublishInventoryActionTypes.LoadInventoryMetaSuccess;

  constructor(public payload: InventoryMeta) {}
 }

export class LoadDiffData implements NevisAdminAction {
  readonly type = PublishInventoryActionTypes.LoadDiffData;
}

export class LoadInventoryContentDiffData implements NevisAdminAction<LoadInventoryDiffPayload> {
  readonly type = PublishInventoryActionTypes.LoadInventoryContentDiffData;

  constructor(public payload: LoadInventoryDiffPayload) {}
}

export class LoadInventoryContentDiffDataSuccess implements NevisAdminAction<Diff<TextDiffData>> {
  readonly type = PublishInventoryActionTypes.LoadInventoryContentDiffDataSuccess;

  constructor(public payload: Diff<TextDiffData>) {}
}

export class RequestPublishInventory implements NevisAdminAction<PublishInventoryPayload> {
  readonly type = PublishInventoryActionTypes.RequestPublishInventory;

  constructor(public payload: PublishInventoryPayload) {}
}

export class PublishInventory implements NevisAdminAction<PublishInventoryPayload> {
  readonly type = PublishInventoryActionTypes.PublishInventory;

  constructor(public payload: PublishInventoryPayload) {}
}

export class ClearPublishInventoryData implements NevisAdminAction {
  readonly type = PublishInventoryActionTypes.ClearPublishInventoryData;
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BatchActionDialogContainerComponent } from './batch-action-dialog-container/batch-action-dialog-container.component';
import { PatternListData } from '../pattern-list-data.model';

@Injectable({providedIn: 'root'})
export class BatchActionDialogService {

  constructor(private dialog: MatDialog) {
  }

  public openBatchActionDialog(header: string, projectKey: string, selectedPatterns: PatternListData[]): MatDialogRef<BatchActionDialogContainerComponent> {

    const config: MatDialogConfig = {
      width: '50%',
      height: selectedPatterns.length * 48 + 380 + 'px',
      maxHeight: '75vh',
      position: {
        top: selectedPatterns.length > 5 ? '14vh' : 'calc(25vh - 64px)'
       },
      disableClose: true,
      autoFocus: false,
      data: {
        header: header,
        projectKey: projectKey,
        selectedPatterns: selectedPatterns
      }
    };
    return this.dialog.open(BatchActionDialogContainerComponent, config);
  }
}

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PatternMainComponent } from './pattern-main.component';
import { SaverService } from '../common/services/saver.service';
import { Injectable } from '@angular/core';
import { SaveChangesDialogService } from '../modal-dialog/save-changes-dialog/save-changes-dialog.service';
import { Observable, of } from 'rxjs';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import { select, Store } from '@ngrx/store';
import { isAuthenticatedView } from '../model/views';
import { AppState } from '../model/reducer';

@Injectable()
export class PatternEditorDeactivationGuard  {
  constructor(private saverService: SaverService, private saveChangesDialogService: SaveChangesDialogService, private store$: Store<AppState>) {}

  canDeactivate(component: PatternMainComponent, _currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> {
    return of(component.hasUnsavedProperty && (this.hasPatternSelectionChanged(currentState, nextState))).pipe(
      withLatestFrom(this.store$.pipe(select(isAuthenticatedView))),
      switchMap(([hasUnsavedChanges, isAuthenticated]: [boolean, boolean]) => {
        if (hasUnsavedChanges && isAuthenticated) {
          return this.saveChangesDialogService.confirmSavingChanges(() => this.saverService.save());
        }
        return of(this.hasPatternSelectionChanged(currentState, nextState));
      }),
      tap((canDeactivate: boolean) => {
        if (canDeactivate) {
          this.disconnectFromFormDirtyGuard(component);
        }
      })
    );
  }

  private disconnectFromFormDirtyGuard(component: PatternMainComponent) {
    if (component && component.propertyListComp && component.propertyListComp.formGuardConnectorService) {
      component.propertyListComp.formGuardConnectorService.disconnect();
    }
  }

  hasPatternSelectionChanged(currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
    if (currentState && nextState) {
      const currentPatternIdAndProjectKey = this.getPatternIdAndProjectKeyFromStateSnapshot(currentState);
      const nextPatternIdAndProjectKey = this.getPatternIdAndProjectKeyFromStateSnapshot(nextState);
      return currentPatternIdAndProjectKey !== nextPatternIdAndProjectKey;
    }
    return false;
  }

  getPatternIdAndProjectKeyFromStateSnapshot(state: RouterStateSnapshot): string | null {
    if (state.root.firstChild && state.root.firstChild.firstChild) {
      const projectKey = _.map(state.root.children, activatedRouteSnapshot => {
        if (_.isNil(activatedRouteSnapshot.params) ) {
          return undefined;
        }
        return activatedRouteSnapshot.params.projectKey;
      });
      return state.root.firstChild.firstChild.params['patternId'] + projectKey;
    }
    return null;
  }
}

import { NgModule } from '@angular/core';
import { SecretManagementComponent } from './secret-management.component';
import { BundleManagementModule } from '../bundle-management/bundle-management.module';
import { CommonModules } from '../../common/common.module';
import { SecretManagementTableComponent } from './secret-management-table/secret-management-table.component';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CreateInventoryAttachTenantResourceDialogComponent } from './inventory-resource-action/create-inventory-attach-tenant-resource-dialog.component';
import { InventoryResourceActionDialogService } from './inventory-resource-action/inventory-resource-action-dialog.service';
import { InventoryResourceActionDialogComponent } from './inventory-resource-action/inventory-resource-action-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { ReplaceInventoryResourceDialogComponent } from './inventory-resource-action/replace-inventory-resource-dialog.component';

@NgModule({
  declarations: [
    SecretManagementComponent,
    SecretManagementTableComponent,
    CreateInventoryAttachTenantResourceDialogComponent,
    InventoryResourceActionDialogComponent,
    ReplaceInventoryResourceDialogComponent
  ],
  imports: [
    BundleManagementModule,
    CommonModules,
    MatTableModule,
    ClipboardModule,
    MatSortModule
  ],
  providers: [
    InventoryResourceActionDialogService
  ]
})
export class SecretManagementModule {
}

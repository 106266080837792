export interface ProjectSummaryReportModel {
  columns: string[];
  rows: any[];
}

export enum ProjectSummaryReportCellType {
  patternLink = 'patternLink',
  values = 'values',
  value = 'value',
  link = 'link',
  graphLink = 'graphLink',
}

export type ProjectSummaryReportType = ProjectSummaryReportLink | ProjectSummaryReportGraphLink | ProjectSummaryReportPatternLink | ProjectSummaryReportPatternValues | ProjectSummaryReportPatternValue | ProjectSummaryReportGenerationModel;

export interface ProjectSummaryReportLink {
  type: ProjectSummaryReportCellType.link;
  link?: string | null;
  linkName?: string | null;
}

export interface ProjectSummaryReportPatternLink {
  type: ProjectSummaryReportCellType.patternLink;
  linkId: string;
  linkName?: string | null;
  linkDescription?: string | null;
}

export interface ProjectSummaryReportGraphLink {
  type: ProjectSummaryReportCellType.graphLink;
  view: string;
  patternId: string;
  linkName: string;
}

export interface ProjectSummaryReportPatternValues {
  type: ProjectSummaryReportCellType.values;
  values?: string [] | null;
}

export interface ProjectSummaryReportPatternValue {
  type: ProjectSummaryReportCellType.value;
  value?: string | null;
}

export interface ProjectSummaryReportGenerationModel {
  reportClassName: string;
  mediaType: string;
}

export const PROJECT_OVERVIEW_APPLICATIONS_REPORT_CLASS_NAME = 'ch.nevis.admin.v4.plugin.base.generation.reports.ApplicationReport';
export const PROJECT_OVERVIEW_REALMS_REPORT_CLASS_NAME = 'ch.nevis.admin.v4.plugin.base.generation.reports.RealmReport';
export const PROJECT_OVERVIEW_INSTANCES_REPORT_CLASS_NAME = 'ch.nevis.admin.v4.plugin.base.generation.reports.InstanceReport';

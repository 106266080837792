import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SelectDependenciesContainerComponent } from './select-dependencies-container.component';
import { EditDependenciesModel, UpgradeNotesModel } from './project-dependencies.model';
import { UpgradeNotesComponent } from './upgrade-notes.component';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ProjectDependenciesDialogService {

  private _openDependencyDialogTrigger = new Subject<void>();
  openDependencyDialogTrigger: Observable<void> = this._openDependencyDialogTrigger.asObservable();

  constructor(private dialog: MatDialog) {}

  openEditDialog(editModel: EditDependenciesModel): MatDialogRef<SelectDependenciesContainerComponent> {

    return this.dialog.open(SelectDependenciesContainerComponent,
      {
        panelClass: 'big-dialog',
        data: editModel,
        disableClose: true,
        autoFocus: false,
        closeOnNavigation: true
      });
  }

  openUpgradeNotesDialog(upgradeNotesModel: UpgradeNotesModel): MatDialogRef<UpgradeNotesComponent> {

    return this.dialog.open(UpgradeNotesComponent,
      {
        panelClass: 'big-dialog',
        data: upgradeNotesModel,
        disableClose: true,
        autoFocus: false,
        closeOnNavigation: true
      });
  }

  /**
   * Need to call open edit dialog through project header component in case of newer library is available.
   */
  triggerDependencyDialogOpen(): void {
    this._openDependencyDialogTrigger.next();
  }
}

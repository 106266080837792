import { Injectable } from '@angular/core';
import { marked, Renderer } from 'marked';
import { SafeHtml } from '@angular/platform-browser';
import { CustomSanitizerService } from './custom-sanitizer.service';

/**
 * Renders HTML from a markdown string using the library `marked`.<br/>
 * DOES NOT SANITIZE EITHER THE INPUT OR THE OUTPUT.
 */
@Injectable()
export class MarkdownRendererService {

    private readonly rootRenderer: Renderer;

    constructor(
        private customSanitizer: CustomSanitizerService,
    ) {
        this.rootRenderer = new marked.Renderer();
        const originalLinkRenderer = this.rootRenderer.link;
        this.rootRenderer.link = (href: string, title: string, text: string) => {
            const html = originalLinkRenderer.call(this.rootRenderer, href, title, text);
            return html.replace(/^<a /, '<a target="_blank" rel="nofollow noopener noreferrer" ');
        };
    }

    /**
     * Renders HTML from a markdown string using the library `marked` and sanitizes it with DOMPurify.
     */
    public renderAndSanitize(markdown: string | undefined): SafeHtml | undefined {
        if (typeof markdown !== 'string' || markdown.trim().length === 0) {
            return undefined;
        }
        const dirtyHtml: string = marked.parse(markdown, {renderer: this.rootRenderer});
        return this.customSanitizer.sanitizeAndTrust(dirtyHtml);
    }
}

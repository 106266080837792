import { NgModule } from '@angular/core';
import { ModalDialogNotificationComponent } from './modal-dialog-notification.component';
import { CommonModules } from '../common/common.module';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { LoadingModalContainerComponent } from './loading-modal/loading-modal-container.component';
import { LoadingModalComponent } from './loading-modal/loading-modal.component';
import { SaveChangesDialogService } from './save-changes-dialog/save-changes-dialog.service';
import { SaveChangesDialogComponent } from './save-changes-dialog/save-changes-dialog.component';
import { LocalChangesConfirmationDialogComponent } from './local-changes-confirmation-dialog/local-changes-confirmation-dialog.component';
import { LocalChangesProjectConfirmationDialogComponent } from './local-changes-confirmation-dialog/local-changes-project-confirmation-dialog.component';
import { LocalChangesInventoryConfirmationDialogComponent } from './local-changes-confirmation-dialog/local-changes-inventory-confirmation-dialog.component';
import { ConcurrentChangesInventoryConfirmationDialogComponent } from './local-changes-confirmation-dialog/concurrent-changes-inventory-confirmation-dialog.component';
import { LocalChangesProjectInfoDialogComponent } from './local-changes-confirmation-dialog/local-changes-project-info-dialog.component';

@NgModule({
  imports: [
    CommonModules
  ],
  declarations: [
    ModalDialogNotificationComponent,
    ConfirmationDialogComponent,
    LoadingModalComponent,
    LoadingModalContainerComponent,
    SaveChangesDialogComponent,
    LocalChangesConfirmationDialogComponent,
    LocalChangesProjectConfirmationDialogComponent,
    LocalChangesInventoryConfirmationDialogComponent,
    LocalChangesProjectInfoDialogComponent,
    ConcurrentChangesInventoryConfirmationDialogComponent
  ],
  exports: [
    ModalDialogNotificationComponent,
    ConfirmationDialogComponent,
    LoadingModalContainerComponent,
    SaveChangesDialogComponent
  ],
  providers: [
    SaveChangesDialogService
  ]
})
export class ModalDialogModule {
}

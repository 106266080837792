import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavigationModel } from '../navigation.model';

/**
 * This is a tab item in the navigation bar.
 */
@Component({
  selector: 'adm4-tab-item',
  template: `
			<a class='tab-element {{className}} focus-bg-light-nevis-blue' [class.active]='active' [title]='title' [href]='path'>
				<span class='tab-title'>{{title}}</span>
				<mat-icon *ngIf='icon'>{{icon}}</mat-icon>
			</a>
  `,
  styleUrls: ['tab.item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabItemComponent {
  @Input() tab: NavigationModel;
  @Input() title: string;
  @Input() path: string  ;
  @Input() icon: string;
  @Input() className: string;

  @Input() active: boolean;
}

/**
 * Model of project coming from backend
 * /nevisadmin/rest/v1/projects/:id
 */
import { LocalStatus, MetaInfo } from '../version-control/meta-info.model';
import { PatternVersionInfo } from '../version-control/pattern-meta-info.model';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { UserAuthorization } from '../model/permissions/user-authorization.model';
import { VersionControlData } from '../common/model/version-control-data.model';

export interface ProjectInventoryDeployment {
  inventoryKey: string;
  timestamp: string;
}

export type Project = {
  projectKey: string;
  tenantKey: string;
  originProjectKey?: string;
  commitId?: string;
  _deployedInventories?: ProjectInventoryDeployment[];
} & UserAuthorization
  & VersionControlData; // add version control information into project model;

export type ProjectFromTemplate = {
  projectTemplateKey?: string;
} & Project;

export interface ProjectDescriptionMeta extends MetaInfo {
  headValue?: string;
}

export interface ProjectMeta {
  lastModification?: string;
  lastAuthor?: string;
  localHead?: string;
  bundles: MetaInfo;
  variables: MetaInfo;
  description: ProjectDescriptionMeta;
  patterns: Dictionary<PatternVersionInfo>;
}

export interface ProjectBundle {
  symbolicName: string;
  allVersions: string[];
  latestVersion: string;
  selectedVersion: string;
  isActiveForProject: boolean;
  isMandatory: boolean;
  currentProjectVersion?: string;
  hint?: string;
}

export interface ProjectDescription {
  description?: string;
}

export enum LocalProjectMetaMembers {
  BUNDLE = 'bundles',
  PATTERN = 'patterns',
  VARIABLE = 'variables',
}

function addAllIfCannotDetermine(output: LocalProjectMetaMembers[]) {
  if (_.isEmpty(output)) {
    output.push(LocalProjectMetaMembers.BUNDLE, LocalProjectMetaMembers.PATTERN, LocalProjectMetaMembers.VARIABLE);
  }
  return output;
}

export type ProjectMetaChangeIndicator = {
  isModifiedByOtherUser: true;
  lastModifiedTime: Date;
  lastModifiedByUser: string;
  showRealTimeChange: boolean;
} | { isModifiedByOtherUser: false };

export default class ProjectMetaUtils {

  private static getModificationList(meta: MetaInfo[] | MetaInfo, localTimestamp: string) {
    if (!meta) {
      return [];
    }

    // not unmodified, localtimestamp older or doesnt exists
    const modificationFilter = function (metaInfo: any) {
      return (metaInfo.localStatus) && (metaInfo.localStatus !== LocalStatus.Unmodified
        && _.isEmpty(localTimestamp) || (new Date(localTimestamp) < new Date(metaInfo.localDate)));
    };

    if (modificationFilter(meta)) {
      // bundle, variable
      return [meta];
    } else {
      return _.values(meta).filter(modificationFilter);
    }
  }

  static isModified(meta: MetaInfo[], payload: any) {
    const modifiedBundles = this.getModificationList(meta, payload);
    return !_.isEmpty(modifiedBundles);
  }

  static getProjectModificationTags(projectMetaInfo: ProjectMeta, projectMetaState: { local: string, remote: string }): LocalProjectMetaMembers[] {
    const output: LocalProjectMetaMembers[] = [];

    for (const item in LocalProjectMetaMembers) {
      if (ProjectMetaUtils.isModified(projectMetaInfo[LocalProjectMetaMembers[item]], projectMetaState.local)) {
        output.push(LocalProjectMetaMembers[item] as LocalProjectMetaMembers);
      }
    }

    // we cannot determine the changes in case the user change back to the same state of the remote. This case project timestamp is new, but according to ProjectMeta nothing has changed
    addAllIfCannotDetermine(output);

    return output;

  }

}

import { Component, OnDestroy } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Event, EventType, NavigationEnd, Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { combineLatest, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, shareReplay, take } from 'rxjs/operators';

import { IOutputData } from 'angular-split/lib/interface';

import { AppState, Dictionary } from '../../../model/reducer';
import { ProjectTemplate } from '../marketplace.model';
import { allProjectTemplatesView, filteredMarketplaceItemsView, selectedMarketplaceProjectKeyView, selectedMarketplaceProjectView } from '../marketplace.view';
import { loadMarketPlaceItems, resetMarketplace, selectItem, textFilterChanged } from '../marketplace.actions';
import { MarkdownRendererService } from '../../../common/services/markdown-renderer.service';
import { NavigationService } from '../../../navbar/navigation.service';
import { CreateProjectDialogService } from '../../../projects/create-project/create-project-dialog.service';
import { Mixin } from '../../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../../common/mixins/split.mixin';
import { SplitPaneConfig } from '../../../common/model/split-pane-config.model';
import { ResizeHelper } from '../../../common/helpers/resize.helper';
import { NavigationConstants } from '../../../common/constants/navigation.constants';
import { canCreateProjectView, hasTenantModificationAccessView } from '../../../model/views/permission.views';
import { selectedTenantKeyView } from '../../../model/views';
import { ROUTE_PARAM_MARKETPLACE_TEMPLATEKEY } from '../../resources-routing.module';
import { ProjectTemplatesService } from '../../../projects/project-templates/project-templates.service';
import { Maybe } from '../../../common/utils/utils';

type SelectedProjectTemplateVM = ProjectTemplate & {
  aboutBody: SafeHtml | undefined,
  isCustom: boolean,
  canCreateProject: boolean,
  canModifyTenant: boolean,
} | undefined;

@Component({
  selector: 'adm4-marketplace-page',
  templateUrl: './marketplace-page.component.html',
  styleUrls: [
      '../../../../app/common/styles/component-specific/settings-details.scss',
      './marketplace-page.component.scss']
})
@Mixin([SplitMixin])
export class MarketplacePageComponent implements OnDestroy, ISplitMixin {

  private readonly subs = new Subscription();

  public readonly search: Subject<string> = new Subject();
  public readonly filteredProjectTemplates$: Observable<ProjectTemplate[]>;
  public readonly filteredCustomProjectTemplates$: Observable<ProjectTemplate[]>;
  public readonly filteredStandardProjectTemplates$: Observable<ProjectTemplate[]>;
  public readonly selectedTenantKey$: Observable<string>;
  public readonly selectedProjectTemplateKey$: Observable<string | null>;
  public readonly selectedProjectTemplate$: Observable<ProjectTemplate | null>;
  public readonly selectedProjectTemplateVm: Observable<SelectedProjectTemplateVM>;

  public readonly customSectionVM: Observable<{shouldShowAdd: boolean, shouldShowSection: boolean, filteredCustomTemplates: ProjectTemplate[], canModifyTenant: boolean}>;
  public readonly showEmptyMsg: Observable<Maybe<boolean>>;

  public readonly canCreateProject$: Observable<boolean>;
  public readonly canModifyTenant$: Observable<boolean>;

  public readonly splitPaneConfigLocalStorageKey = 'marketplace-splitpane-config';
  public readonly splitItemsKey = 'items';
  public readonly splitDescriptionKey = 'description';
  public readonly splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.splitItemsKey]: {order: 0, size: 70},
    [this.splitDescriptionKey]: {order: 1, size: 30, isCollapsed: false},
  };

  get itemsSplitConfig(): SplitPaneConfig {
    return this.splitPaneConfig[this.splitItemsKey];
  }

  get descriptionSplitConfig(): SplitPaneConfig {
    return this.splitPaneConfig[this.splitDescriptionKey];
  }

  constructor(
      private store$: Store<AppState>,
      private createProjectDialog: CreateProjectDialogService,
      private markdownRenderer: MarkdownRendererService,
      private router: Router,
      private route: ActivatedRoute,
      private nav: NavigationService,
      private projectTemplates: ProjectTemplatesService,
  ) {
    this.store$.dispatch(loadMarketPlaceItems());
    this.canCreateProject$ = this.store$.select(canCreateProjectView);
    this.canModifyTenant$ = this.store$.select(hasTenantModificationAccessView);
    this.selectedTenantKey$ = this.store$.select(selectedTenantKeyView).pipe(filter((tenantKey: Maybe<string>): tenantKey is string => !!tenantKey),);
    const allProjectTemplates$: Observable<ProjectTemplate[]> = store$.select(allProjectTemplatesView);
    this.filteredProjectTemplates$ = store$.select(filteredMarketplaceItemsView);

    this.filteredCustomProjectTemplates$ = this.filteredProjectTemplates$.pipe(
        map((allTemplates: ProjectTemplate[]): ProjectTemplate[] => allTemplates.filter((pt: ProjectTemplate): boolean => pt.type === 'CUSTOM')),
    );

    this.filteredStandardProjectTemplates$ = this.filteredProjectTemplates$.pipe(
        map((allTemplates: ProjectTemplate[]): ProjectTemplate[] => allTemplates.filter((pt: ProjectTemplate): boolean => pt.type === 'STANDARD')),
    );

    this.selectedProjectTemplateKey$ = this.store$.select(selectedMarketplaceProjectKeyView).pipe(
        distinctUntilChanged(),
        shareReplay(1),
      );
    this.selectedProjectTemplate$ = this.store$.select(selectedMarketplaceProjectView);
    const aboutBody$: Observable<SafeHtml | undefined> = this.store$.select(selectedMarketplaceProjectView).pipe(
        filter((pt: ProjectTemplate | null) => pt !== null),
        map((pt: ProjectTemplate) => pt.description),
        map((aboutTemplateMD: string) => this.markdownRenderer.renderAndSanitize(aboutTemplateMD)),
    );

    this.selectedProjectTemplateVm = combineLatest([
        merge(of(undefined), this.selectedProjectTemplate$),
        merge(of(null), aboutBody$),
        merge(of(false), this.canCreateProject$),
        merge(of(false), this.canModifyTenant$),
    ]).pipe(
        map((
            [selectedProjectTemplate, aboutBody, canCreateProject, canModifyTenant]: [ProjectTemplate | null, SafeHtml | undefined, boolean, boolean],
        ): SelectedProjectTemplateVM => {
          if (selectedProjectTemplate) {
            return {
              ...selectedProjectTemplate,
              isCustom: 'CUSTOM' === selectedProjectTemplate.type,
              canCreateProject,
              canModifyTenant,
              aboutBody,
            };
          }
          return undefined;
        }),
    );

    this.customSectionVM = combineLatest([
      allProjectTemplates$,
      this.filteredCustomProjectTemplates$,
      this.canModifyTenant$,
      merge(of(''), this.search),
    ]).pipe(
        map(([allTemplates, filteredCustomTemplates, canModifyTenant, search]: [ProjectTemplate[], ProjectTemplate[], boolean, Maybe<string>]) => {
          const hasAnyTemplates: boolean = allTemplates.filter((pt: ProjectTemplate): boolean => 'CUSTOM' === pt.type).length > 0;
          const hasSearch: boolean = !!search;
          return {
            shouldShowSection: (!hasAnyTemplates && !hasSearch) || filteredCustomTemplates.length > 0,
            shouldShowAdd: !hasAnyTemplates || !hasSearch,
            filteredCustomTemplates,
            canModifyTenant,
          };
        }),
        shareReplay(1),
    );

    this.showEmptyMsg = combineLatest([
      this.filteredProjectTemplates$,
      merge(of(null), this.search),
    ]).pipe(
        map(([filteredProjectTemplates, search]): boolean => {
          // only showing the empty message if there are no results but there is search
          return filteredProjectTemplates.length === 0 && !!search;
        }),
    );

    this.subs.add(
        this.search.pipe(
            map((raw: string | undefined) => raw && raw.length > 0 ? raw : null),
            distinctUntilChanged(),
            debounceTime(100),
        ).subscribe((textFilter: string | null) => {
          this.store$.dispatch(textFilterChanged({textFilter}));
        })
    );
    const templateKeyFromRoute = () => {
      const rawTemplateKey: unknown = this.route.snapshot.params[ROUTE_PARAM_MARKETPLACE_TEMPLATEKEY];
      return typeof rawTemplateKey === 'string' && rawTemplateKey.length > 0 ? rawTemplateKey : null;
    };
    const templateKeyRouteParams$: Observable<string | null> = router.events.pipe(
      filter((event: Event): event is NavigationEnd => EventType.NavigationEnd === (event as any).type),
      map((_event: NavigationEnd): string | null => templateKeyFromRoute()),
    );
    this.subs.add(
      merge(
        templateKeyRouteParams$,
        of(templateKeyFromRoute()),
      ).subscribe((templateKey: string | null) => {
        this.store$.dispatch(selectItem({selectedProjectTemplateKey: templateKey}));
      })
    );
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.store$.dispatch(resetMarketplace());
  }

  public deselect() {
    this.selectTile(null);
  }

  public selectTile(projectTemplateKey: Maybe<string>): void {
    const route = projectTemplateKey
        ? ['/', NavigationConstants.RESOURCES, NavigationConstants.MARKETPLACE, projectTemplateKey]
        : ['/', NavigationConstants.RESOURCES, NavigationConstants.MARKETPLACE];
    this.router.navigate(route).then(() => {
      // reopening the description if this event is a selection event, not an unselection
      if (projectTemplateKey) {
        this.selectedProjectTemplateKey$.pipe(first()).subscribe((selectedTemplateKey: string | null) => {
          if (selectedTemplateKey) {
            this.descriptionSplitConfig.isCollapsed = false;
          }
        });
      }
    });
  }

  public createTemplateFrom(projectTemplateKey: string): void {
    this.createProjectDialog.openCreateProjectDialog(null, projectTemplateKey);
  }

  public deleteTemplate(pt: ProjectTemplate): void {
    this.selectedTenantKey$.pipe(take(1)).subscribe(async (selectedTenantKey: string) => {
      const deleted: boolean = await this.projectTemplates.deleteProjectTemplateWithConfirmation(selectedTenantKey, pt);
      if (deleted) {
        this.store$.dispatch(loadMarketPlaceItems());
        this.nav.navigateToMarketPlace();
      }
    });
  }

  public toggleDescriptionSplit() {
    this.descriptionSplitConfig.isCollapsed = !this.descriptionSplitConfig.isCollapsed;
  }

  public searchChanged(searchText: string) {
    this.search.next(searchText);
  }

  public async createTemplate() {
    const created: boolean = await this.projectTemplates.startProjectTemplateCreation(undefined);
    if (created) {
      this.store$.dispatch(loadMarketPlaceItems());
      this.descriptionSplitConfig.isCollapsed = false;
    }
  }

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  /**
   * Implemented by SplitMixin
   */
  onCollapse: (isCollapsed: boolean, collapsibleAreaKey: string) => void;
}

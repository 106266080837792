import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Inventory, InventoryMeta, InventorySchemaType } from '../../inventory.model';

@Component({
  selector: 'adm4-inventory-information-container',
  template: `
    <adm4-inventory-information
      [inventory]='inventory'
      (delete)="delete.emit()"
    ></adm4-inventory-information>
    <adm4-inventory-version-control-information [inventory]='inventory'
                                                [inventoryMeta]='inventoryMeta'></adm4-inventory-version-control-information>
    <adm4-inventory-kubernetes-information [inventory]='inventory'
                                           [inventoryContent]='inventoryContent'
                                           [inventorySchemaType]='inventorySchemaType'></adm4-inventory-kubernetes-information>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryInformationContainerComponent {
  @Input() inventory: Inventory;
  @Input() inventoryContent?: string;
  @Input() inventoryMeta: InventoryMeta | null;
  @Input() inventorySchemaType?: InventorySchemaType;

  @Output() delete: EventEmitter<void> = new EventEmitter();
}

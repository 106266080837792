import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';

import { take } from 'rxjs/operators';

import * as _ from 'lodash';
import { Dictionary } from 'lodash';

import { environment } from '../../../environments/environment';

import { Project } from '../project.model';
import { AppState } from '../../model/reducer';
import { projectKeyView } from '../../model/views';
import { PluginModel } from '../../plugins/plugin.model';
import { CheckUpdatesOfProject, ClearLocalChangesOfProject, RevertProject } from '../../model/project';
import { CreatePatternService } from '../../patterns/create-pattern/create-pattern.service';
import { NavigationService } from '../../navbar/navigation.service';
import { DynamicLinkCreatorService } from '../../common/services/dynamic-link-creator.service';
import { DirtyFormGuardConnectorService } from '../../common/services/dirty-form-guard-connector.service';
import { VariableModel } from '../../variables/variable.model';
import { NavigationConstants } from '../../common/constants/navigation.constants';
import { Maybe } from '../../common/utils/utils';
import { MenuHelper } from '../../common/helpers/menu.helper';
import { MenuElement } from '../../common/menu-element';
import { IssueModel } from '../../common/model/issue.model';
import { FileDownloader } from '../../common/helpers/file-downloader';
import { FileBasedImportProjectHelper } from '../file-based-import-project/file-based-import-project.helper';
import { CreateProjectDialogService } from '../create-project/create-project-dialog.service';
import { FileBasedImportProjectDialogService } from '../file-based-import-project/file-based-import-project-dialog.service';
import { ImportProjectDialogService } from '../import-project/import-project-dialog.service';
import { BranchProjectDialogService } from '../branch-project/branch-project-dialog.service';
import { SideMenuService } from '../../common/services/side-menu.service';
import { ProjectTemplatesService } from '../project-templates/project-templates.service';

@Component({
  selector: 'adm4-project-menu',
  template: `
    <div id='menu-items' class='full-height'>
      <div class="sidenav">
        <adm4-menu-element *ngFor='let navLink of navigationTabs' [menuElement]='navLink'></adm4-menu-element>
      </div>
      <adm4-side-menu-group-header text="actions" [collapsed]="sideMenuService.collapsed$ | async"></adm4-side-menu-group-header>
      <div class='operations scroll-shadows'>
        <adm4-menu-action *ngFor='let projectAction of projectActions' [menuElement]='projectAction'></adm4-menu-action>
      </div>
    </div>
  `,
  styleUrls: [
    '../../common/styles/component-specific/mat-menu.scss',
    './project-menu.component.scss',
  ]
})
export class ProjectMenuComponent implements OnChanges {
  @Input() title: string;
  @Input() readOnly: boolean;
  @Input() currentProjectKey: string;
  @Input() patternPlugins: PluginModel[];
  @Input() projects: Dictionary<Project>;
  @Input() firstPatternId: Maybe<string>;
  @Input() versioned: boolean;
  @Input() variables: VariableModel[];
  @Input() issues: IssueModel[];
  @Input() canCreateProject: boolean = false;
  @Input() canModifyTenant: boolean = false;

  @ViewChild('matMenuTrigger', {read: ElementRef, static: false}) matMenuTrigger: ElementRef<HTMLElement>;

  /** Contains menu options which does NOT navigate the user away from the current screen (i.e. modal opening). */
  projectActions: MenuElement[];

  /** Contains menu options which navigate the user away from the current screen. */
  navigationTabs: MenuElement[];
  projectsList: Project[];

  constructor(
    private createPatternService: CreatePatternService,
    private store$: Store<AppState>,
    private navigationService: NavigationService,
    private linkCreatorService: DynamicLinkCreatorService,
    private formGuardConnectorService: DirtyFormGuardConnectorService,
    private route: ActivatedRoute,

    private createProjectDialogService: CreateProjectDialogService,
    private fileBasedImportProjectDialogService: FileBasedImportProjectDialogService,
    private importProjectDialogService: ImportProjectDialogService,
    private branchProjectDialogService: BranchProjectDialogService,

    private projectTemplates: ProjectTemplatesService,

    public sideMenuService: SideMenuService,
  ) {
    this.projectActions = [
      {
        title: 'Create New',
        icon: 'create_project',
        invertedIcon: 'create_project-inv',
        useSvg: true,
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => this.createProjectDialogService.openCreateProjectDialog()
        ),
        displayCondition: () => this.canCreateProject
      },
      {
        title: 'Export to Zip',
        icon: 'export_to_zip',
        invertedIcon: 'export_to_zip-inv',
        useSvg: true,
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => FileDownloader.downloadFileFromUrl(`${environment.baseUrl}/projects/${this.currentProjectKey}/file-export`)
        ),
      },
      {
        title: 'Import from Zip',
        icon: 'import_from_zip_blk',
        invertedIcon: 'import_from_zip_inv',
        useSvg: true,
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.importProjectFromZip()),
        displayCondition: () => {
          return FileBasedImportProjectHelper.canImportFromZip(this.canCreateProject, this.projectsList);
        }
      },
      {
        title: 'Publish to Git',
        icon: 'file_upload',
        displayCondition: () => {
          return this.versioned && !this.readOnly;
        },
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => this.navigationService.navigateToPublishWindowOfProject(this.currentProjectKey)
        ),
      },
      {
        title: 'Import from Git',
        icon: 'save_alt_outline',
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.importProject()),
        displayCondition: () => this.canCreateProject
      },
      {
        title: 'Update from Git',
        icon: 'update',
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.updateProject()),
        displayCondition: () => this.versioned && !this.readOnly
      },
      {
        title: 'Clear local changes',
        icon: 'cancel',
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.clearLocalChanges()),
        displayCondition: () => this.versioned && !this.readOnly
      },
      {
        title: 'Branch Project',
        icon: 'call_split',
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.branchProject()),
        displayCondition: () => this.versioned && !this.readOnly
      },
      {
        title: 'Revert',
        icon: 'manage_history',
        useSvg: true,
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.revertProject()),
        displayCondition: () => this.versioned && !this.readOnly
      },
      {
        title: 'Create Template',
        icon: 'add-to-queue',
        useSvg: true,
        event: () => this.createTemplateFromCurrentProject(),
        displayCondition: () => {
          return this.canModifyTenant;
        },
      },
    ];

    this.navigationTabs = [
      {
        title: 'Overview',
        icon: 'view_comfortable',
        useSvg: false,
        href: this.linkCreatorService.projectOverviewLink,
        event: () => this.formGuardConnectorService.doIfConfirmed(() => this.navigationService.navigateToProjectSummary(this.currentProjectKey)),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.OVERVIEW),
      },
      {
        title: 'Patterns',
        icon: 'content_paste',
        href: this.linkCreatorService.configPatternsLink,
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => {
              if (this.firstPatternId) {
                this.navigationService.navigateToPattern(this.currentProjectKey, this.firstPatternId);
              }
            }
        ),
        selectedCondition: () => {
          return MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.PATTERNS);
        },
        disabledCondition: () => {
          return !this.firstPatternId;
        },
      },
      {
        title: 'Issues',
        icon: 'label_outline',
        href: this.linkCreatorService.issuesMenuLink,
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => this.navigationService.navigateToIssues(this.currentProjectKey)
        ),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.ISSUES),
        disabledCondition: () => _.isEmpty(this.issues)
      },
      {
        title: 'Variables',
        icon: 'code',
        href: this.linkCreatorService.variablesMenuLink,
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => this.navigationService.navigateToVariables(this.currentProjectKey)
        ),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.VARIABLES),
        disabledCondition: () => _.isEmpty(this.variables)
      },
      {
        title: 'Reports',
        icon: 'assessment',
        href: this.linkCreatorService.reportsMenuLink,
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => this.navigationService.navigateToReports(this.currentProjectKey)
        ),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.REPORTS)
      },
      {
        title: 'Settings',
        icon: 'inventory_settings',
        useSvg: true,
        href: linkCreatorService.projectSettingsMenuLink,
        event: () => this.formGuardConnectorService.doIfConfirmed(
            () => this.navigationService.navigateToProjectSettings(this.currentProjectKey)
        ),
        selectedCondition: () => MenuHelper.isActiveLink(this.route.snapshot, NavigationConstants.PROJECT_SETTINGS)
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projects) {
      this.projectsList = Object.values(this.projects);
    }
  }

  private updateProject(): void {
    this.store$.dispatch(new CheckUpdatesOfProject(this.currentProjectKey));
  }

  private clearLocalChanges(): void {
    this.store$.dispatch(new ClearLocalChangesOfProject(this.currentProjectKey));
  }

  private importProjectFromZip(): void {
    this.fileBasedImportProjectDialogService.openFileBasedImportProjectDialog();
  }

  private importProject(): void {
    this.importProjectDialogService.openImportProjectDialog();
  }

  private branchProject(): void {
    this.branchProjectDialogService.openBranchProjectDialog();
  }

  private revertProject() {
    this.store$.dispatch(new RevertProject(this.currentProjectKey));
  }

  private createTemplateFromCurrentProject() {
    this.store$.select(projectKeyView).pipe(take(1)).subscribe((selectedProjectKey: Maybe<string>): void => {
      this.projectTemplates.startProjectTemplateCreation(selectedProjectKey);
    });
  }
}

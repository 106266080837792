import { Dictionary } from '../reducer';
import {
  Inventory,
  GlobalConstantWithUsage, GlobalConstant,
  ResourceWrapper, ResourceWrapperWithUsage,
  SecretResourceWrapper, SecretResourceWrapperWithUsage,
  SecretWrapper, SecretWrapperWithUsage,
} from '../../inventory/inventory.model';
import * as fromActions from '../actions';
import * as fromShared from '../shared/shared.actions';
import * as _ from 'lodash';
import {
  InventoryActionTypes, LoadInventoryContentSuccess, LoadInventoryTenantScopedSecretsSuccess,
  LoadInventoryTenantScopedSecretsWithUsageSuccess, LoadTenantConstantsSuccess,
  LoadTenantConstantsWithUsageSuccess,
} from './inventory.actions';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';

export interface InventoryResourcesWithUsageState {
  readonly secretResources: SecretResourceWrapperWithUsage[];
  readonly resources: ResourceWrapperWithUsage[];
  readonly secrets: SecretWrapperWithUsage[];
}
export interface InventoryResourcesState {
  readonly secretResources: SecretResourceWrapper[];
  readonly resources: ResourceWrapper[];
  readonly secrets: SecretWrapper[];
}

export class InventoryState {
  readonly selectedInventoryKey: string | null;
  readonly selectedInventoryContent: string | null;
  readonly modifiedInventoryContent: string | null;
  readonly diffInventoryKey: string | null;
  readonly diffInventoryContent: string | null;

  readonly inventoryScopedInventoryResourcesWithUsage: InventoryResourcesWithUsageState;

  readonly inventoryScopedInventoryResources: InventoryResourcesState;

  readonly tenantScopedInventoryResourcesWithUsage: InventoryResourcesWithUsageState;
  readonly tenantScopedInventoryResources: InventoryResourcesState;
  readonly tenantScopedConstantsWithUsage: GlobalConstantWithUsage[];
  readonly tenantScopedConstants: GlobalConstant[];

  readonly allInventories: Dictionary<Inventory> | null;
  readonly validationStatus: ValidationStatus<InventoryValidationIssue> | null;
  readonly timestamp: string;
}

export const initState: InventoryState = {
  selectedInventoryKey: null,
  selectedInventoryContent: null,
  modifiedInventoryContent: null,
  diffInventoryKey: null,
  diffInventoryContent: null,
  allInventories: null,
  validationStatus: null,
  timestamp: '',
  inventoryScopedInventoryResourcesWithUsage: {
    secretResources: [],
    resources: [],
    secrets: []
  },
  inventoryScopedInventoryResources: {
    secretResources: [],
    resources: [],
    secrets: []
  },
  tenantScopedInventoryResourcesWithUsage: {
    secretResources: [],
    resources: [],
    secrets: [],
  },
  tenantScopedInventoryResources: {
    secretResources: [],
    resources: [],
    secrets: [],
  },
  tenantScopedConstantsWithUsage: [],
  tenantScopedConstants: [],
};

export function reducer(state: InventoryState, action: fromActions.NevisAdminAction<any>): InventoryState {
  switch (action.type) {
    case fromShared.SharedActionTypes.SelectTenantKey:
      return initState;
    case InventoryActionTypes.SelectInventory:
      return {
        ...state,
        selectedInventoryKey: action.payload
      };
    case InventoryActionTypes.SelectDiffInventory:
      return {
        ...state,
        diffInventoryKey: action.payload,
        diffInventoryContent: null,
      };
    case InventoryActionTypes.LoadInventoriesSuccess: {
      const newEntries = _.mapKeys(action.payload as Inventory[], inventory => inventory.inventoryKey);
      return {
        ...state,
        allInventories: newEntries,
      };
    }
    case InventoryActionTypes.ResetInventories:
      return {
        ...state,
        allInventories: {},
        diffInventoryKey: null,
        diffInventoryContent: null,
      };
    case InventoryActionTypes.ResetInventoriesAfterDeletingLast:
      return {
        ...state,
        allInventories: {},
        selectedInventoryKey: null,
        diffInventoryKey: null,
        diffInventoryContent: null,
      };
    case InventoryActionTypes.LoadInventoryContent:
      return {
        ...state,
        selectedInventoryContent: null
      };
    case InventoryActionTypes.LoadInventoryContentSuccess:
      const loadSuccessAction = action as LoadInventoryContentSuccess;
      let diffInventoryContent = state.diffInventoryContent;
      if (!!state.diffInventoryKey && state.diffInventoryKey === loadSuccessAction.payload.inventoryKey) {
        // if the same inventory is selected for diffing as the one that was reloaded, we update the diff content too
        diffInventoryContent = loadSuccessAction.payload.inventoryContent;
      }
      return {
        ...state,
        selectedInventoryContent: loadSuccessAction.payload.inventoryContent,
        diffInventoryContent,
      };
    case InventoryActionTypes.LoadDiffInventoryContentSuccess:
      return {
        ...state,
        diffInventoryContent: action.payload,
      };
    case InventoryActionTypes.ValidateInventoryContentSuccess:
      return {
        ...state,
        validationStatus: action.payload
      };
    case  InventoryActionTypes.UpdateInventoryTimestampSuccess: {
      return {
        ...state,
        timestamp: action.payload
      };
    }
    case InventoryActionTypes.LoadInventoryResourcesWithUsageSuccess: {
      return {
        ...state,
        inventoryScopedInventoryResourcesWithUsage: {
          resources: action.payload.resources,
          secretResources: action.payload.secretResources,
          secrets: action.payload.secrets
        }
      };
    }
    case InventoryActionTypes.LoadInventoryResourcesSuccess: {
      return {
        ...state,
        inventoryScopedInventoryResources: {
          resources: action.payload.resources,
          secretResources: action.payload.secretResources,
          secrets: action.payload.secrets
        },
      };
    }
    case InventoryActionTypes.LoadInventoryTenantScopedResourcesWithUsageSuccess: {
      const successAction = action as LoadInventoryTenantScopedSecretsWithUsageSuccess;
      return {
        ...state,
        tenantScopedInventoryResourcesWithUsage: {
          resources: successAction.payload.resources,
          secretResources: successAction.payload.secretResources,
          secrets: successAction.payload.secrets,
        }
      };
    }
    case InventoryActionTypes.LoadInventoryTenantScopedResourcesSuccess: {
      const successAction = action as LoadInventoryTenantScopedSecretsSuccess;
      return {
        ...state,
        tenantScopedInventoryResources: {
          resources: successAction.payload.resources,
          secretResources: successAction.payload.secretResources,
          secrets: successAction.payload.secrets,
        },
      };
    }
    case InventoryActionTypes.LoadTenantConstantsWithUsageSuccess: {
      const successAction = action as LoadTenantConstantsWithUsageSuccess;
      return {
        ...state,
        tenantScopedConstantsWithUsage: successAction.payload,
      };
    }
    case InventoryActionTypes.LoadTenantConstantsSuccess: {
      const successAction = action as LoadTenantConstantsSuccess;
      return {
        ...state,
        tenantScopedConstants: successAction.payload,
      };
    }
    default: {
      return state;
    }
  }
}

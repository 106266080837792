import { FlatTreeControl } from '@angular/cdk/tree';
import * as _ from 'lodash';

import { HierarchyTreeNode } from '../../patterns/pattern-hierarchy/pattern-hierarchy-tree.model';
import { Maybe } from '../utils/utils';

export class CustomTreeControl<T extends HierarchyTreeNode> extends FlatTreeControl<T> {

  /**
   * Recursively expand all parents of the passed node.
   */
  expandParents(node?: T) {
    if (_.isNil(node)) {
      return;
    }
    const parent = this.getParent(node);
    if (_.isNil(parent)) {
      return;
    }
    this.expand(parent);

    if (parent && this.getLevel(parent) > 0) {
      this.expandParents(parent);
    }
  }

  /**
   * Expands the tree nodes recursively, if the child nodes have a single children.<br>
   * In other words, this digs down until there's multiple directions to continue.
   * @param node
   */
  expandSmart(node: T) {
    const descendants = this.getDescendants(node);  // child getter is async 🤷 so trying to stay sync here
    if (null === descendants || undefined === descendants) {
      return;
    }
    const childLevel = this.getLevel(node) + 1;
    const children = descendants.filter((descendant: T) => childLevel === this.getLevel(descendant));

    this.expand(node);

    if (children.length === 1) {
      this.expandSmart(children[0]);
    }
  }

  /**
   * Collapses a subtree rooted at given data node recursively, using the implementation in {@link FlatTreeControl}.<br/>
   * Additionally, `CustomTreeControl#collapseDescendants` also marks all descendants with `isExpanded = false`.
   * @param dataNode the node to collapse
   */
  override collapseDescendants(dataNode: T) {
    super.collapseDescendants(dataNode);
    dataNode.isExpanded = false;
    const descendants = this.getDescendants(dataNode);
    descendants.forEach((node: T) => node.isExpanded = false);
  }

  override expand(dataNode: T) {
    super.expand(dataNode);
    dataNode.isExpanded = true;
  }

  override collapse(dataNode: T) {
    super.collapse(dataNode);
    dataNode.isExpanded = false;
  }

  override expandAll() {
    super.expandAll();
    this.dataNodes.forEach((node: T) => node.isExpanded = true);
  }

  override collapseAll() {
    super.collapseAll();
    this.dataNodes.forEach((node: T) => node.isExpanded = false);
  }

  collapseAllButTopLevel() {
    const rootElement: Maybe<T> = this.dataNodes.find(dn => !this.getParent(dn));
    if (rootElement) {
      this.collapseAll();
      this.expand(rootElement);
      rootElement.isExpanded = true;
    }
  }


  /**
   * Iterate over each node in reverse order and return the first node that has a lower level than the passed node.
   */
  getParent(node: T) {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return undefined;
  }
}

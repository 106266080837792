import { BaseService } from '../shared/base.service';
import { ProjectKey } from '../model/reducer';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class HelpService extends BaseService {

  getPluginDocumentation(projectKey: ProjectKey, className: string): Observable<string | null> {
    const url = `/projects/${projectKey}/documentation/${className}`;
    return this.httpGetText(url);
  }
}

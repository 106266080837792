import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FileService } from '../../../common/services/file/file.service';
import { CanaryTreeNode } from '../custom-resource-tree-node.model';
import * as _ from 'lodash';
import { KubernetesDialogTypeEnum } from '../kubernetes-status-dialog-payload.model';
import { editor } from 'monaco-editor';
import ICodeEditor = editor.ICodeEditor;
import { TreeNodeTypeEnum } from '../../../deployment-wizard/tree-viewer/tree-node-type.enum';

@Component({
  selector: 'adm4-k8s-custom-resource-details',
  templateUrl: 'k8s-custom-resource-details.component.html',
  styleUrls: ['../../../deployment-wizard/deploy/deployment-preview/item-details.scss', './k8s-custom-desource-details.scss']
})
export class K8sCustomResourceDetailsComponent implements OnChanges {
  @Input() node: CanaryTreeNode | null;
  @Input() inventoryKey;
  @Input() kubernetesActionType: KubernetesDialogTypeEnum;
  @ViewChild('editorContainer', {static: false}) editorContainer: ElementRef;
  boxShadowClass = 'default-inventory-box-shadow';
  content: string | undefined;

  editorOptions: any = {
    readOnly: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    }
  };
  isLoading = false;

  constructor(private fileService: FileService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['node'] && this.node) {
      if (this.isMainTreeNode) {
        this.content = '';
      } else {
        const customResourceContentUrl = `/inventories/${this.inventoryKey}/custom-resources/${this.node.id}?mode=${this.kubernetesActionType.toLowerCase()}`;
        this.isLoading = true;
        this.content = '';
        this.fileService.loadYamlFileContent(customResourceContentUrl).subscribe((customResourceContent: string) => {
          this.content = customResourceContent;
          this.isLoading = false;
        });
      }
    }
  }

  initializeEditor(initEditor: ICodeEditor): void {
    initEditor.layout({
      width: this.editorContainer.nativeElement.clientWidth,
      height: this.editorContainer.nativeElement.clientHeight
    });
  }

  get isMainTreeNode(): boolean {
    return this.node?.type === TreeNodeTypeEnum.Host;
  }

  shouldDisplaySearchContent(): boolean {
    return _.isNil(this.node) || this.isMainTreeNode;
  }
}

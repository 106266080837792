import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import * as _ from 'lodash';

import { Keys } from '../../../common/utils/keypress.utils';

@Component({
  template: '',
  styleUrls: ['./deployment-selection-list.component.scss']
})
export class DeploymentSelectionListComponent<T> {
  @ViewChildren('selectionList', {read: ElementRef}) selectionList: QueryList<ElementRef>;
  @Input() items: T[];
  @Output() itemSelected: EventEmitter<any> = new EventEmitter();

  public filterText = '';
  public filteredItems: T[];
  getFilteredItemList: () => T[]; // Need to override based on the selection type in the child instance component

  public activeItemIndex: number;

  selectItem(item: T, index) {
    this.activeItemIndex = index;
    this.itemSelected.emit(item);
  }

  updateSelectedItem(updatedItem: T, selectedItemIndex: number): void {
    this.items[selectedItemIndex] = updatedItem;
  }

  keyDown(event: KeyboardEvent, index: number) {
    switch (event.key) {
      case Keys.UP_ARROW: {
        event.preventDefault();
        this.setPreviousElementActive(this.selectionList, index);
        break;
      }
      case Keys.DOWN_ARROW: {
        event.preventDefault();
        this.setNextElementActive(this.selectionList, index);
        break;
      }
    }
  }

  setNextElementActive(selectionList: QueryList<ElementRef>, currentIndex: number): void {
    const indexToSelect = currentIndex === this.items.length - 1 ? 0 : currentIndex + 1;
    const elem = this.getElementByIndex(selectionList, indexToSelect);
    this.focusElement(elem);
    this.activeItemIndex = indexToSelect;
    this.itemSelected.emit(this.items[this.activeItemIndex]);
  }

  setPreviousElementActive(selectionList: QueryList<ElementRef>, currentIndex: number): void {
    const indexToSelect = currentIndex === 0 ? this.items.length - 1 : currentIndex - 1;
    const elem = this.getElementByIndex(selectionList, indexToSelect);
    this.focusElement(elem);
    this.activeItemIndex = indexToSelect;
    this.itemSelected.emit(this.items[this.activeItemIndex]);
  }

  getElementByIndex(selectionList: QueryList<ElementRef>, index: number): ElementRef | undefined {
    return selectionList.find((_item, i) => index === i);
  }

  focusElement(elem: ElementRef | undefined): void {
    if (elem) {
      elem.nativeElement.focus();
    }
  }

  filterBySearch(filterText: string): void {
    this.filterText = filterText;
    this.filteredItems = this.getFilteredItemList();
  }

  get noAvailableOptions(): boolean {
    return _.isEmpty(this.items);
  }

  get shouldShowEmptyFilterResultMessage(): boolean {
    return _.isEmpty(this.filteredItems);
  }
}

import { DeployedServiceItem, Pod } from './deployed-service.model';
import * as _ from 'lodash';

export class InventoryDeploymentServiceHelper {

  static getPodsByService(deployedServices: DeployedServiceItem[], serviceName: string): Pod[] {
    return _.reduce(deployedServices, (podList: Pod[], deployedService) => {
      if (_.isEqual(deployedService.serviceKey, serviceName) && !_.isNil(deployedService.pods)) {
        return podList.concat(deployedService.pods);
      }
      return podList;
    }, []);
  }

  static getDeployedServiceItemByServiceName(deployedServices: DeployedServiceItem[], serviceName: string): DeployedServiceItem | undefined {
    return deployedServices?.find(deployedService => _.isEqual(deployedService.serviceKey, serviceName));
  }

  static hasDeployedServicePods(deployedServices: DeployedServiceItem[], serviceName: string): boolean {
    return !_.isNil(this.getDeployedServiceItemByServiceName(deployedServices, serviceName)?.pods);
  }

  static getUniqueServiceNameWithProjectKey(service: string, projectKey: string): string {
    return _.join([projectKey, service], '-');
  }
}

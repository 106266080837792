import { GlobalConfig } from 'ngx-toastr';

export class ToastOpeningHelper {
  static getBaseClass(className: string): Partial<GlobalConfig> {
    return {
      closeButton: true,
      tapToDismiss: true,
      autoDismiss: true,
      timeOut: 5000,
      extendedTimeOut: 2000,
      enableHtml: true,
      onActivateTick: false,
      toastClass: 'nevis-notification ' + className,
      titleClass: 'nevis-notification-title ' + className,
      messageClass: 'nevis-notification-message ' + className,
    };
  }

  static getPersistentClass(className: string): Partial<GlobalConfig> {
    return {
      closeButton: false,
      tapToDismiss: false,
      autoDismiss: false,
      disableTimeOut: true,
      enableHtml: true,
      onActivateTick: false,
      preventDuplicates: true,
      toastClass: 'nevis-notification  ' + className,
      titleClass: 'nevis-notification-title ' + className,
      messageClass: 'nevis-notification-message ' + className
    };
  }
}

<div class="full-height-flex">
	<div class="step-sub-heading-with-actions">
		<div class='step-content-header'><mat-icon class='title' *ngIf="titleIcon">{{titleIcon}}</mat-icon>{{node.details.path | nameFromPath}}</div>
		<div class='details-actions'>
			<button class='step-content-text-button' (click)="downloadFile()">
				<mat-icon class="step-content-text-button-icon">save_alt</mat-icon>
				Download file
			</button>
			<button class='step-content-text-button' (click)="viewFile()">
				<mat-icon class="step-content-text-button-icon">fullscreen</mat-icon>
				Fullscreen
			</button>
		</div>
	</div>

	<mat-tab-group class='full-height mat-tabs-full-height tab-content-overflow-y-hidden' [ngClass]='boxShadowClass' [mat-stretch-tabs]='false'
				   [(selectedIndex)]='selectedIndex'>
		<mat-tab label="File">
			<div class="full-height">
				<adm4-deployment-file-view *ngIf='viewingFileView && selectedIndex === 0'
																	 class='full-height'
																	 [fileInfo]="viewingFileView"
																	 [compactMode]="true"></adm4-deployment-file-view>
			</div>
		</mat-tab>
		<mat-tab label="Details">

			<div class="step-content-card item-details full-height">
				<p>
					<adm4-detail-message
									[node]='node'
									[outputType]='outputType'
									[pattern]='pattern'
									[projectKey]='projectKey'>
					</adm4-detail-message>
				</p>
				<ng-container>
					<p>
						<strong>Directory</strong>
					</p>
					<p>{{node.details.path | parentPath}}</p>
				</ng-container>
				<p>
					<strong>Deployment phase</strong>
				</p>
				<p>It is scheduled for the <strong>{{node.details.phase}}</strong> phase of the deployment.</p>
				<p>
					<strong>{{node.type | capitalizeFirst}} attributes</strong>
				</p>
				<ul>
					<li>Owner: {{node.details.requested.owner}}</li>
					<li>Group: {{node.details.requested.group}}</li>
					<li>Permissions: {{node.details.requested.permissions}}</li>
				</ul>
				<ng-container *ngIf='node.details.onChange?.length'>
					<p>
						<strong>Triggers</strong>
					</p>
					If this {{node.type | lowercase}} changes, the following commands will be executed:
					<ul>
						<li *ngFor='let commandDetail of onChangeCommands'>
					<span class='link-button' (click)='navigateToCommand(commandDetail.commandId)'>
						{{commandDetail.label}}
					</span>
						</li>
					</ul>
				</ng-container>
			</div>

		</mat-tab>
	</mat-tab-group>


</div>

export enum DeploymentInstanceTableModel {
 StatusColumnName = 'status',
 InstanceColumnName = 'instance',
 ComponentColumnName = 'component',
 HostColumnName = 'host',
 ActionColumnName = 'action',
 ExpandIconColumn = 'expandIcon'
}

export const inventoryInstanceColumns: DeploymentInstanceTableModel[] = [
  DeploymentInstanceTableModel.StatusColumnName,
  DeploymentInstanceTableModel.HostColumnName,
  DeploymentInstanceTableModel.InstanceColumnName,
  DeploymentInstanceTableModel.ComponentColumnName,
  DeploymentInstanceTableModel.ActionColumnName,
  DeploymentInstanceTableModel.ExpandIconColumn
];

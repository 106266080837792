import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeNodeTypeEnum } from '../../../tree-viewer/tree-node-type.enum';
import { TreeNode } from '../../../tree-viewer/tree-node.model';
import { AppState } from '../../../../model/reducer';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Pattern } from '../../../../patterns/pattern.model';
import { GenerationOutputModel, OutputType } from '../planning-output.model';
import { deploymentWizardSelectionProjectKeyView } from '../../../../model/views';

@Component({
  selector: 'adm4-deployment-preview-details',
  template: `
		<ng-container [ngSwitch]='node.type'>
			<ng-container *ngSwitchCase='DetailType.Check'>
				<adm4-check-detail
								[node]='node'
								[outputType]='outputType'
								[projectKey]='projectKey$ | async'
								[pattern]='pattern'
								[boxShadowClass]='boxShadowClass'
                (changeSelectedDetail)='navigateToNewDetail($event)'>
				</adm4-check-detail>
			</ng-container>
			<ng-container *ngSwitchCase='DetailType.Command'>
				<adm4-command-details
								[node]='node'
								[outputType]='outputType'
								[projectKey]='projectKey$ | async'
								[pattern]='pattern'
								[boxShadowClass]='boxShadowClass'
								(changeSelectedDetail)='navigateToNewDetail($event)'>
				</adm4-command-details>
			</ng-container>
			<ng-container *ngSwitchCase='DetailType.Directory'>
				<adm4-directory-details
								[node]='node'
								[outputType]='outputType'
								[projectKey]='projectKey$ | async'
								[pattern]='pattern'
								[boxShadowClass]='boxShadowClass'
								(changeSelectedDetail)='navigateToNewDetail($event)'>
				</adm4-directory-details>
			</ng-container>
			<ng-container *ngSwitchCase='DetailType.File'>
				<adm4-file-details
								[node]='node'
								[outputType]='outputType'
								[projectKey]='projectKey$ | async'
								[pattern]='pattern'
								[boxShadowClass]='boxShadowClass'
								(changeSelectedDetail)='navigateToNewDetail($event)'>
				</adm4-file-details>
			</ng-container>
		</ng-container>
  `,
  styleUrls: ['../deployment-preview-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentPreviewDetailsComponent implements OnChanges {
  readonly DetailType = TreeNodeTypeEnum;
  @Input() node: TreeNode;
  @Input() outputType: OutputType;
  @Input() boxShadowClass: string;
  @Input() patterns: Map<string, Pattern>;
  @Output() changeSelectedDetail: EventEmitter<string> = new EventEmitter<string>();
  pattern: Pattern | undefined;
  projectKey$: Observable<string | undefined>;

  constructor(private store$: Store<AppState>) {
    this.projectKey$ = this.store$.pipe(select(deploymentWizardSelectionProjectKeyView));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.node || changes.patterns) && this.node.output) {
      const output = <GenerationOutputModel>this.node.output;
      const patternId = output.patternId ? output.patternId : this.node.details.deployablePatternId;
      this.pattern = this.patterns.get(patternId);
    }
  }

  navigateToNewDetail(detailId: string): void {
    this.changeSelectedDetail.emit(detailId);
  }
}

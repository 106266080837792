import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectChangesetItem } from '../project-changeset-item.model';
import { LocalStatus, MetaInfo } from '../../../version-control/meta-info.model';
import * as _ from 'lodash';
import { getHighestSeverity } from '../../project-issues/project-issues.helper';
import { IssueSeverityEnum } from '../../../common/model/issue-severity.enum';

@Component({
  selector: 'adm4-publish-changeset-item',
  templateUrl: './publish-changeset-item.component.html',
  styleUrls: ['./publish-changeset-item.component.scss', '../../../common/styles/component-specific/extended-list-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishChangesetItemComponent {
  @Input() changesetItem: ProjectChangesetItem<MetaInfo, any>;
  @Input() isSelected: boolean;
  @Input() projectKey: string;
  @Input() currentUserName: string | null;

  get isDeleted(): boolean {
    return this.changesetItem.metaData.localStatus === LocalStatus.Deleted;
  }

  get isLastChangeByAnotherUser(): boolean {
    if (_.isNil(this.changesetItem.metaData.localAuthors) || _.isNil(this.currentUserName) || _.isNil((this.changesetItem.metaData))) {
      return false;
    }
    return !_.isEmpty(_.difference(this.changesetItem.metaData.localAuthors, [this.currentUserName]));
  }

  get hasIssues(): boolean {
    return !_.isEmpty(this.changesetItem.issues);
  }

  get hasHighestSeverityErrors(): boolean {
    return this.highestIssueSeverity === IssueSeverityEnum.ERROR;
  }

  get hasHighestSeverityWarnings(): boolean {
    return  this.highestIssueSeverity === IssueSeverityEnum.WARNING;
  }

  private get highestIssueSeverity(): IssueSeverityEnum {
    return getHighestSeverity(this.changesetItem.issues);
  }

}

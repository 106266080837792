import * as _ from 'lodash';

/**
 * Data model for split pane area configuration when using Angular Split module
 */
export interface SplitPaneConfig {
  /**
   * Indicates unique number position of area in split pane
   * NOTE: it is important to properly set this field from 0 incrementally, because it is used to access size of area from sizes list on resizing
   */
  order: number;
  /**
   * Current size of area
   */
  size: number;
  /**
   * Indicates whether area is collapsed or expanded
   */
  isCollapsed?: boolean;
}

/**
 * Type guard used to invalidate stored splitpane config
 * @param splitPaneConfig
 */
export function isValidSplitPaneConfig(splitPaneConfig: any): splitPaneConfig is SplitPaneConfig {
  if (!_.isObject(splitPaneConfig)) {
    return false;
  }
  splitPaneConfig = splitPaneConfig as SplitPaneConfig;
  return 'order' in splitPaneConfig && _.isFinite(splitPaneConfig.order)
      && 'size' in splitPaneConfig && _.isFinite(splitPaneConfig.size)
      && (!('isCollapsed' in splitPaneConfig) || _.isBoolean(splitPaneConfig.isCollapsed));
}

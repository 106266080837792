import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IssueModel } from '../../../common/model/issue.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { Subject } from 'rxjs';

@Component({
  selector: 'adm4-warning-validation-dialog',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title dialog-warning'
                             [header]='"Warning"'
                             [showClose]='true'
                             (closeClicked)="dialogRef.close(false)">
      <div mat-dialog-content class="content-container">
        <span class="title"><p>Warning issues detected</p></span>
        <span class="description">
          <p>If you continue with the deployment, the warning issues detected during the validation will affect the deployment.</p>
          <p>Note that pattern instances with warnings will be included in the deployment.</p>
          <div class='include-warning'>
            <mat-checkbox [(ngModel)]='includeWarnings'>
                Continue with the deployment anyway
            </mat-checkbox>
          </div>
        </span>
      </div>
      <mat-dialog-actions>
        <button class='admn4-button-text' (click)='dialogRef.close(false)'>Cancel</button>
        <button class="admn4-button-ellipse-default" [class.disabled]="previewDisabled" [disabled]="previewDisabled" (click)='dialogRef.close(true)'>Preview deployment</button>
      </mat-dialog-actions>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['./warning-validation-dialog.scss', '../../../modal-dialog/modal-dialog-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningValidationDialogComponent implements OnInit, OnDestroy {
  includeWarnings = false;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(public dialogRef: MatDialogRef<WarningValidationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private validationIssues: IssueModel[]) {
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  get previewDisabled(): boolean {
    return !this.includeWarnings;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}

import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, skip, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppState } from './reducer';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EmptyAction, NevisAdminAction } from './actions';
import * as fromProject from './project';
import { LoadIssues } from './project';
import * as fromVersionControl from './version-control';
import { LoadProjectMeta, LoadProjectMetaSuccess } from './version-control';
import { requireNonNull } from '../common/utils/utils';
import ProjectMetaUtils, { LocalProjectMetaMembers, ProjectMeta } from '../projects/project.model';
import { LoadAllPatternInstances } from './pattern';
import { LoadVariables } from './variables';
import * as _ from 'lodash';
import { projectKeyView, projectMetaStateView } from './views';
import { InvalidatePatternSummaryReports } from './report/report.actions';

@Injectable()
export class ApplicationEffects {

   syncProjectDataPoll: Observable<LoadVariables | LoadAllPatternInstances | InvalidatePatternSummaryReports | EmptyAction> = createEffect(() => this.createSyncProjectDataPollEffect());
   syncProjectIssueDataPoll: Observable<LoadIssues> = createEffect(() => this.createSyncProjectIssueDataPollEffect());
   updateProjectMetaTimestamp: Observable<LoadProjectMeta> = createEffect(() => this.createUpdateProjectMetaTimestampEffect());

  constructor(private store$: Store<AppState>, private actions$: Actions) {}

  private createUpdateProjectMetaTimestampEffect() {
    return this.actions$
      .pipe(
        ofType(fromProject.ProjectActionTypes.ProjectMetaTimestampChangedInBackground),
        skip(1),
        map(() => new LoadProjectMeta())
      );
  }

  private createSyncProjectDataPollEffect() {
    return this.actions$
      .pipe(ofType(fromVersionControl.VersionControlActionTypes.LoadProjectMetaSuccess))
      .pipe(
        map((action: LoadProjectMetaSuccess) => action.payload), skip(1),
        withLatestFrom(this.store$.pipe(select(projectKeyView)), this.store$.pipe(select(projectMetaStateView))),
        mergeMap(([meta, projectKey, projectMetaState]: [ProjectMeta, string, any]) => {

          const projectModificationTags = ProjectMetaUtils.getProjectModificationTags(meta, projectMetaState);

          const actions: (EmptyAction | LoadVariables | LoadAllPatternInstances | InvalidatePatternSummaryReports)[] = projectModificationTags.map((projectModificationTag: LocalProjectMetaMembers) => {
            switch (projectModificationTag) {
              case LocalProjectMetaMembers.BUNDLE: {
                return new EmptyAction();
              }
              case LocalProjectMetaMembers.VARIABLE: {
                return new LoadVariables(requireNonNull(projectKey));
              }
              case LocalProjectMetaMembers.PATTERN: {
                return new LoadAllPatternInstances(requireNonNull(projectKey));
              }
              default: {
                // this case should not happen
                return new EmptyAction();
              }
            }
          });
          actions.push(new InvalidatePatternSummaryReports(projectKey));
          return of(...actions);
        }));
  }

  private createSyncProjectIssueDataPollEffect() {
    return this.actions$
      .pipe(
        ofType(fromProject.ProjectActionTypes.UpdateProjectIssueTimestamp),
        map((action: NevisAdminAction) => action.payload),
        skip(1),
        withLatestFrom(this.store$.pipe(select(projectKeyView))),
        distinctUntilChanged(),
        filter(([, projectKey]) => !_.isNil(projectKey)),
        map(([, projectKey]: [any, string]) => new LoadIssues(projectKey))
      );
  }
}

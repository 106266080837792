import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { combineLatest, Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';

import * as _ from 'lodash';

import { ChangePasswordService } from './change-password.service';
import { DirtyFormGuardConnectorService } from '../common/services/dirty-form-guard-connector.service';
import { UserHelper } from './user.helper';
import { Tenant } from '../tenant/tenant.model';
import { UserData } from '../model/user/user.model';
import { SignOut } from '../model/user';
import { AppState } from '../model/reducer';
import {
  analyticsEnabledView, isAnalyticsSubmissionDueView, isAnalyticsSubmissionNotificationMutedView,
  applicationInfoView,
  selectedTenantKeyView,
  superAdminView,
  tenantsView,
  userDataView,
} from '../model/views';
import { TenantHelper } from '../common/helpers/tenant.helper';
import { SelectTenantKey } from '../model/shared/shared.actions';
import { environment } from '../../environments/environment';
import { getCookie } from '../common/helpers/cookie.helper';
import { NavigationService } from '../navbar/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewAdmin4LogsPayload, ViewLogsDialogComponent } from '../common/components/view-logs-dialog/view-logs-dialog.component';
import { ApplicationInfo } from '../model/shared/application-info.model';
import { FeatureLoaderService } from '../common/services/feature-loader.service';

@Component({
  selector: 'adm4-user-menu',
  template: `
    <div class='menu-trigger-wrapper cursor-pointer' [matMenuTriggerFor]="menu">
      <div *ngIf="shouldShowUserNotifications$ | async" class="user-notification-badge"></div>
      <i class="fa fa-user" aria-hidden="true"></i>
      <div class='menu-trigger-text d-flex flex-column align-items-start' aria-label="menu">
          <span>{{userName$ | async}} </span>
          <span class="tenant-label" *ngIf='shouldShowTenantMenu$ | async' [title]='selectedTenantKey$ | async'>Tenant: {{selectedTenantKey$ | async}} </span>
			</div>
			<i class="fa fa-caret-down" aria-hidden="true"></i>
		</div>
		<mat-menu class="green-border-menu" #menu="matMenu" [overlapTrigger]='false'>
      <div mat-menu-item class='menu-element-indicated-default' (click)="logout()">
        <div class="menu-element-inner">
          <span class="menu-text">Logout</span>
        </div>
      </div>
      <div *ngIf='canChangePassword$ | async' mat-menu-item class='menu-element-indicated-default' (click)="changePassword()">
        <div class="menu-element-inner">
          <span class="menu-text">Change Password</span>
        </div>
      </div>
      <div mat-menu-item class="menu-element-indicated-default" (click)="goToUserManagement()">
        <div class="menu-element-inner">
          <span class="menu-text">Manage Users</span>
        </div>
      </div>
      <div mat-menu-item class="menu-element-indicated-default" (click)="goToGroupManagement()">
        <div class="menu-element-inner">
          <span class="menu-text">Manage Groups</span>
        </div>
      </div>
      <ng-container *ngIf="shouldShowLogs$ | async">
        <mat-divider></mat-divider>
        <div mat-menu-item class="menu-element-indicated-default" (click)="openAdmin4Logs()">
          <div class="menu-element-inner">
            <span class="menu-text">View Logs</span>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <mat-divider></mat-divider>
        <div mat-menu-item class="menu-element-indicated-default" (click)="openSubmitAnalyticsDialog()"
            [class.notified]="shouldShowUserNotifications$ | async">
          <div class="menu-element-inner">
            <span class="menu-text">Analytics Data</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf='shouldShowTenantMenu$ | async'>
        <mat-divider></mat-divider>
        <div class='tenant-section-title'>
          <strong>Tenants:</strong>
        </div>
        <div *ngFor='let tenant of tenants$ | async' mat-menu-item
             class='tenant-item menu-element' [class.selected]='isTenantSelected(tenant, selectedTenantKey$ | async)'
             (click)="selectTenant(tenant.tenantKey)">
          <div class="menu-element-inner">
            <span class='menu-text'>{{tenant.tenantKey}}</span>
          </div>
        </div>
      </ng-container>
		</mat-menu>
    <form id="logout-form" action="{{formPath}}" method="post">
      <input id="csrf-token" type="hidden" [name]="CSRF_FORM_PARAM_NAME" value="unset">
    </form>
  `,
  styleUrls: [
    '../common/styles/component-specific/mat-menu.scss',
    './user-menu.component.scss',
  ],
})
export class UserMenuComponent {

  public readonly formPath: string = environment.baseUrl + '/logout';
  public readonly CSRF_FORM_PARAM_NAME = '_csrf';
  private readonly CSRF_COOKIE_NAME = 'XSRF-TOKEN';

  userName$: Observable<string>;
  shouldShowTenantMenu$: Observable<boolean>;
  shouldShowUserNotifications$: Observable<boolean>;
  tenants$: Observable<Tenant[]>;
  selectedTenantKey$: Observable<string | null>;
  canChangePassword$: Observable<boolean>;
  shouldShowLogs$: Observable<boolean>;

  constructor(
      private store$: Store<AppState>,
      private formGuardConnectorService: DirtyFormGuardConnectorService,
      private changePasswordService: ChangePasswordService,
      private nav: NavigationService,
      private matDialog: MatDialog,
      private features: FeatureLoaderService,
  ) {
    this.shouldShowLogs$ = combineLatest([
      this.store$.select(applicationInfoView).pipe(
          map((appInfo: ApplicationInfo | null): boolean => !!appInfo && appInfo.platform === 'KUBERNETES'),
      ),
      this.store$.select(superAdminView),
    ]).pipe(
        map(([isK8s, hasSuperAdmin]: [boolean, boolean]) => isK8s && hasSuperAdmin),
        startWith(false),
    );
    this.userName$ = this.store$.pipe(
      select(userDataView),
      map((user: UserData | null) => UserHelper.formatUserName(user))
    );
    this.canChangePassword$ = this.store$.pipe(select(userDataView), map((user: UserData | null) => UserHelper.canChangePassword(user)));
    this.tenants$ = this.store$.pipe(select(tenantsView), map(_.values));
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView));
    this.shouldShowTenantMenu$ = combineLatest([this.tenants$, this.selectedTenantKey$]).pipe(
      map(([tenants, selectedTenantKey]: [Tenant[], string | null]) => TenantHelper.shouldShowTenants(tenants, selectedTenantKey)),
    );
    this.shouldShowUserNotifications$ = combineLatest([
      this.store$.select(isAnalyticsSubmissionDueView),
      this.store$.select(analyticsEnabledView),
      this.store$.select(isAnalyticsSubmissionNotificationMutedView),
    ]).pipe(map(([isDue, isEnabled, isMuted]: [boolean, boolean, boolean]) => isDue && isEnabled && !isMuted));
  }

  logout(): void {
    this.formGuardConnectorService.doIfConfirmed(() => {
      this.store$.dispatch(new SignOut());
      const csrfCookieValue: string | undefined = getCookie(this.CSRF_COOKIE_NAME);
      if (!csrfCookieValue) {
        console.warn('CSRF cookie not found when trying to log out.');
        return;
      }

      const formElement = document.querySelector('form#logout-form');
      if (!formElement) {
        console.warn('Form not found when trying to log out.');
        return;
      }
      const form: HTMLFormElement = formElement as HTMLFormElement;
      const inputElement = form.querySelector('input#csrf-token');
      if (!inputElement) {
        console.warn('CSRF token form input not found when trying to log out.');
        return;
      }
      const input: HTMLInputElement = inputElement as HTMLInputElement;
      input.setAttribute('value', csrfCookieValue);
      form.submit();
    });
  }

  changePassword() {
    this.formGuardConnectorService.doIfConfirmed(() => this.changePasswordService.openChangePasswordDialog());
  }

  goToUserManagement(): void {
    this.nav.navigateToUsers();
  }

  goToGroupManagement(): void {
    this.nav.navigateToGroups();
  }

  openAdmin4Logs(): void {
    this.matDialog.open<ViewLogsDialogComponent, ViewAdmin4LogsPayload, void>(
        ViewLogsDialogComponent,
        {
          disableClose: true,
          maxWidth: '100vw',
          maxHeight: '100vh',
          panelClass: 'dialog-full-screen',
          autoFocus: false,
          data: {
            type: 'admin4',
            logOptions: [],
          }
        }
    );
  }

  public openSubmitAnalyticsDialog(): void {
    this.features.openAnalyticsSubmitDialog();
  }

  isTenantSelected(tenant: Tenant, selectedTenantKey: string): boolean {
    return tenant.tenantKey === selectedTenantKey;
  }

  selectTenant(tenantKey: string): void {
    this.formGuardConnectorService.doIfConfirmed(() => {
      this.selectedTenantKey$.pipe(first()).subscribe((currentTenantKey: string) => {
        if (currentTenantKey !== tenantKey) {
          this.store$.dispatch(new SelectTenantKey({tenantKey}));
        }
      });
    });
  }
}

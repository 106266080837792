import { CreateInventoryContainerComponent } from './create-inventory-container.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Inventory } from '../inventory.model';

@Injectable({providedIn: 'root'})
export class CreateInventoryDialogService {

  constructor(private dialog: MatDialog) {
  }

  public openCreateInventoryDialog(predefinedInventoryData: Inventory | null = null): MatDialogRef<CreateInventoryContainerComponent> {
    const config: MatDialogConfig = {
      width: '70%',
      height: '1000',
      disableClose: true,
      data: predefinedInventoryData,
      autoFocus: false
    };
    return this.dialog.open(CreateInventoryContainerComponent, config);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Diff } from '../../../../common/model/publish-changes/diff.model';
import { DeploymentHostDiffData } from '../pattern-diff-data.model';
import { UntypedFormGroup } from '@angular/forms';
import { FormHelper } from '../../../../common/helpers/form.helper';

@Component({
  selector: 'adm4-deployment-host-diff',
  template: `
    <adm4-diff-view-element [remoteCommitInfo]='deploymentHostDiff.remote.commitInfo'
                            [localChangeInfo]='deploymentHostDiff.local.changeInfo'
                            [localFallbackLabel]='deploymentHostDiff.local.fallbackLabel'
                            [currentUsername]='currentUsername'
                            [showOnlyDifferences]='showOnlyDifferences'
    >
      <div remote>
        <adm4-deployment-host [form]='cloneForm(deploymentHostDiff.remote.form)'
                              [fcName]='deploymentHostDiff.remote.formControlName'
                              [issues]='deploymentHostDiff.remote.issues'
                              [readOnly]='true'
        ></adm4-deployment-host>
      </div>
      <div local>
        <adm4-deployment-host [form]='cloneForm(deploymentHostDiff.local.form)'
                              [fcName]='deploymentHostDiff.local.formControlName'
                              [issues]='deploymentHostDiff.local.issues'
                              [readOnly]='true'
        ></adm4-deployment-host>
      </div>
    </adm4-diff-view-element>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentHostDiffComponent {
  @Input() deploymentHostDiff: Diff<DeploymentHostDiffData>;
  @Input() showOnlyDifferences: boolean;
  @Input() currentUsername: string | null;

  /**
   * As form in store is frozen and should not be changed in order to pass it down to actual display component we have to clone it
   * Sure it could have been done better if low level component was not coupled to form, but it is
   * @param form
   */
  cloneForm(form: UntypedFormGroup): UntypedFormGroup {
    return FormHelper.cloneForm(form);
  }
}

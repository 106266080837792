import { NgModule } from '@angular/core';
import { CdkTreeModule } from '@angular/cdk/tree';
import { InventoryHeaderComponent } from './inventory-header/inventory-header.component';
import { InventoryComponent } from './inventory.component';
import { InventoryRoutingModule } from './inventory-routing.module';
import { InventoryMainComponent } from './inventory-main/inventory-main.component';
import { InventoryMenuComponent } from './inventory-menu/inventory-menu.component';
import { InventoryHelpComponent } from './inventory-help/inventory-help.component';
import { CommonModules } from '../common/common.module';
import { InsertProjectVariablesDialogComponent } from './insert-project-variables-dialog/insert-project-variables-dialog.component';
import { InsertProjectVariablesDialogService } from './insert-project-variables-dialog/insert-project-variables-dialog.service';
import { CreateInventoryContainerComponent } from './create-inventory/create-inventory-container.component';
import { CreateInventoryComponent } from './create-inventory/create-inventory.component';
import { FileBasedImportInventoryContainerComponent } from './file-based-import-inventory/file-based-import-inventory-container.component';
import { FileBasedImportInventoryComponent } from './file-based-import-inventory/file-based-import-inventory.component';
import { ConnectInventoryVersionControlContainerComponent } from './connect-inventory-version-control/connect-inventory-version-control-container.component';
import { ConnectInventoryVersionControlComponent } from './connect-inventory-version-control/connect-inventory-version-control.component';
import { ImportInventoryDialogComponent } from './import-inventory/import-inventory-dialog.component';
import { ImportInventoryComponent } from './import-inventory/import-inventory.component';
import { PublishInventoryDialogComponent } from './publish-inventory/publish-inventory-dialog.component';
import { PublishInventoryComponent } from './publish-inventory/publish-inventory.component';
import { PublishInventoryChangesetComponent } from './publish-inventory/publish-inventory-changeset/publish-inventory-changeset.component';
import { PublishInventoryChangesetItemComponent } from './publish-inventory/publish-inventory-changeset-item/publish-inventory-changeset-item.component';
import { PublishInventoryMainComponent } from './publish-inventory/publish-inventory-main.component';
import { ModalDialogModule } from '../modal-dialog/modal-dialog.module';
import { InventoryContentDiffViewComponent } from './publish-inventory/inventory-content-diff-view/inventory-content-diff-view.component';
import { InventoryDeploymentHistoryComponent } from './inventory-deployment-history/inventory-deployment-history.component';
import { InventoryDeploymentHistoryTableComponent } from './inventory-deployment-history/inventory-deployment-history-table/inventory-deployment-history-table.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { DeploymentRevisionInformationComponent } from './inventory-deployment-history/inventory-deployment-history-table/deployment-revision-information.component';
import { InventoryEditorComponent } from './inventory-editor/inventory-editor.component';
import { InsertSecretsModalComponent } from './inventory-editor/insert-secrets-modal/insert-secrets-modal.component';
import { InsertInventoryAttachmentsDialogComponent } from './inventory-editor/insert-inventory-attachments-dialog/insert-inventory-attachments-dialog.component';
import { KubernetesInventoryCreationFormComponent } from './create-inventory/kubernetes-inventory-creation-form/kubernetes-inventory-creation-form.component';
import { InventoryDropdownComponent } from './inventory-header/inventory-dropdown/inventory-dropdown.component';
import { InventoryHelpClassicalComponent } from './inventory-help/inventory-help-classical/inventory-help-classical.component';
import { InventoryHelpKubernetesComponent } from './inventory-help/inventory-help-kubernetes/inventory-help-kubernetes.component';
import { InventoryKubernetesStatusComponentComponent } from './inventory-kubernetes-status/inventory-kubernetes-status-component.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CanaryTreeViewerComponent } from './inventory-kubernetes-status/canary-tree-viewer.component';
import { PromoteRollbackDialogComponent } from './inventory-kubernetes-status/promote-rollback-dialog.component';
import { K8sCustomResourceDetailsComponent } from './inventory-kubernetes-status/k8s-custom-resource-details/k8s-custom-resource-details.component';
import { CanaryDeploymentGenerationOutputComponent } from './inventory-kubernetes-status/canary-deployment-generation-output.component';
import { KubernetesStatusDialogService } from './inventory-kubernetes-status/kubernetes-status-dialog.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { InventoryStatusComponent } from './infrastructure-status/inventory-status.component';
import { InventoryDeploymentInstanceTableComponent } from './infrastructure-status/inventory-status-table/inventory-deployment-instance-table.component';
import { PodStatusIndicatorComponent } from './pod-status-indicator/pod-status-indicator.component';
import { PodDetailsComponent } from './pod-details/pod-details.component';
import { OperatorStatusComponent } from './inventory-kubernetes-status/operator-status/operator-status.component';
import { InsertGlobalConstantDialogComponent } from './inventory-editor/insert-global-constant-dialog/insert-global-constant-dialog.component';
import { InventoryDeploymentStateIconsComponent } from './inventory-deployment-history/inventory-deployment-history-table/inventory-deployment-state-icons/inventory-deployment-state-icons.component';
import { InventoryDeploymentDetailsComponent } from './inventory-deployment-history/inventory-deployment-history-table/inventory-deployment-details/inventory-deployment-details.component';
import { PermissionsModule } from '../permissions/permissions.module';
import { InventorySettingsComponent } from './inventory-settings/inventory-settings.component';
import { InventorySettingsDetailsComponent } from './inventory-settings/inventory-settings-details/inventory-settings-details.component';
import { InventoryInformationContainerComponent } from './inventory-settings/inventory-information-container/inventory-information-container.component';
import { InventoryPermissionsComponent } from './inventory-settings/inventory-permissions/inventory-permissions.component';
import { InventoryInformationComponent } from './inventory-settings/inventory-information/inventory-information.component';
import { InventoryVersionControlInformationComponent } from './inventory-settings/inventory-version-control-information/inventory-version-control-information.component';
import { InventoryKubernetesInformationComponent } from './inventory-settings/inventory-kubernetes-information/inventory-kubernetes-information.component';
import { InventoryEditorActionsComponent } from './inventory-editor/inventory-editor-actions/inventory-editor-actions.component';

@NgModule({
  imports: [
    CommonModules,
    PermissionsModule,
    ModalDialogModule,
    InventoryRoutingModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatExpansionModule,
    MatCardModule,
    ClipboardModule,
    CdkTreeModule,
  ],
  declarations: [
    InventoryComponent,
    InventoryHeaderComponent,
    InventoryMainComponent,
    InventoryMenuComponent,
    InventoryHelpComponent,
    InventoryEditorComponent,
    InsertProjectVariablesDialogComponent,
    CreateInventoryContainerComponent,
    CreateInventoryComponent,
    FileBasedImportInventoryContainerComponent,
    FileBasedImportInventoryComponent,
    ConnectInventoryVersionControlContainerComponent,
    ConnectInventoryVersionControlComponent,
    ImportInventoryDialogComponent,
    ImportInventoryComponent,
    PublishInventoryMainComponent,
    PublishInventoryDialogComponent,
    PublishInventoryComponent,
    PublishInventoryChangesetComponent,
    PublishInventoryChangesetItemComponent,
    InventoryContentDiffViewComponent,
    InventoryDeploymentHistoryComponent,
    InventoryDeploymentHistoryTableComponent,
    DeploymentRevisionInformationComponent,
    InsertSecretsModalComponent,
    InsertInventoryAttachmentsDialogComponent,
    InventoryDropdownComponent,
    InventoryHelpClassicalComponent,
    InventoryHelpKubernetesComponent,
    InsertInventoryAttachmentsDialogComponent,
    KubernetesInventoryCreationFormComponent,
    InventoryKubernetesStatusComponentComponent,
    PromoteRollbackDialogComponent,
    CanaryDeploymentGenerationOutputComponent,
    CanaryTreeViewerComponent,
    K8sCustomResourceDetailsComponent,
    InventoryStatusComponent,
    InventoryDeploymentInstanceTableComponent,
    PodStatusIndicatorComponent,
    PodDetailsComponent,
    OperatorStatusComponent,
    InsertGlobalConstantDialogComponent,
    InventoryDeploymentStateIconsComponent,
    InventoryDeploymentDetailsComponent,
    InventorySettingsComponent,
    InventorySettingsDetailsComponent,
    InventoryInformationContainerComponent,
    InventoryPermissionsComponent,
    InventoryInformationComponent,
    InventoryVersionControlInformationComponent,
    InventoryKubernetesInformationComponent,
    InventoryEditorActionsComponent,
  ],
  providers: [
    InsertProjectVariablesDialogService,
    KubernetesStatusDialogService
  ]
})
export class InventoryModule {
}

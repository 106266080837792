import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'adm4-project-summary-section',
  template: `
    <section>
      <h4>{{title}}</h4>
      <p *ngIf='displaySummary' class='summary'>{{summary}}</p>
      <div class='content'>
        <ng-content></ng-content>
      </div>
    </section>
  `,
  styleUrls: ['../project-summary-section.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummarySectionComponent {
  @Input() title: string;
  @Input() summary: string;

  get displaySummary(): boolean {
    return !!this.summary;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
/**
 * Service to notify the navigation area of the side menu when the side menu is collapsed or un-collapsed.
 */
@Injectable()
export class SideMenuService {
  private _collapsed = new BehaviorSubject<boolean | undefined>(false);

  collapsed$: Observable<boolean | undefined> = this._collapsed.asObservable();

  collapse(isCollapsed: boolean | undefined) {
    this._collapsed.next(isCollapsed);
  }
}

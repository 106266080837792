<div class='full-height-flex'>
  <as-split direction='horizontal' gutterSize='1' useTransition (dragEnd)='onResize($event)'>
    <as-split-area [size]='itemsSplitConfig.size' [minSize]='20'>
      <div class='full-height-flex'>
        <adm4-column-header [styleClass]='"light-inline-header"'>
          <span class="flex-grow-1">Project Templates</span>
          <adm4-toggle-collapse-button *ngIf="descriptionSplitConfig.isCollapsed"
                                       [isCollapsed]="descriptionSplitConfig.isCollapsed" [side]="'right'"
                                       (click)="toggleDescriptionSplit()"></adm4-toggle-collapse-button>
        </adm4-column-header>
        <div class='remaining-space-flex-content-wrapper details-container gc-content d-flex flex-column'>

          <div class="mp-sticky-header sticky-header-shadow">
            <div class="mp-search">
              <adm4-filter (filter)="searchChanged($event)"
                           [placeholderText]='"Type to filter"'>
              </adm4-filter>
            </div>
          </div>

          <div class="mp-scroll" (click)="deselect()">
            <ng-container *ngIf="(customSectionVM | async) as vm">
              <!-- showing the section also if there are no templates at all, so that the create button is available -->
              <ng-container *ngIf="vm.shouldShowSection">
                <h1 class="template-section-title cursor-default custom-templates">Custom Templates</h1>
                <div class="mp-tiles custom-templates">
                  <span *ngIf="vm.shouldShowAdd"
                        [ngbTooltip]="'You do not have permission to create custom project templates.'" [disableTooltip]="vm.canModifyTenant" placement="bottom">
                    <button type="button" [disabled]="!vm.canModifyTenant"
                            id="create-new-custom-template" class="mp-tile" (click)="createTemplate()">
                      <mat-icon>add</mat-icon>
                      <label for="create-new-custom-template">Create new custom template</label>
                    </button>
                  </span>
                  <adm4-marketplace-tile *ngFor="let item of vm.filteredCustomTemplates"
                                         [item]="item"
                                         [selectedProjectTemplateKey]="selectedProjectTemplateKey$ | async"
                                         (selected)="selectTile($event)"
                  ></adm4-marketplace-tile>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="(filteredStandardProjectTemplates$ | async) as filteredStdTemplates;">
              <ng-container *ngIf="filteredStdTemplates.length > 0">
                <h1 class="template-section-title cursor-default standard-templates">Nevis Templates</h1>
                <div class="mp-tiles standard-templates">
                  <adm4-marketplace-tile *ngFor="let item of filteredStdTemplates"
                                         [item]="item"
                                         [selectedProjectTemplateKey]="selectedProjectTemplateKey$ | async"
                                         (selected)="selectTile($event)"
                  ></adm4-marketplace-tile>
                </div>
              </ng-container>
            </ng-container>
            <p *ngIf="(showEmptyMsg | async)" class="empty-msg">No results found.</p>
          </div>
        </div>
      </div>
    </as-split-area>

    <as-split-area [size]="descriptionSplitConfig.size" [minSize]='20' [visible]="!descriptionSplitConfig.isCollapsed">
      <div class='full-height-flex description'>
        <adm4-column-header [styleClass]='"light-inline-header"'>
          <span class="flex-grow-1 text-ellipsis">About template</span>
          <adm4-toggle-collapse-button [isCollapsed]="descriptionSplitConfig.isCollapsed" [side]="'right'" (click)="toggleDescriptionSplit()"></adm4-toggle-collapse-button>
        </adm4-column-header>
        <ng-container *ngIf="selectedProjectTemplateVm | async as ptVm; else noneSelected">
          <div class="markdown-rendered">
            <h1>{{ptVm.title}}</h1>
          </div>

          <div class="categories-list">
            <span class="cat-item" *ngFor="let c of ptVm.categories">{{c}}</span>
          </div>

          <div class="actions sticky-header-shadow">
            <!-- the span is added because the tooltip wouldn't dynamically disable with `disableTooltip` directly on the button -->
            <span [ngbTooltip]="'You don’t have a permission to create a project.'" [disableTooltip]="ptVm.canCreateProject"  placement="bottom" class="d-inline-block">
              <button class="admn4-button-ellipse-blue instantiate-template" (click)="createTemplateFrom(ptVm.projectTemplateKey)"
                [disabled]="!ptVm.canCreateProject"
              >Create new project</button>
            </span>

            <span [ngbTooltip]="'You don’t have a permission to delete a project templates.'" [disableTooltip]="ptVm.canModifyTenant" placement="bottom" class="d-inline-block">
              <button type="button" class="delete-custom-template admn4-button-ellipse-red" *ngIf="ptVm.isCustom"
                      [disabled]="!ptVm.canModifyTenant"
                      (click)="deleteTemplate(ptVm)">Delete template</button>
            </span>
          </div>
          <div class="markdown-rendered pt-description" [innerHTML]="ptVm.aboutBody">
          </div>
        </ng-container>
        <ng-template #noneSelected>
          <div class="markdown-rendered pt-description">
            <h1>What is a project template</h1>
            <p>Project template is a pre-configured sample project. Each template represents a specific use-case. You can use it to create your own project.</p>
            <h1>How to use them</h1>
            <p>Select a template to create a new project from it. Please read the description for more details about the template.</p>
            <h1>What's next</h1>
            <p>After creating a new project from a template, please fill up missing information (e.g. the endpoint of your application) and deploy it. Some additional information can be found in pattern notes and also in the project summary.</p>
            <p>Please note that you should set up your inventory before deployment.</p>
          </div>
        </ng-template>
      </div>
    </as-split-area>
  </as-split>
</div>

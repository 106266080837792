import { PatternId } from '../../model/pattern';

export enum DeploymentInstanceActionType {
  START = 'START',
  STOP = 'STOP',
  RESTART = 'RESTART'
}

export enum DeploymentHostConnectionStatus {
  UP = 'UP',
  DOWN = 'DOWN'
}

export class DeploymentHostStatus {
  host: string;
  connection: DeploymentHostConnectionStatus;
  instances: DeploymentInstance[];
}

export class DeploymentInstance {
  host: string;
  status?: string;
  component?: string;
  instance?: string;
  projectKey?: string;
  patternId?: PatternId;
  actions?: DeploymentInstanceActionType[];
}

export class DeploymentInstanceActionModel {
  instance: DeploymentInstance;
  action: DeploymentInstanceActionType;
}

export class DeployedHostError {
  host: string;
  isError: boolean;
  detail?: string;
}

export class DeploymentHostStatusResponseModel {
  _status: any;
  items: DeploymentHostStatus[];
}



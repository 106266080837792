import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateTenantLevelInventoryResourceDialogPayload, InventoryResourceContentActionDialogPayload, ReplaceInventoryResourceDialogPayload } from './inventory-resource-action-dialog-payload.model';
import { CreateInventoryAttachTenantResourceDialogComponent } from './create-inventory-attach-tenant-resource-dialog.component';
import { InventoryResourceActionDialogComponent } from './inventory-resource-action-dialog.component';
import { ReplaceInventoryResourceDialogComponent } from './replace-inventory-resource-dialog.component';

@Injectable()
export class InventoryResourceActionDialogService {
  constructor(private dialogService: MatDialog) {
  }

  openCreateGlobalResourceDialog(payload: CreateTenantLevelInventoryResourceDialogPayload): MatDialogRef<CreateInventoryAttachTenantResourceDialogComponent> {
    return this.dialogService.open<CreateInventoryAttachTenantResourceDialogComponent, CreateTenantLevelInventoryResourceDialogPayload>(CreateInventoryAttachTenantResourceDialogComponent, {
      width: '700px',
      height: '400px',
      autoFocus: true,
      data: payload
    });
  }

  openViewResourceContentDialog(payload: InventoryResourceContentActionDialogPayload): MatDialogRef<InventoryResourceActionDialogComponent> {
    return this.dialogService.open<InventoryResourceActionDialogComponent, InventoryResourceContentActionDialogPayload>(InventoryResourceActionDialogComponent, {
      width: '1000px',
      height: '600px',
      disableClose: true,
      autoFocus: false,
      data: payload
    });
  }

  openReplaceResourceDialog(payload: ReplaceInventoryResourceDialogPayload): MatDialogRef<ReplaceInventoryResourceDialogComponent> {
    return this.dialogService.open<ReplaceInventoryResourceDialogComponent, ReplaceInventoryResourceDialogPayload>(ReplaceInventoryResourceDialogComponent, {
      width: '1000px',
      height: '600px',
      disableClose: true,
      autoFocus: 'textarea.file-content-textarea',
      data: payload
    });
  }
}

import { PluginModel } from './plugin.model';
import { PatternType } from './pattern-type.model';
import * as _ from 'lodash';
import { PatternTypeClassEnum } from './plugin.enums';

export class PluginHelper {
  static convertPatternTypeToPlugin(patternType: PatternType): PluginModel {
    return {
      className: patternType.className,
      name: patternType.name,
      description: patternType.description,
      category: patternType.categories
  };
  }

  static createPluginsForCreateLinks(allPluginModels: PluginModel[]): PluginModel[] {
    return this.filterPluginsByClassNames(allPluginModels, Object.values(PatternTypeClassEnum));
  }

  static filterPluginsByClassNames(allPluginModels: PluginModel[], classNames: string[]): PluginModel[] {
    const array: PluginModel[] = [];
    _.forEach(classNames, (className: string) => {
      const pluginModel = _.find(allPluginModels, (pm: PluginModel) => pm.className === className);
      if (!!pluginModel) {
        return array.push(pluginModel);
      }
      return undefined;
    });
    return array;
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppState, Dictionary } from '../model/reducer';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { LoadInventories } from '../model/inventory';
import { allInventoriesView } from '../model/views';
import { map, switchMap, takeWhile } from 'rxjs/operators';
import { Inventory } from '../inventory/inventory.model';

@Injectable()
export class GlobalSettingsGuard {

  constructor(public store$: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return this.store$.pipe(
      select(allInventoriesView),
      switchMap((inventories: Dictionary<Inventory> | null) => {
        if (!_.isNil(inventories)) {
          return of(true);
        }
        this.store$.dispatch(new LoadInventories());
        return this.store$.pipe(
          select(allInventoriesView),
          takeWhile((inv: Dictionary<Inventory> | null) => !_.isNil(inv)),
          map((inv: Dictionary<Inventory> | null) => _.isNil(inv)));
      }));
  }
}

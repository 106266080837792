import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Diff } from '../../../../common/model/publish-changes/diff.model';
import { PatternLabelDiffData } from '../pattern-diff-data.model';

@Component({
  selector: 'adm4-pattern-label-diff',
  template: `
    <adm4-diff-view-element [remoteCommitInfo]='patternLabelDiff.remote.commitInfo'
                            [localChangeInfo]='patternLabelDiff.local.changeInfo'
                            [localFallbackLabel]='patternLabelDiff.local.fallbackLabel'
                            [currentUsername]='currentUsername'
                            [showOnlyDifferences]='showOnlyDifferences'
                            [boxed]='false'
    >
      <div remote *ngIf='patternLabelDiff.remote.label' class='pattern-label-wrapper'>
        <mat-icon>local_offer</mat-icon>
        <h2 class='pattern-label'>{{patternLabelDiff.remote.label}}</h2>
      </div>
      <div local *ngIf='patternLabelDiff.local.label' class='pattern-label-wrapper'>
        <mat-icon>local_offer</mat-icon>
        <h2 class='pattern-label'>{{patternLabelDiff.local.label}}</h2>
      </div>
    </adm4-diff-view-element>
  `,
  styleUrls: ['./pattern-label-diff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternLabelDiffComponent {
  @Input() patternLabelDiff: Diff<PatternLabelDiffData>;
  @Input() showOnlyDifferences: boolean;
  @Input() currentUsername: string | null;

}

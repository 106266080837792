import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import * as _ from 'lodash';

import { LocalStatus } from '../../version-control/meta-info.model';
import { NavigationService } from '../../navbar/navigation.service';
import { DirtyFormGuardConnectorService } from '../../common/services/dirty-form-guard-connector.service';
import { DeleteAttachmentObj, ResourceItem, SaveAttachmentObj } from '../../patterns/pattern-attachment.model';

@Component({
  selector: 'adm4-attachment-property-list',
  template: `
    <div *ngIf='isListDisplayed' class='file-list'>
      <!-- existing items-->
      <ng-container *ngFor='let resource of patternResourceItemList'>
        <ng-template #popupLocalChanges>
          <adm4-local-changes-popup [metaInfo]="resource" (publishClick)='openPublishProjectDialog()'>
          </adm4-local-changes-popup>
        </ng-template>

        <div class='file-list-item' *ngIf='!isHiddenItem(resource, propertyKey, deleteResourceList)'>
          <button type='button' class='admn4-button-icon'
                  [ngbTooltip]="downloadHint" placement="top-left"
                  (click)='downloadItem(resource)'
                  [style.color]='!showDownloadButton(resource, publish) ? "transparent" : ""'
                  [disabled]='!showDownloadButton(resource, publish)'>
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
          <ng-template #downloadHint><span>Download {{resource.resourceName}}</span></ng-template>

          <a tabindex="0" *ngIf="!publish && !readOnly; else readOnlyFileName" class='file-name' [ngClass]='getClass(resource, local)' (click)="editItem(resource)"
            [ngbTooltip]="editHint" placement="top-left"
          >{{resource.resourceName}}</a>
          <ng-template #readOnlyFileName>
            <span class="file-name" [ngClass]='getClass(resource, local)'>
              {{resource.resourceName}}
            </span>
          </ng-template>
          <ng-template #editHint><span>Click to edit {{resource.resourceName}}</span></ng-template>
          <div *ngIf='shouldDisplayModificationDate(resource)' class='file-modification-date'
               [ngbTooltip]='popupLocalChanges' [disableTooltip]='!versioned' placement='right' tooltipClass="adm4-tooltip behind-dialog">
            {{getModificationDateOfResource(resource)| customDate}}
          </div>
          <button type='button' class='admn4-button-icon' (click)='deleteResourceItem(resource)' *ngIf='showDownloadButton(resource, publish)'
              [ngbTooltip]="deleteExistingHint" placement="top-right">
            <i [class]='"fa fa-times-circle-o"' aria-hidden="true" *ngIf='!readOnly'></i>
          </button>
          <ng-template #deleteExistingHint><span>Delete {{resource.resourceName}}</span></ng-template>
        </div>
      </ng-container>

      <!-- new items-->
      <ng-container *ngIf='!publish'>
        <ng-container *ngFor='let attachment of newAttachmentList'>
          <div class='file-list-item' *ngIf='attachment.propertyKey === propertyKey'>
            <button type='button' class='admn4-button-icon' disabled *ngIf='!readOnly'
                [ngbTooltip]="newFileHint" placement="top-left">
              <i class="fa fa-file-o" aria-hidden="true"></i>
            </button>
            <ng-template #newFileHint><span>New file</span></ng-template>

            <div class='file-name'
               [ngbTooltip]="modifyHint" placement="top-left"
            >{{attachment.fileToSave.name}}</div>
            <ng-template #modifyHint><span>Save the file</span></ng-template>

            <button type='button' class='admn4-button-icon' (click)='removeFileItem.emit(attachment)' *ngIf='!readOnly'
                [ngbTooltip]="deleteAddedHint" placement="top-right">
              <i class="fa fa-times-circle-o" aria-hidden="true"></i>
            </button>
            <ng-template #deleteAddedHint><span>Delete {{attachment.fileToSave.name}}</span></ng-template>
          </div>
        </ng-container>
      </ng-container>
    </div>
  `,
  styleUrls: ['./attachment-property-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AttachmentPropertyListComponent),
    multi: true
  }]
})
export class AttachmentPropertyListComponent {
  @Input() propertyKey: string;
  @Input() meta = false;
  @Input() newAttachmentList: SaveAttachmentObj[];
  @Input() patternResourceItemList: ResourceItem[];
  @Input() deleteResourceList = [] as  DeleteAttachmentObj[];
  @Input() readOnly: boolean;
  /*TODO: this can be decided based on the instance of propertyContextService - clean up*/
  @Input() publish: boolean;
  @Input() local: boolean;
  @Input() versioned: boolean;
  @Output() removeFileItem = new EventEmitter<SaveAttachmentObj>();
  @Output() deleteResource = new EventEmitter<ResourceItem>();
  @Output() downloadResource = new EventEmitter<ResourceItem>();
  @Output() editResource = new EventEmitter<ResourceItem>();


  get isListDisplayed(): boolean {
    return (!_.isEmpty(this.patternResourceItemList) && !this.patternResourceItemList.every(resource => this.isHiddenItem(resource, this.propertyKey, this.deleteResourceList))) || !_.isEmpty(this.newAttachmentList);
  }

  constructor(
      private navigationService: NavigationService,
      private formGuardConnectorService: DirtyFormGuardConnectorService,
  ) {}

  getClass(resourceItem: ResourceItem, local: boolean): string {
    switch (resourceItem.localStatus) {
      case LocalStatus.Deleted : {
        return local ? 'deleted' : '';
      }
      case LocalStatus.Modified: {
        return '';
      }
      case LocalStatus.Added : {
        return local ? '' : 'hidden';
      }
      case LocalStatus.Unmodified: {
        return '';
      }
      default:
        return '';
    }
  }

  deleteResourceItem(resourceItem: ResourceItem) {
    this.deleteResource.emit(resourceItem);
  }

  public downloadItem(item: ResourceItem) {
    this.downloadResource.emit(item);
  }

  public editItem(item: ResourceItem) {
    this.editResource.emit(item);
  }

  showDownloadButton(resource: ResourceItem, publish: boolean): boolean {
    if (!publish) {
      return true;
    }
    switch (resource.localStatus) {
      case LocalStatus.Added : {
        return this.local;
      }
      case LocalStatus.Deleted: {
        return !this.local;
      }
      default:
        return true;
    }
  }

  /** An item is hidden if it is deleted or is overwritten in the local changes. */
  isHiddenItem(resource: ResourceItem, propertyKey: string, deleteResourceList: DeleteAttachmentObj[]): boolean {
    return this.isDeleted(deleteResourceList, resource, propertyKey) || this.isOverwritten(resource);
  }

  shouldDisplayModificationDate(resource: ResourceItem): boolean {
    return !this.publish && (!_.isNil(resource.localDate) || !_.isNil(resource.remoteDate));
  }

  private isDeleted(deleteResourceList: DeleteAttachmentObj[], resource: ResourceItem, propertyKey: string) {
    return !_.isNil(_.find(deleteResourceList, (item) => {
      return item.fileName === resource.resourceName && item.propertyKey === propertyKey;
    }));
  }

  private isOverwritten(resource: ResourceItem) {
    return !_.isNil(_.find(this.newAttachmentList, (item) => {
      return item.fileToSave.name === resource.resourceName;
    }));
  }

  getModificationDateOfResource(resource: ResourceItem): string {
    return resource.localDate || resource.remoteDate || '';
  }

  openPublishProjectDialog(): void {
    this.formGuardConnectorService.doIfConfirmed(() => this.navigationService.navigateToPublishProjectWindow());
  }
}


import { fromEventPattern as observableFromEventPattern, Observable } from 'rxjs';

import {share} from 'rxjs/operators';
import { Injectable, Renderer2 } from '@angular/core';

@Injectable()
export class GlobalClicks {
  public click$: Observable<MouseEvent>;

  constructor(renderer: Renderer2) {
    const allClicks: Observable<MouseEvent> = observableFromEventPattern(
      (handler: any) => renderer.listen('document', 'click', handler),
      (_handler, detach) => detach()
    );
    this.click$ = allClicks.pipe(share());
  }
}

import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserState } from '../../../model/user/user.reducer';
import { select, Store } from '@ngrx/store';
import { UserData } from '../../../model/user/user.model';
import * as _ from 'lodash';
import { AppState } from '../../../model/reducer';

/** Provides a snapshot of the Store at a given moment in time. Can be used if future changes are irrelevant. */
@Injectable()
export class UserStateService {

  private snapshot: UserState;

  get username(): string | null {
    return _.isNil(this.currentUser) ? null : this.currentUser.userKey;
  }

  get currentUser(): UserData | null {
    return this.snapshot.user;
  }

  constructor(store$: Store<AppState>) {
    // NOTE: State lives for the entirety of the application's lifetime, no need to unsubscribe
    store$.pipe(select(state => state.userState)).pipe(filter(userState => !_.isNil(userState))).subscribe(userState => this.snapshot = _.cloneDeep(userState) || {} as UserState);
  }
}

import { ProjectBundle } from '../../projects/project.model';
import * as _ from 'lodash';
import { ParsedBundle, StandardBundleInfo } from '../../resources/bundle-management/bundle.model';
import { SelectionModel } from '@angular/cdk/collections';
import { VersionCompareHelper } from '../../common/helpers/version-compare.helper';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';


export class ProjectDependenciesHelper {

  static marketplaceFilter: OperatorFunction<ProjectBundle[], ProjectBundle[]> =
      map((projectBundles: ProjectBundle[]) => projectBundles.filter((projectBundle: ProjectBundle) => projectBundle.symbolicName !== 'nevisadmin-plugin-marketplace'));


  static getProjectBundles(allBundles: ParsedBundle[], bundlesAssignedToProject: ParsedBundle[]): ProjectBundle[] {
    const groupedBundles = _.groupBy(allBundles, (bundle: ParsedBundle) => bundle.symbolicName);
    return _.entries(groupedBundles).map(([symbolicName, bundles]: [string, ParsedBundle[]]) => {
      const bundleAssignedToProject: ParsedBundle | undefined = bundlesAssignedToProject.find((bundle: ParsedBundle) => bundle.symbolicName === symbolicName);
      const allBundleVersions = bundles.map((bundle: ParsedBundle) => bundle.version).sort(VersionCompareHelper.reverseCompare);
      return {
        symbolicName: symbolicName,
        allVersions: allBundleVersions,
        selectedVersion: _.isNil(bundleAssignedToProject) ? allBundleVersions[0] : bundleAssignedToProject.version,
        isActiveForProject: !_.isNil(bundleAssignedToProject),
        isMandatory: false,
        latestVersion: allBundleVersions[0]
      };
    });
  }

  static getDisplayProjectBundles(allBundles: ParsedBundle[], bundlesAssignedToProject: ParsedBundle[]): ProjectBundle[] {
    const groupedBundles = _.groupBy(allBundles, (bundle: ParsedBundle) => bundle.symbolicName);
    return bundlesAssignedToProject.map((bundleAssignedToProject: ParsedBundle) => {
      const bundleGroup: ParsedBundle[] | undefined = groupedBundles[bundleAssignedToProject.symbolicName];
      const allBundleVersions = _.isNil(bundleGroup) ? [] : bundleGroup.map((bundle: ParsedBundle) => bundle.version).sort(VersionCompareHelper.reverseCompare);
      return {
        symbolicName: bundleAssignedToProject.symbolicName,
        allVersions: allBundleVersions,
        selectedVersion: bundleAssignedToProject.version,
        isActiveForProject: true,
        isMandatory: false,
        latestVersion: allBundleVersions[0]
      };
    });
  }

  static isProjectBundleStandard(projectBundle: ProjectBundle, standardBundleInfos: StandardBundleInfo[]): boolean {
    return standardBundleInfos.some(standardBundleInfo => standardBundleInfo.symbolicName === projectBundle.symbolicName);
  }

  static updateProjectBundlesBySelectionChange(projectBundles: ProjectBundle[], selection: SelectionModel<string>): ProjectBundle[] {
    return projectBundles.map(projectBundle => {
      return {
        ...projectBundle,
        isActiveForProject: selection.selected.some(selectedProjectBundleSymbolicName => selectedProjectBundleSymbolicName === projectBundle.symbolicName)
      };
    });
  }

  static updateProjectBundlesByBundleVersionChange(projectBundles: ProjectBundle[], projectBundleName: string, newSelectedVersion: string): ProjectBundle[] {
    return projectBundles.map(projectBundle => {
      return projectBundle.symbolicName === projectBundleName ? {
        ...projectBundle,
        selectedVersion: newSelectedVersion
      } : projectBundle;
    });
  }

  static getAllBundlesToLatestVersion(projectBundles: ProjectBundle[]): ProjectBundle[] {
    return projectBundles.map(projectBundle => {
      return {
        ...projectBundle,
        selectedVersion: projectBundle.latestVersion
      };
    });
  }

  static updateAllBundlesToActivateLatestVersionOnProject(projectBundles: ProjectBundle[]): ProjectBundle[] {
    return projectBundles.map(projectBundle => {
      return {
        ...projectBundle,
        selectedVersion: projectBundle.latestVersion,
        isActiveForProject: true
      };
    });
  }

  static createBundleKeyFromProjectBundle(projectBundle: ProjectBundle): string {
    return `${projectBundle.symbolicName}:${projectBundle.selectedVersion}`;
  }

  static areProjectBundleListsIdentical(projectBundles1: ProjectBundle[], projectBundles2: ProjectBundle[]): boolean {
    const difference: ProjectBundle[] = _.differenceWith(projectBundles1, projectBundles2, _.isEqual);
    return _.isEmpty(difference);
  }

  static areAllBundlesActiveForProject(projectBundles: ProjectBundle[]): boolean {
    return !_.isEmpty(projectBundles) && projectBundles.every((projectCommonBundle: ProjectBundle) => projectCommonBundle.isActiveForProject);
  }

  static areAllBundlesOnSameVersion(projectBundles: ProjectBundle[]): boolean {
    return !_.isEmpty(projectBundles) && projectBundles.every((projectCommonBundle: ProjectBundle) => projectCommonBundle.selectedVersion === projectBundles[0].selectedVersion);
  }

  /**
   * Checks if at least one of the bundles is not known to nevisAdmin 4 (not in all bundles)
   *
   * @param {string[]} projectBundles the array of bundles of the project
   * @param {string[]} allBundles the array of all bundles known to nevisAdmin4
   *
   * @returns {boolean} true if at least one bundle is not known to nevisAdmin 4. Otherwise false.
   */
  static isAnyProjectBundleInvalid(projectBundles: ProjectBundle[], allBundles: ParsedBundle[]): boolean {
    const allBundleKeys = allBundles.map((bundle) => bundle.bundleKey);
    return projectBundles.some((projectBundle: ProjectBundle) => {
      const projectBundleKey: string = this.createBundleKeyFromProjectBundle(projectBundle);
      return !_.includes(allBundleKeys, projectBundleKey);
    });
  }

  static displayedVersion(version: string, element: ProjectBundle, currentProjectBaseVersion: string | undefined): string {
    const originalProjectVersion: string | undefined = element.currentProjectVersion ? element.currentProjectVersion : currentProjectBaseVersion;
    let versionText: string = version;
    const bundleVersionCompareResult = originalProjectVersion ? VersionCompareHelper.compare(version, originalProjectVersion) : -1;
    if (bundleVersionCompareResult > 0) {
      versionText += '<sup>new</sup>';
      if (element.selectedVersion === version) {
        versionText += `<div class='upgrade-info'>(upgrading from: ${originalProjectVersion})</div>`;
      }
    }
    return versionText;
  }
}

import { createNgModule, Injectable, NgModuleRef } from '@angular/core';

import { AnalyticsSubmitModule } from '../../analytics/submit/analytics-submit.module';
import { Maybe } from '../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class FeatureLoaderService {

  private analyticsSubmitModule: Maybe<NgModuleRef<AnalyticsSubmitModule>>;

  constructor(
    private moduleRef: NgModuleRef<any>,
  ) {}

  public async openAnalyticsSubmitDialog(): Promise<void> {
    if (!this.analyticsSubmitModule) {
      // need to import the type for correct typing the field, but also need to destructure the type for the factory
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { AnalyticsSubmitModule } = await import('../../analytics/submit/analytics-submit.module');

      this.analyticsSubmitModule = createNgModule(AnalyticsSubmitModule, this.moduleRef.injector);
    }
    this.analyticsSubmitModule.instance.openSubmitDialog();
  }
}

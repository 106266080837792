import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeploymentDialogComponent } from './deployment-dialog/deployment-dialog.component';

/**
 * It's used to work with deployment wizard modal dialog
 */
@Injectable()
export class DeploymentWizardWindowService {
  constructor(private dialogService: MatDialog) {
  }

  openDeploymentWizard(): MatDialogRef<DeploymentDialogComponent> {
    return this.dialogService.open(DeploymentDialogComponent, {
      panelClass: 'big-dialog',
      disableClose: true,
      closeOnNavigation: false,
      autoFocus: false
    });
  }
}

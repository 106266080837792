import { Directive } from '@angular/core';

import { MatFormField } from '@angular/material/form-field';

/**
 * This directive overrides the `subscriptSizing` property of all `mat-form-field`s to dynamic,
 * ie. there won't be any space reserved for hints and validation messages inside the form field. <br/>
 * If you want to change the `subscriptSizing` of a `mat-form-field`,
 * add `[allowSubscriptSizing]` to it so the override will be disabled.<br/>
 * Source: https://timdeschryver.dev/blog/use-angular-directives-to-extend-components-that-you-dont-own
 */
@Directive({
  // this overrides an existing component's input on purpose
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-form-field:not([allowSubscriptSizing])'
})
export class MatFormFieldDynamicSubscriptSizingDirective {
  constructor(private matFormField: MatFormField) {
    this.matFormField.subscriptSizing = 'dynamic';
  }
}

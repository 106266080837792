import { takeUntil } from 'rxjs/operators';
import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { WidgetComponent } from '../property-widgets/widget.component';
import { DynamicPropertyCreationService } from '../property-widgets/dynamic-property-creation.service';
import { PatternProperty } from '../patterns/pattern-instance.model';
import { PropertyType } from '../plugins/property-type.model';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Directive({
  selector: '[adm4DynamicVariable]'
})
export class DynamicVariableDirective implements OnChanges, OnDestroy {

  @Input() variableName: string;
  @Input() variableValue: string;
  @Input() variableParameters: object;
  @Input() formGroup: UntypedFormGroup;
  @Input() readOnly;
  @Input() variableType?: PropertyType;
  @Output() selectVariable = new EventEmitter<string>();
  @Output() validate = new EventEmitter();

  private compInstance: WidgetComponent;
  private compReference: any;

  private destroyed$: Subject<boolean> = new Subject();


  constructor(private container: ViewContainerRef,
              private dynamicPropCreationService: DynamicPropertyCreationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variableType || changes.variableName) {
      this.recreateComponentInstance();
    }
    const patPropVM = this.getPatternPropFromVariable(this.variableName, this.variableValue);
    this.setPropertyInstance(patPropVM, this.compInstance);

    if (changes['readOnly'] && this.compInstance) {
      this.compInstance.readOnly = this.readOnly;
    }
  }

  recreateComponentInstance() {
    if (this.compReference) {
      this.compReference.destroy();
    }
    const uiComponentName = this.variableType ? this.variableType.uiComponent : null;
    const componentType = this.dynamicPropCreationService.getComponentType(uiComponentName);
    this.compReference = this.container.createComponent(componentType);
    this.compInstance = this.compReference.instance as WidgetComponent;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getPatternPropFromVariable(variableName: string, variableValue: any): PatternProperty {
    const patPropVM = new PatternProperty();
    patPropVM.propertyKey = variableName;
    patPropVM.value = variableValue;
    return patPropVM;
  }

  setPropertyInstance(property: PatternProperty, instance: WidgetComponent) {
    instance.value = this.formGroup.value[this.variableName];
    instance.widgetProperty = property;
    instance.group = this.formGroup;
    instance.selectionChanged = this.selectVariable;
    instance.validate.pipe(takeUntil(this.destroyed$)).subscribe(() => this.validate.emit());
    instance.parameters = this.variableParameters || {};
    instance.propertyType = this.variableType;
    instance.readOnly = this.readOnly;
    instance.initFinished();
  }

}


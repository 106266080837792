import { Injectable, Type } from '@angular/core';
import { TextPropertyComponent } from './text-property.component';
import { SelectionPropertyComponent } from './selection-property.component';
import { WidgetComponent } from './widget.component';
import { FallbackPropertyComponent } from './fallback-property.component';
import { PatternReferencePropertyMainComponent } from './pattern-reference/pattern-reference-property-main.component';
import { PropertyType } from '../plugins/property-type.model';
import { isVariable } from '../shared/pattern.utils';
import { AttachmentPropertyComponent } from './attachment-property-component/attachment-property.component';
import { PropertyTypeEnum } from '../common/constants/app.constants';
import { DatetimePropertyComponent } from './datetime-property.component';
import { SimpleTextareaPropertyComponent } from './simple-textarea-property.component';
import { ModSecurityRulePropertyComponent } from './modsecurityrule-property.component';
import * as _ from 'lodash';
import { KeyValuePropertyComponent } from './key-value-widget/key-value-property.component';

export const propertyTypeMap = new Map([<[string | null, Type<WidgetComponent>]>
    [PropertyTypeEnum.SimpleTextPropertyComponent, SimpleTextareaPropertyComponent],
    [PropertyTypeEnum.SelectionPropertyComponent, SelectionPropertyComponent],
    [PropertyTypeEnum.TextPropertyComponent, TextPropertyComponent],
    [PropertyTypeEnum.PatternReferencePropertyComponent, PatternReferencePropertyMainComponent],
    [null, FallbackPropertyComponent],
    [PropertyTypeEnum.FallbackPropertyComponent, FallbackPropertyComponent],
    [PropertyTypeEnum.AttachmentPropertyComponent, AttachmentPropertyComponent],
    [PropertyTypeEnum.DateTimePropertyComponent, DatetimePropertyComponent],
    [PropertyTypeEnum.ModSecurityRulePropertyComponent, ModSecurityRulePropertyComponent],
    [PropertyTypeEnum.KeyValuePropertyComponent, KeyValuePropertyComponent],
  ]
);

/** if a property can hold multiple value, than its value has to be in an array*/
export function multiValueConverter(propertyType: PropertyType | undefined, value: any) {
  const propertyComponentType: Type<WidgetComponent> = propertyTypeMap.get(_.isNil(propertyType) || _.isNil(propertyType.uiComponent) ? null : propertyType.uiComponent) || FallbackPropertyComponent;
  const multiValue = new propertyComponentType().isMultiValue();
  if (!value) {
    value = multiValue ? [] : '';
  } else if (multiValue && !Array.isArray(value) && !isVariable(value)) {
    value = [value];
  }
  return value;
}

@Injectable()
export class DynamicPropertyCreationService {

  /**
   * Returns the type of property if "type" is in the map, otherwise the FallbackProperty
   * @param type
   * @returns {WidgetComponent}
   */
  getComponentType(type?: string | null): Type<WidgetComponent> {
    if (_.isNil(type)) {
      return FallbackPropertyComponent;
    }
    return propertyTypeMap.get(type) || FallbackPropertyComponent;
  }
}

import { Component } from '@angular/core';
import { ItemDetailsComponent } from '../../deployment-preview-details/item-details.component';
import { DirectoryModel } from '../../planning-output.model';

@Component({
  selector: 'adm4-k8s-directory-detail-message',
  templateUrl: './k8s-directory-detail-message.component.html',
  styleUrls: ['../../item-details.scss']
})
export class K8sDirectoryDetailMessageComponent extends ItemDetailsComponent<DirectoryModel> {
}

export const getCookie = (cookieName?: string | undefined) => {
  if (!cookieName) {
    return undefined;
  }
  const maybeCookieNameValue: string[] | undefined = document.cookie.split(';')
      .map((c: string) => c.trim())
      .map((c: string) => c.split('='))
      .find((c: string[]) => cookieName === c[0]);
  if (maybeCookieNameValue) {
    return maybeCookieNameValue[1];
  }
  return undefined;
};

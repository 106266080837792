/**
 * Helper for working with usage data, which is needed for patters/variables usage display
 */
import { UsageInfo } from '../components/usage/usage-info';
import { Usage } from '../model/plugin-usage.model';
import * as _ from 'lodash';
import { PatternType, Property } from '../../plugins/pattern-type.model';
import { Pattern } from '../../patterns/pattern.model';

export class UsageHelper {
  static createUsageInfo(usages: Usage[], patternList: Pattern[], patternTypes: PatternType[]): UsageInfo[] {
    return usages
      .filter((usage) => usage !== null)
      .map((usage: Usage) => {
        const patternName = this.getUsagePatternName(usage, patternList);
        const propertyName = this.getUsagePropertyName(usage, patternList, patternTypes);

        return {
          patternId: usage.patternId,
          patternName: patternName,
          propertyKey: usage.propertyKey,
          propertyName: propertyName
        };
      });
  }

  private static getUsagePatternName(usage: Usage, patternList: Pattern[]): string {
    const foundPattern: Pattern | undefined = _.find(patternList, pattern => pattern.patternId === usage.patternId);
    return _.isNil(foundPattern) ? usage.patternId : foundPattern.name;
  }

  private static getUsagePropertyName(usage: Usage, patternList: Pattern[], patternTypes: PatternType[]): string {
    const foundPattern: Pattern | undefined = _.find(patternList, pattern => pattern.patternId === usage.patternId);
    const foundPatternType: PatternType | undefined = _.isNil(foundPattern) ? undefined : patternTypes.find(patternType => patternType.className === foundPattern.className);
    const foundProperty: Property | undefined = _.isNil(foundPatternType) || _.isNil(foundPatternType.properties) ? undefined : foundPatternType.properties.find((prop: Property) => prop.propertyKey === usage.propertyKey);
    return _.isNil(foundProperty) ? usage.propertyKey : foundProperty.name;
  }
}

import { DeploymentSelection } from '../deployment-selection/deployment-selection.model';
import { TenantHelper } from '../../common/helpers/tenant.helper';

export class DeploymentDialogHelper {
  static resolveDialogTitleFromDeploymentSelection(deploymentSelection: DeploymentSelection): string {
    let dialogTitle = 'Deploy';
    if (deploymentSelection.projectKey) {
      const projectName: string = TenantHelper.cropTenantFromKey(deploymentSelection.projectKey);
      dialogTitle = `${dialogTitle} project ${projectName}`;
    }
    if (deploymentSelection.inventoryKey) {
      const inventoryName: string = TenantHelper.cropTenantFromKey(deploymentSelection.inventoryKey);
      dialogTitle = `${dialogTitle} on ${inventoryName}`;
    }
    return dialogTitle;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '../model/reducer';
import { select, Store } from '@ngrx/store';
import { isErroredProjectView, projectValidatingView } from '../model/views';

@Injectable()
export class PropertyExternalLinkContext {
  isProjectValidationInProgress$: Observable<boolean>;
  isErroredProject$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {
    this.isProjectValidationInProgress$ = this.store$.pipe(select(projectValidatingView));
    this.isErroredProject$ = this.store$.pipe(select(isErroredProjectView));
  }

}

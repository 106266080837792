import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';

import { InventorySettingsContext } from './inventory-settings.context';
import { Inventory } from '../inventory.model';
import { NavigationService } from '../../navbar/navigation.service';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { InventoryContext } from '../inventory.context';

@Component({
  selector: 'adm4-inventory-settings',
  template: `
<div class='full-height-flex'>
  <adm4-inventory-header [currentInventory]='currentInventory$ | async'
                         [inventories]="inventories$ | async"
                         [inventoryValidationStatus]='inventoryValidationStatus$ | async'
                         [readOnly]="isCurrentInventoryReadonly$ | async"></adm4-inventory-header>
  <div class="remaining-space-flex-content-wrapper">
    <adm4-inventory-settings-details></adm4-inventory-settings-details>
  </div>
</div>
  `,
  providers: [InventorySettingsContext]
})
export class InventorySettingsComponent implements OnInit, OnDestroy {
  inventories$: Observable<Inventory[]>;
  currentInventory$: Observable<Inventory | undefined>;
  isCurrentInventoryReadonly$: Observable<boolean>;
  destroyed$: Subject<boolean> = new Subject();
  inventoryValidationStatus$: Observable<ValidationStatus<InventoryValidationIssue> | null>;

  constructor(
      private inventorySettingsContext: InventorySettingsContext,
      private navigationService: NavigationService,
      private inventoryContext: InventoryContext,
  ) {
    this.inventories$ = this.inventorySettingsContext.inventories$;
    this.currentInventory$ = this.inventorySettingsContext.currentInventory$;
    this.isCurrentInventoryReadonly$ = this.inventorySettingsContext.isCurrentInventoryReadonly$;
    this.inventoryValidationStatus$ = this.inventoryContext.inventoryValidationStatus$;
  }

  ngOnInit(): void {
    this.inventories$.pipe(takeUntil(this.destroyed$)).subscribe((inventories: Inventory[]) => {
      if (_.isEmpty(inventories)) {
        this.navigationService.navigateToInventoryWelcome();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

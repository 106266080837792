import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SaverService } from './saver.service';
import { NavigationService } from '../../navbar/navigation.service';
import { DirtyFormGuardConnectorService } from './dirty-form-guard-connector.service';

@Component({
  selector: 'adm4-key-shortcuts-component',
  template: ''
})
export class KeyShortcutsComponent {

  constructor(private saver: SaverService,
              private navigationService: NavigationService,
              private matDialog: MatDialog,
              private formGuardConnectorService: DirtyFormGuardConnectorService,
  ) {}

  @HostListener('window:keydown.control.s', ['$event'])
  ctrlS(event: KeyboardEvent) {
    event.preventDefault();
    if (this.isAnyDialogOpen()) return;
    this.saver.save();
  }

  @HostListener('window:keydown.control.d', ['$event'])
  ctrlD(event: KeyboardEvent) {
    event.preventDefault();
    if (this.isAnyDialogOpen()) return;
    this.formGuardConnectorService.doIfConfirmed(() => this.navigationService.navigateToDeploymentWizard());
  }

  @HostListener('window:keydown.control.k', ['$event'])
  ctrlK(event: KeyboardEvent) {
    event.preventDefault();
    if (this.isAnyDialogOpen()) return;
    this.formGuardConnectorService.doIfConfirmed(() => {
      this.navigationService.navigateToGlobalSearch();
    });
  }

  private isAnyDialogOpen() {
    return this.matDialog.openDialogs.length !== 0;
  }
}

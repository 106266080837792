import { OperationKey, Permission, PermissionType, PermissionWithTypeModel } from './permissions.model';

const TARGET_TENANTS = 'tenants';
const TARGET_PROJECTS = 'projects';
const TARGET_INVENTORIES = 'inventories';

export const isTargetGlobal = (target?: string) => '/' === target;

const tenantTargetRegex = new RegExp(`^/${TARGET_TENANTS}/[A-Za-z0-9\-]+/?$`);

/**
 * Checks if the target points to a tenant, and not to a project, inventory or sg. else.
 * @param target
 */
export const isTargetTenantLevel = (target?: string): boolean => {
  if (typeof target !== 'string') {
    return false;
  }
  return tenantTargetRegex.test(target);
};

/**
 * Checks if the target is that specified tenant, not another one, and not more specific or less specific.
 */
export const isTargetTenant = (target?: string, tenantKey?: string) => {
  if (typeof target !== 'string' || typeof tenantKey !== 'string') {
    return false;
  }
  return new RegExp(`^/${TARGET_TENANTS}/[${tenantKey}]+/?$`).test(target);
};

/**
 * Creates a filter method that can filter targets based on whether they point to the specific tenant.
 */
export const createIsTargetTenant: (tenantKey?: string) => (target?: string) => boolean =
    (tenantKey?: string) => {
      if (typeof tenantKey !== 'string') {
        return () => false;
      }
      const tenantRegex = new RegExp(`^/${TARGET_TENANTS}/[${tenantKey}]+/?$`);
      return (target?: string) => {
        if (typeof target !== 'string') {
          return false;
        }
        return tenantRegex.test(target);
      };
    };

const projectTargetRegex = new RegExp(`^/${TARGET_TENANTS}/[A-Za-z0-9\-]+/${TARGET_PROJECTS}/([A-Za-z0-9\-]+)/?$`);

/**
 * Checks if the target points to a project, and not to a tenant or an inventory or sg. else.<br/>
 * Does not check which tenant the project is in.
 * @param target
 */
export const isTargetProjectLevel = (target: string | undefined): boolean => {
  return projectTargetRegex.test(target||'invalid');
};

/**
 * Extracts the project from the target. The tenant is left in the project name.<br/>
 * Eg. from<br/>
 * `/tenants/DEV/projects/DEV-K8S-SIVEN-MASTER`<br/>
 * this is extracted<br/>
 * `DEV-K8S-SIVEN-MASTER`
 * @param target
 */
export const extractProjectFromTarget = (target: string | undefined): string | undefined => {
  const matches = projectTargetRegex.exec(target??'invalid');
  return matches && matches.length > 1 ? matches[1] : undefined;
};

const inventoryTargetRegex = new RegExp(`^/${TARGET_TENANTS}/[A-Za-z0-9\-]+/${TARGET_INVENTORIES}/([A-Za-z0-9\-]+)/?$`);

/**
 * Checks if the target points to an inventory, and not to a tenant or a project or sg. else.<br/>
 * Does not check which tenant the inventory is in.
 * @param target
 */
export const isTargetInventoryLevel = (target: string | undefined): boolean => {
  return inventoryTargetRegex.test(target||'invalid');
};

/**
 * Extracts the inventory from the target. The tenant is left in the inventory name.<br/>
 * Eg. from<br/>
 * `/tenants/DEV/inventories/DEV-INV-AZURE-DEV-OAUTH`<br/>
 * this is extracted<br/>
 * `DEV-INV-AZURE-DEV-OAUTH`
 * @param target
 */
export const extractInventoryFromTarget = (target: string | undefined): string | undefined => {
  const matches = inventoryTargetRegex.exec(target??'invalid');
  return matches && matches.length > 1 ? matches[1] : undefined;
};

/**
 * An atomic filter that checks a permission and returns whether that permission satisfies the criteria.
 */
export type PermissionFilter = (permission: Permission) => boolean;

/**
 * This are half-built {@link PermissionFilter}, ie. you still have to call them with the tenant's key
 * in order to have a complete {@link PermissionFilter}.
 */
export type TenantSpecificPermissionFilterCreator = (tenantKey: string) => ((permission: Permission) => boolean);

/**
 * Creates a {@link TenantSpecificPermissionFilterCreator} that can check whether a permission is global and has the specified {@link OperationKey}.<br/>
 * Can be used with {@link hasAnyPermissions}, but it will filter for global permissions regardless of the selected tenant.
 */
export const globalPermission = (operation: OperationKey): TenantSpecificPermissionFilterCreator => {
  return (_: string) => {
    return (permission: Permission): boolean => '/' === permission.target && permission.operationKey === operation;
  };
};

/**
 * Creates a {@link PermissionFilter} that can check whether a permission's target is the specified tenant and has the specified {@link OperationKey}.<br/>
 * Can be used with {@link hasAnyPermissions}.
 */
export const tenantPermission = (operation: OperationKey): TenantSpecificPermissionFilterCreator => {
  return (tenantKey: string): PermissionFilter => {
    const tenantTarget = '/tenants/' + tenantKey;
    return (permission: Permission): boolean => tenantTarget === permission.target && permission.operationKey === operation;
  };
};

const WILDCARD_RESOURCE = '*';

/**
 * Creates a {@link PermissionFilter} that can check whether a permission's target is the specified project in the specified tenant
 * and has the specified {@link OperationKey}.<br/>
 * The `projectKey` can be wildcard ('*'), in that case, any permission with its target pointing to any project in this inventory will satisfy the filter.<br/>
 * Can be used with {@link hasAnyPermissions}.
 */
export const projectPermission = (operation: OperationKey, projectKey: string): TenantSpecificPermissionFilterCreator => {
  return (tenantKey: string): PermissionFilter => {
    if (WILDCARD_RESOURCE === projectKey) {
      const projectPrefix = `/tenants/${tenantKey}/projects/`;
      return (permission: Permission): boolean => permission.target.startsWith(projectPrefix) && isTargetProjectLevel(permission.target) && permission.operationKey === operation;
    } else {
      const projectTarget = `/tenants/${tenantKey}/projects/${projectKey}`;
      return (permission: Permission): boolean => projectTarget === permission.target && permission.operationKey === operation;
    }
  };
};

/**
 * Creates a {@link PermissionFilter} that can check whether a permission's target is the specified inventory in the specified tenant
 * and has the specified {@link OperationKey}.<br/>
 * The `inventoryKey` can be wildcard ('*'), in that case, any permission with its target pointing to any inventory in this inventory will satisfy the filter.<br/>
 * Can be used with {@link hasAnyPermissions}.
 */
export const inventoryPermission = (operation: OperationKey, inventoryKey: string): TenantSpecificPermissionFilterCreator => {
  return (tenantKey: string): PermissionFilter => {
    if (WILDCARD_RESOURCE === inventoryKey) {
      const inventoryPrefix = `/tenants/${tenantKey}/inventories/`;
      return (permission: Permission): boolean => permission.target.startsWith(inventoryPrefix) && isTargetInventoryLevel(permission.target) && permission.operationKey === operation;
    } else {
      const inventoryTarget = `/tenants/${tenantKey}/inventories/${inventoryKey}`;
      return (permission: Permission): boolean => inventoryTarget === permission.target && permission.operationKey === operation;
    }
  };
};

/**
 * Checks if the permission is directly assigned to the user.
 * @param permission
 */
export const isUserDirect = (permission: PermissionWithTypeModel) => PermissionType.USER_DIRECT === permission.permissionType;

/**
 * Checks if the permission is assigned to the user via a group.
 * @param permission
 */
export const isGroupDirect = (permission: PermissionWithTypeModel) => PermissionType.GROUP_DIRECT === permission.permissionType;


export const prefixUser = (userKey: string): string => 'user://' + userKey;
export const prefixGroup = (userKey: string): string => 'group://' + userKey;

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../../shared/base.service';
import { ProjectTemplate, ProjectTemplateWithoutIcon } from './marketplace.model';

const PART_NAME_PRJ_TEMPLATE = 'projectTemplate';

@Injectable()
export class MarketplaceApiService extends BaseService {

  public getTenantScopedMarketplaceItems(tenantKey: string): Observable<ProjectTemplate[]> {
    const tenantTemplatesEndpoint = `/tenants/${tenantKey}/project-templates`;
    const templatesEndpoint: string = '/project-templates';
    const templatesPath: string = this.baseUrl + templatesEndpoint;
    return this.httpGetUnwrapped<ProjectTemplateWithoutIcon[]>(tenantTemplatesEndpoint + '?includeStandard=true').pipe(
        map((ptsWithoutIcon: ProjectTemplateWithoutIcon[]): ProjectTemplate[] => {
          return ptsWithoutIcon.map((ptWithoutIcon: ProjectTemplateWithoutIcon): ProjectTemplate => {
            let iconUrl: string;
            if (ptWithoutIcon.type === 'STANDARD') {
              iconUrl = `${templatesPath}/${ptWithoutIcon.projectTemplateKey}/icon`;
            } else {
              iconUrl = './assets/Nevis-Icon.svg';
            }
            return {
              ...ptWithoutIcon,
              iconUrl,
            };
          });
        }),
    );
  }

  /**
   * Creates a new custom project template for the specified tenant, from the specified project, with the specified title, summary and categories.<br/>
   * The description is taken from the project, so can't be specified here.<br/>
   * The key of the new project template will be generated by the backend, and returned in the response.
   * @param tenantKey The tenant to add the new project template to.
   * @param projectKey The key of the project from which the new template to be created.
   * @param templateTitle The title of the new template.
   * @param templateSummary The short summary of the new project template.
   * @param templateCategories The categories to add to the new project template.
   */
  createProjectFromTemplate(
      tenantKey: string,
      projectKey: string,
      templateTitle: string,
      templateSummary: string,
      templateCategories: string[],
  ): Observable<ProjectTemplateWithoutIcon> {
    const url = `/tenants/${tenantKey}/project-templates`;
    const formData: FormData = new FormData();
    const prjTemplatePart = {projectKey, title: templateTitle, categories: templateCategories, summary: templateSummary};
    const blob = new Blob([JSON.stringify(prjTemplatePart)], {type: 'application/json'});
    formData.append(PART_NAME_PRJ_TEMPLATE, blob);
    return this.httpPostFormData(url, formData);
  }

  deleteProjectTemplate(tenantKey: string, projectTemplateKey: string): Observable<void> {
    const url = `/tenants/${tenantKey}/project-templates/${projectTemplateKey}`;
    return this.httpDelete(url);
  }
}

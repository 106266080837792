import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileBasedImportInventoryContainerComponent } from './file-based-import-inventory-container.component';

@Injectable({
  providedIn: 'root'
})
export class FileBasedImportInventoryDialogService {

  constructor(private matDialog: MatDialog) { }

  openFileBasedImportInventoryDialog(): MatDialogRef<FileBasedImportInventoryContainerComponent> {
    return this.matDialog.open(FileBasedImportInventoryContainerComponent, {
      width: '30%',
      autoFocus: false,
      disableClose: true
    });
  }
}

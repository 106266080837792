import { KeyValue } from '@angular/common';

import * as _ from 'lodash';
import { filter, finalize, timeoutWith } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';

import { Dictionary } from '../../model/reducer';

export function requireNonNull<T>(object?: T | undefined | null | any, typeName?: string, throwException = true): T {
  if (_.isNull(object)) {
    const message = `${typeName} must not be null!`;
    if (throwException) {
      throw new Error(message);
    }
    console.error(message);
    return {} as T;
  } else {
    return object as T;
  }
}

export type Maybe<T> = T | undefined | null;

export const filterEmpty = <T>(maybe: T | undefined | null): maybe is T => !!maybe;

export function entryWithKeyExists<T>(entryKey: string, entries: Dictionary<T>): boolean {
  return !_.isNil(entries[entryKey]);
}

/**
 * @param {(element: T) => boolean} predicate - function based on which should decide if element should be replaced
 * @returns {(replacement: T) => (element: T) => T} - original element or replacement based on predicate condition(if true - replacement will be returned)
 */
export function replaceArrayItem<T>(predicate: (element: T) => boolean): (replacement: T) => (element: T) => T {
  return (replacement: T) => (element: T) => predicate(element) ? replacement : element;
}

/**
 * Util function for specifying multi criteria sorting, if previous criteria didn't change sorting position next will be checked
 * @param {((el1: T, el2: T) => number)[]} criteria
 * @returns {(el1: T, el2: T) => number} - position of element being checked for sorting
 */
export function criteriaSorting<T>(criteria: ((el1: T, el2: T) => number)[]): (el1: T, el2: T) => number {
  return (el1: T, el2: T) => criteria.reduce((elementPosition: number, compareFn: (el1: T, el2: T) => number) => {
    if (elementPosition === 0) {
      return compareFn(el1, el2);
    }
    return elementPosition;
  }, 0);
}

/**
 * Does modification logic until condition is met, repeating while incrementing index
 * @param {T} startingValue
 * @param {(index: number, value: T, initialValue: T) => T} action
 * @param {(value: T) => boolean} runUntil
 * @returns {T}
 */
export function reduceUntil<T>(startingValue: T, action: (index: number, value: T, initialValue: T) => T, runUntil: (value: T) => boolean): T {
  let result: T = action(0, startingValue, startingValue);
  for (let i = 1; !runUntil(result); i++) {
    result = action(i, result, startingValue);
  }
  return result;
}

/**
 * Converts map to list of values
 * @param map
 */
export function mapToArray<T>(map: Map<any, T>): T[] {
  return Array.from(map.values());
}

/**
 * Converts dictionary object to list of values
 * null is converted to empty array
 * @param dictionary
 */
export function dictionaryToArray<T>(dictionary: Dictionary<T> | null): T[] {
  return _.isNil(dictionary) ? [] : _.values(dictionary);
}

/**
 * Merges list of dictionaries into a single dictionary
 * @param dictionaries
 */
export function mergeDictionaries<T>(dictionaries: Dictionary<T>[]): Dictionary<T> {
  return dictionaries.reduce((mergedDictionary: Dictionary<T>, dictionary: Dictionary<T>) => {
    return {...mergedDictionary, ...dictionary};
  }, {});
}

export function pluralizeText(num: number, singleText: string, pluralText: string): string {
  if (num === 1 || num === -1) {
    return singleText;
  } else {
    return pluralText;
  }
}

/**
 * This function reverts result of sorting, which is useful when you want to change direction of sorting e.g. from asc to desc
 * @param compareResult
 */
export function reverseCompareResult(compareResult: number): number {
  return compareResult * -1;
}

export function wrapPromiseInSetTimeOut<T>(promisedCallback: () => Promise<T>): Promise<T> {
  return new Promise((resolve => setTimeout(() => promisedCallback().then(() => resolve))));
}

// tagged literals for template strings, for more info check: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals#Tagged_templates
/**
 * Tagged literal for template strings that will output string while removing ident characters from each line of text
 * @param text
 * @param placeholders
 */
export function skipIdent(text: TemplateStringsArray, ...placeholders): string {
  const textAsString: string = text.reduce((result, nextString, i) => (result + (placeholders[i - 1] || '') + nextString), '');
  return textAsString.split('\n').map(line => line.trim()).join('\n');
}

export function filterNotNil(): <T>(source: Observable<T | null | undefined>) => Observable<T>{
  return filter(inputIsNotNil);
}

function inputIsNotNil<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export const timeoutWithMessage = <T>(timeout: number, message: string, data?: Maybe<T>) => (source: Observable<any>): Observable<T> => {
  const response: Observable<T> = inputIsNotNil(data) ? of(data) : EMPTY;
  return source.pipe(timeoutWith(timeout, response.pipe(finalize(() => console.warn(message)))));
};

export const keyValueToObject = (kv: KeyValue<string, string>): Record<string, string> => {
  const {key, value} = kv??{};
  if (typeof key === 'undefined') {
    return {};
  }
  return {[key]: value};
};



import { Injectable } from '@angular/core';

import { ComponentType } from '@angular/cdk/overlay';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { ConfirmationDialogComponent } from '../modal-dialog/confirmation-dialog.component';
import { ConfirmationModalConfig, NotificationMessage, NotificationModel, NotificationModelWithModalConfig } from './notification.model';
import { ModalDialogNotificationComponent } from '../modal-dialog/modal-dialog-notification.component';
import { NotificationType } from './notification-type.enum';
import { ModalOpeniningHelper } from './modal-openining.helper';
import { UpgradeDialogComponent } from '../common/components/upgrade-dialog/upgrade-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ModalNotificationService {

  constructor(private dialog: MatDialog) {}

  openDefaultDialog<T, D extends NotificationModel<Function> = NotificationModel>(componentType: ComponentType<T>, notification: D): MatDialogRef<T> {
    const config: MatDialogConfig<D> = ModalOpeniningHelper.getBaseModalConfig<D>(notification);
    return this.dialog.open(componentType, config);
  }

  openSuccessDialog(notificationMessage: NotificationMessage, notificationCallback?: () => void): MatDialogRef<ModalDialogNotificationComponent> {
    const notification: NotificationModel = {
      type: NotificationType.DIALOG_SUCCESS,
      notificationMessage: notificationMessage,
      notificationCallback: notificationCallback
    };
    return this.openDefaultDialog(ModalDialogNotificationComponent, notification);
  }

  openInfoDialog(notificationMessage: NotificationMessage, notificationCallback?: () => void): MatDialogRef<ModalDialogNotificationComponent> {
    const notification: NotificationModel = {
      type: NotificationType.DIALOG_INFO,
      notificationMessage: notificationMessage,
      notificationCallback: notificationCallback
    };
    return this.openDefaultDialog(ModalDialogNotificationComponent, notification);
  }

  openWarningDialog(notificationMessage: NotificationMessage, notificationCallback?: () => void): MatDialogRef<ModalDialogNotificationComponent> {
    const notification: NotificationModel = {
      type: NotificationType.DIALOG_WARNING,
      notificationMessage: notificationMessage,
      notificationCallback: notificationCallback
    };
    return this.openDefaultDialog(ModalDialogNotificationComponent, notification);
  }

  openErrorDialog(notificationMessage: NotificationMessage, notificationCallback?: () => void): MatDialogRef<ModalDialogNotificationComponent> {
    const notification: NotificationModel = {
      type: NotificationType.DIALOG_ERROR,
      notificationMessage: notificationMessage,
      notificationCallback: notificationCallback
    };
    return this.openDefaultDialog(ModalDialogNotificationComponent, notification);
  }

  /**
   * Opens an error dialog with the specified message and with the details of the error.<br/>
   * If the request was sent with `httpGetText`, use `openHttpTextErrorDialog` instead.
   * @param httpError The error caught from the network request
   * @param message The business level message to show to the user
   */
  openHttpErrorDialog(httpError: HttpErrorResponse, message: string): MatDialogRef<ModalDialogNotificationComponent> {
    return this.openWithErrorDetails(message, httpError.error);
  }

  /**
   * Opens an error dialog with the specified message and with the details of the error.
   * Should only be used when the request was sent with `httpGetText`.
   * @param httpError The error caught from the network request
   * @param message The business level message to show to the user
   */
  openHttpTextErrorDialog(httpError: HttpErrorResponse, message: string): MatDialogRef<ModalDialogNotificationComponent> {
    const errorBody = JSON.parse(httpError.error);
    return this.openWithErrorDetails(message, errorBody);
  }

  private openWithErrorDetails(message: string, errorBody: {error: {detail: string}}): MatDialogRef<ModalDialogNotificationComponent> {
    return this.openErrorDialog({
      headerTitle: 'Error',
      title: message,
      description: `<strong>Details:</strong> ${errorBody.error.detail}`,
    });
  }

  /**
   * Returns opened confirmation dialog, to check whether it was confirmed subscribe to afterClosed() of the returned dialog
   * Subscription callback will have a {boolean | undefined} parameter passed whether it was confirmed, true - main action button clicked, false - secondary button clicked, undefined - modal was closed otherwise
   * @param notificationMessage
   * @param confirmationConfig
   */
  openConfirmDialog(notificationMessage: NotificationMessage, confirmationConfig: ConfirmationModalConfig): MatDialogRef<ConfirmationDialogComponent, boolean | undefined> {
    const notification: NotificationModelWithModalConfig<ConfirmationModalConfig> = {
      type: NotificationType.DIALOG_CONFIRMATION,
      notificationMessage: notificationMessage,
      modalConfig: confirmationConfig
    };
    return this.openDefaultDialog<ConfirmationDialogComponent, NotificationModelWithModalConfig<ConfirmationModalConfig>>(ConfirmationDialogComponent, notification);
  }

  /**
   * Returns opened confirmation dialog, styled as error dialog.
   * To check whether it was confirmed subscribe to afterClosed() of the returned dialog.
   * Subscription callback will have a {boolean | undefined} parameter passed whether it was confirmed, true - main action button clicked, false - secondary button clicked, undefined - modal was closed otherwise
   * @param notificationMessage
   * @param confirmationConfig
   */
  openConfirmErrorDialog(notificationMessage: NotificationMessage, confirmationConfig: ConfirmationModalConfig): MatDialogRef<ConfirmationDialogComponent, boolean | undefined> {
    const notification: NotificationModelWithModalConfig<ConfirmationModalConfig> = {
      type: NotificationType.DIALOG_ERROR_CONFIRMATION,
      notificationMessage: notificationMessage,
      modalConfig: confirmationConfig
    };
    return this.openDefaultDialog<ConfirmationDialogComponent, NotificationModelWithModalConfig<ConfirmationModalConfig>>(ConfirmationDialogComponent, notification);
  }

  /**
   * Opens the dialog to notify the user about an available dialog.
   * Doesn't need data, but assumes that the appropriate part of the store is present, so only open this dialog if that data is present.
   */
  openUpgradeAvailableDialog(): MatDialogRef<UpgradeDialogComponent, void> {
    const defaultConfig: MatDialogConfig<void> = ModalOpeniningHelper.getBaseModalConfig<void>();
    const config: MatDialogConfig<void> = {...defaultConfig, maxWidth: '600px'};
    // types: component, data, result
    return this.dialog.open<UpgradeDialogComponent, void, void>(UpgradeDialogComponent, config);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, Dictionary } from '../../model/reducer';
import { allInventoriesView, inventoryKeyView } from '../../model/views';
import { first, switchMap } from 'rxjs/operators';
import { Inventory, InventorySchemaType } from '../inventory.model';
import { NavigationService } from '../../navbar/navigation.service';
import { REVISION_KEY_SEPARATOR } from '../inventory-deployment-history/inventory-deployment.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { inventoryKeyParam } from '../inventory-routing.constants';

@Injectable({
  providedIn: 'root'
})
export class InventoryDeploymentStatusGuard  {

  constructor(private store$: Store<AppState>, private navigationService: NavigationService, private modalNotificationService: ModalNotificationService) {
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const targetInventoryKey: string | undefined = _.isNil(next.parent) ? undefined : next.parent.params[inventoryKeyParam];
    if (_.isNil(targetInventoryKey)) {
      return of(false);
    }
    if (_.includes(targetInventoryKey, REVISION_KEY_SEPARATOR)) {
      this.modalNotificationService.openErrorDialog({title: 'Cannot navigate to Infrastructure Status screen', description: 'Revision inventories are not allowed to have inventory status'});
      return of(false);
    }
    return forkJoin([
      this.store$.pipe(select(inventoryKeyView), first(inventoryKey => !_.isNil(inventoryKey))),
      this.store$.pipe(select(allInventoriesView), first(inventories => !_.isEmpty(inventories)))
    ]).pipe(
      switchMap(([inventoryKey, inventories]: [string, Dictionary<Inventory>]) => {
        const selectedInventory = inventories[inventoryKey];
        const isClassicInventory = _.isEqual(InventorySchemaType.CLASSIC, selectedInventory.schemaType);
        if (isClassicInventory) {
          return of(true);
        }
        this.navigationService.navigateToInventoryDeploymentHistory(inventoryKey);
        return of(false);
      })
    );
  }
}

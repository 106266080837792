import { ProjectChangesetItem } from './project-changeset-item.model';
import { LocalStatus, MetaInfo } from '../../version-control/meta-info.model';
import { ProjectMeta } from '../project.model';
import * as _ from 'lodash';
import { PatternVersionInfo } from '../../version-control/pattern-meta-info.model';
import { IssueModel, SourceType } from '../../common/model/issue.model';
import { Pattern } from '../../patterns/pattern.model';
import { PatternType } from '../../plugins/pattern-type.model';
import { Dictionary } from '../../model/reducer';
import { getIssuesById } from '../project-issues/project-issues.helper';
import {
  BUNDLE_CHANGESET_ITEM_KEY,
  BUNDLE_CHANGESET_ITEM_NAME,
  PROJECTDESCRIPTION_CHANGESET_ITEM_KEY,
  PROJECTDESCRIPTION_CHANGESET_ITEM_NAME,
  VARIABLE_CHANGESET_ITEM_KEY,
  VARIABLE_CHANGESET_ITEM_NAME
} from './publish-project.constants';
import { PatternDiffViewComponent } from './pattern-diff-view/pattern-diff-view.component';
import { VariablesDiffViewComponent } from './variables-diff-view/variables-diff-view.component';
import { BundlesDiffViewComponent } from './bundles-diff-view/bundles-diff-view.component';
import { ProjectDiffViewComponent } from './project-diff-view/project-diff-view.component';

export class PublishProjectChangesetHelper {

  static hasLocalChanges(projectMeta: ProjectMeta): boolean {
    return !_.isNil(projectMeta.lastModification);
  }

  static createProjectChangesetFromProjectMeta(projectMeta: ProjectMeta, patterns: Map<string, Pattern>, patternTypes: Dictionary<PatternType>, issues: IssueModel[]): ProjectChangesetItem<MetaInfo, any>[] {
    const patternChangeset: ProjectChangesetItem<PatternVersionInfo, any>[] = _.toPairs(projectMeta.patterns)
      .filter(([_patternId, patternVersionInfo]: [string, PatternVersionInfo]) => patternVersionInfo.localStatus !== LocalStatus.Unmodified)
      .map(([patternId, patternVersionInfo]: [string, PatternVersionInfo]) => this.createChangesetItemFromPatternVersionInfo(patternId, patternVersionInfo, patterns, patternTypes, issues));

    const variablesChangesetItem: ProjectChangesetItem<MetaInfo, any> | null = projectMeta.variables.localStatus === LocalStatus.Unmodified ? null : this.createChangesetItemFromVariableVersionInfo(projectMeta.variables, issues);
    const bundlesChangesetItem: ProjectChangesetItem<MetaInfo, any> | null = projectMeta.bundles.localStatus === LocalStatus.Unmodified ? null : this.createChangesetItemFromBundleVersionInfo(projectMeta.bundles);

    let descriptionChangesetItem: ProjectChangesetItem<MetaInfo, ProjectDiffViewComponent> | null;
    if (LocalStatus.Unmodified === projectMeta.description.localStatus) {
      descriptionChangesetItem = null;
    } else {
      descriptionChangesetItem = this.createChangesetItemFromDescription(projectMeta.description);
    }

    const otherChangesetItems: ProjectChangesetItem<MetaInfo, any>[] = [descriptionChangesetItem, variablesChangesetItem, bundlesChangesetItem].filter(changesetItem => !_.isNil(changesetItem)) as ProjectChangesetItem<MetaInfo, any>[];
    return _.concat(patternChangeset, otherChangesetItems);
  }

  static createChangesetItemFromPatternVersionInfo(patternId: string, patternVersionInfo: PatternVersionInfo, patterns: Map<string, Pattern>, patternTypes: Dictionary<PatternType>, issues: IssueModel[]): ProjectChangesetItem<PatternVersionInfo, PatternDiffViewComponent> {
    const relatedPattern: Pattern | undefined = patterns.get(patternId);
    const relatedPatternType: PatternType | undefined = _.isNil(relatedPattern) ? undefined : patternTypes[relatedPattern.className];
    const relatedPatternTypeName: string | undefined = _.isNil(relatedPatternType) ? undefined : relatedPatternType.name;
    return {
      key: patternId,
      localAuthor: patternVersionInfo.localAuthor,
      name: patternVersionInfo.name,
      secondaryName: relatedPatternTypeName,
      issues: getIssuesById(issues, SourceType.PATTERN, patternId),
      metaData: patternVersionInfo,
      diffComponent: PatternDiffViewComponent
    };
  }

  static createChangesetItemFromVariableVersionInfo(variablesMetaInfo: MetaInfo, issues: IssueModel[]): ProjectChangesetItem<MetaInfo, VariablesDiffViewComponent> {
    return {
      key: VARIABLE_CHANGESET_ITEM_KEY,
      name: VARIABLE_CHANGESET_ITEM_NAME,
      issues: issues.filter(issue => issue.target.some(target => target.sourceType === SourceType.VARIABLE)),
      localAuthor: variablesMetaInfo.localAuthor,
      metaData: variablesMetaInfo,
      diffComponent: VariablesDiffViewComponent
    };
  }

  static createChangesetItemFromBundleVersionInfo(bundleVersionInfo: MetaInfo): ProjectChangesetItem<MetaInfo, BundlesDiffViewComponent> {
    return {
      key: BUNDLE_CHANGESET_ITEM_KEY,
      name: BUNDLE_CHANGESET_ITEM_NAME,
      issues: [],
      metaData: bundleVersionInfo,
      localAuthor: bundleVersionInfo.localAuthor,
      diffComponent: BundlesDiffViewComponent
    };
  }

  static createChangesetItemFromDescription(descriptionMetaInfo: MetaInfo): ProjectChangesetItem<MetaInfo, ProjectDiffViewComponent> {
    return {
      key: PROJECTDESCRIPTION_CHANGESET_ITEM_KEY,
      name: PROJECTDESCRIPTION_CHANGESET_ITEM_NAME,
      issues: [],
      metaData: descriptionMetaInfo,
      localAuthor: descriptionMetaInfo.localAuthor,
      diffComponent: ProjectDiffViewComponent,
    };
  }
}


import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatternListData } from '../pattern-list-data.model';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-selected-pattern-list-element',
  template: `
    <div class='selection-item-container'>
      <div class='issue-icon-container'>
        <ng-container *ngIf='isLinkedCopyMode; else displayIssuesAsError'>
          <mat-icon *ngIf='warningIssue' class='warning-icon'>report_problem</mat-icon>
          <em *ngIf='displayInfoIssue' class="fa fa-info-circle help-icon"></em>
        </ng-container>
        <ng-template #displayIssuesAsError>
          <mat-icon *ngIf='warningIssue' class='validation-item-icon error-icon'>error_outline</mat-icon>
        </ng-template>
      </div>
      <div class='pattern-element-description'>
        <div class='selected-item-header' [class.header-with-issue]='warningIssue || infoIssue'>{{displayablePatternName}}</div>
        <div class='list-element-info'>{{validationIssue}}</div>
      </div>
      <div class='checkbox-container' [class.replace-label]='warningIssue'>
        <mat-checkbox [(ngModel)]='isChecked' (change)='checkboxSelect.emit($event)'  [class.replace-option]='isCopy(checkBoxText)'>
          <span class='checkbox-label'>{{checkBoxText}}</span>
        </mat-checkbox>
      </div>
    </div>
  `,
  styleUrls: ['selected-pattern-list-element.scss']
})
export class SelectedPatternListElementComponent {
  @Input() pattern: PatternListData;
  @Input() displayablePatternName: string;
  @Input() isChecked: boolean;
  @Input() warningIssue: string;
  @Input() infoIssue: string;
  @Input() isLinkedCopyMode: boolean;
  @Output() checkboxSelect = new EventEmitter();
  COPY_TEXT = 'Copy';
  REPLACE_TEXT = 'Replace';

  get displayInfoIssue(): boolean {
    if (_.isNil(this.warningIssue)) {
      return !_.isNil(this.infoIssue);
    }
    return _.isEmpty(this.warningIssue);
  }

  get checkBoxText(): string {
    return this.warningIssue && this.isLinkedCopyMode ? this.REPLACE_TEXT : this.COPY_TEXT;
  }

  get validationIssue(): string {
    if (this.warningIssue) {
      return this.warningIssue;
    }
    const patternDescription = !_.isNil(this.pattern.type) ? this.pattern.type.name : this.pattern.pattern.patternId;
    return this.infoIssue ? this.infoIssue : patternDescription;
  }

  isCopy(checkBoxText: string) {
    return checkBoxText === this.COPY_TEXT;
  }
}

import { merge, Observable, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Inventory, InventoryMeta, InventorySchemaType } from '../inventory.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import * as _ from 'lodash';
import { allInventoriesView, inventoryKeyView, selectedInventoryContentView, selectedInventoryView } from '../../model/views';
import { canCreateInventoryView, isSelectedInventoryReadonlyView } from '../../model/views/permission.views';
import { InventoryService } from '../inventory.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalNotificationService } from '../../notification/modal-notification.service';

@Injectable()
export class InventorySettingsContext {
  inventoryKey$: Observable<string | null>;
  inventories$: Observable<Inventory[]>;
  currentInventory$: Observable<Inventory | undefined>;
  inventoryMeta$: Observable<InventoryMeta | null>;
  canCreateInventory$: Observable<boolean>;
  isCurrentInventoryReadonly$: Observable<boolean>;
  currentInventoryContent$: Observable<string | null>;
  currentInventorySchemaType$: Observable<InventorySchemaType | undefined>;

  constructor(private store$: Store<AppState>,
              private inventoryService: InventoryService,
              private modalNotificationService: ModalNotificationService) {
    this.inventoryKey$ = this.store$.pipe(select(inventoryKeyView));
    this.canCreateInventory$ = this.store$.pipe(select(canCreateInventoryView));
    this.inventories$ = this.store$.pipe(select(allInventoriesView)).pipe(map(_.values));
    this.currentInventory$ = this.store$.pipe(select(selectedInventoryView));
    this.isCurrentInventoryReadonly$ = this.store$.pipe(select(isSelectedInventoryReadonlyView));
    this.currentInventoryContent$ = this.store$.pipe(select(selectedInventoryContentView));
    this.currentInventorySchemaType$ = this.currentInventory$.pipe(
      filter((inventory: Inventory | undefined) => !_.isNil(inventory)),
      map((inventory: Inventory) => inventory.schemaType)
    );
    this.inventoryMeta$ = this.currentInventory$.pipe(
      filter((inventory: Inventory | undefined) => !_.isNil(inventory)),
      switchMap((inventory: Inventory) => merge(
        of(null), // reset stream not to show outdated data
        this.inventoryService.getInventoryMeta(inventory.inventoryKey).pipe(
          catchError((error: HttpErrorResponse) => {
            console.error(error);
            this.modalNotificationService.openErrorDialog({
              title: 'Cannot check inventory changes status', description: 'An error occurred while loading inventory meta information'
            });
            return of(null);
          })
        )
      )),
      startWith(null)
    );
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Project } from '../project.model';
import { BranchProjectContainerComponent } from './branch-project-container/branch-project-container.component';

@Injectable({providedIn: 'root'})
export class BranchProjectDialogService {

  constructor(private dialog: MatDialog) { }

  public openBranchProjectDialog(predefinedProjectData: Project | null = null): void {
    const config: MatDialogConfig<Project | null> = {
      width: '30%',
      maxWidth: '50%',
      autoFocus: false,
      position: {
        top: 'calc(25vh - 64px)'
      },
      data: predefinedProjectData,
      disableClose: true
    };
    this.dialog.open(BranchProjectContainerComponent, config);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Inventory, InventorySchemaType } from '../inventory.model';
import { InventoryDeploymentHistoryContext } from './inventory-deployment-history.context';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { InventoryContext } from '../inventory.context';
import { DeploymentHistoryItem } from '../../common/model/deployment-history.model';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { DeploymentHistoryTableModel, inventoryDeploymentHistoryColumns, inventoryK8sDeploymentHistoryColumns } from './inventory-deployment-history-table/inventory-deployment-history-table.model';

@Component({
  selector: 'adm4-inventory-deployment-history',
  templateUrl: './inventory-deployment-history.component.html',
  styleUrls: ['../../common/styles/component-specific/settings-details.scss'],
  providers: [InventoryDeploymentHistoryContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryDeploymentHistoryComponent {
  currentInventory$: Observable<Inventory | undefined>;
  inventories$: Observable<Inventory[]>;
  inventoryDeploymentHistory$: Observable<DeploymentHistoryItem[] | never>;
  inventoryValidationStatus$: Observable<ValidationStatus<InventoryValidationIssue> | null>;
  isCurrentInventoryReadonly$: Observable<boolean>;
  displayedColumns: Observable<DeploymentHistoryTableModel[]>;

  constructor(private inventoryDeploymentHistoryContext: InventoryDeploymentHistoryContext, private inventoryContext: InventoryContext) {
    this.currentInventory$ = this.inventoryContext.currentInventory$;
    this.inventories$ = this.inventoryContext.inventories$.pipe(map(_.values));
    this.inventoryDeploymentHistory$ = this.inventoryDeploymentHistoryContext.inventoryDeploymentHistory$;
    this.inventoryValidationStatus$ = this.inventoryContext.inventoryValidationStatus$;
    this.isCurrentInventoryReadonly$ = this.inventoryContext.isCurrentInventoryReadonly$;
    this.displayedColumns = this.currentInventory$.pipe(filter((currentInventory: Inventory | undefined) => !_.isNil(currentInventory)),
      map((inventory: Inventory) => {
          const isClassicInventory = _.isEqual(InventorySchemaType.CLASSIC, inventory.schemaType);
          return isClassicInventory ? inventoryDeploymentHistoryColumns : inventoryK8sDeploymentHistoryColumns;
        }
      ));
  }

  hasHistory(inventoryDeploymentHistory: DeploymentHistoryItem[]): boolean {
    return !_.isEmpty(inventoryDeploymentHistory);
  }

  redeployDeployment(deploymentHistoryItem: DeploymentHistoryItem): void {
    this.inventoryDeploymentHistoryContext.redeployDeployment(deploymentHistoryItem);
  }
}

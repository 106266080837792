import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalNotificationService } from '../notification/modal-notification.service';
import { ModalDialogNotificationComponent } from '../modal-dialog/modal-dialog-notification.component';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public static readonly ERROR_DIALOG_TITLE = 'Unfortunately, an unexpected error happened.';
  private readonly ERROR_DIALOG_DESCRIPTION = `<p><br>  We\'re sorry for the hassle. Please reload this page to continue. <br>If this problem happens repeatedly, please report it to us. <br> The details can be found in the console.</p>`;
  private readonly ERRORED_NOTIFICATION_ALERT_MESSAGE = `We're sorry for the hassle. Please reload this page to continue. \nIf this problem happens repeatedly, please report it to us. \n The details can be found in the console`;


  private matDialog: MatDialog;
  private modalNotificationService: ModalNotificationService;

  constructor(private injector: Injector) {
    setTimeout(() => {
      this.matDialog = this.injector.get(MatDialog);
      this.modalNotificationService = this.injector.get(ModalNotificationService);
    });
  }

  logError(error: any): void {
    console.error('>>> GlobalErrorHandler: unhandled error', new Date().toISOString());
    console.error(error.stack);
    console.error('%O', error);
    console.error('<<<');
  }

  isSameErrorDialogOpened(): boolean {
    return this.matDialog && this.matDialog.openDialogs
      .filter((dialog: MatDialogRef<any>) => dialog.componentInstance instanceof ModalDialogNotificationComponent)
      .some((dialog: MatDialogRef<ModalDialogNotificationComponent>) => {
        return dialog.componentInstance.data.notificationMessage.title === GlobalErrorHandler.ERROR_DIALOG_TITLE;
      });
  }

  handleError(error: any): void {
    this.logError(error);
    try {
      if (!this.isSameErrorDialogOpened()) {
        this.modalNotificationService.openErrorDialog({
          title: GlobalErrorHandler.ERROR_DIALOG_TITLE,
          description: this.ERROR_DIALOG_DESCRIPTION
        });
      }
    } catch (notificationError) {
      this.logError(notificationError);
      alert(this.ERRORED_NOTIFICATION_ALERT_MESSAGE);
    }
  }
}

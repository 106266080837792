import { Maybe } from '../../common/utils/utils';

export type ProjectTemplateType = 'CUSTOM' | 'STANDARD';

export interface ProjectTemplateWithoutIcon {
  projectTemplateKey: string;
  title: string;
  categories: string[];

  /** One sentence short description of this template */
  summary: string;

  /** The description of the template in markdown format. */
  description: string;

  type: ProjectTemplateType;

  /** Contains the tenant if present. Not present in case {@link ProjectTemplateType} `'STANDARD'` */
  scope: Maybe<string>;

  creationAuthor: string;
  lastModificationAuthor: string;
  creation: string;
  lastModification: string;
}

export interface ProjectTemplate extends ProjectTemplateWithoutIcon {
  iconUrl: string;
}

/**
 * Can be used to filter an array of {@link ProjectTemplate} by text.
 * @param textFilter the string to filter the {@link ProjectTemplate} by.
 * @param includeDescription controls whether the description should also be searched.
 * @returns A method that can be passed to {@link Array#filter}
 */
export const projectTemplateByText = (textFilter: string | null, includeDescription: boolean = true) =>
    (pt: ProjectTemplate): boolean => {
      if (textFilter === null) {
        return true;
      }
      const filterString: string = textFilter.toLowerCase();
      return pt.title?.toLowerCase().includes(filterString)
          || pt.summary?.toLowerCase().includes(filterString)
          || pt.categories?.some((category: string) => category.toLowerCase().includes(filterString))
          || pt.projectTemplateKey?.toLowerCase().includes(filterString)
          || (includeDescription && pt.description?.toLowerCase().includes(filterString));
    };

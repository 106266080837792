export const INVENTORY_ZIP_FORM_CONTROL_NAME = 'inventory_zip';
export const INVENTORY_KEY_MODE_FORM_CONTROL_NAME = 'inventory_key_mode';
export const CREATED_INVENTORY_KEY_FORM_CONTROL_NAME = 'created_inventory_key';
export const EXISTING_INVENTORY_KEY_FORM_CONTROL_NAME = 'existing_inventory_key';

export enum InventoryKeyMode {
  New = 0,
  Existing = 1
}

export interface FileBasedImportInventoryFormValue {
  [INVENTORY_ZIP_FORM_CONTROL_NAME]: File | null;
  [INVENTORY_KEY_MODE_FORM_CONTROL_NAME]: InventoryKeyMode;
  [CREATED_INVENTORY_KEY_FORM_CONTROL_NAME]: string;
  [EXISTING_INVENTORY_KEY_FORM_CONTROL_NAME]: string | null;
}

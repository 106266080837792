import { Component, Inject } from '@angular/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalChangesConfirmationModalConfig, NotificationModelWithModalConfig } from '../../notification/notification.model';
import { NotificationType } from '../../notification/notification-type.enum';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-local-changes-confirmation-dialog',
  template: `
    <adm4-modal-dialog-title [ngClass]='options.style'
                             [header]="data.notificationMessage.headerTitle || options.header"
                             [showClose]="!dialogRef.disableClose"
                             (closeClicked)="closeDialog()">
      <div mat-dialog-content class="content-container">
        <span class="title">{{data.notificationMessage.title}}</span><br>
        <div class="description">
          <ng-container *ngIf='isInfoDialog; else warningDialog'>
            <adm4-local-changes-project-info-dialog *ngIf='projectKey' [projectKey]='projectKey' (closeDialog)='closeDialog(true)'></adm4-local-changes-project-info-dialog>
          </ng-container>
          <ng-template #warningDialog>
            <adm4-local-changes-project-confirmation-dialog *ngIf='projectKey' [projectKey]='projectKey'></adm4-local-changes-project-confirmation-dialog>
            <adm4-local-changes-inventory-confirmation-dialog *ngIf='inventoryKey' [inventoryKey]='inventoryKey'></adm4-local-changes-inventory-confirmation-dialog>
            <adm4-concurrent-changes-inventory-confirmation-dialog *ngIf='concurrentChange' (triggerInventoryRefresh)='triggerAction()'></adm4-concurrent-changes-inventory-confirmation-dialog>
          </ng-template>
        </div>
      </div>
      <mat-dialog-actions>
        <button class='admn4-button-text' *ngIf='!isInfoDialog' (click)='closeDialog(false)'>{{cancelButtonText}}</button>
        <button class='admn4-button-ellipse-default' (click)="closeDialog(true)">{{confirmButtonText}}</button>
      </mat-dialog-actions>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['../modal-dialog-notification.component.scss']
})
export class LocalChangesConfirmationDialogComponent extends ConfirmationDialogComponent {

  projectKey?: string;
  inventoryKey?: string;
  concurrentChange?: boolean;

  constructor(public override dialogRef: MatDialogRef<LocalChangesConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public override data: NotificationModelWithModalConfig<LocalChangesConfirmationModalConfig>) {
    super(dialogRef, data);

    this.projectKey = data.modalConfig.projectKey;
    this.inventoryKey = data.modalConfig.inventoryKey;
    this.concurrentChange = data.modalConfig.concurrentChange;
  }

  triggerAction(): void {
    if (_.isFunction(this.data.modalConfig.onSaveCallback)) {
      this.data.modalConfig.onSaveCallback();
    }
    this.closeDialog();
  }

  get isInfoDialog(): boolean {
    return _.isEqual(this.data.type, NotificationType.DIALOG_INFO);
  }
}

import { AppState } from '../../model/reducer';
import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { MarketplaceState, STORE_FEATURE_KEY_MARKETPLACE } from './marketplace.reducer';
import { ProjectTemplate, projectTemplateByText } from './marketplace.model';

const selectFeature: MemoizedSelector<object, MarketplaceState, DefaultProjectorFn<MarketplaceState>> =
    createFeatureSelector<MarketplaceState>(STORE_FEATURE_KEY_MARKETPLACE);

export const allProjectTemplatesView: MemoizedSelector<AppState, ProjectTemplate[]> = createSelector(
    selectFeature,
    (state: MarketplaceState): ProjectTemplate[] => state.allItems,
);

export const filteredMarketplaceItemsView: MemoizedSelector<AppState, ProjectTemplate[]> = createSelector(
    selectFeature,
    (state: MarketplaceState): ProjectTemplate[] => state.allItems.filter(projectTemplateByText(state.filters.text)),
);

export const selectedMarketplaceProjectKeyView: MemoizedSelector<AppState, string | null> = createSelector(
    selectFeature,
    (state: MarketplaceState): string | null => state.selectedKey,
);

export const selectedMarketplaceProjectView: MemoizedSelector<AppState, ProjectTemplate | null> = createSelector(
    selectFeature,
    (state: MarketplaceState): ProjectTemplate | null => {
      const selectedKey = state.selectedKey;
      if (!selectedKey) {
        return null;
      }
      const found: ProjectTemplate | undefined = state.allItems.find((pt: ProjectTemplate) => selectedKey === pt?.projectTemplateKey);
      return found ? found : null;
    },
);

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NavigationService } from '../../../navbar/navigation.service';
import { Project } from '../../project.model';
import * as _ from 'lodash';
import { Dictionary } from '../../../model/reducer';
import { DirtyFormGuardConnectorService } from '../../../common/services/dirty-form-guard-connector.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationConstants } from '../../../common/constants/navigation.constants';
import { Observable, Subject } from 'rxjs';
import { TenantHelper } from '../../../common/helpers/tenant.helper';

@Component({
  selector: 'adm4-project-dropdown',
  template: `
    <mat-form-field class='selection-dropdown-menu hide-underline cursor-pointer'>
      <mat-select #projectSelection [hideSingleSelectionIndicator]='true'
                  [(ngModel)]='projectKey'
                  panelClass='project-inventory-dropdown'
                  [disableOptionCentering]='true'
                  (click)='focusDropdownInput()'>
        <mat-select-trigger class='dropdown-selection-text'>
          {{projectKey | cropTenantFromKey}}
        </mat-select-trigger>
        <adm4-searchable-dropdown-input *ngIf='projectSelection.focused'
                                        [sourceItems]='projectsList'
                                        [placeholder]="'Please select a project...'"
                                        [noResultsMessage]='"No project found"'
                                        [searchableFormatFn]='searchableProjectFormatFn'
                                        [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                        (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
        <mat-option *ngFor='let project of projectsList'
                    [value]='project.projectKey'
                    [hidden]='!isProjectFilteredOut(project)'
                    [class.selected]='isSelectedProject(project)'
                    class='dropdown-option'>
          <a mat-menu-item
             [class.selected]='isSelectedProject(project)'
             [ngbTooltip]='project.projectKey | cropTenantFromKey' placement='right'
             class='menu-element-indicated-default'
             (click)='startProjectNavigation(project.projectKey)'>
            <div class="menu-element-inner">
              <mat-icon>description</mat-icon>
              <span class="menu-text">{{project.projectKey | cropTenantFromKey}}</span>
            </div>
          </a>
        </mat-option>
      </mat-select>
    </mat-form-field>
  `,
  styleUrls: [
    '../../../common/styles/component-specific/mat-menu.scss',
    '../../../common/styles/component-specific/inventory-project-dropdown.scss',
    './project-dropdown.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectDropdownComponent implements OnChanges {
  @Input() projectKey: string;
  @Input() projects: Dictionary<Project>;

  projectsList: Project[];

  navigationCategoryPath: string;
  filteredProjectList: Project[];

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  constructor(private navigationService: NavigationService,
              private dirtyGuard: DirtyFormGuardConnectorService,
              private activatedRoute: ActivatedRoute,
              private cdRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projects) {
      this.projectsList = _.values(this.projects);
      this.navigationCategoryPath = this.activatedRoute.snapshot.url[0].path;
    }
  }

  startProjectNavigation(newProjectKey: string): void {
    const existingProjectKey: string = this.projectKey;
    this.dirtyGuard.doIfConfirmed(
      () => this.finishProjectNavigation(newProjectKey),
      () => this.rejectProjectNavigation(existingProjectKey),
    );
  }

  private finishProjectNavigation(projectKey: string): void {
      switch (this.navigationCategoryPath) {
        case NavigationConstants.ISSUES:
          this.navigationService.navigateToIssues(projectKey);
          break;
        case NavigationConstants.VARIABLES:
          this.navigationService.navigateToVariables(projectKey);
          break;
        case NavigationConstants.REPORTS:
          this.navigationService.navigateToReports(projectKey);
          break;
        case NavigationConstants.PROJECT_SETTINGS:
          this.navigationService.navigateToProjectSettings(projectKey);
          break;
        case NavigationConstants.OVERVIEW:
        default:
          this.navigationService.navigateToProject(projectKey);
          break;
      }
  }

  private rejectProjectNavigation(originalProjectKey: string): void {
    this.projectKey = originalProjectKey;
    // this cmp is `onPush`, and the callback is called from the dialog, so we need to trigger the cdRef
    this.cdRef.markForCheck();
  }

  isSelectedProject(project: Project): boolean {
    return project.projectKey === this.projectKey;
  }

  updateSearchResult(filteredList: Project[]): void {
    this.filteredProjectList = filteredList;
  }

  searchableProjectFormatFn = (project: Project): string => {
    return TenantHelper.cropTenantFromKey(project.projectKey);
  };

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }

  isProjectFilteredOut(project: Project): boolean {
    return _.includes(this.filteredProjectList, project);
  }
}

import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppState } from '../../../model/reducer';
import { availableVersionView, UpgradeInfo } from '../../../model/views';
import { LocalStorageHelper } from '../../helpers/local-storage.helper';
import { localStorageUpgradeLastNotified } from '../../constants/local-storage-keys.constants';
import { NavigationService } from '../../../navbar/navigation.service';

@Component({
  selector: 'adm4-upgrade-dialog',
  template: `
<adm4-modal-dialog-title class="modal-dialog-title"
  [header]="'Upgrade nevisAdmin 4'" [showClose]="true"
  (closeClicked)="closeWithSnooze()">
  <form (ngSubmit)="navigateToAzureUpgrade()">
    <div class="content-container remaining-space-flex-content-wrapper upgrade-content" *ngIf="upgradeInfo$ | async as upgradeInfo">
      <mat-icon class="title-icon" svgIcon="cloud_up"></mat-icon>
      <h1 class="title-text">Upgrade available!</h1>
      <p class="versions">Upgrade nevisAdmin 4 from <strong>{{upgradeInfo.current}}</strong> to <strong>{{upgradeInfo.upgrade}}</strong> on Azure.</p>
      <p>It may take about 10 minutes.<br/>Make sure to save any unsaved changes before starting the upgrade.</p>
      <adm4-external-link class="relnotes"
                          [linkUrl]="RELEASE_NOTES_LINK_RELATIVE | docLink"
                          linkLabel="See what's new"
                          matIconName="open_in_new"
                          displayStyle='inline'
                          [openInNewTab]='true'></adm4-external-link>
    </div>
    <div mat-dialog-actions>
      <adm4-button-bar [isSubmitDisabled]="false"
                       [submitButtonText]="'Upgrade on Azure'"
                       [cancelButtonText]="'Later'"
                       (cancelClicked)='closeWithSnooze()'></adm4-button-bar>
    </div>
  </form>
</adm4-modal-dialog-title>  
  `,
  styleUrls: ['../../../common/styles/component-specific/modal-window.scss', './upgrade-dialog.component.scss'],
})
export class UpgradeDialogComponent {

  public readonly RELEASE_NOTES_LINK_RELATIVE = 'nevisadmin4/release-notes';

  public readonly upgradeInfo$: Observable<UpgradeInfo>;

  constructor(
      private dialogRef: MatDialogRef<UpgradeDialogComponent, void>,
      private store$: Store<AppState>,
      private navigator: NavigationService,
  ) {
    this.upgradeInfo$ = store$.select(availableVersionView).pipe(
        filter((availableVersion: undefined | false | UpgradeInfo): availableVersion is UpgradeInfo => !!availableVersion),
    );
  }

  public closeWithSnooze(): void {
    LocalStorageHelper.save(localStorageUpgradeLastNotified, new Date().toISOString());
    this.dialogRef.close();
  }

  public navigateToAzureUpgrade(): void {
    this.navigator.toAzureUpgradeNewTab();
  }

}

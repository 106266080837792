import { ResourceLevelEnum, ResourceLevelGroup } from './inventory-attachment-payload.model';
import * as _ from 'lodash';
import { InventoryResource, TenantResourceFlag } from '../../inventory.model';

export class ResourceLevelGroupHelper {

  static groupResourcesByLevel<T extends InventoryResource>(resourceList: (T & TenantResourceFlag)[]): ResourceLevelGroup<T>[] {
    const inventoryLevelGroup = _.filter(resourceList, secret => _.isNil(secret.isTenantScoped) || !secret.isTenantScoped);
    const tenantLevelGroup = _.filter(resourceList, secret => !_.isNil(secret.isTenantScoped) && secret.isTenantScoped);
    return [{
      level: ResourceLevelEnum.INVENTORY,
      resources: inventoryLevelGroup
    }, {
      level: ResourceLevelEnum.GLOBAL,
      resources: tenantLevelGroup
    }];
  }

  static hasDisplayableResourcesByLevel<T extends InventoryResource>(resourceGroup: ResourceLevelGroup<T>): boolean {
    return !_.isEmpty(resourceGroup.resources);
  }
}

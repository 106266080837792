<ng-template #permissionReadonly>
  This permission is assigned to the tenant and cannot be modified here.
</ng-template>
<ng-template #noResult>
  <div class="no-filter-results">{{NO_PERMISSION_FOUND}}</div>
</ng-template>
<ng-container *ngIf="filterMatches; else noResult">
  <mat-table *ngIf="dataSource" [dataSource]="dataSource" class="adm4-mat-table no-shadow">
    <ng-container [matColumnDef]="nameColumn">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="name-container">
          <mat-icon *ngIf="isGroup(element); else userIcon">group</mat-icon>
          <ng-template #userIcon>
            <mat-icon>perm_identity</mat-icon>
          </ng-template>
          {{element | permissionAssigneeName}}
        </div>
      </mat-cell>
    </ng-container>
    <ng-container [matColumnDef]="usernameColumn">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Username</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{element | permissionAssigneeUsername}}
      </mat-cell>
    </ng-container>
    <ng-container *ngFor='let operationColumn of operationColumns' [matColumnDef]='operationColumn.columnKey'>
      <mat-header-cell mat-header-cell *matHeaderCellDef>{{operationColumn.columnLabel}}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <ng-template #readOnlyCheck>
          <mat-icon *ngIf='hasPermissionToOperation(element, operationColumn.operationKey)'>done</mat-icon>
        </ng-template>
        <span [ngbTooltip]='permissionReadonly' placement='right' [disableTooltip]="!isDisabled(element, operationColumn.operationKey)">
          <mat-checkbox *ngIf="editMode; else readOnlyCheck"
                        (change)="onSelectionChange($event, element, operationColumn.operationKey)"
                        [checked]="hasPermissionToOperation(element, operationColumn.operationKey)"
                        [disabled]="isDisabled(element, operationColumn.operationKey)">
          </mat-checkbox>
        </span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</ng-container>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProjectSummaryReportModel } from '../project-summary-report.model';
import { PluginModel } from '../../../../plugins/plugin.model';
import { ProjectSummaryReportContext } from '../project-summary-report.context';
import { Observable } from 'rxjs';

@Component({
  selector: 'adm4-project-summary-report-tab-applications',
  template: `
    <div class='full-height-flex'>
      <adm4-project-summary-report-table [reportModel]='reportModel$ | async'
                                         [projectKey]='projectKey$ | async'
                                         [filterText]='searchTerm$ | async'
                                         [reportsNotFound]='reportsNotFound$ | async'
                                         [reportsError]='reportsError$ | async'
                                         [name]='"applications"'
                                         [quickLinkName]='"Application"'
                                         [plugins]='servicePlugins$ | async'></adm4-project-summary-report-table>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryReportTabApplicationsComponent {

  projectKey$: Observable<string | null>;
  reportModel$: Observable<ProjectSummaryReportModel | undefined>;
  servicePlugins$: Observable<PluginModel[]>;
  searchTerm$: Observable<string | undefined>;

  reportsNotFound$: Observable<boolean>;
  reportsError$: Observable<boolean>;

  constructor(private reportContext: ProjectSummaryReportContext) {
    this.projectKey$ = this.reportContext.projectKey$;
    this.reportModel$ = this.reportContext.applicationsReport$;
    this.servicePlugins$ = this.reportContext.servicePlugins$;
    this.searchTerm$ = this.reportContext.searchTerm$;

    this.reportsNotFound$ = this.reportContext.reportsNotFound$;
    this.reportsError$ = this.reportContext.reportsError$;
  }
}

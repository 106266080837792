import { HttpParams } from '@angular/common/http';
import { BaseService } from '../shared/base.service';
import { PatternInstance } from './pattern-instance.model';
import { forkJoin, Observable, of } from 'rxjs';
import { Pattern, PatternCopyTarget } from './pattern.model';
import { PatternType, Property } from '../plugins/pattern-type.model';
import { PatternId } from '../model/pattern';
import { Usage} from '../common/model/plugin-usage.model';
import { Dictionary, ProjectKey } from '../model/reducer';
import { PropertyType } from '../plugins/property-type.model';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { PropertyTypeEnum } from '../common/constants/app.constants';
import { mergeDictionaries } from '../common/utils/utils';
import { ResourceList } from './pattern-attachment.model';
import { Injectable } from '@angular/core';
import { CopyModel, PatchInstance } from './batch-actions/batch-action.model';
import { PatternViewResponse } from '../common/model/pattern-view.model';
import { AUTHENTICATION_FLOW_VIEW, GraphInfo } from './pattern-graph-info.model';

/**
 * This service contain all the methods needed to retrieve data about the pattern. It accesses the REST API
 */
@Injectable()
export class PatternService extends BaseService {

  getAllPatterns(projectKey: string): Observable<Pattern[]> {
    const url = `/projects/${projectKey}/patterns`;
    return this.httpGetUnwrapped(url);
  }

  getFilteredPatternsByClass(projectKey: string, filterClass: string): Observable<Pattern[]> {
    const url = `/projects/${projectKey}/patterns?filterClass=${filterClass}&sort=name`;
    return this.httpGetUnwrapped(url);
  }

  getPatternInstance(projectKey: string, patternIntanceId: string, meta?: boolean): Observable<PatternInstance> {
    const url = `/projects/${projectKey}/patterns/${patternIntanceId}`;
    let params: HttpParams = new HttpParams();
    if (meta) {
      params = params.set('meta', meta.toString());
    }
    return this.httpGet(url, undefined, params);
  }

  updatePatternInstance(pattern: PatternInstance, projectKey: string, patternInstanceId: string): Observable<PatternInstance> {
    const url = `/projects/${projectKey}/patterns/${patternInstanceId}`;
    return this.httpPut(url, pattern);
  }

  patchPatternInstances(patchList: PatchInstance[], projectKey: string) {
    const url = `/projects/${projectKey}/patterns`;
    return this.httpPatch(url, patchList);
  }

  createPatternInstance(pattern: PatternInstance, projectKey: string) {
    const url = `/projects/${projectKey}/patterns`;
    return this.httpPost(url, pattern);
  }

  duplicatePattern(projectKey: string, patternId: PatternId): Observable<PatternInstance> {
    const url = `/projects/${projectKey}/patterns/${patternId}/duplicate`;
    return this.httpPost(url, {});
  }

  copyPattern(projectKey: string, copyContext: CopyModel): Observable<any> {
    const url = `/projects/${projectKey}/copy-patterns`;
    return this.httpPost(url, copyContext);
  }

  getPatternTypes(projectKey: string): Observable<PatternType[]> {
    const url = `/projects/${projectKey}/pattern-types`;
    return this.httpGetUnwrapped(url);
  }

  deletePattern(projectKey: string, patternId: PatternId): Observable<any> {
    const url = `/projects/${projectKey}/patterns/${patternId}`;
    return this.httpDelete(url);
  }

  deletePatternList(projectKey: string, patternList: { items: string[] }): Observable<any> {
    const url = `/projects/${projectKey}/delete-patterns`;
    return this.httpPost(url, patternList);
  }

  getPatternUsage(projectKey: string, patternId: PatternId): Observable<Usage[]> {
    const url = `/projects/${projectKey}/patterns/${patternId}/usage`;
    return this.httpGetUnwrapped(url);
  }

  getPatternCopyTargets(projectKey: string, patternId: PatternId): Observable<PatternCopyTarget[]> {
    const url = `/projects/${projectKey}/copy-patterns/${patternId}`;
    return this.httpGetUnwrapped<PatternCopyTarget[]>(url);
  }

  getPatternView(projectKey: string, patternId: PatternId): Observable<PatternViewResponse> {
    const url = `/projects/${projectKey}/patterns/${patternId}/views`;
    return this.httpGet(url);
  }

  uploadPatternResource(projectKey: ProjectKey, patternId: string, propertyKey: string, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const url = `/projects/${projectKey}/patterns/${patternId}/resources/${propertyKey}`;
    return this.httpPutFormData(url, formData);
  }

  deletePatternResource(projectKey: ProjectKey, patternId: PatternId, propertyKey: string, filename: string): Observable<any> {
    const url = `/projects/${projectKey}/patterns/${patternId}/resources/${propertyKey}/content/${filename}`;
    return this.httpDelete(url);
  }

  // GET LIST OF RESOURCES  ¶
  getPatternResourceList(projectKey: ProjectKey, patternId: PatternId, propertyKey: string, meta = false): Observable<ResourceList> {
    const url = `/projects/${projectKey}/patterns/${patternId}/resources/${propertyKey}?meta=${meta}`;
    return this.httpGet(url);
  }

  getResourceContent(projectKey: ProjectKey, patternId: PatternId, propertyKey: string, fileName: string): Observable<Blob> {
    const url = `/projects/${projectKey}/patterns/${patternId}/resources/${propertyKey}/content/${fileName}`;
    return this.httpGetBlobDefault(url);
  }

  getHeadResourceContent(projectKey: ProjectKey, patternId: PatternId, propertyKey: string, fileName: string): Observable<Blob> {
    const url = `/projects/${projectKey}/patterns/${patternId}/resources/${propertyKey}/head/content/${fileName}`;
    return this.httpGetBlobDefault(url);
  }

  /**
   * Collects attachments of all patterns for provided sources
   * @param projectKey$
   * @param patterns$
   * @param patternTypes$
   * @param propertyTypes$
   */
  getResourcesOfPatterns(projectKey$: Observable<string | null>, patterns$: Observable<Pattern[]>, patternTypes$: Observable<Dictionary<PatternType> | null>, propertyTypes$: Observable<Dictionary<PropertyType> | null>): Observable<Dictionary<Dictionary<ResourceList>>> {
    return forkJoin([
      patterns$.pipe(first((patterns: Pattern[]) => !_.isEmpty(patterns))),
      projectKey$.pipe(first((projectKey: string | null) => !_.isNil(projectKey))),
      patternTypes$.pipe(first((patternTypes: Dictionary<PatternType> | null) => !_.isNil(patternTypes))),
      propertyTypes$.pipe(first((propertyTypes: Dictionary<PropertyType> | null) => !_.isNil(propertyTypes)))
    ]).pipe(
      switchMap(([patterns, projectKey, patternTypes, propertyTypes]: [Pattern[], string, Dictionary<PatternType>, Dictionary<PropertyType>]) => {
        const allPatternResourceSources$: Observable<Dictionary<Dictionary<ResourceList>>>[] = patterns.map((pattern: Pattern) => {
          const patternType: PatternType | undefined = patternTypes[pattern.className];
          if (_.isNil(patternType) || _.isNil(patternType.properties)) {
            return of({});
          }
          const patternResourceSources$: Observable<Dictionary<ResourceList>>[] = patternType.properties
            .filter((property: Property) => !_.isNil(propertyTypes[property.className]) && propertyTypes[property.className].uiComponent === PropertyTypeEnum.AttachmentPropertyComponent)
            .map((property: Property) => this.getPatternResourceList(projectKey, pattern.patternId, property.propertyKey, false).pipe(
                  map((resources: ResourceList) => {
                    return {[property.propertyKey]: resources};
                  })
            ));
          if (_.isEmpty(patternResourceSources$)) {
            return of({});
          }
          return forkJoin(patternResourceSources$).pipe(map((resourceLists: Dictionary<ResourceList>[]) => {
            const patternResourceLists: Dictionary<ResourceList> = mergeDictionaries(resourceLists);
            return {[pattern.patternId]: patternResourceLists};
          }));
        });
        return forkJoin(allPatternResourceSources$).pipe(map((allPatternResourceLists: Dictionary<Dictionary<ResourceList>>[]) => mergeDictionaries(allPatternResourceLists)));
      })
      , shareReplay(1)
    );
  }

  getAuthenticationFlowGraph(projectKey: ProjectKey): Observable<GraphInfo> {
    const url = `/projects/${projectKey}/graphs/${AUTHENTICATION_FLOW_VIEW}`;
    return this.httpGet(url);
  }
}

import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import {
  Certificate, CertificateWrapperWithUsage,
  InventoryResourceType, ResourceWrapperWithUsage, SecretResourceWrapperWithUsage, SecretWrapperWithUsage,
  TenantResourceFlag,
} from '../../../inventory/inventory.model';
import { InventoryResourceActionType, SecretManagementTableModel } from '../../secret-management/secret-management-table/secret-management-table.model';
import { InventoryResourceTypeHelper } from '../../secret-management/inventory-resource-type.helper';
import { PatternMasterListHelper } from '../../../patterns/pattern-master-list.helper';
import { InventoryResourceActionDialogService } from '../../secret-management/inventory-resource-action/inventory-resource-action-dialog.service';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SecretManagementHelper } from '../../secret-management/secret-management.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { InventoryService } from '../../../inventory/inventory.service';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { ToastNotificationService } from '../../../notification/toast-notification.service';
import { ErrorHelper } from '../../../common/helpers/error.helper';
import { NavigationService } from '../../../navbar/navigation.service';
import { InventoryResourceActionDialogHelper } from '../../secret-management/inventory-resource-action/inventory-resource-action-dialog.helper';
import { InventoryResourceContentActionDialogPayload, ReplaceInventoryResourceDialogPayload } from '../../secret-management/inventory-resource-action/inventory-resource-action-dialog-payload.model';
import { MatSort } from '@angular/material/sort';
import { InventoryResourceSortingHelper } from '../../secret-management/secret-management-table/inventory-resource-sorting.helper';
import { requireNonNull, reverseCompareResult } from '../../../common/utils/utils';
import { select, Store } from '@ngrx/store';
import { getInventoriesWithModifyInventoryAccessView, getInventoriesWithViewSecretContentAccessView, hasTenantModificationAccessView, hasViewSecretContentTenantAccessView } from '../../../model/views/permission.views';
import { AppState } from '../../../model/reducer';
import { CertificateManagementHelper } from './certificate-management.helper';

@Component({
  selector: 'adm4-certificate-management-table',
  templateUrl: './certificate-management-table.component.html',
  styleUrls: ['../../secret-management/secret-management-table/secret-management-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CertificateManagementTableComponent implements OnChanges, AfterViewInit {
  @Input() resourceGroup: (CertificateWrapperWithUsage & TenantResourceFlag)[];
  @Input() displayedColumns: SecretManagementTableModel[];
  @Input() filterText: string;
  @Input() selectedTenantKey: string;
  @Input() selectedScope: string;
  @Output() triggerResourceLoad: EventEmitter<void> = new EventEmitter();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  tableDataSource: MatTableDataSource<CertificateWrapperWithUsage & TenantResourceFlag> = new MatTableDataSource([]);

  hasViewSecretContentTenantLevelPermission$: Observable<boolean>;
  inventoriesWithViewSecretContentPermission$: Observable<string[]>;
  inventoriesWithModifyPermission$: Observable<string[]>;
  hasModifyTenantPermission$: Observable<boolean>;

  isExpandedUsage = false;
  readonly resourceTableColumns = SecretManagementTableModel;
  readonly inventoryResourceActionTypes = InventoryResourceActionType;

  constructor(private inventoryResourceActionDialogService: InventoryResourceActionDialogService,
              private inventoryService: InventoryService,
              private modalNotificationService: ModalNotificationService,
              private toastNotificationService: ToastNotificationService,
              private navigationService: NavigationService,
              private store$: Store<AppState>) {
    this.hasViewSecretContentTenantLevelPermission$ = this.store$.pipe(select(hasViewSecretContentTenantAccessView));
    this.inventoriesWithViewSecretContentPermission$ = this.store$.pipe(select(getInventoriesWithViewSecretContentAccessView));
    this.inventoriesWithModifyPermission$ = this.store$.pipe(select(getInventoriesWithModifyInventoryAccessView));
    this.hasModifyTenantPermission$ = this.store$.pipe(select(hasTenantModificationAccessView));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resourceGroup'] || changes['filterText']) {
      this.tableDataSource.data = this.getFilteredCertificates();
      setTimeout(() => this.setDefaultSorting());
    }
  }

  hasModifyPermissionByLevel(certificate: CertificateWrapperWithUsage & TenantResourceFlag): Observable<boolean> {
    if (certificate.isTenantScoped) return this.hasModifyTenantPermission$;
    return this.inventoriesWithModifyPermission$.pipe(map((inventories: string[]) => _.includes(inventories, certificate.scope)));
  }

  hasViewSecretContentPermissionByLevel(certificate: CertificateWrapperWithUsage & TenantResourceFlag): Observable<boolean> {
    if (certificate.isTenantScoped) return this.hasViewSecretContentTenantLevelPermission$;
    return this.inventoriesWithViewSecretContentPermission$.pipe(map((inventories: string[]) => _.includes(inventories, certificate.scope)));
  }

  ngAfterViewInit(): void {
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.sortData = this.resourceGroupSorting();
  }

  resourceGroupSorting(): (data: CertificateWrapperWithUsage[], sort: MatSort) => CertificateWrapperWithUsage[] {
    return (data:  CertificateWrapperWithUsage[], sort: MatSort) => {
      switch (sort.active) {
        case SecretManagementTableModel.SourceColumnName:
          return data.sort((res1: CertificateWrapperWithUsage, res2: CertificateWrapperWithUsage) => {
            const compareResult = InventoryResourceSortingHelper.byTenantScopeSortingFn(res1, res2);
            return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
          });
        case SecretManagementTableModel.ExpiryDateColumnName:
          return data.sort((res1: CertificateWrapperWithUsage, res2: CertificateWrapperWithUsage) => {
            const compareResult = InventoryResourceSortingHelper.byExpirationDateSortingFn(res1, res2);
            return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
          });
        default:
          return data;
      }
    };
  }

  setDefaultSorting(): void {
    if (!_.isNil(this.sort)) {
      this.sort.active = SecretManagementTableModel.ExpiryDateColumnName;
      this.sort.direction = 'asc';
      this.sort.sortChange.emit();
    }
  }

  private shouldBeFilteredBySearch(certificate: CertificateWrapperWithUsage & TenantResourceFlag, regExp: RegExp): boolean {
    if (_.isEmpty(this.filterText)) {
      return true;
    }
    const matchesId: boolean = !_.isNil(certificate) && !_.isEmpty(certificate.source.match(regExp));
    const matchesUsage: boolean = !_.isNil(certificate?.usedIn) && certificate?.usedIn?.some(usage => usage.match(regExp));
    const matchesDescription: boolean = !_.isNil(certificate.description) && !_.isEmpty(certificate.description.match(regExp));
    const matchesIssuer: boolean = !_.isNil(certificate.certificates) && certificate?.certificates?.some(cert => cert.issuer.match(regExp));
    const matchesSubject: boolean = !_.isNil(certificate.certificates) && certificate?.certificates?.some(cert => cert.subject.match(regExp));
    const matchesSerial: boolean = !_.isNil(certificate.certificates) && certificate?.certificates?.some(cert => cert.serial.toString().match(regExp));
    return matchesId || matchesUsage || matchesDescription || matchesIssuer || matchesSubject || matchesSerial;
  }

  private getFilteredCertificates(): (CertificateWrapperWithUsage & TenantResourceFlag)[] {
    const regExp = PatternMasterListHelper.getFilterRegexpByWord(this.filterText);
    return this.resourceGroup.filter(certificate => this.shouldBeFilteredBySearch(certificate, regExp));
  }

  openViewContentDialog(certificate: CertificateWrapperWithUsage & TenantResourceFlag, isReadOnly: boolean): void {
    const castedCertificate: (SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag = InventoryResourceTypeHelper.convertCertificateWithUsageToInventoryResource(certificate, requireNonNull(certificate.isTenantScoped));
    this.hasViewSecretContentPermissionByLevel(certificate).pipe(take(1)).subscribe((hasViewSecretPermission: boolean) => {
      const inventoryResourceActionDialogPayload: InventoryResourceContentActionDialogPayload = {
        inventoryResourceType: InventoryResourceTypeHelper.isSecretResourceType(castedCertificate) ? InventoryResourceType.SECRET_FILE : InventoryResourceType.FILE,
        isSecret: true,
        resourceItem: castedCertificate,
        hasViewPermission: hasViewSecretPermission,
        isReadOnly: isReadOnly,
        onSaveCallback: () => this.triggerResourceLoad.emit()
      };
      this.inventoryResourceActionDialogService.openViewResourceContentDialog(inventoryResourceActionDialogPayload);
    });
  }

  openReplaceContentDialog(certificate: CertificateWrapperWithUsage & TenantResourceFlag): void {
    const castedCertificate: (SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag = InventoryResourceTypeHelper.convertCertificateWithUsageToInventoryResource(certificate, requireNonNull(certificate.isTenantScoped));
    const replaceInventoryResourceDialogPayload: ReplaceInventoryResourceDialogPayload = {
      inventoryResourceType: InventoryResourceTypeHelper.isSecretResourceType(castedCertificate) ? InventoryResourceType.SECRET_FILE : InventoryResourceType.FILE,
      isSecret: true,
      resourceItem: castedCertificate,
      isCertificate: true,
      onSaveCallback: () => this.triggerResourceLoad.emit()
    };
    this.inventoryResourceActionDialogService.openReplaceResourceDialog(replaceInventoryResourceDialogPayload);
  }

  deleteCertificate(certificate: CertificateWrapperWithUsage & TenantResourceFlag): void {
    const castedCertificate: (SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag = InventoryResourceTypeHelper.convertCertificateWithUsageToInventoryResource(certificate, requireNonNull(certificate.isTenantScoped));
    this.modalNotificationService.openConfirmDialog({
      headerTitle: 'Warning',
      title: `Delete certificate`,
      description: this.getDescription(certificate.source, certificate.usedIn)
    }, {
      confirmButtonText: 'Delete'
    }).afterClosed().pipe(take(1), switchMap((confirmed?: boolean) => {
        if (confirmed) {
          return SecretManagementHelper.getInventoryResourceDeletionFunction(this.inventoryService, castedCertificate);
        }
        return of(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleResourceDeletionError(error);
        return of(undefined);
      })
    ).subscribe((secretResourceId?: string) => {
      if (secretResourceId) {
        this.toastNotificationService.showSuccessToast('The certificate has been successfully deleted', 'Successfully deleted');
        this.triggerResourceLoad.emit();
      }
    });
  }

  getDescription(sourceId: string, usages?: string[]): string {
    const listOfUsedInventories = usages ? InventoryResourceActionDialogHelper.getResourceUsageDescription(usages) : '';
    return `You are deleting certificate <em><b>${sourceId}</b></em>. The removal is irreversible.${listOfUsedInventories}`;
  }

  private handleResourceDeletionError(error: HttpErrorResponse): void {
    console.error(error);
    const errorMessage = ErrorHelper.getErrorDetail(error, 'Something went wrong during deleting the resource.');
    this.modalNotificationService.openErrorDialog({title: 'Error', description: errorMessage});
  }

  get hasDisplayableData(): boolean {
    return !_.isEmpty(this.tableDataSource.data);
  }

  isColorHighlighted(certificate: CertificateWrapperWithUsage, severity: string): boolean {
    return certificate.certificates?.some((cert: Certificate) => {
      return this.checkExpirationBySeverity(cert, severity);
    });
  }

  checkExpirationBySeverity(certificate: Certificate, severity: string): boolean {
    const daysTillExpiry = CertificateManagementHelper.countDaysTillCertificateExpiry(certificate);
    const dayLimitBySeverity = _.isEqual('error', severity) ? 30 : 60;
    return daysTillExpiry <= dayLimitBySeverity;
  }

  resolveExpirationIcon(certificate: Certificate): string | undefined {
    const isHighSeverityExpiration = this.checkExpirationBySeverity(certificate, 'error');
    if (isHighSeverityExpiration) return 'error';
    if (this.checkExpirationBySeverity(certificate, 'warning')) return 'warning_problem';
    return undefined;
  }

  toggleUsedInExpansion(toggleAll?: boolean, certificateItem?: any): void {
    let certificates = this.getFilteredCertificates();
    if (toggleAll === true) {
      this.isExpandedUsage = !this.isExpandedUsage;
      this.tableDataSource.data = certificates.map((certificate: CertificateWrapperWithUsage) => {
        certificate.isExpanded = this.isExpandedUsage;
        return certificate;
      });
    } else {
      const secretToToggleIndex = certificates.findIndex((certificate: CertificateWrapperWithUsage) => {
        return certificate.id === certificateItem.id;
      });
      certificates[secretToToggleIndex].isExpanded = !certificates[secretToToggleIndex].isExpanded;
      this.tableDataSource.data = certificates;
    }
    this.isExpandedUsage = this.tableDataSource.data.every((certificate: CertificateWrapperWithUsage) => {
      if (!certificate?.usedIn || certificate?.usedIn?.length <= 1) {
        return true;
      }
      return certificate.isExpanded === true;
    });
  }

  navigateToInventory(inventoryKey: string): void {
    this.navigationService.navigateToInventory(inventoryKey);
  }

  getResourceActionBtnTooltip(hasPermission: boolean, inventoryResourceActionType: InventoryResourceActionType): string {
    if (hasPermission) {
      return _.isEqual(inventoryResourceActionType, InventoryResourceActionType.Delete) ? inventoryResourceActionType : `${inventoryResourceActionType} content`;
    }
    return `You don’t have permission to ${inventoryResourceActionType.toLowerCase()}.`;
  }

  getExpirationTooltip(singleCertificate: Certificate): string {
    const daysTillExpiry = CertificateManagementHelper.countDaysTillCertificateExpiry(singleCertificate);
    switch (true) {
      case daysTillExpiry <= 0:
        return 'The certificate has expired. It needs to be urgently replaced.';
      case daysTillExpiry <= 30:
        return 'The certificate will expire soon. Please replace it as soon as possible.';
      case daysTillExpiry <= 60:
        return 'The certificate is going to expire. It needs to be urgently replaced.';
      default:
        return '';
    }
  }

  isUsedResource(resourceItem: ((SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag)): boolean {
    return !_.isNil(resourceItem.usedIn) && resourceItem.usedIn?.length > 0;
  }
}

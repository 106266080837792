import { combineLatest as observableCombineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ProjectIssuesDisplayContext } from '../../projects/project-issues-display.context';
import { IssueModel } from '../../common/model/issue.model';
import { PatternIssueData } from '../model/pattern-issue-data.model';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { IssueGroupFragmentHelper } from '../issue-group-fragment.helper';
import { VariableModel } from '../../variables/variable.model';

@Component({
  selector: 'adm4-issue-group-list',
  template: `
          <adm4-general-issue-list
                  *ngIf='shouldDisplayGeneralIssues$ | async'
                  [generalIssues]='generalIssues$ | async'
                  [selectedGroupId]='selectedGroupId$ | async'></adm4-general-issue-list>
          <adm4-pattern-issue-group-list
                  [patternIssueGroups]='patternIssueGroups$ | async'
                  [selectedGroupId]="selectedGroupId$ | async"
                  [projectKey]="projectKey$ | async"
                  [variables]='variables$ | async'
          ></adm4-pattern-issue-group-list>
    `,
  styleUrls: ['./issue-group-list.component.scss', '../issue-group-heading.scss'],
})
export class IssueGroupListComponent implements AfterViewInit, OnDestroy {
  generalIssues$: Observable<IssueModel[]>;
  patternIssueGroups$: Observable<PatternIssueData[]>;
  selectedGroupId$: Observable<string | undefined>;
  shouldDisplayGeneralIssues$: Observable<boolean>;
  projectKey$: Observable<string | null>;
  variables$: Observable<VariableModel[]>;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private issueGroupListContext: ProjectIssuesDisplayContext, private route: ActivatedRoute) {
    this.generalIssues$ = this.issueGroupListContext.generalIssues$;
    this.patternIssueGroups$ = this.issueGroupListContext.patternIssueGroups$;
    this.shouldDisplayGeneralIssues$ = this.issueGroupListContext.shouldDisplayGeneralIssues$;
    // route mapping is inside component because ActivatedRoute has different injector when is injected into service therefore service has no access to current route state
    this.selectedGroupId$ = this.route.fragment.pipe(map((fragment: string | null) => IssueGroupFragmentHelper.getGroupIdFromFragment(fragment)));
    this.projectKey$ = this.issueGroupListContext.projectKey$;
    this.variables$ = this.issueGroupListContext.variables$;
  }

  ngAfterViewInit(): void {
    // scroll to group as selected id changes, we listen to changest of general issues and pattern issues so that we make sure that we still scroll in case data is loaded later
    observableCombineLatest([this.selectedGroupId$, this.generalIssues$, this.patternIssueGroups$]).pipe(
      map(([selectedGroupId]) => selectedGroupId),
      filter((selectedGroupId: string | undefined) => !_.isNil(selectedGroupId)),
      map((selectedGroupId: string) =>  document.getElementById(selectedGroupId)),
      filter((element: HTMLElement | null) => !_.isNil(element)),
      takeUntil(this.destroyed$))
      .subscribe((element: HTMLElement) => {
        element.scrollIntoView();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

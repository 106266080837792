import { Observable, Subject } from 'rxjs';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { Project } from '../../../projects/project.model';
import { BatchActionDialogPayload } from '../batch-action-dialog.payload';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import { getProjectsWithModifyPermission } from '../../../model/views/permission.views';
import { patternsView, selectedPatternInstanceView, selectedProjectView } from '../../../model/views';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { Pattern } from '../../pattern.model';
import { PatternListData } from '../../pattern-list-data.model';
import * as _ from 'lodash';
import { filterEmpty, Maybe } from '../../../common/utils/utils';
import { PatternInstance } from '../../pattern-instance.model';
import { SelectPatternId } from '../../../model/pattern';

@Component({
  selector: 'adm4-batch-action-dialog-container',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title'
                             [header]='batchActionDialogPayload.header'
                             [isFullHeightContent]='true'
                             [showClose]='true'
                             (closeClicked)="handleDialogResult(false)">
      <adm4-batch-action [projects]='projectsExceptSelectedProject | async'
                         [projectKey]='batchActionDialogPayload.projectKey'
                         [selectedPatterns]='selectedPatterns$ | async'
                         (finished)='handleDialogResult($event)'>
      </adm4-batch-action>
    </adm4-modal-dialog-title>
  `,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
})
export class BatchActionDialogContainerComponent implements OnDestroy {

  projectsExceptSelectedProject: Observable<Project[]>;
  selectedPatterns$: Observable<PatternListData[]>;
  private destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public batchActionDialogPayload: BatchActionDialogPayload,
              public dialogRef: MatDialogRef<BatchActionDialogContainerComponent>,
              private store$: Store<AppState>) {
    closeModalOnEscape(dialogRef, this.destroyed$);
    const selectedProjectKey$ = this.store$.pipe(select(selectedProjectView), map(value => value?.projectKey));
    const projectsWithModifyPermission$ = this.store$.pipe(select(getProjectsWithModifyPermission));
    this.projectsExceptSelectedProject = projectsWithModifyPermission$.pipe(withLatestFrom(selectedProjectKey$),
      map(([projectList, projectKey]) => _.filter(projectList, (project) => {
        return project.projectKey !== projectKey;
      })));

    this.selectedPatterns$ = this.store$.pipe(select(patternsView)).pipe(map((allPattern: Map<string, Pattern>) => {
      Array.from(allPattern.values()).forEach((singlePattern: Pattern) => {
        const indexInBatchSelection = this.batchActionDialogPayload.selectedPatterns.findIndex((patternInSelection) => patternInSelection.pattern.patternId === singlePattern.patternId);
        if (indexInBatchSelection !== -1) {
          // update the batch selection every time when a pattern detail has been changed to always be up to date
          this.batchActionDialogPayload.selectedPatterns[indexInBatchSelection].pattern = singlePattern;
        }
      });
      return batchActionDialogPayload.selectedPatterns;
    }));
  }

  handleDialogResult(confirmed: Maybe<boolean>): void {
    if (confirmed) {
      this.store$.select(selectedPatternInstanceView).pipe(
        map((pattern: Maybe<PatternInstance>): Maybe<string> => pattern?.patternId),
        filter(filterEmpty),
        take(1),
      ).subscribe((patternId: string) => this.store$.dispatch(new SelectPatternId(patternId)));
    }
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

import { UserData, UserKeys, UserTypes } from '../model/user/user.model';
import * as _ from 'lodash';

export class UserHelper {

  /**
   * Encapsulates logic to properly format the name of the user for displaying in the application header.
   *
   * @param user user object or null.
   */
  static formatUserName(user: UserData | null): string {
    if (_.isNil(user)) {
      return '';
    }
    if (_.isNil(user.familyName) || _.isNil(user.givenName)) {
      return user.userKey;
    } else {
      const names: string[] = [];
      if (!_.isNil(user.givenName)) {
        names.push(user.givenName);
      }
      if (!_.isNil(user.familyName)) {
        names.push(user.familyName);
      }
      return names.join(' ');
    }
  }

  static canChangePassword(user: UserData | null): boolean {
    return !_.isNil(user) && user.type !== UserTypes.Ldap && user.type !== UserTypes.Saml;
  }

  static isAdminUser(user: UserData | null): boolean {
    return !_.isNil(user) && user.userKey === UserKeys.Admin;
  }

}

import { LocalStatus, MetaInfo } from '../../version-control/meta-info.model';
import * as _ from 'lodash';
import { PublishChangesetItem } from '../model/publish-changes/publish-changeset-item.model';

export class CommitMessageHelper {
  static createDefaultCommitMessage(changeset: PublishChangesetItem<MetaInfo, any>[]): string {
    const createdFileCount: number = this.countFilesByLocalStatus(changeset, LocalStatus.Added);
    const updatedFileCount: number = this.countFilesByLocalStatus(changeset, LocalStatus.Modified);
    const deletedFileCount: number = this.countFilesByLocalStatus(changeset, LocalStatus.Deleted);
    const defaultCommitMessageParts: string[] = [
      this.fillDefaultCommitMessagePart('added', createdFileCount),
      this.fillDefaultCommitMessagePart('updated', updatedFileCount),
      this.fillDefaultCommitMessagePart('deleted', deletedFileCount)
    ];
    return this.combineMessageParts(defaultCommitMessageParts);
  }

  private static countFilesByLocalStatus(changeset: PublishChangesetItem<MetaInfo, any>[], status: LocalStatus): number {
    return changeset.filter(changesetItem => changesetItem.metaData.localStatus === status).length;
  }

  private static fillDefaultCommitMessagePart(actionText: string, fileCount: number): string {
    if (fileCount <= 0) {
      return '';
    }

    const fileText: string = fileCount === 1 ? 'file' : 'files';

    return `${fileCount} ${fileText} ${actionText}`;
  }

  private static combineMessageParts(messageParts: string[]): string {
    return messageParts
      .filter(messagePart => !_.isEmpty(messagePart))
      .reduce((commitMessage: string, message: string, index: number, filteredMessageParts: string[]) => {
        if (index === 0) {
          return message;
        }
        return index === filteredMessageParts.length - 1 ? `${commitMessage} and ${message}` : `${commitMessage}, ${message}`;
      }, '');
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { IssueModel, IssueSeverity, SourceType, Target } from '../common/model/issue.model';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { isErrorIssue, isInfoIssue, isUnusedPattern, isWarningIssue } from '../projects/project-issues/project-issues.helper';

/**
 * This component is used as the content of the popover window. When the user hovers on the pattern error part
 * (left side, red bar), this content pops up
 */
@Component({
  selector: 'adm4-pattern-error-list-hover',
  template: `
            <div class='pe_error_container'>
              <div class='pe_title'>{{title}}</div>
              <div class='pe_error_list' *ngFor='let issue of displayableIssues'>
                <div class='pr_message'>
                  <span class='pr_error_box' [ngClass]='getIssueIndicatorStyle(issue)'></span>
                  <div class='pr_fix_this' *ngIf='!isUnusedPatternInfo(issue)'>
                    <a [href]='"#/projects/" + projectKey + "/patterns/" +
                     getTargetValue(issue.target, patternSourceType) +
                     "#" + getTargetValue(issue.target, propertySourceType)'>
                      Fix this
                    </a>
                  </div>
                  <div class='pr_error_message'>{{issue.message}}</div>
                </div>
              </div>
            </div>
            `,
  styleUrls: ['pattern-error-list-hover.component.scss']
})
export class PatternErrorListHoverComponent implements OnInit {
  /**
   * This is the base of the component's comntent
   */
  @Input() issues: IssueModel[];
  @Input() projectKey: string;
  public title: string;
  public patternSourceType = SourceType.PATTERN;
  public propertySourceType = SourceType.PROPERTY;
  public selectedPatternId: string;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  /**
   * It extracts the useful information from the 'validation' object
   */
  ngOnInit() {
    this.title = `${this.displayableIssues.length} tasks to complete`;
    this.selectedPatternId = this.route.snapshot.params['patternId'];
  }

  getTargetValue(targets: Target[], sourceType: SourceType): string {
    const target = _.find(targets, (t: Target) => t.sourceType === sourceType);
    return target ? target.value : '';
  }

  get displayableIssues(): IssueModel[] {
    return (this.issues??[]).filter((issue) => isErrorIssue(issue) || isWarningIssue(issue) || this.isUnusedPatternInfo(issue) || isInfoIssue(issue));
  }

  isUnusedPatternInfo(issue: IssueModel): boolean {
    return isUnusedPattern(issue);
  }

  getIssueIndicatorStyle(issue: IssueModel): string {
    switch (issue.severity) {
      case IssueSeverity.ERROR:
        return 'pr_val_error';
      case IssueSeverity.WARNING:
        return 'pr_val_warn';
      case IssueSeverity.INTO:
        return this.isUnusedPatternInfo(issue) ? 'pr_val_neutral' : 'pr_val_info';
      default:
        return '';
    }
  }
}

<div [formGroup]='form'>
  <label id='deployment-repo-label' class='input-label'>
    <span>Deployment repository</span>
    <span class='info-icon-container'>
        <i class="fa fa-info-circle help-icon" aria-hidden="true"
           title='This Git repository will contain generated configuration files for transfer to Kubernetes.'></i>
      </span>
  </label>
  <div class='slight-indentation'>
    <adm4-version-control-form (formReady)='addDeploymentRepositoryForm($event)'
                               [pathRequired]='false'
                               [pathVisible]='false'
                               [isDisabled]='isDisabled'></adm4-version-control-form>
  </div>
  <div class="input-label">Kubernetes cluster</div>
  <div class='form-group slight-indentation'>
    <label class='input-label' for='clusterUrl'>URL*</label>
    <div class="item-key-container">
      <div class='item-key-input-wrapper'>
          <input id='clusterUrl'
                 class="form-control admn4-text-input"
                 [formControlName]='K8S_CLUSTER_URL_FORM_CONTROL_NAME'
                 [name]='K8S_CLUSTER_URL_FORM_CONTROL_NAME'
                 [adm4ControlDisable]="isDisabled"
                 placeholder=' '/>
      </div>
    </div>
  </div>

  <div class='form-group slight-indentation'>
    <label class='input-label' for='k8sToken'>Token*</label>
    <div class='form-group admn4-input-group-with-icon'>
      <input id="k8sToken"
             [formControlName]='K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME'
             [name]='K8S_CLUSTER_TOKEN_FORM_CONTROL_NAME'
             class='admn4-text-input form-control'
             [class.errored]='hasError$ | async'
             placeholder='Please enter value'
             [type]="hideToken ? 'password' : 'text'"
             autocomplete="off"
             [adm4ControlDisable]="isDisabled">
      <button type='button'
              class="toggle-secret-icon admn4-button-ellipse-blue input-icon visibility-icon"
              matSuffix
              [disabled]='isDisabled'
              (click)='hideToken = !hideToken'>
        <mat-icon>{{hideToken ? 'visibility' : 'visibility_off'}}</mat-icon>
      </button>
    </div>
  </div>

  <div class='form-group slight-indentation namespace-container'>
    <label for='k8sNameSpace' class='input-label'>Namespace*</label>
    <div class='admn4-input-group'>
      <input class="admn4-text-input form-control" id='k8sNameSpace'
             [formControlName]='K8S_CLUSTER_NAMESPACE_FORM_CONTROL_NAME'
             [adm4ControlDisable]="isDisabled"
             placeholder=' '/>
    </div>
    <div class='validation-message-container'></div>
  </div>

</div>

import { Injectable } from '@angular/core';

import * as DOMPurify from 'dompurify';
import { Config } from 'dompurify';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Wraps `@cure53/DOMPurify`, so that in the tests of `CustomSanitizerService`, `DOMPurify` can be easily mocked.
 */
export class DomPurifyWrapper {
    public readonly domPurifyConfig: Config;

    constructor() {
        this.domPurifyConfig = {
            USE_PROFILES: {
                svg: false, svgFilters: false, html: true, mathMl: false
            },
            ADD_ATTR: ['target'],
        } as const;
    }

    sanitize = (dirtyHtml: string): string | undefined => DOMPurify.sanitize(dirtyHtml, this.domPurifyConfig);
    getRemoved = () => DOMPurify.removed;
}

/**
 * Sanitizes HTML to be safe to add to the DOM.<br/>
 * Uses `@cure53/DOMPurify`.
 */
@Injectable()
export class CustomSanitizerService {

    constructor(
        private domPurifyWrapper: DomPurifyWrapper,
        private defaultSanitizer: DomSanitizer,
    ) {}

    /**
     * Sanitizes HTML to be safe to be added to the DOM and wraps it into a SafeHtml,
     * so that consumers of the sanitization don't need to call the `bypassSecurity` methods.<br/>
     * Uses `@cure53/DOMPurify` for the sanitization.
     * @param dirtyHtml
     */
    public sanitizeAndTrust(dirtyHtml: string | undefined | null): SafeHtml {
        if (typeof dirtyHtml !== 'string') {
            // bypassing for a hardcoded empty string
            // eslint-disable-next-line ban/ban
            return this.defaultSanitizer.bypassSecurityTrustHtml('');
        }
        const clean: string = this.domPurifyWrapper.sanitize(dirtyHtml)??'';
        const removed: [] = this.domPurifyWrapper.getRemoved();
        if (typeof removed === 'object' && removed.length > 0) {
            console.warn(`⚠️⚠️⚠️ CustomSanitizerService#sanitize, unsafe content, some parts were sanitised away`, removed);
        }
        // bypassing security for the html that was just sanitized with `DomPurify`
        // eslint-disable-next-line ban/ban
        return this.defaultSanitizer.bypassSecurityTrustHtml(clean);
    }
}

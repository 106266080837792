import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DiffViewComponent } from '../../../common/model/publish-changes/diff-view-component.model';
import { PatternVersionInfo } from '../../../version-control/pattern-meta-info.model';
import { ProjectChangesetItem } from '../project-changeset-item.model';
import { PatternDiffViewContext } from './pattern-diff-view.context';
import { Observable } from 'rxjs';
import { DeploymentHostDiffData, PatternLabelDiffData, PatternLinkDiffData, PatternNameDiffData, PatternNotesDiffData, PropertyDiffData, SimplePatternDiffData } from './pattern-diff-data.model';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { VariableModel } from '../../../variables/variable.model';
import { UserStateService } from '../../../common/services/user/user.state.service';
import { LocalStatus } from '../../../version-control/meta-info.model';

@Component({
  selector: 'adm4-pattern-diff-view',
  templateUrl: './pattern-diff-view.component.html',
  styleUrls: ['./pattern-diff-view.component.scss', '../../../common/styles/component-specific/diff-view-header.scss', '../../../common/styles/component-specific/dummy-diff-space-taker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PatternDiffViewContext]
})
export class PatternDiffViewComponent implements DiffViewComponent<ProjectChangesetItem<PatternVersionInfo, PatternDiffViewComponent>>, OnInit {

  changesetItem: ProjectChangesetItem<PatternVersionInfo, PatternDiffViewComponent>;
  patternNameDiff$: Observable<Diff<PatternNameDiffData> | null>;
  patternLabelDiff$: Observable<Diff<PatternLabelDiffData> | null>;
  patternLinkDiff$: Observable<Diff<PatternLinkDiffData> | null>;
  simplePatternDiffData$: Observable<Diff<SimplePatternDiffData>[]>;
  deploymentHostDiffData$: Observable<Diff<DeploymentHostDiffData> | null>;
  patternNotesDiffData$: Observable<Diff<PatternNotesDiffData> | null>;
  propertyDiffs$: Observable<Diff<PropertyDiffData>[]>;
  projectKey$: Observable<string>;
  currentBranch$: Observable<string | null>;
  variables$: Observable<VariableModel[]>;
  showOnlyDifferences$: Observable<boolean>;
  currentUsername: string | null;

  constructor(private patternDiffViewContext: PatternDiffViewContext, private userStateService: UserStateService) {
    this.patternNameDiff$ = this.patternDiffViewContext.patternNameDiff$;
    this.patternLabelDiff$ = this.patternDiffViewContext.patternLabelDiff$;
    this.patternLinkDiff$ = this.patternDiffViewContext.patternLinkDiff$;
    this.simplePatternDiffData$ = this.patternDiffViewContext.simplePatternDiffData$;
    this.deploymentHostDiffData$ = this.patternDiffViewContext.deploymentHostDiffData$;
    this.patternNotesDiffData$ = this.patternDiffViewContext.patternNotesDiffData$;
    this.propertyDiffs$ = this.patternDiffViewContext.propertyDiffs$;
    this.projectKey$ = this.patternDiffViewContext.projectKey$;
    this.currentBranch$ = this.patternDiffViewContext.currentBranch$;
    this.variables$ = this.patternDiffViewContext.variables$;
    this.showOnlyDifferences$ = this.patternDiffViewContext.showOnlyDifferences$;
    this.currentUsername = this.userStateService.username;
  }

  ngOnInit(): void {
    this.patternDiffViewContext.selectPatternId(this.changesetItem.key);
  }

  rememberShowDifferencesView(showDiffOnly: boolean): void {
    this.patternDiffViewContext.rememberShowDifferencesView(showDiffOnly);
  }

  get isDeletedPattern(): boolean {
    return this.changesetItem.metaData.localStatus === LocalStatus.Deleted;
  }

}

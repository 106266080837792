import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, of, Subject } from 'rxjs';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { ReplaceInventoryResourceDialogPayload } from './inventory-resource-action-dialog-payload.model';
import { ResourceWrapperWithUsage, SecretResourceWrapperWithUsage, SecretWrapperWithUsage, TenantResourceFlag } from '../../../inventory/inventory.model';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { InventoryService } from '../../../inventory/inventory.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHelper } from '../../../common/helpers/error.helper';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { ToastNotificationService } from '../../../notification/toast-notification.service';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { InventoryResourceTypeHelper } from '../inventory-resource-type.helper';
import { SecretManagementHelper } from '../secret-management.helper';
import { FileService } from '../../../common/services/file/file.service';
import { FileDownloader } from '../../../common/helpers/file-downloader';
import { YamlCertificateHelper } from '../../../inventory/inventory-editor/yaml-certificate.helper';
import { isASCIIContent, isBinaryByExtension, readTextFile, renameFile } from '../../../file-utils';
import { resetTextareaToTop } from '../../../common/utils/dom.utils';

@Component({
  selector: 'adm4-replace-inventory-resource-dialog',
  template: `
    <adm4-modal-dialog-title class='modal-dialog-title'
                             [header]='header'
                             [showClose]='true'
                             [isFullHeightContent]='true'
                             (closeClicked)="closeDialog()">
      <div class='full-height-flex'>
        <div class='secret-content-wrapper content-container'>
          <div class='download-row'>
            <button class='file-download-button' (click)="downloadFile()">
              <mat-icon>save_alt</mat-icon>
              Download file
            </button>
          </div>
          <form [formGroup]="form">
            <ng-container *ngIf="!isLoading; else loadingIndicator">
              <textarea class='form-control admn4-textarea-input file-content-textarea code' #textAreaRef
                        placeholder="You can add file content here"
                        *ngIf="!isBinary; else canNotDisplayMsg"
                        [formControlName]='FILE_CONTENT_FORM_CONTROL_NAME'
              ></textarea>
              <ng-template #canNotDisplayMsg>
                <div class="binary-file-placeholder">
                  <p>The content cannot be displayed.<br/>You can download the file.</p>
                </div>
              </ng-template>
            </ng-container>

            <ng-template #loadingIndicator>
              <div class="binary-file-placeholder">
                <p>Loading file...</p>
              </div>
            </ng-template>

            <div class="upload-new-content-row">
              <div *ngIf="showInfoMessage" class='info-container'>
                <i class="fa fa-info-circle help-icon"></i>
                <div>New content has been uploaded from <b><i>{{ uploadedFileName }}</i></b>.
                  Only the file content will be replaced, file name will remain unchanged.
                </div>
              </div>
              <div *ngIf="showEncodingWarning" class='encoding-warning info-container'>
                <i class="fa fa-info-circle help-icon"></i>
                <span>The edited content will be saved with <code>utf-8</code> encoding.</span>
              </div>
              <div>
                <button class='admn4-button-ellipse-blue'
                        adm4FileLoader [multiFile]='false' (load)='setNewFileContent($event)'>
                  Upload new content
                </button>
              </div>
            </div>
            <label class='input-label'>Description (optional)</label>
            <textarea class='form-control admn4-textarea-input description-textarea'
                      [placeholder]="'Add your description (max 150 characters)'"
                      maxlength='150'
                      [formControlName]='DESCRIPTION_FORM_CONTROL_NAME'></textarea>
          </form>
        </div>
        <mat-dialog-actions>
          <button class='admn4-button-text' (click)='closeDialog()'>Cancel</button>
          <button class="admn4-button-ellipse-blue" [disabled]='shouldDisableSaveBtn()' (click)='onSaveClicked()'>Save
          </button>
        </mat-dialog-actions>
      </div>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['../../../common/styles/component-specific/modal-window.scss', './inventory-resource-action.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReplaceInventoryResourceDialogComponent implements OnDestroy {

  @ViewChild('textAreaRef', {static: false, read: ElementRef})
  textArea: ElementRef<HTMLElement>;

  header: string;
  readonly resourceItem: ((SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag);
  inventoryResourceType: string;

  public form: UntypedFormGroup;
  public FILE_CONTENT_FORM_CONTROL_NAME = 'resourceText';
  public DESCRIPTION_FORM_CONTROL_NAME = 'description';
  public showEncodingWarning = false;
  public showInfoMessage = false;
  private readonly originalFileName: string;
  /** Stored so that we can inform the user that the uploaded file's name will not be used. */
  public uploadedFileName: string;
  public isLoading = true;
  public isBinary = false;
  private binaryFile: File | undefined;

  private destroyed$: Subject<boolean> = new Subject();

  /**
   * The dirtiness of this form control is used to signal that a file was uploaded,
   * even when the file is binary and the content is actually stored in the field `binaryFile`.
   * @private
   */
  private readonly fileContentControl: AbstractControl;

  constructor(@Inject(MAT_DIALOG_DATA) public payload: ReplaceInventoryResourceDialogPayload,
              private dialogRef: MatDialogRef<ReplaceInventoryResourceDialogComponent>,
              private inventoryService: InventoryService,
              private fileService: FileService,
              private fb: UntypedFormBuilder,
              private modalNotificationService: ModalNotificationService,
              private toastNotificationService: ToastNotificationService,
              private cdr: ChangeDetectorRef,
  ) {
    this.resourceItem = this.payload.resourceItem;
    this.header = this.createHeaderText();

    this.form = this.fb.group({});
    this.form.addControl(this.FILE_CONTENT_FORM_CONTROL_NAME, new UntypedFormControl('', null));
    this.form.addControl(this.DESCRIPTION_FORM_CONTROL_NAME, new UntypedFormControl(this.resourceItem?.description, null));
    this.fileContentControl = this.form.controls[this.FILE_CONTENT_FORM_CONTROL_NAME];

    if ('secretResourceName' in this.resourceItem) {
      this.originalFileName = this.resourceItem.secretResourceName;
    } else if ('resourceName' in this.resourceItem) {
      this.originalFileName = this.resourceItem.resourceName;
    }

    this.fetchResourceContentBasedOnType();
    this.inventoryResourceType = this.payload.inventoryResourceType;
    closeModalOnEscape(this.dialogRef, this.destroyed$);

    this.fileContentControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      // when typing into the textarea, set the state to manually edited mode
      this.showInfoMessage = false;
      this.showEncodingWarning = true;
      this.isBinary = false;
      this.binaryFile = undefined;
    });
  }

  private createHeaderText(): string {
    const resourceItemId = InventoryResourceTypeHelper.getTypeSpecificIdWithName(this.resourceItem);
    return `Change content of ${resourceItemId}`;
  }

  private fetchResourceContentBasedOnType(): void {
    SecretManagementHelper.getResourceFileContent(this.fileService, this.resourceItem).pipe(
      map((resourceContent: string) => {
        this.isLoading = false;
        this.handleFileContent(resourceContent, undefined, true);
      }),
      catchError(async (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.closeDialog();
        const extractedErrorFromBlobResponse = await (new Response(error.error)).json();
        this.handleResourceActionError(error, extractedErrorFromBlobResponse.error.detail);
      })
    ).subscribe();
  }

  downloadFile(): void {
    const targetFileUrl = SecretManagementHelper.getResourceContentUrlByType(this.resourceItem);
    this.fileService.loadFile(<string>(targetFileUrl))
      .subscribe((blob: Blob) => {
        FileDownloader.downloadFile(blob, this.originalFileName);
      });
  }

  setNewFileContent($event: FileList): void {
    if ($event && $event.length > 0) {
      const selectedFile: File =$event[0];
      this.uploadedFileName = selectedFile.name;

      if (!this.payload.isCertificate) {
        this.handleNewFileContent(selectedFile);
      } else if (this.payload.isCertificate && this.payload.isSecret) {
        YamlCertificateHelper.validateCertificate(selectedFile).pipe(
          map((validCertificate) => {
            if (validCertificate) {
              this.handleNewFileContent(selectedFile);
            } else {
              this.modalNotificationService.openErrorDialog({
                title: 'Uploading certificate failed',
                description: 'Certificate is invalid or was not found. Please fix and upload again.',
              });
            }
          }),
          catchError((error: Error) => {
            this.modalNotificationService.openErrorDialog({
              title: 'Uploading certificate failed ',
              description: error.message
            });
            return of(undefined);
          })
        ).subscribe();
      }
    }
  }

  private handleNewFileContent(file: File): void {
    if (isBinaryByExtension(file.name)) {
      // if the file is obviously binary, we don't parse it to string
      this.fileContentControl.setValue('');
      // not initial as we are handling a newly uploaded file
      this.markFileDirtyIfInitial(false);
      this.showEncodingWarning = false;
      this.isBinary = true;
      this.binaryFile = file;
      this.cdr.markForCheck();
      return;
    }
    this.isLoading = true;
    readTextFile(file).subscribe(
      resourceContent => {
        this.isLoading = false;
        this.handleFileContent(resourceContent, file, false);
      },
      _error => this.isLoading = false,
    );
  }

  private handleFileContent(fileTextContent: string, file?: File, initial: boolean = true) {
    const setValueOpts = {emitEvent: !initial};
    if (!isASCIIContent(fileTextContent)) {
      this.fileContentControl.setValue('', setValueOpts);
      this.fileContentControl.markAsPristine();
      this.fileContentControl.markAsUntouched();
      this.isBinary = true;
      this.binaryFile = file;
    } else {
      this.fileContentControl.setValue(fileTextContent, setValueOpts);
      this.isBinary = false;
      this.activateTextArea();
    }
    this.markFileDirtyIfInitial(initial);
    this.showEncodingWarning = false;
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  private markFileDirtyIfInitial(initial: boolean = false) {
    if (!initial) {
      this.fileContentControl.markAsDirty();
      this.showInfoMessage = true;
    }
  }

  onSaveClicked(): void {
    if (!this.fileContentControl.pristine || this.fileContentControl.dirty) {
      this.replaceInventoryResource().pipe(take(1)).subscribe();
    } else {
      this.patchInventoryResource().pipe(take(1)).subscribe();
    }
  }

  replaceInventoryResource(): Observable<boolean> {
    let fileToSave: File;
    if (this.isBinary && this.binaryFile) {
      fileToSave =  renameFile(this.binaryFile, this.originalFileName);
    } else {
      fileToSave = new File([this.fileContentControl.value], this.originalFileName);
    }
    const operation: Observable<void> = SecretManagementHelper.getInventoryResourceReplacementFunction(
        this.inventoryService,
        this.resourceItem,
        fileToSave,
        this.form.controls[this.DESCRIPTION_FORM_CONTROL_NAME].value);
    return this.modifyInventoryResource(operation);
  }

  patchInventoryResource(): Observable<boolean> {
    const operation: Observable<void> = SecretManagementHelper.getInventoryResourceDescriptionChangeFunction(
        this.inventoryService,
        this.resourceItem,
        this.form.controls[this.DESCRIPTION_FORM_CONTROL_NAME].value);
    return this.modifyInventoryResource(operation);
  }

  private modifyInventoryResource(operation: Observable<void>): Observable<boolean> {
    return operation.pipe(map(() => {
      this.toastNotificationService.showSuccessToast(`The ${this.inventoryResourceType.toLowerCase()} has been successfully changed`, 'Successfully changed');
      this.payload.onSaveCallback();
      this.closeDialog();
      return true;
    }), catchError((error: HttpErrorResponse) => {
      if (ErrorHelper.responseErrorHasDetail(error)) {
        this.modalNotificationService.openErrorDialog({
          title: `Unable to change your ${this.inventoryResourceType.toLowerCase()}`,
          description: ErrorHelper.getErrorDetail(error, `Something went wrong during changing your ${this.inventoryResourceType.toLowerCase()}.`)
        });
        this.closeDialog();
      }
      return of(false);
    }));
  }

  private handleResourceActionError(error: HttpErrorResponse, defaultErrorMsg: string): void {
    const errorMessage = ErrorHelper.getErrorDetail(error, defaultErrorMsg);
    this.modalNotificationService.openErrorDialog({title: 'Error', description: errorMessage});
  }

  private activateTextArea() {
    setTimeout(() => resetTextareaToTop(this.textArea), 0);
  }

  shouldDisableSaveBtn(): boolean {
    return this.form.pristine && !this.form.dirty && !this.showInfoMessage;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

import { Component, Inject, Input, Optional } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { HelpConnectService } from '../../shared/scroll-helper/help-connect.service';
import { Observable, of } from 'rxjs';
import { IssueModel } from '../../common/model/issue.model';
import { shouldDisplayValidationPendingMessage } from '../validation-pending.helper';
import { getHighestSeverityIssueCssClass } from '../../projects/project-issues/project-issues.helper';
import * as _ from 'lodash';
import { ScrollToHelpMixin } from '../../shared/scroll-helper/scroll-to-help.mixin';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { projectValidatingView } from '../../model/views';
import { DEPLOYMENT_HOST_LABEL } from './deployment-host-widget.constants';

@Component({
  selector: 'adm4-deployment-host',
  template: `
    <ng-container *ngIf="form" [formGroup]='form'>
      <div class='pr_container line-indicatable-validated'
           [ngClass]='highestSeverityIssueClass'>
        <div class='form-group'
             [ngClass]='highestSeverityIssueClass'>
          <div class='pr-heading'>
            <label class='pr-label' for='deploymentHostsId'>{{label}}*</label>
            <i class="fa fa-question-circle help-icon-with-action" aria-hidden="true"
               [class.active]='isDeploymentHostHelpActive$ | async'
               (click)='scrollToHelp(helpClass)'></i>
          </div>
          <div class='input-field-container'>
            <input id='deploymentHostsId' class="form-control admn4-text-input"
                   placeholder=' '
                   [formControlName]="fcName"
                   [readonly]='readOnly'
                   (blur)='trimWhiteSpace($event)'/>
          </div>
          <adm4-property-issues *ngIf='hasIssues && !(isValidationInProgress$ | async)' [issues]='issues'
                                [shouldShowValidationRequiredMessage]='displayValidationPendingMessage'
          ></adm4-property-issues>
        </div>
      </div>
    </ng-container>
  `,
  styleUrls: ['../widget.component.scss']
})
@Mixin([ScrollToHelpMixin])
export class DeploymentHostWidgetComponent implements ScrollToHelpMixin {
  @Input() form: UntypedFormGroup;
  @Input() fcName: string;
  @Input() readOnly: boolean;
  @Input() issues: IssueModel[];

  isValidationInProgress$: Observable<boolean>;
  /** CSS class for deployment host help */
  readonly helpClass = 'sect-deploymentHosts';
  readonly label = DEPLOYMENT_HOST_LABEL;
  isDeploymentHostHelpActive$: Observable<boolean>;

  constructor(
    private store$: Store<AppState>,
    // help should connect to properties on pattern editor, therefore if properties are reused in other places this will not be provided
    @Inject(HelpConnectService) @Optional() public helpConnectService: HelpConnectService | null) {
    this.isValidationInProgress$ = this.store$.pipe(select(projectValidatingView));
    this.isDeploymentHostHelpActive$ = _.isNil(helpConnectService) ? of(false) : helpConnectService.isHelpDisplayedForProperty(this.helpClass);
  }

  scrollToHelp(_htmlClass: string): void {/* implemented by mixin ScrollToHelpMixin */}

  get highestSeverityIssueClass() {
    return getHighestSeverityIssueCssClass(this.issues);
  }

  get displayValidationPendingMessage(): boolean {
    const formControl = this.getFormControl();
    return shouldDisplayValidationPendingMessage(formControl);
  }

  get hasIssues(): boolean {
    return !_.isEmpty(this.issues);
  }

  private getFormControl(): AbstractControl {
    return this.form.controls[this.fcName];
  }

  trimWhiteSpace(event) {
    if (this.readOnly) {
      return;
    }
    this.form.controls[this.fcName].setValue(_.trim(event.target.value));
  }
}

import { NgModule } from '@angular/core';
import { WelcomeComponent } from './welcome.component';
import { CommonModules } from '../common/common.module';
import { WelcomeHeaderComponent } from './welcome-header/welcome-header.component';
import { WelcomeContentComponent } from './welcome-content/welcome-content.component';
import { WelcomeInventoryComponent } from './welcome-content/welcome-inventory.component';

@NgModule({
  imports: [
    CommonModules
  ],
  declarations: [
    WelcomeComponent,
    WelcomeHeaderComponent,
    WelcomeContentComponent,
    WelcomeInventoryComponent
  ],
  exports: [
    WelcomeComponent
  ]
})
export class WelcomeModule { }

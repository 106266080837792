import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Inventory } from '../inventory.model';
import { CreateInventory } from '../../model/inventory';
import { allInventoriesView, selectedTenantKeyView, shouldDisplayTenantsView } from '../../model/views';
import { CreateInventoryPayload } from './create-inventory-payload';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';

@Component({
  selector: 'adm4-create-inventory-container',
  template: `
    <adm4-modal-dialog-title [header]='header'
                             [showClose]='true'
                             (closeClicked)="dialogRef.close()">
      <adm4-create-inventory [inventories]='inventories$ | async'
                             [predefinedInventoryData]='predefinedInventoryData'
                             [selectedTenantKey]='selectedTenantKey$ | async'
                             [displayTenant]='displayTenant$ | async'
                             (addInventory)="onAddInventory($event)"
                             (closeClicked)='closeDialog()'></adm4-create-inventory>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['./create-inventory.component.scss']
})
export class CreateInventoryContainerComponent implements OnDestroy {
  header = 'Create Inventory';

  inventories$: Observable<Inventory[]>;
  displayTenant$: Observable<boolean>;
  selectedTenantKey$: Observable<string>;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(public dialogRef: MatDialogRef<CreateInventoryContainerComponent>,
              @Inject(MAT_DIALOG_DATA) public predefinedInventoryData: Inventory | null,
              private store$: Store<AppState>) {

    closeModalOnEscape(this.dialogRef, this.destroyed$);

    this.inventories$ = this.store$.pipe(select(allInventoriesView)).pipe(map(_.values));
    this.displayTenant$ = this.store$.pipe(select(shouldDisplayTenantsView));
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;
  }

  onAddInventory(createInventoryPayload: CreateInventoryPayload) {
    this.store$.dispatch(new CreateInventory(createInventoryPayload));
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

import { EventEmitter } from '@angular/core';
import { LocalStorageHelper } from '../helpers/local-storage.helper';
import * as _ from 'lodash';

export interface ICollapsiblePaneMixin {
  isCollapsed?: boolean;
  collapse: EventEmitter<boolean>;
  collapseLocalStorageKey?: string;

  setCollapse: (isCollapsed: boolean) => void;
  getStoredCollapsibleState?: () => boolean;
}

/**
 * Provides functionality for collapsing and expanding splitpanes, has an option to save it in LocalStorage if key was specified
 */
export class CollapsiblePaneMixin implements ICollapsiblePaneMixin {
  isCollapsed: boolean;
  collapseLocalStorageKey?: string;
  collapse: EventEmitter<boolean>;

  setCollapse(isCollapsed: boolean): void {
    this.isCollapsed = isCollapsed;
    if (!_.isNil(this.collapseLocalStorageKey)) {
      LocalStorageHelper.save(this.collapseLocalStorageKey, JSON.stringify(isCollapsed));
    }
    this.collapse.emit(isCollapsed);
  }

  getStoredCollapsibleState(): boolean {
    if (_.isNil(this.collapseLocalStorageKey)) {
      return false;
    }
    try {
      const storedIsCollapsible: string | null = LocalStorageHelper.retrieve(this.collapseLocalStorageKey);
      return !_.isNil(storedIsCollapsible) && JSON.parse(storedIsCollapsible);
    } catch (e) {
      return false;
    }
  }
}

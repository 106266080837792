import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { InventoryMainComponent } from './inventory-main/inventory-main.component';
import { ModalNotificationService } from '../notification/modal-notification.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const CONFIRM_UNSAVED_CHANGES = 'Do you want to discard changes?';

@Injectable()
export class InventoryEditorDeactivationGuard  {

  constructor(public modalNotificationService: ModalNotificationService) {}

  canDeactivate(component: InventoryMainComponent,
                _currentRoute: ActivatedRouteSnapshot,
                _currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): boolean | Observable<boolean> {

    if (component.hasUnsavedChanges && (nextState && !nextState.root.queryParams['propertyKey'])) {
      return this.modalNotificationService.openConfirmDialog({
        headerTitle: `Warning`,
        title: 'Unsaved changes',
        description: CONFIRM_UNSAVED_CHANGES
      }, {
        confirmButtonText: 'Discard changes'
      }).afterClosed().pipe(
        map((confirmed?: boolean) => {
          if (!confirmed) {
            return false;
          }
          component.hasUnsavedChanges = false;
          return true;
        }));
    }
    return true;
  }
}

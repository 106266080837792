import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

/**
 * Allows adding specific control to specified events, for example when we want to preventDefault or stopPropagation
 * It just makes it simple when directive that uses this mixin is implemented for that purpose
 */
export interface IEventControlMixin {
  /**
   * Subscribes to provided events on the element and calls onEvent whenever event fires
   * @param element - element to which listeners for events will be attached
   * @param events - list of event names that should be listened
   *                 supports keyboard events with specific keys with format event.key, where key is key name
   *                 Example params: ["click", "keydown.enter", "mousemove"]
   * @param onEvent - function that will be called when an event is fired
   * @returns Subscription[] - list of subscriptions to events, which allow cleanup when listening to events is not needed anymore
   */
  controlEvents: (element: HTMLElement, events: string[], onEvent: (event: Event) => void) => Subscription[];
}

interface EventData {
  eventName: string;
  /**
   * Keyboard key name that event should contain
   */
  keyName?: string;
}

const multiPartEventRegExp = new RegExp('^([a-z]+)(?:\\.([a-z]+))?$');

function getEventData(eventKey: string): EventData {
  const matches: RegExpMatchArray | null = multiPartEventRegExp.exec(eventKey);
  if (_.isNil(matches)) {
    throw new Error(`Cannot parse event string: ${eventKey}`);
  }
  return {
    eventName: matches[1],
    keyName: matches[2]
  };
}

export class EventControlMixin implements IEventControlMixin {
  controlEvents(element: HTMLElement, events: string[], onEvent: (event: Event) => void): Subscription[] {
    return events
      .map((eventKey: string) => getEventData(eventKey))
      .map((eventData: EventData) => {
        return fromEvent(element, eventData.eventName)
          .pipe(filter((event: any) => _.isNil(eventData.keyName) || (!_.isNil(event.key) && event.key.toLowerCase() === eventData.keyName)))
          .subscribe((event: Event) => onEvent(event));
      });
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Project } from '../../../projects/project.model';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { DeployToClassicOptionType, DeployToOption } from '../deploy-to-option.model';
import { LocalStorageHelper } from '../../../common/helpers/local-storage.helper';
import { localStorageDeployToInstancePatterns } from '../../../common/constants/local-storage-keys.constants';
import { MatSelect } from '@angular/material/select';
import { Dictionary } from '../../../model/reducer';
import { DeploymentTargetInstanceHelper } from './deployment-target-instance.helper';
import { Maybe } from '../../../common/utils/utils';

@Component({
  selector: 'adm4-deployment-target-instance-selection',
  template: `
    <mat-form-field *ngIf='shouldDisplayMultiInstanceDropdown'
                    class='instance-select-wrapper'
                    [ngbTooltip]='instanceSelectionPopover' placement='top'>
      <mat-select [value]="selectedDeployToOption"
                  [disableOptionCentering]='true'
                  [placeholder]="selectionLabel"
                  #instancePatternSelection
                  (click)='focusDropdownInput()'
                  (openedChange)='handleEmptySelectionDropdown($event)'>
        <adm4-searchable-dropdown-input *ngIf='instancePatternSelection.focused'
                                        [sourceItems]="classicDeployPatterns"
                                        [placeholder]="'Search and select instance patterns'"
                                        [searchableFormatFn]='searchableInventoryFormatFn'
                                        [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                        (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
        <mat-option [value]='allHostsOption'
                    (click)='onSelectionOptionClick(allHostsOption)'>{{allHostsOption.name}}</mat-option>
        <mat-option *ngIf='hasPreSelectedPatternInstance()'
                    [value]='lastSelectedInstancePatternsOption'
                    (click)='onSelectionOptionClick(lastSelectedInstancePatternsOption)'>{{lastSelectedInstancePatternsOption.name}}</mat-option>
        <mat-option (click)='clearSelectedItems()'>Clear selected items</mat-option>
        <mat-option *ngFor='let patternInstance of filteredInstancePatternList'
                    [value]='patternInstance'
                    (click)='onCheckBoxClick(patternInstance)'>
          <mat-checkbox class='instance-checkbox'
                        [checked]='isPartOfMultiPatternInstanceSelection(patternInstance)'></mat-checkbox>
          {{patternInstance.name}}
        </mat-option>
      </mat-select>
      <ng-template #instanceSelectionPopover>{{selectionLabel}}</ng-template>
    </mat-form-field>
  `,
  styleUrls: ['./deployment-target-instance-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentTargetInstanceSelectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() project: Project;
  @Input() preSelectedProjectKey: Maybe<string>;
  @Input() isClassicDeployment: boolean;
  @Input() classicDeployPatterns: DeployToOption[];
  @Output() instancePatternSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('instancePatternSelection') instancePatternSelectionRef: MatSelect;
  private destroyed$: Subject<boolean> = new Subject();

  selectedInstancePatternList: DeployToOption[];
  selectedDeployToOption: DeployToOption;
  get selectionLabel(): string {
    return this.selectedDeployToOption.name;
  }
  filteredInstancePatternList: DeployToOption[];
  allHostsOption: DeployToOption;
  lastSelectedInstancePatternsOption: DeployToOption;
  storedLastDeployedPatternInstanceConfig: Dictionary<string> | undefined;

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  ngOnInit(): void {
    this.storedLastDeployedPatternInstanceConfig = this.getStoredInstancePatternsConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.classicDeployPatterns || changes.isClassicDeployment) && this.isClassicDeployment) {
      this.allHostsOption = DeploymentTargetInstanceHelper.getAllHostPatternInstanceOption();
      this.lastSelectedInstancePatternsOption = DeploymentTargetInstanceHelper.getLastSelectedPatternInstanceOption(this.getStoredInstancePatternsHostExpression());
      this.selectedInstancePatternList = this.hasPreSelectedPatternInstance() ? this.getStoredInstancePatternList() : Object.assign([], this.classicDeployPatterns);
      this.selectedDeployToOption = _.isEmpty(this.selectedInstancePatternList) ? this.allHostsOption : this.getSelectedDeployToOption();
      this.instancePatternSelected.emit(this.selectedDeployToOption);
    }
  }

  get shouldDisplayMultiInstanceDropdown(): boolean {
    return this.isClassicDeployment && !_.isEmpty(this.classicDeployPatterns) && !_.isNil(this.selectedDeployToOption);
  }

  searchableInventoryFormatFn = (patternInstance: DeployToOption): string => {
    return patternInstance.name;
  };

  updateSearchResult(filteredList: DeployToOption[]): void {
    this.filteredInstancePatternList = filteredList;
  }

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }

  onCheckBoxClick(patternInstanceOption): void {
    // this reopens the select, ie. it stays open when an item is selected
    this.instancePatternSelectionRef.open();
    const isCheckedInstance = _.includes(this.selectedInstancePatternList, patternInstanceOption);
    this.onCheckBoxChange(!isCheckedInstance, patternInstanceOption);
  }

  private getStoredInstancePatternList(): DeployToOption[] {
    const storedInstancePatternsHostExpression = this.getStoredInstancePatternsHostExpression();
    if (_.isNil(storedInstancePatternsHostExpression)) return [];
    const preSelectedInstancePatterns: string[] = _.split(storedInstancePatternsHostExpression, ',');
    return _.filter(this.classicDeployPatterns, instancePattern => _.includes(preSelectedInstancePatterns, instancePattern.hostExpression));
  }

  getStoredInstancePatternsConfig(): Dictionary<string> | undefined {
    const retrieve = LocalStorageHelper.retrieve(localStorageDeployToInstancePatterns);
    return (_.isNil(retrieve) || _.isNil(this.preSelectedProjectKey)) ? undefined : JSON.parse(retrieve);
  }

  getStoredInstancePatternsHostExpression(): string | undefined {
    if (_.isNil(this.storedLastDeployedPatternInstanceConfig) || _.isNil(this.preSelectedProjectKey)) return undefined;
    return this.storedLastDeployedPatternInstanceConfig[this.preSelectedProjectKey]?.split(';').pop();
  }

  hasPreSelectedPatternInstance(): boolean {
    const retrieve = LocalStorageHelper.retrieve(localStorageDeployToInstancePatterns);
    if (_.isNil(retrieve) || _.isNil(this.preSelectedProjectKey)) return false;
    const preSelectedPatternInstancesConfig = JSON.parse(retrieve);
    return !_.isNil(preSelectedPatternInstancesConfig[this.preSelectedProjectKey]);
  }

  isPartOfMultiPatternInstanceSelection(patternInstanceOption: DeployToOption): boolean {
    return _.includes(this.selectedInstancePatternList, patternInstanceOption);
  }

  onCheckBoxChange(isChecked: boolean, patternInstance: DeployToOption): void {
    if (isChecked) {
      this.selectedInstancePatternList.push(patternInstance);
    } else {
      _.pull(this.selectedInstancePatternList, patternInstance);
    }
    this.selectedDeployToOption = this.getSelectedDeployToOption();
    this.instancePatternSelected.emit(this.selectedDeployToOption);
  }

  getSelectedDeployToOption(): DeployToOption {
    const selectedPatternInstancesHostExpression = DeploymentTargetInstanceHelper.getMergedPatternInstancesHostExpression(this.selectedInstancePatternList);
    // xor checks unique values of the given arrays
    const arrayOfLastSelectedPatterns: string[] = _.split(this.lastSelectedInstancePatternsOption.hostExpression, ',');
    const arrayOfCurrentlySelectedPatterns = _.map(this.selectedInstancePatternList, item => item.hostExpression);
    const isLastSelectedPatternsChecked = _.isEmpty(_.xor(arrayOfLastSelectedPatterns, arrayOfCurrentlySelectedPatterns));
    if (isLastSelectedPatternsChecked) {
      return this.lastSelectedInstancePatternsOption;
    }
    const isAllInstancePatternChecked = _.isEmpty(_.xor(this.selectedInstancePatternList, this.classicDeployPatterns));
    if (isAllInstancePatternChecked) {
      return this.allHostsOption;
    }
    return {
      type: DeployToClassicOptionType.Pattern,
      name: DeploymentTargetInstanceHelper.getMergedSelectedPatternInstancesName(this.selectedInstancePatternList),
      hostExpression: selectedPatternInstancesHostExpression
    };
  }

  onSelectionOptionClick(optionType): void {
    this.selectedDeployToOption = optionType;
    this.selectedInstancePatternList = _.isEqual(optionType, this.allHostsOption) ? Object.assign([], this.classicDeployPatterns) : this.getStoredInstancePatternList();
    this.instancePatternSelected.emit(this.selectedDeployToOption);
  }

  clearSelectedItems(): void {
    this.instancePatternSelectionRef.open();
    this.selectedInstancePatternList = [];
  }

  handleEmptySelectionDropdown(isDropdownOpened: boolean): void {
    // Selection cannot be empty - set all host by default if all checkbox are anticked and the dropdown is closed
    if (!isDropdownOpened && _.isEmpty(this.selectedInstancePatternList)) {
      this.selectedDeployToOption = this.allHostsOption;
      this.selectedInstancePatternList = Object.assign([], this.classicDeployPatterns);
      this.instancePatternSelected.emit(this.selectedDeployToOption);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

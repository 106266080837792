import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InsertInventoryAttachmentsDialogComponent } from './insert-inventory-attachments-dialog.component';
import { Observable, of, Subject, throwError } from 'rxjs';
import { InventoryService } from '../../inventory.service';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { InventoryAttachFilePayload, InventoryAttachmentPayload, InventoryAttachmentResourceMode, InventoryAttachmentTenantResourceMode, InventoryAttachTenantResourcePayload } from './inventory-attachment-payload.model';
import * as _ from 'lodash';
import { LoadingService } from '../../../modal-dialog/loading-modal/loading.service';
import { TenantHelper } from '../../../common/helpers/tenant.helper';
import { InventoryAttachmentDialogPayload } from './inventory-attachment-dialog-payload.model';
import { inventoryResourceReferencePrefix, inventorySecretResourceReferencePrefix } from '../../../common/constants/reference-prefix.constants';
import { YamlCertificateHelper } from '../yaml-certificate.helper';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHelper } from '../../../common/helpers/error.helper';

@Injectable({
  providedIn: 'root'
})
export class InsertInventoryAttachmentsDialogService {

  constructor(private dialogService: MatDialog,
              private inventoryService: InventoryService,
              private loadingService: LoadingService,
              private modalNotificationService: ModalNotificationService) {
  }

  openInsertInventoryAttachmentsDialog(headerText: string, mode: InventoryAttachmentResourceMode, inventoryKey: string): MatDialogRef<InsertInventoryAttachmentsDialogComponent> {
    return this.dialogService.open<InsertInventoryAttachmentsDialogComponent, InventoryAttachmentDialogPayload>(InsertInventoryAttachmentsDialogComponent, {
      width: '30%',
      autoFocus: false,
      disableClose: true,
      data: {
        header: headerText,
        resourceAttachmentMode: mode,
        inventoryKey: inventoryKey
      }
    });
  }

  insertInventoryAttachments(inventoryKey: string, header: string, mode: InventoryAttachmentResourceMode): Observable<string | undefined> {
    const dialogRef: MatDialogRef<InsertInventoryAttachmentsDialogComponent> = this.openInsertInventoryAttachmentsDialog(header, mode, inventoryKey);
    return this.handleInventorySecretInsert(dialogRef, mode, inventoryKey);
  }

  handleInventorySecretInsert(dialogRef: MatDialogRef<any>, mode: InventoryAttachmentResourceMode, inventoryKey: string): Observable<string | undefined> {
    return dialogRef.afterClosed().pipe(switchMap((inventoryAttachment: InventoryAttachmentPayload,) => {

      // cancelled: return empty string that indicates a cancellation event while as return undefined indicates an error event
      if (_.isNil(inventoryAttachment)) {
        return of('');
      }

      // upload files
      if (inventoryAttachment instanceof InventoryAttachFilePayload) {
        const loadingTitle: string = `Uploading resource for inventory ${TenantHelper.cropTenantFromKey(inventoryKey)}`;
        const progressText = new Subject<string>();
        this.loadingService.showLoading({title: loadingTitle, description: '', progressText: progressText.asObservable()});

        switch (mode) {
          case InventoryAttachmentResourceMode.secretResourceFile: {
            return this.inventoryService.uploadInventorySecretResource(inventoryKey, inventoryAttachment.file, inventoryAttachment.description).pipe(map(secretResourceWrapper =>
              `${inventorySecretResourceReferencePrefix}${secretResourceWrapper.secretResourceId}#${secretResourceWrapper.secretResourceName}`),
              catchError((error: HttpErrorResponse) => this.handleResourceUploadError(error, mode)));
          }
          case InventoryAttachmentResourceMode.resourceFile: {
            return this.inventoryService.uploadInventoryResource(inventoryKey, inventoryAttachment.file, inventoryAttachment.description).pipe(map(resourceWrapper =>
              `${inventoryResourceReferencePrefix}${resourceWrapper.resourceId}#${resourceWrapper.resourceName}`),
              catchError((error: HttpErrorResponse) => this.handleResourceUploadError(error, mode)));
          }
          case InventoryAttachmentResourceMode.certificate: {
            return YamlCertificateHelper.validateCertificate(inventoryAttachment.file).pipe(
              switchMap((isValidCertificate) => {
                  if (isValidCertificate) {
                    return this.inventoryService.uploadInventorySecretResource(inventoryKey, inventoryAttachment.file, inventoryAttachment.description).pipe(map(secretResourceWrapper =>
                      `${inventorySecretResourceReferencePrefix}${secretResourceWrapper.secretResourceId}#${secretResourceWrapper.secretResourceName}`),
                      catchError((error: HttpErrorResponse) => this.handleResourceUploadError(error, mode)));
                  } else {
                    const defaultErrorMessage = `Certificate is invalid or wasn’t found. Please fix and upload again: <br>"${inventoryAttachment.file.name}`;
                    return throwError(new Error(defaultErrorMessage));
                  }
                }
              ), catchError((error: Error) => this.handleInvalidCertificateError(error)));
          }
        }
      } else if (inventoryAttachment instanceof InventoryAttachTenantResourcePayload) {
        return of(inventoryAttachment.resourceKey);
      }
      return of(undefined);
    }), finalize(() => this.loadingService.hideLoading()
    ));
  }

  // probalby this should go out
  handleTenantLevelSecretInsert(dialogRef: MatDialogRef<any>, mode: InventoryAttachmentTenantResourceMode, tenantKey: string): Observable<string | undefined> {
    return dialogRef.afterClosed().pipe(switchMap((inventoryAttachFilePayload: InventoryAttachmentPayload) => {

        // cancelled
        if (_.isNil(inventoryAttachFilePayload)) {
          return of(undefined);
        }

        // upload files
      if (inventoryAttachFilePayload instanceof InventoryAttachFilePayload) {
        const loadingTitle = `Uploading global resources for tenant ${tenantKey}`;
        const progressText = new Subject<string>();
        this.loadingService.showLoading({title: loadingTitle, description: '', progressText: progressText.asObservable()});
        switch (mode) {
          case InventoryAttachmentTenantResourceMode.tenantLevelSecretResourceFile: {
            return this.inventoryService.uploadTenantSecretResource(tenantKey, inventoryAttachFilePayload.file, inventoryAttachFilePayload.description).pipe(map(
              secretResourceWrapper => `${inventorySecretResourceReferencePrefix}${secretResourceWrapper.secretResourceId}#${secretResourceWrapper.secretResourceName}`),
              catchError((error: HttpErrorResponse) => this.handleResourceUploadError(error, mode)));
          }
          case InventoryAttachmentTenantResourceMode.tenantLevelResourceFile: {
            return this.inventoryService.uploadTenantResource(tenantKey, inventoryAttachFilePayload.file, inventoryAttachFilePayload.description).pipe(map(resourceWrapper =>
              `${inventoryResourceReferencePrefix}${resourceWrapper.resourceId}#${resourceWrapper.resourceName}`),
              catchError((error: HttpErrorResponse) => this.handleResourceUploadError(error, mode)));
          }
        }
      }

        return of(undefined);
      }
    ), finalize(() => this.loadingService.hideLoading()));
  }

  private handleResourceUploadError(error: HttpErrorResponse, mode: InventoryAttachmentResourceMode | InventoryAttachmentTenantResourceMode): Observable<any> {
    const errorMessage = ErrorHelper.getErrorDetail(error, `Something went wrong during uploading ${mode}`);
    this.modalNotificationService.openErrorDialog({title: `Uploading ${mode} failed`, description: errorMessage});
    return of(undefined);
  }

  private handleInvalidCertificateError(error: Error): Observable<any> {
    this.modalNotificationService.openErrorDialog({
      title: 'Uploading certificate failed ',
      description: error.message
    });
    return of(undefined);
  }
}

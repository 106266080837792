import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PluginModel } from '../../../plugins/plugin.model';
import { CreatePatternService } from '../../../patterns/create-pattern/create-pattern.service';
import { ProjectContext } from '../../project.context';

@Component({
  selector: 'adm4-project-summary-quick-link',
  template: `
    <adm4-link [isDisabled]='projectContext.isProjectReadOnly$ | async'
               tooltipText='You dont have permission to edit this project.'
               (click)='openCreatePatternModalDialog()'>
      <span class='icon-with-link-text-wrapper'>
        <mat-icon>add_circle</mat-icon>
        Add new {{name}}
      </span>
    </adm4-link>
  `,
  styleUrls: ['./project-summary-quick-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryQuickLinkComponent {
  @Input() currentProjectKey: string;
  @Input() name: string;
  @Input() pluginsModels: PluginModel[];

  constructor(private createPatternService: CreatePatternService,
              public projectContext: ProjectContext,
  ) {}

  openCreatePatternModalDialog(): void {
    this.createPatternService.openAddPatternWindowThenNavigate(this.pluginsModels, this.currentProjectKey);
  }
}

import * as _ from 'lodash';

/**
 * Decides which form to use based on the quantity given.
 * e.g. "1 pattern" or "3 patterns".
 *
 * @param {number} count the number based on the function should decide.
 * @param {string} singular the term to use for singular form.
 * @param {string} plural the term to use for plural form.
 * @returns {string} the decided term.
 */
export function singularPlural(count: number, singular: string, plural: string): string {
  return count === 1 ? singular : plural;
}

/**
 * Converts the given object to nested array.
 */
export function convertObjectToArray(object: any): any[] {
  return _.map(object, (value, key) => {
    return [key, value];
  });
}

import { Maybe } from '../utils/utils';

export interface FileInfo {
  name: string;
  content: string;
}

export interface FilePosition {
  line: number;
  column: number;
}

export const parseFilePosition = (raw: Maybe<string>): Maybe<FilePosition> => {
  if (typeof raw !== 'string') {
    return undefined;
  }
  const splits = raw.split('-');
  if (splits.length !== 2) {
    return undefined;
  }
  const line: number = Number.parseInt(splits[0]);
  const column = Number.parseInt(splits[1]);
  if (isNaN(line) || isNaN(column)) {
    return undefined;
  }
  return {line, column};
};

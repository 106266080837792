import { Pipe, PipeTransform } from '@angular/core';
import { PathHelper } from '../helpers/path.helper';

@Pipe({
  name: 'nameFromPath'
})
export class NameFromPathPipe implements PipeTransform {
  transform(path: string): string {
    return PathHelper.getArtifactNameFromPath(path);
  }

}

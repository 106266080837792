import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'adm4-button-bar',
  template: `
    <div [ngClass]="wrapperClass">
      <ng-content></ng-content>
      <button type='button' *ngIf="isCancelPresent"
              class='admn4-button-text'
              [disabled]="isCancelDisabled"
              (click)='cancelClicked.emit()'>
        {{cancelButtonText}}
      </button>
      <button *ngIf="!noForm" type="submit" class='admn4-button-ellipse-blue'
              [disabled]='isSubmitDisabled'>
        {{submitButtonText}}
      </button>
      <button *ngIf="noForm" type="button" class='admn4-button-ellipse-blue' (click)="submitClicked.emit()"
              [disabled]='isSubmitDisabled'>
        {{submitButtonText}}
      </button>
    </div>`,
  styleUrls: ['./button-bar.component.scss']
})
export class ButtonBarComponent {
  @Input() isSubmitDisabled: boolean;
  @Input() isCancelDisabled: boolean;
  @Input() isCancelPresent: boolean = true;
  @Input() noForm: boolean = false;
  @Input() cancelButtonText = 'Cancel';
  @Input() submitButtonText = 'Save changes';
  @Input() wrapperClass;

  @Output() cancelClicked = new EventEmitter();
  @Output() submitClicked: EventEmitter<void> = new EventEmitter();
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoadProjects } from '../model/project';
import { select, Store } from '@ngrx/store';
import { AppState } from '../model/reducer';
import { canCreateInventoryView, canCreateProjectView } from '../model/views/permission.views';
import { Observable } from 'rxjs';
import { Project } from '../projects/project.model';
import { allInventoriesView, allProjectsView } from '../model/views';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Inventory } from '../inventory/inventory.model';



@Component({
  selector: 'adm4-welcome',
  template: `
    <div class='full-height-flex'>
      <adm4-column-header styleClass="welcome-header">
        <adm4-welcome-header></adm4-welcome-header>
      </adm4-column-header>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='welcome-container remaining-space-flex-content' [ngSwitch]='mode|async'>
        <adm4-welcome-inventory-content *ngSwitchCase='INVENTORY_MODE' [canCreateInventory]='canCreateInventory$ | async' [projects]='projects$ | async'></adm4-welcome-inventory-content>
          <adm4-welcome-content *ngSwitchDefault [canCreateProject]='canCreateProject$ | async' [projects]='projects$ | async'></adm4-welcome-content>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeComponent implements OnInit {

  canCreateProject$: Observable<boolean>;
  projects$: Observable<Project[]>;
  mode: Observable<'inventory' | 'project'>;
  canCreateInventory$: Observable<boolean>;
  inventories$: Observable<Inventory[]>;
  INVENTORY_MODE = 'inventory';

  constructor(private store$: Store<AppState>, public activatedRoute: ActivatedRoute) {

    this.mode = this.activatedRoute.data.pipe(map(value => {
      return _.isNil(value) || value.mode === this.INVENTORY_MODE ? value.mode : 'project';
    }));

    this.canCreateProject$ = this.store$.pipe(select(canCreateProjectView));
    this.canCreateInventory$ = this.store$.pipe(select(canCreateInventoryView));
    this.projects$ = this.store$.pipe(select(allProjectsView),  map(_.values));
    this.inventories$ = this.store$.pipe(select(allInventoriesView),  map(_.values));
  }

  ngOnInit(): void {
    this.store$.dispatch(new LoadProjects());
  }
}

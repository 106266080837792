import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { allProjectsView, projectKeyView } from '../../model/views';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { AppState, Dictionary } from '../../model/reducer';
import { Project, ProjectMeta } from '../project.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { VersionControlService } from '../../version-control/version-control.service';
import { LoadProjectMetaSuccess } from '../../model/version-control';
import { PublishProjectChangesetHelper } from './publish-project-changeset.helper';

@Injectable({providedIn: 'root'})
export class ProjectHasUnpublishedChangesGuard  {

  readonly ERROR_MODAL_TITLE = 'Could not open publish changes';

  constructor(private store$: Store<AppState>,
              private modalNotificationService: ModalNotificationService,
              private versionControlService: VersionControlService
  ) {
  }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return forkJoin([
      this.store$.pipe(select(projectKeyView), first(projectKey => !_.isNil(projectKey))),
      this.store$.pipe(select(allProjectsView), first(projects => !_.isEmpty(projects)))]
    ).pipe(
      switchMap(([projectKey, projects]: [string, Dictionary<Project>]) => {
        if (_.isNil(projectKey) || _.isNil(projects[projectKey])) {
          this.modalNotificationService.openErrorDialog({title: this.ERROR_MODAL_TITLE, description: 'Either no project is selected or selected project does not exist'});
          return of(false);
        }
        if (_.isNil(projects[projectKey].repository)) {
          this.modalNotificationService.openErrorDialog({title: this.ERROR_MODAL_TITLE, description: 'Project is not connected to version control'});
          return of(false);
        }
        return this.versionControlService.getProjectStatus(projectKey).pipe(
          map((projectMeta: ProjectMeta) => {
            this.store$.dispatch(new LoadProjectMetaSuccess(projectMeta));
            const hasLocalChanges = PublishProjectChangesetHelper.hasLocalChanges(projectMeta);
            if (!hasLocalChanges) {
              this.modalNotificationService.openInfoDialog({title: 'No pending changes found', description: 'There are currently no changes in the project'});
            }
            return hasLocalChanges;
          }),
          catchError((err: HttpErrorResponse) => {
            this.modalNotificationService.openErrorDialog({title: 'Could not open publish changes', description: 'Something went wrong with fetching project status'});
            console.error(err);
            return of(false);
          })
        );
      })
    );
  }
}

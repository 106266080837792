import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { IssueModel } from '../../common/model/issue.model';
import { AppState, Dictionary, ProjectKey } from '../../model/reducer';
import { PatternType } from '../../plugins/pattern-type.model';
import { Pattern } from '../../patterns/pattern.model';
import { select, Store } from '@ngrx/store';
import { allProjectsView, issuesView, patternsDiffView, patternsView, patternTypesView, projectKeyView, projectMetaView, variableListView } from '../../model/views';
import { Project, ProjectMeta } from '../project.model';
import { VariableModel } from '../../variables/variable.model';
import { PatternDiffData } from './pattern-diff-view/pattern-diff-data.model';
import { LoadDiffData, RequestPublishProject } from '../../model/publish-project/publish-project.actions';
import { first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import { NavigationService } from '../../navbar/navigation.service';
import { IssueSeverityCount } from '../project-issues-display.model';
import { ProjectIssuesDisplayHelper } from '../project-issues-display.helper';
import { filterForPatternIssues, getGeneralIssues } from '../project-issues/project-issues.helper';

/**
 * Collects snapshot data relevant for the publishing, snapshot at the moment of the opening of the publish dialog.<br/>
 * Only uses the store as the source.
 */
@Injectable()
export class PublishProjectContext implements OnDestroy {
  /**
   * Snapshots are collecting already preloaded data at the moment of opening publish, which means that data displayed in publish project would not be affected by background updates
   * It is needed so to have publish project modal only contain data which was recent only at the moment of opening modal window and when another user does a change in the meantime
   * it would not refresh parts of publish changes modal
   */

  private _projectKeySnapshot$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _currentProjectSnapshot$: BehaviorSubject<Project | null> = new BehaviorSubject(null);
  private _projectMetaSnapshot$: BehaviorSubject<ProjectMeta | null> = new BehaviorSubject(null);
  private _patternsSnapshot$: BehaviorSubject<Map<string, Pattern>> = new BehaviorSubject(new Map());
  private _patternTypesSnapshot$: BehaviorSubject<Dictionary<PatternType> | null> = new BehaviorSubject(null);
  private _issuesSnapshot$: BehaviorSubject<IssueModel[]> = new BehaviorSubject([]);
  private _variablesSnapshot$: BehaviorSubject<VariableModel[]> = new BehaviorSubject([]);

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  projectKey$: Observable<ProjectKey | null> = this._projectKeySnapshot$.asObservable();
  currentProject$: Observable<Project | null> = this._currentProjectSnapshot$.asObservable();
  projectMeta$: Observable<ProjectMeta | null> = this._projectMetaSnapshot$.asObservable();
  patterns$: Observable<Map<string, Pattern>> = this._patternsSnapshot$.asObservable();
  patternTypes$: Observable<Dictionary<PatternType> | null> = this._patternTypesSnapshot$.asObservable();
  issues$: Observable<IssueModel[]> = this._issuesSnapshot$.asObservable();
  variables$: Observable<VariableModel[]> = this._variablesSnapshot$.asObservable();
  currentBranch$: Observable<string | null>;

  patternsDiff$: Observable<PatternDiffData[]>;

  generalIssues$: Observable<IssueModel[]>;
  patternIssueNumberBySeverity$: Observable<IssueSeverityCount>;

  constructor(
      private store$: Store<AppState>,
      private navigation: NavigationService,
  ) {
    this.store$.pipe(select(projectKeyView), first((projectKey: string | null) => !_.isNil(projectKey)), takeUntil(this.destroyed$))
      .subscribe((projectKey: string) => this._projectKeySnapshot$.next(projectKey));

    forkJoin([this.store$.pipe(select(allProjectsView), first()), this.projectKey$.pipe(first())]).pipe(
      map(([projects, projectKey]: [Dictionary<Project>, string]) => projects[projectKey] || null),
      takeUntil(this.destroyed$)
    ).subscribe((project: Project | null) => this._currentProjectSnapshot$.next(project));

    this.store$.pipe(select(projectMetaView), first((projectMeta: ProjectMeta | null) => !_.isNil(projectMeta)), takeUntil(this.destroyed$))
      .subscribe((projectMeta: ProjectMeta) => this._projectMetaSnapshot$.next(projectMeta));

    this.store$.pipe(select(patternsView), first(), takeUntil(this.destroyed$))
      .subscribe((patterns: Map<string, Pattern>) => this._patternsSnapshot$.next(patterns));

    this.store$.pipe(select(patternTypesView), first((patternTypes: Dictionary<PatternType> | null) => !_.isNil(patternTypes)))
      .subscribe((patternTypes: Dictionary<PatternType>) => this._patternTypesSnapshot$.next(patternTypes));

    this.store$.pipe(select(issuesView), first())
      .subscribe((issues: IssueModel[]) => this._issuesSnapshot$.next(issues));

    this.store$.pipe(select(variableListView), first())
      .subscribe((variables: VariableModel[]) => this._variablesSnapshot$.next(variables));

    this.currentBranch$ = this.currentProject$.pipe(map((project: Project | null) => _.isNil(project) ? null : project.branch || null));

    this.patternsDiff$ = this.store$.pipe(select(patternsDiffView));

    this.generalIssues$ = this.issues$.pipe(
        map((allIssues: IssueModel[]): IssueModel[] => getGeneralIssues(allIssues)),
    );
    this.patternIssueNumberBySeverity$ = this.issues$.pipe(
        map((issues: IssueModel[]) => filterForPatternIssues(issues)),
        map(ProjectIssuesDisplayHelper.issuesToCountBySeverity),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  preloadDiffs(): void {
    this.store$.dispatch(new LoadDiffData());
  }

  requestPublishProjectChanges(commitMessage: string): void {
    this.projectMeta$.pipe(
      first((projectMeta: ProjectMeta | null) => !_.isNil(projectMeta)),
      withLatestFrom(this.projectKey$, this.store$.pipe(select(allProjectsView))),
      takeUntil(this.destroyed$)
    ).subscribe(([projectMeta, projectKey, projects]: [ProjectMeta, string | null, Dictionary<Project>]) => {
      const projectToPublish: Project | undefined = _.isNil(projectKey) ? undefined : projects[projectKey];
      if (_.isNil(projectToPublish)) {
        return;
      }
      this.store$.dispatch(new RequestPublishProject({projectMeta, commitMessage, project: projectToPublish}));
    });
  }

  public navigateToProjectIssues() {
    this.projectKey$.pipe(first()).subscribe((projectKey: string) => this.navigation.navigateToIssues(projectKey));
  }
}

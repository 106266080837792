import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { VersionControlService } from '../../version-control/version-control.service';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { allInventoriesView, selectedTenantKeyView, shouldDisplayTenantsView } from '../../model/views';
import { Inventory } from '../inventory.model';

@Injectable()
export class ImportInventoryContext {
  inventories$: Observable<Inventory[]>;
  selectedTenantKey$: Observable<string>;
  displayTenant$: Observable<boolean>;

  constructor(private versionControlService: VersionControlService, private store$: Store<AppState>) {
    this.inventories$ = this.store$.pipe(select(allInventoriesView), map(_.values));
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;
    this.displayTenant$ = this.store$.pipe(select(shouldDisplayTenantsView));
  }
}

import { UrlHelper } from '../common/helpers/url.helper';
import { patternReferencePrefix } from '../common/constants/reference-prefix.constants';
import { ReportGenerationStatusModel } from './reports.model';

export class ReportsHelper {

  static createReportKey(reportGenerationStatus: ReportGenerationStatusModel): string {
    return reportGenerationStatus.projectKey.concat(reportGenerationStatus.reportClassName);
  }

  static replacePatternReferencesByUrls(generatedReport: string, projectKey: string): string {
    return generatedReport.replace(new RegExp(patternReferencePrefix, 'g'), UrlHelper.combineFullUrl(`projects/${projectKey}/patterns/`));
  }

  static makeExternalLinksOpenInANewTab(generatedReport: string): string {
    const externalLinksRegExp = new RegExp('(<a.*?href="http.+?".*?)(>)(.+?)(</a>)', 'g');
    return generatedReport.replace(externalLinksRegExp, (_linkMatch: string, part1: string, part2: string, part3: string, part4: string) => {
      return (`${part1} target="_blank" class="link-with-icon"${part2}<span>${part3}</span><em class="material-icons">open_in_new</em>${part4}`);
    });
  }

  static applyEdits(generatedReport: string, projectKey: string): string {
    const edits: ((reportToEdit: string) => string)[] = [
      (reportToEdit: string) => this.makeExternalLinksOpenInANewTab(reportToEdit),
      (reportToEdit: string) => this.replacePatternReferencesByUrls(reportToEdit, projectKey)
    ];
    return edits.reduce((updatedReport: string, edit: (reportToEdit: string) => string) => edit(updatedReport), generatedReport);
  }
}

<div class="full-height-flex">
	<div class="step-sub-heading-with-actions">
		<div class='step-content-header'>
			<mat-icon class='title' *ngIf="titleIcon">{{titleIcon}}</mat-icon>
			{{node.name}}
		</div>
		<div class='details-actions'>
			<button class='step-content-text-button' (click)="downloadFile()">
				<mat-icon class="step-content-text-button-icon">save_alt</mat-icon>
				Download file
			</button>
			<button class='step-content-text-button' (click)="viewFile()">
				<mat-icon class="step-content-text-button-icon">fullscreen</mat-icon>
				Fullscreen
			</button>
		</div>
	</div>

	<mat-tab-group class='full-height mat-tabs-full-height tab-content-overflow-y-hidden' [ngClass]='boxShadowClass' [mat-stretch-tabs]='false'
				   [(selectedIndex)]='selectedIndex'>
		<mat-tab label="File">
			<div class="full-height">
				<adm4-deployment-file-view *ngIf='viewingFileView && selectedIndex === 0'
																	 class='full-height'
																	 [fileInfo]="viewingFileView"
																	 [compactMode]="true"></adm4-deployment-file-view>
			</div>
		</mat-tab>
		<mat-tab label="Details">

			<div class="step-content-card item-details full-height" [ngClass]='boxShadowClass'>
				<p>
					<adm4-k8s-detail-message
									[node]='node'
									[outputType]='outputType'
									[pattern]='pattern'
									[projectKey]='projectKey'>
					</adm4-k8s-detail-message>
				</p>
				<p>
					<strong>Target service</strong>
				</p>
				<p>{{node.output.host}}</p>
			</div>

		</mat-tab>
	</mat-tab-group>

</div>

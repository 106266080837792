
import {tap} from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SaverService {
  private _onSave = new EventEmitter<void>();
  onSave: Observable<void> = this._onSave.pipe(tap(() => {
    const focusedElement = <HTMLInputElement>document.querySelector(':focus');
    if (focusedElement) {
      focusedElement.blur();
    }
  }));

  save(): void {
    this._onSave.emit();
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Project, ProjectMeta } from '../../project.model';
import { checkExistingProjectKey } from '../../project-creation.validator';
import { BRANCH_NAME_FORM_CONTROL_NAME, PROJECT_KEY_FORM_CONTROL_NAME, VERSION_CONTROL_FORM_CONTROL_NAME } from '../../create-import-project-common/create-import-branch-project-form.model';
import { Observable, of, Subject } from 'rxjs';
import { Mixin } from '../../../common/decorators/mixin.decorator';
import { IPredefinedProjectFormDataMixin, PredefinedBranchProjectFormDataMixin } from '../../create-import-project-common/predefined-project-form-data.mixin';
import { FormHelper } from '../../../common/helpers/form.helper';
import { VersionControlData } from '../../../common/model/version-control-data.model';
import { BranchProjectHelper, ProjectChangesStatus } from './branch-project.helper';
import { checkExistingBranchName, isValidBranchNameFormat } from '../../project-branching.validator';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { Branch } from '../../../version-control/branch.model';
import { VersionControlService } from '../../../version-control/version-control.service';
import { requireNonNull } from '../../../common/utils/utils';

@Component({
  selector: 'adm4-branch-project',
  templateUrl: './branch-project.component.html',
  styleUrls: [
    '../../../common/styles/component-specific/create-form.scss',
    '../../../common/styles/component-specific/modal-window.scss'
  ]
})
@Mixin([PredefinedBranchProjectFormDataMixin])
export class BranchProjectComponent implements OnInit, OnDestroy, IPredefinedProjectFormDataMixin {
  @Input() projects: Project[];
  @Input() predefinedProjectData: Project;
  @Input() selectedTenantKey: string;
  @Input() displayTenant: boolean;
  @Input() projectMeta: ProjectMeta | null;
  @Input() selectedProject: Project;

  @Output() branchProject = new EventEmitter<Project>();
  @Output() cancelClicked: EventEmitter<any> = new EventEmitter();

  form: UntypedFormGroup;
  onFormSubmit = new Subject<any>();

  readonly ERROR_INVALID_PROJECT_KEY = 'The project key entered contains some invalid characters. Must be of format [A-Z0-9_-]';
  readonly ERROR_PROJECT_KEY_REQUIRED = 'The project key is required';
  readonly ERROR_BRANCH_NAME_REQUIRED = 'The branch name is required';
  readonly ERROR_INVALID_BRANCH_NAME = 'The new branch entered is not a valid git reference name';
  readonly ERROR_BRANCH_NAME_EXISTS = 'The new branch entered already exists';
  readonly BUTTON_BRANCH_PROJECT = 'Branch Project';

  readonly PROJECT_KEY_FORM_CONTROL_NAME = PROJECT_KEY_FORM_CONTROL_NAME;
  readonly BRANCH_NAME_FORM_CONTROL_NAME = BRANCH_NAME_FORM_CONTROL_NAME;
  readonly VERSION_CONTROL_FORM_CONTROL_NAME = VERSION_CONTROL_FORM_CONTROL_NAME;

  private destroyed$: Subject<boolean> = new Subject();

  initialProjectKeyFormValue: () => string;
  initialNewBranchNameValue: () => string;
  predefinedVersionControlData: () => VersionControlData | null;

  constructor(private fb: UntypedFormBuilder, private modalNotificationService: ModalNotificationService, private versionControlService: VersionControlService) {
    this.onFormSubmit.pipe(
      filter(() => this.canSave),
      takeUntil(this.destroyed$),
      switchMap(() => this.displayNotification())
    ).subscribe((confirmed?: boolean) => {
      if (confirmed === true) {
        this.triggerBranchProject();
      }
    });
  }

  displayNotification(): Observable<boolean | undefined> {
    if (this.changedVersionControlData) {
      return this.modalNotificationService.openConfirmDialog({
        title: 'Branching from a different project',
        description: 'You modified the version control data of the current project. <br> ' +
          'Note that if you continue, you will branch from a different project. <br>' +
          'Do you want to continue anyway?'
      }, {
        confirmButtonText: 'Continue'
      }).afterClosed();
    } else {
      const project: Project = BranchProjectHelper.convertFormValueToVersionedProject(this.form.value, this.selectedTenantKey);
      return this.versionControlService.getBranchByRepoAndName(requireNonNull(project.repository), requireNonNull(project.branchFrom)).pipe(
        switchMap((branch: Branch | undefined) => {
          const changes: ProjectChangesStatus = BranchProjectHelper.checkProjectChanges(requireNonNull(this.projectMeta), branch);
          switch (changes) {
            case ProjectChangesStatus.BOTH:
            case ProjectChangesStatus.LOCAL:
            case ProjectChangesStatus.REMOTE:
              return this.modalNotificationService.openConfirmDialog(BranchProjectHelper.getProjectChangesStatusNotification(changes), {
                confirmButtonText: 'Continue'
              }).afterClosed();
            default:
              return of(true);
          }
        }));
     }
  }

  ngOnInit() {
    this.form = this.createFormGroup();
    // when it's the first attempt to branch project with empty branch name and key based on the selected project from the store
    if (_.isNil(this.predefinedProjectData)) {
      this.predefinedProjectData = Object.assign({}, this.selectedProject, { branchFrom: this.selectedProject.branch});
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    group.addControl(this.PROJECT_KEY_FORM_CONTROL_NAME, this.fb.control(this.initialProjectKeyFormValue(), [Validators.pattern(/^[a-zA-Z0-9_-]+$/), checkExistingProjectKey(this.projects, this.selectedTenantKey)]));
    group.addControl(this.BRANCH_NAME_FORM_CONTROL_NAME,
      this.fb.control(this.initialNewBranchNameValue(), [
          isValidBranchNameFormat(),
          checkExistingBranchName(this.projects, this.initialRepositoryName(), [])]));
    return group;
  }

  addVersionControlForm(versionControlForm: UntypedFormGroup): void {
    this.form.setControl(this.VERSION_CONTROL_FORM_CONTROL_NAME, versionControlForm);
  }

  initialRepositoryName(): string {
    return _.isNil(this.predefinedProjectData) || _.isNil(this.predefinedProjectData.repository) ? '' : this.predefinedProjectData.repository;
  }

  addRemoteBranchNameValidation(remoteBranches: Branch[]): void {
    this.form.controls[BRANCH_NAME_FORM_CONTROL_NAME].setValidators([
      isValidBranchNameFormat(),
      checkExistingBranchName(this.projects, this.initialRepositoryName(), remoteBranches)]);
    this.form.controls[BRANCH_NAME_FORM_CONTROL_NAME].updateValueAndValidity();
    this.form.controls[VERSION_CONTROL_FORM_CONTROL_NAME].updateValueAndValidity();
  }

  shouldShowProjectKeyErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[this.PROJECT_KEY_FORM_CONTROL_NAME], errorKey);
  }

  shouldShowBranchNameErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[this.BRANCH_NAME_FORM_CONTROL_NAME], errorKey);
  }

  triggerBranchProject(): void {
    const project: Project = BranchProjectHelper.convertFormValueToVersionedProject(this.form.value, this.selectedTenantKey);
    this.branchProject.emit(project);
  }

  get changedVersionControlData(): boolean {
    const versionControlFormData = BranchProjectHelper.convertFormValueToVersionControlData(this.getVersionControl().value);
    return this.predefinedProjectData.repository !== versionControlFormData.repository || this.predefinedProjectData.branchFrom !== versionControlFormData.branch
      || this.predefinedProjectData.path !== versionControlFormData.path;
  }

  private getVersionControl(): AbstractControl {
    return this.form.controls[this.VERSION_CONTROL_FORM_CONTROL_NAME];
  }

  get canSave(): boolean {
    const versionControlForm = this.getVersionControl() as UntypedFormGroup;
    const versionControlValid: boolean = _.values(versionControlForm.controls).every(control => control.valid);
    // when submitting form we only care about version control validation if was enabled
    const baseFormValid = _.keys(this.form.controls)
      .filter(formControlName => !_.includes([this.VERSION_CONTROL_FORM_CONTROL_NAME], formControlName))
      .every(formControlName => this.form.controls[formControlName].valid);
    return baseFormValid && versionControlValid;
  }

  get projectForTenantExistsError(): string {
    return `This key is already in use for tenant ${this.selectedTenantKey}`;
  }

}

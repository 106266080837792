<div class='import-dialog'>
  <form [formGroup]="form" name="form" (ngSubmit)='submitImportProject()'>
    <div class="content-container create-item-container">
      <adm4-version-control-form [predefinedVersionControl]="predefinedVersionControlData()" (formReady)='addVersionControlForm($event)'></adm4-version-control-form>
      <div class="form-group">
        <label class='input-label'>Project key*</label>
        <div class="item-key-container">
          <div class='tenant-key-wrapper' *ngIf='displayTenant'>
            <span>{{selectedTenantKey}} - </span>
            <div class='validation-message-container'></div>
          </div>
          <div class='item-key-input-wrapper'>
            <input class='admn4-text-input form-control'
                   [class.errored]="form.controls[PROJECT_KEY_FORM_CONTROL_NAME].invalid"
                   formControlName='projectKey'
                   name="projectKey"
                   required/>
            <div class='validation-message-container'>
              <adm4-validation-message *ngIf='shouldShowProjectKeyErrorMessage("pattern")' [isError]='true'
                                       [message]='ERROR_INVALID_PROJECT_KEY'></adm4-validation-message>
              <adm4-validation-message *ngIf='shouldShowProjectKeyErrorMessage("required")' [isError]='true'
                                       [message]='ERROR_PROJECT_KEY_REQUIRED'></adm4-validation-message>
              <adm4-validation-message *ngIf='shouldShowProjectKeyErrorMessage("projectKeyExists")' [isError]='true'
                                       [message]='projectForTenantExistsError'></adm4-validation-message>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions>
      <button type='button' class='admn4-button-text'
              (click)='cancelClicked.emit()'>
        Cancel
      </button>
      <button class='admn4-button-ellipse-blue'
              [disabled]='!canSave'>
        Import
      </button>
    </div>
  </form>
</div>

import { AbstractControl } from '@angular/forms';
import { Project } from './project.model';

export function checkExistingProjectKey(projects: Project[], tenantKey: string): (control: AbstractControl) => { projectKeyExists: boolean } | null {
  return (control: AbstractControl) => {
    const projectKeyAlreadyExists = projects.some((project: Project) => {
      const projectKeyFull = `${tenantKey}-${control.value}`.toUpperCase();
      return control.value && project.projectKey === projectKeyFull;
    });

    if (!projectKeyAlreadyExists) {
      return null;
    }

    return {projectKeyExists: true};
  };
}

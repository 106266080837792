import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Revision } from '../../../version-control/revision/revision.model';
import { AppState } from '../../../model/reducer';
import { select, Store } from '@ngrx/store';
import { LocalStatus, MetaInfo } from '../../../version-control/meta-info.model';
import { LoadRevision } from '../../../model/version-control';
import { requireNonNull } from '../../utils/utils';
import { Observable } from 'rxjs';

/**
 * This component represents the content of the popover window for the uncommited changes
 */
@Component({
  selector: 'adm4-local-changes-popup',
  template: `
    <div class='un-container'>
      <ng-container *ngIf='metaInfo; else loading'>
        <span *ngIf='metaInfo.localStatus !== UNMODIFIED_STATUS'>
          <div>{{metaInfo.localStatus | capitalizeFirst}} by {{metaInfo?.localAuthor}}, {{metaInfo?.localDate | date:'MMMM d, y'}} at {{metaInfo?.localDate | date: 'HH:mm'}}</div>
          <a href='javascript:void(0)' (click)='triggerPublish()'>
            <i class="fa fa-upload left-icon" aria-hidden="true"></i>
            <span class='publish-text'>Publish changes</span>
          </a>
        </span>
        <span *ngIf='metaInfo.localStatus === UNMODIFIED_STATUS'>
          Modified by {{(revision$ | async)?.committer.name}}, {{(revision$ | async)?.committer.when | date:'MMMM d, y'}} at {{(revision$ | async)?.committer.when | date:'HH:mm'}}
        </span>
      </ng-container>
      <ng-template #loading><span>Loading...</span></ng-template>
    </div>
  `,
  styleUrls: ['./local-changes-popup.component.scss']
})
export class LocalChangesPopupComponent implements OnChanges {
  @Input() metaInfo: MetaInfo;
  @Output() publishClick: EventEmitter<undefined> = new EventEmitter();
  public revision$: Observable<Revision>;

  public UNMODIFIED_STATUS = LocalStatus.Unmodified;

  constructor(private store$: Store<AppState>) {
    this.revision$ = this.store$.pipe(select(state => state.versionControl.revisions[this.metaInfo.commitId || '']));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.metaInfo && LocalStatus.Unmodified === this.metaInfo?.localStatus) {
      this.store$.dispatch(new LoadRevision(requireNonNull(this.metaInfo.commitId)));
    }
  }

  triggerPublish(): void {
    this.publishClick.emit();
  }
}

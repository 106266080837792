import { Observable, Observer } from 'rxjs';
import { FileInfo } from './common/model/file-info.model';
import { map } from 'rxjs/operators';

export function isASCIIContent(content: string): boolean {
  const ASCIIRegexp: RegExp = new RegExp('^[\x00-\xFF]*$');
  return ASCIIRegexp.test(content);
}

export function renameFile(file: File, newFileName: string): File {
  if (!(file instanceof Blob)) {
    throw new Error('`file` must be an instance of File or Blob.');
  }
  return new File([file as Blob], newFileName);
}

export function readFileInfo(file: File): Observable<FileInfo> {
  return readTextFile(file).pipe(map((content: string) => {
    return {
      name: file.name,
      content: content,
      file: file
    };
  }));
}

/**
 * RxJs abstraction over File reader. Returns observable of text file content
 * @param blob
 * @returns {Observable<string>}
 */
export function readTextFile(blob: Blob): Observable<string> {
  return new Observable((obs: Observer<string>) => {
    if (!(blob instanceof Blob)) {
      obs.error(new Error('`blob` must be an instance of File or Blob.'));
      return;
    }

    const reader = new FileReader();

    reader.onerror = err => obs.error(err);
    reader.onabort = err => obs.error(err);
    reader.onload = () => obs.next(<string>(reader.result || ''));
    reader.onloadend = () => obs.complete();

    return reader.readAsText(blob);
  });
}

const binaryExtensions: ReadonlyArray<string> = ['zip', 'jpeg', 'jpg', 'png', 'pdf'];

export const isBinaryByExtension = (fileName: string | undefined): boolean => {
  const splits: string[] = (fileName ?? '').split('.');
  if (splits.length < 2) {
    // no extension => not binary
    return false;
  }
  const ext: string = splits[splits.length - 1];
  if (ext === '') {
    // ends with a '.' => not binary
    return false;
  }
  return binaryExtensions.includes(ext);
};

import { createSelector, MemoizedSelector } from '@ngrx/store';

import * as _ from 'lodash';

import { OperationKey } from '../permissions/permissions.model';
import {
  allInventoriesView,
  allProjectsView,
  deploymentWizardSelectionInventoryKeyView,
  inventoryKeyView,
  projectKeyView,
  selectedInventoryView,
  selectedProjectView,
  selectedTenantKeyView,
  tenantsView,
} from '../views';
import { PermissionsHelper } from '../../permissions/permissions.helper';
import { Tenant } from '../../tenant/tenant.model';
import { AppState, Dictionary, ProjectKey } from '../reducer';
import { Inventory } from '../../inventory/inventory.model';
import { Project } from '../../projects/project.model';
import { entryWithKeyExists } from '../../common/utils/utils';

export const canCreateProjectView: MemoizedSelector<AppState, boolean> = createSelector(
    tenantsView,
    (tenants: Dictionary<Tenant>) => {
      return _.some(PermissionsHelper.getObjectWithPermissionOf<Tenant>(_.values(tenants), OperationKey.CREATE_PROJECT));
    },
);

export const canCreateInventoryView: MemoizedSelector<AppState, boolean, (res: Dictionary<Tenant>) => boolean> = createSelector(
  tenantsView,
  (tenants) => {
    return _.some(PermissionsHelper.getObjectWithPermissionOf<Tenant>(_.values(tenants), OperationKey.CREATE_INVENTORY));
  },
);

export const hasCreateInventoryPermissionView: MemoizedSelector<AppState, boolean, (res: Dictionary<Tenant>) => boolean> = createSelector(
  tenantsView,
  (tenants) => _.some(tenants, (t: Tenant) => _.includes(t._userAuthorization, OperationKey.CREATE_INVENTORY)),
);


export const hasProjectWriteAccessView: MemoizedSelector<AppState, boolean, (res1: ProjectKey | null, res2: Dictionary<Project>) => boolean>
  = createSelector(
    projectKeyView,
    allProjectsView,
    (currentProjectKey: ProjectKey | null, projects: Record<string, Project>): boolean => {
      if (!_.isEmpty(projects) && !_.isNil(currentProjectKey) && entryWithKeyExists(currentProjectKey, projects)) {
        return _.includes(projects[currentProjectKey]._userAuthorization, OperationKey.MODIFY_PROJECT);
      }
      return false;
    },
  );

export const getProjectsWithModifyPermission: MemoizedSelector<AppState, Project[], (res1: ProjectKey | null, res2: Dictionary<Project>) => (Project[])>
  = createSelector(
    projectKeyView,
    allProjectsView,
    (currentProjectKey: ProjectKey | null, projects: Record<string, Project>) => {
      const projectsWithPermission: Project[] = [];
      if (!_.isEmpty(projects) && !_.isNil(currentProjectKey)) {
        _.forEach(projects, (project) => {
          if (_.includes(project._userAuthorization, OperationKey.MODIFY_PROJECT)) {
            projectsWithPermission.push(project);
          }
        });
      }
      return projectsWithPermission;
    },
  );

export const hasProjectAdminAccessView: MemoizedSelector<AppState, boolean, (res1: ProjectKey | null, res2: Dictionary<Project>) => boolean>
  = createSelector(
    projectKeyView,
    allProjectsView,
    (currentProjectKey: ProjectKey | null, projects: Record<string, Project>) => {
      if (!_.isEmpty(projects) && !_.isNil(currentProjectKey) && entryWithKeyExists(currentProjectKey, projects)) {
        return _.includes(projects[currentProjectKey]._userAuthorization, OperationKey.ADMIN_PROJECT);
      }
      return false;
    },
  );

/**
 * Returns true for projects to which current user does not have modify permission, or current project is just a revision of specific commit of project
 * NOTE: this check should not be done for deleting project, because it should be possible to delete revision of project, there hasProjectWriteAccessView has to be checked
 */
export const isSelectedProjectReadonlyView: MemoizedSelector<AppState, boolean, (res1: Project | null) => boolean>
  = createSelector(
    selectedProjectView,
    (currentProject: Project | null): boolean => {
      return !_.isNil(currentProject) && (!_.includes(currentProject._userAuthorization, OperationKey.MODIFY_PROJECT) || !_.isNil(currentProject.originProjectKey));
    },
  );

export const hasInventoryWriteAccessView: MemoizedSelector<AppState, boolean, (res1: string | null, res2: Dictionary<Inventory> | null) => boolean>
  = createSelector(
    inventoryKeyView,
    allInventoriesView,
    (currentInventoryKey, inventories) => {
      if (!_.isNil(inventories) && !_.isEmpty(inventories) && !_.isNil(currentInventoryKey) && entryWithKeyExists(currentInventoryKey, inventories)) {
        return _.includes(inventories[currentInventoryKey]._userAuthorization, OperationKey.MODIFY_INVENTORY);
      }
      return false;
    },
  );

export const hasInventoryAdminAccessView: MemoizedSelector<AppState, boolean, (res1: string | null, res2: Dictionary<Inventory>) => boolean>
  = createSelector(
    inventoryKeyView,
    allInventoriesView,
    (currentInventoryKey, inventories) => {
      if (!_.isNil(inventories) && !_.isEmpty(inventories) && !_.isNil(currentInventoryKey) && entryWithKeyExists(currentInventoryKey, inventories)) {
        return _.includes(inventories[currentInventoryKey]._userAuthorization, OperationKey.ADMIN_INVENTORY);
      }
      return false;
    },
  );

export const hasInventoryDeployAccessView: MemoizedSelector<AppState, boolean, (res1: string | undefined, res2: Dictionary<Inventory>) => boolean>
  = createSelector(
    deploymentWizardSelectionInventoryKeyView,
    allInventoriesView,
    (wizardSelectionInventoryKey, inventories) => {
      if (!_.isNil(inventories) && !_.isEmpty(inventories) && !_.isNil(wizardSelectionInventoryKey) && entryWithKeyExists(wizardSelectionInventoryKey, inventories)) {
        return _.includes(inventories[wizardSelectionInventoryKey]._userAuthorization, OperationKey.DEPLOY_INVENTORY);
      }
      return false;
    },
  );

/**
 * Returns true for inventories to which current user does not have modify permission, or current inventory is just a revision of specific commit of inventory
 * NOTE: this check should not be done for deleting inventory, because it should be possible to delete revision of inventory, there hasInventoryWriteAccessView has to be checked
 */
export const isSelectedInventoryReadonlyView: MemoizedSelector<AppState, boolean, (res1: Inventory | null) => boolean>
  = createSelector(
    selectedInventoryView,
    (currentInventory: Inventory | null) => {
      return !_.isNil(currentInventory) && (!_.includes(currentInventory._userAuthorization, OperationKey.MODIFY_INVENTORY) || !_.isNil(currentInventory.originInventoryKey));
    },
  );

const calculateHasTenantModificationAccess = (tenants: Dictionary<Tenant>, selectedTenantKey: string | null): boolean => {
  if (!_.isEmpty(tenants) && !_.isNil(selectedTenantKey) && entryWithKeyExists(selectedTenantKey, tenants)) {
    return _.includes(tenants[selectedTenantKey]._userAuthorization, OperationKey.MODIFY_TENANT);
  }
  return false;
};


/**
 * This is the ngrx version of `hasTenantModificationAccessView`. Has the same logic, has memoization too, but created with ngrx, so that it can be mocked.
 */
export const hasTenantModificationAccessView = createSelector(
    tenantsView,
    selectedTenantKeyView,
    calculateHasTenantModificationAccess,
);

export const getInventoriesWithModifyInventoryAccessView: MemoizedSelector<AppState, string[], (res: Dictionary<Inventory>) => string[]>
  = createSelector(
    allInventoriesView,
    (inventories: Record<string, Inventory> | null): string[] => {
      if (_.isNil(inventories) || _.isEmpty(inventories)) return [];
      return _.reduce(Object.values(inventories), (output: string[], inventory: Inventory) => {
        if (!_.includes(inventory._userAuthorization, OperationKey.MODIFY_INVENTORY)) return output;
        return _.concat(output, inventory.inventoryKey);
      }, []);
    },
  );

export const hasViewSecretContentTenantAccessView: MemoizedSelector<AppState, boolean, (res: Dictionary<Tenant>) => boolean>
  = createSelector(
    tenantsView,
    (tenants) => {
      return _.some(PermissionsHelper.getObjectWithPermissionOf<Tenant>(_.values(tenants), OperationKey.VIEW_SECRET_CONTENT_TENANT));
    },
  );

export const getInventoriesWithViewSecretContentAccessView: MemoizedSelector<AppState, string[], (res: Dictionary<Inventory>) => string[]>
  = createSelector(
    allInventoriesView,
    (inventories) => {
      if (_.isNil(inventories) || _.isEmpty(inventories)) return [];
      return _.reduce(Object.values(inventories), (output: string[], inventory: Inventory) => {
        if (!_.includes(inventory._userAuthorization, OperationKey.VIEW_SECRET_CONTENT_INVENTORY)) return output;
        return _.concat(output, inventory.inventoryKey);
      }, []);
    },
  );

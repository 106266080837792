import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { VersionControlFormContext } from './version-control-form.context';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, skip, takeUntil } from 'rxjs/operators';
import { Branch } from '../../../version-control/branch.model';
import * as _ from 'lodash';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BRANCH_FORM_CONTROL_NAME, PATH_FORM_CONTROL_NAME, REPOSITORY_FORM_CONTROL_NAME } from './version-control.form';
import { VersionControlData } from '../../model/version-control-data.model';
import { FormHelper } from '../../helpers/form.helper';
import { VersionControlHelper } from '../../helpers/version-control.helper';

@Component({
  selector: 'adm4-version-control-form',
  templateUrl: './version-control-form.component.html',
  styleUrls: ['./version-control-form.component.scss'],
  providers: [VersionControlFormContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionControlFormComponent implements OnInit, OnDestroy {

  @Input() predefinedVersionControl: VersionControlData | null;
  /**
   * Field that contains path that, if present, will be automatically filled into path form value when repository is scanned
   * If path form control was touched, it won't fill automatically anymore
   */
  @Input() automaticPath?: string;
  @Input() pathRequired = false;
  @Input() gitRepoLabel = 'Git repository*';
  @Input() gitRepoIconTooltip;
  @Input() pathVisible = true;
  @Input() isDisabled = false;
  @Output() formReady: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() initialBranches: EventEmitter<Branch[]> = new EventEmitter();
  readonly REPOSITORY_FORM_CONTROL_NAME = REPOSITORY_FORM_CONTROL_NAME;
  readonly BRANCH_FORM_CONTROL_NAME = BRANCH_FORM_CONTROL_NAME;
  readonly PATH_FORM_CONTROL_NAME = PATH_FORM_CONTROL_NAME;

  readonly ERROR_PATH__REQUIRED = 'The path is required';
  readonly ERROR_INVALID_PATH = 'Path must not start with \'/\'';

  form: UntypedFormGroup;

  branches$: Observable<Branch[]>;
  isBranchesLoading$: Observable<boolean>;
  repoErrorMessage$: Observable<string | null>;
  repoHasError$: Observable<boolean>;
  shouldDisableRepoInput$: Observable<boolean | undefined> = this.projectVersionControlFormContext.isBranchesLoading$.pipe(map((isBranchesLoading: boolean) => isBranchesLoading ? true : undefined));
  branchDisabled$: Observable<boolean | undefined> = this.projectVersionControlFormContext.branches$.pipe(map((branches: Branch[]) => _.isEmpty(branches) ? true : undefined));

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private fb: UntypedFormBuilder,
              private cdr: ChangeDetectorRef,
              private projectVersionControlFormContext: VersionControlFormContext) {
    this.branches$ = this.projectVersionControlFormContext.branches$;
    this.isBranchesLoading$ = this.projectVersionControlFormContext.isBranchesLoading$;
    this.repoErrorMessage$ = this.projectVersionControlFormContext.repoErrorMessage$;
    this.repoHasError$ = this.projectVersionControlFormContext.repoHasError$;
  }

  ngOnInit() {
    this.form = this.createFormGroup();
    this.formReady.emit(this.form);
    if (!_.isNil(this.predefinedVersionControl) && !_.isNil(this.predefinedVersionControl.repository)) {
      this.loadBranches(this.predefinedVersionControl.repository);
    }
    this.handleBranchesChange();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private handleBranchesChange() {
    this.projectVersionControlFormContext.branches$.pipe(
      skip(1),
      takeUntil(this.destroyed$),
      // debounce time has scheduler as a second parameter, which by default is async, which is equal to wrapping this whole statement in setTimeout in order to prevent expression was changed after it was changed blabla error
      debounceTime(0)
    )
      .subscribe(branches => {
        this.initialBranches.emit(branches);
        const selectedBranch = this.initialBranchFormValue(branches);
        this.form.controls[this.BRANCH_FORM_CONTROL_NAME].setValue(selectedBranch);
        this.cdr.markForCheck();
      });
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    group.addControl(this.REPOSITORY_FORM_CONTROL_NAME, this.fb.control(this.initialRepositoryFormValue, [Validators.required]));
    group.addControl(this.BRANCH_FORM_CONTROL_NAME, this.fb.control(null, Validators.required));
    group.addControl(this.PATH_FORM_CONTROL_NAME, this.fb.control(this.initialPathFormValue, this.pathRequired ? [Validators.pattern(/^[^\/].*/), Validators.required] : [Validators.pattern(/^[^\/].*/)]));
    return group;
  }

  onRepoNameChange(): void {
    this.projectVersionControlFormContext.resetBranches();
    this.form.controls[this.BRANCH_FORM_CONTROL_NAME].setValue(null);
    this.form.controls[this.BRANCH_FORM_CONTROL_NAME].markAsPristine();
  }

  scan(): void {
    this.trimRepositoryFormControlValue();
    const repoNameFormValue = this.repoName;
    if (_.isNil(repoNameFormValue) || _.isEmpty(repoNameFormValue)) {
      return;
    }
    this.loadBranches(repoNameFormValue);
    if (!_.isNil(this.automaticPath) && this.form.controls[this.PATH_FORM_CONTROL_NAME].untouched) {
      this.form.controls[this.PATH_FORM_CONTROL_NAME].setValue(this.automaticPath);
    }
  }

  loadBranches(repoName: string): void {
    this.projectVersionControlFormContext.loadBranches(repoName);
  }

  shouldShowPathErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[this.PATH_FORM_CONTROL_NAME], errorKey);
  }

  shouldDisableRepoInput(shouldDisableRepoInput?: boolean): boolean {
    return this.isDisabled || !!shouldDisableRepoInput;
  }

  shouldDisableRepoScanButton(): boolean {
    return this.isDisabled || !this.form.controls[REPOSITORY_FORM_CONTROL_NAME].value;
  }

  shouldDisableBranch(branchDisabled?: boolean): boolean {
    return this.isDisabled || !!branchDisabled;
  }

  private initialBranchFormValue(branches: Branch[]): Branch | undefined {
    if (_.isEmpty(branches)) {
      return undefined;
    }
    const predefinedBranchName = _.isNil(this.predefinedVersionControl) ? null : this.predefinedVersionControl.branch;
    if (_.isNil(predefinedBranchName)) {
      return VersionControlHelper.selectInitialBranch(branches);
    }
    const predefinedBranch: Branch | undefined = branches.find(branch => branch.name === predefinedBranchName);
    return _.isNil(predefinedBranch) ? VersionControlHelper.selectInitialBranch(branches) : predefinedBranch;
  }

  private trimRepositoryFormControlValue() {
    const repoNameFormValue = this.repoName;
    if (_.isNil(repoNameFormValue)) {
      return;
    }
    this.form.controls[this.REPOSITORY_FORM_CONTROL_NAME].setValue(repoNameFormValue.trim());
  }

  get initialRepositoryFormValue(): string {
    return !_.isNil(this.predefinedVersionControl) && !_.isNil(this.predefinedVersionControl.repository) ? this.predefinedVersionControl.repository : '';
  }

  get initialPathFormValue(): string {
    return !_.isNil(this.predefinedVersionControl) && !_.isNil(this.predefinedVersionControl.path) ? this.predefinedVersionControl.path : '';
  }

  get repoName(): string | undefined {
    return this.form.value[this.REPOSITORY_FORM_CONTROL_NAME];
  }

  get pathPlaceholder(): string {
    return this.pathRequired ? ' ' : 'default (/)';
  }
}

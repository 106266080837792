import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import { Observable } from 'rxjs';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { TextDiffData } from '../../../common/model/publish-changes/text-diff-data.model';
import { bundlesDiffView } from '../../../model/views';
import { PublishProjectContext } from '../publish-project.context';
import { TextDiffWrapperContext } from '../text-diff-wrapper/text-diff-wrapper.model';

@Injectable()
export class BundlesDiffViewContext extends TextDiffWrapperContext {

  readonly textDiff$: Observable<Diff<TextDiffData> | null>;
  readonly currentBranch$: Observable<string | null>;

  constructor(private store$: Store<AppState>, private publishProjectContext: PublishProjectContext) {
    super();
    this.textDiff$ = this.store$.pipe(select(bundlesDiffView));
    this.currentBranch$ = this.publishProjectContext.currentBranch$;
  }

}

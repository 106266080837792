<adm4-modal-dialog-title class='modal-dialog-title' [header]='header'
                         [showClose]='true'
                         (closeClicked)="closeDialog()">
  <form [formGroup]='form'>
    <div class='content-container'>
      <div class="form-group">
        <div class='secret-creation-form-elements'>
          <div class='file-selection-container' [class.disabled-opacity]='shouldFileUploadBeDisabled'>
            <label for='single-file-selection' class='input-label'>Select file</label>
            <adm4-single-file-input [formControlName]='FILE_CONTROL'
                                    [placeholderText]='"Please select a file..."'
                                    [buttonText]='"Select file"'
                                    [adm4ControlDisable]='shouldFileUploadBeDisabled'
                                    id='single-file-selection'></adm4-single-file-input>
          </div>

          <div [class.disabled-opacity]='shouldFileUploadBeDisabled'>
            <label for='attachment-description' class='input-label'>Description (optional)</label>
            <textarea class='form-control admn4-textarea-input description-textarea'
                      placeholder='Add your description (max 150 characters)'
                      maxlength='150'
                      id='attachment-description'
                      [formControlName]='FILE_DESCRIPTION_CONTROL'
                      [readOnly]='shouldFileUploadBeDisabled'></textarea>
          </div>

          <div class='global-secret-selection' [class.disabled-opacity]='shouldTenantSelectionBeDisabled'>
            <label class='input-label'>Or select existing file</label>
            <mat-form-field class='form-group admn4-input-group-with-icon full-width'>
              <mat-select #matSelect placeholder="Please select a secret..."
                          [formControlName]='SECRET_KEY'
                          [adm4ControlDisable]='shouldTenantSelectionBeDisabled'
                          [disableOptionCentering]='true'
                          (click)='focusDropdownInput()'>
                <adm4-searchable-dropdown-input *ngIf='matSelect.focused'
                                                [sourceItems]="resourceView$ | async"
                                                [searchableFormatFn]='searchableResourceFormatFn'
                                                [placeholder]="'Please select a secret...'"
                                                [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                                (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
                <mat-option>None</mat-option>
                <mat-optgroup *ngFor="let resourceGroup of groupedResources"
                              [label]='resourceGroup.level | capitalizeFirst'
                              [hidden]='!shouldShowResourceGroup(resourceGroup)'>
                  <mat-option *ngFor='let resource of resourceGroup.resources'
                              [value]='resource'
                              [hidden]='!isResourceFilteredOut(resource)'
                              [ngbTooltip]='resource?.description' placement='bottom'>
                    {{getResourceLabel(resource)}}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>

            <div *ngIf='matSelect.value?.description' class='global-resource-description'>
              <strong>Description: </strong>{{matSelect.value.description}}
            </div>

          </div>
        </div>
      </div>

    </div>
    <div mat-dialog-actions>
      <button type='button' class='admn4-button-text'
              (click)='closeDialog()'>
        Cancel
      </button>
      <button class='admn4-button-ellipse-blue' (click)='triggerAttachmentInsert()' [disabled]='form.invalid'>
        Attach
      </button>
    </div>
  </form>
</adm4-modal-dialog-title>

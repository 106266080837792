import { PatternVersionInfo } from '../version-control/pattern-meta-info.model';
import { PatternListData } from './pattern-list-data.model';
import { Dictionary } from '../model/reducer';
import * as _ from 'lodash';
import { Pattern } from './pattern.model';

export class PatternSortingHelper {
  static byLastEditSortingFn(p1: PatternListData, p2: PatternListData, isLabelView: boolean, vcInformationOfPattern: Dictionary<PatternVersionInfo>): number {
    const dateComparationResult = this.compareDates(vcInformationOfPattern[p1.pattern.patternId], vcInformationOfPattern[p2.pattern.patternId]);
    if (isLabelView) {
      const labelCompareResult = this.compareLabels(p1.pattern.label, p2.pattern.label);
      if (labelCompareResult !== 0) {
        return labelCompareResult;
      }
    }
    if (dateComparationResult !== 0) {
      return dateComparationResult;
    }
    return this.byNameSortingFn(p1, p2, isLabelView);
  }

  static byNameSortingFn(p1: PatternListData, p2: PatternListData, isLabelView: boolean): number {
    if (isLabelView) {
      return (p1.pattern.label + p1.pattern.name).localeCompare(p2.pattern.label + p2.pattern.name);
    }
    return p1.pattern.name.localeCompare(p2.pattern.name);
  }

  static getDate(patternVersionInfo: PatternVersionInfo | undefined): string | null | undefined {
    if (_.isNil(patternVersionInfo)) {
      return null;
    }
    return _.get(patternVersionInfo, 'localDate', patternVersionInfo.remoteDate);
  }

  static compareDates(p1VersionInfo: PatternVersionInfo | undefined, p2VersionInfo: PatternVersionInfo | undefined): number {
    const p1Date = this.getDate(p1VersionInfo);
    const p2Date = this.getDate(p2VersionInfo);
    if (_.isNil(p1Date) && !_.isNil(p2Date)) {
      return -1;
    } else if (!_.isNil(p1Date) && _.isNil(p2Date)) {
      return 1;
    } else if (!_.isNil(p1Date) && !_.isNil(p2Date)) {
      return new Date(p2Date).valueOf() - new Date(p1Date).valueOf();
    }
    return 0;
  }

  static compareLabels(p1Label: string | undefined, p2Label: string | undefined): number {
    if (_.isNil(p1Label) && !_.isNil(p2Label)) {
      return 1;
    } else if (!_.isNil(p1Label) && _.isNil(p2Label)) {
      return -1;
    } else if (!_.isNil(p1Label) && !_.isNil(p2Label)) {
      return p1Label.localeCompare(p2Label);
    }
    return 0;
  }

  static byIssueStatus(p1: Pattern, p2: Pattern, patternIdsWithWarning: string[], patternIdsWithInfo: string[]): number {
    const p1HasWarning = patternIdsWithWarning.includes(p1.patternId);
    const p2HasWarning = patternIdsWithWarning.includes(p2.patternId);
    const p1HasInfo = patternIdsWithInfo.includes(p1.patternId);
    const p2HasInfo = patternIdsWithInfo.includes(p2.patternId);

    if (p1HasWarning) {
      return p2HasWarning ? 0 : -1;
    } else {
      if (p2HasWarning) {
        return 1;
      } else {
        if (p1HasInfo) {
          return p2HasInfo ? 0 : -1;
        } else {
          return p2HasInfo ? 1 : 0;
        }
      }
    }
  }

  static byLabelNameSortingFn(group1: [string, PatternListData[]], group2: [string, PatternListData[]], unlabelledPatternGroupName: string): number {
    const group1LabelName: string = group1[0];
    const group2LabelName = group2[0];
    if (_.isEqual(group1LabelName, unlabelledPatternGroupName)) {
      return 1;
    } else if (_.isEqual(group2LabelName, unlabelledPatternGroupName)) {
      return -1;
    }
    return group1LabelName.localeCompare(group2LabelName);
  }
}

import { Observable, combineLatest } from 'rxjs';

import { filter, map } from 'rxjs/operators';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { NavigationService } from '../../navbar/navigation.service';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { applicationInfoView, isAuthDoneView, isAuthenticatedView, isAuthExpiredView } from '../../model/views';

@Injectable()
export class AuthenticationGuard  {


  constructor(private navigationService: NavigationService, private store$: Store<AppState>, public notificationService: ModalNotificationService) {
  }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivateApp(state);
  }

  canActivateChild(_childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivateApp(state);
  }

  private canActivateApp(state: RouterStateSnapshot): Observable<boolean> {
    const observable = combineLatest([
      this.store$.pipe(select(isAuthenticatedView)),
      this.store$.pipe(select(isAuthDoneView)),
      this.store$.pipe(select(isAuthExpiredView)),
      // be sure that applicationInfo loaded so we can safley hide/show UI element using directive
      this.store$.pipe(select(applicationInfoView))
    ]);
    const authMap = map(([isAuthenticated, _isAuthDone, isExpired, _appInfo]) => {
      if (isAuthenticated === false) {
        if (isExpired) {
          this.notificationService.openConfirmDialog(
            {
              headerTitle: 'Warning',
              title: 'Your session has ended',
              description: 'Your session expired, or you logged out in another browser tab. <br/>Please log in again to continue working.\n'
            },
            {cancelButtonText: 'Cancel', confirmButtonText: 'Log in'}
          ).afterClosed().subscribe((confirmed?: boolean) => {
            if (confirmed === true) {
              setTimeout(() => this.navigationService.navigateToLogin(state.url));
            }
          });
        } else {
          setTimeout(() => this.navigationService.navigateToLogin(state.url));
        }
        return false;
      }
      return true;
    });
    return observable.pipe(filter(([_isAuthenticated, isAuthDone]) => isAuthDone), authMap);
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { dialogs } from '../modal-dialog-notification.component';
import { NotificationType } from '../../notification/notification-type.enum';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import { localStorageAlwaysSaveChanges } from '../../common/constants/local-storage-keys.constants';
import { closeModalOnEscape } from '../modal-dialog.helper';
import { Subject } from 'rxjs';
import { SaveChangesDialogData } from './save-changes-data.model';

@Component({
  selector: 'adm4-save-changes-dialog',
  template: `
    <adm4-modal-dialog-title (closeClicked)="closeDialog(false)"
                             [ngClass]='options.style'
                             [isFullHeightContent]='false'
                             header="Warning">
      <div mat-dialog-content class="content-container">
        <span class="title">
          Unsaved changes
        </span>
        <br>
        <div class="description">
          Do you want to save your changes?
        </div>
        <div *ngIf='displayAutoSave' class='always-save-option'>
          <label>
            <mat-checkbox [(ngModel)]='shouldAlwaysSaveChanges'></mat-checkbox>
            <span>Save changes automatically in the future</span>
          </label>
        </div>
      </div>
      <mat-dialog-actions>
        <button class='admn4-button-text'
                (click)='closeDialog(false)'>
          Cancel
        </button>
        <button class='admn4-button-ellipse-default' cdkFocusInitial
                (click)="triggerSaveChanges()">
          Save changes
        </button>
      </mat-dialog-actions>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['./save-changes-dialog.component.scss', '../modal-dialog-notification.component.scss']
})
export class SaveChangesDialogComponent implements OnInit, OnDestroy {
  public options = dialogs[NotificationType.DIALOG_WARNING];
  shouldAlwaysSaveChanges = false;
  displayAutoSave: boolean;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(@Inject(MAT_DIALOG_DATA) public data: SaveChangesDialogData,
    private dialogRef: MatDialogRef<SaveChangesDialogComponent, boolean>) {

    this.displayAutoSave = this.data.displayAutoSaveOption;
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  closeDialog(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }

  triggerSaveChanges(): void {
    if (this.shouldAlwaysSaveChanges) {
      LocalStorageHelper.save(localStorageAlwaysSaveChanges, this.shouldAlwaysSaveChanges.toString());
    }
    this.closeDialog(true);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'bundleDownloadUrl'
})
export class BundleDownloadUrlPipe implements PipeTransform {

  transform(bundleKey: string): string {
    return `${environment.baseUrl}/bundles/${bundleKey}`;
  }
}

import { Observable, of } from 'rxjs';
import { Revision } from '../version-control/revision/revision.model';
import * as _ from 'lodash';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { Diff } from '../common/model/publish-changes/diff.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHelper } from '../common/helpers/error.helper';
import { PublishErrorFieldSource } from '../common/constants/publish.constants';
import { ModalNotificationService } from '../notification/modal-notification.service';
import { HTTP_STATUS_BAD_REQUEST, HTTP_STATUS_CONFLICT, HTTP_STATUS_FORBIDDEN } from '../shared/http-status-codes.constants';

export interface IPublishEffectMixin {
  /**
   * Label of what is being published, used for feedback to user
   */
  publishItemLabel: string;
  /**
   * To prevent loading same revision information because different meta entries point to same commit we cache requests in this map, which stores observables with cached values
   * It should be alive only while inventory publish is open and cleared when it's closed
   * cache.ts is not used here, because we're not caching plain values, but only observables with publishReplay, which I think putting into store would be an overkill
   * although moving there would not a bit deal, if it turns out to be better at some point
   */
  revisionCache: Map<string, Observable<Revision>>;
  modalNotificationService: ModalNotificationService;
  /**
   * This method should be provided by mixin user class
   */
  getRevisionOfCommit$: (key: string, commitId: string) => Observable<Revision>;
  getDiffWithRevision$: <T extends object, D extends Diff<T> | null = Diff<T>>(inventoryKey: string, commitId: string | undefined, mapper: (revision?: Revision) => D) => Observable<D>;
  handlePublishError: (error: HttpErrorResponse) => void;
}

export class PublishEffectMixin implements IPublishEffectMixin {
  publishItemLabel: string;

  revisionCache: Map<string, Observable<Revision>>;
  modalNotificationService: ModalNotificationService;

  getRevisionOfCommit$: (key: string, commitId: string) => Observable<Revision>;

  /**
   * Returns Observable of revision for a inventory and a commitId
   * It has a side effect which stores new observable per commitId in the revisionCache, so in case next time when same commit is requested it will return cached observable
   * instead of doing a backend call, caching is achieved by publishReplay(1), refCount()
   * @param inventoryKey
   * @param commitId
   */
  getCachableRevision$(inventoryKey: string, commitId: string): Observable<Revision> {
    const cachedRevision$: Observable<Revision> | undefined = this.revisionCache.get(commitId);
    const revision$ = !_.isNil(cachedRevision$) ? cachedRevision$ : this.getRevisionOfCommit$(inventoryKey, commitId).pipe(publishReplay(1), refCount());
    this.revisionCache.set(commitId, revision$);
    return revision$;
  }

  getDiffWithRevision$<T extends object, D extends Diff<T> | null = Diff<T>>(inventoryKey: string, commitId: string | undefined, mapper: (revision?: Revision) => D): Observable<D> {
    const revision$: Observable<Revision | undefined> = _.isNil(commitId) ? of(undefined) : this.getCachableRevision$(inventoryKey, commitId);
    return revision$.pipe(map((revision?: Revision) => mapper(revision)));
  }

  handlePublishError(error: HttpErrorResponse): void {
    const NO_CHANGES_ERROR_CODE = HTTP_STATUS_BAD_REQUEST;
    if (error.status === HTTP_STATUS_CONFLICT && ErrorHelper.hasSource(error, {FIELD: PublishErrorFieldSource.LastModification})) {
      this.modalNotificationService.openErrorDialog({title: 'Concurrent update', description: `Please refresh the page. Someone else modified the ${this.publishItemLabel.toLowerCase()}`});
    } else if (error.status === HTTP_STATUS_CONFLICT && ErrorHelper.hasSource(error, {FILED: PublishErrorFieldSource.CommitId})) {
      this.modalNotificationService.openErrorDialog({title: 'Remote is ahead', description: `There is a newer version of this ${this.publishItemLabel.toLowerCase()} in the repository. Please, update it before trying to publish again.`});
    } else if (error.status === NO_CHANGES_ERROR_CODE) {
      this.modalNotificationService.openErrorDialog({description: 'No local changes to commit'});
    } else if (error.status === HTTP_STATUS_FORBIDDEN) {
      this.modalNotificationService.openErrorDialog({description: 'Not authorized to publish'});
    } else {
      this.modalNotificationService.openErrorDialog({description: 'Something went wrong with the publish'});
    }
  }
}

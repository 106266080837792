import { PatternListData } from './pattern-list-data.model';
import { PatternVersionInfo } from '../version-control/pattern-meta-info.model';
import { Dictionary } from '../model/reducer';
import { PatternSortingHelper } from './pattern-sorting.helper';

export enum PatternSortingOptionTitles {
  Alphabetically = 'Alphabetical',
  LastEdited = 'Last edit'
}

export const patternSortingOptions: PatternSortingOption[] = [
  {
    title: PatternSortingOptionTitles.Alphabetically,
    iconClass: 'fa-sort-alpha-asc',
    sortingFn: (p1: PatternListData, p2: PatternListData, isLabelView: boolean) => {
      return PatternSortingHelper.byNameSortingFn(p1, p2, isLabelView);
    }
  },
  {
    title: PatternSortingOptionTitles.LastEdited,
    iconClass: 'fa-clock-o',
    sortingFn: (p1: PatternListData, p2: PatternListData, isLabelView: boolean, vcInformationOfPattern: Dictionary<PatternVersionInfo>) => {
      return PatternSortingHelper.byLastEditSortingFn(p1, p2, isLabelView, vcInformationOfPattern);
    }
  }
];

export interface PatternSortingOption {
  title: PatternSortingOptionTitles;
  iconClass: string;
  sortingFn: (p1: PatternListData, p2: PatternListData, isLabelView: boolean, vcInformationOfPattern?: Dictionary<PatternVersionInfo>) => number;
}

<div class="full-height-flex">
  <div class='full-height' [ngClass]='boxShadowClass'>
      <div class='full-height-flex'>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <div class='full-height-flex' [class.info-container]='shouldDisplaySearchContent() || isLoading' #editorContainer>
              <div *ngIf='shouldDisplaySearchContent()' class='resource-info-content'>
                <mat-icon>search</mat-icon>
                Please select a resource to see details
              </div>
              <span *ngIf='isLoading' class='resource-info-content'><mat-spinner [diameter]='60'></mat-spinner></span>
              <span *ngIf='isLoading' class='resource-info-content'> Loading the resource from the Kubernetes cluster. Please wait...</span>
              <ngx-monaco-editor  *ngIf='content'
                                  [(ngModel)]='content'
                                  [options]='editorOptions'
                                  (onInit)='initializeEditor($event)'>
              </ngx-monaco-editor>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>

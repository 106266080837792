import { NgModule } from '@angular/core';
import { UserMenuComponent } from './user-menu.component';
import { ModalDialogModule } from '../modal-dialog/modal-dialog.module';
import { CommonModules } from '../common/common.module';
import { ChangePasswordDialogComponent } from './change-password-dialog.component';
import { ChangePasswordComponent } from './change-password.component';
import { ChangePasswordService } from './change-password.service';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  imports: [
    CommonModules,
    ModalDialogModule,
    BrowserModule
  ],
  declarations: [
    UserMenuComponent,
    ChangePasswordDialogComponent,
    ChangePasswordComponent
  ],
  providers: [
    ChangePasswordService
  ],
  exports: [
    UserMenuComponent
  ]
})
export class UserMenuModule {
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeploymentFileInfo } from './deployment-file-urls.model';

@Injectable()
export class DeploymentFileViewService {
  /**
   * Emits value to either view file or close viewing, providing file id should show file, providing null should close it
   * @type {BehaviorSubject<DeploymentFileInfo | null>}
   */
  viewingFileInfo: BehaviorSubject<DeploymentFileInfo | null> = new BehaviorSubject<DeploymentFileInfo | null>(null);
}

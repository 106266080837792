import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { filter, first, mapTo, shareReplay, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { AppState } from '../../model/reducer';
import { BROWSER_SUPPORT } from '../utils/browser.support.helper';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { availableVersionView, UpgradeInfo } from '../../model/views';
import { LocalStorageHelper } from '../helpers/local-storage.helper';
import {
  localStorageIgnoreUnsupportedBrowser,
  localStorageUpgradeLastNotified,
} from '../constants/local-storage-keys.constants';
import { loadMarketPlaceItems } from '../../resources/marketplace/marketplace.actions';

// 7 days
const UPGRADE_NOTIFICATION_SNOOZE_PERIOD_MS: number = 7 * 24 * 60 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class StartupChecksService {

  private readonly afterAuthenticatedOnce: Observable<void>;

  public readonly checksFinished: Observable<void>;

  constructor(
      private store$: Store<AppState>,
      private modals: ModalNotificationService,
      @Inject(BROWSER_SUPPORT) private readonly isBrowserSupported: boolean,
  ) {
    this.afterAuthenticatedOnce = this.store$.select(appState => appState.userState.loaded).pipe(
        filter(loaded => loaded),
        first(),
        switchMap(() => this.store$.select(appState => appState.userState.authenticated).pipe(
            filter(authenticated => authenticated),
            first(),
        )),
        mapTo(undefined),
        shareReplay(),
    );
    this.afterAuthenticatedOnce.subscribe(() => {
      this.store$.dispatch(loadMarketPlaceItems());
    });
    this.checksFinished = this.afterAuthenticatedOnce.pipe(
        switchMap(() => this.checkBrowserSupport()),
        switchMap(() => this.checkOnceUpgradeVersion()),
        first(),
        shareReplay(),
    );
    this.checksFinished.subscribe();
  }

  private checkBrowserSupport(): Observable<void> {
    const ignoreUnsupported = localStorage.getItem(localStorageIgnoreUnsupportedBrowser) === 'true';
    if (ignoreUnsupported || this.isBrowserSupported) {
      return of(undefined);
    }
    return this.modals.openWarningDialog(
        {
          headerTitle: 'Warning',
          title: 'Unsupported browser ',
          description: 'You’re using an unsupported browser. <br/> Please use the latest version of Chrome or Edge instead.'
        }
    ).afterClosed().pipe(mapTo(undefined));
  }

  /**
   * Checks the store for available upgrades and notifies the user if needed.
   * The returned observable emits once when the dialog is closed.
   */
  private checkOnceUpgradeVersion(): Observable<void> {
    return this.store$.select(availableVersionView).pipe(
        filter((availableVersion: undefined | false | UpgradeInfo): availableVersion is false | UpgradeInfo => availableVersion !== undefined),
        first(),
        switchMap((availableVersion: false | UpgradeInfo) => {
          if (availableVersion && !this.isUpgradeNotificationSnoozed()) {
            return this.modals.openUpgradeAvailableDialog().afterClosed().pipe(first());
          } else {
            return of(undefined);
          }
        }),
        mapTo(undefined),
        first(),
    );
  }

  private isUpgradeNotificationSnoozed(): boolean {
    const rawLastViewedTime: string | null = LocalStorageHelper.retrieve(localStorageUpgradeLastNotified);
    if (!rawLastViewedTime) {
      // if the value is not present, the notification was never snoozed yet
      return false;
    }
    const lastViewedTimeMs = Date.parse(rawLastViewedTime);
    const nowMs = new Date().getTime();
    return nowMs - lastViewedTimeMs < UPGRADE_NOTIFICATION_SNOOZE_PERIOD_MS;
  }
}

<div class='empty-project-message-wrapper'>
	<section class='empty-project-message'>
		<h4>Get started with nevisAdmin 4</h4>

		<p>In nevisAdmin 4, you build your configuration using "patterns".
			Each pattern comes with a form. In the form, you configure one component or feature of the NEVIS Security Suite.
			By adding references (links) between the patterns, you combine the various settings into one overall
			configuration.</p>
		<p>To get started, just add some patterns:</p>

		<ul>
			<li *ngFor="let plugin of pluginsForCreateLinks">
				<adm4-link [isDisabled]='isProjectReadOnly|async' tooltipText='You dont have permission to edit this project.' (click)='createPattern(plugin)'>
				New {{plugin.name}}
				</adm4-link><br/>
        <div [innerHTML]="getDocumentation(pluginDocView$ | async, plugin.className)"></div>
			</li>
			<li>
				<adm4-link [isDisabled]='isProjectReadOnly | async' tooltipText='You dont have permission to edit this project.'
				(click)='openCreatePatternModalDialog()'>Add Pattern</adm4-link>
				<br/>
        <h2></h2><p>Select any pattern from the pattern library.</p>
			</li>
		</ul>
		<p>The actual host names of your NEVIS infrastructure are not configured within patterns.
			Instead, you define <a href='javascript:void(0)' (click)="navigateToInventories()">infrastructure inventories</a>.
			For each stage, such as TEST, INT or PROD, create one inventory.
			To make a configuration setting stage-specific, create a variable within the pattern form.
			Then, define the value of the variable within each inventory.</p>

		<p>Finally,
			<adm4-link (click)="openDeployModalDialog()">deploy</adm4-link>
			the configuration patterns
			to your inventory of choice and enjoy NEVIS in action!
		</p>
	</section>
</div>

import { isValidSplitPaneConfig, SplitPaneConfig } from '../model/split-pane-config.model';
import { Dictionary } from '../../model/reducer';
import { LocalStorageHelper } from './local-storage.helper';
import * as _ from 'lodash';

/**
 * Helper contains methods to handle resizing/collapsing of Angular Split module areas
 */
export class ResizeHelper {
  /**
   * Recalculates configuration on resizing after dragend
   * @param previousResizeConfig - state of configuration before resize
   * @param sizes - list of numbers indicating size of each area after resize
   */
  static updateResizeConfigOnDrag(previousResizeConfig: SplitPaneConfig, sizes: number[]): SplitPaneConfig {
    return {...previousResizeConfig, size: sizes[previousResizeConfig.order]};
  }

  /**
   * Returns stored configuration of split pane from local storage
   * Returns defaultConfig if local storage does not contain any stored config yet or when stored config is invalid
   * @param localStorageKey - key of configuration in local storage
   * @param defaultConfig - default configuration of split pane which will be used to invalidate stored config and returned if stored is invalid
   */
  static retrieveSplitPaneConfig(localStorageKey: string, defaultConfig: Dictionary<SplitPaneConfig>): Dictionary<SplitPaneConfig> {
    const storedConfig: string | null = LocalStorageHelper.retrieve(localStorageKey);
    if (_.isNil(storedConfig)) {
      return defaultConfig;
    }
    try {
      const parsedStoredConfig: any = JSON.parse(storedConfig);
      const isStoredConfigValid: boolean = _.isObject(parsedStoredConfig)
        && _.keys(defaultConfig).every((key: string) => key in parsedStoredConfig && isValidSplitPaneConfig(parsedStoredConfig[key]));
      if (!isStoredConfigValid) {
        this.storeSplitPaneConfig(localStorageKey, defaultConfig);
        return defaultConfig;
      }
      return parsedStoredConfig;
    } catch (e) {
      return defaultConfig;
    }
  }

  /**
   * Saves configuration of split pane in local storage if it does not contain areas sized to 0 when they are not supposed to be collapsible
   * @param localStorageKey - key of configuration in local storage
   * @param configToSave - configuration that will be stored
   */
  static storeSplitPaneConfig(localStorageKey: string, configToSave: Dictionary<SplitPaneConfig>): void {
    // when column is closed without being collapsed, it should not be stored, this can be cleaned up, when angular split is updated to v3, because it'll have min/max size feature
    if (_.values(configToSave).some((config: SplitPaneConfig) => !config.isCollapsed && Math.floor(config.size) === 0)) {
      return;
    }
    LocalStorageHelper.save(localStorageKey, JSON.stringify(configToSave));
  }
}

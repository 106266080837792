import * as _ from 'lodash';
import { NavigationConstants } from '../../constants/navigation.constants';
import { DeployState } from '../../../model/deploy/deploy.reducer';
import { TenantHelper } from '../../helpers/tenant.helper';

export class ApplicationTitleHelper {

  static isWelcomePage(url: any) {
    return _.includes(url, NavigationConstants.WELCOME);
  }

  static isSummary(url: any) {
    return _.endsWith(url, `/${NavigationConstants.OVERVIEW}`);
  }

  static isPatternEditorScreen(url: any, patternInstanceFromStore: any): boolean {
    return this.isOnProject(url) &&
      this.isPatternEditorOpen(url) &&
      !_.isNil(patternInstanceFromStore) && !this.isModalOpen(url);
  }

  static isOnInventoriesScreen(url: any) {
    return _.includes(url, `/${NavigationConstants.INVENTORIES}`) && !this.isModalOpen(url);
  }

  static isResourcesScreen(url: string): boolean {
    return _.includes(url, NavigationConstants.RESOURCES) && !this.isModalOpen(url);
  }

  static isDeployScreen(url: string, deployState: DeployState): boolean {
    return _.includes(url, '(modal:deploy)') && deployState.isWizardOpened;
  }

  static isIssueScreen(url: string, projectKey: string | null): boolean {
    return !_.isNil(projectKey) && _.includes(url, `/${NavigationConstants.PROJECTS}/${projectKey}/${NavigationConstants.ISSUES}`) && !this.isModalOpen(url);
  }

  static isReportsScreen(url: string, projectKey: string | null): boolean {
    return !_.isNil(projectKey) && _.includes(url, `/${NavigationConstants.PROJECTS}/${projectKey}/${NavigationConstants.REPORTS}`) && !this.isModalOpen(url);
  }

  static isVariableScreen(url: string, projectKey: string | null): boolean {
    return !_.isNil(projectKey) && _.includes(url, `/${NavigationConstants.PROJECTS}/${projectKey}/${NavigationConstants.VARIABLES}`);
  }

  static isPublishScreen(url: string): boolean {
    return _.endsWith(url, '(modal:publish)');
  }

  static getTitleOfInventoriesScreen(url: string): string {
    const regexp = new RegExp(`(${NavigationConstants.INVENTORIES}|${NavigationConstants.INVENTORY_SETTINGS})/([A-Z0-9_-]+(?::[0-9A-F]{7})?)`);
    const exec: RegExpExecArray | null = new RegExp(regexp).exec(url);
    const inventoryKey = exec && exec.length >= 3 ? exec[2] : '';
    return TenantHelper.cropTenantFromKey(inventoryKey);
  }

  static getTitleOfGraphViewScreen(url: string): string {
    const regexp = new RegExp(`(/${NavigationConstants.GRAPH_VIEW}/${NavigationConstants.PROJECTS})/([A-Z0-9_-]+(?::[0-9A-F]{7})?)`);
    const exec: RegExpExecArray | null = new RegExp(regexp).exec(url);
    const projectKey = exec && exec.length >= 3 ? exec[2] : '';
    return TenantHelper.cropTenantFromKey(projectKey);
  }

  static isModalOpen(url): boolean {
    return _.includes(url, '(modal:');
  }

  static isProjectSettings(url: string): boolean {
    const projectSettingsRegexp: RegExp = new RegExp(`/${NavigationConstants.RESOURCES}/${NavigationConstants.PROJECT_SETTINGS}`);
    return projectSettingsRegexp.test(url);
  }

  static isInventorySettings(url: string): boolean {
    const projectSettingsRegexp: RegExp = new RegExp(`/${NavigationConstants.RESOURCES}/${NavigationConstants.INVENTORY_SETTINGS}`);
    return projectSettingsRegexp.test(url);
  }

  private static isPatternEditorOpen(url: any): boolean {
    return _.includes(url, `/${NavigationConstants.PATTERNS}/`);
  }

  private static isOnProject(url: any): boolean {
    return _.includes(url, `/${NavigationConstants.PROJECTS}/`);
  }

  static isPatternEditorOverviewSreen(url: string) {
    const projectOverviewRegex: RegExp = new RegExp(`/${NavigationConstants.OVERVIEW}`);
    return projectOverviewRegex.test(url) ;
  }

  static isGraphViewPage(url: string): boolean {
    return _.includes(url, NavigationConstants.GRAPH_VIEW);
  }
}

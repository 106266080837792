import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { MenuHelper } from '../../common/helpers/menu.helper';
import { NavigationConstants } from '../../common/constants/navigation.constants';

@Component({
  selector: 'adm4-pattern-notes',
  template: `
    <form [formGroup]="form">
      <div class='pr_container line-indicatable-validated pattern-notes-container'>
        <div class='form-group'>
          <div class='pr-heading pattern-note-heading' (click)='onPatternNotesCollapse()'>
            <i class='fa fa-book' aria-hidden="true"></i>
            <span *ngIf='shouldDisplayInfoCircle' class="highlight-circle"></span>
            <label class='pr-label pattern-note-label' for='patternNote'>Notes</label>
            <mat-icon class='expand-icon'>{{isPatternNotesCollapsed ? 'expand_less' : 'expand_more'}}</mat-icon>
          </div>
          <div [hidden]='isPatternNotesCollapsed' class='input-field-container'>
            <textarea class='admn4-textarea-input form-control textarea-property pattern-notes-textarea' id='patternNote'
                      [placeholder]='"Add your notes..."'
                      [rows]='DEFAULT_ROW_NUMBER'
                      [formControlName]='fcName'
                      [value]='patternNoteValue'
                      [readonly]='readOnly'></textarea>
          </div>
        </div>
      </div>
    </form>
  `,
  styleUrls: ['../widget.component.scss']
})
export class PatternNotesWidgetComponent {
  @Input() form: UntypedFormGroup;
  @Input() fcName: string;
  @Input() readOnly: boolean;
  @Input() isPatternNotesCollapsed;
  @Output() patterNoteCollapse = new EventEmitter<boolean>();

  readonly DEFAULT_ROW_NUMBER = 3;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  get hasPatternNotes(): boolean {
    return !_.isEmpty(this.patternNoteValue);
  }

  get patternNoteValue() {
    return this.form.controls[this.fcName].value;
  }

  get isPublishScreen(): boolean {
    return MenuHelper.isActiveLink(this.activatedRoute.snapshot, NavigationConstants.PUBLISH_PROJECT);
  }

  get shouldDisplayInfoCircle(): boolean {
    return this.hasPatternNotes && !this.isPublishScreen;
  }

  onPatternNotesCollapse(): void {
    this.isPatternNotesCollapsed = !this.isPatternNotesCollapsed;
    this.patterNoteCollapse.emit(this.isPatternNotesCollapsed);
  }
}

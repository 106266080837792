import { ProjectSummaryReportType } from './project-summary-report.model';
import * as _ from 'lodash';

export class ProjectSummaryReportHelper {

  static shouldRowBeDisplayedForFilter(row: ProjectSummaryReportType[], filter: string): boolean {
    if (_.isEmpty(filter)) {
      return true;
    }

    return row.some((reportData: ProjectSummaryReportType) => this.shouldElementBeDisplayedForFilter(reportData, filter));
  }

  static shouldElementBeDisplayedForFilter(data: ProjectSummaryReportType, filter: string): boolean {
    filter = filter.trim().toLowerCase();
    return this.isArrayIncludesTerm(Object.values(data), filter);
  }

  private static isArrayIncludesTerm(array: any[], filter: string): boolean {
    return _.some(array, value => {
      if (_.isArray(value)) {
        return this.isArrayIncludesTerm(value, filter);
      }
      if (_.isNumber(value)) {
        value = value + '';
      }
      return this.isStringIncludesTerm(value, filter);
    });
  }

  private static isStringIncludesTerm(item: string, filter: string): boolean {
    return !_.isNil(item) && item.toLowerCase().includes(filter);
  }
}

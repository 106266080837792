import { PatternInstance, PatternProperty } from '../patterns/pattern-instance.model';
import * as _ from 'lodash';
import { isVariable } from '../shared/pattern.utils';

export class ValueNormalizer {
  /**
   *  We have to convert single value element to a list for our propertyComponents. Before saving the data we need to convert back to original format to not make 'phantom changes'
   *     A) modification of a single element should not be a one element list
   *     B) if in the original pattern the value is undefined than it should remain undefined
   *     C) if boolean checkbox was not boolen, than false = no change.
   * @param {PatternInstance} patternInstance
   * @param {PatternInstance} originalPattern
   * @returns {PatternInstance}
   */
  static normalizePatternInstance(patternInstance: PatternInstance, originalPattern: PatternInstance): PatternInstance {
    return Object.assign({}, originalPattern, {
      _status: undefined, // if pattern has issues, we don't need to send them when we save it
      deploymentHosts: this.getNewValue(patternInstance.deploymentHosts),
      notes: this.getNewValue(patternInstance.notes),
      label: this.getNewValue(patternInstance.label),
      name: this.getNewValue(patternInstance.name, ''),
      properties: patternInstance.properties.reduce((newPatternProperties: PatternProperty[], property: PatternProperty) => {
        const originalProperty = originalPattern.properties.find((prop: PatternProperty) => prop.propertyKey === property.propertyKey);
        const newPropertyValue = this.getNewValue(property.value);
        // special case for checkboxes, if original property is not boolean, then newProperty shouldn't change to false
        const isFalsyToFalse = !originalProperty && newPropertyValue === false;
        if (_.isNil(newPropertyValue) || isFalsyToFalse) {
          return newPatternProperties;
        }
        const updatedPropertyValue = this.getNormalizedPropertyValue(originalProperty, newPropertyValue);
        const updatedProperty = Object.assign({}, property, {
          value: updatedPropertyValue
        });
        return _.concat(newPatternProperties, [updatedProperty]);
      }, [])
    });
  }

  private static isEmptyString(value): boolean {
    return _.isString(value) && value.length === 0;
  }
  private static isEmptyArray(value): boolean {
    return _.isArray(value) && (value.length === 0 || (value.length === 1 && this.isEmptyString(value[0])));
  }

  private static isEmptyKeyValue(value: any): boolean {
    return _.isEqual([{'': ''}], value);
  }

  /**
   * If new value represents empty value we don't want to save it so we return undefined or, if specified, specific empty value, otherwise the value itself
   * @param {T} value
   * @param emptyValue
   * @returns {T | undefined}
   */
  private static getNewValue<T extends string | string[] | undefined, EmptyValue>(value: T, emptyValue?: EmptyValue): T | EmptyValue | undefined {
    const isEmptyValue = this.isEmptyString(value) || this.isEmptyArray(value) || this.isEmptyKeyValue(value);
    return isEmptyValue ? emptyValue : value;
  }

  /**
   * If original property contained value as a string and new value is array of one element to prevent phantom change (because it might have been same value saved in different structure) we send it back as a string
   * We also check if previously value was set to a variable, then we don't need to normalize anything
   * @param {PatternProperty | undefined} originalProperty
   * @param newPropertyValue
   * @returns {any}
   */
  private static getNormalizedPropertyValue(originalProperty: PatternProperty | undefined, newPropertyValue: string | string[]): string | string[] {
    if (_.isNil(originalProperty)) {
      return newPropertyValue;
    }

    const isNewArrayValue = _.isArray(newPropertyValue) && newPropertyValue.length === 1;
    const requiresNormalization = !_.isArray(originalProperty.value) && !isVariable(originalProperty.value) && isNewArrayValue;
    return requiresNormalization ? newPropertyValue[0] : newPropertyValue;
  }
}

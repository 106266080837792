import { Directive } from '@angular/core';

import { MatSelect } from '@angular/material/select';

/**
 * This directive overrides the `hideSingleSelectionIndicator` property of all `mat-select`s to `true`,
 * ie. the single selection checkmark won't be displayed by default. </br>
 * If you want to enable it for some specific `mat-select`s,
 * add the attribute `[allowSingleSelectionIndicator]` to those `mat-select`s.
 * Source: https://timdeschryver.dev/blog/use-angular-directives-to-extend-components-that-you-dont-own
 */
@Directive({
  // this overrides an existing component's input on purpose
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-select:not([allowSingleSelectionIndicator])'
})
export class MatSelectDefaultSingleSelectionIndicatorDirective {

  constructor(matSelect: MatSelect) {
    matSelect.hideSingleSelectionIndicator = true;
  }

}

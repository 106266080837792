export const PROJECT_ZIP_FORM_CONTROL_NAME = 'project_zip';
export const PROJECT_KEY_MODE_FORM_CONTROL_NAME = 'project_key_mode';
export const CREATED_PROJECT_KEY_FORM_CONTROL_NAME = 'created_project_key';
export const EXISTING_PROJECT_KEY_FORM_CONTROL_NAME = 'existing_project_key';

export enum ProjectKeyMode {
  New = 0,
  Existing = 1
}

export interface FileBasedImportProjectFormValue {
  [PROJECT_ZIP_FORM_CONTROL_NAME]: File | null;
  [PROJECT_KEY_MODE_FORM_CONTROL_NAME]: ProjectKeyMode;
  [CREATED_PROJECT_KEY_FORM_CONTROL_NAME]: string;
  [EXISTING_PROJECT_KEY_FORM_CONTROL_NAME]: string | null;
}

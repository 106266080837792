import { catchError, map } from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DiffEditorModel } from 'ngx-monaco-editor-v2';
import { DeploymentFileInfo } from './deployment-file-urls.model';
import * as _ from 'lodash';
import { FileService } from '../../common/services/file/file.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { indicate } from '../../rx.utils';
import { createDiffEditorModel } from './deployment-file-view.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHelper } from '../../common/helpers/error.helper';
import { HTTP_STATUS_NOT_FOUND } from '../../shared/http-status-codes.constants';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { editor } from 'monaco-editor';
import ICodeEditor = editor.ICodeEditor;
import { Maybe } from '../../common/utils/utils';

@Component({
  selector: 'adm4-deployment-file-view',
  template: `
    <div class='full-height-flex'>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <div class='full-height-flex'>
            <div *ngIf='!compactMode' class='file-info'>
              <h2 class='file-name step-content-header'>{{fileInfo.path}} <span *ngIf='shouldDisplayDiff' class='diff-label'>(old ↔ new)</span></h2>
            </div>
            <div class='editor-container-wrapper' [class.editor-container-wrapper-padding]='!compactMode' [adm4LoadingIndicator]='loading$'>
              <div class='editor-container' [ngClass]='boxShadowClass' #editorContainer>
                <ngx-monaco-diff-editor [class.noRemoteFile]="!remoteFile.code" *ngIf='shouldDisplayDiff; else onlyLocal'
                                        [modifiedModel]='localFile'
                                        [originalModel]='remoteFile'
                                        [options]='editorOptions'
                                        (onInit)='initializeEditor($event)'></ngx-monaco-diff-editor>
                <ng-template #onlyLocal>
                  <ngx-monaco-editor *ngIf='shouldDisplayLocalOnly'
                                     [ngModel]='localFile.code'
                                     [options]='editorOptions'
                                     (onInit)='initializeEditor($event)'>
                  </ngx-monaco-editor>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./deployment-file-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentFileViewComponent implements OnChanges {
  @Input() fileInfo: DeploymentFileInfo;
  @Input() boxShadowClass: Maybe<string>;
  /** If true, component occupies less space. No header with file path and diff label, no padding. */
  @Input() compactMode = false;

  @ViewChild('editorContainer', {static: false}) editorContainer: ElementRef;

  editorOptions: any = {
    readOnly: true,
    scrollBeyondLastLine: false,
    minimap: {
      enabled: false
    }
  };
  localFile: DiffEditorModel;
  remoteFile: DiffEditorModel;

  /** Subject which emits true in case the file contents are loading, emits false if the file content is loaded. */
  loading$ = new Subject<boolean>();

  constructor(
    private fileService: FileService,
    private chr: ChangeDetectorRef,
    private modalNotificationService: ModalNotificationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileInfo']) {
      this.loadFiles(this.fileInfo);
    }
  }

  get shouldDisplayLocalOnly(): boolean {
    return !this.fileInfo.remoteFileUrl && !this.fileInfo.remoteRequired && !_.isNil(this.localFile);
  }

  get shouldDisplayDiff(): boolean {
    return !_.isNil(this.localFile) && !_.isNil(this.remoteFile);
  }

  private loadFiles(fileUrls: DeploymentFileInfo) {
    // We want to load the local and the remote file parallel.
    forkJoin([
      this.downloadLocalFile(fileUrls),
      this.downloadRemoteFile(fileUrls)
    ]).pipe(
      // This bind the loading$ Subject to our Observable which downloads the file contents.
      indicate(this.loading$),
      map((fileContents: string[]) => {
        const local = fileContents[0];
        const remote = fileContents[1];
        const transformedLocal = _.isFunction(this.fileInfo.fileContentMapper) ? this.fileInfo.fileContentMapper(local) : local;
        return [
          transformedLocal,
          remote
        ];
      })
    ).subscribe((results: string[]) => {
      const localFileContent = results[0];
      const remoteFileContent = results[1];
      this.localFile = createDiffEditorModel(localFileContent);
      if (this.fileInfo.remoteRequired) {
        this.remoteFile = createDiffEditorModel(remoteFileContent);
      }
      this.chr.markForCheck();
    });
  }

  private downloadLocalFile(fileUrls: DeploymentFileInfo): Observable<string> {
    return this.fileService.loadFileContent(fileUrls.localFileUrl);
  }

  private downloadRemoteFile(fileUrls: DeploymentFileInfo): Observable<string> {
    if (!_.isNil(fileUrls.remoteFileUrl)) {
      return this.fileService.loadFileContent(fileUrls.remoteFileUrl).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === HTTP_STATUS_NOT_FOUND) {
            return of('');
          }
          const errorMessage = ErrorHelper.getErrorDetail(error, 'Something went wrong with downloading the remote file.');
          this.modalNotificationService.openErrorDialog({title: 'Could not download remote file', description: errorMessage});
          return of('');
        }));
    } else if (this.fileInfo.remoteRequired) {
      return of('');
    } else {
      return of('');
    }
  }

  initializeEditor(initEditor: ICodeEditor): void {
    initEditor.layout({
      width: this.editorContainer.nativeElement.clientWidth,
      height: this.editorContainer.nativeElement.clientHeight
    });
  }
}

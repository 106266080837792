import { GenerationOutputModel, PlanningOutputModel } from '../deploy/deployment-preview/planning-output.model';
import { TreeNodeHelper } from './tree-node.helper';
import { Maybe } from '../../common/utils/utils';

export interface TreeNode {
  id: any;
  name: string;
  type: string;
  parent?: TreeNode;
  nrWarnings: number;
  nrErrors: number;
  /** It's from BE, when it's empty the node should be greyed out */
  tasks: string[];
  hasDescendantTask: boolean;
  /** It's used for holding data that is displayed on the UI */
  children: TreeNode[];
  details?: any;
  output?: PlanningOutputModel | GenerationOutputModel;
  /**
   * True if the node should be filtered out, i.e. hidden. Optional, so if not specified anywhere, the node will not be filtered out.
   */
  hasChangesOrIsImportantType?: boolean;
}

export interface FlatTreeNode extends TreeNode {
  level: number;
  isExpandable: boolean;
  hasError: boolean;
  hasOnlyWarnings: boolean;
}

export const flattenTreeNode = (node: TreeNode, level: number): FlatTreeNode => {
  return {
    ...node,
    level,
    isExpandable: node.children.length > 0,
    hasError: TreeNodeHelper.nodeHasError(node),
    hasOnlyWarnings: TreeNodeHelper.nodeHasOnlyWarnings(node),
  };
};

export interface TreeNodeWithDetails<Details> extends TreeNode {
  details: Details;
  output: PlanningOutputModel | GenerationOutputModel;
}

export const findById = (searchId: string, checkedNodes: FlatTreeNode[]): Maybe<FlatTreeNode> => {
  return checkedNodes.find(n => n.id === searchId);
};

export const extractAncestorChain = (node: TreeNode): string[] => {
  let parentChain: string[] = node.parent
      ? extractAncestorChain(node.parent)
      : [];
  return parentChain.concat(node.id);
};

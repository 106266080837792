import { editor, MarkerSeverity } from 'monaco-editor';
import { ValidationIssue, ValidationStatus } from '../model/validation-status.model';
import { Maybe } from './utils';
import ITextModel = editor.ITextModel;

const baseTheme: editor.BuiltinTheme = 'vs';

interface Adm4MonacoEditor {
  defineTheme: typeof editor.defineTheme;
  setTheme: typeof editor.setTheme;
}

const getWindowMonaco = (): Adm4MonacoEditor => {
  return (window as any).monaco.editor as Adm4MonacoEditor;
};

const createMonacoDiffTheme = () => {
  return {
    base: baseTheme,
    inherit: true,
    rules: [],
    colors: {
      'diffEditor.insertedTextBackground': '#60606033',
      'diffEditor.removedTextBackground': '#28d22833'
    }
  };
};

/**
 * Applies a theme to the monaco editor, which overrides the default one in which the left is the remote side.
 */
export const applyMonacoDiffTheme = () => {
  const globalEditor: Adm4MonacoEditor = getWindowMonaco();
  globalEditor.defineTheme('customDiffTheme', createMonacoDiffTheme());
  globalEditor.setTheme('customDiffTheme');
};

const createMonacoDefaultTheme = () => {
  return {
    base: baseTheme,
    inherit: true,
    rules: [],
    colors: {
      'editor.lineHighlightBackground': '#deeef0',  // $nevis-green-ligtest-c4
    }
  };
};

/**
 * Applies the nevis accented default theme to the monaco editor.
 */
export const applyMonacoDefaultTheme = () => {
  const globalEditor: Adm4MonacoEditor = getWindowMonaco();
  globalEditor.defineTheme('nevisDefaultTheme', createMonacoDefaultTheme());
  globalEditor.setTheme('nevisDefaultTheme');
};

export const setMonacoMarkers = (model: ITextModel, owner: string, markers: editor.IMarkerData[]): void => {
  (window as any).monaco.editor.setModelMarkers(model, owner, markers);
};

const SOURCE_KEY_LINE_NR = 'LINE_NUMBER';
const SOURCE_KEY_COL_NR = 'COLUMN_NUMBER';
// this is a line length big enough to make the marker cover the whole line
const END_COL_WHOLE_LINE = 999;

const hasIssueLineNumber = (issue: ValidationIssue): boolean => {
  const rawLineNumber = issue?.sources[0][SOURCE_KEY_LINE_NR];
  return !isNaN(Number.parseInt(rawLineNumber));

};

const validationIssueToMonacoMarker = (issue: ValidationIssue, severity: MarkerSeverity): editor.IMarkerData => {
  const startLineNumber: number = Number.parseInt(issue?.sources[0][SOURCE_KEY_LINE_NR]);
  let startColumn: number = Number.parseInt(issue?.sources[0][SOURCE_KEY_COL_NR]);
  let endColumn: number;
  startColumn = isNaN(startColumn) ? 0 : startColumn;
  if (startColumn === 0) {
    endColumn = END_COL_WHOLE_LINE;
  } else {
    endColumn = startColumn + 1;
  }
  return {
    severity,
    message: issue.detail,
    code: issue.title,
    startLineNumber: startLineNumber + 1,
    endLineNumber: startLineNumber + 1,
    startColumn: startColumn + 1,
    endColumn: endColumn + 1,
  };
};

export const validationStatusToMonacoMarkers = (validationStatus: Maybe<ValidationStatus<ValidationIssue>>): editor.IMarkerData[] => {
  const errors: editor.IMarkerData[] = (validationStatus?._errors??[]).filter(hasIssueLineNumber).map(e => validationIssueToMonacoMarker(e, MarkerSeverity.Error));
  const warnings: editor.IMarkerData[] = (validationStatus?._warnings??[]).filter(hasIssueLineNumber).map(e => validationIssueToMonacoMarker(e, MarkerSeverity.Warning));
  const infos: editor.IMarkerData[] = (validationStatus?._infos??[]).filter(hasIssueLineNumber).map(e => validationIssueToMonacoMarker(e, MarkerSeverity.Info));
  return errors.concat(warnings, infos);
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'docLink'
})
export class DocLinkPipe implements PipeTransform {

  prefix = `https://docs.nevis.net/`;
  // prefix = `https://docs.nevis.net/nevisadmin4/LTS21/`; // LTS
  // prefix = `http://localhost:3000/nevisadmin4/`; // local test


  transform(value: String): string {
    return this.prefix + value;
  }
}

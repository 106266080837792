import { IssueSeverity } from '../common/model/issue.model';
import { issueFragmentSeparator } from './issues.constants';
import * as _ from 'lodash';

export class IssueGroupFragmentHelper {
  static createIssueSeverityGroupFragment(issueSeverity: IssueSeverity, groupId: string): string {
    return `${issueSeverity}${issueFragmentSeparator}${groupId}`;
  }

  static getGroupIdFromFragment(fragment: string | null): string | undefined {
    if (_.isNil(fragment)) {
      return undefined;
    }
    const indexOfSeparator = fragment.indexOf(issueFragmentSeparator);
    if (indexOfSeparator < 0) {
      throw new Error(`Bad input for fragment. Fragment should contain separator ${issueFragmentSeparator} between composed parts`);
    }
    const groupIdStartIndex = indexOfSeparator + 1;
    return fragment.substring(groupIdStartIndex);
  }
}

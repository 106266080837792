import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { PluginModel } from './plugin.model';
import { PropertyType } from './property-type.model';

/**
 * This service used for getting data about the plugin. It calls the backend service based on the URL
 */
@Injectable()
export class PluginService extends BaseService {

  getPropertyTypes(projectKey: string): Observable<PropertyType[]> {
    const url = `/projects/${projectKey}/property-types`;
    return this.httpGetUnwrapped(url);
  }

  getPluginClassesFilteredByClass(projectKey: string, filterClass: string): Observable<PluginModel[]> {
    const url = `/projects/${projectKey}/plugins?filterClass=${filterClass}`;
    return this.httpGetUnwrapped(url);
  }
}

/**
 * It models the inventory response from the back end
 * /nevisadmin/api/inventories
 */
import { UserAuthorization } from '../model/permissions/user-authorization.model';
import { VersionControlData } from '../common/model/version-control-data.model';
import { MetaInfo } from '../version-control/meta-info.model';

export type Inventory = {
  inventoryKey: string;
  tenantKey: string;
  type: string;
  schemaType: InventorySchemaType;
  manifestTarget?: InventoryManifestTarget;
  color?: string;
  publishRequired: boolean;
  originInventoryKey?: string;
  commitId?: string;
} & UserAuthorization
  & VersionControlData;

export enum InventorySchemaType {
  /** Classic deployment - current structure of the inventory */
  CLASSIC = 'CLASSIC',
  /** Cloud deployment - new inventory file structure for Kubernetes deployment */
  KUBERNETES = 'KUBERNETES'
}

export enum InventoryManifestTarget {
  CLUSTER = 'CLUSTER',
  GIT = 'GIT',
}

export interface InventoryMeta {
  lastModification?: string;
  lastAuthor?: string;
  localHead?: string;
  content: MetaInfo;
}

export interface SecretWrapperWithUsage extends InventoryResourceWithUsage {
  secretId: string;
  value: string;
  isExpanded?: boolean;
}
export interface SecretWrapper extends InventoryResource {
  secretId: string;
  value: string;
  isExpanded?: boolean;
}

export interface SecretResourceWrapperWithUsage extends InventoryResourceWithUsage {
  secretResourceId: string;
  secretResourceName: string;
  isExpanded?: boolean;
}
export interface SecretResourceWrapper extends InventoryResource {
  secretResourceId: string;
  secretResourceName: string;
  isExpanded?: boolean;
}

export interface ResourceWrapperWithUsage extends InventoryResourceWithUsage {
  resourceId: string;
  resourceName: string;
  isExpanded?: boolean;
}
export interface ResourceWrapper extends InventoryResource {
  resourceId: string;
  resourceName: string;
  isExpanded?: boolean;
}

export interface TenantResourceFlag  {
  isTenantScoped?: boolean;
}

export interface InventoryResource {
  scope: string;
  description?: string;
}

export interface InventoryResourceWithUsage extends InventoryResource {
  usedIn?: string[];
}

export type SecretLikeWrapperWithUsage = SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage;

export enum InventoryResourceType {
  PLAIN_TEXT_SECRET = 'Secret',
  SECRET_FILE = 'Secret file',
  FILE = 'File'
}

export interface PreselectedResource {
  resourceType: InventoryResourceType;
  resourceId: string;
  scope: string;
}

export interface CertificateWrapperWithUsage extends InventoryResourceWithUsage {
  source: string;
  id: string;
  fileName: string;
  certificates: Certificate[];
  isExpanded?: boolean;
}

export interface CertificateWrapper extends InventoryResource {
  source: string;
  id: string;
  fileName: string;
  certificates: Certificate[];
  isExpanded?: boolean;
}

export interface Certificate {
  subject: string;
  issuer: string;
  serial: string;
  expires: string;
}

export interface KubernetesCertificateUsage {
  patternId: string;
  projectKey: string;
  label: string;
}

export interface KubernetesCertificate {
  subject: string;
  issuer: string;
  serial: string;
  expires: string;
}

export interface KubernetesCertificateWrapper extends InventoryResourceWithUsage {
  kind: string;
  kubernetesSecretKey: string;
  name: string;
  namespace: string;
  state: string;
  usages: KubernetesCertificateUsage[];
  certificates: KubernetesCertificate[];
  isExpanded?: boolean;
}

export enum InventoryColor {
  Red,
  Yellow,
  Green,
  Purple,
  Brown,
  Blue
}

export interface GlobalConstantBase {
  name: string;
  value: string;
  description?: string;
}

export interface GlobalConstant extends GlobalConstantBase {
  /**
   * The scope is effectively the tenantKey, as the global constants are scoped to tenants.
   */
  scope: string;
}

export interface GlobalConstantWithUsage extends GlobalConstant {
  usedIn: string[] | undefined;
}

export const GLOBAL_CONSTANT_REF_PREFIX = '${g-const://';
export const GLOBAL_CONSTANT_REF_POSTFIX = '}';

export const wrapGlobalConstantRef = (name: string) =>
    `${GLOBAL_CONSTANT_REF_PREFIX}${name}${GLOBAL_CONSTANT_REF_POSTFIX}`;

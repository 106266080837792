export enum DeploymentHistoryTableModel {
 StatusColumnName = 'status',
 ProjectColumnName = 'project',
 DeployedToColumnName = 'deployedTo',
 DeployedByColumnName = 'deployedBy',
 StartDateColumnName = 'startDate',
 DeployColumnName = 'deploy',
 Postfix = 'postfix',
 DeploymentTargets = 'deploymentTargets',
 ExpandIconColumn = 'expandIcon'
}

export const inventoryDeploymentHistoryColumns: DeploymentHistoryTableModel[] = [
  DeploymentHistoryTableModel.StatusColumnName,
  DeploymentHistoryTableModel.ProjectColumnName,
  DeploymentHistoryTableModel.DeployedToColumnName,
  DeploymentHistoryTableModel.DeployedByColumnName,
  DeploymentHistoryTableModel.StartDateColumnName,
  DeploymentHistoryTableModel.DeployColumnName,
  DeploymentHistoryTableModel.ExpandIconColumn
];

export const inventoryK8sDeploymentHistoryColumns: DeploymentHistoryTableModel[] = [
  DeploymentHistoryTableModel.StatusColumnName,
  DeploymentHistoryTableModel.ProjectColumnName,
  DeploymentHistoryTableModel.DeployedByColumnName,
  DeploymentHistoryTableModel.StartDateColumnName,
  DeploymentHistoryTableModel.DeployColumnName,
  DeploymentHistoryTableModel.ExpandIconColumn
];

export const inventoryK8sStatusColumns: DeploymentHistoryTableModel[] = [
  DeploymentHistoryTableModel.StatusColumnName,
  DeploymentHistoryTableModel.ProjectColumnName,
  DeploymentHistoryTableModel.DeployedByColumnName,
  DeploymentHistoryTableModel.StartDateColumnName,
  DeploymentHistoryTableModel.ExpandIconColumn
];

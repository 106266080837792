import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import {
  CertificateWrapperWithUsage, CertificateWrapper,
  GlobalConstantWithUsage, GlobalConstant,
  Inventory,
  InventoryMeta,
  KubernetesCertificateWrapper,
  ResourceWrapperWithUsage, ResourceWrapper,
  SecretResourceWrapperWithUsage, SecretResourceWrapper,
  SecretWrapperWithUsage, SecretWrapper,
} from './inventory.model';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VariableInventoryData } from '../variables/variable-details/variable-details.model';
import { map } from 'rxjs/operators';
import { InventoryFileImportPayload } from './file-based-import-inventory/inventory-file-import-payload.model';
import { VersionControlData } from '../common/model/version-control-data.model';
import { PublishPayload } from '../common/model/publish-changes/publish-payload.model';
import { DeploymentHistoryItem } from '../common/model/deployment-history.model';
import { secretReferencePrefix } from '../common/constants/reference-prefix.constants';
import { KubernetesCustomResource } from './inventory-kubernetes-status/custom-resource-tree-node.model';
import { DeploymentHostStatusResponseModel, DeploymentInstanceActionModel } from './infrastructure-status/inventory-status-item.model';
import { DeployedServiceItem } from './inventory-kubernetes-status/deployed-service.model';
import * as _ from 'lodash';

@Injectable()
export class InventoryService extends BaseService {

  getInventoriesOfTenant(tenantKey: string): Observable<Inventory[]> {
    const url = '/inventories';
    const params: HttpParams = new HttpParams().set('tenantKey', tenantKey);
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getInventory(inventoryKey: string): Observable<Inventory> {
    const url = `/inventories/${inventoryKey}`;
    return this.httpGet(url);
  }

  getHosts(inventoryKey: string): Observable<string[]> {
    const url = `/inventories/${inventoryKey}/hosts`;
    return this.httpGetUnwrapped(url);
  }

  getHostStatus(inventoryKey: string): Observable<DeploymentHostStatusResponseModel> {
    const url = `/inventories/${inventoryKey}/host-status`;
    return this.httpGet(url);
  }

  triggerInventoryDeploymentAction(inventoryKey: string, deploymentInstanceActionModel: DeploymentInstanceActionModel): Observable<any> {
    const url = `/inventories/${inventoryKey}/host-status/${deploymentInstanceActionModel.instance.host}/actions/${deploymentInstanceActionModel.action}`;
    return this.httpPut(url, {component: deploymentInstanceActionModel.instance.component, instance: deploymentInstanceActionModel.instance.instance});
  }

  getGroups(inventoryKey: string): Observable<string[]> {
    const url = `/inventories/${inventoryKey}/groups`;
    return this.httpGetUnwrapped(url);
  }

  getInventoryMeta(inventoryKey: string): Observable<InventoryMeta> {
    const url = `/inventories/${inventoryKey}/meta`;
    return this.httpGet(url);
  }

  clearLocalChanges(inventoryKey: string): Observable<void> {
    const url = `/inventories/${inventoryKey}/meta`;
    return this.httpDelete(url);
  }

  getInventoryTimeStamp(inventoryKey: string): Observable<{ timestamp: string }> {
    const url = `/inventories/${inventoryKey}/timestamp`;
    return this.httpGet(url);
  }

  updateInventory(inventory: Inventory): Observable<Inventory> {
    const url = `/inventories/${inventory.inventoryKey}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpPut(url, inventory, headers);
  }

  updatePublishRequiredFlagOnInventory(inventoryKey: string, publishRequired: boolean): Observable<Inventory> {
    const url = `/inventories/${inventoryKey}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpPatch(url, {publishRequired}, headers);
  }

  createInventory(inventory: Inventory): Observable<Inventory> {
    const url = `/inventories`;
    return this.httpPost(url, inventory);
  }

  /**
   * Starts job for importing inventory
   * @param {Project} inventory
   * @returns {Observable<string>} URL for job, which is running while importing inventory, polling which we can know status of the progress
   */
  importInventory(inventory: Inventory): Observable<string> {
    const url = '/inventories/revision-import';
    return this.httpPost(url, inventory, (response: HttpResponse<null>) => <string>response.headers.get('location'));
  }

  importInventoryFile(inventoryFileImportPayload: InventoryFileImportPayload): Observable<string> {
    const url = '/inventories/file-import';
    const formData = new FormData();
    formData.append('inventory', inventoryFileImportPayload.inventoryFile);
    const params: HttpParams = new HttpParams().append('inventoryKey', inventoryFileImportPayload.inventoryKey);
    return this.httpPostFormData(url, formData, undefined, params, (response: HttpResponse<null>) => <string>response.headers.get('location'));
  }

  importInventoryForRevision(inventoryKey: string, commitId: string): Observable<string> {
    const url = `/inventories/${inventoryKey}/revision-import/${commitId}`;
    return this.httpPost(url, undefined, (response: HttpResponse<null>) => <string>response.headers.get('location'));
  }

  connectInventoryToVersionControl(inventoryKey: string, inventoryVersionControlData: VersionControlData): Observable<Inventory> {
    const url = `/inventories/${inventoryKey}`;
    return this.httpPatch<VersionControlData, Inventory, Inventory>(url, inventoryVersionControlData);
  }

  triggerUpdatesOfInventory(inventoryKey: string): Observable<string> {
    const url = `/inventories/${inventoryKey}/revision-update`;
    return this.httpPut(url, undefined, undefined, undefined, (response: HttpResponse<null>) => <string>response.headers.get('location'));
  }

  publishInventory(inventoryKey: string, publishContent: PublishPayload): Observable<any> {
    const url = `/inventories/${inventoryKey}/revisions`;
    return this.httpPost(url, publishContent);
  }

  deleteInventory(inventoryKey: string): Observable<Inventory> {
    const url = `/inventories/${inventoryKey}`;
    return this.httpDelete(url);
  }

  getInventoriesForVariable(variableKey: string, tenantKey: string): Observable<{ variableKey: string, inventories: VariableInventoryData[] }> {
    const url = `/inventories/variables/${variableKey}`;
    let params: HttpParams = new HttpParams();
    if (tenantKey) {
      params = params.set('tenantKey', tenantKey);
    }
    return this.httpGet(url, undefined, params);
  }

  createInventorySecret(inventoryKey: string, value: string, description?: string): Observable<string> {
    const url = `/inventories/${inventoryKey}/secrets`;
    return this.httpPost(url, {value: value, description: description}).pipe(map((secretObject: SecretWrapperWithUsage) => `${secretReferencePrefix}${secretObject.secretId}`));
  }

  getInventorySecretsWithUsage(inventoryKey: string): Observable<SecretWrapperWithUsage[]> {
    const url = `/inventories/${inventoryKey}/secrets`;
    const params: HttpParams = new HttpParams().append('usedIn', 'true');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getInventorySecrets(inventoryKey: string): Observable<SecretWrapper[]> {
    const url = `/inventories/${inventoryKey}/secrets`;
    const params: HttpParams = new HttpParams().append('usedIn', 'false');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getInventorySecretContent(inventoryKey: string, secretId: string): Observable<string> {
    const url = `/inventories/${inventoryKey}/secrets/${secretId}`;
    return this.httpGet(url).pipe(map((secretObject: SecretWrapperWithUsage) => secretObject.value));
  }

  updateInventorySecretContent(inventoryKey: string, secret: SecretWrapperWithUsage): Observable<string> {
    const url = `/inventories/${inventoryKey}/secrets/${secret.secretId}`;
    return this.httpPut(url, secret).pipe(map((secretObject: SecretWrapperWithUsage) => `${secretReferencePrefix}${secretObject.secretId}`));
  }

  /**
   * The `description` and `scope` are optional parameters, the ones not specified in this request will not be changed.<br/>
   * If the scope is specified, it can only be the same tenantKey as the one in the inventory.
   */
  patchInventorySecret(inventoryKey: string, secretId: string, description?: string, scope?: string): Observable<void> {
    const url = `/inventories/${inventoryKey}/secrets/${secretId}`;
    return this.httpPatch(url, { description, scope });
  }

  deleteInventorySecret(inventoryKey: string, secretId: string): Observable<any> {
    const url = `/inventories/${inventoryKey}/secrets/${secretId}`;
    return this.httpDelete(url);
  }

  getInventorySecretResourcesWithUsage(inventoryKey: string): Observable<SecretResourceWrapperWithUsage[]> {
    const url = `/inventories/${inventoryKey}/secret-resources`;
    const params: HttpParams = new HttpParams().append('usedIn', 'true');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getInventorySecretResources(inventoryKey: string): Observable<SecretResourceWrapper[]> {
    const url = `/inventories/${inventoryKey}/secret-resources`;
    const params: HttpParams = new HttpParams().append('usedIn', 'false');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  deleteInventorySecretResource(inventoryKey: string, secretId: string): Observable<any> {
    const url = `/inventories/${inventoryKey}/secret-resources/${secretId}`;
    return this.httpDelete(url);
  }

  updateInventorySecretResource(inventoryKey: string, resourceId: string, resource: File, description?: string): Observable<void> {
    const url = `/inventories/${inventoryKey}/secret-resources/${resourceId}`;
    const formData: FormData = new FormData();
    formData.append('resource', resource);
    if (description) {
      formData.append('description', description);
    }
    return this.httpPutFormData(url, formData);
  }

  /**
   * The `description` and `scope` are optional parameters, the ones not specified in this request will not be changed.<br/>
   * If the scope is specified, it can only be the same tenantKey as the one in the inventory.
   */
  patchInventorySecretResource(inventoryKey: string, secretResourceId: string, description?: string, scope?: string): Observable<void> {
    const url = `/inventories/${inventoryKey}/secret-resources/${secretResourceId}`;
    return this.httpPatch(url, { description, scope });
  }

  getInventoryResourcesWithUsage(inventoryKey: string): Observable<ResourceWrapperWithUsage[]> {
    const url = `/inventories/${inventoryKey}/resources`;
    const params: HttpParams = new HttpParams().append('usedIn', 'true');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getInventoryResources(inventoryKey: string): Observable<ResourceWrapper[]> {
    const url = `/inventories/${inventoryKey}/resources`;
    const params: HttpParams = new HttpParams().append('usedIn', 'false');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  updateInventoryResource(inventoryKey: string, resourceId: string, resource: File, description?: string): Observable<void> {
    const url = `/inventories/${inventoryKey}/resources/${resourceId}`;
    const formData: FormData = new FormData();
    formData.append('resource', resource);
    if (description) {
      formData.append('description', description);
    }
    return this.httpPutFormData(url, formData);
  }

  /**
   * The `description` and `scope` are optional parameters, the ones not specified in this request will not be changed.<br/>
   * If the scope is specified, it can only be the same tenantKey as the one in the inventory.
   */
  patchInventoryResource(inventoryKey: string, resourceId: string, description?: string, scope?: string): Observable<void> {
    const url = `/inventories/${inventoryKey}/resources/${resourceId}`;
    return this.httpPatch(url, { description, scope });
  }

  getInventoryCertificatesWithUsage(inventoryKey: string, includeTenant: boolean): Observable<CertificateWrapperWithUsage[]> {
    const url = `/inventories/${inventoryKey}/certificates`;
    const params: HttpParams = new HttpParams().set('usedIn', 'true').set('includeTenant', includeTenant.toString());
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getInventoryCertificates(inventoryKey: string, includeTenant: boolean): Observable<CertificateWrapper[]> {
    const url = `/inventories/${inventoryKey}/certificates`;
    const params: HttpParams = new HttpParams().set('usedIn', 'false').set('includeTenant', includeTenant.toString());
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getInventoryKubernetesCertificates(inventoryKey: string): Observable<KubernetesCertificateWrapper[]> {
    const url = `/inventories/${inventoryKey}/kubernetes-certificates`;
    return this.httpGetUnwrapped(url);
  }

  getTenantCertificates(tenantKey: string, includeInventories: boolean): Observable<CertificateWrapperWithUsage[]> {
    const url = `/tenants/${tenantKey}/certificates`;
    const params: HttpParams = new HttpParams().set('usedIn', 'true').set('includeInventories', includeInventories.toString());
    return this.httpGetUnwrapped(url, undefined, params);
  }

  deleteInventorResource(inventoryKey: string, secretId: string): Observable<any> {
    const url = `/inventories/${inventoryKey}/resources/${secretId}`;
    return this.httpDelete(url);
  }

  createTenantSecret(tenantKey: string, secretValue: string, description?: string): Observable<string> {
    const url = `/tenants/${tenantKey}/secrets`;
    return this.httpPost(url, {value: secretValue, description: description});
  }

  getTenantSecretsWithUsage(tenantKey: string): Observable<SecretWrapperWithUsage[]> {
    const url = `/tenants/${tenantKey}/secrets`;
      const params: HttpParams = new HttpParams().append('usedIn', 'true');
      return this.httpGetUnwrapped(url, undefined, params);
  }

  getTenantSecrets(tenantKey: string): Observable<SecretWrapper[]> {
    const url = `/tenants/${tenantKey}/secrets`;
    const params: HttpParams = new HttpParams().append('usedIn', 'false');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getTenantSecretContent(tenantKey: string, secretId: string): Observable<string> {
    const url = `/tenants/${tenantKey}/secrets/${secretId}`;
    return this.httpGet(url).pipe(map((secretObject: SecretWrapperWithUsage) => secretObject.value));
  }

  updateTenantSecretContent(tenantKey: string, secret: SecretWrapperWithUsage): Observable<string> {
    const url = `/tenants/${tenantKey}/secrets/${secret.secretId}`;
    return this.httpPut(url, secret).pipe(map((secretObject: SecretWrapperWithUsage) => `${secretReferencePrefix}${secretObject.secretId}`));
  }

  patchTenantSecret(tenantKey: string, secretId: string, description?: string): Observable<any> {
    const url = `/tenants/${tenantKey}/secrets/${secretId}`;
    return this.httpPatch(url, { description });
  }

  deleteTenantSecret(tenantKey: string, secretId: string): Observable<any> {
    const url = `/tenants/${tenantKey}/secrets/${secretId}`;
    return this.httpDelete(url);
  }

  getTenantSecretResourcesWithUsage(tenantKey: string): Observable<SecretResourceWrapperWithUsage[]> {
    const url = `/tenants/${tenantKey}/secret-resources`;
    const params: HttpParams = new HttpParams().append('usedIn', 'true');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getTenantSecretResources(tenantKey: string): Observable<SecretResourceWrapper[]> {
    const url = `/tenants/${tenantKey}/secret-resources`;
    const params: HttpParams = new HttpParams().append('usedIn', 'false');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  deleteTenantSecretResource(tenantKey: string, secretResourceId: string): Observable<any> {
    const url = `/tenants/${tenantKey}/secret-resources/${secretResourceId}`;
    return this.httpDelete(url);
  }

  updateTenantSecretResource(tenantKey: string, resourceId: string, resource: File, description?: string): Observable<void> {
    const url = `/tenants/${tenantKey}/secret-resources/${resourceId}`;
    const formData: FormData = new FormData();
    formData.append('resource', resource);
    if (description) {
      formData.append('description', description);
    }
    return this.httpPutFormData(url, formData);
  }

  patchTenantSecretResource(tenantKey: string, secretResourceId: string, description: string): Observable<void> {
    const url = `/tenants/${tenantKey}/secret-resources/${secretResourceId}`;
    return this.httpPatch(url, { description});
  }

  getTenantResourcesWithUsage(tenantKey: string): Observable<ResourceWrapperWithUsage[]> {
    const url = `/tenants/${tenantKey}/resources`;
    const params: HttpParams = new HttpParams().append('usedIn', 'true');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getTenantResources(tenantKey: string): Observable<ResourceWrapper[]> {
    const url = `/tenants/${tenantKey}/resources`;
    const params: HttpParams = new HttpParams().append('usedIn', 'false');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  deleteTenantResource(tenantKey: string, resourceId: string): Observable<any> {
    const url = `/tenants/${tenantKey}/resources/${resourceId}`;
    return this.httpDelete(url);
  }

  updateTenantResource(tenantKey: string, resourceId: string, resource: File, description?: string): Observable<void> {
    const url = `/tenants/${tenantKey}/resources/${resourceId}`;
    const formData: FormData = new FormData();
    formData.append('resource', resource);
    if (description) {
      formData.append('description', description);
    }
    return this.httpPutFormData(url, formData);
  }

  patchTenantResource(tenantKey: string, resourceId: string, description: string): Observable<void> {
    const url = `/tenants/${tenantKey}/resources/${resourceId}`;
    return this.httpPatch(url, { description });
  }

  getInventoryDeploymentHistory(inventoryKey: string): Observable<DeploymentHistoryItem[]> {
    const url = `/inventories/${inventoryKey}/deployment-history`;
    return this.httpGetUnwrapped(url);
  }

  uploadInventorySecretResource(inventoryKey: string, resource: File, description: string | undefined): Observable<SecretResourceWrapperWithUsage> {
    const url = `/inventories/${inventoryKey}/secret-resources`;
    const formData: FormData = new FormData();
    if (!_.isNil(description)) {
      formData.append('description', description);
    }
    formData.append('resource', resource);
    return this.httpPostFormData(url, formData);
  }

  uploadInventoryResource(inventoryKey: string, resource: File, description: string | undefined): Observable<ResourceWrapperWithUsage> {
    const url = `/inventories/${inventoryKey}/resources`;
    const formData: FormData = new FormData();
    if (!_.isNil(description)) {
      formData.append('description', description);
    }
    formData.append('resource', resource);
    return this.httpPostFormData(url, formData);
  }

  uploadTenantSecretResource(tenantKey: string, resource: File, description?: string): Observable<SecretResourceWrapperWithUsage> {
    const url = `/tenants/${tenantKey}/secret-resources`;
    const formData: FormData = new FormData();
    formData.append('resource', resource);
    if (description) {
      formData.append('description', description);
    }
    return this.httpPostFormData(url, formData);
  }

  uploadTenantResource(tenantKey: string, resource: File, description?: string): Observable<ResourceWrapperWithUsage> {
    const url = `/tenants/${tenantKey}/resources`;
    const formData: FormData = new FormData();
    formData.append('resource', resource);
    if (description) {
      formData.append('description', description);
    }
    return this.httpPostFormData(url, formData);
  }

  getInventoryCustomResources(inventoryKey: string): Observable<KubernetesCustomResource[]> {
    const url = `/inventories/${inventoryKey}/custom-resources`;
    return this.httpGetUnwrapped(url);
  }

  getGlobalConstantsForTenant(tenantKey: string): Observable<GlobalConstant[]> {
    const params: HttpParams = new HttpParams().append('usedIn', 'false');
    const url = `/tenants/${tenantKey}/constants`;
    return this.httpGetUnwrapped<GlobalConstant[]>(url, undefined, params);
  }

  getGlobalConstantsWithUsageForTenant(tenantKey: string): Observable<GlobalConstantWithUsage[]> {
    const params: HttpParams = new HttpParams().append('usedIn', 'true');
    const url = `/tenants/${tenantKey}/constants`;
    return this.httpGetUnwrapped<GlobalConstantWithUsage[]>(url, undefined, params);
  }

  updateGlobalConstant(gc: GlobalConstantWithUsage, tenantKey: string): Observable<void> {
    const url = `/tenants/${tenantKey}/constants/${gc.name}`;
    return this.httpPut(url, gc);
  }

  createGlobalConstant(gc: GlobalConstantWithUsage, tenantKey: string): Observable<void> {
    const url = `/tenants/${tenantKey}/constants`;
    return this.httpPost(url, gc);
  }

  deleteGlobalConstant(gc: GlobalConstantWithUsage, tenantKey: string): Observable<void> {
    const url = `/tenants/${tenantKey}/constants/${gc.name}`;
    return this.httpDelete(url);
  }

  promoteCanaryDeployment(inventoryKey: string, comment?: string): Observable<any> {
    const url = `/inventories/${inventoryKey}/promote`;
    return this.httpPut(url, {comment: comment});
  }

  rollbackCanaryDeployment(inventoryKey: string, comment?: string): Observable<any> {
    const url = `/inventories/${inventoryKey}/rollback`;
    return this.httpPut(url, {comment: comment});
  }

  getInventoryDeployedServices(inventoryKey: string, serviceList?: string[]): Observable<DeployedServiceItem[]> {
    const url = `/inventories/${inventoryKey}/services`;
    if (serviceList) {
      const params: HttpParams = new HttpParams().append('service', serviceList.join(','));
      return this.httpGetUnwrapped(url, undefined, params);
    }
    return this.httpGetUnwrapped(url);
  }

  getInventoryOperatorServices(inventoryKey: string): Observable<DeployedServiceItem[]> {
    const url = `/inventories/${inventoryKey}/services`;
    const params: HttpParams = new HttpParams().append('type', 'OPERATOR');
    return this.httpGetUnwrapped(url, undefined, params);
  }

  getPodLogs(
      inventoryKey: string, podKey: string, selectedContainer?: string, sinceSeconds?: number, tailLines?: number, nameSpace?: string,
  ): Observable<string | null> {
    const url = `/inventories/${inventoryKey}/pods/${podKey}/log`;
    let params: HttpParams = new HttpParams();
    if (selectedContainer) {
      params = params.set('container', selectedContainer);
    }
    if (sinceSeconds) {
      params = params.set('sinceSeconds', sinceSeconds.toString());
    }
    if (tailLines) {
      params = params.set('tailLines', tailLines.toString());
    }
    if (nameSpace) {
      params = params.set('namespace', nameSpace);
    }
    return this.httpGetText(url, params);
  }

  deleteKubernetesDeployment(deploymentHistoryId: number, inventoryKey: string, comment=''): Observable<any> {
    const url = `/inventories/${inventoryKey}/deployment-history/${deploymentHistoryId}/delete`;
    return this.httpPost(url, {comment: comment});
  }

}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PatternIssueSeverityGroupsContext } from './pattern-issue-severity-groups.context';
import { Observable } from 'rxjs';
import { PatternIssueSeverityGroup } from '../model/pattern-issue-severity-group.model';
import { ActivatedRoute } from '@angular/router';
import { IssueGroupFilteringContext } from '../issue-group-filtering.context';

@Component({
  selector: 'adm4-pattern-issue-severity-groups',
  template: `
		<div class='severity-groups-container full-height-flex'>
			<div class='filter-wrapper'>
				<adm4-filter [placeholderText]='searchPlaceHolder'
                     (filter)='filterGroups($event)'
        ></adm4-filter>
			</div>
			<div class='remaining-space-flex-content-wrapper'>
				<div class='remaining-space-flex-content pattern-issues-list'>
					<div *ngFor='let group of patternIssueSeverityGroups$ | async' class="severity-group">
						<adm4-pattern-issue-severity-group [group]='group'
                                               [selectedFragment]='selectedIssueGroupItemFragment$ | async'
                                               [filterText]='filteredText$ | async'
            ></adm4-pattern-issue-severity-group>
					</div>
          <div *ngIf="!(patternIssueSeverityGroups$ | async)?.length" class="no-issues">
            No issues found.
          </div>
				</div>
			</div>
		</div>
  `,
  styleUrls: ['./pattern-issue-severity-groups.component.scss'],
  providers: [PatternIssueSeverityGroupsContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternIssueSeverityGroupsComponent {
  readonly searchPlaceHolder = 'Find patterns';
  patternIssueSeverityGroups$: Observable<PatternIssueSeverityGroup[]>;
  filteredText$: Observable<string>;
  selectedIssueGroupItemFragment$: Observable<string | null>;

  constructor(
    private patternIssueSeverityGroupsContext: PatternIssueSeverityGroupsContext,
    private route: ActivatedRoute,
    private issueGroupFilteringContext: IssueGroupFilteringContext
  ) {
    this.patternIssueSeverityGroups$ = this.patternIssueSeverityGroupsContext.patternIssueSeverityGroups$;
    this.filteredText$ = this.issueGroupFilteringContext.filterText$;
    this.selectedIssueGroupItemFragment$ = this.route.fragment;
  }

  filterGroups(filterText: string): void {
    this.issueGroupFilteringContext.changeFilterText(filterText);
  }
}

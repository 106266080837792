
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';

@Injectable()
export class ModalWindowGuard  {

  constructor(private matDialog: MatDialog) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.appHasOpenedDialogs();
  }

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    return this.appHasOpenedDialogs();
  }

  private appHasOpenedDialogs(): boolean {
    return _.isEmpty(this.matDialog.openDialogs);
  }

}

import { BehaviorSubject, Observable, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from './user.service';
import { select, Store } from '@ngrx/store';
import { userDataView } from '../../model/views';
import { UserData } from '../../model/user/user.model';
import { AppState } from '../../model/reducer';

@Component({
  selector: 'adm4-session-expiry-dialog',
  template: `
    <adm4-modal-dialog-title header='Session expiry'>
      <div class='login-container session-dialog-container'>
        <h1>Session has expired</h1>
        <div class='login-text'>Log back in to nevisAdmin 4</div>

        <form name='form'
              #f='ngForm'
              novalidate>
          <div class='form-group'>
            <div class="input-group">
              <input type='text'
                     class='form-control'
                     name='username'
                     disabled
                     [ngModel]='userName$ | async'
                     required/>
              <!-- Edge didn't display svg in background when it's cached, therefore this workaround is applied to avoid that problem, because no idea why it happens, rly -->
              <img src='assets/login-profil-off.svg' alt='' class='input-group-prepend username-addon-off'/>
              <img src='assets/login-profil-on.svg' alt='' class='input-group-prepend username-addon'/>
            </div>
          </div>
          <div class='form-group' [ngClass]="{ 'has-error': f.submitted && !password.valid }">
            <div class="input-group">
              <input type='password'
                     class='form-control'
                     name='password'
                     placeholder='Password'
                     [(ngModel)]='passwordModel'
                     (ngModelChange)='resetError()'
                     #password='ngModel'
                     cdkFocusInitial
                     required/>
              <span class='input-group-prepend password-addon'></span>
            </div>
            <div class='validation-message-container'>
              <div *ngIf='f.submitted && !password.valid' class='invalid-feedback'>
                <adm4-validation-indicator [isDisplayed]='true' [isError]='true' [diameter]='8'></adm4-validation-indicator>
                Password is required
              </div>
              <div *ngIf='passwordHasError$ | async' class='error-message'>
                <adm4-validation-indicator [isDisplayed]='true' [isError]='true' [diameter]='8'></adm4-validation-indicator>
                {{INVALID_LOGIN}}
              </div>
            </div>
          </div>
          <a (click)='closeDialog()'>Log in with another user</a>
          <div class='form-group'>
            <button *ngIf='(userName$ | async) as userName' (click)='login(userName)'>Login</button>
          </div>
        </form>
      </div>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['login.component.scss', 'session-expiry-dialog.component.scss']
})
export class SessionExpiryDialogComponent implements OnInit {
  userName$: Observable<string>;
  passwordModel: string;

  private _passwordError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  passwordHasError$: Observable<boolean> = this._passwordError$.asObservable();

  readonly INVALID_LOGIN = 'Password is incorrect, please try again';

  constructor(private dialogRef: MatDialogRef<SessionExpiryDialogComponent, boolean>, private userService: UserService, private store$: Store<AppState>) {
  }

  ngOnInit(): void {
    this.userName$ = this.store$.pipe(
      select(userDataView),
      map((user: UserData) => user.userKey)
    );
  }

  login(prefilledUsername: string): void {
    this.userService.login(prefilledUsername, this.passwordModel).pipe(
      map(() => {
        this._passwordError$.next(false);
        return true;
      }), catchError(() => {
        this._passwordError$.next(true);
        return of(false);
      })).subscribe((loginSuccess: boolean) => {
      if (loginSuccess) {
        this.dialogRef.close(true);
      }
    });
  }

  resetError() {
    this._passwordError$.next(false);
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }
}

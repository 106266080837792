import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TreeNodeWithDetails } from '../../../../tree-viewer/tree-node.model';
import { OutputType } from '../../planning-output.model';
import { Pattern } from '../../../../../patterns/pattern.model';
import { TreeNodeTypeEnum } from '../../../../tree-viewer/tree-node-type.enum';
import { DetailMessageHelper } from './detail-message.helper';
import { NavigationService } from '../../../../../navbar/navigation.service';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-detail-message',
  template: `
    <ng-container [ngSwitch]='outputType'>
      <ng-container *ngSwitchCase='OutputType.GENERATION'>
        <span>This {{node.type | lowercase}} was generated by the pattern </span>
        <a *ngIf='canDisplayPatternLink' href='javascript:void(0)' (click)='navigateToPattern(pattern.patternId)'>
          {{pattern.name}} <mat-icon>open_in_new</mat-icon>
        </a>
        <span> for the target host <strong>{{node.output.host}}.</strong></span>
      </ng-container>
      <ng-container *ngSwitchCase='OutputType.PLAN'>
        <span>
          This {{node.type | lowercase}} {{actionMessage}} on the target host <strong>{{node.output.host}}.</strong>
          <!-- This is special case for directories when it will not be changed but something within it might change -->
          <span *ngIf='node.type === TreeNodeTypeEnum.Directory && !node?.details.tasks.length && node.hasDescendantTask'>
            However, some of the files and directories within this directory will change.
          </span>
        </span>
        <ng-container *ngIf='canDisplayPatternLink'>
          <span>It was generated by the pattern </span>
          <a href='javascript:void(0)' (click)='navigateToPattern(pattern.patternId)'>
            {{pattern.name}} <mat-icon class='details-link'>open_in_new</mat-icon>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailMessageComponent implements OnChanges {
  @Input() node: TreeNodeWithDetails<any>;
  @Input() projectKey: string;
  @Input() pattern: Pattern;
  @Input() outputType: OutputType;
  readonly OutputType = OutputType;
  readonly TreeNodeTypeEnum = TreeNodeTypeEnum;
  actionMessage: string;

  constructor(private navigationService: NavigationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['node'] && this.node) {
      this.actionMessage = DetailMessageHelper.getActionTextByCriteria(this.node, this.outputType);
    }
  }

  navigateToPattern(patternId: string): void {
    this.navigationService.navigateToPattern(this.projectKey, patternId);
  }

  get canDisplayPatternLink(): boolean {
    return !_.isNil(this.projectKey) && !_.isNil(this.pattern);
  }
}

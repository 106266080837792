import { TextDiffData } from '../../../common/model/publish-changes/text-diff-data.model';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { Revision } from '../../../version-control/revision/revision.model';
import { BundlesVersionedInfo } from '../../../version-control/bundles-meta-info.model';
import * as _ from 'lodash';

export class BundlesDiffViewHelper {
  static createBundlesDiff(bundlesMeta: BundlesVersionedInfo, revision?: Revision): Diff<TextDiffData> {
    const sortedLocalBundles: string[] = this.sortBundles(bundlesMeta.items);
    const sortedRemoteBundles: string[] = _.isNil(bundlesMeta._meta.headValue) ? [] : this.sortBundles(bundlesMeta._meta.headValue);
    return {
      local: {
        fallbackLabel: '',
        content: {
          text: this.createBudnleListContent(sortedLocalBundles)
        },
        changeInfo: bundlesMeta._meta
      },
      remote: {
        commitInfo: revision,
        content: {
          text: this.createBudnleListContent(sortedRemoteBundles)
        }
      }
    };
  }

  private static sortBundles(bundles: string[]): string[] {
    return [...bundles].sort((bundle1: string, bundle2: string) => {
      const [bundleName1] = bundle1.split(':');
      const [bundleName2] = bundle2.split(':');
      return bundleName1.localeCompare(bundleName2);
    });
  }

  private static createBudnleListContent(bundles: string[]): string {
    return bundles.join('\n');
  }
}

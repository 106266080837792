import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { projectKeyView, projectReportsView } from '../model/views';
import { AppState } from '../model/reducer';
import { first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { PluginModel } from '../plugins/plugin.model';
import * as _ from 'lodash';
import { PluginService } from '../plugins/plugin.service';
import { PluginClassEnum } from '../plugins/plugin.enums';
import { RouteParamHelper } from '../common/helpers/route-param.helper';
import { reportClassParam } from '../projects/project-routing.constants';
import { NavigationService } from '../navbar/navigation.service';
import { ModalNotificationService } from '../notification/modal-notification.service';
import { LoadReportsSuccess } from '../model/report/report.actions';

@Injectable({
  providedIn: 'root'
})
export class ReportGuard  {

  constructor(private store$: Store<AppState>,
              private pluginService: PluginService,
              private navigationService: NavigationService,
              private modalNotificationService: ModalNotificationService) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivateReportScreen(next);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivateReportScreen(childRoute);
  }

  private canActivateReportScreen(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store$.pipe(
      select(projectReportsView),
      first(),
      switchMap((reports: PluginModel[]) => {
        if (_.isEmpty(reports)) {
          return this.store$.pipe(
            select(projectKeyView),
            first((projectKey: string | null) => !_.isNil(projectKey)),
            mergeMap((projectKey: string) => this.pluginService.getPluginClassesFilteredByClass(projectKey, PluginClassEnum.Report)),
            tap((loadedReports: PluginModel[]) => this.store$.dispatch(new LoadReportsSuccess(loadedReports)))
          );
        }
        return of(reports);
      }),
      map((reports: PluginModel[]) => _.sortBy(reports, 'name')),
      withLatestFrom(this.store$.pipe(select(projectKeyView))),
      map(([reports, projectKey]: [PluginModel[], string]) => this.canActivateBasedOnData(next, reports, projectKey))
    );
  }

  private canActivateBasedOnData(next: ActivatedRouteSnapshot, reports: PluginModel[], projectKey: string): boolean {
    const queryReportClass: string | undefined = RouteParamHelper.getPathParamFromRoute(next, reportClassParam);
    const queriedReport: PluginModel | undefined = _.isNil(queryReportClass) ? undefined : reports.find(report => report.className === queryReportClass);
    if (!_.isNil(queryReportClass) && _.isNil(queriedReport)) {
      this.modalNotificationService.openErrorDialog({title: 'Cannot open report', description: `Report for className ${queryReportClass} does not exist. Please, check for typos or make sure that you have necessary bundles.`});
    }
    if (!_.isNil(queriedReport)) {
      return true;
    }
    if (!_.isEmpty(reports) && (_.isNil(queryReportClass) || _.isNil(queriedReport))) {
      setTimeout(() => this.navigationService.navigateToReport(projectKey, reports[0].className));
      return false;
    }
    if (_.isEmpty(reports) && !_.isNil(queryReportClass)) {
      setTimeout(() => this.navigationService.navigateToReports(projectKey));
      return false;
    }
    return true;
  }
}

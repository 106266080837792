import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { generatedReportsView, isReportGenerationInProgressView, projectKeyView, sortedProjectReportsView } from '../model/views';
import * as _ from 'lodash';
import { AppState, Dictionary } from '../model/reducer';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { PluginModel } from '../plugins/plugin.model';
import { Inventory } from '../inventory/inventory.model';
import { LoadVariables } from '../model/variables';
import { GeneratedReportInfo, ReportGenerationStatus } from './reports.model';
import { InventoryService } from '../inventory/inventory.service';
import { TenantHelper } from '../common/helpers/tenant.helper';

@Injectable()
export class ReportsContext {

  private _selectedReportClassName: BehaviorSubject<string | null> = new BehaviorSubject(null);

  projectKey$: Observable<string | null> = this.store$.pipe(select(projectKeyView));
  reports$: Observable<PluginModel[]> = this.store$.pipe(select(sortedProjectReportsView));
  selectedReportClassName$: Observable<string | null> = this._selectedReportClassName.asObservable().pipe(distinctUntilChanged(_.isEqual));
  inventories$: Observable<Inventory[]> = this.projectKey$.pipe(
    filter((projectKey: string | null) => !_.isNil(projectKey)),
    map((projectKey: string) => TenantHelper.getTenantFromKey(projectKey)),
    switchMap((tenantKey: string) => this.inventoryService.getInventoriesOfTenant(tenantKey))
  );
  generatedReports$: Observable<Dictionary<GeneratedReportInfo>> = this.store$.pipe(select(generatedReportsView));
  isGenerationInProgress$: Observable<boolean> = this.store$.pipe(select(isReportGenerationInProgressView));

  isGenerationFailed$: Observable<boolean>;
  isNothingToDo$: Observable<boolean>;
  lastReportInfo$: Observable<GeneratedReportInfo | null>;
  generationStatus$: Observable<ReportGenerationStatus | null>;
  generationOutput$: Observable<string | null>;
  notGenerated$: Observable<boolean>;
  showGenerationOutput$: Observable<boolean>;

  constructor(private store$: Store<AppState>, private inventoryService: InventoryService) {
    this.lastReportInfo$ = combineLatest([this.generatedReports$, this.projectKey$, this.selectedReportClassName$]).pipe(
      map(([generatedReports, selectedProjectKey, selectedReportClassName]: [Dictionary<GeneratedReportInfo>, string | null, string]) => {
          if (_.isNil(selectedProjectKey)) {
            return null;
          }
          const generatedReportKey = selectedProjectKey.concat(selectedReportClassName);
          if (_.isEmpty(generatedReports) || _.isEmpty(generatedReports[generatedReportKey])) {
            return null;
          }
          return generatedReports[generatedReportKey];
        }
      ));
    this.generationStatus$ = this.lastReportInfo$.pipe(map((lastReportInfo: GeneratedReportInfo) => lastReportInfo ? lastReportInfo.finalGenerationStatus : null));
    this.generationOutput$ = this.lastReportInfo$.pipe(map((lastReportInfo: GeneratedReportInfo) => lastReportInfo ? lastReportInfo.generationOutput : null));
    this.isGenerationFailed$ = combineLatest([
      this.generationStatus$,
      this.isGenerationInProgress$
    ]).pipe(
      map(([generationStatus, isGenerationInProgress]: [ReportGenerationStatus, boolean]) => generationStatus === ReportGenerationStatus.FAILED && !isGenerationInProgress)
    );
    this.isNothingToDo$ = combineLatest([
      this.generationStatus$,
      this.isGenerationInProgress$
    ]).pipe(
      map(([generationStatus, isGenerationInProgress]: [ReportGenerationStatus, boolean]) => generationStatus === ReportGenerationStatus.NOTHING_TO_DO && !isGenerationInProgress)
    );
    this.notGenerated$ = combineLatest([this.lastReportInfo$, this.isGenerationInProgress$])
      .pipe(map(([lastReportInfo, isGenerationInProgress]: [GeneratedReportInfo | null, boolean]) => _.isEmpty(lastReportInfo) && !isGenerationInProgress));
    this.showGenerationOutput$ = combineLatest([this.generationOutput$, this.isGenerationInProgress$])
      .pipe(map(([generationOutput, isGenerationInProgress]: [string | null, boolean]) => !_.isEmpty(generationOutput) && !isGenerationInProgress));
  }

  loadProjectVariables(projectKey: string): void {
    this.store$.dispatch(new LoadVariables(projectKey));
  }

  selectReport(reportClassName: string): void {
    this._selectedReportClassName.next(reportClassName);
  }

}

import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { GetUserSuccess, SignOut } from '../../model/user';
import { UrlHelper } from '../helpers/url.helper';
import { HTTP_STATUS_NOT_FOUND, HTTP_STATUS_UNAUTHORIZED } from '../../shared/http-status-codes.constants';
import { SessionExpiryDialogService } from '../../user/login/session-expiry-dialog.service';
import { ProjectSyncService } from '../../projects/project-sync.service';
import { NavigationService } from '../../navbar/navigation.service';
import { isAuthenticatedView } from '../../model/views';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';


@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  hasOpenExpiryDialog = false;
  isAuthenticated$: Observable<boolean>;

  constructor(private store$: Store<AppState>, private zone: NgZone,
              private sessionExpiryDialogService: SessionExpiryDialogService,
              private syncService: ProjectSyncService,
              private navigationService: NavigationService,
              private matDialog: MatDialog) {
    this.isAuthenticated$ = this.store$.pipe(select(isAuthenticatedView));
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      tap(
        () => {/* not handling the values here */},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {

            this.SUPPORT_BACKEND_NOAUTH(err, this.store$);

            if (err.status === HTTP_STATUS_UNAUTHORIZED && !UrlHelper.isLogout(err.url)) {
              /**
               * need to enter to NgZone as the @ProjectSyncService.startSync do the polling outside of angular,
               * and its side effect could result in HTTP_STATUS_UNAUTHORIZED
               * triggering logout action outside angular causes issues in the login screen
               */
              this.zone.run(() => this.handleSessionExpiry());
            }
          }
        }
      ),
      catchError((error) => {
        if (error.status === HTTP_STATUS_UNAUTHORIZED && !UrlHelper.isLogin(error.url) && !UrlHelper.isGetUser(error.url)) {
          // redirection been handled by the interceptor, so swallow it to
          // not display unnecessary error message
          // only one exception is the login page
          return EMPTY;
        }
        return throwError(error);
      })
    );
  }

  /**
   * TEMPORARLY SOLUTION TO SUPPORT BACKEND WITHOUT AUTHENTICATION
   * NEVISADMV4-3326
   */
  private SUPPORT_BACKEND_NOAUTH(err: any, store$: Store<AppState>) {
    if (err.status === HTTP_STATUS_NOT_FOUND && UrlHelper.isGetUser(err.url)) {
      store$.dispatch(new GetUserSuccess({
        userKey: 'admin',
        email: '',
        type: 'local'
      }));
    }
  }

  private handleSessionExpiry() {
    this.isAuthenticated$.pipe(take(1), switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          return of(false);
        }
        return this.openExpiryDialogIfNeeded();

      }), map((isAuthenticated: boolean) => {
        // clear Store with new SignOut action when session expired and user is not authenticated (login screen or not cancelled expiry dialog)
        if (!isAuthenticated) {
          this.store$.dispatch(new SignOut(true));
        }
      })
    ).subscribe();
  }

  private openExpiryDialogIfNeeded(): Observable<boolean> {
    if (this.hasOpenExpiryDialog) return of(true);

    this.hasOpenExpiryDialog = true;
    return this.sessionExpiryDialogService.openSessionExpiryDialog()
      .afterClosed().pipe(switchMap((confirmed: boolean) => {
        this.hasOpenExpiryDialog = false;
        if (!confirmed) {
          const hasOpenDialog = !_.isEmpty(this.matDialog.openDialogs);
          if (hasOpenDialog) this.matDialog.closeAll();
          this.navigationService.navigateAwayFromModalWindow().then(() => {
            this.navigationService.navigateToLogin();
          });
        }
        return of(confirmed);
      }));
  }
}

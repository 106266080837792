import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { Observable } from 'rxjs';
import { BundleGroup } from './bundle-group.model';
import { bundlesView } from '../reducers/view';
import { map } from 'rxjs/operators';
import { ParsedBundle } from './bundle.model';
import { BundleManagementHelper } from './bundle-management.helper';
import { superAdminView } from '../../model/views';
import { LoadBundles, UploadBundles } from '../reducers/bundle-management.actions';

@Injectable()
export class BundleManagementContext {
  bundleGroups$: Observable<BundleGroup[]>;
  bundleUploadPermitted$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {
    this.bundleGroups$ = this.store$.pipe(select(bundlesView), map((bundles: ParsedBundle[]) => BundleManagementHelper.createBundleGroups(bundles)));
    this.bundleUploadPermitted$ = this.store$.pipe(select(superAdminView));
  }

  loadBundles(): void {
    this.store$.dispatch(new LoadBundles());
  }

  uploadBundles(files: File[]): void {
    this.store$.dispatch(new UploadBundles(files));
  }
}

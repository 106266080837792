import { Pattern, PatternLink } from './pattern.model';
import { PatternFieldMetaInfo, PropertyMetaInfo } from '../version-control/pattern-field-meta-info.model';

/**
 * Model of pattern type coming from backend
 * /nevisadmin/rest/v1/projects/<projectKey>/patterns/<patternId>
 */
export class PatternInstance extends Pattern {
  properties: Array<PatternProperty>;
}

/**
 * A field on a pattern. Only describes the field name and the value,
 * but the details of the field type are described in the other descriptors
 */
export class PatternProperty {
  propertyKey: string;
  value: any;
}

/**
 * Models the meta information of pattern instance. This is used instead of PatternInstance when backend is called
 * with 'meta=true'
 */
export interface PatternInstanceMeta extends PatternInstance {
  _meta: PatternInstanceMetaInfo;
}

/**
 * Models the meta information (version control) of a pattern instance
 * It's used when the backend is called with 'meta=true'
 */
export interface PatternInstanceMetaInfo {
  name: PatternFieldMetaInfo<string>;
  className: PatternFieldMetaInfo<string>;
  deploymentHosts?: PatternFieldMetaInfo<string>;
  label?: PatternFieldMetaInfo<string>;
  notes?: PatternFieldMetaInfo<string>;
  link: PatternFieldMetaInfo<PatternLink>;
  properties: PropertyMetaInfo[];
}

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import * as _ from 'lodash';

import { IssueModel, SourceType, Target } from '../common/model/issue.model';
import { IssueGroupList } from '../issues/model/issue-group-list.model';
import {
  getGeneralIssues, getIssuesById, getPatternIssuesOfNonExistingProperties, getPatternIssuesWithoutPropertyInfo, getSpecificTargetOfIssue, isErrorIssue, isInfoIssue, isWarningIssue
} from './project-issues/project-issues.helper';
import { PropertyInitHelper } from '../property-widgets/property-init.helper';
import { PatternIssueData } from '../issues/model/pattern-issue-data.model';
import { Dictionary } from '../model/reducer';
import { PatternType } from '../plugins/pattern-type.model';
import { PropertyType } from '../plugins/property-type.model';
import { PatternInstance } from '../patterns/pattern-instance.model';
import { addPropertyControlsToForm } from '../property-widgets/property-list-form.helper';
import { SpecificPatternFieldsEnum } from '../common/model/specific-pattern-fields.enum';
import { IssueSeverityCount } from './project-issues-display.model';

export class ProjectIssuesDisplayHelper {

  /**
   * @param {IssueModel[]} issues
   * @returns {string[]} - list of unique pattern ids of patterns that have issues
   */
  static getIssuedPatternIds(issues: IssueModel[]): string[] {
    return _(issues)
      .map(issue => getSpecificTargetOfIssue(issue, SourceType.PATTERN))
      .filter((target: Target | undefined) => !_.isNil(target))
      .map((patternTarget: Target) => patternTarget.value)
      .uniq()
      .value();
  }


  static createIssueGroupList(patterns: PatternInstance[], patternTypes: Dictionary<PatternType>, propertyTypes: Dictionary<PropertyType>, issues: IssueModel[]): IssueGroupList {
    return {
      generalIssues: getGeneralIssues(issues),
      patternIssuesData: this.getPatternIssuesData(patterns, patternTypes, propertyTypes, issues)
    };
  }

  /**
   * Patterns are pre sorted before mapping them to groups of issues, so to keep groups ordered by pattern name
   * @param {PatternInstance[]} patterns
   * @param {Dictionary<PatternType>} patternTypes
   * @param {Dictionary<PropertyType>} propertyTypes
   * @param {IssueModel[]} issues
   * @returns {PatternIssueData[]} - groups of issues by pattern with required data in order to display it as list component
   */
  static getPatternIssuesData(patterns: PatternInstance[], patternTypes: Dictionary<PatternType>, propertyTypes: Dictionary<PropertyType>, issues: IssueModel[]): PatternIssueData[] {
    return this.getSortedPatternInstances(patterns)
      .map((pattern: PatternInstance) => this.createPatternIssueData(pattern, patternTypes, propertyTypes, issues));
  }

  static getSortedPatternInstances(patterns: PatternInstance[]): PatternInstance[] {
    return [...patterns].sort((p1: PatternInstance, p2: PatternInstance) => p1.name.localeCompare(p2.name));
  }

  static createPatternIssueData(pattern: PatternInstance, patternTypes: Dictionary<PatternType>, propertyTypes: Dictionary<PropertyType>, issues: IssueModel[]): PatternIssueData {
    const patternType = patternTypes[pattern.className];
    const patternIssues = getIssuesById(issues, SourceType.PATTERN, pattern.patternId);
    const deploymentHostIssues = getIssuesById(patternIssues, SourceType.FIELD, SpecificPatternFieldsEnum.DeploymentHosts);
    const propertiesData = PropertyInitHelper.initPropertyModel(pattern, patternType, propertyTypes, patternIssues).filter(propertyData => !_.isEmpty(propertyData.issues));
    // controls for specific fields are not extracted to a separate method, because it's unclear how such fields will behave if more will be created later
    const form = new UntypedFormGroup({[SpecificPatternFieldsEnum.DeploymentHosts]: new UntypedFormControl(pattern.deploymentHosts)}, null);
    addPropertyControlsToForm(form, propertiesData);
    return {
      pattern: pattern,
      propertiesData: propertiesData,
      form: form,
      generalIssues: this.getUncategorizedPatternIssues(patternIssues, pattern),
      deploymentHostIssues: deploymentHostIssues
    };
  }

  static getUncategorizedPatternIssues(issues: IssueModel[], pattern: PatternInstance): IssueModel[] {
    const patternGeneralIssues = getPatternIssuesWithoutPropertyInfo(issues, pattern.patternId);
    // if for any reason pattern has issues on properties that don't exist, such issues will be included in general
    const missingPatternIssues = _.isEmpty(pattern.className) ? getPatternIssuesOfNonExistingProperties(issues, pattern) : [];
    return  _.concat(patternGeneralIssues, missingPatternIssues);
  }

  static issuesToCountBySeverity(issues: IssueModel[]): IssueSeverityCount {
    const errorCount: number = issues.filter(isErrorIssue).length;
    const warningCount: number = issues.filter(isWarningIssue).length;
    const infoCount: number = issues.filter(isInfoIssue).length;
    return {error: errorCount, warning: warningCount, info: infoCount};
  }

}

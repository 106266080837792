import { Injectable } from '@angular/core';
import { VariableModel } from '../../variables/variable.model';
import * as _ from 'lodash';
import { skipIdent } from '../../common/utils/utils';
import { yamlBasicIndentation } from '../inventory-editor/yaml-code-editor.constants';
import { PropertyTypeClassEnum } from '../../plugins/plugin.enums';

@Injectable()
export class InsertProjectVariablesHelper {
  static convertVariableListToYaml(variables: VariableModel[], projectName: string): string {
    if (_.isEmpty(variables)) {
      return '';
    }

    const beginText =
      skipIdent`Variables added from project ${projectName}
      Please copy them to any vars:-section above, or uncomment them (shortcut: CTRL-/)

      vars:`;
    return this.convertVariablesToYaml(variables, beginText);
  }

  static convertCertificatesVariablesToYaml(fileList: string[]): string {
    if (_.isEmpty(fileList)) {
      return '';
    }

    const beginText =
      skipIdent`Uploaded certificate
      Please copy that to any vars:-section above, or uncomment them (shortcut: CTRL-/)

      vars:`;
    return fileList.reduce((yaml: string, certificate: string) => {
        return `${yaml}\n${yamlBasicIndentation}${yamlBasicIndentation}- ${this.indentYaml(certificate)}`;
      }, beginText + this.indentYaml('\ntrusted-certificates:'));
  }

  static convertVariablesToYaml(variables: VariableModel[], beginText: string): string {
    return variables.map(variable => this.convertVariableToYaml(variable))
      .reduce((yaml: string, variableYaml: string) => {
        return `${yaml}\n  ${this.indentYaml(variableYaml)}`;
      }, beginText);
  }

  private static convertVariableToYaml(variable: VariableModel): string {
    if (_.isString(variable.value) && variable.className === PropertyTypeClassEnum.TextProperty) {
      const complexVariableValueYaml = this.indentYaml(variable.value);
      return `${variable.variableKey}: |\n${yamlBasicIndentation}${complexVariableValueYaml}`;
    } else if (_.isString(variable.value)) {
      return `${variable.variableKey}: ${variable.value}`;
    } else if (_.isArray(variable.value) && variable.value.length === 1) {
      return `${variable.variableKey}: ${variable.value[0]}`;
    } else if (_.isArray(variable.value)) {
      const multiLineVariableValueYaml = variable.value.join(`\n${yamlBasicIndentation}- `);
      return `${variable.variableKey}:\n${yamlBasicIndentation}- ${multiLineVariableValueYaml}`;
    } else if (_.isBoolean(variable.value)) {
      return `${variable.variableKey}: ${variable.value.toString()}`;
    }
    return `${variable.variableKey}:`;
  }

  private static indentYaml(yamlCode: string): string {
    return yamlCode.split('\n').join(`\n${yamlBasicIndentation}`);
  }
}

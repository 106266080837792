import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';

import { AppState } from '../../model/reducer';
import { ClearUserError, Login } from '../../user';
import { NavigationService } from '../../navbar/navigation.service';

@Component({
  selector: 'adm4-login',
  template: `
    <div class='login-container'>
      <img class='application-logo' [src]="'assets/Logo-Nevis-RGB-positive.svg'" alt=''/>
      <h1>Log in to nevisAdmin 4</h1>

      <form name='form'
            (ngSubmit)='f.form.valid && login()'
            #f='ngForm'
            novalidate>
        <div class='form-group' [ngClass]="{ 'has-error': f.submitted && !username.valid }">
          <div class="input-group">
            <input type='text'
                   class='form-control'
                   name='username'
                   placeholder='User name'
                   [(ngModel)]='model.username'
                   (ngModelChange)='resetError()'
                   #username='ngModel'
                   #userNameInput
                   required
                   autofocus/>
            <!-- Edge didn't display svg in background when it's cached, therefore this workaround is applied to avoid that problem, because no idea why it happens, rly -->
            <img src='assets/login-profil-off.svg' alt='' class='input-group-prepend username-addon-off'/>
            <img src='assets/login-profil-on.svg' alt='' class='input-group-prepend username-addon'/>
            <img src='assets/loginUsernameInvalid.svg' alt='' class='input-group-prepend username-addon-error'/>
          </div>
          <div class='validation-message-container'>
            <div *ngIf='f.submitted && !username.valid' class='invalid-feedback'>
              <adm4-validation-indicator [isDisplayed]='true' [isError]='true' [diameter]='8'></adm4-validation-indicator>
              Username is required
            </div>
          </div>
        </div>
        <div class='form-group' [ngClass]="{ 'has-error': f.submitted && !password.valid }">
          <div class="input-group">
            <input type='password'
                   class='form-control'
                   name='password'
                   placeholder='Password'
                   [(ngModel)]='model.password'
                   (ngModelChange)='resetError()'
                   #password='ngModel'
                   required/>
            <span class='input-group-prepend password-addon'></span>
          </div>
          <div class='validation-message-container'>
            <div *ngIf='f.submitted && !password.valid' class='invalid-feedback'>
              <adm4-validation-indicator [isDisplayed]='true' [isError]='true' [diameter]='8'></adm4-validation-indicator>
              Password is required
            </div>
            <div *ngIf='error$ | async' class='error-message'>
              <adm4-validation-indicator [isDisplayed]='true' [isError]='true' [diameter]='8'></adm4-validation-indicator>
              {{(error$ | async)}}
            </div>
          </div>
        </div>
        <div class='form-group'>
          <button>Login</button>
        </div>
      </form>
    </div>
  `,
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  model: any = {};
  public error$: Observable<any | undefined>;

  @ViewChild('userNameInput', {static: true}) userNameInput;

  constructor(
    private store$: Store<AppState>,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.error$ = this.store$.select(appState => appState.userState.error);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.userNameInput.nativeElement.focus();
    });
  }

  login() {
    this.store$.dispatch(new Login({username: this.model.username, password: this.model.password}));
    this.navigateIfAuthenticated();
  }

  resetError() {
    this.store$.dispatch(new ClearUserError());
  }

  navigateIfAuthenticated() {
    this.store$.select(appState => appState.userState.loaded).pipe(
      filter(loaded => loaded),
      take(1),
      withLatestFrom(this.store$.select(appState => appState.userState.authenticated)),
    ).subscribe(
      ([loadState, _authState]: [boolean, boolean]) => {
        if (loadState) {
          this.navigationService.navigateToReturnUrl();
        }
      }
    );
  }
}

import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Maybe } from '../utils/utils';

/**
 * This directive vertically scrolls the item into view when the selected key equals the item's key.<br/>
 * **adm4ScrollTarget** The permanent key of the item that will be scrolled into view.
 * **scrollToWhenSelectedKey** The selected key that can change over time, this is matched against the key of this item.
 * **scrollMode** can be  'smooth' or 'instant'.<br/><br/>
 * **Note**, that when multiple elements are scrolled at the same time, you have to use **scrollMode** 'instant'
 * otherwise the smooth scrolling calls will interrupt each other and only the last one will be executed.
 */
@Directive({
  selector: '[adm4ScrollTarget]'
})
export class ScrollToWhenSelectedDirective implements OnChanges {

  private isSelected: boolean = false;

  /**
   * The permanent key of the item that will be scrolled into view.
   */
  @Input()
  public adm4ScrollTarget: Maybe<string>;

  /**
   * The selected key that can change over time, this is matched against the key of this item.
   */
  @Input()
  public scrollToWhenSelectedKey: Maybe<string>;

  /**
   * One of `'start'`, `'center'`, `'end'`, or `'nearest'`. Defaults to `'center'`;
   */
  @Input() scrollVerticalAlignment: 'start' | 'center' | 'end' | 'nearest' = 'center';

  /**
   * Can be  `'smooth'` or `'instant'`.<br/><br/>
   * **Note**, that when multiple elements are scrolled at the same time, you have to use **scrollMode** 'instant'
   * otherwise the smooth scrolling calls will interrupt each other and only the last one will be executed.
   */
  @Input()
  public scrollMode: 'smooth' | 'instant' = 'smooth';

  constructor(private host: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scrollToWhenSelectedKey) {
      const prevSelected: boolean = this.isSelected;
      this.isSelected = !!this.scrollToWhenSelectedKey && this.adm4ScrollTarget === this.scrollToWhenSelectedKey;
      if (this.isSelected && !prevSelected) {
        this.isSelected = true;
        setTimeout(() => this.host.nativeElement?.scrollIntoView({behavior: this.scrollMode, block: this.scrollVerticalAlignment}));
      }
    }
  }
}

<div class='vairable-creation'>
	<form [formGroup]='form' (ngSubmit)='onFormSubmit()'>
		<div class='variable-creation-form-elements content-container'>
			<h4 class='modal-local-heading'>Create new variable</h4>
			<div class='form-group' [class.has-error]='form.controls[variableNameFormControlName].invalid'>
				<label class='input-label' for='new-variable-name-input'>Variable name* (may only contain: A-Z, a-z, 0-9, _ and -)</label>
				<input id='new-variable-name-input' type='text' class='admn4-text-input form-control'
							 [readonly]='createVariableInputDisabled'
							 [formControl]='form.controls[variableNameFormControlName]'>
				<div class='validation-message-container'>
					<adm4-validation-message *ngIf="shouldShowVariableNameValidationIssue('required')"
																	 [isError]='true' message='Variable name should not be empty'></adm4-validation-message>
          <adm4-validation-message *ngIf="shouldShowVariableNameValidationIssue('existingVariable')"
                                   [isError]='true' message='Variable with such name already exists'></adm4-validation-message>
          <adm4-validation-message *ngIf="shouldShowVariableNameValidationIssue('pattern')"
                                   [isError]='true' message='Variable name does not match [A-Za-z0-9_-]*'></adm4-validation-message>
				</div>
			</div>
			<div class='form-group'>
        <label class='input-label'>Sample value</label>
        <ng-container *ngIf='canVariableDefineSampleValueonCreation; else noSampleValueVariable'>
          <ng-template adm4DynamicVariable
                       [readOnly]='createVariableInputDisabled'
                       [formGroup]='form'
                       [variableName]='variableValueFormControlName'
                       [variableValue]='form.value[variableValueFormControlName]'
                       [variableType]='createVariableData.variableType'
                       [variableParameters]='createVariableData.patternTypeProperty?.parameters'></ng-template>
          <div class='validation-message-container'>
            <adm4-validation-message [isInfo]='true' message='This sample value is used for validation. To set the deployed value, edit the inventory.'></adm4-validation-message>
          </div>
        </ng-container>
				<ng-template #noSampleValueVariable>
          <div class='validation-message-container'>
            <adm4-no-sample-value-variable [variableType]='variableType' [secret]='isSecret'></adm4-no-sample-value-variable>
          </div>
        </ng-template>
			</div>
			<ng-container *ngIf='isVariableDropdownShown'>
        <div class='variable-selection'>
          <h4 class='modal-local-heading'>Or select an existing variable</h4>
          <adm4-variable-selection [variables]='variablesForSelection'
                                   [projectKey]='createVariableData.projectKey'
                                   (variableSelected)='selectVariable($event)'
                                   (removeSelectedVariable)='selectVariable(null)'>
          </adm4-variable-selection>
        </div>
			</ng-container>
		</div>
		<div mat-dialog-actions>
			<adm4-button-bar [isSubmitDisabled]="form.invalid"
                       [submitButtonText]="'Set variable'"
                       (cancelClicked)='cancel.emit()'></adm4-button-bar>
		</div>
	</form>
</div>

import { Pattern } from '../../patterns/pattern.model';
import { VariableModel } from '../../variables/variable.model';
import { InventorySampleContentCommonHelper } from './inventory-sample-content-common.helper';
import * as _ from 'lodash';
import * as yaml from 'js-yaml';

export class InventorySampleContentHelper {

  /**
   * Creates content for the new initial inventory with optional sample data.
   *
   * @param includeSample If it is true, it will include the sample data. Otherwise not.
   * @param patterns (Optional) array of patterns to be used for generating sample inventory data based on their deploymentHosts.
   * @param variables (Optional) array of variables to be used for generating 'vars' block into sample inventory content.
   *
   * @returns {string} the initial inventory content.
   */
  static createInitialInventoryContent(includeSample: boolean, patterns?: Pattern[], variables?: VariableModel[]): string {

    let content = 'schemaVersion: 1\n\n';

    if (includeSample) {
       content += this.createSample(patterns, variables);
    }
    return content;
  }

  static createSample(patterns?: Pattern[], variables?: VariableModel[]): string {
    // The hosts section is static so it is easier to add it as string yaml directly.
    const hosts =
`hosts:   # replace the hosts with to ones in your infrastructure
- host1:
    vars:
      __connection_user: #insert user
      __connection_password: #insert password
`;
    const groups = this.createGroupsBlock(patterns);
    const variablesPart = InventorySampleContentCommonHelper.createVariablesSampleBlock(variables);

    const parts = [
      hosts,
      groups
    ];

    if (variablesPart) {
      const automaticPKIForDev =
`  # set to true for DEV only
  __nevisadmin_pki_automatic_enabled: false
`;
      parts.push(variablesPart + automaticPKIForDev);
    }

    // Add a new line between blocks.
    return parts.join('\n');
  }

  static createGroupsBlock(patterns?: Pattern[]): string {
    if (_.isNil(patterns) || _.isEmpty(patterns)) {
      return 'groups:\n';
    } else {
      const deploymentHosts = InventorySampleContentCommonHelper.getDeploymentHosts(patterns);

      if (_.isEmpty(deploymentHosts)) {
        return 'groups:\n';
      }

      const groups = deploymentHosts.map((deploymentHost: string) => {
        return {
          [deploymentHost]: {
            members: [
              'host1'
            ]
          }
        };
      });

      const dummyObject = {
        groups: groups
      };

      const dumped = yaml.safeDump(dummyObject);
      // Adding comments to YAML is not supported with js-yaml, so the workaround is to add them by string replace.
      return dumped.replace('groups:', 'groups:   # replace the member hosts with to ones in the "hosts" section above');
    }
  }
}

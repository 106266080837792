import { PatternDiffData } from '../../projects/publish-project/pattern-diff-view/pattern-diff-data.model';
import { PublishProjectActionTypes } from './publish-project.actions';
import { NevisAdminAction } from '../actions';
import { Diff } from '../../common/model/publish-changes/diff.model';
import { VariableDiffData } from '../../projects/publish-project/variables-diff-view/variable-diff-data.model';
import { TextDiffData } from '../../common/model/publish-changes/text-diff-data.model';

export interface PublishProjectState {
  patternsDiffData: PatternDiffData[];
  variablesDiffData: Diff<VariableDiffData>[];
  showOnlyDifferences: boolean;
  bundlesDiff: Diff<TextDiffData> | null;
  projectDescriptionDiff: Diff<TextDiffData> | null;
}

export const initialState: PublishProjectState = {
  patternsDiffData: [],
  variablesDiffData: [],
  showOnlyDifferences: true,
  bundlesDiff: null,
  projectDescriptionDiff: null,
};

export function reducer(state = initialState, action: NevisAdminAction<any>): PublishProjectState {
  switch (action.type) {
    case PublishProjectActionTypes.LoadPatternDiffDataSuccess:
      return {
        ...state,
        patternsDiffData: [...state.patternsDiffData, action.payload]
      };
    case PublishProjectActionTypes.LoadVariablesDiffDataSuccess:
      return {
        ...state,
        variablesDiffData: action.payload
      };
    case PublishProjectActionTypes.LoadBundlesDiffDataSuccess:
      return {
        ...state,
        bundlesDiff: action.payload
      };
    case PublishProjectActionTypes.LoadProjectDiffDataSuccess:
      return {
        ...state,
        projectDescriptionDiff: action.payload,
      };
    case PublishProjectActionTypes.StoreShowOnlyDifferences:
      return {
        ...state,
        showOnlyDifferences: action.payload
      };
    case PublishProjectActionTypes.ClearPublishProjectData:
      return initialState;
    default:
      return state;
  }
}

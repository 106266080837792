import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DeploymentPreviewDetailsComponent } from '../deployment-preview-details/deployment-preview-details.component';

@Component({
  selector: 'adm4-deployment-preview-details-kubernetes',
  templateUrl: './deployment-preview-details-kubernetes.component.html',
  styleUrls: ['../deployment-preview-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentPreviewDetailsKubernetesComponent extends DeploymentPreviewDetailsComponent {
}

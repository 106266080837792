import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { Observable, of } from 'rxjs';
import * as _ from 'lodash';

import { InventoryResourceActionType, SecretManagementTableModel } from './secret-management-table.model';
import {
  InventoryResourceType,
  ResourceWrapperWithUsage,
  SecretLikeWrapperWithUsage,
  SecretResourceWrapperWithUsage,
  SecretWrapperWithUsage,
  TenantResourceFlag,
} from '../../../inventory/inventory.model';
import { InventoryResourceTypeHelper } from '../inventory-resource-type.helper';
import { reverseCompareResult } from '../../../common/utils/utils';
import { InventoryResourceSortingHelper } from './inventory-resource-sorting.helper';
import { PatternMasterListHelper } from '../../../patterns/pattern-master-list.helper';
import { NavigationService } from '../../../navbar/navigation.service';
import { SecretManagementContextService } from '../secret-management-context.service';

type SecretTableModel = (SecretLikeWrapperWithUsage) & TenantResourceFlag & {
  typeSpecificId: string;
  isUsed: boolean;
};

@Component({
  selector: 'adm4-secret-management-table',
  templateUrl: './secret-management-table.component.html',
  styleUrls: ['./secret-management-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecretManagementTableComponent implements OnChanges, AfterViewInit {
  @Input() resourceGroup: (SecretLikeWrapperWithUsage & TenantResourceFlag)[];
  @Input() displayedColumns: SecretManagementTableModel[];
  @Input() resourceType: InventoryResourceType;
  @Input() filterText: string;
  @Input() selectedScope: string;
  @Output() viewResource: EventEmitter<SecretLikeWrapperWithUsage & TenantResourceFlag> = new EventEmitter();
  @Output() editResource: EventEmitter<SecretLikeWrapperWithUsage & TenantResourceFlag> = new EventEmitter();
  @Output() deleteResource: EventEmitter<SecretLikeWrapperWithUsage & TenantResourceFlag> = new EventEmitter();
  @Output() expandScopeToTenantResource: EventEmitter<SecretLikeWrapperWithUsage & TenantResourceFlag> = new EventEmitter();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  tableDataSource: MatTableDataSource<SecretTableModel> = new MatTableDataSource([]);

  hasViewSecretContentTenantLevelPermission$: Observable<boolean>;
  hasViewSecretContentInventoryLevelPermission$: Observable<boolean>;
  hasModifyInventoryPermission$: Observable<boolean>;
  hasModifyTenantPermission$: Observable<boolean>;
  hasModifyTenantAndInventoryPermission$: Observable<boolean>;

  typeSpecificIdColumnDef: string;

  isExpandedUsage = false;
  readonly resourceTableColumns = SecretManagementTableModel;
  readonly inventoryResourceActionTypes = InventoryResourceActionType;

  constructor(
    private navigationService: NavigationService,
    private context: SecretManagementContextService,
  ) {
    this.hasViewSecretContentTenantLevelPermission$ = this.context.hasViewSecretContentTenantLevelPermission$;
    this.hasViewSecretContentInventoryLevelPermission$ = this.context.hasViewSecretContentInventoryLevelPermission$;
    this.hasModifyInventoryPermission$ = this.context.hasModifyInventoryPermission$;
    this.hasModifyTenantPermission$ = this.context.hasModifyTenantPermission$;
    this.hasModifyTenantAndInventoryPermission$ = this.context.hasModifyTenantAndInventoryPermission$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resourceGroup'] || changes['filterText']) {
      this.tableDataSource.data = this.mapAndFilterResourcesFromInput();
    }
    if (changes.resourceType) {
      this.typeSpecificIdColumnDef = InventoryResourceTypeHelper.getTypeSpecificIdColumnDef(this.resourceType);
    }
  }

  hasModifyPermissionByLevel(resourceItem: (SecretLikeWrapperWithUsage & TenantResourceFlag)): Observable<boolean> {
    return resourceItem.isTenantScoped ? this.hasModifyTenantPermission$ : this.hasModifyInventoryPermission$;
  }

  hasViewSecretContentPermissionByLevel(resourceItem: (SecretLikeWrapperWithUsage & TenantResourceFlag)): Observable<boolean> {
    if (!InventoryResourceTypeHelper.isSecret(this.resourceType)) return of(true);
    return resourceItem.isTenantScoped ? this.hasViewSecretContentTenantLevelPermission$ : this.hasViewSecretContentInventoryLevelPermission$;
  }

  private shouldBeFilteredBySearch(resourceItem: (SecretLikeWrapperWithUsage & TenantResourceFlag), regExp: RegExp): boolean {
    if (_.isEmpty(this.filterText)) {
      return true;
    }
    const matchesId: boolean = !_.isNil(resourceItem) && !_.isEmpty(this.getTypeSpecificId(resourceItem).match(regExp));
    const matchesUsage: boolean = !_.isNil(resourceItem?.usedIn) && resourceItem?.usedIn?.some(usage => usage.match(regExp));
    const matchesFileName: boolean = !_.isNil(resourceItem) && 'secretResourceName' in resourceItem && !_.isEmpty(resourceItem.secretResourceName.match(regExp)) ||
      'resourceName' in resourceItem && !_.isEmpty(resourceItem.resourceName.match(regExp));
    const matchesDescription: boolean = !_.isNil(resourceItem.description) && !_.isEmpty(resourceItem.description.match(regExp));
    return matchesId || matchesUsage || matchesFileName || matchesDescription;
  }

  private mapAndFilterResourcesFromInput(): SecretTableModel[]{
    const regExp = PatternMasterListHelper.getFilterRegexpByWord(this.filterText);
    return this.resourceGroup
      .filter(certificate => _.isEmpty(this.filterText) || this.shouldBeFilteredBySearch(certificate, regExp))
      .map((resource: (SecretLikeWrapperWithUsage) & TenantResourceFlag): SecretTableModel => {
        return {
          ...resource,
          typeSpecificId: this.getTypeSpecificId(resource),
          isUsed: this.isUsedResource(resource),
        };
      });
  }

  ngAfterViewInit(): void {
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.sortData = this.resourceGroupSorting();
  }

  resourceGroupSorting<T extends (SecretLikeWrapperWithUsage & TenantResourceFlag)>(): (data: T[], sort: MatSort) => T[] {
    return (data:  T[], sort: MatSort) => {
      switch (sort.active) {
        case SecretManagementTableModel.IdColumnName:
          return data.sort((res1: T, res2: T) => {
            const compareResult = InventoryResourceSortingHelper.byTenantScopeSortingFn(res1, res2);
            return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
          });
        case SecretManagementTableModel.FileNameColumnName:
          return data.sort((res1: T, res2: T) => {
            const compareResult = InventoryResourceSortingHelper.byFileNameSortingFn(res1, res2);
            return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
          });
        default:
          return data;
      }
    };
  }

  get hasDisplayableData(): boolean {
    return this.tableDataSource.data.length > 0;
  }

  get noResourceFoundText(): string {
    const resourceTypeExpression = InventoryResourceType.PLAIN_TEXT_SECRET === this.resourceType ? 'secret' : this.resourceType.toLowerCase();
    return `There is no ${resourceTypeExpression} found.`;
  }

  public toggleUsedInAll() {
    const targetExpandState = !this.isExpandedUsage;
    this.tableDataSource.data.forEach((resource: SecretTableModel): void => {
      resource.isExpanded = targetExpandState;
    });
    this.isExpandedUsage = this.calculateIsExpandedUsage();
  }

  public toggleUsedIn(resourceItem?: SecretTableModel): void {
    // using some casts because both the method input and the array we are searching in are union types
    const secretToToggleIndex = this.tableDataSource.data.findIndex((resource: SecretTableModel): boolean => {
      if ((resource as SecretWrapperWithUsage).secretId)
        return (resource as SecretWrapperWithUsage).secretId === (resourceItem as SecretWrapperWithUsage).secretId;
      if ((resource as SecretResourceWrapperWithUsage).secretResourceId)
        return (resource as SecretResourceWrapperWithUsage).secretResourceId === (resourceItem as SecretResourceWrapperWithUsage).secretResourceId;
      if ((resource as ResourceWrapperWithUsage).resourceId)
        return (resource as ResourceWrapperWithUsage).resourceId === (resourceItem as ResourceWrapperWithUsage).resourceId;
      return false;
    });
    this.tableDataSource.data[secretToToggleIndex].isExpanded = !this.tableDataSource.data[secretToToggleIndex].isExpanded;
    this.isExpandedUsage = this.calculateIsExpandedUsage();
  }

  private calculateIsExpandedUsage(): boolean {
    return this.tableDataSource.data.every((resource: SecretTableModel) => {
      if (!resource?.usedIn || resource?.usedIn?.length <= 1) {
        return true;
      }
      return resource.isExpanded === true;
    });
  }

  navigateToInventory(inventoryKey: string): void {
    this.navigationService.navigateToInventory(inventoryKey);
  }

  getExtendScopeActionTooltip(hasPermission: boolean): string {
    return hasPermission ? 'Extend scope' : 'You don’t have permission to extend the scope.';
  }

  getResourceActionBtnTooltip(hasPermission: boolean, inventoryResourceActionType: InventoryResourceActionType): string {
    if (hasPermission) {
      return InventoryResourceActionType.Delete === inventoryResourceActionType ? inventoryResourceActionType : `${inventoryResourceActionType} content`;
    }
    return `You don’t have permission to ${inventoryResourceActionType.toLowerCase()}.`;
  }

  private getTypeSpecificId(inventoryResourceItem: SecretLikeWrapperWithUsage): string {
    return InventoryResourceTypeHelper.getTypeSpecificIdWithName(inventoryResourceItem);
  }

  private isUsedResource(resourceItem: ((SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag)): boolean {
    return !_.isNil(resourceItem.usedIn) && resourceItem.usedIn?.length > 0;
  }
}

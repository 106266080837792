import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadResultsPayload } from './file-upload-results-payload.model';
import * as _ from 'lodash';
import { FileUploadResult, FileUploadStatus } from '../../model/file-upload-result.model';

@Component({
  selector: 'adm4-file-upload-results-dialog',
  template: `
    <adm4-modal-dialog-title [header]='header' [showClose]='true' (closeClicked)='close()'>
      <div class='file-upload-results-container content-container'>
        <div *ngIf='hasFailedResults' class='file-upload-result-group'>
          <div class='file-upload-result-group-title'>{{failureGroupText}}</div>
          <div>
            <div *ngFor='let result of failedResults' class='file-upload-result'>
              <mat-icon class='warning-icon'>report_problem</mat-icon>
              <div>
                <div><strong>{{result.fileName}}</strong></div>
                <div *ngIf='result.errorDetail'>Detail: {{result.errorDetail}}</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf='hasSuccessResults' class='file-upload-result-group'>
          <div class='file-upload-result-group-title'>{{successGroupText}}</div>
          <div>
            <div *ngFor='let result of successResults' class='file-upload-result'>
              <mat-icon class='done-icon'>done</mat-icon><strong>{{result.fileName}}</strong>
            </div>
          </div>
        </div>
      </div>
      <div mat-dialog-actions>
        <button type='button' class='admn4-button-ellipse-blue' (click)='close()'>OK</button>
      </div>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['./file-upload-results-dialog.component.scss', '../../styles/component-specific/modal-window.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadResultsDialogComponent {
  readonly DEFAULT_HEADER = 'Results of file uploading';
  readonly DEFAULT_FAILURE_GROUP_TEXT = 'Files failed to upload';
  readonly DEFAULT_SUCCESS_GROUP_TEXT = 'Successfully uploaded files';

  successResults: FileUploadResult[];
  failedResults: FileUploadResult[];

  constructor(@Inject(MAT_DIALOG_DATA) public fileUploadResultsPayload: FileUploadResultsPayload,
              private dialogRef: MatDialogRef<FileUploadResultsDialogComponent>) {
    this.successResults = fileUploadResultsPayload.fileUploadResults.filter(res => res.status === FileUploadStatus.Success);
    this.failedResults = fileUploadResultsPayload.fileUploadResults.filter(res => res.status === FileUploadStatus.Fail);
  }

  close(): void {
    this.dialogRef.close();
  }

  get header(): string {
    return _.isNil(this.fileUploadResultsPayload.headerText) ? this.DEFAULT_HEADER : this.fileUploadResultsPayload.headerText;
  }

  get hasFailedResults(): boolean {
    return !_.isEmpty(this.failedResults);
  }

  get hasSuccessResults(): boolean {
    return !_.isEmpty(this.successResults);
  }

  get successGroupText(): string {
    return _.isEmpty(this.fileUploadResultsPayload.successGroupText) || _.isNil(this.fileUploadResultsPayload.successGroupText) ? this.DEFAULT_SUCCESS_GROUP_TEXT : this.fileUploadResultsPayload.successGroupText;
  }

  get failureGroupText(): string {
    return _.isEmpty(this.fileUploadResultsPayload.failureGroupText) || _.isNil(this.fileUploadResultsPayload.failureGroupText) ? this.DEFAULT_FAILURE_GROUP_TEXT : this.fileUploadResultsPayload.failureGroupText;
  }
}

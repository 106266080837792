import { Component } from '@angular/core';
import { Event, EventType, IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

import { NavigationConstants } from './common/constants/navigation.constants';
import { applicationInfoView, availableVersionView, UpgradeInfo } from './model/views';
import { filterNotNil } from './common/utils/utils';
import { ApplicationInfo } from './model/shared/application-info.model';
import { AppState } from './model/reducer';

const nonStrictMatchOptions: IsActiveMatchOptions = {
  paths: 'subset',
  queryParams: 'subset',
  fragment: 'ignored',
  matrixParams: 'ignored'
};

@Component({
  selector: 'adm4-root-layout',
  template: `
    <div class='full-height-flex'>
      <ng-container *ngIf='shouldShowTopNavBarOnCurrentPage | async'>
        <adm4-navigation></adm4-navigation>
      </ng-container>
      <div class='remaining-space-flex-content-wrapper'>
        <div class="app-content remaining-space-flex-content">
          <adm4-key-shortcuts-component *ngIf='isApplicationInfoLoaded | async'></adm4-key-shortcuts-component>
          <router-outlet></router-outlet>
          <router-outlet name='modal'></router-outlet>
        </div>
      </div>
      <ng-container *ngIf="shouldShowTopNavBarOnCurrentPage | async">
        <adm4-footer [showVersion]='shouldShowTopNavBarOnCurrentPage | async'
                     [upgradeAvailable]='upgradeAvailable | async'
        ></adm4-footer>
      </ng-container>
    </div>`
})
export class AppLayoutComponent {
  public readonly upgradeAvailable: Observable<boolean>;
  public readonly isApplicationInfoLoaded: Observable<boolean>;
  public readonly shouldShowTopNavBarOnCurrentPage: Observable<boolean>;

  constructor(
      private router: Router,
      private store$: Store<AppState>,
  ) {
    this.isApplicationInfoLoaded = this.store$.select(applicationInfoView).pipe(
        filterNotNil(),
        map((appInfo: ApplicationInfo) => appInfo !== null),
    );
    this.upgradeAvailable = this.store$.select(availableVersionView).pipe(
        map((availableVersion: undefined | false | UpgradeInfo): boolean => !!availableVersion),
    );
    this.shouldShowTopNavBarOnCurrentPage = router.events.pipe(
        filter((event: Event): event is NavigationEnd => EventType.NavigationEnd === (event as any).type),
        map((): boolean => {
          const isLoginPage = this.router.isActive(NavigationConstants.LOGIN, nonStrictMatchOptions);
          const isGraphView = this.router.isActive(NavigationConstants.GRAPH_VIEW, nonStrictMatchOptions);
          return !(isLoginPage || isGraphView);
        }),
        startWith(true),
        shareReplay(),
    );
  }
}

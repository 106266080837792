import { NevisAdminAction } from '../actions';
import { CanaryRoutingOption, DeploymentProcessModel } from '../../deployment-wizard/deployment-dialog/deployment-process.model';
import { DeployToOption } from '../../deployment-wizard/deployment-selection/deploy-to-option.model';
import { DeploymentStartModel, DeploymentStatusModel, GenerationStatusModel } from '../../deployment-wizard/validate-deployment/generation-status.model';
import { IssueModel } from '../../common/model/issue.model';
import { GenerationOutputModel, PlanningOutputModel } from '../../deployment-wizard/deploy/deployment-preview/planning-output.model';
import { ValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';

export enum DeployActionTypes {
  StoreDeploymentWizardWindowState = '[Deploy] Store Deployment Wizard Window State',
  StoreDeploymentProject = '[Deploy] Store Deployment Project',
  StoreDeploymentInventory = '[Deploy] Store Deployment Inventory',
  StoreDeploymentHost = '[Deploy] Store Deployment Host',
  StoreDeploymentCanaryRoutingOptions = '[Deploy] Store Deployment Canary Routing Options',
  LoadDeployablePatternInstancesSuccess = '[Deploy] Load deployable pattern instances Success',
  LoadHostsSuccess = '[Deploy] Load hosts Success',
  LoadGroupsSuccess = '[Deploy] Load groups Success',
  StartDeploymentProcess = '[Deploy] Start Deployment Process',
  StartDeploymentProcessSuccess = '[Deploy] Start Deployment Process Success',
  StartGeneration = '[Deploy] Start Generation',
  StartGenerationFailed = '[Deploy] Start Generation Failed',
  PollGenerationStatus = '[Deploy] Poll Generation Status',
  PollGenerationStatusSuccess = '[Deploy] Poll Generation Status Success',
  ScheduleGenerationDone = '[Deploy] Schedule Generation Done',
  GenerationDone = '[Deploy] Generation Done',
  FetchGenerationIssues = '[Deploy] Fetch Generation Issues',
  FetchGenerationIssuesSuccess = '[Deploy] Fetch Generation Issues Success',
  FetchGenerationOutput = '[Deploy] Fetch Generation Output',
  FetchGenerationOutputSuccess = '[Deploy] Fetch Generation Output Success',
  AbortGeneration = '[Deploy] Abort Generation',
  AbortGenerationSuccess = '[Deploy] Abort Generation Success',
  StartDeploymentPlanning = '[Deploy] Start Deployment Planning',
  PollDeploymentPlanningStatus = '[Deploy] Poll Deployment Planning Status',
  PollDeploymentPlanningStatusSuccess = '[Deploy] Poll Deployment Planning Status Success',
  ScheduleDeploymentPlanningDone = '[Deploy] Schedule Deployment Planning Done',
  DeploymentPlanningDone = '[Deploy] Deployment Planning Done',
  FetchDeploymentPlanningOutput = '[Deploy] Fetch Deployment Planning Output',
  FetchDeploymentPlanningOutputSuccess = '[Deploy] Fetch Deployment Planning Output Success',
  ForceRedeployment = '[Deploy] Force redeployment',
  AbortDeploymentPlanning = '[Deploy] Abort Deployment Planning',
  AbortDeploymentPlanningSuccess = '[Deploy] Abort Deployment Planning Success',
  StartDeploy = '[Deploy] Start Deploy',
  PollDeploymentStatus = '[Deploy] Poll Deployment Status',
  PollDeploymentStatusSuccess = '[Deploy] Poll Deployment Status Success',
  GetDeploymentProcess = '[Deploy] Get Deployment Status',
  GetDeploymentProcessSuccess = '[Deploy] Get Deployment Status Success',
  DeleteDeploymentProcess = '[Deploy] Delete Deployment Process',
  DeleteDeploymentProcessSuccess = '[Deploy] Delete Deployment Process Success'
}

export class StoreDeploymentWizardWindowState implements NevisAdminAction<boolean> {
  readonly type = DeployActionTypes.StoreDeploymentWizardWindowState;

  constructor(public payload: boolean) {}
}

export class StoreDeploymentProject implements NevisAdminAction<string | undefined> {
  readonly type = DeployActionTypes.StoreDeploymentProject;

  constructor(public payload: string | undefined) {}
}

export class StoreDeploymentInventory implements NevisAdminAction<string | undefined> {
  readonly type = DeployActionTypes.StoreDeploymentInventory;

  constructor(public payload: string | undefined) {}
}

export class StoreDeploymentHost implements NevisAdminAction<string | undefined> {
  readonly type = DeployActionTypes.StoreDeploymentHost;

  constructor(public payload: string | undefined) {}
}

export class StoreDeploymentCanaryRoutingOptions implements NevisAdminAction<CanaryRoutingOption | undefined> {
  readonly type = DeployActionTypes.StoreDeploymentCanaryRoutingOptions;

  constructor(public payload: CanaryRoutingOption | undefined) {}
}

export class LoadDeployablePatternInstancesSuccess implements NevisAdminAction<DeployToOption[]> {
  readonly type = DeployActionTypes.LoadDeployablePatternInstancesSuccess;

  constructor(public payload: DeployToOption[]) {}
}

export class LoadHostsSuccess implements NevisAdminAction<DeployToOption[]> {
  readonly type = DeployActionTypes.LoadHostsSuccess;

  constructor(public payload: DeployToOption[]) {}
}

export class LoadGroupsSuccess implements NevisAdminAction<DeployToOption[]> {
  readonly type = DeployActionTypes.LoadGroupsSuccess;

  constructor(public payload: DeployToOption[]) {}
}

export class StartDeploymentProcess implements NevisAdminAction<DeploymentProcessModel> {
  readonly type = DeployActionTypes.StartDeploymentProcess;

  constructor(public payload: DeploymentProcessModel) {}
}

export class StartDeploymentProcessSuccess implements NevisAdminAction<DeploymentProcessModel> {
  readonly type = DeployActionTypes.StartDeploymentProcessSuccess;

  constructor(public payload: DeploymentProcessModel) {}
}

export class StartGeneration implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.StartGeneration;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class StartGenerationFailed implements NevisAdminAction<ValidationStatus<ValidationIssue> | null | undefined> {
  readonly type = DeployActionTypes.StartGenerationFailed;

  constructor(public payload: ValidationStatus<ValidationIssue> | null | undefined) {}
}

export class PollGenerationStatus implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.PollGenerationStatus;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class PollGenerationStatusSuccess implements NevisAdminAction<GenerationStatusModel> {
  readonly type = DeployActionTypes.PollGenerationStatusSuccess;

  constructor(public payload: GenerationStatusModel) {}
}

export class FetchGenerationIssues implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.FetchGenerationIssues;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class FetchGenerationIssuesSuccess implements NevisAdminAction<IssueModel[]> {
  readonly type = DeployActionTypes.FetchGenerationIssuesSuccess;

  constructor(public payload: IssueModel[]) {}
}

export class ScheduleGenerationDone implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.ScheduleGenerationDone;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class GenerationDone implements NevisAdminAction {
  readonly type = DeployActionTypes.GenerationDone;
}

export class FetchGenerationOutput implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.FetchGenerationOutput;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class FetchGenerationOutputSuccess implements NevisAdminAction<GenerationOutputModel[]> {
  readonly type = DeployActionTypes.FetchGenerationOutputSuccess;

  constructor(public payload: GenerationOutputModel[]) {}
}

export class AbortGeneration implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.AbortGeneration;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class AbortGenerationSuccess implements NevisAdminAction {
  readonly type = DeployActionTypes.AbortGenerationSuccess;
}

export class StartDeploymentPlanning implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.StartDeploymentPlanning;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class PollDeploymentPlanningStatus implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.PollDeploymentPlanningStatus;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class PollDeploymentPlanningStatusSuccess implements NevisAdminAction<DeploymentStatusModel> {
  readonly type = DeployActionTypes.PollDeploymentPlanningStatusSuccess;

  constructor(public payload: DeploymentStatusModel) {}
}

export class ScheduleDeploymentPlanningDone implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.ScheduleDeploymentPlanningDone;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class DeploymentPlanningDone implements NevisAdminAction {
  readonly type = DeployActionTypes.DeploymentPlanningDone;
}

export class FetchDeploymentPlanningOutput implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.FetchDeploymentPlanningOutput;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class FetchDeploymentPlanningOutputSuccess implements NevisAdminAction<PlanningOutputModel[]> {
  readonly type = DeployActionTypes.FetchDeploymentPlanningOutputSuccess;

  constructor(public payload: PlanningOutputModel[]) {}
}

export class ForceRedeployment implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.ForceRedeployment;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class AbortDeploymentPlanning implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.AbortDeploymentPlanning;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class AbortDeploymentPlanningSuccess implements NevisAdminAction {
  readonly type = DeployActionTypes.AbortDeploymentPlanningSuccess;
}

export class StartDeploy implements NevisAdminAction<DeploymentStartModel> {
  readonly type = DeployActionTypes.StartDeploy;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: DeploymentStartModel) {}
}

export class PollDeploymentStatus implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.PollDeploymentStatus;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class PollDeploymentStatusSuccess implements NevisAdminAction<DeploymentStatusModel> {
  readonly type = DeployActionTypes.PollDeploymentStatusSuccess;

  constructor(public payload: DeploymentStatusModel) {}
}

export class GetDeploymentProcess implements NevisAdminAction<string> {
  readonly type = DeployActionTypes.GetDeploymentProcess;

  /**
   * @param payload - deploymentId
   */
  constructor(public payload: string) {}
}

export class GetDeploymentProcessSuccess implements NevisAdminAction<DeploymentProcessModel> {
  readonly type = DeployActionTypes.GetDeploymentProcessSuccess;

  constructor(public payload: DeploymentProcessModel) {}
}

export class DeleteDeploymentProcess implements NevisAdminAction {
  readonly type = DeployActionTypes.DeleteDeploymentProcess;
}

export class DeleteDeploymentProcessSuccess implements NevisAdminAction {
  readonly type = DeployActionTypes.DeleteDeploymentProcessSuccess;
}

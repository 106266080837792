import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * @deprecated TODO NEVISADMV4-9206 Remove this pipe as it bypasses security
 */
@Pipe({name: 'reportsHtml'})
export class ReportsHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(html) {
    // TODO NEVISADMV4-9206 remove this when the reports are improved
    // eslint-disable-next-line ban/ban
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}

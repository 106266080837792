import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { BundleGroup } from '../bundle-group.model';
import { animate, style, transition, trigger } from '@angular/animations';
import * as _ from 'lodash';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { reverseCompareResult } from '../../../common/utils/utils';
import { VersionCompareHelper } from '../../../common/helpers/version-compare.helper';

@Component({
  selector: 'adm4-bundle-management-table',
  templateUrl: './bundle-management-table.component.html',
  styleUrls: ['./bundle-management-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('previousVersionBundlesExpand', [
      transition(':enter', [
        style({height: '0px', minHeight: '0'}),
        animate(600, style({height: '*'}))
      ]),
      transition(':leave', [
        style({height: '*'}),
        animate(600, style({height: '0px', minHeight: '0'}))
      ])
    ]),
  ]
})
export class BundleManagementTableComponent implements OnChanges, AfterViewInit {
  @Input() bundleGroups: BundleGroup[];
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  tableDataSource: MatTableDataSource<BundleGroup> = new MatTableDataSource([]);

  expandedBundleGroupKeys: string[] = [];

  readonly bundleNameColumnName = 'bundleName';
  readonly versionColumnName = 'version';
  readonly previousBundleVersionsColumnName = 'previousBundleVersions';

  displayedColumns = [this.bundleNameColumnName, this.versionColumnName];
  expandableColumns = [this.previousBundleVersionsColumnName];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.bundleGroups) {
      this.tableDataSource.data = this.bundleGroups;
    }
  }

  ngAfterViewInit(): void {
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.sortData = this.bundleGroupSorting();
  }

  private bundleGroupSorting(): (data: BundleGroup[], sort: MatSort) => BundleGroup[] {
    return (data: BundleGroup[], sort: MatSort) => {
      switch (sort.active) {
        case this.bundleNameColumnName:
          return data.sort((bg1: BundleGroup, bg2: BundleGroup) => {
            const compareResult = bg1.latestBundle.symbolicName.localeCompare(bg2.latestBundle.symbolicName);
            return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
          });
        case this.versionColumnName:
          return data.sort((bg1: BundleGroup, bg2: BundleGroup) => {
            const compareResult = VersionCompareHelper.compare(bg1.latestBundle.version, bg2.latestBundle.version);
            return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
          });
        default:
          return data;
      }
    };
  }

  isGroupExpanded(bundleGroup: BundleGroup): boolean {
    return _.some(this.expandedBundleGroupKeys, (groupKey: string) => groupKey === bundleGroup.latestBundle.symbolicName);
  }

  groupHasPreviousVersionBundles(bundleGroup: BundleGroup): boolean {
    return !_.isEmpty(bundleGroup.previousVersionBundles);
  }

  toggleBundleGroup(bundleGroup: BundleGroup): void {
    if (this.isGroupExpanded(bundleGroup)) {
      this.expandedBundleGroupKeys = this.expandedBundleGroupKeys.filter((groupKey: string) => groupKey !== bundleGroup.latestBundle.symbolicName);
    } else {
      this.expandedBundleGroupKeys = _.concat(this.expandedBundleGroupKeys, bundleGroup.latestBundle.symbolicName);
    }
  }

}

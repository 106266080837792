import { Component } from '@angular/core';
import { Dictionary, ProjectKey } from '../model/reducer';
import { IssueModel } from '../common/model/issue.model';
import { IssueSeverityEnum } from '../common/model/issue-severity.enum';
import { IssueGroupFilteringContext } from './issue-group-filtering.context';
import { Observable } from 'rxjs';
import { ProjectContext } from '../projects/project.context';
import { PropertyWidgetContext } from '../property-widgets/property-widget.context';
import { IssuesPropertyContext } from './issues-property.context';
import { SplitPaneConfig } from '../common/model/split-pane-config.model';
import { Mixin } from '../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../common/mixins/split.mixin';
import { ResizeHelper } from '../common/helpers/resize.helper';
import { IOutputData } from 'angular-split/lib/interface';
import { Project, ProjectMetaChangeIndicator } from '../projects/project.model';

@Component({
  selector: 'adm4-issues-main',
  templateUrl: './issues-main.component.html',
  providers: [
    IssueGroupFilteringContext,
    {provide: PropertyWidgetContext, useClass: IssuesPropertyContext}
  ]
})
@Mixin([SplitMixin])
export class IssuesMainComponent implements ISplitMixin {
  projectKey$: Observable<ProjectKey>;
  issues$: Observable<IssueModel[]>;
  highestSeverity$: Observable<IssueSeverityEnum>;
  projects$: Observable<Dictionary<Project>>;
  public projectMetaChangeIndicator$: Observable<ProjectMetaChangeIndicator>;

  readonly listSplitAreaKey = 'list';
  readonly issuesSplitAreaKey = 'issues';
  readonly helpSplitAreaKey = 'help';
  readonly splitPaneConfigLocalStorageKey = 'issue-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.listSplitAreaKey]: {order: 0, size: 30},
    [this.issuesSplitAreaKey]: {order: 1, size: 40},
    [this.helpSplitAreaKey]: {order: 2, size: 30, isCollapsed: false}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  /**
   * Implemented by SplitMixin
   */
  onCollapse: (isCollapsed: boolean, collapsibleAreaKey: string) => void;

  constructor(private projectContext: ProjectContext) {
    this.projectKey$ = this.projectContext.projectKey$;
    this.issues$ = this.projectContext.issues$;
    this.highestSeverity$ = this.projectContext.highestSeverity$;
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
    this.projects$ = this.projectContext.projects$;
    this.projectMetaChangeIndicator$ = this.projectContext.projectMetaChangeIndicator$;
  }
}

import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastNotificationService } from '../notification/toast-notification.service';
import { closeModalOnEscape } from '../modal-dialog/modal-dialog.helper';
import { Subject } from 'rxjs';

@Component({
  selector: 'adm4-change-password',
  template: `
		<adm4-modal-dialog-title header='Change Password' [showClose]='true' (closeClicked)="onCloseClicked()">
			<adm4-change-password-dialog
              (cancelClicked)='onCloseClicked()'
              (passwordChanged)='onChangePassword()'>
			</adm4-change-password-dialog>
		</adm4-modal-dialog-title>
  `
})
export class ChangePasswordComponent implements OnDestroy {

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private dialogRef: MatDialogRef<ChangePasswordComponent>,
              private toastService: ToastNotificationService) {

    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onChangePassword(): void {
    this.dialogRef.afterClosed().subscribe(() => {
      this.toastService.showSuccessToast('The new password has been saved.', 'Password change successful');
    });
    this.dialogRef.close();
  }

  onCloseClicked() {
    this.dialogRef.close();
  }
}

import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { editor } from 'monaco-editor';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { DirtyFormGuardConnectorService } from '../../services/dirty-form-guard-connector.service';
import { Maybe } from '../../utils/utils';

export interface TextEditorDialogPayload {
  content: string;
  syntax?: Maybe<string>;
}

@Component({
  selector: 'adm4-text-editor-dialog',
  templateUrl: './text-editor-dialog.component.html',
  styleUrls: ['./text-editor-dialog.component.scss', '../../styles/component-specific/modal-window.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-override-mdc-dialog-component-host'"},
})
export class TextEditorDialogComponent implements OnInit, OnDestroy {
  @ViewChild('editorContainer', {static: false}) editorContainer: ElementRef;
  @Output() textHasChanged = new EventEmitter<boolean>();

  editedContent: string;
  private readonly originalContent: string;
  private destroyed$: Subject<boolean> = new Subject();

  public readonly header = 'Edit';
  editorOptions: Record<string, Maybe<string | boolean>> = {
    // `wordBasedSuggestions` is _not_ typed, but works, see https://github.com/microsoft/monaco-editor/issues/1746
    wordBasedSuggestions: false,
    language: undefined,
  };
  public readonly syntax: Maybe<string>;

  constructor(
      @Inject(MAT_DIALOG_DATA) payload: TextEditorDialogPayload,
      private dialogRef: MatDialogRef<TextEditorDialogComponent>,
      public formGuardConnectorService: DirtyFormGuardConnectorService,
  ) {
    this.originalContent = payload.content;
    this.editedContent = payload.content;
    this.syntax = payload.syntax;
    this.editorOptions.language = this.syntax;
  }

  ngOnInit(): void {
    this.formGuardConnectorService.connect(this.textHasChanged, () => this.applyEdit());
    closeModalOnEscape(this.dialogRef, this.destroyed$, () => {
      this.formGuardConnectorService.doIfConfirmed(() => this.applyEdit());
    });
  }

  modelChanged(): void {
    this.textHasChanged.emit(this.hasChanges);
  }

  ngOnDestroy(): void {
    this.formGuardConnectorService.disconnect();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  initializeEditor(initEditor: editor.IEditor): void {
    initEditor.layout({
      width: this.editorContainer.nativeElement.clientWidth,
      height: this.editorContainer.nativeElement.clientHeight
    });
  }

  applyEdit(): void {
    this.dialogRef.close(this.hasChanges ? this.editedContent : undefined);
  }

  closeDialog(): void {
    this.formGuardConnectorService.doIfConfirmed(() => this.applyEdit());
  }

  cancelDialog(): void {
    this.dialogRef.close();
  }

  get hasChanges(): boolean {
    return this.editedContent !== this.originalContent;
  }
}

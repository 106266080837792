import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { generalIssueGroupId } from '../issues.constants';
import { IssueModel } from '../../common/model/issue.model';
import { getIssueCssClassBySeverity } from '../../projects/project-issues/project-issues.helper';
import { IssueSeverityEnum } from '../../common/model/issue-severity.enum';

@Component({
  selector: 'adm4-general-issue-list',
  template: `
    <div class='issue-group general-group' [id]='generalGroupId' [class.selected]='isGroupSelected(selectedGroupId, generalGroupId)'>
      <div class='issue-group-heading-outer' [class.selected]='isGroupSelected(selectedGroupId, generalGroupId)'>
        <div class='issue-group-heading'>
          <h2>GENERAL</h2>
        </div>
      </div>
      <div class='issue-group-list'>
        <ng-container *ngFor="let issue of generalIssues">
          <div class='issue-group-list-item-wrapper line-indicatable-validated' [ngClass]='getIssueIndicationCssClass(issue)'>
            <div class='issue-group-list-item'>
              <adm4-validation-issue [issue]="issue"></adm4-validation-issue>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  `,
  styleUrls: ['../issue-group-list/issue-group-list.component.scss', '../issue-group-heading.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralIssueListComponent {

  readonly generalGroupId = generalIssueGroupId;
  @Input() generalIssues: IssueModel[];
  @Input() selectedGroupId?: string;

  isGroupSelected(selectedGroupId: string | undefined, groupId: string): boolean {
    return selectedGroupId === groupId;
  }

  getIssueIndicationCssClass(issue: IssueModel): string {
    return getIssueCssClassBySeverity(IssueSeverityEnum[issue.severity]);
  }
}

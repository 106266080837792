import { PatternType, Property } from '../plugins/pattern-type.model';
import { IssueModel, SourceType } from '../common/model/issue.model';
import { PatternInstance, PatternProperty } from '../patterns/pattern-instance.model';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { getIssuesById } from '../projects/project-issues/project-issues.helper';
import { PropertyDataModel, PropertyModelCategoryGroup } from './property-data.model';
import { replaceArrayItem, requireNonNull } from '../common/utils/utils';
import { multiValueConverter } from './dynamic-property-creation.service';
import { PropertyMetaInfo } from '../version-control/pattern-field-meta-info.model';
import { PropertyType } from '../plugins/property-type.model';
import { DEFAULT_PROPERTY_CATEGORY } from './pattern-editor.constants';

export class PropertyInitHelper {

  static findPatInstanceMetaProperty(propertyKey: string, patternInstanceProps: PropertyMetaInfo[] = []): PropertyMetaInfo | undefined {
    return patternInstanceProps.find(result => result.propertyKey === propertyKey);
  }

  /**
   * It's used for initializing data for properties. It eliminates calling methods from template
   * @returns {PropertyDataModel[]}
   */
  static initPropertyModel(pattern: PatternInstance, patternType: PatternType | undefined, propertyTypes: Dictionary<PropertyType>, issues: IssueModel[]): PropertyDataModel[] {
    const existingPropertyModels: PropertyDataModel[] = this.getExistingPropertyModels(patternType, pattern, propertyTypes, issues);
    const unknownPropertyModels = this.getUnknownPropertyModels(patternType, pattern, issues);
    return _.concat(existingPropertyModels, unknownPropertyModels);
  }

  private static getExistingPropertyModels(patternType: PatternType | undefined, pattern: PatternInstance, propertyTypes: Dictionary<PropertyType>, issues: IssueModel[]): PropertyDataModel[] {
    // In case the pattern type is unknown we cannot build PropertyDataModel[] just return empty list
    if (_.isNil(patternType) || _.isNil(patternType.properties)) {
      return [];
    }

    return patternType.properties
      .filter((property: Property) => !property.hidden) // hidden properties are not shown on the UI, so they are filtered out
      .map((patternTypeProp: Property) => {
        const patternProp = this.findPatternInstanceProperty(patternTypeProp.propertyKey, pattern.properties) || this.initPatternInstProperty(patternTypeProp.propertyKey);
        const propType = propertyTypes[patternTypeProp.className];
        const propertyIssues: IssueModel[] = this.findPropertyIssues(patternTypeProp.propertyKey, issues);
        const normalizedPatterProp = {
          ...patternProp,
          value: multiValueConverter(requireNonNull(propType), patternProp.value),
        };
        return {
          patternProp: normalizedPatterProp,
          patternTypeProp: patternTypeProp,
          issues: propertyIssues,
          propType: propType,
          categoryOrder: patternType.categoryOrder
        };
      });
  }

  private static   getUnknownPropertyModels(patternType: PatternType | undefined, pattern: PatternInstance, issues: IssueModel[]): PropertyDataModel[] {
    let unknownProperties;
    if (!_.isNil(patternType)) {
      unknownProperties = this.getUnknownProperties(pattern.properties, patternType.properties);
    } else {
      // In case the pattern type is unknown we should display all properties as unknown property
      unknownProperties = pattern.properties;
    }
    return unknownProperties
      .map((prop: PatternProperty) => {
        const propertyIssues: IssueModel[] = this.findPropertyIssues(prop.propertyKey, issues);
        return <PropertyDataModel>{
          patternProp: prop,
          propType: undefined,
          issues: propertyIssues,
          patternTypeProp: undefined,
          categoryOrder: []
        };
      });
  }

  static getPatternInstanceWithProperties(pattern: PatternInstance, propertiesModel: PropertyDataModel[]): PatternInstance {
    return Object.assign({}, pattern, {
      properties: _.map(propertiesModel, prop => prop.patternProp)
    });
  }

  /**
   * Returns issue of a property by key
   * [0] is used because getIssuesById return a list of issues with the given id, but a property can only have one issue
   * @param {string} propertyKey
   * @param {IssueModel[]} issues
   * @returns {IssueModel}
   */
  static findPropertyIssues(propertyKey: string, issues: IssueModel[] = []): IssueModel[] {
    return getIssuesById(issues, SourceType.PROPERTY, propertyKey);
  }

  static findPatternInstanceProperty(propertyKey: string, properties: PatternProperty[] = []): PatternProperty | undefined {
    return properties.find((property: PatternProperty) => property.propertyKey === propertyKey);
  }

  /**
   * It returns an initialized pattern property based on pattern type key
   * @param {string} patternTypeKey
   * @returns {PatternProperty}
   */
  static initPatternInstProperty(patternTypeKey: string): PatternProperty {
    return {
      propertyKey: patternTypeKey,
      value: undefined
    };
  }

  /**
   * It returns those properties that are in the pattern instance, but not in pattern type
   * @param {PatternProperty[]} patternInstProps
   * @param {Property[]} patternTypeProps
   * @returns {PatternProperty[]}
   */
  static getUnknownProperties<T extends { propertyKey: string }>(patternInstProps: T[], patternTypeProps?: Property[]): T[] {
    return _.filter(patternInstProps, (patternInstProp: T) => {
      return !_.find(patternTypeProps, (patternTypeProp: Property) => patternInstProp.propertyKey === patternTypeProp.propertyKey);
    });
  }

  static getPropertyModelCategoryGroups(propertiesModel: PropertyDataModel[]): PropertyModelCategoryGroup[]  {
    const groups: PropertyModelCategoryGroup[] = propertiesModel.reduce((propertyModelCategoryGroups: PropertyModelCategoryGroup[], propertyModel: PropertyDataModel) => {
      const propertyCategory: string = _.isNil(propertyModel.patternTypeProp) || _.isNil(propertyModel.patternTypeProp.category) ? DEFAULT_PROPERTY_CATEGORY : propertyModel.patternTypeProp.category;
      const groupOfPropertyCategory: PropertyModelCategoryGroup | undefined = propertyModelCategoryGroups.find(group => group.category === propertyCategory);
      if (_.isNil(groupOfPropertyCategory)) {
        return _.concat(propertyModelCategoryGroups, {category: propertyCategory, propertiesModel: [propertyModel]});
      }
      const updatedGroupOfPropertyCategory = {...groupOfPropertyCategory, propertiesModel: _.concat(groupOfPropertyCategory.propertiesModel, propertyModel)};
      return propertyModelCategoryGroups.map(replaceArrayItem<PropertyModelCategoryGroup>(group => group.category === updatedGroupOfPropertyCategory.category)(updatedGroupOfPropertyCategory));
    }, []);

    if (_.isEmpty(propertiesModel)) {
      return groups;
    }

    return this.sortPropertyModelCategoryGroups(groups, propertiesModel[0].categoryOrder);
  }

  /**
   * It is necessary to keep default category first, rest should be sorted according to categoryOrder array.
   * */
  static sortPropertyModelCategoryGroups(groups: PropertyModelCategoryGroup[], categoryOrder: string[]) {
    return groups.sort((group1: PropertyModelCategoryGroup, group2: PropertyModelCategoryGroup) => {
      if (group1.category === group2.category) {
        return 0;
      } else if (group1.category === DEFAULT_PROPERTY_CATEGORY) {
        return -1;
      } else if (group2.category === DEFAULT_PROPERTY_CATEGORY) {
        return 1;
      } else {
        const group1CategoryIndex = categoryOrder.findIndex((category: string) => category === group1.category);
        const group2CategoryIndex = categoryOrder.findIndex((category: string) => category === group2.category);

        if (group1CategoryIndex === -1 || group2CategoryIndex === -1) {
          return 0;
        }

        if (group1CategoryIndex > group2CategoryIndex) {
          return 1;
        } else if (group2CategoryIndex > group1CategoryIndex) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  }
}

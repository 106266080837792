import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingModalContainerComponent } from './loading-modal-container.component';
import { LoadingModalData } from './loading-modal-data.model';
import { Observable } from 'rxjs';

@Injectable()
export class LoadingService {
  private loadingModal: MatDialogRef<LoadingModalContainerComponent> | null = null;

  constructor(private dialog: MatDialog) {
  }

  /**
   * Opens modal window that shows spinning wheel indicating loading of data, it can only be closed by hideLoading method called on this same service
   * @param loadingData - data that is needed for display of content inside loading modal
   * @return Observable<undefined> - event that will be fired after the loading modal is fully closed
   */
  showLoading(loadingData: LoadingModalData): Observable<undefined> {
    if (this.loadingModal !== null) {
      throw new Error('There is already one loading ongoing, should not open more at once.');
    }

    this.loadingModal = this.dialog.open(LoadingModalContainerComponent, {
      disableClose: true,
      width: '50vw',
      data: loadingData
    });
    return this.loadingModal.afterClosed();
  }

  hideLoading(): void {
    if (this.loadingModal === null) {
      throw new Error('There is no loading to hide.');
    }
    this.loadingModal.close();
    this.loadingModal = null;
  }
}

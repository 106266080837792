import { EMPTY, Observable, of, of as observableOf } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EmptyAction, NevisAdminAction } from '../actions';
import { GetUserError, GetUserSuccess, GetUserSuperAdminSuccess, Login, LoginComplete, LoginError, UserActionTypes } from './user.actions';
import { NavigationService } from '../../navbar/navigation.service';
import { UserData, UserToken } from './user.model';
import { UserService } from '../../user/login/user.service';
import { AssignedPermission } from '../permissions/permissions.model';
import { Store } from '@ngrx/store';
import { AppState } from '../reducer';
import { LoginPayloadModel } from './login-payload.model';
import { PermissionsHelper } from '../../permissions/permissions.helper';


@Injectable()
export class UserEffects {
   authenticate$: Observable<LoginComplete | LoginError>;
   getUser$: Observable<GetUserSuccess | GetUserError>;

  // TODO: switched off to be able to run under BE doesnt have authentication
   getUserError$: Observable<void>;
   logoutEffect$: Observable<EmptyAction>;

   getUserSuperAdmin$: Observable<GetUserSuperAdminSuccess> = createEffect(() => this.actions$
    .pipe(ofType(UserActionTypes.LoginComplete, UserActionTypes.GetUserSuccess))
    .pipe(
      map((action: LoginComplete | GetUserSuccess) => {
        return (action.payload as any).user ? (action.payload as LoginComplete['payload']).user : action.payload as GetUserSuccess['payload'];
      }),
      switchMap((userData: UserData) => {
        return this.userService.getUserPermissionsV2(userData.userKey)
          .pipe(
            map((assignedPermissions: AssignedPermission[]) => {
              const isSuperAdmin: boolean = PermissionsHelper.hasGlobalSuperAdmin(assignedPermissions);
              return new GetUserSuperAdminSuccess(isSuperAdmin);
            }),
          );
      })
    ));

  constructor(private actions$: Actions<NevisAdminAction<any>>,
              private store$: Store<AppState>,
              private userService: UserService,
              private navigationService: NavigationService) {

    this.authenticate$ = createEffect(() => actions$
      .pipe(ofType(UserActionTypes.Login))
      .pipe(
        map((action: Login) => action.payload),
        switchMap(({username, password}: LoginPayloadModel) => {
          return userService.login(username, password)
            .pipe(
              mergeMap((userID: UserToken) => {
                return userService.getUserData()
                  .pipe(map((userData: UserData) => new LoginComplete({user: userData, userId: userID})));
              }),
              catchError(() => observableOf(new LoginError('Username or password is incorrect, please try again')))
            );
        })
      ));


    this.getUser$ = createEffect(() => this.actions$
      .pipe(ofType(UserActionTypes.GetUser))
      .pipe(
        switchMap(() => userService.getUserData()
          .pipe(
            map((res: UserData) => new GetUserSuccess(res)),
            catchError((error) => of(new GetUserError(error)))
          )
        )
      ));

    this.getUserError$ = createEffect(() => actions$
      .pipe(
        ofType(UserActionTypes.GetUserError, UserActionTypes.LoginError),
        switchMap((): Observable<void> => {
          navigationService.navigateToLogin();
          return EMPTY;
        })
      ), {dispatch: false});
  }
}

import { Dictionary } from '../model/reducer';
import { RouteParamHelper } from '../common/helpers/route-param.helper';
import { Inventory } from './inventory.model';
import * as _ from 'lodash';

export class InventoryHelper {
  static getFromStoreOrFirstAvailable(storedKey: string | null, inventories: Dictionary<Inventory>, localStorageKey: string): string | undefined {
    const existingStoredKey: string | undefined = RouteParamHelper.takeKeyFromStore(storedKey, inventories, localStorageKey);
    return _.isNil(existingStoredKey) ? this.getFirstInventoryKey(inventories) : existingStoredKey;
  }

  static getFirstInventoryKey(inventories: Dictionary<Inventory>): string | undefined {
    const firstInventory: Inventory | undefined  = _.values(inventories)[0];
    return _.isNil(firstInventory) ? undefined : firstInventory.inventoryKey;
  }

  static isVersionedInventory(inventory: Inventory): boolean {
    return !_.isEmpty(inventory.repository) && !_.isEmpty(inventory.branch);
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TypedAction } from '@ngrx/store/src/models';
import { BundleManagementActionTypes, LoadBundles, LoadBundlesSuccess } from './bundle-management.actions';
import { EmptyAction } from '../../model/actions';
import { catchError, concatMap, finalize, map, switchMap, tap } from 'rxjs/operators';
import { BundleService } from '../bundle-management/bundle.service';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FileUploadResultHelper } from '../../common/helpers/file-upload-result.helper';
import { LoadingService } from '../../modal-dialog/loading-modal/loading.service';
import { FileUploadResultsDialogService } from '../../common/components/file-upload-results-dialog/file-upload-results-dialog.service';
import { FileUploadResult, FileUploadStatus } from '../../common/model/file-upload-result.model';
import * as _ from 'lodash';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { loadMarketPlaceItems, MarketplaceActionType } from '../marketplace/marketplace.actions';


@Injectable()
export class BundleManagementEffects {
   loadBundles: Observable<LoadBundlesSuccess | EmptyAction> = createEffect(() => this.actions$.pipe(
    ofType(BundleManagementActionTypes.LoadBundles),
    switchMap(() => this.bundleService.getAllBundles().pipe(
      map((bundles) => new LoadBundlesSuccess(bundles)),
      catchError((err) => {
        console.error(err);
        return of(new EmptyAction());
      })
    ))
  ));

   uploadBundles: Observable<LoadBundles | EmptyAction | TypedAction<MarketplaceActionType.LOAD_ITEMS>> = createEffect(() => this.actions$.pipe(
    ofType(BundleManagementActionTypes.UploadBundles),
    map((action: LoadBundles) => action.payload),
    switchMap((files: File[]) => {
      const combineProgressText = (loadedBundles: number) => `${loadedBundles} of ${files.length} libraries have been uploaded`;
      const progressText = new Subject<string>();
      let loadedBundlesAmount = 0;
      this.loadingService.showLoading({title: 'Uploading new libraries', description: '', progressText: progressText.asObservable()});
      return forkJoin(files.map(file => this.bundleService.uploadBundle(file).pipe(
        tap(() => {
          loadedBundlesAmount++;
          progressText.next(combineProgressText(loadedBundlesAmount));
        }),
        map(() => FileUploadResultHelper.createSuccessFileUploadResult(file)),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(FileUploadResultHelper.createFailFileUploadResult(file, err, ''));
        })
      ))).pipe(
        finalize(() => this.loadingService.hideLoading()),
        tap((fileUploadResults: FileUploadResult[]) => {
          const failedResults = fileUploadResults.filter(res => res.status === FileUploadStatus.Fail);
          if (_.isEmpty(failedResults)) {
            this.toastNotificationService.showSuccessToast('All bundles have been successfully uploaded');
          } else {
            this.fileUploadResultsDialogService.openFileUploadResultsDialog(fileUploadResults, {
              headerText: 'Failure during library upload',
              failureGroupText: 'The following libraries could not be uploaded',
              successGroupText: 'Successfully uploaded libraries'
            });
          }
        }),
        concatMap(() => of(new LoadBundles(), loadMarketPlaceItems())),
      );
    }),
  ));

  constructor(private actions$: Actions,
              private bundleService: BundleService,
              private loadingService: LoadingService,
              private fileUploadResultsDialogService: FileUploadResultsDialogService,
              private toastNotificationService: ToastNotificationService) {
  }
}

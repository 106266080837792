import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImportInventoryDialogComponent } from './import-inventory-dialog.component';
import { Inventory } from '../inventory.model';

@Injectable({providedIn: 'root'})
export class ImportInventoryDialogService {

  constructor(private dialog: MatDialog) { }

  openImportInventoryDialog(predefinedInventoryData: Inventory | null = null): void {
    this.dialog.open<ImportInventoryDialogComponent, Inventory | null>(ImportInventoryDialogComponent, {
      width: '30%',
      autoFocus: false,
      disableClose: true,
      data: predefinedInventoryData
    });
  }
}

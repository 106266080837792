import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuElement } from '../../menu-element';

@Component({
  selector: 'adm4-menu-item-wrapper',
  template: `
    <div class='menu-element-inner'>
      <mat-icon *ngIf='menuElement.useSvg; else fontIcon' [svgIcon]='menuElement.icon'
        [ngbTooltip]='menuElement.title' placement="right" 
      ></mat-icon>
      <ng-template #fontIcon>
        <mat-icon [ngbTooltip]='menuElement.title' placement="right">{{menuElement.icon}}</mat-icon>
      </ng-template>
      <span class="menu-text text-ellipsis">{{menuElement.title}}</span>
    </div>`,
  styleUrls: ['../../styles/component-specific/mat-menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemWrapperComponent {
  @Input() public menuElement: MenuElement;
}

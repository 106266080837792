import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InventoryAttachFilePayload, InventoryAttachmentResourceMode, InventoryAttachTenantResourcePayload, ResourceLevelGroup } from './inventory-attachment-payload.model';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { InventoryAttachmentDialogPayload } from './inventory-attachment-dialog-payload.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { resourcesView, secretResourcesView, selectedTenantKeyView } from '../../../model/views';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import { Certificate, CertificateWrapper, InventoryResource, ResourceWrapper, SecretResourceWrapper, TenantResourceFlag } from '../../inventory.model';
import * as _ from 'lodash';
import { InventoryResourceTypeHelper } from '../../../resources/secret-management/inventory-resource-type.helper';
import { InventoryService } from '../../inventory.service';
import { DatePipe } from '@angular/common';
import { ResourceLevelGroupHelper } from './resource-level-group.helper';
import { Maybe } from '../../../common/utils/utils';

@Component({
  selector: 'adm4-insert-inventory-attachments',
  templateUrl: './insert-inventory-attachments-dialog.component.html',
  styleUrls: ['../../../common/styles/component-specific/modal-window.scss', './insert-inventory-attachments-dialog.component.scss', '.././insert-secrets-modal/insert-secrets-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsertInventoryAttachmentsDialogComponent implements OnInit, OnDestroy {
  header: string;
  inventoryKey: string;
  selectedTenantKey$: Observable<string>;

  private destroyed$: Subject<boolean> = new Subject();
  public form: UntypedFormGroup;
  readonly FILE_CONTROL = 'file-form-control';
  readonly FILE_DESCRIPTION_CONTROL = 'file-comment-control';
  readonly SECRET_KEY = 'secretKey';
  public resourceView$: Observable<((ResourceWrapper | SecretResourceWrapper) & TenantResourceFlag)[]>;
  filteredResourceList: ((SecretResourceWrapper | ResourceWrapper) & TenantResourceFlag)[];
  groupedResources: ResourceLevelGroup<ResourceWrapper | SecretResourceWrapper>[];
  mode: InventoryAttachmentResourceMode.resourceFile | InventoryAttachmentResourceMode.secretResourceFile | InventoryAttachmentResourceMode.certificate;

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  constructor(@Inject(MAT_DIALOG_DATA) public data: InventoryAttachmentDialogPayload,
              private dialogRef: MatDialogRef<InsertInventoryAttachmentsDialogComponent>,
              private fb: UntypedFormBuilder, private store$: Store<AppState>,
              private inventoryService: InventoryService) {
    this.header = this.data.header;
    this.mode = this.data.resourceAttachmentMode;
    this.inventoryKey = this.data.inventoryKey;
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;

    switch (this.data.resourceAttachmentMode) {
      case InventoryAttachmentResourceMode.resourceFile: {
        this.resourceView$ = this.store$.select(resourcesView);
        break;
      }
      case InventoryAttachmentResourceMode.secretResourceFile:
        this.resourceView$ = this.store$.select(secretResourcesView);
        break;
      case InventoryAttachmentResourceMode.certificate:
        // Cast certificates to resource item type and provide a description extended with expiryDate into
        this.resourceView$ = this.inventoryService.getInventoryCertificates(this.inventoryKey, true).pipe(
          withLatestFrom(this.selectedTenantKey$),
          map(([certificates, selectedTenantKey]: [CertificateWrapper[], string]) => {
            return _.map(certificates, (cert) => {
              const isTenantScoped = _.isEqual(selectedTenantKey, cert.scope);
              return {
                ...InventoryResourceTypeHelper.convertCertificateToInventoryResource(cert, isTenantScoped),
                description: cert.description ? `${cert.description}\n${this.getEarliestExpiryDate(cert?.certificates)}` : ''
              };
            });
          }));
        break;
    }
    this.resourceView$.pipe(takeUntil(this.destroyed$)).subscribe((resources) => {
      this.groupedResources = ResourceLevelGroupHelper.groupResourcesByLevel(resources);
    });
  }

  ngOnInit() {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
    this.form = this.fb.group({}, {validators: this.attachResourceValidator});
    this.form.addControl(this.FILE_CONTROL, new UntypedFormControl(null, null));
    this.form.addControl(this.FILE_DESCRIPTION_CONTROL, new UntypedFormControl('', null));
    this.form.addControl(this.SECRET_KEY, new UntypedFormControl(null, null));
  }

  attachResourceValidator: ValidatorFn = (fg: UntypedFormGroup) => {
    const isFileUploaded = !_.isNil(fg.get(this.FILE_CONTROL)?.value);
    const isTenantLevelKeySelected = !_.isEmpty(fg.get(this.SECRET_KEY)?.value);
    return (isFileUploaded || isTenantLevelKeySelected) && !(isFileUploaded && isTenantLevelKeySelected) ? null : {textAndKeyFilled: true};
  };

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  triggerAttachmentInsert(): void {
    const file = this.getFileToUpload();
    const description = this.form.controls[this.FILE_DESCRIPTION_CONTROL].value;
    const tenantLevelSecretKey = this.getSelectedGlobalSecret();

    if (!_.isNil(tenantLevelSecretKey)) {
      const inventoryAttachTenantResourcePayload = new InventoryAttachTenantResourcePayload();
      inventoryAttachTenantResourcePayload.resourceKey = this.getTypeSpecificId(tenantLevelSecretKey);
      return this.dialogRef.close(inventoryAttachTenantResourcePayload);
    }

    if (!_.isNil(file)) {
      const inventoryAttachFilePayload = new InventoryAttachFilePayload();
      inventoryAttachFilePayload.file = file;
      inventoryAttachFilePayload.description = description;
      return this.dialogRef.close(inventoryAttachFilePayload);
    }

    this.dialogRef.close();
  }

  private getSelectedGlobalSecret() {
    return this.form.controls[this.SECRET_KEY].value;
  }

  private getFileToUpload() {
    return this.form.controls[this.FILE_CONTROL].value;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getResourceLabel(resource: SecretResourceWrapper | ResourceWrapper & TenantResourceFlag): string {
    return String(this.getTypeSpecificId(resource) + (_.isEmpty(resource.description) ? '' : ` (${resource.description})`));
  }

  getTypeSpecificId(resourceItem: SecretResourceWrapper | ResourceWrapper & TenantResourceFlag): string {
    return InventoryResourceTypeHelper.getTypeSpecificIdWithName(resourceItem);
  }

  get shouldTenantSelectionBeDisabled(){
    return !_.isNil(this.getFileToUpload());
  }

  get shouldFileUploadBeDisabled(){
    return !_.isNil(this.getSelectedGlobalSecret());
  }

  getEarliestExpiryDate(certificates: Certificate[]): string {
    const earliestExpiryDate = _.reduce(certificates, (result: Maybe<Date>, certificate) => {
      if(_.isNil(result)) {
        return new Date(certificate.expires);
      }
      return result < new Date(certificate.expires) ? result : new Date(certificate.expires);
    }, null);
    return `Expires in ${new DatePipe('en-US').transform(earliestExpiryDate, 'MMM d, y')}` || '';
  }

  searchableResourceFormatFn = (resource: SecretResourceWrapper | ResourceWrapper & TenantResourceFlag): string => {
    return this.getResourceLabel(resource);
  };

  updateSearchResult(filteredList: ((SecretResourceWrapper | ResourceWrapper) & TenantResourceFlag)[]): void {
    this.filteredResourceList = filteredList;
  }

  focusDropdownInput(): void {
   this._searchableDropdownInputFocusTrigger$.next();
  }

  shouldShowResourceGroup(resourceGroup: ResourceLevelGroup<ResourceWrapper | SecretResourceWrapper | CertificateWrapper>): boolean {
    return ResourceLevelGroupHelper.hasDisplayableResourcesByLevel(resourceGroup) && _.some(resourceGroup.resources, resource => this.isResourceFilteredOut(resource));
  }

  isResourceFilteredOut(resource: InventoryResource & TenantResourceFlag): boolean {
    return _.some(this.filteredResourceList, filteredResource => _.isEqual(filteredResource, resource));
  }
}

import { distinctUntilChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { IssueModel } from '../common/model/issue.model';
import { PatternListData } from './pattern-list-data.model';
import { PluginModel } from '../plugins/plugin.model';
import { select, Store } from '@ngrx/store';
import { AppState, ProjectKey } from '../model/reducer';
import {
  copyTargetsView,
  issuesView,
  patternListItemsView,
  patternMetaInfoView,
  patternTypesView,
  patternUsageView,
  projectMetaView,
  propertyTypesView,
  selectedPatternDocView,
  selectedPatternInstanceView,
  selectedPatternIssuesView,
  sortedProjectReportsView
} from '../model/views';
import { PatternInstance } from './pattern-instance.model';
import { PatternType } from '../plugins/pattern-type.model';
import { PropertyType } from '../plugins/property-type.model';
import { VariableModel } from '../variables/variable.model';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { PatternId, SelectPatternId } from '../model/pattern';
import { PatternEditorComponent } from '../property-widgets/pattern-editor.component';
import { Project, ProjectMeta, ProjectMetaChangeIndicator } from '../projects/project.model';
import { UsageInfo } from '../common/components/usage/usage-info';
import { PatternVersionInfo } from '../version-control/pattern-meta-info.model';
import { IssueSeverityEnum } from '../common/model/issue-severity.enum';
import { getHighestSeverity } from '../projects/project-issues/project-issues.helper';
import { requireNonNull } from '../common/utils/utils';
import { NavigationService } from '../navbar/navigation.service';
import { patternKeyParam } from '../projects/project-routing.constants';
import { ProjectContext } from '../projects/project.context';
import { HelpConnectService } from '../shared/scroll-helper/help-connect.service';
import { IssueReducingHelper } from '../projects/project-issues/issue-reducing.helper';
import { SplitPaneConfig } from '../common/model/split-pane-config.model';
import { ResizeHelper } from '../common/helpers/resize.helper';
import { ISplitMixin, SplitMixin } from '../common/mixins/split.mixin';
import { Mixin } from '../common/decorators/mixin.decorator';
import { IOutputData } from 'angular-split/lib/interface';
import { getProjectsWithModifyPermission } from '../model/views/permission.views';
import { PatternGroupingOption, patternGroupingOptions, PatternGroupingOptionTitles } from './pattern-grouping-options.enum';
import { PatternMainConnectService } from './pattern-main-connect.service';
import { DisplayComponentHelperService } from '../common/services/display-component-helper.service';
import { PatternCopyTarget, PatternRestrictions } from './pattern.model';
import { NavigationConstants } from '../common/constants/navigation.constants';
import { CustomSanitizerService } from '../common/services/custom-sanitizer.service';

@Component({
  selector: 'adm4-pattern-main',
  templateUrl: './pattern-main.component.html',
  styleUrls: ['pattern-main.scss'],
  providers: [HelpConnectService, PatternMainConnectService],
})
@Mixin([SplitMixin])
export class PatternMainComponent implements ISplitMixin, OnInit, OnDestroy {
  public projectKey$: Observable<string>;
  public patternId$: Observable<string>;
  public selectedPropertyKey$: Observable<string | null>;
  public patternList$: Observable<PatternListData[]>;
  public helpData$: Observable<SafeHtml>;
  public usageData$: Observable<UsageInfo[]>;
  public copyTargets: Observable<PatternCopyTarget[]>;
  public variables$: Observable<VariableModel[]>;
  public pluginsByPatternType$: Observable<PluginModel[]>;
  public vcInfoOfProject$: Observable<ProjectMeta | null>;
  public hasUnsavedProperty: boolean;
  public readOnly$: Observable<boolean>;

  public pattern$: Observable<PatternInstance | null>;
  public patternMetaInfo$: Observable<PatternVersionInfo | null>;
  public patternTypes$: Observable<Dictionary<PatternType> | null>;
  public propertyTypes$: Observable<Dictionary<PropertyType> | null>;
  public patternIssues$: Observable<IssueModel[] | undefined>;
  public allIssues$: Observable<IssueModel[] | undefined>;
  public reports$: Observable<PluginModel[] | undefined>;
  public hasProjectModificationPermission$: Observable<boolean>;
  public projectMetaChangeIndicator$: Observable<ProjectMetaChangeIndicator>;

  projects$: Observable<Dictionary<Project>>;
  highestSeverity$: Observable<IssueSeverityEnum>;
  versioned$: Observable<boolean>;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('propertyListComp', {static: false}) public propertyListComp: PatternEditorComponent;

  readonly listSplitAreaKey = 'list';
  readonly editorSplitAreaKey = 'editor';
  readonly helpSplitAreaKey = 'help';
  readonly splitPaneConfigLocalStorageKey = 'pattern-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.listSplitAreaKey]: {order: 0, size: 30},
    [this.editorSplitAreaKey]: {order: 1, size: 40},
    [this.helpSplitAreaKey]: {order: 2, size: 30, isCollapsed: false}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  /**
   * Implemented by SplitMixin
   */
  onCollapse: (isCollapsed: boolean, collapsibleAreaKey: string) => void;

  constructor(private route: ActivatedRoute,
              private store$: Store<AppState>,
              private navigationService: NavigationService,
              private projectContext: ProjectContext,
              private patternMainConnectService: PatternMainConnectService,
              private displayComponentHelperService: DisplayComponentHelperService,
              private sanitizer: CustomSanitizerService,
  ) {
    this.projectKey$ = this.projectContext.projectKey$;
    this.pluginsByPatternType$ = this.projectContext.pluginsByPatternType$;
    this.variables$ = this.projectContext.variables$;
    this.projects$ = this.projectContext.projects$;
    this.versioned$ = this.projectContext.versioned$;
    this.readOnly$ = combineLatest([projectContext.isProjectReadOnly$, this.store$.select(selectedPatternInstanceView)])
      .pipe(map(([isProjectReadonly, pattern]) => isProjectReadonly || this.isWholePatternReadOnly(pattern?.displayOptions?.readOnly)));
    // retrieve data from the url
    this.patternId$ = this.route.params.pipe(map(params => params[patternKeyParam]), distinctUntilChanged());
    this.selectedPropertyKey$ = this.route.fragment.pipe(distinctUntilChanged());
    // Resolve data for the individual components
    this.patternList$ = this.store$.pipe(select(patternListItemsView));
    this.helpData$ = this.store$.pipe(
        select(selectedPatternDocView),
        map((dirtyDoc: string | null) => sanitizer.sanitizeAndTrust(dirtyDoc)),
    );
    this.usageData$ = this.store$.pipe(select(patternUsageView));
    this.copyTargets = this.store$.pipe(select(copyTargetsView));
    this.pattern$ = this.store$.pipe(select(selectedPatternInstanceView)).pipe(distinctUntilChanged(_.isEqual));
    this.patternMetaInfo$ = this.store$.pipe(select(patternMetaInfoView));
    this.patternTypes$ = this.store$.pipe(select(patternTypesView));
    this.propertyTypes$ = this.store$.pipe(select(propertyTypesView));
    this.patternIssues$ = this.store$.pipe(
      select(selectedPatternIssuesView),
      distinctUntilChanged(_.isEqual),
      filter(issues => !_.isNil(issues)),
      withLatestFrom(this.variables$),
      map(([issues, variables]: [IssueModel[], VariableModel[]]) => IssueReducingHelper.fakeVariableIssues(issues, variables))
    );
    this.vcInfoOfProject$ = this.store$.pipe(select(projectMetaView));
    this.allIssues$ = this.store$.pipe(select(issuesView));
    this.highestSeverity$ = this.allIssues$.pipe(map((issues) => getHighestSeverity(requireNonNull(issues))));
    this.reports$ = this.store$.pipe(select(sortedProjectReportsView));
    this.hasProjectModificationPermission$ = this.store$.pipe(select(getProjectsWithModifyPermission), withLatestFrom(this.projectKey$),
      map(([projectsWithPermission, projectKey]: [Project[], string]) => !_.isEmpty(projectsWithPermission.filter(project => project.projectKey !== projectKey))));
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
    this.projectMetaChangeIndicator$ = this.projectContext.projectMetaChangeIndicator$;
  }

  private isWholePatternReadOnly(restrictions?: PatternRestrictions) {
    return this.displayComponentHelperService.shouldBeRestrictedByProperty(restrictions, '*');
  }

  ngOnInit(): void {
    combineLatest([this.patternId$, this.projectKey$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([patternId, ]: [PatternId, ProjectKey]) => {
        this.store$.dispatch(new SelectPatternId(patternId || null));
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  selectProperty(propertyKey: string): void {
    this.navigationService.scrollToProperty(propertyKey);
  }

  onPropertyHasChanged(hasChanged: boolean) {
    this.hasUnsavedProperty = hasChanged;
  }

  switchToLabelView(): void {
    const labelViewOption = patternGroupingOptions.find((option: PatternGroupingOption) => option.title === PatternGroupingOptionTitles.Label);
    this.patternMainConnectService.setGroupingOption(requireNonNull(labelViewOption));
  }

  get isSummaryRouteActive(): boolean {
    return _.includes(this.route.snapshot.url.map(urlSegment => urlSegment.path), NavigationConstants.OVERVIEW);
  }

  get summaryAreaSize(): number {
    return this.splitPaneConfig[this.helpSplitAreaKey].isCollapsed ? this.splitPaneConfig[this.editorSplitAreaKey].size : this.splitPaneConfig[this.helpSplitAreaKey].size + this.splitPaneConfig[this.editorSplitAreaKey].size;
  }
}

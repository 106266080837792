import { CheckModel, CommandModel, DirectoryModel, FileModel, GenerationTriggerModel, OutputType } from '../planning-output.model';
import { Pattern } from '../../../../patterns/pattern.model';
import { Component, Input } from '@angular/core';
import { TreeNodeWithDetails } from '../../../tree-viewer/tree-node.model';
import { TreeNodeTypeEnum } from '../../../tree-viewer/tree-node-type.enum';

@Component({template: ''})
export class ItemDetailsComponent<T extends CheckModel | GenerationTriggerModel | CommandModel | DirectoryModel | FileModel> {
  @Input() node: TreeNodeWithDetails<T>;
  @Input() projectKey: string | undefined;
  @Input() pattern: Pattern | undefined;
  @Input() outputType: OutputType;
  @Input() boxShadowClass: string;

  get titleIcon() {
    switch (this.node.type) {
      case TreeNodeTypeEnum.Host : {
        return 'storage';
      }
      case TreeNodeTypeEnum.Pattern: {
        return 'content_paste';
      }
      case TreeNodeTypeEnum.DeployablePattern : {
        return 'content_paste';
      }
      case TreeNodeTypeEnum.CheckParent: {
        return 'playlist_add_check';
      }
      case TreeNodeTypeEnum.CommandParent: {
        return 'input';
      }
      case TreeNodeTypeEnum.Directory: {
        return 'folder';
      }
      case TreeNodeTypeEnum.File: {
        return 'insert_drive_file';
      }
      case TreeNodeTypeEnum.KubernetesCustomResource: {
        return 'book';
      }
      default:
        return '';
    }
  }
}

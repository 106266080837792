import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';

import * as _ from 'lodash';

import { MenuElement } from '../../../common/menu-element';
import { NavigationService } from '../../../navbar/navigation.service';
import { Inventory } from '../../inventory.model';
import { InventoryColorHelper } from '../../../common/helpers/inventory-color.helper';
import { NavigationConstants } from '../../../common/constants/navigation.constants';
import { InventorySchemaTypeHelper } from '../../../deployment-wizard/deployment-selection/inventory-list/inventory-schema-type.helper';
import { TenantHelper } from '../../../common/helpers/tenant.helper';
import { DirtyFormGuardConnectorService } from '../../../common/services/dirty-form-guard-connector.service';

@Component({
  selector: 'adm4-inventory-dropdown',
  template: `
    <mat-form-field class='selection-dropdown-menu hide-underline cursor-pointer'>
      <mat-select #inventorySelection [hideSingleSelectionIndicator]='true'
                  [(ngModel)]='currentInventoryKey'
                  panelClass='project-inventory-dropdown'
                  [disableOptionCentering]='true'
                  (click)='focusDropdownInput()'>
        <mat-select-trigger class='dropdown-selection-text'>
          {{currentInventoryKey | cropTenantFromKey}}
        </mat-select-trigger>
        <adm4-searchable-dropdown-input *ngIf='inventorySelection.focused'
                                        [sourceItems]='inventories'
                                        [placeholder]="'Please select an inventory...'"
                                        [noResultsMessage]='"No inventory found"'
                                        [searchableFormatFn]='searchableInventoryFormatFn'
                                        [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                        (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
        <mat-option *ngFor='let inventory of inventories'
                    [value]='inventory.inventoryKey'
                    [hidden]='!isInventoryFilteredOut(inventory)'
                    [class.selected]='isInventorySelected(inventory)'
                    class='dropdown-option'>
          <a mat-menu-item
             [ngClass]='getInventoryMenuItemClassName(inventory.color)'
             [class.selected]='isInventorySelected(inventory)'
             [ngbTooltip]='inventory.inventoryKey | cropTenantFromKey' placement='right'
             (click)="startInventoryNavigation(inventory.inventoryKey)"
             class='menu-element'>
            <div class="menu-element-inner">
              <mat-icon>{{getInventoryIconName(inventory)}}</mat-icon>
              <span class="menu-text">{{inventory.inventoryKey | cropTenantFromKey}}</span>
            </div>
          </a>
        </mat-option>

        <mat-divider></mat-divider>
        <adm4-menu-element *ngFor='let navigationEntry of navigationEntries' [menuElement]='navigationEntry'></adm4-menu-element>
      </mat-select>
    </mat-form-field>
  `,
  styleUrls: [
    '../../../common/styles/component-specific/mat-menu.scss',
    '../../../common/styles/component-specific/inventory-project-dropdown.scss',
    './inventory-dropdown.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryDropdownComponent {
  @Input() currentInventoryKey;
  @Input() inventories: Inventory[];

  navigationEntries: MenuElement[];
  filteredInventoryList: Inventory[];

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  readonly getInventoryMenuItemClassName = (color: string) => InventoryColorHelper.getInventoryMenuItemClassName(color);

  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dirtyGuard: DirtyFormGuardConnectorService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.navigationEntries = [
      {
        title: 'Inventory Settings',
        icon: 'inventory_settings',
        useSvg: true,
        event: () => this.navigationService.navigateToInventorySettings(this.currentInventoryKey || undefined)
      }
    ];
  }

  startInventoryNavigation(newInventoryKey: string) {
    const existingInventoryKey: string = this.currentInventoryKey;
    this.dirtyGuard.doIfConfirmed(
      () => this.finishInventoryNavigation(newInventoryKey),
      () => this.rejectInventoryNavigation(existingInventoryKey),
    );
  }

  private finishInventoryNavigation(inventoryKey: string) {
    const targetRoute: string[] = _.isEmpty(this.activatedRoute.snapshot.url)
      ? [NavigationConstants.INFRASTRUCTURE_INVENTORIES, inventoryKey]
      : [NavigationConstants.INFRASTRUCTURE_INVENTORIES, inventoryKey, this.activatedRoute.snapshot.url[0].path];
    this.router.navigate(targetRoute);
  }

  private rejectInventoryNavigation(originalInventoryKey: string): void {
    this.currentInventoryKey = originalInventoryKey;
    // this cmp is `onPush`, and the callback is called from the dialog, so we need to trigger the cdRef
    this.cdRef.markForCheck();
  }

  isInventorySelected(inventory: Inventory): boolean {
    return inventory.inventoryKey === this.currentInventoryKey;
  }

  getInventoryIconName(inventory: Inventory): string {
    return InventorySchemaTypeHelper.getInventoryIconName(inventory.schemaType);
  }

  updateSearchResult(filteredList: Inventory[]): void {
    this.filteredInventoryList = filteredList;
  }

  searchableInventoryFormatFn = (inventory: Inventory): string => {
    return TenantHelper.cropTenantFromKey(inventory.inventoryKey);
  };

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }

  isInventoryFilteredOut(inventory: Inventory): boolean {
    return _.includes(this.filteredInventoryList, inventory);
  }
}

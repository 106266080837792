import { Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import * as _ from 'lodash';
import { editor } from 'monaco-editor';
import { ProjectService } from '../../../projects/project.service';
import { select, Store } from '@ngrx/store';
import { projectKeyView, selectedPatternInstanceView } from '../../../model/views';
import { AppState } from '../../../model/reducer';
import { PropertyTypeClassEnum } from '../../../plugins/plugin.enums';
import { requireNonNull } from '../../utils/utils';
import { catchError, filter, map } from 'rxjs/operators';
import { HTTP_STATUS_CONFLICT } from '../../../shared/http-status-codes.constants';
import { ErrorHelper } from '../../helpers/error.helper';
import { HttpErrorResponse } from '@angular/common/http';
import { PatternInstance } from '../../../patterns/pattern-instance.model';
import { WhitelistConverterDialogPayload } from './whitelist-converter-dialog.payload';
import { RecommenderInput, RecommenderOutput } from './recommender.model';
import { ModalNotificationService } from '../../../notification/modal-notification.service';
import { ValidationIssue } from '../../model/validation-status.model';

@Component({
  selector: 'adm4-whitelist-converter-dialog',
  templateUrl: './whitelist-converter-dialog.component.html',
  styleUrls: ['./whitelist-converter-dialog.component.scss', '../../styles/component-specific/modal-window.scss']
})
export class WhitelistConverterDialogComponent implements OnInit, OnDestroy {
  private projectKey: string | null;
  private propKey: string;
  private patternId: string | undefined;
  @ViewChild('editorContainer', {static: false}) editorContainer: ElementRef;
  @ViewChild('outputRuleContainer', {static: false}) outputRuleContainer: ElementRef;

  header = 'Create New Rules';
  editedContent: string;
  convertedResult: string;

  readonly DEFAULT_CONVERT_ERROR_MESSAGE = 'It was not possible to convert the log message to whitelist rule.';
  private _warnings$: BehaviorSubject<ValidationIssue[] | null> = new BehaviorSubject(null);
  inputWarnings$: Observable<ValidationIssue[] | null> = this._warnings$.asObservable().pipe(
    filter((warningList: ValidationIssue[] | null) => !_.isNil(warningList)),
    map((validationIssues: ValidationIssue[]) => validationIssues.filter((issue: ValidationIssue) => !_.isEmpty(issue.sources[0]))));
  hasInputWarning$: Observable<boolean> = this.inputWarnings$.pipe(map((issue: ValidationIssue[] | null)  => !_.isEmpty(issue)));
  outputWarnings$: Observable<ValidationIssue[] | null> = this._warnings$.asObservable().pipe(
    filter((warningList: ValidationIssue[] | null) => !_.isNil(warningList)),
    map((validationIssues: ValidationIssue[]) => validationIssues.filter((issue: ValidationIssue) => _.isEmpty(issue.sources[0]))));
  hasOutputWarning$: Observable<boolean> = this.outputWarnings$.pipe(map((issue: ValidationIssue[] | null)  => !_.isEmpty(issue)));

  @Output() ruleHasChanged = new EventEmitter<boolean>();
  private destroyed$: Subject<boolean> = new Subject();

  editorOptions: editor.IEditorOptions = {
    wordBasedSuggestions: false,
    automaticLayout: true,
    wordWrap: 'on',
    minimap : {
      enabled: false
    }
  // `wordBasedSuggestions` is _not_ typed, but works, see https://github.com/microsoft/monaco-editor/issues/1746
  } as editor.IEditorOptions;

  outputOptions: editor.IEditorOptions = {
    readOnly: true,
    automaticLayout: true,
    wordWrap: 'on',
    minimap : {
      enabled: false
    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) private content: WhitelistConverterDialogPayload,
              private dialogRef: MatDialogRef<WhitelistConverterDialogComponent>,
              private store$: Store<AppState>,
              private projectService: ProjectService,
              private modalNotificationService: ModalNotificationService) {
    this.propKey = content.propertyKey;
    this.store$.pipe(select(projectKeyView)).subscribe((selectedProjectKey: string | null) => {
      this.projectKey = selectedProjectKey;
    });
    this.store$.pipe(select(selectedPatternInstanceView)).subscribe((selectedPattern: PatternInstance | null) => {
      if (!_.isNil(selectedPattern)) {
        this.patternId = _.isNil(selectedPattern.patternId) ? undefined : selectedPattern.patternId;
      }
    });
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$, () => {
      this.closeDialog();
    });
  }

  setInitialFocus(focusedEditor: editor.IEditor): void {
    focusedEditor.focus();
  }

  inputModelChanged(): void {
    this.ruleHasChanged.emit(this.canConfirmInsert);
  }

  outputModelChanged(): void {
    this.ruleHasChanged.emit(this.canConfirmInsert);
  }

  applyEdit(): void {
    const resultWithoutEmptyLines = this.convertedResult.replace(/^\s*\n/gm, '');
    this.dialogRef.close(resultWithoutEmptyLines);
  }

  get isConvertDisabled(): boolean {
    return _.isEmpty(this.editedContent);
  }

  get canConfirmInsert(): boolean {
    return !_.isEmpty(this.convertedResult);
  }

  convertToRule(): void {
  const recommenderInput: RecommenderInput = {
      className: PropertyTypeClassEnum.ModSecurityRuleProperty,
      input: this.editedContent
    };
    this.projectService.getRecommender(requireNonNull(this.projectKey), requireNonNull(this.patternId), this.propKey, recommenderInput)
      .pipe(
        map((convertedRule: RecommenderOutput) => {
          if (!_.isNil(convertedRule._status) && !_.isNil(convertedRule._status._warnings) && !_.isEmpty(convertedRule._status._warnings)) {
            this._warnings$.next(convertedRule._status._warnings.reverse());
          } else {
            this._warnings$.next(null);
          }
          this.convertedResult = convertedRule.output;
          this.outputOptions = {...this.outputOptions, readOnly: false};
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === HTTP_STATUS_CONFLICT) {
            const errorMessage = ErrorHelper.getErrorDetail(error, this.DEFAULT_CONVERT_ERROR_MESSAGE);
            this.modalNotificationService.openErrorDialog({title: 'Error', description: errorMessage});
          }
          return throwError(error);
        })
      ).subscribe();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

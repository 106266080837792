import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'adm4-validation-message',
  template: `
    <div class='validation-message'>
			<adm4-validation-indicator [isDisplayed]='true'
                                 [isError]='isError'
                                 [isWarning]='isWarning'
                                 [isInfo]='isInfo'
                                 [diameter]='diameter || validationIndicatorDiameter'></adm4-validation-indicator>
			<span [ngClass]="validationClass">{{message}}</span>
    </div>
  `,
  styleUrls: ['./validation-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationMessageComponent {
  @Input() isError: boolean;
  @Input() isWarning: boolean;
  @Input() isInfo: boolean;
  @Input() message: string;
  @Input() diameter: number;

  readonly validationIndicatorDiameter = 8;

  /**
   * Makes sure that only one class can be attached at once
   * @returns {string}
   */
  get validationClass(): string {
    if (this.isInfo) {
      return 'info';
    } else if (this.isError) {
      return 'error';
    } else if (this.isWarning) {
      return 'warning';
    } else {
      return '';
    }
  }
}

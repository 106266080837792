import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { AppState } from '../model/reducer';
import { ProjectService } from '../projects/project.service';
import { NavigationService } from '../navbar/navigation.service';
import { IProjectGuardMixin, ProjectGuardMixin } from '../common/mixins/project-guard.mixin';
import { ModalNotificationService } from '../notification/modal-notification.service';
import { Mixin } from '../common/decorators/mixin.decorator';
import { NevisAdminAction } from '../model/actions';

@Injectable({
  providedIn: 'root'
})
@Mixin([ProjectGuardMixin])
export class ProjectSettingsGuard  implements IProjectGuardMixin {

  // implemented in the mixin
  canActivateScreen: (next: ActivatedRouteSnapshot) => Observable<boolean>;

  constructor(public store$: Store<AppState>,
              public projectService: ProjectService,
              public navigationService: NavigationService,
              public modalNotificationService: ModalNotificationService,
              public actions$: Actions<NevisAdminAction<any>>,
  ) {}

  navigateToScreen(projectKey: string): Promise<boolean> {
    return this.navigationService.navigateToProjectSettings(projectKey);
  }

  canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateScreen(next);
  }
}

import { TextDiffData } from '../../../common/model/publish-changes/text-diff-data.model';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { Revision } from '../../../version-control/revision/revision.model';
import { MetaInfo } from '../../../version-control/meta-info.model';

export class InventoryContentDiffViewHelper {
  static createInventoryContentDiff(remoteContent: string, localContent: string, inventoryContentMeta: MetaInfo, revision?: Revision): Diff<TextDiffData> {
    return {
      remote: {
        content: {
          text: remoteContent
        },
        commitInfo: revision
      },
      local: {
        content: {
          text: localContent
        },
        changeInfo: inventoryContentMeta,
        fallbackLabel: ''
      }
    };
  }
}

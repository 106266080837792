import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { PatternCopyTarget } from '../../../patterns/pattern.model';

@Component({
  selector: 'adm4-copy-targets',
  template: `
<div class='title-container d-flex justify-content-between align-items-center cursor-pointer' (click)="toggleCollapse()">
  <h1 class='main-title'>Copies</h1>
  <mat-icon class="size-16">{{isCollapsed ? 'expand_more' : 'expand_less'}}</mat-icon>
</div>
<div class="copy-targets-expanded" *ngIf="!isCollapsed; else collapsed">
  <ng-container *ngIf="isPresent; else notPresent">
    <mat-table class="adm4-mat-table" [dataSource]="dataSource" matSort>
      <ng-container [matColumnDef]="COLUMNS.projectKey">
        <mat-header-cell class="col-project-key" *matHeaderCellDef mat-sort-header>
          <span>Copied to</span>
        </mat-header-cell>
        <mat-cell class="col-project-key" *matCellDef="let copyTarget;">
          <a [ngbTooltip]="projectTooltip" [routerLink]="['/', 'projects', copyTarget.projectKey, 'patterns', copyTarget.patternId]">{{copyTarget.projectKey | cropTenantFromKey}}</a>
          <ng-template #projectTooltip>
            <span>Click to navigate to the copied pattern in the project <strong>{{copyTarget.projectKey | cropTenantFromKey}}</strong></span>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container [matColumnDef]="COLUMNS.copyTime">
        <mat-header-cell class="col-copy-time" *matHeaderCellDef mat-sort-header>
          <span>Copy date</span>
        </mat-header-cell>
        <mat-cell class="col-copy-time" *matCellDef="let copyTarget;">
          <div class="flex-center-xy" [ngbTooltip]="copyTimeTooltip" placement="top-right">
            <span>{{copyTarget.copyTime | customDate}}</span>
          </div>
          <ng-template #copyTimeTooltip>
            <span class="cursor-default">Copied by <strong>{{copyTarget.copyAuthor}}</strong>, {{copyTarget.copyTime | date:'MMMM d, y'}} at {{copyTarget.copyTime | date: 'HH:mm'}}</span>
          </ng-template>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnList; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnList;"></mat-row>
    </mat-table>
  </ng-container>
  <ng-template #notPresent>
    <h2>This pattern was not copied to other projects.</h2>
    <div class="separator"></div>
  </ng-template>
</div>
<ng-template #collapsed>
    <div class="separator"></div>
</ng-template>
  `,
  styleUrls: ['./copy-targets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyTargetsComponent implements OnChanges {

  public readonly COLUMNS = {
    projectKey: 'projectKey',
    copyTime: 'copyTime',
  } as const;
  public readonly columnList: ReadonlyArray<string> = Object.values(this.COLUMNS);

  @Input() copyTargets: PatternCopyTarget[] = [];

  public isCollapsed: boolean = true;
  public isPresent: boolean = true;

  public readonly dataSource: MatTableDataSource<PatternCopyTarget> = new MatTableDataSource();

  constructor() {
    this.dataSource.sortingDataAccessor = (copyTarget: PatternCopyTarget, sortHeaderId: string) => {
      if (sortHeaderId === this.COLUMNS.copyTime) {
        return new Date(copyTarget.copyTime).getTime();
      }
      return copyTarget[sortHeaderId];
    };
  }

  @ViewChild(MatSort, {static: false}) set tableSort(newSort: MatSort) {
    // the table is not always visible, eg. initially, and this applies the sort when it becomes available
    this.dataSource.sort = newSort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.copyTargets) {
      this.isPresent = Array.isArray(this.copyTargets) && this.copyTargets.length > 0;
      this.dataSource.data = this.copyTargets??[];
    }
  }

  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImportProjectComponent } from './import-project.component';
import { Project } from '../project.model';

@Injectable({providedIn: 'root'})
export class ImportProjectDialogService {

  constructor(private dialog: MatDialog) { }

  openImportProjectDialog(predefinedProjectData: Project | null = null): void {
    this.dialog.open<ImportProjectComponent, Project | null>(ImportProjectComponent, {
      width: '30%',
      autoFocus: false,
      data: predefinedProjectData
    });
  }
}

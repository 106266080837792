import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Inventory } from '../inventory.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ConnectInventoryVersionControlHelper } from './connect-inventory-version-control.helper';
import { VersionControlData } from '../../common/model/version-control-data.model';
import { TenantHelper } from '../../common/helpers/tenant.helper';

@Component({
  selector: 'adm4-connect-inventory-version-control',
  template: `
    <form [formGroup]="form" name="form" #addInventoryForm (ngSubmit)='submitConnectInventoryToVersionControl()' cdkFocusInitial>
      <div class="content-container create-item-container">
        <adm4-version-control-form [automaticPath]='inventoryName' [pathRequired]='true' (formReady)='addVersionControlForm($event)'></adm4-version-control-form>
      </div>
      <div mat-dialog-actions>
        <adm4-button-bar [isSubmitDisabled]="!canSave"
                         [submitButtonText]="'Connect'"
                         (cancelClicked)='cancel.emit()'></adm4-button-bar>
      </div>
    </form>
  `,
  styleUrls: [
    '../../common/styles/component-specific/create-form.scss',
    '../../common/styles/component-specific/modal-window.scss'
  ]
})
export class ConnectInventoryVersionControlComponent {
  @Input() inventory: Inventory;
  @Output() inventoryConnect: EventEmitter<VersionControlData> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();

  form: UntypedFormGroup;

  // TODO add proper constants
  readonly VERSION_CONTROL_FORM_CONTROL_NAME = 'versionControl';

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({});
  }

  addVersionControlForm(versionControlForm: UntypedFormGroup): void {
    this.form.setControl(this.VERSION_CONTROL_FORM_CONTROL_NAME, versionControlForm);
  }

  submitConnectInventoryToVersionControl(): void {
    const inventoryVersionControlData = ConnectInventoryVersionControlHelper.getInventoryVersionControlData(this.form.value[this.VERSION_CONTROL_FORM_CONTROL_NAME]);
    this.inventoryConnect.emit(inventoryVersionControlData);
  }

  get canSave(): boolean {
    const versionControlForm = this.form.controls[this.VERSION_CONTROL_FORM_CONTROL_NAME] as UntypedFormGroup;
    const versionControlValid: boolean = _.values(versionControlForm.controls).every(control => control.valid);
    return !_.isNil(versionControlForm) && versionControlValid;
  }

  get inventoryName(): string {
    return TenantHelper.cropTenantFromKey(this.inventory.inventoryKey);
  }
}

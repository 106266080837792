import { ModalNotificationService } from '../notification/modal-notification.service';
import { EmptyAction, NevisAdminAction } from './actions';
import { NotificationMessage } from '../notification/notification.model';

export interface IEffectWithErrorHandlingMixin {
  modalNotificationService: ModalNotificationService;
  /**
   * Handles error in EffectWithErrorHandlingMixin which displays error to user in a modal window and outputs actual error to the console
   * @param error - actual HTTP response error to be logged
   * @param displayMessage - notification message object which contains data for user friendly error display
   * @param returnedAction - action that will be returned after handling error (default is new EmptyAction())
   */
  handleErrorAction: <T extends NevisAdminAction<any> = EmptyAction>(error: any, displayMessage: NotificationMessage, returnedAction?: T) => T;
}

export class EffectWithErrorHandlingMixin implements IEffectWithErrorHandlingMixin {
  modalNotificationService: ModalNotificationService;

  handleErrorAction<T extends NevisAdminAction<any> = EmptyAction>(error: any, displayMessage: NotificationMessage, returnedAction: T = new EmptyAction() as T): T {
    console.error(displayMessage.description, error);
    this.modalNotificationService.openErrorDialog(displayMessage);
    return returnedAction;
  }
}

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { VersionControlService } from '../../version-control/version-control.service';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Project } from '../project.model';
import { allProjectsView, selectedTenantKeyView, shouldDisplayTenantsView } from '../../model/views';

@Injectable()
export class ImportProjectContext {
  projects$: Observable<Project[]>;
  selectedTenantKey$: Observable<string>;
  displayTenant$: Observable<boolean>;

  constructor(private versionControlService: VersionControlService, private store$: Store<AppState>) {
    this.projects$ = this.store$.pipe(select(allProjectsView), map(_.values));
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;
    this.displayTenant$ = this.store$.pipe(select(shouldDisplayTenantsView));
  }
}

import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import * as _ from 'lodash';
import { Project } from '../../project.model';
import { CreateProject } from '../../../model/project';
import { allProjectsView, selectedTenantKeyView, shouldDisplayTenantsView } from '../../../model/views';
import { closeModalOnEscape } from '../../../modal-dialog/modal-dialog.helper';
import { ProjectTemplate } from '../../../resources/marketplace/marketplace.model';
import { allProjectTemplatesView } from '../../../resources/marketplace/marketplace.view';
import { NavigationService } from '../../../navbar/navigation.service';

export interface CreateProjectDialogPayload {
  predefinedProjectData: Project | null;
  preselectedProjectTemplateKey?: string;
}

@Component({
  selector: 'adm4-create-project-container',
  template: `
    <adm4-modal-dialog-title header='Create Project'
                             [showClose]='true'
                             (closeClicked)="dialogRef.close()">
      <adm4-create-project [projects]='projects$ | async'
                           [availableProjectTemplates]="allProjectTemplates$ | async"
                           [predefinedProjectData]='predefinedProjectData'
                           [selectedTenantKey]='selectedTenantKey$ | async'
                           [displayTenant]='displayTenant$ | async'
                           [preselectedProjectTemplateKey]="preselectedProjectTemplateKey"
                           (projectCreated)='onProjectCreated($event)'
                           (cancelClicked)="onCanceled()"
                           (goToMarketPlace)="onGoToMarketplace()">
      </adm4-create-project>
    </adm4-modal-dialog-title>
  `
})
export class CreateProjectContainerComponent implements OnDestroy {
  private destroyed$: Subject<boolean> = new Subject();

  public readonly predefinedProjectData: Project | null;
  public readonly preselectedProjectTemplateKey: string | undefined;

  public readonly projects$: Observable<Project[]>;
  public readonly displayTenant$: Observable<boolean>;
  public readonly selectedTenantKey$: Observable<string>;
  public readonly allProjectTemplates$: Observable<ProjectTemplate[]>;

  constructor(@Inject(MAT_DIALOG_DATA) data: CreateProjectDialogPayload,
              public dialogRef: MatDialogRef<CreateProjectContainerComponent>,
              private store$: Store<AppState>,
              private navigation: NavigationService,
  ) {
    closeModalOnEscape(dialogRef, this.destroyed$);
    this.predefinedProjectData = data.predefinedProjectData;
    this.preselectedProjectTemplateKey = data.preselectedProjectTemplateKey;

    this.projects$ = this.store$.pipe(select(allProjectsView)).pipe(map(_.values));
    this.displayTenant$ = this.store$.pipe(select(shouldDisplayTenantsView));
    this.selectedTenantKey$ = this.store$.pipe(select(selectedTenantKeyView)) as Observable<string>;
    this.allProjectTemplates$ = this.store$.select(allProjectTemplatesView);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onProjectCreated({project, projectTemplateKey}: {project: Project, projectTemplateKey: string | undefined}): void {
    this.store$.dispatch(new CreateProject({project, projectTemplateKey}));
    this.dialogRef.close();
  }

  onCanceled() {
    this.dialogRef.close();
  }

  onGoToMarketplace() {
    this.navigation.navigateToMarketPlace();
  }
}

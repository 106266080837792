import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { VariableDetails } from '../variable-details/variable-details.model';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { CollapsiblePaneMixin, ICollapsiblePaneMixin } from '../../common/mixins/collapsible-pane.mixin';

@Component({
  selector: 'adm4-variable-help',
  template: `
   <div class='full-height-flex help-wrapper-container'>
      <adm4-column-header styleClass="light-header">
        Variable Help
        <adm4-toggle-collapse-button side='right'
                                     [isCollapsed]='false'
                                     (click)='setCollapse(true)'
        ></adm4-toggle-collapse-button>
      </adm4-column-header>
       <div class='remaining-space-flex-content-wrapper'>
           <div class='remaining-space-flex-content'>
              <div class="help-container scrollable-container">
                  <div class='help-info'>
                    <adm4-usage-info *ngIf='variableDetails.usages'
                            [usageData]='variableDetails.usages'
                            [basePath]="'../../patterns/'" type='Variable'></adm4-usage-info>
                    <div class="format-info" *ngIf='!!format'>
                      <h1 class='main-title'>Format</h1>
                      <div>{{FORMAT_INFO_TEXT}} {{format}} </div>
                    </div>
                    <h1 class='main-title'>About variables</h1>
                    <div class='main-content'>
                        <p>Use a variable to set a pattern property to a variable value instead of a fixed one. The actual value of the property will then be set during deployment, based on the inventory to which the project is deployed.</p>
                        <p>The sample value must be defined to enable full validation of your configuration. This value is also used as a default, when importing variables into a specific inventory on the inventory screen.</p>
                        <p>The inventory values display the values of the variable in selected inventories.</p>
                        <p>To create a variable, go to the pattern property for which you want to use a variable, and click the <button class='adm4-button-variable active middle-aligned'></button> button.</p>
                    </div>
                  </div>
              </div>
           </div>
       </div>
    </div>
  `,
  styleUrls: ['variable-help.scss', '../../common/styles/component-specific/help.scss']
})
@Mixin([CollapsiblePaneMixin])
export class VariableHelpComponent implements ICollapsiblePaneMixin, OnChanges {
  @Input() variableDetails: VariableDetails;
  @Input() collapseLocalStorageKey: string;
  @Output() collapse: EventEmitter<boolean> = new EventEmitter();

  format: string;

  FORMAT_INFO_TEXT = 'The format of the variable is ';

  /**
   * Implemented by CollapsiblePaneMixin
   */
  setCollapse: (isCollapsed: boolean) => void;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['variableDetails'] && this.variableDetails.selectedVariable) {
        this.format = this.variableDetails.selectedVariable.variable.parameters['format'];
    }
  }
}

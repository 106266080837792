import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { IssueModel } from '../../model/issue.model';
import { isErrorIssue, isInfoIssue, isWarningIssue } from '../../../projects/project-issues/project-issues.helper';
import { Maybe } from '../../utils/utils';

@Component({
  selector: 'adm4-validation-issue',
  template: `
    <div *ngIf='isPresent' class="validation-message"
         [ngClass]='validationClass' [class.force-wrap]="forceWrapMsg">
      <adm4-validation-indicator
              [diameter]='6'
              [isDisplayed]='true'
              [isError]='isError'
              [isWarning]='isWarning'
              [isInfo]="isInfo"></adm4-validation-indicator>
      <span>
        <ng-content></ng-content>
        <span class="validation-message-content">{{validationMessage}}</span>
      </span>
	</div>
  `,
  styleUrls: ['./validation-issue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationIssueComponent implements OnChanges {
  @Input() issue: Maybe<IssueModel>;

  /**
   * This is a numeric threshold, if issue's message has a substring without spaces that is at least as long as this threshold,
   * the message will be displayed force wrapped.
   */
  @Input() msgForceWrapThreshold: Maybe<number> = 50;

  public isError: boolean;
  public isWarning: boolean;
  public isInfo: boolean;

  public isPresent: boolean = false;
  public validationClass: string = '';
  public validationMessage: string;
  public forceWrapMsg: boolean = false;

  private forceWrapChecker: Maybe<RegExp>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.issue && this.issue) {
      this.isPresent = !!this.issue;
      this.isError = this.issue ? isErrorIssue(this.issue) : false;
      this.isWarning = this.issue ? isWarningIssue(this.issue) : false;
      this.isInfo = this.issue ? isInfoIssue(this.issue) : false;

      this.validationClass = this.calculateValidationClass();
      this.validationMessage = this.calculateValidationMessage();
    }

    if ((changes.issue && this.issue) || changes.msgForceWrapThreshold) {
      if (typeof this.msgForceWrapThreshold === 'number') {
        this.forceWrapChecker = ValidationIssueComponent.createForceWrapChecker(this.msgForceWrapThreshold);
        this.forceWrapMsg = this.forceWrapChecker.test(this.validationMessage);
      } else {
        this.forceWrapChecker = null;
        this.forceWrapMsg = false;
      }
    }
  }

  private calculateValidationClass(): string {
    if (this.isError) {
      return 'error';
    } else if (this.isWarning) {
      return 'warning';
    } else if (this.isInfo) {
      return 'info';
    } else {
      return '';
    }
  }

  private calculateValidationMessage(): string {
    let validationMessage: string = '';
    if (this.issue && this.issue.message) {
      validationMessage = this.issue.message;
    } else if (this.issue) {
      validationMessage = this.issue.code;
    }
    return validationMessage;
  }

  public static createForceWrapChecker(maxLengthWithoutSpaces: number): RegExp {
    return new RegExp('.*\\S{' + maxLengthWithoutSpaces + '}.*');
  }
}

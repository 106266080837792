import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Revision } from '../../../version-control/revision/revision.model';
import { LocalStatus, MetaInfo } from '../../../version-control/meta-info.model';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-diff-view-element',
  templateUrl: './diff-view-element.component.html',
  styleUrls: ['./diff-view-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiffViewElementComponent  {
  @Input() remoteCommitInfo?: Revision;
  @Input() localChangeInfo: MetaInfo;
  @Input() localFallbackLabel: string;
  @Input() currentUsername: string | null;
  @Input() showOnlyDifferences = true;
  @Input() boxed = true;

  readonly dateFormat = 'MMM d, y';
  readonly hourFormat = 'HH:mm';

  isDoneByAnotherUser(author?: string): boolean {
    return !_.isNil(this.currentUsername) && !_.isNil(author) &&  author !== this.currentUsername;
  }

  get shouldShowDiff(): boolean {
    return !this.showOnlyDifferences || this.localChangeInfo.localStatus !== LocalStatus.Unmodified;
  }

  get shouldShowRemote(): boolean {
    return this.localChangeInfo.localStatus !== LocalStatus.Added;
  }

  get shouldShowLocal(): boolean {
    return this.localChangeInfo.localStatus !== LocalStatus.Deleted;
  }

  get isUnmodified(): boolean {
    return this.localChangeInfo.localStatus === LocalStatus.Unmodified;
  }

  get hasRemoteCommitInfo(): boolean {
    return !_.isNil(this.remoteCommitInfo);
  }

  get hasLocalInfo(): boolean {
    return this.localChangeInfo.localStatus !== LocalStatus.Unmodified;
  }

}

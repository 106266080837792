import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'adm4-welcome-header',
  template: `
    <div class="header-container">
      <div class='menu-key-wrapper'>
        <span class='title'>Welcome</span>
      </div>
    </div>
  `,
  styleUrls: ['../../common/styles/component-specific/header.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeHeaderComponent {}

import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { InventoryResourceType, KubernetesCertificate, KubernetesCertificateWrapper } from '../../../inventory/inventory.model';
import { InventoryResourceActionType, SecretManagementTableModel } from '../../secret-management/secret-management-table/secret-management-table.model';
import { PatternMasterListHelper } from '../../../patterns/pattern-master-list.helper';
import { InventoryResourceActionDialogService } from '../../secret-management/inventory-resource-action/inventory-resource-action-dialog.service';
import { NavigationService } from '../../../navbar/navigation.service';
import { InventoryResourceContentActionDialogPayload } from '../../secret-management/inventory-resource-action/inventory-resource-action-dialog-payload.model';
import { MatSort } from '@angular/material/sort';
import { InventoryResourceSortingHelper } from '../../secret-management/secret-management-table/inventory-resource-sorting.helper';
import { reverseCompareResult } from '../../../common/utils/utils';
import { CertificateManagementHelper } from '../../certificates/certificate-management-table/certificate-management.helper';

@Component({
  selector: 'adm4-kubernetes-certificate-management-table',
  templateUrl: './kubernetes-certificate-management-table.component.html',
  styleUrls: ['../../secret-management/secret-management-table/secret-management-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KubernetesCertificateManagementTableComponent implements OnChanges, AfterViewInit {
  @Input() resourceGroup: (KubernetesCertificateWrapper)[];
  @Input() displayedColumns: SecretManagementTableModel[];
  @Input() filterText: string;
  @Input() selectedTenantKey: string;
  @Input() selectedScope: string;
  @Output() triggerResourceLoad: EventEmitter<void> = new EventEmitter();
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  tableDataSource: MatTableDataSource<KubernetesCertificateWrapper> = new MatTableDataSource([]);
  isExpandedUsage = false;

  readonly resourceTableColumns = SecretManagementTableModel;
  readonly inventoryResourceActionTypes = InventoryResourceActionType;

  constructor(private inventoryResourceActionDialogService: InventoryResourceActionDialogService,
              private navigationService: NavigationService) {
  }

  get hasDisplayableData(): boolean {
    return !_.isEmpty(this.tableDataSource.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resourceGroup'] || changes['filterText']) {
      this.tableDataSource.data = this.getFilteredCertificates();
      setTimeout(() => this.setDefaultSorting());
    }
  }

  ngAfterViewInit(): void {
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.sortData = this.resourceGroupSorting();
  }

  resourceGroupSorting(): (data: KubernetesCertificateWrapper[], sort: MatSort) => KubernetesCertificateWrapper[] {
    return (data:  KubernetesCertificateWrapper[], sort: MatSort) => {
      switch (sort.active) {
        case SecretManagementTableModel.ExpiryDateColumnName:
          return data.sort((res1: KubernetesCertificateWrapper, res2: KubernetesCertificateWrapper) => {
            const compareResult = InventoryResourceSortingHelper.byExpirationDateSortingFn(res1, res2);
            return sort.direction === 'asc' ? compareResult : reverseCompareResult(compareResult);
          });
        default:
          return data;
      }
    };
  }

  setDefaultSorting(): void {
    if (!_.isNil(this.sort)) {
      this.sort.active = SecretManagementTableModel.ExpiryDateColumnName;
      this.sort.direction = 'asc';
      this.sort.sortChange.emit();
    }
  }

  private shouldBeFilteredBySearch(certificate: KubernetesCertificateWrapper, regExp: RegExp): boolean {
    if (_.isEmpty(this.filterText)) {
      return true;
    }
    // TODO szabnagy use the label what will be added later
    const matchesUsage: boolean = !_.isNil(certificate?.usages) && certificate?.usages?.some(usage => usage?.label?.match(regExp));
    const matchesKind: boolean = !_.isNil(certificate.kind) && !_.isEmpty(certificate.kind.match(regExp));
    const matchesName: boolean = !_.isNil(certificate.name) && !_.isEmpty(certificate.name.match(regExp));
    const matchesKubernetesSecretKey: boolean = !_.isNil(certificate.kubernetesSecretKey) && !_.isEmpty(certificate.kubernetesSecretKey.match(regExp));
    const matchesSubject: boolean = !_.isNil(certificate.certificates) && certificate?.certificates?.some(cert => cert.subject.match(regExp));
    const matchesIssuer: boolean = !_.isNil(certificate.certificates) && certificate?.certificates?.some(cert => cert.issuer.match(regExp));
    const matchesSerial: boolean = !_.isNil(certificate.certificates) && certificate?.certificates?.some(cert => cert.serial.toString().match(regExp));
    return matchesUsage || matchesKind || matchesName || matchesKubernetesSecretKey || matchesIssuer || matchesSubject || matchesSerial;
  }

  private getFilteredCertificates(): (KubernetesCertificateWrapper)[] {
    const regExp = PatternMasterListHelper.getFilterRegexpByWord(this.filterText);
    return this.resourceGroup.filter(certificate => this.shouldBeFilteredBySearch(certificate, regExp));
  }

  openViewContentDialog(certificate: KubernetesCertificateWrapper, isReadOnly: boolean): void {
    certificate.scope = this.selectedScope;
    const inventoryResourceActionDialogPayload: InventoryResourceContentActionDialogPayload = {
      inventoryResourceType: InventoryResourceType.SECRET_FILE ,
      isSecret: true,
      resourceItem: certificate,
      hasViewPermission: true,
      isReadOnly: isReadOnly,
      onSaveCallback: () => this.triggerResourceLoad.emit()
    };
    this.inventoryResourceActionDialogService.openViewResourceContentDialog(inventoryResourceActionDialogPayload);
  }

  isColorHighlighted(certificate: KubernetesCertificateWrapper, severity: string): boolean {
    return certificate.certificates?.some((k8sCert: KubernetesCertificate) => this.checkExpirationBySeverity(k8sCert, severity));
  }

  checkExpirationBySeverity(certificate: KubernetesCertificate, severity: string): boolean {
    const daysTillExpiry = CertificateManagementHelper.countDaysTillCertificateExpiry(certificate);
    const dayLimitBySeverity = _.isEqual('error', severity) ? 30 : 60;
    return daysTillExpiry <= dayLimitBySeverity;
  }

  resolveExpirationIcon(certificate: KubernetesCertificate): string | undefined {
    const isHighSeverityExpiration = this.checkExpirationBySeverity(certificate, 'error');
    if (isHighSeverityExpiration) return 'error';
    if (this.checkExpirationBySeverity(certificate, 'warning')) return 'warning_problem';
    return undefined;
  }

  toggleUsedInExpansion(toggleAll?: boolean, certificateItem?: any): void {
    let certificates = this.getFilteredCertificates();
    if (toggleAll === true) {
      this.isExpandedUsage = !this.isExpandedUsage;
      this.tableDataSource.data = certificates.map((certificate: KubernetesCertificateWrapper) => {
        certificate.isExpanded = this.isExpandedUsage;
        return certificate;
      });
    } else {
      const secretToToggleIndex = certificates.findIndex((certificate: KubernetesCertificateWrapper) => {
          return certificate.kubernetesSecretKey === certificateItem.kubernetesSecretKey;
      });
      certificates[secretToToggleIndex].isExpanded = !certificates[secretToToggleIndex].isExpanded;
      this.tableDataSource.data = certificates;
    }
    this.isExpandedUsage = this.tableDataSource.data.every((certificate: KubernetesCertificateWrapper) => {
      if (!certificate?.usages || certificate?.usages?.length <= 1) {
        return true;
      }
      return certificate.isExpanded === true;
    });
  }


  navigateToPattern(projectKey: string, patternId: string): void {
    this.navigationService.navigateToPattern(projectKey, patternId);
  }

  getExpirationTooltip(singleCertificate: KubernetesCertificate): string {
    const daysTillExpiry = CertificateManagementHelper.countDaysTillCertificateExpiry(singleCertificate);
    switch (true) {
      case daysTillExpiry <= 0:
        return 'The certificate has expired. It needs to be urgently replaced.';
      case daysTillExpiry <= 30:
        return 'The certificate will expire soon. Please replace it as soon as possible.';
      case daysTillExpiry <= 60:
        return 'The certificate is going to expire. It needs to be urgently replaced.';
      default:
        return '';
    }
  }

  isUsedResource(resourceItem: (KubernetesCertificateWrapper)): boolean {
    return !_.isNil(resourceItem.usages) && resourceItem.usages?.length > 0;
  }
}

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import { NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { DeployState } from '../../../model/deploy/deploy.reducer';
import * as _ from 'lodash';
import { PatternInstance } from '../../../patterns/pattern-instance.model';
import { ApplicationTitleHelper } from './application-title.helper';
import { projectKeyView, selectedPatternInstanceView } from '../../../model/views';
import { combineLatest } from 'rxjs';
import { TenantHelper } from '../../helpers/tenant.helper';
import { NavigationConstants } from '../../constants/navigation.constants';

@Injectable()
export class ApplicationTitleService {

  constructor(
    private store$: Store<AppState>,
    private titleService: Title
  ) {
  }

  startService(router: Router) {
    const filterNavigationEnd = filter(e => e instanceof NavigationEnd);
    const mapUrl = map((navigationEnd: NavigationEnd) => navigationEnd.urlAfterRedirects);
    const deployStateStore = this.store$.pipe(select(state => state.deploy));

    combineLatest([
      router.events.pipe(filterNavigationEnd, mapUrl),
      this.store$.pipe(select(projectKeyView)),
      this.store$.pipe(select(selectedPatternInstanceView)),
      deployStateStore
    ]).pipe(
      map(([url, projectKey, patternInstanceFromStore, deployState]: [string, string | null, PatternInstance | null, DeployState]) => {
        const defaultTitle = 'nevisAdmin 4';
        const projectName = _.isNil(projectKey) ? '' : TenantHelper.cropTenantFromKey(projectKey);
        const deploymentProjectName = _.isNil(deployState.deploymentSelection.projectKey) ? '' : TenantHelper.cropTenantFromKey(deployState.deploymentSelection.projectKey);
        const deploymentInventoryName = _.isNil(deployState.deploymentSelection.inventoryKey) ? '' : TenantHelper.cropTenantFromKey(deployState.deploymentSelection.inventoryKey);

        const titleConfigs: [() => boolean, string][] = [
          [() => ApplicationTitleHelper.isWelcomePage(url), 'Welcome '],
          [() => ApplicationTitleHelper.isPatternEditorScreen(url, patternInstanceFromStore), `${patternInstanceFromStore && patternInstanceFromStore.name} - ${projectName}`],
          [() => ApplicationTitleHelper.isPatternEditorOverviewSreen(url), `${projectName} - ${NavigationConstants.OVERVIEW}`],
          [() => ApplicationTitleHelper.isVariableScreen(url, projectKey), `Variables - ${projectName} 	`],
          [() => ApplicationTitleHelper.isIssueScreen(url, projectKey), `Issues - ${projectName} 	`],
          [() => ApplicationTitleHelper.isReportsScreen(url, projectKey), `Reports - ${projectName} 	`],
          [() => ApplicationTitleHelper.isPublishScreen(url), `Publish - ${projectName} `],
          [() => ApplicationTitleHelper.isDeployScreen(url, deployState), `Deploy ${deploymentProjectName} - ${deploymentInventoryName}`],
          [() => ApplicationTitleHelper.isSummary(url), `Summary - ${projectName} `],
          [() => ApplicationTitleHelper.isProjectSettings(url), `Settings - ${projectName}`],
          [() => ApplicationTitleHelper.isInventorySettings(url), `Settings - ${ApplicationTitleHelper.getTitleOfInventoriesScreen(url)}`],
          // main screens should go last, so when above checks don't pass it will set to these
          // otherwise if main screen passed check e.g. settings check would be ignored unless we implement more specific logic to check main screens
          [() => ApplicationTitleHelper.isResourcesScreen(url), 'Resources '],
          [() => ApplicationTitleHelper.isOnInventoriesScreen(url), ApplicationTitleHelper.getTitleOfInventoriesScreen(url)],
          [() => ApplicationTitleHelper.isGraphViewPage(url), `Authentication flow - ${ApplicationTitleHelper.getTitleOfGraphViewScreen(url)} `],
        ];
        return _.reduce(titleConfigs, (title: string, [condition, output]: [() => boolean, string]) => {
          return title === defaultTitle && condition() ? `${output}  - ${defaultTitle}` : title;
        }, defaultTitle);

      }))
      .subscribe(value => {
        this.titleService.setTitle(value);
      });
  }
}

import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { HelpConnectService } from '../shared/scroll-helper/help-connect.service';
import { IssueModel } from '../common/model/issue.model';
import { VariableModel } from '../variables/variable.model';
import { DynamicPropertyCreationService } from './dynamic-property-creation.service';
import { WidgetComponent } from './widget.component';
import { Property } from '../plugins/pattern-type.model';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { PatternProperty } from '../patterns/pattern-instance.model';
import { ScrollService } from '../common/services/scroll/scroll.service';
import { PropertyType } from '../plugins/property-type.model';
import { isVariable } from '../shared/pattern.utils';
import { select, Store } from '@ngrx/store';
import { AppState } from '../model/reducer';
import { CreateVariable } from '../model/variables';
import * as _ from 'lodash';
import { shouldDisplayValidationPendingMessage } from './validation-pending.helper';
import { SetVariableService } from '../set-variable/set-variable.service';
import { CreateVariableOutputData } from '../set-variable/create-variable-output-data.model';
import { NavigationService } from '../navbar/navigation.service';
import { getHighestSeverityIssueCssClass } from '../projects/project-issues/project-issues.helper';
import { ParameterHelper } from './parameter.helper';
import { projectValidatingView } from '../model/views';
import { PropertyExternalLinkContext } from './property-external-link.context';
import { isVariableRef, variableReferencePrefix } from '../common/constants/reference-prefix.constants';
import { PropertyFormValueConverter } from './property-form-value-converter';
import { Mixin } from '../common/decorators/mixin.decorator';
import { ScrollToHelpMixin } from '../shared/scroll-helper/scroll-to-help.mixin';
import { PropertyTypeEnum } from '../common/constants/app.constants';
import { VariablesHelper } from '../variables/variables.helper';
import { Maybe } from '../common/utils/utils';

@Component({
  selector: 'adm4-property-list-element',
  template: `
    <div class='pr_container ui_list_item line-indicatable-validated'
         [ngClass]='highestSeverityIssueClass'
         [class.selected]='isSelected'
         (click)='selectProperty.emit(patternProperty.propertyKey)'>
      <div class='form-group'
           [ngClass]='highestSeverityIssueClass'>
        <div class='pr-heading'>
          <label class='pr-label'>{{patterTypeProp?.name || "Unknown property: " + patternProperty.propertyKey}}<span *ngIf="isRequiredWithNoDefault">*</span>
          </label>
          <span *ngIf='shouldDisplayVarButton' [ngbTooltip]="disabledVarButtonTooltip" [disableTooltip]="!shouldDisableVarButton">
            <button type='button'
                    class='adm4-button-variable'
                    [class.active]='isSelected'
                    [class.selected]='variableMode'
                    [disabled]='readOnly || shouldDisableVarButton'
                    (click)='openSetVariableDialog()'></button>
          </span>
          <i *ngIf='patternProperty' class="fa fa-question-circle help-icon-with-action" aria-hidden="true"
             [class.active]='isHelpForPropertyActive$ | async'
             (click)='scrollToHelp(propertyHelpClass)'></i>
        </div>
        <div [hidden]='variableMode'>
          <ng-template #propertyTemplate></ng-template>
        </div>
        <div *ngIf='variableMode' class='selected-variable'>
          <a *ngIf='shouldDisplayVariableLink; else variableNameAsText' (click)='navigateToVariable($event)'>
            {{selectedVariableName}}
          </a>
          <ng-template #variableNameAsText>
              {{selectedVariableName}}
          </ng-template>
          <div *ngIf='!readOnly' (click)='exitVariableMode()'>
            <i class="fa fa-times-circle-o cancel-icon admn4-button-icon" aria-hidden="true"></i>
          </div>
        </div>
        <adm4-property-issues *ngIf='hasIssues && !(validating$ | async)' [issues]='issues'
                              [shouldShowValidationRequiredMessage]='displayValidationPendingMessage'
        ></adm4-property-issues>
        <div *ngIf='shouldShowExternalLinks' class='additional-info-links-container'>
          <adm4-property-external-link-list [externalLinkDisablingDependencies$]='externalLinkDisablingDependencies$'
                                            [links]='patterTypeProp.links'
                                            [projectKey]='projectKey'
                                            [patternId]='patternId'
          ></adm4-property-external-link-list>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['widget.component.scss']
})
@Mixin([ScrollToHelpMixin])
export class PropertyListElementComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit, ScrollToHelpMixin {
  @Input() issues: IssueModel[];
  @Input() isSelected: boolean;
  @Input() readOnly: boolean;
  @Input() patternProperty: PatternProperty;
  @Input() variables: VariableModel[];
  @Input() propertyType?: PropertyType;
  @Input() patterTypeProp?: Property;
  @Input() projectKey: string;
  @Input() patternId: string;
  @Input() form: UntypedFormGroup;
  @Input() isLocal = true;
  @Input() scrollArea?: HTMLElement;
  @Output() clearPropertyValue = new EventEmitter();

  @Output() validate = new EventEmitter();
  @Output() selectProperty = new EventEmitter<string>();
  @ViewChild('propertyTemplate', {read: ViewContainerRef, static: true}) propertyTemplate: ViewContainerRef;
  // disabled link templates
  @ViewChild('disabledLinkValidationReason', {static: false}) disabledLinkValidationReason: TemplateRef<any>;
  @ViewChild('disabledLinkErrorReason', {static: false}) disabledLinkErrorReason: TemplateRef<any>;
  @ViewChild('disabledLinkDirtyFormReason', {static: false}) disabledLinkDirtyFormReason: TemplateRef<any>;

  readonly helpClassPrefix = 'sect'; // TODO 05-Apr-2018/soroka: might need to be global constant to be reused in help wherever needed

  propertyInstance: WidgetComponent;
  isHelpForPropertyActive$: Observable<boolean>; // indicates if help is displayed for current property
  validating$: Observable<boolean>;
  externalLinkDisablingDependencies$: Observable<[boolean, boolean, boolean]>;
  propertyHelpClass: string; // help css class of this property
  selectedVariableName: string | null;
  isRequiredWithNoDefault = false;
  shouldShowExternalLinks = false;
  shouldDisplayVariableLink: boolean = false;
  private routeSubscription: Subscription;
  private variableVisible = true;
  private propertyTypeUiComponent: string;

  public shouldDisableVarButton: boolean;
  public disabledVarButtonTooltip: Maybe<string>;

  private destroyed$: Subject<boolean> = new Subject();
  private formValueChangesSub: Subscription;

  constructor(private dynamicPropCreationService: DynamicPropertyCreationService,
              private elRef: ElementRef,
              private scrollService: ScrollService,
              private createVariableService: SetVariableService,
              private store$: Store<AppState>,
              private navigationService: NavigationService,
              // help should connect to properties on pattern editor, therefore if properties are reused in other places this will not be provided
              @Inject(HelpConnectService) @Optional() public helpConnectService: HelpConnectService | null,
              // this is optional because component is used in multiple places but this feature is required only in one place so far
              // to make feature work, the component that uses property list should have a provider for the token specified in Inject
              // different users can provide different implementation for this which can provide data from different sources for example
              // in  that case it would require to adjust current solution to use interface as injection token and provide class that implements it
              @Inject(PropertyExternalLinkContext) @Optional() private propertyExternalLinkContext: PropertyExternalLinkContext | null) {
  }

  ngOnInit(): void {
    this.propertyHelpClass = this.patternProperty ? `${this.helpClassPrefix}-${this.patternProperty.propertyKey}` : '';
    this.propertyTypeUiComponent = _.isNil(this.propertyType) || _.isNil(this.propertyType.uiComponent) ? PropertyTypeEnum.FallbackPropertyComponent : this.propertyType.uiComponent;
    this.variableVisible = this.propertyTypeUiComponent !== PropertyTypeEnum.FallbackPropertyComponent
      && this.propertyTypeUiComponent !== PropertyTypeEnum.PatternReferencePropertyComponent;
    this.propertyInstance = this.initPropertyComponent();
    this.updatePropertyInstance();
    this.isRequiredWithNoDefault = ParameterHelper.isRequiredPropertyValueWithNoDefault(this.patterTypeProp, this.propertyType, this.propertyInstance.defaultValue);
    this.form.controls[this.patternProperty.propertyKey].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => this.updateVariableBasedOnPropertyValue(value));
    this.validating$ = this.store$.pipe(select(projectValidatingView));
    this.isHelpForPropertyActive$ = _.isNil(this.helpConnectService) ? of(false) : this.helpConnectService.isHelpDisplayedForProperty(this.propertyHelpClass);
    this.setupExternalLinksFeature();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const onlyIsSelectedChanged = _.isEqual(Object.keys(changes), ['isSelected']);
    // FIXME NEVISADMV4-8738
    if (this.propertyInstance && !onlyIsSelectedChanged) {
      this.updatePropertyInstance();
    }
    if (changes['isSelected'] && this.isSelected && this.scrollArea) {
      this.scrollService.scrollIntoViewIfNeeded(this.scrollArea, this.elRef.nativeElement);
    }
    if (changes['variables'] && this.variables && isVariable(this.form.controls[this.patternProperty.propertyKey].value)) {
      this.updateVariableBasedOnPropertyValue(this.form.controls[this.patternProperty.propertyKey].value);
    }
  }

  ngAfterViewInit(): void {
    if (this.isSelected && this.scrollArea) {
      this.scrollService.scrollIntoViewIfNeeded(this.scrollArea, this.elRef.nativeElement);
    }
  }

  setupExternalLinksFeature(): void {
    if (_.isNil(this.propertyExternalLinkContext) || _.isNil(this.patterTypeProp) || _.isEmpty(this.patterTypeProp.links)) {
      return;
    }
    this.externalLinkDisablingDependencies$ = combineLatest([
      this.propertyExternalLinkContext.isProjectValidationInProgress$,
      this.propertyExternalLinkContext.isErroredProject$,
      this.form.valueChanges.pipe(debounceTime(0), map(() => this.form.dirty), startWith(this.form.dirty))]
    );
    this.shouldShowExternalLinks = true;
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    this.formValueChangesSub.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  initPropertyComponent(): WidgetComponent {
    const componentType = this.dynamicPropCreationService.getComponentType(this.propertyTypeUiComponent);
    const compReference = this.propertyTemplate.createComponent(componentType);
    return compReference.instance as WidgetComponent;
  }

  private updatePropertyInstance(): void {
    const property: PatternProperty = this.patternProperty;
    const instance: WidgetComponent = this.propertyInstance;
    instance.value = this.form.value[this.patternProperty.propertyKey];
    instance.patternId = this.patternId;
    instance.widgetProperty = property;
    instance.issues = this.issues;
    instance.parameters = this.patterTypeProp ? this.patterTypeProp.parameters : {};
    instance.group = this.form;
    instance.validate.pipe(takeUntil(this.destroyed$)).subscribe(() => this.validate.emit(property));
    instance.selectionChanged = this.selectProperty;
    instance.readOnly = this.readOnly;
    instance.local = this.isLocal;
    instance.propertyType = this.propertyType;
    instance.propertyName = this.patterTypeProp ? this.patterTypeProp.name : '';
    instance.defaultValue = this.patterTypeProp && this.patterTypeProp.defaultValue ? this.patterTypeProp.defaultValue : null;
    instance.scrollArea = this.scrollArea;
    instance.initFinished();
    this.calculateDisableVarButton();
    this.formValueChangesSub?.unsubscribe();
    this.formValueChangesSub = this.form.controls[this.patternProperty.propertyKey]?.valueChanges
        .subscribe(() => this.calculateDisableVarButton());
  }

  scrollToHelp(_htmlClass: string): void {/* implemented by mixin ScrollToHelpMixin */}

  updateVariableBasedOnPropertyValue(value: any): void {
    let parsedValue: any;
    try {
      parsedValue = JSON.parse(value);
    } catch (e) {
      parsedValue = value;
    }
    let newVarName;
    if (Array.isArray(parsedValue) && parsedValue.length === 1 && isVariableRef(parsedValue[0])) {
      newVarName = parsedValue[0].replace(variableReferencePrefix, '');
    } else if (isVariable(value)) {
      newVarName = parsedValue.replace(variableReferencePrefix, '');
    }
    if (newVarName) {
      this.selectedVariableName = newVarName;
      this.shouldDisplayVariableLink = this.variables.some(variable => variable.variableKey === this.selectedVariableName);
    } else {
      this.selectedVariableName = null;
    }
  }

  onCreateVariable(createVariableOutput: CreateVariableOutputData): void {
    if (this.patterTypeProp) {
      const newVariable: VariableModel = {
        variableKey: createVariableOutput.variableName,
        value: createVariableOutput.value,
        bundleKey: this.patterTypeProp.bundle,
        className: this.patterTypeProp.className,
        parameters: this.patterTypeProp.parameters,
        requireOverloading: true
      };
      this.onSelectVariable(newVariable.variableKey);
      this.store$.dispatch(new CreateVariable(_.concat(this.variables, newVariable)));
    }
  }

  openSetVariableDialog(): void {
    if (this.variableMode) {
      return;
    }

    this.createVariableService.openCreateVariableDialog({
      projectKey: this.projectKey,
      patternName: this.form.controls['pattern_name'].value,
      patternTypeProperty: this.patterTypeProp,
      variableStartingValue: this.getVariableStartingValue(),
      variableType: this.propertyType,
      existingVariables: this.variables,
      onCreate: (createVariableOutput => this.onCreateVariable(createVariableOutput)),
      onSelect: (selectedVariableName => this.onSelectVariable(selectedVariableName))
    });
  }

  private getVariableStartingValue(): string | undefined {
    if (!_.isEmpty(this.form.controls[this.patternProperty.propertyKey].value)) {
      return this.form.controls[this.patternProperty.propertyKey].value;
    }

    if (_.get(this.patterTypeProp, 'parameters.secret')) {
      return VariablesHelper.SECRET_VARIABLE_SAMPLE_VALUE;
    }
    return undefined;
  }

  onSelectVariable(variableName: string): void {
    this.selectedVariableName = variableName;
    this.form.controls[this.propertyInstance.widgetProperty.propertyKey].markAsDirty();
    this.form.controls[this.propertyInstance.widgetProperty.propertyKey].markAsTouched();
    const newProp: PatternProperty = {value: `${variableReferencePrefix}${variableName}`, propertyKey: this.patternProperty.propertyKey};
    const newFormValue = PropertyFormValueConverter.toFormValue(newProp, this.propertyType, this.patterTypeProp);
    this.form.controls[this.patternProperty.propertyKey].setValue(newFormValue);
  }

  exitVariableMode(): void {
    const currentVariable = this.variables.find(variable => variable.variableKey === this.selectedVariableName);
    let currentVariableValue = _.isNil(currentVariable)
        ? PropertyFormValueConverter.emptyFormValue(this.propertyTypeUiComponent)
        : PropertyFormValueConverter.toFormValue(currentVariable, this.propertyType, this.patterTypeProp);
    this.selectedVariableName = null;

    if (VariablesHelper.isSecretVariable(currentVariable)) {
      currentVariableValue = '';
    }

    this.form.controls[this.propertyInstance.widgetProperty.propertyKey].markAsDirty();
    this.form.controls[this.propertyInstance.widgetProperty.propertyKey].markAsTouched();
    this.form.controls[this.patternProperty.propertyKey].setValue(currentVariableValue);
    this.updatePropertyInstance();
  }

  navigateToVariable(event: MouseEvent): void {
    event.stopPropagation();
    if (_.isNil(this.selectedVariableName)) {
      return;
    }

    this.navigationService.navigateToVariable(this.projectKey, this.selectedVariableName);
  }

  get hasIssues(): boolean {
    return !_.isEmpty(this.issues);
  }

  get variableMode(): boolean {
    return _.isString(this.selectedVariableName) && !_.isEmpty(this.selectedVariableName);
  }

  get shouldDisplayVarButton() {
    const canSetVariable = !this.readOnly && !_.isNil(this.propertyType) && this.variableVisible;
    return canSetVariable || this.variableMode;
  }

  calculateDisableVarButton() {
    switch (this.propertyTypeUiComponent) {
      case PropertyTypeEnum.AttachmentPropertyComponent:
        const value = this.form.controls[this.patternProperty.propertyKey]?.value;
        // the var button should be disabled if the property has a value, and it's not a variable reference value
        const result = !!value && !(typeof value === 'string' && value.startsWith(variableReferencePrefix));
        this.shouldDisableVarButton = result;
        this.disabledVarButtonTooltip = result ? 'Delete all attachments to assign a variable to this property.' : undefined;
        break;
      default:
        this.shouldDisableVarButton = false;
        this.disabledVarButtonTooltip = undefined;
    }
  }

  get highestSeverityIssueClass() {
    return getHighestSeverityIssueCssClass(this.issues);
  }

  get displayValidationPendingMessage(): boolean {
    const formControl = this.getFormControl();
    return !_.isEmpty(this.issues) && shouldDisplayValidationPendingMessage(formControl);
  }

  private getFormControl(): AbstractControl {
    return this.form.controls[this.propertyInstance.widgetProperty.propertyKey];
  }
}

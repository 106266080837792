import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'adm4-inventory-help-kubernetes',
  templateUrl: './inventory-help-kubernetes.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryHelpKubernetesComponent {

}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InsertProjectVariablesDialogComponent } from './insert-project-variables-dialog.component';
import { InsertProjectVariablesPayload } from './insert-project-variables-payload.model';

@Injectable()
export class InsertProjectVariablesDialogService {

  constructor(private dialog: MatDialog) { }

  openInsertProjectVariablesDialog(tenantKey: string, onInsert: (content: string) => void): void {
    this.dialog.open<InsertProjectVariablesDialogComponent, InsertProjectVariablesPayload>(InsertProjectVariablesDialogComponent, {
      width: '30%',
      autoFocus: false,
      data: {
        tenantKey: tenantKey,
        onInsert: onInsert
      }
    });
  }
}

import * as _ from 'lodash';
import {
  DeploymentHistoryItem,
  DeploymentHistoryJobStatus,
  DeploymentHistoryState
} from '../../common/model/deployment-history.model';

export class KubernetesStatusHelper {

  /**
   * Checks if the deployment's status is either empty or PROMOTED.
   * @param deploymentHistoryItem
   */
  static isPrimaryDeployment(deploymentHistoryItem: DeploymentHistoryItem): boolean {
    return _.isNil(deploymentHistoryItem.deploymentState) || deploymentHistoryItem.deploymentState === DeploymentHistoryState.PROMOTED;
  }

  static isSecondaryDeployment(deploymentHistoryItem: DeploymentHistoryItem): boolean {
    return !_.isNil(deploymentHistoryItem.deploymentState) &&
      deploymentHistoryItem.deploymentState === DeploymentHistoryState.CANARY ||
      deploymentHistoryItem.deploymentState === DeploymentHistoryState.ROLLBACK_FAILED ||
      deploymentHistoryItem.deploymentState === DeploymentHistoryState.PROMOTE_FAILED;
  }

  static getSecondaryList(deploymentList: DeploymentHistoryItem[]): DeploymentHistoryItem[] {
    return deploymentList.filter((deploymentHistoryItem: DeploymentHistoryItem) => this.isSecondaryDeployment(deploymentHistoryItem));
  }

  /**
   * Filters the deployment list for active deployments. Those deployments are considered active,
   * where the `deploymentState` of the deployment with the biggest prefix for a project is empty or PROMOTED.<br/>
   * If the last deployment for a project is `DELETED`, that means that the project is not active anymore,
   * even if it had some primary or promoted items earlier.
   * @param deploymentList
   */
  static filterForActivePrimaryDeployments(deploymentList: DeploymentHistoryItem[]): DeploymentHistoryItem[] {
    const primarySecondaryOrDeleted = deploymentList.filter((deploymentHistoryItem: DeploymentHistoryItem) => {
      return _.isNil(deploymentHistoryItem.deploymentState)
            || deploymentHistoryItem.deploymentState === DeploymentHistoryState.PROMOTED
            || deploymentHistoryItem.deploymentState === DeploymentHistoryState.DELETED;
    });
    const highestPostfixOfDeployments =  _.maxBy(primarySecondaryOrDeleted, (deployment) => deployment.timestamp)?.postfix || '';
    const latestDeployments = primarySecondaryOrDeleted.filter((deployment: DeploymentHistoryItem) => {
      return _.isEqual(deployment.postfix, highestPostfixOfDeployments);
    });
    const groupedByProject = _.uniqBy(latestDeployments, 'projectKey');
    return groupedByProject.filter((d: DeploymentHistoryItem) => KubernetesStatusHelper.isPrimaryDeployment(d));
  }

  static hasActivePrimaryDeployments(deploymentList: DeploymentHistoryItem[]): boolean {
    return this.filterForActivePrimaryDeployments(deploymentList).length > 0;
  }

  static getSortedDeploymentHistoryByTime(deploymentList: DeploymentHistoryItem[]): DeploymentHistoryItem[] {
    return [...deploymentList].sort((dh1: DeploymentHistoryItem, dh2: DeploymentHistoryItem) => dh2.timestamp.localeCompare(dh1.timestamp));
  }

  /**
   * Promotion of a deployment item is disabled if the promotion of an item has failed, or the deployment of an item is in progress.
   */
  static isPromoteDisabled(secondaryDeploymentHistory: DeploymentHistoryItem[]): boolean {
    return secondaryDeploymentHistory.some((deploymentHistoryItem: DeploymentHistoryItem) => {
      return deploymentHistoryItem.deploymentState === DeploymentHistoryState.PROMOTE_FAILED || this.isDeploymentRunning(deploymentHistoryItem);
    });
  }

  static isPromoteFailed(history: DeploymentHistoryItem[]): boolean {
    return history.some((item: DeploymentHistoryItem) => item.deploymentState === DeploymentHistoryState.PROMOTE_FAILED);
  }

  static isRollbackDisabled(secondaryDeploymentHistory: DeploymentHistoryItem[]): boolean {
    return secondaryDeploymentHistory.some((deploymentHistoryItem: DeploymentHistoryItem) => {
      return deploymentHistoryItem.deploymentState === DeploymentHistoryState.ROLLBACK_FAILED || this.isDeploymentRunning(deploymentHistoryItem);
    });
  }

  static isDeploymentRunning(deploymentHistoryItem: DeploymentHistoryItem): boolean {
    return _.isNil(deploymentHistoryItem.jobStatus) || _.isEqual(deploymentHistoryItem.jobStatus, DeploymentHistoryJobStatus.Running);
  }
}

import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { PropertyType } from '../plugins/property-type.model';
import { PropertyTypeEnum } from '../common/constants/app.constants';
import { VariablesHelper } from './variables.helper';
import { Property } from '../plugins/pattern-type.model';

@Component({
  selector: 'adm4-no-sample-value-variable',
  template: `
    <ng-template #attachmentVariable>
      <adm4-validation-message message='This variable does not have a sample value. You need to upload files for it in inventory' [isInfo]='true' [diameter]='validationIndicatorDiameter'></adm4-validation-message>
    </ng-template>
    <ng-template #fallback>
      <adm4-validation-message message='This property does not have a sample value.' [isError]='true' [diameter]='validationIndicatorDiameter'></adm4-validation-message>
    </ng-template>
    <ng-template #secretVariable>
      <adm4-validation-message message='This variable does not have a sample value. To define a deployable value, edit the inventory and insert a secret. To set a sample, go to the Variables screen.' [isInfo]='true' [diameter]='validationIndicatorDiameter'></adm4-validation-message>
    </ng-template>
    <ng-container *ngTemplateOutlet='noSampleValueTemplate'></ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoSampleValueVariableComponent {
  @Input() variableType: PropertyType;
  @Input() patternTypeProperty: Property;
  @Input() secret = false;


  @ViewChild('attachmentVariable', {static: true}) attachmentVariableTemplate: TemplateRef<any>;
  @ViewChild('secretVariable', {static: true}) secretVariable: TemplateRef<any>;
  @ViewChild('fallback', {static: true}) fallbackTemplate: TemplateRef<any>;

  readonly validationIndicatorDiameter = 6;

  get noSampleValueTemplate(): TemplateRef<any> {
    if (this.variableType.uiComponent === PropertyTypeEnum.AttachmentPropertyComponent) {
      return this.attachmentVariableTemplate;
    }

    if (this.secret) {
      return this.secretVariable;
    }

    if (VariablesHelper.isSecretVariable(this.patternTypeProperty)) {
      return this.secretVariable;
    }

    return this.fallbackTemplate;
  }
}

import { DeploymentItemStatusModel } from '../deployment-status.model';
import * as _ from 'lodash';

export class PatternLogHelper {
  static grepPatternLog(content: string, item: DeploymentItemStatusModel): string {
    const patternName: string = this.resolvePatternNameFromDeploymentItem(item);
    const expectedEntry = new RegExp(`${patternName} at \\d+%`);
    const lineRegexp: RegExp = new RegExp('[^\\r\\n]+', 'g');
    const lines: string[] | null = content.match(lineRegexp);
    if (_.isNil(lines) || _.isEmpty(lines)) {
      return content;
    }
    const filteredLines = lines.filter(line => expectedEntry.test(line)).join('\n');
    return !filteredLines ? content : filteredLines;
  }

  static resolvePatternNameFromDeploymentItem(item: DeploymentItemStatusModel): string {
    const cutoutText = `${item.host} - `;
    return item.label.replace(cutoutText, '');
  }
}

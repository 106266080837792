import { Component, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Maybe } from '../../utils/utils';

import { editor, IDisposable } from 'monaco-editor';
import ITextModel = editor.ITextModel;
import ICodeEditor = editor.ICodeEditor;
import IEditorMinimapOptions = editor.IEditorMinimapOptions;

export interface MonacoEditorOptions extends editor.IEditorOptions {
  // `wordBasedSuggestions` is somehow _not_ typed in ngx-monaco-editor, but works, see https://github.com/microsoft/monaco-editor/issues/1746
  wordBasedSuggestions?: boolean;
  theme?: string;
  language?: string;
}

const setMonacoModelLanguage = (model: Maybe<ITextModel>, syntax: Maybe<string>) => {
  if (model) {
    // this is not part of the typing
    (window as any).monaco.editor.setModelLanguage(model, syntax || '');
  } else {
    console.warn('MonacoEditorFormControlComponent#setMonacoModelLanguage: missing model');
  }
};

const defaultOptions: MonacoEditorOptions = {
  hover: {enabled: true, sticky: true},
  theme: 'vs',
  language: 'yaml',
  glyphMargin: false,
  lineNumbersMinChars: 1,
  scrollBeyondLastLine: false,
  wordBasedSuggestions: true,
  automaticLayout: true,
  wordWrap: 'on',
  minimap:<IEditorMinimapOptions> {
    enabled: false,
  },
};

@Component({
  selector: 'adm4-monaco-editor-form-control',
  template: `
  <ngx-monaco-editor
    [options]="_options"
    (onInit)="onMonacoEditorInit($event)"
  ></ngx-monaco-editor>
  `,
  styleUrls: ['./monaco-editor-form-control.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MonacoEditorFormControlComponent),
    multi: true,
  }],
})
export class MonacoEditorFormControlComponent implements OnChanges, OnDestroy, ControlValueAccessor {

  @HostListener('focus') formControlFocused() {
    this.editorReady.then((e: ICodeEditor) => e.focus());
  }

  private destroyed$: Subject<void> = new Subject();

  private disabled: boolean = false;

  public editorReady: Promise<ICodeEditor>;
  public onMonacoEditorInit: (editor: ICodeEditor) => void;

  _options: MonacoEditorOptions = {...defaultOptions};

  @Input()
  public options: MonacoEditorOptions | undefined;

  @Input()
  public syntax: Maybe<string>;

  /**
   * Emits when the content or state of the monaco editor changes.<br/>
   * Can be used to trigger change detection, which might be needed because the monaco runs outside the zone.
   */
  @Output()
  public editorChanged: EventEmitter<void> = new EventEmitter();

  constructor() {
    this.editorReady = new Promise<ICodeEditor>(resolve => {
      this.onMonacoEditorInit = (e: ICodeEditor) => {
        resolve(e);
      };
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.applyConfig();
    }
    if (changes.syntax) {
      this.editorReady.then((e: ICodeEditor) => {
        const model: Maybe<ITextModel> = e.getModel();
        setMonacoModelLanguage(model, this.syntax);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private applyConfig(): void {
    this._options = {
      ...defaultOptions,
      ...this.options,
      readOnly: this.disabled,
    };
  }

  registerOnChange(onChangeCallback: any): void {
    this.editorReady.then((e: ICodeEditor) => {
      const modelChangeSub: IDisposable = e.onDidChangeModelContent((_event: editor.IModelContentChangedEvent) => {
        const model: editor.ITextModel | null = e.getModel();
        if (model) {
          onChangeCallback(model.getValue());
          this.editorChanged.emit();
        }
      });
      this.destroyed$.pipe(take(1)).subscribe(() => {
        modelChangeSub.dispose();
      });
    });
  }

  registerOnTouched(onTouchedCallback: any): void {
    this.editorReady.then((e: ICodeEditor) => {
      let editorFocusedSub: IDisposable;
      editorFocusedSub = e.onDidFocusEditorText(() => {
        onTouchedCallback();
        editorFocusedSub?.dispose();
        this.editorChanged.emit();
      });
    });
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.applyConfig();
  }

  writeValue(value: any): void {
    this.editorReady.then((e: ICodeEditor) => e.setValue(value));
  }
}

import { NgModule } from '@angular/core';
import { FilterComponent } from './components/filter/filter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipConfig, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HighLightPipe } from './pipes/highlight.pipe';
import { AutosizeDirective } from './directive/autosize.directive';
import { ScrollableDirective } from './directive/scrollable.directive';
import { CommonModule, DatePipe } from '@angular/common';
import { EditableTextComponent } from './components/editable-text-area/editable-text.component';
import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { NameFromPathPipe } from './pipes/name-from-path.pipe';
import { ParentPathPipe } from './pipes/parent-path.pipe';
import { ColumnHeaderComponent } from './column-header/column-header.component';
import { ValidationIndicatorComponent } from './components/validation/validation-indicator.component';
import { ButtonBarComponent } from './components/button-bar/button-bar.component';
import { DecimalToPercentsPipe } from './pipes/decimal-to-percents.pipe';
import { AbbreviatePipe } from './pipes/abbreviate.pipe';
import { ProjectIssuesTooltipComponent } from './components/validation/project-issues-tooltip.component';
import { RouterModule } from '@angular/router';
import { ValidationIssueComponent } from './components/validation/validation-issue.component';
import { AfterViewInitDirective } from './directive/after-view-init.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UsageInfoComponent } from './components/usage/usage-info.component';
import { SimpleListItemComponent } from './components/simple-list-item/simple-list-item.component';
import { ValidationMessageComponent } from './components/validation/validation-message/validation-message.component';
import { ModalDialogTitleComponent } from '../modal-dialog/modal-dialog-title.component';
import { ControlDisableDirective } from './directive/control-disable.directive';
import { ExternalLinkComponent } from './components/external-link/external-link.component';
import { LinkComponent } from './components/link/link.component';
import { SplitButtonComponent } from './components/split-button/split-button.component';
import { CropTenantFromKeyPipe } from './pipes/crop-tenant-from-key.pipe';
import { SingleFileInputComponent } from './components/single-file-input/single-file-input.component';
import { FileUploadResultsDialogComponent } from './components/file-upload-results-dialog/file-upload-results-dialog.component';
import { CloseModalOnNavigationDirective } from './directive/close-modal-on-navigation.directive';
import { MenuItemWrapperComponent } from './components/menu-element/menu-item-wrapper.component';
import { DiffViewElementComponent } from './components/diff-view-element/diff-view-element.component';
import { VersionControlFormComponent } from './components/version-control-form/version-control-form.component';
import { CommitInfoComponent } from './components/commit-info/commit-info.component';
import { FileContentDiffComponent } from './components/file-content-diff/file-content-diff.component';
import { FileContentDiffEditorComponent } from './components/file-content-diff/file-content-diff-editor.component';
import { LocalChangesPopupComponent } from './components/local-changes-popup/local-changes-popup.component';
import { MenuElementComponent } from './components/menu-element/menu-element.component';
import { StopEventPropagationDirective } from './directive/event-control/stop-event-propagation.directive';
import { PreventDefaultEventDirective } from './directive/event-control/prevent-default-event.directive';
import { HighestSeverityIssueIndicatorComponent } from './components/validation/highest-severity-issue-indicator.component';
import { InventoryValidationTooltipContentComponent } from './components/inventory-validation-tooltip-content/inventory-validation-tooltip-content.component';
import { InventoryValidationIssueComponent } from './components/inventory-validation-tooltip-content/inventory-validation-issue/inventory-validation-issue.component';
import { TextEditorDialogComponent } from './components/text-editor-dialog/text-editor-dialog.component';
import { FileLoaderDirective } from './directive/file-loader.directive';
import { AreaWithSideMenuComponent } from './components/area-with-side-menu/area-with-side-menu.component';
import { AngularSplitModule } from 'angular-split';
import { ToggleCollapseButtonComponent } from './components/toggle-collapse-button/toggle-collapse-button.component';
import { ReportsHtmlPipe } from './pipes/reports-html.pipe';
import { LoadingIndicatorDirective } from './directive/loading-indicator.directive';
import { WhitelistConverterDialogComponent } from './components/text-editor-dialog/whitelist-converter-dialog.component';
import { SearchableDropdownInputComponent } from './components/searchable-dropdown/searchable-dropdown-input.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { A11yModule } from '@angular/cdk/a11y';
import { DraggableCheckboxMenuComponent } from './components/draggable-checkbox-menu/draggable-checkbox-menu.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { EmptyResourceComponent } from './components/empty-resource/empty-resource.component';
import { MenuActionComponent } from './components/menu-action/menu-action.component';
import { SideMenuGroupHeaderComponent } from './components/side-menu-group-header/side-menu-group-header.component';
import { UpgradeDialogComponent } from './components/upgrade-dialog/upgrade-dialog.component';
import { BROWSER_SUPPORT, isSupportedBrowser } from './utils/browser.support.helper';
import { DocLinkPipe } from './pipes/doc-link.pipe';
import { MonacoEditorFormControlComponent } from './components/monaco-editor-form-control/monaco-editor-form-control.component';
import { ViewLogsDialogComponent } from './components/view-logs-dialog/view-logs-dialog.component';
import { ShowMoreItemsComponent } from './components/show-more-items/show-more-items.component';
import { ScrollToWhenSelectedDirective } from './directive/scroll-to-when-selected.directive';
import { CopyTargetsComponent } from './components/copy-targets/copy-targets.component';
import { MatFormFieldDynamicSubscriptSizingDirective } from './directive/mat-form-field-dynamic-subscript-sizing.directive';
import { MatSelectDefaultSingleSelectionIndicatorDirective } from './directive/mat-select-default-single-selection-indicator.directive';

const isBrowserSupported: boolean = isSupportedBrowser();

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MonacoEditorModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatRippleModule,
    MatSelectModule,
    ScrollingModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDividerModule,
    MatRadioModule,
    NgbTooltipModule,
    MatMenuModule,
    AngularSplitModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTableModule,
    MatSortModule,
    MatProgressBarModule,
    DragDropModule,
    A11yModule,
  ],
  declarations: [
    FilterComponent,
    HighLightPipe,
    CapitalizeFirstPipe,
    AutosizeDirective,
    EditableTextComponent,
    ScrollableDirective,
    NameFromPathPipe,
    ParentPathPipe,
    ColumnHeaderComponent,
    ValidationIndicatorComponent,
    ProjectIssuesTooltipComponent,
    HighestSeverityIssueIndicatorComponent,
    ValidationIssueComponent,
    ButtonBarComponent,
    AbbreviatePipe,
    DecimalToPercentsPipe,
    AfterViewInitDirective,
    UsageInfoComponent,
    SimpleListItemComponent,
    ValidationMessageComponent,
    ModalDialogTitleComponent,
    ControlDisableDirective,
    ExternalLinkComponent,
    LinkComponent,
    SplitButtonComponent,
    CropTenantFromKeyPipe,
    ReportsHtmlPipe,
    SingleFileInputComponent,
    FileUploadResultsDialogComponent,
    CloseModalOnNavigationDirective,
    FileUploadResultsDialogComponent,
    MenuItemWrapperComponent,
    DiffViewElementComponent,
    VersionControlFormComponent,
    CommitInfoComponent,
    FileContentDiffComponent,
    FileContentDiffEditorComponent,
    LocalChangesPopupComponent,
    MenuElementComponent,
    StopEventPropagationDirective,
    PreventDefaultEventDirective,
    InventoryValidationIssueComponent,
    InventoryValidationTooltipContentComponent,
    TextEditorDialogComponent,
    WhitelistConverterDialogComponent,
    MenuElementComponent,
    FileLoaderDirective,
    AreaWithSideMenuComponent,
    ToggleCollapseButtonComponent,
    LoadingIndicatorDirective,
    SearchableDropdownInputComponent,
    DraggableCheckboxMenuComponent,
    CustomDatePipe,
    EmptyResourceComponent,
    MenuActionComponent,
    SideMenuGroupHeaderComponent,
    UpgradeDialogComponent,
    DocLinkPipe,
    MonacoEditorFormControlComponent,
    ViewLogsDialogComponent,
    ShowMoreItemsComponent,
    ScrollToWhenSelectedDirective,
    CopyTargetsComponent,
    MatFormFieldDynamicSubscriptSizingDirective,
    MatSelectDefaultSingleSelectionIndicatorDirective,
  ],
  exports: [
    // reexported modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MonacoEditorModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDividerModule,
    MatRadioModule,
    MatIcon,
    MatAutocompleteModule,
    MatChipsModule,
    MatProgressBarModule,
    NgbTooltipModule,
    AngularSplitModule,
    A11yModule,
    // components
    FilterComponent,
    EditableTextComponent,
    ColumnHeaderComponent,
    ValidationIndicatorComponent,
    ProjectIssuesTooltipComponent,
    HighestSeverityIssueIndicatorComponent,
    ButtonBarComponent,
    ValidationIssueComponent,
    UsageInfoComponent,
    CopyTargetsComponent,
    SimpleListItemComponent,
    ValidationMessageComponent,
    ModalDialogTitleComponent,
    ExternalLinkComponent,
    LinkComponent,
    SplitButtonComponent,
    SingleFileInputComponent,
    DiffViewElementComponent,
    VersionControlFormComponent,
    CommitInfoComponent,
    FileContentDiffComponent,
    FileContentDiffEditorComponent,
    LocalChangesPopupComponent,
    MenuElementComponent,
    InventoryValidationTooltipContentComponent,
    TextEditorDialogComponent,
    AreaWithSideMenuComponent,
    ToggleCollapseButtonComponent,
    MenuItemWrapperComponent,
    MenuActionComponent,
    SideMenuGroupHeaderComponent,
    ShowMoreItemsComponent,
    // directives
    AutosizeDirective,
    ScrollableDirective,
    AfterViewInitDirective,
    ControlDisableDirective,
    CloseModalOnNavigationDirective,
    StopEventPropagationDirective,
    PreventDefaultEventDirective,
    FileLoaderDirective,
    LoadingIndicatorDirective,
    ScrollToWhenSelectedDirective,
    MatFormFieldDynamicSubscriptSizingDirective,
    MatSelectDefaultSingleSelectionIndicatorDirective,
    // pipes
    HighLightPipe,
    CapitalizeFirstPipe,
    NameFromPathPipe,
    ParentPathPipe,
    AbbreviatePipe,
    DecimalToPercentsPipe,
    CropTenantFromKeyPipe,
    ReportsHtmlPipe,
    SearchableDropdownInputComponent,
    DraggableCheckboxMenuComponent,
    CustomDatePipe,
    EmptyResourceComponent,
    DocLinkPipe,
    MonacoEditorFormControlComponent,
  ],
  providers: [
    // FIXME: Zvitkoczi 20220420: this datepipe should not be provided here, but for some reason it will be missed in pod-detailes-component
    DatePipe,
    {provide: BROWSER_SUPPORT, useValue: isBrowserSupported},
  ]
})
export class CommonModules {

  constructor(private tooltipConfig: NgbTooltipConfig) {
    tooltipConfig.container = 'body';
    tooltipConfig.tooltipClass = 'adm4-tooltip';
    tooltipConfig.closeDelay = 1500;
    tooltipConfig.openDelay = 200;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import * as _ from 'lodash';
import { first } from 'rxjs/operators';

import { DeploymentHistoryItem, DeploymentHistoryState } from '../../../../common/model/deployment-history.model';
import { CanaryRoutingOption, DeploymentAction, deploymentActionTypeToLabel } from '../../../../deployment-wizard/deployment-dialog/deployment-process.model';
import { CapitalizeFirstPipe } from '../../../../common/pipes/capitalize-first.pipe';
import { DeployedServiceItem, DeployedServiceStatus, Pod } from '../../../inventory-kubernetes-status/deployed-service.model';
import { InventoryDeploymentServiceHelper } from '../../../inventory-kubernetes-status/inventory-deployment-service.helper';
import { Revision } from '../../../../version-control/revision/revision.model';
import { InventoryDeploymentHistoryTableContextService } from '../inventory-deployment-history-table-context.service';

interface DeploymentActionDisplay {
  userKey: string;
  actionLabel: string;
  timestamp: string;
}

@Component({
  selector: 'adm4-inventory-deployment-details',
  templateUrl: './inventory-deployment-details.component.html',
  styleUrls: ['./inventory-deployment-details.component.scss'],
  animations: [
    trigger('deploymentDetailsExpand', [
      transition(':enter', [
        style({height: '0px', minHeight: '0'}),
        animate(600, style({height: '*'}))
      ]),
      transition(':leave', [
        style({height: '*'}),
        animate(600, style({height: '0px', minHeight: '0'}))
      ])
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryDeploymentDetailsComponent implements OnChanges {

  @Input() historyItem: DeploymentHistoryItem;
  @Input() deployedServices: DeployedServiceItem[];
  @Input() expanded: boolean = false;

  @Input() isKubernetesInventory: boolean = false;
  @Input() isKubernetesStatusScreen: boolean = false;
  @Input() isSecondary: boolean = false;

  @Output() openPodLogsDialog: EventEmitter<Pod> = new EventEmitter();

  inventoryServiceHelper = InventoryDeploymentServiceHelper;

  private expandedServices: string[] = [];

  public inventoryRevision: Revision | null = null;
  public projectRevision: Revision | null = null;

  public deploymentComment: string | undefined;
  public showMore: boolean = false;
  public deploymentActions: DeploymentActionDisplay[] = [];

  constructor(
      private context: InventoryDeploymentHistoryTableContextService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.historyItem) {
      this.deploymentComment = this.extractDeploymentComments();
      this.deploymentActions = this.extractDeploymentActionDisplays();
      if (this.historyItem) {
        if (!_.isNil(this.historyItem.projectCommitId) && !this.historyItem.projectDeleted) {
          this.context.getRevisionOfProjectCommit(this.historyItem.projectKey, this.historyItem.projectCommitId)
              .pipe(first())
              .subscribe((r: Revision) => this.projectRevision = r);
        }
        if (!_.isNil(this.historyItem.inventoryCommitId) && !this.historyItem.projectDeleted) {
          this.context.getRevisionOfInventoryCommit(this.historyItem.inventoryKey, this.historyItem.inventoryCommitId)
              .pipe(first())
              .subscribe((r: Revision) => this.inventoryRevision = r);
        }
      }
    }
  }

  private extractDeploymentComments(): string | undefined {
    const capitalizeFirstPipe = new CapitalizeFirstPipe();
    const deploymentComments = _.filter(this.historyItem.actions, (action: DeploymentAction) => !_.isNil(action.comment) && !_.isEmpty(action.comment))
        .map((ac: DeploymentAction) => capitalizeFirstPipe.transform(ac.action) + ': ' + ac.comment);
    return _.isEmpty(deploymentComments) ? undefined : deploymentComments.join('\n');
  }

  private extractDeploymentActionDisplays(): DeploymentActionDisplay[] {
    const actions: DeploymentAction[] = this.historyItem?.actions??[];
    return actions.map((action: DeploymentAction) => ({
      userKey: action.userKey,
      actionLabel: deploymentActionTypeToLabel(action.action),
      timestamp: action.timestamp,
    }));
  }

  toggleDeployedServiceDropdown(service: string, projectKey: string): void {
    const serviceNameWithProject = InventoryDeploymentServiceHelper.getUniqueServiceNameWithProjectKey(service, projectKey);
    if (this.expandedServices.includes(serviceNameWithProject)) {
      _.pull(this.expandedServices, serviceNameWithProject);
    } else {
      this.expandedServices.push(serviceNameWithProject);
    }
  }

  isDeployedServiceExpanded(service: string, projectKey: string): boolean {
    return this.expandedServices.includes(InventoryDeploymentServiceHelper.getUniqueServiceNameWithProjectKey(service, projectKey));
  }

  shouldDisplayCanaryRoutingInfo(canaryRoutingInfo: CanaryRoutingOption, inventoryDeploymentHistoryState: DeploymentHistoryState): boolean {
    return !_.isNil(canaryRoutingInfo) && !_.isEqual(inventoryDeploymentHistoryState, DeploymentHistoryState.PROMOTED);
  }

  checkDeployedServiceValidity(validityStatusToDisplay: string, serviceName: string): boolean | undefined {
    const targetServiceStatus = this.deployedServices?.find(deployedService => _.isEqual(deployedService.serviceKey, serviceName))?.status;
    if (_.isNil(targetServiceStatus)) {
      return _.isEqual(validityStatusToDisplay, 'neutral');
    }
    switch(validityStatusToDisplay) {
      case 'valid':
        return _.isEqual(targetServiceStatus, DeployedServiceStatus.Stable);
      case 'error':
        return _.isEqual(targetServiceStatus, DeployedServiceStatus.Failed);
      case 'warning':
        return _.isEqual(targetServiceStatus, DeployedServiceStatus.Unknown);
    }
    return undefined;
  }

  getDeploymentTargets(deploymentTargets: string[], postfix: any): string[] | undefined {
    if (_.isEmpty(deploymentTargets)) {
      return;
    }
    return _.map(deploymentTargets, (deploymentTarget) => deploymentTarget + postfix);
  }

  public deleteK8sDeployment() {
    const idToDelete = this.historyItem.deploymentHistoryId;
    const inventoryKey = this.historyItem.inventoryKey;

    this.context.deleteK8sDeployment(idToDelete, inventoryKey);
  }
}

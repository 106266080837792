import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

import { WidgetComponent } from '../widget.component';
import { PropertyWidgetContext } from '../property-widget.context';
import { keyValueToObject } from '../../common/utils/utils';
import { PropertyWidgetContextType } from '../property-widget-context-type.enum';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import * as _ from 'lodash';
import { ParameterHelper } from '../parameter.helper';


/**
 * Converts an object with a single key and value, by putting the single key of the original object into a property called `key` in the new object
 * and by putting the single value of the original object into a property called `value` in the new object.<br/>
 * If the original object has multiple properties, only one will be transferred, and you can't control which one.<br/>
 * # Input
 * ```
 * { myKey: 'myValue' }
 * ```
 * # Output
 * ```
 * { key: 'myKey', value: 'myValue' }
 * ```
 * @param input
 */
const objectToKeyValueFields = (input: Record<string, string>): KeyValue<string, string> => {
  const entries = Object.entries(input);
  return {key: entries[0][0], value: entries[0][1]};
};

@Component({
  selector: 'adm4-key-value-widget',
  template: `
    <div class="migration" *ngIf="needsMigration">
      <div class="msg-container" *ngIf="shouldShowWarning()">
        <div class="msg-icon">
          <mat-icon>report_problem</mat-icon>
        </div>
        <p class="msg-text">This property has to be migrated to a new format.
          Please verify that the key-value pairs are properly migrated. After saving the pattern, the old value will not be available anymore.
          If you are using git, you can still view the value on the Publish to Git screen.</p>
      </div>
      <p class="label"><span>Legacy value</span><mat-icon matRipple matRippleCentered (click)="copyOriginalValue()">content_copy</mat-icon></p>
      <pre class="original">{{originalValue}}</pre>
      <div class="migrate-action flex-center-xy">
        <button class="admn4-button-ellipse-blue flex-center-xy" *ngIf="shouldShowMigrateBtn()" type="button" (click)="confirmMigrate()">
          <mat-icon>arrow_downward</mat-icon>
          <span>Migrate</span>
        </button>
      </div>
    </div>
    <div class='entries' *ngIf="shouldShowEntries()">
      <div class="header-row" *ngIf="shouldShowEntryColHeaders()">
        <span class="header-cell">Key</span>
        <span class="header-cell">Value</span>
        <!-- placeholder for the remove button, for spacing-->
        <span class="remove-placeholder" *ngIf="shouldShowRemoveEntry()"></span>
      </div>
      <ng-container *ngFor="let entry of entries; index as i; trackBy: trackByFn">
        <div class="entry-row">
          <input [placeholder]="readOnly ? '' : PLACEHOLDER_KEY" class="admn4-text-input form-control"
                 [(ngModel)]="entry.key" [disabled]="readOnly" (input)="entryChanged()">
          <input [placeholder]="readOnly ? '' : PLACEHOLDER_VALUE" class="admn4-text-input form-control"
                 [(ngModel)]="entry.value" [disabled]="readOnly" (input)="entryChanged()">
          <button type="button" class="remove flex-center-xy" *ngIf="shouldShowRemoveEntry()" (click)="removeEntry(i)"><mat-icon>highlight_remove</mat-icon></button>
        </div>
      </ng-container>
      <p class="default-label" *ngIf="shouldShowEmptyLabel()">Empty value.</p>
    </div>
    <div class="entry-actions" *ngIf="shouldShowEntriesActions()">
      <button class="add-entry admn4-button-text" type="button" (click)="addNewEntry()">
        <mat-icon>add_circle</mat-icon><span>Add more</span>
      </button>
    </div>
    <ng-container *ngIf="shouldShowDefaultValue()">
      <p class="default-label">Default value:</p>
      <div class="default-entries">
        <ng-container *ngFor="let entry of defaultEntries; index as i; trackBy: trackByFn">
          <div class="entry-row">
            <span class="text-ellipsis">{{entry.key}}</span>
            <span class="text-ellipsis">{{entry.value}}</span>
          </div>
        </ng-container>
      </div>
      <div class="default-actions">
        <button class="override-entry admn4-button-text" type="button" (click)="overrideDefault()">
          <mat-icon>edit</mat-icon><span>Override default value</span>
        </button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./key-value-property.component.scss'],
})
export class KeyValuePropertyComponent extends WidgetComponent implements OnInit {

  public readonly PLACEHOLDER_KEY = 'Please enter a key...';
  public readonly PLACEHOLDER_VALUE = 'Please enter a value...';

  /**
   * Shows whether the currently saved data is in legacy format or not,
   * or in other words, if it needs migration.<br/>
   * Stays true even if the user has done the migration and/or has edited the data in the inputs.
   */
  public needsMigration = false;
  /** Shows whether the use has done the migration or not. */
  public wasMigrated = false;
  public originalValue: string | undefined;
  public defaultMode = false;
  public defaultEntries: Array<KeyValue<string, string>>;

  public entries: Array<KeyValue<string, string>>;

  private separators: string[];
  private switchedSeparators: string[];
  private DEFAULT_SEPARATORS = ['->',': ',' = ', ':', '='];
  private DEFAULT_SWITCHED_SEPARATORS = ['->'];

  constructor(
      private pwc: PropertyWidgetContext,
      private cdRef: ChangeDetectorRef,
      private modals: ModalNotificationService,
  ) {
    super();
  }

  public trackByFn(index, _entry: KeyValue<string, string>) {
    return index;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.separators = ParameterHelper.getSpecificParameterValue(ParameterHelper.SEPARATORS, this.parameters, this.propertyType) || this.DEFAULT_SEPARATORS;
    this.switchedSeparators = ParameterHelper.getSpecificParameterValue(ParameterHelper.SWITCHED_SEPARATORS, this.parameters, this.propertyType) || this.DEFAULT_SWITCHED_SEPARATORS;
  }

  override writeValue(value: any): void {
    super.writeValue(value);
  }

  override registerOnChange(fn: any): void {
    super.registerOnChange(fn);
  }

  override initFinished() {
    super.initFinished();
    if (this.group) {
      this.applyConfigAndValue(this.group.value);
    } else {
      console.error('KeyValuePropertyComponent#initFinished: form group is not available');
    }
  }

  private applyConfigAndValue(formValue): void {
    // null of undefined can happen on the variables screen
    const newValue = (formValue[this.widgetProperty.propertyKey])??[];
    if (!Array.isArray(newValue)) {
      if (newValue && !(typeof newValue === 'string' && newValue.startsWith('var://'))) {
        console.warn(`KeyValuePropertyComponent: unexpected value for property '${this.widgetProperty?.propertyKey}':`, newValue);
      }
      this.needsMigration = true;
      this.originalValue = JSON.stringify(newValue);
      this.entries = [{key: '', value: ''}];
      return;
    }
    this.needsMigration = this.calculateNeedsMigration(newValue);
    if (this.needsMigration) {
      this.wasMigrated = false;
      this.originalValue = newValue.map((row: any) => row.toString()).join('\n');
      this.entries = [{key: '', value: ''}];
    } else {
      if (newValue.length === 0 && this.defaultValue !== null && this.defaultValue !== undefined) {
        this.applyDefaultValue();
        this.entries = [{key: '', value: ''}];
      } else {
        this.needsMigration = false;
        this.defaultMode = false;
        this.originalValue = undefined;
        this.entries = newValue.map((og: Record<string, string>) => objectToKeyValueFields(og));
      }
    }
    this.cdRef.detectChanges();
  }

  private applyDefaultValue(): void {
    this.defaultMode = true;
    if (Array.isArray(this.defaultValue)) {
      this.defaultEntries = this.defaultValue.map((defaultItem) => objectToKeyValueFields(defaultItem));
    } else {
      console.warn('KeyValuePropertyComponent#applyDefaultValue: unprocessable default value: ' + JSON.stringify(this.defaultValue));
      this.defaultEntries = [{key: '', value: this.defaultValue??''}];
    }
  }

  public confirmMigrate(): void {
    if (!this.needsMigration) {
      return;
    }
    this.modals.openConfirmDialog(
        {
          headerTitle: 'Warning',
          title: 'Legacy value will be replaced',
          description: 'Please make sure that each key-value pair is properly migrated into the new fields. <br/>' +
            'There could be cases where the key and the value were used in reverse order (usually happened with the -> separator), in such cases the migration swaps the key-value position.<br/>' +
            'Please note that after saving the pattern, the legacy value will not be available anymore (If you are using git, you can still view it on Publish to Git screen).',
        }, {
          confirmButtonText: 'Migrate',
          cancelButtonText: 'Cancel'
        }
    ).afterClosed().pipe(first()).subscribe((doMigrate: boolean) => {
      if (doMigrate) {
        this.migrate();
      }
    });
  }

  private migrate(): void {
    this.entries = KeyValuePropertyComponent.migrateLegacyValue(this.originalValue, this.separators, this.switchedSeparators);
    this.wasMigrated = true;
    this.calculateValueFromEntries();
  }

  private isSplitSeparator(actualSeparator: string) {
    return this.switchedSeparators.includes(actualSeparator);
  }

  public addNewEntry(): void {
    this.entries = [...this.entries, {key: '', value: ''}];
    this.calculateValueFromEntries();
  }

  public overrideDefault(): void {
    this.defaultMode = false;
    this.entries = [{key: '', value: ''}];
    // need to calculate and submit the form value, otherwise cloned formValue passed in again with the empty state, which un-does the override
    this.calculateValueFromEntries();
  }

  private setControlValue(value: any) {
    const control: AbstractControl = this.group.controls[this.widgetProperty.propertyKey];
    // order is important due too PatternMainComponent.onPropertyHasChanged
    control.markAsDirty();
    control.setValue(value);
  }

  public entryChanged() {
    this.calculateValueFromEntries();
  }

  public removeEntry(i: number): void {
    this.entries.splice(i, 1);
    this.calculateValueFromEntries();
  }

  private calculateValueFromEntries(): void {
    const newValue: Array<Record<string, string>> = this.entries.map((entry: KeyValue<string, string>) => keyValueToObject(entry));
    this.setControlValue(newValue);
    if (this.entries.length === 0 && this.defaultValue) {
      this.applyDefaultValue();
    }
  }


  private calculateNeedsMigration(valueArray: Array<string | {}>): boolean {
    return valueArray.some((value: string | {}) => typeof value === 'string');
  }

  public get contextType(): string {
    switch (this.pwc.type) {
      case PropertyWidgetContextType.DEPLOY:
        return 'DEPLOY';
      case PropertyWidgetContextType.ISSUES:
        return 'ISSUES';
      case PropertyWidgetContextType.PUBLISH:
        return 'PUBLISH';
      case PropertyWidgetContextType.PATTERN_EDITOR:
        return 'PATTERN_EDITOR';
      case PropertyWidgetContextType.CREATE_VARIABLE:
        return 'CREATE_VARIABLE';
    }
  }

  public copyOriginalValue(): void {
    navigator.clipboard.writeText(this.originalValue??'');
  }

  public shouldShowEntries(): boolean {
    return !(this.defaultMode || (this.needsMigration && !this.wasMigrated));
  }

  public shouldShowRemoveEntry(): boolean {
    return !(this.readOnly || this.needsMigration);
  }

  public shouldShowEntriesActions(): boolean {
    return !(this.readOnly || this.defaultMode || this.needsMigration);
  }

  public shouldShowMigrateBtn(): boolean {
    return !(this.readOnly || !this.needsMigration);
  }

  public shouldShowDefaultValue(): boolean {
    return !this.readOnly && this.defaultMode;
  }

  public shouldShowWarning(): boolean {
    return !(this.readOnly && this.needsMigration);
  }

  public shouldShowEmptyLabel(): boolean {
    return !this.entries || this.entries.length === 0;
  }

  public shouldShowEntryColHeaders(): boolean {
    return !(!this.entries || this.entries.length === 0);
  }

  override isMultiValue() {
    return true;
  }

  isPropertyMultiValue(): boolean {
    return this.maxAllowed > 1;
  }

  /**
   *
   * @param legacyValue unmigrated value of the property
   * @param separators separators that should be considered for splitting to key and value
   * @param switchedSeparators separators that used to split the line, but the order of key-value has to be switched
   * @private
   */
  private static migrateLegacyValue(legacyValue: string | undefined, separators: string[], switchedSeparators: string[] = []): KeyValue<string, string>[] {
    if(!legacyValue) return [];
    return (legacyValue).trim().split('\n').map((line: string) => {
      let newEntry = {key: '', value: line};
      _.forEach(separators, (actualSeparator) => {
        if (line.includes(actualSeparator)) {
          const [ key, ...value] = line.split(actualSeparator);
          newEntry = switchedSeparators.includes(actualSeparator) ? {key: value.join(actualSeparator), value: key} : {key: key, value: value.join(actualSeparator)};
          return false;
        }
        return;
      });
      return {key: newEntry.key.trim(), value: newEntry.value.trim()};
    });
  }
}

export const patternReferencePrefix = 'pattern://';
export const variableReferencePrefix = 'var://';
export const resourceReferencePrefix = 'res://';
export const secretReferencePrefix = 'secret://';
export const inventorySecretResourceReferencePrefix = 'inv-res-secret://';
export const inventoryResourceReferencePrefix = 'inv-res://';
export const k8sSecretReferencePrefix = 'k8s-secret://';
export const k8sSecretFileReferencePrefix = 'k8s-secret-file://';

export const isVariableRef = (value: unknown): value is string => {
  return typeof value === 'string' && value.startsWith(variableReferencePrefix);
};

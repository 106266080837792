import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Diff } from '../../../../common/model/publish-changes/diff.model';
import { PatternNotesDiffData } from '../pattern-diff-data.model';
import { UntypedFormGroup } from '@angular/forms';
import { FormHelper } from '../../../../common/helpers/form.helper';

@Component({
  selector: 'adm4-pattern-notes-diff',
  template: `
    <adm4-diff-view-element [remoteCommitInfo]='patternNotesDiff.remote.commitInfo'
                            [localChangeInfo]='patternNotesDiff.local.changeInfo'
                            [localFallbackLabel]='patternNotesDiff.local.fallbackLabel'
                            [currentUsername]='currentUsername'
                            [showOnlyDifferences]='showOnlyDifferences'>
      <div remote>
        <adm4-pattern-notes [form]='cloneForm(patternNotesDiff.remote.form)'
                            [fcName]='patternNotesDiff.remote.formControlName'
                            [readOnly]='true'></adm4-pattern-notes>
      </div>
      <div local>
        <adm4-pattern-notes [form]='cloneForm(patternNotesDiff.local.form)'
                            [fcName]='patternNotesDiff.local.formControlName'
                            [readOnly]='true'></adm4-pattern-notes>
      </div>
    </adm4-diff-view-element>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternNotesDiffComponent {
  @Input() patternNotesDiff: Diff<PatternNotesDiffData>;
  @Input() showOnlyDifferences: boolean;
  @Input() currentUsername: string | null;

  /**
   * As form in store is frozen and should not be changed in order to pass it down to actual display component we have to clone it
   * Sure it could have been done better if low level component was not coupled to form, but it is
   * @param form
   */
  cloneForm(form: UntypedFormGroup): UntypedFormGroup {
    return FormHelper.cloneForm(form);
  }
}

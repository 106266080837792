import { Pattern } from './pattern.model';
import { Dictionary } from '../model/reducer';
import { PatternType } from '../plugins/pattern-type.model';
import * as _ from 'lodash';
import { PropertyType } from '../plugins/property-type.model';
import { StandardPatternCategory } from '../plugins/standard-pattern-category.enum';
import { PatternListData } from './pattern-list-data.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class PatternHelper {
  static convertPatternsListToMap(patterns: Pattern[]): Map<string, Pattern> {
    return patterns.reduce((patternsMap: Map<string, Pattern>, pattern: Pattern) => {
      return patternsMap.set(pattern.patternId, pattern);
    }, new Map());
  }

  static convertPatternListToMap(patterns: PatternListData[]): Map<string, PatternListData> {
    return patterns.reduce((patternsMap: Map<string, PatternListData>, pattern: PatternListData) => {
      return patternsMap.set(pattern.pattern.patternId, pattern);
    }, new Map());
  }
  /**
   * Converts list of patternTypes or propertyTypes into dictionary
   * @param {T[]} types
   * @returns {Dictionary<T extends PatternType | PropertyType>}
   */
  static convertTypesListToDictionary<T extends PatternType | PropertyType>(types: T[]): Dictionary<T> {
    return  _.mapKeys(types, type => type.className);
  }

  static isUnknownPattern(patternType: PatternType | undefined): boolean {
    return _.isNil(patternType);
  }

  static isStandardPattern(pattern: Pattern): boolean {
    return _.startsWith(pattern.className, 'ch.nevis.admin.v4');
  }

  static isExperimentalStandardPattern(pattern: Pattern, patternType: PatternType | undefined): boolean {
    return !_.isNil(patternType) && this.isStandardPattern(pattern) && patternType.categories.includes(StandardPatternCategory.Experimental);
  }

  static resolvePatternTypeIconClass(pattern: Pattern, patternType: PatternType | undefined): string {
    if (this.isUnknownPattern(patternType)) {
      return 'unknown-pattern-icon';
    } else if (this.isExperimentalStandardPattern(pattern, patternType)) {
      return 'experimental-standard-pattern-icon';
    } else if (this.isStandardPattern(pattern)) {
      return 'standard-pattern-icon';
    } else {
      return 'custom-pattern-icon';
    }
  }

  static resolvePatternTypeHeaderClass(pattern: Pattern, patternType: PatternType | undefined): string {
    return !this.isUnknownPattern(patternType) && this.isStandardPattern(pattern) && !this.isExperimentalStandardPattern(pattern, patternType) ? 'nevis-green-header' : 'carbon-header';
  }

  static getPatternsLabels(patternsView: Observable<Map<string, Pattern>>) {
    return patternsView.pipe(
      map((patterns: Map<string, Pattern>) => Array.from(patterns.values())),
      map((patternList: Pattern[]) => {
        return patternList.reduce((labelList: string[], pattern) => {
          if (!pattern.label || labelList.includes(pattern.label)) {
            return labelList;
          } else {
            return [...labelList, pattern.label];
          }
        }, []);
      }));
  }
}

import { PluginModel } from '../../plugins/plugin.model';
import * as _ from 'lodash';

export class CreatePatternHelper {

  static getAllCategoriesFromPattern(plugins: PluginModel[]): string[] {
    const categoryList: string[] = _(plugins).flatMap((plugin: PluginModel) => plugin.category).uniq().value();
    return _.sortBy(categoryList);
  }

  static getIntersectionOfFilters(filter1: PluginModel[], filter2: PluginModel[]): PluginModel[] {
    if (filter1 && filter2) {
      return filter1.filter(f => filter2.indexOf(f) > -1);
    }
    return [];
  }
}

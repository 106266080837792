import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { singularPlural } from '../project-summary.helper';
import { countPatternsWithStatus } from '../project-status.helper';
import { LocalStatus } from '../../../version-control/meta-info.model';
import { PatternListData } from '../../../patterns/pattern-list-data.model';
import { ProjectMeta } from '../../project.model';

@Component({
  selector: 'adm4-project-summary-patterns',
  template: `
    <adm4-project-summary-section [title]='"Patterns"'
                                  [summary]='patternSummary'>
      <ul class='pattern-stats-list'>
        <li *ngIf='displayPatternsModified'>{{patternsModified}}</li>
        <li *ngIf='displayPatternsAdded'>{{patternsAdded}}</li>
        <li *ngIf='displayPatternsDeleted'>{{patternsDeleted}}</li>
      </ul>
    </adm4-project-summary-section>`,
  styleUrls: ['./project-summary-patterns.component.scss', '../project-summary-section.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryPatternsComponent {
  @Input() patternList: PatternListData[];
  @Input() projectStatusModel: ProjectMeta;

  get patternSummary(): string {
    const patternCount = !!this.patternList ? this.patternList.length : 0;
    const what = singularPlural(patternCount, 'pattern', 'patterns');
    return `This project contains ${patternCount} ${what}.`;
  }

  get displayPatternsModified(): boolean {
    const count = countPatternsWithStatus(this.projectStatusModel, LocalStatus.Modified);
    return count > 0;
  }

  get patternsModified(): string {
    const count = this.modifiedPatternCount();
    return `${count} modified`;
  }

  private modifiedPatternCount() {
    return countPatternsWithStatus(this.projectStatusModel, LocalStatus.Modified);
  }

  get displayPatternsDeleted(): boolean {
    const count = this.deletedPatternCount();
    return count > 0;
  }

  get patternsDeleted(): string {
    const count = this.deletedPatternCount();
    return `${count} deleted`;
  }

  private deletedPatternCount() {
    return countPatternsWithStatus(this.projectStatusModel, LocalStatus.Deleted);
  }

  get displayPatternsAdded(): boolean {
    const count = this.addedPatternCount();
    return count > 0;
  }

  get patternsAdded(): string {
    const count = countPatternsWithStatus(this.projectStatusModel, LocalStatus.Added);
    return `${count} added`;
  }

  private addedPatternCount() {
    return countPatternsWithStatus(this.projectStatusModel, LocalStatus.Added);
  }
}

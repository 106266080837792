/**
 * Data interface that reflects common change information for each versioned data
 */
export interface MetaInfo {
  localStatus: LocalStatus;
  localAuthor?: string;
  localAuthors?: string[];
  localDate?: string;
  commitId?: string;
  remoteDate?: string;
}

export enum LocalStatus {
  Modified = 'MODIFIED',
  Unmodified = 'UNMODIFIED',
  Added = 'ADDED',
  Deleted = 'DELETED'
}

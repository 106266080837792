import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DeletePattern } from '../../model/pattern';
import { Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { PatternInstance } from '../../patterns/pattern-instance.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { DirtyFormGuardConnectorService } from '../../common/services/dirty-form-guard-connector.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'adm4-pattern-menu',
  template: `
    <button type="button" class="imgContainer" [matMenuTriggerFor]="menu" aria-label="menu">
      <i class="material-icons">{{icon}}</i>
    </button>
    <mat-menu class="overflow-menu" #menu="matMenu" [xPosition]="'before'">
      <button *ngIf='!readOnly' type="button" mat-menu-item (click)="renamePattern()">
        <mat-icon>mode_edit</mat-icon>
        <span class="menu-text">Rename Pattern</span>
      </button>
      <button type="button" mat-menu-item
              *ngIf='!readOnly'
              [matMenuTriggerFor]="labelling"
              (menuOpened)="labelMenu.setFocusOnInput()"
              (menuClosed)='labelMenu.closeLabelOptionsDropdown()'>
        <mat-icon>local_offer</mat-icon>
        <span class="menu-text">Label Pattern</span>
      </button>
      <mat-menu #labelling>
        <adm4-label-menu-option
                #labelMenu
                [selectedPatternIds]='[pattern.patternId]'
                [projectKey]='projectKey'
                [labels]='getPatternLabel()'
                [allPatternLabels]='allPatternLabels'
                (triggerMenuClose)='closeMenu()'>
        </adm4-label-menu-option>
      </mat-menu>
      <button type="button" mat-menu-item (click)="copyPattern()">
        <mat-icon>content_copy</mat-icon>
        <span class="menu-text">Copy Pattern</span>
      </button>
      <button *ngIf='!readOnly' type="button" mat-menu-item (click)="duplicatePattern()">
        <mat-icon>file_copy</mat-icon>
        <span class="menu-text">Duplicate Pattern</span>
      </button>
      <button *ngIf='!readOnly' type="button" mat-menu-item (click)="deletePattern()">
        <mat-icon>delete_forever</mat-icon>
        <span class="menu-text">Remove Pattern</span>
      </button>
    </mat-menu>`,
  styleUrls: ['../../common/styles/component-specific/overflow-menu.scss']
})
export class PatternMenuComponent {
  @Input() pattern: PatternInstance;
  @Input() readOnly: boolean;
  @Input() icon;
  @Input() projectKey: string;
  @Input() allPatternLabels: string[];
  @Output() copy = new EventEmitter<void>();
  @Output() rename = new EventEmitter<void>();
  @Output() duplicate = new EventEmitter<void>();
  @ViewChild(MatMenuTrigger, {static: false}) menuTrigger: MatMenuTrigger;

  @ViewChild('selection', {static: false}) selectionRef: ElementRef;

  constructor(
    private modalNotificationService: ModalNotificationService,
    private store$: Store<AppState>,
    private formGuardConnectorService: DirtyFormGuardConnectorService
  ) {
  }

  copyPattern() {
    this.copy.emit();
  }

  renamePattern() {
    this.rename.emit();
  }

  duplicatePattern() {
    this.duplicate.emit();
  }

  deletePattern() {
    this.formGuardConnectorService.doIfConfirmed(() => this.openDeletePatternDialog());
  }

  openDeletePatternDialog() {
    this.modalNotificationService.openConfirmDialog({
      headerTitle: `Warning`,
      title: `Remove ${this.pattern.name}`,
      description: `You are removing the "${this.pattern.name}" pattern. The removal is irreversible. It can't be undone.`
    }, {
      confirmButtonText: 'Remove'
    }).afterClosed().subscribe((confirmed?: boolean) => {
      if (confirmed === true) {
        this.store$.dispatch(new DeletePattern([this.pattern]));
      }
    });
  }

  closeMenu(): void {
    this.menuTrigger.closeMenu();
  }

  getPatternLabel(): string[] {
    return this.pattern.label ? [this.pattern.label] : [];
  }

  getPatternId(): string[] {
    return this.pattern.patternId ? [this.pattern.patternId] : [];
  }
}

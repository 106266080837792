<form [formGroup]="form" name="form" (ngSubmit)='onFormSubmit.next(form.value)'>
  <mat-tab-group [mat-stretch-tabs]='false'>
    <mat-tab label="Basic Settings">
      <div class="content-container create-item-container" cdkFocusInitial>
        <div class='form-group'>
          <label class='input-label'>
            <span>New branch* </span>
              <span class='info-icon-container'>
              <em class="fa fa-info-circle help-icon" aria-hidden="true"
                  title='A new branch with the current project configuration will be created in the Git repository and then imported into a new project'></em>
              </span>
          </label>
          <div class='item-key-input-wrapper'>
            <input class="form-control admn4-text-input"
                   placeholder=' '
                   name="branchName"
                   [formControlName]='BRANCH_NAME_FORM_CONTROL_NAME'
                   required/>
              <div class="validation-message-container">
                <adm4-validation-message *ngIf='shouldShowBranchNameErrorMessage("pattern")' [isError]='true' [message]='ERROR_INVALID_BRANCH_NAME'></adm4-validation-message>
                <adm4-validation-message *ngIf='shouldShowBranchNameErrorMessage("required")' [isError]='true' [message]='ERROR_BRANCH_NAME_REQUIRED'></adm4-validation-message>
                <adm4-validation-message *ngIf='shouldShowBranchNameErrorMessage("branchNameAlreadyExists")' [isError]='true' [message]='ERROR_BRANCH_NAME_EXISTS'></adm4-validation-message>
              </div>
          </div>
        </div>
        <div class='form-group'>
          <label class='input-label'>Project key*</label>
          <div [ngClass]="displayTenant ? 'item-key-container' : ' '">
            <div class='tenant-key-wrapper' *ngIf='displayTenant'>
              <span>{{selectedTenantKey}} - </span>
              <div class='validation-message-container'></div>
            </div>
            <div class='item-key-input-wrapper'>
                <input class="form-control admn4-text-input"
                       placeholder=' '
                       name="projectKey"
                       [formControlName]='PROJECT_KEY_FORM_CONTROL_NAME'
                       required/>
              <div class="validation-message-container">
                <adm4-validation-message *ngIf='shouldShowProjectKeyErrorMessage("pattern")' [isError]='true' [message]='ERROR_INVALID_PROJECT_KEY'></adm4-validation-message>
                <adm4-validation-message *ngIf='shouldShowProjectKeyErrorMessage("required")' [isError]='true' [message]='ERROR_PROJECT_KEY_REQUIRED'></adm4-validation-message>
                <adm4-validation-message *ngIf='shouldShowProjectKeyErrorMessage("projectKeyExists")' [isError]='true' [message]='projectForTenantExistsError'></adm4-validation-message>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Advanced Settings">
      <div class="content-container create-item-container">
        <div class='form-group'>
          <adm4-version-control-form [predefinedVersionControl]="predefinedVersionControlData()"
                                     (formReady)='addVersionControlForm($event)'
                                     (initialBranches)='addRemoteBranchNameValidation($event)'
          ></adm4-version-control-form>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div mat-dialog-actions>
    <adm4-button-bar [isSubmitDisabled]="!canSave"
                     [submitButtonText]="BUTTON_BRANCH_PROJECT"
                     (cancelClicked)='cancelClicked.emit()'></adm4-button-bar>
  </div>
</form>

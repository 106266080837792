import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HelpConnectService } from '../shared/scroll-helper/help-connect.service';
import { UsageInfo } from '../common/components/usage/usage-info';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { Mixin } from '../common/decorators/mixin.decorator';
import { CollapsiblePaneMixin, ICollapsiblePaneMixin } from '../common/mixins/collapsible-pane.mixin';
import { catchError, filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { PatternListData } from './pattern-list-data.model';
import { MatTabGroup } from '@angular/material/tabs';
import { SafeHtml } from '@angular/platform-browser';
import { PatternService } from './pattern.service';
import { PatternViewModel, PatternViewResponse } from '../common/model/pattern-view.model';
import { PatternHierarchyContext } from './pattern-hierarchy/pattern-hierarchy-context';
import { LocalStorageHelper } from '../common/helpers/local-storage.helper';
import { localStorageHelpTabIndex } from '../common/constants/local-storage-keys.constants';
import { ValidationStatusHelper } from '../common/helpers/validation-status.helper';
import { ValidationIssue } from '../common/model/validation-status.model';
import { NavigationService } from '../navbar/navigation.service';
import { PatternHierarchyTreeComponent } from './pattern-hierarchy/pattern-hierarchy-tree.component';
import { PatternCopyTarget } from './pattern.model';
import { Maybe } from '../common/utils/utils';


/**
 * This component displays the help section. It gets the HTML from the backend and injects it into the template.
 * It can scroll to the section which is selected.
 */
@Component({
  selector: 'adm4-pattern-help',
  template: `
    <div class='full-height-flex help-wrapper-container'>
      <adm4-column-header styleClass="light-header" class='header-title'>
        {{headerTitle}}
        <adm4-toggle-collapse-button side='right'
                                     [isCollapsed]='false'
                                     (click)='setCollapse(true)'
        ></adm4-toggle-collapse-button>
      </adm4-column-header>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <mat-tab-group class='full-height mat-tabs-full-height' #tabGroup [mat-stretch-tabs]='false' animationDuration='0ms'
                         (selectedTabChange)="tabChanged()">
            <mat-tab label="Usage">
              <div class="full-height help-container">
                <div class="help-heading">
                  <adm4-usage-info
                          [usageData]='usageData'
                          [basePath]="'../'" type='Pattern'></adm4-usage-info>
                </div>
                <div class="help-heading">
                  <adm4-copy-targets [copyTargets]="copyTargets"></adm4-copy-targets>
                </div>
                <div class="help-heading auth-flow-heading" (click)='onAuthFlowSectionChange()'>
                  <div class='title-container'>
                    <h1 class='main-title'>Authentication flow</h1>
                    <span class='info-icon-container' [ngbTooltip]='authFlowPopover' placement='left'>
                      <i class="fa fa-question-circle help-icon cursor-default" aria-hidden="true" title=''></i>
                      <ng-template #authFlowPopover>
                        This section shows how the selected pattern is involved in the authentication flow. One pattern can belong to multiple realms.
                        To see how the selected pattern is used by the other authentication realms, please use the Referenced Realm dropdown.
                      </ng-template>
                    </span>
                  </div>
                  <mat-icon class='size-16 cursor-pointer'>{{isAuthFlowCollapsed ? 'expand_more' : 'expand_less'}}</mat-icon>
                </div>
                <div *ngIf='shouldDisplayAuthTree' class='auth-flow-content-wrapper'>

                  <ng-container *ngIf='authFlowWarnings?.length > 0'>
                    <div *ngFor='let warning of authFlowWarnings' class='depth-limit-warning-wrapper'>
                      <mat-icon class='warning-icon'>report_problem</mat-icon>
                      {{warning.detail}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf='hasPatternAuthFlowView(patternViews$ | async);else noAuthFlowLoaded'>
                    <div class='context-selection-wrapper'>
                      <label for='target-context'>Referenced realm:</label>
                      <mat-form-field *ngIf='hasMultipleContext(patternViews$ | async); else singleContext' class='full-width'>
                        <mat-select id='target-context'
                                    placeholder="Please select"
                                    [(ngModel)]='selectedContext'
                                    (ngModelChange)='contextChange()'
                                    [disableOptionCentering]='true'>
                          <mat-option *ngFor='let context of patternViews$ | async' [value]='context'>
                            {{context.label}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <ng-template #singleContext>
                        <div class='single-context'>{{getPatternViewLabel(patternViews$ | async)}}</div>
                      </ng-template>
                    </div>
                    <div class='fullscreen-view-wrapper'>
                      <a class='fullscreen-link' [href]='graphViewLink' target='_blank'>
                        <mat-icon class='new-tab-icon size-16'>open_in_new</mat-icon>
                        <span>Full graph</span>
                      </a>
                    </div>
                    <div #treeViewScrollArea class='scroll-area-tree-view'>
                      <adm4-pattern-hierarchy-tree *ngIf='selectedContext'
                                                        #hierarchyTreeComponent
                                                        [patterns]="patterns"
                                                        [selection]='patternId'
                                                        [projectKey]='projectKey'
                                                        [scrollArea]='treeViewScrollArea'
                                                        [shouldRenderAuthTree]='shouldDisplayAuthTree'>
                      </adm4-pattern-hierarchy-tree>
                    </div>
                  </ng-container>
                </div>
                <ng-template #noAuthFlowLoaded> 
                  <div class="no-auth-flow-text">
                    <h2>{{resolveNoAuthFlowText(authTreeRenderInProgress | async)}}</h2>
                  </div>
                </ng-template>
              </div>
            </mat-tab>
            <mat-tab label="Help">
              <div class="full-height help-container">
                <div class="help-heading">
                  <h1 class='main-title'>Help</h1>
                </div>
                <div adm4Scrollable class='scroll-area help-info'
                     [selectedClass]='htmlClass$ | async'
                     [removeSelector]='"section"'
                     [innerHtml]='helpData'
                     #scrollArea
                ></div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../common/styles/component-specific/help.scss', '../common/styles/component-specific/help-info.scss', './pattern-help.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Mixin([CollapsiblePaneMixin])
export class PatternHelpComponent implements ICollapsiblePaneMixin, OnInit, AfterViewInit, OnChanges, OnDestroy {
  headerTitle = 'Usage & Help';
  @Input() helpData: SafeHtml;
  @Input() usageData: UsageInfo[];
  @Input() copyTargets: PatternCopyTarget[];
  @Input() patternId: Maybe<string>;
  @Input() patterns: PatternListData[];
  @Input() selection: string;
  @Input() projectKey: string;
  @Input() isCollapsed: boolean;
  @Output() collapse: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('scrollArea', {static: false}) scrollArea: ElementRef<HTMLElement>;
  @ViewChild('treeViewScrollArea', {static: false}) treeViewScrollArea: ElementRef<HTMLElement>;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('hierarchyTreeComponent') hierarchyTreeComponent: PatternHierarchyTreeComponent;
  selectedContext: PatternViewModel | undefined;
  contextHistory: PatternViewModel[] = [];
  patternViews$: Observable<PatternViewModel[]>;
  loadPatternHierarchy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  htmlClass$: Observable<string | null>;
  _authTreeRenderInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  authTreeRenderInProgress: Observable<boolean> = this._authTreeRenderInProgress.asObservable();

  /**
   * Implemented by CollapsiblePaneMixin
   */
  setCollapse: (isCollapsed: boolean) => void;
  readonly USAGE_TAB_INDEX = 0;
  readonly HELP_TAB_INDEX = 1;
  isAuthFlowCollapsed: boolean = true;
  authFlowWarnings: ValidationIssue[] = [];

  readonly INCOMPLETE_VIEW_WARNING = 'issue.code.pattern.view.incomplete';
  readonly AUTH_FLOW_LOADING_TEXT = 'Loading authentication flow, please wait...';
  readonly NO_AUTH_FLOW_TEXT = 'No authentication flow to display for the selected pattern. It is either not part of the authentication flow or referenced by any Authentication Realm pattern.';
  private destroyed$: Subject<boolean> = new Subject();

  constructor(private helpConnectService: HelpConnectService,
              private patternHierarchyContext: PatternHierarchyContext,
              private patternService: PatternService,
              private cdr: ChangeDetectorRef,
              private navigationService: NavigationService) {
  }

  ngOnInit(): void {
    this.htmlClass$ = this.helpConnectService.htmlClass$;
    this.htmlClass$.pipe(
      filter((helpClass: string | null) => !_.isNil(helpClass)),
      takeUntil(this.destroyed$)
    ).subscribe(() => {
      if (this.isUsageTabSelected) {
        this.tabGroup.selectedIndex = this.HELP_TAB_INDEX;
      }
      this.setCollapse(false);
    });
    this.patternViews$ = this.loadPatternViews();
  }

  ngAfterViewInit(): void {
    const preSelectedTabIndex = LocalStorageHelper.retrieve(localStorageHelpTabIndex);
    this.tabGroup.selectedIndex = _.isNil(preSelectedTabIndex) || _.isNaN(Number(preSelectedTabIndex)) ? this.HELP_TAB_INDEX : Number(preSelectedTabIndex);
    // Trigger a change detection to avoid ExpressionChangedAfterItHasBeenCheckedError due to the tabIndex change
    this.cdr.detectChanges();
    this.triggerAuthFlowLoadIfNeeded();
    this.handleAuthFlowTreeRendering();
  }

  /**
   * Keep the authTreeRenderInProgress false if there's no pattern view for the selected pattern
   * OR there is a patternView but the hierarchy tree hasn't been rendered
   * otherwise set authTreeRenderInProgress to true to display loading text instead of no auth flow text.
   */
  handleAuthFlowTreeRendering(): void {
    this.patternViews$.pipe(
      takeUntil(this.destroyed$),
      map(([patternViews]: [any]) => {
        if (_.isEmpty(patternViews)) {
          return false;
        }
        return _.isNil(this.hierarchyTreeComponent);
      })
    ).subscribe((isTreeLoadInProgress) => {
      this._authTreeRenderInProgress.next(isTreeLoadInProgress);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!_.isNil(changes.patternId) && this.scrollArea) {
      this.scrollArea.nativeElement.scrollTop = 0;
      // setTimeout is needed to change the value outside of current changeDetection cycle
      setTimeout(() => this.helpConnectService.resetHelpSelection());
      this._authTreeRenderInProgress.next(true);
    }
    if (!_.isNil(changes.patterns)) {
      this.patternHierarchyContext.loadAllPatternsIntoMap(this.patterns);
    }
    if ((!_.isNil(changes.patterns) || !_.isNil(changes.patternId) || !_.isNil(changes.isCollapsed)) && !this.isCollapsed && this.shouldDisplayAuthTree) {
      this.loadPatternHierarchy$.next(true);
    }
  }

  loadPatternViews(): Observable<PatternViewModel[]> {
    return this.loadPatternHierarchy$.pipe(
      filter((shouldLoadPatternViews: boolean) => shouldLoadPatternViews),
      switchMap(() => {
        if (!this.patternId) {
          return EMPTY;
        }
        return this.patternService.getPatternView(this.projectKey, this.patternId)
          .pipe(map((patternViewResponse: PatternViewResponse) => {
            const patternModels: PatternViewModel[] = patternViewResponse.items;
            this.authFlowWarnings = _.isNil(patternViewResponse._status) ? [] : ValidationStatusHelper.getValidationWarningsByTitle(patternViewResponse._status, this.INCOMPLETE_VIEW_WARNING);
            this.selectedContext = this.getContextSelection(patternModels, this.selectedContext);
            this.updateContextHistory(this.contextHistory, this.selectedContext);
            if (!_.isNil(this.selectedContext)) {
              this.patternHierarchyContext.createPatternHierarchyByContextPatternId(this.selectedContext.patternId, this.selectedContext);
            }
            return patternModels;
          }), catchError(() => []));
      }), shareReplay(1));
  }

  getContextSelection(patternViews: PatternViewModel[], selectedContext: PatternViewModel | undefined): PatternViewModel | undefined {
    if (_.isEmpty(patternViews)) {
      return selectedContext;
    }
    const targetContext = patternViews.find(patternView => _.isEqual(patternView, selectedContext));
    if (targetContext) {
      return targetContext;
    }
    return this.getLastUsedContext(patternViews, this.contextHistory);
  }

  contextChange(): void {
    if (!_.isNil(this.selectedContext)) {
      this.updateContextHistory(this.contextHistory, this.selectedContext);
      this.patternHierarchyContext.createPatternHierarchyByContextPatternId(this.selectedContext.patternId, this.selectedContext);
    }
  }

  get isUsageTabSelected(): boolean {
    return this.tabGroup?.selectedIndex === this.USAGE_TAB_INDEX;
  }

  tabChanged(): void {
    if (this.isUsageTabSelected) {
      this.helpConnectService.resetHelpSelection();
      this.triggerAuthFlowLoadIfNeeded();
    }
    try {
      LocalStorageHelper.save(localStorageHelpTabIndex, JSON.stringify(this.tabGroup?.selectedIndex));
    } catch (error) {
      console.error('Cannot save selected tab index' + error);
    }
  }

  private updateContextHistory(contextHistory: PatternViewModel[], selectedContext: PatternViewModel | undefined): void {
    if (_.isNil(selectedContext)) {
      return;
    }
    _.remove(this.contextHistory, selectedContext);
    contextHistory.unshift(selectedContext);
  }

  private getLastUsedContext(patternViews: PatternViewModel[], contextHistory: PatternViewModel[]): PatternViewModel {
    let targetContext: PatternViewModel | undefined;
    _.forEach(contextHistory, (historyItem) => {
      targetContext = _.find(patternViews, (patternView) => patternView.patternId === historyItem.patternId);
      if (targetContext) {
        return false;
      }
      return undefined;
    });
    return targetContext || patternViews[0];
  }

  hasPatternAuthFlowView(patternViewModels: PatternViewModel[]): boolean {
    return !_.isEmpty(patternViewModels);
  }

  onAuthFlowSectionChange(): void {
    this.isAuthFlowCollapsed = !this.isAuthFlowCollapsed;
    this.triggerAuthFlowLoadIfNeeded();
  }

  get shouldDisplayAuthTree(): boolean {
    return !this.isAuthFlowCollapsed && this.isUsageTabSelected;
  }

  triggerAuthFlowLoadIfNeeded(): void {
    if (this.shouldDisplayAuthTree) {
      this.loadPatternHierarchy$.next(true);
    }
  }

  hasMultipleContext(patternViewModels: PatternViewModel[]): boolean {
    return _.size(patternViewModels) > 1;
  }

  resolveNoAuthFlowText(authTreeRenderInProgress: boolean): string {
    return authTreeRenderInProgress ? this.AUTH_FLOW_LOADING_TEXT : this.NO_AUTH_FLOW_TEXT;
  }

  get graphViewLink(): string {
    return this.navigationService.getGraphViewLink(this.projectKey, this.patternId);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getPatternViewLabel(patternView: PatternViewModel[] | null) {
    if (_.isNil(patternView) || _.isEmpty(patternView)) return '';
    return patternView[0]?.label;
  }
}

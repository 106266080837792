import { distinctUntilChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PropertyListComponentModel } from './property-list-component-model';
import { PropertyType } from '../plugins/property-type.model';
import { IssueModel, SourceType } from '../common/model/issue.model';
import { PatternType } from '../plugins/pattern-type.model';
import { PatternInstance, PatternProperty } from '../patterns/pattern-instance.model';
import { select, Store } from '@ngrx/store';
import { AppState, ProjectKey } from '../model/reducer';
import { VariableModel } from '../variables/variable.model';
import { PropertyDataModel, PropertyModelCategoryGroup } from './property-data.model';
import { PropertyInitHelper } from './property-init.helper';
import { patternsView, projectKeyView, selectedPatternInstanceView, selectedPatternIssuesWithoutPropertyInfoView } from '../model/views';
import { DuplicatePattern, UpdatePatternInstance } from '../model/pattern';
import { SaverService } from '../common/services/saver.service';
import { MatDialog } from '@angular/material/dialog';
import { PatternVersionInfo } from '../version-control/pattern-meta-info.model';
import { NevisAdminAction } from '../model/actions';
import { getIssuesById } from '../projects/project-issues/project-issues.helper';
import { PropertyFormValueConverter } from './property-form-value-converter';
import { UserStateService } from '../common/services/user/user.state.service';
import { ModalNotificationService } from '../notification/modal-notification.service';
import { ToastNotificationService } from '../notification/toast-notification.service';
import { addPropertyControlsToForm } from './property-list-form.helper';
import { ValueNormalizer } from './value-normalizer';
import { SpecificPatternFieldsEnum } from '../common/model/specific-pattern-fields.enum';
import { DirtyFormGuardConnectorService } from '../common/services/dirty-form-guard-connector.service';
import { PropertyExternalLinkContext } from './property-external-link.context';
import { PatternHelper } from '../patterns/pattern.helper';
import { PatternEditorPropertyContext } from './pattern-editor-context.service';
import { PropertyWidgetContext } from './property-widget.context';
import { DEFAULT_PROPERTY_CATEGORY } from './pattern-editor.constants';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../navbar/navigation.service';
import { NavigationConstants } from '../common/constants/navigation.constants';
import { DuplicatePatternPayload } from '../model/pattern/create-pattern-payload.model';
import { BatchActionDialogService } from '../patterns/batch-actions/batch-action-dialog.service';
import { PatternListData } from '../patterns/pattern-list-data.model';
import { PatternRestrictions } from '../patterns/pattern.model';
import { DisplayComponentHelperService } from '../common/services/display-component-helper.service';

const PATTERN_NAME_FORM_CONTROL = 'pattern_name';
const ACTIONS_TO_DISTPACH_FC_NAME = '#actionsToDistpach';

/**
 * This component displays a list of widgets.
 */
@Component({
  selector: 'adm4-pattern-editor',
  templateUrl: './pattern-editor.component.html',
  styleUrls: ['pattern-editor.component.scss', '../common/styles/component-specific/pattern-type-icon.scss'],
  providers: [PropertyExternalLinkContext, {provide: PropertyWidgetContext, useClass: PatternEditorPropertyContext}]
})
export class PatternEditorComponent implements OnChanges, OnDestroy {
  NO_PROPERTIES_LABEL = 'This pattern does not have configurable properties.';
  readonly deploymentHostsFormControlName = SpecificPatternFieldsEnum.DeploymentHosts;
  readonly patternNotesFormControlName = SpecificPatternFieldsEnum.PatternNotes;

  @Input() selectedPropertyKey: string;
  @Input() propertyListEntry: PropertyListComponentModel;
  // pattern = patternInstance
  @Input() pattern: PatternInstance;
  @Input() patternMetaInfo: PatternVersionInfo | null;
  @Input() patternTypes: Dictionary<PatternType>;
  @Input() propertyTypes: Dictionary<PropertyType>;
  @Input() issues: IssueModel[];
  @Input() variables: VariableModel[];
  @Input() readOnly: boolean;
  @Input() projectKey: string;
  @Input() isHelpCollapsed: boolean;
  @Input() hasProjectModificationPermission: boolean;

  @Output() selectionChanged: EventEmitter<string> = new EventEmitter();
  @Output() propertyHasChanged = new EventEmitter<boolean>();
  @Output() expandHelp: EventEmitter<undefined> = new EventEmitter();
  @Output() labelViewClicked: EventEmitter<undefined> = new EventEmitter();

  @ViewChild('deploymentHost', {static: false}) deploymentHost;
  @ViewChild('scrollArea', {static: false}) scrollAreaRef: ElementRef<HTMLElement>;

  onFormSubmit = new Subject<any>();

  formInitialValue: AbstractControl;
  propertiesModel: PropertyDataModel[];
  propertyModelCategoryGroups:  PropertyModelCategoryGroup[];
  patternModification: PatternInstance;
  patternType?: PatternType;

  patternIssues$: Observable<IssueModel[] | undefined>;
  headerEditMode: boolean;
  headerTitle: string;
  deploymentHostIssues: IssueModel[];
  propertyValueMap: Map<string, any> = new Map();
  form: UntypedFormGroup;
  selectedPropertyCategoryIndex$: BehaviorSubject<number> = new BehaviorSubject(0);
  // emits value every time form is recreated (selected pattern instance has changed)
  formRecreated: EventEmitter<void> = new EventEmitter();
  categoryIndex$: Observable<number>;
  patternLabels$: Observable<string[]>;
  _isPatternNotesCollapsed$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isPatternNotesCollapsed$: Observable<boolean>;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private chRef: ChangeDetectorRef,
              private modalNotificationService: ModalNotificationService,
              private toastNotificationService: ToastNotificationService,
              private fb: UntypedFormBuilder,
              private store$: Store<AppState>,
              private saver: SaverService,
              private matDialogService: MatDialog,
              private userService: UserStateService,
              public formGuardConnectorService: DirtyFormGuardConnectorService,
              public route: ActivatedRoute,
              public navigationService: NavigationService,
              private batchActionDialogService: BatchActionDialogService,
              private displayComponentHelperService: DisplayComponentHelperService
  ) {

    this.saver.onSave.pipe(
      filter(() => !this.formActionsDisabled && !this.matDialogService.openDialogs.length && !this.readOnly),
      takeUntil(this.destroyed$)
    ).subscribe(() => this.onFormSubmit.next(this.form.value));

    this.onFormSubmit.pipe(
      withLatestFrom(this.store$.pipe(select(projectKeyView))),
      filter<[any, string]>(([, projectKey]: [any, string | null]) => !_.isNil(projectKey)),
      takeUntil(this.destroyed$)
    ).subscribe(([form, projectKey]) => {
      this.savePatternInstance(form, projectKey);
    });

    this.patternIssues$ = this.store$.pipe(select(selectedPatternIssuesWithoutPropertyInfoView));
    this.categoryIndex$ =
      merge(this.route.queryParams.pipe(
        map(queryparams => _.get(queryparams, NavigationConstants.CATEGORY))),
        this.selectedPropertyCategoryIndex$,
      ).pipe(
        filter(() => this.isSelectedPatternUrlActive()),
        distinctUntilChanged());

    this.patternLabels$ = PatternHelper.getPatternsLabels(this.store$.pipe(select(patternsView)));

    this.isPatternNotesCollapsed$ = merge(
     this._isPatternNotesCollapsed$.asObservable(),
      this.store$.pipe(select(selectedPatternInstanceView), distinctUntilChanged((previousValue, currentValue) => {
        return _.isEqual(previousValue?.patternId, currentValue?.patternId);
    }), map(() => true))); // always set pattern notes collapsed when switching between patterns
  }

  // TODO improve naming so that it's not confusing with guards
  // TODO improve return value to be more self explanatory
  @HostListener('window:beforeunload')
  canDeactivate(): boolean | undefined {
    // check if there are pending changes here;
    if (this.form.dirty) {
      return false;
    }
    return undefined;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const patternChanges = changes['pattern'];
    const patternDataChanged = changes['pattern'] || changes['patternTypes'] || changes['propertyTypes'];
    const previousPattern = _.get(patternChanges, 'previousValue', null);
    const newPattern = _.get(patternChanges, 'currentValue', null);
    const isSamePattern = changes['pattern'] && _.get(previousPattern, 'patternId') === _.get(newPattern, 'patternId');

    if (patternDataChanged && this.pattern && this.propertyTypes && this.issues) {
      this.formGuardConnectorService.disconnect();
      this.updateProperties();
      this.updateDeploymentHostIssues();
      this.initMap(this.propertyListEntry.pattern.properties);
      this.chRef.markForCheck();
    }
    const hasLocalAuthor = !this.patternMetaInfo || !_.isNil(this.patternMetaInfo.localAuthor);
    const isModifiedByOtherUser = this.patternMetaInfo && hasLocalAuthor && this.userService.username && this.patternMetaInfo.localAuthor !== this.userService.username;
    if (changes['issues'] && this.issues && this.propertiesModel && (this.form && !this.form.dirty)) {
      this.updatePatternIssues();
    }
    if (patternChanges && previousPattern && newPattern && isSamePattern && isModifiedByOtherUser && this.form && this.form.dirty) {
      // setTimeout is used as workAround for expression checked error since this is a lifecycle hook
      setTimeout(() => this.toastNotificationService.showWarningToast(`Pattern ${this.pattern.name} was updated by another user. You will not be able to save changes.`));
      return;
    } else if (patternChanges && previousPattern && newPattern && isSamePattern && isModifiedByOtherUser && this.form && !this.form.dirty) {
      // setTimeout is used as workAround for expression checked error since this is a lifecycle hook
      setTimeout(() => this.toastNotificationService.showWarningToast(`Reloaded pattern <strong>${this.pattern.name}</strong> because it was updated by another user.`));
    }
    if (patternChanges) {
      // we only want new instance of the form when displaying a different pattern
      if (isSamePattern) {
        // if properties were modified concurrently we will reset to latest data from server
        this.resetForm();
      } else {
        this.createForm();
      }
    }
    if (changes['selectedPropertyKey'] || patternChanges) {
      const patternIdChanged = patternChanges && (!patternChanges.previousValue || patternChanges.currentValue.patternId !== patternChanges.previousValue.patternId);
      this.headerEditMode = this.selectedPropertyKey === 'titleEdit' && patternIdChanged;
    }
    // if selected another pattern, reset scrolling of the pattern editor
    if (patternChanges && !patternChanges.firstChange && patternChanges.currentValue.patternId !== patternChanges.previousValue.patternId && this.scrollAreaRef) {
      this.scrollAreaRef.nativeElement.scrollTop = 0;
    }
    setTimeout(() => this.changeToCategoryOfProperty(this.selectedPropertyKey));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.formGuardConnectorService.disconnect();
  }

  propertyCategoryGroupTrackBy(propertyModelCategoryGroup: PropertyModelCategoryGroup): string {
    return propertyModelCategoryGroup.category;
  }

  isDefaultPropertyModelCategoryGroup(propertyModelCategoryGroup: PropertyModelCategoryGroup): boolean {
    return propertyModelCategoryGroup.category === DEFAULT_PROPERTY_CATEGORY;
  }

  getIssuesOfPropertyCategoryGroup(propertyModelCategoryGroup: PropertyModelCategoryGroup): IssueModel[] {
    const initialIssuesOfPropertyCategoryGroup: IssueModel[] = this.isDefaultPropertyModelCategoryGroup(propertyModelCategoryGroup) ? this.deploymentHostIssues : [];
    return propertyModelCategoryGroup.propertiesModel.reduce((issuesOfPropertyCategoryGroup: IssueModel[], propertyModel: PropertyDataModel) => {
      return _.isNil(propertyModel.issues) ? issuesOfPropertyCategoryGroup : _.concat(issuesOfPropertyCategoryGroup, propertyModel.issues);
    }, initialIssuesOfPropertyCategoryGroup);
  }

  propertyCategoryGroupHasIssues(propertyModelCategoryGroup): boolean {
    const issuesOfPropertyCategoryGroup = this.getIssuesOfPropertyCategoryGroup(propertyModelCategoryGroup);
    return !_.isEmpty(issuesOfPropertyCategoryGroup);
  }

  changeToCategoryOfProperty(propertyKey?: string): void {
    const queryParameterFromUrl = this.getQueryParameterFromUrl();
    if (_.isNil(propertyKey)) {
      if (!_.isNil(queryParameterFromUrl)) {
        this.selectedPropertyCategoryIndex$.next(queryParameterFromUrl);
      }
      return;
    }
    const indexOfCategoryGroupToSelect = this.getCategoryOfPropertyKey(propertyKey);
    if (_.isNil(queryParameterFromUrl)) {
      this.selectedPropertyCategoryIndex$.next(indexOfCategoryGroupToSelect);
      return;
    }
    // Set higher priority for the selected property (if not matches with the query param)
    if (queryParameterFromUrl !== indexOfCategoryGroupToSelect) {
      this.selectedPropertyCategoryIndex$.next(indexOfCategoryGroupToSelect);
    }
  }

  private getQueryParameterFromUrl(): number | undefined {
    return _.isNil(this.route.snapshot.queryParams['c']) ? 0 : this.route.snapshot.queryParams['c'];
  }

  isSelectedPatternUrlActive(): boolean {
    return _.includes(this.route.snapshot.url.map(urlSegment => urlSegment.path), this.pattern.patternId);
  }

  /**
   * returns the category of the propertykey, or 0 as default
   * @param propertyKey
   */
  private getCategoryOfPropertyKey(propertyKey: string | undefined) {
    const categoryNumber = _.findIndex(this.propertyModelCategoryGroups, (group: PropertyModelCategoryGroup) => {
      return group.propertiesModel.some((propertyModel: PropertyDataModel) => propertyModel.patternProp.propertyKey === propertyKey);
    });
    return categoryNumber > 0 ? categoryNumber : 0;
  }

  selectPropertyCategoryByIndex(index: number): void {
    this.selectedPropertyCategoryIndex$.next(index);
    this.navigationService.navigateToCategory(index);
  }

  updateProperties() {
    this.headerTitle = this.pattern.name;
    this.patternType = _.find(this.patternTypes, (type: PatternType) => type.className === this.pattern.className);
    this.propertiesModel = PropertyInitHelper.initPropertyModel(this.pattern, this.patternType, this.propertyTypes, this.issues)
      .filter((propertyDataModel: PropertyDataModel) => this.displayComponentHelperService.showByPatternType(propertyDataModel?.patternTypeProp));
    this.propertyModelCategoryGroups = PropertyInitHelper.getPropertyModelCategoryGroups(this.propertiesModel);
    this.patternModification = PropertyInitHelper.getPatternInstanceWithProperties(this.pattern, this.propertiesModel);
    this.propertyListEntry = new PropertyListComponentModel(
      this.patternModification,
      this.patternType,
      this.propertyTypes,
      this.issues
    );
  }

  updatePatternIssues(): void {
    this.updateDeploymentHostIssues();
    this.propertiesModel = this.propertiesModel.map(model => {
      return Object.assign({}, model, {
        issues: PropertyInitHelper.findPropertyIssues(model.patternProp.propertyKey, this.issues)
      });
    });
    this.propertyModelCategoryGroups = PropertyInitHelper.getPropertyModelCategoryGroups(this.propertiesModel);
  }

  updateDeploymentHostIssues(): void {
    this.deploymentHostIssues = getIssuesById(this.issues, SourceType.FIELD, SpecificPatternFieldsEnum.DeploymentHosts);
  }

  createFormGroup(propertyListEntry: PropertyListComponentModel): UntypedFormGroup {
    const group = this.fb.group({});

    group.addControl(ACTIONS_TO_DISTPACH_FC_NAME, this.fb.control(<NevisAdminAction[]>[]));

    addPropertyControlsToForm(group, this.propertiesModel);
    if (propertyListEntry.patternType && propertyListEntry.patternType.deployablePattern) {
      group.addControl(SpecificPatternFieldsEnum.DeploymentHosts, this.fb.control(propertyListEntry.pattern.deploymentHosts || ''));
    }
    group.addControl(PATTERN_NAME_FORM_CONTROL, this.fb.control(propertyListEntry.pattern.name));
    group.addControl(SpecificPatternFieldsEnum.PatternNotes, this.fb.control(propertyListEntry.pattern.notes || ''));
    return group;
  }

  createUpdatedPatternInstance(formValue: any): PatternInstance {
    return {
      ...this.propertyListEntry.pattern,
      name: formValue.pattern_name,
      deploymentHosts: formValue.deploymentHosts,
      notes: formValue.patternNotes,
      properties: this.propertyListEntry.pattern.properties.map((prop: PatternProperty) => {
        const propModel = _.find((this.propertiesModel), (propertyModel: PropertyDataModel) => {
          return propertyModel.patternProp.propertyKey === prop.propertyKey;
        });
        if (_.isNil(propModel)) {
          return prop;
        }
        let propValue: any = prop.value;
        try {
          propValue = PropertyFormValueConverter.toPropertyValue(propModel.propType, formValue[prop.propertyKey]);
        } catch (e) {
          console.error(e);
          this.toastNotificationService.showWarningToast(
            `Could not parse value for unknown property <strong>${prop.propertyKey}</strong>. The value has not been saved.`);
        }
        return {
          ...prop,
          value: propValue
        };
      })
    };
  }

  changeSelectedProperty(propertyKey: string): void {
    this.selectionChanged.emit(propertyKey);
  }

  savePatternInstance(value: any, projectKey: ProjectKey) {

    const patternNameChanged = this.form.controls[PATTERN_NAME_FORM_CONTROL].dirty;

    if (patternNameChanged) {
      if (_.isEmpty(this.form.controls[PATTERN_NAME_FORM_CONTROL].value)) {
        this.modalNotificationService.openErrorDialog({description: `Pattern name shouldn't be empty.`}, () => {
          // Timeout: workaround for pattern name input to lose focus right after it has been focused
          setTimeout(
            () => this.onRenamePattern(), 500
          );
        });
        return;
      }
    }
    const updatedPattern = this.createUpdatedPatternInstance(value);

    const actionControl = this.form.controls[ACTIONS_TO_DISTPACH_FC_NAME];
    if (_.isArray(actionControl.value)) {
      actionControl.value.forEach((action: NevisAdminAction) => {
        if (!_.isEmpty(action.payload)) {
          this.store$.dispatch(action);
        }
      });
    }

    const pattern = ValueNormalizer.normalizePatternInstance(updatedPattern, this.pattern);
    this.store$.dispatch(new UpdatePatternInstance({patternNameChanged: patternNameChanged, pattern: pattern, projectKey: projectKey, patternId: updatedPattern.patternId, onUpdateSuccess: () => this.onUpdatePatternInstance(value, updatedPattern.patternId)}));
  }

  onUpdatePatternInstance(_value: any, updatedPatternId: string) {
    // It can happen, that the user has already selected another pattern when the update finished executing.
    if (updatedPatternId === this.pattern.patternId) {
      this.resetForm();
      this.propertyHasChanged.emit(false);
    }
  }

  createForm() {
    this.form = this.createFormGroup(this.propertyListEntry);
    this.formRecreated.emit(); // if for has been recreated we need to get rid of subscription to value of old form instance
    this.form.valueChanges.pipe(
      takeUntil(this.destroyed$),
      takeUntil(this.formRecreated))
      .subscribe(() => {
        this.propertyHasChanged.emit(this.form.dirty);
      });
    this.propertyHasChanged.emit(false);
    this.formInitialValue = _.cloneDeep(this.form.value);
    this.formGuardConnectorService.connectForm(this.form);
  }

  resetForm() {
    const form = this.createFormGroup(this.propertyListEntry);
    this.form.reset(form.value);
    this.headerTitle = this.form.value['pattern_name'];
    this.updatePatternIssues();
    this.propertyHasChanged.emit(false);
    this.formGuardConnectorService.connectForm(this.form);
  }

  onCopyPattern(): void {
    if (!this.hasProjectModificationPermission) {
      const title = `Target project was not found`;
      this.modalNotificationService.openErrorDialog({title: title, description: `There is no target project found. It does not seem to exist, or you may not have access.`});
      return;
    }
    const patternToCopy = new PatternListData(this.pattern, [], this.patternType);
    this.formGuardConnectorService.doIfConfirmed(() => {
      this.batchActionDialogService.openBatchActionDialog('Copy pattern', this.projectKey, [patternToCopy]);
    });
  }

  onRenamePattern() {
    if (this.readOnly) {
      return;
    }
    this.headerEditMode = true;
  }

  onHeaderBlur() {
    this.headerEditMode = false;
    this.headerTitle = this.form.value['pattern_name'];
  }

  /**
   * It triggers the validation just when the property's value changed.
   * @param patternProperty
   */
  triggerValidation(patternProperty: PatternProperty): void {
    if (this.propertyValueMap.get(patternProperty.propertyKey) !== patternProperty.value) {
      this.propertyValueMap.set(patternProperty.propertyKey, patternProperty.value);
    }
  }

  initMap(properties: any[]): void {
    properties.forEach(property => {
      this.propertyValueMap.set(property.propertyKey, property.value);
    });
  }

  onHelpExpanderClick(): void {
    this.expandHelp.emit();
  }

  get formActionsDisabled(): boolean {
    return this.readOnly || !this.form.dirty;
  }

  get patternTypeIconClass(): string {
    return PatternHelper.resolvePatternTypeIconClass(this.pattern, this.patternType);
  }

  get headerStyle(): string {
    const patternTypeHeaderClass = PatternHelper.resolvePatternTypeHeaderClass(this.pattern, this.patternType);
    return `pattern-editable-title ${patternTypeHeaderClass} flex-header-stretched`;
  }

  get noProperties(): boolean | undefined {
    return (this.patternType && !this.patternType.deployablePattern) && this.propertiesModel.length === 0;
  }

  onDuplicatePattern() {
    this.formGuardConnectorService.doIfConfirmed(() => {
      const modifiedPattern = this.createUpdatedPatternInstance(this.form.value);
      const duplicatePatternPayload: DuplicatePatternPayload = {
        patternId: modifiedPattern.patternId,
        patternName: modifiedPattern.name,
        projectKey: this.projectKey,
        onCreateSuccess: (pattern: PatternInstance) => {
          this.navigationService.navigateToNewlyCreatedPattern(this.projectKey, pattern.patternId);
        }
      };
      this.store$.dispatch(new DuplicatePattern(duplicatePatternPayload));
    });
  }

  switchToLabelView(): void {
    this.labelViewClicked.emit();
  }

  updatePatternNotesCollapseState(isCollapsed: boolean) {
    this._isPatternNotesCollapsed$.next(isCollapsed);
  }

  shouldBeRestricted(restrictions: PatternRestrictions, property: string) {
    return this.displayComponentHelperService.shouldBeRestrictedByProperty(restrictions, property);
  }

}

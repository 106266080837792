import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState, Dictionary } from '../model/reducer';
import { TenantService } from './tenant.service';
import { tenantsView } from '../model/views';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { Tenant } from './tenant.model';
import * as _ from 'lodash';
import { InitTenantKey, LoadTenantsSuccess } from '../model/shared/shared.actions';
import { TenantHelper } from '../common/helpers/tenant.helper';
import { ModalNotificationService } from '../notification/modal-notification.service';
import { SignOut } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class TenantGuard  {

  constructor(private store$: Store<AppState>, private tenantService: TenantService, private modalNotificationService: ModalNotificationService) {}

  canActivateChild(_childRoute: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivateApp();
  }

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivateApp();
  }

  canActivateApp(): Observable<boolean> {
    return this.store$.pipe(
      select(tenantsView),
      first(),
      switchMap((tenants: Dictionary<Tenant>) => {
        if (_.isEmpty(tenants)) {
          return this.tenantService.getAllTenants().pipe(
            tap((loadedTenants: Tenant[]) => {
              if (!_.isEmpty(loadedTenants)) {
                this.store$.dispatch(new LoadTenantsSuccess(loadedTenants));
                this.store$.dispatch(new InitTenantKey({tenantKey: TenantHelper.getInitialTenantKey(loadedTenants)}));
              } else {
                this.modalNotificationService.openInfoDialog({
                  title: 'Permissions required',
                  description: 'Your credentials are correct, but you need to have permissions assigned to be able to access. Please contact your administrator.',
                });
                this.store$.dispatch(new SignOut());
              }
            })
          );
        }
        return of(_.values(tenants));
      }),
      map((tenants: Tenant[]) => !_.isEmpty(tenants))
    );
  }
}

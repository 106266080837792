import { IssueSeverityEnum } from '../../common/model/issue-severity.enum';
import { compareIssueModelBySeverityDesc, IssueModel, IssueSeverity, SourceType, Target, UNUSED_PATTERN_ISSUE_CODE } from '../../common/model/issue.model';
import { Maybe, requireNonNull } from '../../common/utils/utils';
import * as _ from 'lodash';
import { IssueSeverityCssClassEnum } from '../../common/model/issue-severity-css-class.enum';
import { PatternInstance } from '../../patterns/pattern-instance.model';
import { deploymentHostTargetName } from '../../issues/issues.constants';
import { Pattern } from '../../patterns/pattern.model';

export function isIssueSeverityEqualTo(issueSeverity: IssueSeverity, comparedSeverity: IssueSeverityEnum) {
  return IssueSeverityEnum[issueSeverity] === comparedSeverity;
}

export function isErrorIssue(issue: IssueModel): boolean {
  return isIssueSeverityEqualTo(issue.severity, IssueSeverityEnum.ERROR);
}

export function isWarningIssue(issue: IssueModel): boolean {
  return isIssueSeverityEqualTo(issue.severity, IssueSeverityEnum.WARNING);
}

export function isInfoIssue(issue: IssueModel): boolean {
  return isIssueSeverityEqualTo(issue.severity, IssueSeverityEnum.INFO);
}

export function isUnusedPattern(issue: IssueModel): boolean {
  return isInfoIssue(issue) && _.isEqual(issue.code, UNUSED_PATTERN_ISSUE_CODE);
}

export function getHighestSeverity(issues: IssueModel[]): IssueSeverityEnum {
  return issues.reduce((highestSeverity: IssueSeverityEnum, issue: IssueModel) => {
    return IssueSeverityEnum[issue.severity] > highestSeverity ? IssueSeverityEnum[issue.severity] : highestSeverity;
  }, IssueSeverityEnum.NO_ISSUE);
}

export function getHighestSeverityBetweenIssues(issue1: IssueModel, issue2: IssueModel): IssueSeverity {
  return _.invert(IssueSeverityEnum)[Math.max(IssueSeverityEnum[issue1.severity], IssueSeverityEnum[issue2.severity])] as IssueSeverity;
}

export function getIssuesById(issues: IssueModel[], searchedType: SourceType, searchedId: string): IssueModel[] {
  return _.filter(issues, issue =>
    !!_.find(issue.target, (target) =>
      isTargetFound(target, searchedType, requireNonNull(searchedId))
    )
  );
}

/**
 * @param {IssueModel[]} issues
 * @returns {IssueModel[]} - list of issues that don't have pattern target, ordered by severity, from error to info
 */
export function getGeneralIssues(issues: IssueModel[]): IssueModel[] {
  return issues
      .filter(issue => {
        const patternTarget: Target | undefined = getSpecificTargetOfIssue(issue, SourceType.PATTERN);
        return _.isNil(patternTarget);
      })
      .sort(compareIssueModelBySeverityDesc);
}

/**
 * Filters out the issues that don't have a pattern target.
 * @param issues
 */
export function filterForPatternIssues(issues: IssueModel[]): IssueModel[] {
  return issues.filter((issue: IssueModel) => {
    const patternTarget: Target | undefined = getSpecificTargetOfIssue(issue, SourceType.PATTERN);
    return !_.isNil(patternTarget);
  });
}

export function getPatternIssuesWithoutPropertyInfo(projectIssues: IssueModel[], searchedId: string): IssueModel[] {
  const patternIssues = getIssuesById(projectIssues, SourceType.PATTERN, searchedId);
  return _.filter(patternIssues, issue =>
    !issue.target.some((target) => target.sourceType === SourceType.PROPERTY || (target.sourceType === SourceType.FIELD && target.value === deploymentHostTargetName))
  );
}

export function getPatternIssuesOfNonExistingProperties(patternIssues: IssueModel[], pattern: PatternInstance): IssueModel[] {
  return patternIssues.filter((issue) => {
    const propertyTarget: Target | undefined = getSpecificTargetOfIssue(issue, SourceType.PROPERTY);
    return !_.isNil(propertyTarget) && pattern.properties.every(property => property.propertyKey !== propertyTarget.value);
  }).map(issue => {
    const propertyTarget: Target | undefined = getSpecificTargetOfIssue(issue, SourceType.PROPERTY);
    return _.isNil(propertyTarget) ? issue : {...issue, message: `${issue.message} in property ${propertyTarget.value}`};
  });
}

function isTargetFound(target: Target, searchedType: SourceType, searchedValue: string): boolean {
  return target.sourceType === searchedType && target.value === searchedValue;
}


export function filterOutInfoIssues(issues: IssueModel[]): IssueModel[] {
  return _.filter(issues, (issue: IssueModel) => {
    return !isInfoIssue(issue);
  });
}

export function getIssueCssClassBySeverity(severity: IssueSeverityEnum): string {
  switch (severity) {
    case IssueSeverityEnum.ERROR:
      return IssueSeverityCssClassEnum.ERROR;
    case IssueSeverityEnum.WARNING:
      return IssueSeverityCssClassEnum.WARNING;
    case IssueSeverityEnum.INFO:
      return IssueSeverityCssClassEnum.INFO;
    default:
      return '';
  }
}

export function getHighestSeverityIssueCssClass(issues: IssueModel[]): string {
  const highestSeverity = getHighestSeverity(issues);
  return getIssueCssClassBySeverity(highestSeverity);
}

export function getSpecificTargetOfIssue(issue: IssueModel, targetType: SourceType): Target | undefined {
  return issue.target.find(target => target.sourceType === targetType);
}

export function getOptionalTargetValue(issueTarget: Target | undefined): string | null {
  return _.isNil(issueTarget) ? null : issueTarget.value;
}

/**
 * Maps all the patterns to the ID if the first one. The order is non-deterministic, ie. based on an implementation detail.
 */
export function allPatternsToFirstId(allPatterns: Map<string, Pattern>): Maybe<string> {
  return Array.from(allPatterns.values())
      .map((pattern: Pattern) => pattern.patternId).find(v => v);
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IssueSeverity } from '../../model/issue.model';

export type ValidationIndicator = 'info' | 'error' | 'warning' | 'valid' | 'neutral' | '';

@Component({
  selector: 'adm4-validation-indicator',
  template: `
    <div class='validation-container'
         [style.width.px]='containerSize'
         [style.height.px]='containerSize'>
      <mat-spinner *ngIf='validating' [diameter]='15' title='Validation in progress...'></mat-spinner>
      <div *ngIf='isDisplayed'
           class='validation-circle-outer'
           [style.border-width.px]='wrapperBorderWidth'>
        <div class='validation-circle'
             [ngClass]='validationClass'
             [style.width.px]='diameter'
             [style.height.px]='diameter'>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./validation-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationIndicatorComponent {
  @Input() isDisplayed: boolean;
  @Input() isValid: boolean;
  @Input() isError: boolean;
  @Input() isWarning: boolean;
  @Input() isInfo: boolean;
  @Input() isNeutral: boolean;

  /**
   * Sets the indicator to the specified status. **If this is set, all the other status inputs are ignored.**
   */
  @Input() validationStatus: ValidationIndicator | undefined;
  /**
   * Sets the indicator based on this issue severity. `validationStatus` takes precedence over this if set,
   * and this takes precedence over the boolean status inputs.
   */
  @Input() issueSeverity: IssueSeverity;

  @Input() validating?: boolean;
  /**
   * @type {number} diameter of validation circle indicator
   */
  @Input() diameter = 12;
  @Input() wrapperBorderWidth = 1;

  get containerSize(): number {
    return this.diameter + this.wrapperBorderWidth * 2;
  }

  /**
   * Makes sure that only one class be attached at once
   * @returns {string}
   */
  get validationClass(): ValidationIndicator {
    if (this.validationStatus) {
      return this.validationStatus;
    } else if (this.issueSeverity) {
      if (this.issueSeverity === IssueSeverity.ERROR) {
        return 'error';
      }
      if (this.issueSeverity === IssueSeverity.WARNING) {
        return 'warning';
      }
      return 'info';
    } else if (this.isInfo) {
      return 'info';
    } else if (this.isError) {
      return 'error';
    } else if (this.isWarning) {
      return 'warning';
    } else if (this.isValid) {
      return 'valid';
    } else if (this.isNeutral) {
      return 'neutral';
    } else {
      return '';
    }
  }
}

import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Project, ProjectInventoryDeployment } from '../../projects/project.model';
import { combineLatest, Observable } from 'rxjs';
import { Inventory, InventorySchemaType } from '../../inventory/inventory.model';
import { DeploymentProcessModel } from '../deployment-dialog/deployment-process.model';
import * as _ from 'lodash';
import { DeploymentSelection } from './deployment-selection.model';
import { AppState } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { LoadProjectsWithInventoryDeployments } from '../../model/project';
import { LoadInventories } from '../../model/inventory';
import {
  allInventoriesView,
  allProjectsView,
  deployToClassicInstancePatternsView,
  deployToClassicOptionsView,
} from '../../model/views';
import {
  StoreDeploymentCanaryRoutingOptions,
  StoreDeploymentHost,
  StoreDeploymentInventory,
  StoreDeploymentProject,
} from '../../model/deploy';
import {
  allHosts,
  DeployToKubernetesOptionType,
  DeployToOption,
  primaryDeployOption,
  secondaryDeployOption,
} from './deploy-to-option.model';
import { NavigationService } from '../../navbar/navigation.service';
import { CreateInventoryDialogService } from '../../inventory/create-inventory/create-inventory-dialog.service';
import { DeploymentDialogComponent } from '../deployment-dialog/deployment-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { InventorySchemaTypeHelper } from './inventory-list/inventory-schema-type.helper';
import { DeploymentWizardContext } from '../deployment-wizard.context';
import { InventoryService } from '../../inventory/inventory.service';
import { filterEmpty, requireNonNull } from '../../common/utils/utils';
import {
  DeploymentHistoryItem
} from '../../common/model/deployment-history.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormHelper } from '../../common/helpers/form.helper';
import { KubernetesStatusHelper } from '../../inventory/inventory-kubernetes-status/kubernetes-status.helper';
import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import { localStorageDeployToInstancePatterns } from '../../common/constants/local-storage-keys.constants';
import { DeployToOptionHelper } from './deploy-to-option.helper';
import {
  DeploymentActivityContextService,
  DeploymentActivityIndicator
} from '../../common/services/deployment-activity-context.service';

@Component({
  selector: 'adm4-deployment-selection',
  template: `
    <div class="full-height-flex">
      <div class="remaining-space-flex-content-wrapper">
        <div class="remaining-space-flex-content">
          <div class='deployment-selection step-content full-height'>
            <div>
              <adm4-project-list *ngIf='projects$ | async' [items]='projects$ | async'
                                 [preSelectedProjectKey]='deploymentSelection.projectKey'
                                 [isClassicDeployment]='isClassicDeployment(selectedInventorySchemaType$ | async)'
                                 [classicDeployPatterns]='classicDeployInstancePatternsView$ | async'
                                 [deploymentActivity]="deploymentActivity$ | async"
                                 (itemSelected)='onProjectSelected($event)'
                                 (instancePatternSelected)='onInstancePatternClicked($event)'></adm4-project-list>
            </div>
            <div>
              <adm4-inventory-list *ngIf='inventories$ | async' [items]='inventories$ | async'
                                   [selectedProjectInventoryDeployments]="selectedProjectInventoryDeployments$ | async"
                                   [preSelectedInventoryKey]='deploymentSelection.inventoryKey'
                                   [deploymentActivity]="deploymentActivity$ | async"
                                   (itemSelected)='inventoryClicked($event)'
                                   (createInventoryClick)='goToCreateInventory()'></adm4-inventory-list>
            </div>
            <div class='deployment-host-selection'>
              <adm4-host-list *ngIf='isClassicDeployment(selectedInventorySchemaType$ | async)'
                              [items]='classicDeployTargets$ | async'
                              [preSelectedHostExpression]='getHostExpressionPrefix(deploymentSelection.deployTarget)'
                              [boxShadowClass]='boxShadowClass'
                              [inventoryColor]='inventoryColor$ | async'
                              (itemSelected)='onDeployTargetClicked($event)'></adm4-host-list>
              <adm4-k8s-list *ngIf='isKubernetesDeployment(selectedInventorySchemaType$ | async)'
                             [items]='kubernetesDeployTargets$ | async'
                             [preSelectedCanaryRoutingOptions]='deploymentSelection.canaryRouting'
                             [preSelectedProjectKey]='deploymentSelection.projectKey'
                             [inventoryDeploymentHistory]='inventoryDeploymentHistory$ | async'
                             [boxShadowClass]='boxShadowClass'
                             [inventoryColor]='inventoryColor$ | async'
                             [form]='form'
                             (itemSelected)='onDeployK8sTargetClicked($event)'>
              </adm4-k8s-list>
              <form [formGroup]="form">
                <div class='comment-wrapper'>
                  <div class="list-group comment-content" [ngClass]='boxShadowClass'>
                    <div class='comment-title' (click)='onCommentSectionClicked()'>Comment (optional)
                      <mat-icon class='expand-icon'>{{isCommentCollapsed ? 'expand_less' : 'expand_more'}}</mat-icon>
                    </div>
                    <textarea *ngIf='!isCommentCollapsed'
                              class='form-control admn4-textarea-input'
                              placeholder='Add your deployment comments'
                              [formControlName]='COMMENT_FIELD_FORM_CONTROL_NAME'
                              [(ngModel)]='commentMessage'></textarea>
                    <div *ngIf='!isCommentCollapsed' class="validation-message-container">
                      <adm4-validation-message *ngIf="shouldShowCommentErrorMessage('maxlength')" [isError]='true' [message]='"You have exceeded the maximum character limit of 150."'></adm4-validation-message>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="step-action-bar">
        <div class='step-button-wrapper '
             [ngbTooltip]='disabledNextStep' [disableTooltip]='!isValidateDisabled((projects$ | async), (inventories$ | async))' placement='top-right'>
          <button matStepperNext cdkFocusInitial tabindex="0"
                  class='next-step-button '
                  [disabled]='isValidateDisabled((projects$ | async), (inventories$ | async))'
                  (click)='onValidateDeploymentClicked()'>Validate deployment
          </button>
        </div>
      </div>
      <ng-template #disabledNextStep>
        <p>Please select a project and an inventory to continue the deployment.</p>
      </ng-template>
    </div>
  `,
  styleUrls: ['./deployment-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentSelectionComponent implements OnInit {
  @Input() deploymentSelection: DeploymentSelection;
  @Input() boxShadowClass: string;
  @Output() closeDialogClicked: EventEmitter<void> = new EventEmitter();
  @Output() validateDeploymentClicked: EventEmitter<DeploymentProcessModel> = new EventEmitter();
  @Output() inventorySelected: EventEmitter<Inventory> = new EventEmitter(true);
  @Output() deploymentCommentChanged: EventEmitter<string> = new EventEmitter();

  projects$: Observable<Project[]>;
  inventories$: Observable<Inventory[]>;
  selectedInventorySchemaType$: Observable<InventorySchemaType | undefined>;
  selectedProjectInventoryDeployments$: Observable<ProjectInventoryDeployment[]>;
  inventoryColor$: Observable<string>;
  classicDeployInstancePatternsView$: Observable<DeployToOption[]>;
  classicDeployTargets$: Observable<DeployToOption[]>;
  inventoryDeploymentHistory$: Observable<DeploymentHistoryItem[]>;
  kubernetesDeployTargets$: Observable<DeployToOption[]>;
  public deploymentActivity$: Observable<DeploymentActivityIndicator>;
  form: UntypedFormGroup;
  readonly COMMENT_FIELD_FORM_CONTROL_NAME = 'comment';
  commentMessage: string;
  isCommentCollapsed: boolean = true;

  constructor(private dialogRef: MatDialogRef<DeploymentDialogComponent>,
              private store$: Store<AppState>,
              private navigationService: NavigationService,
              private createInventoryDialogService: CreateInventoryDialogService,
              private deploymentWizardContext: DeploymentWizardContext,
              private inventoryService: InventoryService,
              private fb: UntypedFormBuilder,
              deploymentActivityContext: DeploymentActivityContextService,
  ) {
    this.projects$ = this.store$.pipe(select(allProjectsView), map(_.values), distinctUntilChanged(_.isEqual));
    this.inventories$ = this.store$.pipe(select(allInventoriesView), map(_.values), distinctUntilChanged(_.isEqual));
    this.selectedInventorySchemaType$ = deploymentWizardContext.selectedInventorySchemaType$;
    this.selectedProjectInventoryDeployments$ = deploymentWizardContext.selectedProjectInventoryDeployments$;
    this.inventoryColor$ = this.deploymentWizardContext.inventoryColor$;
    this.classicDeployInstancePatternsView$ = this.store$.pipe(select(deployToClassicInstancePatternsView));
    this.classicDeployTargets$ = this.store$.pipe(select(deployToClassicOptionsView));
    this.inventoryDeploymentHistory$ = this.deploymentWizardContext.selectedInventoryKey$.pipe(
      filter(filterEmpty),
      switchMap((inventoryKey: string) => this.inventoryService.getInventoryDeploymentHistory(requireNonNull(inventoryKey))));
    this.kubernetesDeployTargets$ = combineLatest([
      this.inventoryDeploymentHistory$,
    ]).pipe(
      map(([deploymentHistoryItems]: [DeploymentHistoryItem[]]): DeployToOption[] => {
        const hasPrimaryActive = KubernetesStatusHelper.hasActivePrimaryDeployments(deploymentHistoryItems);
        if (!hasPrimaryActive) {
          this.resetDeploymentSelection();
        }
        return hasPrimaryActive ? [primaryDeployOption, secondaryDeployOption] : [primaryDeployOption];
      }),
    );
    this.deploymentActivity$ = deploymentActivityContext.deploymentActivity$;
  }

  ngOnInit(): void {
    this.store$.dispatch(new LoadProjectsWithInventoryDeployments());
    this.store$.dispatch(new LoadInventories());
    this.form = this.createFormGroup();
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    group.addControl(this.COMMENT_FIELD_FORM_CONTROL_NAME, this.fb.control(null, Validators.maxLength(150)));
    return group;
  }

  shouldShowCommentErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[this.COMMENT_FIELD_FORM_CONTROL_NAME], errorKey);
  }

  isValidateDisabled(projects: Project[], inventories: Inventory[]): boolean {
    const formControlValid: boolean = _.values(this.form.controls).every(control => control.valid) && this.form.valid;
    return _.isNil(this.deploymentSelection.projectKey) || _.isNil(this.deploymentSelection.inventoryKey) || _.isEmpty(projects) || _.isEmpty(inventories) || !formControlValid;
  }

  isClassicDeployment(inventorySchemaType?: InventorySchemaType): boolean {
    return InventorySchemaTypeHelper.isClassicDeployment(inventorySchemaType);
  }

  isKubernetesDeployment(inventorySchemaType?: InventorySchemaType): boolean {
    return InventorySchemaTypeHelper.isKubernetesDeployment(inventorySchemaType);
  }

  onProjectSelected(project: Project): void {
    if (project.projectKey !== this.deploymentSelection.projectKey) {
      this.store$.dispatch(new StoreDeploymentProject(project.projectKey));
    }
  }

  inventoryClicked(inventory: Inventory): void {
    this.inventorySelected.emit(inventory);
    if (inventory.inventoryKey !== this.deploymentSelection.inventoryKey) {
      this.store$.dispatch(new StoreDeploymentInventory(inventory.inventoryKey));
    }
  }

  onDeployTargetClicked(deployToOption: DeployToOption): void {
    if (deployToOption.hostExpression !== this.deploymentSelection.deployTarget) {
      if (_.isNil(this.deploymentSelection.deployTarget)) {
        this.store$.dispatch(new StoreDeploymentHost(deployToOption.hostExpression));
        return;
      }
      const selectedPatternInstance = DeployToOptionHelper.unwrapPatternInstancesFromHostExpression(this.deploymentSelection.deployTarget);
      const finalHostExpression = _.isEmpty(selectedPatternInstance) ? deployToOption.hostExpression : (deployToOption.hostExpression + ';' + selectedPatternInstance);
      this.store$.dispatch(new StoreDeploymentHost(finalHostExpression));
    }
  }

  onInstancePatternClicked(patternInstancesDeployToOption: DeployToOption): void {
    if (_.isNil(this.deploymentSelection.deployTarget)) {
      this.store$.dispatch(new StoreDeploymentHost(allHosts.hostExpression));
      return;
    }
    const hostExpressionPrefix = this.getHostExpressionPrefix(this.deploymentSelection.deployTarget);
    const finalHostExpression = _.isEmpty(patternInstancesDeployToOption.hostExpression) ? hostExpressionPrefix : hostExpressionPrefix + ';' + patternInstancesDeployToOption.hostExpression;
    this.store$.dispatch(new StoreDeploymentHost(finalHostExpression));
  }

  getHostExpressionPrefix(fullHostExpression: string): string {
    return DeployToOptionHelper.getHostExpressionPrefix(fullHostExpression);
  }

  onDeployK8sTargetClicked(deployToOption: DeployToOption): void {
    if (_.isEqual(deployToOption.type, DeployToKubernetesOptionType.Primary) || !_.isEqual(deployToOption.canaryRouting, this.deploymentSelection.canaryRouting)) {
      this.store$.dispatch(new StoreDeploymentCanaryRoutingOptions(deployToOption.canaryRouting));
    }
  }

  onValidateDeploymentClicked(): void {
    const deployment = this.createDeploymentFromDeploymentSelection();
    this.validateDeploymentClicked.emit(deployment);
    this.storeSelectedInstancePatterns(deployment);
  }

  storeSelectedInstancePatterns(deployment: DeploymentProcessModel): void {
    const retrieve = LocalStorageHelper.retrieve(localStorageDeployToInstancePatterns);
    const selectedProjectWithPatternInstanceSelection = {[deployment.projectKey]: deployment.hostExpression};
    if (_.isNil(retrieve)) {
      LocalStorageHelper.save(localStorageDeployToInstancePatterns, JSON.stringify(selectedProjectWithPatternInstanceSelection));
      return;
    }
    const currentDeployToConfig = JSON.parse(retrieve);
    currentDeployToConfig[deployment.projectKey] = deployment.hostExpression;
    LocalStorageHelper.save(localStorageDeployToInstancePatterns, JSON.stringify(currentDeployToConfig));
  }

  createDeploymentFromDeploymentSelection(): DeploymentProcessModel {
    if (_.isNil(this.deploymentSelection.projectKey) || _.isNil(this.deploymentSelection.inventoryKey)) {
      // while selected project and selected inventory are empty button for the next step should be disabled therefore to prevent misusing of this method such case is handled
      throw new Error('Should not try to proceed to deployment validation without selecting project and inventory');
    }

    const deployment = new DeploymentProcessModel();
    deployment.projectKey = this.deploymentSelection.projectKey;
    deployment.inventoryKey = this.deploymentSelection.inventoryKey;
    if (this.deploymentSelection.deployTarget) {
      deployment.hostExpression = this.deploymentSelection.deployTarget;
    }
    if (this.deploymentSelection.canaryRouting) {
      deployment.canaryRouting = this.deploymentSelection.canaryRouting;
    }
    if (!_.isEmpty(this.commentMessage)) {
      this.deploymentCommentChanged.emit(this.commentMessage);
    }
    return deployment;
  }

  goToCreateInventory(): void {
    this.navigationService.navigateAwayFromModalWindow()
      .then(() => this.dialogRef.afterClosed().toPromise())
      .then(() => this.navigationService.navigateToInventories())
      .then(() => this.createInventoryDialogService.openCreateInventoryDialog());
  }

  private resetDeploymentSelection() {
    this.deploymentSelection = {
      projectKey: this.deploymentSelection.projectKey,
      inventoryKey: this.deploymentSelection.inventoryKey,
      comment: this.deploymentSelection.comment
    };
  }

  onCommentSectionClicked(): void {
    this.isCommentCollapsed = !this.isCommentCollapsed;
  }
}

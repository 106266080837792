import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output, QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as _ from 'lodash';

import { Inventory } from '../../../inventory/inventory.model';
import { InventoryColorHelper } from '../../../common/helpers/inventory-color.helper';
import { DeploymentSelectionListComponent } from '../deployment-selection-list/deployment-selection-list.component';
import { AppState } from '../../../model/reducer';
import { Tenant } from '../../../tenant/tenant.model';
import { selectedTenantView } from '../../../model/views';
import { PermissionsHelper } from '../../../permissions/permissions.helper';
import { OperationKey } from '../../../model/permissions/permissions.model';
import { PatternMasterListHelper } from '../../../patterns/pattern-master-list.helper';
import { TenantHelper } from '../../../common/helpers/tenant.helper';
import { ProjectInventoryDeployment } from '../../../projects/project.model';
import { Maybe } from '../../../common/utils/utils';
import { Keys } from '../../../common/utils/keypress.utils';
import { DeploymentActivityIndicator } from '../../../common/services/deployment-activity-context.service';

@Component({
  selector: 'adm4-inventory-list',
  template: `
    <div class='full-height-flex'>
      <h2 class='step-content-header'>Select inventory</h2>
      <div *ngIf='noAvailableOptions; else itemsList' class='step-content-info'>
        There are no inventories to display. Please
        <adm4-link (click)='createInventory()' [isDisabled]='!(hasPermissionToCreateInventory$ | async)' tooltipText='You dont have permission to create inventories on this tenant.'>create an inventory</adm4-link>
        and retry.
      </div>
      <ng-template #itemsList>
        <div class='remaining-space-flex-content-wrapper'>
          <div class='remaining-space-flex-content'>
            <div class="list-group"
                 [ngClass]="getInventoryBoxShadowClassName(selectedInventory?.color)">
              <div class='search-filter'>
                <adm4-filter (filter)="filterInventories($event)"
                             [placeholderText]='"Type to filter"'
                             [filterText]="filterText"
                             class='search-input'>
                </adm4-filter>
              </div>
              <div class='selection-list'>
                <button class="list-group-header" (click)="toggleSuggested()" tabindex="0">
                    <span>Recently used Inventories</span>
                    <mat-icon class="size-16">{{suggestedCollapsed ? 'expand_less' : 'expand_more'}}</mat-icon>
                </button>
                <ng-container *ngIf="!suggestedCollapsed">
                  <div *ngIf='projectNotDeployedYet' class='no-result-found-text'>Inventories this project is deployed to will appear here.</div>
                  <div *ngIf='!projectNotDeployedYet && suggestedInventories.length === 0' class='no-result-found-text'>No inventory found.</div>
                  <adm4-deployment-inventory-list-item #suggestedSelectionList *ngFor="let inventory of suggestedInventories; let i = index"
                    [inventory]="inventory"
                    [selectedInventoryKey]="preSelectedInventoryKey" 
                    [deploymentActivity]="deploymentActivity"
                    [inventoryColorClassName]="getInventorySelectionItemClassName(inventory.color)"
                    (inventoryKeyDown)="keyDownOnInventory($event, i, 'suggested')"
                    (selected)="selectItem(inventory, i)"
                    [adm4ScrollTarget]="inventory.inventoryKey" [scrollToWhenSelectedKey]="preSelectedInventoryKey"
                  ></adm4-deployment-inventory-list-item>
                </ng-container>

                <button class="list-group-header" (click)="toggleOther()" tabindex="0">
                  <span>Other Inventories</span>
                  <mat-icon class="size-16">{{otherCollapsed ? 'expand_less' : 'expand_more'}}</mat-icon>
                </button>
                <ng-container *ngIf="!otherCollapsed">
                  <div *ngIf='otherInventories.length === 0' class='no-result-found-text'>No inventory found.</div>
                  <adm4-deployment-inventory-list-item #otherSelectionList *ngFor="let inventory of otherInventories; let i = index"
                    [inventory]="inventory"
                    [selectedInventoryKey]="preSelectedInventoryKey"
                    [deploymentActivity]="deploymentActivity"
                    [inventoryColorClassName]="getInventorySelectionItemClassName(inventory.color)"
                    (inventoryKeyDown)="keyDownOnInventory($event, i, 'other')"
                    (selected)="selectItem(inventory, i)"
                    [adm4ScrollTarget]="inventory.inventoryKey" [scrollToWhenSelectedKey]="preSelectedInventoryKey"
                  ></adm4-deployment-inventory-list-item>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ['../deployment-selection-list/deployment-selection-list.component.scss', './inventory-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryListComponent extends DeploymentSelectionListComponent<Inventory> implements OnChanges {
  @ViewChildren('suggestedSelectionList', {read: ElementRef}) suggestedSelectionList: QueryList<ElementRef>;
  @ViewChildren('otherSelectionList', {read: ElementRef}) otherSelectionList: QueryList<ElementRef>;

  @Input() preSelectedInventoryKey: string | undefined;
  @Input() selectedProjectInventoryDeployments: Maybe<Array<ProjectInventoryDeployment>>;
  @Input() public deploymentActivity: Maybe<DeploymentActivityIndicator>;

  @Output() createInventoryClick: EventEmitter<void> = new EventEmitter();

  hasPermissionToCreateInventory$: Observable<boolean>;
  readonly getInventorySelectionItemClassName = (color: string) => InventoryColorHelper.getInventorySelectionItemClassName(color);
  readonly getInventoryBoxShadowClassName = (color: string) => InventoryColorHelper.getInventoryBoxShadowClassName(color);

  /** Signals if the project is not deployed yet, ie. there's no suggested inventory even without filtering. */
  public projectNotDeployedYet: boolean = true;
  /** The suggested inventories, filtered by the text. */
  public suggestedInventories: Array<Inventory> = [];
  public suggestedCollapsed: boolean = false;
  /** The other inventories, filtered by the text. */
  public otherInventories: Array<Inventory> = [];
  public otherCollapsed: boolean = false;

  constructor(store$: Store<AppState>) {
    super();
    this.hasPermissionToCreateInventory$ = store$.pipe(
      select(selectedTenantView),
      distinctUntilChanged(_.isEqual),
      filter((tenant: Tenant | null) => !_.isNil(tenant)),
      map((tenant: Tenant) => PermissionsHelper.objectHasPermission(tenant, OperationKey.CREATE_INVENTORY))
    );
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.activeItemIndex = _.findIndex(this.items, (item: Inventory) => item.inventoryKey === this.preSelectedInventoryKey);
    if (this.preSelectedInventoryKey && this.activeItemIndex >= 0) {
      this.itemSelected.emit(this.items[this.activeItemIndex]);
    }
    this.calculateGroupingAndFiltering();
  }

  private calculateGroupingAndFiltering() {
    const unFilteredSuggestedInventories: Array<Inventory> = this.items.filter((currentInventory: Inventory): boolean => {
      return (this.selectedProjectInventoryDeployments ?? []).some((deployment: ProjectInventoryDeployment): boolean => deployment.inventoryKey === currentInventory.inventoryKey);
    });
    this.suggestedInventories = PatternMasterListHelper.getWildCardFilteredItemList(unFilteredSuggestedInventories, this.filterText, (inventory) => TenantHelper.cropTenantFromKey(inventory.inventoryKey));

    const unfilteredOtherInventories: Array<Inventory> = this.items.filter((currentInventory: Inventory): boolean => {
      return !(this.selectedProjectInventoryDeployments ?? []).some((deployment: ProjectInventoryDeployment): boolean => deployment.inventoryKey === currentInventory.inventoryKey);
    });
    this.otherInventories = PatternMasterListHelper.getWildCardFilteredItemList(unfilteredOtherInventories, this.filterText, (inventory) => TenantHelper.cropTenantFromKey(inventory.inventoryKey));

    this.projectNotDeployedYet = unFilteredSuggestedInventories.length === 0;
  }

  public toggleSuggested() {
    this.suggestedCollapsed = !this.suggestedCollapsed;
  }

  public toggleOther() {
    this.otherCollapsed = !this.otherCollapsed;
  }

  public filterInventories(filterText: string) {
    this.filterText = filterText;
    this.calculateGroupingAndFiltering();
  }

  keyDownOnInventory(event: KeyboardEvent, index: number, listName: 'suggested' | 'other') {
    const currentInventorySelectionList: QueryList<ElementRef> = listName === 'suggested' ? this.suggestedSelectionList : this.otherSelectionList;
    const currentInventoryList: Array<Inventory> = listName === 'suggested' ? this.suggestedInventories : this.otherInventories;
    switch (event.key) {
      case Keys.UP_ARROW: {
        event.preventDefault();
        this.setPreviousInvElementActive(currentInventorySelectionList, currentInventoryList, index);
        break;
      }
      case Keys.DOWN_ARROW: {
        event.preventDefault();
        this.setNextInvElementActive(currentInventorySelectionList, currentInventoryList, index);
        break;
      }
    }
  }

  setNextInvElementActive(selectionList: QueryList<ElementRef>, inventories: Array<Inventory>, currentIndex: number) {
    const indexToSelect = currentIndex === inventories.length - 1 ? 0 : currentIndex + 1;
    const elem: Maybe<ElementRef> = this.getElementByIndex(selectionList, indexToSelect);
    this.focusButton(elem);
    this.activeItemIndex = indexToSelect;
    this.itemSelected.emit(inventories[indexToSelect]);
  }

  setPreviousInvElementActive(selectionList: QueryList<ElementRef>, inventories: Array<Inventory>, currentIndex: number) {
    const indexToSelect = currentIndex === 0 ? inventories.length - 1 : currentIndex - 1;
    const elem = this.getElementByIndex(selectionList, indexToSelect);
    this.focusButton(elem);
    this.activeItemIndex = indexToSelect;
    this.itemSelected.emit(inventories[indexToSelect]);
  }

  private focusButton(elem: Maybe<ElementRef>) {
      if (elem) {
        const button: Maybe<any> = elem.nativeElement?.querySelector('button');
        button?.focus();
      }
  }

  createInventory(): void {
    this.createInventoryClick.emit();
  }

  override getFilteredItemList = () => {
    return PatternMasterListHelper.getWildCardFilteredItemList(this.items, this.filterText, (inventory) => TenantHelper.cropTenantFromKey(inventory.inventoryKey));
  };

  get selectedInventory(): Inventory | undefined {
    return _.find(this.items, (item: Inventory) => item.inventoryKey === this.preSelectedInventoryKey);
  }
}

import { Subject } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ImportInventoryHelper } from './import-inventory.helper';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { FormHelper } from '../../common/helpers/form.helper';
import { VersionControlData } from '../../common/model/version-control-data.model';
import { INVENTORY_KEY_FORM_CONTROL_NAME, PUBLISH_REQUIRED_FORM_CONTROL_NAME, VERSION_CONTROL_FORM_CONTROL_NAME } from '../inventory-creation.model';
import { Inventory } from '../inventory.model';
import { InventoryValidators } from '../inventory.validators';
import { IPredefinedInventoryFormDataMixin, PredefinedInventoryFormDataMixin } from '../predefined-inventory-form-data.mixin';
import { INestedVersionControlFormMixin, NestedVersionControlFormMixin } from '../../common/components/version-control-form/nested-version-control-form.mixin';

@Component({
  selector: 'adm4-import-inventory',
  templateUrl: './import-inventory.component.html',
  styleUrls: ['../../common/styles/component-specific/modal-window.scss', '../../common/styles/component-specific/create-form.scss']
})
@Mixin([NestedVersionControlFormMixin, PredefinedInventoryFormDataMixin])
export class ImportInventoryComponent implements OnInit, OnDestroy, INestedVersionControlFormMixin, IPredefinedInventoryFormDataMixin {

  @Input() inventories: Inventory[];
  @Input() predefinedInventoryData: Inventory | null;
  @Input() selectedTenantKey: string;
  @Input() displayTenant: boolean;

  @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
  @Output() importInventory = new EventEmitter<Inventory>();
  @Output() closeClicked = new EventEmitter<any>();

  public form: UntypedFormGroup;

  readonly ERROR_INVALID_INVENTORY_KEY = 'The inventory key entered contains some invalid characters. Must be of format [A-Z0-9_-]';
  readonly ERROR_INVENTORY_KEY_REQUIRED = 'The inventory key is required';

  readonly VERSION_CONTROL_FORM_CONTROL_NAME = VERSION_CONTROL_FORM_CONTROL_NAME;
  readonly INVENTORY_KEY_FORM_CONTROL_NAME = INVENTORY_KEY_FORM_CONTROL_NAME;
  readonly PUBLISH_REQUIRED_FORM_CONTROL_NAME = PUBLISH_REQUIRED_FORM_CONTROL_NAME;

  destroyed$: Subject<boolean> = new Subject();

  // implemented by mixin NestedVersionControlFormMixin
  updateDefaultKeyOnVersionControlChange: (inventoryKeyFormControlName: string) => void;

  // implemented by mixin PredefinedInventoryFormDataMixin
  initialInventoryKeyFormValue: () => string;
  predefinedVersionControlData: () => VersionControlData | null;
  initialPublishRequiredFormValue: () => boolean;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.createFormGroup();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    group.addControl(this.INVENTORY_KEY_FORM_CONTROL_NAME, this.fb.control(this.initialInventoryKeyFormValue(), [
      Validators.pattern(/^[a-zA-Z0-9_-]+$/), Validators.required, InventoryValidators.checkExistingInventoryKey(this.inventories, this.selectedTenantKey)
    ]));
    group.addControl(this.PUBLISH_REQUIRED_FORM_CONTROL_NAME, this.fb.control(this.initialPublishRequiredFormValue()));
    return group;
  }

  addVersionControlForm(versionControlForm: UntypedFormGroup): void {
    this.form.addControl(this.VERSION_CONTROL_FORM_CONTROL_NAME, versionControlForm);
    if (_.isNil(this.predefinedInventoryData) || _.isNil(this.predefinedInventoryData.inventoryKey)) {
      this.updateDefaultKeyOnVersionControlChange(this.INVENTORY_KEY_FORM_CONTROL_NAME);
    }
  }

  submitImportInventory(): void {
    if (this.canSave) {
      const inventory: Inventory = ImportInventoryHelper.getInventoryFromFormValue(this.form.value, this.selectedTenantKey);
      this.importInventory.emit(inventory);
    }
  }

  shouldShowInventoryKeyErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[this.INVENTORY_KEY_FORM_CONTROL_NAME], errorKey);
  }

  get canSave(): boolean {
    const versionControlForm = this.form.controls[this.VERSION_CONTROL_FORM_CONTROL_NAME] as UntypedFormGroup;
    const versionControlValid: boolean = _.values(versionControlForm.controls).every(control => control.valid);
    return this.form.valid && versionControlValid;
  }

  get inventoryForTenantExistsError(): string {
    return `This key is already in use for tenant ${this.selectedTenantKey}`;
  }
}

import { NgModule } from '@angular/core';
import { ProjectSummaryContentComponent } from './project-summary-content/project-summary-content.component';
import { CommonModules } from '../../common/common.module';
import { ProjectSummaryComponent } from './project-summary/project-summary.component';
import { ProjectSummarySectionComponent } from './project-summary-section/project-summary-section.component';
import { ProjectSummaryEmptyComponent } from './project-summary-empty/project-summary-empty.component';
import { ProjectSummaryPatternsComponent } from './project-summary-patterns/project-summary-patterns.component';
import { ProjectSummaryVariablesComponent } from './project-summary-variables/project-summary-variables.component';
import { ProjectSummaryIssuesComponent } from './project-summary-issues/project-summary-issues.component';
import { ProjectSummaryChangesComponent } from './project-summary-changes/project-summary-changes.component';
import { ProjectSummaryReportTabSummaryComponent } from './project-summary-report/project-summary-report-tab-summary/project-summary-report-tab-summary.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ProjectSummaryReportTableComponent } from './project-summary-report/project-summary-report-table/project-summary-report-table.component';
import { MatTableModule } from '@angular/material/table';
import { ProjectSummaryReportTableCellComponent } from './project-summary-report/project-summary-report-table-cell/project-summary-report-table-cell.component';
import { ProjectSummaryQuickLinkComponent } from './project-summary-quick-link/project-summary-quick-link.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { ProjectSummaryReportTabApplicationsComponent } from './project-summary-report/project-summary-report-tab-applications/project-summary-report-tab-applications.component';
import { ProjectSummaryReportTabRealmsComponent } from './project-summary-report/project-summary-report-tab-realms/project-summary-report-tab-realms.component';
import { ProjectSummaryReportTabInstancesComponent } from './project-summary-report/project-summary-report-tab-instances/project-summary-report-tab-instances.component';
import { ProjectSummaryDescriptionComponent } from './project-summary-description/project-summary-description.component';

@NgModule({
  imports: [
    CommonModules,
    MatTabsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatInputModule,
  ],
  declarations: [
    ProjectSummaryContentComponent,
    ProjectSummaryEmptyComponent,
    ProjectSummaryComponent,
    ProjectSummarySectionComponent,
    ProjectSummaryPatternsComponent,
    ProjectSummaryVariablesComponent,
    ProjectSummaryIssuesComponent,
    ProjectSummaryChangesComponent,
    ProjectSummaryReportTabSummaryComponent,
    ProjectSummaryReportTableComponent,
    ProjectSummaryReportTableCellComponent,
    ProjectSummaryQuickLinkComponent,
    ProjectSummaryReportTabApplicationsComponent,
    ProjectSummaryReportTabRealmsComponent,
    ProjectSummaryReportTabInstancesComponent,
    ProjectSummaryDescriptionComponent,
  ],
  exports: [
    ProjectSummaryComponent
  ]
})
export class ProjectSummaryModule { }

export interface CopyModel {
  copyMode: CopyMode;
  patternIds: string[];
  targetProjectKey: string;
  includeVariables: boolean;
  prefix?: string;
  postfix?: string;
}

export enum CopyMode {
  DRY_RUN = 'DRY_RUN',
  DRY_RUN_NEW = 'DRY_RUN_NEW',
  FORCE  = 'FORCE',
  NEW  = 'NEW',
  NORMAL = 'NORMAL'
}

export interface PatchInstance {
  id: string;
  op: PatchOption;
  path: string;
  value?: any;
}

export enum PatchOption {
  REMOVE = 'remove',
  ADD  = 'add'
}

export interface PatternCopyOption {
  name: PatternCopyOptionName;
  checked: boolean
}

export enum PatternCopyOptionName {
  LINKED_COPY = 'Link to the source pattern',
  VARIABLE_COPY = 'Copy with variables'
}

export const defaultPatternCopyOptions = [{
  name: PatternCopyOptionName.LINKED_COPY,
  checked: true
}, {
  name: PatternCopyOptionName.VARIABLE_COPY,
  checked: false
}];

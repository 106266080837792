import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { MatDialog } from '@angular/material/dialog';

import { Observable, of } from 'rxjs';
import { catchError, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { CreateCustomProjectTemplateDialogComponent, CreateCustomProjectTemplateDialogConfig } from './create-custom-project-template-dialog/create-custom-project-template-dialog.component';
import { Maybe } from '../../common/utils/utils';
import { ProjectService } from '../project.service';
import { NavigationService } from '../../navbar/navigation.service';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { MarketplaceApiService } from '../../resources/marketplace/marketplace-api.service';
import { ProjectTemplate } from '../../resources/marketplace/marketplace.model';

@Injectable()
export class ProjectTemplatesService {

  constructor(
      private matDialog: MatDialog,
      private projectApi: ProjectService,
      private marketplaceApi: MarketplaceApiService,
      private nav: NavigationService,
      private modals: ModalNotificationService,
      private toasts: ToastNotificationService,
  ) { }

  /**
   * Starts the project template creation with the dialog.<br/>
   * If the projectKey is specified, that project will be preselected,
   * and it will not be possible to change it in the dialog.<br/>
   * If the custom project template is successfully created, the user will be navigated there and the promise will resolve to `true`.<br/>
   * If the no custom project template (user cancels or an error happens), the promise will resolve to `false`
   * @param selectedProjectKey
   */
  public startProjectTemplateCreation(selectedProjectKey: Maybe<string>): Promise<boolean> {
    let creationResult: Observable<boolean>;
    if (selectedProjectKey) {
        creationResult = this.projectApi.hasProjectDescription(selectedProjectKey).pipe(
          take(1),
          switchMap((hasDescription: boolean): Observable<boolean> => {
            if (hasDescription) {
              return this.startProjectTemplateCreationDialog(selectedProjectKey);
            } else {
              this.modals.openErrorDialog({
                headerTitle: 'Error', title: `Missing project description`,
                description: `Project description is required to create a custom template.
                  Please fill it in the <strong>About Project</strong> section on the <strong>Overview</strong> page.`,
              });
              return of(false);
            }
          }),
      );
    } else {
        creationResult = this.startProjectTemplateCreationDialog(selectedProjectKey);
    }
    return new Promise(resolve =>
        creationResult.pipe(take(1))
            .subscribe((created: boolean): void => resolve(created))
    );
  }

  private startProjectTemplateCreationDialog(selectedProjectKey: Maybe<string>): Observable<boolean> {
    const matDialogRef = this.matDialog.open<CreateCustomProjectTemplateDialogComponent, CreateCustomProjectTemplateDialogConfig, ProjectTemplate>(
        CreateCustomProjectTemplateDialogComponent,
        {
          data: {selectedProjectKey},
          maxWidth: '600px',
        },
    );
    return matDialogRef.afterClosed().pipe(
      take(1),
      map((newProjectTemplate: ProjectTemplate): boolean => {
        if (newProjectTemplate) {
          this.toasts.showSuccessToast(`Custom project template <strong>${newProjectTemplate.title}</strong> successfully created.`, 'Successfully created');
          this.nav.navigateToMarketPlaceTemplate(newProjectTemplate.projectTemplateKey);
          return true;
        }
        return false;
      }),
    );
  }

  public deleteProjectTemplateWithConfirmation(tenantKey: string, pt: ProjectTemplate): Promise<boolean> {
      return new Promise((resolve): void => {
          const dialog = this.modals.openConfirmDialog(
              {
                  title: 'Delete Custom Project Template', headerTitle: 'Warning',
                  description: `You are deleting the custom project template <strong>${pt.title}</strong>. The deletion is irreversible. This can not be undone.`,
              },
              {confirmButtonText: 'Delete', cancelButtonText: 'Cancel'},
          );
          dialog.afterClosed()
              .pipe(
                  take(1),
                  switchMap((confirmed: Maybe<boolean>): Observable<boolean> => {
                    if (confirmed) {
                      return this.deleteProjectTemplate(tenantKey, pt);
                    }
                    return of(false);
                  }),
              ).subscribe((deleted: boolean): void => resolve(deleted));
      });
  }

  private deleteProjectTemplate(tenantKey: string, pt: ProjectTemplate): Observable<boolean> {
      return this.marketplaceApi.deleteProjectTemplate(tenantKey, pt.projectTemplateKey).pipe(
          mapTo(true),
          tap(() => this.toasts.showSuccessToast(`Custom project template <strong>${pt.title}</strong> has been successfully deleted.`, 'Successfully deleted')),
          catchError((error: HttpErrorResponse) => this.modals.openHttpErrorDialog(
              error,
              'Could not delete custom project template.',
          ).afterClosed().pipe(mapTo(false))),
      );
  }
}

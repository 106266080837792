<mat-table multiTemplateDataRows matSort class='adm4-mat-table full-width deployment-history' [dataSource]='tableDataSource'>
  <!--  Status Column -->
  <ng-container [matColumnDef]="deploymentInstanceTableColumns.StatusColumnName">
    <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf='element.status'>{{element.status | capitalizeFirst}}</ng-container>
      <mat-icon *ngIf='getDeploymentInstanceErrorByHost(element.host)' [ngClass]='resolveDeploymentErrorIconClass(element.host)'
                [ngbTooltip]='deployProblemTooltip' placement='right'>{{resolveDeploymentErrorStatusIcon(element.host)}}</mat-icon>
      <ng-template #deployProblemTooltip>
        <div [innerHTML]='displayedDeploymentErrorTooltip(element.host)'></div>
      </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Host -->
  <ng-container [matColumnDef]="deploymentInstanceTableColumns.HostColumnName">
    <mat-header-cell *matHeaderCellDef> Host</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class='project-key-text' [ngbTooltip]='hostNamePopover' placement='top-left'>{{element.host}}</div>
      <ng-template #hostNamePopover>{{element.host}}</ng-template>
    </mat-cell>
  </ng-container>

  <!-- Instance Column -->
  <ng-container [matColumnDef]="deploymentInstanceTableColumns.InstanceColumnName">
    <mat-header-cell *matHeaderCellDef> Instance name</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div>{{element.instance}}</div>
    </mat-cell>
  </ng-container>

  <!-- Component Column -->
  <ng-container [matColumnDef]="deploymentInstanceTableColumns.ComponentColumnName">
    <mat-header-cell *matHeaderCellDef> Component</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div>{{element.component}}</div>
    </mat-cell>
  </ng-container>

  <!-- Action Column -->
  <ng-container [matColumnDef]="deploymentInstanceTableColumns.ActionColumnName">
    <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf='hasMultipleAction(element.actions); else singleActionBtn'>
        <adm4-split-button *ngIf='hasDeployPermission && element.actions'
                           [primaryButtonText]='getPrimaryAction(element.actions) | capitalizeFirst'
                           (primaryClick)='triggerDeploymentAction(element, getPrimaryAction(element.actions))'
                           (click)='$event.stopPropagation()'>
          <div mat-menu-item *ngFor='let action of getSecondaryActions(element.actions)'
               (click)='triggerDeploymentAction(element, action)'>{{action | capitalizeFirst}}
          </div>
        </adm4-split-button>
      </ng-container>
      <ng-template #singleActionBtn>
        <button *ngIf='hasDeployPermission && element.actions'
                type='button' class='admn4-button-ellipse-yellow'
                [adm4StopEventPropagation]='["click"]'
                (click)='triggerDeploymentAction(element, getPrimaryAction(element.actions))'
        >{{getPrimaryAction(element.actions) | capitalizeFirst}}</button>
      </ng-template>

    </mat-cell>
  </ng-container>

  <!-- Expand Icon Column -->
  <ng-container [matColumnDef]="deploymentInstanceTableColumns.ExpandIconColumn">
    <mat-header-cell *matHeaderCellDef class='expand-icon-cell'></mat-header-cell>
    <mat-cell *matCellDef="let element" class='expand-icon-cell'>
      <mat-icon
              *ngIf='hasDetailedContent(element)'>{{this.isDeploymentExpanded(element) ? 'expand_less' : 'expand_more'}}</mat-icon>
    </mat-cell>
  </ng-container>

  <!-- Expanded Content for deployment instance - The details of deployment row is made up of this one column that spans across all columns -->
  <ng-container [matColumnDef]="deploymentDetailsColumnName">
    <mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
      <div *ngIf='hasDetailedContent(element) && isDeploymentExpanded(element)'
           class='deployment-details-container host-status-deployment-details'>
        <div class='deployment-details full-width deployment-instance-details'>
          <div *ngIf='element.projectKey'>
              <div class='deployment-detail-header'>Project deployed</div>
              {{element.projectKey | cropTenantFromKey}}
          </div>
          <div *ngIf='shouldDisplayPatternId(element)'>
            <div class='deployment-detail-header second-row-header'>Instance configuration</div>
            <a href="javascript:void(0)" (click)='navigateToPattern(element.projectKey, element.patternId)'>Go to linked pattern</a>
          </div>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" (click)='toggleDeploymentDetails(row)'
           class='deployment-history-row'></mat-row>
  <mat-row *matRowDef="let row; columns: expandableColumns;" class='expanded-deployment-details'
           [class.expanded]='isDeploymentExpanded(row)'></mat-row>
</mat-table>

<adm4-modal-dialog-title [header]='header'
                         [showClose]='true'
                         (closeClicked)="closeDialog()">
  <div class='full-height-flex'>
    <div class='rules-dialog-container content-container remaining-space-flex-content-wrapper'>
      <div class='info-icon-container'>
        <em class="fa fa-info-circle help-icon" [ngbTooltip]='"Insert one log message per line"' placement="top-left"></em>
        <div>Copy log snippets with <em>IW4ModsecF</em> from the nevisProxy navajo.log. <br>
          <strong>Insert one log per line</strong> in the first box and convert to allowlist rules.
        </div>
      </div>
      <div class='whitelist-editor-container' #editorContainer>
        <ngx-monaco-editor [(ngModel)]='editedContent'
                           [options]='editorOptions'
                           (ngModelChange)='inputModelChanged()'
                           (onInit)='setInitialFocus($event)'>
        </ngx-monaco-editor>
      </div>
      <div class="validation-message-container" *ngIf='hasInputWarning$ | async'>
          <adm4-validation-message *ngFor='let warning of inputWarnings$ | async' [isWarning]='true' [message]='warning.detail'></adm4-validation-message>
      </div>
      <div class='convert-button-container'>
        <button class='admn4-button-ellipse-blue' type='button' [disabled]="isConvertDisabled"
                (click)='convertToRule()'>Convert to rule
        </button>
      </div>
      <div class='whitelist-editor-container output-rules-container' #outputRuleContainer>
        <ngx-monaco-editor [(ngModel)]='convertedResult'
                           [options]='outputOptions'
                           (ngModelChange)='outputModelChanged()'>
        </ngx-monaco-editor>
      </div>
      <div class="validation-message-container output-validation-message" *ngIf='hasOutputWarning$ | async'>
        <adm4-validation-message *ngFor='let warning of outputWarnings$ | async' [isWarning]='true' [message]='warning.detail'></adm4-validation-message>
      </div>
    </div>
    <div mat-dialog-actions>
      <button class='admn4-button-text' (click)='closeDialog()'>Cancel</button>
      <button class='admn4-button-ellipse-blue' [disabled]='!canConfirmInsert' (click)="applyEdit()">
        Insert
      </button>
    </div>
  </div>
</adm4-modal-dialog-title>

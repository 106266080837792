import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { Maybe } from '../utils/utils';

@Pipe({name: 'highlight'})
export class HighLightPipe implements PipeTransform {
  /**
   * It highlights all occurrences of given text. The text to be highlighted is put inside a span with the class
   * highlight and then the whole text is put inside another span with class greyed-out on which we can apply
   * CSS to highlight and grey out the text
   * It should be used in an innerHtml element only, because it contains HTML span elements which shouldn't be displayed
   * as text
   * @param text in which occurs
   * @param textToHighlight
   * @returns {string} the highlighted text
   */
  transform(text: Maybe<string>, textToHighlight: any): string {
    if (typeof text !== 'string') {
      return '';
    }
    if (_.isNil(textToHighlight)) {
      return text;
    }

    const regex = new RegExp(_.replace(_.escapeRegExp(_.trim(textToHighlight)), ' ', '.*'), 'gi');

    if (textToHighlight) {
      const match = regex.exec(text);
      if (match !== null) {
        text = text.replace(new RegExp(_.escapeRegExp(match[0]), 'gi'), `<span class="highlight">${match[0]}</span>`);
      }
      text = `<span class="greyed-out">${text}</span>`;
    }
    return text;
  }
}



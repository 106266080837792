import { ActionReducerMap } from '@ngrx/store';
import * as fromBundleManagement from './bundle-management.reducer';

export interface ResourcesState {
  bundleManagement: fromBundleManagement.BundleManagementState;
}

export const reducers: ActionReducerMap<ResourcesState> = {
  bundleManagement: fromBundleManagement.reducer,
};

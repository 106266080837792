export interface FileUploadResult<ResultType = undefined> {
  fileName: string;
  status: FileUploadStatus;
  errorDetail?: string;
  result?: ResultType;
}

export enum FileUploadStatus {
  Success,
  Fail
}

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { EMPTY, merge, Observable } from 'rxjs';
import { filter, map, mapTo, share, shareReplay, skip, startWith } from 'rxjs/operators';

import * as _ from 'lodash';

import { AppState } from '../../model/reducer';
import { inventoryKeyView, projectKeyView } from '../../model/views';
import { NavigationConstants } from '../constants/navigation.constants';
import { ApplicationTitleHelper } from './application-title/application-title.helper';
import { PatternActionTypes} from '../../model/pattern';
import { NevisAdminAction} from '../../model/actions';

function getInitialUrl(regexp: RegExp): string | undefined {
  const initialUrl: string | null = _.isNil(document.location) ? null : document.location.hash;
  return !_.isNil(initialUrl) && regexp.test(initialUrl) ? initialUrl : undefined;
}

@Injectable()
export class DynamicLinkCreatorService {

  private regexpResources = new RegExp('\/resources\/');
  private regexpInventories = new RegExp('\/infrastructure\/inventories');
  private regexpConfiguration = new RegExp('\/projects\/');
  private regexpConfigurationPatterns = new RegExp('\/projects\/[a-zA-Z0-9-]+\/patterns\/.*');
  private regexpIssuesMenu = new RegExp('\/issues');
  private regexpVariablesMenu = new RegExp('\/variables');
  private regexpReportsMenu = new RegExp('\/reports');
  private regexpProjectSettingsMenu = new RegExp('\/project-settings');
  private regexpInventorySettingsMenu = new RegExp('\/inventory-settings');
  private regexpProjectOverview = new RegExp('\/overview\/');

  private USE_HASH_LOCATION_STRATEGY = '#';
  private readonly urlChanges: Observable<string>;

  inventoriesLink: Observable<string>;
  configurationLink: Observable<string>;
  resourcesLink: Observable<string>;

  configPatternsLink: Observable<string | undefined>;
  issuesMenuLink: Observable<string | undefined>;
  variablesMenuLink: Observable<string | undefined>;
  reportsMenuLink: Observable<string | undefined>;
  projectSettingsMenuLink: Observable<string | undefined>;
  inventorySettingsMenuLink: Observable<string | undefined>;
  projectOverviewLink: Observable<string | undefined>;

  constructor(
      private router: Router,
      private store$: Store<AppState>,
      private actions$: Actions<NevisAdminAction<any>>,
    ) {
    const projectDefaultUrl = this.USE_HASH_LOCATION_STRATEGY + '/projects/';
    const infrastructureDefaultUrl = this.USE_HASH_LOCATION_STRATEGY + '/infrastructure/';
    const resourcesDefaultUrl = this.USE_HASH_LOCATION_STRATEGY + `/${NavigationConstants.RESOURCES}/${NavigationConstants.GLOBAL_CONSTANTS}/`;

    const projectChanges: Observable<void> = this.store$.pipe(select(projectKeyView), skip(1), mapTo(undefined));
    const deletePatternEvents: Observable<void> = this.actions$.pipe(
      ofType(PatternActionTypes.DeletePattern),
      mapTo(undefined),
      share(),
    );

    this.urlChanges = this.router.events.pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd && !ApplicationTitleHelper.isModalOpen(e.urlAfterRedirects)),
        map((navigationEnd: NavigationEnd) => navigationEnd.urlAfterRedirects),
        map(url => this.USE_HASH_LOCATION_STRATEGY + url),
        share(),
    );

    const inventoryChanges = this.store$.pipe(select(inventoryKeyView));
    // the default URL (which the resetStream is mapTo'd to) is undefined,
    // because the first navigation is handled in the project menu and the pattern main component
    this.configPatternsLink = this.createUrlStream(this.regexpConfigurationPatterns, undefined, merge(projectChanges, deletePatternEvents));
    this.projectOverviewLink = this.createUrlStream(this.regexpProjectOverview, projectDefaultUrl, projectChanges);
    this.issuesMenuLink = this.createUrlStream(this.regexpIssuesMenu, undefined, projectChanges);
    this.variablesMenuLink = this.createUrlStream(this.regexpVariablesMenu, undefined, projectChanges);
    this.reportsMenuLink = this.createUrlStream(this.regexpReportsMenu, undefined, projectChanges);
    this.projectSettingsMenuLink = this.createUrlStream(this.regexpProjectSettingsMenu, undefined, projectChanges);
    this.inventorySettingsMenuLink = this.createUrlStream(this.regexpInventorySettingsMenu, undefined, inventoryChanges);
    this.inventoriesLink = this.createUrlStream(this.regexpInventories, infrastructureDefaultUrl, inventoryChanges);
    this.configurationLink = this.createUrlStream(this.regexpConfiguration, projectDefaultUrl, projectChanges);

    this.resourcesLink = this.createUrlStream(this.regexpResources, resourcesDefaultUrl);
  }

  createUrlStream<T>(urlMatcher: RegExp, initialFallbackUrl: T, resetStream: Observable<any> = EMPTY): Observable<string | T> {
    const initialUrl: string | T = getInitialUrl(urlMatcher) || initialFallbackUrl;
    return merge(
      this.urlChanges.pipe(
        filter(url => urlMatcher.test(url))
      ),
      resetStream.pipe(map(() => initialFallbackUrl)),
    ).pipe(
      startWith(initialUrl),
      shareReplay(1)
    );
  }
}

import { OperationColumnData } from '../../../permissions/model/operation-column-data.model';
import { OperationKey } from '../../../model/permissions/permissions.model';

export const inventoryOperationColumns: OperationColumnData[] = [
  {
    operationKey: OperationKey.ADMIN_INVENTORY,
    columnKey: 'administrate',
    columnLabel: 'Administrate'
  },
  {
    operationKey: OperationKey.DEPLOY_INVENTORY,
    columnKey: 'deploy',
    columnLabel: 'Deploy'
  },
  {
    operationKey: OperationKey.MODIFY_INVENTORY,
    columnKey: 'modify',
    columnLabel: 'Modify'
  },
  {
    operationKey: OperationKey.VIEW_INVENTORY,
    columnKey: 'view',
    columnLabel: 'View'
  },
  {
    operationKey: OperationKey.VIEW_SECRET_CONTENT_INVENTORY,
    columnKey: 'view_secret',
    columnLabel: 'View secret'
  }
];

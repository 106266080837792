import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MetaInfo } from '../../../version-control/meta-info.model';
import * as _ from 'lodash';
import { InventoryChangesetItem } from '../inventory-changeset-item.model';
import { Dictionary } from '../../../model/reducer';
import { SplitPaneConfig } from '../../../common/model/split-pane-config.model';
import { IOutputData } from 'angular-split/lib/interface';
import { ResizeHelper } from '../../../common/helpers/resize.helper';
import { Mixin } from '../../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../../common/mixins/split.mixin';

@Component({
  selector: 'adm4-publish-inventory-changeset',
  templateUrl: './publish-inventory-changeset.component.html',
  styleUrls: ['./publish-inventory-changeset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@Mixin([SplitMixin])
export class PublishInventoryChangesetComponent implements ISplitMixin, AfterViewInit {

  @Input() changesetItems: InventoryChangesetItem<MetaInfo, any>[];
  @Input() initialCommitMessage: string;
  @Input() currentUserName: string | null;
  @Output() commitMessageChanged: EventEmitter<string> = new EventEmitter();
  @Output() changesetItemSelected: EventEmitter<InventoryChangesetItem<MetaInfo, any>> = new EventEmitter();

  selectedChangesetItem?: InventoryChangesetItem<MetaInfo, any>;

  readonly changesetSplitAreaKey = 'changeset';
  readonly commitMessageSplitAreaKey = 'commit-message';
  readonly splitPaneConfigLocalStorageKey = 'publish-inventory-changeset-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.changesetSplitAreaKey]: {order: 0, size: 75},
    [this.commitMessageSplitAreaKey]: {order: 1, size: 25}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  constructor() {
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const initialInventorySelection = this.changesetItems[0];
      this.selectChangesetItem(initialInventorySelection);
    });
  }

  isSelectedChangesetItem(changesetItem: InventoryChangesetItem<MetaInfo, any>): boolean {
    return !_.isNil(this.selectedChangesetItem) && this.selectedChangesetItem.key === changesetItem.key;
  }

  onCommitMessageChange(commitMesage: string): void {
    this.commitMessageChanged.emit(commitMesage);
  }

  selectChangesetItem(selectChangesetItem: InventoryChangesetItem<MetaInfo, any>): void {
    if (this.isSelectedChangesetItem(selectChangesetItem)) {
      return;
    }
    this.selectedChangesetItem = selectChangesetItem;
    this.changesetItemSelected.emit(selectChangesetItem);
  }

}

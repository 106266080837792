import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map, mapTo,
  switchMap,
  take,
  takeUntil,
  tap,
  timeout,
  withLatestFrom,
} from 'rxjs/operators';
import * as _ from 'lodash';

import { AppState } from '../../model/reducer';
import {
  inventoryResourceColumns,
  inventorySecretColumns,
  SecretManagementTableModel,
} from './secret-management-table/secret-management-table.model';
import {
  Inventory,
  InventoryResourceType,
  PreselectedResource,
  ResourceWrapperWithUsage,
  SecretLikeWrapperWithUsage,
  SecretResourceWrapperWithUsage,
  SecretWrapperWithUsage,
  TenantResourceFlag,
} from '../../inventory/inventory.model';
import {
  InventoryResourceActionDialogService,
} from './inventory-resource-action/inventory-resource-action-dialog.service';
import {
  CreateTenantLevelInventoryResourceDialogPayload,
  InventoryResourceContentActionDialogPayload,
  ReplaceInventoryResourceDialogPayload,
} from './inventory-resource-action/inventory-resource-action-dialog-payload.model';
import { resourcesWithUsageView, secretResourcesWithUsageView, secretsWithUsageView, } from '../../model/views';
import { LoadInventoryResourcesWithUsage, LoadInventoryTenantScopedResourcesWithUsage } from '../../model/inventory';
import { SecretManagementHelper } from './secret-management.helper';
import { InventoryResourceTypeHelper } from './inventory-resource-type.helper';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { InventoryService } from '../../inventory/inventory.service';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import {
  InventoryResourceActionDialogHelper
} from './inventory-resource-action/inventory-resource-action-dialog.helper';
import { ErrorHelper } from '../../common/helpers/error.helper';
import { SecretManagementContextService } from './secret-management-context.service';
import { filterNotNil, Maybe } from '../../common/utils/utils';
import { NavigationService } from '../../navbar/navigation.service';
import { TenantHelper } from '../../common/helpers/tenant.helper';
import {
  inventorySecretResourceReferencePrefix,
  secretReferencePrefix,
} from '../../common/constants/reference-prefix.constants';

@Component({
  selector: 'adm4-secret-management',
  template: `
      <div class='full-height-flex'>
        <div class='secret-management-header'>
          <adm4-column-header [styleClass]='"light-inline-header"'>
            <span>Secret & Files</span>
          </adm4-column-header>
        </div>
        <div class='remaining-space-flex-content-wrapper bg-screen-white'>
          <div class='remaining-space-flex-content'>
            <div class='full-height-flex details-container'>
              <div class='filters-wrapper'>
                <label for='scope' class='input-label scope-label'>Scope: </label>
                <mat-form-field class='scope-selection'>
                  <mat-select id='scope' #scopeSelection
                              [placeholder]='"Global"'
                              [formControl]='scopeSelectionControl'
                              [disableRipple]='true'
                              [disableOptionCentering]='true' 
                              (click)='focusDropdownInput()'>
                    <adm4-searchable-dropdown-input *ngIf='scopeSelection.focused'
                                                    [sourceItems]='scopes$ | async'
                                                    [placeholder]="'Please select a scope...'"
                                                    [searchableFormatFn]='searchableProjectFormatFn'
                                                    [focusTrigger]='searchableDropdownInputFocusTrigger$'
                                                    (filteredResult)="updateSearchResult($event)"></adm4-searchable-dropdown-input>
                    <mat-option *ngFor='let scopeOption of scopeOptions$ | async' [value]='scopeOption.value' [hidden]='!isScopeFilteredOut(scopeOption.value)'>
                      {{ scopeOption.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div class='search-filter'>
                  <adm4-filter (filter)="filterBySearch($event)"
                               [placeholderText]='"Type to filter..."'
                               [filterText]="filterText"
                               class='search-input'>
                  </adm4-filter>
                </div>
              </div>
              <div class='remaining-space-flex-content-wrapper'>
                <div class='remaining-space-flex-content'>
                    <p class="mb-0">The tables show the secret and files created on the inventory and global level. Project level secrets are not displayed here.
                        You can create global secrets and files and use them easily in your inventories by inserting the reference IDs.</p>
                  <div class='section-title first-section-title'>
                    Secrets
                    <ng-container *ngIf='(selectedTenantKey$ | async) as tenantKey'>
                      <button type="submit" class='admn4-button-ellipse-blue'
                              [disabled]='!(hasModifyTenantPermission$ | async)'
                              [title]='getCreateActionBtnTooltip(hasModifyTenantPermission$ | async, inventoryResourceTypes.SECRET_FILE)'
                              (click)='openCreateInventorySecretDialog(tenantKey, inventoryResourceTypes.PLAIN_TEXT_SECRET)'>Create global secret
                      </button>
                    </ng-container>
                  </div>
                  <ng-container *ngIf='((secrets$ | async)??[]).length > 0; else noSecretFound'>
                    <adm4-secret-management-table
                            [displayedColumns]='displayedColumnsForSecrets'
                            [resourceGroup]='secrets$ | async'
                            [resourceType]='inventoryResourceTypes.PLAIN_TEXT_SECRET'
                            [filterText]='filterText'
                            [selectedScope]='selectedScope$ | async'
                            (expandScopeToTenantResource)="extendSecretScopeToTenant($event)"
                            (viewResource)='viewPlainTextSecret($event)'
                            (editResource)='editPlainTextSecret($event)'
                            (deleteResource)='deletePlainTextSecret($event)'>
                    </adm4-secret-management-table>
                  </ng-container>
                  <div class='section-title'>
                    Secret files
                    <ng-container *ngIf='(selectedTenantKey$ | async) as tenantKey'>
                      <button type="submit" class='admn4-button-ellipse-blue'
                              [disabled]='!(hasModifyTenantPermission$ | async)'
                              [title]='getCreateActionBtnTooltip(hasModifyTenantPermission$ | async, inventoryResourceTypes.SECRET_FILE)'
                              adm4FileLoader [multiFile]='false'
                              (load)='openCreateInventorySecretDialog(tenantKey, inventoryResourceTypes.SECRET_FILE, $event)'>Upload global secret file
                      </button>
                    </ng-container>
                  </div>
                  <ng-container *ngIf='((secretResources$ | async)??[]).length > 0;else noSecretResourceFound'>
                    <adm4-secret-management-table
                            [displayedColumns]='displayedColumnsForFiles'
                            [resourceGroup]='secretResources$ | async'
                            [resourceType]='inventoryResourceTypes.SECRET_FILE'
                            [filterText]='filterText'
                            [selectedScope]='selectedScope$ | async'
                            (expandScopeToTenantResource)="extendSecretResourceScopeToTenant($event)"
                            (viewResource)='viewResource($event, inventoryResourceTypes.SECRET_FILE)'
                            (editResource)='editResource($event, inventoryResourceTypes.SECRET_FILE)'
                            (deleteResource)='deleteSecretFile($event)'>
                    </adm4-secret-management-table>
                  </ng-container>
                  <div class='section-title'>
                    Files
                    <ng-container *ngIf='(selectedTenantKey$ | async) as tenantKey'>
                      <button type="submit" class='admn4-button-ellipse-blue'
                              [disabled]='!(hasModifyTenantPermission$ | async)'
                              [title]='getCreateActionBtnTooltip(hasModifyTenantPermission$ | async, inventoryResourceTypes.FILE)'
                              adm4FileLoader [multiFile]='false'
                              (load)='openCreateInventorySecretDialog(tenantKey, inventoryResourceTypes.FILE, $event)'>Upload global file
                      </button>
                    </ng-container>
                  </div>
                  <ng-container *ngIf='((resources$ | async)??[]).length > 0;else noFileFound'>
                    <adm4-secret-management-table
                            [displayedColumns]='displayedColumnsForFiles'
                            [resourceGroup]='resources$ | async'
                            [resourceType]='inventoryResourceTypes.FILE'
                            [filterText]='filterText'
                            [selectedScope]='selectedScope$ | async'
                            (expandScopeToTenantResource)="extendResourceScopeToTenant($event)"
                            (viewResource)='viewResource($event, inventoryResourceTypes.FILE)'
                            (editResource)='editResource($event, inventoryResourceTypes.FILE)'
                            (deleteResource)='deleteFile($event)'>
                    </adm4-secret-management-table>
                  </ng-container>
                  <ng-template #noSecretFound>
                    <adm4-empty-resource><span>There is no secret found.</span></adm4-empty-resource>
                  </ng-template>
                  <ng-template #noSecretResourceFound>
                    <adm4-empty-resource><span>There is no secret file found.</span></adm4-empty-resource>
                  </ng-template>
                  <ng-template #noFileFound>
                    <adm4-empty-resource><span>There is no file found.</span></adm4-empty-resource>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  `,
  styleUrls: ['./secret-management.component.scss', '../../common/styles/component-specific/settings-details.scss'],
  providers: [SecretManagementContextService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecretManagementComponent implements OnDestroy {
  selectedTenantKey$: Observable<string>;
  inventories$: Observable<Inventory[]>;
  displayedColumnsForSecrets: Readonly<Array<SecretManagementTableModel>>;
  displayedColumnsForFiles: Readonly<Array<SecretManagementTableModel>>;
  scopes$: Observable<string[]>;
  scopeOptions$: Observable<Array<{label: string, value: string}>>;
  selectedScope$: Observable<string>;
  inventoryResourceTypes = InventoryResourceType;
  scopeSelectionControl = new UntypedFormControl();
  filteredScopes: string[];
  selectedTenantKey: string;

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  hasViewSecretContentTenantLevelPermission$: Observable<boolean>;
  hasViewSecretContentInventoryLevelPermission$: Observable<boolean>;
  hasModifyInventoryPermission$: Observable<boolean>;
  hasModifyTenantPermission$: Observable<boolean>;

  secrets$: Observable<(SecretWrapperWithUsage & TenantResourceFlag)[]>;
  secretResources$: Observable<(SecretResourceWrapperWithUsage & TenantResourceFlag)[]>;
  resources$: Observable<(ResourceWrapperWithUsage & TenantResourceFlag)[]>;

  public filterText = '';
  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private store$: Store<AppState>,
    private context: SecretManagementContextService,
    private inventoryResourceActionDialogService: InventoryResourceActionDialogService,
    private modalNotificationService: ModalNotificationService,
    private inventoryService: InventoryService,
    private toastNotificationService: ToastNotificationService,
    private nav: NavigationService,
  ) {
    this.displayedColumnsForSecrets = inventorySecretColumns;
    this.displayedColumnsForFiles = inventoryResourceColumns;
    this.selectedTenantKey$ = this.context.selectedTenantKey$;
    this.inventories$ = this.context.inventories$;
    this.hasViewSecretContentTenantLevelPermission$ = this.context.hasViewSecretContentTenantLevelPermission$;
    this.hasViewSecretContentInventoryLevelPermission$ = this.context.hasViewSecretContentInventoryLevelPermission$;
    this.hasModifyInventoryPermission$ = this.context.hasModifyInventoryPermission$;
    this.hasModifyTenantPermission$ = this.context.hasModifyTenantPermission$;

    this.scopes$ = this.context.scopes$;
    this.scopeOptions$ = this.context.scopeOptions$;
    this.selectedScope$ = this.context.selectedScope$;

    this.scopeSelectionControl.valueChanges.pipe(
      withLatestFrom(this.selectedTenantKey$),
      filter(([selectedScope, selectedTenantKey]: [string, string]) => !!selectedScope && !!selectedTenantKey),
      takeUntil(this.destroyed$),
      tap(([selectedScope, selectedTenantKey]: [string, string]) => {
        this.triggerInventoryResourceLoad(selectedScope, selectedTenantKey);
        this.context.updateSelectedScope(selectedScope);
      }),
    ).subscribe();

    this.context.selectedTenantKey$.pipe(
        filterNotNil(),
        first(),
        takeUntilDestroyed(),
    ).subscribe(selectedTenantKey => {
      this.selectedTenantKey = selectedTenantKey;
      this.scopeSelectionControl.setValue(selectedTenantKey);
    });

    this.secrets$ = combineLatest([
      this.context.selectedScope$,
      this.context.selectedTenantKey$,
      this.store$.select(secretsWithUsageView),
    ]).pipe(
      map(([selectedScope, selectedTenantKey, secrets]: [string, string, (SecretWrapperWithUsage & TenantResourceFlag)[]]) => {
        return SecretManagementHelper.isTenantScopedSelection(selectedScope, selectedTenantKey) ? this.filterOutNonTenantScopedResources(secrets) : secrets;
      }),
    );

    this.secretResources$ = combineLatest([
      this.context.selectedScope$,
      this.context.selectedTenantKey$,
      this.store$.select(secretResourcesWithUsageView),
    ]).pipe(
        map(([selectedScope, selectedTenantKey, secretResources]: [string, string, (SecretResourceWrapperWithUsage & TenantResourceFlag)[]]) => {
          return SecretManagementHelper.isTenantScopedSelection(selectedScope, selectedTenantKey) ? this.filterOutNonTenantScopedResources(secretResources) : secretResources;
        }),
    );

    this.resources$ = combineLatest([
      this.context.selectedScope$,
      this.context.selectedTenantKey$,
      this.store$.select(resourcesWithUsageView),
    ]).pipe(
        map(([selectedScope, selectedTenantKey, resources]: [string, string, (ResourceWrapperWithUsage & TenantResourceFlag)[]]) => {
          return SecretManagementHelper.isTenantScopedSelection(selectedScope, selectedTenantKey) ? this.filterOutNonTenantScopedResources(resources) : resources;
        }),
    );

    this.context.preselectedResource$.pipe(takeUntilDestroyed()).subscribe((preSelectedResource: Maybe<PreselectedResource>) => {
      if (preSelectedResource) {
        this.applyPreselectionToDialogs(preSelectedResource);
      }
    });
  }

  filterBySearch(filterText: string): void {
    this.filterText = filterText;
  }

  private triggerInventoryResourceLoadWithSelection(): void {
    combineLatest([
      this.context.selectedScope$,
      this.selectedTenantKey$,
    ]).pipe(first()).subscribe(([selectedScope, selectedTenantKey]: [string, string]): void =>
      this.triggerInventoryResourceLoad(selectedScope, selectedTenantKey));
  }

  triggerInventoryResourceLoad(selectedScope: string, selectedTenantKey: string): void {
    this.store$.dispatch(new LoadInventoryTenantScopedResourcesWithUsage());
    if (selectedTenantKey && SecretManagementHelper.isTenantScopedSelection(selectedScope, selectedTenantKey)) {
      // For tenant scope selection, no need to trigger inventory level resource BE call
      return;
    }
    this.store$.dispatch(new LoadInventoryResourcesWithUsage(selectedScope));
  }

  openCreateInventorySecretDialog(tenantKey: string, targetInventoryResourceType: InventoryResourceType, files?: FileList): void {
    const resourceCreationModel: CreateTenantLevelInventoryResourceDialogPayload = {
      inventoryResourceType: targetInventoryResourceType,
      tenantKey: tenantKey,
      isSecret: _.isEqual(targetInventoryResourceType, InventoryResourceType.PLAIN_TEXT_SECRET) || _.isEqual(targetInventoryResourceType, InventoryResourceType.SECRET_FILE),
      file: files ? files[0] : undefined,
      onSaveCallback: () => this.triggerInventoryResourceLoadWithSelection(),
    };
    this.inventoryResourceActionDialogService.openCreateGlobalResourceDialog(resourceCreationModel);
  }

  viewPlainTextSecret(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag) {
    this.openViewContentDialog(resourceItem, true, InventoryResourceType.PLAIN_TEXT_SECRET);
  }

  editPlainTextSecret(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag) {
    this.openViewContentDialog(resourceItem, false, InventoryResourceType.PLAIN_TEXT_SECRET);
  }

  viewResource(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag, resourceType: InventoryResourceType) {
    this.openViewContentDialog(resourceItem, true, resourceType);
  }

  editResource(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag, resourceType: InventoryResourceType) {
    this.openReplaceContentDialog(resourceItem, resourceType);
  }

  openViewContentDialog(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag, isReadOnly: boolean, resourceType: InventoryResourceType): void {
    this.hasViewSecretContentPermissionByLevel(resourceItem, resourceType).pipe(take(1))
        .subscribe((hasViewSecretPermission: boolean) => {
          const inventoryResourceActionDialogPayload: InventoryResourceContentActionDialogPayload = {
            inventoryResourceType: resourceType,
            isSecret: InventoryResourceTypeHelper.isSecret(resourceType),
            resourceItem: resourceItem,
            hasViewPermission: hasViewSecretPermission,
            isReadOnly: isReadOnly,
            onSaveCallback: () => this.triggerInventoryResourceLoadWithSelection(),
          };
          this.inventoryResourceActionDialogService.openViewResourceContentDialog(inventoryResourceActionDialogPayload);
        });
  }

  openReplaceContentDialog(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag, resourceType: InventoryResourceType): void {
    const replaceInventoryResourceDialogPayload: ReplaceInventoryResourceDialogPayload = {
      inventoryResourceType: resourceType,
      isSecret: InventoryResourceTypeHelper.isSecret(resourceType),
      resourceItem: resourceItem,
      isCertificate: false,
      onSaveCallback: () => this.triggerInventoryResourceLoadWithSelection(),
    };
    this.inventoryResourceActionDialogService.openReplaceResourceDialog(replaceInventoryResourceDialogPayload);
  }

  deletePlainTextSecret(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag) {
    this.openDeleteResourceDialog(resourceItem, InventoryResourceType.PLAIN_TEXT_SECRET);
  }

  deleteSecretFile(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag) {
    this.openDeleteResourceDialog(resourceItem, InventoryResourceType.SECRET_FILE);
  }

  deleteFile(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag) {
    this.openDeleteResourceDialog(resourceItem, InventoryResourceType.FILE);
  }

  openDeleteResourceDialog(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag, resourceType: InventoryResourceType): void {
    const resourceId = InventoryResourceTypeHelper.getTypeSpecificIdWithName(resourceItem);
    const resourceTypeLabel = resourceType.toLowerCase();

    const listOfUsedInventories = resourceItem.usedIn ? InventoryResourceActionDialogHelper.getResourceUsageDescription(resourceItem.usedIn) : '';
    const confirmDescription = `You are deleting <em><b>${resourceId}</b></em>. The removal is irreversible.${listOfUsedInventories}`;

    this.modalNotificationService.openConfirmDialog({
      headerTitle: 'Warning',
      title: `Delete ${resourceTypeLabel}`,
      description: confirmDescription,
    }, {
      confirmButtonText: 'Delete'
    }).afterClosed().pipe(
        take(1),
        switchMap((confirmed?: boolean) => {
          if (confirmed) {
            return SecretManagementHelper.getInventoryResourceDeletionFunction(this.inventoryService, resourceItem);
          }
          return of(false);
        }),
        catchError((error: HttpErrorResponse) => {
          this.handleResourceDeletionError(error);
          return of(undefined);
        }),
        withLatestFrom(this.context.selectedScope$),
    ).subscribe(([secretResourceId, selectedScope]: [Maybe<string>, string]) => {
      if (secretResourceId) {
        this.toastNotificationService.showSuccessToast(`The ${resourceTypeLabel} has been successfully deleted`, 'Successfully deleted');
        this.triggerInventoryResourceLoad(selectedScope, this.selectedTenantKey);
      }
    });
  }

  private confirmScopeChangeDialog(secretLike: SecretLikeWrapperWithUsage & TenantResourceFlag): Observable<boolean | undefined> {
    const resourceIdLabel = InventoryResourceTypeHelper.getTypeSpecificIdWithName(secretLike);
    return this.modalNotificationService.openConfirmDialog(
      {
        headerTitle: 'Warning',
        title: 'Change scope',
        description: `The scope of the item will be changed to global. After changing this, it can not be set back to inventory scope.<br/>
Do you want to change the scope of <strong>${resourceIdLabel}</strong>?`,
      },
      {
        confirmButtonText: 'Change scope to global'
      }
    ).afterClosed();
  }

  public extendSecretScopeToTenant(secretLike: SecretLikeWrapperWithUsage & TenantResourceFlag) {
    const currentSecret: SecretWrapperWithUsage = secretLike as SecretWrapperWithUsage;
    const saveAction = (selected: {scope: string, tenant: string}) =>
      this.inventoryService.patchInventorySecret(selected.scope, currentSecret.secretId, undefined, selected.tenant);
    this.extendSecretLikeScopeToTenant(secretLike, saveAction, InventoryResourceType.PLAIN_TEXT_SECRET);
  }

  public extendSecretResourceScopeToTenant(secretLike: SecretLikeWrapperWithUsage & TenantResourceFlag) {
    const secretFile: SecretResourceWrapperWithUsage = secretLike as SecretResourceWrapperWithUsage;
    const saveAction = (selected: {scope: string, tenant: string}) =>
      this.inventoryService.patchInventorySecretResource(selected.scope, secretFile.secretResourceId, undefined, selected.tenant);
    this.extendSecretLikeScopeToTenant(secretLike, saveAction, InventoryResourceType.SECRET_FILE);
  }

  public extendResourceScopeToTenant(secretLike: SecretLikeWrapperWithUsage & TenantResourceFlag) {
    const file: ResourceWrapperWithUsage = secretLike as ResourceWrapperWithUsage;
    const saveAction = (selected: {scope: string, tenant: string}) =>
      this.inventoryService.patchInventoryResource(selected.scope, file.resourceId, undefined, selected.tenant);
    this.extendSecretLikeScopeToTenant(secretLike, saveAction, InventoryResourceType.FILE);
  }

  private extendSecretLikeScopeToTenant(
    secretLike: SecretLikeWrapperWithUsage & TenantResourceFlag,
    saveAction: (selected: {scope: string, tenant: string}) => Observable<void>,
    resourceType: InventoryResourceType,
  ) {
    this.confirmScopeChangeDialog(secretLike).pipe(
      take(1),
      switchMap((confirmed?: boolean): Observable<boolean> => {
        if (confirmed) {
          return this.context.selectedScopeAndTenant$.pipe(
            take(1),
            switchMap((selected: {scope: string, tenant: string}): Observable<boolean> => {
              return saveAction(selected).pipe(
                mapTo(true),
                catchError((error: HttpErrorResponse) => {
                  this.modalNotificationService.openHttpErrorDialog(error, 'Something went wrong when trying to extend the scope.');
                  return of(false);
                }),
              );
            }),
          );
        }
        return of(false);
      }),
      withLatestFrom(this.context.selectedScope$),
    ).subscribe(([operationDone, selectedScope]: [boolean, string]) => {
      if (operationDone) {
        const typeLabel = resourceType.toLowerCase();
        this.toastNotificationService.showSuccessToast(`The scope of the ${typeLabel} has been successfully extended to global`, 'Scope successfully extended');
        this.triggerInventoryResourceLoad(selectedScope, this.selectedTenantKey);
      }
    });
  }

  private applyPreselectionToDialogs(preSelectedResource: PreselectedResource): void {
    if (!TenantHelper.isScopeTenantLevel(preSelectedResource.scope)) {
      // applying the scope selection, the change will trigger the new scope's resources to be loaded
      this.scopeSelectionControl.setValue(preSelectedResource.scope);
    }
    const hasScopeLevelPermission$ = (resource: TenantResourceFlag): Observable<boolean> => {
      return resource.isTenantScoped ? this.hasViewSecretContentTenantLevelPermission$ : this.hasViewSecretContentInventoryLevelPermission$;
    };
    switch (preSelectedResource.resourceType) {
      case InventoryResourceType.PLAIN_TEXT_SECRET:
        this.waitForResourceAndOpenDialog<SecretWrapperWithUsage & TenantResourceFlag>(
          preSelectedResource,
          this.secrets$,
          (resourceId: string) => (secretFile: SecretWrapperWithUsage & TenantResourceFlag) => secretFile.secretId === resourceId,
          (secret: SecretWrapperWithUsage & TenantResourceFlag): void => {
            hasScopeLevelPermission$(secret).pipe(first()).subscribe(hasPermission => {
              if (hasPermission) {
                this.viewPlainTextSecret(secret);
              } else {
                this.toastNotificationService.showInfoToast(`You don't have permission to view the content of <strong>${secretReferencePrefix + secret.secretId}</strong>`);
              }
            });
          },
        );
        break;
      case InventoryResourceType.SECRET_FILE:
        this.waitForResourceAndOpenDialog<SecretResourceWrapperWithUsage & TenantResourceFlag>(
          preSelectedResource,
          this.secretResources$,
          (resourceId: string) => (secretFile: SecretResourceWrapperWithUsage & TenantResourceFlag) => secretFile.secretResourceId === resourceId,
          secretFile => {
            hasScopeLevelPermission$(secretFile).pipe(first()).subscribe(hasPermission => {
              if (hasPermission) {
                this.viewResource(secretFile, InventoryResourceType.SECRET_FILE);
              } else {
                this.toastNotificationService.showInfoToast(`You don't have permission to view the content of <strong>${inventorySecretResourceReferencePrefix + secretFile.secretResourceId}</strong>`);
              }
            });
          }
        );
        break;
      case InventoryResourceType.FILE:
        this.waitForResourceAndOpenDialog<ResourceWrapperWithUsage & TenantResourceFlag>(
          preSelectedResource,
          this.resources$,
          (resourceId: string) => (file: ResourceWrapperWithUsage & TenantResourceFlag) => file.resourceId === resourceId,
          file => this.viewResource(file, InventoryResourceType.FILE),
        );
        break;
    }
    this.nav.navigateToSecretManagement(true);
  }

  /**
   * Filters `resources$` using `filterCreator`, until it first finds the resource with the ID defined in `preSelectedResource`.
   * If the resource is found, it is passed to `dialogHandler` to open it in the dialog.
   * If it is not found in 5 seconds, prints an error to the console and does nothing else.<br/>
   * The point is that the resource is being loaded, is expected to be present in `resources$` soon, but might not be loaded right now.<br/>
   * The timeout is there so that slow requests would not randomly finish this later, when the user might even have navigated away.
   */
  private waitForResourceAndOpenDialog<T extends TenantResourceFlag>(
    preSelectedResource: PreselectedResource,
    resources$: Observable<Array<T>>,
    filterCreator: (resourceId: string) => (currentResource: T) => boolean,
    dialogHandler: (resource: T) => void,
  ) {
    resources$.pipe(
      map(secrets => secrets.find(filterCreator(preSelectedResource.resourceId))),
      filterNotNil(),
      first(),
      timeout(5000),
      catchError(e => {
        console.warn('SecretManagementComponent#applyPreselectionToDialogs: could not find resource in timely manner', preSelectedResource, e);
        return EMPTY;
      }),
    ).subscribe((resource: Maybe<T>) => {
      if (resource) {
        dialogHandler(resource);
      }
    });
  }

  private handleResourceDeletionError(error: HttpErrorResponse): void {
    console.error(error);
    const errorMessage = ErrorHelper.getErrorDetail(error, 'Something went wrong during deleting the resource.');
    this.modalNotificationService.openErrorDialog({title: 'Error', description: errorMessage});
  }

  filterOutNonTenantScopedResources<T extends (SecretLikeWrapperWithUsage)>(resources: (T & TenantResourceFlag)[]): (T & TenantResourceFlag)[] {
    return resources.filter((secret) => secret.isTenantScoped);
  }

  getCreateActionBtnTooltip(hasPermission: boolean, inventoryResourceType: InventoryResourceType): string {
    const actionExpression = _.isEqual(inventoryResourceType, InventoryResourceType.PLAIN_TEXT_SECRET) ? 'create' : 'upload';
    return hasPermission ? '' : `You don’t have permission to ${actionExpression} ${inventoryResourceType.toLowerCase()}.`;
  }

  searchableProjectFormatFn = (scope: string): string => {
    return this.context.resolveScopeItemText(scope, this.selectedTenantKey);
  };

  updateSearchResult(filteredList: string[]): void {
    this.filteredScopes = filteredList;
  }

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }

  isScopeFilteredOut(scope: string): boolean {
    return _.includes(this.filteredScopes, scope);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  hasViewSecretContentPermissionByLevel(resourceItem: SecretLikeWrapperWithUsage & TenantResourceFlag, resourceType: InventoryResourceType): Observable<boolean> {
    if (!InventoryResourceTypeHelper.isSecret(resourceType)) return of(true);
    return resourceItem.isTenantScoped ? this.hasViewSecretContentTenantLevelPermission$ : this.hasViewSecretContentInventoryLevelPermission$;
  }
}

import { Injectable } from '@angular/core';
import { PluginModel } from '../../plugins/plugin.model';
import { CreatePatternContainerComponent } from './create-pattern-container/create-pattern-container.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AppState, ProjectKey } from '../../model/reducer';
import { PatternInstance } from '../pattern-instance.model';
import { Store } from '@ngrx/store';
import { NavigationService } from '../../navbar/navigation.service';
import { createNewPatternInstanceObject } from '../pattern-instance.helper';
import { CreatePattern } from '../../model/pattern';
import { Pattern } from '../pattern.model';
import { CreatePatternData } from './create-pattern-data.model';

/**
 * Handles the addition of a new pattern
 */
@Injectable()
export class CreatePatternService {
  constructor(private dialog: MatDialog,
              private store$: Store<AppState>,
              private navigationService: NavigationService) {
  }

  /**
   * Opens the create pattern modal dialog with the given properties set.
   *
   * @param {PluginModel[]} plugins list of plugins to be set on the dialog component
   * @param {ProjectKey} projectKey project key to be set on the dialog component
   * @param onCreateSuccess
   * @param {string} patternName pattern name to be set on the dialog component
   *
   * @returns {MatDialogRef<CreatePatternContainerComponent>} The dialog reference of the displayed dialog.
   */
  openAddPatternWindow(
    plugins: PluginModel[],
    projectKey: ProjectKey,
    onCreateSuccess?: (pattern: PatternInstance) => void,
    patternName?: string
  ): MatDialogRef<CreatePatternContainerComponent> {
    const config: MatDialogConfig<CreatePatternData> = {
      panelClass: 'big-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        projectKey: projectKey,
        patternName: patternName,
        plugins: plugins,
        onCreateSuccess: onCreateSuccess
      }
    };
    return this.dialog.open(CreatePatternContainerComponent, config);
  }

  /**
   * Opens the create pattern modal dialog with the given properties set then when the new pattern is created, navigates to it.
   *
   * @param {PluginModel[]} plugins list of plugins to be set on the dialog component
   * @param {ProjectKey} projectKey project key to be set on the dialog component
   * @param {string} patternName pattern name to be set on the dialog component
   *
   * @returns {MatDialogRef<CreatePatternContainerComponent>} The dialog reference of the displayed dialog.
   */
  openAddPatternWindowThenNavigate(plugins: PluginModel[],
                                   projectKey: ProjectKey,
                                   patternName?: string
  ): MatDialogRef<CreatePatternContainerComponent> {

    const onCreateSuccess = (pattern: PatternInstance) => {
      this.navigationService.navigateToNewlyCreatedPattern(projectKey, pattern.patternId);
    };
    return this.openAddPatternWindow(plugins, projectKey, onCreateSuccess, patternName);
  }

  /**
   * Creates a new pattern based on the given plugin to the given project.
   *
   * @param {PluginModel} plugin to create new pattern based on this.
   * @param {string} projectKey to create new pattern in this project.
   */
  createPattern(plugin: PluginModel, projectKey: string): void {
    const newPattern = createNewPatternInstanceObject(plugin);
    this.store$.dispatch(new CreatePattern({
      pattern: newPattern,
      projectKey: projectKey,
      onCreateSuccess: (createdPattern: Pattern) => {
        this.navigationService.navigateToPattern(projectKey, createdPattern.patternId);
      }
    }));
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PatternListData } from './pattern-list-data.model';
import { PluginModel } from '../plugins/plugin.model';
import { PatternType } from '../plugins/pattern-type.model';
import * as _ from 'lodash';
import { Project, ProjectMeta } from '../projects/project.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../model/reducer';
import { PatternSortingOption, patternSortingOptions, PatternSortingOptionTitles } from './pattern-sorting-options.enum';
import { CreatePatternService } from './create-pattern/create-pattern.service';
import { LocalStorageHelper } from '../common/helpers/local-storage.helper';
import { localStoragePatternListGroupingKey, localStoragePatternListSortingKey } from '../common/constants/local-storage-keys.constants';
import { DirtyFormGuardConnectorService } from '../common/services/dirty-form-guard-connector.service';
import { PatternMasterListHelper } from './pattern-master-list.helper';
import { BatchSelectionContext } from './batch-actions/batch-selection.context';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { BatchActionDialogService } from './batch-actions/batch-action-dialog.service';
import { filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { getProjectsWithModifyPermission } from '../model/views/permission.views';
import { PatternGroupingOption, patternGroupingOptions, PatternGroupingOptionTitles } from './pattern-grouping-options.enum';
import { patternsView } from '../model/views';
import { PatternMainConnectService } from './pattern-main-connect.service';
import { requireNonNull } from '../common/utils/utils';
import { PatternListComponent } from './pattern-list/pattern-list.component';
import { DisplayComponentHelperService } from '../common/services/display-component-helper.service';
import { IssueSeverity } from '../common/model/issue.model';
import { ModalNotificationService } from '../notification/modal-notification.service';
import { DeletePattern } from '../model/pattern';
import { PatternHelper } from './pattern.helper';

const FILTER_SELECTED = 'categoryFilterEnabled';

/**
 * This component lists a list of pattern.
 * It also implements the Filterable interface which means it can be filtered
 */
@Component({
  selector: 'adm4-pattern-master-list',
  template: `
    <div class='patterns full-height-flex'>
      <div *ngIf='!shouldHideSearchAndFilters' class='filters-wrapper'>
        <div class='pl-filter'>
          <adm4-filter (filter)="filterBySearch($event)"
                       [placeholderText]='"Find patterns"'
                       [filterText]="filterText"
                       class='search-input'>
          </adm4-filter>
          <button class='admn4-button-filter-icon'
                  [class.selected]='categoryFilterEnabled'
                  (click)='toggleFiltering()'>
            <i class="fa fa-filter" aria-hidden="true"></i>
          </button>
        </div>
        <div *ngIf='categoryFilterEnabled' class='pattern-filter-container'>
          <button *ngFor='let category of allCategories'
                  class='admn4-button-filter-category pattern-category'
                  [class.selected]='categoriesSelected[category]'
                  (click)='onCategoryClick(category)'>
            {{category}}
          </button>
          <button *ngFor='let status of statusFilters'
                  class='admn4-button-filter-category status-category'
                  [class.selected]='!!statusFiltersSelected.get(status)'
                  [ngClass]='getStatusFilterColor(status)'
                  (click)='onStatusFilterClick(status)'>
            {{getStatusFilterText(status) | capitalizeFirst}}
          </button>
        </div>
      </div>
      <div *ngIf='!shouldHideSearchAndFilters' class='pattern-sorting'>
        <div class='sorting-option-wrapper checkbox-wrapper'>
          <div class="sorting-option">
            <div class='main-checkbox-wrapper' [title]='"Batch actions"'>
              <mat-checkbox [disabled]='shouldDisableMainCheckBox'
                            [checked]='hasSelectedPattern$ | async'
                            (change)='selectAllPatterns($event.checked)'
                            (indeterminateChange)='setNeutralStatus($event)'
                            [indeterminate]='multipleButNotAllPatternSelected$ | async'>
              </mat-checkbox>
              <adm4-batch-actions-menu
                      [hasProjectModificationPermission]='hasProjectModificationPermission$ | async'
                      [multiSelectionCount]='multiSelectedPatternsCount$ | async'
                      [filteredPatternCount]='filteredPatterns.length'
                      [selectedPatterns]='batchSelection$ | async'
                      [allPatternLabels]='patternLabels$ | async'
                      [projectKey]='projectKey'
                      [shouldDisableBatchSelection]='shouldDisableMainCheckBox'
                      [readOnly]='readOnly'
                      (selectAll)='selectAllPatterns(true)'
                      (copy)='copySelectedPatterns()'
                      (deleteSelected)='deleteSelected(projectKey, batchSelection$)'>
              </adm4-batch-actions-menu>
            </div>
          </div>
        </div>
        <div class='sorting-option-wrapper' [title]="SORTING_OPTIONS_MENU_TITLE + ' ' + selectedSortingOption.title">
          <div class="sorting-option">
            <button class="admn4-button-sorting" [matMenuTriggerFor]="menu" aria-label="menu" [disabled]='shouldShowEmptyResultMessage'>
              <mat-icon class="sorting-option-icon" fontSet='fa' [fontIcon]='selectedSortingOption.iconClass'></mat-icon>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </button>
            <mat-menu class="overflow-menu" #menu="matMenu" [xPosition]="'after'">
              <div mat-menu-item class='overall-menu-title' [disabled]='true'>
                <span class='menu-text'>{{SORTING_OPTIONS_MENU_TITLE}}</span>
              </div>
              <div class='sorting-option-wrapper' *ngFor='let sortingOption of sortingOptions'>
                <button class='menu-element-indicated-default' mat-menu-item (click)='onSortingOptionChange(sortingOption)' [class.selected]='sortingOption === selectedSortingOption'>
                  <mat-icon class="sorting-option-icon" fontSet='fa' [fontIcon]='sortingOption.iconClass'></mat-icon>
                  <span class="menu-text">{{sortingOption.title}}</span>
                </button>
              </div>
            </mat-menu>
          </div>
        </div>
        <div class='sorting-option-wrapper' [title]="GROUPING_OPTIONS_MENU_TITLE + ' ' + selectedGroupingOption.title">
          <div class="sorting-option">
            <button class="admn4-button-sorting" [matMenuTriggerFor]="groupingMenu" aria-label="menu" [disabled]='shouldShowEmptyResultMessage'>
              <mat-icon class="sorting-option-icon">{{selectedGroupingOption.iconClass}}</mat-icon>
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </button>
            <mat-menu class="overflow-menu" #groupingMenu="matMenu" [xPosition]="'after'">
              <div mat-menu-item class='overall-menu-title' [disabled]='true'>
                <span class='menu-text'>{{GROUPING_OPTIONS_MENU_TITLE}}</span>
              </div>
              <div class='sorting-option-wrapper' *ngFor='let groupingOption of groupingOptions'>
                <button class='menu-element-indicated-default' mat-menu-item (click)='onGroupingOptionChange(groupingOption)' [class.selected]='groupingOption === selectedGroupingOption'>
                  <mat-icon class="sorting-option-icon">{{groupingOption.iconClass}}</mat-icon>
                  <span class="menu-text">{{groupingOption.title}}</span>
                </button>
              </div>
            </mat-menu>
          </div>
        </div>
      </div>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='outer-pattern-list remaining-space-flex-content'>
          <div class="pl-list ui_list" #scrollArea>
            <adm4-pattern-list *ngIf='shouldShowPatternList'
                               #patternListComponent
                               [patterns]="filteredPatterns"
                               [textToHighLight]="filterText"
                               [selection]="selection"
                               [projectKey]='projectKey'
                               [scrollArea]='scrollArea'
                               [selectedCategories]='categoriesSelected'
                               [statusFiltersSelected]='statusFiltersSelected'
                               [patternMetaInfos]='vcStatusOfProject?.patterns'
                               [versioned]='versioned'
                               [batchSelection]='batchSelection$ | async'
                               [isLabelView]='isLabelView'
                               [mainCheckBoxClicked]='mainCheckBoxClicked'
                               [filterText]='filterText'
            ></adm4-pattern-list>
            <div *ngIf="noPatternsInProject" class="no-patterns">
              No patterns found. Use the + button below to add a pattern.
            </div>
            <div *ngIf="shouldShowEmptyResultMessage" class="no-patterns">
              No patterns found.
            </div>
          </div>
            <button *ngIf='!readOnly' [disabled]='noPluginAvailable' mat-fab id='heroButton' class='admn4-button-hero' [color]='' type='button' (click)="openAddPatternWindow()">
            <img src="assets/nevisadmin4PlusIcon.svg" alt="Plus icon">
          </button>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['pattern-master-list.scss', '../common/styles/component-specific/overflow-menu.scss', '../common/styles/component-specific/mat-menu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PatternMasterListComponent implements OnChanges, OnDestroy, OnInit {
  @Input() selection: string | null;
  @Input() patterns: PatternListData[];
  @Input() plugins: PluginModel[];
  @Input() vcStatusOfProject: ProjectMeta;
  @Input() projectKey: string;
  @Input() readOnly: boolean;
  @Input() versioned: boolean;
  mainCheckBoxClicked: boolean | undefined;

  @ViewChild('scrollArea', {static: false}) public scrollArea;
  @ViewChild('patternListComponent', {static: false}) public patternListComponent: PatternListComponent;
  public filteredPatterns: PatternListData[] = [];
  public filterText = '';
  public categoriesSelected: Record<string, boolean> = {};
  public allCategories: string[] = [];
  public statusFiltersSelected: Map<IssueSeverity, boolean> = new Map();
  public statusFilters: IssueSeverity[] = [IssueSeverity.ERROR, IssueSeverity.WARNING, IssueSeverity.INTO, IssueSeverity.UNUSED];
  public categoryFilterEnabled: boolean;
  public sortingOptions: PatternSortingOption[] = patternSortingOptions;
  selectedSortingOption: PatternSortingOption;
  public groupingOptions: PatternGroupingOption[] = patternGroupingOptions;
  selectedGroupingOption: PatternGroupingOption;
  readonly SORTING_OPTIONS_MENU_TITLE = 'Sort by:';
  readonly GROUPING_OPTIONS_MENU_TITLE = 'Group by:';

  private patternsCountChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  batchSelection$: Observable<PatternListData[]>;
  hasSelectedPattern$: Observable<boolean | undefined> = this.batchSelectionContext.batchSelection$.pipe(map((patternList: PatternListData[]) => patternList.length > 0));
  multipleButNotAllPatternSelected$: Observable<boolean | undefined> = combineLatest([this.batchSelectionContext.batchSelection$, this.patternsCountChange$]).pipe(
    map(([patternList, ]: [PatternListData[], boolean]) => {
      return patternList.length > 0 && patternList.length < this.patterns.length;
    })
  );
  multiSelectedPatternsCount$: Observable<number> = this.batchSelectionContext.batchSelection$.pipe(map((patternListData: PatternListData[]) => patternListData.length));
  hasProjectModificationPermission$: Observable<boolean>;
  patternLabels$: Observable<string[]>;
  groupingOptionChangedFromEditor$: Observable<PatternGroupingOption | null>;

  private destroyed$: Subject<boolean> = new Subject();

  constructor(private store$: Store<AppState>,
              private createPatternService: CreatePatternService,
              private formGuardConnectorService: DirtyFormGuardConnectorService,
              private batchSelectionContext: BatchSelectionContext,
              private batchActionDialogService: BatchActionDialogService,
              private patternMainConnectService: PatternMainConnectService,
              private chRef: ChangeDetectorRef,
              private displayComponentHelperService: DisplayComponentHelperService,
              private modalNotificationService: ModalNotificationService) {
    this.categoryFilterEnabled = JSON.parse(LocalStorageHelper.retrieve(FILTER_SELECTED) || 'false');
    this.batchSelection$ = this.batchSelectionContext.batchSelection$.pipe(takeUntil(this.destroyed$));
    this.handleBatchSelectionChange();
    this.hasProjectModificationPermission$ = this.store$.pipe(select(getProjectsWithModifyPermission), map((projectsWithPermission: Project[]) => {
      return !_.isEmpty(projectsWithPermission.filter(project => project.projectKey !== this.projectKey));
    }));
    this.selectedSortingOption = this.getSelectedSortingOption();
    this.selectedGroupingOption = this.getSelectedGroupingOption();
  }

  ngOnInit(): void {
    this.batchSelection$ = this.batchSelectionContext.batchSelection$.pipe(takeUntil(this.destroyed$));
    this.patternLabels$ = PatternHelper.getPatternsLabels(this.store$.pipe(select(patternsView)));
    this.groupingOptionChangedFromEditor$ = this.patternMainConnectService.groupingOption$;
    this.groupingOptionChangedFromEditor$.pipe(
      filter((groupingOption: PatternGroupingOption | null) => !_.isNil(groupingOption)),
      takeUntil(this.destroyed$)
    ).subscribe((groupingOption) => {
      this.onGroupingOptionChange(requireNonNull(groupingOption));
      this.chRef.markForCheck();
    });
  }

  // Need to detect changes when some pattern changes and update the batch selection
  handleBatchSelectionChange(): void {
    this.patternsCountChange$.pipe(
      takeUntil(this.destroyed$),
      withLatestFrom(this.batchSelectionContext.batchSelection$),
      filter(([, batchSelection]: [boolean, PatternListData[]]) => !_.isEmpty(batchSelection) && !_.isNil(this.patterns)),
      map(([, batchSelection]: [boolean, PatternListData[]]) => {
        batchSelection.forEach((batchSelectionElement: PatternListData) => {
          let canBeFoundInPatternList = false;
          const patternToUpdate = this.patterns.find((actualPatternListElement: PatternListData) => {
            canBeFoundInPatternList = canBeFoundInPatternList || actualPatternListElement.pattern.patternId === batchSelectionElement.pattern.patternId;
            let patternPropertyChanged = false;
            const isPartOfBatchSelection = actualPatternListElement.pattern.patternId === batchSelectionElement.pattern.patternId;
            if (isPartOfBatchSelection) {
              patternPropertyChanged = !_.isEqual(actualPatternListElement.pattern, batchSelectionElement.pattern);
            }
            return isPartOfBatchSelection && patternPropertyChanged;
          });
          // if the pattern that is part of the batch selection has been completely deleted than need to update batchSelectionContext
          if (!canBeFoundInPatternList) {
            this.batchSelectionContext.singlePatternSelectionChange(batchSelectionElement);
          }
          if (patternToUpdate) {
            this.batchSelectionContext.updateBatchSelectionItem(patternToUpdate);
          }
        });
      })).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['patterns'] || changes['vcStatusOfProject']) && this.patterns && this.vcStatusOfProject) {
      const listOfKnonwPatterns = _.filter(this.patterns, pattern => !_.isNil(pattern.type));
      const patternTypes: PatternType[] = _.map(listOfKnonwPatterns, pattern => pattern.type) as PatternType[];
      this.allCategories = PatternMasterListHelper.getAllCategoriesFromPatternTypes(patternTypes);
      this.categoriesSelected = PatternMasterListHelper.fillSelectedCategories(this.categoriesSelected, this.allCategories);
      this.filteredPatterns = this.getFilteredSortedPatternList();
      const patternsChanged = changes['patterns'];
      if (patternsChanged) {
        this.patternsCountChange$.next(true);
      }
    }
    if (changes['selection'] && this.selection && this.isSelectedPatternFilteredOut()) {
      // reset filtering if we navigate to a pattern and the selected pattern is not shown anymore in the filtered patterns
      // (e.g. clicking a pattern reference link in help)
      this.filteredPatterns = this.patterns;
      this.filterText = '';
      this.categoriesSelected = {};
      this.statusFiltersSelected = new Map();
    }
    if (changes.projectKey) {
      this.filterText = '';
      this.categoriesSelected = {};
      this.statusFiltersSelected = new Map();
      this.batchSelectionContext.multiplePatternSelectionChange([]);
    }
  }

  getSelectedSortingOption(): PatternSortingOption {
    return patternSortingOptions.find(sortingOption => {
      let initializedSortingOptionTitle: string | null = LocalStorageHelper.retrieve(localStoragePatternListSortingKey);
      const invalidStoredOption = !Object.values(PatternSortingOptionTitles).includes(initializedSortingOptionTitle as PatternSortingOptionTitles);
      if (_.isNil(initializedSortingOptionTitle) || invalidStoredOption) {
        initializedSortingOptionTitle = PatternSortingOptionTitles.LastEdited;
      }
      return sortingOption.title === initializedSortingOptionTitle;
    }) as PatternSortingOption;
  }

  getSelectedGroupingOption(): PatternGroupingOption {
    return patternGroupingOptions.find(groupingOption => {
      let initializedGroupingOptionTitle: string | null = LocalStorageHelper.retrieve(localStoragePatternListGroupingKey);
      const invalidStoredOption = !Object.values(PatternGroupingOptionTitles).includes(initializedGroupingOptionTitle as PatternGroupingOptionTitles);
      if (_.isNil(initializedGroupingOptionTitle) || invalidStoredOption) {
        initializedGroupingOptionTitle = PatternGroupingOptionTitles.Ungrouped;
      }
      return groupingOption.title === initializedGroupingOptionTitle;
    }) as PatternGroupingOption;
  }

  private shouldBeFilteredBySearch(details: PatternListData, regExp: RegExp): boolean {
    if (_.isEmpty(this.filterText)) {
      return true;
    }
    const matchesName: boolean = !_.isNil(details) && !_.isEmpty(details.pattern.name.match(regExp));
    const matchesType: boolean = !_.isNil(details) && !_.isNil(details.type) && !_.isEmpty(details.type.name.match(regExp));
    return matchesName || matchesType;
  }

  private shouldBeFilteredByCategory(details: PatternListData): boolean {
    return !this.categoryFilterEnabled || !PatternMasterListHelper.hasCategoriesSelected(this.categoriesSelected) || (!_.isNil(details.type) &&
      PatternMasterListHelper.patternContainsAllSelectedCategories(details.type.categories, this.categoriesSelected));
  }

  private shouldBeFilteredByIssueStatus(details: PatternListData): boolean {
    return !this.categoryFilterEnabled || !PatternMasterListHelper.hasIssueFilterSelected(this.statusFiltersSelected) || (!_.isNil(details.issues) &&
      PatternMasterListHelper.patternContainsSelectedIssue(details.issues, this.statusFiltersSelected));
  }

  private getFilteredSortedPatternList(): PatternListData[] {
    const regExp = PatternMasterListHelper.getFilterRegexpByWord(this.filterText);
    // if later memory and GC becomes a problem (which I doubt), consider using transducer library
    return _(this.patterns)
      .filter((details: PatternListData) => {
        return this.shouldBeFilteredBySearch(details, regExp)
          && this.shouldBeFilteredByCategory(details)
          && this.shouldBeFilteredByIssueStatus(details)
          && this.displayComponentHelperService.showByPatternType(details.type)
          && this.displayComponentHelperService.showPatternByPatternData(details.pattern);
      })
      .sort((p1: PatternListData, p2: PatternListData) => this.selectedSortingOption.sortingFn(p1, p2, this.isLabelView, this.vcStatusOfProject?.patterns??{}))
      .value();
  }

  filterBySearch(filterText: string): void {
    this.filterText = filterText;
    this.filteredPatterns = this.getFilteredSortedPatternList();
  }

  openAddPatternWindow(): void {
    this.formGuardConnectorService.doIfConfirmed(() => this.createPatternService.openAddPatternWindowThenNavigate(this.plugins, this.projectKey));
  }

  isSelectedPatternFilteredOut() {
    return !this.filteredPatterns.some((patternData: PatternListData) => {
      return patternData.pattern.patternId === this.selection;
    });
  }

  onCategoryClick(category: string) {
    const toggleValue: boolean = !this.categoriesSelected[category];
    this.categoriesSelected = {
      ...this.categoriesSelected,
      [category]: toggleValue,
    };
    this.filteredPatterns = this.getFilteredSortedPatternList();
  }

  onStatusFilterClick(status: IssueSeverity) {
    const toggleValue: boolean = !this.statusFiltersSelected.get(status);
    this.statusFiltersSelected.set(status, toggleValue);
    this.filteredPatterns = this.getFilteredSortedPatternList();
  }

  toggleFiltering(): void {
    this.categoryFilterEnabled = !this.categoryFilterEnabled;
    LocalStorageHelper.save(FILTER_SELECTED, JSON.stringify(this.categoryFilterEnabled));
  }

  onSortingOptionChange(sortingOption: PatternSortingOption): void {
    this.selectedSortingOption = sortingOption;
    LocalStorageHelper.save(localStoragePatternListSortingKey, sortingOption.title);
    this.filteredPatterns = this.getFilteredSortedPatternList();
  }

  onGroupingOptionChange(groupingOption: PatternGroupingOption): void {
    this.selectedGroupingOption = groupingOption;
    LocalStorageHelper.save(localStoragePatternListGroupingKey, groupingOption.title);
  }

  selectAllPatterns(isAllSelected: boolean): void {
    this.mainCheckBoxClicked = isAllSelected;
  }

  // need to set mainCheckBox status to undefined to be able to trigger deselection when indeterminate elements are selected
  // (so mainCheckBox already false and need to trigger onChanges in child)
  // so if there are patterns that selected but not all than need to set mainCheckBox to undefined
  setNeutralStatus(isIndeterminateSelection: boolean): void {
    if (isIndeterminateSelection) {
      this.mainCheckBoxClicked = undefined;
    }
  }

  copySelectedPatterns(): void {
    this.formGuardConnectorService.doIfConfirmed(() => {
      this.batchSelection$.pipe(first()).subscribe((patternList: PatternListData[]) => {
        this.batchActionDialogService.openBatchActionDialog('Copy selected', this.projectKey, patternList);
      });
    });
  }

  // TODO remove `_projectKey` param, because it's a class field passed back via the template
  deleteSelected(_projectKey: string, batchSelection$: Observable<PatternListData[]>) {
    this.formGuardConnectorService.doIfConfirmed(() => {
      batchSelection$.pipe(first()).subscribe((patternListData: PatternListData[]) => {
        const patternList = patternListData.map(value => value.pattern);
        this.modalNotificationService.openConfirmDialog({
          headerTitle: 'Warning', title: 'Remove Patterns',
          description: `You are removing the selected patterns. The removal is irreversible. It can’t be undone.`
        }, {confirmButtonText: 'Remove'})
          .afterClosed().subscribe((confirmed?: boolean) => {
            if (confirmed === true) {
              this.store$.dispatch(new DeletePattern(patternList));
            }
          }
        );
      });
    });
  }

  get isLabelView(): boolean {
    return this.selectedGroupingOption.title === PatternGroupingOptionTitles.Label;
  }

  get shouldHideSearchAndFilters(): boolean {
    return this.noPatternsInProject;
  }

  get noPatternsInProject(): boolean {
    return _.isEmpty(this.patterns);
  }

  get shouldShowEmptyResultMessage(): boolean {
    return !_.isEmpty(this.patterns) && _.isEmpty(this.filteredPatterns);
  }

  get shouldDisableMainCheckBox(): boolean {
    return (this.patternListComponent && this.patternListComponent.isAllGroupCollapsed()) || this.shouldShowEmptyResultMessage;
  }

  get shouldShowPatternList(): boolean {
    return !this.noPatternsInProject && !this.shouldShowEmptyResultMessage;
  }

  get noPluginAvailable(): boolean {
    return _.isEmpty(this.plugins);
  }

  getStatusFilterText(status: IssueSeverity): string {
    return status;
  }

  getStatusFilterColor(status: IssueSeverity): string {
    return _.isEqual(status, IssueSeverity.UNUSED) ? 'neutral' : status.toLowerCase();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


}

<mat-table multiTemplateDataRows matSort class='adm4-mat-table full-width bundle-management-table' [dataSource]='tableDataSource'>
  <!-- ID Column (Secret ID or Secret resource ID or Resource ID based on the data type) -->
  <ng-container [matColumnDef]="resourceTableColumns.IdColumnName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>{{typeSpecificIdColumnDef}}</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class='id-and-actions'>
        <div class='text-ellipsis cursor-default' [class.disabled-opacity]='!element.isUsed'
             [ngbTooltip]='resourceIdPopover' placement='top-left'
             [innerHTML]='element.typeSpecificId | highlight: filterText'></div>
        <mat-icon class="cursor-pointer color-info" [cdkCopyToClipboard]="element.typeSpecificId">content_copy</mat-icon>
        <mat-icon class='global-icon' *ngIf="element.isTenantScoped"
                  [ngbTooltip]='globalResourcePopover' placement='top-left'
        >public</mat-icon>
      </div>
      <ng-template #resourceIdPopover>{{element.typeSpecificId}}</ng-template>
      <ng-template #globalResourcePopover>Global scope.<br/>Use it in your inventory by inserting its reference ID.
      </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Used in Column -->
  <ng-container [matColumnDef]="resourceTableColumns.UsageColumnName">
    <mat-header-cell *matHeaderCellDef>
      Used in
      <span class='expansion-wrapper' (click)='toggleUsedInAll()'>{{isExpandedUsage ? 'Collapse all' : 'Expand all'}}
        <i class="fa" [ngClass]="isExpandedUsage ? 'fa-minus-square-o' : 'fa-plus-square-o'" aria-hidden="true"></i>
      </span>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container *ngIf='element?.usedIn; else noUsageTemplate'>
        <ng-container *ngIf='element?.usedIn?.length > 1 && !element.isExpanded; else usageList'>
          <a (click)="toggleUsedIn(element)">{{element?.usedIn.length}} inventories...</a>
        </ng-container>
        <ng-template #usageList>
          <div class='usage-wrapper'>
            <div *ngFor='let usage of element.usedIn' class='usage-item'>
              <a (click)='navigateToInventory(usage)' [innerHTML]='usage | cropTenantFromKey | highlight: filterText'></a></div>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #noUsageTemplate>
        <ng-container *ngIf='element?.isTenantScoped; else unlinked'>
          <span [class.disabled-opacity]='!element.isUsed'>Unused</span>
          <i class="fa fa-info-circle help-icon" aria-hidden="true" [ngbTooltip]='unusedPopover' placement='right'></i>
        </ng-container>
      </ng-template>

      <ng-template #unlinked>
        <span [class.disabled-opacity]='!element.isUsed'>Unlinked</span>
        <i class="fa fa-info-circle help-icon" aria-hidden="true"
           [ngbTooltip]='unLinkedPopover' placement='right'></i>
      </ng-template>

      <ng-template #unusedPopover>The {{resourceType | lowercase}} has not been used yet.<br/>
        1. Go to your project.<br/>
        2. Create a variable in your pattern.<br/>
        3. Insert the {{resourceType | lowercase}} reference to your variable in the Inventory.
      </ng-template>
      <ng-template #unLinkedPopover>The file reference has been removed from an inventory and is not used in any
        inventory. To clean up, please remove it.
      </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Description Column -->
  <ng-container [matColumnDef]="resourceTableColumns.DescriptionColumnName">
    <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div *ngIf='{desc: element.description, showMore: false} as descInfo;' class='description-content-wrapper'
           [class.disabled-opacity]='!element.isUsed'>
        {{(descInfo.showMore) ? descInfo.desc : (descInfo.desc | slice:0:25) || '-'}}
        <span *ngIf="!descInfo.showMore && descInfo.desc?.length > 25">...</span><br>
        <a *ngIf="!descInfo.showMore && descInfo.desc?.length > 25" (click)="descInfo.showMore=true">Show More</a>
      </div>
    </mat-cell>
  </ng-container>

  <!-- File name Column -->
  <ng-container [matColumnDef]="resourceTableColumns.FileNameColumnName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> File name</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div class='filename-content-wrapper' [ngbTooltip]='fileNamePopover' [class.disabled-opacity]='!element.isUsed'
           placement='bottom' [innerHTML]='(element.resourceName || element.secretResourceName) | highlight: filterText'></div>
      <ng-template #fileNamePopover>{{element.resourceName || element.secretResourceName}}</ng-template>
    </mat-cell>
  </ng-container>

  <!-- Action Column -->
  <ng-container [matColumnDef]="resourceTableColumns.ActionColumnName">
    <mat-header-cell *matHeaderCellDef> Actions</mat-header-cell>
    <mat-cell *matCellDef="let element" class='action-cell'>
      <span *ngIf="!element.isTenantScoped" [ngbTooltip]="getExtendScopeActionTooltip(hasModifyTenantAndInventoryPermission$ | async)"
            placement='bottom-right'>
        <button class='action-btn action-make-global'
                [disabled]='!(hasModifyTenantAndInventoryPermission$ | async)'
                (click)="expandScopeToTenantResource.emit(element)">
          <mat-icon>public</mat-icon>
        </button>
      </span>
      <span [ngbTooltip]='getResourceActionBtnTooltip(hasViewSecretContentPermissionByLevel(element) | async, inventoryResourceActionTypes.View)'
            placement='bottom-right'>
        <button class='action-btn'
                [disabled]='!(hasViewSecretContentPermissionByLevel(element) | async)'
                (click)='viewResource.emit(element)'>
          <mat-icon>visibility</mat-icon>
        </button>
      </span>
      <span [ngbTooltip]='getResourceActionBtnTooltip(hasModifyPermissionByLevel(element) | async, inventoryResourceActionTypes.Change)'
            placement='bottom-right'>
        <button class='action-btn'
                [disabled]='!(hasModifyPermissionByLevel(element) | async)'
                (click)='editResource.emit(element)'>
          <mat-icon>mode_edit</mat-icon>
        </button>
      </span>
      <span [ngbTooltip]='getResourceActionBtnTooltip(hasModifyPermissionByLevel(element) | async, inventoryResourceActionTypes.Delete)'
            placement='bottom-right'>
        <button class='action-btn'
                [disabled]='!(hasModifyPermissionByLevel(element) | async)'
                (click)='deleteResource.emit(element)'>
          <mat-icon>delete_forever</mat-icon>
        </button>
      </span>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>

<ng-container *ngIf="!hasDisplayableData">
  <adm4-empty-resource><span>{{noResourceFoundText}}</span></adm4-empty-resource>
</ng-container>

export enum SecretManagementTableModel {
 IdColumnName = 'id',
 SourceColumnName = 'source',
 UsageColumnName = 'usage',
 DescriptionColumnName = 'description',
 FileNameColumnName = 'fileName',
 ExpiryDateColumnName = 'expiry Date',
 ActionColumnName = 'action',
 IssuerColumnName = 'issuer',
 SubjectColumnName = 'subject',
 SerialColumnName = 'serial',
 KindColumnName = 'kind',
 SecretNameColumnName= 'secret Name',
 ResourceNameColumnName= 'name',
 StateColumnName= 'state'
}

export const inventorySecretColumns: Readonly<Array<SecretManagementTableModel>> = [
  SecretManagementTableModel.IdColumnName,
  SecretManagementTableModel.UsageColumnName,
  SecretManagementTableModel.DescriptionColumnName,
  SecretManagementTableModel.ActionColumnName
];

export const inventoryResourceColumns: Readonly<Array<SecretManagementTableModel>> = [
  SecretManagementTableModel.IdColumnName,
  SecretManagementTableModel.UsageColumnName,
  SecretManagementTableModel.DescriptionColumnName,
  SecretManagementTableModel.FileNameColumnName,
  SecretManagementTableModel.ActionColumnName
];

export const initialCertificatesColumns: Readonly<Array<SecretManagementTableModel>> = [
  SecretManagementTableModel.SourceColumnName,
  SecretManagementTableModel.UsageColumnName,
  SecretManagementTableModel.DescriptionColumnName,
  SecretManagementTableModel.ExpiryDateColumnName,
  SecretManagementTableModel.ActionColumnName
];

export const fixCertificatesColumns: Readonly<Array<SecretManagementTableModel>> = [
  SecretManagementTableModel.SourceColumnName,
  SecretManagementTableModel.UsageColumnName,
  SecretManagementTableModel.ActionColumnName
];

export const customizableCertificatesColumns: Readonly<Array<SecretManagementTableModel>> = [
  SecretManagementTableModel.DescriptionColumnName,
  SecretManagementTableModel.ExpiryDateColumnName,
  SecretManagementTableModel.IssuerColumnName,
  SecretManagementTableModel.SubjectColumnName,
  SecretManagementTableModel.SerialColumnName
];

export const initialKubernetesCertificatesColumns: Readonly<Array<SecretManagementTableModel>> = [
  SecretManagementTableModel.UsageColumnName,
  SecretManagementTableModel.KindColumnName,
  SecretManagementTableModel.ResourceNameColumnName,
  SecretManagementTableModel.ExpiryDateColumnName,
  SecretManagementTableModel.SubjectColumnName,
  SecretManagementTableModel.StateColumnName,
  SecretManagementTableModel.ActionColumnName
];

export const fixKubernetesCertificatesColumns: Readonly<Array<SecretManagementTableModel>> = [
  SecretManagementTableModel.UsageColumnName,
  SecretManagementTableModel.ResourceNameColumnName,
  SecretManagementTableModel.ExpiryDateColumnName,
  SecretManagementTableModel.StateColumnName,
  SecretManagementTableModel.ActionColumnName
];

export const customizableKubernetesCertificatesColumns: Readonly<Array<SecretManagementTableModel>> = [
  SecretManagementTableModel.SecretNameColumnName,
  SecretManagementTableModel.IssuerColumnName,
  SecretManagementTableModel.SubjectColumnName,
  SecretManagementTableModel.SerialColumnName,
  SecretManagementTableModel.KindColumnName,
];

export enum InventoryResourceActionType {
  View = 'View',
  Edit = 'Edit',
  Change = 'Change',
  Replace = 'Replace',
  Delete = 'Delete',
  ExtendScope = 'Extend the scope',
}

import { DeploymentItemStatusModel, Status } from './deployment-status.model';
import { GenerationItemStatus, GenerationStatusModel } from '../validate-deployment/generation-status.model';
import * as _ from 'lodash';

export class ValidatingHelper {
  static isItemPending(itemStatus: Status): boolean {
    return itemStatus === Status.Pending;
  }

  static isItemRunning(itemStatus: Status): boolean {
    return itemStatus === Status.Running;
  }

  static isItemDone(itemStatus: Status): boolean {
    return itemStatus === Status.Done;
  }

  static isItemFailed(itemStatus: Status): boolean {
    return itemStatus === Status.Failed;
  }

  /**
   * @param {GenerationStatusModel} generationStatus
   * @returns {boolean} true in case the validation is finished, and has failed item
   */
  static isAnyFailed(generationStatus: GenerationStatusModel) {
    if (!generationStatus || !generationStatus.items || generationStatus.status !== Status.Done) {
      return false;
    }
    return !this.isAllDone(generationStatus);
  }

  static combineItemLogName(item: DeploymentItemStatusModel): string {
    return `${item.host} log`;
  }

  /**
   * @param {GenerationStatusModel} generationStatus
   * @returns {boolean} true in case the validation is finished, and all the items are validated sucesfully
   */
  static isAllSuccess(generationStatus: GenerationStatusModel) {
    if (!generationStatus || !generationStatus.items || generationStatus.status !== Status.Done) {
      return false;
    }
    return this.isAllDone(generationStatus);
  }

  private static isAllDone(generationStatus: GenerationStatusModel) {
    return _.every(generationStatus.items, (x: GenerationItemStatus) => {
      return x.status === Status.Done;
    });
  }
}

<div class="full-height-flex">
	<div class="step-sub-heading-with-actions">
    <div class='step-content-header'><mat-icon class='title' *ngIf="titleIcon">{{titleIcon}}</mat-icon>{{node.details.label}}</div>
	</div>
	<div class="remaining-space-flex-content-wrapper">
		<div class="remaining-space-flex-content">
			<div class='step-content-card item-details full-height' [ngClass]='boxShadowClass'>
				<p>
					<adm4-detail-message
									[node]='node'
									[outputType]='outputType'
									[pattern]='pattern'
									[projectKey]='projectKey'>
					</adm4-detail-message>
				</p>
				<p>
					<strong>Command</strong>
				</p>
				<p>{{node.details.command}}</p>
				<ng-container *ngIf='shouldDisplayConditions'>
					<p>
						<strong>Command triggers</strong>
					</p>
					<p>
						Depending on the outcome of this check, the following commands will be run:
					</p>
					<ul>
						<li *ngFor='let command of conditionFailureCommands'>
							<span>If the check fails: </span><span class='link-button' (click)='navigateToCommand(command.commandId)'>{{command.label}}</span>
						</li>
						<li *ngFor='let command of conditionSuccessCommands'>
							<span>If the check succeeds: </span><span class='link-button' (click)='navigateToCommand(command.commandId)'>{{command.label}}</span>
						</li>
					</ul>
				</ng-container>
			</div>
		</div>
	</div>
</div>
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ProjectSummaryReportCellType, ProjectSummaryReportModel, ProjectSummaryReportType } from '../project-summary-report.model';
import { MatTableDataSource } from '@angular/material/table';
import { ProjectSummaryReportHelper } from '../project-summary-report.helper';
import * as _ from 'lodash';
import { PluginModel } from '../../../../plugins/plugin.model';
import { MatSort } from '@angular/material/sort';
import { ProjectSummaryReportTableSortHelper } from '../project-summary-report-table-sort.helper';

@Component({
  selector: 'adm4-project-summary-report-table',
  template: `
    <div class='full-height-flex'>
      <div class='table-wrapper'>
        <ng-container *ngIf="reportsError">
          <div class="message">Failed to load pattern summary reports.</div>
        </ng-container>
        <ng-container *ngIf="isReportEmpty && !reportsError && !reportsNotFound">
          <div class="message">There are no {{name | lowercase}} configured in this project.</div>
        </ng-container>
        <ng-container *ngIf="reportsNotFound">
          <div class="message">Report not found. Please make sure that this project has the standard pattern libraries (base) enabled and with 4.5 version or higher. You can do this in 'Project Settings' in the 'Standard libraries' table.</div>
        </ng-container>
        <div *ngIf='filterText && !filterMatches' class="message">No data matches the search term.</div>
        <table mat-table matSort *ngIf="dataSource" [dataSource]="dataSource" [class.hidden]='!filterMatches || reportsNotFound || isReportEmpty'>
          <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let i = index">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column}} </th>
            <td mat-cell *matCellDef="let element">
              <adm4-project-summary-report-table-cell [cell]='element[i]'
                                                      [projectKey]='projectKey'
                                                      [textToHighlight]='filterText'></adm4-project-summary-report-table-cell>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <adm4-project-summary-quick-link *ngIf='shouldDisplayQuickLink(plugins)'
                                       [pluginsModels]="plugins"
                                       [name]='quickLinkName'
                                       [currentProjectKey]='projectKey'></adm4-project-summary-quick-link>
    </div>
  `,
  styleUrls: ['./project-summary-report-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectSummaryReportTableComponent implements OnChanges, AfterViewInit {

  @Input() reportModel: ProjectSummaryReportModel;
  @Input() projectKey: string;
  @Input() filterText: string;
  @Input() reportsNotFound: boolean;
  @Input() reportsError: boolean;
  @Input() name: string;
  @Input() quickLinkName: string;
  @Input() plugins: PluginModel[];

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  displayedColumns: string[];
  dataSource: MatTableDataSource<any[]> = new MatTableDataSource();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reportModel'] && this.reportModel) {
      this.displayedColumns = this.reportModel.columns;
      this.dataSource.data = this.reportModel.rows;
      this.setFilterPredicate();
    }
    if (changes['filterText'] && this.dataSource) {
      this.filterTable(this.filterText);
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortData = this.reportTableSorting();
  }

  setFilterPredicate(): void {
    this.dataSource.filterPredicate = (data: ProjectSummaryReportType[], filter: string) => ProjectSummaryReportHelper.shouldRowBeDisplayedForFilter(data, filter);
  }

  filterTable(filterText: string): void {
    this.dataSource.filter = filterText;
  }

  get isReportEmpty(): boolean {
    return !_.isNil(this.dataSource) && !_.isNil(this.reportModel) && _.isEmpty(this.reportModel.rows);
  }

  get filterMatches(): boolean {
    return !_.isNil(this.dataSource) && !_.isEmpty(this.dataSource.filteredData);
  }

  shouldDisplayQuickLink(plugins: PluginModel[]): boolean {
    return !_.isNil(plugins) && !_.isEmpty(plugins);
  }

  private reportTableSorting(): (data: any[], sort: MatSort) => any[] {

    return (data: any[], sort: MatSort) => {
      if (_.isEmpty(sort.direction)) {
        return data.sort(ProjectSummaryReportTableSortHelper.compareBySimpleStringParameter(sort.direction, 0));
      }
      const columnName = sort.active;
      const columnIndex = this.displayedColumns.findIndex((column: string) => columnName === column);
      const firstRow = data[0];
      if (!firstRow) {
        return data;
      }
      const firstCell = firstRow[columnIndex];

      switch (firstCell.type) {
        case ProjectSummaryReportCellType.values:
          return data.sort(
            ProjectSummaryReportTableSortHelper.compareByStringArrayParameter(sort.direction, columnIndex)
          );
        case ProjectSummaryReportCellType.link:
        case ProjectSummaryReportCellType.patternLink:
        case ProjectSummaryReportCellType.value:
        default:
          return data.sort(
            ProjectSummaryReportTableSortHelper.compareBySimpleStringParameter(sort.direction, columnIndex)
          );
      }
    };
  }
}

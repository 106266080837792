import { concat, defer, interval as observableInterval, Observable, Observer, of as observableOf, ReplaySubject, Subject } from 'rxjs';
import { catchError, concatMap, finalize, multicast, startWith, take, takeWhile } from 'rxjs/operators';
import { KeysOfType } from './common/model/advanced-types.model';
import { editor, IDisposable } from 'monaco-editor';
import ICodeEditor = editor.ICodeEditor;

/**
 * It emits results while the predicate is true, inclusive the value that failed the predicate
 * @param {(t: T) => boolean} predicate
 * @returns {(source: Observable<T>) => Observable<T | undefined>}
 */
export function takeWhileInclusive<T>(predicate: (t: T) => boolean): (source: Observable<T>) => Observable<T> {
  return source => source.pipe(multicast(
    () => new ReplaySubject<T>(1),
    (result: Observable<T>) => {
      return concat(result.pipe(takeWhile(predicate)), result.pipe(take(1)));
    }
  ));
}

/**
 * It polls the function in the param for the specified interval until poll condition is true and the first false value as well
 * @param {number} interval
 * @param {() => Observable<T>} functionToPoll
 * @param {(t: T) => boolean} pollCondition
 * @returns {Observable<T | undefined>}
 */
export function poll<T>(interval: number,
                        functionToPoll: () => Observable<T>,
                        pollCondition: (t: T) => boolean): Observable<T> {
  return observableInterval(interval).pipe(
    startWith(1),
    concatMap(() => {
      return functionToPoll().pipe(
        catchError(e => {
            console.error('An error occurred while calling poll function', e);
            return observableOf({} as T);
          }
        ));
    }),
    (takeWhileInclusive(pollCondition)),
    catchError(e => {
      console.error('An error occurred while polling', e);
      return observableOf({} as T);
    }));
}

export function fromMonacoEditorEvent<T>(
    editorForEvents: ICodeEditor,
    eventName: KeysOfType<ICodeEditor, ((listener: (() => void)) => IDisposable) | ((listener: ((e: T) => void)) => IDisposable)>
): Observable<T> {
  return new Observable((obs: Observer<T>) => {
    return editorForEvents[eventName].call(editorForEvents, (e: T) => obs.next(e));
  });
}

/**
 * Operator which invokes a callback upon subscription.
 * https://nils-mehlhorn.de/posts/indicating-loading-the-right-way-in-angular
 *
 * @param callback to be called upon subscription.
 */
export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => defer(() => {
    callback();
    return source;
  });
}

/**
 * Operator accepting a subject as our sink for the loading state.
 * Update the subject upon subscription to the actual source stream via indicator.next(true).
 * Similarly, uses the finalize operator to inform it about the loading being completed via indicator.next(false).
 * https://nils-mehlhorn.de/posts/indicating-loading-the-right-way-in-angular
 *
 * @param indicator
 */
export function indicate<T>(indicator: Subject<boolean>): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => source.pipe(
    prepare(() => indicator.next(true)),
    finalize(() => indicator.next(false))
  );
}

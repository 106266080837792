/**
 * Environment that is actually used for installing a real application on a real server
 */
export const environment = {
  env: 'embedded',
  production: true,
  baseUrl: './api/v1',
  externalUrl: './api/v1',
  enableAnimations: true
};

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TextDiffWrapperContext } from '../text-diff-wrapper/text-diff-wrapper.model';
import { ProjectDiffViewContextService } from './project-diff-view-context.service';
import { DiffViewComponent } from '../../../common/model/publish-changes/diff-view-component.model';
import { ProjectChangesetItem } from '../project-changeset-item.model';
import { MetaInfo } from '../../../version-control/meta-info.model';

@Component({
  selector: 'adm4-project-diff-view',
  template: `<adm4-text-diff-wrapper></adm4-text-diff-wrapper>`,
  providers: [
    {provide: TextDiffWrapperContext, useClass: ProjectDiffViewContextService}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDiffViewComponent implements DiffViewComponent<ProjectChangesetItem<MetaInfo, ProjectDiffViewComponent>> {

  /**
   * This field represents selected changeset in left column of publish project, it's a way to connect the 2 sides when interation between them is needed
   */
  changesetItem: ProjectChangesetItem<MetaInfo, ProjectDiffViewComponent>;

}

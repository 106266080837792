import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

import { Project } from '../../project.model';
import { checkExistingProjectKey } from '../../project-creation.validator';
import { IS_VERSIONED_FORM_CONTROL_NAME, PROJECT_KEY_FORM_CONTROL_NAME, VERSION_CONTROL_FORM_CONTROL_NAME } from '../../create-import-project-common/create-import-branch-project-form.model';
import { CreateProjectHelper } from './create-project.helper';
import { Mixin } from '../../../common/decorators/mixin.decorator';
import { NestedVersionControlFormMixin } from '../../../common/components/version-control-form/nested-version-control-form.mixin';
import { IPredefinedProjectFormDataMixin, PredefinedProjectFormDataMixin } from '../../create-import-project-common/predefined-project-form-data.mixin';
import { FormHelper } from '../../../common/helpers/form.helper';
import { VersionControlData } from '../../../common/model/version-control-data.model';
import { ProjectTemplate, projectTemplateByText } from '../../../resources/marketplace/marketplace.model';

type VersionControlFormAnimationState = 'opened' | 'closed';
const versionControlAnimationStateOpened: VersionControlFormAnimationState = 'opened';
const versionControlAnimationStateClosed: VersionControlFormAnimationState = 'closed';

@Component({
  selector: 'adm4-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: [
    '../../../common/styles/component-specific/create-form.scss',
  ],
  animations: [
    trigger('showVersionControlForm', [
      // ...
      state(versionControlAnimationStateOpened, style({
        height: '*',
        overflow: '*'
      })),
      state(versionControlAnimationStateClosed, style({
        height: '0',
        overflow: 'hidden'
      })),
      transition(`${versionControlAnimationStateOpened} => ${versionControlAnimationStateClosed}`, [style({overflow: 'hidden'}), animate('500ms ease-in-out')]),
      transition(`${versionControlAnimationStateClosed} => ${versionControlAnimationStateOpened}`, animate('500ms ease-in-out'))
    ]),
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {'[class]': "'adm4-mat-dialog'"},
})
@Mixin([NestedVersionControlFormMixin, PredefinedProjectFormDataMixin])
export class CreateProjectComponent implements OnInit, OnDestroy, OnChanges, NestedVersionControlFormMixin, IPredefinedProjectFormDataMixin {
  @Input() availableProjectTemplates: ProjectTemplate[] = [];
  @Input() projects: Project[];
  @Input() predefinedProjectData: Project | null;
  @Input() preselectedProjectTemplateKey: string | undefined;
  @Input() selectedTenantKey: string;
  @Input() displayTenant: boolean;

  @Output() projectCreated = new EventEmitter<{project: Project, projectTemplateKey: string | undefined}>();
  @Output() cancelClicked = new EventEmitter<void>();
  @Output() goToMarketPlace = new EventEmitter<void>();

  form: UntypedFormGroup;

  readonly ERROR_INVALID_PROJECT_KEY = 'The project key entered contains some invalid characters. Must be of format [A-Z0-9_-]';
  readonly ERROR_PROJECT_KEY_REQUIRED = 'The project key is required';
  readonly BUTTON_CREATE = 'Create';

  readonly IS_VERSIONED_FORM_CONTROL_NAME = IS_VERSIONED_FORM_CONTROL_NAME;
  readonly PROJECT_KEY_FORM_CONTROL_NAME = PROJECT_KEY_FORM_CONTROL_NAME;
  readonly VERSION_CONTROL_FORM_CONTROL_NAME = VERSION_CONTROL_FORM_CONTROL_NAME;
  readonly PROJECT_TEMPLATE_KEY_FORM_CONTROL_NAME = 'projectTemplateKey';

  destroyed$: Subject<boolean> = new Subject();

  private _searchInputFocusTrigger$: Subject<void> = new Subject<void>();
  public searchInputFocusTrigger$: Observable<void> = this._searchInputFocusTrigger$.asObservable();
  private projectTemplateFilter: string | undefined = undefined;
  public filteredProjectTemplates: ProjectTemplate[] = [];

  // implemented by mixin NestedVersionControlFormMixin
  updateDefaultKeyOnVersionControlChange: (projectKeyFormControlName: string) => void;

  // implemented by mixin PredefinedProjectFormDataMixin
  initialProjectKeyFormValue: () => string;
  predefinedVersionControlData: () => VersionControlData | null;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.createFormGroup();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preselectedProjectTemplateKey) {
      this.form && this.form.controls[this.PROJECT_TEMPLATE_KEY_FORM_CONTROL_NAME]?.setValue(this.preselectedProjectTemplateKey ?? '');
    }
    if (changes.availableProjectTemplates) {
      this.filterProjectTemplates();
    }
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    group.addControl(this.IS_VERSIONED_FORM_CONTROL_NAME, this.fb.control(this.isVersionControlEnabledInitially()));
    group.addControl(this.PROJECT_KEY_FORM_CONTROL_NAME,
        this.fb.control(this.initialProjectKeyFormValue(), [Validators.pattern(/^[a-zA-Z0-9_-]+$/), checkExistingProjectKey(this.projects, this.selectedTenantKey)]));
    group.addControl(this.PROJECT_TEMPLATE_KEY_FORM_CONTROL_NAME, this.fb.control(this.preselectedProjectTemplateKey ?? ''));
    return group;
  }

  isVersionControlEnabledInitially(): boolean {
    return !_.isNil(this.predefinedProjectData) && !_.isNil(this.predefinedProjectData.repository);
  }

  addVersionControlForm(versionControlForm: UntypedFormGroup): void {
    this.form.setControl(this.VERSION_CONTROL_FORM_CONTROL_NAME, versionControlForm);
    if (_.isNil(this.predefinedProjectData) || _.isNil(this.predefinedProjectData.projectKey)) {
      this.updateDefaultKeyOnVersionControlChange(this.PROJECT_KEY_FORM_CONTROL_NAME);
    }
  }

  shouldShowProjectKeyErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.form.controls[this.PROJECT_KEY_FORM_CONTROL_NAME], errorKey);
  }

  submitCreateProject(): void {
    if (!this.canSave) {
      return;
    }
    const project = this.isVersioned
      ? CreateProjectHelper.convertFormValueToVersionedProject(this.form.value, this.selectedTenantKey)
      : CreateProjectHelper.convertFormValueToProject(this.form.value, this.selectedTenantKey);
    const rawTemplateKey: string = this.form.value[this.PROJECT_TEMPLATE_KEY_FORM_CONTROL_NAME];
    const projectTemplateKey: string | undefined = rawTemplateKey??''.length > 0 ? rawTemplateKey : undefined;
    this.projectCreated.emit({project, projectTemplateKey});
  }

  cancel() {
    this.cancelClicked.emit();
  }

  goToMarketplace() {
    this.goToMarketPlace.emit();
    this.cancel();
  }

  selectToggled(isOpen: boolean) {
    if (isOpen) {
      this._searchInputFocusTrigger$.next();
    }
  }

  public projectTemplateSearchChanged(searchText: string) {
    this.projectTemplateFilter = searchText;
    this.filterProjectTemplates();
  }

  private filterProjectTemplates() {
    if (this.projectTemplateFilter && this.projectTemplateFilter.trim().length > 0) {
      this.filteredProjectTemplates = this.availableProjectTemplates.filter(projectTemplateByText(this.projectTemplateFilter, false));
    } else {
      this.filteredProjectTemplates = this.availableProjectTemplates;
    }
  }

  get showVersionControlFormState(): VersionControlFormAnimationState {
    return this.isVersioned ? versionControlAnimationStateOpened : versionControlAnimationStateClosed;
  }

  get isVersioned(): boolean {
    return this.form.value[this.IS_VERSIONED_FORM_CONTROL_NAME] || false;
  }

  get canSave(): boolean {
    const versionControlForm = this.form.controls[this.VERSION_CONTROL_FORM_CONTROL_NAME] as UntypedFormGroup;
    const versionControlValid: boolean = versionControlForm && _.values(versionControlForm.controls).every(control => control.valid);
    // when submitting form we only care about version control validation if was enabled
    const baseFormValid = _.keys(this.form.controls)
      .filter(formControlName => !_.includes([this.VERSION_CONTROL_FORM_CONTROL_NAME, this.IS_VERSIONED_FORM_CONTROL_NAME], formControlName))
      .every(formControlName => this.form.controls[formControlName].valid);
    return baseFormValid && (versionControlValid || !this.isVersioned);
  }

  get projectForTenantExistsError(): string {
    return `This key is already in use for tenant ${this.selectedTenantKey}`;
  }

}

import {
  CertificateWrapperWithUsage,
  CertificateWrapper,
  InventoryResourceType,
  KubernetesCertificateWrapper,
  ResourceWrapperWithUsage,
  ResourceWrapper,
  SecretResourceWrapperWithUsage,
  SecretWrapperWithUsage,
  TenantResourceFlag, SecretResourceWrapper,
} from '../../inventory/inventory.model';
import { inventoryResourceReferencePrefix, inventorySecretResourceReferencePrefix, k8sSecretReferencePrefix, secretReferencePrefix } from '../../common/constants/reference-prefix.constants';
import * as _ from 'lodash';

export class InventoryResourceTypeHelper {
  static getTypeSpecificId(inventoryResourceItem: SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage): string {
    if ('secretId' in inventoryResourceItem) {
      return `${secretReferencePrefix}${inventoryResourceItem?.secretId}`;
    } else if ('secretResourceId' in inventoryResourceItem) {
      return `${inventorySecretResourceReferencePrefix}${inventoryResourceItem?.secretResourceId}`;
    }
    return `${inventoryResourceReferencePrefix}${inventoryResourceItem?.resourceId}`;
  }

  static getTypeSpecificIdWithName(inventoryResourceItem: SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage | KubernetesCertificateWrapper): string {
    if ('secretId' in inventoryResourceItem) {
      return `${secretReferencePrefix}${inventoryResourceItem?.secretId}`;
    } else if ('secretResourceId' in inventoryResourceItem) {
      return `${inventorySecretResourceReferencePrefix}${inventoryResourceItem?.secretResourceId}#${inventoryResourceItem.secretResourceName}`;
    } else if ('kubernetesSecretKey' in inventoryResourceItem) {
      return `${k8sSecretReferencePrefix}${inventoryResourceItem?.kubernetesSecretKey}#${inventoryResourceItem.name}`;
    }
    return `${inventoryResourceReferencePrefix}${inventoryResourceItem?.resourceId}#${inventoryResourceItem.resourceName}`;
  }

  static getTypeSpecificIdColumnDef(inventoryResourceType: InventoryResourceType): string {
    switch (inventoryResourceType) {
      case InventoryResourceType.PLAIN_TEXT_SECRET:
        return 'Secret ID';
      case InventoryResourceType.SECRET_FILE:
        return 'Secret resource ID';
      case InventoryResourceType.FILE:
        return 'Resource ID';
    }
  }

  static isPlainTextSecretType(inventoryResourceItem: SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage | KubernetesCertificateWrapper): boolean {
    return 'secretId' in inventoryResourceItem;
  }

  static isSecretResourceType(inventoryResourceItem: SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage | KubernetesCertificateWrapper): boolean {
    return 'secretResourceId' in inventoryResourceItem;
  }

  static convertCertificateToInventoryResource(certificate: CertificateWrapper, isTenantScoped: boolean): (SecretResourceWrapper | ResourceWrapper) & TenantResourceFlag {
    const isSecretResource = _.startsWith(certificate.source, inventorySecretResourceReferencePrefix);
    const inventoryResourceItem = {
      description: certificate.description,
      scope: certificate.scope,
      isTenantScoped: isTenantScoped
    };
    return isSecretResource ? {
      ...inventoryResourceItem,
      secretResourceId: certificate.id,
      secretResourceName: certificate.fileName,
    } : {
      ...inventoryResourceItem,
      resourceId: certificate.id,
      resourceName: certificate.fileName,
    };
  }

  static convertCertificateWithUsageToInventoryResource(certificate: CertificateWrapperWithUsage, isTenantScoped: boolean): (SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag {
    const isSecretResource = _.startsWith(certificate.source, inventorySecretResourceReferencePrefix);
    const inventoryResourceItem = {
      description: certificate.description,
      scope: certificate.scope,
      usedIn: certificate.usedIn,
      isTenantScoped: isTenantScoped
    };
    return isSecretResource ? {
      ...inventoryResourceItem,
      secretResourceId: certificate.id,
      secretResourceName: certificate.fileName,
    } : {
      ...inventoryResourceItem,
      resourceId: certificate.id,
      resourceName: certificate.fileName,
    };
  }

  static isSecret(resourceType: InventoryResourceType): boolean {
    return InventoryResourceType.PLAIN_TEXT_SECRET === resourceType || InventoryResourceType.SECRET_FILE === resourceType;
  }
}

import { Component, forwardRef, OnInit } from '@angular/core';
import { WidgetComponent } from './widget.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { TextEditorDialogService } from '../common/components/text-editor-dialog/text-editor-dialog.service';
import { ToastNotificationService } from '../notification/toast-notification.service';

@Component({
  selector: 'adm4-mod-security-rule-property',
  template: `
    <div class='form-control json-editor'
         [class.disabled]='readOnly'
         [class.filled]='!isEmpty'
         [class.focused]='isFocused'>
      <ngx-codemirror [options]="config"
                      [(ngModel)]='editedContent'
                      (focusChange)='onSelect($event)'
                      (ngModelChange)='setUpdatedValue($event)'></ngx-codemirror>
    </div>
    <div class='code-mirror-actions rule-converter'>
      <a *ngIf='!readOnly' class='link-with-icon' (click)='openRuleConverter()'>
        <span>Rule recommender</span>
        <mat-icon class='rule-converter-icon'>add_circle</mat-icon>
      </a>
      <a *ngIf='!readOnly' class='link-with-icon' (click)='editInFullScreenEditor()'>
        <span>Edit full-screen</span>
        <mat-icon>edit</mat-icon>
      </a>
    </div>
  `,
  styleUrls: [
    'text-property.component.scss'
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ModSecurityRulePropertyComponent),
    multi: true
  }]
})
export class ModSecurityRulePropertyComponent extends WidgetComponent implements OnInit {
  config: any;
  isFocused: boolean;
  editedContent: string;

  constructor(private textEditorDialogService: TextEditorDialogService, private toastNotificationService: ToastNotificationService) {
    super();
  }

  override ngOnInit() {
   const placeholder = this.defaultValue ? 'default:\n' + this.defaultValue : '';
    this.config = {
      placeholder: placeholder
    };
    this.editedContent = this.group.value[this.widgetProperty.propertyKey];
  }

  override onSelect(focusEvent: boolean): void {
    this.isFocused = focusEvent;
    this.isFocused ? this.selectionChanged.emit(this.widgetProperty.propertyKey) : this.onValidate(focusEvent);
  }

  editInFullScreenEditor(): void {
    this.textEditorDialogService.openTextEditorDialog(this.group.value[this.widgetProperty.propertyKey]).afterClosed().subscribe((updatedValue?: string) => {
      if (!_.isNil(updatedValue)) {
        this.setUpdatedValue(updatedValue);
      }
    });
  }

  get isEmpty() {
    return _.isEmpty(this.widgetProperty.value);
  }

  openRuleConverter(): void {
    const editorValue: string = this.group.value[this.widgetProperty.propertyKey];
    this.textEditorDialogService.openWhitelistConverterDialog(this.widgetProperty.propertyKey, editorValue).afterClosed().subscribe((updatedValue?: string) => {
      if (!_.isNil(updatedValue) && !_.isEmpty(updatedValue)) {
        const convertedRuleSet = _.isEmpty(editorValue) ? updatedValue : editorValue.concat('\n', updatedValue);
        this.setUpdatedValue(convertedRuleSet);
        this.toastNotificationService.showSuccessToast(`New rule has been inserted successfully`, 'Success');
      }
    });
  }

  setUpdatedValue(updatedValue: string): void {
    this.group.controls[this.widgetProperty.propertyKey].markAsDirty();
    this.group.controls[this.widgetProperty.propertyKey].setValue(updatedValue);
    this.editedContent = updatedValue;
  }
}

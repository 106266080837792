import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { Maybe } from '../../utils/utils';

@Component({
  selector: 'adm4-simple-list-item',
  template: `
    <a mat-button class='simple-list-item-wrapper' [style]='_inlineStyle'
       [routerLink]='link' [fragment]='fragment' [replaceUrl]='replaceUrl'
       [class.selected]='isSelected' [class.batch-selected]='isBatchSelection' [ngClass]='customSelectionClass'>
      <div class='simple-list-item' (click)='clickOnItem.next($event)'>
        <ng-content></ng-content>
      </div>
    </a>
  `,
  styleUrls: ['./simple-list-item.component.scss']
})
export class SimpleListItemComponent implements OnChanges {

  _inlineStyle: Maybe<string>;

  @Input() isSelected: boolean;
  @Input() link: any[] | string;
  @Input() fragment: string;
  @Input() replaceUrl: boolean;
  @Input() customSelectionClass = 'default-selection';
  @Input() isBatchSelection: boolean;
  @Input() itemSize: number | 'content';
  @Output() clickOnItem = new EventEmitter<UIEvent>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemSize) {
      if (isNaN(Number(this.itemSize))) {
        if ('content' === this.itemSize) {
          this._inlineStyle = 'height: unset';
        } else {
          this._inlineStyle = undefined;
        }
      } else {
        this._inlineStyle = `height: ${this.itemSize}px`;
      }
    }
  }
}

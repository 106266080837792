import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeNode } from '../../tree-viewer/tree-node.model';
import { TreeGeneratingHelper } from '../../tree-viewer/tree-generating.helper';
import { GenerationOutputModel, OutputType } from '../../deploy/deployment-preview/planning-output.model';
import { Pattern } from '../../../patterns/pattern.model';
import { TREE_EXPAND, treeExpandProvider } from '../../tree-viewer/tree-expand.provider';
import { Dictionary } from '../../../model/reducer';
import { SplitPaneConfig } from '../../../common/model/split-pane-config.model';
import { IOutputData } from 'angular-split/lib/interface';
import { ResizeHelper } from '../../../common/helpers/resize.helper';
import { Mixin } from '../../../common/decorators/mixin.decorator';
import { ISplitMixin, SplitMixin } from '../../../common/mixins/split.mixin';
import { InventorySchemaType } from '../../../inventory/inventory.model';

@Component({
  selector: 'adm4-generation-output',
  template: `
    <div class="full-height-flex">
      <h2 class='step-content-header'>Generation results</h2>
      <div class="remaining-space-flex-content-wrapper">
        <div class="remaining-space-flex-content">
          <as-split class='deployment-step-split as-split-handle-only as-split-handle-upper' direction='horizontal' gutterSize='5' useTransition (dragEnd)='onResize($event)'>
            <as-split-area [order]='splitPaneConfig[treeSplitAreaKey].order' [size]='splitPaneConfig[treeSplitAreaKey].size' [minSize]='20'>
              <div class="full-height-flex split-primary-content">
                <div class="step-sub-heading-with-actions">
                  <button *ngIf='nodes?.length'
                          (click)="expandFiles()"
                          class="step-content-text-button">
                    <mat-icon class='step-content-text-button-icon'>add</mat-icon>
                    <span>Expand files</span>
                  </button>
                  <button type="button" class="step-content-text-button" (click)="onDownloadGenerationResult()">
                      <mat-icon class="step-content-text-button-icon download">save_alt</mat-icon>
                      <span>Download all files</span>
                  </button>
                </div>
                <div class="remaining-space-flex-content-wrapper">
                  <div class="remaining-space-flex-content">
                    <div class="full-height step-content-card margin-sm" [ngClass]='boxShadowClass'>
                      <adm4-tree-viewer *ngIf='nodes?.length'
                                        [nodes]='nodes'
                                        [selectedNodeId]='selectedNodeId'
                                        [initialExpandNodeIds]="initialExpandNodeIds"
                                        [forceShowAllItems]="true"
                                        (nodeSelected)='selectNode($event)'></adm4-tree-viewer>
                    </div>
                  </div>
                </div>
              </div>
            </as-split-area>
            <as-split-area [order]='splitPaneConfig[contentSplitAreaKey].order' [size]='splitPaneConfig[contentSplitAreaKey].size' [minSize]='40'>
              <div class="full-height split-secondary-content">
                <ng-container [ngSwitch]='selectedInventorySchemaType'>
                  <ng-container *ngSwitchCase='DeploymentType.KUBERNETES'>
                    <adm4-deployment-preview-details-kubernetes *ngIf='selectedNode'
                                                                [node]="selectedNode"
                                                                [outputType]='outputType'
                                                                [boxShadowClass]='boxShadowClass'
                                                                [patterns]='patterns'
                                                                (changeSelectedDetail)='selectNodeById($event)'>
                    </adm4-deployment-preview-details-kubernetes>
                  </ng-container>
                  <ng-container *ngSwitchCase='DeploymentType.CLASSIC'>
                    <adm4-deployment-preview-details *ngIf='selectedNode'
                                                     [node]="selectedNode"
                                                     [outputType]='outputType'
                                                     [boxShadowClass]='boxShadowClass'
                                                     [patterns]='patterns'
                                                     (changeSelectedDetail)='selectNodeById($event)'
                    ></adm4-deployment-preview-details>
                  </ng-container>
                </ng-container>
              </div>
            </as-split-area>
          </as-split>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./generation-output.scss'],
  providers: [treeExpandProvider]
})
@Mixin([SplitMixin])
export class GenerationOutputComponent implements ISplitMixin, OnChanges {
  @Input() generationOutputArray: GenerationOutputModel[];
  @Input() public patterns: Map<string, Pattern>;
  @Input() boxShadowClass: string;
  @Input() selectedInventorySchemaType: InventorySchemaType;

  @Output() downloadGenerationResult: EventEmitter<void> = new EventEmitter();

  readonly DeploymentType = InventorySchemaType;
  readonly outputType: OutputType = OutputType.GENERATION;
  public nodes: TreeNode[] = [];
  public selectedNode: TreeNode;
  public selectedNodeId: string;
  initialExpandNodeIds: string[] = [];

  readonly treeSplitAreaKey = 'tree';
  readonly contentSplitAreaKey = 'content';
  readonly splitPaneConfigLocalStorageKey = 'generation-output-splitpane-config';
  splitPaneConfig: Dictionary<SplitPaneConfig> = {
    [this.treeSplitAreaKey]: {order: 0, size: 30},
    [this.contentSplitAreaKey]: {order: 1, size: 70}
  };

  /**
   * Implemented by SplitMixin
   */
  onResize: (event: IOutputData) => void;

  constructor(@Inject(TREE_EXPAND) private treeExpand: EventEmitter<void>) {
    this.splitPaneConfig = ResizeHelper.retrieveSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['generationOutputArray'] && this.generationOutputArray) {
      this.nodes = this.createTreeNodesFromGenerationOutput();
      // this expands the top level nodes
      this.nodes.forEach((treeModel: TreeNode) => {
        this.initialExpandNodeIds.push(treeModel.id);
      });
    }
  }

  private createTreeNodesFromGenerationOutput(): TreeNode[] {
    switch (this.selectedInventorySchemaType) {
      case InventorySchemaType.CLASSIC:
        return TreeGeneratingHelper.getClassicTreeFromGenOutput(this.generationOutputArray, this.patterns);
      case InventorySchemaType.KUBERNETES:
        return TreeGeneratingHelper.getKubernetesTreeFromGenerationOutput(this.generationOutputArray, this.patterns);
      default:
        return [];
    }
  }

  selectNode(selectedNode: TreeNode): void {
    this.selectedNode = selectedNode;
    this.selectedNodeId = selectedNode.id;
  }

  selectNodeById(detailId: string): void {
    this.selectedNodeId = detailId;
  }

  expandFiles() {
    this.treeExpand.emit();
  }

  onDownloadGenerationResult() {
    this.downloadGenerationResult.emit();
  }
}

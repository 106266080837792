import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { NavigationService } from '../navbar/navigation.service';
import { IProjectGuardMixin, ProjectGuardMixin } from '../common/mixins/project-guard.mixin';
import { Mixin } from '../common/decorators/mixin.decorator';
import { ProjectService } from './project.service';
import { AppState } from '../model/reducer';
import { ModalNotificationService } from '../notification/modal-notification.service';
import { NevisAdminAction } from '../model/actions';

@Injectable({
  providedIn: 'root'
})
@Mixin([ProjectGuardMixin])
export class ProjectGuard  implements IProjectGuardMixin {

  canActivateScreen: (next: ActivatedRouteSnapshot) => Observable<boolean>;

  constructor(public store$: Store<AppState>,
              public projectService: ProjectService,
              public navigationService: NavigationService,
              public modalNotificationService: ModalNotificationService,
              public actions$: Actions<NevisAdminAction<any>>,
  ) {}


  navigateToScreen(projectKey: string): Promise<boolean> {
    return this.navigationService.navigateToProject(projectKey, true);
  }


  canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateScreen(next);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivateScreen(childRoute);
  }

}

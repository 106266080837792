import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import * as _ from 'lodash';

import { MatSelectChange } from '@angular/material/select';

import { Maybe } from '../../common/utils/utils';
import { Inventory } from '../inventory.model';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { ValidationStatusHelper } from '../../common/helpers/validation-status.helper';

@Component({
  selector: 'adm4-inventory-header',
  template: `
    <ng-template #popContentInventoryIssues>
      <adm4-inventory-validation-tooltip-content [inventoryValidationStatus]='inventoryValidationStatus'>
      </adm4-inventory-validation-tooltip-content>
    </ng-template>
    <adm4-column-header styleClass="inventory-header {{inventoryBackgroundClassName}}">
      <div *ngIf='currentInventoryKey' class='inventory-header-heading'>
        <span class='inventory-validation-dropdown-wrapper'>
          <span class="inventory-validation-indicator">
            <adm4-validation-indicator
                    [ngbTooltip]='popContentInventoryIssues' [disableTooltip]='isValidationValid' placement='bottom-left'
                    [isDisplayed]="true"
                    [isValid]='isValidationValid'
                    [isError]="isValidationWithErrorOrWarning"
                    [isWarning]="false"
                    [isInfo]='isValidationWithInfo'
            ></adm4-validation-indicator>
          </span>
          <adm4-inventory-dropdown
                  [currentInventoryKey]='currentInventoryKey'
                  [inventories]='inventories'></adm4-inventory-dropdown>
        </span>
        <span class="flex-grow-1"></span>
        <mat-form-field class="diff-selector" [class.diff-rightmost]="!diffMode" *ngIf="diffViewEnabled"
                    [ngbTooltip]="'Select an Inventory to compare to'">
            <mat-select placeholder="Compare to..."
                        [value]="selectedDiffInventory" (selectionChange)="onDiffInventorySelectionChanged($event)">
                <mat-option *ngFor="let i of inventories" [value]="i.inventoryKey">{{i.inventoryKey | cropTenantFromKey}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button type="button" class="close-diff admn4-button-icon-white" *ngIf="diffViewEnabled && diffMode" [class.diff-rightmost]="diffMode" (click)="closeDiffMode()">
            <mat-icon class="size-16 color-black">close</mat-icon>
        </button>
        <adm4-toggle-collapse-button *ngIf='isHelpCollapsed' class="flex-center-xy"
                                     side='right'
                                     [isCollapsed]='isHelpCollapsed'
                                     (click)='onHelpExpanderClick()'
        ></adm4-toggle-collapse-button>
      </div>
    </adm4-column-header>
  `,
  styleUrls: ['./inventory-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryHeaderComponent implements OnChanges {
  @Input() currentInventory?: Inventory;
  @Input() inventories: Inventory[];
  @Input() inventoryValidationStatus: ValidationStatus<InventoryValidationIssue> | null;
  @Input() inventoryBackgroundClassName: string;
  @Input() readOnly: boolean;
  @Input() isHelpCollapsed: boolean;
  @Output() expandHelp: EventEmitter<undefined> = new EventEmitter();

  @Input() diffViewEnabled: boolean = false;
  @Input() selectedDiffInventory: Maybe<string>;
  @Output() diffInventorySelectionChanged: EventEmitter<Maybe<string>> = new EventEmitter();

  public diffMode: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDiffInventory) {
      this.diffMode = !!this.selectedDiffInventory;
    }
  }

  onDiffInventorySelectionChanged(change: MatSelectChange) {
    this.diffInventorySelectionChanged.emit(change.value);
  }

  closeDiffMode() {
    this.diffInventorySelectionChanged.emit(null);
  }

  onHelpExpanderClick(): void {
    this.expandHelp.emit();
  }

  get isValidationValid(): boolean {
    return ValidationStatusHelper.isValidationValid(this.inventoryValidationStatus);
  }

  get isValidationWithErrorOrWarning(): boolean {
    return ValidationStatusHelper.isValidationWithError(this.inventoryValidationStatus) ||
        ValidationStatusHelper.isValidationWithWarning(this.inventoryValidationStatus);
  }

  get isValidationWithInfo(): boolean {
    return ValidationStatusHelper.isValidationWithInfo(this.inventoryValidationStatus) && !this.isValidationWithErrorOrWarning;
  }

  get currentInventoryKey(): string | null {
    return _.isNil(this.currentInventory) ? null : this.currentInventory.inventoryKey;
  }
}

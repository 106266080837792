export enum CheckActionText {
  Passed = 'passed',
  Failed = 'failed'
}

export enum CommandActionText {
  Execute = 'will be executed',
  NoExecute = 'will not be executed'
}

export enum DirectoryActionText {
  AttributeChangeAndChildrenDelete = 'attributes will be changed and child files or sub-directories will be deleted',
  AttributeChange = 'attributes will be changed',
  Create = 'will be created',
  NoChange = 'will not be changed'
}

export enum FileActionText {
  Create = 'will be created',
  ContentAndAttributeChange = 'content and attributes will be changed',
  ContentChange = 'content will be changed',
  AttributeChange = 'attributes will be changed',
  NoChange = 'will not be modified'
}

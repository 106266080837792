import { ActivatedRouteSnapshot } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { Observable, race } from 'rxjs';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';

import { allProjectsView, projectKeyView, selectedTenantKeyView } from '../../model/views';
import { TenantHelper } from '../helpers/tenant.helper';
import { ProjectHelper } from '../../projects/project.helper';
import { AppState, Dictionary } from '../../model/reducer';
import { RouteParamHelper } from '../helpers/route-param.helper';
import { localStorageProjectKey } from '../constants/local-storage-keys.constants';
import { projectKeyParam } from '../../projects/project-routing.constants';
import { SelectTenantKeyByUrl } from '../../model/shared/shared.actions';
import { Project } from '../../projects/project.model';
import { LocalStorageHelper } from '../helpers/local-storage.helper';
import { NavigationService } from '../../navbar/navigation.service';
import { ProjectService } from '../../projects/project.service';
import { timeoutWithMessage, wrapPromiseInSetTimeOut } from '../utils/utils';
import { LoadProjectsIfMissing, ProjectActionTypes, SelectProject } from '../../model/project';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { NevisAdminAction } from '../../model/actions';

export interface IProjectGuardMixin {
  store$: Store<AppState>;
  navigationService: NavigationService;
  projectService: ProjectService;
  modalNotificationService: ModalNotificationService;
  canActivateScreen: (next: ActivatedRouteSnapshot) => Observable<boolean>;
}

/**
 * Makes sure that the projects are loaded, reads the selected project from the route, then does either of the following:
 * - selects the project coming the route, even switches tenants if needed
 * - selects a default project
 * - navigates to the welcome page if there are no projects
 */
export class ProjectGuardMixin implements IProjectGuardMixin {

  store$: Store<AppState>;
  actions$: Actions<NevisAdminAction<any>>;
  navigationService: NavigationService;
  projectService: ProjectService;
  modalNotificationService: ModalNotificationService;
  navigateToScreen: (projectKey: string) => Promise<boolean>;

  public canActivateScreen(next: ActivatedRouteSnapshot): Observable<boolean> {
    const url = next.url.join('/');
    this.store$.dispatch(new LoadProjectsIfMissing());

    return race(
        this.actions$.pipe(ofType(ProjectActionTypes.ProjectsNowAvailable), first()),
        this.store$.select(allProjectsView).pipe(filter((allProjects: Record<string, Project>) => !_.isEmpty(allProjects)), first()),
    ).pipe(
      timeoutWithMessage<void>(5000, 'ProjectGuardMixin#canActivateScreen, preconditions did not resolve for route ' + url),
      switchMap((): Observable<Record<string, Project>> => {
        return this.store$.pipe(
          select(allProjectsView),
          first(),
        );
      }),
      withLatestFrom(this.store$.pipe(select(projectKeyView)), this.store$.pipe(select(selectedTenantKeyView))),
      map(([projects, storedProjectKey, selectedTenantKey]: [Dictionary<Project>, string | null, string]) => this.canActivateBasedOnData(next, projects, storedProjectKey, selectedTenantKey))
    );
  }

  private canActivateBasedOnData(next: ActivatedRouteSnapshot, projects: Dictionary<Project>, storedProjectKey: string | null, selectedTenantKey: string): boolean {
    const queryProjectKey: string | undefined = RouteParamHelper.getPathParamFromRoute(next, projectKeyParam);
    // automatically switch tenant if tenant of queried project key different from current
    const tenantKeyOfQueriedProject = _.isNil(queryProjectKey) ? null : TenantHelper.getTenantFromKey(queryProjectKey);
    if (!_.isNil(tenantKeyOfQueriedProject) && tenantKeyOfQueriedProject !== selectedTenantKey) {
      // angular has a bug with doubles navigation with hashchange trigger (described in https://github.com/angular/angular/issues/16710), therefore timeout works it around
      this.store$.dispatch(new SelectTenantKeyByUrl({
        tenantKey: tenantKeyOfQueriedProject,
        afterSelect: () => wrapPromiseInSetTimeOut(() => this.navigateToScreen(<string>queryProjectKey))
      }));
      return false;
    }
    if (_.isEmpty(projects) && !_.isNil(queryProjectKey)) {
      this.showCannotOpenProjectWindow(queryProjectKey);
      setTimeout(() => this.navigationService.navigateToWelcome());
      return false;
    }
    // go to welcome screen when there are no projects
    if (_.isEmpty(projects)) {
      setTimeout(() => this.navigationService.navigateToWelcome());
      return false;
    }
    // go to stored project or first from list if url doesn't contain project key
    if (_.isNil(queryProjectKey)) {
      this.navigateToInitial(projects, storedProjectKey, selectedTenantKey);
      return false;
    }
    // navigate to project with key from url if such project exists
    if (!_.isNil(projects[queryProjectKey])) {
      if (storedProjectKey !== queryProjectKey) {
        this.store$.dispatch(new SelectProject(queryProjectKey));
      }
      return true;
    } else {
      // otherwise from store or first from list
      this.showCannotOpenProjectWindow(queryProjectKey, 'Instead, previous or first available project is now selected.');
      this.navigateToInitial(projects, storedProjectKey, selectedTenantKey);
      return false;
    }
  }

  private navigateToInitial(projects: Dictionary<Project>, storedProjectKey: string | null, selectedTenantKey: string): void {
    const localStorageKey = LocalStorageHelper.prefixKey(localStorageProjectKey, selectedTenantKey);
    const projectKey = ProjectHelper.getFromStoreOrFirstAvailable(storedProjectKey, projects, localStorageKey);
    if (!_.isNil(projectKey)) {
      // angular has a bug with doubles navigation with hashchange trigger (described in https://github.com/angular/angular/issues/16710), therefore timeout works it around
      setTimeout(() => this.navigateToScreen(projectKey));
    }
  }

  public showCannotOpenProjectWindow(projectKey: string, additionalText?: string): void {
    const projectName: string = TenantHelper.cropTenantFromKey(projectKey);
    const title = `Could not open project ${projectName}`;
    const secondLine = _.isNil(additionalText) ? '' : `<br>${additionalText}`;
    this.modalNotificationService.openErrorDialog({title: title, description: `The project does not exist or you are not permitted to view it.${secondLine}`});
  }
}

import { InventorySchemaType } from '../../../inventory/inventory.model';

export class InventorySchemaTypeHelper {

  static getInventoryIconName(schemaType?: InventorySchemaType): string {
    switch (schemaType) {
      case InventorySchemaType.CLASSIC:
        return 'insert_drive_file';
      case InventorySchemaType.KUBERNETES:
        return 'cloud';
      default:
        return 'insert_drive_file';
    }
  }

  static isClassicDeployment(inventorySchemaType?: InventorySchemaType): boolean {
    if (!inventorySchemaType) {
      return true;
    }
    return inventorySchemaType === InventorySchemaType.CLASSIC;
  }

  static isKubernetesDeployment(inventorySchemaType?: InventorySchemaType): boolean {
    return !!inventorySchemaType && inventorySchemaType === InventorySchemaType.KUBERNETES;
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { Inventory, InventoryMeta } from '../inventory.model';
import { InventoryValidationIssue, ValidationStatus } from '../../common/model/validation-status.model';
import { AppState, Dictionary, ProjectKey } from '../../model/reducer';
import { select, Store } from '@ngrx/store';
import { allInventoriesView, inventoryKeyView, inventoryMetaView } from '../../model/views';
import { first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';
import { LoadDiffData, RequestPublishInventory } from '../../model/publish-inventory/publish-inventory.actions';

@Injectable()
export class PublishInventoryContext implements OnDestroy {
  private _inventoryKeySnapshot$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _currentBranchSnapshot$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _inventoryMetaSnapshot$: BehaviorSubject<InventoryMeta | null> = new BehaviorSubject(null);
  private _inventoryValidationStatusSnapshot$: BehaviorSubject<ValidationStatus<InventoryValidationIssue> | null> = new BehaviorSubject(null);

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  inventoryKey$: Observable<ProjectKey | null> = this._inventoryKeySnapshot$.asObservable();
  currentBranch$: Observable<string | null> = this._currentBranchSnapshot$.asObservable();
  inventoryMeta$: Observable<InventoryMeta | null> = this._inventoryMetaSnapshot$.asObservable();
  inventoryValidationStatus$: Observable<ValidationStatus<InventoryValidationIssue> | null> = this._inventoryValidationStatusSnapshot$.asObservable();

  constructor(private store$: Store<AppState>) {
    this.store$.pipe(select(inventoryKeyView), first((inventoryKey: string | null) => !_.isNil(inventoryKey)), takeUntil(this.destroyed$))
      .subscribe((inventoryKey: string) => this._inventoryKeySnapshot$.next(inventoryKey));

    this.store$.pipe(select(inventoryMetaView), first((inventoryMeta: InventoryMeta | null) => !_.isNil(inventoryMeta)), takeUntil(this.destroyed$))
      .subscribe((inventoryMeta: InventoryMeta) => this._inventoryMetaSnapshot$.next(inventoryMeta));

    forkJoin([this.store$.pipe(select(allInventoriesView), first()), this.inventoryKey$.pipe(first())]).pipe(
      map(([inventories, inventoryKey]: [Dictionary<Inventory>, string]) => {
        const inventory: Inventory | undefined = inventories[inventoryKey];
        return _.isNil(inventory) ? null : inventory.branch || null;
      }),
      takeUntil(this.destroyed$)
    ).subscribe((branch: string | null) => this._currentBranchSnapshot$.next(branch));
  }

  preloadDiffs(): void {
    this.store$.dispatch(new LoadDiffData());
  }

  requestPublishInventoryChanges(commitMessage: string): void {
    this.inventoryMeta$.pipe(
      first((inventoryMeta: InventoryMeta | null) => !_.isNil(inventoryMeta)),
      withLatestFrom(this.inventoryKey$, this.store$.pipe(select(allInventoriesView))),
      takeUntil(this.destroyed$)
    ).subscribe(([inventoryMeta, inventoryKey, inventories]: [InventoryMeta, string | null, Dictionary<Inventory>]) => {
      const inventoryToPublish: Inventory | undefined = _.isNil(inventoryKey) ? undefined : inventories[inventoryKey];
      if (_.isNil(inventoryToPublish)) {
        return;
      }
      this.store$.dispatch(new RequestPublishInventory({inventoryMeta, commitMessage, inventory: inventoryToPublish}));
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PatternGroupingOption } from './pattern-grouping-options.enum';

/**
 * It's used for sending data between the PatternMain component and PatternMasterList component
 * It triggers grouping option change when the link is clicked in the PatternEditor's label tooltip
 */
@Injectable()
export class PatternMainConnectService {
  private _groupingOption = new Subject<PatternGroupingOption | null>();

  groupingOption$: Observable<PatternGroupingOption | null> = this._groupingOption.asObservable();

  setGroupingOption(patternGroupingOption: PatternGroupingOption): void {
    this._groupingOption.next(patternGroupingOption);
  }
}

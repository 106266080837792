import {
  Certificate, CertificateWrapperWithUsage,
  KubernetesCertificate, KubernetesCertificateWrapper,
  ResourceWrapperWithUsage, SecretResourceWrapperWithUsage, SecretWrapperWithUsage,
  TenantResourceFlag,
} from '../../../inventory/inventory.model';
import * as _ from 'lodash';

export class InventoryResourceSortingHelper {

  static byTenantScopeSortingFn<T extends (SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage | CertificateWrapperWithUsage | KubernetesCertificateWrapper) & TenantResourceFlag>(res1: T, res2: T): number {
    return (res1.isTenantScoped === res2.isTenantScoped) ? 0 : res1.isTenantScoped ? -1 : 1;
  }

  static byFileNameSortingFn<T extends (SecretWrapperWithUsage | SecretResourceWrapperWithUsage | ResourceWrapperWithUsage) & TenantResourceFlag>(res1: T, res2: T): number {
    if ('secretResourceName' in res1) {
      const secRes1: SecretResourceWrapperWithUsage & TenantResourceFlag = res1 as SecretResourceWrapperWithUsage & TenantResourceFlag;
      const secRes2: SecretResourceWrapperWithUsage & TenantResourceFlag = res2 as SecretResourceWrapperWithUsage & TenantResourceFlag;
      return secRes1.secretResourceName.localeCompare(secRes2.secretResourceName);
    } else if ('resourceName' in res1) {
      const fileRes1: ResourceWrapperWithUsage & TenantResourceFlag = res1 as ResourceWrapperWithUsage & TenantResourceFlag;
      const fileRes2: ResourceWrapperWithUsage & TenantResourceFlag = res2 as ResourceWrapperWithUsage & TenantResourceFlag;
      return fileRes1.resourceName.localeCompare(fileRes2.resourceName);
    }
    return 0;
  }


  static byExpirationDateSortingFn(cert1: CertificateWrapperWithUsage | KubernetesCertificateWrapper, cert2: CertificateWrapperWithUsage | KubernetesCertificateWrapper): number {
    if (_.isEmpty(cert1.certificates) && !_.isEmpty(cert2.certificates)) {
      return -1;
    } else if (!_.isEmpty(cert1.certificates) && _.isEmpty(cert2.certificates)) {
      return 1;
    } else if (!_.isEmpty(cert1.certificates) && !_.isEmpty(cert2.certificates)) {
      return this.compareCertificateDates(cert1.certificates, cert2.certificates);
    }
    return 0;
  }

  static compareCertificateDates(certList1: Certificate[] | KubernetesCertificate[], certList2: Certificate[] | KubernetesCertificate[]): number {
    const earliestExpirationDateCert1 = certList1.reduce((prev, curr) => {
      return Date.parse(prev.expires) > Date.parse(curr.expires) ? curr : prev;
    });
    const earliestExpirationDateCert2 = certList2.reduce((prev, curr) => {
      return Date.parse(prev.expires) > Date.parse(curr.expires) ? curr : prev;
    });
    return Date.parse(earliestExpirationDateCert1.expires) - Date.parse(earliestExpirationDateCert2.expires);
  }
}

import { Property } from '../plugins/pattern-type.model';
import { PropertyType } from '../plugins/property-type.model';


export class ParameterHelper {

  public static MIN_REQUIRED = 'minRequired';
  public static MAX_ALLOWED = 'maxAllowed';
  public static SECRET_PARAM = 'secret';
  public static SEPARATORS = 'separators';
  public static SWITCHED_SEPARATORS = 'switchedSeparators';
  public static SYNTAX = 'syntax';

  // The expected lower bound for the element’s value.
  public static MIN = 'min';
  // The expected upper bound for the element’s value.
  public static MAX = 'max';

  static getSpecificParameterValueNumber(parameterName: string, parameters: any, propertyType?: PropertyType): number {
    return ParameterHelper.getSpecificParameterValue(parameterName, parameters, propertyType);
  }

  static getSpecificParameterValueBoolean(parameterName: string, parameters: any, propertyType?: PropertyType): boolean {
    return ParameterHelper.getSpecificParameterValue(parameterName, parameters, propertyType);
  }

  static getSpecificParameterValue(parameterName: string, parameters: any, propertyType?: PropertyType): any {
    if (!parameters[parameterName] && !!propertyType) {
      const defaultParam = propertyType.parameters.find(param => param.parameterKey === parameterName);
      return !!defaultParam ? defaultParam.defaultValue : undefined;
    }
    return parameters[parameterName];
  }

  static isRequiredPropertyValueWithNoDefault(patterTypeProp: Property | undefined, propertyType: PropertyType | undefined, defaultValue: string | null): any {
        if (patterTypeProp && propertyType) {
            const minRequired =  ParameterHelper.getSpecificParameterValueNumber(ParameterHelper.MIN_REQUIRED, patterTypeProp.parameters, propertyType);
            return !defaultValue && minRequired > 0;
        }
        return false;
  }

  static isMultiAllowed(patternPropType: Property | undefined): boolean {
    const maxAllowed: unknown = patternPropType?.parameters?.[ParameterHelper.MAX_ALLOWED];
    if (typeof maxAllowed !== 'number') {
      return true;
    }
    return maxAllowed > 1;
  }
}

import { ProjectMeta } from '../../projects/project.model';
import { LocalStatus } from '../../version-control/meta-info.model';
import { PatternVersionInfo } from '../../version-control/pattern-meta-info.model';
import * as _ from 'lodash';
import * as vagueTime from 'vague-time';
import { Dictionary } from '../../model/reducer';
import { convertObjectToArray } from './project-summary.helper';

/**
 * Counts how much pattern version info we have with the given LocalStatus.
 */
export function countPatternsWithStatus(projectStatusModel: ProjectMeta | undefined, status: LocalStatus) {
  if (!projectStatusModel || !projectStatusModel.patterns) {
    return 0;
  }
  const patterns = _.values(projectStatusModel.patterns);
  const matchingStatusArray = _.filter(patterns, ((info: PatternVersionInfo) => {
      return info.localStatus === status;
  }));
  return matchingStatusArray.length;
}

/**
 * Checks if projectStatusModel.patterns has more than the given number.
 * Before the check it filters out the unmodified ones.
 */
export function haveMoreUnpublishedPatternChanges(projectStatusModel: ProjectMeta | undefined, number: number): boolean {
  if (!projectStatusModel || !projectStatusModel.patterns) {
    return false;
  }
  const patternVersionInfos = filterOutUnmodifiedPatternVersionInfo(projectStatusModel.patterns);
  return patternVersionInfos.length > number;
}

/**
 * Converts ProjectStatusModel object to Array<PatternVersionInfoGroup[] to be displayed as last unpublished changes.
 */
export function convertProjectStatus(projectStatusModel: ProjectMeta | undefined, limit?: number): Array<string | Array<PatternVersionInfo>> {
  if (!projectStatusModel || !projectStatusModel.patterns) {
    return [];
  }

  const filteredPatternVersionInfos = filterOutUnmodifiedPatternVersionInfo(projectStatusModel.patterns);
  let sortedPatternVersionInfos = sortPatternVersionInfosByDate(filteredPatternVersionInfos);

  if (limit) {
    sortedPatternVersionInfos = _.take(sortedPatternVersionInfos, 8);
  }

  return groupByDate(sortedPatternVersionInfos);
}

/**
 * Filters out all PatternVersionInfo objects which  are LocalStatus.Unmodified and returns the rest.
 */
export function filterOutUnmodifiedPatternVersionInfo(patternVersionInfos: Dictionary<PatternVersionInfo>): PatternVersionInfo[] {
  return _.filter(_.values(patternVersionInfos), ((patternVersionInfo: PatternVersionInfo) => {
    return patternVersionInfo.localStatus !== LocalStatus.Unmodified;
  }));
}

export function groupByDate(patternVersionInfos: PatternVersionInfo[] | undefined): Array<string | Array<PatternVersionInfo>> {
  const groups = _.groupBy(patternVersionInfos, (patternVersionInfo: PatternVersionInfo) => {
    if (!patternVersionInfo.localDate) {
      return;
    }
    const date = new Date(patternVersionInfo.localDate);

    return vagueTime.get({
      from: Date.now(),
      to: date
    });
  });
  return convertObjectToArray(groups);
}

/**
 * Sorts the given array of PatternVersionInfo objects based on their localDate or remoteDate.
 */
export function sortPatternVersionInfosByDate(patternVersionInfos: PatternVersionInfo[] | undefined): PatternVersionInfo[] {
  if (!patternVersionInfos) {
    return [];
  }
  return patternVersionInfos.sort((a: PatternVersionInfo, b: PatternVersionInfo) => {
    const dateISOStringA = a.localDate || a.remoteDate || '';
    const dateISOStringB = b.localDate || b.remoteDate || '';

    const dateA = new Date(dateISOStringA);
    const dateB = new Date(dateISOStringB);

    if (dateA < dateB) {
      return 1;
    } else if (dateA > dateB) {
      return -1;
    } else {
      return 0;
    }
  });
}

import { NgModule } from '@angular/core';
import { ProjectHeaderComponent } from './project-header.component';
import { ProjectMenuModule } from '../project-menu/project-menu.module';
import { CommonModules } from '../../common/common.module';
import { ProjectDropdownComponent } from './project-dropdown/project-dropdown.component';

@NgModule({
  imports: [
    CommonModules,
    ProjectMenuModule
  ],
  declarations: [
    ProjectHeaderComponent,
    ProjectDropdownComponent
  ],
  exports: [
    ProjectHeaderComponent
  ]
})
export class ProjectHeaderModule {
}

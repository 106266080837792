import { IssueModel, SourceType, Target } from '../../../common/model/issue.model';
import { TreeNode } from '../../tree-viewer/tree-node.model';
import { TreeNodeTypeEnum } from '../../tree-viewer/tree-node-type.enum';
import { Pattern } from '../../../patterns/pattern.model';
import * as _ from 'lodash';
import { TreeGeneratingHelper } from '../../tree-viewer/tree-generating.helper';
import { ValidationIssueGroup } from './validation-issue-group.model';
import { getOptionalTargetValue, getSpecificTargetOfIssue } from '../../../projects/project-issues/project-issues.helper';
import { generalIssueGroupId, generalIssueGroupName } from '../../../issues/issues.constants';
import { Dictionary } from '../../../model/reducer';
import { criteriaSorting, pluralizeText } from '../../../common/utils/utils';

/**
 * This contains the logic of creating a tree model out of a list of issues
 */
export class ValidationTreeHelper {

  static groupValidationIssues(issues: IssueModel[]): ValidationIssueGroup[] {
    return issues.reduce((issueGroups: ValidationIssueGroup[], issue: IssueModel) => {
      const issuePattern: Target | undefined = getSpecificTargetOfIssue(issue, SourceType.DEPLOYABLE_PATTERN);
      const issueHost: Target | undefined = getSpecificTargetOfIssue(issue, SourceType.HOST);
      const issueTargetNameByPattern: string = getOptionalTargetValue(issuePattern) || generalIssueGroupId;
      const issueHostName: string | null = getOptionalTargetValue(issueHost);
      const existingGroup: ValidationIssueGroup | undefined = issueGroups.find(group => group.issueGroupName === issueTargetNameByPattern && group.hostName === issueHostName);
      if (_.isNil(existingGroup)) {
        return _.concat(issueGroups, [{issues: [issue], issueGroupName: issueTargetNameByPattern, hostName: issueHostName}]);
      } else {
        const updatedIssueGroup: ValidationIssueGroup = {...existingGroup, issues: existingGroup.issues.concat([issue])};
        return issueGroups.map(group => group === existingGroup ? updatedIssueGroup : group);
      }
    }, []);
  }

  /**
   * It return a tree model from a list of issues
   * It either create a deployable pattern node with host children or an 'other' node when no deployable pattern is specified
   * @param {IssueModel[]} issues
   * @param patterns
   * @returns {any}
   */
  static getTreeFromValidationResult(issues: IssueModel[], patterns: Map<string, Pattern>): TreeNode[] {
    const issueGroups: ValidationIssueGroup[] = this.groupValidationIssues(issues);
    const issueGroupsByGroupName: Dictionary<ValidationIssueGroup[]> = _.groupBy(issueGroups, (issueGroup: ValidationIssueGroup) => issueGroup.issueGroupName);
    const validationTree: TreeNode[] = _.entries(issueGroupsByGroupName).map(([issueGroupId, groups]: [string, ValidationIssueGroup[]]) => {
      if (issueGroupId === generalIssueGroupId) {
        return this.createGeneralNode(groups);
      }
      const patternNode: TreeNode = this.createPatternNode(issueGroupId, patterns);
      const hostNodes: TreeNode[] = this.createHostNodes(groups);
      return {...patternNode, children: hostNodes};
    });
    return this.sortTopLevelNodes(validationTree);
  }

  static getKubernetesTreeFromValidationResult(issues: IssueModel[], patterns: Map<string, Pattern>): TreeNode[] {
    const issueGroups: ValidationIssueGroup[] = this.groupValidationIssues(issues);
    const issueGroupsByGroupNameAndService: Dictionary<ValidationIssueGroup[]> = _.groupBy(issueGroups, (issueGroup: ValidationIssueGroup) => {
      if (issueGroup.issueGroupName === generalIssueGroupId) {
        return generalIssueGroupId;
      }
      return `${issueGroup.issueGroupName}-${issueGroup.hostName}`;
    });

    const validationTree: TreeNode[] = _.entries(issueGroupsByGroupNameAndService).map(([issueGroupId, groups]: [string, ValidationIssueGroup[]]) => {
      if (issueGroupId.startsWith(generalIssueGroupId)) {
        return this.createGeneralNode(groups);
      }
      return this.createPatternServiceNode(patterns, groups);
    });
    return this.sortTopLevelNodes(validationTree);
  }

  static createGeneralNode(issueGroups: ValidationIssueGroup[]): TreeNode {
    const issues: IssueModel[] = _.concat([], ...issueGroups.map(issueGroup => issueGroup.issues));
    const errorsCount: number = issues.filter(issue => issue.severity === 'ERROR').length;
    const warningsCount: number = issues.filter(issue => issue.severity === 'WARNING').length;
    const generalNode: TreeNode = TreeGeneratingHelper.createNode(TreeNodeTypeEnum.DeployablePattern, generalIssueGroupId, generalIssueGroupName, [], issues);
    return {...generalNode, nrErrors: errorsCount, nrWarnings: warningsCount};
  }

  static createPatternNode(groupId: string, patterns: Map<string, Pattern>): TreeNode {
    const patternName: string = this.getPatternNameById(patterns, groupId);
    return TreeGeneratingHelper.createNode(TreeNodeTypeEnum.DeployablePattern, groupId, patternName, []);
  }

  static createHostNodes(issueGroups: ValidationIssueGroup[]): TreeNode[] {
    return issueGroups
      .filter((issueGroup: ValidationIssueGroup) => !_.isNil(issueGroup.hostName))
      .map((issueGroup: ValidationIssueGroup) => {
        const errorsCount: number = issueGroup.issues.filter(issue => issue.severity === 'ERROR').length;
        const warningsCount: number = issueGroup.issues.filter(issue => issue.severity === 'WARNING').length;
        const nodeId = issueGroup.issueGroupName + <string>issueGroup.hostName;
        const nodeName = this.getHostNodeName(<string>issueGroup.hostName, errorsCount, warningsCount);
        const hostNode: TreeNode = TreeGeneratingHelper.createNode(TreeNodeTypeEnum.Host, nodeId, nodeName, [], issueGroup.issues);
        return {...hostNode, nrErrors: errorsCount, nrWarnings: warningsCount};
      });
  }

  static getHostNodeName(hostName: string, errorsCount: number, warningsCount: number): string {
    const errorText = this.getErrorText(errorsCount);
    const warningText = this.getWarningText(warningsCount);
    const detailParts = [errorText, warningText].filter(part => !_.isEmpty(part));
    return `${hostName} (${detailParts.join(' ')})`;
  }

  private static getWarningText(warningsCount: number) {
    return warningsCount > 0 ? `${warningsCount} ${pluralizeText(warningsCount, 'warning', 'warnings')}` : '';
  }

  private static getErrorText(errorsCount: number) {
    return errorsCount > 0 ? `${errorsCount} ${pluralizeText(errorsCount, 'error', 'errors')}` : '';
  }

  static createPatternServiceNode(patterns: Map<string, Pattern>, issueGroups: ValidationIssueGroup[]): TreeNode {
    const filteredIssueGroup = issueGroups.filter((issueGroup: ValidationIssueGroup) => !_.isNil(issueGroup.hostName));
    const firstIssueGroup: ValidationIssueGroup = filteredIssueGroup[0];

    const patternId = firstIssueGroup.issueGroupName;
    const patternName: string = this.getPatternNameById(patterns, patternId);

    const errorsCount: number = firstIssueGroup.issues.filter(issue => issue.severity === 'ERROR').length;
    const warningsCount: number = firstIssueGroup.issues.filter(issue => issue.severity === 'WARNING').length;
    const serviceName = <string>firstIssueGroup.hostName;
    const nodeId = `${patternId}-${serviceName}`;
    const nodeName = this.getPatternServiceNodeName(patternName, serviceName, errorsCount, warningsCount);
    const hostNode: TreeNode = TreeGeneratingHelper.createNode(TreeNodeTypeEnum.DeployablePattern, nodeId, nodeName, [], firstIssueGroup.issues);

    return {...hostNode, nrErrors: errorsCount, nrWarnings: warningsCount};
  }

  static getPatternServiceNodeName(patternName: string, serviceName: string, errorsCount: number, warningsCount: number): string {
    const errorText = this.getErrorText(errorsCount);
    const warningText = this.getWarningText(warningsCount);
    const issueCountsParts = [errorText, warningText].filter(part => !_.isEmpty(part));
    if (patternName === serviceName) {
      return `${patternName} (${issueCountsParts.join(' ')})`;
    } else {
      return `${patternName} (${serviceName}) (${issueCountsParts.join(' ')})`;
    }
  }

  /**
   * Returns a pattern name based on id or simply the id if pattern is not found
   * @param {Map<string, Pattern>} patterns
   * @param {string} id
   * @returns {string}
   */
  static getPatternNameById(patterns: Map<string, Pattern>, id: string): string {
    const pattern = patterns.get(id);
    return pattern ? pattern.name : id;
  }

  static sortTopLevelNodes(tree: TreeNode[]): TreeNode[] {
    return [...tree].sort(criteriaSorting([
      (el1, el2) => this.sortForGeneralFirst(el1, el2),
      (el1, el2) => el1.name.localeCompare(el2.name, undefined, {sensitivity: 'accent'})
    ]));
  }

  /**
   * General node should be on top of the tree
   * @param el1
   * @param el2
   */
  static sortForGeneralFirst(el1: TreeNode, el2: TreeNode): number {
    if (el1.name === el2.name) {
      return 0;
    } else if (el1.name === generalIssueGroupName) {
      return -1;
    } else if (el2.name === generalIssueGroupName) {
      return 1;
    }
    return 0;
  }
}

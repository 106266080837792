import { LocalStorageHelper } from '../../common/helpers/local-storage.helper';
import { localStorageAlwaysSaveChanges } from '../../common/constants/local-storage-keys.constants';
import * as _ from 'lodash';

export class SaveChangesDialogHelper {
  static shouldAutoSave(): boolean {
    try {
      const shouldAlwaysSave: string | null = LocalStorageHelper.retrieve(localStorageAlwaysSaveChanges);
      if (_.isNil(shouldAlwaysSave)) {
        return false;
      }
      const shouldAlwaysSaveParsed = JSON.parse(shouldAlwaysSave);
      return _.isBoolean(shouldAlwaysSaveParsed) ? shouldAlwaysSaveParsed : false;
    } catch (e) {
      return false;
    }
  }
}

import * as _ from 'lodash';
import { SplitPaneConfig } from '../model/split-pane-config.model';
import { ResizeHelper } from '../helpers/resize.helper';
import { Dictionary } from '../../model/reducer';
import { IOutputData } from 'angular-split/lib/interface';

export interface ISplitMixin {
  splitPaneConfigLocalStorageKey: string;
  splitPaneConfig: Dictionary<SplitPaneConfig>;
  onResize: (event: IOutputData) => void;
  onCollapse?: (isCollapsed: boolean, collapsibleAreaKey: string) => void;
}

/**
 * SplitMixin takes care about handling of resizing/collapsing by Angular Split module
 * The user class should provide default configuration for the split pane
 */
export class SplitMixin implements ISplitMixin {
  readonly splitPaneConfigLocalStorageKey: string;
  splitPaneConfig: Dictionary<SplitPaneConfig>;

  /**
   * Call this method on dragend
   * @param event
   */
  onResize(event: IOutputData): void {
    const splitConfigs = _.keys(this.splitPaneConfig).filter((key) => !this.splitPaneConfig[key].isCollapsed);
    if (event.sizes.length !== splitConfigs.length) {
      return;
    }
    this.splitPaneConfig = {
      ...this.splitPaneConfig,
      ...splitConfigs.reduce((splitPaneConfig: Dictionary<SplitPaneConfig>, key: string) => {
        return {...splitPaneConfig, [key]: ResizeHelper.updateResizeConfigOnDrag(this.splitPaneConfig[key], event.sizes as number[])};
      }, {})
    };
    ResizeHelper.storeSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
  }

  /**
   * Call this method when a split area is collapsed
   * @param isCollapsed - boolean whether area was collapsed or expanded
   * @param collapsibleAreaKey - key of collapsible area configuration, which will be either collapsed or expanded
   */
  onCollapse(isCollapsed: boolean, collapsibleAreaKey: string): void {
    if (this.splitPaneConfig[collapsibleAreaKey].isCollapsed === isCollapsed) {
      return;
    }
    this.splitPaneConfig = {
      ...this.splitPaneConfig,
      [collapsibleAreaKey]: {...this.splitPaneConfig[collapsibleAreaKey], isCollapsed: isCollapsed}
    };
    ResizeHelper.storeSplitPaneConfig(this.splitPaneConfigLocalStorageKey, this.splitPaneConfig);
  }
}

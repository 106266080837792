import {
  AfterViewInit,
  ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild,
} from '@angular/core';

import { ReplaySubject } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { ProjectTemplate } from '../../marketplace.model';

@Component({
  selector: 'adm4-marketplace-tile',
  templateUrl: './marketplace-tile.component.html',
  styleUrls: ['./marketplace-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketplaceTileComponent implements OnChanges, AfterViewInit {
  @Input() item: ProjectTemplate = {} as any;
  @Input() selectedProjectTemplateKey: string | null;

  @Output() selected: EventEmitter<string> = new EventEmitter();

  @ViewChild('tileBody', {read: ElementRef, static: false}) tileBody: ElementRef;

  public isSelected: boolean = false;

  private viewInited: ReplaySubject<void> = new ReplaySubject(1);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProjectTemplateKey) {
      const prevSelected: boolean = this.isSelected;
      this.isSelected = this.item?.projectTemplateKey === this.selectedProjectTemplateKey;
      if (this.isSelected && !prevSelected) {
        // the delay is to allow the DOM to completely render before scrolling to the element
        this.viewInited.pipe(take(1), delay(20)).subscribe(() => {
          this.tileBody?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest'});
        });
      }
    }
  }

  ngAfterViewInit(): void {
    this.viewInited.next();
  }

  public select(event: Event) {
    event.stopPropagation();
    this.selected.emit(this.item.projectTemplateKey);
  }
}

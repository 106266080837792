import { Injectable } from '@angular/core';
import { PublishProjectContext } from '../publish-project.context';
import { DeploymentHostDiffData, PatternDiffData, PatternLabelDiffData, PatternLinkDiffData, PatternNameDiffData, PatternNotesDiffData, PropertyDiffData, SimplePatternDiffData } from './pattern-diff-data.model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { map, publishReplay, refCount } from 'rxjs/operators';
import * as _ from 'lodash';
import { VariableModel } from '../../../variables/variable.model';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../model/reducer';
import { showOnlyDifferencesView } from '../../../model/views';
import { StoreShowOnlyDifferences } from '../../../model/publish-project/publish-project.actions';

@Injectable()
export class PatternDiffViewContext {

  private patternId$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  patternDiff: Observable<PatternDiffData | null>;
  patternNameDiff$: Observable<Diff<PatternNameDiffData> | null>;
  patternLabelDiff$: Observable<Diff<PatternLabelDiffData> | null>;
  patternLinkDiff$: Observable<Diff<PatternLinkDiffData> | null>;
  simplePatternDiffData$: Observable<Diff<SimplePatternDiffData>[]>;
  deploymentHostDiffData$: Observable<Diff<DeploymentHostDiffData> | null>;
  patternNotesDiffData$: Observable<Diff<PatternNotesDiffData> | null>;
  propertyDiffs$: Observable<Diff<PropertyDiffData>[]>;
  variables$: Observable<VariableModel[]>;
  projectKey$: Observable<string>;
  currentBranch$: Observable<string | null>;
  showOnlyDifferences$: Observable<boolean>;

  constructor(private publishProjectContext: PublishProjectContext, private store$: Store<AppState>) {
    this.patternDiff = combineLatest([this.publishProjectContext.patternsDiff$, this.patternId$]).pipe(
      map(([patternDiffs, currentPatternId]: [PatternDiffData[], string]) => patternDiffs.find(patternDiff => patternDiff.patternId === currentPatternId) || null),
      publishReplay(1),
      refCount(),
    );
    this.patternNameDiff$ = this.patternDiff.pipe(map((patternDiffData: PatternDiffData | null) => _.isNil(patternDiffData) ? null : patternDiffData.patternNameDiffData));
    this.patternLabelDiff$ = this.patternDiff.pipe(map((patternDiffData: PatternDiffData | null) => _.isNil(patternDiffData) ? null : patternDiffData.patternLabelDiffData));
    this.patternLinkDiff$ = this.patternDiff.pipe(map((patternDiffData: PatternDiffData | null) => _.isNil(patternDiffData) ? null : patternDiffData.patternLinkDiffData));
    this.simplePatternDiffData$ = this.patternDiff.pipe(map((patternDiffData: PatternDiffData | null) => _.isNil(patternDiffData) ? [] : patternDiffData.simplePatternDiffData));
    this.deploymentHostDiffData$ = this.patternDiff.pipe(map((patternDiffData: PatternDiffData | null) => _.isNil(patternDiffData) ? null : patternDiffData.deploymentHostDiffData));
    this.patternNotesDiffData$ = this.patternDiff.pipe(map((patternDiffData: PatternDiffData | null) => _.isNil(patternDiffData) ? null : patternDiffData.patternNotesDiffData));
    this.propertyDiffs$ = this.patternDiff.pipe(map((patternDiffData: PatternDiffData | null) => _.isNil(patternDiffData) ? [] : patternDiffData.propertiesDiffData));
    this.variables$ = this.publishProjectContext.variables$;
    this.projectKey$ = this.publishProjectContext.projectKey$ as Observable<string>;
    this.currentBranch$ = this.publishProjectContext.currentBranch$;
    this.showOnlyDifferences$ = this.store$.pipe(select(showOnlyDifferencesView));
  }

  selectPatternId(patternId: string): void {
    this.patternId$.next(patternId);
  }

  rememberShowDifferencesView(showDiffOnly: boolean): void {
    this.store$.dispatch(new StoreShowOnlyDifferences(showDiffOnly));
  }
}

import {TreeNodeTypeEnum} from '../../deployment-wizard/tree-viewer/tree-node-type.enum';

export interface SideBySideModel {
  host: string;
  kubernetesCustomResources: KubernetesCustomResource[];
}

export interface KubernetesCustomResource {
  customResourceKey: string;
  label: string;
  kind: string;
  name: string;
  deploymentTarget: string;
  state: KubernetesCustomResourceState;
}

export enum KubernetesCustomResourceState {
  PRIMARY = 'primary',
  SECONDARY = 'canary'
}

export interface CanaryTreeNode  {
  id: any;
  name: string;
  type: string;
  children: CanaryTreeNode[],
  isChildNode: boolean;
  isSecondaryNode: boolean;
}

const createCanaryTreeNode = (
    type: TreeNodeTypeEnum,
    id: string,
    name: string,
    children?: CanaryTreeNode[],
): CanaryTreeNode => {
  return {
    id: id,
    name: name,
    type: type,
    children: children??[],
    isChildNode: type === TreeNodeTypeEnum.KubernetesCustomResource,
    isSecondaryNode: name === KubernetesCustomResourceState.SECONDARY,
  };
};

export const k8sCustomResourceListToTree = (item: SideBySideModel): CanaryTreeNode => {
  const kubernetesCustomResources: CanaryTreeNode[] = (item.kubernetesCustomResources??[])
      .map((resource: KubernetesCustomResource) => createCanaryTreeNode(TreeNodeTypeEnum.KubernetesCustomResource, resource.customResourceKey, resource.label, []));
  return createCanaryTreeNode(TreeNodeTypeEnum.Host, item.host, item.host, kubernetesCustomResources);
};

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { PatternInstance } from '../../patterns/pattern-instance.model';
import { PatternType } from '../../plugins/pattern-type.model';
import * as _ from 'lodash';
import { Pattern } from '../../patterns/pattern.model';
import { NavigationService } from '../../navbar/navigation.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Dictionary } from '../../model/reducer';

@Component({
  selector: 'adm4-added-pattern-list',
  template: `
    <ul cdkDropList class='ui-pattern-list' (cdkDropListDropped)="onDrop($event)">
      <li *ngFor='let pattern of patternList' class='pattern-item' cdkDrag [cdkDragDisabled]="isDragDisabled">
        <div *ngIf='!readOnly && patternList.length > 1' class='drop-down' cdkDragHandle><i class="fa fa-arrows-v" aria-hidden="true"></i></div>
        <div class='ui-pattern-content' (focus)="focusLink.emit()" tabindex='0'>
          <ng-container *ngIf='!isPatternDeleted(pattern); else textPattern'>
            <a href='javascript:void(0)' class='pattern-info-text' (click)='navigateToPattern(pattern.patternId, $event)' #patternNameRef
               [ngbTooltip]='patternNamePopover' placement='bottom' [disableTooltip]='!isEllipsisActive(patternNameRef)'>
              {{pattern.name}}</a>
            <div class='pattern-info-text pattern-class' #patternTypeRef [ngbTooltip]='patternTypePopover' [disableTooltip]='!isEllipsisActive(patternTypeRef)' placement='right'>
              {{getPatternType(pattern) || 'unknown pattern type of pattern://' + pattern.patternId}}</div>
          </ng-container>
          <ng-template #textPattern>{{pattern.name}}</ng-template>
        </div>
        <div *ngIf='pattern.label' class='label-info' [ngbTooltip]='labelPopover' placement='top'>
          <span class='label-text'>{{pattern?.label}}</span>
          <mat-icon class='label-icon'>local_offer</mat-icon>
        </div>
        <ng-template #labelPopover>{{pattern?.label}}</ng-template>
        <ng-template #patternNamePopover>{{pattern.name}}</ng-template>
        <ng-template #patternTypePopover>{{getPatternType(pattern)}}</ng-template>
        <button *ngIf='!readOnly'
                type='button'
                class='admn4-button-icon delete-button'
                (click)='!readOnly && deletePattern.emit(pattern)'>
          <i class='fa fa-times-circle-o'></i>
        </button>
      </li>
    </ul>
  `,
  styleUrls: ['added-pattern-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddedPatternListComponent implements OnDestroy {
  @Input() patternList: PatternInstance[];
  @Input() uniqueId: string;
  @Input() readOnly: boolean;
  @Input() patternTypes: Dictionary<PatternType>;
  @Input() projectKey: string;
  @Output() deletePattern = new EventEmitter<any>();
  @Output() orderChanges = new EventEmitter<any>();
  @Output() focusLink = new EventEmitter<void>();
  private dropSubscription: EventEmitter<any>;

  constructor(private navigationService: NavigationService) {}

  get isDragDisabled(): boolean {
    return this.readOnly || this.patternList.length <= 1;
  }

  /**
   * A deleted pattern does not have a className.
   * @see PatternReferencePropertyComponent.getPatternsFromIds
   * @param {Pattern} pattern which needs to be checked if it was deleted
   * @returns {boolean} true if the pattern was deleted, false otherwise.
   */
  isPatternDeleted(pattern: Pattern): boolean {
    return !pattern.className;
  }

  onDrop(event: CdkDragDrop<string[]>): void {
    const patternIds: string[] = this.patternList.map((patternInstance: PatternInstance) => patternInstance.patternId);
    moveItemInArray(patternIds, event.previousIndex, event.currentIndex);
    this.orderChanges.emit(patternIds);
  }

  navigateToPattern(patternId: string, $event: Event): void {
    /**
     * stopImmediatePropagation is needed here,
     * as the click also triggers the property selection whats navigation cause this routing cancelled in the guard.
     */
    $event.stopImmediatePropagation();
    this.navigationService.navigateToPattern(this.projectKey, patternId);
  }

  ngOnDestroy(): void {
      if (!_.isNil(this.dropSubscription)) {
          this.dropSubscription.unsubscribe();
      }
  }

  getPatternType(pattern: PatternInstance): string {
    return this.patternTypes[pattern.className]?.name;
  }

  isEllipsisActive(elementRef: HTMLElement): boolean {
    return elementRef?.offsetWidth < elementRef?.scrollWidth;
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SaveChangesDialogComponent } from './save-changes-dialog.component';
import { ModalOpeniningHelper } from '../../notification/modal-openining.helper';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SaveChangesDialogHelper } from './save-changes-dialog.helper';
import { SaveChangesDialogData } from './save-changes-data.model';

@Injectable()
export class SaveChangesDialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * Asynchronously can save action after confirmation or automatically if the flag is set
   * Returns observable that will emit one boolean value whether it was saved or canceled.
   *
   * @param {() => void} saveChanges - The function to invoke when the user decides to save changes in confirmation modal.
   * @param {boolean} withAutoSave - (Optional) indicates the confirmation modal should offer to auto-save changes. Defaults to true.
   */
  confirmSavingChanges(saveChanges: () => void, withAutoSave = true): Observable<boolean> {
    if (withAutoSave && SaveChangesDialogHelper.shouldAutoSave()) {
      saveChanges();
      return of(true);
    }
    return this.openSaveChangesDialog(withAutoSave).afterClosed().pipe(
      map((confirmed: boolean | undefined) => confirmed === true),
      tap((confirmed: boolean) => {
        if (confirmed) {
          saveChanges();
        }
      })
    );
  }

  private openSaveChangesDialog(displayAutoSaveOption: boolean): MatDialogRef<SaveChangesDialogComponent, boolean> {
    const config: MatDialogConfig = ModalOpeniningHelper.getBaseModalConfig();
    const data: SaveChangesDialogData = {
      displayAutoSaveOption: displayAutoSaveOption
    };
    config.data = data;
    return this.dialog.open(SaveChangesDialogComponent, config);
  }
}

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PropertyDataModel } from './property-data.model';
import { PropertyFormValueConverter } from './property-form-value-converter';

export function addPropertyControlsToForm(formGroup: UntypedFormGroup, propertiesModel: PropertyDataModel[]): void {
  propertiesModel.forEach(propertyModel => {
    const formValue = PropertyFormValueConverter.toFormValue(propertyModel.patternProp, propertyModel.propType, propertyModel.patternTypeProp);
    formGroup.addControl(propertyModel.patternProp.propertyKey, new UntypedFormControl(formValue, null));
  });
}

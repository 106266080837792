import { HierarchyTreeNode } from './pattern-hierarchy-tree.model';

export class PatternHierarchyHelper {

  static getParentNode(selectedNode: HierarchyTreeNode, allNodes: Array<HierarchyTreeNode>): HierarchyTreeNode | undefined {
    const currentLevel = selectedNode.level;

    if (currentLevel < 1) {
      return;
    }

    const startIndex = allNodes.indexOf(selectedNode) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode: HierarchyTreeNode = allNodes[i];

      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return undefined;
  }
}

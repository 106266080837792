import { Injectable } from '@angular/core';
import { PatternListData } from '../pattern-list-data.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class BatchSelectionContext {
  private _batchSelection$: BehaviorSubject<PatternListData[]> = new BehaviorSubject([]);

  batchSelection$: Observable<PatternListData[]> = this._batchSelection$.asObservable();

  singlePatternSelectionChange(selectedPattern: PatternListData): void {
    this.batchSelection$.pipe(take(1)).subscribe((currentSelection: PatternListData[]) => {
      const newSelection: PatternListData[] = Object.assign([], currentSelection);
      const selectedPatternIndex = currentSelection.findIndex((pattern: PatternListData) => pattern.pattern.patternId === selectedPattern.pattern.patternId);
      if (selectedPatternIndex !== -1) {
        newSelection.splice(selectedPatternIndex, 1);
      } else {
        newSelection.push(selectedPattern);
      }
      this._batchSelection$.next(newSelection);
    });
  }

  multiplePatternSelectionChange(selectedPatterns: PatternListData[]): void {
    this._batchSelection$.next(selectedPatterns);
  }

  updateBatchSelectionItem(selectedPattern: PatternListData): void {
    this.batchSelection$.pipe(take(1)).subscribe((currentSelection: PatternListData[]) => {
      const updatedSelection: PatternListData[] = Object.assign([], currentSelection);
      const selectedPatternIndex = currentSelection.findIndex((pattern: PatternListData) => pattern.pattern.patternId === selectedPattern.pattern.patternId);
      if (selectedPatternIndex !== -1) {
        updatedSelection[selectedPatternIndex] = selectedPattern;
        this._batchSelection$.next(updatedSelection);
      }
    });
  }
}

import { Dictionary } from '../reducer';
import { NevisAdminAction } from '../actions';
import { VersionControlActionTypes } from './version-control.actions';
import { Revision } from '../../version-control/revision/revision.model';
import { ProjectMeta } from '../../projects/project.model';
import * as fromShared from '../shared/shared.actions';

export class VersionControlState {
  readonly revisions: Dictionary<Revision>;
  readonly projectMeta: ProjectMeta | null;
}

export const initState: VersionControlState = {
  revisions: {},
  projectMeta: null
};


export function reducer(state: VersionControlState, action: NevisAdminAction<any>): VersionControlState {
  switch (action.type) {
    case fromShared.SharedActionTypes.SelectTenantKey:
      return initState;
    case VersionControlActionTypes.LoadRevisionSuccess: {
      const commitId = action.payload.commitId;
      const newItem = {};
      newItem[commitId] = action.payload;
      const newEntries = {...state.revisions, ...newItem};
      return {
        ...state,
        revisions: newEntries
      };
    }
    case VersionControlActionTypes.LoadProjectMetaSuccess: {
      return {
        ...state,
        projectMeta: action.payload
      };
    }
    case VersionControlActionTypes.clearProjectMeta: {
      return {
        ...state,
        projectMeta: initState.projectMeta
      };
    }
    default:
      return state;

  }
}

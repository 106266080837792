import { BehaviorSubject, combineLatest, interval, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, takeWhile, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Inventory, InventorySchemaType } from '../inventory.model';
import { AppState } from '../../model/reducer';
import * as _ from 'lodash';
import { ValidateInventoryContent, ValidateInventoryContentBeforeSave } from '../../model/inventory';
import { InventoryColorHelper } from '../../common/helpers/inventory-color.helper';
import {
  inventoryTimestampView,
  selectedInventoryContentView,
  selectedDiffInventoryContentView,
} from '../../model/views';
import { InventoryContext } from '../inventory.context';
import { InventoryService } from '../inventory.service';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { LocalChangesModalNotificationService } from '../../notification/local-changes-modal-notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class InventoryMainContext {
  inventoryContent$: Observable<string | null>;
  diffInventoryContent$: Observable<string | null>;
  inventoryColor$: Observable<string>;
  inventorySchemaType$: Observable<InventorySchemaType>;
  inventoryBackgroundCssClass$: Observable<string>;
  inventoryKey$: Observable<string | null>;
  _inventoryHasUnsavedChanges$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  inventoryHasUnsavedChanges$: Observable<boolean> = this._inventoryHasUnsavedChanges$.asObservable();
  _editedInventoryContent$: BehaviorSubject<string> = new BehaviorSubject('');
  editedInventoryContent$: Observable<string> = this._editedInventoryContent$.asObservable();
  private POLLING_INTERVAL = 2000;
  private pauseInterval: boolean;

  constructor(private store$: Store<AppState>,
              private inventoryContext: InventoryContext,
              private inventoryService: InventoryService,
              private modalNotificationService: ModalNotificationService,
              private localChangesNotificationService: LocalChangesModalNotificationService,
              private dialogRef: MatDialog) {
    this.inventoryContent$ = this.store$.pipe(select(selectedInventoryContentView));
    this.diffInventoryContent$ = this.store$.pipe(select(selectedDiffInventoryContentView));
    this.inventoryColor$ = this.inventoryContext.currentInventory$.pipe(map((inventory: Inventory | undefined) => _.isNil(inventory) || _.isNil(inventory.color) ? '' : inventory.color));
    this.inventorySchemaType$ = this.inventoryContext.currentInventory$.pipe(map((inventory: Inventory | undefined) => _.isNil(inventory) ? InventorySchemaType.CLASSIC : inventory.schemaType));
    this.inventoryBackgroundCssClass$ = this.inventoryColor$.pipe(map((color: string) => InventoryColorHelper.getInventoryBackgroundClassName(color)));
    this.inventoryKey$ = this.inventoryContext.inventoryKey$;
  }

  saveInventory(inventoryContent: string): void {
    // inventory content will be saved by effect of this action if content is valid or errored content was confirmed by user
    this.store$.dispatch(new ValidateInventoryContentBeforeSave(inventoryContent));
  }

  validateInventory(inventoryContent: string): void {
    this.store$.dispatch(new ValidateInventoryContent(inventoryContent));
  }

  pollInventoryTimestamp(): Observable<boolean> {
    return combineLatest([
      this.inventoryKey$.pipe(filter((inventoryKey) => !_.isNil(inventoryKey))),
      interval(this.POLLING_INTERVAL).pipe(filter(() => !this.pauseInterval)),
      this.inventoryHasUnsavedChanges$.pipe(filter(() => !this.pauseInterval), distinctUntilChanged())
    ]).pipe(
      switchMap(([inventoryKey, , hasUnsavedChanges]: [string, number, boolean]) => {
        return this.inventoryService.getInventoryTimeStamp(inventoryKey).pipe(
          withLatestFrom(this.store$.pipe(select(inventoryTimestampView), filter((timestamp) => !_.isNil(timestamp)))),
          switchMap(([serverTimestamp, latestInventoryMetaState]: [{ timestamp: string }, string]) => {
            const isServerDataNewer = this.isServerDataNewer(latestInventoryMetaState, serverTimestamp.timestamp);
            this.pauseInterval = false;
            if (isServerDataNewer) {
              if (hasUnsavedChanges) {
                {
                  this.pauseInterval = true;
                  // Close all the previous dialogs to prevent repeatedly popping up dialogs above each other
                  this.dialogRef.closeAll();
                  return this.localChangesNotificationService.openConfirmDialog({
                    title: 'Inventory is not up to date',
                    description: ''
                  }, {
                    confirmButtonText: 'Save & overwrite',
                    cancelButtonText: 'Cancel',
                    concurrentChange: true,
                    onSaveCallback: () => this.inventoryContext.selectInventory(inventoryKey)
                  }).afterClosed().pipe(
                    withLatestFrom(this.editedInventoryContent$), switchMap(([confirmed, editedInventoryContent]: [boolean | undefined, string]) => {
                      if (confirmed) {
                        this.saveInventory(editedInventoryContent);
                        return of(false);
                      }
                      return of(true);
                    }),
                    takeWhile((shouldReturn) => shouldReturn));
                }
              } else {
                this.inventoryContext.selectInventory(inventoryKey);
              }
            }
            return of(true);
          }));
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('An error occurred while calling inventory poll timestamp', error);
        this.modalNotificationService.openErrorDialog({title: 'Cannot load inventory', description: `An error occurred while loading inventory`});
        return of(false);
      })
    );
  }

  private isServerDataNewer(innerTimestamp: string, serverTimestamp: string): boolean {
    return new Date(innerTimestamp) < new Date(serverTimestamp);
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { applicationInfoView } from '../../model/views';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { HideableUIElement, PropertyKey } from '../../plugins/pattern-type.model';
import { ApplicationMode } from '../../model/shared/application-info.model';
import { filterNotNil } from '../utils/utils';
import { Pattern, PatternRestrictions } from '../../patterns/pattern.model';
import { hasProjectAdminAccessView } from '../../model/views/permission.views';

@Injectable({
  providedIn: 'root'
})
export class DisplayComponentHelperService {

  private modes?: ApplicationMode;
  private hasSelectedProjectAdminAccessView: boolean;

  constructor(public store$: Store<AppState>) {
    const modes$ = this.store$.select(applicationInfoView).pipe(filterNotNil(), map((ai) => ai?.modes));
    modes$.subscribe(value => {
      this.modes = value;
    });
    this.store$.select(hasProjectAdminAccessView).subscribe(value => {
      this.hasSelectedProjectAdminAccessView = value;
    });
  }

  /**
   * Filter by Type information (patternType, PropertyType).<br/>
   * @param hideable
   * @return True if the element or item (patternType or propertyType) has to be shown, false it not.
   */
  showByPatternType<T extends HideableUIElement>(hideable?: T): boolean {
    if (_.isEmpty(hideable?.hideUI) || _.isEmpty(this.modes)) return true;
    return _.isEmpty(_.intersection(this.modes, hideable?.hideUI));
  }

  /**
   * Filter based on Pattern Data (note: as this is based on pattern data, doesn't depend on application mode.<br/>
   * '*': whole pattern has to be filtered
   * @param pattern
   */
  showPatternByPatternData(pattern: Pattern): boolean {
    if (this.hasSelectedProjectAdminAccessView) return true;
    return !pattern?.displayOptions?.hidden?.includes('*');
  }

  /**
   * Returns if the property should be restricted based on the restrictions.<br/>
   * Doesn't consider the wildcard restriction implicitly, that has to be checked explicitly by passing it as a restriction.
   * @param restrictions
   * @param property
   */
  shouldBeRestrictedByProperty(restrictions?: PatternRestrictions, property?: PropertyKey): boolean {
    if (this.hasSelectedProjectAdminAccessView || _.isNil(restrictions)) return false;
    return _.includes(restrictions, property);
  }

}

import { CommandModel, FileModel, GenerationOutputModel, GenerationTriggerModel, OutputModel, PlanningOutputModel, PlanTriggerModel } from './deploy/deployment-preview/planning-output.model';
import * as _ from 'lodash';

export class OutputHelper {

  /**
   * Returns files which match given ids
   * @param {PlanningOutputModel} output
   * @param {string[]} artifactIds
   * @returns {FileModel[]}
   */
  static getFilesByIds(output: PlanningOutputModel | GenerationOutputModel, artifactIds: string[]): FileModel[] {
    return output.files.filter((file: FileModel) => _.includes(artifactIds, file.fileId));
  }

  /**
   * Returns triggers which will run given command on failure
   * @param {T} output
   * @param commandId
   * @returns {(PlanTriggerModel | GenerationTriggerModel)[]}
   */
  static getFailureTriggersByIds<T extends OutputModel>(output: T, commandId: string): (PlanTriggerModel | GenerationTriggerModel)[] {
    return output.triggers.filter((trigger) => _.includes(trigger.onFailure, commandId));
  }

  /**
   * Returns triggers which will run given command on success
   * @param {T} output
   * @param {string} commandId
   * @returns {(PlanTriggerModel | GenerationTriggerModel)[]}
   */
  static getSuccessTriggersByIds<T extends OutputModel>(output: T, commandId: string): (PlanTriggerModel | GenerationTriggerModel)[] {
    return output.triggers.filter((trigger) => !_.isNil(trigger.onSuccess) && _.includes(trigger.onSuccess, commandId));
  }

  /**
   * Returns commands which match given ids or id
   * @param {PlanningOutputModel | GenerationOutputModel} output
   * @param {string[] | string} commandIds
   * @returns {CommandModel[]}
   */
  static getCommandsByIds(output: PlanningOutputModel | GenerationOutputModel, commandIds: string[] | string): CommandModel[] {
    return output.commands.filter((command: CommandModel) => _.includes(commandIds, command.commandId));
  }
}

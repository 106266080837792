import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DeploymentDialogComponent } from './deployment-dialog/deployment-dialog.component';
import { DeploymentWizardWindowService } from './deployment-wizard-window.service';
import { DeploymentProcessService } from './deployment-process.service';
import { AppState } from '../model/reducer';
import { Store } from '@ngrx/store';
import { StoreDeploymentWizardWindowState } from '../model/deploy';

@Component({
  selector: 'adm4-deployment-wizard-main',
  template: '',
})
export class DeploymentWizardMainComponent implements OnInit, OnDestroy {

  dialogRef: MatDialogRef<DeploymentDialogComponent>;

  constructor(private deploymentWizardWindowService: DeploymentWizardWindowService,
              private deploymentProcessService: DeploymentProcessService,
              private store$: Store<AppState>) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.dialogRef = this.deploymentWizardWindowService.openDeploymentWizard();
      this.store$.dispatch(new StoreDeploymentWizardWindowState(true));
    });
  }

  ngOnDestroy(): void {
    this.dialogRef.afterClosed().subscribe(() => {
      this.store$.dispatch(new StoreDeploymentWizardWindowState(false));
      this.deploymentProcessService.clearDeployProcess();
    });
    this.dialogRef.close();
  }

}

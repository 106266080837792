import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable, of } from 'rxjs';
import { AppState } from '../reducer';
import { select, Store } from '@ngrx/store';
import { catchError, finalize, first, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { inventoryKeyView, inventoryMetaView } from '../views';
import * as _ from 'lodash';
import { Diff } from '../../common/model/publish-changes/diff.model';
import { PatternService } from '../../patterns/pattern.service';
import { Revision } from '../../version-control/revision/revision.model';
import { VersionControlService } from '../../version-control/version-control.service';
import { VariableService } from '../../variables/variable.service';
import { EmptyAction, NevisAdminAction } from '../actions';
import { Branch } from '../../version-control/branch.model';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { PublishPayload } from '../../common/model/publish-changes/publish-payload.model';
import { LoadingService } from '../../modal-dialog/loading-modal/loading.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationMessage } from '../../notification/notification.model';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { EffectWithErrorHandlingMixin, IEffectWithErrorHandlingMixin } from '../effect-with-error-handling.mixin';
import { LoadInventoryContentDiffData, LoadInventoryContentDiffDataSuccess, PublishInventory, PublishInventoryActionTypes, RequestPublishInventory } from './publish-inventory.actions';
import { Inventory, InventoryMeta } from '../../inventory/inventory.model';
import { InventoryService } from '../../inventory/inventory.service';
import { FileService } from '../../common/services/file/file.service';
import { InventoryContentDiffViewHelper } from '../../inventory/publish-inventory/inventory-content-diff-view/inventory-content-diff-view.helper';
import { TextDiffData } from '../../common/model/publish-changes/text-diff-data.model';
import { LocalStatus } from '../../version-control/meta-info.model';
import { IPublishEffectMixin, PublishEffectMixin } from '../publish-effect.mixin';
import { GetUpdatesOfInventory } from '../inventory/inventory.actions';

/**
 * Effects are used to collect all required data for the diff views of the inventory
 * Reason to use effects is that it makes it simpler to divide complex collecting of data from different data sources for different parts of the diff views
 * One action calls to load diff data, then that it being separated into several actions which then have its effects which can also divide loading into separate parts and then combine them
 */
@Injectable()
@Mixin([EffectWithErrorHandlingMixin, PublishEffectMixin])
export class PublishInventoryEffects implements IEffectWithErrorHandlingMixin, IPublishEffectMixin {
   loadDiffData: Observable<LoadInventoryContentDiffData> = createEffect(() => this.createLoadDiffDataEffect());
   loadInventoryContentDiffData: Observable<LoadInventoryContentDiffDataSuccess | EmptyAction> = createEffect(() => this.createLoadInventoryContentDiffDataEffect());
   requestPublishInventory: Observable<PublishInventory | GetUpdatesOfInventory | EmptyAction> = createEffect(() => this.createRequestPublishInventoryEffect());
   publishInventory: Observable<EmptyAction> = createEffect(() => this.createPublishInventoryEffect());
   clearRevisionCache: Observable<any> = createEffect(() => this.createClearRevisionCacheEffect(), {dispatch: false});

  /**
   * Implemented by EffectWithErrorHandlingMixin
   */
  handleErrorAction: <T extends NevisAdminAction<any> = EmptyAction>(error: any, displayMessage: NotificationMessage, returnedAction?: T) => T;

  /**
   * Implemented by PublishEffectMixin
   */
  getDiffWithRevision$: <T extends object, D extends Diff<T> | null = Diff<T>>(inventoryKey: string, commitId: (string | undefined), mapper: (revision?: Revision) => D) => Observable<D>;
  /**
   * Implemented by PublishEffectMixin
   */
  handlePublishError: (error: HttpErrorResponse) => void;

  /**
   * Used by PublishEffectMixin for feedback to user
   */
  readonly publishItemLabel = 'inventory';
  /**
   * To prevent loading same revision information because different meta entries point to same commit we cache requests in this map, which stores observables with cached values
   * It should be alive only while inventory publish is open and cleared when it's closed
   * cache.ts is not used here, because we're not caching plain values, but only observables with publishReplay, which I think putting into store would be an overkill
   * although moving there would not a bit deal, if it turns out to be better at some point
   */
  revisionCache: Map<string, Observable<Revision>> = new Map();

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private versionControlService: VersionControlService,
    private patternService: PatternService,
    private variableService: VariableService,
    private inventoryService: InventoryService,
    private fileService: FileService,
    public modalNotificationService: ModalNotificationService,
    private loadingService: LoadingService
  ) {}

  private createLoadDiffDataEffect(): Observable<LoadInventoryContentDiffData> {
    return this.actions$.pipe(
      ofType(PublishInventoryActionTypes.LoadDiffData),
      switchMap(() => {
        return forkJoin([
          this.store$.pipe(select(inventoryMetaView), first((inventoryMeta: InventoryMeta | null) => !_.isNil(inventoryMeta))),
          this.store$.pipe(select(inventoryKeyView), first((inventoryKey: string | null) => !_.isNil(inventoryKey)))
        ]).pipe(
          mergeMap(([inventoryMeta, inventoryKey]: [InventoryMeta, string]) => {
            return [
              new LoadInventoryContentDiffData({inventoryMeta, inventoryKey})
            ];
          }),
          takeUntil(this.actions$.pipe(ofType(PublishInventoryActionTypes.ClearPublishInventoryData)))
        );
      })
    );
  }

  private createLoadInventoryContentDiffDataEffect(): Observable<LoadInventoryContentDiffDataSuccess | EmptyAction> {
    return this.actions$.pipe(
      ofType(PublishInventoryActionTypes.LoadInventoryContentDiffData),
      switchMap((action: LoadInventoryContentDiffData) => {
        return forkJoin([
          action.payload.inventoryMeta.content.localStatus === LocalStatus.Added ? of('') : this.fileService.loadYamlFileContent(`/inventories/${action.payload.inventoryKey}/head`),
          this.fileService.loadYamlFileContent(`/inventories/${action.payload.inventoryKey}`)
        ]).pipe(
          mergeMap(([remoteInventoryContent, localInventoryContent]: [string, string]) => {
            return this.getDiffWithRevision$(action.payload.inventoryKey, action.payload.inventoryMeta.content.commitId,
              (revision?: Revision) => InventoryContentDiffViewHelper.createInventoryContentDiff(remoteInventoryContent, localInventoryContent, action.payload.inventoryMeta.content, revision)
            ).pipe(
              map((inventoryContentDiffData: Diff<TextDiffData>) => new LoadInventoryContentDiffDataSuccess(inventoryContentDiffData)),
              catchError((error: HttpErrorResponse) => of(this.handleErrorAction<EmptyAction>(error, {title: 'Could not create diff view of inventory content', description: 'Something went wrong while loading data for diff view of inventory content.'})))
            );
          })
        );
      })
    );
  }

  private createRequestPublishInventoryEffect(): Observable<PublishInventory | GetUpdatesOfInventory | EmptyAction> {
    return this.actions$.pipe(
      ofType(PublishInventoryActionTypes.RequestPublishInventory),
      switchMap((action: RequestPublishInventory) => {
        const inventoryToPublish: Inventory = action.payload.inventory;
        if (_.isNil(inventoryToPublish.repository) || _.isNil(inventoryToPublish.branch)) {
          return of(new EmptyAction());
        }
        return this.versionControlService.getBranchByRepoAndName(inventoryToPublish.repository, inventoryToPublish.branch).pipe(
          mergeMap((branch: Branch | undefined) => {
            const hasRemoteChange = !_.isNil(branch) && branch.commitId !== action.payload.inventoryMeta.localHead;
            if (hasRemoteChange) {
              return this.modalNotificationService.openConfirmDialog({
                title: 'Inventory not up to date',
                description: 'Please update the inventory before publishing changes. Note that there are local changes that will be lost if the inventory was modified remotely. Do you want to continue?'
              }, {
                confirmButtonText: 'Cancel',
                cancelButtonText: 'Update inventory'
              }).afterClosed().pipe(
                map((confirmed?: boolean) => {
                  return confirmed === false ? new GetUpdatesOfInventory(action.payload.inventory.inventoryKey) : new EmptyAction();
                })
              );
            } else {
              return of(new PublishInventory(action.payload));
            }
          })
        );
      })
    );
  }

  private createPublishInventoryEffect(): Observable<EmptyAction> {
    return this.actions$.pipe(
      ofType(PublishInventoryActionTypes.PublishInventory),
      switchMap((action: PublishInventory) => {
        if (_.isNil(action.payload.inventoryMeta.lastModification)) {
          this.modalNotificationService.openErrorDialog({description: 'There are no changes to publish. Something went wrong.'});
          return of(new EmptyAction());
        }
        const publishPayload: PublishPayload = {
          message: action.payload.commitMessage,
          lastModification: action.payload.inventoryMeta.lastModification,
          localHead: action.payload.inventoryMeta.localHead
        };
        this.loadingService.showLoading({title: 'We are publishing your changes to the repository', description: `The changes are published to the inventory ${action.payload.inventory.inventoryKey}. This may take a few seconds`});
        return this.inventoryService.publishInventory(action.payload.inventory.inventoryKey, publishPayload).pipe(
          map(() => new EmptyAction()),
          tap(() => this.modalNotificationService.openSuccessDialog({title: 'Success', description: 'Publish was successful!'})),
          finalize(() => this.loadingService.hideLoading()),
          catchError((error: HttpErrorResponse) => {
            this.handlePublishError(error);
            console.error(error);
            return of(new EmptyAction());
          })
        );
      })
    );
  }

  private createClearRevisionCacheEffect(): Observable<any> {
    return this.actions$.pipe(
      ofType(PublishInventoryActionTypes.ClearPublishInventoryData),
      tap(() => this.revisionCache.clear())
    );
  }

  getRevisionOfCommit$(inventoryKey: string, commitId: string): Observable<Revision> {
    return this.versionControlService.getRevisionOfInventoryCommit(inventoryKey, commitId);
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../model/reducer';
import { Inventory } from '../inventory.model';
import { ImportInventoryContext } from './import-inventory.context';
import { ImportInventory } from '../../model/inventory';
import { closeModalOnEscape } from '../../modal-dialog/modal-dialog.helper';
import { Subject } from 'rxjs';

@Component({
  selector: 'adm4-import-inventory-dialog',
  template: `
    <adm4-modal-dialog-title header='Import Inventory from Git' [showClose]='true' (closeClicked)="onCloseClicked()">
      <adm4-import-inventory [inventories]='importInventoryContext.inventories$ | async'
                                  [predefinedInventoryData]='predefinedInventoryData'
                                  [selectedTenantKey]="importInventoryContext.selectedTenantKey$ | async"
                                  [displayTenant]='importInventoryContext.displayTenant$ | async'
                                  (cancelClicked)='onCancelClicked()'
                                  (importInventory)='onImportInventory($event)'
      >
      </adm4-import-inventory>
    </adm4-modal-dialog-title>
  `,
  providers: [
    ImportInventoryContext
  ]
})
export class ImportInventoryDialogComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<boolean> = new Subject();

  constructor(public dialogRef: MatDialogRef<ImportInventoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public predefinedInventoryData: Inventory | null,
              private store$: Store<AppState>,
              public importInventoryContext: ImportInventoryContext) {
  }

  ngOnInit(): void {
    closeModalOnEscape(this.dialogRef, this.destroyed$);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onCancelClicked(): void {
    this.dialogRef.close();
  }

  onImportInventory(inventory: Inventory): void {
    this.store$.dispatch(new ImportInventory(inventory));
    this.dialogRef.close();
  }

  onCloseClicked() {
    this.dialogRef.close();
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Inventory } from '../inventory.model';
import { CreateInventoryPayload } from './create-inventory-payload';
import { CreateInventoryHelper } from './create-inventory.helper';
import { FormHelper } from '../../common/helpers/form.helper';
import { InventoryValidators } from '../inventory.validators';
import { INCLUDE_SAMPLE_FORM_CONTROL_NAME, INVENTORY_KEY_FORM_CONTROL_NAME, IS_KUBERNETES_FORM_CONTROL_NAME, IS_VERSIONED_FORM_CONTROL_NAME, K8S_CONTROL_FORM_CONTROL_NAME, PROJECT_KEY_FORM_CONTROL_NAME, PUBLISH_REQUIRED_FORM_CONTROL_NAME, VERSION_CONTROL_FORM_CONTROL_NAME } from '../inventory-creation.model';
import * as _ from 'lodash';
import { IPredefinedInventoryFormDataMixin, PredefinedInventoryFormDataMixin } from '../predefined-inventory-form-data.mixin';
import { Mixin } from '../../common/decorators/mixin.decorator';
import { VersionControlData } from '../../common/model/version-control-data.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import { Project } from '../../projects/project.model';
import { InsertProjectVariablesDialogContext } from '../insert-project-variables-dialog/insert-project-variables-dialog.context';
import { first, skip } from 'rxjs/operators';
import { ProjectHelper } from '../../projects/project.helper';
import { localStorageProjectKey } from '../../common/constants/local-storage-keys.constants';
import { TenantHelper } from '../../common/helpers/tenant.helper';

@Component({
  selector: 'adm4-create-inventory',
  templateUrl: './create-inventory.component.html',
  styleUrls: [
    '../../common/styles/component-specific/create-form.scss',
    '../../common/styles/component-specific/modal-window.scss',
    'create-inventory.component.scss',
  ],
  providers: [InsertProjectVariablesDialogContext]
})
@Mixin([PredefinedInventoryFormDataMixin])
export class CreateInventoryComponent implements OnInit, IPredefinedInventoryFormDataMixin {
  @Input() predefinedInventoryData: Inventory | null;
  @Input() inventories: Inventory[];
  @Input() selectedTenantKey: string;
  @Input() displayTenant: boolean;
  @Output() addInventory: EventEmitter<CreateInventoryPayload> = new EventEmitter();
  @Output() closeClicked: EventEmitter<void> = new EventEmitter();

  public createInventoryForm: UntypedFormGroup;

  readonly ERROR_INVENTORY_KEY_REQUIRED = 'The inventory key is required';
  readonly BUTTON_CREATE = 'Create';

  readonly INVENTORY_KEY_FORM_CONTROL_NAME = INVENTORY_KEY_FORM_CONTROL_NAME;
  readonly INCLUDE_SAMPLE_FORM_CONTROL_NAME = INCLUDE_SAMPLE_FORM_CONTROL_NAME;
  readonly PUBLISH_REQUIRED_FORM_CONTROL_NAME = PUBLISH_REQUIRED_FORM_CONTROL_NAME;
  readonly VERSION_CONTROL_FORM_CONTROL_NAME = VERSION_CONTROL_FORM_CONTROL_NAME;
  readonly IS_VERSIONED_FORM_CONTROL_NAME = IS_VERSIONED_FORM_CONTROL_NAME;
  readonly IS_KUBERNETES_FORM_CONTROL_NAME = IS_KUBERNETES_FORM_CONTROL_NAME;
  readonly K8S_CONTROL_FORM_CONTROL_NAME = K8S_CONTROL_FORM_CONTROL_NAME;
  readonly PROJECT_KEY_FORM_CONTROL_NAME = PROJECT_KEY_FORM_CONTROL_NAME;

  projects$: Observable<Project[]>;
  filteredProjectList: Project[];

  _searchableDropdownInputFocusTrigger$: Subject<void> = new Subject<void>();
  searchableDropdownInputFocusTrigger$: Observable<void> = this._searchableDropdownInputFocusTrigger$.asObservable();

  // implemented by mixin PredefinedInventoryFormDataMixin
  initialInventoryKeyFormValue: () => string;
  predefinedVersionControlData: () => VersionControlData | null;
  initialPublishRequiredFormValue: () => boolean;

  constructor(private fb: UntypedFormBuilder,
              private insertProjectVariablesDialogContext: InsertProjectVariablesDialogContext) {
  }

  ngOnInit(): void {
    this.projects$ = this.insertProjectVariablesDialogContext.projects$;
    this.insertProjectVariablesDialogContext.loadProjects(this.selectedTenantKey);
    this.createInventoryForm = this.createFormGroup();
    this.preselectProjectKey();
  }

  preselectProjectKey(): void {
    forkJoin([
      this.insertProjectVariablesDialogContext.currentProjectKey$.pipe(first()),
      this.insertProjectVariablesDialogContext.projects$.pipe(skip(1), first())
    ]).subscribe(([projectKey, projects]: [string | null, Project[]]) => {
      const preselectedKey = ProjectHelper.getFromStoreOrFirstAvailable(projectKey, _.keyBy(projects, 'projectKey'), localStorageProjectKey);
      this.createInventoryForm.controls[this.PROJECT_KEY_FORM_CONTROL_NAME].setValue(preselectedKey);
    });
  }

  shouldShowInventoryKeyErrorMessage(errorKey: string): boolean {
    return FormHelper.shouldShowFormControlErrorForKey(this.createInventoryForm.controls[this.INVENTORY_KEY_FORM_CONTROL_NAME], errorKey);
  }

  createFormGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    group.addControl(this.IS_VERSIONED_FORM_CONTROL_NAME, this.fb.control(this.isVersionControlActiveInitially()));
    group.addControl(this.INVENTORY_KEY_FORM_CONTROL_NAME, this.fb.control(this.initialInventoryKeyFormValue(), [
      Validators.pattern(/^[a-zA-Z0-9_-]+$/), InventoryValidators.checkExistingInventoryKey(this.inventories, this.selectedTenantKey)
    ]));
    group.addControl(this.INCLUDE_SAMPLE_FORM_CONTROL_NAME, this.fb.control(false, null));
    group.addControl(this.PROJECT_KEY_FORM_CONTROL_NAME, this.fb.control(null, null));
    group.addControl(this.PUBLISH_REQUIRED_FORM_CONTROL_NAME, this.fb.control(this.initialPublishRequiredFormValue(), null));

    group.addControl(this.IS_KUBERNETES_FORM_CONTROL_NAME, this.fb.control(false));

    return group;
  }

  isVersionControlActiveInitially(): boolean {
    return !_.isNil(this.predefinedInventoryData) && !_.isNil(this.predefinedInventoryData.repository);
  }

  addVersionControlForm(versionControlForm: UntypedFormGroup): void {
    this.createInventoryForm.setControl(this.VERSION_CONTROL_FORM_CONTROL_NAME, versionControlForm);
  }

  addK8sControlForm(k8sForm: UntypedFormGroup) {
    this.createInventoryForm.setControl(this.K8S_CONTROL_FORM_CONTROL_NAME, k8sForm);
  }

  createInventory(): void {
    if (!this.canSave) {
      return;
    }
    this.addInventory.emit(CreateInventoryHelper.convertFormValueToCreateInventoryPayload(this.createInventoryForm.value, this.selectedTenantKey));
  }

  cancel(): void {
    this.closeClicked.emit();
  }

  get isSampleChecked(): boolean {
    return this.createInventoryForm.value[this.INCLUDE_SAMPLE_FORM_CONTROL_NAME] || false;
  }

  shouldDisableProjectDropdown(projects: Project[]): boolean {
    return !this.isSampleChecked || this.projectsAreEmpty(projects);
  }

  projectsAreEmpty(projects: Project[]): boolean {
    return _.isNil(projects) || _.isEmpty(projects);
  }

  get isVersioned(): boolean {
    return this.createInventoryForm.value[this.IS_VERSIONED_FORM_CONTROL_NAME] || false;
  }

  get isKubernetes(): boolean {
    return this.createInventoryForm.value[this.IS_KUBERNETES_FORM_CONTROL_NAME] || false;
  }

  get inventoryInvalidError() {
    return `The inventory entered contains some invalid characters. Must be of format [A-Z0-9_-]`;
  }

  get inventoryKeyAlreadyExistsError() {
    return `This key is already in use for tenant ${this.selectedTenantKey}`;
  }

  get canSave(): boolean {
    const kubernetesForm = this.createInventoryForm.controls[this.K8S_CONTROL_FORM_CONTROL_NAME] as UntypedFormGroup;
    const kubernetesFormValid: boolean = _.values(kubernetesForm.controls).every((instance: UntypedFormControl | UntypedFormGroup) => {
      if (instance instanceof UntypedFormGroup) {
        return _.values(instance.controls).every((control) => control.valid);
      }
      return instance.valid;
    });
    const versionControlForm = this.createInventoryForm.controls[this.VERSION_CONTROL_FORM_CONTROL_NAME] as UntypedFormGroup;
    const versionControlValid: boolean = _.values(versionControlForm.controls).every(control => control.valid);
    // when submitting form we only care about version control validation if was enabled, same for kubernetes.
    const baseFormValid = _.keys(this.createInventoryForm.controls)
      .filter(formControlName => !_.includes([
        this.VERSION_CONTROL_FORM_CONTROL_NAME,
        this.IS_VERSIONED_FORM_CONTROL_NAME,
        this.K8S_CONTROL_FORM_CONTROL_NAME,
        this.IS_KUBERNETES_FORM_CONTROL_NAME,
        this.PUBLISH_REQUIRED_FORM_CONTROL_NAME,
        this.PROJECT_KEY_FORM_CONTROL_NAME,
        this.INCLUDE_SAMPLE_FORM_CONTROL_NAME], formControlName))
      .every(formControlName => this.createInventoryForm.controls[formControlName].valid);
    return baseFormValid && (versionControlValid || !this.isVersioned) && (kubernetesFormValid || !this.isKubernetes);
  }

  searchableProjectFormatFn = (project: Project): string => {
    return TenantHelper.cropTenantFromKey(project.projectKey);
  };

  updateSearchResult(filteredList: Project[]): void {
    this.filteredProjectList = filteredList;
  }

  focusDropdownInput(): void {
    this._searchableDropdownInputFocusTrigger$.next();
  }

  isProjectFilteredOut(project: Project): boolean {
    return _.includes(this.filteredProjectList, project);
  }
}

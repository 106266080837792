import { PatternProperty } from '../patterns/pattern-instance.model';
import { PropertyType } from '../plugins/property-type.model';
import { PropertyTypeEnum } from '../common/constants/app.constants';
import { VariableModel } from '../variables/variable.model';
import { Maybe } from '../common/utils/utils';
import { isVariableRef } from '../common/constants/reference-prefix.constants';
import { Property } from '../plugins/pattern-type.model';
import { ParameterHelper } from './parameter.helper';

export class PropertyFormValueConverter {
  private static TextareaConverter = class {
    static toPropertyValue(formValue: string): string[] | string {
      if (formValue && formValue.includes('\n')) {
        return formValue.split('\n');
      } else {
        return formValue;
      }
    }

    static toFormValue(propertyValue: string[] | string): string {
      if (Array.isArray(propertyValue)) {
        return propertyValue ? propertyValue.join('\n') : '';
      } else {
        return propertyValue;
      }
    }
  };

  /**
   * Fallback property is designed to work with plain JSON since property type is unknown and we cannot know what format we should present in
   * therefore form value is always stringified JSON and to convert it back to property value is parsed
   * When property value is deleted (set to empty string) undefined is returned
   *
   * NOTE: it can happen that from form cannot be parsed into property because it's not a valid JSON, but we simply let the error bubble up and don't handle it here by design
   * because it may need different handling in place of usage, so be conscious when using it and make use to catch errors
   */
  private static FallbackConverter = class {
    static toPropertyValue(formValue: string): any {
      if (formValue.length === 0) {
        return undefined;
      }
      return JSON.parse(formValue);
    }

    static toFormValue(propertyValue: any): string {
      return JSON.stringify(propertyValue) || '';
    }
  };

  private static SelectionPropertyConverter = class {
    static toPropertyValue(formValue: any, _propertyType: PropertyType | undefined): any {
      if (Array.isArray(formValue) && formValue.length === 1 && isVariableRef(formValue[0])) {
        return formValue[0];
      }
      return formValue;
    }

    static toFormValue(
      property: PatternProperty | VariableModel, _propertyType: PropertyType | undefined, patternPropType: Property | undefined,
    ): any {
      const value = property.value;
      if (ParameterHelper.isMultiAllowed(patternPropType) && isVariableRef(value)) {
        // a variableRef string needs to be wrapped into an array so that the value widget will not throw an error
        return [value];
      }
      return value;
    }
  };


  static toPropertyValue(propertyType: PropertyType | undefined, formValue: any): any {
    if (!propertyType) {
      return this.FallbackConverter.toPropertyValue(formValue);
    }

    switch (propertyType.uiComponent) {
      case PropertyTypeEnum.SimpleTextPropertyComponent:
        return this.TextareaConverter.toPropertyValue(formValue);
      case PropertyTypeEnum.SelectionPropertyComponent:
        return this.SelectionPropertyConverter.toPropertyValue(formValue, propertyType);
      default:
        return formValue;
    }
  }

  static toFormValue(
    property: PatternProperty | VariableModel, propertyType: PropertyType | undefined, patternPropertyType: Property | undefined,
  ): any {
    if (!propertyType) {
      return this.FallbackConverter.toFormValue(property.value);
    }

    switch (propertyType.uiComponent) {
      case PropertyTypeEnum.SimpleTextPropertyComponent:
        return this.TextareaConverter.toFormValue(property.value);
      case PropertyTypeEnum.SelectionPropertyComponent:
        return this.SelectionPropertyConverter.toFormValue(property, propertyType, patternPropertyType);
      default:
        return property.value;
    }
  }

  static emptyFormValue(propertyType: Maybe<string>): any {
    switch (propertyType) {
      case PropertyTypeEnum.AttachmentPropertyComponent:
        return undefined;
      case PropertyTypeEnum.KeyValuePropertyComponent:
        return [];
      default:
        return '';
    }
  }
}

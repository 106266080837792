import { DeploymentHostDiffData, PatternLabelDiffData, PatternLinkDiffData, PatternNameDiffData, PatternNotesDiffData, PropertyDiffData, SimplePatternDiffData } from './pattern-diff-data.model';
import { PatternInstanceMeta, PatternProperty } from '../../../patterns/pattern-instance.model';
import { Diff } from '../../../common/model/publish-changes/diff.model';
import { LocalStatus } from '../../../version-control/meta-info.model';
import { Revision } from '../../../version-control/revision/revision.model';
import { Dictionary } from '../../../model/reducer';
import { PatternType, Property } from '../../../plugins/pattern-type.model';
import { DEPLOYMENT_HOST_FORM_CONTROL_NAME, PATTERN_NOTES_FORM_CONTROL_NAME, PATTERN_TYPE_DIFF_LABEL } from './pattern-diff-view.constants';
import { IssueModel, SourceType } from '../../../common/model/issue.model';
import { UntypedFormGroup } from '@angular/forms';
import { getIssuesById } from '../../project-issues/project-issues.helper';
import { deploymentHostTargetName } from '../../../issues/issues.constants';
import { PatternFieldMetaInfo, PropertyMetaInfo } from '../../../version-control/pattern-field-meta-info.model';
import { PropertyType } from '../../../plugins/property-type.model';
import * as _ from 'lodash';
import { PropertyFormValueConverter } from '../../../property-widgets/property-form-value-converter';
import { multiValueConverter } from '../../../property-widgets/dynamic-property-creation.service';
import { DEPLOYMENT_HOST_LABEL } from '../../../property-widgets/deployment-host-widget/deployment-host-widget.constants';
import { FormHelper } from '../../../common/helpers/form.helper';
import { PatternLink } from '../../../patterns/pattern.model';
import { PATTERN_NOTES_LABEL } from '../../../property-widgets/pattern-note-widget/pattern-notes-widget.constants';

export class PatternDiffViewHelper {

  static createPatternNameDiffData(patternMeta: PatternInstanceMeta, revision?: Revision): Diff<PatternNameDiffData> {
    return {
      local: {
        patternName: patternMeta.name,
        changeInfo: patternMeta._meta.name,
        fallbackLabel: patternMeta.name
      },
      remote: {
        patternName: this.resolveRemotePatternName(patternMeta),
        commitInfo: revision
      }
    };
  }

  static createPatternLabelDiffData(patternMeta: PatternInstanceMeta, revision?: Revision): Diff<PatternLabelDiffData> | null {
    const labelMeta: PatternFieldMetaInfo<string> | undefined = patternMeta._meta.label;
    if (!labelMeta) {
      return null;
    }
    return {
      local: {
        label: patternMeta.label,
        changeInfo: labelMeta,
        fallbackLabel: patternMeta.label || ''
      },
      remote: {
        label: this.resolveRemotePatternLabel(patternMeta),
        commitInfo: revision
      }
    };
  }

  static createPatternLinkDiffData(patternMeta: PatternInstanceMeta, revision?: Revision): Diff<PatternLinkDiffData> {
    return {
      local: {
        link: patternMeta.link,
        changeInfo: patternMeta._meta.link,
        fallbackLabel: patternMeta.name
      },
      remote: {
        link: this.resolvePatternLink(patternMeta),
        commitInfo: revision
      }
    };
  }

  private static resolveRemotePatternName(patternMeta: PatternInstanceMeta): string {
    return patternMeta._meta.name.localStatus === LocalStatus.Unmodified ? patternMeta.name : patternMeta._meta.name.headValue || '';
  }

  private static resolveRemotePatternLabel(patternMeta: PatternInstanceMeta): string | undefined {
    if (_.isNil(patternMeta._meta.label) || patternMeta._meta.label.localStatus === LocalStatus.Unmodified) {
      return patternMeta.label;
    } else {
      return patternMeta._meta.label.headValue;
    }
  }

  static resolvePatternLink(patternMeta: PatternInstanceMeta): PatternLink | undefined {
    if (_.isNil(patternMeta._meta.link) || patternMeta._meta.link.localStatus === LocalStatus.Unmodified) {
      return patternMeta.link;
    } else {
      return patternMeta._meta.link.headValue;
    }
  }

  static createPatternTypeDiff(patternMeta: PatternInstanceMeta, patternTypes: Dictionary<PatternType>, revision?: Revision): Diff<SimplePatternDiffData> {
    const localPatternType: PatternType | undefined = patternTypes[patternMeta.className];
    const remotePatternType: PatternType | undefined = _.isNil(patternMeta._meta.className.headValue) ? undefined : patternTypes[patternMeta._meta.className.headValue];
    const localPatternTypeName: string = _.isNil(localPatternType) || _.isNil(remotePatternType) ? patternMeta.className : localPatternType.name;
    const remotePatternTypeName: string = _.isNil(localPatternType) || _.isNil(remotePatternType) ? patternMeta._meta.className.headValue || '' : remotePatternType.name;
    return {
      local: {
        label: PATTERN_TYPE_DIFF_LABEL,
        value: localPatternTypeName,
        changeInfo: patternMeta._meta.className,
        fallbackLabel: PATTERN_TYPE_DIFF_LABEL
      },
      remote: {
        label: PATTERN_TYPE_DIFF_LABEL,
        value: remotePatternTypeName,
        commitInfo: revision
      }
    };
  }

  static createDeploymentHostDiff(patternMeta: PatternInstanceMeta, patternTypes: Dictionary<PatternType>, issues: IssueModel[], revision?: Revision): Diff<DeploymentHostDiffData> | null {
    const deploymentHostsMeta: PatternFieldMetaInfo<string> | undefined = patternMeta._meta.deploymentHosts;
    const patternType: PatternType | undefined = patternTypes[patternMeta.className];
    const hasDeploymentHost = !_.isNil(deploymentHostsMeta) || (!_.isNil(patternType) && patternType.deployablePattern);

    if (!hasDeploymentHost) {
      return null;
    }

    const ensuredDeploymentHostMeta: PatternFieldMetaInfo<string> | undefined = _.isNil(deploymentHostsMeta) ? {headValue: undefined, localStatus: LocalStatus.Unmodified} : deploymentHostsMeta;

    const localForm = FormHelper.createFormFromFormValue({[DEPLOYMENT_HOST_FORM_CONTROL_NAME]: patternMeta.deploymentHosts});
    const remoteForm = FormHelper.createFormFromFormValue({[DEPLOYMENT_HOST_FORM_CONTROL_NAME]: ensuredDeploymentHostMeta.headValue});

    const patternIssues: IssueModel[] = getIssuesById(issues, SourceType.PATTERN, patternMeta.patternId);
    const deploymentHostIssues = getIssuesById(patternIssues, SourceType.FIELD, deploymentHostTargetName);

    return {
      local: {
        form: localForm,
        formControlName: DEPLOYMENT_HOST_FORM_CONTROL_NAME,
        issues: deploymentHostIssues,
        changeInfo: ensuredDeploymentHostMeta,
        fallbackLabel: DEPLOYMENT_HOST_LABEL
      },
      remote: {
        form: remoteForm,
        formControlName: DEPLOYMENT_HOST_FORM_CONTROL_NAME,
        issues: [],
        commitInfo: revision
      }
    };
  }

  static createPatternNotesDiff(patternMeta: PatternInstanceMeta, revision?: Revision): Diff<PatternNotesDiffData> | null {
    const patternNotesMeta: PatternFieldMetaInfo<string> | undefined = patternMeta._meta.notes;
    const ensuredPatternNotes: PatternFieldMetaInfo<string> | undefined = _.isNil(patternNotesMeta) ? {headValue: undefined, localStatus: LocalStatus.Unmodified} : patternNotesMeta;

    const localForm = FormHelper.createFormFromFormValue({[PATTERN_NOTES_FORM_CONTROL_NAME]: patternMeta.notes});
    const remoteForm = FormHelper.createFormFromFormValue({[PATTERN_NOTES_FORM_CONTROL_NAME]: ensuredPatternNotes.headValue});

    return {
      local: {
        form: localForm,
        formControlName: PATTERN_NOTES_FORM_CONTROL_NAME,
        changeInfo: ensuredPatternNotes,
        fallbackLabel: PATTERN_NOTES_LABEL
      },
      remote: {
        form: remoteForm,
        formControlName: PATTERN_NOTES_FORM_CONTROL_NAME,
        commitInfo: revision
      }
    };
  }

  static createPropertyDiff(propertyMetaInfo: PropertyMetaInfo, patternMeta: PatternInstanceMeta, patternTypes: Dictionary<PatternType>, propertyTypes: Dictionary<PropertyType>, issues: IssueModel[], revision?: Revision): Diff<PropertyDiffData> {
    const currentLocalProperty: PatternProperty | undefined = patternMeta.properties.find(property => property.propertyKey === propertyMetaInfo.propertyKey);
    const localPropertyValue = _.isNil(currentLocalProperty) ? undefined : currentLocalProperty.value;
    const remotePropertyValue = propertyMetaInfo.localStatus === LocalStatus.Unmodified ? localPropertyValue : propertyMetaInfo.headValue;

    const patternType: PatternType | undefined = patternTypes[patternMeta.className];
    const patternTypeProperty: Property | undefined = _.isNil(patternType) || _.isNil(patternType.properties) ? undefined : patternType.properties.find(property => property.propertyKey === propertyMetaInfo.propertyKey);
    const propertyType: PropertyType | undefined = _.isNil(patternTypeProperty) ? undefined : propertyTypes[patternTypeProperty.className];
    const propertyName: string = _.isNil(patternTypeProperty) ? propertyMetaInfo.propertyKey : patternTypeProperty.name;

    const patternIssues: IssueModel[] = getIssuesById(issues, SourceType.PATTERN, patternMeta.patternId);
    const propertyIssues: IssueModel[] = getIssuesById(patternIssues, SourceType.PROPERTY, propertyMetaInfo.propertyKey);

    const ensuredLocalProperty = this.getEnsuredPatternProperty(propertyMetaInfo, propertyType, localPropertyValue);
    const remotePatternProperty = this.getEnsuredPatternProperty(propertyMetaInfo, propertyType, remotePropertyValue);

    const localPropertyFormValue = PropertyFormValueConverter.toFormValue(ensuredLocalProperty, propertyType, patternTypeProperty);
    const remotePropertyFormValue = PropertyFormValueConverter.toFormValue(remotePatternProperty, propertyType, patternTypeProperty);

    const localForm: UntypedFormGroup = FormHelper.createFormFromFormValue({[propertyMetaInfo.propertyKey]: localPropertyFormValue});
    const remoteForm: UntypedFormGroup = FormHelper.createFormFromFormValue({[propertyMetaInfo.propertyKey]: remotePropertyFormValue});
    return {
      local: {
        form: localForm,
        patternId: patternMeta.patternId,
        patternProperty: ensuredLocalProperty,
        patternTypeProperty: patternTypeProperty,
        propertyType: propertyType,
        issues: propertyIssues,
        changeInfo: propertyMetaInfo,
        fallbackLabel: propertyName
      },
      remote: {
        form: remoteForm,
        patternId: patternMeta.patternId,
        patternProperty: remotePatternProperty,
        patternTypeProperty: patternTypeProperty,
        propertyType: propertyType,
        issues: [],
        commitInfo: revision
      }
    };
  }

  private static getEnsuredPatternProperty(propertyMetaInfo: PropertyMetaInfo, propertyType: PropertyType | undefined, propertyValue?: any): PatternProperty {
    const normalizedPropertyValue = _.isNil(propertyType) ? propertyValue : multiValueConverter(propertyType, propertyValue);
    return {propertyKey: propertyMetaInfo.propertyKey, value: normalizedPropertyValue};
  }

  static getUnsetMetaProperties(patternMeta: PatternInstanceMeta, patternTypes: Dictionary<PatternType>): PropertyMetaInfo[] {
    const includedPropertyKeys: string[] = patternMeta._meta.properties.map((propertyMetaInfo) => propertyMetaInfo.propertyKey);
    const patternType: PatternType | undefined = patternTypes[patternMeta.className];
    const missingProperties = _.isNil(patternType) || _.isNil(patternType.properties) ? [] : patternType.properties.filter(property => !_.includes(includedPropertyKeys, property.propertyKey));
    return missingProperties.map(property => {
      return {propertyKey: property.propertyKey, localStatus: LocalStatus.Unmodified, headValue: undefined};
    });
  }
}

import { Component, forwardRef, OnInit } from '@angular/core';
import { WidgetComponent } from './widget.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { blurOnKeyDown } from '../common/utils/keypress.utils';
import * as _ from 'lodash';
import { PropertyDisplayType } from './property-display-type.enum';
import { PropertyWidgetContext } from './property-widget.context';
import { PropertyWidgetContextType } from './property-widget-context-type.enum';

@Component({
  selector: 'adm4-simple-textarea-property',
  template: `
    <div [formGroup]='group' class='input-field-container'>
      <ng-container [ngSwitch]='type'>
        <textarea *ngSwitchCase='displayType.MULTI'
                  [placeholder]='multiLinePlaceholder' [rows]='defaultNumberOfRows'
                  class='admn4-textarea-input form-control textarea-property'
                  [readonly]='readOnly'
                  [formControlName]='widgetProperty.propertyKey'
                  #textArea
                  (focus)='onSelect($event, textArea)'
                  (blur)='trimWhiteSpace($event)'
        ></textarea>
        <input *ngSwitchCase='displayType.SINGLE'
               [placeholder]='defaultText'
               class='admn4-text-input form-control textfield-property'
               [readonly]='readOnly'
               [formControlName]='widgetProperty.propertyKey'
               (keydown.enter)='onEnterPressed($event)'
               (focus)='onSelect($event, this)'
               (blur)='trimWhiteSpace($event)'
        />
        <span *ngSwitchCase='displayType.SECRET_WARNING'>
          Please set a variable for this property. To define the value, edit the inventory and insert a secret.
        </span>
      </ng-container>
    </div>
  `,
  styleUrls: ['widget.component.scss', 'simple-textarea-property.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SimpleTextareaPropertyComponent),
    multi: true
  }]
})

export class SimpleTextareaPropertyComponent extends WidgetComponent implements OnInit {

  private readonly PLACEHOLDER_TEXT = 'Please enter one value per line...';
  private readonly DEFAULT_ROW_NUMBER = 2;

  private readonly EXTENDED_ROW_NUMBER = 3;
  public readonly displayType = PropertyDisplayType;

  constructor(private pwc: PropertyWidgetContext) {
    super();
  }

  override isMultiValue() {
    return true;
  }

  get type(): PropertyDisplayType {
    if (this.isSecret && this.isValueEmpty() && this.isPatternEditorScreen()) {
      return PropertyDisplayType.SECRET_WARNING;
    }
    if (this.isPropertyMultiValue()) {
      return PropertyDisplayType.MULTI;
    }
    return PropertyDisplayType.SINGLE;
  }

  isPropertyMultiValue(): boolean {
    return this.maxAllowed > 1;
  }

  /**
   * Cannot call blurOnEnterDown directly from template
   * @param event
   */
  onEnterPressed(event): void {
    blurOnKeyDown(event);
  }

  get multiLinePlaceholder(): string {
    if (_.isArray(this.defaultValue)) {
      return `default:\n${this.defaultValue.join('\n')}`;
    } else if (_.isNil(this.defaultValue)) {
      return this.PLACEHOLDER_TEXT;
    }
    return this.defaultText;
  }

  get defaultNumberOfRows(): number {
    if (_.isArray(this.defaultValue) && this.defaultValue.length > 1) {
      return this.EXTENDED_ROW_NUMBER;
    }
    return this.DEFAULT_ROW_NUMBER;
  }

  isPatternEditorScreen() {
    return this.pwc.type === PropertyWidgetContextType.PATTERN_EDITOR;
  }

  trimWhiteSpace(event) {
    if (this.readOnly) {
      return;
    }
    this.group.controls[this.widgetProperty.propertyKey].setValue(_.trim(event.target.value));
  }
}

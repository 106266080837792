export enum NavigationConstants {
  ROOT = '/',
  WELCOME = 'welcome',
  LOGOUT = 'logout',
  LOGIN = 'login',
  PROJECTS = 'projects',
  PATTERNS = 'patterns',
  ISSUES = 'issues',
  OVERVIEW = 'overview',
  OVERVIEW_APPLICATIONS = 'applications',
  OVERVIEW_REALMS = 'realms',
  OVERVIEW_INSTANCES = 'instances',
  VARIABLES = 'variables',
  REPORTS = 'reports',
  OVERVIEW_SUMMARY = 'summary',
  GRAPH_VIEW = 'graph',
  DEPLOY_MODAL_OUTLET = 'deploy',
  GLOBAL_SEARCH = 'global-search',
  PUBLISH_PROJECT = 'publishProject',
  PUBLISH_INVENTORY = 'publishInventory',
  INFRASTRUCTURE = 'infrastructure',
  INVENTORIES = 'inventories',
  EDIT = 'edit',
  INFRASTRUCTURE_INVENTORIES = '/infrastructure/inventories',
  INFRA_WELCOME = 'infra-welcome',
  DEPLOYMENT_HISTORY = 'deployments',
  KUBERNETES_STATUS = 'k8s-status',
  INVENTORY_STATUS = 'inventory-status',
  RESOURCES = 'resources',
  INVENTORY_SETTINGS = 'inventory-settings',
  PROJECT_SETTINGS = 'project-settings',
  LIBRARY_MANAGEMENT = 'library-management',
  MARKETPLACE = 'marketplace',
  GLOBAL_CONSTANTS = 'global-constants',
  SECRET_MANAGEMENT = 'secret-management',
  CERTIFICATE_OVERVIEW = 'certificate-overview',
  KUBERNETES_CERTIFICATE_OVERVIEW = 'kubernetes-certificate-overview',
  ACCESS_MANAGEMENT = 'access-mgmt',
  USER_MANAGEMENT = 'users',
  GROUP_MANAGEMENT = 'groups',
  CATEGORY = 'c',
  SUMMARY_SEARCH_TERM = 'q',
  TITLE_EDIT = 'titleEdit',
  PARAM_POSITION = 'pos',
  PARAM_RESOURCETYPE = 'resourceType',
  PARAM_RESOURCEID = 'resourceID',
  PARAM_SCOPE = 'scope',
}

import { map, withLatestFrom } from 'rxjs/operators';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Pattern } from '../../patterns/pattern.model';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PatternInstance, PatternProperty } from '../../patterns/pattern-instance.model';
import { PluginModel } from '../../plugins/plugin.model';
import { PatternType } from '../../plugins/pattern-type.model';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { PropertyType } from '../../plugins/property-type.model';
import { CreatePattern } from '../../model/pattern';
import { select, Store } from '@ngrx/store';
import { AppState, ProjectKey } from '../../model/reducer';
import { PatternService } from '../../patterns/pattern.service';
import { Observable } from 'rxjs';
import { PluginService } from '../../plugins/plugin.service';
import { CreatePatternService } from '../../patterns/create-pattern/create-pattern.service';
import { PluginHelper } from '../../plugins/plugin.helper';
import { createNewPatternInstanceObject } from '../../patterns/pattern-instance.helper';
import { ModalNotificationService } from '../../notification/modal-notification.service';
import { ParameterHelper } from '../parameter.helper';
import { patternReferencePrefix } from '../../common/constants/reference-prefix.constants';
import { selectedPatternInstanceView } from '../../model/views';

const ENABLED_INPUT_PLACEHOLDER = 'Search or add a pattern...';

@Component({
  selector: 'adm4-pattern-reference',
  template: `
    <div *ngIf='!readOnly && canHaveNewPatterns'
         class='ui-dropdown'
         [class.active]='dropdownActive'
         [class.allowed]='addedPatterns.length < maxPatterns'
         (click)='addedPatterns.length < maxPatterns'
         (blur)='onDropdownBlur()' #dropdownInput>
      <input [(ngModel)]='inputValue' #input
             (click)='toggleDropdown(true)'
             (focus)='select.emit(input)'
             (keydown.tab)='toggleDropdown(false)'
             (keyup.esc)='onDeactivateInput()'
             #textInput
             [placeholder]='placeholder'
             class='admn4-text-input form-control ui-dropdown-input'
             (blur)='setFormControlAsTouched()'
      />
      <adm4-validation-message *ngIf="hasError" [isError]='true' [message]=PATTERN_NOT_EXISTS_MESSAGE></adm4-validation-message>
      <div class='form-control ui-icon-container'
           (click)='onArrowClick(); textInput.focus()'>
        <span class='fa fa-caret-down'></span>
      </div>
      <adm4-dropdown-list *ngIf='dropdownActive'
                          [listItems]='abstractPatterns$ | async'
                          [inputValue]='inputValue'
                          [patternTypes]='patternTypes'
                          [scrollArea]='scrollArea'
                          (itemClicked)='onPatternClick($event)'
                          (outsideClicked)='onOutsideClicked($event)'
                          (createPatternClicked)='onCreatePatternClicked(parameters.className, projectKey, inputValue)'></adm4-dropdown-list>
    </div>
    <span *ngIf='readOnly && addedPatterns?.length === 0'>Not set</span>
    <div *ngIf='addedPatterns.length > 0'
         class='form-control ui-added-pattern-list full'
         [formGroup]='group'>
      <adm4-added-pattern-list [patternList]='addedPatterns'
                               [uniqueId]='parameters.className'
                               [readOnly]='readOnly'
                               [patternTypes]='patternTypes'
                               [projectKey]='projectKey'
                               (deletePattern)='onDeleteClick($event)'
                               (orderChanges)='updateForm(group, widgetProperty.propertyKey, $event)'
                               (focusLink)='select.emit(this)'
      ></adm4-added-pattern-list>
    </div>
  `,
  styleUrls: ['pattern-reference-property.scss', '../widget.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PatternReferencePropertyComponent),
    multi: true
  }]
})
export class PatternReferencePropertyComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() projectKey: string;
  @Input() patterns: Pattern[];
  @Input() patternTypes: Dictionary<PatternType>;
  @Input() propertyType: PropertyType;
  @Input() widgetProperty: PatternProperty;
  @Input() group: UntypedFormGroup;
  @Input() value: string[] = [];
  @Input() parameters: any;
  @Input() readOnly: boolean;
  @Input() scrollArea?: HTMLElement;
  @Output() select = new EventEmitter();
  addedPatterns: Pattern[] = [];
  dropdownActive = false;
  inputValue = '';
  minRequired: number;
  maxPatterns: number;
  placeholder = ENABLED_INPUT_PLACEHOLDER;
  PATTERN_NOT_EXISTS_MESSAGE = 'The pattern reference is not set. Please click on the input field and select an existing pattern from the list or create a new one by clicking on "+ Add patterns"';

  abstractPatterns$: Observable<Pattern[]>;

  @ViewChild('dropdown', {static: false}) dropdown: ElementRef;
  @ViewChild('dropdownInput', {static: false}) dropdownInput: ElementRef;
  @ViewChild('textInput', {static: false}) textInput: ElementRef;

  constructor(private store$: Store<AppState>,
              private patternService: PatternService,
              private pluginService: PluginService,
              private createPatternService: CreatePatternService,
              private modalNotificationService: ModalNotificationService) {
  }

  get canHaveNewPatterns(): boolean {
    return this.addedPatterns.length < this.maxPatterns;
  }

  ngOnInit(): void {
    this.minRequired = ParameterHelper.getSpecificParameterValueNumber(ParameterHelper.MIN_REQUIRED, this.parameters, this.propertyType) || 0;
    this.maxPatterns = ParameterHelper.getSpecificParameterValueNumber(ParameterHelper.MAX_ALLOWED, this.parameters, this.propertyType) || Number.MAX_SAFE_INTEGER;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const patterns = changes['patterns'];
    if (patterns && patterns.currentValue) {
      this.initAddedPatterns(patterns.currentValue, this.value);
      this.placeholder = this.getPlaceholderText();
    }
  }

  onArrowClick(): void {
    this.toggleDropdown(!this.dropdownActive);
    this.textInput.nativeElement.focus();
  }

  initAddedPatterns(patterns: Pattern[] = [], value: string[] = []): void {
    this.addedPatterns = this.getPatternsFromIds(value, patterns);
  }

  getPatternsFromIds(value: string[] = [], patterns: Pattern[] = []): Pattern[] {
    return value.map((refPatternValue: string) => {
      let referencedPattern = patterns.find(item =>
        item.patternId === refPatternValue.replace(patternReferencePrefix, ''));
      if (!referencedPattern) {
        const refValueWithoutPrefix = refPatternValue.replace(patternReferencePrefix, '');
        // create a fake pattern || consider creating a different type
        referencedPattern = {patternId: refValueWithoutPrefix, name: refValueWithoutPrefix} as Pattern;
      }
      return referencedPattern;
    });
  }

  onDropdownBlur(): void {
    this.dropdownActive = false;
  }

  setFormControlAsTouched(): void {
    this.group.controls[this.widgetProperty.propertyKey].markAsTouched();
  }

  onDeactivateInput() {
    this.textInput.nativeElement.blur();
    this.onDropdownBlur();
  }

  onPatternClick(item): void {
    this.dropdownActive = false;
    this.addedPatterns.push(item);
    const addedPatIds = this.addedPatterns.map(pat => pat.patternId);
    this.updateForm(this.group, this.widgetProperty.propertyKey, addedPatIds);
    this.clearInputField();
  }

  onDeleteClick(pattern): void {
    this.addedPatterns.splice(this.addedPatterns.indexOf(pattern), 1);
    const addedPatIds = this.addedPatterns.map(pat => pat.patternId);
    this.updateForm(this.group, this.widgetProperty.propertyKey, addedPatIds);
  }

  createPattern(patternPlugins: PluginModel[], projectKey: ProjectKey, patternName: string): void {
    const createPatternCallback = (createdPattern) => {
      this.handleCreatedPattern(createdPattern);
    };

    if (_.isEmpty(patternPlugins)) {
     this.modalNotificationService.openErrorDialog({description: 'There are currently no pattern types available for this property.'});
    } else if (patternPlugins.length === 1) {
      const newPattern = createNewPatternInstanceObject(patternPlugins[0], patternName);
      this.store$.dispatch(new CreatePattern({pattern: newPattern, projectKey: projectKey, onCreateSuccess: createPatternCallback}));
    } else {
      this.createPatternService.openAddPatternWindow(patternPlugins, projectKey, createPatternCallback, patternName);
    }
    this.dropdownActive = false;
  }

  onCreatePatternClicked(className: string, projectKey: ProjectKey, patternName: string): void {
    this.pluginService.getPluginClassesFilteredByClass(projectKey, className).pipe(
      map((plugins: PluginModel[]) => plugins.map(plugin => PluginHelper.convertPatternTypeToPlugin(this.patternTypes[plugin.className]))))
      .subscribe((plugins: PluginModel[]) => {
        this.createPattern(plugins, projectKey, patternName);
      });
  }

  handleCreatedPattern(pattern: PatternInstance): void {
    this.patterns.push(pattern);
    this.onPatternClick(pattern);
  }

  getPlaceholderText(): string {
    return this.addedPatterns.length < this.maxPatterns ?
      ENABLED_INPUT_PLACEHOLDER : `Maximum number of references (${this.maxPatterns}) reached`;
  }

  private clearInputField(): void {
    this.inputValue = '';
  }

  updateForm(group: UntypedFormGroup, controlKey: string, values: string[] = []): void {
    values = this.addPrefixToPropertyValue(values);
    group.controls[controlKey].markAsDirty();
    group.markAsDirty();
    group.controls[controlKey].setValue(values);
  }

  addPrefixToPropertyValue(values: string[]): string[] {
    return _.map(values, (v: string) => {
      if (!v.startsWith(patternReferencePrefix)) {
        v = patternReferencePrefix + v;
      }
      return v;
    });
  }

  writeValue(obj: any): void {
    this.value = [...obj];
    if (obj.length !== this.addedPatterns.length && this.patterns) {
      this.addedPatterns = [];
      const addedPatArray = this.getPatternsFromIds(obj, this.patterns);
      this.addedPatterns.push(...addedPatArray);
    } else if (obj.length && this.addedPatterns.length > 0) {
      this.addedPatterns = this.getPatternsFromIds(obj, this.patterns);
    }
    this.placeholder = this.getPlaceholderText();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {/* noop */}

  propagateChange = () => {/* noop */};

  onOutsideClicked(nativeElement: any): void {
    if (!this.dropdownInput.nativeElement.contains(nativeElement)) {
      this.dropdownActive = false;
    }
  }

  // dropdown status (closed/open) depends on the passed parameter
  toggleDropdown(dropdownActive: boolean): void {
    // data is loaded when dropdown goes from closed to open
    if (!this.dropdownActive && dropdownActive) {
      const abstractPatterns = this.loadAbstractPatterns();
      this.abstractPatterns$ = this.removeFirstListFromSecond(this.addedPatterns, abstractPatterns);
    }
    this.dropdownActive = dropdownActive;
  }

  loadAbstractPatterns(): Observable<Pattern[]> {
    return this.patternService.getFilteredPatternsByClass(this.projectKey, this.parameters.className);
  }

  /**
   * Removes all elements of first list from the second
   * @param {Pattern[]} addedPatterns
   * @param {Observable<Pattern[]>} abstractPatterns
   * @returns {Observable<Pattern[]>}
   */
  removeFirstListFromSecond(addedPatterns: Pattern[], abstractPatterns: Observable<Pattern[]>): Observable<Pattern[]> {
    return abstractPatterns.pipe(withLatestFrom(this.store$.pipe(select(selectedPatternInstanceView))), map(([patterns, selectedPatternInstance]: [Pattern[], PatternInstance]) => {
      return patterns.filter((pattern: Pattern) => !addedPatterns.find((p: Pattern) => p.patternId === pattern.patternId) && pattern.patternId !== selectedPatternInstance.patternId);
    }));
  }

  get hasError() {
    return !_.isEmpty(this.inputValue) && !this.dropdownActive;
  }
}

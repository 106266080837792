import { Tenant } from '../../tenant/tenant.model';
import { Dictionary } from '../reducer';
import { NevisAdminAction } from '../actions';
import { SetAnalyticsSubmissionNotificationMute, SharedActionTypes } from './shared.actions';
import * as _ from 'lodash';
import { SelectTenantPayload } from './select-tenant-payload.model';
import { AppInfoState } from './application-info.model';
import { AnalyticsState } from './analytics.model';

export interface SharedState {
  readonly allTenants: Dictionary<Tenant>;
  readonly selectedTenantKey: string | null;
  readonly applicationInfo: AppInfoState | null;
  readonly analytics: AnalyticsState;
}

export const initState: SharedState = {
  allTenants: {},
  selectedTenantKey: null,
  applicationInfo: null,
  analytics: {enabled: false, isSubmissionDue: false, isSubmissionNotificationMuted: false},
};

export const sharedReducer = (state: SharedState, action: NevisAdminAction<any>): SharedState => {
  switch (action.type) {
    case SharedActionTypes.LoadApplicationInfoSuccess:
      return {
        ...state,
        applicationInfo : action.payload
      };
    case SharedActionTypes.LoadAnalyticsInfoSuccess:
      return {
        ...state,
        analytics: action.payload,
      };
    case SharedActionTypes.SetAnalyticsSubmissionNotificationMute:
      const payload: boolean = (action as SetAnalyticsSubmissionNotificationMute).payload;
      return {
        ...state,
        analytics: {
          ...state.analytics,
          isSubmissionNotificationMuted: payload,
        },
      };
    case SharedActionTypes.LoadTenantsSuccess:
      return storeTenants(state, action.payload);
    case SharedActionTypes.InitTenantKey:
    case SharedActionTypes.SelectTenantKey:
      const selectTenantPayload: SelectTenantPayload = action.payload;
      if (_.isNil(state.allTenants[selectTenantPayload.tenantKey])) {
        return state;
      }
      return {
        ...state,
        selectedTenantKey: selectTenantPayload.tenantKey
      };
    default:
      return state;
  }
};

const storeTenants = (state: SharedState, tenantsList: Tenant[]): SharedState => {
  const tenants = _.mapKeys(tenantsList, (tenant: Tenant) => tenant.tenantKey);
  return {
    ...state,
    allTenants: tenants
  };
};

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { ApplicationInfo } from '../model/shared/application-info.model';

@Injectable()
export class ApplicationInfoService extends BaseService {

  getApplicationInfo(): Observable<ApplicationInfo> {
    const url = `/info`;
    return this.httpGet(url);
  }

  getFeVersion(): Observable<string> {
    return this.http.get('assets/version').pipe(
      map((response: { version: string }) => {
          return response.version;
        }
      ));
  }
}

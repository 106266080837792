import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PublishInventoryDialogComponent } from './publish-inventory-dialog.component';

@Injectable({providedIn: 'root'})
export class PublishInventoryDialogService {
  constructor(private dialog: MatDialog) {}

  openPublishChangesWindow(inventoryKey: string): MatDialogRef<PublishInventoryDialogComponent> {
    return this.dialog.open<PublishInventoryDialogComponent, string>(PublishInventoryDialogComponent, {
      panelClass: 'big-dialog',
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: false,
      autoFocus: false,
      data: inventoryKey
    });
  }
}

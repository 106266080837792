export interface UserToken {
  userKey?: string;
}

export interface UserData {
  credentials?: string;
  email?: string;
  familyName?: string;
  givenName?: string;
  type: UserTypes | string;
  userKey: UserKeys | string;
  groupKeys?: string[];
}

export interface UserVM extends UserData {
  displayName: string;
  /**
   * This is used to make specific users not editable.
   */
  readOnlyFE: boolean;
}

export const HACK_READONLY_USER_NAME = 'admin';

export const userToViewModel = (user: UserData): UserVM => {
  let displayName: string = ((user.givenName??'') + ' ' + (user.familyName??'')).trim();
  if (!displayName) {
    displayName = user.userKey;
  }
  const readOnlyFE: boolean = HACK_READONLY_USER_NAME === user.userKey;
  return {
    ...user,
    displayName,
    readOnlyFE,
  };
};

export enum UserTypes {
  Ldap = 'ldap',
  Saml = 'saml',
  local = 'local',
}

export enum UserKeys {
  Admin = 'admin'
}

import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'adm4-nothing-to-deploy',
  template: `
    <div class='full-height-flex'>
      <div class='remaining-space-flex-content-wrapper'>
        <div class='remaining-space-flex-content'>
          <div class="step-content">
            <h2 class='step-content-header'>Nothing to deploy</h2>
            <p>
              Please make sure the inventory and hosts you selected match the Deployment Target in at least one of the projects's patterns.
            </p>
          </div>
        </div>
      </div>
      <div class='step-action-bar'>
        <button matStepperPrevious class='previous-step-button'
                (click)='onBackFromNothingToDeploy()'>
          Change selection
        </button>
      </div>
    </div>
  `
})
export class NothingToDeployComponent {
  @Output() backFromNothingToDeploy: EventEmitter<void> = new EventEmitter<void>();

  onBackFromNothingToDeploy(): void {
    this.backFromNothingToDeploy.emit();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { InsertProjectVariablesDialogContext } from './insert-project-variables-dialog.context';
import { forkJoin, Observable } from 'rxjs';
import { Project } from '../../projects/project.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first, skip } from 'rxjs/operators';
import { ProjectHelper } from '../../projects/project.helper';
import { localStorageProjectKey } from '../../common/constants/local-storage-keys.constants';
import * as _ from 'lodash';
import { VariableService } from '../../variables/variable.service';
import { VariableModel } from '../../variables/variable.model';
import { InsertProjectVariablesHelper } from './insert-project-variables.helper';
import { ToastNotificationService } from '../../notification/toast-notification.service';
import { InsertProjectVariablesPayload } from './insert-project-variables-payload.model';
import { TenantHelper } from '../../common/helpers/tenant.helper';

@Component({
  selector: 'adm4-insert-project-variables-dialog',
  template: `
    <adm4-modal-dialog-title header='Import Variables' [showClose]='true' (closeClicked)="closeDialog()">
      <div class='insert-project-variables-containerg'>
        <form [formGroup]="form" name="form" #importForm (ngSubmit)='insertProjectVariables()'>
          <div class="content-container create-item-container">
            <div class='form-group'>
              <label for='insert-project-variables-project-keys-dropdown' class='input-label'>Import variables from project:</label>
              <mat-form-field class='full-width'>
                <mat-select id='insert-project-variables-project-keys-dropdown'
                            [formControlName]='projectKeyFormControlName'
                            [disableOptionCentering]='true'>
                  <mat-option *ngFor='let project of projects$ | async' [value]='project.projectKey'>{{project.projectKey | cropTenantFromKey}}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class='validation-message-container'>
                <adm4-validation-message [isInfo]='true' message='All variables of the selected project will be added at the bottom of the inventory with their sample value.'></adm4-validation-message>
              </div>
            </div>
          </div>
          <div mat-dialog-actions>
            <button type='button' class='admn4-button-text'
                    (click)='closeDialog()'>
              Cancel
            </button>
            <button class='admn4-button-ellipse-blue'
                    [disabled]='!canInsert'>
              Import variables
            </button>
          </div>
        </form>
      </div>
    </adm4-modal-dialog-title>
  `,
  styleUrls: ['../../common/styles/component-specific/modal-window.scss', '../../common/styles/component-specific/create-form.scss'],
  providers: [InsertProjectVariablesDialogContext]
})
export class InsertProjectVariablesDialogComponent implements OnInit {
  projects$: Observable<Project[]>;

  form: UntypedFormGroup;

  readonly projectKeyFormControlName = 'projectKey';

  constructor(@Inject(MAT_DIALOG_DATA) private insertProjectVariablePayload: InsertProjectVariablesPayload,
              private dialogRef: MatDialogRef<InsertProjectVariablesDialogComponent>,
              private insertProjectVariablesDialogContext: InsertProjectVariablesDialogContext,
              private fb: UntypedFormBuilder,
              private variableService: VariableService,
              private toastNotificationService: ToastNotificationService) {
    this.projects$ = this.insertProjectVariablesDialogContext.projects$;
  }

  ngOnInit(): void {
    this.insertProjectVariablesDialogContext.loadProjects(this.insertProjectVariablePayload.tenantKey);
    this.form = this.createForm();
    this.preselectProjectKey();
  }

  preselectProjectKey(): void {
    forkJoin([
      this.insertProjectVariablesDialogContext.currentProjectKey$.pipe(first()),
      this.insertProjectVariablesDialogContext.projects$.pipe(skip(1), first())
    ]).subscribe(([projectKey, projects]: [string | null, Project[]]) => {
      const preselectedKey = ProjectHelper.getFromStoreOrFirstAvailable(projectKey, _.keyBy(projects, 'projectKey'), localStorageProjectKey);
      this.form.controls[this.projectKeyFormControlName].setValue(preselectedKey);
    });
  }

  createForm(): UntypedFormGroup {
    const form = this.fb.group({});
    form.addControl(this.projectKeyFormControlName, this.fb.control(null, Validators.required));
    return form;
  }

  insertProjectVariables(): void {
    const projectKey = this.form.value[this.projectKeyFormControlName];
    this.variableService.getAllVariablesOfProject(projectKey)
      .subscribe((variables: VariableModel[]) => {
        const projectName = TenantHelper.cropTenantFromKey(projectKey);
        if (_.isEmpty(variables)) {
          this.toastNotificationService.showWarningToast(`Project ${projectName} has no variables to insert.`);
          return;
        }
        const variableYamlContent = InsertProjectVariablesHelper.convertVariableListToYaml(variables, projectName);
        this.insertProjectVariablePayload.onInsert(variableYamlContent);
      });
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  get canInsert(): boolean {
    return this.form.valid;
  }
}

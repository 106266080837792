import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { VariableModel } from './variable.model';
import { VariableEditorHelper } from './variable-editor/variable-editor.helper';
import * as _ from 'lodash';

export class VariableValidators {
  static VariableKey = class {
    static variableWithSuchKeyExists(existingVariables: VariableModel[], initialVariableKey?: string): ValidatorFn {
      return (control: UntypedFormControl) => {
        const variableKey = control.value.trim();
        const variableNameChanged = _.isNil(initialVariableKey) || variableKey !== initialVariableKey;
        if (variableNameChanged && VariableEditorHelper.isVariableNameExistingAlready(existingVariables, variableKey)) {
          return {
            existingVariable: true
          };
        } else {
          return null;
        }
      };
    }
  };
}


import {merge as observableMerge,  Observable } from 'rxjs';

import {withLatestFrom, filter, map, switchMap, startWith} from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { InventorySettingsContext } from '../inventory-settings.context';
import { PermissionsHelper } from '../../../permissions/permissions.helper';
import * as _ from 'lodash';
import { OperationKey, PermissionAssignee } from '../../../model/permissions/permissions.model';
import { PermissionsService } from '../../../permissions/permissions.service';
import { inventoryOperationColumns } from './inventory-operation-columns.constant';

@Injectable()
export class InventoryPermissionsContext {
  readonly allowedOperationKeys: OperationKey[] = PermissionsHelper.getOperationKeysFromPermissionOperationColumns(inventoryOperationColumns);

  target$: Observable<string>;
  permissionAssignees$: Observable<PermissionAssignee[]>;

  private _loadPermissionAssignees: EventEmitter<void> = new EventEmitter();

  constructor(private inventorySettingsContext: InventorySettingsContext, private permissionsService: PermissionsService) {
    this.target$ = this.inventorySettingsContext.inventoryKey$.pipe(
      filter((inventoryKey: string | null) => !_.isNil(inventoryKey)),
      map((inventoryKey: string) => PermissionsHelper.createInventoryTarget(inventoryKey)));
    this.permissionAssignees$ = observableMerge(
      this.target$,
      this._loadPermissionAssignees.pipe(withLatestFrom(this.target$), map(([_v, target]) => target))
    ).pipe(
      switchMap((target: string) => {
        return this.permissionsService.getPermissionAssigneesForTarget(target, this.allowedOperationKeys, true, false);
      }), startWith([]));
  }

  loadPermissionAssignees(): void {
    this._loadPermissionAssignees.emit();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { extractAncestorChain, findById, flattenTreeNode, FlatTreeNode, TreeNode, } from './tree-node.model';
import { TreeNodeTypeEnum } from './tree-node-type.enum';
import { TreeNodeHelper } from './tree-node.helper';
import { TREE_EXPAND } from './tree-expand.provider';
import { Maybe } from '../../common/utils/utils';

@Component({
  selector: 'adm4-tree-viewer',
  template: `
    <cdk-virtual-scroll-viewport [itemSize]="itemSizePx" class="cdk-virtual-scroll-width-100">
      <ng-container *cdkVirtualFor="let node of dataSource">
        <div class='tree-node tree-node-h-21' (click)="onNodeSelect(node)"
             [adm4ScrollTarget]="node.id" [scrollToWhenSelectedKey]="selectedNodeId" [scrollMode]="'instant'" [scrollVerticalAlignment]="'nearest'"
             [style.margin-left]="16 * node.level + 'px'"
             [class.node-selected]="node.id === selectedNode?.id"
             [class.error-node]='node.hasError'
             [class.warning-node]='node.hasOnlyWarnings'
             [class.greyed-out]='isGreyedOut(node)'>
          <div class="toggle-control-container">
            <ng-container *ngIf="hasChild(node)">
              <mat-icon class="tree-node-icon toggle-control" *ngIf="treeControl.isExpanded(node); else collapsedIcon" (click)="toggleNode($event, node)">arrow_drop_down</mat-icon>
              <ng-template #collapsedIcon><mat-icon class="tree-node-icon toggle-control" (click)="toggleNode($event, node)">arrow_right</mat-icon></ng-template>
            </ng-container>
          </div>

          <adm4-deployment-resource-icon class="tree-node-icon" [type]="node.type" [expanded]="treeControl.isExpanded(node)"></adm4-deployment-resource-icon>
          <span class="text-ellipsis">{{ node.name }}</span>
        </div>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  `,
  styleUrls: ['./tree-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeViewerComponent implements OnChanges {
  @Input() public nodes: TreeNode[] = [];
  @Input() initialExpandNodeIds: string[];
  /**
   * When only the differences should be shown, the nodes that don't have tasks should not be displayed.
   * When `showOnlyDifferences` is false, all nodes should be displayed, but the nodes that don't have tasks should be greyed out.
   */
  @Input() showOnlyDifferences: boolean = false;
  /**
   * When `forceShowAllItems` is true, all nodes should be displayed, regardless of the `showOnlyDifferences` value,
   * and also, they should not be greyed out.
   */
  @Input() forceShowAllItems: boolean = false;
  @Input() selectedNodeId: string;
  @Output() nodeSelected: EventEmitter<FlatTreeNode> = new EventEmitter();

  @ViewChild(CdkVirtualScrollViewport) virtualScroll: Maybe<CdkVirtualScrollViewport>;

  readonly itemSizePx = 21;
  readonly TreeNodeTypeEnum = TreeNodeTypeEnum;
  readonly treeControl: FlatTreeControl<FlatTreeNode, string>;
  readonly dataSource: MatTreeFlatDataSource<TreeNode, FlatTreeNode, string>;

  selectedNode: Maybe<FlatTreeNode>;

  private initialSelectionDone: boolean = false;

  constructor(
    @Inject(TREE_EXPAND) @Optional() private treeExpand: EventEmitter<void> | null,
    private cdRef: ChangeDetectorRef,
  ) {
    const treeFlattener: MatTreeFlattener<TreeNode, FlatTreeNode, string> = new MatTreeFlattener(
      (sourceNode: TreeNode, level: number): FlatTreeNode => flattenTreeNode(sourceNode, level),
      node => node.level,
      node => node.isExpandable,
      node => node.children,
    );
    this.treeControl = new FlatTreeControl<FlatTreeNode, string>(node => node.level, node => node.isExpandable, {trackBy: node => node.id});
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, treeFlattener);
    if (this.treeExpand) {
      this.treeExpand.pipe(takeUntilDestroyed()).subscribe(() => {
        this.treeControl.expandAll();
        this.cdRef.markForCheck();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nodes || changes.showOnlyDifferences || changes.forceShowAllItems) {
      this.applyFiltering();
    }
    if (changes.initialExpandNodeIds || changes.nodes) {
      this.applyInitialExpansion();
    }
    if (!this.initialSelectionDone && changes.initialExpandNodeIds) {
      const firstNode: Maybe<FlatTreeNode> = this.treeControl.dataNodes[0];
      if (firstNode) {
        this.initialSelectionDone = true;
        setTimeout(() => this.onNodeSelect(firstNode));
      }
    }
    if (changes['selectedNodeId'] && this.selectedNodeId && this.selectedNode && this.selectedNodeId !== this.selectedNode.id) {
      this.applySelection();
    }
  }

  private applyInitialExpansion() {
    (this.initialExpandNodeIds ?? []).forEach((expandedNodeId) => {
      const expandedNode: Maybe<FlatTreeNode> = (this.treeControl.dataNodes ?? []).find((node: FlatTreeNode) => node.id === expandedNodeId);
      if (expandedNode) {
        this.treeControl.expand(expandedNode);
      }
    });
  }

  private applyFiltering(): void {
    const shouldFilterNodes = this.showOnlyDifferences && !this.forceShowAllItems;
    this.dataSource.data = TreeNodeHelper.applyFiltering(this.nodes, shouldFilterNodes);
  }

  private applySelection(): void {
    const newSelectedNode: Maybe<FlatTreeNode> = findById(this.selectedNodeId, this.treeControl.dataNodes);
    if (newSelectedNode) {
      this.selectedNode = newSelectedNode;
      this.nodeSelected.emit(newSelectedNode);
      const ancestors: string[] = extractAncestorChain(newSelectedNode);
      ancestors.forEach((ancestor: string) => {
        const ancestorNode: Maybe<FlatTreeNode> = findById(ancestor, this.treeControl.dataNodes);
        if (ancestorNode) {
          this.treeControl.expand(ancestorNode);
        }
      });
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
      if (this.virtualScroll) {
        this.virtualScroll.scrollToIndex(this.treeControl.dataNodes.indexOf(newSelectedNode), 'smooth');
      }
    }
  }

  toggleNode(event: Event, node: FlatTreeNode) {
    event.preventDefault();
    event.stopPropagation();
    this.treeControl.toggle(node);
  }

  onNodeSelect(node: FlatTreeNode): void {
    this.selectedNode = node;
    this.nodeSelected.emit(this.selectedNode);
  }

  hasChild(node: TreeNode): boolean {
    return node.children && node.children.length > 0;
  }

  isGreyedOut(node: TreeNode): boolean {
    if (this.forceShowAllItems) {
      return false;
    }
    return !this.showOnlyDifferences && !node.hasChangesOrIsImportantType;
  }
}

// Global app constants
export const POLL_INTERVAL =  1000;
export const MILLISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;
export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE;
export const DEFAULT_TENANT_KEY = 'DEFAULT';
export const DEFAULT_INVENTORY_TYPE = 'YAML';

export enum PropertyTypeEnum {
  SimpleTextPropertyComponent = 'SimpleTextPropertyComponent',
  SelectionPropertyComponent = 'SelectionPropertyComponent',
  TextPropertyComponent = 'TextPropertyComponent',
  PatternReferencePropertyComponent = 'PatternReferencePropertyComponent',
  FallbackPropertyComponent = 'FallbackPropertyComponent',
  AttachmentPropertyComponent = 'AttachmentPropertyComponent',
  DateTimePropertyComponent = 'DateTimePropertyComponent',
  ModSecurityRulePropertyComponent = 'ModSecurityRulePropertyComponent',
  KeyValuePropertyComponent = 'KeyValuePropertyComponent',
}

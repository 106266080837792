import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProjectSettingsContext } from '../project-settings.context';
import { ProjectDependenciesDialogService } from './project-dependencies-dialog.service';
import { EditDependenciesModel } from './project-dependencies.model';
import { Observable } from 'rxjs';
import { ProjectBundle } from '../../projects/project.model';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { ParsedBundle, STANDARD_BUNDLE_INFO } from '../../resources/bundle-management/bundle.model';
import { ProjectDependenciesHelper } from './project-dependencies.helper';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-project-dependencies',
  template: `
    <div>
      <div class="section-title">Standard libraries</div>
      <div class="table-container">
        <adm4-project-common-dependencies *ngIf='shouldDisplayBundleTableSection(standardProjectBundles$ | async); else noStandardBundles'
                [projectBundles]="standardProjectBundles$ | async"
                [isEditMode]="false"></adm4-project-common-dependencies>
        <ng-template #noStandardBundles>
          <div class='empty-library-message'>No library is assigned to this project</div>
        </ng-template>
        <div class="footer">
          <div>
            <adm4-validation-message *ngIf="hasInvalidBundles(standardProjectBundles$ | async, projectSettingsContext.allBundles$ | async)" [isError]='true' [message]='DEPENDENCIES_NOT_AVAILABLE_TEXT'></adm4-validation-message>
          </div>
          <div *ngIf="!readOnly">
            <button (click)="openEditDialog()" class="admn4-button-ellipse-blue">Edit</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf='shouldDisplayBundleTableSection(additionalProjectBundles$ | async)'>
      <div class="section-title">Additional libraries</div>
      <div class="table-container">
        <adm4-project-dependencies-table
                [projectBundles]="additionalProjectBundles$ | async"
                [isEditMode]="false"></adm4-project-dependencies-table>
        <div class="footer">
          <div>
            <adm4-validation-message *ngIf="hasInvalidBundles(additionalProjectBundles$ | async, projectSettingsContext.allBundles$ | async)" [isError]='true' [message]='DEPENDENCIES_NOT_AVAILABLE_TEXT'></adm4-validation-message>
          </div>
          <div *ngIf="!readOnly">
            <button (click)="openEditDialog()" class="admn4-button-ellipse-blue">Edit</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['project-dependencies.component.scss']
})
export class ProjectDependenciesComponent implements OnChanges {
  @Input() readOnly: boolean;
  @Input() projectKey: string;

  standardProjectBundles$: Observable<ProjectBundle[]>;
  additionalProjectBundles$: Observable<ProjectBundle[]>;

  readonly DEPENDENCIES_NOT_AVAILABLE_TEXT = 'Some of the libraries are not available anymore. Please update or remove them.';

  constructor(public projectSettingsContext: ProjectSettingsContext,
              private projectDependenciesDialogService: ProjectDependenciesDialogService) {
    this.standardProjectBundles$ = this.projectSettingsContext.displayProjectBundles$.pipe(
      map((projectBundles: ProjectBundle[]) => projectBundles.filter(projectBundle => ProjectDependenciesHelper.isProjectBundleStandard(projectBundle, STANDARD_BUNDLE_INFO)))
    );
    this.additionalProjectBundles$ = this.projectSettingsContext.displayProjectBundles$.pipe(
      ProjectDependenciesHelper.marketplaceFilter,
      map((projectBundles: ProjectBundle[]) => projectBundles.filter(projectBundle => !ProjectDependenciesHelper.isProjectBundleStandard(projectBundle, STANDARD_BUNDLE_INFO)))
    );
    this.handleDependencyUpdateTrigger();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectKey && this.projectKey) {
      this.projectSettingsContext.loadProjectBundles(this.projectKey);
    }
  }

  shouldDisplayBundleTableSection(projectBundles: ProjectBundle[]): boolean {
    return !_.isEmpty(projectBundles);
  }

  hasInvalidBundles(projectBundles: ProjectBundle[], allBundles: ParsedBundle[]): boolean {
    return ProjectDependenciesHelper.isAnyProjectBundleInvalid(projectBundles, allBundles);
  }

  openEditDialog(): void {
    const editModel: EditDependenciesModel = {
      projectBundles$: this.projectSettingsContext.projectBundles$,
      projectKey: this.projectKey,
      onSaveCallback: () => this.reloadBundles()
    };
    this.projectDependenciesDialogService.openEditDialog(editModel);
  }

  reloadBundles(): void {
    this.projectSettingsContext.loadProjectBundles(this.projectKey);
    this.projectSettingsContext.reloadPatternTypes(this.projectKey);
    this.projectSettingsContext.reloadPropertyTypes(this.projectKey);
    this.projectSettingsContext.invalidatePluginDoc();
  }

  handleDependencyUpdateTrigger(): void {
    this.projectSettingsContext.allBundles$.pipe(
      withLatestFrom(this.projectDependenciesDialogService.openDependencyDialogTrigger))
      .pipe(take(1)).subscribe(() => this.openEditDialog());
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, OnChanges, Output } from '@angular/core';
import { CommandModel, FileModel, GenerationTriggerModel, OutputType, PlanTriggerModel } from '../../planning-output.model';
import { ItemDetailsComponent } from '../item-details.component';
import { OutputHelper } from '../../../../output.helper';
import * as _ from 'lodash';

@Component({
  selector: 'adm4-command-details',
  templateUrl: './command-details.component.html',
  styleUrls: ['../../item-details.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommandDetailsComponent extends ItemDetailsComponent<CommandModel> implements OnChanges {
  @Output() changeSelectedDetail: EventEmitter<string> = new EventEmitter<string>();
  readonly OutputType = OutputType;
  conditionFiles: FileModel[];
  conditionFailureTriggers: (GenerationTriggerModel | PlanTriggerModel)[];
  conditionSuccessTriggers: (GenerationTriggerModel | PlanTriggerModel)[];

  ngOnChanges(): void {
    this.conditionFiles = OutputHelper.getFilesByIds(this.node.output, this.node.details.conditions);
    this.conditionFailureTriggers = OutputHelper.getFailureTriggersByIds(this.node.output, this.node.details.commandId);
    this.conditionSuccessTriggers = OutputHelper.getSuccessTriggersByIds(this.node.output, this.node.details.commandId);
  }

  get shouldDisplayConditions(): boolean {
    return !_.isEmpty(this.conditionFiles) || !_.isEmpty(this.conditionFailureTriggers) || !_.isEmpty(this.conditionSuccessTriggers);
  }

  get canNavigateToTrigger(): boolean {
    return this.outputType === OutputType.GENERATION;
  }

  /**
   * Emits event to change selected node, which will show details of either file or directory
   * @param {string} artifactId
   */
  navigateToArtifact(artifactId: string): void {
    this.changeSelectedDetail.emit(artifactId);
  }

  navigateToTrigger(triggerId: string): void {
    if (this.canNavigateToTrigger) {
      this.changeSelectedDetail.emit(triggerId);
    }
  }
}

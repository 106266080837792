import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { ScrollService } from '../services/scroll/scroll.service';
import * as _ from 'lodash';

@Directive({
  selector: '[adm4Scrollable]'
})
export class ScrollableDirective implements OnChanges {
  @Input() selectedClass: string | null;
  @Input() removeSelector: string;

  constructor(private renderer: Renderer2, private scrollAreaElemRef: ElementRef, private scrollService: ScrollService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedClass']) {
      this.changeSelectionAndScrollPosition();
    }
  }

  private changeSelectionAndScrollPosition(): void {
    const deselectElements = this.scrollAreaElemRef.nativeElement.querySelectorAll(this.removeSelector);
    if (deselectElements) {
      _.forEach(deselectElements, (element) => this.renderer.removeClass(element, 'selected'));
    }
    if (!_.isNil(this.selectedClass)) {
      const selectedElement = this.scrollAreaElemRef.nativeElement.querySelector('.'.concat(this.selectedClass));
      if (!selectedElement) {
        console.warn(`Could not find help entry for ${this.selectedClass}`);
        return;
      }
      this.renderer.addClass(selectedElement, 'selected');
      setTimeout(() => this.scrollService.scrollIntoViewIfNeeded(this.scrollAreaElemRef.nativeElement, selectedElement));
    }
  }
}


import { Component, Input } from '@angular/core';
import { LoadingModalData } from './loading-modal-data.model';

@Component({
  selector: 'adm4-loading-modal',
  template: `
    <div class='loading-content'>
      <div class="loading-spinner">
        <mat-spinner [diameter]='100'></mat-spinner>
      </div>
      <div class='loading-description'>
        <p>{{loadingData.description}}</p>
        <p *ngIf='loadingData.progressText'>{{loadingData.progressText | async}}</p>
      </div>
    </div>
  `,
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent {
  @Input() loadingData: LoadingModalData;
}

import { Component, Input } from '@angular/core';
import { InventoryValidationIssue } from '../../../model/validation-status.model';

@Component({
  selector: 'adm4-inventory-validation-issue',
  template: `
    <div class='issue'>
      <div class="issue-heading">
        <adm4-validation-indicator [isDisplayed]='true' [isError]='isError' [isWarning]='isWarning' [isInfo]='isInfo'></adm4-validation-indicator>
        <span class="issue-text">
           {{issue.detail}}
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./inventory-validation-issue.component.scss']
})
export class InventoryValidationIssueComponent {
  @Input() issue: InventoryValidationIssue;
  @Input() isError: boolean;
  @Input() isWarning: boolean;
  @Input() isInfo: boolean;
}

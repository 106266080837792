import { Dictionary } from '../reducer';
import { PluginModel } from '../../plugins/plugin.model';
import * as fromActions from '../actions';
import { ReportActionTypes } from './report.actions';
import { GeneratedReportInfo, ReportGeneratingOutputPayload, ReportGenerationStatus, ReportGenerationStatusModel } from '../../reports/reports.model';
import { ReportsHelper } from '../../reports/reports.helper';
import { ProjectSummaryReportModel } from '../../projects/project-summary/project-summary-report/project-summary-report.model';
import { HTTP_STATUS_NOT_FOUND } from '../../shared/http-status-codes.constants';

export class PatternSummaryReportsState {
  readonly applicationsReport?: ProjectSummaryReportModel;
  readonly realmsReport?: ProjectSummaryReportModel;
  readonly instancesReport?: ProjectSummaryReportModel;
  readonly reportsNotFound: boolean;
  readonly reportsFailedToLoad: boolean;
}

export class ReportState {
  readonly reports: PluginModel[];
  readonly generatedReports: Dictionary<GeneratedReportInfo>;
  readonly isGenerationInProgress: boolean;
  readonly patternSummaryReports: PatternSummaryReportsState;
}

export const initState: ReportState = {
  reports: [],
  generatedReports: {},
  isGenerationInProgress: false,
  patternSummaryReports: {
    reportsNotFound: false,
    reportsFailedToLoad: false
  }
};

export function reducer(state: ReportState, action: fromActions.NevisAdminAction<any>): ReportState {
  switch (action.type) {
    case ReportActionTypes.LoadReportsSuccess: {
      return {
        ...state,
        reports: action.payload
      };
    }
    case ReportActionTypes.InvalidatePatternSummaryReports: {
      return {
        ...state,
        patternSummaryReports: initState.patternSummaryReports
      };
    }
    case ReportActionTypes.LoadPatternSummaryReportsSuccess: {
      const reports: ProjectSummaryReportModel[] = action.payload;
      // Note: Here we rely on the order of reports in payload to be the same as we expect (applications, realms, instances).
      const newPatternSummaryReports = {
        ...state.patternSummaryReports,
        reportsNotFound: false,
        reportsFailedToLoad: false,
        applicationsReport: reports[0],
        realmsReport: reports[1],
        instancesReport: reports[2]
      };
      return {
        ...state,
        patternSummaryReports: newPatternSummaryReports
      };
    }
    case ReportActionTypes.FailedToLoadLoadPatternSummaryReport: {
      const statusCode: number = action.payload;
      const newPatternSummaryReports = {
        ...state.patternSummaryReports,
        applicationsReport: undefined,
        realmsReport: undefined,
        instancesReport: undefined,
        reportsFailedToLoad: HTTP_STATUS_NOT_FOUND !== statusCode,
        reportsNotFound: HTTP_STATUS_NOT_FOUND === statusCode
      };
      return {
        ...state,
        patternSummaryReports: newPatternSummaryReports
      };
    }
    case ReportActionTypes.GenerateReport: {
      return {
        ...state,
        isGenerationInProgress: true
      };
    }
    case ReportActionTypes.PollGenerationStatusSuccess: {
      const reportGenerationStatus: ReportGenerationStatusModel = action.payload;
      const generatedReportKey = ReportsHelper.createReportKey(reportGenerationStatus);
      return {
        ...state,
        isGenerationInProgress: reportGenerationStatus.overallStatus !== ReportGenerationStatus.FAILED,
        generatedReports: {
          ...state.generatedReports,
          [generatedReportKey]: {
            reportId: reportGenerationStatus.reportId,
            inventoryKey: reportGenerationStatus.inventoryKey,
            generationDatetime: Date.now(),
            generationOutput: null,
            finalGenerationStatus: reportGenerationStatus.overallStatus
          }
        }
      };
    }
    case ReportActionTypes.GetGenerationOutputComplete:
      const reportGeneratingOutputPayload: ReportGeneratingOutputPayload = action.payload;
      return {
        ...state,
        isGenerationInProgress: false,
        generatedReports: {
          ...state.generatedReports,
          [reportGeneratingOutputPayload.reportKey]: {
            ...state.generatedReports[reportGeneratingOutputPayload.reportKey],
            generationOutput: reportGeneratingOutputPayload.generationOutput
          }
        }
      };
    case ReportActionTypes.StopGeneration:
      return {
        ...state,
        isGenerationInProgress: false
      };
    default: {
      return state;
    }
  }
}

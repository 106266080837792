import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform  {
  readonly DATE_FMT_FULL = 'MMM d, y, HH:mm';
  readonly DATE_FMT_CURRENT_YEAR = 'MMM d, HH:mm';
  readonly DATE_FMT_TODAY = 'HH:mm';
  private currentDate: Date;

  constructor(private datePipe: DatePipe) {
    this.currentDate = new Date();
  }

  transform(value: Date | string): string | null {
    let transformed = '';

    if (!value) {
      return transformed;
    }

    try {
      return this.datePipe.transform(value, this.getFormat(new Date(value))) || '';
    } catch (err) {
      return value + '';
    }
  }

  private isCurrentYear(value: Date) {
    return this.currentDate.getFullYear() === value.getFullYear();
  }

  private isCurrentMonth(value: Date) {
    return this.isCurrentYear(value) && this.currentDate.getMonth() === value.getMonth();
  }

  private isToday(value: Date) {
    return this.isCurrentMonth(value) && this.currentDate.getDate() === value.getDate();
  }

  getFormat(value: Date) {
    if (this.isToday(value)) {
      return this.DATE_FMT_TODAY;
    } else if (this.isCurrentYear(value)) {
      return this.DATE_FMT_CURRENT_YEAR;
    }
    return this.DATE_FMT_FULL;
  }

}

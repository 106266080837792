import * as _ from 'lodash';

/**
 * Decorator that allows adding mixins into classes, which allows implementing composition
 * for more info read https://www.typescriptlang.org/docs/handbook/mixins.html
 * When using a mixin, add decorator and pass your mixin classes in, and don't forget to add interface of the mixin to implements declarations
 * Implementing a mixin class can be similar to implementing interface with default methods in java
 * NOTE: decorator will only carry over the methods, state should be provided by client(class that implements mixin)
 * @param baseCtors
 * @constructor
 */
export function Mixin(baseCtors: Function[]) {
  return function (derivedCtor: Function) {
    baseCtors.forEach(baseCtor => {
      Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
        if (_.isFunction(baseCtor.prototype[name]) && baseCtor.prototype[name] !== baseCtor) {
          derivedCtor.prototype[name] = baseCtor.prototype[name];
        }
      });
    });
  };
}
